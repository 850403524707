import scriptBuilderLabels from './labelCollections/scriptBuilderLabels';
import pipedaTextLabels from './labelCollections/pipedaTextLabels';
import slaSearchLabels from './labelCollections/slaSearchLabels';
import endcallLabels from './labelCollections/endcallLabels';
import errorLabels from './labelCollections/errorLabels';
import healthServiceLabels from './labelCollections/healthServiceLabels';
import tourManagementLabels from './labelCollections/tourManagementLabels';
import five9ErrorLabels from './labelCollections/five9ErrorLabels';
import notificationsLabels from './labelCollections/notificationsLabels';
import warmTransferLabels from './labelCollections/warmTransferLabels';
import inquirySearchLabels from './labelCollections/inquirySearchLabels';
import inquiryLabels from './labelCollections/inquiryLabels';
import operationsLabels from './labelCollections/operationsLabels';

export default {
  ...endcallLabels,
  ...errorLabels,
  ...five9ErrorLabels,
  ...healthServiceLabels,
  ...inquiryLabels,
  ...inquirySearchLabels,
  ...notificationsLabels,
  ...operationsLabels,
  ...pipedaTextLabels,
  ...scriptBuilderLabels,
  ...slaSearchLabels,
  ...tourManagementLabels,
  ...warmTransferLabels,
  ABOUT_THE_FAMILY: 'About the Family',
  ABOUT_THE_RESIDENT: 'About the Resident',
  ACTION: 'Action',
  ACTIONS: 'Actions',
  ACTIONS_LAST_30_DAYS: 'ACTIONS (last 30 days)',
  ACTION_LIST: 'Action List',
  ACTIVITY: 'Activity',
  ACTUAL_SCHEDULED_TOUR_DATE: 'When was the tour actually completed?',
  ADD_NOTES: 'Add Notes',
  ADDITIONAL_CONTACT: 'Additional Contact',
  ADDITIONAL_CONTACTS: 'Additional Contacts',
  ADDITIONAL_CONTACT_DETAILS: 'Additional Contact Details',
  ADDITIONAL_MESSAGE_SPECIFIC_TO_THIS_COMMUNITY:
    'Additional message specific to this community',
  ADDITIONAL_NOTES: 'Additional Notes',
  ADDITIONAL_NOTES_FOR_THE_FAMILY: 'Additional notes for the family',
  ADDRESS: 'Address',
  ADDRESS_1: 'Address 1',
  ADDRESS_2: 'Address 2',
  ADD_LOCATION: 'Add location',
  ADD_MORE_COMMUNITIES:
    'Add more communities to your list, or browse other options.',
  ADULT_DAY_SERVICES: 'Adult Day Services',
  ADVISOR_EMAIL_SUBJECT: '{SLA_NAME} - Your Advisor with A Place for Mom',
  AFTERNOON_TIMES: 'Afternoon (12:00 PM - 6:00 PM)',
  AGE: 'Age',
  AGENT_NAME: 'Agent Name',
  ALLOW_CONTACT: 'Allow contact',
  ALL_ROOM_TYPES: 'All room types',
  ALL_URGENCY_TYPES: 'All urgency types',
  ALL_YOUR_NOTES_WILL_SHOW_UP: 'All your notes will show up here',
  ALMOST_THERE: 'almost there!',
  ALREADY_REFERRED_COMMUNITES: 'Already Referred Communities',
  AND: 'and',
  ANY_RANGE: 'Any range',
  APARTMENT_UNIT_TYPES: 'Apartment/Unit Types',
  APFM: 'A Place For Mom',
  APPLY: 'Apply',
  APPLY_FILTERS: 'Apply Filters',
  APP_ERROR_TITLE: 'Error {errorCode}: Uh oh, Something’s wrong here...',
  ARE_THERE_ANY_COMBATIVE_OR_WANDERING_ISSUES:
    'Are there any combative or wandering issues? ',
  ARE_THERE_ANY_OTHER_HEALTH_ISSUES_AND_OR_NUTRITIONAL_NEEDS:
    'Are there any other health issues and/or nutritional needs?',
  ARE_THEY_A_VETERAN_OR_WAS_THEIR_SPOUSE_A_VETERAN:
    'Are they a veteran or was their spouse a veteran?',
  ARE_YOU: 'Are you',
  ARE_YOU_CURRENTLY_ENROLLED_IN_MEDICAID:
    'Are you currently enrolled in Medicaid?',
  ASSIGNED_SLA: 'Assigned SLA',
  ASSIGN_IN_YGL: 'Assign in YGL',
  ASSISTED_LIVING: 'Assisted Living',
  ASSISTED_LIVING_COMMUNITIES: 'Assisted Living Communities',
  AT: 'at',
  AUTO_ANSWER: 'Auto - Answer',
  BACK: 'Back',
  BACK_TO_ALL_LIST: 'Back to list',
  BACK_TO_COMMUNITY_SEARCH: 'Back to Community Search',
  BACK_TO_EDITING: 'Back to editing',
  BACK_TO_FAMILY_FILE: 'Back to Family File',
  BACK_TO_HOME: 'Back to Home',
  BANG_QUOTA_MESSAGE: '!',
  BATHING: 'Bathing / Dressing / Toileting',
  BATHING_OR_SHOWERING: 'Bathing or Showering',
  BEDROOM_TYPE: 'Bedroom Type',
  BEDROOM_TYPES: 'Bedroom Types',
  BEEDRIDDEN_UNABLE_TO_BEAR_WEIGHT: 'Bedridden, Unable to Bear Weight',
  BLANK_SPACE: ' ',
  BREAK: 'Break',
  BUDGET: 'Budget',
  BUDGET_RANGE: 'Budget Range',
  BUDGET_RANGE_DESCRIPTOR_1: '$10,000 and over',
  BUDGET_RANGE_DESCRIPTOR_2: '$9,000-$9,999',
  BUDGET_RANGE_DESCRIPTOR_3: '$8,000-$8,999',
  BUDGET_RANGE_DESCRIPTOR_4: '$7,000-$7,999',
  BUDGET_RANGE_DESCRIPTOR_5: '$6,000-$6,999',
  BUDGET_RANGE_DESCRIPTOR_6: '$5,000-$5,999',
  BUDGET_RANGE_DESCRIPTOR_7: '$4,000-$4,999',
  BUDGET_RANGE_DESCRIPTOR_8: '$3,000-$3,999',
  BUDGET_RANGE_DESCRIPTOR_9: '$2,500-$2,999',
  BUDGET_RANGE_DESCRIPTOR_10: '$2,000-$2,499',
  BUDGET_RANGE_DESCRIPTOR_11: '$1,500-$1,999',
  BUDGET_RANGE_DESCRIPTOR_12: '$1,000-$1,499',
  BUDGET_RANGE_DESCRIPTOR_13: '$500-$999',
  BUDGET_RANGE_DESCRIPTOR_14: 'Under $500',
  CALL: 'Call',
  CALL_DISCONNECTED: 'Call Disconnected',
  CALL_DISCONNECTED_BY_CALLER:
    'The call has been disconnected by the other party. Please finish processing the inquiry.',
  CALL_DISCONNECTED_BY_AGENT:
    'The call has been disconnected. Please finish processing the inquiry.',
  CANCEL: 'Cancel',
  CANCELED: 'Canceled',
  CANCEL_MANUAL_INQUIRY: 'Cancel Manual Inquiry',
  CANCEL_MANUAL_INQUIRY_DESCRIPTION:
    'Are you sure you want to cancel this manual inquiry? Any information you have added will be lost.',
  CANCEL_SCHEDULE: 'Cancel Schedule',
  CANCEL_TOUR: 'Cancel Tour',
  CANCEL_SEARCH: 'Cancel Search',
  CANE: 'Cane',
  CANNOT_MARK_TOUR_COMPLETE_IN_THE_PAST:
    'Cannot mark a tour completed in the future',
  CANNOT_COMPLETE_SAME_DAY_TOUR:
    'Cannot complete a Same Day tour with a date in the future. Please select the Different Day option to complete the tour.',
  CANNOT_SCHEDULE_IN_THE_FUTURE: 'Cannot schedule in the future',
  CANNOT_SCHEDULE_IN_THE_PAST: 'Cannot schedule in the past',
  CANNOT_SCHEDULE_TIMES: 'Specifying a time is not required',
  CANT_SELECT_PAST_DATES: 'Cannot schedule a call back in the past',
  CARE_AND_PREFERENCES: 'Care & Preferences',
  CARE_RATES: 'Care Rates',
  CARE_TYPES: 'Care Types',
  CARE_TYPE_LABELS_WITH_CODE_MEMORY_CARE: 'Memory Care (Z)',
  CARE_TYPE_LABELS_WITH_CODE_ASSISTED_LIVING: 'Assisted Living (A)',
  CARE_TYPE_LABELS_WITH_CODE_NURSING_HOME: 'Nursing Home (N)',
  CARE_TYPE_LABELS_WITH_CODE_SENIOR_APARTMENTS: 'Senior Apartments (P)',
  CARE_TYPE_LABELS_WITH_CODE_ADULT_DAY_SERVICES: 'Adult Day Services (D)',
  CARE_TYPE_LABELS_WITH_CODE_HOME_CARE: 'Home Care (H)',
  CARE_TYPE_LABELS_WITH_CODE_RESIDENTIAL_CARE_HOME: 'Residential Care Home (G)',
  CARE_TYPE_LABELS_WITH_CODE_RETIREMENT_COMMUNITY: 'Retirement Community (R)',
  CELL_PHONE: 'Cell Phone',
  CELL_PHONE_CODE: 'CP',
  CHAPEL_CHURCH: 'Chapel / Church nearby',
  CHECK_AND_EVALUATE_LINK: 'Please check and evaluate here',
  CHOOSE_REASON_TO_CLOSE_INQUIRY: 'Choose Reason to Close Inquiry',
  CITY: 'City',
  CLEAR: 'Clear',
  CLEAR_ALL: 'Clear all',
  CLEAR_CHANGES: 'Clear Changes',
  CLEAR_FILTER: 'Clear Filters',
  CLEAR_LIST: 'Clear list',
  CLICK_TO_EDIT_OR_UPDATE_COMMUNITY_PREFERENCES_CRITERIA:
    'Click to edit/update community preferences',
  CLICK_TO_EDIT_OR_UPDATE_FINANCIAL_CRITERIA:
    'Click to edit/update financial criteria',
  CLICK_TO_EDIT_OR_UPDATE_KEYNOTE: 'Click to edit/update keynote',
  CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND:
    'Click to edit/update medical background',
  CLOSE: 'Close',
  CLOSED: 'Closed',
  CLOSED_COMMUNITY_TOOLTIP_TEXT:
    'The family decided against this community so no further activity is allowed. If something has changed, you can re-open this community via the YGL WorkingList.',
  CLOSED_DESCRIPTION: 'Manage your family files that are moved-in or closed.',
  CLOSING_PARAGRAPH: 'Closing paragraph',
  CLOSING_PARENTHESIS: ')',
  COLLAPSE_ALL_CATEGORIES: 'Collapse all categories',
  COMBATIVE: 'Combative',
  COMMA_QUOTA_MESSAGE: ', ',
  COMMONLY_USED: 'Commonly Used',
  COMMUNITIES: 'Communities',
  COMMUNITY: 'Community',
  COMMUNITY_AMENITIES: 'Community Amenities',
  COMMUNITY_DISCLOSURE_MESSAGE_1:
    'Communities in {state} require a disclosure. Please ',
  COMMUNITY_DISCLOSURE_MESSAGE_2: 'return to YGL',
  COMMUNITY_DISCLOSURE_MESSAGE_3: ' to complete the disclosure.',
  COMMUNITY_DISCLOSURE_STATE_MESSAGE:
    'Communities in {state} require a disclosure. Please complete disclosures in YGL.',
  COMMUNITY_DOES_NOT_USE_CT_APP: 'This community does not use APFM CT App',
  COMMUNITY_INTRODUCTION: 'Community introduction',
  COMMUNITY_NOTES: 'Community Notes',
  COMMUNITY_PREFERENCES: 'Community Preferences',
  COMMUNITY_PREFERENCES_SUCCESS_MESSAGE:
    'Success! Community Preferences saved.',
  COMMUNITY_PRIMARY_CONTACT: 'Community Primary Contact',
  COMPLETE: 'Complete',
  COMPLETED: 'Completed',
  COMPLETE_ASSESSMENT_NOW: 'Complete Assessment Now',
  COMPLETE_CALL: 'Complete Call',
  COMPLETE_TASK: 'Complete Task',
  CONDITION_SPECIFIC_CARE: 'Condition specific care',
  CONFERENCE_CALL: 'Conference Call',
  CONFIRM: 'Confirm',
  CONFIRM_DELETE_NOTE: 'Are you sure you want to delete this note?',
  CONFIRM_SCHEDULED_TOUR:
    'I confirm that the family would like to schedule a tour at this community',
  CONGRATULATIONS_QUOTA_MESSAGE: 'Congratulations',
  CONTACT: 'Contact',
  CONTACTS: 'Primary Contact',
  CONTACTS_SUCCESSFULLY_UPDATED:
    'You have successfully updated the family file’s contacts information',
  CONTACT_DUPLICATE_CHECK: 'Contact Duplicate Check',
  CONTACT_DUPLICATE_CHECKING_WAIT_MESSAGE:
    'Checking for Contact Duplicates. Please Wait...',
  CONTACT_EMAIL: 'Contact Email',
  CONTACT_FIRST_NAME: 'Contact First Name',
  CONTACT_HISTORY: 'Contact History',
  CONTACT_INFORMATION: 'Contact Information',
  CONTACT_LAST_NAME: 'Contact Last Name',
  CONTACT_PHONE: 'Contact Phone',
  CONTACT_RELATION: 'Contact Relation',
  CONTACT_RELATION_TO_RESIDENT: 'Contact Relation to Resident',
  CONTINUE: 'Continue',
  CONTINUE_TO_FAMILY_LETTER: 'Continue to Family Letter',
  CONTINUING_CARE_RETIREMENT: 'Continuing Care Retirement Communities',
  COPY: 'Copy',
  CORPORATE: 'Corporate',
  COTTAGE_VILLA: 'Cottage/Villa',
  COUNTRY: 'Country',
  COVID_VACCINATION: 'COVID Vaccination',
  COVID_VACCINE_CLINICS_OFFERED: 'Covid-19 Vaccine Clinics Offered',
  CREATED: 'Created',
  CREATE_NEW_INQUIRY: 'Create New Inquiry',
  CT_APP: 'CT App',
  CT_APP_ENABLED: 'CT APP ENABLED',
  CURRENT: 'Current',
  CURRENTLY_APPLYING: 'Currently Applying',
  CURRENTLY_IN_HOSPITAL: 'Currently in hospital',
  CURRENTLY_IN_REHAB: 'Currently in rehab',
  CURRENT_CALL: 'Current Call',
  CURRENT_LIVING_SITUATION: 'Current Living Situation',
  CURRENT_MEDICATIONS: 'Current Medications',
  CURRENT_RECIPIENT: 'Current recipient',
  CUSTOMER_EXPERIENCE: 'Customer Experience',
  DASHBOARD: 'Dashboard',
  DASH_MESSAGE: '-',
  DATA_WAS_NOT_LOADED: 'The data was not be loaded',
  DATE: 'Date',
  DATE_RANGE_LIMITATION_1_YR: 'Cannot set range greater than 1 year.',
  DATE_SENT: 'Date Sent',
  DEFAULT_EMAIL: 'Default Email',
  DELETE: 'Delete',
  DESCRIPTION: 'Description',
  DESELECT_ALL: 'Deselect all',
  DESELECT_ALL_COMMUNITIES: 'Deselect all communities',
  DETAILED_RATES: 'detailed rates',
  DETAILS: 'Details',
  DIALPAD: 'Dialpad',
  DIFFERENT_DAY: 'Different Day',
  DISABLED_BY_CALL_ACTIVE: 'Disabled while call is active',
  DISCLOSURE_READ: 'Disclosure Read',
  DISPOSITION_NOT_FOUND:
    'To complete this inquiry, please select a disposition.',
  DISPOSITION_NOT_SET: 'Inquiry Disposition Not Set',
  DISPOSITION_TITLE: 'Select disposition',
  DISTANCE: 'Distance',
  DISTANCE_MIN_VALUE: 'Minimum value: {MIN_VALUE} miles',
  DISTANCE_NEAREST: 'Distance (nearest)',
  DISTANCE_RANGE_WITHIN: 'Distance range within',
  DISTANCE_REQUESTED: 'Distance Requested',
  DOCTORS_ON_CALL: 'Doctors on call',
  DOES_YOUR_LOVED_ONE_EXPERIENCE_MEMORY_LOSS:
    'Does your loved one experience memory loss?',
  DONT_MAKE_ANY_CHANGES: 'Are you sure you don’t want to make any changes?',
  DO_NOT_CALL: 'Do not Call',
  DO_NOT_MERGE: 'Do Not Merge',
  DO_THEY_NEED_HELP_IN_ANY_OF_THESE: 'Do they need help in any of these?',
  DUE: 'DUE',
  DUE_DATE: 'Create date',
  EDIT: 'Edit',
  EDIT_CONTACT_INFORMATION: 'Edit Contact Information',
  EDIT_FAMILY_FILE: 'Edit Family File',
  EDIT_NOTE: 'Edit Note',
  EDIT_RESIDENT_INFORMATION: 'Edit Resident Information',
  EMAIL: 'Email',
  EMAIL_ADDRESS: 'Email address',
  EMAIL_ADDRESS_PROMPT: 'Please enter your email address',
  EMAIL_LABEL_INPUT: 'What is the best email to reach you?',
  EMAIL_NOT_AVAILABLE: 'Email Not Available',
  EMAIL_TEMPLATE: 'Email Template',
  EMAIL_TEMPLATES: 'Email Templates',
  EMPTY_COMMUNITY_TIP: 'You have not saved or referred any communities.',
  EMPTY_KEYNOTE_HEADER: "It's empty in here",
  ENTER_YOUR_NOTES_HERE: 'Enter your notes here',
  ENTER_WHEN_TOUR_COMPLETED: 'Please enter when the tour was completed',
  ERROR_IN_TOKEN_PROVIDED_BY_YGL: 'Token provided by YGL threw an error: ',
  ESTIMATED_DATE: 'Estimated Date',
  ESTIMATED_FINANCIAL_RANGE: 'Estimated Financial Range',
  EXISTING_CONTACT: 'Existing Contact',
  EXPAND_ALL_CATEGORIES: 'Expand all categories',
  EXPECTED_MOVE_IN: 'Expected Move-in',
  EXPECTED_MOVE_IN_DATE: 'Expected Move-in Date',
  EXPORT: 'Export',
  FAILED_TO_LOAD_EXTERNAL_ID: 'Could not load from YGL',
  FAMILY_FILE: 'Family File',
  FAMILY_FILES: 'Family Files',
  FAMILY_FILE_DUPLICATE_CHECK: 'Family File Duplicate Check',
  FAMILY_FILE_EMPTY_DESCRIPTION_LINE1:
    'Looks like you have no family files right now.',
  FAMILY_FILE_EMPTY_DESCRIPTION_LINE2:
    "We'll keep you posted if there are any.",
  FAMILY_FILE_EMPTY_HEADER: "You're all caught up!",
  FAMILY_FILE_HISTORY: 'Family File History',
  FAMILY_FILE_ID: 'Family File ID ',
  FAMILY_FILE_LETTER_NOTE:
    'The family letter will only include communities for which a referral has already been sent.',
  FAMILY_FILE_LETTER_TOAST_PART_1:
    'The family letter already has the list of properties, your signature, residential care home video and construction and Texas disclaimers. DO NOT DUPLICATE THIS INFORMATION.',
  FAMILY_FILE_LETTER_TOAST_PART_2:
    '[MySearch] is a placeholder for the MySearch link. When generating letter [MySearch] will be replaced by the link. DO NOT add [MySearch] to the Subject.',
  FAMILY_FILE_NOT_FOUND_DESCRIPTION_LINE1:
    "We don't have any family files to show.",
  FAMILY_FILE_NOT_FOUND_DESCRIPTION_LINE2:
    'Please try adjusting your search by changing your filter.',
  FAMILY_FILE_NOT_FOUND_HEADER: 'No Family Files found',
  FAMILY_FILE_SEARCH_COMMUNITY_BANNER: 'Looking for More Communities?',
  FAMILY_FILE_SEARCH_COMMUNITY_BANNER_BUTTON: 'Open Community Search Page',
  FAMILY_FINANCIAL_MEANS_AND_OTHER_RESOURCES:
    'Family financial means and other resources',
  /** @deprecated: Can be removed with the full launch of IC */
  FAMILY_FINANCIAL_RANGE: 'Family Financial Range',
  FAMILY_BUDGET: 'Family Budget',
  FAMILY_LETTER: 'Family Letter',
  FAMILY_LETTER_MANAGER: 'Family Letter Manager',
  FAMILY_LETTER_NOT_SENT: 'Family Letter not sent',
  FAMILY_LETTER_SENT: 'Family Letter Sent',
  FAMILY_LETTER_SUCCESSFULLY_SENT_TO_CONTACTS:
    'You have successfully sent a Family Letter to the following contacts: ',
  FAMILY_PREFERRED_TIME_ZONE: 'Family’s Preferred Timezone',
  FAR_FROM_GOAL: 'to reach the goal',
  FAX_NOT_AVAILABLE: 'Fax Not Available',
  FEES: 'Fees',
  FEES_AND_CHARGES: 'Fees and Charges',
  FF_CREATION: 'FF Creation',
  FF_STATUS: 'FF Status',
  FIELDS_SHOULD_NOT_BE_EMPTY: 'Financial Range can not be empty',
  FILTER_BY: 'Filter by',
  FINANCIAL_CRITERIA: 'Financial Criteria',
  FINANCIAL_CRITERIA_EMPTY_KEYNOTE_DESCRIPTION_PART1:
    'Add financial or budget information about the',
  FINANCIAL_CRITERIA_EMPTY_KEYNOTE_DESCRIPTION_PART2:
    'family to provide a minimum care assessment for',
  FINANCIAL_CRITERIA_EMPTY_KEYNOTE_DESCRIPTION_PART3: 'this senior',
  FINANCIAL_CRITERIA_SUCCESS_MESSAGE: 'Success! Financial Criteria saved.',
  FINANCIAL_RANGE: 'Financial Range',
  FINANCIAL_RANGE_REFERRAL_VALIDATION:
    'Financial Range (Can\'t be "Family Undecided")',
  FINANCIAL_RESOURCES: 'Financial Resources',
  FINANCIAL_RESOURCE_401K: '401K',
  FINANCIAL_RESOURCE_MONTHLY_INCOME: 'Monthly income',
  FINANCIAL_RESOURCE_COMPANION_ROOM_INTEREST: 'Companion room interest',
  FINANCIAL_RESOURCE_OTHER_ASSETS: 'Other assets',
  FINANCIAL_RESOURCE_FAMILY_WILLING_TO_CONTRIBUTE:
    'Family willing to contribute',
  FINANCIAL_RESOURCE_PRIVATE_INSURANCE:
    'Private insurance (BC, BS, Aetna, etc.)',
  FINANCIAL_RESOURCE_HOME_TO_SELL: 'Home to sell',
  FINANCIAL_RESOURCE_SAVINGS: 'Savings',
  FINANCIAL_RESOURCE_STOCKS_BONDS_OR_CDS: `Stocks/Bonds/or CD’s`,
  FINISHED_WITH_CALLS: 'Finished with Calls?',
  FIRST_NAME: 'First Name',
  FOR: 'for',
  FROM: 'from',
  FULL_KITCHEN: 'Full kitchen',
  GETTING_AROUND: 'Getting Around',
  GOAL_REACHED: 'goal reached!',
  GO_BACK: 'Go back',
  GO_BACK_TO_COMMUNITY_PAGE_TO_COMPLETE_INFO:
    'Go back to the Communities Tab to complete this information',
  GO_BACK_TO_YGL_TO_COMPLETE_INFO:
    'Go back to YGL to complete this information.',
  GO_OFFLINE_CALLS: 'Go offline to stop making and receiving calls',
  GO_TO_COMMUNITIES_PAGE: 'Go to Communities Page',
  GO_TO_FAMILY_FILE: 'Go back to the Family File to complete this information',
  GO_TO_WORKING_LIST: 'Open Working List in YGL',
  HAS_EMAIL: 'Has Email',
  HAS_POLICY: 'Has policy',
  HAVE_THEY_BEEN_DIAGNOSED_WITH_MEMORY_CONDITION:
    'Have they been diagnosed with memory condition?',
  HCAE: 'HCAE',
  HEALTH_CARE: 'Health Care',
  HEALTH_CARE_URGENT: 'Health Care Urgent',
  HEALTHCARE_SERVICES: 'Healthcare Services',
  HELP_IN_SHOWER: 'Help in Shower/Bath',
  HELP_IN_TOILETING: 'Help in Toileting',
  HELP_SENDING_FAMILY_LETTER: 'Help sending the ‘Family Letter’.',
  HELP_SENDING_FAMILY_LETTER_EMAIL: 'ebooksbymailrequest@aplaceformom.com',
  HERES_WHAT_WE_HAVE: "Here's what we have for you today",
  HIDE: 'Hide',
  HIDE_ALL: 'Hide all',
  HIDE_FAMILY_FILE: 'Hide Family File',
  HIDE_NOTES: 'Hide Notes',
  HISTORY: 'History',
  HOLD: 'Hold',
  HOME: 'Home',
  HOME_CARE: 'Home Care',
  HOME_CARE_RATES: 'Home Care Rates',
  HOME_PHONE: 'Home Phone',
  HOME_PHONE_CODE: 'HP',
  HOSPICE: 'Hospice / end of life services',
  HOSPITAL: 'Hospital',
  HOUSEKEEPING: 'Housekeeping',
  ID: 'ID',
  IF_THE_PROBLEM_PERSISTS: 'If the problem persists, please reach out to',
  INQUIRY_DATE_RANGE: 'Inquiry Date Range',
  INQUIRY_DETAILS: 'Inquiry Details',
  IMMEDIATELY: 'Immediately',
  IMPORT: 'Import',
  INACTIVE_USER: 'Inactive User',
  INBOUND_AUTODIAL_CALL: 'Auto-Answer Inbound/Autodial Calls',
  INBOUND_CALL: 'Inbound Call',
  INCOMPLETE_FAMILY_FILE: 'Incomplete Family File',
  INCLUDE_SELECTED_TOUR_TIME:
    'Include selected tour time in this referral update',
  INCLUDE_TOUR_INFORMATION:
    'I want to include this tour information in the referral alert',
  INDEPENDENT: 'Independent',
  INDEPENDENT_LIVING: 'Independent living',
  INPUT_DISTANCE_OPTIONAL: 'if mentioned',
  INQUIRER: 'Inquirer',
  INQUIRY_ID: 'Inquiry ID',
  INQUIRY_RESULT: 'Inquiry result(s)',
  INQUIRY_STATUS: 'Inquiry Status',
  THAT_MEET_YOUR_SEARCH_CRITERIA: 'that meet your search criteria.',
  INQUIRY_WAS_CREATED_SUCCESSFULLY:
    'Inquiry #{INQUIRY_ID} was successfully created.',
  INSURANCE_POLICY: 'Insurance Policy',
  INTERNAL_CALL: 'Auto Answer Internal Calls',
  INTRODUCTION: 'Introduction',
  IN_PERSON_TOUR: 'In-Person Tour',
  INVALID_PHONE_NUMBER: 'Invalid phone number',
  INVALID_POSTAL_CODE: 'Invalid postal code',
  INVALID_EMAIL: 'Invalid email',
  IS_THERE_ANYTHING_ELSE_YOU_WOULD_LIKE_TO_TELL_ME_ABOUT_YOUR_LOVED_ONE:
    'Is there anything else you would like to tell me about your loved one?',
  IS_YOUR_LOVED_ONE: 'Is your loved one',
  IS_YOUR_LOVED_ONE_LOOKING_FOR_A_REHAB_SKILLED_NURSING_FACILITY:
    'Is your loved one looking for a Rehab/Skilled Nursing Facility?',
  IS_YOUR_LOVED_ONE_TAKING_ANY_MEDICATIONS:
    'Is your loved one taking any medications?',
  ITS_EMPTY_HERE: 'It’s empty in here',
  KEY_NOTE: 'Key Note',
  KEYNOTE: 'Keynote',
  KEYPAD: 'Keypad',
  KITCHENETTE: 'Kitchenette',
  LAUNCH_FIVE9: 'Launch Five9',
  LAUNCH_LOGIN_FIVE9: 'Launch and login to Five9 to start calls',
  LAST_CONTACTED: 'Last contacted',
  LAST_NAME: 'Last Name',
  LAST_TOUR_COMPLETED_ON: 'Last tour completed on',
  LAST_UPDATED: 'Last Updated',
  LAST_UPDATES: 'Last Updates',
  LEAD_KEYNOTE_BY: 'Lead keynote by',
  LEAD_KEYNOTE_ERROR:
    'Unable to save keynote due to an error. Please try again.',
  LEAD_KEYNOTE_SUCCESS: 'Success! Key note saved.',
  LIKELIHOOD: 'Likelihood',
  LIKELIHOOD_MOVE: 'Likelihood to Move',
  LINKS: 'Links',
  LIST_VIEW: 'List View',
  LOADING: 'Loading...',
  LOADING_ERROR: 'An error has occurred while loading your cases!',
  LOCATION: 'Location',
  LOCATIONS: 'Locations',
  LOGIN: 'Log in',
  LOGOFF_FIVE9: 'Logoff Five9',
  LOGOUT: 'Logout',
  LOG_OUT: 'Log Out',
  LONG_TERM_CARE_INSURANCE_POLICY: 'Long-Term Care Insurance Policy',
  LOOKING_FOR_REHAB: 'Looking for rehab',
  LOVED_ONE_NAME_ONE: 'If mentioned, input ',
  LOVED_ONE_NAME_THREE: 'name',
  LOVED_ONE_NAME_TWO: 'loved one’s ',
  MAILINGS: 'Mailings',
  MAKE_SURE_YOU_FOLLOWUP:
    'Great, now make sure that you follow up once the tour is completed.',
  MANAGE_FAMILY_FILES: 'Manage Family Files',
  MANAGE_YOUR_NON_REFERRED_FAMILIES:
    'Manage the family files you need to contact today',
  MANDATORY_REPORTING: 'Mandatory Reporting',
  MANUAL: 'Manual',
  MANUAL_INQUIRY: 'Manual Inquiry',
  MAP_VIEW: 'Map View',
  MARK_TOUR_COMPLETED: 'Mark Tour as Completed',
  MARK_TOUR_COMPLETED_SUCCESS: 'You have successfuly marked the tour completed',
  MAX: 'Max',
  MEAL: 'Meal',
  MEALS_PROVIDED: 'Meals provided',
  MEDICAID: 'Medicaid',
  MEDICAL_BACKGROUND: 'Medical Background',
  MEDICAL_BACKGROUND_EMPTY_KEYNOTE_DESCRIPTION_PART1:
    'Add medical background about the senior to provide a',
  MEDICAL_BACKGROUND_EMPTY_KEYNOTE_DESCRIPTION_PART2: 'minimum care assessment',
  MEDICAL_BACKGROUND_SUCCESS_MESSAGE: 'Success! Medical Background saved.',
  MEDICARE: 'Medicare',
  MEDICATION_ASSISTANCE: 'Medication assistance',
  MEETING: 'Meeting',
  MEMORY_CARE_URGENT: 'Memory Care Urgent',
  MEMORY_DIAGNOSIS_ALZHEIMERS: "Alzheimer's",
  MEMORY_DIAGNOSIS_BUT_NONE: 'but no diagnosis',
  MEMORY_DIAGNOSIS_DEMENTIA: 'dementia',
  MEMORY_DIAGNOSIS_OTHER: 'other memory condition',
  MEMORY_DIAGNOSIS_SPECIAL: 'special memory care needed',
  MEMORY_LOSS: 'Memory Loss',
  MERGE: 'Merge',
  MESSAGE_TO_ALL_COMMUNITIES_SELECTED: 'Message to all communities selected',
  METRICS_ROLE_WARNING: "User's role not allowed for metrics",
  MILES: 'miles',
  MILES_PREFERRED_LOCATION: 'miles from preferred location',
  MIN: 'Min',
  MOBILE_NOT_AVAILABLE: 'Mobile Not Available',
  MODIFY_TOUR_DATE: 'Would you like to modify the tour date?',
  MONTH: 'Month',
  MONTHLY: 'monthly',
  MONTHS: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  MORE_ACTIONS: 'More actions',
  MORE_FILTERS: 'More Filters',
  MORE_HELP: 'More Help?',
  MORE_LOCATIONS: 'more locations',
  MORE_OPEN_TASKS: 'More open tasks',
  MORNING_TIMES: 'Morning (8:00 AM - 11:30AM)',
  MOVE_INS: 'Move-Ins',
  MOVE_INS_DESCRIPTION:
    'Manage your family files that are in the process of moving in.',
  MOVE_IN_QUESTION: 'When do you think the resident would like to move?',
  MTD: 'MTD',
  MUTE: 'Mute',
  MY_CALENDAR: 'My Calendar',
  MY_WORK_LIST: 'My Worklist',
  NAMES_LABEL_INPUT: 'How do you spell your name?',
  NAME_UNKNOWN: 'Name unknown',
  NEW_CONTACT: 'New Contact',
  NEW_AND_PRE_REFERRED: 'New & Pre-Referred',
  NEW_AND_PRE_REFERRED_DESCRIPTION:
    'Keep track of the families you need to contact today.',
  NEW_NOTE_SAVED_SUCCESSFULLY: 'New note saved successfully',
  NEW_REFERRAL: 'New Referral',
  NO: 'No',
  NO_ADDRESS_IN_REFERRAL_ALERT: 'Do not include address in Referal Alert',
  NO_DUPLICATES_FOUND: 'No Duplicates Found',
  NO_EMAIL_USE_ADDRESS: 'No Email, Use Address',
  NO_LONGER_AN_SLA: 'No longer an SLA',
  NO_I_AM_RECORDING:
    'No, I am recording tour info from the community or family',
  NO_POLICY: 'No policy',
  NO_INQUIRIES_FOUND: 'No Inquiries Found',
  NONE: 'None',
  NOTES: 'Notes',
  NOTES_FROM_SCREENING: 'Notes from Screening',
  NOTES_SAVED: 'Notes Saved!',
  NOT_A_CANDIDATE: 'Not a candidate',
  NOT_AUTHORIZED_MESSAGE_1: 'We appreciate your curiosity!',
  NOT_AUTHORIZED_MESSAGE_2:
    'But access to this page is for other roles at APFM.',
  NOT_AUTHORIZED_MESSAGE_3_1: 'If you have any questions, ',
  NOT_AUTHORIZED_MESSAGE_3_3: ' to our product support team.',
  NOT_AUTHORIZED_MESSAGE_3_LINK: 'send an email',
  NOT_AUTHORIZED_TITLE: 'Error 401: Unauthorized Role',
  NOT_AVAILABLE: 'not available',
  NOT_CONTACTED_YET: 'Not contacted yet',
  NOT_IN_YGL: 'not in YGL',
  NOT_SELF_FINANCIALLY_UNQUALIFIED_NO_RUSH: 'FUNR',
  NOT_SENT: 'Not sent',
  NOT_SURE: 'Not sure',
  NO_CONTACT: 'No contact',
  NO_FAMILY_FILE_FOUND: 'No Family File Found',
  NO_FAMILY_FILE_TO_SHOW: "We don't have any family files to show",
  NO_FILTER_SELECTED: ' No Filter Selected',
  NO_KEYNOTE_YET: 'No Keynote yet.',
  NO_MATCHES: 'No Matches',
  NO_MORE_ACTIONS: 'Unable to do more actions when there’s no tour scheduled',
  NO_NOTES_YET: 'No notes yet',
  NO_PREFERRED_LOCATION: 'No Preferred Location',
  NO_RATINGS: 'No Ratings',
  NO_REFERRED_COMMUNITIES: 'No Referred Communities',
  NO_REFERRED_COMMUNITIES_YET:
    'You have not referred any communities to the family yet.',
  NO_RESULTS_FOUND: 'No results found',
  NO_RUSH: 'No Rush',
  NO_SAVED_COMMUNITIES: 'No Saved Communities',
  NO_TIME_PREF: 'No Time Preference',
  NURSING_CARE_24_HOURS: '24 hours nursing care',
  NURSING_HOME: 'Nursing Home',
  NURSING_HOMES: 'Nursing Homes',
  OF: 'of',
  OFFLINE_VENDOR_UNPAID: 'Offline Vendor Unpaid',
  OK: 'Ok',
  OLDER_REFERRALS: 'Referrals > 26 Days',
  OLDER_REFERRALS_DESCRIPTION:
    'Manage your priority family files that were first referred more than 26 days ago.',
  ONEID: 'OneID',
  ONE_BEDROOM: '1 Bedroom',
  ONE_ID: 'One ID',
  OPEN: 'Open',
  OPENNING_PARENTHESIS: '(',
  OPEN_FAMILY_FILE: 'Open Family File',
  OPEN_FAMILY_FILE_PANEL: 'Open the Family File to add this information',
  OPEN_LEAD_SUMMARY_IN_YGL: 'Open Lead in YGL',
  OPEN_TASK: 'Open tasks',
  OPEN_TASKS_IN_YGL: 'Open Tasks in YGL',
  OPEN_WORKING_LIST_IN_YGL: 'Open Working List in YGL',
  OPTIONAL: 'Optional',
  OPTIONS: 'Options',
  OR_LATER: '(or Later)',
  OTHER: 'Other',
  OTHERS: 'Others',
  OTHER_ISSUES: 'Other Issues',
  OTHER_MEMORY_ISSUES: 'Other memory issues',
  OTHER_SPECIAL_FEATURES: 'Other special features',
  OUTBOUND_CALL: 'Auto-Answer Outbound Calls',
  OVERVIEW: 'Overview',
  OVERVIEW_EMPTY_KEYNOTE_DESCRIPTION:
    "Add some description about the resident and family background so you would know what's happening",
  OVERVIEW_EMPTY_KEYNOTE_HEADER: "It's empty in here",
  OVERVIEW_ERROR_MESSAGE:
    'Unable to save due to error. Please try again later.',
  OVERVIEW_SUCCESS_MESSAGE: 'Success! Key note saved.',
  OVERWRITE: 'Overwrite',
  PENDING: 'Pending',
  PENDING_CANCELATION: 'Pending cancelation of',
  PENDING_RESCHEDULE: 'Pending reschedule from',
  PENDING_SCHEDULE: 'Pending scheduling for',
  PERCENTAGE: '%',
  PET_FRIENDLY: 'Pet-friendly',
  PHONE_NUMBER: 'Phone Number',
  PHONE: 'Phone',
  PHONE_NOT_AVAILABLE: 'Phone Not Available',
  PHONE_NUMBER_LABEL_INPUT:
    'Is this the best number to reach you in case we get disconnected?',
  PIPE: '|',
  PLEASE_ADJUST_SCREEN_SIZE:
    'Please try viewing this page on a larger screen resolution',
  PLEASE_ADJUST_YOUR_SEARCH:
    'Please try adjusting your search by changing your filter',
  PLEASE_COMPLETE_INITIAL_CONSULTATION:
    'Please complete the initial consultation to refer communities for this senior.',
  PLEASE_COMPLETE_INITIAL_CONSULTATION_EXAMPLE:
    '(Ex: Preferred Location, Budget and Care Types)',
  PLEASE_CONFIRM: 'Please confirm',
  PLEASE_INFORM_FAMILY_ABOUT_RESTRICTIONS:
    'Please inform the family about the following restrictions:',
  PLEASE_INFORM_FAMILY_THAT_COVID_CLINIC_IS_AVAILABLE:
    'Please inform the family that a Covid-19 clinic is available at',
  PLEASE_MAKE_SURE_YOU_HAVE_SENT_THE_REFERRAL_FIRST:
    'Please make sure you have sent the referral alert first, and then you should be good to go!',
  PLEASE_NOTE: 'Please note',
  PLEASE_SELECT: 'Please select',
  PLEASE_SELECT_A_DATE: 'Please select a date.',
  PLEASE_TRY_REFRESHING: 'Please try refreshing.',
  PLEASE_TRY_SEARCHING_AGAIN: 'Please try searching again',
  PLUS: '+',
  PLUS_MORE_LETTERS: ' + {QUANTITY} more',
  PODIATRIST: 'Podiatrist',
  POINT_OF_CONTACT_NOT_AVAILABLE: 'Point-of-Contact Not Availble',
  POINTS_MTD: 'Points MTD',
  POINTS_THIS_MONTH: 'My Points',
  POSSIBLE_CONTACTS_DUPLICATES_FOUND:
    'There are ({QUANTITY}) potential duplicates found. Please review the information and merge duplicate contacts to continue.',
  POSSIBLE_RESIDENT_DUPLICATES_FOUND:
    'There are ({QUANTITY}) potential duplicate family files with the same contact information:',
  POSTAL_CODE: 'Postal Code',
  POTENTIAL_CANDIDATE: 'Potential candidate',
  POTENTIAL_DUPLICATE_FAMILY_FILE:
    ') potential duplicate family files found. Please check and evaluate the information below:',
  POTENTIAL_FOUND_DUPLICATE: ') potential duplicate family files found.',
  PREFERRED_LOCATION: 'Preferred Location',
  PREFERRED_LOCATIONS: 'Preferred Location(s)',
  PREFERRED_TOUR_TIME: 'Preferred Tour Time',
  PREVIEW: 'Preview',
  PRESS_OR_TYPE_NUMBER_TO_DIAL: 'Press or type number to dial...',
  PRICE_10K_AND_OVER_1: 'Select if Price is',
  PRICE_10K_AND_OVER_2: '$10,000 or more',
  PRICE_WITHIN_RANGE: 'Price within range',
  PRIMARY_ADDRESS: 'Primary Contact Address',
  PRIMARY_ADDRESS_TITLE: 'Address for Primary Contact ',
  PRIMARY_CONTACT: 'Primary Contact',
  PRIMARY_CONTACT_DETAILS: 'Primary Contact Details',
  PRIMARY_CONTACT_EMAIL_PART1:
    '- If the primary contact doesn’t have an email, please reach out to',
  PRIMARY_CONTACT_EMAIL: 'ebooksbymailrequest@aplaceformom.com',
  PRIMARY_CONTACT_EMAIL_PART2: 'for help sending the ‘Family Letter’.',
  PRIMARY_CONTACT_NAME_COMMUNITY_SEARCH_TITLE:
    '{primaryContactName}: Community Search',
  PRIMARY_CONTACT_NAME_FAMILY_LETTER_TITLE:
    '{primaryContactName}: Family Letter',
  PRIMARY_PHONE: 'Primary Phone',
  PRIVATE: 'Private',
  PROFESSIONAL_REFERRAL: 'Professional Referral',
  PROFESSIONAL_SOURCE: 'Professional Source',
  QUALITY: 'Quality',
  QUESTION_WITH_NO_ANSWER: '-- Question with no answer --',
  RATES_UNAVAILABLE: 'rates unavailable',
  REACHED_MAX_CHARS: 'Reached maximum characters',
  REACHED_QUOTA: 'You have reached your quota',
  READY: 'Ready',
  READY_TO_START_YOUR_DAY: 'Ready to Start Your Day?',
  READ_MORE: 'Read more',
  REASON_FOR_CLOSE: 'Reason For Close',
  RECENTLY_REFERRED: 'Recently Referred',
  RECENTLY_REFERRED_DESCRIPTION:
    'Manage your priority family files that were first referred in the last 26 days.',
  REDIAL_NOT_ALLOWED_TITLE: 'Redial not allowed',
  REDIAL_NOT_ALLOWED_DESCRIPTION:
    'Redial is not allowed because there is a Warm transfer process ongoing',
  REFERRAL: 'Referral',
  REFERRED: 'Referred',
  REFERRAL_ALERT: 'Referral Alert',
  REFERRAL_ALERT_MANAGER: 'Referral Alert Manager',
  REFERRAL_ALERT_SENT: 'Referral Alert Sent',
  REFERRAL_PREFERENCES: 'Referral Preferences',
  REFERRAL_REJECTED: 'Referral Rejected',
  REFERRAL_SENT: 'Referral Sent',
  REFERRAL_SOURCE: 'Referral Source',
  REFERRAL_SUB_SOURCE: 'Referral Sub Source',
  REFERRAL_TOAST_MESSAGE:
    'Create a narrative about the resident such as hobbies, personal story, hot buttons, medical and family situation of Resident: ',
  REFERRAL_UPDATE: 'Referral Update',
  REFERRED_COMMUNITIES: 'Referred Communities',
  REFERRED_FAMILY_FILES: 'Referred Family Files',
  REHAB: 'Rehab',
  REHAB_REFERRAL_VALIDATION: 'Rehab/Skilled Nursing Facility',
  REHAB_SKILLED_NURSING_REQUIRED: 'Rehab/Skilled Nursing Required',
  RELATION_TO_RESIDENT: 'Relation to resident',
  RELATIONSHIP: 'Relationship',
  RELATIONSHIP_QUESTION_DROPBOX: 'What is your relationship to your loved one?',
  RELATIONSHIP_TO_RESIDENT: 'Relationship to resident',
  REMAINING_QUOTA_DAYS: 'to go to reach your goal',
  REMOVE_FROM_SAVED_COMMUNITIES: 'Remove from Saved Communities',
  REMOVE_PREFERRED_LOCATION_CONFIRMATION:
    'You have removed | from Preferred Locations. Click |here| to undo.',
  REMOVE_SELECTED: 'Remove selected',
  REMOVE_TOUR_TIME: 'Remove tour time',
  REQUEST_A_TOUR_WITH_COMMUNITY: 'Request a Tour with the Community',
  REQUIRED: 'Required',
  RESCHEDULE: 'Reschedule',
  RESCHEDULED: 'Rescheduled',
  RESCHEDULE_CALL_SCRIPT:
    'Thank you. We will call you back at the scheduled time. Have a great day.',
  RESCHEDULE_TOUR: 'Reschedule Tour',
  RESIDENT: 'Resident',
  RESIDENTIAL_CARE_HOME: 'Residential Care Home',
  RESIDENTS: 'Resident(s)',
  RESIDENT_DEDUP_MESSAGE:
    "I see we've spoken with you before. Are you still searching for options for the same person?",
  RESIDENT_DUPLICATE_CHECK: 'Resident Duplicate Check',
  RESIDENT_FIRST_NAME: 'Resident First Name',
  RESIDENT_INFORMATION: 'Resident Information',
  RESIDENT_LAST_NAME: 'Resident Last Name',
  RESIDENT_LIVING_SITUATION: 'Resident Living Situation',
  RESIDENT_NAME: 'Resident Name',
  RESOURCE_LINE: 'Resource Line',
  RESPITE_CARE: 'Respite care',
  RESULT: 'Result',
  RESULTS: 'result(s)',
  RESULTS_FILTERED_BY: 'Results filtered by',
  RESULTS_PER_PAGE: 'Results per page',
  RESULTS_SHOWING_BY: 'Results showing by',
  RETIREMENT_COMMUNITY: 'Retirement Community',
  REVIEW: 'review',
  ROOM_AMENITIES: 'Room Amenities',
  ROOM_CHARGES: 'Room Charges',
  ROOM_PREFERENCE: 'Room Preference',
  ROOM_TYPE: 'Room Type',
  ROOM_TYPES: 'Room Types',
  SALES_PHASE: 'Sales Phase',
  SALUTATION: 'Salutation',
  SAME_DAY: 'Same Day',
  SAME_RESIDENT: 'Same Resident',
  SAVE_AND_CLOSE: 'Save & Close',
  SAVE_AND_REFER: 'Save & Send Referral Alert',
  SAVE: 'Save',
  SAVED: 'Saved',
  SAVED_COMMUNITIES: 'Saved Communities',
  SAVED_COMMUNITIES_FOR_REFERRAL: 'Saved Communities for Referral',
  SAVED_TOUR_AT: 'You have saved a tour at',
  SAVE_COMMUNITY_BUTTON: 'Save community button',
  SAVE_COMMUNITY_FOR_REFERRAL: 'Save Community for Referral',
  SAVE_SETTINGS: 'Save Settings',
  SAVE_UPDATES: 'Save Updates',
  SELECT_AND_FILL_RELEVENT_FIELDS:
    'Select and fill relevent fields to create a new inquiry. Name and phone number required.',
  SELECT_FROM_LIST: 'Select from list',
  SELECT_PREFERRED_CARE_TYPE:
    'Select what type of care is required or best recommended for the resident',
  SELECT_PREFERRED_RECOMMENDED_ROOM_TYPE:
    'Select what type of room is preferred or best recommended for the resident',
  SCHEDULED: 'Scheduled',
  SCHEDULED_COMPLETED_ON: 'Scheduled/Completed On',
  COMMUNITY_REJECTED_REFERALL:
    'The community has rejected this referral so no further activity is allowed. If something has changed, you can deselect the referral rejected checkbox for this communityvia the YGL WorkingList',
  SCHEDULED_TOUR_HAS_BEEN_CANCELED:
    'The {tourType} for {datetime} has been canceled.',
  SCHEDULED_TOUR_HAS_BEEN_RESCHEDULED_V1:
    'The tour has been rescheduled from {prevDate} to {newDate}.',
  SCHEDULED_TOUR_HAS_BEEN_RESCHEDULED:
    'The tour has been rescheduled from {prevDate} to {newDate}.',
  SCHEDULED_TOUR_INFORMATION_MESSAGE_PART_1:
    'Discuss the family’s preferred type of tour and availability before you request a tour. If this is an ',
  SCHEDULED_TOUR_INFORMATION_MESSAGE_PART_2: 'urgent tour or a tour for today',
  SCHEDULED_TOUR_INFORMATION_MESSAGE_PART_3:
    ', please call the community first.',
  SCHEDULED_TOUR_WITH_SAME_TYPE:
    'Is the tour the same type that was originally scheduled?',
  SCHEDULED_TOUR_WITHOUT_RA:
    'If the community communicated tour information to you, do not send this tour information in the referral update. If you scheduled the tour or if the family told you about the tour, please continue to send tour info in your referral alert updates.',
  SCHEDULE_CALLBACK: 'Schedule Callback',
  SCHEDULE_TOUR: 'Schedule Tour',
  SCHEDULED_TOUR_TYPE: 'Scheduled Tour Type',
  SCHEDULING_TOUR_IN_PAST:
    'It looks like you are scheduling a tour in the past. You can either send this referral update without including past tour info, or you can schedule a tour for the future in this referral.',
  SCREEN_NOT_SUPPORTED: 'Current screen resolution is not supported',
  SCRIPT_NO_PARTNER_COMMUNITIES:
    'I’m sorry, we don’t have any partner communities in ',
  SCRIPT_WOULD_LIKE_ANOTHER_LOCATION:
    'Would you like to search in another location?',
  SEARCH: 'Search',
  SEARCH_INQUIRES: 'Search Inquires',
  SEARCH_AS_I_MOVE_THE_MAP: 'Search as I move the map',
  SEARCH_COMMUNITIES: 'Search Communities',
  SEARCH_FOR_COMMUNITIES: 'Search for Communities',
  SEARCH_FOR_COMMUNITIES_NOW: 'Search for communities now',
  SEARCH_MORE_COMMUNITIES: 'Search More Communities',
  SEARCH_RESULTS: 'Search Results',
  SECONDARY_CONTACT: 'Secondary Contact',
  SECONDARY_CONTACT_DETAILS: 'Secondary Contact Details',
  SECONDARY_EMAIL: 'Second Email',
  SECOND_ROUND_REFERRALS: 'Referred Family Files with 2nd round referrals',
  SECURITY_AND_EMERGENCY_CALLS: 'Security and emergency calls',
  SEE_ALL_TASKS: 'See all tasks',
  SELECTED: 'Selected',
  /** @deprecated Can be removed with full launch of the IC workflow */
  SELECTED_ESTIMATED_FINANCIAL_RANGE: 'Selected estimated financial range',
  SELECTED_ESTIMATED_BUDGET_RANGE: 'Selected estimated budget range',
  SELECTED_TOUR_TIME: 'Selected Tour Time',
  SELECT_ACTION: 'Select Action',
  SELECT_ALL: 'Select all',
  SELECT_ALL_COMMUNITIES: 'Select all communities',
  SELECT_ALL_LIST: 'Select all list',
  SELECT_CONTACT_PREFERENCE: 'Select contact preference',
  SELECT_DATE: 'Select Date',
  SELECT_DOCUMENT_TO_LINK_FAMILY_LETTER:
    'Select each document link you want to include in this family letter.',
  SELECT_EMAIL_TEMPLATE: 'Select Email Template',
  SELECT_RELATIONSHIP: 'Select relationship',
  SELECT_TIME_ZONE: 'Select Time Zone',
  SELECT_TYPE_OF_CARE:
    'Select what type of care required or best recommended for the resident',
  SELF: 'Self',
  SELF_CARE: 'Self',
  SELF_NOT_URGENT_FINANCIALLY_QUALIFIED: ' Self Digital SLA Path',
  SELF_NOT_URGENT_NOT_VETERAN_FINANCIALLY_UNQUALIFIED:
    'Self Close Lead and Send Resource Email',
  SELF_URGENT_FINANCIALLY_QUALIFIED: 'Self Urgent Financially Qualified',
  SEND_EMAIL: 'Send an email',
  SEND_FAMILY_LETTER: 'Send Family Letter',
  SEND_NOW: 'Send Now',
  SEND_REFERRAL_ALERT: 'Send Referral Alert',
  SEND_REFERRAL_IN_YGL: 'Send Referral in YGL',
  SEND_REFERRAL_UPDATE: 'Send a Referral Update',
  SEND_REFERRAL_UPDATES: 'Send Referral Updates',
  SEND_SINGLE_REFERRAL_UPDATE: 'Send Referral Update',
  SEND_TEXT: 'Send text message',
  SEND_TO: 'Select contacts to send to',
  SENIOR_ADVISOR_REVIEW: 'Senior Advisor Review',
  SENIOR_APARTMENTS: 'Senior Apartments',
  SEO_BEACON_SCREENING_VARIANT: 'SEO Beacon Screening Variant',
  SEO_OPP_GEN_SCREENING_VARIANT: 'SEO OppGen Screening Variant',
  SERVICES_OFFERED: 'Services Offered (special features)',
  SET_STATUS_AS: 'Set status as',
  SELECT_LOGOUT_REASON: 'Select a Log Out Reason',
  SHARED: 'Shared (Semi-Private)',
  SHOWING: 'Showing',
  SHOW_ALL: 'Show all',
  SHOW_BY_MEDICAL_URGENCY: 'Show by medical urgency',
  SHOW_LESS: 'Show less',
  SHOW_LIST: 'Show list',
  SHOW_MORE: 'Show more',
  SHOW_MORE_DETAILS: 'Show more details',
  SHOW_NOTES: 'Show Notes',
  SLA: 'SLA',
  SLA_NOT_FOUND: 'User not found or it is not an SLA',
  SMART_PRIORITY: 'Smart priority',
  SMOKING: 'Smoking',
  SOCIAL_ACTIVITIES: 'Social activities',
  SOURCE: 'Source',
  SPANISH_LINE: 'Spanish line',
  SPECIFIC_DISPOSITION_NOT_FOUND:
    'Disposition: {DISPOSITION} was not found, please choose another one',
  START_CALL: 'Start Call',
  STATE_PROV: 'State/Prov',
  STATUS: 'Status',
  STATUS_DETAILS: 'Status Details',
  STATUS_WILL_UPDATE_AFTER_CALL_ENDS: 'Status will update after the call ends',
  STUDIO: 'Studio',
  SUBJECT: 'Subject',
  SUBMIT: 'Submit',
  SUCCESS: 'Success!',
  SUCCESSFULLY_SAVED_FAMILY_LETTER_NOTES:
    'You have successfully saved the notes you wrote for this family letter.',
  SUCCESSFULLY_SAVED_NOTES:
    'You have successfully saved the notes you wrote for the community.',
  SUCCESSFULLY_SENT_REFERRAL:
    'You have successfully sent a Referral Alert to the Community.',
  SUCCESSFULLY_SENT_REFERRAL_LIST:
    'You have successfully sent a Referral Alert to the following communities:',
  SUCCESSFULLY_UPDATED:
    'You have successfully updated the family file’s resident information',
  SUGGESTED: 'Suggested',
  SUGGESTED_PLACES_TITLE: 'Suggestions',
  SURPASSING_QUOTA_MESSAGE:
    'You have reached your quota this month! Keep up the good work!',
  SWIMMING_POOL: 'Swimming Pool',
  TAGS: 'Tags',
  TARGET: 'Target',
  TASK_MARKED_COMPLETE: 'task marked complete.',
  TECH_ISSUE: 'Tech Issue',
  TECH_SUPPORT: 'tech support.',
  TEMPORARY_IMMOBILE_DUE_TO_INJURY: 'Temporary Immobile Due to Injury',
  TEST_SCREENING_PROCESS: 'Test Screening Process',
  THE: 'The',
  THERE: 'There are (',
  THERE_ARE: 'There are',
  THERE_IS: 'There is',
  THIS_INQUIRY_IS_MARKED_AS: 'This inquiry is marked as ',
  THIS_IS_YOUR_BEACON: 'This is your Beacon',
  THOUGH_NOT_MANDATORY:
    'Though not mandatory, it is best practice to refer the family to more than one Community Customer or Care Provider.',
  TO: 'to',
  TO_DO_LIST: 'To-Do List',
  TOAST_NO_PARTNER_COMMUNITIES: 'No partner communities found.',
  TOILETING: 'Toileting',
  TOURING: 'Touring',
  TOURING_DESCRIPTION:
    'Manage your family files where recent tour activity has occurred.',
  TOURS_SCHEDULED_DURING_INITIAL_CONSULT:
    'Referred Family Files with at least 1 tour scheduled during IC',
  TOUR_ACTIVITY: 'Tour Activity',
  TOUR_ACTIVITY_MESSAGE_COMPLETED:
    'Now that the tour has been completed, make sure you follow up with the community and/or the family to know how it went. This will help you determine the best next steps for the family.',
  TOUR_ACTIVITY_MESSAGE:
    'If you scheduled the tour or if the family told you about the tour please continue to send tour info in your referral alert updates. Discuss with the family about their preferred type of tour and availability options to request a tour with the community. But if this is urgent or requesting for same day tour, please call the community first.',
  TOUR_DETAILS: 'Tour Details',
  TOUR_HISTORY_WILL_SHOW_HERE: 'Tour history will show up here.',
  TOUR_QUESTION_SCHEDULING: 'Are you updating the community about this tour?',
  TOUR_QUESTION_SCHEDULING_TOOLTIP_NO:
    'If you are recording a tour the community told you about, do not send that in the Referral Alert.',
  TOUR_QUESTION_STATUS: 'Is it a scheduled or completed tour?',
  TOUR_QUESTION_STATUS_TOOLTIP_COMPLETED:
    'You cannot mark a tour complete before referring the community.',
  TOUR_QUESTION_UPDATE_TYPE: 'What update do you want to make to this tour?',
  TOUR_QUESTION_UPDATE: 'Are you updating the community about this tour?',
  TOUR_SAVED_TITLE: 'Tour Saved!',
  TOUR_SCHEDULED: 'Tour is scheduled on {date} at {time}. Type: {tourType}',
  TOUR_SCHEDULED_ON: 'Tour is scheduled on',
  TOUR_STATUS_CANCELED: 'Tour Canceled',
  TOUR_STATUS_COMPLETED: 'Tour Completed',
  TOUR_STATUS_RESCHEDULED: 'Tour Rescheduled',
  TOUR_STATUS_SCHEDULED: 'Tour Scheduled',
  TOUR_SUMMARY_THERE_IS: 'There is',
  TOUR_SUMMARY_SCHEDULED_ON: 'scheduled on',
  TOUR_TYPE: 'Tour Type',
  TRAINING: 'Training',
  TRANSFER: 'Transfer',
  TRANSFER_OPTIONS: 'Transfer Options',
  TRANSFER_TO_SLA: 'Transfer to SLA',
  TRANSPORTATION: 'Transportation',
  TRYING_TO_LOAD_EXTERNAL_ID: 'Trying to load from YGL',
  TRYING_TO_SEND_FAMILY_LETTER_WITH_NO_REFERRED_COMMUNITITES:
    'Sorry, looks like you were trying to send a family letter to communities that have not been referred yet.',
  TWO_BEDROOM: '2 Bedroom',
  TYPE: 'Type',
  TYPE_OF_CARE: 'Type of Care',
  TYPE_OF_CARE_PROVIDED: 'Type of Care Provided',
  TYPE_YOUR_KEYNOTES_HERE: 'Type your keynotes here',
  UH_OH_THERE_WAS_A_PROBLEM: 'Uh oh! There was a Problem',
  UNABLE_TO_MARK_TOUR_COMPLETE_WITHOUT_TOUR:
    'Unable to mark tour as completed when there’s no tour scheduled',
  UNABLE_TO_REMOVE_DUE_TO_ERROR:
    'Unable to remove due to error. Please try again later.',
  UNABLE_TO_SAVE_DUE_TO_ERROR:
    'Unable to save due to error. Please try again later.',
  UNABLE_TO_SAVE_COMMUNITY_NOTE_DUE_TO_ERROR:
    'Unable to save community note(s) due to error. Please try again later.',
  UNABLE_TO_SAVE_NOTE_DUE_TO_ERROR:
    'Unable to save note due to an error. Please try again.',
  UNABLE_TO_SEND_DUE_TO_ERROR:
    'Unable to send due to error. Please try again later.',
  UNDECIDED: 'Undecided',
  UNDECIDED_BUDGET: 'Undecided Budget',
  UNKNOWN: 'Unknown',
  UNKNOWN_ERROR_MESSAGE_1:
    'Sorry we are having technical issues (as you can see) -',
  UNKNOWN_ERROR_MESSAGE_2: 'Try refreshing the page, sometimes it works!',
  UNKNOWN_ERROR_MESSAGE_3: 'Meanwhile,',
  UNKNOWN_ERROR_MESSAGE_4: 'click here',
  UNKNOWN_ERROR_MESSAGE_5: 'to email our product support team',
  UNKNOWN_ERROR_MESSAGE_6: 'if you keep seeing this error message.',
  UNKNOWN_PRIMARY_CONTACT: 'Unknown primary contact',
  UNKNOWN_RELATION: 'Unknown Relation',
  UPDATE_TOUR_INFO: 'Update Tour Info',
  UPDATED_REFERRAL_RECOMMENDED:
    'Sending an updated referral alert to this community is recommended.',
  UPDATE_STATUS_TO_CREATE_MANUAL_INQUIRY:
    'Update Status to Create Manual Inquiry',
  UPDATE_STATUS_FOR_MANUAL_INQUIRY:
    'Please update your status to "Not Ready" before creating a manual inquiry',
  UPDATE_STATUS_TO_OPEN_INQUIRY: 'Update Status to Open Inquiry Search',
  UPDATE_STATUS_FOR_INQUIRY_SEARCH:
    'Please update your status to "Not Ready" before searching for inquiries',
  URGENCY_TYPES: 'Urgency types',
  URGENT_NEED: 'Urgent Need',
  US_RESIDENTS_ONLY: 'US Residents only',
  VA_AID_AND_ATTENDANCE_BENEFITS: 'VA Aid and Attendance Benefits',
  VA_AID_ATTENDANCE_BENEFITS: 'VA Aid/Attendance Benefits',
  VETERANS_ADMINISTRATION: 'Veterans Administration',
  VETERANS_ADMINISTRATION_DESCRIPTION:
    "has an 'Aid & Attendance' benefit for veterans and their spouses (including widows/widowers) who financially qualify. This benefit contributes a portion of the cost of care services. I am sending you information on the VA pension.",
  VETERAN_DESCRIPTION_PART1:
    "\"has an 'Aid & Attendance' benefit for veterans and their spouses",
  VETERAN_DESCRIPTION_PART2:
    ' (including widows/widowers) who financially qualify. This benefit contributes a portion of the cost of',
  VETERAN_DESCRIPTION_PART3:
    ' care services. I am sending you information on the VA pension"',
  VETERAN_OR_SPOUSE_VETERAN: 'Veteran or Spouse is a Veteran',
  VETERAN_STATUS: 'Veteran Status',
  VIEW_ACTIVITY_HISTORY: 'View activity history',
  VIEW_IN_YGL: 'View in YGL',
  VIEW_LESS: 'View less',
  VIEW_MORE: 'View more',
  VIEW_PROFILE: 'View Profile',
  VIEW_TASKES_IN_YGL: 'view task in YGL',
  VIRTUAL_TOURS_OTHER: 'Virtual Tours - Other',
  WAITING_FOR_CALL_TO_BE_CONNECTED: 'Waiting for Call to be Connected ...',
  WALKER: 'Walker',
  WANDERING: 'Wandering',
  WASHER_AND_DRYER: 'Washer & Dryer',
  WEB: 'Web',
  WELCOME: 'Welcome',
  WE_DONT_HAVE_ANY_COMMUNITY: 'We dont have any communities to show.',
  WHAT_LEVEL_OF_DIABETIC_CARE_DO_THEY_NEED:
    'What level of diabetic care do they need?',
  WHAT_WE_HAVE_FOR_YOU: "Here's what we have for you",
  WHEELCHAIR: 'Wheelchair',
  WHERE_IS_YOUR_LOVED_ONE_CURRENTLY_LIVING:
    'Where is your loved one currently living?',
  WHERE_LOOKING: 'What area are you looking for options today?',
  WORKING_LIST: 'Working List',
  WORKING_LIST_FOR_REFERRAL: 'Working List for Referral',
  WORK_PHONE: 'Work Phone',
  WORK_PHONE_CODE: 'WP',
  YEAR: 'Year',
  YEARS_OLD: 'years old',
  YEAR_OF_BIRTH: 'Year of birth',
  YES: 'Yes',
  YES_CANCEL_INQUIRY: 'Yes, cancel inquiry',
  YES_DONT_SAVE: "Yes, don't save",
  YES_UPDATE_LOCATION: 'Yes, Update Location',
  YOUR_PERFORMANCE: 'Your Performance',
  YOU_ARE_MISSING_FAMILYFILE_INFORMATION:
    'You’re missing some information that is required to send this Family File:',
  YOU_ARE_MISSING_INFORMATION:
    'You’re missing some information that is required to send this Referral Alert:',
  YOU_CAN_SAVE_COMMUNITIES:
    'You can save communities to refer later by pressing the',
  YOU_HAVE: 'You have',
  YOU_HAVE_NOT_SENT_FAMILY_LETTERS:
    'You have not yet sent a Family Letter to {QUANTITY} communities that you have referred. Please make sure to send the letter as soon as possible.',
  YOU_HAVE_NOT_SCHEDULED_A_TOUR:
    'You have not scheduled a tour for this community yet.',
  YOU_HAVE_SELECTED: 'You have selected ',
  YOU_WILL_RECEIVE_AN_INBOUND_OR_OUTBOUND:
    'You’ll receive an inbound or outbound call shortly.',
  ZIP_CODE_ERROR_MESSAGE_1:
    'Sorry, but it seems that zip code {zipCode} is invalid, so we’re unable to',
  ZIP_CODE_ERROR_MESSAGE_2: 'show you any results',
  ZIP_CODE_ERROR_MESSAGE_3:
    'Please enter a different zip code and try your search again.',
  ZIP_CODE_ERROR_MESSAGE_TITLE: 'Error XXX Uh oh, Something’s wrong here...',
};
