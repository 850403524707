import React from 'react';
import PropTypes from 'prop-types';

const OldWoman = ({ className }) => {
  return (
    <svg
      className={className}
      width="138"
      height="138"
      viewBox="0 0 138 138"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M118.253 138C112.788 115.042 92.8112 98.0181 69 98.0181C45.1888 98.0181 25.212 115.042 19.7467 138H118.253Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M118.253 138C112.788 115.042 92.8113 98.0181 69 98.0181C62.7037 98.0181 56.6761 99.2093 51.1133 101.385L87.7288 138H118.253Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M35.1072 111.492V138H102.893V111.492C93.9093 103.115 82.0288 98.0181 69 98.0181C55.9712 98.0181 44.0907 103.115 35.1072 111.492Z"
          fill="url(#paint2_linear)"
        />
        <path
          d="M118.252 138H64.0104V119.912C64.0104 117.726 65.8052 116.002 67.9915 116.009H68.034H69.966C76.4369 116.009 81.6825 110.764 81.6825 104.293V99.6795C99.6685 104.48 113.79 119.262 118.252 138Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M69.9663 116.009H68.0337C61.5629 116.009 56.3173 110.763 56.3173 104.292V99.3057H81.6827V104.292C81.6827 110.763 76.4371 116.009 69.9663 116.009Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M80.1915 99.3057H57.8093C57.3092 99.4226 56.8116 99.5462 56.3173 99.6783V104.292C56.3173 105.181 56.4195 106.045 56.6071 106.878L65.4477 115.718C66.2801 115.906 67.1446 116.008 68.0335 116.008H69.966C76.4369 116.008 81.6825 110.763 81.6825 104.292V99.678C81.1887 99.5465 80.6913 99.4226 80.1915 99.3057Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M37.1136 96.3034C46.927 96.3034 54.8824 88.3481 54.8824 78.5347C54.8824 68.7213 46.927 60.7659 37.1136 60.7659C27.3002 60.7659 19.3449 68.7213 19.3449 78.5347C19.3449 88.3481 27.3002 96.3034 37.1136 96.3034Z"
          fill="url(#paint6_linear)"
        />
        <path
          d="M100.886 96.3034C110.7 96.3034 118.655 88.3481 118.655 78.5347C118.655 68.7213 110.7 60.7659 100.886 60.7659C91.073 60.7659 83.1177 68.7213 83.1177 78.5347C83.1177 88.3481 91.073 96.3034 100.886 96.3034Z"
          fill="url(#paint7_linear)"
        />
        <path
          d="M37.1136 67.0101C43.1161 67.0101 47.982 62.1441 47.982 56.1417C47.982 50.1392 43.1161 45.2732 37.1136 45.2732C31.1111 45.2732 26.2451 50.1392 26.2451 56.1417C26.2451 62.1441 31.1111 67.0101 37.1136 67.0101Z"
          fill="url(#paint8_linear)"
        />
        <path
          d="M35.1072 66.2852C39.509 66.2852 43.0774 62.7168 43.0774 58.315C43.0774 53.9132 39.509 50.3448 35.1072 50.3448C30.7054 50.3448 27.137 53.9132 27.137 58.315C27.137 62.7168 30.7054 66.2852 35.1072 66.2852Z"
          fill="url(#paint9_linear)"
        />
        <path
          d="M100.886 67.0101C106.889 67.0101 111.755 62.1441 111.755 56.1417C111.755 50.1392 106.889 45.2732 100.886 45.2732C94.8839 45.2732 90.0179 50.1392 90.0179 56.1417C90.0179 62.1441 94.8839 67.0101 100.886 67.0101Z"
          fill="url(#paint10_linear)"
        />
        <path
          d="M102.893 66.2852C107.295 66.2852 110.863 62.7168 110.863 58.315C110.863 53.9132 107.295 50.3448 102.893 50.3448C98.491 50.3448 94.9226 53.9132 94.9226 58.315C94.9226 62.7168 98.491 66.2852 102.893 66.2852Z"
          fill="url(#paint11_linear)"
        />
        <path
          d="M69.9246 105.852H68.0757C47.387 105.852 31.3899 87.7023 33.9881 67.1773L34.9126 59.8739C37.0863 42.7029 51.6924 29.8291 69.0003 29.8291C86.3082 29.8291 100.914 42.7029 103.088 59.8739L104.012 67.1773C106.61 87.7023 90.6132 105.852 69.9246 105.852Z"
          fill="url(#paint12_linear)"
        />
        <path
          d="M61.4251 38.1906C61.4251 38.1906 51.8517 54.1407 36.1349 54.1407C36.1349 54.1407 23.9876 34.8237 32.8313 20.2682C41.4901 6.01714 54.882 11.6094 54.882 11.6094C54.882 11.6094 56.8655 1.44528 73.239 0.064197C88.2118 -1.19837 114.048 16.1114 101.875 54.1407C101.875 54.1407 75.2744 65.3834 61.4251 38.1906Z"
          fill="url(#paint13_linear)"
        />
        <path
          d="M101.875 54.1405C94.6131 27.221 58.6276 23.3348 58.6276 23.3348C58.4471 29.2878 61.4252 38.1904 61.4252 38.1904C75.2744 65.3831 101.875 54.1405 101.875 54.1405Z"
          fill="url(#paint14_linear)"
        />
        <path
          d="M32.8313 20.2684C23.9874 34.8239 36.1349 54.1409 36.1349 54.1409C30.058 19.3292 54.8823 11.6096 54.8823 11.6096C54.8823 11.6096 41.4901 6.01761 32.8313 20.2684Z"
          fill="url(#paint15_linear)"
        />
        <path
          d="M29.3927 68.7034C31.5845 68.7034 33.3614 66.9265 33.3614 64.7347C33.3614 62.5428 31.5845 60.7659 29.3927 60.7659C27.2008 60.7659 25.424 62.5428 25.424 64.7347C25.424 66.9265 27.2008 68.7034 29.3927 68.7034Z"
          fill="url(#paint16_radial)"
        />
        <path
          d="M108.607 68.7034C110.799 68.7034 112.576 66.9265 112.576 64.7347C112.576 62.5428 110.799 60.7659 108.607 60.7659C106.416 60.7659 104.639 62.5428 104.639 64.7347C104.639 66.9265 106.416 68.7034 108.607 68.7034Z"
          fill="url(#paint17_radial)"
        />
        <path
          d="M78.7344 74.5577L76.2455 56.0338H61.7545L59.2656 74.5577C58.9144 77.1713 60.4749 79.6948 62.9951 80.4709C66.901 81.6736 71.099 81.6736 75.0049 80.4709C77.5251 79.6948 79.0856 77.1713 78.7344 74.5577Z"
          fill="url(#paint18_linear)"
        />
        <path
          d="M52.4159 64.6466C54.5706 64.6466 56.3173 62.3884 56.3173 59.6026C56.3173 56.8169 54.5706 54.5586 52.4159 54.5586C50.2613 54.5586 48.5146 56.8169 48.5146 59.6026C48.5146 62.3884 50.2613 64.6466 52.4159 64.6466Z"
          fill="url(#paint19_linear)"
        />
        <path
          d="M85.5841 64.6466C87.7387 64.6466 89.4854 62.3884 89.4854 59.6026C89.4854 56.8169 87.7387 54.5586 85.5841 54.5586C83.4294 54.5586 81.6827 56.8169 81.6827 59.6026C81.6827 62.3884 83.4294 64.6466 85.5841 64.6466Z"
          fill="url(#paint20_linear)"
        />
        <path
          d="M52.4159 43.6346C49.5507 43.6346 46.8978 44.5413 44.7274 46.0834C43.1886 47.1768 42.9981 49.39 44.3329 50.7248L44.5212 50.9131C45.5833 51.9752 47.2377 52.0715 48.4729 51.217C49.5926 50.4423 50.9512 49.9885 52.4159 49.9885C53.8806 49.9885 55.2392 50.4423 56.3589 51.217C57.5939 52.0715 59.2485 51.9752 60.3106 50.9131L60.4989 50.7248C61.8337 49.39 61.6432 47.1768 60.1044 46.0834C57.934 44.5416 55.2809 43.6346 52.4159 43.6346Z"
          fill="url(#paint21_linear)"
        />
        <path
          d="M85.5841 43.6346C82.7191 43.6346 80.066 44.5413 77.8956 46.0834C76.3567 47.1768 76.1662 49.39 77.5011 50.7248L77.6894 50.9131C78.7515 51.9752 80.4058 52.0715 81.6411 51.217C82.7608 50.4423 84.1194 49.9885 85.5841 49.9885C87.0488 49.9885 88.4074 50.4423 89.5271 51.217C90.7621 52.0715 92.4167 51.9752 93.4788 50.9131L93.6671 50.7248C95.0019 49.39 94.8114 47.1768 93.2726 46.0834C91.1022 44.5416 88.4493 43.6346 85.5841 43.6346Z"
          fill="url(#paint22_linear)"
        />
        <path
          d="M76.4369 90.6993C76.4382 90.685 76.4388 90.6771 76.4388 90.6771L76.4358 90.6774C76.4122 90.4271 76.1717 90.1987 75.8675 90.3078L75.5845 90.4095C74.2278 90.8966 72.722 90.7245 71.51 89.9438C69.9823 88.9597 68.0199 88.9597 66.4922 89.9438C65.2805 90.7242 63.7744 90.8963 62.418 90.4095L62.1349 90.3078C61.7658 90.1752 61.4898 90.5397 61.5837 90.8379C61.715 91.9102 62.7367 98.0179 69.0011 98.0179H69.0041C75.298 98.0179 76.2999 91.8526 76.4233 90.823C76.4344 90.7824 76.4385 90.7407 76.4369 90.6993Z"
          fill="url(#paint23_linear)"
        />
        <path
          d="M76.4393 90.676C76.4393 90.676 76.4371 90.6841 76.4371 90.6987C76.4393 90.7399 76.435 90.781 76.4228 90.8221C76.4044 90.9826 76.363 91.2664 76.2851 91.6306C76.2837 91.6374 76.2821 91.6442 76.2805 91.6512C76.1159 92.3829 75.4462 92.8932 74.6972 92.8596C73.6742 92.8139 72.2408 92.8494 71.5103 93.3194C69.9817 94.3027 68.0191 94.3027 66.4927 93.3194C65.7681 92.8532 64.3418 92.8172 63.3095 92.8642C62.554 92.8986 61.8797 92.3831 61.7204 91.6439C61.7196 91.6401 61.7187 91.6363 61.7179 91.6325C61.6438 91.2786 61.6027 91.001 61.5843 90.8384C61.4936 90.5504 61.7447 90.2006 62.0943 90.2972C62.1087 90.2993 62.1211 90.3034 62.1354 90.3075L62.4174 90.4084C63.7752 90.896 65.281 90.7231 66.4927 89.9435C68.0191 88.9602 69.9817 88.9602 71.5103 89.9435C72.722 90.7231 74.2278 90.896 75.5834 90.4084L75.8673 90.3075C75.8816 90.3034 75.896 90.2993 75.9084 90.2972C76.1944 90.219 76.4144 90.4392 76.435 90.6776L76.4393 90.676Z"
          fill="url(#paint24_linear)"
        />
        <path
          d="M71.9653 122.746C71.2383 121.927 70.1808 121.409 69 121.409C66.8082 121.409 65.0312 123.185 65.0312 125.377C65.0312 126.559 65.55 127.616 66.3688 128.343L76.0261 138H87.2192L71.9653 122.746Z"
          fill="url(#paint25_linear)"
        />
        <path
          d="M69 129.346C71.1918 129.346 72.9687 127.569 72.9687 125.377C72.9687 123.186 71.1918 121.409 69 121.409C66.8081 121.409 65.0312 123.186 65.0312 125.377C65.0312 127.569 66.8081 129.346 69 129.346Z"
          fill="url(#paint26_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="51.8279"
          y1="91.4169"
          x2="72.0409"
          y2="145.559"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE548" />
          <stop offset="0.1758" stopColor="#FFDE50" />
          <stop offset="0.4446" stopColor="#FFCA65" />
          <stop offset="0.7709" stopColor="#FFAA87" />
          <stop offset="1" stopColor="#FF90A4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="94.155"
          y1="125.936"
          x2="55.1903"
          y2="81.1537"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBA5C2" stopOpacity="0" />
          <stop offset="0.3857" stopColor="#FCA0BA" stopOpacity="0.386" />
          <stop offset="0.9737" stopColor="#FF91A5" stopOpacity="0.974" />
          <stop offset="1" stopColor="#FF90A4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="69"
          y1="125.553"
          x2="69"
          y2="139.099"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBA5C2" stopOpacity="0" />
          <stop offset="0.3857" stopColor="#FCA0BA" stopOpacity="0.386" />
          <stop offset="0.9737" stopColor="#FF91A5" stopOpacity="0.974" />
          <stop offset="1" stopColor="#FF90A4" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="105.264"
          y1="128.975"
          x2="66.1193"
          y2="116.708"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBA5C2" stopOpacity="0" />
          <stop offset="0.3857" stopColor="#FCA0BA" stopOpacity="0.386" />
          <stop offset="0.9737" stopColor="#FF91A5" stopOpacity="0.974" />
          <stop offset="1" stopColor="#FF90A4" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="68.9118"
          y1="102.13"
          x2="72.1589"
          y2="115.659"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCCECE" />
          <stop offset="1" stopColor="#FBA5C2" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="76.3522"
          y1="112.451"
          x2="58.4933"
          y2="89.7214"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCCECE" stopOpacity="0" />
          <stop offset="1" stopColor="#BB4966" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="29.6467"
          y1="65.7093"
          x2="51.2995"
          y2="102.901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4F7FF" />
          <stop offset="1" stopColor="#A2E3E9" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="99.0399"
          y1="72.457"
          x2="105.534"
          y2="93.8335"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4F7FF" />
          <stop offset="1" stopColor="#A2E3E9" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="32.0279"
          y1="50.376"
          x2="49.1145"
          y2="69.7468"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCCECE" />
          <stop offset="1" stopColor="#FBA5C2" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="32.7847"
          y1="60.6726"
          x2="47.4991"
          y2="45.7356"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCCECE" stopOpacity="0" />
          <stop offset="1" stopColor="#BB4966" />
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1="105.972"
          y1="50.376"
          x2="88.8858"
          y2="69.7468"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCCECE" />
          <stop offset="1" stopColor="#FBA5C2" />
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1="105.215"
          y1="60.6726"
          x2="90.5009"
          y2="45.7356"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCCECE" stopOpacity="0" />
          <stop offset="1" stopColor="#BB4966" />
        </linearGradient>
        <linearGradient
          id="paint12_linear"
          x1="52.9111"
          y1="40.9996"
          x2="98.9111"
          y2="120.011"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCCECE" />
          <stop offset="1" stopColor="#FBA5C2" />
        </linearGradient>
        <linearGradient
          id="paint13_linear"
          x1="54.1306"
          y1="11.0087"
          x2="94.8284"
          y2="80.9133"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4F7FF" />
          <stop offset="1" stopColor="#A2E3E9" />
        </linearGradient>
        <linearGradient
          id="paint14_linear"
          x1="82.7032"
          y1="29.5524"
          x2="73.6385"
          y2="63.5112"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A2E3E9" stopOpacity="0" />
          <stop offset="1" stopColor="#518CD2" />
        </linearGradient>
        <linearGradient
          id="paint15_linear"
          x1="41.3267"
          y1="17.4843"
          x2="49.1453"
          y2="66.9043"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A2E3E9" stopOpacity="0" />
          <stop offset="1" stopColor="#518CD2" />
        </linearGradient>
        <radialGradient
          id="paint16_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.1379 61.5777) scale(7.889)"
        >
          <stop stopColor="#C4F8B6" />
          <stop offset="0.2416" stopColor="#C1F6B6" />
          <stop offset="0.4094" stopColor="#B8EFB5" />
          <stop offset="0.5548" stopColor="#A9E3B4" />
          <stop offset="0.6875" stopColor="#93D2B2" />
          <stop offset="0.8115" stopColor="#77BCB0" />
          <stop offset="0.9274" stopColor="#55A2AE" />
          <stop offset="1" stopColor="#3B8EAC" />
        </radialGradient>
        <radialGradient
          id="paint17_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(106.353 61.5777) scale(7.88899)"
        >
          <stop stopColor="#C4F8B6" />
          <stop offset="0.2416" stopColor="#C1F6B6" />
          <stop offset="0.4094" stopColor="#B8EFB5" />
          <stop offset="0.5548" stopColor="#A9E3B4" />
          <stop offset="0.6875" stopColor="#93D2B2" />
          <stop offset="0.8115" stopColor="#77BCB0" />
          <stop offset="0.9274" stopColor="#55A2AE" />
          <stop offset="1" stopColor="#3B8EAC" />
        </radialGradient>
        <linearGradient
          id="paint18_linear"
          x1="69"
          y1="63.2236"
          x2="69"
          y2="101.271"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCCECE" stopOpacity="0" />
          <stop offset="1" stopColor="#BB4966" />
        </linearGradient>
        <linearGradient
          id="paint19_linear"
          x1="46.8066"
          y1="52.553"
          x2="55.0552"
          y2="62.9198"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A34A9E" />
          <stop offset="1" stopColor="#343168" />
        </linearGradient>
        <linearGradient
          id="paint20_linear"
          x1="79.9748"
          y1="52.553"
          x2="88.2237"
          y2="62.9198"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A34A9E" />
          <stop offset="1" stopColor="#343168" />
        </linearGradient>
        <linearGradient
          id="paint21_linear"
          x1="52.4159"
          y1="44.4707"
          x2="52.4159"
          y2="54.5725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4F7FF" />
          <stop offset="1" stopColor="#A2E3E9" />
        </linearGradient>
        <linearGradient
          id="paint22_linear"
          x1="85.5841"
          y1="44.4707"
          x2="85.5841"
          y2="54.5725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4F7FF" />
          <stop offset="1" stopColor="#A2E3E9" />
        </linearGradient>
        <linearGradient
          id="paint23_linear"
          x1="69.0019"
          y1="82.1398"
          x2="69.0019"
          y2="95.8694"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4974" />
          <stop offset="0.4497" stopColor="#FE4773" />
          <stop offset="0.6292" stopColor="#FC406D" />
          <stop offset="0.7605" stopColor="#F73464" />
          <stop offset="0.8683" stopColor="#F12257" />
          <stop offset="0.9606" stopColor="#E80C46" />
          <stop offset="1" stopColor="#E4003D" />
        </linearGradient>
        <linearGradient
          id="paint24_linear"
          x1="69.0024"
          y1="85.3164"
          x2="69.0024"
          y2="92.8745"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4974" />
          <stop offset="0.4497" stopColor="#FE4773" />
          <stop offset="0.6292" stopColor="#FC406D" />
          <stop offset="0.7605" stopColor="#F73464" />
          <stop offset="0.8683" stopColor="#F12257" />
          <stop offset="0.9606" stopColor="#E80C46" />
          <stop offset="1" stopColor="#E4003D" />
        </linearGradient>
        <linearGradient
          id="paint25_linear"
          x1="77.1041"
          y1="133.481"
          x2="56.2877"
          y2="112.665"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#343168" stopOpacity="0" />
          <stop offset="1" stopColor="#A34A9E" />
        </linearGradient>
        <linearGradient
          id="paint26_linear"
          x1="65.0312"
          y1="125.377"
          x2="72.9687"
          y2="125.377"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A34A9E" />
          <stop offset="1" stopColor="#343168" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="138" height="138" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

OldWoman.propTypes = {
  className: PropTypes.string,
};

export default OldWoman;
