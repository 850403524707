export default {
  PIPEDA_ENTIRETY: 'PIPEDA in its entirety:',
  IF_INTERRUPTED: `If Interrupted:`,
  IF_ADDITIONAL_INFORMATION: `If the family requests additional Information about the PIPEDA disclosure, use the scripting below:`,
  FIRST_PIPEDA_PARAGRAPH: `"I want to let you know that PIPEDA (Personal Information
          Protection & Electronic Documents Act) is a Canadian Act that ensures
          companies advise you of their intent to collect and use your personal
          information and how they manage your information in a way that
          safeguards your privacy. Since you do not have email I am required to
          offer to read the information to you. Feel free to interrupt me at any
          point if you feel comfortable with the information. The information is
          always available on the privacy page of our website if you wish to
          review it again at a later date."`,
  SECOND_PIPEDA_PARAGRAPH: `"The Personal Information provided by you during this phone call
          or information that is subsequently provided by you over the phone to
          our Senior Advisors, may be stored or accessed in Canada and/or the
          United States. This Personal Information may be disclosed in response
          to valid demands or requests from governments, regulators, courts and
          law enforcement authorities in those jurisdictions or countries.`,
  THIRD_PIPEDA_PARAGRAPH: `We use Personal Information to provide you with the services you have
          requested from us and to provide services to the senior living and
          care providers who have contracted with us (hereinafter referred to as
          "Participating Communities") by signing our
          Referral/Commission Agreement for Information Services (hereinafter
          referred to as the "Referral & Commission Agreement") and
          agreeing to be part of our referral information service program in
          relation to the services that we provide you. Based on the Personal
          Information that we have collected, we will provide you with the
          information about one or more of our Participating Communities whom we
          identify as options for your consideration. The Participating
          Communities then may contact you directly regarding their facilities
          or services. We will work with both you and the Participating
          Communities in your search.`,
  FOURTH_PIPEDA_PARAGRAPH: `We may share Personal Information with one or more third parties with
          whom we have contracted to provide services to us. [Our contracts with
          these parties specify the purpose(s) for which Personal Information
          has been shared with them and the limited purpose(s) for which the
          Personal Information may be used by them. Our contracts with these
          parties also require that they implement privacy and security
          practices and procedures comparable to, or at least as stringent as
          those implemented by us, that they do not disclose Personal
          Information to any other third party, and that they comply with all
          applicable laws, including privacy laws.] We may use Personal
          Information for our own internal statistical, design or operational
          purposes, such as to estimate our audience size, measure aggregate
          traffic patterns, and understand demographic trends among our users.`,
  FIFTH_PIPEDA_PARAGRAPH: `We will not disclose your Personal Information to anyone unless (a)
          you (or an individual authorized to act on your behalf) have consented
          to this disclosure; or (b) such disclosure is permitted or required by
          law.`,
  SIXTH_PIPEDA_PARAGRAPH: `We may disclose Personal Information to one or more 
        Participating Communities so that the Participating Communities may learn about
        the specific senior living care services that you are seeking and determine how they
        can assist you. Participating Communities may contact you directly (through any means
        that you specify). We do not control how these Participating Communities use or disclose
        your Personal Information, however as per our Referral & Commission Agreement with each
        Participating Community, each Participating Community has agreed to limit its use of your
        Personal Information to (a) its own internal evaluation of potential residents; and (b)
        providing senior living and/or care services to such residents. Each Participating Community
        has also agreed to implement privacy and security practices and procedures comparable to,
        or at least as stringent as those implemented by us and not to disclose Personal Information
        to any third party.`,
  SEVENTH_PIPEDA_PARAGRAPH: `We may disclose some or all of your Personal Information with our Parent, 
        subsidiaries, joint ventures, or other companies under common control with us, in which case we
        will require them to honor this Privacy Policy.`,
  EIGHTH_PIPEDA_PARAGRAPH: `We may disclose Personal Information when we believe it is appropriate to 
        comply with the law; to enforce or apply this Privacy Policy or our other policies or agreements;
        to protect our or our users' rights, property or safety; to protect our users from fraudulent,
        abusive or unlawful use of, our Sites; or if we reasonably believe that an emergency involving
        immediate danger of death or serious physical injury to any person requires disclosure of
        communications or justifies disclosure of records without delay.`,
  NINETH_PIPEDA_PARAGRAPH: `We may also disclose Personal Information as part of any merger, acquisition, 
        debt financing, sale of company assets or other business transaction.`,
  TENTH_PIPEDA_PARAGRAPH: `We use reasonable security measures to store and maintain Personal Information 
        to protect it from theft, loss, misuse, alteration or destruction by any unauthorized party while it 
        is under our control.  Please note that while we will make reasonable efforts to protect Personal 
        Information, no transmission of data over the Internet is 100% secure.  All Internet users should 
        take care with how they handle and disclose their Personal Information over the Internet. 
        [NTD: Insert information re how long APFM will keep Personal Information and when it will dispose of it.]`,
  ELEVENTH_PIPEDA_PARAGRAPH: `Upon written request, you may access your Personal Information that is in our possession 
        and request a correction or update of any such information.`,
  TWELVETH_PIPEDA_PARAGRAPH: `If you have any questions or concerns about this Summary Privacy Policy or the 
        full Privacy Policy or the privacy practices of our Sites, please contact us at privacy@aplaceformom.com."`,
  THIRTEENTH_PIPEDA_PARAGRAPH: `"Ok, great, at this time you are agreeing to the terms & conditions of the PIPEDA 
        privacy policy.  Again, a copy of the full privacy policy is always available on our website at www.aplaceformom.com/privacy"`,
  FOURTEENTH_PIPEDA_PARAGRAPH: `"The PIPEDA disclosure gives an in-depth explanation of how we store, protect 
        and share your personal information.  For example, we may share your information with any referred communities
        to help them learn about the specific senior living care services you are seeking so they can better assist you.
        However, we will never share your personal information unless you consent to this disclosure or otherwise required or permitted
        by law. If you do have an email address I would love to send the detailed disclosure for you to review at your leisure."`,
  FIFTEENTH_PIPEDA_PARAGRAPH: `Your information may be stored or accessed in Canada and/or the United States`,
  SIXTEENTH_PIPEDA_PARAGRAPH: `We may disclose info in response to valid requests from governmental agencies`,
  SEVENTEENTH_PIPEDA_PARAGRAPH: `We may share your information with the communities we refer in order to streamline our communication.`,
  EIGHTEENTH_PIPEDA_PARAGRAPH: `We may share your info with a third party we partner with but those third parties are required to adhere to a privacy policy comparable or at least as stringent as APFM’s.`,
  NINETEENTH_PIPEDA_PARAGRAPH: `We don’t disclose personal information unless the consent to the disclosure has been obtained or if required by law.`,
  TWENTIETH_PIPEDA_PARAGRAPH: `We use reasonable measures to ensure your information’s safety but no transmission of data over the internet is 100% secure.`,
};
