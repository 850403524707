import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DefaultMarker from './CommunityMarkers/DefaultMarker';
import ReferredMarker from './CommunityMarkers/ReferredMarker';
import SavedMarker from './CommunityMarkers/SavedMarker';
import HoveredMarker from './CommunityMarkers/HoveredMarker';
import StartingPointMarker from './CommunityMarkers/StartingPointMarker';
import { getSmallContainerTop } from '../../services/communityMarkerService';

const CommunityMarker = ({
  type,
  $hover,
  labels,
  community,
  onCommunitySelected,
  isCommunitySelected,
  isPinpointCardOpen,
  onSave,
  onRemove,
  isHovered,
  familyFileId,
  isClickedInsideTheMap,
  dataForSegment,
  locationFilter,
  onSaveCommunityUpdate,
  communitiesFeature,
  isSmallMap,
  mapSmallContainer,
  setSmallContainerPositionStyle,
  smallContainerPositionStyle,
}) => {
  const [isPinPointClicked, setIsPinPointClicked] = useState(
    isPinpointCardOpen,
  );

  const updateSmallContainerPosition = (content) => {
    const top = getSmallContainerTop(content);
    setSmallContainerPositionStyle({ top: `-${top}px` });
  };

  const updateSmallContainerPositionOnHover = (content) => {
    updateSmallContainerPosition(content);
  };

  useEffect(() => {
    if (mapSmallContainer?.current?.getBoundingClientRect().y) {
      mapSmallContainer.current.style.top = smallContainerPositionStyle.top;
    }
  });

  const changeState = (community) => {
    onCommunitySelected(community);
    setIsPinPointClicked(true);
    updateSmallContainerPosition(community);
  };

  useEffect(() => {
    setIsPinPointClicked(isPinpointCardOpen);
  }, [isPinpointCardOpen]);

  const markersMap = {
    default: (
      <DefaultMarker
        community={community}
        labels={labels}
        onSave={onSave}
        onRemove={onRemove}
        familyFileId={familyFileId}
        isClickedInsideTheMap={isClickedInsideTheMap}
        isCommunitySelected={isCommunitySelected}
        isPinPointClicked={isPinPointClicked}
        dataForSegment={dataForSegment}
        locationFilter={locationFilter}
        setIsPinPointClicked={setIsPinPointClicked}
        changeState={changeState}
        isHovered={isHovered}
        onSaveCommunityUpdate={onSaveCommunityUpdate}
        $hover={$hover}
        communitiesFeature={communitiesFeature}
        isSmallMap={isSmallMap}
        mapSmallContainer={mapSmallContainer}
        updateSmallContainerPositionOnHover={
          updateSmallContainerPositionOnHover
        }
      />
    ),
    referred: (
      <ReferredMarker
        community={community}
        labels={labels}
        onSave={onSave}
        onRemove={onRemove}
        familyFileId={familyFileId}
        isClickedInsideTheMap={isClickedInsideTheMap}
        isCommunitySelected={isCommunitySelected}
        isPinPointClicked={isPinPointClicked}
        dataForSegment={dataForSegment}
        locationFilter={locationFilter}
        setIsPinPointClicked={setIsPinPointClicked}
        changeState={changeState}
        isHovered={isHovered}
        $hover={$hover}
        onSaveCommunityUpdate={onSaveCommunityUpdate}
        isSmallMap={isSmallMap}
        mapSmallContainer={mapSmallContainer}
        updateSmallContainerPositionOnHover={
          updateSmallContainerPositionOnHover
        }
      />
    ),
    saved: (
      <SavedMarker
        community={community}
        labels={labels}
        onSave={onSave}
        onRemove={onRemove}
        familyFileId={familyFileId}
        isClickedInsideTheMap={isClickedInsideTheMap}
        isCommunitySelected={isCommunitySelected}
        isPinPointClicked={isPinPointClicked}
        dataForSegment={dataForSegment}
        locationFilter={locationFilter}
        setIsPinPointClicked={setIsPinPointClicked}
        changeState={changeState}
        isHovered={isHovered}
        $hover={$hover}
        onSaveCommunityUpdate={onSaveCommunityUpdate}
        isSmallMap={isSmallMap}
        mapSmallContainer={mapSmallContainer}
        updateSmallContainerPositionOnHover={
          updateSmallContainerPositionOnHover
        }
      />
    ),
    hovered: (
      <HoveredMarker
        community={community}
        isCommunitySelected={isCommunitySelected}
        changeState={changeState}
        $hover={$hover}
      />
    ),
    startingPoint: <StartingPointMarker $hover={$hover} />,
  };

  return markersMap[type];
};

CommunityMarker.propTypes = {
  type: PropTypes.oneOf([
    'default',
    'startingPoint',
    'saved',
    'hovered',
    'referred',
  ]),
  $hover: PropTypes.bool,
  labels: PropTypes.object,
  community: PropTypes.object,
  onCommunitySelected: PropTypes.func,
  isSelected: PropTypes.bool,
  isPinpointCardOpen: PropTypes.bool,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
  isHovered: PropTypes.bool,
  isCommunitySelected: PropTypes.bool,
  familyFileId: PropTypes.number,
  isClickedInsideTheMap: PropTypes.bool,
  locationFilter: PropTypes.object,
  dataForSegment: PropTypes.shape({
    familyFileId: PropTypes.number,
    oneId: PropTypes.string,
  }),
  onSaveCommunityUpdate: PropTypes.func,
  communitiesFeature: PropTypes.array,
  mapSmallContainer: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

CommunityMarker.defaultProps = {
  type: 'default',
};

export default CommunityMarker;
