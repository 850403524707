import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  actions_container: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '16px',
      color: theme.palette.neutral.cosmicCobalt3,
      marginLeft: '10px',
      verticalAlignment: 'middle',
    },
  },
  icon: {
    fontSize: '40px',
  },
  empty_state: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export { useStyles };
