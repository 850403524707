import React from 'react';
import PropTypes from 'prop-types';

const StarFilled = ({ className, color }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 19.1298L4.79412 22.9182L6.17034 14.8942L0.340576 9.21159L8.39711 8.04091L12.0001 0.740448L15.6031 8.04091L23.6596 9.21159L17.8299 14.8942L19.2061 22.9182L12.0001 19.1298Z"
        fill={color}
      />
    </svg>
  );
};

StarFilled.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

StarFilled.defaultProps = {
  color: '#0D1321',
};

export default StarFilled;
