import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './ReferredCommunityInfo.style';
import CommunityImage from 'pages/Communities/CommunityImage/CommunityImage';

import {
  getDisplayImageId,
  IMAGE_SIZE,
} from 'pages/Communities/CommunitiesTable/communityService';
import CommunityPrimaryContact from '../../CommunityPrimaryContact/CommunityPrimaryContact';
import { getTags } from 'services/communityTagsService';

const ReferredCommunityInfo = ({
  community,
  imageUrl,
  labels,
  flags,
  familyFileId,
  familyFileUserId,
  careTypes,
  dialerService,
  currentUser,
  familyFilePrimaryContact,
  externalId,
}) => {
  const classes = useStyles();

  const primaryCommunityContact = community.employees?.filter((employee) => {
    return employee.isPrimaryContact;
  })[0];

  return (
    <div className={classes.referred_community_info_container}>
      <CommunityImage
        imageId={getDisplayImageId(community.images, IMAGE_SIZE.SMALL)}
        imageUrl={imageUrl}
        size="referred"
        tags={getTags(community)}
      />
      {primaryCommunityContact && (
        <CommunityPrimaryContact
          labels={labels}
          contact={primaryCommunityContact}
          community={community}
          flags={flags}
          familyFileId={familyFileId}
          familyFileUserId={familyFileUserId}
          careTypes={careTypes}
          dialerService={dialerService}
          currentUser={currentUser}
          familyFilePrimaryContact={familyFilePrimaryContact}
          externalId={externalId}
        />
      )}
    </div>
  );
};

ReferredCommunityInfo.propTypes = {
  community: PropTypes.object.isRequired,
  imageUrl: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  flags: PropTypes.object,
  familyFileId: PropTypes.number,
  familyFileUserId: PropTypes.number,
  externalId: PropTypes.number,
  careTypes: PropTypes.array,
  dialerService: PropTypes.object,
  currentUser: PropTypes.object,
  familyFilePrimaryContact: PropTypes.object,
};

export default ReferredCommunityInfo;
