import gql from 'graphql-tag';

export default gql`
  query($familyFileId: Int!, $communitiesIds: [Int!]!) {
    getCommunitiesReferralStatus(
      input: { familyFileId: $familyFileId, communitiesIds: $communitiesIds }
    ) {
      communityId
      isReferralStarted
      referralDate
      isRejected
    }
  }
`;
