import PropTypes from 'prop-types';
import React, { useState, Fragment, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from 'common/Button/Button';
import Dropdown from 'common/Dropdown/Dropdown';
import Modal from 'common/Modal/Modal';
import { withLabels } from 'context/LabelContext';
import { useStyles } from './DispositionsModal.style';
import Typography from 'common/Typography/Typography';
import { updateDisposition } from 'stateManagement/callcenter/formChiclets/actions/formChicletsActions';
import { PhoneContext } from '@aplaceformom/owl-connect-client';
import allCallStates from 'config/callStates.config';

const DispositionsModal = ({ isOpen, labels, onClose, dispositions }) => {
  const { callState } = useContext(PhoneContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedDisposition, setSelectedDisposition] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (callState === allCallStates.FINISHED.key) {
      setLoading(false);
    }
  }, [callState]);

  let modalContent;
  const dispositionItems = dispositions
    .filter((d) => d.id > 0)
    .map((d) => ({
      label: d.name,
      value: d.id,
    }));
  const isEnabled = dispositions?.length > 0;

  function handleUpdateDisposition() {
    setLoading(true);
    dispatch(updateDisposition(selectedDisposition.label));
  }

  if (loading === true) {
    modalContent = (
      <div className="progressContainer" align="center">
        <CircularProgress />
      </div>
    );
  } else {
    modalContent = (
      <Fragment>
        <Typography bold>{labels.DISPOSITION_NOT_SET}</Typography>
        <Typography>
          <p>{labels.DISPOSITION_NOT_FOUND}</p>
        </Typography>
        <Dropdown
          label="Select Disposition"
          items={dispositionItems}
          onChange={setSelectedDisposition}
          selected={selectedDisposition}
        />
        <div className={classes.modal_footer}>
          <Button
            type={isEnabled ? 'primary' : 'disabled'}
            onClick={() => handleUpdateDisposition()}
          >
            {labels.SUBMIT}
          </Button>
        </div>
      </Fragment>
    );
  }

  return (
    <Modal
      className={classes.dispositionsModal}
      title={labels.DISPOSITION_TITLE}
      isOpen={isOpen}
      onClose={onClose}
      color="cosmicCobalt2"
    >
      {modalContent}
    </Modal>
  );
};

DispositionsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  labels: PropTypes.object,
  dispositions: PropTypes.array,
  disposition: PropTypes.string,
  loading: PropTypes.bool,
};

DispositionsModal.defaultProps = {
  dispositions: [],
  onClose: () => {},
  onSave: () => {},
  loading: false,
};

export default withLabels(DispositionsModal);
