import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import { useStyles } from './ReferredCommunitiesRow.style';

const ReferralCommunityActions = ({ actions }) => {
  const classes = useStyles();

  return actions.map((item, index) => (
    <SmartTooltip
      title={item.disabled ? item.disabledToolTipText : item.value}
      cursor={item.disabled ? 'default' : 'pointer'}
      placement="top"
      key={index}
    >
      <div
        className={clsx(
          classes.display_icon_container,
          item.disabled && classes.icon_disabled,
        )}
        onClick={() => !item.disabled && item.action()}
        key={index}
      >
        {item.label}
      </div>
    </SmartTooltip>
  ));
};

ReferralCommunityActions.propTypes = {
  actions: PropTypes.array,
};

ReferralCommunityActions.defaultProps = {
  actions: [],
};

export default ReferralCommunityActions;
