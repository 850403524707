import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './TopBar.style';
import { withAuthenticationContext } from '../../context/Authentication';
import Typography from '../../common/Typography/Typography';
import ChevronBottom from '../../common/Icons/arrow/ChevronBottom';
import ChevronTop from '../../common/Icons/arrow/ChevronTop';
import { withLabels } from '../../context/LabelContext';
import ArrowLeft from '../../common/Icons/arrow/ArrowLeft';
import { Link, useLocation } from 'react-router-dom';
import { withUser } from '../../context/UserContext';
import { getHomePage } from '../../config/roles';
import Menu from '../../common/Icons/basic/Menu';
import NavigationSidePanel from '../NavigationSidePanel/NavigationSidePanel';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import SquareStairs from '../../common/Icons/basic/SquareStairs';
import { registerEvent } from '../../services/Analytics';
import clsx from 'clsx';
import Version from '../../components/Version';

const TopBarXL = ({ auth, labels, currentUser, flags, isMobile }) => {
  const location = useLocation();
  const classes = useStyles();
  const wrapperRef = useRef(null);
  const [isUserOpen, setIsUserOpen] = useState(false);
  const [isHome, setIsHome] = useState(false);
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);

  const getDisplayName = () => {
    const familyName = auth.user.family_name || '';
    const name = auth.user.given_name || auth.user.name;
    return `${name} ${familyName.substring(0, 1).toUpperCase()}.`;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsUserOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  let leftNavigation = clsx(
    classes.left_navigation,
    !isHome && classes.left_navigation_with_back,
  );

  useEffect(() => {
    const path = location.pathname;
    setIsHome(path === getHomePage(currentUser.role));
  }, [location, currentUser]);

  const handleHamburgerClick = () => {
    setIsNavigationOpen(true);
    registerEvent('Navigation', 'Navigation side panel is opened');
  };

  return (
    <div className={classes.top_bar_container}>
      <div className={leftNavigation}>
        {flags.sidePanelNavigation && (
          <>
            <div className={classes.hamburger} onClick={handleHamburgerClick}>
              <Menu />
            </div>
            {isNavigationOpen && (
              <NavigationSidePanel
                labels={labels}
                backgroundColor="cosmicCobalt1"
                width="240"
                xColor="platinum5"
                isOpen={isNavigationOpen}
                onClose={() => setIsNavigationOpen(false)}
              />
            )}
          </>
        )}
        {!flags.sidePanelNavigation && isHome && <SquareStairs />}
        {!isHome && (
          <Link to="/home">
            <ArrowLeft />
            <Typography bold color="platinum5">
              {labels.BACK}
            </Typography>
          </Link>
        )}
      </div>
      <div
        className={classes.user}
        data-testid="user-menu"
        onClick={() => setIsUserOpen(!isUserOpen)}
        ref={wrapperRef}
      >
        <img src={auth.user.picture} alt="user" />
        {!isMobile && (
          <Typography bold color="platinum5">
            {getDisplayName()}
          </Typography>
        )}
        {isUserOpen ? <ChevronTop /> : <ChevronBottom />}
        {isUserOpen && (
          <div className={classes.user_menu}>
            <div
              data-testid="logout-button"
              className={classes.user_menu_item}
              onClick={() => auth.logout()}
            >
              <Typography color="platinum5">{labels.LOGOUT}</Typography>
            </div>
            <Version
              class={clsx(classes.user_menu_item, classes.version_info)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

TopBarXL.propTypes = {
  labels: PropTypes.object.isRequired,
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.string,
    __typename: PropTypes.string,
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      given_name: PropTypes.string,
      family_name: PropTypes.string,
      picture: PropTypes.string.isRequired,
      name: PropTypes.string,
    }).isRequired,
    logout: PropTypes.func.isRequired,
  }).isRequired,
  flags: PropTypes.shape({
    sidePanelNavigation: PropTypes.bool,
  }),
  isMobile: PropTypes.bool,
};

TopBarXL.defaultProps = {
  isMobile: false,
};

export default withLDConsumer()(
  withUser(withLabels(withAuthenticationContext(TopBarXL))),
);
export { TopBarXL };
