import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  flip_main_container: {
    width: '100%',
    height: '100%',
    minHeight: theme.spacing(24),
    position: 'relative',
  },
  components_container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    transition: 'transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
    transformStyle: 'preserve-3d',
  },
  component: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    '-webkit-backface-visibility': 'hidden',
    backfaceVisibility: 'hidden',
  },
  front: {
    zIndex: 0,
  },
  flipped: {
    transform: 'rotateY(180deg)',
    transformStyle: 'preserve-3d',
  },
  back: {
    transform: 'rotateY(180deg)',
    transformStyle: 'preserve-3d',
  },
}));

export { useStyles };
