import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './EmptyCommunities.style';
import Typography from '../../common/Typography/Typography';
import PinnedMap from '../../common/Graphics/communities/PinnedMap';
import PinAdd from '../../common/Icons/maps/PinAdd';

const EmptyCommunitiesXS = ({ labels }) => {
  const classes = useStyles();
  return (
    <div className={classes.empty_container_xs}>
      <div className={classes.no_content}>
        <PinnedMap className={classes.no_content_graphic} />
        <Typography className={classes.empty_text} bold level="large">
          {labels.ITS_EMPTY_HERE}
        </Typography>
        <Typography>{labels.EMPTY_COMMUNITY_TIP}</Typography>
        <Typography>{labels.SEARCH_FOR_COMMUNITIES_NOW}</Typography>
        <Typography>{labels.YOU_CAN_SAVE_COMMUNITIES}</Typography>
        <Typography className={classes.save_community_button}>
          <PinAdd />
          {`- ${labels.SAVE_COMMUNITY_BUTTON}`}
        </Typography>
      </div>
    </div>
  );
};

EmptyCommunitiesXS.propTypes = {
  labels: PropTypes.object,
};

export default EmptyCommunitiesXS;
