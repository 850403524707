import FamilyFileFormModel from '../FamilyFileFormModel';

/**
 * Class defining the general structure of the values stored within the Financial Criteria form.
 */
class FinancialCriteriaFormModel extends FamilyFileFormModel {
  /**
   * Constructs an instance of FinancialCriteriaFormModel.
   *
   * @param {Object} data - Object matching the structure of a FinancialCriteriaFormModel.
   */
  constructor(data) {
    super();

    // Various identifiers
    this.formDataId = data.formDataId;
    this.oneId = data.oneId;
    this.familyFileId = data.familyFileId;

    // The budget range, as a string (i.e. "$4000-4999")
    this.estimatedBudgetRange = data.estimatedBudgetRange;

    // An array of strings representing individual resources (i.e. "Home to sell", "Monthly Income")
    this.financialResources = data.financialResources;

    // String representing whether or not they have a long term care insurance policy ("No Policy" or "Has Policy")
    this.hasLongTermCareInsurancePolicy = data.hasLongTermCareInsurancePolicy;

    // Boolean indicating whether they or their spouse is a veteran
    this.isVeteran = data.isVeteran;

    // String indicating any VA aid they are receiving ("Not a candidate", "Potential Candidate", "Current recipient").
    // Can be null.
    this.vaAid = data.vaAid;

    // String indicating Medicaid status ("NO", "YES", "CURRENTLY_APPLYING"). Can be null.
    this.medicaid = data.medicaid;
  }
}

export default FinancialCriteriaFormModel;
