import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './TableCell.style';
import clsx from 'clsx';

const TableCell = ({ className, children, colSpan }) => {
  const classes = useStyles();
  const containerClasses = clsx(className, classes.cell);
  return (
    <td className={containerClasses} colSpan={colSpan}>
      {children}
    </td>
  );
};

TableCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  colSpan: PropTypes.number,
};

export default TableCell;
