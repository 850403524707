import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useLocation } from 'react-router-dom';
import Card from 'common/Card/Card';
import EditCard from 'common/Card/EditCard/EditCard';
import Heart from 'common/Icons/basic/Heart';
import Toast from 'common/Toast/Toast';
import Typography from 'common/Typography/Typography';
import { registerEvent } from 'services/Analytics';
import { inFamilyFilePage } from 'services/familyFileService';
import { useStyles } from './CommunityPreferences.style';
import CommunityPreferencesContent from './CommunityPreferencesContent';
import CommunityPreferencesEdit from './CommunityPreferencesEdit';
import useGraphQLMutation from 'hooks/useGraphQLMutation';
import upsertCommunityPreferenceMutation from './UpsertCommunityPreference';
import CommunityPreferenceFormModel from './CommunityPreferenceFormModel';
import InitialConsultationFormService from '../../../pages/InitialConsultation/InitialConsultationFormService';
import updateCareTypesMutation from '../../../services/Resident/Mutations/updateCareTypesMutation';
import FamilyFileAnalyticsService from '../FamilyFileAnalyticsService';

const MESSAGE_TYPES = {
  SUCCESS: {
    type: 'success',
    label: 'COMMUNITY_PREFERENCES_SUCCESS_MESSAGE',
  },
  ERROR: {
    type: 'error',
    label: 'OVERVIEW_ERROR_MESSAGE',
  },
  NONE: {},
};

const CommunityPreferences = ({ labels, communityPreferencesData, flags }) => {
  const classes = useStyles();
  const location = useLocation();
  const [communityPreferencesState, setCommunityPreferencesState] = useState(
    communityPreferencesData,
  );
  const originalFormData = useRef(communityPreferencesData);
  const [editCommunityPreferences, setEditCommunityPreferences] = useState(
    false,
  );
  const [messageType, setMessageType] = useState(MESSAGE_TYPES.NONE);
  const [upsertCommunityPreference] = useGraphQLMutation(
    upsertCommunityPreferenceMutation,
  );
  const [updateCareTypes] = useGraphQLMutation(updateCareTypesMutation);

  const isEditable = () => {
    return flags.updateContactsOwl && inFamilyFilePage(location);
  };

  const renderMessage = () => {
    if (messageType === MESSAGE_TYPES.NONE) return null;
    return (
      <div className={classes.show_message}>
        <Toast
          type={messageType.type}
          onClose={() => setMessageType[MESSAGE_TYPES.NONE]}
        >
          <Typography>{labels[messageType.label]}</Typography>
        </Toast>
      </div>
    );
  };

  const saveCommunityPreferences = (formModel) => {
    const initialConsultationService = new InitialConsultationFormService({
      upsertCommunityPreferenceMutation: upsertCommunityPreference,
      updateCareTypesMutation: updateCareTypes,
    });
    const communityPreferenceFormModel = new CommunityPreferenceFormModel({
      ...formModel,

      // TODO: Remove this line. With IC workflow, the Distance Request field moved to the Resident Information form.
      // This line is protective programming from the IC workflow, where the field won't be in the UI, potentially
      // causing an overwrite of prior locationRange data. Currently, the upsertFamilyFileCommunityPreferences mutation
      // does not allow you to specify an input without a locationRange. It is allowed by GraphQL schema, but a Prisma
      // error is thrown because the column cannot be null and the resolver does not currently provide a default value.
      // This can be removed when both the IC workflow is launched and BUB-2625 is completed.
      locationRange: flags.isInitialConsultationEnabled
        ? communityPreferencesState.locationRange
        : formModel.locationRange,
    });

    const familyFileAnalyticsService = new FamilyFileAnalyticsService();
    familyFileAnalyticsService.submitFamilyFileUpdateAnalytics({
      familyFileId: communityPreferenceFormModel.familyFileId,
      section: 'family file',
      screenName: 'family file',
      submitButtonIdentifier: 'Save',
      initialCommunityPreferencesFormModel: originalFormData.current,
      updatedCommunityPreferencesFormModel: communityPreferenceFormModel,
    });

    initialConsultationService
      .upsertCommunityPreference(communityPreferenceFormModel)
      .then(() => {
        originalFormData.current = communityPreferenceFormModel;
        setCommunityPreferencesState(formModel);
        setMessageType(MESSAGE_TYPES.SUCCESS);
        setEditCommunityPreferences(false);
      })
      .catch(() => {
        setMessageType(MESSAGE_TYPES.ERROR);
      });
  };

  const startEdition = () => {
    registerEvent('FamilyFiles', 'Edit community preferences is opened');
    setEditCommunityPreferences(true);
    setMessageType(MESSAGE_TYPES.NONE);
  };

  const renderNotEditingCommunityPreferences = () => (
    <div className={classes.change_background} onClick={() => startEdition()}>
      <CommunityPreferencesContent
        communityPreferences={communityPreferencesState}
        isEditable={true}
        labels={labels}
      />
    </div>
  );

  const renderEditingCommunityPreferences = () => (
    <div className={classes.edit_container}>
      <CommunityPreferencesEdit
        communityPreferences={communityPreferencesState}
        labels={labels}
        onSave={saveCommunityPreferences}
        onCancel={() => setEditCommunityPreferences(false)}
        shouldShowActionControls
      />
    </div>
  );

  // Developer Note: With the introduction of the IC workflow, the new terminology for "Community Preferences" is
  // "Care & Preferences". When the IC workflow is fully launched, this conditional can be removed.
  const cardLabel = flags.isInitialConsultationEnabled
    ? labels.CARE_AND_PREFERENCES
    : labels.COMMUNITY_PREFERENCES;

  const editableCard = (
    <EditCard
      headerColor="platinum4"
      type="light"
      title={cardLabel}
      icon={<Heart />}
      className={classes.container}
      clickCallback={() => startEdition()}
      flags={flags}
    >
      <div>
        {renderMessage()}
        {editCommunityPreferences
          ? renderEditingCommunityPreferences()
          : renderNotEditingCommunityPreferences()}
      </div>
    </EditCard>
  );

  const notEditableCard = (
    <Card className={classes.container} title={cardLabel} icon={<Heart />}>
      <CommunityPreferencesContent
        communityPreferences={communityPreferencesData}
        isEditable={false}
        labels={labels}
      />
    </Card>
  );

  return <Fragment>{isEditable() ? editableCard : notEditableCard}</Fragment>;
};

CommunityPreferences.propTypes = {
  labels: PropTypes.object.isRequired,
  communityPreferencesData: PropTypes.instanceOf(CommunityPreferenceFormModel)
    .isRequired,
  flags: PropTypes.shape({
    updateContactsOwl: PropTypes.bool,
    isInitialConsultationEnabled: PropTypes.bool,
  }),
};

export default withLDConsumer()(CommunityPreferences);
