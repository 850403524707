import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './Slider.style';
import MaterialSlider from '@material-ui/core/Slider';
import clsx from 'clsx';

const Slider = ({
  className,
  disabled,
  label,
  marks,
  max,
  min,
  onChange,
  step,
  values,
}) => {
  const classes = useStyles();

  const getProps = () => {
    if (values.length > 1) {
      return { value: values };
    }
    return { value: values[0] };
  };

  const getMarks = () => {
    if (marks && marks.length > 0) {
      return { marks: marks };
    } else {
      return '';
    }
  };

  return (
    <div
      className={clsx(classes.slider_container, className, 'slider_container')}
    >
      {label && <div className={classes.label}>{label}</div>}
      <MaterialSlider
        step={step}
        {...getProps()}
        max={max}
        min={min}
        onChange={(e, values) => onChange(values)}
        {...getMarks()}
        disabled={disabled}
      />
    </div>
  );
};

Slider.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  marks: PropTypes.arrayOf(PropTypes.object),
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  step: PropTypes.number,
  values: PropTypes.arrayOf(PropTypes.number),
};

Slider.defaultProps = {
  className: '',
  disabled: false,
  label: '',
  marks: [],
  max: 10000,
  min: 0,
  onChange: () => {},
  step: 1,
  values: [0],
};

export default Slider;
