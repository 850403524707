import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './NoReferredCommunities.style';
import Typography from 'common/Typography/Typography';
import Button from 'common/Button/Button';
import Search from 'common/Icons/basic/Search';
import Refer from 'common/Graphics/communities/Refer';
import { registerEvent } from 'services/Analytics';

const NoReferredCommunities = ({ labels, familyFileId }) => {
  const classes = useStyles();
  return (
    <div className={classes.no_referred_communities_container}>
      <Refer className={classes.icon} />
      <Typography level="large" bold className={classes.title}>
        {labels.NO_REFERRED_COMMUNITIES}
      </Typography>
      <Typography className={classes.description}>
        {labels.NO_REFERRED_COMMUNITIES_YET}
      </Typography>
      <div
        onClick={() => {
          registerEvent(
            'FamilyFiles',
            'Go to search communities',
            'No Referred Communities',
          );
        }}
      >
        <Button
          openInNewTab
          to={`/communities/${familyFileId}`}
          className={classes.edit_ff_button}
          startIcon={<Search />}
        >
          <Typography color="platinum5">
            {labels.SEARCH_FOR_COMMUNITIES}
          </Typography>
        </Button>
      </div>
    </div>
  );
};

NoReferredCommunities.propTypes = {
  labels: PropTypes.object,
  familyFileId: PropTypes.number.isRequired,
};

NoReferredCommunities.defaultProps = {};

export default NoReferredCommunities;
