import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './Toast.style';
import clsx from 'clsx';
import CircleChecked from '../Icons/basic/CircleChecked';
import CircleInfo from '../Icons/basic/CircleInfo';
import X from '../Icons/basic/X';

const Toast = ({ className, children, type, onClose, onMore }) => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(true);
  const toastClasses = clsx(className, classes.toast_container, classes[type]);

  if (!isVisible) {
    return '';
  }

  const handleCloseClick = () => {
    setIsVisible(false);
    onClose();
  };

  const handleMoreClick = () => {
    onMore();
  };

  return (
    <div className={toastClasses}>
      <div className={classes.message}>
        {type === 'success' ? <CircleChecked /> : <CircleInfo />}
        {children}
      </div>
      {onClose && (
        <div className={classes.close_icon} onClick={handleCloseClick}>
          <X />
        </div>
      )}
      {onMore && (
        <div className={classes.more_container} onClick={handleMoreClick}>
          more...
        </div>
      )}
    </div>
  );
};

Toast.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  onMore: PropTypes.func,
  type: PropTypes.oneOf(['error', 'warning', 'success', 'informative']),
};

Toast.defaultProps = {
  type: 'success',
};

export default Toast;
