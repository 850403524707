import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useStyles } from './DateTimePickerModal.style';
import { DatePicker as MaterialDatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import { withLabels } from '../../context/LabelContext';
import Modal from '../Modal/Modal';
import CalendarDates from '../Icons/time/CalendarDates';
import ArrowLeft from '../Icons/arrow/ArrowLeft';
import ArrowRight from '../Icons/arrow/ArrowRight';
import SmartTooltip from '../SmartTooltip/SmartTooltip';
import { addTimeInHours } from '../../utils/dateFormat';
import {
  timezoneType,
  timezoneItems,
  getMSTzone,
  getMDTzone,
} from '../../utils/timezoneUtils';
import Divider from '../Divider/Divider';
import Dropdown from '../Dropdown/Dropdown';
import Button from '../Button/Button';
import Typography from '../Typography/Typography';
import CircleChecked from '../Icons/basic/CircleChecked';
import Toast from '../Toast/Toast';
import ContainerItem from '../ContainerItem/ContainerItem';
import Container from '../Container/Container';
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { zonedTimeToUtc, utcToZonedTime, format } from 'date-fns-tz';
import { isAfter, toDate } from 'date-fns';
import dailyTimes from './times';

const DateTimePickerModal = ({
  labels,
  className,
  isOpen,
  onClose,
  title,
  disablePast,
  onSave,
}) => {
  const INPUT_DATE_FORMAT = 'MMM d, y (EEE)';
  const dstItems = timezoneType.map((i) => ({
    label: i,
    value: i,
  }));
  const classes = useStyles();

  const today = new Date();
  const [currentTime, setCurrentTime] = useState(today.getTime());
  const [times, setDailyTimes] = useState(null);
  const [isToday, setIsToday] = useState(true);
  const [isValid, setFormValid] = useState(false);
  const [hasError, setDateError] = useState(false);
  const [hasNoTimePreference, setNoTimePref] = useState(false);

  const [selectedDate, setSelectedDate] = useState(today);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedTimeZone, setSelectedTimeZone] = useState(timezoneItems[0]);
  const [showDST, setShowDST] = useState(false);
  const [selectedTimeZoneType, setSelectedTimeZoneType] = useState(dstItems[0]);
  const [dateTimeObj, setDateTimeObj] = useState(
    zonedTimeToUtc(currentTime, timezoneItems[0].value),
  );

  const renderDay = (
    day,
    selectedDateFromComponent,
    dayInCurrentMonth,
    dayComponent,
  ) => {
    if (disablePast && dayComponent.props.disabled) {
      return (
        <SmartTooltip
          title={labels.CANT_SELECT_PAST_DATES}
          placement="top"
          cursor="not-allowed"
        >
          {dayComponent}
        </SmartTooltip>
      );
    }
    if (
      selectedDate === null &&
      dayComponent.props.current &&
      dayComponent.props.selected
    ) {
      return React.cloneElement(dayComponent, {
        className: clsx('MuiPickersDay-day', 'MuiPickersDay-current'),
      });
    }
    return dayComponent;
  };

  const onDateChange = (date) => {
    setSelectedDate(date);
    setDateTimeObj(date);
    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      setIsToday(true);
    } else {
      setIsToday(false);
    }
  };
  const onTimeZoneSelect = (timezone) => {
    setSelectedTimeZone(timezone);
    if (timezone.abbr === 'MDT' || timezone.abbr === 'MST') {
      setShowDST(true);
    } else {
      setShowDST(false);
    }
  };

  const checkPastTimes = useMemo(
    ({ date = selectedDate, timezone = selectedTimeZone } = {}) => {
      if (date && timezone) {
        const now = new Date();
        setCurrentTime(now.getTime());
        const zonedTime = utcToZonedTime(now.toISOString(), timezone.value);
        const updatedTimes = dailyTimes.map((time) => {
          const timeParts = time.value.split(' ');
          let [hours, minutes] = timeParts[0].split(':');
          const updatedTime = toDate(now);
          updatedTime.setHours(hours);
          updatedTime.setMinutes(minutes);

          if (isAfter(zonedTime, updatedTime)) {
            time['isPast'] = true;
          } else {
            time['isPast'] = false;
          }
          return time;
        });
        return updatedTimes;
      }
    },
    [selectedDate, selectedTimeZone],
  );

  const onTimeZoneTypeSelect = (tzType) => {
    let timezone = selectedTimeZone;
    if (showDST) {
      setSelectedTimeZoneType(tzType);
      timezone = tzType.value === 'Daylight' ? getMDTzone : getMSTzone;
      onTimeZoneSelect(timezone);
    }
  };
  const onTimeSelect = (time) => {
    const timeParts = time.split(' ');
    let [hours, minutes] = timeParts[0].split(':');
    if (timeParts[1] === 'PM' && hours !== '12') {
      hours = (parseInt(hours) + 12).toString();
    }

    const newTime = selectedDate.setHours(hours, minutes);
    const updatedTime = toDate(newTime, {
      timeZone: selectedTimeZone.value,
    });
    setSelectedTime(time);
    setDateTimeObj(zonedTimeToUtc(updatedTime, selectedTimeZone.value));
  };

  const handleNoTimePreference = (evt) => {
    const checked = evt.target.checked;
    setNoTimePref(checked);
    const now = new Date().getTime();
    setCurrentTime(now);

    if (checked === true) {
      const newTime = addTimeInHours(currentTime, 2);
      setDateTimeObj(newTime);
    } else {
      setSelectedTime(null);
    }
  };
  const handleSubmit = async () => {
    await onSave(dateTimeObj.getTime())
      .then(() => {
        onClose();
      })
      .catch((error) => {
        setFormValid(false);
        setDateError(error);
      });
  };
  const ShowMessage = () => {
    if (!hasError) {
      return '';
    } else {
      return (
        <div id="dateTime_error">
          <Toast type="error">
            <Typography>{labels.TIME_ERROR}</Typography>
          </Toast>
        </div>
      );
    }
  };
  useEffect(() => {
    if (isToday) setDailyTimes(checkPastTimes);
  }, [selectedTimeZone, selectedDate, isToday, checkPastTimes]);

  useEffect(() => {
    setFormValid(
      (selectedTime || hasNoTimePreference === true) &&
        selectedDate &&
        selectedTimeZone,
    );
  }, [selectedDate, selectedTime, selectedTimeZone, hasNoTimePreference]);

  return (
    <div className={clsx(classes.date_time_picker_modal_container, className)}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        color="cosmicCobalt2"
        noBodyPadding
        icon={<CalendarDates />}
      >
        <div>
          <div className={classes.modal_body}>
            <ShowMessage />
            <div className={classes.date_picker_container}>
              <MaterialDatePicker
                value={selectedDate}
                onChange={onDateChange}
                variant="static"
                disablePast={disablePast}
                leftArrowIcon={<ArrowLeft className={classes.arrow} />}
                rightArrowIcon={<ArrowRight className={classes.arrow} />}
                disableToolbar
                renderDay={renderDay}
                format={INPUT_DATE_FORMAT}
              />
              <div className={classes.selected_date}>
                {!selectedDate && labels.PLEASE_SELECT_A_DATE}
                {selectedDate && (
                  <Fragment>
                    {labels.YOU_HAVE_SELECTED}
                    <b>{format(selectedDate, 'MMMM dd, yyyy (EEE)')}</b>
                  </Fragment>
                )}
              </div>
            </div>
            <Divider />
          </div>
          <div className={classes.timezone_container}>
            <Container>
              <ContainerItem xs={7}>
                <Dropdown
                  className={classes.timezones}
                  items={timezoneItems}
                  label={labels.FAMILY_PREFERRED_TIME_ZONE}
                  selectedItem={
                    showDST && selectedTimeZoneType === 'Daylight'
                      ? [getMSTzone]
                      : selectedTimeZone
                  }
                  onChange={onTimeZoneSelect}
                  emptyLabel={labels.SELECT_TIME_ZONE}
                  isMandatory={true}
                />
              </ContainerItem>
              <ContainerItem xs={5}>
                <Dropdown
                  xs={6}
                  className={classes.dst}
                  items={dstItems}
                  label="Standard or Daylight Time"
                  selectedItem={selectedTimeZoneType}
                  onChange={onTimeZoneTypeSelect}
                  disabled={!showDST}
                />
              </ContainerItem>
            </Container>
            <div className={classes.times_section}>
              <Typography color="eerieBlack5" level="small">
                {labels.MORNING_TIMES}
              </Typography>
              <div className={classes.times_container}>
                {times &&
                  times
                    .filter((time) => time.isMorning)
                    .map((time, key) => (
                      <Button
                        className={clsx(
                          classes.time_btn,
                          time.value === selectedTime && classes.active,
                        )}
                        type={
                          hasNoTimePreference || (isToday && time.isPast)
                            ? 'outlinedDisabled'
                            : time.value === selectedTime
                            ? 'primary'
                            : 'outlined'
                        }
                        size="small"
                        key={key}
                        onClick={() => onTimeSelect(time.value)}
                      >
                        {time.label}
                      </Button>
                    ))}
              </div>
            </div>
            <div className={classes.times_section}>
              <Typography color="eerieBlack5" level="small">
                {labels.AFTERNOON_TIMES}
              </Typography>
              <div className={classes.times_container}>
                {times &&
                  times
                    .filter((time) => !time.isMorning)
                    .map((time, key) => (
                      <Button
                        className={clsx(
                          classes.time_btn,
                          time.value === selectedTime && classes.active,
                        )}
                        type={
                          hasNoTimePreference || (isToday && time.isPast)
                            ? 'outlinedDisabled'
                            : time.value === selectedTime
                            ? 'primary'
                            : 'outlined'
                        }
                        size="small"
                        key={key}
                        onClick={() => onTimeSelect(time.value)}
                      >
                        {time.label}
                      </Button>
                    ))}
              </div>
            </div>
            <div className={clsx(classes.no_timepref)}>
              <Divider />
              <FormControlLabel
                className={classes.no_timepref}
                control={
                  <Checkbox
                    icon={<CircleUnchecked fontSize="small" />}
                    checkedIcon={<CircleCheckedFilled fontSize="small" />}
                    name="hasNoTimePreference"
                    color="primary"
                    checked={hasNoTimePreference}
                    onChange={handleNoTimePreference}
                  />
                }
                label={labels.NO_TIME_PREF}
              />
            </div>
          </div>
          <div className={classes.modal_footer}>
            <Button type="light" size="small" onClick={onClose}>
              {labels.CANCEL}
            </Button>
            <Button
              startIcon={<CircleChecked />}
              type={isValid ? 'secondary' : 'disabled'}
              size="small"
              onClick={handleSubmit}
            >
              {labels.SAVE}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

DateTimePickerModal.propTypes = {
  labels: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  disablePast: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

DateTimePickerModal.defaultProps = {
  className: '',
  title: '',
  isOpen: false,
  disablePast: false,
  onClose: () => {},
  onSave: () => {},
};

export default withLabels(DateTimePickerModal);
