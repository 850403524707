import { makeStyles } from '../Theme/Theme';

const useBackgroundColorStyles = makeStyles((theme) => {
  return {
    eerieBlack1: {
      backgroundColor: theme.palette.dark.eerieBlack1,
      color: theme.palette.light.white,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.light.white,
        fill: theme.palette.light.white,
      },
    },
    eerieBlack2: {
      backgroundColor: theme.palette.dark.eerieBlack2,
      color: theme.palette.light.white,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.light.white,
        fill: theme.palette.light.white,
      },
    },
    eerieBlack3: {
      backgroundColor: theme.palette.dark.eerieBlack3,
      color: theme.palette.light.white,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.light.white,
        fill: theme.palette.light.white,
      },
    },
    eerieBlack4: {
      backgroundColor: theme.palette.dark.eerieBlack4,
      color: theme.palette.light.white,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.light.white,
        fill: theme.palette.light.white,
      },
    },
    eerieBlack5: {
      backgroundColor: theme.palette.dark.eerieBlack5,
      color: theme.palette.light.white,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.light.white,
        fill: theme.palette.light.white,
      },
    },
    disabled: {
      backgroundColor: theme.palette.dark.disabled,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    divider: {
      backgroundColor: theme.palette.light.divider,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    platinum1: {
      backgroundColor: theme.palette.light.platinum1,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    platinum2: {
      backgroundColor: theme.palette.light.platinum2,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    platinum3: {
      backgroundColor: theme.palette.light.platinum3,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    platinum4: {
      backgroundColor: theme.palette.light.platinum4,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    platinum5: {
      backgroundColor: theme.palette.light.platinum5,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    white: {
      backgroundColor: theme.palette.light.white,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    cosmicCobalt1: {
      backgroundColor: theme.palette.neutral.cosmicCobalt1,
      color: theme.palette.light.platinum5,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.light.platinum5,
        fill: theme.palette.light.platinum5,
      },
    },
    cosmicCobalt2: {
      backgroundColor: theme.palette.neutral.cosmicCobalt2,
      color: theme.palette.light.platinum5,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.light.platinum5,
        fill: theme.palette.light.platinum5,
      },
    },
    cosmicCobalt3: {
      backgroundColor: theme.palette.neutral.cosmicCobalt3,
      color: theme.palette.light.platinum5,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.light.platinum5,
        fill: theme.palette.light.platinum5,
      },
    },
    cosmicCobalt4: {
      backgroundColor: theme.palette.neutral.cosmicCobalt4,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    cosmicCobalt5: {
      backgroundColor: theme.palette.neutral.cosmicCobalt5,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    dropdown: {
      backgroundColor: theme.palette.neutral.dropdown,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    regalia1: {
      backgroundColor: theme.palette.care.regalia1,
      color: theme.palette.light.platinum5,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.light.platinum5,
        fill: theme.palette.light.platinum5,
      },
    },
    regalia2: {
      backgroundColor: theme.palette.care.regalia2,
      color: theme.palette.light.platinum5,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.light.platinum5,
        fill: theme.palette.light.platinum5,
      },
    },
    regalia3: {
      backgroundColor: theme.palette.care.regalia3,
      color: theme.palette.light.platinum5,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.light.platinum5,
        fill: theme.palette.light.platinum5,
      },
    },
    regalia4: {
      backgroundColor: theme.palette.care.regalia4,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    regalia5: {
      backgroundColor: theme.palette.care.regalia5,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    wintergreenDream1: {
      backgroundColor: theme.palette.positive.wintergreenDream1,
      color: theme.palette.light.platinum5,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.light.platinum5,
        fill: theme.palette.light.platinum5,
      },
    },
    wintergreenDream2: {
      backgroundColor: theme.palette.positive.wintergreenDream2,
      color: theme.palette.light.platinum5,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.light.platinum5,
        fill: theme.palette.light.platinum5,
      },
    },
    wintergreenDream3: {
      backgroundColor: theme.palette.positive.wintergreenDream3,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    wintergreenDream4: {
      backgroundColor: theme.palette.positive.wintergreenDream4,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    wintergreenDream5: {
      backgroundColor: theme.palette.positive.wintergreenDream5,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    smokyTopaz1: {
      backgroundColor: theme.palette.correction.smokyTopaz1,
      color: theme.palette.light.platinum5,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.light.platinum5,
        fill: theme.palette.light.platinum5,
      },
    },
    smokyTopaz2: {
      backgroundColor: theme.palette.correction.smokyTopaz2,
      color: theme.palette.light.platinum5,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.light.platinum5,
        fill: theme.palette.light.platinum5,
      },
    },
    smokyTopaz3: {
      backgroundColor: theme.palette.correction.smokyTopaz3,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    smokyTopaz4: {
      backgroundColor: theme.palette.correction.smokyTopaz4,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    smokyTopaz5: {
      backgroundColor: theme.palette.correction.smokyTopaz5,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    hotCinnamon1: {
      backgroundColor: theme.palette.miscellaneous1.hotCinnamon1,
      color: theme.palette.light.platinum5,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.light.platinum5,
        fill: theme.palette.light.platinum5,
      },
    },
    hotCinnamon2: {
      backgroundColor: theme.palette.miscellaneous1.hotCinnamon2,
      color: theme.palette.light.platinum5,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.light.platinum5,
        fill: theme.palette.light.platinum5,
      },
    },
    hotCinnamon3: {
      backgroundColor: theme.palette.miscellaneous1.hotCinnamon3,
      color: theme.palette.light.platinum5,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.light.platinum5,
        fill: theme.palette.light.platinum5,
      },
    },
    hotCinnamon4: {
      backgroundColor: theme.palette.miscellaneous1.hotCinnamon4,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    hotCinnamon5: {
      backgroundColor: theme.palette.miscellaneous1.hotCinnamon5,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    fieldDrab1: {
      backgroundColor: theme.palette.miscellaneous2.fieldDrab1,
      color: theme.palette.light.platinum5,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.light.platinum5,
        fill: theme.palette.light.platinum5,
      },
    },
    fieldDrab2: {
      backgroundColor: theme.palette.miscellaneous2.fieldDrab2,
      color: theme.palette.light.platinum5,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.light.platinum5,
        fill: theme.palette.light.platinum5,
      },
    },
    fieldDrab3: {
      backgroundColor: theme.palette.miscellaneous2.fieldDrab3,
      color: theme.palette.light.platinum5,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.light.platinum5,
        fill: theme.palette.light.platinum5,
      },
    },
    fieldDrab4: {
      backgroundColor: theme.palette.miscellaneous2.fieldDrab4,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    fieldDrab5: {
      backgroundColor: theme.palette.miscellaneous2.fieldDrab5,
      color: theme.palette.dark.eerieBlack1,
      '& h1,h2,h3,h4,p,svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
  };
});

export { useBackgroundColorStyles };
