import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from './MonthAndYearPicker.style';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ChevronTop from '../Icons/arrow/ChevronTop';
import ChevronBottom from '../Icons/arrow/ChevronBottom';
import Typography from '../Typography/Typography';
import Radio from '../Radio/Radio';
import Dropdown from '../Dropdown/Dropdown';

const MonthAndYearPicker = ({
  labels,
  onChange,
  disabled,
  className,
  selectedMonth,
  selectedYear,
  label,
  basicInformation,
}) => {
  const selfLeadsAudicences = [
    'self_not_urgent_veteran_financially_unqualified',
    'self_urgent_financially_unqualified',
    'self_not_urgent_financially_qualified',
  ];

  let isSelfLead = selfLeadsAudicences.some((selfAudience) =>
    basicInformation.audienceKey?.includes(selfAudience),
  );
  const NO_RUSH_DATE_OPTION = '2';
  const UNDECIDED_DATE_OPTION = '2';
  const DECIDED_DATE_OPTION = '1';
  const monthAndYearOption = { label: ``, value: DECIDED_DATE_OPTION };
  const undecidedOrNoRushOption = {
    label: isSelfLead ? labels.NO_RUSH : labels.UNDECIDED,
    value: isSelfLead ? NO_RUSH_DATE_OPTION : UNDECIDED_DATE_OPTION,
  };
  const [dateOption, setDateOption] = useState(
    selectedMonth && selectedYear ? DECIDED_DATE_OPTION : UNDECIDED_DATE_OPTION,
  );
  const [year, setYear] = useState(
    selectedYear === undefined ? labels.YEAR : selectedYear,
  );
  const [month, setMonth] = useState(
    selectedMonth === undefined ? labels.MONTH : selectedMonth,
  );

  const classes = useStyles();
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const selectClasses = clsx([
    className,
    classes.month_and_year_container,
    disabled && classes.disabled,
  ]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    if (dateOption === DECIDED_DATE_OPTION) {
      onChange({ month, year });
    } else {
      onChange({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month, dateOption, isOpen]);

  const getMonth = () => {
    return labels.MONTHS.map((month) => {
      return { label: month.substring(0, 3), value: month.substring(0, 3) };
    });
  };

  const getYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i <= currentYear + 15; i++) {
      years.push({ label: i.toString(), value: i.toString() });
    }
    return years;
  };

  const getSelectedText = () => {
    if (dateOption === DECIDED_DATE_OPTION) {
      if (month && year) {
        return `${month} ${year}`;
      }
      return labels.SELECT_DATE;
    }

    return isSelfLead ? labels.NO_RUSH : labels.UNDECIDED;
  };

  const onMonthChange = (month) => {
    setDateOption(DECIDED_DATE_OPTION);
    setMonth(month);
  };

  const onYearChange = (year) => {
    setDateOption(DECIDED_DATE_OPTION);
    setYear(year);
  };

  return (
    <div className={selectClasses} ref={wrapperRef}>
      <div className={classes.label}>{label}</div>
      <div
        className={classes.select}
        tabIndex="0"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>{getSelectedText()}</div>
        <div className={classes.icon}>
          {isOpen ? <ChevronTop /> : <ChevronBottom />}
        </div>
      </div>
      {isOpen && !disabled && (
        <div className={classes.menu}>
          <div className={clsx(classes.items_container, 'items_container')}>
            <Typography
              className={classes.group_name}
              level="small"
              color="eerieBlack3"
            >
              {labels.ESTIMATED_DATE}
            </Typography>
            <div>
              <div className={classes.year_and_month_container}>
                <Radio
                  className={classes.radio_container}
                  items={[monthAndYearOption]}
                  isRow={false}
                  name="month_and_year_filter"
                  selected={{ value: dateOption }}
                  onChange={(item) => setDateOption(item.value)}
                />
                <div className={classes.dropdowns_container}>
                  <Dropdown
                    emptyLabel="Month"
                    className={clsx(classes.select_option, 'select_option')}
                    items={getMonth()}
                    onChange={(item) => onMonthChange(item.value)}
                    selectedItem={{ value: month, label: month }}
                  />
                  <Dropdown
                    emptyLabel="Year"
                    className={clsx(classes.select_option, 'select_option')}
                    items={getYears()}
                    onChange={(item) => onYearChange(item.value)}
                    selectedItem={{ value: year, label: year }}
                  />
                </div>
              </div>
              <Radio
                className={classes.radio_container}
                items={[undecidedOrNoRushOption]}
                isRow={false}
                name="month_and_year_filter"
                selected={{ value: dateOption }}
                onChange={(item) => setDateOption(item.value)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

MonthAndYearPicker.propTypes = {
  labels: PropTypes.object,
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  selectedMonth: PropTypes.string,
  selectedYear: PropTypes.string,
  label: PropTypes.string,
  basicInformation: PropTypes.object,
};

MonthAndYearPicker.defaultProps = {
  disabled: false,
  className: '',
  onChange: () => {},
};

export default MonthAndYearPicker;
