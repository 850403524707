import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => ({
  toast_container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
  },
  error: {
    backgroundColor: theme.palette.correction.smokyTopaz5,
    border: `1px solid ${theme.palette.correction.smokyTopaz3}`,
    '& svg': {
      color: theme.palette.correction.smokyTopaz1,
    },
  },
  warning: {
    backgroundColor: theme.palette.miscellaneous2.fieldDrab5,
    border: `1px solid ${theme.palette.miscellaneous2.fieldDrab3}`,
    '& svg': {
      color: theme.palette.miscellaneous1.hotCinnamon2,
    },
  },
  success: {
    backgroundColor: theme.palette.positive.wintergreenDream5,
    border: `1px solid ${theme.palette.positive.wintergreenDream2}`,
    '& svg': {
      color: theme.palette.positive.wintergreenDream2,
    },
  },
  informative: {
    backgroundColor: theme.palette.neutral.dropdown,
    border: `1px solid ${theme.palette.neutral.cosmicCobalt3}`,
    '& svg': {
      color: theme.palette.neutral.cosmicCobalt3,
    },
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      alignSelf: 'flex-start',
      marginRight: '8px',
      fontSize: '22px',
      minWidth: '22px',
    },
  },
  close_icon: {
    cursor: 'pointer',
    display: 'flex',
    '& svg': {
      color: theme.palette.dark.eerieBlack1,
      fontSize: '16px',
    },
  },
  more_container: {
    cursor: 'pointer',
    color: theme.palette.correction.smokyTopaz1,
  },
}));

export { useStyles };
