import { makeStyles } from '../../Theme/Theme';

const useStyles = makeStyles((theme) => ({
  location_search_container: {
    minWidth: '280px',
    '& svg': {
      color: theme.palette.dark.disabled,
      fill: theme.palette.dark.disabled,
    },
    [theme.breakpoints.down(1024)]: {
      zIndex: '1',
    },
  },
  delete_icon: {
    cursor: 'pointer',
    '& svg': {
      verticalAlign: 'middle',
    },
  },
  menu: {
    position: 'relative',
    width: '100%',
  },
  items_container: {
    zIndex: '1',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.neutral.cosmicCobalt5}`,
    backgroundColor: theme.palette.light.white,
    textAlign: 'left',
  },
  group_item: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '10px 16px',
    '&:hover': {
      backgroundColor: theme.palette.neutral.dropdown,
    },
    '& svg': {
      fontSize: '24px',
      color: theme.palette.dark.eerieBlack1,
      marginRight: '12px',
    },
  },
  group_name: {
    padding: '10px 16px',
  },
}));

export { useStyles };
