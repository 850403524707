import React from 'react';
import Card from 'common/Card/Card';
import FinancialCriteria from 'common/Graphics/FinancialCriteria';
import Button from 'common/Button/Button';
import { useStyles } from './FinancialCriteriaDefaultPage.style';
import Typography from 'common/Typography/Typography';
import PropTypes from 'prop-types';
import Pulse from 'common/Icons/basic/Pulse';
const FinancialCriteriaDefaultPage = ({
  labels,
  toggleFinancialCriteriaDefaultPage,
  toggleFinancialCriteriaEditPage,
}) => {
  const classes = useStyles();
  const changingToggles = () => {
    toggleFinancialCriteriaDefaultPage(false);
    toggleFinancialCriteriaEditPage(true);
  };
  return (
    <Card
      className={classes.container}
      title={labels.FINANCIAL_CRITERIA}
      icon={<Pulse />}
    >
      <div className={classes.icon}>
        <FinancialCriteria />
        <Typography level="body" bold>
          {labels.EMPTY_KEYNOTE_HEADER}
        </Typography>
        <div className={classes.empty_medical_background}>
          <Typography>
            {labels.FINANCIAL_CRITERIA_EMPTY_KEYNOTE_DESCRIPTION_PART1}
          </Typography>
          <Typography>
            {labels.FINANCIAL_CRITERIA_EMPTY_KEYNOTE_DESCRIPTION_PART2}
          </Typography>
          <Typography>
            {labels.FINANCIAL_CRITERIA_EMPTY_KEYNOTE_DESCRIPTION_PART3}
          </Typography>
        </div>
        <Button
          className={classes.medical_background_assessment_button}
          onClick={changingToggles}
        >
          {labels.COMPLETE_ASSESSMENT_NOW}
        </Button>
      </div>
    </Card>
  );
};

FinancialCriteriaDefaultPage.propTypes = {
  labels: PropTypes.object.isRequired,
  toggleFinancialCriteriaDefaultPage: PropTypes.func.isRequired,
  toggleFinancialCriteriaEditPage: PropTypes.func.isRequired,
};

export default FinancialCriteriaDefaultPage;
