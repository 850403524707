import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  selected_community: {
    border: '1px solid',
    borderColor: theme.palette.neutral.cosmicCobalt3,
  },
  communities_table_row_container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  communities_table_row_xs_container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  communities_header: {
    flexGrow: 0,
    width: '725px',
  },
  distance_container: {
    width: '200px',
    flexGrow: 0,
  },
  left_container: {
    display: 'flex',
    flex: '2',
  },
  advisory_container: {
    flexBasis: '100%',
    justifyContent: 'flex-end',
    marginLeft: '180px',
    marginTop: theme.spacing(2),
  },
  advisory_message: {
    fontSize: '14px',
  },
  distance_header: {
    flexGrow: 0,
    width: '200px',
  },
  communities_table_lg_container: {
    display: 'flex',
  },
  communities_table_row_lg_container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  communities_table_sm_container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 201px)',
  },
  table_container: {
    width: '50%',
    height: 'calc(100vh - 201px)',
    overflow: 'auto',
    padding: '24px 16px 16px 24px',
  },
  table_container_xs: {
    width: '100%',
    height: 'calc(100vh - 201px)',
    overflow: 'auto',
    padding: '24px 16px 16px 24px',
  },
  table_container_sm_top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    padding: '24px 24px 16px 24px',
    flexWrap: 'wrap',
  },
  table_container_sm_bottom: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 10px 16px 24px',
    flexWrap: 'wrap',
    overflow: 'auto',
  },
  table_container_sm_empty: {
    padding: '0px 24px 16px 24px',
  },
  table_container_sm_message: {
    padding: '0 24px 0 24px',
  },
  map_container_sm: {
    height: 'calc(100vh - 300px)',
    padding: '0px 24px 16px 24px',
  },
  disclosure_message: {
    marginBottom: '24px',
  },
  save_community_error: {
    marginBottom: '24px',
  },
  save_community_error_sm_list: {
    margin: '0px 24px 24px 24px',
  },
  map_container: {
    width: '50%',
  },
  filters_container: {
    display: 'flex',
    marginBottom: '19px',
    alignItems: 'baseline',
    flexWrap: 'wrap',
  },
  count_container: {
    display: 'flex',
    marginBottom: '19px',
    alignItems: 'baseline',
    flexWrap: 'wrap',
  },
  clear_all: {
    color: theme.palette.neutral.cosmicCobalt3,
    fontSize: '12px',
    marginLeft: '8px',
  },
  tag: {
    marginLeft: '8px',
    marginBottom: '8px',
  },
  referred_communities_title: {
    marginBottom: '16px',
  },
  communities_count: {
    padding: theme.spacing(3, 3, 2, 3),
  },
  break: {
    flexBasis: '100%',
    height: '0',
  },
  last_container: {
    display: 'flex',
    flexBasis: '100%',
    justifyContent: 'flex-end',
    marginTop: '10px',
    alignItems: 'baseline',
  },
  filter_content: {
    display: 'flex',
    marginLeft: '180px',
    marginTop: '16px',
    justifyContent: 'flex-end',
  },
  amenity_icon: {
    '& > svg': {
      fontSize: '24px',
      marginLeft: '15px',
    },
  },
  error_icon: {
    width: '97px',
    height: '97px',
  },
}));

export { useStyles };
