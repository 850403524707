import { PhoneContext } from '@aplaceformom/owl-connect-client';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Container from 'common/Container/Container';
import ContainerItem from 'common/ContainerItem/ContainerItem';
import NoActiveCall from 'common/Graphics/other/NoActiveCall';
import Typography from 'common/Typography/Typography';
import Loading from 'components/Loading/Loading';
import { getCallType } from 'config/callStates.config';
import { withLabels } from 'context/LabelContext';
import { useAutoCloseFive9 } from 'hooks/useAutoCloseFive9';
import { useInquiryIdFromActiveContact } from 'hooks/useInquiryIdFromActiveContact';
import { enteredWaitingScreen } from 'stateManagement/callcenter/formChiclets/actions/formChicletsActions';
import { selectDisposition } from 'stateManagement/callcenter/formChiclets/reducers/formChicletsReducer';
import {
  createInquiryRequest,
  updateCallId,
} from 'stateManagement/callcenter/inquiry/actions/inquiryActions';
import {
  selectCallId,
  selectCreatedInquiry,
  selectInquiry,
  selectIsInboundCallFinished,
  selectLatestInquiryId,
} from 'stateManagement/callcenter/inquiry/reducers/inquiryReducer';
import CallBar from '../Inquiry/CallBar/CallBar';
import { callBarScreens } from '../Inquiry/CallBar/callBarScreenEnum';
import { useStyles } from './WaitingScreen.style';
import allCallStates, { callTypes } from 'config/callStates.config';

const WaitingScreen = ({ labels, flags }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { inquiry } = useSelector(selectInquiry);
  const latestInquiryId = useSelector(selectLatestInquiryId);
  const isInboundCallFinished = useSelector(selectIsInboundCallFinished);
  const {
    callPreview,
    activeCampaigns,
    callData,
    agentState,
    setDisposition,
    dispositions,
    isAuthenticated,
    skills,
    callState,
  } = useContext(PhoneContext);

  const { number, dnis } = callData;
  const callId = useSelector(selectCallId);

  const {
    isInboundCallInquiryCreating,
    isInboundCallInquiryCreated,
    inboundCallInquiry,
  } = useSelector(selectCreatedInquiry);
  const disposition = useSelector(selectDisposition);
  const [dispositionsModalOpen, setDispositionsModalOpen] = useState(false);
  const callType = getCallType(
    activeCampaigns,
    callData,
    callPreview,
    callState,
  );

  useAutoCloseFive9(isAuthenticated, skills, dispatch);

  useInquiryIdFromActiveContact(
    inquiry,
    callPreview,
    latestInquiryId,
    callType,
    flags.allowDisabledChiclets,
  );

  useEffect(() => {
    dispatch(enteredWaitingScreen());
  }, [dispatch]);

  useEffect(() => {
    if (disposition && dispositionsModalOpen) {
      const dispositionsModalOpenDisposition = dispositions.find(
        (d) => d.name === disposition,
      );
      setDisposition(
        dispositionsModalOpenDisposition.id,
        dispositionsModalOpenDisposition.name,
      );
      setDispositionsModalOpen(false);
    }
  }, [disposition, dispositionsModalOpen, dispositions, setDisposition]);

  if (isInboundCallInquiryCreating) {
    return <Loading />;
  }
  if (
    callType === callTypes.INBOUND &&
    callId !== callData?.callId &&
    !isInboundCallInquiryCreating &&
    !isInboundCallInquiryCreated &&
    !isInboundCallFinished &&
    callState !== null &&
    callState !== allCallStates.FINISHED.key
  ) {
    dispatch(createInquiryRequest(number, dnis));
    dispatch(updateCallId(callData?.callId));
  }

  if (callPreview?.state === 'PREVIEW' || callPreview?.state === 'ON_CALL') {
    return (
      <Redirect to={`/callcenter/inquiry/${callPreview.contact.inquiryId}`} />
    );
  }

  if (
    callType === callTypes.INBOUND &&
    agentState.name === 'Ready' &&
    !isInboundCallFinished &&
    isInboundCallInquiryCreated &&
    callState !== null
  ) {
    const url = `/callcenter/inquiry/${inboundCallInquiry.inquiryId}`;
    return <Redirect to={url} />;
  }

  return (
    <div className={classes.call_wrapper_container}>
      <CallBar screen={callBarScreens.WAITING} />
      <div className={classes.body}>
        <Container container spacing={2}>
          <ContainerItem item xs={12} sm={12} xl={12}>
            <div className={classes.container} data-testid="waiting-call">
              <div className={classes.content}>
                <NoActiveCall />
                <Typography bold level="large" className={classes.title}>
                  {labels.WAITING_FOR_CALL_TO_BE_CONNECTED}
                </Typography>
                <Typography>
                  {labels.YOU_WILL_RECEIVE_AN_INBOUND_OR_OUTBOUND}
                </Typography>
              </div>
            </div>
          </ContainerItem>
        </Container>
      </div>
    </div>
  );
};

WaitingScreen.propTypes = {
  labels: PropTypes.object,
  flags: PropTypes.shape({
    allowDisabledChiclets: PropTypes.bool,
  }),
};
export default withLDConsumer()(withLabels(WaitingScreen));
