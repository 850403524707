import { makeStyles } from '../../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    t_head: {
      display: 'table-header-group',
      '& tr': {
        borderBottom: `1px solid ${theme.palette.dark.disabled}`,
      },
    },
  };
});

export { useStyles };
