import gql from 'graphql-tag';

export default gql`
  query($familyFileId: Int!) {
    findFamilyFileById(id: $familyFileId) {
      familyFileId
      oneId
      notes {
        note
        createdAt
        noteType {
          noteTypeCode
        }
        createdBy {
          firstName
          lastName
        }
      }
      audienceKey
      salesPhase
      professionalSourceContactId
      professionalSourceOrganizationName
      residents {
        profileFormDataId
        residentId
        careTypes {
          careTypeId
          code
          name
        }
        profileData {
          firstName
          lastName
          address1
          address2
          city
        }
      }
      contacts {
        profileFormDataId
        contactId
        profileData {
          formDataId
          firstName
          lastName
          isPrimary
          relationToResidentId
          relationToResident
          primaryPhone
          emailAddress
          secondaryPhone
          secondaryPhoneType
          primaryPhoneType
          address1
          address2
          isInquirer
          allowMailing
          allowContact
          postalCode
          city
          state
          country
          phones {
            isPrimaryForCategory
            areaCode
            localNumber
            subCategoryCode
            countryCode
          }
          emailAddresses {
            isPrimary
            emailAddress
          }
        }
      }
      questionsData {
        formDataId
        age
      }
      leadData {
        formDataId
        doNotCall
        expectedMoveDate
        likelihoodToMovePct
      }
      familyDesiredLocations {
        familyDesiredLocationId
        isPrimary
        city
        state
        zip
      }
    }
  }
`;
