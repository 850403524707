import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './ContactInfo.style';
import TextInput from 'common/TextInput/TextInput';
import Mail from 'common/Icons/basic/Mail';
import CallPhone from 'common/Icons/call/CallPhone';
import Delete from 'common/Icons/basic/Delete';
import Typography from 'common/Typography/Typography';
import { parsePhoneNumber } from 'libphonenumber-js';
import { formatPhoneWithParenthesis } from 'utils/phoneFormat';
import { useSelector } from 'react-redux';
import { selectManualInquiryState } from 'stateManagement/callcenter/formChiclets/reducers/formChicletsReducer';
import clsx from 'clsx';

const ContactInfo = ({
  oneId,
  firstName,
  lastName,
  email,
  phone,
  answer,
  errors,
  labels,
  onChange,
}) => {
  const classes = useStyles();
  const manualInquiryState = useSelector(selectManualInquiryState);
  const tryParsePhoneInUSNationalFormat = (phone) => {
    let cleanedPhone = '';
    try {
      cleanedPhone = phone
        ? parsePhoneNumber(phone, 'US').formatNational()
        : '';
    } catch {
      return phone;
    }
    return cleanedPhone;
  };
  const primaryPhone =
    answer.userInputValues && answer.userInputValues.phone
      ? answer.userInputValues.phone
      : phone || '';
  let cleanPhone = tryParsePhoneInUSNationalFormat(primaryPhone);

  const [contact, setContact] = useState({
    oneId: oneId || '',
    firstName: firstName || '',
    lastName: lastName || '',
    email: email || '',
    phone: cleanPhone,
  });
  const [contactErrors, setContactErrors] = useState(errors);

  answer.userInputValues = {
    oneId,
    firstName,
    lastName,
    email,
    phone: cleanPhone,
  };

  useEffect(() => {
    const cleanedPhone = tryParsePhoneInUSNationalFormat(phone);
    setContact({
      oneId: oneId || contact.oneId,
      firstName: firstName || contact.firstName,
      lastName: lastName || contact.lastName,
      email: email || contact.email,
      phone: cleanedPhone || contact.phone,
    });
    answer.userInputValues = {
      oneId: oneId || contact.oneId,
      firstName: firstName || contact.firstName,
      lastName: lastName || contact.lastName,
      email: email || contact.email,
      phone: cleanedPhone || contact.phone,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    oneId,
    firstName,
    lastName,
    email,
    phone,
    contact.firstName,
    contact.lastName,
    contact.email,
    contact.phone,
  ]);

  useEffect(() => {
    setContactErrors(errors);
  }, [errors]);

  const handleChange = (id, value) => {
    const newContact = { ...contact, [id]: value };

    setContact(newContact);
    onChange({
      value: answer.value,
      userInputValues: newContact,
    });
  };

  const getEndIcon = (id) => {
    const value = contact[id];
    if (value && value.length > 0) {
      return (
        <div
          onClick={() => handleChange(id, '')}
          className={classes.delete_icon}
        >
          <Delete />
        </div>
      );
    }
  };

  const getFieldError = (fieldName) => {
    return contactErrors.find((error) =>
      error.match(new RegExp(fieldName, 'gi')),
    );
  };

  const getFieldLabel = (fieldName) => {
    const error = getFieldError(fieldName);
    switch (fieldName) {
      case 'name':
        return error ? error : labels.NAMES_LABEL_INPUT;
      case 'email':
        return error ? error : labels.EMAIL_LABEL_INPUT;
      case 'phone':
        return error ? error : labels.PHONE_NUMBER_LABEL_INPUT;
      default:
        return '';
    }
  };

  const errorNames = !!getFieldError('name') || !!getFieldError('name');

  const isEmptyValueNames = contact.firstName && contact.lastName;
  const containerClasses = clsx(
    classes.default_input,
    errorNames && classes.error,
  );

  return (
    <Fragment>
      <div className={containerClasses}>
        <Typography
          className={clsx(classes.label, isEmptyValueNames && classes.name)}
          htmlFor="inputNames"
        >
          {!manualInquiryState.isManualInquiry && (
            <span className={classes.mandatory}>*</span>
          )}
          {getFieldLabel('name')}
        </Typography>
        <div className={classes.names_container} id="inputNames">
          <TextInput
            id="firstName"
            value={contact.firstName}
            onChange={(value) => handleChange('firstName', value)}
            error={!!getFieldError('name')}
            autoComplete="off"
          />
          <TextInput
            id="lastName"
            value={contact.lastName}
            onChange={(value) => handleChange('lastName', value)}
            error={!!getFieldError('name')}
            autoComplete="off"
          />
        </div>
      </div>
      <TextInput
        id="email"
        label={getFieldLabel('email')}
        isMandatory={false}
        value={contact.email}
        onChange={(value) => handleChange('email', value)}
        startIcon={<Mail className={classes.icon} />}
        endIcon={getEndIcon('email')}
        error={!!getFieldError('email')}
        className={clsx(classes.default_input, 'contact_info')}
        autoComplete="off"
      />

      <TextInput
        id="phone"
        label={getFieldLabel('phone')}
        isMandatory={true}
        value={formatPhoneWithParenthesis(contact.phone)}
        onChange={(value) => handleChange('phone', value)}
        startIcon={<CallPhone />}
        endIcon={getEndIcon('phone')}
        error={!!getFieldError('phone')}
        className={clsx(classes.last_item, 'contact_info')}
        maxLength={16}
        autoComplete="off"
      />
    </Fragment>
  );
};

ContactInfo.propTypes = {
  oneId: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  answer: PropTypes.object,
  errors: PropTypes.array,
  labels: PropTypes.object,
  onChange: PropTypes.func,
};

export default ContactInfo;
