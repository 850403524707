import React from 'react';
import PropTypes from 'prop-types';

const PetFriendly = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.4236 25.5692C29.4437 22.4805 26.0716 20.6362 22.4032 20.6362C18.7351 20.6362 15.3627 22.4805 13.3831 25.5692L8.61225 33.0119C7.82069 34.2469 7.45546 35.6733 7.55583 37.1365C7.6562 38.6002 8.21311 39.9631 9.16615 41.0786C10.1198 42.1937 11.38 42.9558 12.8104 43.2827C14.2408 43.6094 15.7071 43.47 17.0506 42.8796L17.1382 42.8406C20.5189 41.3834 24.3831 41.3965 27.7555 42.8796C28.6252 43.2619 29.5467 43.4553 30.4766 43.4553C30.9821 43.4553 31.4907 43.3979 31.9946 43.2831C33.425 42.9564 34.6852 42.1944 35.6392 41.0792C36.5926 39.9641 37.1498 38.6008 37.2505 37.1372C37.3512 35.6733 36.986 34.2469 36.1944 33.0115L31.4236 25.5692ZM33.6791 39.4028C32.4656 40.8214 30.5024 41.2699 28.7938 40.5186C26.7628 39.6254 24.5828 39.1789 22.4022 39.1789C20.2235 39.1789 18.0439 39.625 16.0143 40.5173L15.9565 40.5428C14.261 41.2598 12.3267 40.8057 11.1266 39.4028C9.91441 37.9838 9.77644 35.975 10.7835 34.4037L15.5547 26.961C17.0579 24.6159 19.6179 23.2157 22.4032 23.2157C25.1884 23.2157 27.7488 24.6159 29.2523 26.961L34.0229 34.4033C35.0303 35.9754 34.892 37.9845 33.6791 39.4028Z"
        fill="currentColor"
      />
      <path
        d="M9.89721 25.5598C11.3165 25.0146 12.3948 23.8709 12.9335 22.3391C13.4458 20.8816 13.4039 19.236 12.8147 17.7052C12.2252 16.1755 11.153 14.9267 9.79583 14.1885C8.36947 13.4134 6.80279 13.2872 5.38583 13.8333C2.53513 14.9294 1.22694 18.4522 2.47001 21.6879C3.46298 24.2633 5.77893 25.8891 8.12039 25.8891C8.71825 25.8891 9.3178 25.783 9.89721 25.5598ZM4.87692 20.7614C4.14444 18.8546 4.78796 16.8264 6.31234 16.2403C6.58761 16.1342 6.87866 16.0815 7.17575 16.0815C7.6343 16.0815 8.10796 16.2067 8.56384 16.4548C9.3836 16.9002 10.0385 17.6737 10.4078 18.6321C10.7767 19.5912 10.8096 20.6036 10.5001 21.4835C10.2165 22.2898 9.67364 22.8826 8.97204 23.1522L8.9707 23.1529C7.44833 23.7397 5.61175 22.6664 4.87692 20.7614Z"
        fill="currentColor"
      />
      <path
        d="M19.1543 19.7285C22.718 19.7285 25.6174 16.4253 25.6174 12.3651C25.6174 8.30389 22.718 5 19.1543 5C15.5909 5 12.6919 8.30389 12.6919 12.3651C12.6919 16.4253 15.5909 19.7285 19.1543 19.7285ZM19.1543 7.57913C21.2957 7.57913 23.0383 9.72622 23.0383 12.3651C23.0383 15.003 21.2957 17.1494 19.1543 17.1494C17.0129 17.1494 15.271 15.003 15.271 12.3651C15.271 9.72622 17.0129 7.57913 19.1543 7.57913Z"
        fill="currentColor"
      />
      <path
        d="M30.3162 21.5375H30.3165C30.8647 21.7194 31.424 21.8064 31.9809 21.8064C34.5798 21.8064 37.1126 19.9147 38.0791 17.0076C38.6357 15.3342 38.5991 13.5648 37.976 12.0256C37.3241 10.4146 36.116 9.24508 34.5738 8.73214C33.0316 8.21988 31.3629 8.43338 29.8761 9.33371C28.4558 10.1941 27.3675 11.5892 26.8119 13.2627C25.6387 16.7928 27.2107 20.5049 30.3162 21.5375ZM29.2594 14.076C29.6223 12.9834 30.3159 12.083 31.2125 11.5399C32.042 11.0373 32.947 10.9094 33.7604 11.1797C34.5735 11.4502 35.2213 12.0941 35.5852 12.9931C35.9783 13.9646 35.9951 15.1012 35.6316 16.1939C34.9068 18.3746 32.8873 19.6737 31.1299 19.0899C29.3739 18.5058 28.5347 16.2567 29.2594 14.076Z"
        fill="currentColor"
      />
      <path
        d="M43.9268 20.6782L43.9258 20.6772C41.4682 18.8618 37.7927 19.6487 35.7319 22.4322C33.6731 25.2171 33.995 28.9611 36.4489 30.7779C37.3439 31.4409 38.4013 31.7578 39.4836 31.7578C41.3698 31.7578 43.3323 30.795 44.6438 29.0245C46.7026 26.2396 46.381 22.4957 43.9268 20.6782ZM42.5709 27.4901C41.354 29.1326 39.2966 29.6775 37.9837 28.705C36.6718 27.7338 36.5919 25.6075 37.8054 23.966C38.6121 22.8763 39.7904 22.2701 40.8763 22.2701C41.4255 22.2701 41.9512 22.4252 42.393 22.7518C43.7039 23.724 43.7834 25.8496 42.5709 27.4901Z"
        fill="currentColor"
      />
    </svg>
  );
};

PetFriendly.propTypes = {
  className: PropTypes.string,
};

export default PetFriendly;
