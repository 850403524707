import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  no_referred_communities_container: {
    backgroundColor: theme.palette.light.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '80px 0',
    textAlign: 'center',
  },
  no_referred_communities_container_xs: {
    backgroundColor: theme.palette.light.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '40px 0',
    textAlign: 'center',
  },
  icon: {
    fontSize: '80px',
  },
  description: {
    marginTop: '24px',
    padding: '0 45px',
  },
  title: {
    marginTop: '24px',
  },
  edit_ff_button: {
    marginTop: '24px',
  },
}));

export { useStyles };
