import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: 680,
    flexGrow: 1,
    overflow: 'auto',
  },
  container: {
    padding: '30px',
    backgroundColor: '#ccc',
    height: '100vh',
  },
  top: {
    display: 'flex',
    marginBottom: 10,
  },
  main: {
    display: 'grid',
    gridTemplateColumns: '275px 1fr',
    gridGap: '4px',
    height: '700px',
  },
  tree_bar: {
    backgroundColor: '#fff',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: '8px',
    padding: '8px',
  },
  content: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '16px',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
}));

export { useStyles };
