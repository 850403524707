import { cloneDeep } from 'lodash';
import { CHICLET_CARD_STATES } from '../../pages/callCenter/Inquiry/FormChiclets/ChicletCard/ChicletCard';
import inquiryService from './inquiryService';

const SPECIAL_PROPERTIES = [
  'contactInfo',
  'familyInfo',
  'locationInfo',
  'medicalInfo',
];

const getStepExtraFields = (step) => {
  return {
    hidden: step.isHidden,
    span: step.size,
    isOptional: step.isOptional,
    isPipeda: step.isPipeda,
  };
};

const getAnswerExtraFields = (answer) => {
  return {
    hides: answer.hides,
    shows: answer.shows,
    action: answer.action,
    closeInquiry: answer.closeInquiry,
    closeReason: answer.closeReason,
    addToDoNotCall: answer.addToDoNotCall,
    selectedRule: answer.selectedRule,
  };
};

const processAnswers = (step) => {
  if (!step.answers) return [];
  return step.answers.map((answer) => {
    const newAnswer = {
      ...answer,
      nextQuestion: answer.nextStep,
    };
    const extraFields = getAnswerExtraFields(answer);
    newAnswer.extraFields = extraFields;
    return newAnswer;
  });
};

const processStep = (step, isDisabled) => {
  const extraFields = getStepExtraFields(step);
  let state = CHICLET_CARD_STATES.NOT_CURRENT.value;
  const newStep = {
    state,
    type: step.type,
    name: step.name,
    extraFields: extraFields,
    answer: {},
    errors: [],
    number: step.number,
    order: step.order,
    question: step.text,
    answers: processAnswers(step),
    hidden: step.isHidden,
    span: step.size,
    collapsed: false,
    property: step.property,
    disabled: isDisabled,
    answered: false,
  };
  return newStep;
};

const buildPrefilledStep = (step, answer) => {
  const newStep = cloneDeep(step);
  newStep.answer = answer;
  newStep.extraFields.hidden = false;
  newStep.collapsed = !step.extraFields.isOptional;
  newStep.state = CHICLET_CARD_STATES.ANSWERED.value;
  return newStep;
};

const prefillStep = (step, originalAnswer) => {
  let processedAnswer = originalAnswer;

  let found;
  if (Array.isArray(processedAnswer)) {
    const availableOptions = step.answers.map((answer) => answer.value);
    const selectedOptions = processedAnswer.filter((processedAnswer) =>
      availableOptions.includes(processedAnswer),
    );
    if (selectedOptions.length > 0) {
      found = {
        value: 'selectedOptions',
        userInputValues: { selectedOptions },
      };
    }
  } else {
    const BOOLEAN_PROPERTIES = ['bathing', 'toileting', 'veteran'];

    if (BOOLEAN_PROPERTIES.includes(step.property)) {
      found = step.answers.find((a) => a.value === processedAnswer.toString());
    } else {
      found = step.answers.find((a) => a.value === processedAnswer);
    }
  }
  let newAnswer = {};
  if (found) {
    newAnswer = {
      value: found.value,
    };
    if (found.userInputValues) {
      newAnswer.userInputValues = found.userInputValues;
    }
    return buildPrefilledStep(step, newAnswer);
  }
  return cloneDeep(step);
};

const hasFormDataProp = (inquiryFormData, property) => {
  return (
    inquiryFormData &&
    inquiryFormData[property] !== null &&
    inquiryFormData[property] !== undefined
  );
};

const prefillContactInfo = (step, inquiry) => {
  const newStep = cloneDeep(step);
  const { inquiryFormData } = inquiry;
  const hasFirstNameFormData = hasFormDataProp(inquiryFormData, 'firstName');
  const hasLastNameFormData = hasFormDataProp(inquiryFormData, 'lastName');
  const hasPhoneFormData = hasFormDataProp(inquiryFormData, 'primaryPhone');
  const hasEmailFormData = hasFormDataProp(inquiryFormData, 'email');
  newStep.extraFields.hidden = false;
  if (
    hasFirstNameFormData &&
    hasLastNameFormData &&
    hasPhoneFormData &&
    hasEmailFormData
  ) {
    newStep.answer = {
      value: 'save',
      userInputValues: {
        firstName: inquiryFormData.firstName,
        lastName: inquiryFormData.lastName,
        email: inquiryFormData.email,
        phone: inquiryFormData.primaryPhone,
        oneId: inquiry.inquiryContact.oneId,
      },
    };
    newStep.collapsed = true;
    newStep.state = CHICLET_CARD_STATES.ANSWERED.value;
  } else {
    newStep.answer = {};
    newStep.collapsed = false;
    newStep.state = CHICLET_CARD_STATES.NOT_CURRENT.value;
  }
  return newStep;
};

const prefillFamilyInfo = (step, inquiry) => {
  const newStep = cloneDeep(step);
  const { inquiryFormData } = inquiry;
  const hasRelationToResidentFormData = hasFormDataProp(
    inquiryFormData,
    'relationToResident',
  );
  const hasResidentFirstNameFormData = hasFormDataProp(
    inquiryFormData,
    'resident1FirstName',
  );
  const hasResidentLastNameFormData = hasFormDataProp(
    inquiryFormData,
    'resident1LastName',
  );
  if (hasRelationToResidentFormData) {
    newStep.answer = {
      value: 'save',
      userInputValues: {
        relationship: inquiryFormData.relationToResident,
        resident1FirstName: hasResidentFirstNameFormData
          ? inquiryFormData.resident1FirstName
          : '',
        resident1LastName: hasResidentLastNameFormData
          ? inquiryFormData.resident1LastName
          : '',
      },
    };
    newStep.collapsed = true;
    newStep.state = CHICLET_CARD_STATES.ANSWERED.value;
  } else {
    newStep.answer = {};
    newStep.collapsed = false;
    newStep.state = CHICLET_CARD_STATES.NOT_CURRENT.value;
  }
  return newStep;
};
const prefillLocationInfo = (step, inquiry) => {
  const newStep = cloneDeep(step);
  const { inquiryFormData } = inquiry;
  const hasDesiredPostalCodeFormData = hasFormDataProp(
    inquiryFormData,
    'desiredPostalCode',
  );
  const hasDesiredCityFormData = hasFormDataProp(
    inquiryFormData,
    'desiredCity',
  );
  const hasDesiredStateFormData = hasFormDataProp(
    inquiryFormData,
    'desiredState',
  );
  const hasDdesiredDistanceFormData = hasFormDataProp(
    inquiryFormData,
    'desiredDistance',
  );
  if (
    hasDesiredPostalCodeFormData &&
    hasDesiredCityFormData &&
    hasDesiredStateFormData &&
    hasDesiredStateFormData &&
    hasDdesiredDistanceFormData
  ) {
    const preferredLocation = inquiryService.getInfo(
      inquiry,
      'preferredLocationObj',
    );

    newStep.answer = {
      value: 'save',
      userInputValues: {
        location: {
          zip: preferredLocation.locationValues.zip,
          city: preferredLocation.locationValues.city,
          state: preferredLocation.locationValues.state,
          address: preferredLocation.locationValues.address,
          country: preferredLocation.locationValues.country,
        },
        distance: preferredLocation.distance,
        hasCommunities: true,
      },
    };
    newStep.collapsed = true;
    newStep.state = CHICLET_CARD_STATES.ANSWERED.value;
  } else {
    newStep.answer = {
      userInputValues: { location: {}, hasCommunities: true },
    };
    newStep.collapsed = false;
    newStep.state = CHICLET_CARD_STATES.NOT_CURRENT.value;
  }
  return newStep;
};

const prefillMedicalInfo = (step, inquiry) => {
  const newStep = cloneDeep(step);

  const { property } = step;
  const { inquiryFormData } = inquiry;

  let hasFormData = hasFormDataProp(inquiryFormData, property) || false;

  let value = hasFormData
    ? inquiryFormData[property]
    : (inquiry['lookingReason'] || [''])[0];

  newStep.answer = { value };

  return newStep;
};

const prefillStepSpecialProp = (step, inquiry) => {
  switch (step.property) {
    case 'contactInfo':
      return prefillContactInfo(step, inquiry);
    case 'familyInfo':
      return prefillFamilyInfo(step, inquiry);
    case 'locationInfo':
      return prefillLocationInfo(step, inquiry);
    case 'medicalInfo':
      return prefillMedicalInfo(step, inquiry);
    default:
      break;
  }
  return cloneDeep(step);
};

const prefillAnswers = (formSteps, inquiry) => {
  let filledSteps;

  const newFormSteps = cloneDeep(formSteps);
  filledSteps = newFormSteps.map((step) => {
    if (SPECIAL_PROPERTIES.includes(step.property)) {
      return prefillStepSpecialProp(step, inquiry);
    }

    const { property } = step;
    const { inquiryFormData } = inquiry;
    let hasFormData = hasFormDataProp(inquiryFormData, property);

    let originalAnswer = hasFormData
      ? inquiryFormData[property]
      : inquiry[property];

    if (originalAnswer !== null && originalAnswer !== undefined) {
      return prefillStep(step, originalAnswer);
    }
    return step;
  });

  return filledSteps;
};

const initializeForm = (originalForm, inquiry, isDisabled) => {
  if (originalForm && inquiry) {
    const steps = originalForm.steps;
    const formSteps = steps
      .map((step) => processStep(step, isDisabled))
      .sort((stepA, stepB) => stepA.order - stepB.order);
    return prefillAnswers(formSteps, inquiry);
  }
  return null;
};

const formInitializerService = {
  initializeForm,
};

export default formInitializerService;
