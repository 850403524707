import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from 'common/Typography/Typography';
import { useStyles } from './FamilyFileLetters.style';
import AlertCircle from 'common/Icons/notifications/AlertCircle';
import useGraphQLQuery from 'hooks/useGraphQLQuery';
import familyCommunityLetters from './familyCommunityLettersQuery';
import Loading from '../../Loading/Loading';
import { getShortMonthDayYearFormat } from 'utils/dateFormat';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';

const FamilyFileLetters = ({ labels, familyFileId, communityId }) => {
  const classes = useStyles();
  const [lettersSent, setLettersSent] = useState([]);

  const { loading, error, data } = useGraphQLQuery(familyCommunityLetters, {
    variables: { familyFileId, communityId },
  });

  useEffect(() => {
    if (data && data.findFamilyLettersByFamilyFileId.length > 0) {
      setLettersSent(data.findFamilyLettersByFamilyFileId);
    }
  }, [data, setLettersSent]);

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  const getMoreLettersInfo = () => {
    const lettersSentWithoutFirst = lettersSent;
    lettersSentWithoutFirst.shift();
    if (lettersSentWithoutFirst.length > 0) {
      return (
        <SmartTooltip
          title={formatDates(lettersSentWithoutFirst)}
          placement="bottom"
        >
          <Typography
            level="small"
            color="cosmicCobalt3"
            className={classes.more_letters_sent}
          >
            {labels.PLUS_MORE_LETTERS.replace(
              '{QUANTITY}',
              lettersSentWithoutFirst.length,
            )}
          </Typography>
        </SmartTooltip>
      );
    }
    return '';
  };

  const formatDates = (lettersSentWithoutFirst) => {
    const formattedDates = lettersSentWithoutFirst.map((letterSent, key) => {
      return (
        <Typography
          key={`${letterSent.sentAt}-${key}`}
          color="platinum5"
          level="tiny"
        >
          {getShortMonthDayYearFormat(letterSent.sentAt)}
        </Typography>
      );
    });

    return <Fragment>{formattedDates}</Fragment>;
  };

  return (
    <Fragment>
      {lettersSent && lettersSent.length === 0 && (
        <Typography
          level="small"
          color="smokyTopaz2"
          className={classes.not_sent}
        >
          <AlertCircle className={classes.alert_circle} />
          {labels.FAMILY_LETTER_NOT_SENT}
        </Typography>
      )}
      {lettersSent && lettersSent.length > 0 && (
        <Fragment>
          <Typography level="small" className={classes.historical}>
            <b>{labels.FAMILY_LETTER}:</b>
            {getShortMonthDayYearFormat(lettersSent[0].sentAt)}
            {getMoreLettersInfo()}
          </Typography>
        </Fragment>
      )}
    </Fragment>
  );
};

FamilyFileLetters.propTypes = {
  labels: PropTypes.object.isRequired,
  familyFileId: PropTypes.number.isRequired,
  communityId: PropTypes.number.isRequired,
};

export default React.memo(FamilyFileLetters);
