const salesPhases = [
  {
    label: 'New Lead',
    color: 'fieldDrab5',
  },
  {
    label: 'Pre Referral Cnslt.',
    color: 'fieldDrab5',
  },
  {
    label: 'Referral Sent',
    color: 'wintergreenDream5',
  },
  {
    label: 'Scheduled Tour',
    color: 'wintergreenDream5',
  },
  {
    label: 'Post-Tour',
    color: 'wintergreenDream5',
  },
  {
    label: 'Deposit/Community Fee Paid',
    color: 'wintergreenDream5',
  },
  {
    label: 'Wait List',
    color: 'hotCinnamon5',
  },
  {
    label: 'Move-In Process',
    color: 'hotCinnamon5',
  },
  {
    label: 'Awaiting Decision',
    color: 'hotCinnamon5',
  },
  {
    label: 'Move-In Subscription Partner',
    color: 'hotCinnamon5',
  },
  {
    label: 'Move-In Referral Partner',
    color: 'hotCinnamon5',
  },
  {
    label: 'Auto-Close: Self',
    color: 'smokyTopaz5',
  },
];

const changeState = (foundResident, currentResidentAndLocation) => {
  let longestLocation = 0;
  foundResident.longestLocation = 0;
  foundResident.forEach((resident) => {
    resident.checked = false;
    resident.residentNameMatch = false;
    resident.contactNameMatch = false;
    resident.relationMatch = false;
    resident.oneIdMatch = false;
    resident.count = 0;
    if (
      resident.residentFirstName ===
        currentResidentAndLocation.residentFirstName &&
      resident.residentLastName === currentResidentAndLocation.residentLastName
    ) {
      resident.residentNameMatch = true;
      resident.count++;
    }
    if (
      resident.contactFirstName ===
        currentResidentAndLocation.contactFirstName &&
      resident.contactLastName === currentResidentAndLocation.contactLastName
    ) {
      resident.contactNameMatch = true;
    }
    if (resident.oneId === currentResidentAndLocation.oneId) {
      resident.oneIdMatch = true;
      resident.count++;
    }
    if (resident.relationToResident === currentResidentAndLocation.relation) {
      resident.relationMatch = true;
    }

    if (Array.isArray(resident.location)) {
      resident.location.forEach((ffLoc) => {
        currentResidentAndLocation.location.forEach((currentLoc) => {
          if (ffLoc === currentLoc) {
            resident.count++;
          }
        });
      });
      if (resident.location.length > longestLocation) {
        longestLocation = resident.location.length;
      }
    }
  });

  foundResident.longestLocation = longestLocation;
  return foundResident;
};

const getSalePhase = (name) => {
  const salePhase = salesPhases.find((p) => p.label.includes(name));
  return salePhase?.color ?? 'disabled';
};

const getCurrentFamilyFile = (contactInformation, communityPreferences) => {
  return {
    phone: contactInformation.primaryContact.contactDetail.phonePrimary,
    email: contactInformation.primaryContact.contactDetail.email,
    oneId: communityPreferences.oneId,
  };
};

/* This function is adjusting the height of the
 * location row in the duplicate popUp */
const createsValueForDynamicHeightInFamilyFile = (
  foundFFLongestLocation,
  currentFFlocationLength,
) => {
  const maxLocationLines = Math.max(
    currentFFlocationLength,
    foundFFLongestLocation,
  );
  return maxLocationLines;
};

export {
  changeState,
  getSalePhase,
  getCurrentFamilyFile,
  createsValueForDynamicHeightInFamilyFile,
};
