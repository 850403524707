import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useGraphQLQuery from 'hooks/useGraphQLQuery';
import referralCommunitiesQuery from './referralCommunitiesQuery';
import Loading from '../../Loading/Loading';
import referredCommunityService from 'services/referredCommunitiesService';
import getCommunitiesReferralStatus from './getCommunitiesReferralStatus';
import ReferredCommunitiesList from './ReferredCommunitiesList/ReferredCommunitiesList';
import Card from 'common/Card/Card';
import ArrowForwardAll from 'common/Icons/arrow/ArrowForwardAll';

const ReferredCommunities = ({
  sendReferredCommunitiesCount,
  labels,
  familyFileId,
}) => {
  const REFETCH_TIMER = 30000;

  const [referredCommunities, setReferredCommunities] = useState([]);
  let interval;

  let familyFileUserId = useRef(0);

  const {
    loading,
    error,
    data,
    refetch: refetchReferralCommunities,
  } = useGraphQLQuery(referralCommunitiesQuery, {
    variables: { familyFileId },
  });

  const communitiesPedingReferral = referredCommunities.filter(
    (rc) => rc.isReferralStarted && !rc.referralDate,
  );
  const communitiesIds = communitiesPedingReferral.map((community) =>
    Number(community.id),
  );
  const { refetch } = useGraphQLQuery(getCommunitiesReferralStatus, {
    variables: { familyFileId, communitiesIds },
  });

  useEffect(() => {
    if (data?.findFamilyFileById) {
      familyFileUserId.current = data.findFamilyFileById.userId;

      const communitiesWithStatus = referredCommunityService.buildCommunities(
        data.findFamilyFileById.referralStatus,
        data.findFamilyFileById.communities,
        data.findFamilyFileById.tourDetailsExtended,
        data.findFamilyFileById.tourDrafts,
      );
      const communitiesWithDisabled = communitiesWithStatus.map((community) => {
        return {
          ...community,
          ...{
            disabled:
              community.communityStatus === labels.CLOSED ||
              community.isRejected,
          },
        };
      });
      setReferredCommunities(communitiesWithDisabled);
      sendReferredCommunitiesCount(communitiesWithDisabled.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (referredCommunities) {
      sendReferredCommunitiesCount(referredCommunities.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendReferredCommunitiesCount]);

  const communitiesReferralStatus = async () => {
    const { data: statusData } = await refetch();
    const pendingReferrals = statusData.getCommunitiesReferralStatus.find(
      (status) => {
        return status.isReferralStarted && !status.referralDate;
      },
    );
    if (!pendingReferrals) {
      clearInterval(interval);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    interval = setInterval(communitiesReferralStatus, REFETCH_TIMER);
    return () => clearInterval(interval);
  }, []);

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  if (referredCommunities.length === 0) {
    return null;
  }

  const handleRefetch = async () => {
    const { data: refetchedData } = await refetchReferralCommunities();

    const communitiesWithStatus = referredCommunityService.buildCommunities(
      refetchedData.findFamilyFileById.referralStatus,
      refetchedData.findFamilyFileById.communities,
      refetchedData.findFamilyFileById.tourDetailsExtended,
      refetchedData.findFamilyFileById.tourDrafts,
    );

    setReferredCommunities(communitiesWithStatus);
  };

  return (
    <Card
      title={labels.REFERRED_COMMUNITIES}
      icon={<ArrowForwardAll />}
      width="extended"
    >
      <ReferredCommunitiesList
        labels={labels}
        familyFileId={familyFileId}
        familyFileUserId={familyFileUserId.current}
        referredCommunities={referredCommunities}
        setReferredCommunities={setReferredCommunities}
        shouldRefetch={handleRefetch}
      />
    </Card>
  );
};

ReferredCommunities.propTypes = {
  labels: PropTypes.object.isRequired,
  familyFileId: PropTypes.number.isRequired,
  sendReferredCommunitiesCount: PropTypes.func,
};

export default ReferredCommunities;
