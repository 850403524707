import { makeStyles } from '../../common/Theme/Theme';

const drawerWidth = 472;
const minDrawerWidth = 320;
const largeScreenSize = 1280;
const useStyles = makeStyles((theme) => ({
  note_side_panel_container: {
    color: theme.palette.dark.eerieBlack1,
  },
  drawerPaper: {
    top: (props) => (props?.isUnderneathTopBar ? '72px' : 0),
    width: drawerWidth + 16,
    [theme.breakpoints.down(largeScreenSize)]: {
      width: minDrawerWidth + theme.spacing(2),
    },
  },
  header: {
    backgroundColor: theme.palette.light.platinum4,
    boxSizing: 'border-box',
    paddingTop: '21px',
    paddingBottom: '21px',
    paddingLeft: '36px',
  },
  body: {
    overflowY: (props) => (props?.isUnderneathTopBar ? 'hidden' : 'scroll'),
  },
  error_container: {
    marginTop: '42px',
  },
  error_msg: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  close_button: {
    top: (props) => (props?.isUnderneathTopBar ? '82px' : '10px'),
    position: 'fixed',
    boxSizing: 'border-box',
    right: '470px',
    [theme.breakpoints.down(largeScreenSize)]: {
      right: '320px',
    },
    width: '40px',
    height: '40px',
    display: 'flex',
    padding: '4px',
    borderRadius: '2px',
    fontSize: '24px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.light.platinum5,
    boxShadow: '0px 2px 4px rgba(13, 19, 22, 0.3)',
    '&:hover': {
      boxShadow: '0px 0px 8px rgba(13, 19, 33, 0.5)',
    },
  },
  notes_row_container: {
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    padding: '16px 8px 16px 16px',
    borderBottom: `1px solid ${theme.palette.light.platinum1}`,
    [theme.breakpoints.down(largeScreenSize)]: {
      width: minDrawerWidth,
    },
  },
  note_header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.5),
  },
  keynote_container: {
    display: 'flex',
    width: '100%',
  },
  keynote_header: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(0.5),
    width: '100%',
  },
  keynote_title: {
    color: theme.palette.neutral.cosmicCobalt3,
    fontWeight: 'bold',
  },
  keynote_edit: {
    '& > svg': {
      fontSize: '20px',
      marginRight: theme.spacing(2.25),
      fill: theme.palette.neutral.cosmicCobalt3,
      cursor: 'pointer',
    },
  },
  keynote_edit_button_container: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  keynote_cancel_button: {
    marginRight: theme.spacing(3),
  },
  keynote_toast_container: {
    marginBottom: theme.spacing(2),
  },
  inactive_user: {
    color: theme.palette.correction.smokyTopaz1,
  },
  note_text: {
    lineHeight: '24px',
    whiteSpace: 'pre-line',
  },
  read_more: {
    textAlign: 'right',
    color: 'blue',
  },
  note_text_input_container: {
    padding: '16px 25px 16px 16px',
    boxSizing: 'border-box',
    borderBottom: `1px solid ${theme.palette.light.platinum1}`,
  },
  note_input_area: {
    '& textarea': {
      resize: 'vertical',
      overflowY: 'scroll',
    },
  },
  add_note_button: {
    textAlign: 'right',
    marginTop: '16px',
  },
}));

export { useStyles };
