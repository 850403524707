import React from 'react';
import PropTypes from 'prop-types';

const Headphone = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0002 0C7.00766 0 2.94629 3.93548 2.94629 8.77322V8.8327H2.88499C1.29427 8.8327 0 10.0868 0 11.6282V15.7026C0 17.244 1.29427 18.4981 2.88499 18.4981H5.15615C5.66472 18.4981 6.07687 18.0987 6.07687 17.6059V9.72492C6.07692 9.23212 5.66472 8.8327 5.15615 8.8327H4.78778V8.77322C4.78778 4.91953 8.02329 1.78439 12.0002 1.78439C15.9772 1.78439 19.2127 4.91958 19.2127 8.77322V8.8327H18.8441C18.3355 8.8327 17.9233 9.23212 17.9233 9.72487V17.6059C17.9233 18.0987 18.3355 18.4981 18.8441 18.4981H19.6681C19.3102 19.3021 18.4841 19.8662 17.5247 19.8662H15.8301C15.4291 18.5114 14.1388 17.517 12.6136 17.517C10.7693 17.517 9.26855 18.971 9.26855 20.7584C9.26855 22.5458 10.7831 24 12.6446 24C13.5638 24 14.4493 23.6369 15.0735 23.0034C15.4559 22.6158 15.7219 22.1526 15.8576 21.6506H17.5247C19.5248 21.6506 21.2009 20.2803 21.6058 18.4577C22.9637 18.2313 24.0002 17.082 23.9999 15.7026V11.6282C23.9999 10.0868 22.706 8.8327 21.1149 8.8327H21.0542V8.77322C21.0542 3.93548 16.9925 0 12.0002 0ZM4.23543 16.7138H2.88499C2.29999 16.7138 1.84149 16.2698 1.84149 15.7026V11.6283C1.84149 11.0614 2.30004 10.6171 2.88499 10.6171H4.23543V16.7138ZM21.1149 16.7138C21.7002 16.7138 22.1584 16.2698 22.1584 15.7026V11.6282C22.1584 11.0614 21.7002 10.6171 21.1149 10.6171H19.7648V16.7138H21.1149ZM12.6449 22.2153C13.0635 22.2153 13.4637 22.053 13.7427 21.7701C14.0024 21.5069 14.1358 21.1616 14.119 20.7985C14.1184 20.7848 14.1181 20.7714 14.1181 20.7577C14.1178 19.9551 13.4432 19.3011 12.6139 19.3011C11.7846 19.3011 11.1103 19.9548 11.1103 20.7581C11.1103 21.548 11.8131 22.2153 12.6449 22.2153Z"
        fill="currentColor"
      />
    </svg>
  );
};

Headphone.propTypes = {
  className: PropTypes.string,
};

export default Headphone;
