import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from './RichDropdown.style';
import ChevronBottom from '../Icons/arrow/ChevronBottom';
import ChevronTop from '../Icons/arrow/ChevronTop';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Checkbox from '../Checkbox/Checkbox';

const RichDropdown = ({
  error,
  groups,
  emptyLabel,
  label,
  textHint,
  onChange,
  disabled,
  type,
}) => {
  const classes = useStyles();
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [elements, setElements] = useState([]);

  const selectClasses = clsx([
    classes.custom_select,
    error && classes.error,
    disabled && classes.disabled,
    // selected && classes.filled,
    classes[type],
  ]);

  useEffect(() => {
    setElements(groups);
  }, [groups]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleOptionList = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };
  const handleGroupItemOnChange = (e, item) => {
    item.checked = e.target.checked;
    setElements([...elements]);
    const selectedItems = elements
      .map((group) => {
        const items = group.items.filter((item) => item.checked);
        if (items.length > 0) {
          return { ...group, ...{ items } };
        }
        return '';
      })
      .filter((group) => !!group);
    onChange(selectedItems);
  };

  return (
    <div className={selectClasses} ref={wrapperRef} data-testid="dropdown-id">
      <div className={classes.label}>{label}</div>
      <div className={classes.select} onClick={handleOptionList} tabIndex="0">
        <div>{emptyLabel}</div>
        <div className={classes.icon}>
          {isOpen ? <ChevronTop /> : <ChevronBottom />}
        </div>
      </div>
      {isOpen && !disabled && (
        <div className={classes.menu}>
          <div className={classes.items_container}>
            {elements.map((group, key) => (
              <div key={key}>
                <div className={classes.group_title}>{group.title}</div>
                <div>
                  {group.items.map((item, key) => (
                    <div className={classes.group_item} key={key}>
                      <Checkbox
                        checked={item.checked}
                        onChange={(e) => handleGroupItemOnChange(e, item)}
                        label={item.label}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={classes.text_hint}>{textHint}</div>
    </div>
  );
};

RichDropdown.propTypes = {
  error: PropTypes.bool,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      type: PropTypes.oneOf(['radio', 'checkbox']),
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  emptyLabel: PropTypes.string,
  label: PropTypes.string,
  textHint: PropTypes.string,
  type: PropTypes.oneOf(['default', 'textual', 'buttoned']),
  onChange: PropTypes.func,
  selectedItem: PropTypes.object,
  disabled: PropTypes.bool,
};

RichDropdown.defaultProps = {
  emptyLabel: 'Select from list',
  type: 'textual',
  error: false,
  disabled: false,
  onChange: () => {},
};

export default RichDropdown;
