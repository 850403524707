import { get, isEqual } from 'lodash';

/**
 * A utility function for comparing two objects by a specific set of keys.
 *
 * @param {Object} objectA - The first object to compare
 * @param {Object} objectB - The second object to compare
 * @param {String[]} keysToCompare - A list of strings representing the keys of the objects to compare
 * @returns {Object[]} An array of objects with the following properties:
 *    - key: A string representing the key that was different.
 */
export function calculateDiff(objectA, objectB, keysToCompare) {
  const diffs = [];
  keysToCompare.forEach((key) => {
    if (!isEqual(get(objectA, key), get(objectB, key))) {
      diffs.push({ key });
    }
  });

  return diffs;
}
