import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  resident: {
    '& > div:last-child': {
      borderRadius: 0,
      backgroundColor: theme.palette.light.platinum5,
    },
    '& h4': {
      lineHeight: '24px',
    },
  },
  card: {
    '& > div:first-child': {
      borderBottom: 'none',
    },
  },
  row: {
    display: 'flex',
    marginBottom: '16px',
  },
  column: {
    display: 'flex',
    flexGrow: 1,
    width: '50%',
    flexDirection: 'column',
    '& p': {
      marginTop: '4px',
      lineHeight: '15px',
    },
    '& p:first-child': {
      marginTop: '0',
    },
  },
  location: {
    '& p': {
      margin: 0,
      paddingTop: '4px',
    },
  },
  family_file_id: {
    paddingLeft: '5px',
  },
  inline: {
    display: 'inline-flex',
    '& p': {
      display: 'inline',
    },
    '& div': {
      marginRight: '4px',
    },
  },
  care_types_container: {
    flexWrap: 'wrap',
  },
  oneLetterTag: {
    padding: '4px 10px !important',
    margin: '0 0 5px 0 !important',
  },
  do_not_call_container: {
    paddingLeft: theme.spacing(1),
  },
  salesPhase: {
    marginBottom: '5px',
  },
}));

export { useStyles };
