import { logEvent } from '../../services/analyticsService';
import { getCurrentUserFromSessionStorage } from '../../utils/utils';

const SECTION = 'inquiry';
const SECTION_HOME = 'homepage';
const SCREEN_NAME = 'inquiry page';
const SCREEN_HOME_NAME = 'call center homepage';
const ACTION_EVENT_TYPE = 'action';
const VIEW_EVENT_TYPE = 'view';

const logWarmTransferInitiated = ({
  familyFileId,
  inquiryId,
  leadId,
  slaEmail,
  warmTransferAttempt,
  warmTransferId,
}) => {
  logEvent('Warm Transfer Initiated', {
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    eventType: ACTION_EVENT_TYPE,
    familyFileId: familyFileId ?? 'none',
    inquiryId,
    leadId,
    screenName: SCREEN_NAME,
    section: SECTION,
    slaEmail,
    warmTransferAttempt,
    warmTransferId,
  });
};

const logWarmTransferListReturned = ({
  desiredPostalCode,
  inquiryId,
  leadId,
  numSlasReturned,
  warmTransferAttempt,
  warmTransferId,
}) => {
  logEvent('Warm Transfer List Returned', {
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    desiredPostalCode,
    inquiryId,
    leadId,
    numSlasReturned,
    screenName: SCREEN_NAME,
    warmTransferAttempt,
    warmTransferId,
  });
};

const logWarmTransferCancelled = ({
  familyFileId,
  inquiryId,
  leadId,
  warmTransferAttempt,
  warmTransferId,
}) => {
  logEvent('Warm Transfer Cancelled', {
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    eventType: ACTION_EVENT_TYPE,
    familyFileId: familyFileId ?? 'none',
    inquiryId,
    leadId,
    screenName: SCREEN_NAME,
    section: SECTION,
    warmTransferAttempt,
    warmTransferId,
  });
};

const logSLANotFound = ({
  familyFileId,
  inquiryId,
  leadId,
  warmTransferAttempt,
  warmTransferId,
}) => {
  logEvent('SLA Match Not Found', {
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    eventType: ACTION_EVENT_TYPE,
    familyFileId: familyFileId ?? 'none',
    inquiryId,
    leadId,
    screenName: SCREEN_NAME,
    section: SECTION,
    warmTransferAttempt,
    warmTransferId,
  });
};

const logInquiryViewed = (inquiryId, familyFileId) => {
  logEvent('Inquiry Viewed', {
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    eventPath: SCREEN_NAME,
    eventType: VIEW_EVENT_TYPE,
    familyFileId: familyFileId ?? 'none',
    inquiryId,
    screenName: SCREEN_NAME,
    section: SECTION,
  });
};

const logCompleteCallClicked = ({
  disposition = '',
  familyFileId = 'none',
  inquiryId = '',
  leadId = '',
  slaEmail = '',
  warmTransferAttempt = '',
  warmTransferId = '',
}) => {
  logEvent('Complete Call Clicked', {
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    disposition,
    eventType: ACTION_EVENT_TYPE,
    familyFileId,
    inquiryId,
    leadId,
    screenName: SCREEN_NAME,
    slaEmail,
    warmTransferAttempt,
    warmTransferId,
  });
};

const logPingSLA = ({
  leadId = '',
  pingRound = 0,
  slaEmail = '',
  warmTransferAttempt = '',
  warmTransferId = '',
}) => {
  logEvent('SLA Ping Event', {
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    eventType: ACTION_EVENT_TYPE,
    inquiryId: leadId,
    pingRound,
    slaEmail,
    screenName: SCREEN_NAME,
    warmTransferAttempt,
    warmTransferId,
  });
};

const logWarmTransferPingStatusUptate = ({
  inquiryId,
  leadId,
  pingRound,
  pingStatus,
  slaEmail,
  warmTransferId,
  warmTransferAttempt,
}) => {
  logEvent('Warm Transfer Ping Status Updated', {
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    inquiryId,
    leadId,
    pingRound,
    pingStatus,
    slaEmail,
    screenName: SCREEN_NAME,
    warmTransferAttempt,
    warmTransferId,
  });
};

const logCompleteWarmTransfer = ({
  inquiryId = '',
  leadId = '',
  slaEmail = '',
  warmTransferAttempt = '',
  warmTransferId = '',
}) => {
  logEvent('Warm Transfer Leave Conference Clicked', {
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    eventType: ACTION_EVENT_TYPE,
    inquiryId,
    leadId,
    screenName: SCREEN_NAME,
    slaEmail,
    warmTransferAttempt,
    warmTransferId,
  });
};

const logConferenceCallClicked = (inquiryId, familyFileId) => {
  logEvent('Conference Call Clicked', {
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    eventType: ACTION_EVENT_TYPE,
    familyFileId: familyFileId ?? 'none',
    inquiryId,
    screenName: SCREEN_NAME,
    section: SECTION,
  });
};

const logInquiryNoteModified = (inquiryId, familyFileId, modification) => {
  logEvent('Inquiry Note Modified', {
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    eventType: ACTION_EVENT_TYPE,
    familyFileId: familyFileId ?? 'none',
    inquiryId,
    modification,
    screenName: SCREEN_NAME,
    section: SECTION,
  });
};

const logInquiryErrorViewed = (inquiryId, errorMessage) => {
  logEvent('Inquiry Error Viewed', {
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    errorMessage,
    eventPath: SCREEN_NAME,
    eventType: VIEW_EVENT_TYPE,
    familyFileId: 'none',
    inquiryId: inquiryId ?? 'none',
    screenName: SCREEN_NAME,
    section: SECTION,
  });
};

const logMergeClicked = (inquiryId, contactsLength) => {
  logEvent('Contact Duplicate Action Taken', {
    button: 'Merge',
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    eventType: ACTION_EVENT_TYPE,
    familyFileId: 'none',
    inquiryId,
    numContacts: contactsLength,
    screenName: SCREEN_NAME,
    section: SECTION,
  });
};

const logDoNotMergeClicked = (inquiryId, contactsLength) => {
  logEvent('Contact Duplicate Action Taken', {
    button: 'Do Not Merge',
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    eventType: ACTION_EVENT_TYPE,
    familyFileId: 'none',
    inquiryId,
    numContacts: contactsLength,
    screenName: SCREEN_NAME,
    section: SECTION,
  });
};

const logCallbackScheduled = (inquiryId, familyFileId, date) => {
  logEvent('Callback Scheduled', {
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    eventType: ACTION_EVENT_TYPE,
    familyFileId: familyFileId ?? 'none',
    inquiryId,
    screenName: SCREEN_NAME,
    section: SECTION,
    selectedCallbackTime: date,
  });
};

const logCallCenterHomepageViewed = () => {
  logEvent('Call Center Homepage Viewed', {
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    eventPath: SCREEN_HOME_NAME,
    eventType: VIEW_EVENT_TYPE,
    screenName: SCREEN_HOME_NAME,
    section: SECTION_HOME,
  });
};

const logCallTransferred = (inquiryId, familyFileId, transferOption) => {
  logEvent('Call Transferred', {
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    eventPath: SCREEN_NAME,
    eventType: ACTION_EVENT_TYPE,
    familyFileId: familyFileId ?? 'none',
    inquiryId: inquiryId ?? 'none',
    screenName: SCREEN_NAME,
    section: SECTION,
    transferOption,
  });
};

const logResidentDupCheckCompleted = ({
  action = '',
  externalId = '',
  familyFileId = '',
  inquiryId = '',
  slaName = '',
}) => {
  logEvent('Resident Duplicate Check Completed', {
    action,
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    eventType: ACTION_EVENT_TYPE,
    externalId,
    familyFileId,
    inquiryId,
    screenName: SCREEN_NAME,
    section: SECTION,
    slaName,
  });
};

const logWarmTransferScreenViewed = (inquiryId) => {
  logEvent('Warm Transfer Screen Viewed', {
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    inquiryId,
    screenName: SCREEN_NAME,
  });
};

const logWarmTransferConferenceClicked = ({
  familyFileId,
  inquiryId,
  leadId,
  slaEmail,
  warmTransferAttempt,
  warmTransferId,
}) => {
  logEvent('Warm Transfer Conference Clicked', {
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    eventType: ACTION_EVENT_TYPE,
    familyFileId: familyFileId ?? 'none',
    inquiryId,
    leadId,
    screenName: SCREEN_NAME,
    section: SECTION,
    slaEmail,
    warmTransferAttempt,
    warmTransferId,
  });
};

const logWarmTransferAddSlaToConferenceRetried = ({
  familyFileId,
  inquiryId,
  leadId,
  slaEmail,
  warmTransferAttempt,
  warmTransferId,
}) => {
  logEvent('Warm Transfer Add SLA to Conference Retried', {
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    familyFileId: familyFileId ?? 'none',
    inquiryId,
    leadId,
    screenName: SCREEN_NAME,
    slaEmail,
    warmTransferAttempt,
    warmTransferId,
  });
};

const logWarmTransferErrorViewed = ({
  inquiryId,
  leadId,
  slaEmail,
  warmTransferAttempt,
  warmTransferId,
}) => {
  logEvent('Warm Transfer Error Viewed', {
    crmRole: getCurrentUserFromSessionStorage()?.role ?? '',
    crmUserId: getCurrentUserFromSessionStorage()?.userId ?? '',
    inquiryId,
    leadId,
    screenName: SCREEN_NAME,
    slaEmail,
    warmTransferAttempt,
    warmTransferId,
  });
};
//#region Exports

export {
  logCallbackScheduled,
  logCallCenterHomepageViewed,
  logCallTransferred,
  logCompleteCallClicked,
  logCompleteWarmTransfer,
  logConferenceCallClicked,
  logDoNotMergeClicked,
  logInquiryErrorViewed,
  logInquiryNoteModified,
  logInquiryViewed,
  logMergeClicked,
  logPingSLA,
  logResidentDupCheckCompleted,
  logSLANotFound,
  logWarmTransferAddSlaToConferenceRetried,
  logWarmTransferCancelled,
  logWarmTransferConferenceClicked,
  logWarmTransferErrorViewed,
  logWarmTransferInitiated,
  logWarmTransferListReturned,
  logWarmTransferScreenViewed,
  logWarmTransferPingStatusUptate,
};

//#endregion
