import PropTypes from 'prop-types';
import React from 'react';
import { useStyles } from './ErrorLayout.style';

const ErrorLayout = ({ children, showTopBar }) => {
  const classes = useStyles();

  return (
    <div className={classes.layout_container}>
      {showTopBar && <div className={classes.top_bar}></div>}
      <div className={classes.main_container}>{children}</div>
    </div>
  );
};

ErrorLayout.propTypes = {
  children: PropTypes.node.isRequired,
  showTopBar: PropTypes.bool,
};

ErrorLayout.defaultProps = {
  showTopBar: true,
};

export default ErrorLayout;
