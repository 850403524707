import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => ({
  default_input: {
    width: '100%',
    marginBottom: '10px',
  },
  dropdown_input: {
    zIndex: 999,
    '& div:nth-child(3)': {
      overflow: 'auto',
      maxHeight: '170px',
    },
  },
  secondBlock: {
    margin: '20px 0',
  },
  last_item: {
    marginBottom: '-10px',
  },
}));

export { useStyles };
