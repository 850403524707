import { registerEvent } from '../../services/Analytics';

/**
 * Class for providing utilities for interacting with the backend services required for the Initial Consultation form.
 */
class InitialConsultationFormService {
  constructor({
    updateFinancialCriteriaMutation,
    upsertCommunityPreferenceMutation,
    updateCareTypesMutation,
    updateMedicalBackgroundMutation,
  }) {
    this.updateFinancialCriteriaMutation = updateFinancialCriteriaMutation;
    this.upsertCommunityPreferenceMutation = upsertCommunityPreferenceMutation;
    this.updateCareTypesMutation = updateCareTypesMutation;
    this.updateMedicalBackgroundMutation = updateMedicalBackgroundMutation;
  }

  /**
   * @param {FinancialCriteriaFormModel} financialCriteriaFormModel
   */
  updateFinancialCriteria(financialCriteriaFormModel) {
    registerEvent('FamilyFiles', 'Edit financial info is saved');
    const financialCriteriaInput = {
      variables: {
        formDataId: financialCriteriaFormModel.formDataId,
        budgetDescription: financialCriteriaFormModel.estimatedBudgetRange,
        financialResources: financialCriteriaFormModel.financialResources,
        longTermCare: financialCriteriaFormModel.hasLongTermCareInsurancePolicy,
        veteran: financialCriteriaFormModel.isVeteran,
        vaAid: financialCriteriaFormModel.vaAid,
        oneId: financialCriteriaFormModel.oneId,
        familyFileId: financialCriteriaFormModel.familyFileId,
        medicaid: financialCriteriaFormModel.medicaid,
      },
    };

    return this.updateFinancialCriteriaMutation(financialCriteriaInput);
  }

  /**
   * @param {CommunityPreferenceFormModel} communityPreferenceFormModel
   */
  upsertCommunityPreference(communityPreferenceFormModel) {
    registerEvent('FamilyFiles', 'Edit community preferences is saved');
    const upsertCommunityPreferenceMutationInput = {
      variables: {
        familyFileId: communityPreferenceFormModel.familyFileId,
        oneId: communityPreferenceFormModel.oneId,
        roomPreference: communityPreferenceFormModel.roomPreference,
        locationRange: communityPreferenceFormModel.locationRange,
      },
    };

    const updateCareTypesMutationInput = {
      variables: {
        familyFileId: communityPreferenceFormModel.familyFileId,
        oneId: communityPreferenceFormModel.oneId,
        residentId: communityPreferenceFormModel.residentId,
        careTypes: communityPreferenceFormModel.careTypePreference.map(
          (careType) => careType.code,
        ),
      },
    };

    return Promise.all([
      this.upsertCommunityPreferenceMutation(
        upsertCommunityPreferenceMutationInput,
      ),
      this.updateCareTypesMutation(updateCareTypesMutationInput),
    ]);
  }

  /**
   * @param {MedicalBackgroundFormModel} medicalBackgroundFormModel
   */
  updateMedicalBackground(medicalBackgroundFormModel) {
    registerEvent('FamilyFiles', 'Edit Medical Background is saved');
    const medicalBackgroundInput = {
      variables: {
        formDataId: medicalBackgroundFormModel.formDataId,
        currentLiving: medicalBackgroundFormModel.currentSituation,
        locationName: medicalBackgroundFormModel.locationName,
        rehab: medicalBackgroundFormModel.rehabSkilledNursingRequired,
        medications: medicalBackgroundFormModel.currentMedication,
        memoryLoss: medicalBackgroundFormModel.memoryLoss,
        memoryDiagnosis: medicalBackgroundFormModel.memoryDiagnosis,
        wandering: medicalBackgroundFormModel.otherIssues,
        toileting: _parseToYesOrNo(medicalBackgroundFormModel.helpInToileting),
        getAround: medicalBackgroundFormModel.gettingAroundDescription,
        bathing: _parseToYesOrNo(medicalBackgroundFormModel.helpInShower),
        diabetic: medicalBackgroundFormModel.diabeticCare,
        healthIssues: medicalBackgroundFormModel.healthIssues,
        anythingElse: medicalBackgroundFormModel.anythingElse,
        oneId: medicalBackgroundFormModel.oneId,
        familyFileId: medicalBackgroundFormModel.familyFileId,
      },
    };

    return this.updateMedicalBackgroundMutation(medicalBackgroundInput);
  }
}

export default InitialConsultationFormService;

const _parseToYesOrNo = (text) => {
  return text && text === true ? 'Yes' : 'No';
};
