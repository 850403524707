import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './CommunitiesTableMapPinPoint.style';
import SaveCommunity from '../SaveCommunity/SaveCommunity';
import CommunityImage from '../CommunityImage/CommunityImage';
import CommunityPriceRange from '../CommunityPriceRange/CommunityPriceRange';
import CommunityInfoPinPointXS from '../CommunityInfo/CommunityInfoPinPointXS';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {
  getDisplayImageId,
  getPriceRanges,
  getRoomCharges,
  getRoomFees,
} from './communityService';
import withConfig from 'utils/withConfig';
import { getTags } from 'services/communityTagsService';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import { getSmallContainerTop } from 'services/communityMarkerService';
import clsx from 'clsx';

const imageUrl = withConfig('IMAGE_URL');

const CommunitiesTableMapPinPoint = ({
  labels,
  community,
  onSave,
  onRemove,
  familyFileId,
  isClickedInsideTheMap,
  isCommunitySelected,
  isPinPointClicked,
  dataForSegment,
  locationFilter,
  onSaveCommunityUpdate,
  communitiesFeature,
  isSmallMap,
  mapSmallContainer,
  isMapPinPointHovered,
  isHovered,
}) => {
  const props = {
    top:
      isMapPinPointHovered || isHovered ? getSmallContainerTop(community) : 180,
  };
  const classes = useStyles(props);
  const [isClickedOutsideTheDiv, setIsClickedOutsideTheDiv] = useState(true);
  const [shouldDisplayTheDiv, setShouldDisplayTheDiv] = useState(false);

  const isSavable = !community.isReferred;

  useEffect(() => {
    if (isCommunitySelected === false) {
      setShouldDisplayTheDiv(false);
      return;
    }

    if (isPinPointClicked === true) {
      setShouldDisplayTheDiv(true);
      return;
    }
    if (isClickedInsideTheMap === true && isClickedOutsideTheDiv === true) {
      setShouldDisplayTheDiv(false);
      return;
    }
  }, [
    isClickedInsideTheMap,
    isCommunitySelected,
    isClickedOutsideTheDiv,
    isPinPointClicked,
  ]);

  const getSpecialFeatures = () => {
    if (
      communitiesFeature !== null &&
      communitiesFeature !== undefined &&
      !isSmallMap
    ) {
      return (
        <div className={classes.filter_content}>
          {communitiesFeature.map((feature, key) => (
            <div key={key}>
              <SmartTooltip title={feature.label} placement="bottom">
                <div className={classes.amenity}>{feature.icon}</div>
              </SmartTooltip>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <>
      {(shouldDisplayTheDiv || isMapPinPointHovered) && (
        <ClickAwayListener onClickAway={() => setIsClickedOutsideTheDiv(true)}>
          <div
            ref={mapSmallContainer}
            className={
              isSmallMap
                ? clsx(classes.container, classes.small_container)
                : classes.container
            }
            onClick={() => setIsClickedOutsideTheDiv(false)}
          >
            {!isSmallMap && (
              <CommunityImage
                imageId={getDisplayImageId(community.images)}
                imageUrl={imageUrl}
                size="map"
                tags={getTags(community)}
              />
            )}
            <div className={classes.row}>
              <div className={classes.column_community_info}>
                <CommunityInfoPinPointXS
                  labels={labels}
                  communityId={community.id}
                  address={community.address}
                  phone={community.phoneNumber}
                  companyName={community.organizationName}
                  name={community.name}
                  city={community.city}
                  state={community.state}
                  distance={community.distance}
                  zip={community.zip}
                  referred={0}
                  moveIns={0}
                  careTypes={community.careTypes}
                  ratingAverage={community.ratingAverage}
                  numberOfReviews={community.numberOfReviews}
                  isSmallMap={isSmallMap}
                />
              </div>
              {isSavable && (
                <div
                  className={
                    isSmallMap
                      ? clsx(
                          classes.column_save_community,
                          classes.column_save_community_smallmap,
                        )
                      : classes.column_save_community
                  }
                >
                  <SaveCommunity
                    labels={labels}
                    onSave={() => onSave(community)}
                    onRemove={onRemove}
                    familyFileId={familyFileId}
                    community={community}
                    dataForSegment={dataForSegment}
                    locationFilter={locationFilter}
                    onUpdate={onSaveCommunityUpdate}
                  />
                </div>
              )}
            </div>
            <CommunityPriceRange
              careTypes={community.careTypes}
              careTypeStyle="map"
              communityId={community.id}
              communityName={community.name}
              labels={labels}
              prices={getPriceRanges(
                community.careTypes,
                community.roomCharges,
              )}
              roomCharges={getRoomCharges(community.roomCharges)}
              roomFees={getRoomFees(community.roomFees)}
              isSmallMap={isSmallMap}
            />
            <div>{getSpecialFeatures()}</div>
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};

CommunitiesTableMapPinPoint.propTypes = {
  labels: PropTypes.object,
  community: PropTypes.object,
  locationFilter: PropTypes.object,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
  familyFileId: PropTypes.number,
  isClickedInsideTheMap: PropTypes.bool,
  isSelected: PropTypes.bool,
  isMapPinPointHovered: PropTypes.bool,
  isCommunitySelected: PropTypes.bool,
  isPinPointClicked: PropTypes.bool,
  dataForSegment: PropTypes.shape({
    familyFileId: PropTypes.number,
    oneId: PropTypes.string,
  }),
  onSaveCommunityUpdate: PropTypes.func,
  communitiesFeature: PropTypes.array,
  isSmallMap: PropTypes.bool,
  mapSmallContainer: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  isHovered: PropTypes.bool,
};

CommunitiesTableMapPinPoint.defaultProps = {};

export default CommunitiesTableMapPinPoint;
