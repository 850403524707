import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    fontSize: '16px',
    color: theme.palette.neutral.cosmicCobalt4,
  },
}));

export { useStyles };
