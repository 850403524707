import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => ({
  conference_call_buttons_container: {
    display: 'flex',
  },
  left: {
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, 0.42)',
    padding: '9px 0 9px 25px',
    borderRadius: '30px 0 0 30px',
    '& > div': {
      marginRight: '26px',
    },
  },
  right: {
    borderRadius: '30px',
  },
  icon: {
    marginRight: '10px',
    '& svg': {
      fontSize: '24px',
      verticalAlign: 'middle',
    },
  },
  disabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    '& div': {
      cursor: 'default',
    },
  },
}));

export { useStyles };
