import gql from 'graphql-tag';
import { Community } from '../../queries/communityQueryFragments';

export default gql`
  query($familyFileId: Int) {
    findFamilyFileById(id: $familyFileId) {
      oneId
      userId
      salesPhase
      residents {
        profileData {
          firstName
          lastName
        }
      }
      contacts {
        profileData {
          firstName
          lastName
        }
      }
      residents {
        careTypes {
          code
        }
      }
      familyDesiredLocations {
        city
        state
        zip
      }
      leadData {
        budgetDescription
      }
      communities {
        id
        name
        organizationName
        address
        address2
        city
        state
        zip
        phoneNumber
        primaryContact
        ...CommunityCareTypes
        roomCharges {
          careType
          amount
          apartmentType {
            description
          }
        }
        roomFees {
          amount
          careType
          feeType {
            name
          }
        }
        geoLocation {
          lat
          lon
        }
        title
      }
    }
  }
  ${Community.fragments.careTypes}
`;
