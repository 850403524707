import React from 'react';
import PropTypes from 'prop-types';

const CircleInfo = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM11 17V10H13V17H11ZM13.0007 7C13.0007 7.55228 12.5528 8 12.0003 8C11.4479 8 11 7.55228 11 7C11 6.44772 11.4479 6 12.0003 6C12.5528 6 13.0007 6.44772 13.0007 7Z"
        fill="currentColor"
      />
    </svg>
  );
};

CircleInfo.propTypes = {
  className: PropTypes.string,
};

CircleInfo.defaultProps = {
  className: '',
};

export default CircleInfo;
