import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './StaticDatePicker.style';
import { DatePicker as MaterialDatePicker } from '@material-ui/pickers';
import ArrowLeft from '../Icons/arrow/ArrowLeft';
import ArrowRight from '../Icons/arrow/ArrowRight';
import SmartTooltip from '../SmartTooltip/SmartTooltip';
import clsx from 'clsx';

const StaticDatePicker = ({
  disable,
  disableFuture,
  disablePast,
  futureDatesTooltipTitle,
  onChange,
  pastDatesTooltipTitle,
  value,
}) => {
  const classes = useStyles();
  const [dateSelected, setDateSelected] = useState(value);
  const [isDaySelected, setIsDaySelected] = useState(!!value);

  useEffect(() => {
    setDateSelected(value);
    setIsDaySelected(!!value);
  }, [value]);

  const onDateChange = (date) => {
    setDateSelected(date);
    setIsDaySelected(true);
    onChange(date);
  };

  const renderDay = (day, selectedDate, dayInCurrentMonth, dayComponent) => {
    if (disableFuture && dayComponent.props.disabled) {
      return (
        <SmartTooltip
          title={futureDatesTooltipTitle}
          placement="top"
          cursor="not-allowed"
        >
          {dayComponent}
        </SmartTooltip>
      );
    }

    if (disablePast && dayComponent.props.disabled) {
      return (
        <SmartTooltip
          title={pastDatesTooltipTitle}
          placement="top"
          cursor="not-allowed"
        >
          {dayComponent}
        </SmartTooltip>
      );
    }

    if (!isDaySelected && dayComponent.props.current) {
      return React.cloneElement(dayComponent, {
        className: clsx('MuiPickersDay-day', 'MuiPickersDay-current'),
      });
    }

    return dayComponent;
  };

  const renderDayDisabled = (
    day,
    selectedDate,
    dayInCurrentMonth,
    dayComponent,
  ) => {
    return React.cloneElement(dayComponent, {
      disabled: true,
      current: false,
      className: clsx('MuiPickersDay-day', 'MuiPickersDay-dayDisabled'),
    });
  };

  return (
    <>
      {disable ? (
        <div className={classes.date_picker_container}>
          <MaterialDatePicker
            minDate={new Date()}
            maxDate={new Date()}
            value={null}
            onChange={() => {}}
            variant="static"
            disableHighlightToday={true}
            disableFuture={disableFuture}
            disablePast={disablePast}
            leftArrowIcon={<ArrowLeft className={classes.arrow} />}
            rightArrowIcon={<ArrowRight className={classes.arrow} />}
            disableToolbar={true}
            renderDay={renderDayDisabled}
          />
        </div>
      ) : (
        <div className={classes.date_picker_container}>
          <MaterialDatePicker
            value={dateSelected}
            onChange={onDateChange}
            variant="static"
            disableFuture={disableFuture}
            disablePast={disablePast}
            leftArrowIcon={<ArrowLeft className={classes.arrow} />}
            rightArrowIcon={<ArrowRight className={classes.arrow} />}
            disableToolbar={true}
            renderDay={renderDay}
          />
        </div>
      )}
    </>
  );
};

StaticDatePicker.propTypes = {
  disable: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  futureDatesTooltipTitle: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  pastDatesTooltipTitle: PropTypes.string,
  value: PropTypes.instanceOf(Date),
};

StaticDatePicker.defaultProps = {
  disable: false,
  disableFuture: false,
  disablePast: false,
  futureDatesTooltipTitle: '',
  pastDatesTooltipTitle: '',
  value: null,
};

export default StaticDatePicker;
