import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clonedeep from 'lodash.clonedeep';
import clsx from 'clsx';

import { useStyles } from './MarkTourCompletedModal.style';
import Typography from 'common/Typography/Typography';
import Radio from 'common/Radio/Radio';
import Dropdown from 'common/Dropdown/Dropdown';
import DatePicker from 'common/DatePicker/DatePicker';
import Alert from 'common/Alert/Alert';
import useGraphQLMutation from 'hooks/useGraphQLMutation';
import markTourCompleteMutation from './Mutation/markTourCompleteMutation';
import Toast from 'common/Toast/Toast';
import tourScheduleService, {
  logTourMarkedCompleted,
} from 'services/tourScheduleService';
import { getDate, getHourAndMinutes12HourFormat } from 'utils/dateFormat';

const MarkTourCompletedModal = ({
  community,
  familyFileId,
  familyFileUserId,
  isModalOpen,
  labels,
  markTourCompletedInfo,
  onClose,
  onSave,
  tourDetails,
}) => {
  const classes = useStyles();
  const SAME_DAY = 'SAME';
  const DIFFERENT_DAY = 'DIFFERENT';
  const TOURED = 'Toured';
  const invalidTourDateErrorMessage =
    "Invalid property 'properties.TourCompletedActivity.CompletedActivityDate";

  const [markTourCompleteError, setMarkTourCompleteError] = useState();
  const [markTourComplete] = useGraphQLMutation(markTourCompleteMutation);
  const [isActualCompletedDateError, setIsActualCompletedDateError] = useState(
    false,
  );
  const [
    markTourCompletedInformation,
    setMarkTourCompletedInformation,
  ] = useState(clonedeep(markTourCompletedInfo));

  useEffect(() => {
    const selectedTourType = markTourCompletedInformation?.selectedTourType
      ? markTourCompletedInformation.selectedTourType
      : {
          value: tourDetails.tourType,
          label: tourScheduleService.getTourTypeLabel(tourDetails.tourType),
        };

    const selectedWhenCompleted = markTourCompletedInformation?.selectedWhenCompleted
      ? markTourCompletedInformation.selectedWhenCompleted
      : { value: SAME_DAY };

    const selectedActualDate = markTourCompletedInformation?.selectedActualDate
      ? markTourCompletedInformation.selectedActualDate
      : null;

    setMarkTourCompletedInformation({
      selectedTourType,
      selectedWhenCompleted,
      selectedActualDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourDetails]);

  const isTourDateInTheFuture = () => {
    const currentDateTime = new Date().getTime();

    return tourDetails.scheduledTourDate > currentDateTime;
  };

  const getTourAnalyticsDetails = () => {
    const tourTypeText = tourScheduleService.getTourTypeLabel(
      tourDetails.tourType,
    );

    const selectedTime = getHourAndMinutes12HourFormat(
      tourDetails.scheduledTourDate,
      true,
    );
    const tourDateTime = `${getDate(
      tourDetails.scheduledTourDate,
    )} ${selectedTime}`;

    const whenCompleted = tourScheduleService.getWhenCompletedLabel(
      markTourCompletedInformation.selectedWhenCompleted.value,
      tourDetails.scheduledTourDate,
    );

    let currentTourDateTime = null;
    if (
      markTourCompletedInformation.selectedWhenCompleted.value ===
        DIFFERENT_DAY &&
      markTourCompletedInformation.selectedActualDate
    ) {
      const currentSelectedTime = getHourAndMinutes12HourFormat(
        markTourCompletedInformation.selectedActualDate,
        true,
      );
      currentTourDateTime = `${getDate(
        markTourCompletedInformation.selectedActualDate,
      )} ${currentSelectedTime}`;
    } else {
      currentTourDateTime = tourDateTime;
    }

    const currentTourTypeText = tourScheduleService.getTourTypeLabel(
      markTourCompletedInformation.selectedTourType.value,
    );

    return [
      tourTypeText,
      tourDateTime,
      whenCompleted,
      currentTourDateTime,
      currentTourTypeText,
    ];
  };

  const createTourMarkedCompletedAnalyticsEvents = () => {
    const [
      tourTypeText,
      tourDateTime,
      whenCompleted,
      currentTourDateTime,
      currentTourTypeText,
    ] = getTourAnalyticsDetails();

    logTourMarkedCompleted(
      community.id,
      familyFileId,
      tourDateTime,
      tourTypeText,
      whenCompleted,
      currentTourDateTime,
      currentTourTypeText,
      familyFileUserId,
    );
  };

  const handleConfirm = () => {
    createTourMarkedCompletedAnalyticsEvents();

    let completedActivityDate =
      markTourCompletedInformation.selectedWhenCompleted.value === SAME_DAY
        ? new Date(tourScheduleService.getScheduledTourDate(tourDetails))
        : markTourCompletedInformation.selectedActualDate;

    if (
      !completedActivityDate &&
      markTourCompletedInformation.selectedWhenCompleted.value === DIFFERENT_DAY
    ) {
      setIsActualCompletedDateError(true);
      return;
    } else {
      setIsActualCompletedDateError(false);
    }

    if (
      markTourCompletedInformation.selectedWhenCompleted.value === SAME_DAY &&
      isTourDateInTheFuture()
    ) {
      setMarkTourCompleteError(invalidTourDateErrorMessage);
      return;
    }

    const activityDateAtNoon = new Date(
      completedActivityDate.getFullYear(),
      completedActivityDate.getMonth(),
      completedActivityDate.getDate(),
    );
    activityDateAtNoon.setHours(0, 0, 0, 0);

    const variables = {
      familyFileId: parseInt(familyFileId),
      communityId: parseInt(community.id),
      familyCommunityTourId: tourDetails.familyCommunityTourId,
      completedActivityDate: activityDateAtNoon.getTime(),
      completedTourType: markTourCompletedInformation.selectedTourType.value,
      activityTypeCode: TOURED,
      timeOffset: activityDateAtNoon.getTimezoneOffset(),
    };

    const markTourCompletePromise = markTourComplete({ variables });

    markTourCompletePromise
      .then(() => {
        setMarkTourCompleteError();
        onSave();
      })
      .catch((error) => {
        setMarkTourCompleteError(error.message);
        console.error(error);
      });
  };

  const setProperty = (name, value) => {
    setMarkTourCompletedInformation((information) => ({
      ...information,
      [name]: value,
    }));
  };

  const isInvalidCompletedActivityDate = markTourCompleteError?.includes(
    invalidTourDateErrorMessage,
  );

  const modalBody = (
    <>
      <div className={classes.modal_body}>
        {markTourCompleteError && (
          <Toast type="error" className={classes.error}>
            <Typography>
              {isInvalidCompletedActivityDate
                ? labels.CANNOT_COMPLETE_SAME_DAY_TOUR
                : labels.UNABLE_TO_SAVE_DUE_TO_ERROR}
            </Typography>
          </Toast>
        )}

        <div className={classes.radio_buttons_container}>
          <Typography level="small" className={classes.enter_tour_detail}>
            {labels.ENTER_WHEN_TOUR_COMPLETED}
          </Typography>
          <Radio
            className={classes.complete_action_options}
            name="complete-options"
            selected={markTourCompletedInformation.selectedWhenCompleted}
            onChange={(value) => setProperty('selectedWhenCompleted', value)}
            items={tourScheduleService.getMarkTourCompletedRadioItems(
              tourDetails.scheduledTourDate,
            )}
          />
        </div>

        {markTourCompletedInformation.selectedWhenCompleted.value ===
          DIFFERENT_DAY && (
          <>
            <Typography
              level="small"
              className={clsx(isActualCompletedDateError && classes.error_text)}
            >
              {labels.ACTUAL_SCHEDULED_TOUR_DATE}
            </Typography>
            <div className={classes.input_container}>
              <DatePicker
                dateSelected={markTourCompletedInformation.selectedActualDate}
                onChange={(value) => setProperty('selectedActualDate', value)}
                defaultLabel={labels.SELECT_DATE}
                disableFuture={true}
                disableDatesTooltipTitle={
                  labels.CANNOT_MARK_TOUR_COMPLETE_IN_THE_PAST
                }
                error={isActualCompletedDateError}
                textHint={labels.REQUIRED}
              />
            </div>
          </>
        )}

        <Typography level="small">
          {labels.SCHEDULED_TOUR_WITH_SAME_TYPE}
        </Typography>
        <div className={classes.input_container}>
          <Dropdown
            selectedItem={markTourCompletedInformation.selectedTourType}
            items={tourScheduleService.getTourTypes()}
            onChange={(value) => setProperty('selectedTourType', value)}
          />
        </div>
      </div>
    </>
  );

  return (
    <>
      <Alert
        type="success"
        title={labels.MARK_TOUR_COMPLETED}
        confirmText={labels.CONFIRM}
        cancelText={labels.CANCEL}
        onConfirm={handleConfirm}
        onClose={() => onClose(markTourCompletedInformation)}
        isOpen={isModalOpen}
        modalBody={modalBody}
      />
    </>
  );
};

MarkTourCompletedModal.propTypes = {
  community: PropTypes.object.isRequired,
  familyFileId: PropTypes.number.isRequired,
  familyFileUserId: PropTypes.number.isRequired,
  isModalOpen: PropTypes.bool,
  labels: PropTypes.object,
  markTourCompletedInfo: PropTypes.shape({
    selectedWhenCompleted: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  tourDetails: PropTypes.object,
};

MarkTourCompletedModal.defaultProps = {
  isModalOpen: false,
  onClose: () => {},
  onOpen: () => {},
  onSave: () => {},
};

export default MarkTourCompletedModal;
