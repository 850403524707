import React from 'react';
import PropTypes from 'prop-types';
import DotDefault from 'common/Icons/maps/DotDefault';
import { useStyles } from '../Marker.style';
import clsx from 'clsx';
import SelectedDefault from 'common/Icons/maps/SelectedDefault';

const HoveredMarker = ({
  community,
  isCommunitySelected,
  changeState,
  $hover,
}) => {
  const classes = useStyles($hover);

  return (
    <>
      <button
        className={classes.pinpoint_button}
        onClick={() => changeState(community)}
      >
        {isCommunitySelected ? (
          <SelectedDefault className={clsx(classes.default, classes.common)} />
        ) : (
          <DotDefault className={clsx(classes.default, classes.common)} />
        )}
      </button>
      <div className={classes.shadow} />
    </>
  );
};

HoveredMarker.propTypes = {
  $hover: PropTypes.bool,
  community: PropTypes.object,
  changeState: PropTypes.func,
  isCommunitySelected: PropTypes.bool,
};

export default HoveredMarker;
