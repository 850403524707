import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withLabels } from 'context/LabelContext';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { withUser } from 'context/UserContext';
import useGraphQLMutation from 'hooks/useGraphQLMutation';
import { useGraphQLLazyQuery } from 'hooks/useGraphQLQuery';
import useOnWindowResize from 'hooks/useOnWindowResize';
import collapsibleChicletService from 'services/callCenter/collapsibleChicletService';
import emailService from 'services/callCenter/emailService';
import formChicletsService from 'services/callCenter/formChicletsService';
import formService from 'services/callCenter/formService';
import {
  updateFormSteps,
  updateCloseReason,
  updateDisposition,
  getManualInquiryState,
  updateWarmTransferPoolType,
  updateCollapsedState,
} from 'stateManagement/callcenter/formChiclets/actions/formChicletsActions';
import { updateInquiry as updateInquiryAction } from 'stateManagement/callcenter/inquiry/actions/inquiryActions';
import {
  selectFormChiclets,
  selectManualInquiryState,
  selectCloseReason,
} from 'stateManagement/callcenter/formChiclets/reducers/formChicletsReducer';
import { selectInquiry } from 'stateManagement/callcenter/inquiry/reducers/inquiryReducer';
import { CHICLET_CARD_STATES } from './ChicletCard/ChicletCard';
import Chiclets from './Chiclets';
import updateContactInfoMutation from './ContactInfo/updateContactInfoMutation';
import ContactsPopup from './ContactsPopup/ContactsPopup';
import createInquiryPipedaEmailLogMutation from './CreateInquiryPipedaEmailLogMutation';
import PipedaPopup from './PipedaPopup/PipedaPopup';
import ResidentPopup from './ResidentPopup/ResidentPopup';
import { inquiryProperties, stepTypes } from 'services/callCenter/fieldsMapper';
import CreateInquiryPipedaLogMutation from './CreateInquiryPipedaLogMutation';
import CreateInquiryDisclaimerLogMutation from './CreateInquiryDisclaimerLogMutation';
import LoadingPopup from '../FormChiclets/LoadingPopup';
import { logWarmTransferScreenViewed } from 'services/callCenter/amplitudeEventsService';
import DuplicateResidentQuery from 'queries/DuplicateResidentQuery';
import {
  restartWarmTransfer,
  setWarmTransferType,
  updateWarmTransferState,
} from 'stateManagement/callcenter/warmTransfer/actions/warmTransferActions';
import {
  warmTransferStates,
  warmTransferTypes,
} from 'services/callCenter/warmTransferService';
import warmTransferFormService from 'services/callCenter/warmTransferFormService';

const noOp = () => {};

let TOGGLE_POPUP = {
  show: false,
  title: null,
  waitingMessage: null,
};

const FormChiclets = ({
  currentUser,
  labels,
  onSaveForm,
  warmTransfer,
  sendingPayload,
  flags,
}) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState({});
  const [foundContacts, setFoundContacts] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [isModalPipedaOpen, setIsModalPipedaOpen] = useState(false);
  const [isContactsModalOpen, setIsContactsModalOpen] = useState(false);
  const [isResidentsModalOpen, setIsResidentsModalOpen] = useState(false);

  const { inquiry } = useSelector(selectInquiry);

  const { formSteps } = useSelector(selectFormChiclets);

  const { isScheduleCallSelected } = useSelector(selectCloseReason);
  const manualInquiryState = useSelector(selectManualInquiryState);

  const [currentContact, setCurrentContact] = useState({
    ...inquiry.inquiryContact,
  });
  const [currentState, setCurrentState] = useState(
    CHICLET_CARD_STATES.CURRENT.value,
  );

  const [updateInquiryContact] = useGraphQLMutation(updateContactInfoMutation);

  const [createInquiryPipedaEmailLog] = useGraphQLMutation(
    createInquiryPipedaEmailLogMutation,
  );

  const [createInquiryPipedaLog] = useGraphQLMutation(
    CreateInquiryPipedaLogMutation,
  );

  const [createInquiryDisclaimerLog] = useGraphQLMutation(
    CreateInquiryDisclaimerLogMutation,
  );

  const [
    familyFileDuplicatedResidents,
    { data: residentsData },
  ] = useGraphQLLazyQuery(DuplicateResidentQuery);

  useOnWindowResize(() => {
    const newFormSteps = collapsibleChicletService.updateOnResize(formSteps);
    dispatch(updateFormSteps([...newFormSteps]));
  });

  useEffect(() => {
    for (const step of formSteps) {
      const { state, number, extraFields } = step;
      const hidden = extraFields?.hidden;
      const isOptional = extraFields?.isOptional;

      if (
        !hidden &&
        !isOptional &&
        state !== CHICLET_CARD_STATES.ANSWERED.value
      ) {
        setCurrentQuestion(number);
        break;
      }
    }
  }, [formSteps]);

  useEffect(() => {
    if (residentsData) {
      if (
        residentsData.findFamilyFileDuplicatedResidents &&
        residentsData.findFamilyFileDuplicatedResidents.length > 0
      ) {
        setIsResidentsModalOpen(true);
        dispatch(restartWarmTransfer());
      } else {
        dispatch(setWarmTransferType(warmTransferTypes.ANY_SLA));
      }
    }
  }, [residentsData, dispatch]);

  const callUpdateContactMutation = async (args) => {
    let newValues = cloneDeep(args.variables);
    const newInquiry = cloneDeep(inquiry);
    if (manualInquiryState.isManualInquiry) {
      newValues = {
        ...newValues,
        isManualInquiry: manualInquiryState.isManualInquiry,
        oneId: newValues.oneId || '',
      };
    }
    const response = await updateInquiryContact({ variables: newValues });
    if (response) {
      if (response.data.updateInquiryContact.createdContact) {
        newInquiry.inquiryContact = {
          ...args.variables,
          oneId: response.data.updateInquiryContact.createdContact.oneId,
          inquiryContactId:
            response.data.updateInquiryContact.createdContact.inquiryContactId,
        };
        dispatch(updateInquiryAction(newInquiry));
      }
      return response.data.updateInquiryContact;
    }
    return [];
  };

  const validateRefferalResourceInput = async (args) => {
    let errors = '';
    let values = cloneDeep(args.variables);
    const source = parseInt(values?.source);
    const subSource = parseInt(values?.subSource);
    if (source === undefined || Number.isNaN(source)) {
      errors += 'source required,';
    }
    if (subSource === undefined || Number.isNaN(subSource)) {
      errors += 'subSource required,';
    }
    if (source <= 0) {
      errors += 'source must be a number greater than 0,';
    }
    if (subSource <= 0) {
      errors += 'subSource must be a number greater than 0,';
    }
    if (errors.length > 0) {
      throw Error(errors);
    }
  };

  const callReloadUpdateContact = (
    userInputValues,
    selectedOption,
    response,
  ) => {
    inquiry.inquiryContact.firstName = userInputValues.firstName;
    inquiry.inquiryContact.lastName = userInputValues.lastName;
    inquiry.inquiryContact.phone = userInputValues.phone;
    inquiry.inquiryContact.email = userInputValues.email;
    selectedOption.label = labels.SAVED;
    setEraseContactInfo(false);
    if (response.matchedContacts) {
      TOGGLE_POPUP.show = false;
    }
    if (response.matchedContacts && response.matchedContacts.length > 0) {
      const newInquiry = cloneDeep(inquiry);
      if (response.createdContact) {
        setCurrentContact({
          ...response.createdContact,
        });
      } else {
        if (
          !userInputValues.oneId &&
          newInquiry.inquiryContact.oneId &&
          newInquiry.inquiryContact.oneId !== ''
        ) {
          userInputValues = {
            ...userInputValues,
            oneId: newInquiry.inquiryContact.oneId,
          };
        }
        setCurrentContact({
          ...currentContact,
          ...formChicletsService.buildCurrentContact(userInputValues),
        });
      }

      response.matchedContacts.forEach((contact) => {
        return formChicletsService.mapMatchedFieldsContact(
          contact,
          userInputValues,
        );
      });
      setIsContactsModalOpen(true);
      setFoundContacts(response.matchedContacts);
    }
  };

  const callReloadNoOp = (userInputValues, selectedOption) => {
    if (inquiry.inquiryFormData) {
      inquiry.inquiryFormData.relationToResident =
        userInputValues.relationship || '';
      inquiry.inquiryFormData.resident1FirstName =
        userInputValues.resident1FirstName || '';
      inquiry.inquiryFormData.resident1LastName =
        userInputValues.resident1LastName || '';
    } else {
      inquiry.relationToResident = userInputValues.relationship || '';
      inquiry.resident1FirstName = userInputValues.resident1FirstName || '';
      inquiry.resident1LastName = userInputValues.resident1LastName || '';
    }
    selectedOption.label = labels.SAVED;
  };

  const callUpdatePreferredLocation = async (values) => {
    const variables = {
      oneId: inquiry.inquiryContact.oneId,
    };
    const result = values.variables.location;
    setInputValue(result);
    if (flags.residentDuplicateCheck) {
      familyFileDuplicatedResidents({ variables });
    }

    return formService.showPipedaChiclet(formSteps, values);
  };

  const callReloadUpdatePreferredLocation = (
    userInputValues,
    selectedOption,
  ) => {
    if (inquiry.inquiryFormData) {
      inquiry.inquiryFormData.desiredCity = userInputValues.location.city;
      inquiry.inquiryFormData.desiredState = userInputValues.location.state;
      inquiry.inquiryFormData.desiredCountry = userInputValues.location.country;
    } else {
      inquiry.desiredCity = userInputValues.location.city;
      inquiry.desiredState = userInputValues.location.state;
      inquiry.desiredCountry = userInputValues.location.country;
    }

    selectedOption.label = labels.SAVED;
  };

  const setEraseContactInfo = (shouldErase) => {
    const steps = formService.setEraseContactInfo(formSteps, shouldErase);
    dispatch(updateFormSteps(steps));
  };
  const doNotCollapsedChiclets = (selectedOption, selectedStep) => {
    if (
      selectedOption.nextStep !== -1 &&
      selectedStep.property !== 'transfer'
    ) {
      dispatch(updateCollapsedState(false));
    }
  };

  const isContactInfoEmpty = (selectedStep) => {
    return (
      manualInquiryState.isManualInquiry ||
      (selectedStep.answer.userInputValues.firstName &&
        selectedStep.answer.userInputValues.lastName &&
        selectedStep.answer.userInputValues.phone)
    );
  };

  const setTransferSlaEvent = (steps, collapsibleSteps) => {
    const initialStateTransferSLAChiclet = warmTransferFormService.findTransferToSLA(
      steps,
    );
    const finalStateTransferSLAChiclet = warmTransferFormService.findTransferToSLA(
      collapsibleSteps,
    );

    if (
      initialStateTransferSLAChiclet?.hidden &&
      !finalStateTransferSLAChiclet?.hidden
    ) {
      logWarmTransferScreenViewed(inquiry.inquiryId);
    }
  };

  const handleAnswer = async (selectedStep, selectedOption) => {
    if (selectedStep.disabled) {
      console.error('tried to answer a disabled step: ', selectedStep);
      return;
    }

    let state = currentState;
    let currentFormSteps = cloneDeep(formSteps);
    const { nextQuestion, value, disposition } = selectedOption;
    const nextStep = currentFormSteps.find((s) => s.number === nextQuestion);

    if (nextStep?.type === stepTypes.transferSla.key) {
      dispatch(updateWarmTransferState(warmTransferStates.REQUESTED));
    }

    if (
      selectedStep?.type === 'contactInfo' &&
      selectedOption.value === 'save' &&
      isContactInfoEmpty(selectedStep)
    ) {
      TOGGLE_POPUP = {
        show: true,
        title: labels.CONTACT_DUPLICATE_CHECK,
        waitingMessage: labels.CONTACT_DUPLICATE_CHECKING_WAIT_MESSAGE,
      };
    }

    if (selectedOption.extraFields?.action) {
      const actionResponse = await handleActionButton(
        selectedStep,
        selectedOption,
        newActions,
        state,
        onUserInputChange,
      );
      state = actionResponse.state;
      selectedStep = actionResponse.selectedStep;
      if (actionResponse.updatePreferredLocation) {
        currentFormSteps = actionResponse.updatePreferredLocation;
      }
      if (actionResponse.transferToSLA) {
        currentFormSteps = actionResponse.transferToSLA;
      }
      currentFormSteps = currentFormSteps.map((s) => {
        if (s.number === selectedStep?.number) {
          s.errors = selectedStep?.errors;
        }
        return s;
      });
    }
    const steps = formService.updateStepStates(
      currentFormSteps,
      nextQuestion,
      state,
    );
    const newFormSteps = formService.updateSteps(
      steps,
      selectedStep,
      selectedOption,
    );

    const updatedRelationToResidentSteps = formService.updateRelationShipBasedOnInterest(
      newFormSteps,
    );
    let collapsibleSteps = collapsibleChicletService.updateSteps(
      selectedStep,
      updatedRelationToResidentSteps,
    );

    setTransferSlaEvent(steps, collapsibleSteps);

    doNotCollapsedChiclets(selectedOption, selectedStep);

    dispatch(updateFormSteps(collapsibleSteps));
    if (selectedOption.poolType) {
      dispatch(updateWarmTransferPoolType(selectedOption.poolType));
    }
    if (selectedOption.closeInquiry) {
      dispatch(
        updateCloseReason({
          closeInquiry: selectedOption.closeInquiry,
          closeReason: selectedOption.closeReason,
          isNoAnswerSelected: false,
          isScheduleCallSelected,
          removeFromCampaign: true,
        }),
      );
    }

    const isNextQuestionCompleteCall = collapsibleSteps.find(
      (step) =>
        step.number === selectedOption.nextQuestion && step.type === 'endCall',
    );

    if (isNextQuestionCompleteCall && !selectedOption.closeInquiry) {
      dispatch(
        updateCloseReason({
          closeInquiry: false,
          closeReason: null,
          isNoAnswerSelected: false,
          isScheduleCallSelected,
          removeFromCampaign: false,
        }),
      );
    }

    if (isNextQuestionCompleteCall && selectedOption.disposition === null) {
      dispatch(updateDisposition(null));
    }

    setCurrentQuestion(nextQuestion);
    setCurrentState(state);
    const watchingFields = ['moveDateRange', 'confirmInterest'];
    if (watchingFields.includes(selectedStep.property)) {
      const newInquiry = cloneDeep(inquiry);
      if (!newInquiry.inquiryFormData) {
        newInquiry.inquiryFormData = {};
      }
      newInquiry.inquiryFormData[selectedStep.property] = selectedOption.value;
      if (
        selectedStep.property === 'confirmInterest' &&
        selectedOption.value === 'self'
      ) {
        newInquiry.inquiryFormData.relationToResident = 'SELF';
        newInquiry.relationToResident = 'SELF';
      }
      dispatch(updateInquiryAction(newInquiry));
    }

    if (!selectedStep.errors || selectedStep.errors.length === 0) {
      const formStatus = nextQuestion === -1 ? 'closed' : 'open';
      if (!manualInquiryState.isManualInquiry) {
        onSaveForm(updatedRelationToResidentSteps, value, formStatus);
      }
    }

    if (disposition) {
      dispatch(updateDisposition(disposition));
    }

    if (selectedStep.type === stepTypes.recordingDisclaimer.key) {
      const variablesInquiryDisclaimerLog = {
        variables: {
          inquiryId: inquiry.inquiryId,
          value: selectedOption.value,
        },
      };
      await createInquiryDisclaimerLog(variablesInquiryDisclaimerLog);
    }

    if (selectedStep.property === inquiryProperties.pipedaDisclosure.key) {
      const variablesInquiryPipedaLog = {
        variables: {
          inquiryId: inquiry.inquiryId,
          value: selectedOption.value,
        },
      };
      await createInquiryPipedaLog(variablesInquiryPipedaLog);
    }
  };

  const handleActionButton = async (
    selectedStep,
    selectedOption,
    newActions,
    state,
    onUserInputChange,
  ) => {
    let lastSelectedStep = cloneDeep(selectedStep);
    const contactInfoValues = formSteps.find(
      (step) => step.property === 'contactInfo',
    );

    if (
      selectedStep.property === 'contactInfo' &&
      contactInfoValues.answer.userInputValues &&
      contactInfoValues.answer.userInputValues.firstName &&
      contactInfoValues.answer.userInputValues.lastName &&
      contactInfoValues.answer.userInputValues.phone
    ) {
      lastSelectedStep = contactInfoValues;
    }

    if (selectedStep.property === 'notes') {
      return formService.showFamilyInfoStep(formSteps);
    }
    const response = await formService.handleActionButton(
      lastSelectedStep,
      selectedOption,
      newActions,
      state,
      onUserInputChange,
      selectedOption.extraFields.action,
    );
    if (manualInquiryState.isManualInquiry) {
      TOGGLE_POPUP.show = false;
      if (response.state === 'CURRENT_WITH_ERROR') {
        dispatch(
          getManualInquiryState({
            isManualInquiry: true,
            isCreatedManualInquiry: false,
            hasErrors: true,
          }),
        );
      } else {
        dispatch(
          getManualInquiryState({
            isManualInquiry: true,
            isCreatedManualInquiry: false,
            hasErrors: false,
          }),
        );
      }
    }

    return response;
  };

  const newActions = {
    updateInquiryContact: {
      action: callUpdateContactMutation,
      reload: callReloadUpdateContact,
    },
    fakeSaveMethod: {
      action: noOp,
      reload: callReloadNoOp,
    },
    referralResource: {
      action: validateRefferalResourceInput,
      reload: (userInputValues, selectedOption) => {
        selectedOption.label = labels.SAVED;
      },
    },
    eraseContactInfoFields: {
      action: () => setEraseContactInfo(true),
      reload: noOp,
    },
    keepContactInfoFields: {
      action: () => setEraseContactInfo(false),
      reload: noOp,
    },
    updatePreferredLocation: {
      action: callUpdatePreferredLocation,
      reload: callReloadUpdatePreferredLocation,
    },
    readPipeda: {
      action: () => setIsModalPipedaOpen(true),
      reload: noOp,
    },
    findBestSLAAvailable: {
      action: warmTransfer?.initiateWarmTransfer,
      reload: noOp,
    },
    transferToSLA: {
      action: warmTransfer?.transferToSLA,
      reload: noOp,
    },
    completeWarmTransfer: {
      action: warmTransfer?.completeWarmTransfer,
      reload: noOp,
    },
    completeWarmTransferFamilyDisconnected: {
      action: warmTransfer?.completeWarmTransferFamilyLeft,
      reload: noOp,
    },
    sendPipedaEmail: {
      action: () =>
        emailService.sendPipedaEmail(
          inquiry,
          labels,
          currentUser,
          createInquiryPipedaEmailLog,
        ),
      reload: noOp,
    },
    completeCallAction: {
      action: noOp,
      reload: noOp,
    },
  };

  const getStepCollapsedOrHidden = (step) => {
    if (manualInquiryState.isManualInquiry) return { ...step, collapsed: true };
    return { ...step, hidden: true };
  };

  const closeOptionalStep = (stepToInclude) => {
    const newFormSteps = cloneDeep(formSteps);
    const updatedSteps = newFormSteps.map((step) => {
      if (step.extraFields && step.number === stepToInclude.number) {
        return getStepCollapsedOrHidden(step);
      }
      return step;
    });

    dispatch(updateCollapsedState(true));

    const newSteps = formService.updateStepStates(updatedSteps, {}, 'CURRENT');
    dispatch(updateFormSteps(newSteps));
  };

  const onUserInputChange = (step, answer) => {
    answer.label = labels.SAVE;
    step.answer = { ...answer };
    const steps = formService.updateStepAnswer(formSteps, step, answer);
    dispatch(updateFormSteps(steps));
  };

  const onManyChoiceChange = (step, selectedOptions) => {
    const steps = formService.updateManyChoiceStepAnswer(
      formSteps,
      step,
      selectedOptions,
    );
    dispatch(updateFormSteps(steps));
    if (!manualInquiryState.isManualInquiry) onSaveForm(steps);
  };

  const onTextInputGroupSave = (step, textAnswer) => {
    const steps = formService.updateTextInputGroupAnswer(
      formSteps,
      step,
      textAnswer,
    );
    dispatch(updateFormSteps(steps));
    if (!manualInquiryState.isManualInquiry) onSaveForm(steps);
  };

  const renderChiclets = () => {
    const steps = formService.updateStepStates(
      formSteps,
      currentQuestion,
      currentState,
    );
    return (
      <Chiclets
        steps={steps}
        inquiry={inquiry}
        currentUser={currentUser}
        labels={labels}
        warmTransfer={warmTransfer}
        handleAnswer={handleAnswer}
        closeOptionalStep={closeOptionalStep}
        onUserInputChange={onUserInputChange}
        onManyChoiceChange={onManyChoiceChange}
        onTextInputGroupSave={onTextInputGroupSave}
      />
    );
  };

  const renderPipedaPopUp = () => {
    return (
      <PipedaPopup
        isOpen={isModalPipedaOpen}
        onClose={() => setIsModalPipedaOpen(false)}
        labels={labels}
      />
    );
  };

  const renderContactsPopUp = () => {
    return (
      <ContactsPopup
        currentContact={currentContact}
        inquiry={inquiry}
        onClose={() => setIsContactsModalOpen(false)}
        isOpen={isContactsModalOpen}
        labels={labels}
        contacts={foundContacts}
        action={newActions['updateInquiryContact']}
      />
    );
  };

  const renderLoadingPopUp = () => {
    return (
      <LoadingPopup
        isOpen={TOGGLE_POPUP.show}
        title={TOGGLE_POPUP.title}
        waitingMessage={TOGGLE_POPUP.waitingMessage}
      />
    );
  };

  const renderResidentPopUp = () => {
    let residentResponse = [];

    if (residentsData && residentsData.findFamilyFileDuplicatedResidents) {
      residentResponse = residentsData.findFamilyFileDuplicatedResidents;
    }
    return (
      <ResidentPopup
        userInputValues={inputValue}
        isOpen={isResidentsModalOpen}
        inquiry={inquiry}
        foundResident={residentResponse}
        onClose={() => setIsResidentsModalOpen(false)}
        labels={labels}
        sendUpdateInquiryPayload={sendingPayload}
      />
    );
  };

  return (
    <Fragment>
      {renderResidentPopUp()}
      {renderLoadingPopUp()}
      {renderContactsPopUp()}
      {renderPipedaPopUp()}
      {renderChiclets()}
    </Fragment>
  );
};

FormChiclets.propTypes = {
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    __typename: PropTypes.string,
  }),
  labels: PropTypes.object,
  onSaveForm: PropTypes.func,
  warmTransfer: PropTypes.object,
  flags: PropTypes.shape({
    residentDuplicateCheck: PropTypes.bool,
  }),
  sendingPayload: PropTypes.func,
};

export default withLDConsumer()(withLabels(withUser(FormChiclets)));
