import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      height: theme.spacing(75),
      padding: theme.spacing(3),
      borderRadius: '4px',
      fontFamily: 'Poppins',
      backgroundColor: theme.palette.light.platinum5,
      boxShadow: '0px 0px 8px rgba(186, 186, 184, 0.3)',
      display: 'flex',
    },
    content: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      margin: '31px 0 25px 0',
    },
    header_content: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '16px',
    },
    external_call_component: {
      marginRight: '20px',
    },
  };
});

export { useStyles };
