import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';

const Media = ({ xs, sm, md, lg, xl }) => {
  const getMediaToHide = (media) => {
    switch (media) {
      case 'xs':
        if (sm) {
          return ['sm', 'md', 'lg', 'xl'];
        }
        if (md) {
          return ['md', 'lg', 'xl'];
        }
        if (lg) {
          return ['lg', 'xl'];
        }
        if (xl) {
          return ['xl'];
        }
        return [];
      case 'sm':
        if (md) {
          return ['xs', 'md', 'lg', 'xl'];
        }
        if (lg) {
          return ['xs', 'lg', 'xl'];
        }
        if (xl) {
          return ['xs', 'xl'];
        }
        return ['xs'];
      case 'md':
        if (lg) {
          return ['xs', 'sm', 'lg', 'xl'];
        }
        if (xl) {
          return ['xs', 'sm', 'xl'];
        }
        return ['xs', 'sm'];
      case 'lg':
        if (xl) {
          return ['xs', 'sm', 'md', 'xl'];
        }
        return ['xs', 'sm', 'md'];
      case 'xl':
        return ['xs', 'sm', 'md', 'lg'];
      default:
        return ['xs', 'sm', 'md', 'lg'];
    }
  };

  return (
    <Grid>
      {xs && (
        <Hidden only={getMediaToHide('xs')}>
          <Grid item xs={12} style={{ height: '100%' }}>
            {xs}
          </Grid>
        </Hidden>
      )}

      {sm && (
        <Hidden only={getMediaToHide('sm')}>
          <Grid item sm={12} style={{ height: '100%' }}>
            {sm}
          </Grid>
        </Hidden>
      )}

      {md && (
        <Hidden only={getMediaToHide('md')}>
          <Grid item md={12} style={{ height: '100%' }}>
            {md}
          </Grid>
        </Hidden>
      )}

      {lg && (
        <Hidden only={getMediaToHide('lg')}>
          <Grid item lg={12} style={{ height: '100%' }}>
            {lg}
          </Grid>
        </Hidden>
      )}

      {xl && (
        <Hidden only={getMediaToHide('xl')}>
          <Grid item xl={12} style={{ height: '100%' }}>
            {xl}
          </Grid>
        </Hidden>
      )}
    </Grid>
  );
};

Media.propTypes = {
  xs: PropTypes.node,
  sm: PropTypes.node,
  md: PropTypes.node,
  lg: PropTypes.node,
  xl: PropTypes.node,
};

export default Media;
