import { PhoneContext } from '@aplaceformom/owl-connect-client';
import clsx from 'clsx';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import React, { Fragment, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateTimePickerModal from 'common/DateTimePickerModal/DateTimePickerModal';
import CalendarCreate from 'common/Icons/time/CalendarCreate';
import Typography from 'common/Typography/Typography';
import allCallStates, {
  activeConferenceStates,
  callPreviewStates,
  getCallStateLabel,
  getCallStatus,
  getCallType,
  callTypes,
} from 'config/callStates.config';
import { withLabels } from 'context/LabelContext';
import useGraphQLMutation from 'hooks/useGraphQLMutation';
import { useInterval } from 'hooks/useInterval';
import {
  STATUS_DETAILS,
  telephonyEventReasons,
} from 'services/callCenter/enums';
import { logCallbackScheduled } from 'services/callCenter/amplitudeEventsService';
import {
  updateCloseReason,
  updateCollapsedState,
  updateDisposition,
} from 'stateManagement/callcenter/formChiclets/actions/formChicletsActions';
import { selectInquiry } from 'stateManagement/callcenter/inquiry/reducers/inquiryReducer';
import { showTelephonyIframe } from 'stateManagement/telephony/actions/telephonyActions';
import { formatPhoneWithParenthesis } from 'utils/phoneFormat';
import { getReadableDateWithTime } from 'utils/dateFormat';
import withConfig from 'utils/withConfig';
import addCallToListMutation from '../telephony/addCallToListMutation';
import { useStyles } from './CallBar.style';
import { callBarScreens, CallBarScreenType } from './callBarScreenEnum';
import ConferenceCallButtons from './ConferenceCallButtons/ConferenceCallButtons';
import SquareButton from './ConferenceCallButtons/SquareButton/SquareButton';
import TransferDropdown from './ConferenceCallButtons/TransferDropdown/TransferDropdown';

const LIST_NAME = withConfig('CALL_BACK_LIST_NAME');
const CAMPAIGN_NAME = withConfig('CALL_BACK_CAMPAIGN_NAME');

const LIST_NAME_TEST = withConfig('CALL_BACK_LIST_NAME_TEST');
const CAMPAIGN_NAME_TEST = withConfig('CALL_BACK_CAMPAIGN_NAME_TEST');

const CallBar = ({
  labels,
  onEndCall,
  onTransfer,
  onReschedule,
  onRedial,
  flags,
  rescheduleDisposition,
  onUpdateStatusDetail,
  screen,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { inquiry } = useSelector(selectInquiry);
  const [isDateTimePickerOpen, setIsDateTimePickerOpen] = useState(false);
  const {
    activeCampaigns,
    callData,
    callState,
    activeContact,
    auth,
    callPreview,
    makePreviewCall,
    agentState,
    activeConference,
    isHolding,
  } = useContext(PhoneContext);
  const { number, campaignName } = callData;
  const callType = getCallType(
    activeCampaigns,
    callData,
    callPreview,
    callState,
  );

  const authString = JSON.stringify(auth);
  const { oneId } = activeContact;
  const callClass = getCallStatus(callState, callPreview);
  const [addCallToList] = useGraphQLMutation(addCallToListMutation);
  const [previewCallCountDown, setPreviewCallCountDown] = useState(-5);
  const [hasAnswer, setHasAnswer] = useState(false);
  const [elapsedCallSeconds, setElapsedCallSeconds] = useState(0);
  const REFETCH_TIMER = 1000;

  const handleCallState = async () => {
    if (inquiry?.state === 'closed') {
      setPreviewCallCountDown(-5);
      setElapsedCallSeconds(0);
    }

    if (
      agentState.name === 'Ready' &&
      callPreview?.state === callPreviewStates.PREVIEW.key
    ) {
      if (previewCallCountDown === 0) {
        if (!flags.autoDialObCalls) {
          makePreviewCall();
          onUpdateStatusDetail(STATUS_DETAILS.ATTEMPTED);
        }
      } else {
        setPreviewCallCountDown(previewCallCountDown + 1);
      }
      setElapsedCallSeconds(0);
    }

    if (
      callState === allCallStates.TALKING.key ||
      callState === allCallStates.ON_HOLD.key
    ) {
      if (!hasAnswer) {
        setHasAnswer(true);
        onUpdateStatusDetail(STATUS_DETAILS.CONNECTED);
      }
      setPreviewCallCountDown(-5);
      setElapsedCallSeconds(elapsedCallSeconds + 1);
    }

    if (agentState.name !== 'Ready') {
      setPreviewCallCountDown(-5);
      setElapsedCallSeconds(0);
    }
  };

  useInterval(handleCallState, REFETCH_TIMER);

  const humanizeSeconds = (elapsedSeconds) => {
    return new Date(1000 * Math.abs(elapsedSeconds))
      .toISOString()
      .substr(11, 8);
  };

  const getActiveConferenceInfo = () => {
    if (!activeConference || Object.keys(activeConference).length === 0) {
      return '';
    }
    if (
      callState === allCallStates.TALKING.key &&
      activeConference.state !== activeConferenceStates.FINISHED.key
    ) {
      return getCallStateLabel(activeConference.state) || '';
    }

    if (
      callState === allCallStates.TALKING.key &&
      activeConference.state === activeConferenceStates.FINISHED.key &&
      callPreview?.state === callPreviewStates.FINISHED.key
    ) {
      return 'Conference Agent Left';
    }

    if (
      callState === allCallStates.WRAP_UP.key &&
      activeConference.state !== activeConferenceStates.FINISHED.key
    ) {
      return 'Conference Ongoing';
    }

    if (
      callState === allCallStates.WRAP_UP.key &&
      activeConference.state === activeConferenceStates.FINISHED.key &&
      callPreview?.state === callPreviewStates.FINISHED.key
    ) {
      return 'Conference Finished';
    }
    return '';
  };

  const getOBCallInfo = () => {
    const { eventReason } = activeContact;

    if (agentState?.name !== 'Ready') {
      return '';
    }
    if (callPreview?.state === callPreviewStates.PREVIEW.key) {
      return `Outbound Call (Dialing in ${humanizeSeconds(
        previewCallCountDown,
      )})`;
    }

    if (callState === allCallStates.RINGING_ON_OTHER_SIDE.key) {
      return 'Outbound Call Dialing...';
    }

    if (
      callState === allCallStates.TALKING.key ||
      callState === allCallStates.ON_HOLD.key
    ) {
      return `Outbound Call ${isHolding ? '| On hold ' : ''}(${humanizeSeconds(
        elapsedCallSeconds,
      )})`;
    }

    if (
      callState === allCallStates.WRAP_UP.key &&
      eventReason === telephonyEventReasons.DISCONNECTED_BY_CALLER
    ) {
      return labels.DISCONNECTED_BY_CALLER;
    }
    if (
      callState === allCallStates.WRAP_UP.key &&
      eventReason === telephonyEventReasons.DISCONNECTED_BY_AGENT
    ) {
      return labels.CALL_ENDED;
    }

    if (callState === null) {
      return 'Finished';
    }

    return '';
  };

  const getFormattedActiveConferenceInfo = () => {
    const state = getActiveConferenceInfo();
    return state === '' ? '' : `${state} | `;
  };

  const CallInfo = () => {
    if (screen === callBarScreens.WAITING) {
      return <Typography color="platinum5">{labels.WAITING_ROOM}</Typography>;
    }
    return (
      <Typography color="platinum5">
        {getFormattedActiveConferenceInfo()}
        {callType === callTypes.OUTBOUND
          ? getOBCallInfo()
          : callType?.label || ''}
      </Typography>
    );
  };

  const getName = () => {
    let completeName = labels.UNKNOWN;
    if (inquiry && inquiry.firstName && inquiry.lastName) {
      completeName = `${inquiry.firstName} ${inquiry.lastName}`;
    } else if (
      inquiry &&
      inquiry.inquiryContact &&
      inquiry.inquiryContact.lastName &&
      inquiry.inquiryContact.firstName
    ) {
      completeName = `${inquiry.inquiryContact.firstName} ${inquiry.inquiryContact.lastName}`;
    }
    return completeName;
  };

  const getListAndCampaign = (inquiry) => {
    const AUTOMATION_EMAIL_CONTENT = 'srcautomation';
    let listNameDefault = LIST_NAME;
    let campaignNameDefault = CAMPAIGN_NAME;
    if (inquiry.inquiryContact?.email?.includes(AUTOMATION_EMAIL_CONTENT)) {
      listNameDefault = LIST_NAME_TEST;
      campaignNameDefault = CAMPAIGN_NAME_TEST;
    }
    return { campaignNameDefault, listNameDefault };
  };

  const CallDetails = () => {
    if (screen === callBarScreens.WAITING) {
      return (
        <Typography color="platinum5">
          {labels.NEXT_CALL_WILL_START_SOON}
        </Typography>
      );
    }

    const getNumber = () => {
      let number = '';
      if (
        callPreview?.state === callPreviewStates.PREVIEW.key &&
        callPreview?.contact?.phone
      ) {
        number = callPreview?.contact?.phone;
      }
      if (
        callPreview?.state !== callPreviewStates.PREVIEW.key &&
        activeContact?.phone
      ) {
        number = activeContact?.phone;
      }
      return formatPhoneWithParenthesis(number);
    };

    return (
      <Fragment>
        <Typography bold color="platinum5">
          {callType === callTypes.INBOUND ? '' : getName()}
        </Typography>
        &nbsp;&nbsp;
        <Typography color="platinum5">{getNumber()}</Typography>
      </Fragment>
    );
  };

  const saveDateTimeInfo = async (dateObj) => {
    dispatch(updateCollapsedState(true));
    logCallbackScheduled(
      inquiry?.inquiryId,
      inquiry?.familyFileId,
      getReadableDateWithTime(dateObj),
    );
    onReschedule(dateObj);

    const { campaignNameDefault, listNameDefault } = getListAndCampaign(
      inquiry,
    );

    const response = await addCallToList({
      variables: {
        inquiryId: inquiry?.inquiryId,
        provider: 'five9',
        phoneNumber: number,
        campaignName:
          campaignNameDefault === 'RETRIEVE_FROM_CALL_DATA'
            ? campaignName
            : campaignNameDefault,
        listName: listNameDefault,
        auth: authString,
        callTime: dateObj,
        oneId: oneId,
      },
    });

    if (response.data?.addCallToList) {
      onUpdateStatusDetail(STATUS_DETAILS.CONTACTED);
      dispatch(
        updateCloseReason({
          closeInquiry: false,
          closeReason: null,
          isNoAnswerSelected: false,
          isScheduleCallSelected: true,
          removeFromCampaign: false,
        }),
      );
      dispatch(updateDisposition(rescheduleDisposition));
    }
    return response;
  };

  const isDisabled =
    callState !== allCallStates.TALKING.key ||
    screen === callBarScreens.WAITING;

  return (
    <div className={clsx(classes.call_bar_container, callClass)}>
      <div className={classes.left}>
        <div>
          <CallInfo />
          <div className={classes.contact_info}>
            <CallDetails />
          </div>
        </div>
      </div>
      <div className={classes.center}>
        <ConferenceCallButtons
          onEndCall={onEndCall}
          callStatus={callClass}
          onRedial={onRedial}
          onConferenceCall={() => dispatch(showTelephonyIframe())}
          screen={screen}
        />
      </div>
      <div className={classes.right}>
        <div className={classes.divider} />

        <SquareButton
          icon={
            <CalendarCreate className={clsx(isDisabled, classes.disabled)} />
          }
          title={isDisabled ? '' : labels.SCHEDULE_CALLBACK}
          onClick={() => setIsDateTimePickerOpen(true)}
          disabled={isDisabled}
        />
        <DateTimePickerModal
          title={labels.SCHEDULE_CALLBACK}
          isOpen={isDateTimePickerOpen}
          onClose={() => setIsDateTimePickerOpen(false)}
          onSave={saveDateTimeInfo}
          className="invisible"
          disablePast
        />

        <TransferDropdown onChange={onTransfer} disabled={isDisabled} />
      </div>
    </div>
  );
};

CallBar.propTypes = {
  labels: PropTypes.object,
  onEndCall: PropTypes.func,
  onReschedule: PropTypes.func,
  onTransfer: PropTypes.func,
  onRedial: PropTypes.func,
  onUpdateStatusDetail: PropTypes.func,
  flags: PropTypes.shape({
    autoDialObCalls: PropTypes.bool,
  }),
  rescheduleDisposition: PropTypes.string,
  screen: CallBarScreenType.isRequired,
};

CallBar.defaultProps = {
  onEndCall: () => {},
  onTransfer: () => {},
  onRedial: () => {},
  onReschedule: () => {},
  onUpdateStatusDetail: () => {},
};

export default withLDConsumer()(withLabels(CallBar));
