import {
  GET_SCRIPT_BY_SLUG_ERROR,
  GET_SCRIPT_BY_SLUG_REQUEST,
  GET_SCRIPT_BY_SLUG_SUCCESS,
  UPDATE_PREVIEW_FORM_STEPS,
} from '../actions/scriptPreviewActions';

const scriptPreviewReducer = (
  state = { loading: false, error: null, data: null, formSteps: null },
  action,
) => {
  switch (action.type) {
    case GET_SCRIPT_BY_SLUG_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_SCRIPT_BY_SLUG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.response,
      };
    case GET_SCRIPT_BY_SLUG_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        data: null,
      };
    case UPDATE_PREVIEW_FORM_STEPS:
      return {
        ...state,
        formSteps: action.payload.formSteps,
      };
    default:
      return state;
  }
};

export const selectScriptPreview = (state) => ({
  scriptPreviewLoading: state?.admin?.scriptPreview?.loading,
  scriptPreviewError: state?.admin?.scriptPreview?.error,
  scriptPreview: state?.admin?.scriptPreview?.data,
  scriptPreviewSteps: state?.admin?.scriptPreview?.formSteps,
});

export default scriptPreviewReducer;
