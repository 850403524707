import React from 'react';
import Tag from 'common/Tag/Tag';
import PropTypes from 'prop-types';
import { useStyles } from './ReferredCommunityStatus.style';
import labels from 'config/labels';

const ReferredCommunityStatus = ({ status }) => {
  const classes = useStyles();
  const getColor = () => {
    if (status === labels.CLOSED) return 'smokyTopaz2';
    return 'wintergreenDream5';
  };

  return (
    <div className={classes.referred_community_status_container}>
      {status && <Tag text={status} color={getColor()} />}
    </div>
  );
};

ReferredCommunityStatus.propTypes = {
  status: PropTypes.string,
};

export default ReferredCommunityStatus;
