import gql from 'graphql-tag';

export default gql`
  mutation($communityIds: [Int!]!, $familyFileId: Int!, $oneId: String) {
    deleteCommunities(
      input: {
        familyFileId: $familyFileId
        communityIds: $communityIds
        oneId: $oneId
      }
    ) {
      count
    }
  }
`;
