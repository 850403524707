import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => ({
  community_care_types_container: {
    display: 'flex',
  },
  oneLetterTag: {
    padding: '4px 10px !important',
    marginBottom: '5px',
    marginRight: '4px',
  },
}));

export { useStyles };
