import restClient from '../utils/restClient';
import withConfig from '../utils/withConfig';

const states = {
  OR: 'Oregon',
  AZ: 'Arizona',
  CO: 'Colorado',
  WA: 'Washington',
};

const disclosureTypes = new Map()
  .set(1, {
    name: 'Washington',
    stateCode: 'WA',
  })
  .set(5, {
    name: 'Oregon',
    stateCode: 'OR',
  })
  .set(6, {
    name: 'Arizona',
    stateCode: 'AZ',
  })
  .set(7, {
    name: 'Colorado',
    stateCode: 'CO',
  })
  .set(9, {
    name: 'Colorado',
    stateCode: 'CO',
  });

const isDisclosureState = (stateCode) => {
  const disclosureStates = Object.keys(states);
  return disclosureStates.indexOf(stateCode) >= 0;
};

const isDisclosureStateWithoutDisclosure = (
  stateCode,
  stateCodesWithCompletedDisclosures,
) => {
  if (
    isDisclosureState(stateCode) &&
    !stateCodesWithCompletedDisclosures.includes(stateCode)
  ) {
    return true;
  }
  return false;
};

const filterDisclosureStates = (stateCodes) => {
  const disclosureStateCodes = Object.keys(states);
  const filteredStateCodes = stateCodes.filter((stateCode) =>
    disclosureStateCodes.includes(stateCode),
  );
  return filteredStateCodes;
};

const getDisclosureStateName = (state) => states[state];

const getDisclosureStateNames = (stateCodes) => {
  const stateNames = stateCodes.map((stateCode) => {
    return states[stateCode];
  });
  const uniqueStateNames = [...new Set(stateNames)];
  return uniqueStateNames;
};

const getExternalId = async (familyFileId) => {
  const graphQlUrl = withConfig('GRAPHQL_URL');
  let familyFileExternalIdQuery =
    'query findFamilyFileById ($familyFileId: Int) {';
  familyFileExternalIdQuery += ' findFamilyFileById(id: $familyFileId)';
  familyFileExternalIdQuery += ' {externalId}}';
  try {
    const externalIdResponse = await restClient.graphql(graphQlUrl, {
      operationName: 'findFamilyFileById',
      variables: { familyFileId },
      query: familyFileExternalIdQuery,
    });
    return externalIdResponse?.data?.data?.findFamilyFileById?.externalId;
  } catch (exception) {
    console.error(
      `disclosureService. Error getting external id for familyFileId: ${familyFileId}. Error: ${exception}`,
    );
  }
  return null;
};

const stateCodesWithCompletedDisclosures = async (familyFileId) => {
  const externalId = await getExternalId(familyFileId);
  if (!externalId) {
    console.error(
      `disclosureService. Could not get external id for familyFileId: ${familyFileId}.`,
    );
    return [];
  }

  const url = withConfig('YGL_DISCLOSURE_URL', { yglLeadId: externalId });
  try {
    const disclosureStateCodes = [];
    const response = await restClient.get(url);
    const disclosures = response.data;

    disclosures.forEach((disclosure) => {
      const value = disclosureTypes.get(disclosure.disclosureId);
      if (value !== undefined) {
        disclosureStateCodes.push(value.stateCode);
      }
    });

    return disclosureStateCodes;
  } catch (exception) {
    console.error(
      `disclosureService. Error getting disclosure details for familyFileId: ${familyFileId}. Error: ${exception}`,
    );
  }
};

export default {
  filterDisclosureStates,
  isDisclosureState,
  isDisclosureStateWithoutDisclosure,
  stateCodesWithCompletedDisclosures,
  getDisclosureStateName,
  getDisclosureStateNames,
};
