import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './Breadcrumb.style';
import { Breadcrumbs } from '@material-ui/core';
import Typography from '../../common/Typography/Typography';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ separator, items, showLastItemAsLink }) => {
  const classes = useStyles();

  const lastItem = items.pop();

  return (
    <Breadcrumbs separator={separator}>
      {items.map((item) => (
        <Link
          key={item.to}
          className={classes.link}
          to={item.to}
          onClick={item.onClick}
        >
          {item.icon && item.icon}
          {item.label && item.label}
        </Link>
      ))}
      {showLastItemAsLink && (
        <Link
          key={lastItem.to}
          className={classes.link}
          to={lastItem.to}
          onClick={lastItem.onClick}
        >
          {lastItem.icon && lastItem.icon}
          {lastItem.label && lastItem.label}
        </Link>
      )}
      {!showLastItemAsLink && (
        <Typography className={classes.link}>
          {lastItem.icon && lastItem.icon}
          {lastItem.label && lastItem.label}
        </Typography>
      )}
    </Breadcrumbs>
  );
};

Breadcrumb.propTypes = {
  separator: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string.isRequired,
      icon: PropTypes.any,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  showLastItemAsLink: PropTypes.bool,
};

Breadcrumb.defaultProps = {
  showLastItemAsLink: false,
};

export default Breadcrumb;
