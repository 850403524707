import gql from 'graphql-tag';

export default gql`
  mutation(
    $communityId: Int!
    $familyFileId: Int!
    $oneId: String
    $desiredZipCode: String
  ) {
    saveCommunity(
      input: {
        communityId: $communityId
        familyFileId: $familyFileId
        oneId: $oneId
        desiredZip: $desiredZipCode
      }
    ) {
      familyFileId
    }
  }
`;
