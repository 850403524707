import React from 'react';
import MetricHeader from './MetricHeader';
import MetricFooter from './MetricFooter';
import MetricBody from './MetricBody';
import { useStyles } from './Metric.style';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isSameSize from 'utils/sizesHelper';

const Metric = ({
  percentageAccomplished,
  metricTitle,
  size,
  numberOfTours,
  totalOfReferredContacts,
  metricLimits,
  labels,
}) => {
  const classes = useStyles();

  const useSMDownClass = isSameSize(size, 'xs') || isSameSize(size, 'sm');

  const selectClasses = clsx([
    classes.tours_scheduled_container,
    useSMDownClass ? classes.tours_scheduled_container_bellow_small_size : '',
  ]);

  return (
    <div className={selectClasses}>
      <MetricHeader labels={labels} metricTitle={metricTitle} size={size} />
      <MetricBody
        percentageAccomplished={percentageAccomplished}
        metricLimits={metricLimits}
        size={size}
        labels={labels}
      />
      <MetricFooter
        numberOfTours={numberOfTours}
        totalOfReferredContacts={totalOfReferredContacts}
        labels={labels}
      />
    </div>
  );
};

Metric.propTypes = {
  percentageAccomplished: PropTypes.number,
  metricTitle: PropTypes.string,
  size: PropTypes.string,
  numberOfTours: PropTypes.number,
  totalOfReferredContacts: PropTypes.number,
  metricLimits: PropTypes.shape({
    low: PropTypes.number.isRequired,
    goal: PropTypes.number.isRequired,
    high: PropTypes.number.isRequired,
    lowest: PropTypes.number.isRequired,
  }),
  labels: PropTypes.object,
};

export default Metric;
