import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    tooltip: {
      backgroundColor: theme.palette.light.platinum5,
      border: `1px solid ${theme.palette.neutral.cosmicCobalt5}`,
      boxShadow: '0px 0px 8px rgba(186, 186, 184, 0.3)',
      borderRadius: '2px',
      color: theme.palette.dark.eerieBlack1,
      fontSize: '12px',
      padding: '0px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      margin: '2px',
    },
  };
});

export { useStyles };
