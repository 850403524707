import React from 'react';
import PropTypes from 'prop-types';

const OneBedroom = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7257 11.1117V5.55075C19.7257 4.41996 18.8057 3.5 17.6749 3.5H6.32506C5.19427 3.5 4.27438 4.41996 4.27438 5.55075V11.1117C2.97141 11.4251 2 12.5996 2 13.9974V18.1452C2 18.4743 2.26676 18.741 2.59582 18.741H4.27434V20.1821C4.27434 20.5112 4.5411 20.7779 4.87017 20.7779C5.19923 20.7779 5.46599 20.5112 5.46599 20.1821V18.741H18.534V20.1821C18.534 20.5112 18.8008 20.7779 19.1298 20.7779C19.4589 20.7779 19.7257 20.5112 19.7257 20.1821V18.741H21.4042C21.7332 18.741 22 18.4743 22 18.1452V13.9974C22.0001 12.5996 21.0287 11.4251 19.7257 11.1117ZM5.46603 5.55075C5.46603 5.07708 5.85142 4.69168 6.32506 4.69168H17.675C18.1487 4.69168 18.534 5.07708 18.534 5.55075V11.029H16.5651V10.089C16.5651 8.53154 15.298 7.26451 13.7406 7.26451H10.2595C8.7021 7.26451 7.43506 8.53158 7.43506 10.089V11.0289H5.46603V5.55075ZM15.3735 10.089V11.0289H8.62663V10.089C8.62663 9.18862 9.35909 8.45615 10.2594 8.45615H13.7406C14.6409 8.45611 15.3735 9.18866 15.3735 10.089ZM3.19165 17.5494V13.9974C3.19165 13.0177 3.98872 12.2206 4.96845 12.2206H19.0316C20.0113 12.2206 20.8084 13.0177 20.8084 13.9974V17.5494H3.19165Z"
        fill="currentColor"
      />
    </svg>
  );
};

OneBedroom.propTypes = {
  className: PropTypes.string,
};

export default OneBedroom;
