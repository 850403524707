export default {
  ARE_YOU_SURE: 'Are you sure you want to cancel the SLA search?',
  CONFIRM_STOP: 'Stop Search',
  CONTINUE_SEARCH: 'Continue Searching',
  REQUESTING_EXISTING_SLA: 'Trying to Contact SLA. Please Wait!',
  REQUESTING_SLAS: `Requesting SLAs. Please wait ...`,
  SLA_MATCH_COPY: `Your Senior Living Advisor is John Doe.`,
  SLA_SEARCH_P1: `We have hundreds of Advisors that are experts in senior living and care. Your Advisor can customize a list of options based on your care needs, budget, and location.`,
  SLA_SEARCH_P2: `We help over 300,000 families through this process every year and our service is free to you.`,
  STOP_SEARCH: `Stop SLA Search?`,
};
