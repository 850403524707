import PropTypes from 'prop-types';
import React from 'react';
import Divider from 'common/Divider/Divider';
import ButtonBar from '../common/ButtonBar/ButtonBar';
import ViewRow from '../common/ViewRow/ViewRow';
import { ScriptPropType } from '../scriptBuilderPropTypes';
import { useStyles } from './ScriptView.style';

const ScriptView = ({
  script,
  onAddStep,
  onEdit,
  onDuplicate,
  onDelete,
  onExport,
  onImport,
  onSort,
  labels,
  onViewHistory,
}) => {
  const classes = useStyles();

  const targetMap = {
    self_urgent_financially_qualified: 'Self Urgent Financially Qualified',
    health_care: 'Health Care Test',
  };
  return (
    <div>
      <ButtonBar
        withPreview={script.slug}
        onDelete={
          script.steps && script.steps.length === 0
            ? () => onDelete(script)
            : null
        }
        onAdd={() => onAddStep(script)}
        onDuplicate={() => onDuplicate(script)}
        onEdit={() => onEdit(script)}
        onExport={() => onExport()}
        onImport={() => onImport()}
        addLabel={labels.ADD_STEP}
        duplicateLabel={labels.DUPLICATE_SCRIPT}
        onSort={onSort}
        sortLabel={labels.SORT_STEPS}
        labels={labels}
        onViewHistory={() => onViewHistory()}
      />
      <div className={classes.body}>
        <ViewRow label={labels.NAME} value={script.name} />
        <ViewRow label={labels.TARGET} value={script.target} />
        <ViewRow label={labels.STATUS} value={script.status} />
        <ViewRow label={labels.SLUG} value={script.slug} />
        <ViewRow label={labels.DESCRIPTION} value={script.description} />
        <ViewRow label={labels.TARGET} value={targetMap[script.target]} />
        <ViewRow label={labels.VERSION} value={script.version} />
        <ViewRow label={labels.VERSION_NAME} value={script.versionName} />
      </div>
      <Divider />
      <div className={classes.body}>
        <ViewRow
          label={labels.CREATED_BY}
          value={
            script.createdBy?.firstName && script.createdBy?.lastName
              ? `${script.createdBy?.firstName} ${script.createdBy?.lastName}`
              : undefined
          }
        />
        <ViewRow label={labels.CREATED_AT} value={script.createdAt} date />
        <ViewRow
          label={labels.UPDATED_BY}
          value={
            script.updatedBy?.firstName && script.updatedBy?.lastName
              ? `${script.updatedBy?.firstName} ${script.updatedBy?.lastName}`
              : undefined
          }
        />
        <ViewRow label={labels.UPDATED_AT} value={script.updatedAt} date />
      </div>
    </div>
  );
};

ScriptView.propTypes = {
  script: ScriptPropType,
  onAddStep: PropTypes.func,
  onEdit: PropTypes.func,
  onDuplicate: PropTypes.func,
  onDelete: PropTypes.func,
  onExport: PropTypes.func,
  onImport: PropTypes.func,
  onSort: PropTypes.func,
  labels: PropTypes.object,
  onViewHistory: PropTypes.func,
};

export default ScriptView;
