import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../common/Button/Button';
import ExternalLink from '../../common/Icons/basic/ExternalLink';
import Link from '../../common/Link/Link';
import Typography from '../../common/Typography/Typography';
import { registerEvent } from '../../services/Analytics';
import withConfig from '../../utils/withConfig';
import { withLabels } from '../../context/LabelContext';

const YglLink = ({
  externalId,
  hasError,
  url,
  type,
  className,
  eventParams,
  color,
  target,
  registerEventOverride,
  onClick,
  labels,
  children,
  textColor,
}) => {
  let content = children;
  let disabled = false;
  if (hasError) {
    content = labels.FAILED_TO_LOAD_EXTERNAL_ID;
    disabled = true;
  }
  if (!hasError && !externalId) {
    content = labels.TRYING_TO_LOAD_EXTERNAL_ID;
    disabled = true;
  }

  const onClickAny = (e) => {
    e.preventDefault();
    if (!disabled) {
      if (onClick) {
        onClick();
      }
      if (registerEventOverride) {
        registerEventOverride();
      } else {
        const params = eventParams
          ? eventParams
          : ['FamilyFiles', `Click to go to ${url}`];
        registerEvent(...params);
      }

      let openNewYglTab = false;
      try {
        const openerOrigin = window.opener.location.origin;
        const currentOrigin = window.location.origin;

        if (openerOrigin === currentOrigin) {
          openNewYglTab = true;
        } else {
          if (target === 'self' && window.opener) {
            openNewYglTab = false;
          } else {
            openNewYglTab = true;
          }
        }
      } catch (error) {
        console.error(`Error: ${error}`);
        if (target === 'self' && window.opener) {
          openNewYglTab = false;
        } else {
          openNewYglTab = true;
        }
      }
      if (openNewYglTab) {
        window.open(
          withConfig(url, {
            yglLeadID: externalId,
          }),
          `_${target}`,
        );
      } else {
        window.close();
      }
    }
  };

  if (type === 'link') {
    return (
      <Link
        openInNewTab={true}
        onClick={onClickAny}
        disabled={disabled}
        className={className}
      >
        {content}
      </Link>
    );
  }
  if (type === 'button') {
    return (
      <Button
        size="small"
        startIcon={disabled ? null : <ExternalLink />}
        type={disabled ? 'disabled' : color}
        onClick={onClickAny}
      >
        <Typography color={disabled ? 'disabled' : textColor}>
          {content}
        </Typography>
      </Button>
    );
  }
};

YglLink.defaultProps = {
  externalId: null,
  hasError: false,
  className: '',
  eventParams: null,
  color: 'primary',
  target: 'blank',
  registerEventOverride: null,
  onClick: null,
  textColor: 'platinum5',
};

YglLink.propTypes = {
  externalId: PropTypes.number,
  children: PropTypes.node.isRequired,
  hasError: PropTypes.bool,
  url: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['link', 'button']).isRequired,
  className: PropTypes.string,
  eventParams: PropTypes.array,
  color: PropTypes.oneOf(['primary', 'secondary', 'light']),
  target: PropTypes.oneOf(['self', 'blank']),
  registerEventOverride: PropTypes.func,
  onClick: PropTypes.func,
  labels: PropTypes.object,
  textColor: PropTypes.string,
};

export default withLabels(YglLink);
