const createInquiryMutation = `
  mutation createInquiry(
    $primaryPhone: String!
    $inquiryMethod:InquiryMethod
    $dnis:String
    ) {
      createInquiry(
        input: {  
        primaryPhone: $primaryPhone
        inquiryMethod:$inquiryMethod
        dnis:$dnis
        }
        ) {
      oneId
      matchCriteria
      inquiryId
      firstName
      lastName
      email
      primaryPhone
      desiredPostalCode
      desiredCity
      desiredState
      relationToResident
      careTypeCode
      lookingReason
      moveDateRange
      veteran
      resident1FirstName
      resident1LastName
      resident1Age
      resident1Gender
      resident2FirstName
      resident2LastName
      resident2Age
      resident2Gender
      budgetMin
      budgetMax
      financialResources
      medicaid
      locationRange
      roomPreference
      getAround
      diabetic
      memoryLoss
      behavioral
      toileting
      bathing
      medicaid
      referrerUrl
      utmCampaign
      utmMedium
      utmSource
      utmTerm
      utmContent
      status
      createdBy
      createdAt
      updatedBy
      updatedAt
      audienceKey
      sourceId
      subSourceId
      inquiryMethod
      submissionUrl
      inquiryFormData {
        inquiryFormDataId
        inquiryId
        createdAt
        createdBy
        updatedAt
        updatedBy
        slugId
        versionId
        contactConfirmation
        disclaimer
        firstName
        lastName
        email
        primaryPhone
        noAnswerCall
        confirmInterest
        relationToResident
        resident1FirstName
        resident1LastName
        budget
        medicalInfo
        desiredCity
        desiredState
        desiredPostalCode
        desiredCountry
        desiredDistance
        notInterestedReason
        transferSla
        unqualified
        pipedaDisclosure
        isCompleteCall
        moveDateRange
        careTypeCode
        veteran
      }
      inquiryContact {
        inquiryContactId
        firstName 
        lastName 
        phone
        oneId
        email    
      }
    }
  }`;

export default createInquiryMutation;
