import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './MedicalBackgroundEdit.style';
import Injured from 'common/Graphics/disability/Injured';
import Walker from 'common/Graphics/disability/Walker';
import Cane from 'common/Graphics/disability/Cane';
import Wheelchair from 'common/Graphics/disability/Wheelchair';
import Independent from 'common/Graphics/disability/Independent';
import Bedridden from 'common/Graphics/disability/Bedridden';
import Button from 'common/Button/Button';
import Typography from 'common/Typography/Typography';
import Dropdown from 'common/Dropdown/Dropdown';
import CircleChecked from 'common/Icons/basic/CircleChecked';
import TextInput from 'common/TextInput/TextInput';
import Radio from 'common/Radio/Radio';
import Checkbox from 'common/Checkbox/Checkbox';
import clsx from 'clsx';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import Container from '../../../common/Container/Container';
import ContainerItem from '../../../common/ContainerItem/ContainerItem';
import MedicalBackgroundFormModel from './MedicalBackgroundFormModel';
import { useLocation } from 'react-router-dom';
import { inSendReferralPage } from 'services/familyFileService';

const MedicalBackgroundDesktopEdit = ({
  labels,
  medicalBackgroundData,
  onSave,
  isSubmitting,
  onCancel,
  isSelfLead,
  shouldShowActionControls,
  enableReferralAlertValidation,
  flags,
  onChange,
  showSidePanel,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const maxInputTextLength = 200;
  const maxRowsCount = 4;
  const healthIssuesTextAreaId = 'healthIssuesTextAreaId';
  const anythingElseTextAreaId = 'anythingElseTextAreaId';

  const locationId = 'locationId';

  const [selectedLocationName, setSelectedLocationName] = useState(
    medicalBackgroundData.locationName || '',
  );
  const [selectedMemoryLossItem, setSelectedMemoryLossItem] = useState({
    value: medicalBackgroundData.memoryLoss || '',
  });
  const [selectedOtherIssues, setSelectedOtherIssues] = useState(
    medicalBackgroundData.otherIssues || '',
  );
  const [selectedOtherIssuesItem, setSelectedOtherIssuesItem] = useState([
    {
      label: 'None',
      value: 'No',
      checked: medicalBackgroundData.otherIssues === 'No',
    },
    {
      label: 'Combative',
      value: 'Yes, combative issues',
      checked:
        medicalBackgroundData.otherIssues === 'Yes, combative issues' ||
        medicalBackgroundData.otherIssues ===
          'Yes, combative and wandering issues',
    },
    {
      label: 'Wandering',
      value: 'Yes, wandering issues',
      checked:
        medicalBackgroundData.otherIssues === 'Yes, wandering issues' ||
        medicalBackgroundData.otherIssues ===
          'Yes, combative and wandering issues',
    },
  ]);

  const [
    selectedHelpingInBathingItem,
    setSelectedHelpingInBathingItem,
  ] = useState(medicalBackgroundData.helpInShower ?? false);
  const [
    selectedHelpingInToiletingItem,
    setSelectedHelpingInToiletingItem,
  ] = useState(medicalBackgroundData.helpInToileting ?? false);
  const [healthIssuesTextInput, setHealthIssuesTextInput] = useState(
    medicalBackgroundData.healthIssues,
  );

  const [anythingElseTextInput, setAnythingElseTextInput] = useState(
    medicalBackgroundData.anythingElse,
  );

  const gettingAroundItems = [
    { label: 'Independent', value: 'Independent', icon: <Independent /> },
    { label: 'Walker', value: 'Walker', icon: <Walker /> },
    { label: 'Cane', value: 'Cane', icon: <Cane /> },
    { label: 'Wheelchair', value: 'Wheelchair', icon: <Wheelchair /> },
    {
      label: 'Temporarily Immobile Due to Injury',
      value: 'Temporarily Immobile Due to Injury',
      icon: <Injured />,
    },
    {
      label: 'Bedridden, Unable to Bear Weight',
      value: 'Bedridden, Unable to Bear Weight',
      icon: <Bedridden />,
    },
  ];
  const [selectedGettingAround, setSelectedGettingAround] = useState({
    value: medicalBackgroundData.gettingAroundDescription || '',
  });

  const selectedMedicationItem = medicalBackgroundData.currentMedication
    ? medicalBackgroundData.currentMedication
    : 'Select from list';

  const memoryConditionTitles = [
    {
      label: 'Select from list',
      value: 'Select from list',
    },
    { label: "Yes, Alzheimer's", value: "Yes, Alzheimer's" },
    { label: 'Yes, dementia', value: 'Yes, dementia' },
    {
      label: 'Yes, other memory condition',
      value: 'Yes, other memory condition',
    },
    { label: 'No', value: 'No' },
    {
      label: 'No, but special memory care needed',
      value: 'No, but special memory care needed',
    },
  ];

  const [
    selectedMemoryConditionItem,
    setSelectedMemoryConditionItem,
  ] = useState(
    medicalBackgroundData.memoryDiagnosis
      ? memoryConditionTitles.find(
          (item) => item.value === medicalBackgroundData.memoryDiagnosis,
        )
      : memoryConditionTitles.find((item) => item.value === 'Select from list'),
  );

  const selectedDiabeticItem = medicalBackgroundData.diabeticCare
    ? medicalBackgroundData.diabeticCare
    : 'Select from list';

  const currentLivingSituationTitles = [
    {
      label: 'Select from list',
      value: null,
      requiresLocation: false,
      display: isSelfLead,
    },
    {
      label: 'Assisted Living',
      value: 'Assisted Living',
      requiresLocation: true,
      display: true,
    },
    {
      label: 'Residential Care Home',
      value: 'Residential Care Home',
      requiresLocation: true,
      display: true,
    },
    {
      label: 'Home (lives alone)',
      value: 'Home (lives alone)',
      requiresLocation: false,
      display: true,
    },
    {
      label: 'Home (with Services)',
      value: 'Home (with Services)',
      requiresLocation: false,
      display: true,
    },
    {
      label: 'Home (lives with spouse)',
      value: 'Home (lives with spouse)',
      requiresLocation: false,
      display: true,
    },
    {
      label: 'Home (lives with partner)',
      value: 'Home (lives with partner)',
      requiresLocation: false,
      display: true,
    },
    {
      label: 'Lives with Family',
      value: 'Lives with Family',
      requiresLocation: false,
      display: true,
    },
    {
      label: 'Hospital',
      value: 'Hospital',
      requiresLocation: true,
      display: true,
    },
    {
      label: 'Nursing Home',
      value: 'Nursing Home',
      requiresLocation: true,
      display: true,
    },
    {
      label: 'Retirement Community',
      value: 'Retirement Community',
      requiresLocation: true,
      display: true,
    },
    { label: 'Rehab', value: 'Rehab', requiresLocation: true, display: true },
  ];

  const lookingForRehabTitles = [
    {
      label: 'Select from list',
      value: null,
      requiresLocation: false,
    },
    { label: 'Yes, short term', value: 'Yes, short term' },
    { label: 'Yes, long term', value: 'Yes, long term' },
    {
      label: 'No, not looking for rehab/SNF',
      value: 'No, not looking for rehab/SNF',
    },
  ];

  const currentMedicationTitles = [
    {
      label: 'Select from list',
      value: 'Select from list',
    },
    {
      label: 'Yes - they need help taking their medications',
      value: 'Yes - they need help taking their medications',
    },
    {
      label: 'Yes - they can take medications on their own',
      value: 'Yes - they can take medications on their own',
    },
    { label: 'No', value: 'No' },
  ];

  const memoryLossRadioItems = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
  ];

  const diabeticCareTitles = [
    {
      label: 'Select from list',
      value: 'Select from list',
    },
    { label: 'Not Diabetic', value: 'Not Diabetic' },
    { label: 'Self Management', value: 'Self Management' },
    {
      label: 'Needs Blood Sugar Monitored To Determine Pill Dosage',
      value: 'Needs Blood Sugar Monitored To Determine Pill Dosage',
    },
    {
      label: 'Needs Insulin Injections Administered',
      value: 'Needs Insulin Injections Administered',
    },
    {
      label: 'Needs Sliding Scale Administered',
      value: 'Needs Sliding Scale Administered',
    },
  ];

  const [
    selectedCurrentLivingSituation,
    setSelectedCurrentLivingSituation,
  ] = useState(
    currentLivingSituationTitles.find(
      (item) => item.value === medicalBackgroundData.currentSituation,
    ),
  );

  const [showLocation, setshowLocation] = useState(
    selectedCurrentLivingSituation
      ? selectedCurrentLivingSituation.requiresLocation
      : false,
  );

  const [selectedLookingForRehab, setSelectedLookingForRehab] = useState(
    lookingForRehabTitles.find(
      (item) =>
        item.value === medicalBackgroundData.rehabSkilledNursingRequired,
    ),
  );

  const [selectedMedication, setSelectedMedication] = useState(
    currentMedicationTitles.find(
      (item) => item.value === selectedMedicationItem,
    ),
  );

  const [selectedDiabeticCare, setSelectedDiabeticCare] = useState(
    diabeticCareTitles.find(
      (item) =>
        item.value.trim().toLowerCase() ===
        selectedDiabeticItem.trim().toLowerCase(),
    ),
  );

  const changeInCurrentLivingSituation = (selectedItem) => {
    setshowLocation(selectedItem.requiresLocation);
    setSelectedCurrentLivingSituation(selectedItem);
    if (selectedItem.requiresLocation === false) {
      setSelectedLocationName('');
    }
  };

  const changeInLookingForRehab = (selectedItem) => {
    setSelectedLookingForRehab(selectedItem);
  };

  const changeInMedication = (selectedItem) => {
    setSelectedMedication(selectedItem);
  };

  const changeInMemoryCondition = (selectedItem) => {
    setSelectedMemoryConditionItem(selectedItem);
  };

  const changeInDiabeticCare = (selectedItem) => {
    setSelectedDiabeticCare(selectedItem);
  };

  const changeInLocationName = (value) => {
    setSelectedLocationName(value);
  };

  const onChangeGettingAroundItem = (selectedItem) => {
    setSelectedGettingAround(selectedItem);
  };

  const changeInMemoryLoss = (selectedItem) => {
    setSelectedMemoryLossItem(selectedItem);
    if (selectedItem.value === 'No') {
      setSelectedOtherIssues('');
      setSelectedMemoryConditionItem('');
      selectedOtherIssuesItem.forEach((e) => (e.checked = false));
    }
  };

  const changeInotherIssues = (e, selectedItem) => {
    selectedItem.checked = e.target.checked;

    if (selectedItem.checked === true && selectedItem.value === 'No') {
      selectedOtherIssuesItem.forEach((e) => {
        if (e.value !== 'No') e.checked = false;
      });
    }

    if (
      selectedItem.checked === true &&
      (selectedItem.value === 'Yes, combative issues' ||
        selectedItem.value === 'Yes, wandering issues')
    ) {
      selectedOtherIssuesItem.forEach((e) => {
        if (e.value === 'No') e.checked = false;
      });
    }

    setSelectedOtherIssuesItem([...selectedOtherIssuesItem]);

    if (
      selectedOtherIssuesItem.filter((e) => e.checked === true).length === 0
    ) {
      setSelectedOtherIssues('No');
    }

    if (
      selectedOtherIssuesItem.filter((e) => e.checked === true).length === 1
    ) {
      setSelectedOtherIssues(
        selectedOtherIssuesItem.find((e) => e.checked === true).value,
      );
    }

    if (
      selectedOtherIssuesItem.some(
        (e) => e.value === 'Yes, combative issues' && e.checked,
      ) &&
      selectedOtherIssuesItem.some(
        (e) => e.value === 'Yes, wandering issues' && e.checked,
      )
    ) {
      setSelectedOtherIssues('Yes, combative and wandering issues');
    }
  };

  const changeInBathing = (e) => {
    setSelectedHelpingInBathingItem(e.target.checked);
  };

  const changeInToileting = (e) => {
    setSelectedHelpingInToiletingItem(e.target.checked);
  };

  const validateAndSave = () => {
    if (
      (isSelfLead ||
        (!isSelfLead &&
          selectedCurrentLivingSituation.label !== 'Select from list')) &&
      ((selectedCurrentLivingSituation.requiresLocation === true &&
        selectedLocationName !== '') ||
        (selectedCurrentLivingSituation.requiresLocation === false &&
          selectedLocationName === ''))
    ) {
      onSave();
    }
  };

  // Used to ensure the onChange does not trigger just because we have received props for the first time.
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const formModel = new MedicalBackgroundFormModel({
      formDataId: medicalBackgroundData.formDataId,
      oneId: medicalBackgroundData.oneId,
      familyFileId: medicalBackgroundData.familyFileId,
      memoryLoss: selectedMemoryLossItem.value,
      currentSituation: selectedCurrentLivingSituation.value,
      locationName: selectedLocationName,
      rehabSkilledNursingRequired: selectedLookingForRehab.value,
      currentMedication: selectedMedication.value,
      memoryDiagnosis: selectedMemoryConditionItem.value,
      otherIssues: selectedOtherIssues,
      gettingAroundDescription: selectedGettingAround.value,
      diabeticCare: selectedDiabeticCare.value,
      helpInToileting: selectedHelpingInToiletingItem,
      helpInShower: selectedHelpingInBathingItem,
      healthIssues: healthIssuesTextInput,
      anythingElse: anythingElseTextInput,
    });

    onChange(formModel);
  }, [
    medicalBackgroundData.formDataId,
    medicalBackgroundData.oneId,
    medicalBackgroundData.familyFileId,
    selectedCurrentLivingSituation,
    selectedLocationName,
    selectedMedication,
    selectedMemoryConditionItem,
    selectedLookingForRehab,
    selectedMemoryLossItem,
    selectedOtherIssues,
    selectedGettingAround,
    selectedDiabeticCare,
    selectedHelpingInToiletingItem,
    selectedHelpingInBathingItem,
    anythingElseTextInput,
    healthIssuesTextInput,
    onChange,
  ]);

  const Actions = ({ shouldShow }) => {
    return (
      <div
        className={clsx(
          enableReferralAlertValidation
            ? classes.column_button_send_referral
            : classes.column_button,
          shouldShow ? '' : classes.actions_for_desktop,
        )}
      >
        <div>
          <Button type="light" onClick={onCancel}>
            {labels.CANCEL}
          </Button>
        </div>
        <div>
          <Button
            type="secondary"
            onClick={validateAndSave}
            startIcon={<CircleChecked />}
          >
            {labels.SAVE}
          </Button>
        </div>
      </div>
    );
  };

  const handleHealthIssuesTextInputBlur = (text) => {
    setHealthIssuesTextInput(text);
  };

  const handleAnythingElseTextInputBlur = (text) => {
    setAnythingElseTextInput(text);
  };

  Actions.propTypes = {
    shouldShow: PropTypes.bool.isRequired,
  };

  if (flags.isInitialConsultationEnabled && !inSendReferralPage(location)) {
    return (
      <Fragment>
        {shouldShowActionControls && (
          <div className={classes.actions_container}>
            <Actions shouldShow />
          </div>
        )}
        <Container
          spacing={6}
          className={classes.medical_background_form_container}
        >
          <ContainerItem
            className={clsx(showSidePanel && classes.max_width)}
            xs={12}
            lg={6}
          >
            <div className={classes.header_and_buttons}>
              <div className={classes.first_row_header}>
                <div className={classes.column}>
                  <Typography color="eerieBlack1" bold={true}>
                    {labels.CURRENT_LIVING_SITUATION}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={clsx(classes.row, classes.row_flex_wrap)}>
              <div
                className={clsx(
                  classes.column,
                  enableReferralAlertValidation &&
                    'send_referral_small_block_container',
                )}
              >
                <div
                  className={clsx(
                    classes.column_currently_living,
                    enableReferralAlertValidation &&
                      'send_referral_column_currently_living',
                  )}
                >
                  {!isSelfLead && (
                    <Dropdown
                      className={classes.mandatory_dropdown}
                      error={selectedCurrentLivingSituation.value === null}
                      textHint={
                        selectedCurrentLivingSituation.value === null
                          ? 'Required'
                          : ''
                      }
                      items={currentLivingSituationTitles.filter(
                        (clst) => clst.display,
                      )}
                      label={labels.WHERE_IS_YOUR_LOVED_ONE_CURRENTLY_LIVING}
                      selectedItem={selectedCurrentLivingSituation}
                      onChange={(e) => changeInCurrentLivingSituation(e)}
                    />
                  )}
                  {isSelfLead && (
                    <Dropdown
                      className={classes.dropdown}
                      items={currentLivingSituationTitles.filter(
                        (clst) => clst.display,
                      )}
                      label={labels.WHERE_IS_YOUR_LOVED_ONE_CURRENTLY_LIVING}
                      selectedItem={selectedCurrentLivingSituation}
                      onChange={(e) => changeInCurrentLivingSituation(e)}
                    />
                  )}
                </div>
              </div>
            </div>
            {showLocation === true && (
              <div className={clsx(classes.row, classes.row_flex_wrap)}>
                <div
                  className={clsx(
                    classes.column,
                    enableReferralAlertValidation &&
                      'send_referral_small_block_container',
                  )}
                >
                  <div className={classes.currently_living}>
                    <TextInput
                      className={clsx(
                        classes.text_input_location,
                        enableReferralAlertValidation &&
                          'send_referral_currently_living',
                      )}
                      id={locationId}
                      error={selectedLocationName === ''}
                      textHint={selectedLocationName === '' ? 'Required' : ''}
                      label={labels.LOCATION}
                      value={selectedLocationName}
                      maxLength={50}
                      rows={1}
                      onChange={(value) => changeInLocationName(value)}
                    />
                  </div>
                </div>
              </div>
            )}
            <>
              <div className={classes.row}>
                <div className={classes.column_rehab}>
                  <Dropdown
                    className={classes.dropdown}
                    items={lookingForRehabTitles}
                    label={
                      labels.IS_YOUR_LOVED_ONE_LOOKING_FOR_A_REHAB_SKILLED_NURSING_FACILITY
                    }
                    selectedItem={selectedLookingForRehab}
                    onChange={(e) => changeInLookingForRehab(e)}
                  />
                </div>
              </div>
              <div className={classes.row_header}>
                <div className={classes.column}>
                  <Typography color="eerieBlack1" bold={true}>
                    {labels.CURRENT_MEDICATIONS}
                  </Typography>
                </div>
              </div>

              <div className={classes.row}>
                <div className={classes.column_medication}>
                  <Dropdown
                    className={
                      showSidePanel
                        ? classes.custom_select_panel
                        : classes.custom_select
                    }
                    items={currentMedicationTitles}
                    label={labels.IS_YOUR_LOVED_ONE_TAKING_ANY_MEDICATIONS}
                    selectedItem={selectedMedication}
                    onChange={(e) => changeInMedication(e)}
                  />
                </div>
              </div>

              <div className={classes.row_header}>
                <div className={classes.column}>
                  <Typography color="eerieBlack1" bold={true}>
                    {labels.MEMORY_LOSS}
                  </Typography>
                </div>
              </div>
              <div className={classes.row}>
                <Radio
                  selected={selectedMemoryLossItem}
                  label={labels.DOES_YOUR_LOVED_ONE_EXPERIENCE_MEMORY_LOSS}
                  name="complete-options"
                  items={memoryLossRadioItems}
                  onChange={changeInMemoryLoss}
                />
              </div>
              {selectedMemoryLossItem.value === 'Yes' && (
                <>
                  <div
                    className={clsx(classes.row, classes.small_block_container)}
                  >
                    <div className={classes.column_memory_condition}>
                      <Dropdown
                        className={
                          showSidePanel
                            ? classes.custom_select_panel
                            : classes.custom_select
                        }
                        items={memoryConditionTitles}
                        label={
                          labels.HAVE_THEY_BEEN_DIAGNOSED_WITH_MEMORY_CONDITION
                        }
                        selectedItem={selectedMemoryConditionItem}
                        onChange={(e) => changeInMemoryCondition(e)}
                      />
                    </div>
                  </div>

                  <div
                    className={clsx(
                      classes.column,
                      classes.small_block_container,
                    )}
                  >
                    <Typography color="eerieBlack1" level="small">
                      {labels.ARE_THERE_ANY_COMBATIVE_OR_WANDERING_ISSUES}
                    </Typography>
                    <div className={classes.group_item}>
                      {selectedOtherIssuesItem.map((item, key) => (
                        <Checkbox
                          key={key}
                          className={classes.other_issue}
                          checked={item.checked}
                          label={item.label}
                          onChange={(e) => changeInotherIssues(e, item)}
                        />
                      ))}
                    </div>
                  </div>
                </>
              )}
              <div className={classes.row_header}>
                <Typography color="eerieBlack1" bold={true}>
                  {labels.GETTING_AROUND}
                </Typography>
              </div>

              <div className={classes.row_getting_around}>
                {gettingAroundItems.map((item, key) => (
                  <div
                    key={key}
                    className={classes.getting_around_items}
                    onClick={() => onChangeGettingAroundItem(item)}
                  >
                    <Radio
                      selected={selectedGettingAround}
                      items={[{ value: item.value }]}
                      name="getting-around"
                    />
                    <div className={classes.getting_around_item}>
                      {item.icon}
                      <Typography className={classes.icon_text} level="body">
                        {item.value}
                      </Typography>
                    </div>
                  </div>
                ))}
              </div>
            </>
          </ContainerItem>
          <ContainerItem
            className={clsx(showSidePanel && classes.max_width)}
            xs={12}
            lg={6}
          >
            <div className={classes.first_row_header}>
              <Typography color="eerieBlack1" bold={true}>
                {labels.DIABETIC_CARE}
              </Typography>
            </div>
            <div className={classes.row}>
              <Dropdown
                className={
                  showSidePanel
                    ? classes.custom_select_panel
                    : classes.custom_select
                }
                items={diabeticCareTitles}
                label={labels.WHAT_LEVEL_OF_DIABETIC_CARE_DO_THEY_NEED}
                selectedItem={selectedDiabeticCare}
                onChange={(e) => changeInDiabeticCare(e)}
              />
            </div>
            <div className={classes.row_header}>
              <Typography color="eerieBlack1" bold={true}>
                {labels.INCONTINENCE_CARE}
              </Typography>
            </div>
            <div className={classes.row}>
              <div className={classes.column_memory_condition}>
                <Typography color="eerieBlack1" level="small">
                  {labels.DO_THEY_NEED_HELP_IN_ANY_OF_THESE}
                </Typography>
                <div className={classes.group_item}>
                  <Checkbox
                    className={classes.other_issue}
                    checked={selectedHelpingInBathingItem}
                    label={labels.BATHING_OR_SHOWERING}
                    onChange={changeInBathing}
                  />
                  <Checkbox
                    className={classes.other_issue}
                    checked={selectedHelpingInToiletingItem}
                    label={labels.TOILETING}
                    onChange={changeInToileting}
                  />
                </div>
              </div>
            </div>
            <div className={classes.row_header}>
              <Typography color="eerieBlack1" bold={true}>
                {labels.ADDITIONAL_NOTES}
              </Typography>
            </div>
            <div className={classes.row}>
              <div className={classes.column_text_input}>
                <div className={classes.text_input_margin_bottom}>
                  <Typography color="eerieBlack1" level="small">
                    {
                      labels.ARE_THERE_ANY_OTHER_HEALTH_ISSUES_AND_OR_NUTRITIONAL_NEEDS
                    }
                  </Typography>
                  <div>
                    <TextInput
                      id={healthIssuesTextAreaId}
                      className={classes.text_input_container}
                      maxLength={maxInputTextLength}
                      rows={maxRowsCount}
                      multiline={true}
                      disabled={isSubmitting}
                      value={healthIssuesTextInput}
                      onBlur={handleHealthIssuesTextInputBlur}
                    />
                  </div>
                </div>
                <div className={classes.text_input_margin_bottom}>
                  <Typography color="eerieBlack1" level="small">
                    {
                      labels.IS_THERE_ANYTHING_ELSE_YOU_WOULD_LIKE_TO_TELL_ME_ABOUT_YOUR_LOVED_ONE
                    }
                  </Typography>
                  <div>
                    <TextInput
                      id={anythingElseTextAreaId}
                      className={classes.text_input_container}
                      maxLength={maxInputTextLength}
                      rows={maxRowsCount}
                      multiline={true}
                      disabled={isSubmitting}
                      value={anythingElseTextInput}
                      onBlur={handleAnythingElseTextInputBlur}
                    />
                  </div>
                </div>
              </div>
            </div>
            {shouldShowActionControls && (
              <div className={classes.actions_container}>
                <Actions shouldShow={false} />
              </div>
            )}
          </ContainerItem>
        </Container>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <Actions shouldShow={true} />
      <div className={classes.header_and_buttons}>
        <div className={classes.first_row_header}>
          <div className={classes.column}>
            <Typography color="eerieBlack1" bold={true}>
              {labels.CURRENT_LIVING_SITUATION}
            </Typography>
          </div>
        </div>
      </div>

      <div
        className={clsx(
          classes.row,
          classes.small_block_container,
          enableReferralAlertValidation &&
            'send_referral_small_block_container',
        )}
      >
        <div
          className={clsx(
            classes.column_currently_living,
            classes.extended_width,
            enableReferralAlertValidation &&
              'send_referral_column_currently_living',
          )}
        >
          {!isSelfLead && (
            <Dropdown
              className={classes.mandatory_dropdown}
              error={selectedCurrentLivingSituation.value === null}
              textHint={
                selectedCurrentLivingSituation.value === null ? 'Required' : ''
              }
              items={currentLivingSituationTitles.filter(
                (clst) => clst.display,
              )}
              label={labels.WHERE_IS_YOUR_LOVED_ONE_CURRENTLY_LIVING}
              selectedItem={selectedCurrentLivingSituation}
              onChange={(e) => changeInCurrentLivingSituation(e)}
            />
          )}
          {isSelfLead && (
            <Dropdown
              className={classes.dropdown}
              items={currentLivingSituationTitles.filter(
                (clst) => clst.display,
              )}
              label={labels.WHERE_IS_YOUR_LOVED_ONE_CURRENTLY_LIVING}
              selectedItem={selectedCurrentLivingSituation}
              onChange={(e) => changeInCurrentLivingSituation(e)}
            />
          )}
        </div>

        {showLocation === true && (
          <div className={classes.currently_living}>
            <TextInput
              className={clsx(
                classes.text_input_location,
                enableReferralAlertValidation &&
                  'send_referral_currently_living',
              )}
              id={locationId}
              error={selectedLocationName === ''}
              textHint={selectedLocationName === '' ? 'Required' : ''}
              label={labels.COMMUNITY}
              value={selectedLocationName}
              maxLength={50}
              rows={1}
              onChange={(value) => changeInLocationName(value)}
            />
          </div>
        )}
      </div>
      <div className={classes.row}>
        <div className={classes.column_rehab}>
          <Dropdown
            className={classes.dropdown}
            items={lookingForRehabTitles}
            label={
              labels.IS_YOUR_LOVED_ONE_LOOKING_FOR_A_REHAB_SKILLED_NURSING_FACILITY
            }
            selectedItem={selectedLookingForRehab}
            onChange={(e) => changeInLookingForRehab(e)}
          />
        </div>
      </div>

      {!enableReferralAlertValidation && (
        <>
          <div className={classes.row_header}>
            <div className={classes.column}>
              <Typography color="eerieBlack1" bold={true}>
                {labels.CURRENT_MEDICATIONS}
              </Typography>
            </div>
          </div>

          <div className={classes.row}>
            <div className={classes.column_medication}>
              <Dropdown
                className={classes.custom_select}
                items={currentMedicationTitles}
                label={labels.IS_YOUR_LOVED_ONE_TAKING_ANY_MEDICATIONS}
                selectedItem={selectedMedication}
                onChange={(e) => changeInMedication(e)}
              />
            </div>
          </div>

          <div className={classes.row_header}>
            <div className={classes.column}>
              <Typography color="eerieBlack1" bold={true}>
                {labels.MEMORY_LOSS}
              </Typography>
            </div>
          </div>
          <div className={classes.row}>
            <Radio
              selected={selectedMemoryLossItem}
              label={labels.DOES_YOUR_LOVED_ONE_EXPERIENCE_MEMORY_LOSS}
              name="complete-options"
              items={memoryLossRadioItems}
              onChange={changeInMemoryLoss}
            />
          </div>
          {selectedMemoryLossItem.value === 'Yes' && (
            <div className={clsx(classes.row, classes.small_block_container)}>
              <div className={classes.column_memory_condition}>
                <Dropdown
                  className={classes.custom_select}
                  items={memoryConditionTitles}
                  label={labels.HAVE_THEY_BEEN_DIAGNOSED_WITH_MEMORY_CONDITION}
                  selectedItem={selectedMemoryConditionItem}
                  onChange={(e) => changeInMemoryCondition(e)}
                />
              </div>

              <div className={classes.column_other_issue}>
                <Typography color="eerieBlack1" level="small">
                  {labels.OTHER_MEMORY_ISSUES}
                </Typography>
                <div className={classes.group_item}>
                  {selectedOtherIssuesItem.map((item, key) => (
                    <Checkbox
                      key={key}
                      className={classes.other_issue}
                      checked={item.checked}
                      label={item.label}
                      onChange={(e) => changeInotherIssues(e, item)}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className={classes.row_header}>
            <Typography color="eerieBlack1" bold={true}>
              {labels.GETTING_AROUND}
            </Typography>
          </div>

          <div className={classes.row_getting_around}>
            {gettingAroundItems.map((item, key) => (
              <div
                key={key}
                className={classes.getting_around_items}
                onClick={() => onChangeGettingAroundItem(item)}
              >
                <Radio
                  selected={selectedGettingAround}
                  items={[{ value: item.value }]}
                  name="getting-around"
                />
                <div className={classes.getting_around_item}>
                  {item.icon}
                  <Typography className={classes.icon_text} level="body">
                    {item.value}
                  </Typography>
                </div>
              </div>
            ))}
          </div>

          <div className={classes.row}>
            <div className={classes.column_memory_condition}>
              <Typography color="eerieBlack1" level="small">
                {labels.DO_THEY_NEED_HELP_IN_ANY_OF_THESE}
              </Typography>
              <div className={classes.group_item}>
                <Checkbox
                  className={classes.other_issue}
                  checked={selectedHelpingInBathingItem}
                  label={labels.BATHING_OR_SHOWERING}
                  onChange={changeInBathing}
                />
                <Checkbox
                  className={classes.other_issue}
                  checked={selectedHelpingInToiletingItem}
                  label={labels.TOILETING}
                  onChange={changeInToileting}
                />
              </div>
            </div>
          </div>

          <div className={classes.row_header}>
            <Typography color="eerieBlack1" bold={true}>
              {labels.DIABETIC_CARE}
            </Typography>
          </div>
          <div className={classes.row}>
            <Dropdown
              className={classes.custom_select}
              items={diabeticCareTitles}
              label={labels.WHAT_LEVEL_OF_DIABETIC_CARE_DO_THEY_NEED}
              selectedItem={selectedDiabeticCare}
              onChange={(e) => changeInDiabeticCare(e)}
            />
          </div>
          <Actions shouldShow={false} />
        </>
      )}
    </Fragment>
  );
};

MedicalBackgroundDesktopEdit.propTypes = {
  labels: PropTypes.object.isRequired,
  showSidePanel: PropTypes.bool,
  medicalBackgroundData: PropTypes.shape({
    formDataId: PropTypes.number,
    oneId: PropTypes.string,
    familyFileId: PropTypes.number,
    rehabSkilledNursingRequired: PropTypes.string,
    memoryLoss: PropTypes.string,
    memoryDiagnosis: PropTypes.string,
    currentSituation: PropTypes.string,
    locationName: PropTypes.string,
    currentMedication: PropTypes.string,
    otherIssues: PropTypes.string,
    moreHelp: PropTypes.bool,
    helpInShower: PropTypes.bool,
    helpInToileting: PropTypes.bool,
    diabeticCare: PropTypes.string,
    gettingAroundType: PropTypes.string,
    gettingAroundDescription: PropTypes.string,
    healthIssues: PropTypes.string,
    anythingElse: PropTypes.string,
  }).isRequired,
  flags: PropTypes.shape({
    isInitialConsultationEnabled: PropTypes.bool.isRequired,
  }).isRequired,
  onSave: PropTypes.func,
  isSubmitting: PropTypes.bool,
  onCancel: PropTypes.func,
  isSelfLead: PropTypes.bool,
  shouldShowActionControls: PropTypes.bool,
  enableReferralAlertValidation: PropTypes.bool,
  /**
   * Callback invoked when any input within the form is changed. When invoked, an instance of
   * {@link MedicalBackgroundFormModel} containing the current values of the form is provided as an argument.
   */
  onChange: PropTypes.func,
};

MedicalBackgroundDesktopEdit.defaultProps = {
  memoryLoss: false,
  enableReferralAlertValidation: false,
  flags: {},
  onChange: () => {},
  showSidePanel: false,
};

export default withLDConsumer()(MedicalBackgroundDesktopEdit);
