import PropTypes from 'prop-types';
import React from 'react';
import { useStyles } from './CommunityAdvisory.style';
import {
  ADVISORY_TYPE,
  ADVISORY_TYPE_HEADER,
} from './CommunityAdvisoryService';
import { getMonthDayYearFormat } from 'utils/dateFormat';
import Heart from 'common/Icons/basic/Heart';
import AlertCircle from 'common/Icons/notifications/AlertCircle';
import AlertOctagon from 'common/Icons/notifications/AlertOctagon';
import AlertTriangle from 'common/Icons/notifications/AlertTriangle';
import Construction from 'common/Icons/various/Construction';
import Disaster from 'common/Icons/various/Disaster';
import Typography from 'common/Typography/Typography';

const CommunityAdvisoryDetail = ({ type, advisory }) => {
  const classes = useStyles();

  const getHeader = (type) => {
    if (type === ADVISORY_TYPE.NATURAL_DISASTER) {
      return (
        <div className={classes.advisory_header}>
          <Disaster />
          <Typography bold>{ADVISORY_TYPE_HEADER.NATURAL_DISASTER}</Typography>
        </div>
      );
    } else if (type === ADVISORY_TYPE.CONSTRUCTION) {
      return (
        <div className={classes.advisory_header}>
          <Construction />
          <Typography bold>{ADVISORY_TYPE_HEADER.CONSTRUCTION}</Typography>
        </div>
      );
    } else if (type === ADVISORY_TYPE.HEALTH) {
      return (
        <div className={classes.advisory_header}>
          <Heart />
          <Typography bold>{ADVISORY_TYPE_HEADER.HEALTH}</Typography>
        </div>
      );
    }
  };

  const getInEffectTimeRangeText = () => {
    if (advisory.effectiveEndDate) {
      return (
        <Typography>
          In effect from {getMonthDayYearFormat(advisory.effectiveStartDate)}{' '}
          through {getMonthDayYearFormat(advisory.effectiveEndDate)}
        </Typography>
      );
    }
    return (
      <Typography>
        In effect from {getMonthDayYearFormat(advisory.effectiveStartDate)}
      </Typography>
    );
  };

  return (
    <div className={classes.advisory_detail_container}>
      {getHeader(type)}
      <div className={classes.advisory_restrictions_container}>
        {getInEffectTimeRangeText()}
        {advisory.restrictionDetails.important.length > 0 && (
          <div className={classes.restriction_detail_container}>
            <AlertTriangle className={classes.important_icon} />
            <div className={classes.restiction_details}>
              {advisory.restrictionDetails.important.map(
                (restrictionDetail, key) => (
                  <Typography key={key}>{restrictionDetail}</Typography>
                ),
              )}
            </div>
          </div>
        )}
        {advisory.restrictionDetails.warning.length > 0 && (
          <div className={classes.restriction_detail_container}>
            <AlertOctagon className={classes.warning_icon} />
            <div className={classes.restriction_details}>
              {advisory.restrictionDetails.warning.map(
                (restrictionDetail, key) => (
                  <Typography key={key}>{restrictionDetail}</Typography>
                ),
              )}
            </div>
          </div>
        )}
        {advisory.restrictionDetails.informational.length > 0 && (
          <div className={classes.restriction_detail_container}>
            <AlertCircle className={classes.information_icon} />
            <div className={classes.restiction_details}>
              {advisory.restrictionDetails.informational.map(
                (restrictionDetail, key) => (
                  <Typography key={key}>{restrictionDetail}</Typography>
                ),
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

CommunityAdvisoryDetail.propTypes = {
  type: PropTypes.string.isRequired,
  advisory: PropTypes.object.isRequired,
};

export default CommunityAdvisoryDetail;
