import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './Overlay.style';

const Overlay = ({ children, type }) => {
  const classes = useStyles(type);
  return <div className={classes.overlay_container}>{children}</div>;
};

Overlay.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['dark', 'light']),
};

Overlay.defaultProps = {
  type: 'dark',
};

export default Overlay;
