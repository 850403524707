import gql from 'graphql-tag';

export default gql`
  mutation updateKeyNote(
    $keyNoteId: Int!
    $noteText: String!
    $updatedAt: Date
    $familyFileId: Int!
  ) {
    updateKeyNote(
      input: {
        keyNoteId: $keyNoteId
        noteText: $noteText
        updatedAt: $updatedAt
        familyFileId: $familyFileId
      }
    ) {
      keyNoteId
      noteText
      createdAt
      createdBy
      updatedAt
    }
  }
`;
