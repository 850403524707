import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => {
  const formPadding = theme.spacing(2);
  const sidebarRenderingWidth = 400;
  const minWidthToSupportSidebarRendering =
    sidebarRenderingWidth - 2 * formPadding;

  return {
    financial_criteria_form_container: {
      width: '100%',
      boxSizing: 'border-box',
    },
    individual_container_item: {
      minWidth: theme.spacing(40),
    },

    form_container: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: `repeat(auto-fit, minmax(${minWidthToSupportSidebarRendering}px, 1fr))`,
      gridColumnGap: theme.spacing(6),
      gridRowGap: theme.spacing(2),
      boxSizing: 'border-box',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
      },

      '& > * ': {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
      },
    },

    container: {
      margin: '24px 0',
    },
    row: {
      display: 'flex',
      flexFlow: 'row nowrap',
      flexGrow: 1,
      marginBottom: '20px',
    },
    /** @deprecated Can be removed with full launch of IC workflow */
    budget_range_item_row1: {
      display: 'flex',
      flexFlow: 'row nowrap',
      flexGrow: 1,
      height: '35px',
    },
    /** @deprecated Can be removed with full launch of IC workflow */
    budget_range_item_row2: {
      display: 'flex',
      flexFlow: 'row nowrap',
      flexGrow: 1,
      marginTop: '8px',
    },
    /** @deprecated Can be removed with full launch of IC workflow */
    budget_range_dropdown_row: {
      display: 'flex',
      flexFlow: 'row nowrap',
      flexGrow: 1,
      marginLeft: '30px',
      height: '56px',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    first_row_header: {
      marginBottom: '10px',
    },

    row_header_financial_resources: {
      display: 'flex',
      flexFlow: 'row nowrap',
      flexGrow: 1,
      marginTop: '30px',
    },
    row_header: {
      display: 'flex',
      flexFlow: 'row nowrap',
      flexGrow: 1,
      marginTop: '30px',
      marginBottom: '16px',
    },
    row_veteran_description: {
      width: '100%',
      paddingBottom: '6px',
    },
    icon_text: {
      padding: '12px 16px',
    },
    column_budget_range: {
      zIndex: 900,
      display: 'flex',
      flexDirection: 'column',
      width: '350px',
    },
    column_rehab: {
      zIndex: 800,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    column_medication: {
      width: '170px',
      zIndex: 700,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    column_memory_condition: {
      display: 'flex',
      flexDirection: 'column',
      width: '450px',
    },
    /** @deprecated Can be removed with full launch of IC workflow */
    row_financial_resources: {
      display: 'flex',
      flexDirection: 'column',
      width: '800px',
      paddingTop: '5px',
    },
    financial_resources_grid: {
      display: 'grid',
      gridTemplateRows: 'repeat(5, 1fr)',
      gridTemplateColumns: 'repeat(auto-fill, minmax(min-content, 340px))',
      width: 'auto',
      maxWidth: '800px',
      paddingTop: '5px',
      paddingBottom: '30px',
    },
    financial_resources_select_all_toggle_container: {
      gridColumn: '1 / span 2',
    },
    family_budget_controls_container: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: theme.spacing(4),
    },
    budget_range_dropdown_container: {
      marginRight: theme.spacing(3),
    },
    dropdown_budget_range: {
      zIndex: 999,
      width: '200px !important',
      marginRight: '16px',
    },
    dropdown_memory_condition: {
      width: '420px !important',
      zIndex: 333,
    },
    dropdown_residents_only: {
      '&.custom_select': {
        width: '250px',
      },
      [theme.breakpoints.down('sm')]: {
        '&.custom_select': {
          width: '100%',
        },
      },
    },

    column_button: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'flex-end',
      '& div:not(:first-child)': {
        paddingLeft: '24px',
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
      },
      marginBottom: theme.spacing(3),
    },
    column_button_send_referral: {
      display: 'flex',
      marginBottom: theme.spacing(3),
      '& div': {
        paddingLeft: 0,
        paddingRight: theme.spacing(3),
      },
    },
    text_input_location: {
      width: '320px',
      position: 'relative',

      '&::before': {
        content: `'*'`,
        position: 'absolute',
        color: 'red',
        left: '-2px',
      },
      '& label': {
        marginLeft: '10px',
      },
    },
    group_item: {
      display: 'flex',
    },
    getting_around_items: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingRight: '12px',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        '& > label': {
          width: '100%',
        },
      },
    },
    getting_around_item: {
      display: 'flex',
      alginItems: 'center',
      flexGrow: 1,
      height: '70px',
      '& svg': {
        fontSize: '48px',
      },
      cursor: 'pointer',
    },
    other_issue: {
      color: theme.palette.dark.eerieBlack1,
      width: '318px',
    },
    /** @deprecated: Can be removed with full launch of IC. New styles use required_field_star_at_start */
    family_budget_star: {
      paddingBottom: '4px',
      '&::after': {
        content: `'*'`,
        position: 'relative',
        color: 'red',
        left: '5px',
      },
    },
    required_field_star_at_start: {
      paddingBottom: '4px',
      '&::before': {
        content: `'*'`,
        position: 'relative',
        color: 'red',
        marginRight: '6px',
      },
    },
    section_heading_label: {
      paddingBottom: '4px',
    },
    veteran_description_label: {
      lineHeight: '22px',
    },
    us_residents_only_label: {
      marginBottom: theme.spacing(1),
    },
    div_insurance_radio: {
      '& label': {
        marginRight: '45px',
      },
      '& legend': {
        fontSize: '13px',
      },
    },
    actions_container: {
      position: 'relative',
      height: '64px',
    },
    actions_for_desktop: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        marginTop: theme.spacing(2),
      },
    },
    residents_option_container: {
      [theme.breakpoints.down('md')]: {
        maxWidth: '700px',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    radio_button_label: {
      fontSize: '13px',
      marginTop: '4px',
      lineHeight: '19px',
      color: theme.palette.dark.eerieBlack5,
    },
    veteran_status_dropdown_container: {
      marginRight: '12px',
    },
    va_aid_dropdown_container: {
      display: 'flex',
    },
    va_aid_description_container: {
      paddingRight: theme.spacing(6),
    },
    standard_dropdown_width: {
      '&.custom_select': {
        width: '240px',
      },
      [theme.breakpoints.down('sm')]: {
        '&.custom_select': {
          width: '100%',
        },
      },
      maxWidth: '400px',
    },
  };
});

export { useStyles };
