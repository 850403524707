import React from 'react';
import _uniq from 'lodash.uniq';
import AppError from '../../common/AppError/AppError';
import ServerError from '../../common/Graphics/error/ServerError';
import labels from '../../config/labels';
import InternalServerError from '../../common/Graphics/error/InternalServerError';

/* 
These are based on apfm.Ref_Budget in YGL. Note that some are inactive but 
are here to support lookups of existing data.

const budgets = [
  {
    id: 1,
    minimum: null,
    maximum: null,
    description: 'Family Undecided',
  },
  {
    id: 2,
    minimum: 0,
    maximum: 999,
    description: 'Under $1000',
  },
  {
    id: 3,
    minimum: 1000,
    maximum: 1499,
    description: '$1000-$1499',
  },
  {
    id: 4,
    minimum: 1500,
    maximum: 1999,
    description: '$1500-$1999',
  },
  {
    id: 5,
    minimum: 2000,
    maximum: 2499,
    description: '$2000-$2499',
  },
  {
    id: 6,
    minimum: 2500,
    maximum: 2999,
    description: '$2500-$2999',
  },
  {
    id: 7,
    minimum: 3000,
    maximum: 3999,
    description: '$3000-$3999',
  },
  {
    id: 8,
    minimum: 4000,
    maximum: 99999999,
    description: '$4000 and over',
  },
  {
    id: 9,
    minimum: 0,
    maximum: 499,
    description: 'Under $500',
  },
  {
    id: 10,
    minimum: 500,
    maximum: 999,
    description: '$500-$999',
  },
  {
    id: 11,
    minimum: 4000,
    maximum: 4999,
    description: '$4000-$4999',
  },
  {
    id: 12,
    minimum: 5000,
    maximum: 5999,
    description: '$5000-$5999',
  },
  {
    id: 13,
    minimum: 6000,
    maximum: 99999999,
    description: '$6000 and over',
  },
  {
    id: 14,
    minimum: 6000,
    maximum: 6999,
    description: '$6000-$6999',
  },
  {
    id: 15,
    minimum: 7000,
    maximum: 7999,
    description: '$7000-$7999',
  },
  {
    id: 16,
    minimum: 8000,
    maximum: 8999,
    description: '$8000-$8999',
  },
  {
    id: 17,
    minimum: 9000,
    maximum: 9999,
    description: '$9000-$9999',
  },
  {
    id: 18,
    minimum: 10000,
    maximum: 99999999,
    description: '$10000 or more',
  },
];
*/
const BAD_REQUEST = 400;
const SERVICE_UNAVAILABLE = 503;
const ENOTFOUND = 'ENOTFOUND';
const INTERNAL_SERVER_ERROR = 500;
export const careTypeLookup = {
  D: 'adultDayServices',
  A: 'assistedLiving',
  H: 'homeCare',
  R: 'independentLiving', // Independent Living is the new term for Retirement Community
  Z: 'memoryCare',
  O: 'notSure',
  N: 'nursingHome',
  G: 'residentialCareHome',
  M: 'respiteCare',
  P: 'seniorApartments',
};

const getParamFromUrl = (param, location) => {
  const params = new URLSearchParams(location.search);
  return params.get(param);
};

const buildPreferredLocationFromUrl = (location) => {
  const zipCode = getParamFromUrl('zip', location);
  const city = getParamFromUrl('city', location);
  const state = getParamFromUrl('state', location);
  if (state && zipCode && city && isZipCodeValid(zipCode)) {
    return {
      label: `${city}, ${state} ${zipCode}`,
      value: zipCode,
    };
  }
  return undefined;
};

function isZipCodeValid(zipCode) {
  if (!zipCode) {
    return undefined;
  }

  zipCode = zipCode.toString().trim();

  var usZipCodeRegex = new RegExp('^\\d{5}(-{0,1}\\d{4})?$');
  var canadaZipCodeRegex = new RegExp(
    /([ABCEGHJKLMNPRSTVXY]\d)([ABCEGHJKLMNPRSTVWXYZ]\d){2}/i,
  );

  if (usZipCodeRegex.test(zipCode.toString())) {
    return zipCode;
  }

  if (canadaZipCodeRegex.test(zipCode.toString().replace(/\W+/g, ''))) {
    return zipCode;
  }
  return undefined;
}

const getBudgetRange = (budgetDescription) => {
  if (budgetDescription) {
    const userBudget = budgetDescription
      .split('-')
      .map((value) => Number(value.replace('$', '')));
    if (userBudget.length === 2) {
      return { min: 0, max: userBudget[1] };
    }
  }
  return { min: 0, max: 10000 };
};

export const getInitialPreferredLocations = (preferredLocations, location) => {
  const locationFromParams = buildPreferredLocationFromUrl(location);
  if (!locationFromParams) {
    return preferredLocations;
  } else {
    const foundLocationIndex = preferredLocations.findIndex(
      (location) => location.label === locationFromParams.label,
    );
    if (foundLocationIndex > -1) {
      preferredLocations.splice(foundLocationIndex, 1);
    }
    preferredLocations.unshift(locationFromParams);
    return preferredLocations;
  }
};

export const getInitialCareTypeCodes = (familyFileCareTypeCodes, location) => {
  const careTypeCodesParam = getParamFromUrl('careTypeCodes', location);
  const careTypeCodes = careTypeCodesParam?.split(',') || [];

  const combinedCareTypeCodes = _uniq(
    familyFileCareTypeCodes.concat(careTypeCodes),
  );

  return combinedCareTypeCodes;
};

export const getInitialCareTypeFilter = (careTypeCodes) => {
  return careTypeCodes.map((code) => {
    return careTypeLookup[code];
  });
};

export const getInitialBudget = (budgetDescription, location) => {
  const budgetParam = getParamFromUrl('budgetId', location);
  if (budgetParam) {
    return {
      min: 0,
      max: 0,
    };
  }
  return getBudgetRange(budgetDescription);
};

export const getAppErrorMessages = (error) => {
  const errorCode = error?.message?.includes(ENOTFOUND)
    ? SERVICE_UNAVAILABLE
    : BAD_REQUEST;

  const title = labels.APP_ERROR_TITLE?.replace('{errorCode}', errorCode);

  return {
    title,
    message1: labels.UNKNOWN_ERROR_MESSAGE_1,
    message2: labels.UNKNOWN_ERROR_MESSAGE_2,
    message3: labels.UNKNOWN_ERROR_MESSAGE_3,
    message4: labels.UNKNOWN_ERROR_MESSAGE_4,
    message5: labels.UNKNOWN_ERROR_MESSAGE_5,
    message6: labels.UNKNOWN_ERROR_MESSAGE_6,
  };
};

export const getAppError = (error, errorIconClass) => {
  return (
    <AppError
      messages={getAppErrorMessages(error)}
      errorIcon={<ServerError className={errorIconClass} />}
      email={'beacon-help@aplaceformom.com'}
    />
  );
};

export const getZipCodeErrorMessages = (zipCode) => {
  return {
    title: labels.APP_ERROR_TITLE.replace('{errorCode}', INTERNAL_SERVER_ERROR),
    message1: labels.ZIP_CODE_ERROR_MESSAGE_1.replace('{zipCode}', zipCode),
    message2: labels.ZIP_CODE_ERROR_MESSAGE_2,
    message3: labels.ZIP_CODE_ERROR_MESSAGE_3,
  };
};

export const getZipCodeError = (zipCode, errorIconClass) => {
  return (
    <AppError
      messages={getZipCodeErrorMessages(zipCode)}
      errorIcon={<InternalServerError className={errorIconClass} />}
      showTopBar={false}
    />
  );
};

export const isZipCodeError = (error) => {
  return error?.message?.toLowerCase().includes('zip code');
};
