import {
  GET_SNAPSHOTS_ERROR,
  GET_SNAPSHOTS_REQUEST,
  GET_SNAPSHOTS_SUCCESS,
} from '../actions/scriptBuilderActions';

const snapshotsBuilderReducer = (
  state = { loading: false, error: null, data: null },
  action,
) => {
  switch (action.type) {
    case GET_SNAPSHOTS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_SNAPSHOTS_SUCCESS:
      return {
        loading: false,
        error: null,
        data: action.payload.response,
      };
    case GET_SNAPSHOTS_ERROR:
      return {
        loading: false,
        error: action.payload.error,
        data: null,
      };
    default:
      return state;
  }
};

export const selectSnapshots = (state) => ({
  snapshotsLoading: state?.admin?.snapshots?.loading,
  snapshotsError: state?.admin?.snapshots?.error,
  snapshots: state?.admin?.snapshots?.data,
});

export default snapshotsBuilderReducer;
