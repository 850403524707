import React from 'react';
import PropTypes from 'prop-types';
import { originalFormStepAnswerPropType } from '../../inquiryPropTypes';
import { useStyles } from './ButtonGroup.style';
import Button from 'common/Button/Button';

const ButtonLocation = ({
  options,
  onClick,
  selected,
  testId,
  hasCommunities,
}) => {
  const classes = useStyles();
  const saveOption = options[0];
  const noCloseOption = options[1];

  const getType = (option) => {
    let type = 'outlined';
    if (selected && selected.value === option.value) {
      type = 'primary';
    }
    return type;
  };

  const unqualifiedButtons = (
    <div className={classes.buttons_container}>
      <Button
        type={'outlinedDisabled'}
        className={classes.button}
        onClick={() => onClick(saveOption)}
        testId={`${testId}_actions_${saveOption.value}`}
      >
        {saveOption.label}
      </Button>
      <Button
        type={getType(noCloseOption)}
        className={classes.button}
        onClick={() => onClick(noCloseOption)}
        testId={`${testId}_actions_${noCloseOption.value}`}
      >
        {noCloseOption.label}
      </Button>
    </div>
  );

  const saveButton = (
    <Button
      type={getType(saveOption)}
      className={classes.button}
      onClick={() => onClick(saveOption)}
      testId={`${testId}_actions_${saveOption.value}`}
    >
      {getType(saveOption) === 'primary' ? 'Saved' : saveOption.label}
    </Button>
  );

  return (
    <div className={classes.buttons_container}>
      {hasCommunities || hasCommunities === undefined
        ? saveButton
        : unqualifiedButtons}
    </div>
  );
};

ButtonLocation.defaultProps = {
  disabled: false,
};

ButtonLocation.propTypes = {
  options: PropTypes.arrayOf(originalFormStepAnswerPropType).isRequired,
  onClick: PropTypes.func.isRequired,
  selected: originalFormStepAnswerPropType,
  testId: PropTypes.string.isRequired,
  hasCommunities: PropTypes.bool,
};

export default ButtonLocation;
