import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  square_button_container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.light.platinum4,
    width: '40px',
    height: '40px',
    boxShadow: '0px 2px 4px rgba(13, 19, 33, 0.3)',
    borderRadius: '2px',
    '& svg': {
      fontSize: '24px',
      color: theme.palette.dark.eerieBlack1,
      fill: theme.palette.dark.eerieBlack1,
    },
    color: theme.palette.dark.eerieBlack1,
    '&:hover': {
      backgroundColor: theme.palette.light.platinum2,
      color: theme.palette.neutral.cosmicCobalt2,
    },
  },
  active: {
    backgroundColor: theme.palette.light.platinum2,
    '& svg': {
      color: theme.palette.neutral.cosmicCobalt2,
      fill: theme.palette.neutral.cosmicCobalt2,
    },
  },
  disabled: {
    cursor: 'default',
    '& svg': {
      color: theme.palette.dark.disabled,
      fill: theme.palette.dark.disabled,
    },
  },
}));

export { useStyles };
