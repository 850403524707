export const RESET_TELEPHONY_STATE = 'telephony/reset';
export const TELEPHONY_IFRAME_SHOW = 'telephony/iframe/show';
export const TELEPHONY_IFRAME_HIDE = 'telephony/iframe/hide';
export const HAS_REDIALED = 'telephony/hasRedialed;';
export const UPDATE_CALL_STATE = 'telephony/callState/update';
export const TELEPHONY_WARM_TRANSFER_UPDATE = 'telephony/warmTransfer/update';
export const SET_DISPOSITION_TYPE = 'telephony/disposition/type';
export const DISPOSITION_MODAL_OPEN = 'telephony/disposition/isModalOpen';

export const SET_CALL_ON_WRAP_UP_BY_AGENT = 'telephony/callState/wrapUpByAgent';
export const SET_CALL_ON_WRAP_UP_BY_CALLER =
  'telephony/callState/wrapUpByCaller';

export const resetTelephonyState = () => ({
  type: RESET_TELEPHONY_STATE,
});

export const showTelephonyIframe = () => ({
  type: TELEPHONY_IFRAME_SHOW,
});

export const hideTelephonyIframe = () => ({
  type: TELEPHONY_IFRAME_HIDE,
});

export const updateRedialed = (hasRedialed) => ({
  type: HAS_REDIALED,
  payload: { hasRedialed },
});

export const updateCallState = ({ callState, activeContact, flags }) => ({
  type: UPDATE_CALL_STATE,
  payload: { callState, activeContact, flags },
});

export const updateTelephonyWarmTransfer = ({
  agentInfo,
  availableAdvisors,
  cancelWarmTransfer,
  completeWarmTransfer,
  contextAuth,
  warmTransfer,
}) => ({
  type: TELEPHONY_WARM_TRANSFER_UPDATE,
  payload: {
    agentInfo,
    availableAdvisors,
    cancelWarmTransfer,
    completeWarmTransfer,
    contextAuth,
    warmTransfer,
  },
});

export const setCallOnWrapUpByAgent = (isWrapUpByAgent) => ({
  type: SET_CALL_ON_WRAP_UP_BY_AGENT,
  payload: { isWrapUpByAgent },
});

export const setCallOnWrapUpByCaller = (isWrapUpByCaller) => ({
  type: SET_CALL_ON_WRAP_UP_BY_CALLER,
  payload: { isWrapUpByCaller },
});
export const setDispositionType = ({
  activeConference,
  deleteCallsFromLists,
  dispositions,
  isLastCallAttempt,
  leaveConference,
  number,
  setDisposition,
}) => ({
  type: SET_DISPOSITION_TYPE,
  payload: {
    activeConference,
    deleteCallsFromLists,
    dispositions,
    isLastCallAttempt,
    leaveConference,
    number,
    setDisposition,
  },
});

export const setDispositionModalOpen = (dispositionModalOpen) => ({
  type: DISPOSITION_MODAL_OPEN,
  payload: { dispositionModalOpen },
});
