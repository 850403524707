const toContacts = (contacts, residents, leadData, familyFileId) => {
  return {
    primaryContact: _getPrimaryContact(contacts, familyFileId),
    secondaryContact: _getSecondaryContact(contacts, familyFileId),
    additionalContact: _getAdditionalContact(contacts, familyFileId),
    doNotCall: !!leadData.doNotCall,
    careTypes: residents.length > 0 ? residents[0].careTypes : [],
    familyFileId,
  };
};

const _getPrimaryContact = (contacts, familyFileId) => {
  const primaryContact = contacts.find(
    (contact) => contact.profileData.isPrimary,
  );
  if (primaryContact) {
    const primaryContactEmails = primaryContact.profileData.emailAddresses;
    let emailAddresses = [];
    const primaryContactEmailsLength =
      primaryContact.profileData.emailAddresses?.length ?? 0;
    for (let i = 0; i < primaryContactEmailsLength; i++) {
      if (i === 0) {
        emailAddresses.push({
          emailAddress: primaryContactEmails[i].emailAddress,
          isPrimary: true,
        });
      } else {
        emailAddresses.push({
          emailAddress: primaryContactEmails[i].emailAddress,
          isPrimary: false,
        });
      }
    }

    return {
      contactId: primaryContact.contactId,
      profileFormDataId: primaryContact.profileFormDataId,
      formDataId: primaryContact.profileData.formDataId,
      name: getName(primaryContact.profileData),
      firstName: primaryContact.profileData.firstName,
      lastName: primaryContact.profileData.lastName,
      address1: primaryContact.profileData.address1,
      address2: primaryContact.profileData.address2,
      city: primaryContact.profileData.city,
      state: primaryContact.profileData.state,
      country: _getCountryCode(primaryContact.profileData),
      postalCode: primaryContact.profileData.postalCode,
      allowContact: primaryContact.profileData.allowContact,
      isInquirer: primaryContact.profileData.isInquirer,
      allowMailing: primaryContact.profileData.allowMailing,
      relationship: primaryContact.profileData.relationToResident,
      relationToResidentId: primaryContact.profileData.relationToResidentId,
      contactDetail: _buildContactDetail(primaryContact.profileData),
      phones: primaryContact.profileData.phones,

      emailAddresses: emailAddresses,
      oneId: primaryContact.profileData?.oneId?.oneId,
      familyFileId,
      isPrimary: primaryContact.profileData?.isPrimary,
    };
  }
};

const _buildContactDetail = (profileData) => {
  const { tertiaryPhoneType, tertiaryPhone } = getTertiaryPhone(profileData);
  const email = getEmail(profileData);

  if (profileData.emailAddress !== '' && profileData.primaryPhone === '') {
    return { email };
  }
  if (profileData.emailAddress === '' && profileData.primaryPhone !== '') {
    const phonePrimary = profileData.primaryPhone;
    const primaryPhoneType = profileData.primaryPhoneType;
    let phoneSecondary;
    let secondaryPhoneType;
    if (profileData.secondaryPhone !== '') {
      phoneSecondary = profileData.secondaryPhone;
      secondaryPhoneType = profileData.secondaryPhoneType;
    }
    return {
      phonePrimary,
      phoneSecondary,
      primaryPhoneType,
      secondaryPhoneType,
      tertiaryPhone,
      tertiaryPhoneType,
      email,
    };
  }

  if (profileData.emailAddress !== '' && profileData.primaryPhone !== '') {
    const phonePrimary = profileData.primaryPhone;
    const primaryPhoneType = profileData.primaryPhoneType;
    let phoneSecondary;
    let secondaryPhoneType;
    if (profileData.secondaryPhone !== '') {
      phoneSecondary = profileData.secondaryPhone;
      secondaryPhoneType = profileData.secondaryPhoneType;
    }
    return {
      phonePrimary,
      phoneSecondary,
      email,
      primaryPhoneType,
      secondaryPhoneType,
      tertiaryPhone,
      tertiaryPhoneType,
    };
  }
  return {
    phonePrimary: '',
    phoneSecondary: '',
    email,
    primaryPhoneType: '',
    secondaryPhoneType: '',
    tertiaryPhone,
    tertiaryPhoneType,
  };
};

const _getCountryCode = (profileData) => {
  if (profileData.country === undefined || !profileData.country) {
    if (profileData.phones !== undefined && profileData.phones.length > 0) {
      return profileData.phones[0].countryCode;
    }
  }

  return profileData.country;
};

const _getSecondaryContact = (contacts, familyFileId) => {
  const secondaryContact = contacts.find(
    (contact) => !contact.profileData.isPrimary,
  );

  if (secondaryContact) {
    const secondaryContactEmails = secondaryContact.profileData.emailAddresses;
    let emailAddresses = [];
    const secondaryContactEmailsLength =
      secondaryContact.profileData.emailAddresses?.length ?? 0;
    for (let i = 0; i < secondaryContactEmailsLength; i++) {
      if (i === 0) {
        emailAddresses.push({
          emailAddress: secondaryContactEmails[i].emailAddress,
          isPrimary: true,
        });
      } else {
        emailAddresses.push({
          emailAddress: secondaryContactEmails[i].emailAddress,
          isPrimary: false,
        });
      }
    }
    return {
      contactId: secondaryContact.contactId,
      profileFormDataId: secondaryContact.profileFormDataId,
      formDataId: secondaryContact.profileData.formDataId,
      name: getName(secondaryContact.profileData),
      firstName: secondaryContact.profileData.firstName,
      lastName: secondaryContact.profileData.lastName,
      address1: secondaryContact.profileData.address1,
      address2: secondaryContact.profileData.address2,
      city: secondaryContact.profileData.city,
      state: secondaryContact.profileData.state,
      country: _getCountryCode(secondaryContact.profileData),
      postalCode: secondaryContact.profileData.postalCode,
      allowContact: secondaryContact.profileData.allowContact,
      isInquirer: secondaryContact.profileData.isInquirer,
      allowMailing: secondaryContact.profileData.allowMailing,
      relationship: secondaryContact.profileData.relationToResident,
      relationToResidentId: secondaryContact.profileData.relationToResidentId,
      contactDetail: _buildContactDetail(secondaryContact.profileData),
      phones: secondaryContact.profileData.phones,
      emailAddresses: emailAddresses,
      oneId: secondaryContact?.profileData?.oneId?.oneId,
      familyFileId,
    };
  }
};

const _getAdditionalContact = (contacts, familyFileId) => {
  const secondaryContact = contacts.filter(
    (contact) => !contact.profileData.isPrimary,
  );

  if (secondaryContact.length > 1) {
    const additionalContactEmails =
      secondaryContact[1].profileData.emailAddresses;
    let emailAddresses = [];
    const additionalContactEmailsLength =
      secondaryContact[1].profileData.emailAddresses.length;
    for (let i = 0; i < additionalContactEmailsLength; i++) {
      if (i === 0) {
        emailAddresses.push({
          emailAddress: additionalContactEmails[i].emailAddress,
          isPrimary: true,
        });
      } else {
        emailAddresses.push({
          emailAddress: additionalContactEmails[i].emailAddress,
          isPrimary: false,
        });
      }
    }
    return {
      contactId: secondaryContact[1].contactId,
      profileFormDataId: secondaryContact[1].profileFormDataId,
      formDataId: secondaryContact[1].profileData.formDataId,
      name: getName(secondaryContact[1].profileData),
      firstName: secondaryContact[1].profileData.firstName,
      lastName: secondaryContact[1].profileData.lastName,
      address1: secondaryContact[1].profileData.address1,
      address2: secondaryContact[1].profileData.address2,
      city: secondaryContact[1].profileData.city,
      state: secondaryContact[1].profileData.state,
      country: _getCountryCode(secondaryContact[1].profileData),
      postalCode: secondaryContact[1].profileData.postalCode,
      allowContact: secondaryContact[1].profileData.allowContact,
      isInquirer: secondaryContact[1].profileData.isInquirer,
      allowMailing: secondaryContact[1].profileData.allowMailing,
      relationship: secondaryContact[1].profileData.relationToResident,
      relationToResidentId:
        secondaryContact[1].profileData.relationToResidentId,
      contactDetail: _buildContactDetail(secondaryContact[1].profileData),
      phones: secondaryContact[1].profileData.phones,
      emailAddresses: emailAddresses,
      oneId: secondaryContact[1]?.profileData?.oneId?.oneId,
      familyFileId,
    };
  }
};

const getTertiaryPhone = (profileData) => {
  let tertiaryPhoneObject,
    tertiaryPhone,
    tertiaryPhoneType = null;

  const primarySecondaryPhoneTypes = [
    profileData.primaryPhoneType,
    profileData.secondaryPhoneType,
  ];

  if (profileData?.phones?.length > 1) {
    tertiaryPhoneObject = profileData.phones.find(
      (phone) =>
        primarySecondaryPhoneTypes.indexOf(phone.subCategoryCode) === -1,
    );

    tertiaryPhone = tertiaryPhoneObject
      ? `${tertiaryPhoneObject.areaCode}${tertiaryPhoneObject.localNumber}`
      : null;
    tertiaryPhoneType = tertiaryPhoneObject?.subCategoryCode;
  }

  return {
    tertiaryPhone,
    tertiaryPhoneType,
  };
};

const getName = (profileData) => {
  const firstName = profileData?.firstName ? profileData.firstName?.trim() : '';
  const lastName = profileData?.lastName ? profileData.lastName?.trim() : '';

  return `${firstName} ${lastName}`.trim();
};

const getEmail = (profileData) => {
  let email = '';

  if (profileData.emailAddress) {
    email = profileData.emailAddress;
  } else {
    email = profileData?.emailAddresses?.find(
      (email) => email.isPrimary === true,
    )?.emailAddress;
  }

  return email;
};

export { toContacts };
