import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './Contacts.style';
import Typography from 'common/Typography/Typography';
import Divider from 'common/Divider/Divider';
import ChevronBottom from 'common/Icons/arrow/ChevronBottom';
import ChevronTop from 'common/Icons/arrow/ChevronTop';
import clsx from 'clsx';
import ReferralPreferencesDisplay from './ReferralPreferencesDisplay';
import Address from './Address/Address';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CallPhone from 'common/Icons/call/CallPhone';
import Mail from 'common/Icons/basic/Mail';
import Copy from 'common/Icons/basic/Copy';
import Send from 'common/Icons/basic/Send';
import CommentText from 'common/Icons/chatting/CommentText';
import ActionableTooltip from 'common/ActionableTooltip/ActionableTooltip.js';
import Link from 'common/Link/Link';
import { withUser } from 'context/UserContext';
import chatboxClient from 'services/communication/text/Chatbox';
import withConfig from 'utils/withConfig';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import Toast from 'common/Toast/Toast.js';
import { formatPhoneWithParenthesis } from 'utils/phoneFormat';
import { registerEvent } from 'services/Analytics';

const ContactsMobileDisplay = ({
  labels,
  familyFileInfo,
  contact,
  title,
  currentUser,
  flags,
  dialerService,
}) => {
  const familyFileId = familyFileInfo.familyFileId;
  const careTypes = familyFileInfo.careTypes.map((careType) => {
    return careType.code;
  });
  let careTypeCodes = careTypes.toString();
  const doNotCall = familyFileInfo.doNotCall;

  const firstPhone = contact.contactDetail.phonePrimary;
  const secondPhone = contact.contactDetail.phoneSecondary;
  const tertiaryPhone = contact.contactDetail.tertiaryPhone;

  let contactFirstName = '';
  if (contact && contact.firstName) {
    contactFirstName =
      contact.firstName.toUpperCase() === labels.UNKNOWN.toUpperCase()
        ? ''
        : contact.firstName;
  }
  const getEmailAddress = ({ contactDetail, emailAddresses }) => {
    if (contactDetail.email) return contactDetail.email;
    if (!contactDetail.email && emailAddresses.length) {
      return emailAddresses[0].emailAddress;
    }
    return;
  };

  const email = getEmailAddress(contact);
  const firstPhoneType = contact.contactDetail.primaryPhoneType;
  const secondPhoneType = contact.contactDetail.secondaryPhoneType;
  const tertiaryPhoneType = contact.contactDetail.tertiaryPhoneType;
  const address1 = contact.address1;
  const address2 = contact.address2;
  const city = contact.city;
  const postalCode = contact.postalCode;
  const state = contact.state;
  const advisorFirstName = `${currentUser.firstName}`;
  const advisorEmail = `${currentUser.email}`;

  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [telephonyError, setTelephonyError] = useState(null);

  const getPhoneType = (phoneType) => {
    if (phoneType === 'WP') return 'Work Phone';
    if (phoneType === 'HP') return 'Home Phone';
    if (phoneType === 'CP') return 'Cell Phone';
    return '';
  };

  const firstPhoneTitle = getPhoneType(firstPhoneType);
  const secondPhoneTitle = getPhoneType(secondPhoneType);
  const tertiaryPhoneTitle = getPhoneType(tertiaryPhoneType);

  const getDisplayContactRelationship = (contact) => {
    let ContactRelationship = '';
    if (contact.relationship) {
      return `${labels.OPENNING_PARENTHESIS}${contact.relationship}${labels.CLOSING_PARENTHESIS}`;
    }
    return ContactRelationship;
  };

  const getActionableTitleEmail = () => {
    return (
      <div>
        <div className={classes.actionItem}>
          <CopyToClipboard text={email}>
            <span className={classes.pointer_show}>
              <Typography level="small" className={classes.contact}>
                <Copy className={classes.icon} />
                {labels.COPY}
              </Typography>
            </span>
          </CopyToClipboard>
        </div>
        <div className={classes.actionItem}>
          <Typography level="small">
            <Link
              className={classes.contact}
              to={`mailto:${email}?subject=${labels.ADVISOR_EMAIL_SUBJECT.replace(
                '{SLA_NAME}',
                advisorFirstName,
              )}`}
              onClick={() => {
                registerEvent('FamilyFiles', 'Click to Email');
              }}
            >
              <Send className={classes.icon} />
              {labels.SEND_EMAIL}
            </Link>
          </Typography>
        </div>
      </div>
    );
  };

  const clickToDial = async (phoneNumber) => {
    try {
      setTelephonyError(null);
      var response = await dialerService.dialCall(
        phoneNumber,
        currentUser.username,
      );
      if (!response.success) {
        setTelephonyError(response.error);
      }
    } catch (err) {
      setTelephonyError(err.message);
    }
  };

  const textOverriddenNumber = () => {
    return flags.textOverrideNumber;
  };

  const getTextUrlResponse = (phoneNumber) => {
    const textOverrideNumber = textOverriddenNumber();
    const textToPhoneNumber = textOverrideNumber.trim()
      ? textOverrideNumber
      : phoneNumber;

    //Care Type is mandatory to be sent to get the chat url. So if not care type is present send a dummy value of X
    var careTypeCodesList = careTypeCodes ? careTypeCodes : 'X';

    const contactDetails = {
      fullName: contact.name,
      leadId: familyFileId,
      phoneNumber: textToPhoneNumber,
      slaEmail: advisorEmail,
      careTypeList: careTypeCodesList,
    };

    let response = chatboxClient.getTextingServiceUrl(
      withConfig('YGL_COMMUNICATION_SERVICE_TEXT_URL'),
      contactDetails,
    );
    return response;
  };

  const sendTextClickHandler = async (phoneNumber) => {
    await getTextUrlResponse(phoneNumber).then((response) => {
      window.open(response, '_blank');
    });
  };

  const enableOneClickDial = () => {
    return flags.enableOneClickDial && !doNotCall;
  };

  const getActionableTitlePhone = (phoneNumber) => {
    return (
      <div>
        <div className={classes.actionItem}>
          <CopyToClipboard text={phoneNumber}>
            <span>
              <Typography level="small" className={classes.contact}>
                <Copy className={classes.icon} />
                {labels.COPY}
              </Typography>
            </span>
          </CopyToClipboard>
        </div>
        {enableOneClickDial() && (
          <div className={classes.actionItem}>
            <Typography level="small">
              <Link
                onClick={(event) => {
                  event.preventDefault();
                  registerEvent('FamilyFiles', 'Click to Call');
                  clickToDial(phoneNumber);
                }}
                className={classes.contact}
              >
                <CallPhone className={classes.icon} />
                {labels.CALL} {contactFirstName}
              </Link>
            </Typography>
          </div>
        )}
        <div
          className={classes.actionItem}
          onClick={() => {
            registerEvent('FamilyFiles', 'Click to Text');
            sendTextClickHandler(phoneNumber);
          }}
        >
          <Typography level="small" className={classes.contact}>
            <CommentText className={classes.icon} />
            {labels.SEND_TEXT}
          </Typography>
        </div>
      </div>
    );
  };

  const getTertiaryPhoneSection = (
    <>
      <div className={classes.row}>
        <div className={classes.column}>
          <Typography level="tiny" color="eerieBlack5">
            {tertiaryPhoneTitle}
          </Typography>
          <div>
            <ActionableTooltip
              title={getActionableTitlePhone(tertiaryPhone)}
              placement="bottom-start"
            >
              <div className={classes.contact}>
                <CallPhone className={classes.icon} />
                <Typography level="small">
                  {tertiaryPhone
                    ? formatPhoneWithParenthesis(tertiaryPhone)
                    : ''}
                </Typography>
              </div>
            </ActionableTooltip>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div>
      {title === labels.PRIMARY_CONTACT && (
        <div>
          {telephonyError && (
            <div className={[classes.row]}>
              <Toast
                className={classes.toast_error}
                type="error"
                onClose={() => {
                  setTelephonyError(null);
                }}
              >
                {telephonyError}
              </Toast>
            </div>
          )}
          <div className={classes.row}>
            <div
              className={clsx(classes.column, classes.contact_name_container)}
            >
              <div className={classes.contact_name}>
                <Typography level="large">
                  <b>{contact.name}</b> {getDisplayContactRelationship(contact)}
                </Typography>
              </div>
            </div>
          </div>
          {firstPhone && (
            <div className={classes.row}>
              <div className={classes.column}>
                <Typography level="tiny" color="eerieBlack5">
                  {firstPhoneTitle}
                </Typography>
                <div>
                  <ActionableTooltip
                    title={getActionableTitlePhone(firstPhone)}
                    placement="bottom-start"
                  >
                    <div className={classes.contact}>
                      <CallPhone className={classes.icon} />
                      <Typography level="small">
                        {formatPhoneWithParenthesis(firstPhone)}
                      </Typography>
                    </div>
                  </ActionableTooltip>
                </div>
              </div>
            </div>
          )}
          {secondPhone && (
            <div className={classes.row}>
              <div className={classes.column}>
                <Typography level="tiny" color="eerieBlack5">
                  {secondPhoneTitle}
                </Typography>
                <div>
                  <ActionableTooltip
                    title={getActionableTitlePhone(secondPhone)}
                    placement="bottom-start"
                  >
                    <div className={classes.contact}>
                      <CallPhone className={classes.icon} />
                      <Typography level="small">{secondPhone}</Typography>
                    </div>
                  </ActionableTooltip>
                </div>
              </div>
            </div>
          )}
          {tertiaryPhone && getTertiaryPhoneSection}

          {email && (
            <div className={classes.row}>
              <div className={classes.column}>
                <Typography level="tiny" color="eerieBlack5">
                  {labels.EMAIL}
                </Typography>
                <div>
                  <ActionableTooltip
                    title={getActionableTitleEmail()}
                    placement="bottom-start"
                  >
                    <div className={classes.contact}>
                      <Mail className={classes.icon} />
                      <Typography level="small">{email}</Typography>
                    </div>
                  </ActionableTooltip>
                </div>
              </div>
            </div>
          )}
          <Address
            labels={labels}
            address1={address1}
            address2={address2}
            city={city}
            postalCode={postalCode}
            state={state}
          />

          <ReferralPreferencesDisplay labels={labels} contact={contact} />
        </div>
      )}
      {title !== labels.PRIMARY_CONTACT && (
        <div>
          <Divider color="platinum1" />
          {telephonyError && (
            <div className={[classes.row]}>
              <Toast
                className={classes.toast_error}
                type="error"
                onClose={() => {
                  setTelephonyError(null);
                }}
              >
                {telephonyError}
              </Toast>
            </div>
          )}
          <div
            className={clsx(classes.row, classes.secondary_contact)}
            onClick={() => setIsOpen(!isOpen)}
          >
            <div
              className={clsx(classes.column, classes.contact_name_container)}
            >
              <Typography
                level="tiny"
                color="eerieBlack5"
                className={classes.contact_level}
              >
                {title}
                {isOpen ? <ChevronTop /> : <ChevronBottom />}
              </Typography>
              <div className={classes.contact_name}>
                <Typography level="small">
                  <b>{contact.name}</b> {getDisplayContactRelationship(contact)}
                </Typography>
              </div>
            </div>
          </div>
          {isOpen && (
            <div>
              {firstPhone && (
                <div className={classes.row}>
                  <div className={classes.column}>
                    <Typography level="tiny" color="eerieBlack5">
                      {firstPhoneTitle}
                    </Typography>
                    <div>
                      <ActionableTooltip
                        title={getActionableTitlePhone(firstPhone)}
                        placement="bottom-start"
                      >
                        <div className={classes.contact}>
                          <CallPhone className={classes.icon} />
                          <Typography level="small">
                            {formatPhoneWithParenthesis(firstPhone)}
                          </Typography>
                        </div>
                      </ActionableTooltip>
                    </div>
                  </div>
                </div>
              )}
              {secondPhone && (
                <div className={classes.row}>
                  <div className={classes.column}>
                    <Typography level="tiny" color="eerieBlack5">
                      {secondPhoneTitle}
                    </Typography>
                    <div>
                      <ActionableTooltip
                        title={getActionableTitlePhone(secondPhone)}
                        placement="bottom-start"
                      >
                        <div className={classes.contact}>
                          <CallPhone className={classes.icon} />
                          <Typography level="small">
                            {formatPhoneWithParenthesis(secondPhone)}
                          </Typography>
                        </div>
                      </ActionableTooltip>
                    </div>
                  </div>
                </div>
              )}
              {tertiaryPhone && getTertiaryPhoneSection}
              {email && (
                <div className={classes.row}>
                  <div className={classes.column}>
                    <Typography level="tiny" color="eerieBlack5">
                      {labels.EMAIL}
                    </Typography>
                    <div>
                      <ActionableTooltip
                        title={getActionableTitleEmail()}
                        placement="bottom-start"
                      >
                        <div className={classes.contact}>
                          <Mail className={classes.icon} />
                          <Typography level="small">{email}</Typography>
                        </div>
                      </ActionableTooltip>
                    </div>
                  </div>
                </div>
              )}

              <Address
                labels={labels}
                address1={address1}
                address2={address2}
                city={city}
                postalCode={postalCode}
                state={state}
              />
              <ReferralPreferencesDisplay labels={labels} contact={contact} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

ContactsMobileDisplay.propTypes = {
  labels: PropTypes.shape({
    CONTACTS: PropTypes.string.isRequired,
    HOME_PHONE: PropTypes.string.isRequired,
    CELL_PHONE: PropTypes.string.isRequired,
    EMAIL: PropTypes.string.isRequired,
    SECONDARY_CONTACT: PropTypes.string.isRequired,
    PRIMARY_CONTACT: PropTypes.string.isRequired,
    UNKNOWN: PropTypes.string.isRequired,
    OPENNING_PARENTHESIS: PropTypes.string,
    CLOSING_PARENTHESIS: PropTypes.string,
    ADDRESS: PropTypes.string,
    COPY: PropTypes.string,
    CALL: PropTypes.string,
    SEND_TEXT: PropTypes.string,
    SEND_EMAIL: PropTypes.string,
    ADVISOR_EMAIL_SUBJECT: PropTypes.string,
    WORK_PHONE: PropTypes.string,
  }).isRequired,
  contact: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    name: PropTypes.string,
    relationship: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    state: PropTypes.string,
    contactDetail: PropTypes.shape({
      phonePrimary: PropTypes.string,
      phoneSecondary: PropTypes.string,
      email: PropTypes.string,
      primaryPhoneType: PropTypes.string,
      secondaryPhoneType: PropTypes.string,
      tertiaryPhone: PropTypes.string,
      tertiaryPhoneType: PropTypes.string,
    }),
  }),
  title: PropTypes.string,
  familyFileInfo: PropTypes.shape({
    familyFileId: PropTypes.number,
    careTypes: PropTypes.arrayOf(PropTypes.object),
    doNotCall: PropTypes.bool,
  }),
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    username: PropTypes.string,
  }),
  flags: PropTypes.shape({
    enableOneClickDial: PropTypes.bool,
    textOverrideNumber: PropTypes.string,
  }),
  dialerService: PropTypes.object,
};

ContactsMobileDisplay.defaultProps = {};

export default withLDConsumer()(withUser(ContactsMobileDisplay));
