import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './DropdownWithSearchInputStyle';
import Search from '../../common/Icons/basic/Search';
import Delete from '../../common/Icons/basic/Delete';
import Dropdown from '../../common/Dropdown/Dropdown';
import TextInput from '../../common/TextInput/TextInput';
import { registerEvent } from '../../services/Analytics';
import useQueryParams from '../../hooks/useQueryParams';

const DropdownWithSearchInput = ({ items, onEnterPressed }) => {
  const classes = useStyles();
  const filterType = useQueryParams('type');
  const filterValue = useQueryParams('value');
  const [text, setText] = useState(filterValue || '');
  const [selectedElement, setSelectedElement] = useState(
    items[0] ? items[0] : undefined,
  );
  const [placeholder, setPlaceholder] = useState('');

  const cleanInput = () => {
    setText('');
  };

  const getEndIcon = () => {
    if (text.length > 0) {
      return (
        <div onClick={cleanInput} className={classes.delete_icon}>
          <Delete />
        </div>
      );
    }
  };
  const getPlaceholder = (lastElement) => {
    setSelectedElement(lastElement);
  };

  useEffect(() => {
    setPlaceholder(selectedElement.placeholder);
    registerEvent('Search', 'Search in Topbar', selectedElement.label);
  }, [selectedElement]);

  const enterFunction = (event) => {
    if (event.key === 'Enter') {
      onEnterPressed(selectedElement.value, text);
    }
  };

  const getSelectedItem = (value) => {
    return items.find((i) => i.value === value) || items[0];
  };

  return (
    <div className={classes.filters_container}>
      <Dropdown
        className={classes.filters}
        items={items}
        selectedItem={getSelectedItem(filterType)}
        onChange={getPlaceholder}
      />
      <TextInput
        onKeyPress={enterFunction}
        className={classes.filters_input}
        id="location_filter_input"
        startIcon={<Search />}
        value={text}
        endIcon={getEndIcon()}
        onChange={setText}
        autoComplete="off"
        placeholder={placeholder}
      />
    </div>
  );
};

DropdownWithSearchInput.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      placeholder: PropTypes.string,
    }),
  ).isRequired,
  onEnterPressed: PropTypes.func,
};

export default DropdownWithSearchInput;

export { DropdownWithSearchInput };
