import { calculateDiff } from 'utils/calculateDiff';
import FamilyFileFormModel from '../FamilyFileFormModel';

/**
 * Class defining the general structure of the values stored within the Financial Criteria form.
 */
class CommunityPreferenceFormModel extends FamilyFileFormModel {
  /**
   * Constructs an instance of FinancialCriteriaFormModel.
   *
   * @param {Object} data - Object matching the structure of a FinancialCriteriaFormModel.
   */
  constructor(data) {
    super();

    // Various identifiers
    this.oneId = data.oneId;
    this.familyFileId = data.familyFileId;
    this.residentId = data.residentId;

    // An array of objects representing individual care type preferences (i.e. "Memory Care", "Home Care", etc.)
    // The objects should have the following properties:
    //   - careTypeId: An integer programmatic identifier for the care type (i.e. 1)
    //   - code: A string programmatic identifier for the care type (i.e. "A")
    //   - name: A human readable representation of the care type (i.e. "Assisted Living")
    this.careTypePreference =
      data?.careTypePreference?.map(({ careTypeId, code, name }) => ({
        careTypeId,
        code,
        name,
      })) || [];

    // An array of strings representing individual room preferences (i.e. "STUDIO", "PRIVATE_ONE_BEDROOM", etc.)
    this.roomPreference = data.roomPreference || [];

    // Developer Note: With the introduction of the IC workflow, the location range component moved to the Resident
    // Information section. With the full launch of the IC workflow and completion of tech debt BUB-2625, this can be
    // removed.
    // An integer indicating how many miles away the community can be.
    this.locationRange = data.locationRange;
  }

  calculateDiff(other) {
    const sanitizedInputA = {
      ...this,
      careTypePreference: [...this.careTypePreference].sort(careTypeComparator),
      roomPreference: [...this.roomPreference].sort(roomPreferenceComparator),
    };
    const sanitizedInputB = {
      ...other,
      careTypePreference: [...other.careTypePreference].sort(
        careTypeComparator,
      ),
      roomPreference: [...other.roomPreference].sort(roomPreferenceComparator),
    };

    const keysToCompare = [...Object.keys(this)].filter(
      (key) => key !== 'calculateDiff',
    );

    return calculateDiff(sanitizedInputA, sanitizedInputB, keysToCompare);
  }
}

function careTypeComparator(careType1, careType2) {
  return careType1.careTypeId - careType2.careTypeId;
}

function roomPreferenceComparator(roomType1, roomType2) {
  return roomType1 - roomType2;
}

export default CommunityPreferenceFormModel;
