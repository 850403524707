import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => {
  return {
    checkbox_group: {
      display: 'block',
      '& label': {
        width: '100%',
        height: '30px',
      },
    },
  };
});
export { useStyles };
