import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  main_container: {},
  center_container: {},
  side_bar: {},
  optional_chiclet: {
    backgroundColor: theme.palette.neutral.cosmicCobalt5,
    color: theme.palette.dark.eerieBlack,
    padding: '.75rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '.5rem',
    '&:hover': {
      cursor: 'pointer',
    },
    divider: {
      padding: '1rem 0',
    },
  },
  info_row: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 8,
  },
  name_row: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  right_spaced: {
    marginRight: 4,
  },
  centered_icon: {
    display: 'flex',
    alignItems: 'center',
  },
  two_cols: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  new_inquiry_container: {
    display: 'flex',
    flexDirection: 'column',
  },
  body: {
    padding: '32px',
    backgroundColor: theme.palette.light.platinum3,
  },
  callbarNotes: {
    alignItems: 'right',
  },
  header_content: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '16px',
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 10,
  },
  external_call_component: {
    marginRight: '20px',
  },
  notes_component: {
    alignSelf: 'center',
  },
  audience_tag: {
    margin: '16px 0 ',
  },
  urgent_audience_alert: {
    margin: '12px 0',
  },
  script_name: {
    fontSize: '15px',
    marginBottom: '17px',
    padding: '17px 0 2px',
  },
}));

export { useStyles };
