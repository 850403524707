import { PhoneContext } from '@aplaceformom/owl-connect-client';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import LightHouse from 'common/Graphics/LightHouse';
import OldMan from 'common/Graphics/OldMan';
import OldWoman from 'common/Graphics/OldWoman';
import Toast from 'common/Toast/Toast';
import Typography from 'common/Typography/Typography';
import ExternalCall from 'components/Call/ExternalCall/ExternalCall';
import allCallStates, {
  getCallType,
  callTypes,
} from 'config/callStates.config';
import { withAuthenticationContext } from 'context/Authentication';
import { withLabels } from 'context/LabelContext';
import { useAutoCloseFive9 } from 'hooks/useAutoCloseFive9';
import { useInquiryIdFromActiveContact } from 'hooks/useInquiryIdFromActiveContact';
import {
  updateCloseReason,
  updateDisposition,
} from 'stateManagement/callcenter/formChiclets/actions/formChicletsActions';
import {
  selectDisposition,
  selectManualInquiryState,
} from 'stateManagement/callcenter/formChiclets/reducers/formChicletsReducer';
import {
  createInquiryRequest,
  updateCallId,
} from 'stateManagement/callcenter/inquiry/actions/inquiryActions';
import {
  selectCallId,
  selectCreatedInquiry,
  selectInquiry,
  selectLatestInquiryId,
  selectIsInboundCallFinished,
} from 'stateManagement/callcenter/inquiry/reducers/inquiryReducer';
import CallBar from '../Inquiry/CallBar/CallBar';
import { callBarScreens } from '../Inquiry/CallBar/callBarScreenEnum';
import DispositionsModal from '../Inquiry/DispositionsModal/DispositionsModal';
import { useStyles } from './HomeContent.style';
import { logCallCenterHomepageViewed } from 'services/callCenter/amplitudeEventsService';
import { logCallTransferred } from 'services/callCenter/amplitudeEventsService';
import Loading from 'components/Loading/Loading';

const STATUSES_WITH_CALL_BAR = ['TALKING', 'ON_HOLD', 'RINGING_ON_OTHER_SIDE'];

const showCallBar = (state) => STATUSES_WITH_CALL_BAR.includes(state);

const HomeContent = ({ auth, labels, flags }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    agentState,
    callPreview,
    activeCampaigns,
    callData,
    callState,
    transferExternalCall,
    isAuthenticated,
    skills,
    endCall,
    setDisposition,
    dispositions,
  } = useContext(PhoneContext);
  const { number, dnis } = callData;

  const [dispositionsModalOpen, setDispositionsModalOpen] = useState(false);

  const disposition = useSelector(selectDisposition);
  const { inquiry } = useSelector(selectInquiry);
  const callType = getCallType(
    activeCampaigns,
    callData,
    callPreview,
    callState,
  );
  const callId = useSelector(selectCallId);
  const latestInquiryId = useSelector(selectLatestInquiryId);
  const {
    isInboundCallInquiryCreating,
    isInboundCallInquiryCreated,
    inboundCallInquiry,
  } = useSelector(selectCreatedInquiry);
  const manualInquiryState = useSelector(selectManualInquiryState);
  const isInboundCallFinished = useSelector(selectIsInboundCallFinished);

  useAutoCloseFive9(isAuthenticated, skills, dispatch);

  useInquiryIdFromActiveContact(
    inquiry,
    callPreview,
    latestInquiryId,
    null,
    flags.allowDisabledChiclets,
  );

  const loggedOnce = useRef(false);

  useEffect(() => {
    if (loggedOnce.current) {
      return;
    }
    logCallCenterHomepageViewed();
    loggedOnce.current = true;
  });

  useEffect(() => {
    if (disposition) {
      const foundDisposition = dispositions.find(
        (d) => d.name?.trim() === disposition?.trim() && d.id > 0,
      );
      setDisposition(foundDisposition.id, foundDisposition.name);
      setDispositionsModalOpen(false);
      dispatch(updateDisposition(null));
    }
  }, [disposition, dispositions, setDisposition, dispatch]);

  useEffect(() => {
    if (callState === allCallStates.WRAP_UP.key) {
      setDispositionsModalOpen(true);
    }
  }, [callState]);

  const getWelcomeText = () => {
    const name = auth.user.given_name || auth.user.name;
    return `${labels.WELCOME}, ${name}!`;
  };

  const getCallStatusTitle = () => {
    let title = labels.READY_TO_START_YOUR_DAY;
    if (isAuthenticated) {
      title = labels.FINISHED_WITH_CALLS;
    }
    return title;
  };

  const getDefaultCallText = () => {
    let text = labels.LAUNCH_LOGIN_FIVE9;
    if (isAuthenticated) {
      text = labels.GO_OFFLINE_CALLS;
    }
    return text;
  };

  const getExternalCall = () => {
    if (isAuthenticated && !showCallBar(callState)) {
      return (
        <div className={classes.externalCallContainer}>
          <ExternalCall className={classes.externalCall} />
        </div>
      );
    }
  };

  if (isInboundCallInquiryCreating) {
    return <Loading />;
  }

  if (
    callType === callTypes.INBOUND &&
    callId !== callData?.callId &&
    !isInboundCallInquiryCreating &&
    !isInboundCallInquiryCreated &&
    callState !== null &&
    callState !== allCallStates.FINISHED.key
  ) {
    dispatch(createInquiryRequest(number, dnis));
    dispatch(updateCallId(callData?.callId));
  }

  if (inquiry) {
    if (
      (callPreview?.state === 'PREVIEW' || callPreview?.state === 'ON_CALL') &&
      callPreview?.contact?.inquiryId &&
      agentState.name === 'Ready'
    ) {
      return (
        <Redirect to={`/callcenter/inquiry/${callPreview.contact.inquiryId}`} />
      );
    }

    if (
      callType === callTypes.INBOUND &&
      agentState.name === 'Ready' &&
      !isInboundCallFinished &&
      isInboundCallInquiryCreated &&
      JSON.stringify(callState) !== null
    ) {
      const url = `/callcenter/inquiry/${inboundCallInquiry.inquiryId}`;
      return <Redirect to={url} />;
    }
  }

  const onTransfer = (element) => {
    if (element.value !== 'resourceLine') {
      dispatch(
        updateCloseReason({
          closeInquiry: true,
          closeReason: element.closeInquiryReason,
          isNoAnswerSelected: false,
        }),
      );
    }
    transferExternalCall(element.phoneNumber);
    logCallTransferred(
      inquiry?.inquiryId,
      inquiry?.familyFileId,
      element.label,
    );
  };

  const onEndCall = async (endCallReason) => {
    await endCall()
      .then(() => {
        let homeScreenDisposition = dispositions.filter(
          (d) => d.name === endCallReason,
        );

        if (homeScreenDisposition.length && endCallReason) {
          setDisposition(homeScreenDisposition[0].id, endCallReason);
        } else {
          console.log(
            'home screen - onEndCall error',
            endCallReason,
            dispositions,
            homeScreenDisposition,
          );
          setDispositionsModalOpen(true);
        }
      })
      .catch((error) => {
        console.error(`Error on end call ${error}`);
      });
  };

  return (
    <Fragment>
      <DispositionsModal
        isOpen={dispositionsModalOpen}
        onClose={() => setDispositionsModalOpen(false)}
        labels={labels}
        dispositions={dispositions}
      />
      {showCallBar(callState) && (
        <CallBar
          onEndCall={onEndCall}
          onTransfer={onTransfer}
          screen={callBarScreens.HOME}
        />
      )}
      <div className={classes.outerWrapper}>
        {manualInquiryState.isCreatedManualInquiry && (
          <Toast
            type="success"
            className={classes.successfullManualInquiry}
            onClose={() => {}}
          >
            <Typography>
              {labels.INQUIRY_WAS_CREATED_SUCCESSFULLY.replace(
                '{INQUIRY_ID}',
                latestInquiryId,
              )}
            </Typography>
          </Toast>
        )}
        <div className={classes.dashboardContainer}>{getExternalCall()}</div>

        <div className={classes.starDayContainer}>
          <div className={classes.top}>
            <div className={classes.welcomeMessageContainer}>
              <Typography level="h3" color="platinum5">
                {getWelcomeText()}
              </Typography>
              <Typography
                className={classes.welcomeBodyDefaultText}
                level="body"
                color="platinum5"
              >
                {labels.THIS_IS_YOUR_BEACON}
              </Typography>
            </div>
            <div className={classes.oldPersonsContainer}>
              <OldWoman className={classes.oldWoman} />
              <OldMan className={classes.oldMan} />
            </div>
          </div>
          <div className={classes.bottom}>
            <div className={classes.callStatusContainer}>
              <Typography level="h4" color="platinum5">
                {getCallStatusTitle()}
              </Typography>
              <Typography
                className={classes.callStatusDefaultText}
                level="body"
                color="platinum5"
              >
                {getDefaultCallText()}
              </Typography>
            </div>
            <div className={classes.lightHouseContainer}>
              <LightHouse className={classes.lightHouse} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

HomeContent.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      given_name: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
    logout: PropTypes.func.isRequired,
  }).isRequired,
  labels: PropTypes.object.isRequired,
  flags: PropTypes.shape({
    allowDisabledChiclets: PropTypes.bool,
  }),
};

export default withLDConsumer()(
  withAuthenticationContext(withLabels(HomeContent)),
);
