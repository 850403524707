import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Typography from 'common/Typography/Typography';
import { useStyles } from './DuplicateFamilyFilePopup.style';
import Link from 'common/Link/Link';
import Loading from 'components/Loading/Loading';
import FamilyFilePopup from './FamilyFilePopup';
import Toast from 'common/Toast/Toast';
import useGraphQLQuery from 'hooks/useGraphQLQuery';
import DuplicateResidentQuery from 'queries/DuplicateResidentQuery';

const DuplicateFamilyFile = ({ labels, params, externalId, familyFileId }) => {
  const classes = useStyles();
  let showPopup = false;
  let filteredFoundFamilyFile;
  let filteredCurrentFamilyFile;
  const [openPopup, setOpenPopup] = useState(false);
  const { loading, error, data } = useGraphQLQuery(DuplicateResidentQuery, {
    variables: params,
  });

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  if (
    data.findFamilyFileDuplicatedResidents &&
    data.findFamilyFileDuplicatedResidents.length > 0
  ) {
    filteredFoundFamilyFile = data.findFamilyFileDuplicatedResidents.filter(
      (item) =>
        item.familyFileId !== familyFileId && item.externalId !== externalId,
    );
    filteredCurrentFamilyFile = data.findFamilyFileDuplicatedResidents.filter(
      (item) =>
        item.familyFileId === familyFileId || item.externalId === externalId,
    );
    filteredCurrentFamilyFile = { ...filteredCurrentFamilyFile[0], ...params };

    if (filteredFoundFamilyFile.length > 0) showPopup = true;
  }

  return (
    <Fragment>
      {showPopup && (
        <Fragment>
          <div className={classes.toast}>
            <Toast type="error">
              <Typography>
                {labels.THERE}
                {filteredFoundFamilyFile.length}
                {labels.POTENTIAL_FOUND_DUPLICATE}
                &nbsp;
              </Typography>
              <div>
                <Link
                  className={classes.link}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenPopup(true);
                  }}
                >
                  {labels.CHECK_AND_EVALUATE_LINK}
                </Link>
              </div>
            </Toast>
          </div>

          <FamilyFilePopup
            params={params}
            isOpen={openPopup}
            currentFamilyFile={filteredCurrentFamilyFile}
            foundFamilyFile={filteredFoundFamilyFile}
            onClose={() => setOpenPopup(false)}
            labels={labels}
          />
        </Fragment>
      )}
      {showPopup && <Fragment></Fragment>}
    </Fragment>
  );
};

DuplicateFamilyFile.propTypes = {
  labels: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  familyFileId: PropTypes.number.isRequired,
  externalId: PropTypes.number.isRequired,
};

export default DuplicateFamilyFile;
