import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const DocumentHead = ({ title }) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

DocumentHead.propTypes = {
  title: PropTypes.string,
};

export default DocumentHead;
