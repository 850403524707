import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './ReferralSource.style';
import TextInput from 'common/TextInput/TextInput';
import { inquiryPropType } from '../../inquiryPropTypes';
import Dropdown from 'common/Dropdown/Dropdown';
import inquiryService from 'services/callCenter/inquiryService';
import { useSelector } from 'react-redux';
import { selectCatalogs } from 'stateManagement/callcenter/catalogs/reducers/catalogsReducer';

const ReferralSource = ({ inquiry, answer, labels, onChange, errors }) => {
  const classes = useStyles();
  const [referralErrors, setReferralErrors] = useState(errors);
  const { catalogs } = useSelector(selectCatalogs);

  const source =
    answer.userInputValues && answer.userInputValues.source
      ? answer.userInputValues.source
      : inquiryService.getInfo(inquiry, 'referralSource', labels);
  const subSource =
    answer.userInputValues && answer.userInputValues.subSource
      ? answer.userInputValues.subSource
      : inquiryService.getInfo(inquiry, 'referralSubSource', labels);

  const [referralSource, setReferralSource] = useState({
    source: source || '',
    subSource: subSource || '',
  });

  const handleChange = (id, value) => {
    const newReferralSource = { ...referralSource, [id]: value };

    setReferralSource(newReferralSource);
    onChange({
      value: answer.value,
      userInputValues: newReferralSource,
    });
  };

  useEffect(() => {
    answer.userInputValues = {
      subSource: subSource || referralSource.subSource,
      source: source || referralSource.source,
    };
  }, [subSource, source, referralSource, answer.userInputValues]);

  useEffect(() => {
    setReferralErrors(errors);
  }, [errors]);

  const getFieldError = (fieldName) => {
    return referralErrors.find((error) => error.match(new RegExp(fieldName)));
  };

  const getFieldLabel = (fieldName) => {
    const error = getFieldError(fieldName);
    switch (fieldName) {
      case 'source':
        return error ? error : labels.REFERRAL_SOURCE;
      case 'subSource':
        return error ? error : labels.REFERRAL_SUB_SOURCE;
      default:
        return '';
    }
  };

  const allItems = catalogs?.referralSources
    .map((currentValue) => ({
      label: currentValue.enumValue + ' - ' + currentValue.enumLabel,
      value: currentValue.enumValue,
    }))
    .sort((a, b) => a.value - b.value);

  const selectedTargetItem = referralSource.source
    ? referralSource.source
    : 'Select from list';

  const selectedTarget = allItems.find(
    (item) => item.value.toString() === selectedTargetItem.toString(),
  );

  return (
    <Fragment>
      <Dropdown
        className={classes.dropdown_input}
        items={allItems}
        label={getFieldLabel('source')}
        selectedItem={selectedTarget}
        onChange={(e) => handleChange('source', e.value)}
      />
      <div className={classes.secondBlock}>
        <TextInput
          id="subSource"
          type="number"
          value={referralSource.subSource}
          className={classes.default_input}
          onChange={(value) => handleChange('subSource', value)}
          isMandatory={true}
          label={getFieldLabel('subSource')}
          error={!!getFieldError('subSource')}
          autoComplete="off"
        />
      </div>
      <div className={classes.last_item}>
        <Dropdown disabled items={[]} label={labels.HCAE} onChange={() => {}} />
      </div>
    </Fragment>
  );
};

ReferralSource.propTypes = {
  inquiry: inquiryPropType,
  answer: PropTypes.object,
  labels: PropTypes.object,
  onChange: PropTypes.func,
  errors: PropTypes.array,
};

export default ReferralSource;
