import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  call_bar_container: {
    display: 'flex',
    height: '64px',
    justifyContent: 'space-between',
    background: 'linear-gradient(90deg, #43CEA2 0%, #185A9D 100%)',
    position: 'fixed',
    top: 72,
    width: '100%',
    zIndex: 13,
    '&.DISCONNECTED': {
      background: theme.palette.dark.eerieBlack5,
    },
    '&.CONNECTING': {
      background: 'linear-gradient(90deg, #3956B5 0%, #4568DC 100%)',
    },
    '&.DIALING': {
      background: theme.palette.neutral.cosmicCobalt3,
    },
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 20px',
    alignItems: 'flex-start',
  },
  center: {
    alignItems: 'center',
    display: 'flex',
  },
  contact_info: {
    display: 'flex',
    width: '110%',
  },
  phone_number: {
    marginLeft: '23px',
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    marginRight: '17px',
    '& > div': {
      marginRight: '16px',
      '&.invisible': {
        marginRight: '0px',
      },
    },
  },
  divider: {
    width: '1px',
    backgroundColor: theme.palette.dark.eerieBlack1,
    height: '40px',
    padding: '12px 0',
    boxSizing: 'border-box',
  },
  disabled: {
    cursor: 'default',
    '& svg': {
      color: theme.palette.dark.disabled,
      fill: theme.palette.dark.disabled,
    },
  },
}));

export { useStyles };
