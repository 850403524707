import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => ({
  expandable: {
    paddingTop: '16px',
  },
  title: {
    '& p': {
      marginBottom: 0,
    },
  },
  family_file_history_container: {
    '& $expandable:last-child': {
      marginBottom: '24px',
    },
  },
}));

export { useStyles };
