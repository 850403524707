import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/Modal/Modal';
import Typography from 'common/Typography/Typography';
import { useStyles } from './LoadingPopup.styles';
import Searching from 'common/Graphics/animations/searching/searching';

const LoadingPopup = ({ title, isOpen, waitingMessage }) => {
  const classes = useStyles();
  return (
    <Modal
      className={classes.modal_container}
      noBodyPadding
      title={title}
      isOpen={isOpen}
      color="cosmicCobalt2"
      showCloseIcon={false}
      noBodyScroll
    >
      <div className={classes.modal_body}>
        <div className={classes.search_animation}>
          <Searching />
          <Typography
            level="tiny"
            color="cosmicCobalt3"
            className={classes.subtitle}
          >
            {waitingMessage}
          </Typography>
        </div>
      </div>
    </Modal>
  );
};

LoadingPopup.propTypes = {
  isOpen: PropTypes.bool,
  waitingMessage: PropTypes.string,
  title: PropTypes.string,
};

export default LoadingPopup;
