const getErrorMessage = (errors) => {
  let message = '';

  if (errors.graphQLErrors && errors.graphQLErrors.length > 0) {
    message += errors.graphQLErrors[0].message;
  } else if (errors.message && errors.message.length > 0) {
    message += errors.message;
  }

  return message;
};

export default getErrorMessage;
