import React from 'react';
import { withAuthenticationContext } from '../../context/Authentication';
import { withLabels } from '../../context/LabelContext';
import { withUser } from '../../context/UserContext';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import TopBarXS from '../TopBar/TopBarXS';
import TopBarXL from '../TopBar/TopBarXL';
import TopBarWithoutSearchInput from '../TopBar/TopBarWithoutSearch';
import Media from '../../common/Media/Media';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const TopBar = ({ flags }) => {
  const history = useHistory();
  const onSearch = (filterType, filterValue) => {
    history.push(
      `/search?type=${filterType}&value=${filterValue}&page=1&pageSize=10`,
    );
  };

  return (
    <div>
      {flags.searchTopBar ? (
        <Media
          xs={<TopBarXS onSearch={onSearch} />}
          sm={<TopBarXL onSearch={onSearch} isMobile={true} />}
          md={<TopBarXL onSearch={onSearch} />}
        ></Media>
      ) : (
        <TopBarWithoutSearchInput />
      )}
    </div>
  );
};

TopBar.propTypes = {
  flags: PropTypes.object,
};

export default withLDConsumer()(
  withUser(withLabels(withAuthenticationContext(TopBar))),
);
export { TopBar };
