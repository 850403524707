export default {
  INTERNAL_ERROR: 'Internal error',
  SERVER_UNAVAILABLE: 'Server unavailable',
  NOT_AUTHORIZED: 'Not authorized',
  SKILL_VOICEMAIL_HAS_NO_CONTACT: 'Skill voicemail has no contact',
  INVALID_ACCOUNT: 'Invalid account',
  PASSWORD_EXPIRED: 'Password expired',
  ACCOUNT_LOCKED: 'Account locked',
  DUPLICATE_LOGIN: 'Duplicate login',
  CALL_NOT_IN_TIME: 'Call not in time',
  NUMBER_IN_DNC_LIST: 'Phone number is on the Do Not Call list',
  Q_NUMBER_IN_DNC_LIST_FORCE: 'Phone number is on the Do Not Call list',
  ALREADY_ON_FARM: 'Already on farm',
  USER_HAS_ACTIVE_TASK: 'User has active task',
  Q_MULTIPLE_CONTACTS_SELECT: 'Multiple contacts select',
  UNSUPPORTED: 'Unsupported',
  FAILED_TO_REMOVE_CONTACT: 'Failed to remove contact',
  SIMILAR_CONTACT: 'Similar contact',
  INCORRECT_CONTACT_FIELD_VALUE: 'Incorrect contact field value',
  CONTACT_DOES_NOT_EXIST: 'Contact does not exist',
  CONTACT_OBSOLETE: 'Contact obsolete',
  FIELD_DOES_NOT_EXIST: 'Field does not exist',
  UNMODIFIABLE_CONTACT_FIELD: 'Unmodifiable contact field',
  CRM_SERVICE_FAILURE: 'CRM service failure',
  CRM_SERVICE_UNAVAILABLE: 'CRM service unavailable',
  NO_CAMPAIGN_SCRIPT: 'No campaign script',
  SOCIAL_SERVICES_UNAVAILABLE: 'Social services unavailable',
  DUPLICATE_CHAT_ID: 'Duplicate chat id',
  RETRY_LATER: 'Retry later',
  RESTART_STATION_FAILED: 'Restart station failed',
  STATION_BUSY: 'Station busy',
  STATION_RESTARTING: 'Station restarting',
  CALLER_DISCONNECTED: 'Caller disconnected',
  PASSWORD_MATCH_PREVIOUS: 'Password match previous',
  PASSWORD_MIN_CAPITAL_LETTERS: 'Password min capital letters',
  PASSWORD_MIN_DIGITS: 'Password min digits',
  PASSWORD_MIN_LENGTH: 'Password min length',
  PASSWORD_MIN_SPECIAL_CHARACTERS: 'Password min special characters',
  CANNOT_LOG_OUT_DURING_ACTIVE_CALL: 'Cannot log out during active call',
  CANNOT_LOG_OUT_WHEN_PARKED_CALLS: 'Cannot log out when parked calls',
  CANNOT_LOG_OUT_AFTER_ACCEPTING_VOICEMAIL:
    'Cannot log out after accepting voicemail',
  CANNOT_DO_FOR_TEST_CALL: 'Cannot do for test call',
  INVALID_WAV_FILE_FORMAT: 'Invalid wav file format',
  VOICEMAIL_MAX_COMMENT_LENGTH_EXCEEDED:
    'Voicemail max comment length exceeded',
  WRONG_PASSWORD: 'Wrong password',
  CONSULTANT_DISCONNECTED: 'Consultant disconnected',
  MUST_BE_NOT_READY_TO_MAKE_ECHO_CALL: 'Must be not ready to make echo call',
  INVALID_PHONE_NUMBER: 'Invalid phone number',
  NO_RECIPIENTS_AVAILABLE: 'No recipients available',
  NOT_A_NUMBER_FIELD: 'Not a_number field',
  NUMBER_IS_RESTRICTED_FOR_CALL: 'Number is restricted for call',
  INCORRECT_CONTACT_RECORD_NO_NUMBER_FIELDS:
    'Incorrect contact record no number fields',
  INVALID_FILE_FORMAT: 'Invalid file format',
  PSTN_BILL_RATE_NOT_FOUND: 'PSTN bill rate not found',
  INVALID_STATION_ID: 'Invalid station id',
  HAS_ACTIVE_TASK: 'Has active task',
  AGENT_NOT_TRANFERABLE: 'Agent not transferable',
  PREVIEW_HAS_NO_NUMBERS_TO_CALL: 'Preview has no numbers to call',
  INVALID_CONTACT_RECORD: 'Invalid contact record',
  EMAIL_ATTACHMENT_ID_NOT_FOUND: 'Email attachment id not found',
  EMAIL_ATTACHMENT_FILE_NOT_FOUND: 'Email attachment file not found',
  NOT_ALLOWED_TO_CHANGE_LANGUAGE: 'Not allowed to change language',
  UNSUPPORTED_LANGUAGE: 'Unsupported language',
  INVALID_WORKSHEET_ANSWERS: 'Invalid worksheet answers',
  PREVIEW_HAS_ACTIVE_CALL: 'Preview has active call',
  CANNOT_LOG_OUT_WHEN_QUEUED_CALLS: 'Cannot log out when queued calls',
  PASSWORD_NOT_ALLOWED_SPECIAL_CHARACTERS:
    'Password not allowed special characters',
  PASSWORD_CANNOT_CONTAIN_USERNAME: 'Password cannot contain username',
  AGENT_IS_NOT_LOGGED_IN: 'Agent is not logged in',
  INVALID_AGENT_RECORDING_STATE: 'Invalid agent recording state',
  AGENT_DOES_NOT_HAVE_PERMISSION: 'Agent does not have permission',
  AGENT_IS_NOT_IN_CORRECT_STATE: 'Agent is not in correct state',
  JAVA_AGENT_CANNOT_BE_READY_FOR_NON_VOICE_CHANNELS:
    'Java agent cannot be ready for non voice channels',
  PASSWORD_IN_BLACK_LIST: 'Password in black list',
  MAX_RUNNING_CAMPAIGNS: 'Max running campaigns',
  PROHIBITED_CAMPAIGN_DIALING_MODE: 'Prohibited campaign dialing mode',
  AUTODIAL_CAMPAIGN_SUPPRESSED: 'Auto-dial campaign suppressed',
  FORCE_LOGOUT_ERROR: 'Force logout error',
  CANNOT_FORCE_LOGOUT_SELF: 'Cannot force logout self',
  MONITOR_AGENT_FAILED: 'Monitor agent failed',
  AGENT_IS_NOT_READY_FOR_CALLS: 'Agent is not ready for calls',
  ALERT_NOT_FOUND_OR_NOT_ACCESSIBLE_TO_USER:
    'Alert not found or not accessible to user',
  ALERT_UPDATED_BY_OTHER_SESSION: 'Alert updated by other session',
  USER_TRIES_TO_MODIFY_ALERT_CREATED_BY_OTHER_USER:
    'User tries to modify alert created by other user',
  USER_TRIES_TO_MODIFY_ALERT_INFO_FOR_OTHER_USER:
    'User tries to modify alert info for other user',
  USER_DOES_NOT_HAVE_RIGHTS_TO_DELETE_ALERT:
    'User does not have rights to delete alert',
  OBJECT_LOCKED_BY_ANOTHER_USER: 'Object locked by another user',
  BATCH_OPERATION_ERROR: 'Batch operation error',
  AGENT_HAS_PROFILE: 'Agent has profile',
  CATEGORY_NOT_FOUND: 'Category not found',
  CATEGORY_UPDATED_BY_OTHER_SESSION: 'Category updated by other session',
  CATEGORY_KEY_NOT_FOUND: 'Category key not found',
  CATEGORY_ITEM_NOT_FOUND: 'Category item not found',
  CANNOT_SEND_EMAIL: 'Cannot send email',
  NO_EMAIL_CONFIGURED: 'No email configured',
  WORKSHEET_NOT_FOUND: 'Worksheet not found',
  WORKSHEET_UPDATED_BY_OTHER_SESSION: 'Worksheet updated by other session',
  AGENT_CHANNEL_NOT_PERMITTED: 'Agent channel not permitted',
  DIALING_LIST_EMPTY: 'Dialing list empty',
  NUMBER_NOT_IN_SMS_OPTIN_LIST: 'Number not in SMS option list',
  SMS_CHAT_WITH_CUSTOMER_EXISTS: 'SMS chat with customer exists',
  SMS_MESSAGE_DELIVERY_FAILED: 'SMS message delivery failed',
  EMAIL_ATTACHMENT_ACCESS_FORBIDDEN: 'Email attachment access forbidden',
  DRAFT_DOES_NOT_EXIST: 'Draft does not exist',
  CAMPAIGN_IS_NOT_CONFIGURED_FOR_SMS: 'Campaign is not configured for SMS',
  WORKSHEET_ALREADY_EXISTS: 'Worksheet already exists',
  SMS_OPEN_DISPOSITION_NOT_ALLOWED: 'SMS open disposition not allowed',
  OBJECT_NOT_FOUND: 'Object not found',
  NO_ROLES_ASSIGNED: 'No roles assigned',
  NO_PERMISSIONS_ASSIGNED: 'No permissions assigned',
  EXISTING_SESSION_USE_FORCE_LOGIN: 'Existing session use force login',
  NO_VALID_APPLICATION_ROLE_ASSIGNED: 'No valid application role assigned',
  USER_ACCOUNT_NOT_ACTIVE: 'User account not active',
  USER_PWD_CHANGE_NEEDED: 'User pwd change needed',
  USER_SECURITY_QUESTION_CHANGE_NEEDED: 'User security question change needed',
  EXISTING_SESSION_ON_ACTIVE_CALL: 'Existing session on active call',
  CAN_NOT_RUN_WEB_CLIENT: 'Can not run web client',
  CAN_NOT_RUN_WEB_CLIENT_RUN_JAVA_AGENT:
    'Can not run web client run java agent',
  FREEDOM_NOT_ENABLED: 'Freedom not enabled',
  SFAGENT_APP_CONCURRENCY_EXCEEDED: 'SF agent app concurrency exceeded',
  SAML_AUTH_FAILED: 'Saml auth failed',
  CONTINUOUS_LOGIN_FAILED: 'Continuous login failed',
  WARN_INVALID_REQUEST_ADDRESS: 'Warn invalid request address',
  CANT_GET_DB_CONNECTION: 'Cant get db connection',
  WRONG_STATE: 'Wrong state',
  INVALID_PARAMETER_VALUE: 'Invalid parameter value',
  LIMIT_REACHED: 'Limit reached',
  SERVICE_MIGRATED_RETRY_REQUEST: 'Service migrated retry request',
  SERVICE_UNAVAILABLE: 'Service unavailable',
  SERVICE_ERROR: 'Service error',
  UNABLE_TO_ACQUIRE_DISTRIBUTED_LOCK: 'Unable to acquire distributed lock',
  INVALID_REQUEST: 'Invalid request',
  OUT_OF_RANGE: 'Out of range',
  CANNOT_FORCE_LOGOUT_WHEN_TALKING: 'Cannot force logout when talking',
  CALL_HAS_NO_CONTACT: 'Call has no contact',
};
