import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import TextInput from 'common/TextInput/TextInput';
import Typography from 'common/Typography/Typography';
import DropdownGroup from './DropdownGroup/DropdownGroup';
import { useStyles } from './FamilyInfo.style';
import inquiryService from 'services/callCenter/inquiryService';
import { inquiryPropType } from '../../inquiryPropTypes';
import { selectCatalogs } from 'stateManagement/callcenter/catalogs/reducers/catalogsReducer';
import { useSelector } from 'react-redux';

const FamilyInfo = ({ inquiry, answer, labels, onChange }) => {
  const classes = useStyles();
  const { catalogs } = useSelector(selectCatalogs);
  const familyRelationship =
    answer.userInputValues && answer.userInputValues.relationship
      ? answer.userInputValues.relationship
      : inquiryService.getInfo(inquiry, 'relation', labels);

  const firstName =
    answer.userInputValues && answer.userInputValues.resident1FirstName
      ? answer.userInputValues.resident1FirstName
      : inquiryService.getInfo(inquiry, 'resident1FirstName', labels);
  const lastName =
    answer.userInputValues && answer.userInputValues.resident1LastName
      ? answer.userInputValues.resident1LastName
      : inquiryService.getInfo(inquiry, 'resident1LastName', labels);

  const [familyNames, setFamilyNames] = useState({
    relationship: familyRelationship,
    resident1FirstName: firstName,
    resident1LastName: lastName,
  });

  answer.userInputValues = familyNames;

  const allItems =
    catalogs?.familyRelationships?.map((currentValue) => ({
      label: currentValue.enumLabel,
      value: currentValue.enumValue,
    })) ?? [];

  const commonUsedRelationships = allItems.slice(0, 6);
  let otherRelationships = allItems
    .slice(6)
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  const itemsThatAreNotBeenUsed = [
    'EMPLOYED_CAREGIVER',
    'FINANCIAL_ADVISOR',
    'CASE_MGR_SOCIAL_WORKER',
    'CONSERVATOR_TRUSTEE',
    'PROFESSIONAL_CAREGIVER',
    'REALTOR',
    'REFERRAL_AGENCY',
    'SELF',
    'MYSELF_AND_SPOUSE',
    'HEALTHCARE_PROFESSIONAL',
    'HOME_CARE_SURROGATE',
    'LAWYER',
  ];
  otherRelationships = otherRelationships?.filter(
    (item) => !itemsThatAreNotBeenUsed.includes(item.value),
  );

  const groups = [
    { items: commonUsedRelationships, name: labels.COMMONLY_USED },
    { items: otherRelationships, name: labels.OTHERS },
  ];

  const getSelectedRelationshipItem = (relationshipValue) => {
    if (!relationshipValue || relationshipValue === 'Unknown') {
      return { value: '', label: 'Relationship' };
    }
    return allItems?.find(
      (rel) => rel.value.toString() === relationshipValue.toString(),
    );
  };

  const handleChange = (id, value) => {
    const newFamilyName = { ...familyNames, [id]: value };
    setFamilyNames(newFamilyName);
    onChange({ value: answer.value, userInputValues: newFamilyName });
  };

  return (
    <Fragment>
      {labels.RELATIONSHIP_QUESTION_DROPBOX}
      <DropdownGroup
        emptyLabel={labels.RELATIONSHIP}
        groups={groups}
        label={labels.SELECT_RELATIONSHIP}
        onChange={(item) => handleChange('relationship', item.value)}
        className={classes.relationship_options}
        selectedItem={getSelectedRelationshipItem(familyNames.relationship)}
        isMandatory={true}
      />
      <Typography
        color="eerieBlack5"
        level="small"
        className={classes.loved_ones_name_label}
      >
        {labels.LOVED_ONE_NAME_ONE}
        <strong>{labels.LOVED_ONE_NAME_TWO}</strong>
        {labels.LOVED_ONE_NAME_THREE}
      </Typography>
      <div className={classes.loved_one_name_container}>
        <TextInput
          id="first-name"
          placeholder={labels.FIRST_NAME}
          onChange={(value) => handleChange('resident1FirstName', value)}
          value={familyNames.resident1FirstName}
          autoComplete="off"
        />
        <TextInput
          id="last-name"
          placeholder={labels.LAST_NAME}
          onChange={(value) => handleChange('resident1LastName', value)}
          value={familyNames.resident1LastName}
          autoComplete="off"
        />
      </div>
    </Fragment>
  );
};

FamilyInfo.propTypes = {
  inquiry: inquiryPropType,
  answer: PropTypes.object,
  labels: PropTypes.object,
  onChange: PropTypes.func,
};

export default FamilyInfo;
