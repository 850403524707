import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import restClient from '../../utils/restClient';
import withConfig from '../../utils/withConfig';
import { GetRLToursMetrics } from '../../services/Metrics';
import math from '../../utils/math';
import { withAuthenticationContext } from '../../context/Authentication';
import { withLabels } from '../../context/LabelContext';
import TopContainer from './TopContainer';
import Media from '../../common/Media/Media';
import {
  ScheduledToursDuringInitialConsult,
  SecondRoundReferrals,
} from '../ScheduledToursMetric/ScheduledToursMetric';
import QuotaFlipHelper from '../../components/Quota/QuotaFlipHelper';

const HomeHeader = ({ auth, labels }) => {
  const [quota, setQuota] = useState(0);
  const [points, setPoints] = useState(0);
  const toursScheduledICServiceResponse = GetRLToursMetrics(
    withConfig('TOURS_SCHEDULED_METRICS_DURING_IC_URL'),
  );

  const secondRoundReferralsMetricsServiceResponse = GetRLToursMetrics(
    withConfig('SECOND_ROUND_REFERRALS_METRICS_URL'),
  );

  useEffect(() => {
    const getQuotaData = async () => {
      const quotaUrl = withConfig('QUOTA_URL');
      const pointsUrl = withConfig('POINTS_URL');
      let quotaResult = null;
      let pointsResult = null;
      try {
        quotaResult = await restClient.get(quotaUrl);
        pointsResult = await restClient.get(pointsUrl);
      } catch (error) {
        console.error('Error getting quota/points', error);
      }

      const quotasRaw = math.validateNumber(
        quotaResult ? quotaResult.data.value : 0,
      );
      const pointsRaw = math.validateNumber(
        pointsResult ? pointsResult.data.value : 0,
      );

      setQuota(quotasRaw);
      setPoints(pointsRaw);
    };
    getQuotaData();
  }, []);

  const quotaFlipHelper = (
    <QuotaFlipHelper
      userGivenName={auth.user.given_name}
      labels={labels}
      quota={quota}
      points={points}
    />
  );

  const scheduledToursDuringInitialConsult = (
    <ScheduledToursDuringInitialConsult
      numberOfTours={toursScheduledICServiceResponse.numberOfTours}
      totalOfReferredContacts={
        toursScheduledICServiceResponse.totalOfReferredContacts
      }
      percentageAccomplished={
        toursScheduledICServiceResponse.percentageAccomplished
      }
      labels={labels}
    />
  );

  const secondRoundReferrals = (
    <SecondRoundReferrals
      numberOfTours={secondRoundReferralsMetricsServiceResponse.numberOfTours}
      totalOfReferredContacts={
        secondRoundReferralsMetricsServiceResponse.totalOfReferredContacts
      }
      percentageAccomplished={
        secondRoundReferralsMetricsServiceResponse.percentageAccomplished
      }
      labels={labels}
    />
  );

  return (
    <Media
      xs={
        <TopContainer
          size="xs"
          labels={labels}
          quotaFlipHelper={quotaFlipHelper}
          scheduledToursDuringInitialConsult={
            scheduledToursDuringInitialConsult
          }
          secondRoundReferrals={secondRoundReferrals}
        />
      }
      sm={
        <TopContainer
          size="sm"
          labels={labels}
          quotaFlipHelper={quotaFlipHelper}
          scheduledToursDuringInitialConsult={
            scheduledToursDuringInitialConsult
          }
          secondRoundReferrals={secondRoundReferrals}
        />
      }
      md={
        <TopContainer
          size="md"
          labels={labels}
          quotaFlipHelper={quotaFlipHelper}
          scheduledToursDuringInitialConsult={
            scheduledToursDuringInitialConsult
          }
          secondRoundReferrals={secondRoundReferrals}
        />
      }
      lg={
        <TopContainer
          size="lg"
          labels={labels}
          quotaFlipHelper={quotaFlipHelper}
          scheduledToursDuringInitialConsult={
            scheduledToursDuringInitialConsult
          }
          secondRoundReferrals={secondRoundReferrals}
        />
      }
    />
  );
};

HomeHeader.propTypes = {
  labels: PropTypes.shape({
    YOUR_PERFORMANCE: PropTypes.string,
    WELCOME: PropTypes.string,
    COMMA_QUOTA_MESSAGE: PropTypes.string,
    WHAT_WE_HAVE_FOR_YOU: PropTypes.string.isRequired,
  }).isRequired,
  auth: PropTypes.shape({
    user: PropTypes.shape({
      given_name: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default withLabels(withAuthenticationContext(HomeHeader));
export { HomeHeader, TopContainer };
