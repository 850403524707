import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    side_panel_container: {
      display: 'flex',
      flexDirection: 'column',

      color: theme.palette.dark.eerieBlack1,
      '& .MuiDrawer-paper': {
        width: '448px',
      },
    },
    header: {
      display: 'flex',
      padding: '12px 16px',
      alignItems: 'center',
      backgroundColor: theme.palette.light.platinum4,
    },
    header_title: {
      display: 'flex',
      flexGrow: '1',
      '& svg': {
        marginRight: '10px',
      },
    },
    close_icon: {
      cursor: 'pointer',
    },
    collapse_all_row: {
      display: 'flex',
      margin: '8px 0',
      paddingRight: '16px',
      justifyContent: 'flex-end',
      '& svg': {
        marginLeft: '18px',
      },
    },
    collapse_all: {
      display: 'flex',
      userSelect: 'none',
    },
    filters_container: {
      display: 'flex',
      flexDirection: 'column',
    },
    filters_content: {
      overflowY: 'auto',
      height: 'calc(100vh - 117px)',
    },
    footer: {
      display: 'flex',
      borderTop: `1px solid ${theme.palette.light.platinum1}`,
      padding: '15px 0',
      justifyContent: 'center',
      '& button': {
        marginLeft: '16px',
      },
    },
  };
});

export { useStyles };
