import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Alert from '../../common/Alert/Alert';
import EditContactsInformation from '../../components/Contacts/EditContactsInformation/EditContactsInformation';
import useGraphQLMutation from '../../hooks/useGraphQLMutation';
import updateContactMutation from './Mutations/updateContactMutation';
import createContactMutation from './Mutations/createContactMutation';
import upsertContactMutation from './Mutations/upsertContactMutation';
import { registerEvent } from './../../services/Analytics';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { isContactsFormChanged } from '../formService';

const ContactsUpdate = ({
  children,
  labels,
  contact,
  handleRefetchState,
  familyFileId,
  flags,
  editContactInformationOpen,
  handleClosingEdit,
  enableReferralAlertValidation,
  isSelfLead,
}) => {
  const [isOpen, setIsOpen] = useState(editContactInformationOpen);
  const [contactsInformation, setContactsInformation] = useState({
    ...contact,
  });
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isConfirmAlertOpen, setIsConfirmAlertOpen] = useState(false);
  const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false);
  const [shouldUpdateContactsInfo, setShouldUpdateContactsInfo] = useState(
    false,
  );

  const [updateContact] = useGraphQLMutation(updateContactMutation);
  const [createContact] = useGraphQLMutation(createContactMutation);
  const [upsertContact] = useGraphQLMutation(upsertContactMutation);

  useEffect(() => {
    setIsOpen(editContactInformationOpen);
  }, [editContactInformationOpen]);

  useEffect(() => {
    const buildContactObject = (contactToBeUsed) => {
      const primaryPhone = contactToBeUsed.phones.find(
        (phone) => phone.isPrimaryForCategory === true,
      );

      if (!contactToBeUsed.country) {
        contactToBeUsed.country = 'US';
      }

      const phonesModified = contactToBeUsed.phones
        .map((phone) => {
          delete phone['__typename'];
          phone.countryCode = contactToBeUsed.country;
          return phone;
        })
        .filter(
          (phone) =>
            phone.subCategoryCode !== null &&
            phone.localNumber !== '' &&
            phone.localNumber !== null,
        );
      const relationToResidentId = parseInt(
        contactToBeUsed.relationToResidentId,
      );

      return {
        firstName: contactToBeUsed.firstName || '',
        lastName: contactToBeUsed.lastName || '',
        relationToResidentId,
        address1: contactToBeUsed.address1,
        address2: contactToBeUsed.address2,
        isInquirer: contactToBeUsed.isInquirer,
        allowMailing: contactToBeUsed.allowMailing,
        allowContact: contactToBeUsed.allowContact,
        postalCode: contactToBeUsed.postalCode,
        city: contactToBeUsed.city,
        state: contactToBeUsed.state,
        country: contactToBeUsed.country,
        primaryPhone: primaryPhone
          ? `${primaryPhone.areaCode}${primaryPhone.localNumber}`
          : '',
        primaryPhoneType: primaryPhone ? primaryPhone.subCategoryCode : '',
        phones: phonesModified,
        emailAddress:
          contactToBeUsed.emailAddresses?.length > 0
            ? contactToBeUsed.emailAddresses[0].emailAddress
            : '',
        emailAddresses:
          contactToBeUsed.emailAddresses?.length > 0 &&
          contactToBeUsed.emailAddresses[0].emailAddress
            ? contactToBeUsed.emailAddresses
            : [],
        oneId: contactsInformation.primaryContact.oneId,
        familyFileId: contactsInformation.primaryContact.familyFileId,
        isPrimary: contactToBeUsed.isPrimary,
        contactId: contactToBeUsed.contactId,
      };
    };

    const handleSuccessState = () => {
      setShowErrorMessage(false);
      setIsOpen(false);
      setIsSuccessAlertOpen(true);
    };

    const handleErrorState = () => {
      setShowErrorMessage(true);
      setIsOpen(true);
      setIsSuccessAlertOpen(false);
    };

    const updateAdditionalContact = (additionalContact, promisesQueue) => {
      if (additionalContact !== undefined && flags.canEditAdditionalContacts) {
        const variablesToSendAdditionalContact = buildContactObject(
          additionalContact,
        );

        if (additionalContact.contactId === null) {
          const createAdditionalContactPromise = createContact({
            variables: variablesToSendAdditionalContact,
          });

          promisesQueue.push(
            createAdditionalContactPromise.catch((error) => {
              console.error(`Error creating secondary contact info ${error}`);
            }),
          );
        } else {
          variablesToSendAdditionalContact.formDataId =
            additionalContact.formDataId;
          const updateAdditionalContactPromise = updateContact({
            variables: variablesToSendAdditionalContact,
          });

          promisesQueue.push(
            updateAdditionalContactPromise.catch((error) => {
              console.error(`Error updating secondary contact info ${error}`);
            }),
          );
        }
      }
    };

    const triggerUpdateContactsInfo = async () => {
      let primaryContact = contactsInformation.primaryContact;
      let secondaryContact = contactsInformation.secondaryContact;
      let additionalContact = contactsInformation.additionalContact;
      const promisesQueue = [];

      if (primaryContact !== undefined) {
        const variablesToSendPrimaryContact = buildContactObject(
          primaryContact,
        );

        if (primaryContact.contactId === null) {
          if (flags.beaconContactCreation) {
            const createPrimaryContactPromise = createContact({
              variables: variablesToSendPrimaryContact,
            });
            promisesQueue.push(
              createPrimaryContactPromise.catch((error) => {
                console.error(`Error creating primary contact info ${error}`);
              }),
            );
          }
        } else {
          variablesToSendPrimaryContact.formDataId = primaryContact.formDataId;

          const updatePrimaryContactPromise = updateContact({
            variables: variablesToSendPrimaryContact,
          });
          promisesQueue.push(
            updatePrimaryContactPromise.catch((error) => {
              console.error(`Error updating primary contact info ${error}`);
            }),
          );
        }
      }

      if (secondaryContact !== undefined && flags.canEditAdditionalContacts) {
        console.info('secondaryContact is being edited!!!!');
        const variablesToSendSecondaryContact = buildContactObject(
          secondaryContact,
        );

        if (secondaryContact.contactId === null) {
          const createSecondaryContactPromise = createContact({
            variables: variablesToSendSecondaryContact,
          });

          promisesQueue.push(
            createSecondaryContactPromise.catch((error) => {
              console.error(`Error creating secondary contact info ${error}`);
            }),
          );
        } else {
          variablesToSendSecondaryContact.formDataId =
            secondaryContact.formDataId;

          const updateSecondaryContactPromise = updateContact({
            variables: variablesToSendSecondaryContact,
          });

          promisesQueue.push(
            updateSecondaryContactPromise.catch((error) => {
              console.error(`Error updating secondary contact info ${error}`);
            }),
          );
        }
      }

      updateAdditionalContact(additionalContact, promisesQueue);

      let createNewContact;

      await Promise.all(promisesQueue).then((response) => {
        const errors = response.filter((value) => value === undefined);

        if (errors.length > 0) {
          handleErrorState();
        } else {
          createNewContact = response.filter(
            (value) =>
              value.data.createContact &&
              value.data.createContact.formDataId !== undefined,
          );
          if (createNewContact.length > 0) {
            createNewContact.map(async (element) => {
              await upsertContact({
                variables: {
                  profileFormDataId: element.data.createContact.formDataId,
                  familyFileId: familyFileId,
                },
              })
                .then(() => {
                  handleSuccessState();
                })
                .catch((error) => {
                  handleErrorState();
                  console.error(`Error running upsert ${error}`);
                });
            });
          } else {
            handleSuccessState();
          }
        }
      });
      setShouldUpdateContactsInfo(false);
    };

    if (shouldUpdateContactsInfo) {
      triggerUpdateContactsInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdateContactsInfo]);

  const handleSave = (editedContactsInformation) => {
    registerEvent('FamilyFiles', 'Edit contact info is saved');
    setContactsInformation({ ...editedContactsInformation });
    setShouldUpdateContactsInfo(true);
  };

  const handleOnCloseEdit = (editedContactsInformation) => {
    if (!isContactsFormChanged(contact, editedContactsInformation)) {
      handleDontSave();
      return;
    }
    setContactsInformation({ ...editedContactsInformation });
    setIsConfirmAlertOpen(true);
    setIsOpen(false);
  };

  const handleDontSave = () => {
    setIsConfirmAlertOpen(false);
    setShowErrorMessage(false);
    setContactsInformation({ ...contact });
    handleClosingEdit();
  };

  const handleBackToEditing = () => {
    setIsConfirmAlertOpen(false);
    setIsOpen(true);
  };

  const handleOnEditMode = (open) => {
    registerEvent('FamilyFiles', 'Edit contact info is opened');
    setIsOpen(open);
  };

  const handleSuccessClose = () => {
    handleRefetchState(true);
    setIsSuccessAlertOpen(false);
    handleClosingEdit();
  };

  const withOnEdit = (Component) => {
    const ComponentWithOnEditMode = React.cloneElement(Component, {
      onEditMode: () => handleOnEditMode(),
    });
    return ComponentWithOnEditMode;
  };

  const checkForChangedFormData = (originalData) => (
    editedData,
    certainProperty = undefined,
  ) => {
    if (certainProperty) {
      return isContactsFormChanged(
        originalData?.[certainProperty],
        editedData?.[certainProperty],
      );
    }
    return isContactsFormChanged(originalData, editedData);
  };

  return (
    <Fragment>
      <Alert
        type="delete"
        title={labels.PLEASE_CONFIRM}
        description={labels.DONT_MAKE_ANY_CHANGES}
        cancelText={labels.BACK_TO_EDITING}
        confirmText={labels.YES_DONT_SAVE}
        onConfirm={handleDontSave}
        onClose={handleBackToEditing}
        isOpen={isConfirmAlertOpen}
      />
      <Alert
        type="success"
        title={labels.SUCCESS}
        description={labels.CONTACTS_SUCCESSFULLY_UPDATED}
        confirmText={labels.OK}
        onConfirm={handleSuccessClose}
        onClose={handleSuccessClose}
        isOpen={isSuccessAlertOpen}
      />
      <EditContactsInformation
        isOpen={isOpen}
        onClose={handleOnCloseEdit}
        contactInfo={contactsInformation}
        labels={labels}
        onSave={handleSave}
        size={'xs'}
        showErrorMessage={showErrorMessage}
        enableReferralAlertValidation={enableReferralAlertValidation}
        isSelfLead={isSelfLead}
        checkForChanges={checkForChangedFormData(contact)}
      />
      {withOnEdit(children)}
    </Fragment>
  );
};

ContactsUpdate.propTypes = {
  labels: PropTypes.object.isRequired,
  familyFileId: PropTypes.number.isRequired,
  contact: PropTypes.shape({
    primaryContact: PropTypes.shape({
      name: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      relationship: PropTypes.string,
      address1: PropTypes.string,
      address2: PropTypes.string,
      allowContact: PropTypes.bool,
      isInquirer: PropTypes.bool,
      city: PropTypes.string,
      postalCode: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      contactDetail: PropTypes.shape({
        phonePrimary: PropTypes.string,
        phoneSecondary: PropTypes.string,
        email: PropTypes.string,
        primaryPhoneType: PropTypes.string,
        secondaryPhoneType: PropTypes.string,
      }),
      phones: PropTypes.arrayOf(
        PropTypes.shape({
          subCategoryCode: PropTypes.string,
          phone: PropTypes.string,
        }),
      ),
      emailAddresses: PropTypes.arrayOf(
        PropTypes.shape({
          emailAddress: PropTypes.string,
        }),
      ),
    }),
    secondaryContact: PropTypes.shape({
      name: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      relationship: PropTypes.string,
      address1: PropTypes.string,
      address2: PropTypes.string,
      allowContact: PropTypes.bool,
      isInquirer: PropTypes.bool,
      city: PropTypes.string,
      postalCode: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      contactDetail: PropTypes.shape({
        phonePrimary: PropTypes.string,
        phoneSecondary: PropTypes.string,
        email: PropTypes.string,
        primaryPhoneType: PropTypes.string,
        secondaryPhoneType: PropTypes.string,
      }),
    }),
    additionalContact: PropTypes.shape({
      name: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      relationship: PropTypes.string,
      address1: PropTypes.string,
      address2: PropTypes.string,
      allowContact: PropTypes.bool,
      isInquirer: PropTypes.bool,
      city: PropTypes.string,
      postalCode: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      contactDetail: PropTypes.shape({
        phonePrimary: PropTypes.string,
        phoneSecondary: PropTypes.string,
        email: PropTypes.string,
        primaryPhoneType: PropTypes.string,
        secondaryPhoneType: PropTypes.string,
      }),
    }),
  }),
  handleRefetchState: PropTypes.func,
  flags: PropTypes.shape({
    beaconContactCreation: PropTypes.bool,
    dialOverrideNumber: PropTypes.string,
    canEditAdditionalContacts: PropTypes.bool,
  }),
  children: PropTypes.element,
  editContactInformationOpen: PropTypes.bool.isRequired,
  handleClosingEdit: PropTypes.func,
  enableReferralAlertValidation: PropTypes.bool,
  isSelfLead: PropTypes.bool,
};

ContactsUpdate.defaultProps = {
  enableReferralAlertValidation: true,
  children: <Fragment></Fragment>,
};

export default withLDConsumer()(ContactsUpdate);
