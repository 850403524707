import clsx from 'clsx';
import Table from 'common/Table/Table/Table';
import TableBody from 'common/Table/TableBody/TableBody';
import TableCell from 'common/Table/TableCell/TableCell';
import TableHead from 'common/Table/TableHead/TableHead';
import TableRow from 'common/Table/TableRow/TableRow';
import Typography from 'common/Typography/Typography';
import { parsePhoneNumber } from 'libphonenumber-js';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { createsValueForDynamicHeightInFamilyFile } from 'services/callCenter/duplicateFamilyFilePopupService';
import { getShortMonthDayYearFormat } from 'utils/dateFormat';
import { formatPhoneWithParenthesis } from 'utils/phoneFormat';
import {
  getButtonType,
  getFamilyFileIdForDuplicates,
  getLocationForCurrent,
  getLocationForDuplicates,
  getReferredStatusForCurrent,
  getSlaStatusForDuplicates,
  getTagSalesPhase,
  POPUP_TYPES,
} from './DuplicateFamilyFileHelper';
import { useStyles } from './DuplicateFamilyFilePopup.style';

const DuplicateFamilyFilePopupSm = ({
  foundFamilyFile,
  labels,
  currentFamilyFile,
  type,
  doMerging,
  onClose,
}) => {
  const foundFFLongestLocation = foundFamilyFile.longestLocation;
  const currentFFlocationLength = currentFamilyFile.location.length;
  const valueForCalcHeight = createsValueForDynamicHeightInFamilyFile(
    foundFFLongestLocation,
    currentFFlocationLength,
  );

  const classes = useStyles(valueForCalcHeight);

  return (
    <Fragment>
      <div className={classes.header_content}>
        <Typography>
          <div
            dangerouslySetInnerHTML={{
              __html: labels.POSSIBLE_RESIDENT_DUPLICATES_FOUND.replace(
                '{QUANTITY}',
                `<span class="${classes.bold_blue}">${foundFamilyFile.length}</span>`,
              ),
            }}
          />
        </Typography>
        <div>
          <Typography className={classes.typography} bold>
            {labels.PHONE}:&nbsp;
          </Typography>
          <Typography className={classes.typography}>
            {formatPhoneWithParenthesis(
              parsePhoneNumber(
                currentFamilyFile.phone.trim(),
                'US',
              ).formatNational(),
            )}
          </Typography>
        </div>
        <div>
          <Typography className={classes.typography} bold>
            {labels.EMAIL}:&nbsp;
          </Typography>
          <Typography className={classes.typography}>
            {currentFamilyFile.email}
          </Typography>
        </div>
      </div>

      <div className={classes.table_container}>
        <div className={classes.sticky_column_sm}>
          <Table>
            <TableHead className={classes.header}>
              <TableRow size="small">
                <TableCell className={classes.table_cell_title}>
                  <Typography level="small" color="eerieBlack1">
                    {labels.FAMILY_FILE_ID}
                  </Typography>
                </TableCell>
                <TableCell className={classes.table_cell_current_call_title}>
                  <Typography
                    color="cosmicCobalt3"
                    className={classes.typography_current}
                  >
                    {labels.CURRENT}:
                  </Typography>
                  <Typography className={classes.typography}>
                    {currentFamilyFile.familyFileId || labels.DASH_MESSAGE}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow size="medium">
                <TableCell className={classes.table_cell_title}>
                  <Typography level="small" color="eerieBlack1">
                    {labels.PRIMARY_CONTACT}
                  </Typography>
                </TableCell>
                <TableCell className={classes.table_cell_current_call}>
                  <Typography>{`${currentFamilyFile.contactFirstName} ${currentFamilyFile.contactLastName} (${currentFamilyFile.relationToResident})`}</Typography>
                </TableCell>
              </TableRow>
              <TableRow size="medium">
                <TableCell className={classes.table_cell_title}>
                  <Typography
                    level="small"
                    color="eerieBlack1"
                    className={classes.title_label}
                  >
                    {labels.RESIDENT_NAME}
                  </Typography>
                </TableCell>
                <TableCell className={classes.table_cell_current_call}>
                  <Typography className={classes.custom_label}>
                    {`${
                      currentFamilyFile.residentFirstName || labels.UNKNOWN
                    } ${currentFamilyFile.residentLastName || labels.UNKNOWN}`}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow size="medium">
                <TableCell className={classes.table_cell_title}>
                  <Typography
                    level="small"
                    color="eerieBlack1"
                    className={classes.title_label}
                  >
                    {labels.LOCATIONS}
                  </Typography>
                </TableCell>

                <TableCell className={classes.table_cell_current_call_location}>
                  {getLocationForCurrent(
                    currentFFlocationLength,
                    currentFamilyFile,
                    classes,
                  )}
                </TableCell>
              </TableRow>
              <TableRow size="medium">
                <TableCell className={classes.table_cell_title}>
                  <Typography
                    level="small"
                    color="eerieBlack1"
                    className={classes.title_label}
                  >
                    {labels.CREATED}
                  </Typography>
                </TableCell>
                <TableCell className={classes.table_cell_current_call}>
                  <Typography className={classes.custom_label}>
                    {getShortMonthDayYearFormat(
                      currentFamilyFile.familyFileCreation,
                    ) || labels.DASH_MESSAGE}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow size="medium">
                <TableCell className={classes.table_cell_title}>
                  <Typography
                    level="small"
                    color="eerieBlack1"
                    className={classes.title_label}
                  >
                    {labels.SALES_PHASE}
                  </Typography>
                </TableCell>
                <TableCell className={classes.table_cell_current_call}>
                  <Typography className={classes.custom_label}>
                    {getTagSalesPhase(currentFamilyFile, labels, classes)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow size="medium">
                <TableCell className={classes.table_cell_title}>
                  <Typography
                    level="small"
                    color="eerieBlack1"
                    className={classes.title_label}
                  >
                    {labels.REFERRED}?
                  </Typography>
                </TableCell>
                <TableCell className={classes.table_cell_current_call}>
                  <Typography className={classes.custom_label}>
                    {getReferredStatusForCurrent(
                      currentFamilyFile,
                      type,
                      labels,
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow size="medium">
                <TableCell className={classes.table_cell_title}>
                  <Typography
                    level="small"
                    color="eerieBlack1"
                    className={classes.title_label}
                  >
                    {labels.BUDGET}
                  </Typography>
                </TableCell>
                <TableCell className={classes.table_cell_current_call}>
                  <Typography className={classes.custom_label}>
                    {currentFamilyFile.budget || labels.DASH_MESSAGE}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow size="medium">
                <TableCell className={classes.table_cell_title}>
                  <Typography
                    level="small"
                    color="eerieBlack1"
                    className={classes.title_label}
                  >
                    {labels.ASSIGNED_SLA}
                  </Typography>
                </TableCell>
                <TableCell className={classes.table_cell_current_call}>
                  <Typography className={classes.custom_label}>
                    {currentFamilyFile.sla || labels.DASH_MESSAGE}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <div className={classes.scrollable_table}>
          <Table>
            <TableHead className={classes.header}>
              <TableRow size="small">
                {foundFamilyFile.map((familyFile, index) => (
                  <TableCell
                    key={index}
                    className={classes.table_cell_current_call_title}
                  >
                    {getFamilyFileIdForDuplicates(familyFile, type, classes)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow size="medium">
                {foundFamilyFile.map((familyFile, index) => (
                  <TableCell
                    key={index}
                    className={clsx(
                      familyFile.contactNameMatch
                        ? classes.table_cell_matched_contact
                        : classes.table_cell_default,
                    )}
                  >
                    <Typography>{`${familyFile.contactFirstName} ${familyFile.contactLastName} (${familyFile.relationToResident})`}</Typography>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow size="medium">
                {foundFamilyFile.map((familyFile, index) => (
                  <TableCell
                    key={index}
                    className={clsx(
                      familyFile.residentNameMatch
                        ? classes.table_cell_matched_contact
                        : classes.table_cell_default,
                    )}
                  >
                    <Typography className={classes.custom_label}>
                      {`${familyFile.residentFirstName} ${familyFile.residentLastName}`}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow size="medium">
                {getLocationForDuplicates(foundFamilyFile, currentFamilyFile)}
              </TableRow>
              <TableRow size="medium">
                {foundFamilyFile.map((familyFile, index) => (
                  <TableCell key={index} className={classes.table_cell_default}>
                    <Typography className={classes.custom_label}>
                      {getShortMonthDayYearFormat(
                        familyFile.familyFileCreation,
                      )}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow size="medium">
                {foundFamilyFile.map((familyFile, index) => (
                  <TableCell key={index} className={classes.table_cell_default}>
                    {getTagSalesPhase(familyFile, labels, classes)}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow size="medium">
                {foundFamilyFile.map((familyFile, index) => (
                  <TableCell key={index} className={classes.table_cell_default}>
                    <Typography className={classes.custom_label}>
                      {familyFile.referredActivityDate ? labels.YES : labels.NO}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow size="medium">
                {foundFamilyFile.map((familyFile, index) => (
                  <TableCell key={index} className={classes.table_cell_default}>
                    <Typography className={classes.custom_label}>
                      {familyFile.budget || labels.DASH_MESSAGE}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow size="medium">
                {foundFamilyFile.map((familyFile, index) => (
                  <TableCell key={index} className={classes.table_cell_default}>
                    {getSlaStatusForDuplicates(
                      familyFile,
                      currentFamilyFile,
                      classes,
                      labels,
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>

      <div className={classes.column_button}>
        {getButtonType(type, doMerging, onClose, labels, classes)}
      </div>
    </Fragment>
  );
};

DuplicateFamilyFilePopupSm.propTypes = {
  foundFamilyFile: PropTypes.array,
  currentFamilyFile: PropTypes.object,
  labels: PropTypes.object,
  doMerging: PropTypes.func,
  onClose: PropTypes.func,
  type: PropTypes.oneOf(Object.values(POPUP_TYPES)),
};
export default DuplicateFamilyFilePopupSm;
