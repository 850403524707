import React from 'react';
import PropTypes from 'prop-types';

const Duplicate = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66634 0.5H11.6091L15.333 4.05459V13.2273C15.333 13.9302 14.7361 14.5 13.9997 14.5H13.333L13.333 15.1667C13.333 15.903 12.7361 16.5 11.9997 16.5H2.66634C1.92996 16.5 1.33301 15.903 1.33301 15.1667V3.16667C1.33301 2.43029 1.92996 1.83333 2.66634 1.83333H3.33301V1.77273C3.33301 1.06982 3.92996 0.5 4.66634 0.5ZM3.33301 3.16667H2.66634V15.1667H11.9997V14.5H4.66634C3.92996 14.5 3.33301 13.9302 3.33301 13.2273V3.16667ZM9.99967 1.77273H4.66634V13.2273H13.9997V5.59091H11.333C10.5966 5.59091 9.99967 5.02109 9.99967 4.31818V1.77273ZM11.333 2.03632V4.31818H13.7235L11.333 2.03632ZM10.0021 5.59091H8.66829V10.0455H10.0021V5.59091ZM10.0001 11.3182C10.0001 11.6696 9.70154 11.9546 9.33323 11.9546C8.96492 11.9546 8.66634 11.6696 8.66634 11.3182C8.66634 10.9667 8.96492 10.6818 9.33323 10.6818C9.70154 10.6818 10.0001 10.9667 10.0001 11.3182Z"
        fill="#FEFEFE"
      />
    </svg>
  );
};

Duplicate.propTypes = {
  className: PropTypes.string,
};

export default Duplicate;
