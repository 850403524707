import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './CommunityPriceRange.style';
import { toCurrency, toDecimalCurrency } from 'utils/currencyFormat';
import Typography from 'common/Typography/Typography';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import Time from 'common/Icons/time/Time';
import Calendar from 'common/Icons/time/Calendar';
import clsx from 'clsx';

const CARE_TYPE_HOME_CARE = 'H';

const CommunityPriceRange = ({
  careTypeStyle,
  className,
  labels,
  prices,
  roomFees,
  isSmallMap,
}) => {
  const classes = useStyles(careTypeStyle);

  const careTypeName = {
    A: labels.ASSISTED_LIVING,
    Z: labels.MEMORY_CARE,
    N: labels.NURSING_HOME,
    H: labels.HOME_CARE,
    I: labels.INDEPENDENT_LIVING,
    M: labels.RESPITE_CARE,
    D: labels.ADULT_DAY_SERVICES,
    O: labels.NOT_SURE,
    G: labels.RESIDENTIAL_CARE_HOME,
    P: labels.SENIOR_APARTMENTS,
    R: labels.RETIREMENT_COMMUNITY,
  };
  const getText = (careType, min, max) => {
    if (careTypeStyle === 'text') {
      return (
        <SmartTooltip title={careTypeName[careType]} placement="bottom">
          <Typography level="small">
            {`(${careType}) ${getPriceRange(min, max)}`}
          </Typography>
        </SmartTooltip>
      );
    }
    return (
      <div className={classes.tag_container}>
        <SmartTooltip title={careTypeName[careType]} placement="bottom">
          <div className={classes.care_type}>{careType}</div>
        </SmartTooltip>
        {!isSmallMap && (
          <Typography level="small">{`${getPriceRange(min, max)}`}</Typography>
        )}
      </div>
    );
  };

  const getPriceRange = (min, max) => {
    if (min === null || max === null) {
      return labels.RATES_UNAVAILABLE;
    }
    return `${toCurrency(min)} - ${toCurrency(max)}`;
  };

  const HomeCareFee = ({ fee }) => {
    return (
      <SmartTooltip title={fee.feeType.toString()} placement="top">
        <Typography className={classes.homeCareFee} level="small">
          {fee.feeType === 'Care Rate' && (
            <>
              <Time /> {toDecimalCurrency(fee.amount)}/hr
            </>
          )}
          {fee.feeType === 'Live-In Care Rate' && (
            <>
              <Calendar />
              {toDecimalCurrency(fee.amount)}/mo.
            </>
          )}
        </Typography>
      </SmartTooltip>
    );
  };
  HomeCareFee.propTypes = {
    fee: PropTypes.object.isRequired,
  };

  return (
    <div className={clsx(classes.community_price_range_container, className)}>
      <div className={classes.price_range_link_container}>
        {roomFees.length === 0 && prices.length === 0 && (
          <Typography level="small" color="disabled">
            {labels.RATES_UNAVAILABLE}
          </Typography>
        )}
        <div className="small_map_caretypes">
          {!isSmallMap && (
            <div className={classes.price_range_rates}>
              {roomFees
                .filter((roomFee) => {
                  return roomFee.careType === CARE_TYPE_HOME_CARE;
                })
                .map((roomFee, key) => (
                  <HomeCareFee fee={roomFee} key={key}></HomeCareFee>
                ))}
            </div>
          )}
          {prices.map((price, key) => (
            <div className={classes.tag} key={key}>
              {getText(price.careType, price.min, price.max)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

CommunityPriceRange.propTypes = {
  careTypeStyle: PropTypes.oneOf(['tag', 'map']),
  className: PropTypes.string,
  labels: PropTypes.object,
  roomFees: PropTypes.array,
  prices: PropTypes.arrayOf(
    PropTypes.shape({
      careType: PropTypes.string,
      min: PropTypes.number,
      max: PropTypes.number,
    }).isRequired,
  ),
  isSmallMap: PropTypes.bool,
};

CommunityPriceRange.defaultProps = {
  careTypeStyle: 'tag',
};

export default CommunityPriceRange;
