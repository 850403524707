import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withLabels } from '../../context/LabelContext';
import {
  useAuth0,
  withAuthenticationContext,
} from '../../context/Authentication';
import Button from '../../common/Button/Button';

const Login = ({ auth, labels }) => {
  const { isAuthenticated } = useAuth0();
  if (isAuthenticated) {
    return <Redirect to="/home" />;
  }
  return (
    <Button type="primary" onClick={auth.loginWithPopup}>
      {labels.LOGIN}
    </Button>
  );
};

Login.propTypes = {
  labels: PropTypes.shape({
    LOGIN: PropTypes.string.isRequired,
  }).isRequired,
  auth: PropTypes.shape({
    loginWithPopup: PropTypes.func.isRequired,
  }).isRequired,
};

export default withLabels(withAuthenticationContext(Login));
export { Login };
