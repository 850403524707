import { isSameDay, isSameYear, isToday, isYesterday } from './dateUtils';
import { parse, addHours } from 'date-fns';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const humanize = (time) => {
  if (!time) {
    return time;
  }
  const now = new Date(Date.now());
  const receivedDate = new Date(time);

  if (isSameDay(now, receivedDate)) {
    const hourDifference = now.getHours() - receivedDate.getHours();
    if (hourDifference === 1) {
      return `${hourDifference} hour ago`;
    }
    if (hourDifference < 1) {
      return 'a few minutes ago';
    }
    return `${hourDifference} hours ago`;
  }

  if (isYesterday(now, receivedDate)) {
    return 'yesterday';
  }

  if (isSameYear(now, receivedDate)) {
    return `${months[receivedDate.getMonth()].substring(
      0,
      3,
    )} ${receivedDate.getDate()}`;
  }

  return `${months[receivedDate.getMonth()].substring(
    0,
    3,
  )} ${receivedDate.getDate()} ${receivedDate.getFullYear()}`;
};

const getReadableDate = (timestamp) => {
  const date = new Date(timestamp);
  const month = months[date.getMonth()].substring(0, 3);
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  const weekDay = weekDays[date.getDay()].substring(0, 3);

  return `${month} ${day}, ${year}, ${weekDay}`;
};

const getReadableDateWithTime = (timestamp) => {
  const date = new Date(timestamp);
  const month = months[date.getMonth()].substring(0, 3);
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  const weekDay = weekDays[date.getDay()].substring(0, 3);
  const time = date
    .toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    })
    .toUpperCase();

  return `${month} ${day}, ${year}, ${weekDay} ${time}`;
};

const getHourAndMinutes12HourFormat = (timestamp, withMinutes = false) => {
  const initialDate = new Date(timestamp);
  const date = getDateInUtc(initialDate);
  if (date.getMinutes() === 0 && !withMinutes) {
    return date
      .toLocaleString('en-US', { hour: 'numeric', hour12: true })
      .toLowerCase();
  } else {
    return date
      .toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
      .toLowerCase();
  }
};

const getDateInUtc = (date) => {
  const myDate = parse(
    date.toISOString().slice(0, -1),
    "yyyy-MM-dd'T'HH:mm:ss.SSS",
    new Date(),
  );
  return myDate;
};

const getMonthAndYearDate = (timestamp) => {
  const date = new Date(timestamp);
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${month} ${year}`;
};

const getDateWithTimezoneOffset = (utcTimestamp) => {
  return new Date(utcTimestamp + new Date().getTimezoneOffset() * 60 * 1000);
};

const getDateLessTimezoneOffset = (timestamp) => {
  const timezoneOffsetMS = new Date().getTimezoneOffset() * 60000;
  const localTime = timestamp - timezoneOffsetMS;
  return localTime;
};

const getTodayOrPastDate = (timestamp) => {
  const date = new Date(timestamp);
  const weekDay = weekDays[date.getDay()].substring(0, 3);
  const month = months[date.getMonth()].substring(0, 3);
  const day = date.getDate().toString().padStart(2, '0');

  if (isToday(timestamp)) {
    return 'Today';
  }
  return `${weekDay}, ${month} ${day}`;
};

const getTodayOrYesterdayOrPastDate = (timestamp) => {
  if (!timestamp) {
    return timestamp;
  }
  const date = new Date(timestamp);
  const weekDay = weekDays[date.getDay()].substring(0, 3);
  const today = new Date(Date.now());
  const year = date.getFullYear();
  const month = months[date.getMonth()].substring(0, 3);
  const day = date.getDate().toString().padStart(2, '0');

  if (isToday(timestamp)) {
    return 'Today';
  }

  if (isYesterday(today, date)) {
    return 'Yesterday';
  }

  if (!isSameYear(today, date)) {
    return `${month} ${day}, ${year}`;
  }
  return `${weekDay}, ${month} ${day}`;
};

const getMonthDayYearFormat = (timestamp) => {
  if (!timestamp) {
    return timestamp;
  }
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate().toString().padStart(2, '0');

  return `${month} ${day}, ${year}`;
};

const getShortMonthDayYearFormat = (timestamp) => {
  if (!timestamp) {
    return timestamp;
  }
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = months[date.getMonth()].substring(0, 3);
  const day = date.getDate().toString().padStart(2, '0');

  return `${month} ${day}, ${year}`;
};

const getDateTime = (timestamp) => {
  if (timestamp) {
    const initialDate = new Date(timestamp);
    const date = getDateInUtc(initialDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
};

const getDate = (inputDate) => {
  if (inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
};

const addTimeInHours = (inputDate, numHours) => {
  return addHours(inputDate, numHours);
};

const getTimeOffsetFromSelectedDate = (inputDate) => {
  return new Date(inputDate).getTimezoneOffset();
};

export {
  addTimeInHours,
  getDate,
  getDateInUtc,
  getDateLessTimezoneOffset,
  getDateTime,
  getDateWithTimezoneOffset,
  getHourAndMinutes12HourFormat,
  getMonthAndYearDate,
  getMonthDayYearFormat,
  getReadableDate,
  getReadableDateWithTime,
  getShortMonthDayYearFormat,
  getTimeOffsetFromSelectedDate,
  getTodayOrPastDate,
  getTodayOrYesterdayOrPastDate,
  humanize,
};
