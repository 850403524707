import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  not_sent: {
    justifyContent: 'center',
    '& svg': {
      marginRight: '4px',
      fontSize: '20px',
      verticalAlign: 'middle',
      color: theme.palette.correction.smokyTopaz2,
    },
  },
  historical: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    '& b': {
      marginRight: '4px',
    },
  },
  more_letters_sent: {
    paddingLeft: '4px',
  },
}));

export { useStyles };
