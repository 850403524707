import React from 'react';
import PropTypes from 'prop-types';

const DiabeticCare = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M18.4528 6.64617H12.7386V0.931885H6.30999V6.64617H0.595703V13.0747H6.30999V18.789H12.7386V13.0747H18.4528V6.64617Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.2863 24.7414C29.5244 24.7414 21.5482 8.78897 19.2863 6.16992C17.0244 8.78897 9.04816 24.7414 19.2863 24.7414Z"
          fill="white"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6667 16.2889C16.1905 17.3604 15.3572 20.3366 17.0238 22.0032"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0 0.33667)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

DiabeticCare.propTypes = {
  className: PropTypes.string,
};

export default DiabeticCare;
