import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Button from '../../common/Button/Button';
import Card from '../../common/Card/Card';
import Search from '../../common/Icons/basic/Search';
import PinAdd from '../../common/Icons/maps/PinAdd';
import Typography from '../../common/Typography/Typography';
import { useExternalId } from '../../hooks/useExternalId';
import useGraphQLQuery from '../../hooks/useGraphQLQuery';
import { registerEvent } from '../../services/Analytics';
import referredCommunityService from '../../services/referredCommunitiesService';
import EmptyCommunities from '../EmptyCommunities/EmptyCommunities';
import Loading from '../Loading/Loading';
import SavedCommunities from '../SavedCommunities/SavedCommunities';
import { useStyles } from './Community.style';
import familyFileCommunitiesQuery from './familyFileCommunitiesQuery';
import IncompleteFamilyFile from './IncompleteFamilyFile/IncompleteFamilyFile';
import ReferredCommunities from './ReferredCommunities/ReferredCommunities';
import FamilyFileSearchBanner from './FamilyFileSearchBanner/FamilyFileSearchBanner';

const CommunityLG = ({ labels, familyFileId }) => {
  const classes = useStyles();
  const { loading, error, data } = useGraphQLQuery(familyFileCommunitiesQuery, {
    variables: { familyFileId },
  });
  const [communitiesLength, setCommunitiesLength] = useState(0);
  const [referredCommunitiesCount, setReferredCommunitiesCount] = useState(0);
  const { fetchedFamilyId } = useExternalId(familyFileId);

  let familyFileUserId = useRef();
  const sendReferredCommunitiesCount = (count) => {
    setReferredCommunitiesCount(count);
  };

  useEffect(() => {
    if (data?.findFamilyFileById?.communities) {
      familyFileUserId.current = data.findFamilyFileById.userId;

      const savedCommunities = referredCommunityService.buildSavedCommunities(
        data.findFamilyFileById.referralStatus,
        data.findFamilyFileById.communities,
      );
      setCommunitiesLength(savedCommunities.length);
    }
  }, [data]);

  if (loading) return <Loading height="calc(100vh - 197px)" />;
  if (error) return `Error! ${error.message}`;

  const savedCommunities = referredCommunityService.buildSavedCommunities(
    data.findFamilyFileById.referralStatus,
    data.findFamilyFileById.communities,
  );

  const tourDetails = data.findFamilyFileById.tourDetailsExtended;
  const tourDrafts = data.findFamilyFileById.tourDrafts;

  const dataForSegment = {
    familyFileId: familyFileId,
    oneId: data.findFamilyFileById.oneId,
  };

  const preferredLocations =
    data.findFamilyFileById.familyDesiredLocations || [];

  const shouldShowIncompleteFamilyFile = preferredLocations.length === 0;

  const shouldShowEmptyReferredCommunities =
    !shouldShowIncompleteFamilyFile && referredCommunitiesCount === 0;

  const shouldShowSavedCommunities =
    !shouldShowIncompleteFamilyFile && communitiesLength > 0;

  return (
    <div className={classes.community_container}>
      {(shouldShowSavedCommunities || !shouldShowEmptyReferredCommunities) && (
        <FamilyFileSearchBanner familyFileId={familyFileId} labels={labels} />
      )}

      <ReferredCommunities
        labels={labels}
        familyFileId={fetchedFamilyId ?? familyFileId}
        sendReferredCommunitiesCount={sendReferredCommunitiesCount}
      />
      {shouldShowIncompleteFamilyFile && (
        <IncompleteFamilyFile labels={labels} familyFileId={familyFileId} />
      )}
      {!shouldShowSavedCommunities && shouldShowEmptyReferredCommunities && (
        <div className={classes.empty_communities_container}>
          <EmptyCommunities labels={labels} />
          <div
            className={classes.empty_communities_container_button}
            onClick={() => {
              registerEvent(
                'FamilyFiles',
                'Go to search communities',
                'Empty Communities',
              );
            }}
          >
            <Button
              openInNewTab
              to={`/communities/${familyFileId}`}
              className={classes.search_community}
              startIcon={<Search />}
            >
              <Typography color="platinum5">
                {labels.SEARCH_FOR_COMMUNITIES}
              </Typography>
            </Button>
          </div>
        </div>
      )}
      {shouldShowSavedCommunities && (
        <Card
          className={classes.saved_communities_container}
          title={labels.SAVED_COMMUNITIES}
          icon={<PinAdd />}
        >
          <div className={classes.text_container}>
            <Typography>{labels.YOU_HAVE}</Typography>
            <Typography bold>{`(${communitiesLength})`}</Typography>
            <Typography>{labels.SAVED_COMMUNITIES.toLowerCase()}</Typography>
          </div>

          <SavedCommunities
            dataForAnalytics={{
              originatingPage: 'communities tab',
            }}
            dataForSegment={dataForSegment}
            familyFileId={fetchedFamilyId ?? familyFileId}
            familyFileUserId={familyFileUserId.current}
            labels={labels}
            onRemove={(newCommunities) => {
              setCommunitiesLength(newCommunities.length);
            }}
            savedCommunities={savedCommunities}
            tourDetails={tourDetails}
            tourDrafts={tourDrafts}
          />
        </Card>
      )}
    </div>
  );
};

CommunityLG.propTypes = {
  labels: PropTypes.object.isRequired,
  familyFileId: PropTypes.number.isRequired,
};

export default CommunityLG;
