import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  navigation_side_panel_container: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: theme.palette.neutral.cosmicCobalt1,
  },
  my_work_list: {
    marginLeft: '16px',
    marginBottom: '4px',
  },
  list_item: {
    display: 'flex',
    alignItems: 'center',
    padding: '13px 20px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.neutral.cosmicCobalt2,
    },
    '& svg': {
      color: theme.palette.light.platinum5,
      fill: theme.palette.light.platinum5,
      fontSize: '24px',
    },
    '& p': {
      marginLeft: '20px',
    },
  },
  family_files_items: {
    display: 'flex',
    alignItems: 'center',
    padding: '13px 20px 13px 32px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.neutral.cosmicCobalt2,
    },
    '& svg': {
      color: theme.palette.light.platinum5,
      fill: theme.palette.light.platinum5,
      fontSize: '24px',
    },
    '& p': {
      marginLeft: '8px',
    },
  },
  family_files_arrow: {
    display: 'flex',
    marginLeft: '52px',
    '& svg': {
      fontSize: '18px',
    },
  },
  yellow_circle: {
    height: '16px',
    width: '16px',
    backgroundColor: theme.palette.miscellaneous2.fieldDrab4,
    borderRadius: '50%',
  },
  green_circle: {
    height: '16px',
    width: '16px',
    backgroundColor: theme.palette.positive.wintergreenDream3,
    borderRadius: '50%',
  },
  amber_circle: {
    height: '16px',
    width: '16px',
    backgroundColor: theme.palette.miscellaneous1.hotCinnamon4,
    borderRadius: '50%',
  },
  red_circle: {
    height: '16px',
    width: '16px',
    backgroundColor: theme.palette.correction.smokyTopaz3,
    borderRadius: '50%',
  },
  square_stairs: {
    marginRight: 'auto',
    marginTop: 'auto',
    color: theme.palette.neutral.cosmicCobalt2,
    fontSize: '58px',
  },
  item_active: {
    backgroundColor: theme.palette.neutral.cosmicCobalt2,
  },
}));

export { useStyles };
