import getSpecialFeatures from 'common/Filters/MoreCommunityFilters/otherFeatures';
import getTypeOfCares from 'common/Filters/MoreCommunityFilters/typeOfCares';
import labels from 'config/labels';
import { logEvent } from 'services/analyticsService';
import bedroomTypes from 'components/MoreFiltersSidePanel/FilterGroups/bedroomTypesList';
import roomAmenities from 'components/MoreFiltersSidePanel/FilterGroups/roomAmenitiesList';
import communityAmenities from 'components/MoreFiltersSidePanel/FilterGroups/communityAmenitiesList';
import healthcareServices from 'components/MoreFiltersSidePanel/FilterGroups/healthcareServicesList';

export const IMAGE_SIZE = {
  LARGE: 1,
  REGULAR: 4,
  SMALL: 2,
  THUMBNAIL: 3,
};

const careTypeLookup = {
  adultDayServices: 'D',
  assistedLiving: 'A',
  homeCare: 'H',
  independentLiving: 'R', // Independent Living is the new term for Retirement Community
  memoryCare: 'Z',
  notSure: 'O',
  nursingHome: 'N',
  residentialCareHome: 'G',
  respiteCare: 'M',
  seniorApartments: 'P',
};

export const getPriceRanges = (careTypes, roomCharges) => {
  roomCharges = roomCharges || [];
  const groups = roomCharges.reduce((accumulator, current) => {
    if (!accumulator[current.careType]) {
      accumulator[current.careType] = [];
    }
    if (current.amount > 0) {
      accumulator[current.careType].push(current.amount);
    }
    return accumulator;
  }, {});

  // Add entry for careTypes without charges
  for (const careType in careTypes) {
    if (careTypes[careType] === true) {
      const code = careTypeLookup[careType];
      if (!groups[code]) {
        groups[code] = [];
      }
    }
  }

  const prices = Object.keys(groups).map((careType) => {
    const roomCharges = groups[careType];
    if (roomCharges.length > 0) {
      const min = Math.min(...roomCharges);
      const max = Math.max(...roomCharges);
      return { careType, min, max };
    }
    return { careType, min: null, max: null };
  });
  return prices;
};

export const getRoomCharges = (roomCharges) => {
  roomCharges = roomCharges || [];
  return roomCharges.map((roomCharge) => ({
    careType: roomCharge.careType,
    amount: roomCharge.amount,
    apartmentType: roomCharge.apartmentType.description,
  }));
};

export const getRoomFees = (fees) => {
  fees = fees || [];
  return fees.map((fee) => ({
    careType: fee.careType,
    amount: fee.amount,
    feeType: fee.feeType.name,
  }));
};

export const getDisplayImageId = (images, imageSize = IMAGE_SIZE.REGULAR) => {
  const imageId = images
    ?.find((image) => {
      return image.isDefault;
    })
    ?.assets.find((asset) => {
      return asset.sizeId === imageSize;
    })?.id;

  if (isNaN(imageId)) {
    return 0;
  }
  return parseInt(imageId);
};

export const getQueryVariables = (
  locationFilter,
  distanceFilter,
  careTypeFilter,
  budgetFilter,
  attributeNamesFilter,
  groupNamesFilter,
) => {
  const filters = {};
  if (locationFilter) {
    filters.zip = locationFilter.zip;
  }
  if (Number(distanceFilter)) {
    filters.distance = Number(distanceFilter);
  }
  if (careTypeFilter?.length > 0) {
    filters.careTypes = careTypeFilter;
  }
  if (attributeNamesFilter?.length > 0) {
    filters.groupAttributeNames = attributeNamesFilter;
  }
  if (groupNamesFilter?.length > 0) {
    filters.groupNames = groupNamesFilter;
  }

  const isHomeCare = careTypeFilter.some((careType) => careType === 'homeCare');

  if (!isHomeCare) {
    if (budgetFilter?.min) {
      filters.budgetGte = budgetFilter.min;
    }
    if (budgetFilter?.max) {
      filters.budgetLte = budgetFilter.max;
    }
  }
  return filters;
};

export const getCareTypesLabels = (community) => {
  const careTypeList = [];

  for (const careType in community.careTypes) {
    if (community.careTypes[careType] === true) {
      const code = careTypeLookup[careType];
      careTypeList.push(code);
    }
  }

  return careTypeList;
};

export const getAmenities = (communitiesFeature, labels) => {
  const allSpecialFeatures = getSpecialFeatures(labels);
  let mappedFeatures = [];

  if (
    communitiesFeature !== null &&
    communitiesFeature !== undefined &&
    JSON.stringify(communitiesFeature) !== '{}'
  ) {
    const communityFeatures = [].concat(...communitiesFeature);
    mappedFeatures = allSpecialFeatures.filter((feature) => {
      return communityFeatures.includes(feature.label);
    });
  }

  return mappedFeatures;
};

export const logCommunitySearchViewed = (
  familyFileId,
  externalId,
  userId,
  resultsCount,
  alertNotification,
) => {
  logEvent('Community Search Viewed', {
    alertNotification: alertNotification,
    channel: 'community',
    crmUserId: userId,
    leadId: externalId,
    eventPath: 'community search start',
    eventType: 'view',
    familyFileId: familyFileId,
    numberSearchResults: resultsCount,
    screenName: 'community search',
    section: 'community search',
  });
};

export const logCommunitySearchResultsViewed = (
  familyFileId,
  userId,
  communityIds,
  filters,
) => {
  logEvent('Community Search Results Viewed', {
    channel: 'community',
    communityIdList: communityIds.map((id) => Number(id)),
    crmUserId: userId,
    eventType: 'view',
    familyFileId: familyFileId,
    numberSearchResults: communityIds.length,
    screenName: 'community search results',
    section: 'community search',
    ...mappedFilters(filters),
  });
};

export const logCommunitySearchCommunitySaved = (
  familyFileId,
  userId,
  communityId,
  communitySearchRank,
  orderCommunitySaved,
  communityIds,
  filters,
) => {
  logEvent('Community Search Community Saved', {
    channel: 'community',
    communityId: communityId,
    communityIdList: communityIds.map((id) => Number(id)),
    communitySearchRank: communitySearchRank,
    crmUserId: userId,
    eventPath: 'community search save',
    eventType: 'action',
    familyFileId: familyFileId,
    orderCommunitySaved: orderCommunitySaved,
    screenName: 'community search',
    section: 'community search',
    ...mappedFilters(filters),
  });
};

export const logCommunitySearchFiltered = (
  familyFileId,
  userId,
  resultsCount,
  filters,
) => {
  logEvent('Community Search Filtered', {
    channel: 'community',
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    numberSearchResults: resultsCount,
    screenName: 'community search',
    section: 'community search',
    ...mappedFilters(filters),
  });
};

export const logCommunitySearchSavedCommunitiesViewed = (
  familyFileId,
  userId,
  alertNotification,
) => {
  logEvent('Community Search Saved Communities Viewed', {
    alertNotification: alertNotification,
    channel: 'community',
    crmUserId: userId,
    eventType: 'view',
    familyFileId: familyFileId,
    screenName: 'community search saved communities',
    section: 'community search',
  });
};

export const logCommunitySearchWorkingListSaved = (
  familyFileId,
  userId,
  communityIds,
  selectedCount,
) => {
  logEvent('Community Search Working List Saved', {
    channel: 'community',
    communityIdList: communityIds.map((id) => Number(id)),
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    savedWorkingListNum: selectedCount,
    screenName: 'community send referral alert',
    section: 'community search',
  });
};

export const logCommunitySearchSavedCommunityRemoved = (
  familyFileId,
  userId,
  communityIds,
) => {
  logEvent('Community Search Saved Community Removed', {
    channel: 'community',
    communityIdList: communityIds.map((id) => Number(id)),
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    screenName: 'community search saved communities',
    section: 'community search',
  });
};

export const logCommunitySearchCommunityAttributeClicked = (
  familyFileId,
  userId,
  communityId,
  communitySearchRank,
  salesPhase,
  attribute,
) => {
  logEvent('Community Search Community Attribute Clicked', {
    attribute: attribute,
    channel: 'community',
    communityId: communityId,
    communitySearchRank: communitySearchRank,
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    salesPhase: salesPhase,
    section: 'community search',
    screenName: 'community search results',
  });
};

export const logCommunitySearchProfileViewed = (
  familyFileId,
  userId,
  communityId,
  communitySearchRank,
) => {
  logEvent('Community Search Profile Viewed', {
    channel: 'community',
    communityId: communityId,
    communitySearchRank: communitySearchRank,
    crmUserId: userId,
    eventType: 'view',
    familyFileId: familyFileId,
    screenName: 'community search view profile',
    section: 'community search',
  });
};

export const logCommunitySearchViewErrorEncountered = (
  familyFileId,
  userId,
  errorMessage,
) => {
  logEvent('Community Search View Error Encountered', {
    channel: 'community',
    errorMessage: errorMessage,
    crmUserId: userId,
    eventType: 'view',
    familyFileId: familyFileId,
    screenName: 'community search error page',
    section: 'community search',
  });
};

const mappedFilters = (filters) => {
  const mappedFilters = {};

  mappedFilters.filterLocation = filters.location.address;
  mappedFilters.filterDistance = filters.distance;
  mappedFilters.filterFinancialRange = `${filters.budget.min}|${filters.budget.max}`;

  mappedFilters.filterCommunityCareType = filters.careTypes
    .flatMap((filter) => {
      return getTypeOfCares(labels).filter((careType) => {
        return careType.value === filter;
      });
    })
    .map((feature) => feature.label)
    .join('|');

  mappedFilters.filterBedroomType = filters.other
    .flatMap((filter) => {
      return bedroomTypes.filter((bedroom) => {
        return bedroom.value === filter.value && filter.selected;
      });
    })
    .map((feature) => feature.label)
    .join('|');

  mappedFilters.filterRoomAmenities = filters.other
    .flatMap((filter) => {
      return roomAmenities.filter((amenity) => {
        return amenity.value === filter.value && filter.selected;
      });
    })
    .map((amenity) => amenity.label)
    .join('|');

  mappedFilters.filterCommunityAmenities = filters.other
    .flatMap((filter) => {
      return communityAmenities.filter((amenity) => {
        return amenity.value === filter.value && filter.selected;
      });
    })
    .map((amenity) => amenity.label)
    .join('|');

  if (filters.healthcareServices) {
    mappedFilters.filterHealthcareServices = filters.healthcareServices
      .flatMap((filter) => {
        return healthcareServices.filter((service) => {
          return service.value === filter.value && filter.selected;
        });
      })
      .map((service) => service.label)
      .join('|');
  } else {
    mappedFilters.filterHealthcareServices = '';
  }

  return mappedFilters;
};
