import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      minHeight: '85px',
      width: '100%',
    },
    graphic_column: {
      display: 'flex',
      width: '20px',
      flexDirection: 'column',
      alignItems: 'center',
      height: 'auto',
    },
    ball: {
      width: '16px',
      height: '16px',
      borderRadius: '50%',
      border: (color) => `4px solid ${theme.palette.getColor(color)}`,
      boxSizing: 'border-box',
    },
    line: {
      height: '100%',
      width: '1px',
      backgroundColor: theme.palette.dark.disabled,
    },
    card_column: {
      width: '100%',
      display: 'flex',
      paddingLeft: '24px',
      paddingTop: '16px',
      alignItems: 'center',
      flexGrow: 1,
    },
  };
});

export { useStyles };
