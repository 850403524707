import React from 'react';
import PropTypes from 'prop-types';

const CheckMark = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M9.70711 14.2929L19 5L20.4142 6.41421L9.70711 17.1213L4 11.4142L5.41421 10L9.70711 14.2929Z"
      />
    </svg>
  );
};

CheckMark.propTypes = {
  className: PropTypes.string,
};

export default CheckMark;
