import gql from 'graphql-tag';

export default gql`
  mutation($closedReason: String, $status: String, $inquiryId: Int) {
    closeInquiry(
      input: {
        closedReason: $closedReason
        status: $status
        inquiryId: $inquiryId
      }
    ) {
      resultStatus
      error
    }
  }
`;
