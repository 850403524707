import gql from 'graphql-tag';

export default gql`
  mutation($contactId: Int, $familyFileId: Int!, $profileFormDataId: Int!) {
    upsertContact(
      input: {
        contactId: $contactId
        familyFileId: $familyFileId
        profileFormDataId: $profileFormDataId
      }
    ) {
      contactId
      profileData {
        firstName
        lastName
        formDataId
      }
      updatedAt
      updatedBy
      createdAt
      createdBy
    }
  }
`;
