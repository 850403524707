import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from './EmailFamilyLetterFilter.style';
import ChevronBottom from '../../Icons/arrow/ChevronBottom';
import ChevronTop from '../../Icons/arrow/ChevronTop';
import EmailContainerFilter from '../EmailContainerFilter/EmailContainerFilter';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Typography from '../../Typography/Typography';

const initialPrimaryContact = (contacts, labels) => {
  const primaryContactList = [];
  const contact = contacts.find(
    (contact) => contact.profileData && contact.profileData.isPrimary,
  );
  const hasSecondaryEmail = contact.profileData.emailAddresses.find(
    (secondaryEmail) => !secondaryEmail.isPrimary,
  );
  const fullName = `${contact.profileData.firstName} ${contact.profileData.lastName}`;
  const relationship = contact.profileData.relationToResident
    ? contact.profileData.relationToResident
    : labels.UNKNOWN_RELATION;
  if (contact) {
    primaryContactList.push({
      name: fullName,
      relationToResident: relationship,
      emailAddress: `${contact.profileData.emailAddress}`,
      isPrimary: true,
      checked: true,
      disabled: true,
    });
  }
  if (hasSecondaryEmail) {
    primaryContactList.push({
      name: fullName,
      relationToResident: relationship,
      emailAddress: hasSecondaryEmail.emailAddress,
      isPrimary: false,
      checked: false,
      disabled: false,
    });
  }
  return primaryContactList;
};

const initialAdditionalContacts = (contacts) => {
  return contacts
    .filter(
      (contact) =>
        contact.profileData &&
        !contact.profileData.isPrimary &&
        contact.profileData.emailAddress,
    )
    .map((contact) => ({
      name: `${contact.profileData.firstName} ${contact.profileData.lastName}`,
      relationToResident: contact.profileData.relationToResident,
      emailAddress: contact.profileData.emailAddress,
      checked: false,
    }));
};
const EmailFamilyLetterFilter = ({
  labels,
  contacts,
  disabled,
  className,
  onContactsSelected,
}) => {
  const classes = useStyles();
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedText, setSelectedText] = useState('');
  const [elementsPrimary, setElementsPrimary] = useState(
    initialPrimaryContact(contacts, labels),
  );
  const [elementsAdditional, setElementsAdditional] = useState(
    initialAdditionalContacts(contacts),
  );
  const getPrimaryContactFullName = () => {
    const primaryContact = contacts.find(
      (contact) => contact.profileData && contact.profileData.isPrimary,
    );
    if (primaryContact) {
      return `${primaryContact.profileData.firstName} ${primaryContact.profileData.lastName}`;
    }
  };
  const getPrimaryContactRelationship = () => {
    const primaryContact = contacts.find(
      (contact) => contact.profileData && contact.profileData.isPrimary,
    );
    if (primaryContact) {
      return primaryContact.profileData.relationToResident
        ? primaryContact.profileData.relationToResident
        : labels.UNKNOWN_RELATION;
    }
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleOptionList = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const onChangeAdditional = (numberAdditional) => {
    if (numberAdditional === 0) {
      setSelectedText(
        `${getPrimaryContactFullName()} (${getPrimaryContactRelationship()})`,
      );
    } else {
      setSelectedText(
        `${getPrimaryContactFullName()} (${getPrimaryContactRelationship()}) + (${numberAdditional})`,
      );
    }
    return selectedText;
  };

  const onContactsChange = (selectedAdditionals, selectedPrimary) => {
    setElementsPrimary(selectedPrimary);
    setElementsAdditional(selectedAdditionals);
    onContactsSelected(selectedAdditionals, selectedPrimary);
  };

  useEffect(
    () => {
      if (elementsPrimary) {
        onChangeAdditional(determineSelectedAdditional());
      } else {
        setSelectedText('-');
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elementsPrimary, elementsAdditional],
  );

  const determineSelectedAdditional = () => {
    const selectedAdditional = elementsAdditional.filter((element) => {
      return element.checked;
    });
    return selectedAdditional.length;
  };
  const selectClasses = clsx([
    className,
    classes.custom_select,
    disabled && classes.disabled,
  ]);
  return (
    <div className={selectClasses} ref={wrapperRef} data-testid="dropdown-id">
      <Typography level="small">{labels.SEND_TO}</Typography>
      <div className={classes.select} onClick={handleOptionList} tabIndex="0">
        <div>{selectedText}</div>
        <div className={classes.icon}>
          {isOpen ? <ChevronTop /> : <ChevronBottom />}
        </div>
      </div>
      {isOpen && !disabled && (
        <EmailContainerFilter
          className={classes.filter}
          labels={labels}
          onChangeAdditional={onChangeAdditional}
          elementsPrimary={elementsPrimary}
          elementsAdditional={elementsAdditional}
          onContactsChange={onContactsChange}
          multiline
          isMandatory
        />
      )}
    </div>
  );
};

EmailFamilyLetterFilter.propTypes = {
  labels: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  selectedText: PropTypes.string,
  primaryContact: PropTypes.array,
  additionalContacts: PropTypes.array,
  contacts: PropTypes.array,
  isMandatory: PropTypes.bool,
  onContactsSelected: PropTypes.func,
};

EmailFamilyLetterFilter.defaultProps = {
  disabled: false,
  className: '',
  onContactsSelected: () => {},
  isMandatory: true,
};

export default EmailFamilyLetterFilter;
