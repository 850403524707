import gql from 'graphql-tag';

export default gql`
  mutation(
    $duplicateFamilyFileId: Int
    $inquiryId: Int
    $createFamilyFile: Boolean
    $familyFileStatus: String
    $newFamilyFileId: Int
    $isSameResident: Boolean
    $familyFilesFound: [FamilyFileDuplicate]
  ) {
    updateInquiry(
      input: {
        duplicateFamilyFileId: $duplicateFamilyFileId
        inquiryId: $inquiryId
        createFamilyFile: $createFamilyFile
        familyFileStatus: $familyFileStatus
        newFamilyFileId: $newFamilyFileId
        isSameResident: $isSameResident
        familyFilesFound: $familyFilesFound
      }
    ) {
      duplicateStatus
      inquiryId
      inquiryFamilyFile {
        familyFileId
      }
    }
  }
`;
