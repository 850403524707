import React from 'react';

const Independent = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9134 33.1378C31.5119 33.1378 38.4823 26.1674 38.4823 17.5689C38.4823 8.97044 31.5119 2 22.9134 2C14.3149 2 7.34448 8.97044 7.34448 17.5689C7.34448 26.1674 14.3149 33.1378 22.9134 33.1378Z"
        fill="#F3F5FC"
      />
      <path
        d="M7.34474 25.1724C6.94484 25.1724 6.62061 24.8481 6.62061 24.4482V22.2758C6.62061 21.8759 6.94484 21.5517 7.34474 21.5517C7.74464 21.5517 8.06887 21.8759 8.06887 22.2758V24.4482C8.06887 24.8481 7.74464 25.1724 7.34474 25.1724Z"
        fill="#0D1321"
      />
      <path
        d="M7.34474 30.2413C6.94484 30.2413 6.62061 29.9171 6.62061 29.5172V27.3447C6.62061 26.9448 6.94484 26.6206 7.34474 26.6206C7.74464 26.6206 8.06887 26.9448 8.06887 27.3447V29.5172C8.06887 29.9171 7.74464 30.2413 7.34474 30.2413Z"
        fill="#0D1321"
      />
      <path
        d="M5.89655 26.6206H3.72414C3.32423 26.6206 3 26.2964 3 25.8965C3 25.4966 3.32423 25.1724 3.72414 25.1724H5.89655C6.29645 25.1724 6.62068 25.4966 6.62068 25.8965C6.62068 26.2964 6.29645 26.6206 5.89655 26.6206Z"
        fill="#0D1321"
      />
      <path
        d="M10.9656 26.6206H8.79323C8.39332 26.6206 8.06909 26.2964 8.06909 25.8965C8.06909 25.4966 8.39332 25.1724 8.79323 25.1724H10.9656C11.3655 25.1724 11.6898 25.4966 11.6898 25.8965C11.6898 26.2964 11.3655 26.6206 10.9656 26.6206Z"
        fill="#0D1321"
      />
      <path
        d="M8.06886 15.7586C8.46879 15.7586 8.793 15.4344 8.793 15.0344C8.793 14.6345 8.46879 14.3103 8.06886 14.3103C7.66893 14.3103 7.34473 14.6345 7.34473 15.0344C7.34473 15.4344 7.66893 15.7586 8.06886 15.7586Z"
        fill="#0D1321"
      />
      <path
        d="M43.5518 17.931C43.9517 17.931 44.2759 17.6068 44.2759 17.2069C44.2759 16.8069 43.9517 16.4827 43.5518 16.4827C43.1518 16.4827 42.8276 16.8069 42.8276 17.2069C42.8276 17.6068 43.1518 17.931 43.5518 17.931Z"
        fill="#0D1321"
      />
      <path
        d="M7.70684 7.06895C6.30926 7.06895 5.17236 5.93206 5.17236 4.53447C5.17236 3.13689 6.30926 2 7.70684 2C9.10442 2 10.2413 3.13689 10.2413 4.53447C10.2413 5.93206 9.10442 7.06895 7.70684 7.06895ZM7.70684 3.44827C7.1078 3.44827 6.62063 3.93543 6.62063 4.53447C6.62063 5.13351 7.1078 5.62068 7.70684 5.62068C8.30588 5.62068 8.79304 5.13351 8.79304 4.53447C8.79304 3.93543 8.30588 3.44827 7.70684 3.44827Z"
        fill="#0D1321"
      />
      <path
        d="M42.4656 28.0689C41.068 28.0689 39.9312 26.932 39.9312 25.5344C39.9312 24.1368 41.068 22.9999 42.4656 22.9999C43.8632 22.9999 45.0001 24.1368 45.0001 25.5344C45.0001 26.932 43.8632 28.0689 42.4656 28.0689ZM42.4656 24.4482C41.8666 24.4482 41.3794 24.9354 41.3794 25.5344C41.3794 26.1334 41.8666 26.6206 42.4656 26.6206C43.0647 26.6206 43.5518 26.1334 43.5518 25.5344C43.5518 24.9354 43.0647 24.4482 42.4656 24.4482Z"
        fill="#0D1321"
      />
      <path
        d="M19.6548 24.0609H26.172V32.2308H19.6548V24.0609Z"
        fill="#F3E0D4"
      />
      <path
        d="M26.172 32.9549H19.6548C19.2549 32.9549 18.9307 32.6306 18.9307 32.2307V24.0609C18.9307 23.661 19.2549 23.3367 19.6548 23.3367H26.172C26.5719 23.3367 26.8962 23.661 26.8962 24.0609V32.2307C26.8962 32.6306 26.5719 32.9549 26.172 32.9549ZM20.3789 31.5066H25.4479V24.785H20.3789V31.5066Z"
        fill="#0D1321"
      />
      <path
        d="M12.7011 29.8931L19.2743 28.6603L19.8257 29.0837C21.6451 30.4809 24.178 30.4755 25.9914 29.0703L26.3348 28.8042L33.0473 29.9473C35.3501 30.3394 37.0344 32.3347 37.0344 34.6706V44.724H8.79297V34.6022C8.79297 32.2967 10.4349 30.318 12.7011 29.8931Z"
        fill="#B5C3F1"
      />
      <path
        d="M37.0343 45.4481H8.79298C8.39308 45.4481 8.06885 45.1239 8.06885 44.724V34.6024C8.06885 31.9502 9.96083 29.6705 12.5677 29.1815L19.1409 27.9485C19.3435 27.9106 19.5522 27.9604 19.7155 28.0861L20.2669 28.5097C21.824 29.7052 23.9958 29.7007 25.5479 28.4981L25.8913 28.232C26.0515 28.1076 26.2564 28.0562 26.4565 28.0904L33.169 29.2333C35.8282 29.6862 37.7584 31.9727 37.7584 34.6704V44.724C37.7584 45.1239 37.4342 45.4481 37.0343 45.4481ZM9.51712 43.9998H36.3101V34.6704C36.3101 32.6812 34.8868 30.9949 32.9257 30.6611L26.5269 29.5714L26.4347 29.6428C24.3628 31.2484 21.4635 31.2545 19.3846 29.6583L19.0896 29.4317L12.8346 30.605C10.9123 30.9654 9.51712 32.6465 9.51712 34.6024V43.9998ZM12.7011 29.8931H12.703H12.7011Z"
        fill="#0D1321"
      />
      <path
        d="M31.9654 45.4481C31.5654 45.4481 31.2412 45.1238 31.2412 44.7239V36.0343C31.2412 35.6344 31.5654 35.3102 31.9654 35.3102C32.3653 35.3102 32.6895 35.6344 32.6895 36.0343V44.7239C32.6895 45.1238 32.3653 45.4481 31.9654 45.4481Z"
        fill="#0D1321"
      />
      <path
        d="M14.586 45.4481C14.186 45.4481 13.8618 45.1238 13.8618 44.7239V36.0343C13.8618 35.6344 14.186 35.3102 14.586 35.3102C14.9859 35.3102 15.3101 35.6344 15.3101 36.0343V44.7239C15.3101 45.1238 14.9859 45.4481 14.586 45.4481Z"
        fill="#0D1321"
      />
      <path
        d="M14.2307 10.7238C14.2492 11.3824 14.254 14.9919 14.1892 14.9919C13.197 14.9919 12.3966 15.8139 12.4323 16.8142C12.4524 17.3765 12.7982 17.8848 13.2647 18.1996C13.8302 18.5812 14.316 18.5543 14.747 18.4092C15.4447 21.8129 17.9155 24.6589 21.327 25.7571C22.3052 26.0719 23.3631 26.0418 24.3405 25.725L24.6526 25.6238C28.0094 24.5358 30.4584 21.7491 31.1881 18.4016C31.6174 18.5532 32.1062 18.5794 32.6598 18.2347C33.1078 17.9556 33.45 17.4974 33.5144 16.9735C33.6464 15.9028 32.8146 14.9921 31.7702 14.9921C31.7186 14.9921 31.7617 13.8032 31.7702 13.2125L14.2307 10.7238Z"
        fill="#F3E0D4"
      />
      <path
        d="M22.761 26.7044C22.1969 26.7044 21.6402 26.6184 21.1053 26.4462C17.7283 25.3593 15.1355 22.6299 14.2005 19.2331C13.7378 19.2369 13.294 19.093 12.8597 18.7997C12.1662 18.3319 11.7361 17.5993 11.7089 16.8402C11.6846 16.1593 11.9314 15.5154 12.4034 15.0263C12.7117 14.7067 13.0891 14.481 13.5033 14.3628C13.5247 13.3731 13.5221 11.2859 13.5071 10.7439C13.5011 10.5306 13.5895 10.3255 13.7486 10.1836C13.9077 10.0415 14.121 9.97613 14.3326 10.0067L31.8721 12.4952C32.2331 12.5463 32.4997 12.858 32.4945 13.2226C32.4923 13.3642 32.4883 13.54 32.4842 13.7255C32.48 13.9085 32.4744 14.152 32.4715 14.3687C32.9147 14.4994 33.3178 14.7527 33.6301 15.1057C34.101 15.6381 34.3206 16.3511 34.2331 17.0618C34.1459 17.7709 33.7009 18.4389 33.0428 18.849C32.619 19.1131 32.178 19.2409 31.7246 19.2311C30.7624 22.5591 28.1911 25.2378 24.876 26.3123L24.5639 26.4135C23.9667 26.6075 23.3597 26.7044 22.761 26.7044ZM14.7472 17.6853C14.8725 17.6853 14.9972 17.7179 15.1084 17.7818C15.2881 17.8851 15.4149 18.0607 15.4567 18.264C16.1122 21.462 18.4466 24.0691 21.549 25.0677C22.349 25.3253 23.2612 25.3139 24.1173 25.0362L24.4296 24.935C27.4782 23.9467 29.7969 21.3843 30.4809 18.2476C30.525 18.0446 30.6543 17.8705 30.8357 17.7691C31.0173 17.6681 31.2334 17.6498 31.4291 17.7188C31.7421 17.8288 31.9876 17.8006 32.2773 17.6199C32.5676 17.4391 32.7617 17.1641 32.7961 16.8847C32.8334 16.5813 32.7445 16.2904 32.5454 16.0654C32.3489 15.8433 32.0665 15.716 31.7705 15.716C31.5756 15.716 31.3858 15.636 31.2495 15.4964C31.0098 15.2513 31.004 15.1338 31.0332 13.839L14.965 11.5595C14.9675 12.0175 14.9681 12.5731 14.9657 13.081C14.9559 15.2431 14.9469 15.2527 14.7103 15.496C14.5739 15.6362 14.385 15.7162 14.1894 15.7162C13.9065 15.7162 13.6422 15.8284 13.4454 16.0323C13.2488 16.2359 13.1461 16.5044 13.1561 16.7888C13.1668 17.0821 13.3636 17.3927 13.6699 17.5996C14.006 17.8265 14.2432 17.8149 14.5164 17.7233C14.5915 17.6978 14.6695 17.6853 14.7472 17.6853Z"
        fill="#0D1321"
      />
      <path
        d="M31.7703 11.7378C31.7703 0.575597 14.1893 1.27891 14.2281 10.3049C14.2324 11.3182 14.2536 12.4183 14.2433 13.1783C16.165 13.244 17.9601 12.6082 19.1466 11.4165C21.1927 15.3903 26.8965 17.0196 31.7703 13.2125C31.7703 11.7378 31.7703 12.3729 31.7703 11.7378Z"
        fill="#E9CE73"
      />
      <path
        d="M25.9076 16.097C25.2682 16.097 24.6299 16.0286 23.9984 15.8898C21.8977 15.4287 20.106 14.2221 18.9635 12.5265C17.6876 13.4684 16.0065 13.9627 14.2183 13.902C13.8243 13.8886 13.5135 13.5624 13.5189 13.1683C13.5255 12.6936 13.5193 12.0814 13.513 11.4333C13.5092 11.0601 13.5054 10.6783 13.5037 10.3078C13.4925 7.71808 14.8008 5.52811 17.1872 4.14121C20.4373 2.25212 24.986 2.24433 28.2489 4.12202C30.9867 5.69738 32.4944 8.4022 32.4944 11.7378V13.2125C32.4944 13.4353 32.3917 13.646 32.2161 13.7831C30.2684 15.3043 28.0827 16.097 25.9076 16.097ZM19.1466 10.6923C19.1835 10.6923 19.2204 10.6952 19.2575 10.7008C19.4864 10.7363 19.6842 10.8791 19.7903 11.0849C20.6752 12.8035 22.3219 14.0392 24.3087 14.4755C26.5593 14.9696 28.939 14.3933 31.0459 12.8524V11.7378C31.0459 8.15473 29.132 6.30112 27.5264 5.37713C24.7362 3.7719 20.694 3.7786 17.915 5.39342C16.557 6.18255 14.9406 7.67481 14.952 10.3016C14.9536 10.6693 14.9573 11.0486 14.9611 11.4186C14.9649 11.7763 14.9681 12.1232 14.9696 12.4441C16.4274 12.3506 17.7359 11.8067 18.6335 10.9054C18.7704 10.768 18.9556 10.6923 19.1466 10.6923ZM31.7702 13.2125H31.772H31.7702Z"
        fill="#0D1321"
      />
    </svg>
  );
};

export default Independent;
