import withConfig from '../../utils/withConfig';
import PropTypes from 'prop-types';

class ScheduledTourMetricLimit {
  constructor() {
    this.low = 0;
    this.goal = 0;
    this.high = 0;
    this.lowest = 0;
  }

  setToursScheduledMetricLimit() {
    this.low = withConfig('TOUR_SCHEDULED_LOW_LIMIT');
    this.goal = withConfig('TOUR_SCHEDULED_GOAL');
    this.high = withConfig('TOUR_SCHEDULED_HIGH_LIMIT');
    this.lowest = withConfig('TOUR_SCHEDULED_LOWEST_LIMIT');
  }

  setSecondRoundMetricLimit() {
    this.low = withConfig('SECOND_ROUND_LOW_LIMIT');
    this.goal = withConfig('SECOND_ROUND_GOAL');
    this.high = withConfig('SECOND_ROUND_HIGH_LIMIT');
    this.lowest = withConfig('SECOND_ROUND_LOWEST_LIMIT');
  }
}

ScheduledTourMetricLimit.PropTypes = {
  low: PropTypes.number,
  goal: PropTypes.number,
  high: PropTypes.number,
  lowest: PropTypes.number,
};

export default ScheduledTourMetricLimit;
