import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    checkbox_container: {
      fontFamily: 'Poppins',
      '& span': {
        fontFamily: 'Poppins',
        fontSize: '15px',
      },
      '& .MuiTypography-body1': {
        color: theme.palette.dark.eerieBlack1,
      },
    },
    checkbox: {
      color: theme.palette.dark.eerieBlack1,
      '&.Mui-checked': {
        color: theme.palette.neutral.cosmicCobalt3,
      },
    },
    checkbox_disabled: {
      color: theme.palette.dark.eerieBlack1,
      '&.Mui-checked': {
        color: theme.palette.dark.disabled,
      },
    },
  };
});

export { useStyles };
