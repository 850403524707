import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from './CommunityForReferral.style';
import Typography from '../../common/Typography/Typography';
import Button from '../../common/Button/Button';
import TextInput from '../../common/TextInput/TextInput';
import CalendarDates from '../../common/Icons/time/CalendarDates';
import ScheduledTourInfo from '../../components/SavedCommunities/ScheduledTourInfo';
import { registerEvent } from '../../services/Analytics';
import tourScheduleService, {
  logRemoveTourTimeSelected,
} from '../../services/tourScheduleService';
import useGraphQLMutation from '../../hooks/useGraphQLMutation';
import scheduleTourMutation from '../Communities/CommunityInfo/Mutations/scheduleTourMutation';
import deleteTourMutation from '../Communities/CommunityInfo/Mutations/deleteTourMutation';
import Radio from '../../common/Radio/Radio';
import Tag from '../../common/Tag/Tag';
import Cross from '../../common/Icons/various/Cross';
import { isCtAppEnabled } from '../../config/ctAppCommunities';
import CheckBox from '../../common/Checkbox/Checkbox';
import { formatTourActivityDate } from '../../common/utils/dateFormat';
import updateScheduleTourMutation from '../Communities/CommunityInfo/Mutations/updateScheduleTourMutation';
import {
  getDate,
  getDateTime,
  getHourAndMinutes12HourFormat,
  getTimeOffsetFromSelectedDate,
} from '../../utils/dateFormat';
import TourStatusMessage from '../../components/TourStatusMessage/TourStatusMessage';

/**
 * @deprecated Being replaced by Tours v2 functionality.
 */
const CommunityForReferral = ({
  className,
  community,
  dataForAnalytics,
  disableScheduleTourForHomeCare,
  familyFileId,
  familyFileUserId,
  isForCancelFromUrl,
  isForRescheduleTour,
  labels,
  onChange,
  onSelectedTourTimeChange,
  onTourActionChange,
  onTourScheduleChange,
  selectedTourTime,
  tourDetails,
  currentUser,
  onTourStatusChange,
}) => {
  const classes = useStyles();
  const NO_TOUR_MODIFICATION = 'noTourModification';
  const CANCEL_TOUR = 'cancelTour';
  const CANCELED = 'Canceled';
  const COMPLETED = 'Completed';
  const RESCHEDULE_TOUR = 'rescheduleTour';
  const SCHEDULED = 'Scheduled';
  const RESCHEDULED = 'Rescheduled';
  const UNKNOWN = 'Unknown';

  const CANCELLATION_PART = ' has been canceled.';
  const RESCHEDULE_PART = 'Scheduled Tour has been rescheduled ';
  const emptyTourDetails = {
    scheduledTourDate: null,
    tourType: '',
    familyCommunityTourId: null,
    communityId: parseInt(community.id),
    currentStatus: null,
    tourActivities: [],
  };

  const [isForCancel, setIsForCancel] = useState(isForCancelFromUrl);

  const buildInitialTourInformation = () => {
    if (isForCancel) {
      return { value: CANCEL_TOUR };
    }
    if (isForRescheduleTour) {
      return { value: RESCHEDULE_TOUR };
    }
    return { value: NO_TOUR_MODIFICATION };
  };

  const [previousTourLabel, setPreviousSelectedTourLabel] = useState(null);
  const [selectedTourLabel, setSelectedTourLabel] = useState(null); // Scheduled Tour date/time changed
  const [isScheduledTourOpen, setIsScheduledTourOpen] = useState(false);
  const [familyCommunityTourId, setFamilyCommunityTourId] = useState(null);
  const [tourInfo, setTourInfo] = useState();
  const [extendedTourDetails, setExtendedTourDetails] = useState(
    tourDetails || emptyTourDetails,
  );
  const [isCanceledOrCompleted] = useState(
    tourDetails?.currentStatus === CANCELED ||
      tourDetails?.currentStatus === COMPLETED,
  );

  const [rescheduleTourInformation, setRescheduleTourInformation] = useState(
    buildInitialTourInformation(),
  );

  const [isSelectedTourTime, setIsSelectedTourTime] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(
    tourDetails?.currentStatus,
  );

  const [additionalMessage, setAdditionalMessage] = useState(
    community?.referralNote?.text || '',
  );

  const originalMessage = useRef(community?.referralNote?.text || '');

  const [
    displayRescheduleTourRadioButtons,
    setDisplayRescheduleTourRadioButtons,
  ] = useState();

  const [scheduleTour] = useGraphQLMutation(scheduleTourMutation);
  const [updateScheduleTour] = useGraphQLMutation(updateScheduleTourMutation);
  const [deleteScheduledTour] = useGraphQLMutation(deleteTourMutation);

  const modifyTourItems = [
    { label: labels.NO, value: NO_TOUR_MODIFICATION },
    { label: labels.CANCEL_TOUR, value: CANCEL_TOUR },
    { label: labels.RESCHEDULE_TOUR, value: RESCHEDULE_TOUR },
  ];

  useEffect(() => {
    if (tourInfo && currentStatus !== CANCELED && currentStatus !== COMPLETED) {
      setSelectedTourLabel(
        tourScheduleService.formatTourScheduleLabel(tourInfo),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourInfo]);

  useEffect(() => {
    onChange(additionalMessage, community);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalMessage]);

  useEffect(() => {
    if (tourDetails) {
      if (currentStatus === COMPLETED) {
        setTourInfo(tourScheduleService.toCompletedTourInfo(tourDetails));
      } else {
        setTourInfo(tourScheduleService.toTourInfo(tourDetails));
      }

      setFamilyCommunityTourId(tourDetails.familyCommunityTourId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourDetails]);

  useEffect(() => {
    if (isForCancel) {
      onTourActionChange(CANCEL_TOUR, community);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isForCancel]);

  useEffect(() => {
    setAdditionalMessage(getAdditionalMessage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTourLabel]);

  useEffect(() => {
    setAdditionalMessage(getAdditionalMessage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rescheduleTourInformation]);

  useEffect(() => {
    if (
      displayRescheduleTourRadioButtons === undefined ||
      displayRescheduleTourRadioButtons === null
    ) {
      const isReschedulingAllowed = tourScheduleService.isReschedulingAllowed(
        community.referralStatus,
        tourDetails?.scheduledTourDate,
        selectedTourTime,
        tourDetails?.currentStatus,
      );
      setDisplayRescheduleTourRadioButtons(isReschedulingAllowed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayRescheduleTourRadioButtons]);

  useEffect(() => {
    if (isCanceledOrCompleted) {
      setExtendedTourDetails({
        scheduledTourDate: null,
        tourType: '',
        familyCommunityTourId: tourDetails?.familyCommunityTourId,
        communityId: parseInt(community.id),
      });
      setDisplayRescheduleTourRadioButtons(false);
    }
    onTourStatusChange(currentStatus, parseInt(community.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatus]);

  const handleTourScheduleClick = () => {
    registerEvent('TourManagement', 'Schedule tour modal opened');
    const previousTourLabel = `${formatTourActivityDate(
      tourDetails?.scheduledTourDate,
      'MMM DD, YYYY (DDD) @ HH:mm a',
    )} - ${
      tourDetails?.tourType
        ? tourScheduleService.availableTourTypes[tourDetails?.tourType.trim()]
        : UNKNOWN
    }`;
    setPreviousSelectedTourLabel(previousTourLabel);
    setIsScheduledTourOpen(true);
  };

  const handleSave = ({ activityDate, selectedType }) => {
    let scheduleTourPromise;

    const timeOffset = getTimeOffsetFromSelectedDate(activityDate);

    const notes = community?.referralNote?.text;

    const referral = {
      communityId: parseInt(community.id),
      communityName: community.name,
      notes: notes || '',
      desiredPostalCode: community.zip,
      tourType: selectedType,
      tourDateTime: getDateTime(activityDate),
      isTourScheduled: true,
    };

    if (familyCommunityTourId) {
      scheduleTourPromise = updateScheduleTour({
        variables: {
          familyCommunityTourId,
          activityDate,
          tourType: selectedType,
          activityTypeCode: isCanceledOrCompleted ? SCHEDULED : RESCHEDULED,
          timeOffset,
          referral,
          userName: currentUser.username,
          userRole: currentUser.role,
        },
      });
    } else {
      scheduleTourPromise = scheduleTour({
        variables: {
          familyFileId,
          communityId: parseInt(community.id),
          activityDate,
          activityTypeCode: SCHEDULED,
          tourType: selectedType,
          timeOffset,
          referral,
          userName: currentUser.username,
          userRole: currentUser.role,
        },
      });
    }

    scheduleTourPromise
      .then((response) => {
        let tourScheduled;
        if (response.data.scheduleTour) {
          tourScheduled = response.data.scheduleTour;
        } else {
          tourScheduled = response.data.updateScheduledTour;
        }

        extendedTourDetails.scheduledTourDate = tourScheduled.activityDate;
        extendedTourDetails.tourType = tourScheduled.tourType;
        extendedTourDetails.familyCommunityTourId =
          tourScheduled.familyCommunityTourId;
        extendedTourDetails.communityId = tourScheduled.communityId;

        setExtendedTourDetails(extendedTourDetails);

        if (currentStatus === SCHEDULED) {
          setCurrentStatus(RESCHEDULED);
        } else {
          setCurrentStatus(SCHEDULED);
        }

        setTourInfo(
          tourScheduleService.toTourInfo({
            scheduledTourDate: tourScheduled.activityDate,
            tourType: tourScheduled.tourType,
            communityId: tourScheduled.communityId,
            familyCommunityTourId: tourScheduled.familyCommunityTourId,
          }),
        );

        setFamilyCommunityTourId(tourScheduled.familyCommunityTourId);

        if (selectedTourTime) {
          setIsSelectedTourTime(true);
          onSelectedTourTimeChange(true, community.id);
        }

        onTourScheduleChange(
          tourScheduled.activityDate,
          selectedType,
          community.id,
          true,
          tourScheduled.familyCommunityTourId,
        );
        setIsScheduledTourOpen(false);
      })
      .catch((error) => {
        console.error(
          `There was an error scheduling tour for familyFileId ${familyFileId}`,
          error,
        );
        setIsScheduledTourOpen(false);
      });
  };

  const removeScheduledTour = () => {
    createRemoveTourTimeSelectedAnalyticsEvents();

    const deleteScheduledTourPromise = deleteScheduledTour({
      variables: {
        familyCommunityTourId: parseInt(familyCommunityTourId),
      },
    });

    deleteScheduledTourPromise
      .then(() => {
        setSelectedTourLabel();
        setFamilyCommunityTourId();
        setExtendedTourDetails(emptyTourDetails);
        if (isSelectedTourTime) {
          setIsSelectedTourTime(false);
          onSelectedTourTimeChange(false, community.id);
        }
        onTourScheduleChange(
          extendedTourDetails.scheduledTourDate,
          extendedTourDetails.tourType,
          community.id,
          false,
        );
      })
      .catch((error) => {
        console.error(`There was an error removing scheduled tour`, error);
      });
  };
  const getTourType = () => {
    return tourScheduleService.availableTourTypes[tourInfo?.selectedType];
  };

  const getAdditionalMessage = () => {
    const isForCancel = rescheduleTourInformation.value === CANCEL_TOUR;
    const isForReschedule = rescheduleTourInformation.value === RESCHEDULE_TOUR;
    const noTourModification =
      rescheduleTourInformation.value === NO_TOUR_MODIFICATION;

    let message = community?.referralNote?.text || '';

    if (noTourModification) {
      if (message !== originalMessage.current && message !== '') {
        return message;
      }
      return originalMessage.current;
    }

    if (currentStatus === CANCELED) {
      return originalMessage.current;
    }

    if (
      currentStatus === COMPLETED &&
      (message.includes(CANCELLATION_PART) || message.includes(RESCHEDULE_PART))
    ) {
      return originalMessage.current;
    }

    if (isForReschedule) {
      message = originalMessage.current;
    }

    if (
      isForReschedule &&
      previousTourLabel &&
      selectedTourLabel !== previousTourLabel
    ) {
      message =
        labels.SCHEDULED_TOUR_HAS_BEEN_RESCHEDULED_V1.replace(
          '{prevDate}',
          previousTourLabel,
        ).replace('{newDate}', selectedTourLabel) +
        '\r\n' +
        originalMessage.current;
    }

    if (isForCancel) {
      const formattedDate = selectedTourLabel?.split(' - ')[0];
      const tourType = getTourType();
      message =
        labels.SCHEDULED_TOUR_HAS_BEEN_CANCELED.replace(
          '{tourType}',
          tourType,
        ).replace('{datetime}', formattedDate) +
        '\r\n' +
        originalMessage.current;
    }
    return message;
  };

  const displayTourInformation = () => {
    if (
      displayRescheduleTourRadioButtons ||
      currentStatus === CANCELED ||
      currentStatus === COMPLETED
    ) {
      return (
        <TourStatusMessage
          selectedTime={
            currentStatus === COMPLETED ? '' : tourInfo?.selectedTime
          }
          selectedDate={
            currentStatus === COMPLETED
              ? tourInfo?.completedDate
              : tourInfo?.selectedDate
          }
          status={tourDetails?.currentStatus}
          labels={labels}
          tourType={getTourType()}
        />
      );
    }
  };

  const handleTourActionChange = (item) => {
    onTourActionChange(item.value, community);

    setRescheduleTourInformation(item);
    setIsForCancel(item.value === CANCEL_TOUR);
  };

  const rescheduleTourRadioButtons = (
    <>
      <div className={classes.radio_buttons_container}>
        <Typography level="small" className={classes.modify_tour_label}>
          {labels.MODIFY_TOUR_DATE}
        </Typography>
        <Radio
          className={classes.modify_action_options}
          name="complete-options"
          items={modifyTourItems}
          selected={rescheduleTourInformation}
          onChange={handleTourActionChange}
        />
      </div>
    </>
  );
  const showReferralType = (community) => {
    if (
      community.referralStatus.isReferralStarted &&
      community.referralStatus.referralDate
    ) {
      return (
        <Typography
          className={classes.referral_type}
          color="cosmicCobalt3"
          level="small"
          bold
        >
          {labels.REFERRAL_UPDATE}
        </Typography>
      );
    }
    return (
      <Typography
        className={classes.referral_type}
        color="wintergreenDream2"
        level="small"
        bold
      >
        {labels.NEW_REFERRAL}
      </Typography>
    );
  };

  const shouldDisplayRemoveTour =
    selectedTourLabel &&
    rescheduleTourInformation?.value !== RESCHEDULE_TOUR &&
    currentStatus !== RESCHEDULED;

  const handleSelectedTourTimeChange = () => {
    setIsSelectedTourTime((value) => {
      return !value;
    });

    onSelectedTourTimeChange(!isSelectedTourTime, community.id);
  };

  const showScheduleButtonContainer = () => {
    if (
      (!isForRescheduleTour && !displayRescheduleTourRadioButtons) ||
      rescheduleTourInformation?.value === RESCHEDULE_TOUR
    ) {
      return (
        <div className={classes.button_container}>
          <Button
            type="outlined"
            onClick={handleTourScheduleClick}
            startIcon={<CalendarDates />}
            size="small"
            className={clsx(selectedTourLabel && classes.selected_tour_button)}
          >
            {selectedTourLabel ? selectedTourLabel : labels.SCHEDULE_TOUR}
          </Button>
          {shouldDisplayRemoveTour && (
            <div
              onClick={removeScheduledTour}
              className={classes.remove_tour_info_container}
            >
              <Typography
                underlined
                className={classes.remove_tour_time}
                color="smokyTopaz2"
                level="small"
              >
                {labels.REMOVE_TOUR_TIME}
              </Typography>
            </div>
          )}
        </div>
      );
    }

    return '';
  };

  const getTourAnalyticsDetails = () => {
    const selectedTourTypeText = tourScheduleService.getTourTypeLabel(
      extendedTourDetails.tourType,
    );
    const selectedTime = getHourAndMinutes12HourFormat(
      extendedTourDetails.scheduledTourDate,
      true,
    );
    const tourDateTime = `${getDate(
      extendedTourDetails.scheduledTourDate,
    )} ${selectedTime}`;

    return [selectedTourTypeText, tourDateTime];
  };

  const createRemoveTourTimeSelectedAnalyticsEvents = () => {
    registerEvent('TourManagement', 'Scheduled tour removed');

    const [selectedTourTypeText, tourDateTime] = getTourAnalyticsDetails();

    logRemoveTourTimeSelected(
      community.id,
      familyFileId,
      dataForAnalytics.originatingPage,
      tourDateTime,
      selectedTourTypeText,
      familyFileUserId,
    );
  };

  const shouldDisplayScheduleTourButton = () => {
    return isScheduledTourOpen && !disableScheduleTourForHomeCare;
  };

  return (
    <div
      className={clsx(classes.community_for_referral_container, className)}
      data-id={community.id}
    >
      <div className={classes.header}>
        <div className={classes.header_container}>
          <Typography bold>{community.name}</Typography>
          {isCtAppEnabled(community.id) ? (
            <Tag
              key="1"
              text={labels.CT_APP_ENABLED}
              color="cosmicCobalt3"
              icon={<Cross />}
              className={classes.tag_margin}
            />
          ) : (
            <div />
          )}
        </div>
        {displayTourInformation()}
      </div>
      <div className={classes.additional_container}>
        <div className={classes.column}>
          {showReferralType(community)}
          <TextInput
            id={`additional-message-${community.id}`}
            multiline
            maxLength={2500}
            value={additionalMessage}
            rows={3}
            onChange={setAdditionalMessage}
            label={labels.ADDITIONAL_MESSAGE_SPECIFIC_TO_THIS_COMMUNITY}
            className={classes.additional_message}
          />
        </div>
        {!disableScheduleTourForHomeCare && !selectedTourTime && (
          <div
            className={clsx(
              classes.tour_schedule_column,
              !isForRescheduleTour && classes.tour_schedule_container,
            )}
          >
            <div className={classes.scheduled_tour_button_container}>
              {displayRescheduleTourRadioButtons && rescheduleTourRadioButtons}

              {showScheduleButtonContainer()}
            </div>
          </div>
        )}
        {selectedTourTime &&
          !disableScheduleTourForHomeCare &&
          currentStatus !== COMPLETED && (
            <div
              className={clsx(
                classes.tour_schedule_column,
                classes.tour_schedule_container,
              )}
            >
              <div className={classes.scheduled_tour_button_container}>
                <div className={classes.selected_tour_time_container}>
                  <CheckBox
                    checked={
                      isSelectedTourTime &&
                      selectedTourLabel !== null &&
                      selectedTourLabel !== undefined
                    }
                    label={labels.INCLUDE_SELECTED_TOUR_TIME}
                    onChange={handleSelectedTourTimeChange}
                    disabled={!selectedTourLabel}
                  />
                  <Typography level="small">
                    {labels.SELECTED_TOUR_TIME}
                  </Typography>
                </div>
                {showScheduleButtonContainer()}
              </div>
            </div>
          )}
        {shouldDisplayScheduleTourButton() && (
          <ScheduledTourInfo
            address={community.address}
            city={community.city}
            dataForAnalytics={{
              careTypes: community.careTypes,
              originatingPage: 'referral alert',
            }}
            familyFileId={familyFileId}
            familyFileUserId={familyFileUserId}
            isCtAppEnabled={isCtAppEnabled(community.id)}
            isOpen={isScheduledTourOpen}
            labels={labels}
            name={community.name}
            onClose={() => setIsScheduledTourOpen(false)}
            onSave={handleSave}
            phone={community.phone}
            state={community.state}
            tourDetails={extendedTourDetails}
            zip={community.zip}
            isPastDisabled={true}
          />
        )}
      </div>
    </div>
  );
};

CommunityForReferral.propTypes = {
  className: PropTypes.string,
  community: PropTypes.object,
  dataForAnalytics: PropTypes.shape({
    originatingPage: PropTypes.string,
  }),
  disableScheduleTourForHomeCare: PropTypes.bool,
  familyFileId: PropTypes.number,
  familyFileUserId: PropTypes.number,
  isForCancelFromUrl: PropTypes.bool,
  isForRescheduleTour: PropTypes.bool,
  labels: PropTypes.object,
  onChange: PropTypes.func,
  onSelectedTourTimeChange: PropTypes.func,
  onTourActionChange: PropTypes.func,
  onTourScheduleChange: PropTypes.func,
  selectedTourTime: PropTypes.bool,
  tourDetails: PropTypes.object,
  currentUser: PropTypes.object,
  onTourStatusChange: PropTypes.func,
};

CommunityForReferral.defaults = {};

export default CommunityForReferral;
