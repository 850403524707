const formatPhone = (phone) => {
  return phone.replace(/(\d{3})[\s-]*(\d{3})[\s-]*(\d*)/g, '$1 - $2 - $3');
};

const formatPhoneWithParenthesis = (phone, condensed = false) => {
  const phoneWithoutParenthesis = phone.replace(/[()]/g, '');
  return phoneWithoutParenthesis.replace(
    /(\d{3})[\s-]*(\d{3})[\s-]*(\d*)/g,
    condensed ? '($1) $2-$3' : '($1) $2 - $3',
  );
};

const formatPhoneWithoutPlusOne = (phone) => {
  return phone.replace(/[+]\d/g, '');
};

export { formatPhone, formatPhoneWithParenthesis, formatPhoneWithoutPlusOne };
