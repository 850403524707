import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from './Button.style';
import MaterialButton from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const Button = ({
  type,
  className,
  size,
  children,
  onClick,
  startIcon,
  endIcon,
  openInNewTab,
  to,
  testId,
}) => {
  const classes = useStyles();
  const isDisabled = type.toLowerCase().includes('disabled');
  const handleClick = (event) => {
    if (!isDisabled && onClick) {
      onClick(event);
    }
  };
  const additionalProps = to
    ? { to, component: Link, target: openInNewTab ? '_blank' : '' }
    : {};
  if (testId) additionalProps['data-testid'] = testId;
  return (
    <MaterialButton
      disabled={isDisabled}
      className={clsx(className, classes.button, classes[type], classes[size])}
      onClick={handleClick}
      startIcon={startIcon}
      endIcon={endIcon}
      {...additionalProps}
    >
      {children}
    </MaterialButton>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'light',
    'disabled',
    'outlined',
    'outlinedWarning',
    'warning',
    'outlinedDisabled',
    'misc',
  ]),
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large']),
  onClick: PropTypes.func,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  to: PropTypes.string,
  openInNewTab: PropTypes.bool,
  testId: PropTypes.string,
};

Button.defaultProps = {
  type: 'primary',
  size: 'medium',
  testId: null,
};

export default Button;
