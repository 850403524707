import gql from 'graphql-tag';

export default gql`
  query($oneId: String) {
    findFamilyFileDuplicatedResidents(params: { oneId: $oneId }) {
      externalId
      familyFileId
      residentFirstName
      residentLastName
      relationToResident
      contactFirstName
      contactLastName
      location
      oneId
      sla
      familyFileCreation
      familyFileStatus
      reasonForClose
      salesPhase
      budget
      referredActivityDate
      userRoleId
      isActive
      phoneProviderUsername
    }
  }
`;
