import React from 'react';
import PropTypes from 'prop-types';

const StartingPoint = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.4 21.6624H29.6V45.7565C29.6 46.5881 28.8836 47.2624 28 47.2624C27.1164 47.2624 26.4 46.5881 26.4 45.7565V21.6624Z"
        fill="#0D1321"
      />
      <path
        d="M28 22C32.9707 22 37 17.9707 37 13C37 8.02926 32.9707 4 28 4C23.0293 4 19 8.02926 19 13C19 17.9707 23.0293 22 28 22Z"
        fill="#E1737D"
        stroke="#A03E47"
        strokeWidth="2"
      />
    </svg>
  );
};

StartingPoint.propTypes = {
  className: PropTypes.string,
};

export default StartingPoint;
