import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  modal_container: {
    width: '1000px',
  },
  modal_body: {
    maxHeight: '68vh',
    overflow: 'auto',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '40px 10px',
    padding: theme.spacing(3),
  },
  modal_footer: {
    borderTop: `1px solid ${theme.palette.light.platinum1}`,
    display: 'flex',
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(3),
    },
  },
  home_table_header: {
    '& $icon svg': {
      fontSize: '15px',
    },
    height: '20px',
    alignItems: 'center',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.light.platinum5,
    },
    padding: '12px',
  },
  home_table: {
    marginTop: '12px',
    height: 'calc(100vh -20px)',
  },
  home_table_row: {
    verticalAlign: 'top',
  },
  snapshot_link: {
    color: 'black',
    textDecoration: 'none',
  },
}));

export { useStyles };
