import { put, takeLeading } from 'redux-saga/effects';
import graphqlClient from 'stateManagement/graphqlClient';
import {
  getAdminCatalogsError,
  getAdminCatalogsSuccess,
  GET_ADMIN_CATALOGS_REQUEST,
} from '../actions/adminCatalogsActions';

import findFormEnumQuery from '../graphql/findFormEnumQuery';

const DISPOSITIONS_ENUM_ID = 1;
const CLOSE_REASONS_ID = 2;

const getAdminCatalog = (formEnumFieldId) => {
  return graphqlClient
    .makeGraphqlRequest({
      query: findFormEnumQuery,
      operationName: 'findFormEnumValuesById',
      variables: {
        formEnumFieldId,
      },
    })
    .then((response) => response.data.data.findFormEnumValuesById);
};

const mapToCatalog = (response) => {
  return response.map((item) => ({
    key: item.formEnumFieldValueKey,
    value: item.formEnumFieldValueValue,
    label: item.formEnumFieldValueLabel,
    order: item.formEnumFieldValueOrder,
  }));
};

function* getAdminCatalogsSaga() {
  try {
    const dispositions = yield getAdminCatalog(DISPOSITIONS_ENUM_ID);
    const closeReasons = yield getAdminCatalog(CLOSE_REASONS_ID);

    const catalog = {
      dispositions: mapToCatalog(dispositions),
      closeReasons: mapToCatalog(closeReasons),
    };

    yield put(getAdminCatalogsSuccess(catalog));
  } catch (err) {
    yield put(getAdminCatalogsError(err));
  }
}

function* watchAdminCatalogs() {
  yield takeLeading(GET_ADMIN_CATALOGS_REQUEST, getAdminCatalogsSaga);
}

export { watchAdminCatalogs, getAdminCatalogsSaga };
