import React, { Fragment } from 'react';
import Typography from '../../common/Typography/Typography';

const ActionList = () => {
  return (
    <Fragment>
      <Typography level="h1">Welcome to the Action List</Typography>
    </Fragment>
  );
};

export default ActionList;
