import React from 'react';
import PropTypes from 'prop-types';

import Media from 'common/Media/Media';
import CommunitiesTableXS from './CommunitiesTableXS';
import CommunitiesTableSM from './CommunitiesTableSM';
import CommunitiesTableMD from './CommunitiesTableMD';
import CommunitiesTableLG from './CommunitiesTableLG';

const CommunitiesTable = ({
  labels,
  locationFilter,
  distanceFilter,
  familyFileId,
  familyFileUserId,
  familyFileSalesPhase,
  onSave,
  onRemove,
  onMapChange,
  savedCommunities,
  referredCommunities,
  dataForSegment,
  attributeNamesFilter,
  groupNamesFilter,
  otherFilters,
  onRemoveFilters,
  onRemoveSingleFilter,
  careTypeFilter,
  careTypeFilterWithLabels,
  budgetFilter,
  communitySearchUiFromYgl,
  onAppError,
}) => {
  return (
    <Media
      xs={<CommunitiesTableXS labels={labels} />}
      sm={
        <CommunitiesTableSM
          labels={labels}
          careTypeFilter={careTypeFilter}
          locationFilter={locationFilter}
          distanceFilter={distanceFilter}
          familyFileId={familyFileId}
          familyFileSalesPhase={familyFileSalesPhase}
          onSave={onSave}
          onRemove={onRemove}
          savedCommunities={savedCommunities}
          referredCommunities={referredCommunities}
          otherFilters={otherFilters}
          attributeNamesFilter={attributeNamesFilter}
          groupNamesFilter={groupNamesFilter}
          onRemoveFilters={onRemoveFilters}
          onRemoveSingleFilter={onRemoveSingleFilter}
          budgetFilter={budgetFilter}
          dataForSegment={dataForSegment}
          onAppError={onAppError}
        />
      }
      md={
        <CommunitiesTableMD
          labels={labels}
          careTypeFilter={careTypeFilter}
          locationFilter={locationFilter}
          distanceFilter={distanceFilter}
          familyFileId={familyFileId}
          familyFileUserId={familyFileUserId}
          familyFileSalesPhase={familyFileSalesPhase}
          onSave={onSave}
          onRemove={onRemove}
          savedCommunities={savedCommunities}
          referredCommunities={referredCommunities}
          otherFilters={otherFilters}
          attributeNamesFilter={attributeNamesFilter}
          groupNamesFilter={groupNamesFilter}
          onRemoveFilters={onRemoveFilters}
          onRemoveSingleFilter={onRemoveSingleFilter}
          budgetFilter={budgetFilter}
          dataForSegment={dataForSegment}
          communitySearchUiFromYgl={communitySearchUiFromYgl}
          onAppError={onAppError}
        />
      }
      lg={
        <CommunitiesTableLG
          labels={labels}
          careTypeFilter={careTypeFilter}
          careTypeFilterWithLabels={careTypeFilterWithLabels}
          locationFilter={locationFilter}
          distanceFilter={distanceFilter}
          familyFileId={familyFileId}
          familyFileUserId={familyFileUserId}
          familyFileSalesPhase={familyFileSalesPhase}
          onSave={onSave}
          onRemove={onRemove}
          onMapChange={onMapChange}
          savedCommunities={savedCommunities}
          referredCommunities={referredCommunities}
          otherFilters={otherFilters}
          attributeNamesFilter={attributeNamesFilter}
          groupNamesFilter={groupNamesFilter}
          onRemoveFilters={onRemoveFilters}
          onRemoveSingleFilter={onRemoveSingleFilter}
          budgetFilter={budgetFilter}
          dataForSegment={dataForSegment}
          communitySearchUiFromYgl={communitySearchUiFromYgl}
          onAppError={onAppError}
        />
      }
    />
  );
};

CommunitiesTable.propTypes = {
  locationFilter: PropTypes.object,
  familyFileId: PropTypes.number,
  familyFileUserId: PropTypes.number,
  familyFileSalesPhase: PropTypes.string,
  distanceFilter: PropTypes.string,
  labels: PropTypes.object.isRequired,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
  savedCommunities: PropTypes.array,
  referredCommunities: PropTypes.array,
  onMapChange: PropTypes.func,
  dataForSegment: PropTypes.shape({
    familyFileId: PropTypes.number,
    oneId: PropTypes.string,
  }),
  attributeNamesFilter: PropTypes.array,
  groupNamesFilter: PropTypes.array,
  otherFilters: PropTypes.array,
  onRemoveFilters: PropTypes.func,
  onRemoveSingleFilter: PropTypes.func,
  careTypeFilter: PropTypes.array,
  careTypeFilterWithLabels: PropTypes.array,
  budgetFilter: PropTypes.object,
  communitySearchUiFromYgl: PropTypes.bool,
  onAppError: PropTypes.func,
};

export default CommunitiesTable;
