import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  inline: {
    display: 'inline-block',
  },
  percentage_row_wrapper: {
    display: 'flex',
    alignItems: 'baseline',
    '& svg': {
      paddingRight: theme.spacing(1),
    },
  },
  goal_container: {
    height: theme.spacing(7),
  },
  goal_container_bellow_small_size: {
    marginTop: theme.spacing(1),
  },
  current_message: {
    marginLeft: theme.spacing(1),
  },
}));

export { useStyles };
