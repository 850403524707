import gql from 'graphql-tag';
import { Community } from '../../queries/communityQueryFragments';

export default gql`
  query($familyFileId: Int) {
    findFamilyFileById(
      id: $familyFileId
      filters: { withoutToursDateRangeLimit: true }
    ) {
      familyFileId
      oneId
      userId
      familyDesiredLocations {
        city
        state
        zip
      }
      referralStatus {
        communityId
        isReferralStarted
        referralDate
        communityStatus
        isRejected
      }
      communities {
        id
        name
        address
        address2
        city
        state
        zip
        phoneNumber
        organizationName
        primaryContact
        ...CommunityCareTypes
        roomCharges {
          careType
          amount
          apartmentType {
            description
          }
        }
        roomFees {
          amount
          careType
          feeType {
            name
          }
        }
        geoLocation {
          lat
          lon
        }
        title
        images {
          isDefault
          assets {
            id
            sizeId
          }
        }
      }
      tourDetailsExtended {
        familyCommunityTourId
        communityId
        scheduledTourDate
        tourType
      }
      tourDrafts {
        communityId
        familyCommunityTourDraftId
        activityDate
        tourType
      }
    }
  }
  ${Community.fragments.careTypes}
`;
