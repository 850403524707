import React from 'react';
import PropTypes from 'prop-types';

const Coffee = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.35962 3.04466L4.82916 2.10557C5.16795 1.428 5.86048 1 6.61802 1H17.382C18.1395 1 18.832 1.428 19.1708 2.10557L19.6404 3.04466C20.3736 3.20235 20.9718 3.76416 21.1595 4.51493L21.6595 6.51493C21.9274 7.58652 21.2759 8.67239 20.2043 8.94029C20.0939 8.96789 19.9814 8.98599 19.8681 8.99445L17.8673 23H6.13269L4.13191 8.99454C3.09686 8.91839 2.28076 8.0545 2.28076 7C2.28076 6.83648 2.30082 6.67357 2.34048 6.51493L2.84048 4.51493C3.02817 3.76416 3.62641 3.20235 4.35962 3.04466ZM6.153 9H17.847L16.1327 21H7.86728L6.153 9ZM4.78076 5H5.61802L6.61802 3H17.382L18.382 5H19.2192L19.7192 7H4.28076L4.78076 5Z"
        fill="currentColor"
      />
    </svg>
  );
};

Coffee.propTypes = {
  className: PropTypes.string,
};

export default Coffee;
