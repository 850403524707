import gql from 'graphql-tag';

export default gql`
  mutation upsertFamilyFileCommunityPreferences(
    $familyFileId: Int!
    $locationRange: Int
    $roomPreference: [String]
    $oneId: String
  ) {
    upsertFamilyFileCommunityPreferences(
      input: {
        familyFileId: $familyFileId
        locationRange: $locationRange
        roomPreference: $roomPreference
        oneId: $oneId
      }
    ) {
      locationRange
      roomPreference
    }
  }
`;
