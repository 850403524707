import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useStyles } from './ScriptSnapshotsDialog.style';
import TableHead from 'common/Table/TableHead/TableHead';
import TableRow from 'common/Table/TableRow/TableRow';
import TableCell from 'common/Table/TableCell/TableCell';
import TableBody from 'common/Table/TableBody/TableBody';
import Typography from 'common/Typography/Typography';
import Table from 'common/Table/Table/Table';
import Button from 'common/Button/Button';
import Modal from 'common/Modal/Modal';
import TimeHistory from 'common/Icons/time/TimeHistory';
import { useDispatch, useSelector } from 'react-redux';
import { selectSnapshots } from 'stateManagement/admin/scriptBuilder/reducers/snapshotsBuilderReducer';
import {
  cancelPendingSnapshotRequests,
  getSnapshotsSuccess,
} from 'stateManagement/admin/scriptBuilder/actions/scriptBuilderActions';
import { getReadableDate } from 'utils/dateFormat';
import Link from 'common/Link/Link';

const ScriptSnapshotsDialog = ({
  onClose,
  open,
  labels,
  onViewSnapshotHistory,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { snapshots } = useSelector(selectSnapshots);

  const [snapshotsToShow, setSnapshotsToShow] = useState(
    snapshots ? snapshots : [],
  );

  useEffect(() => {
    if (snapshots) {
      setSnapshotsToShow(snapshots);
    }
  }, [snapshots, setSnapshotsToShow]);

  const onCloseModal = () => {
    setSnapshotsToShow([]);
    dispatch(cancelPendingSnapshotRequests());
    dispatch(getSnapshotsSuccess([]));
    onClose();
  };

  const getSnapshotInfo = (snapshot) => {
    return snapshot.length > 100
      ? snapshot.substring(0, 100) + ' ...'
      : snapshot;
  };

  const onClickViewHistory = (snapshot) => {
    const data = JSON.parse(snapshot);
    onViewSnapshotHistory(data);
  };

  const bodyComponent = (
    <Fragment>
      <div>
        <Table className={classes.home_table}>
          <TableHead className={classes.home_table_header}>
            <TableRow className={classes.home_table_header_row} size="medium">
              <TableCell>
                <Typography bold>{labels.WHEN}</Typography>
              </TableCell>
              <TableCell>
                <Typography bold>{labels.USER}</Typography>
              </TableCell>
              <TableCell>
                <Typography bold>{labels.REASON}</Typography>
              </TableCell>
              <TableCell>
                <Typography bold>{labels.SNAPSHOT}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {snapshotsToShow?.map((row, index) => (
              <TableRow
                key={index}
                className={classes.home_table_row}
                size="medium"
              >
                <TableCell>
                  <div>{getReadableDate(row.createdAt)}</div>
                </TableCell>
                <TableCell>
                  <div>
                    {`${row.createdBy.firstName} ${row.createdBy.lastName}`}
                  </div>
                </TableCell>
                <TableCell>
                  <div>{row.reason}</div>
                </TableCell>
                <TableCell>
                  <Link
                    className={classes.snapshot_link}
                    onClick={() => onClickViewHistory(row.snapshot)}
                  >
                    {getSnapshotInfo(row.snapshot)}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Fragment>
  );

  return (
    <Modal
      className={classes.modal_container}
      icon={<TimeHistory />}
      title={labels.SCRIPT_SNAPSHOTS}
      isOpen={open}
      noBodyScroll={true}
      noBodyPadding={true}
      onClose={() => onCloseModal()}
    >
      <div className={classes.modal_body}>{bodyComponent}</div>
      <div className={classes.modal_footer}>
        <Button onClick={() => onCloseModal()} style={{ marginLeft: 10 }}>
          {labels.OK}
        </Button>
      </div>
    </Modal>
  );
};

ScriptSnapshotsDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  labels: PropTypes.object,
  onViewSnapshotHistory: PropTypes.func,
};

export default ScriptSnapshotsDialog;
