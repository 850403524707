import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    custom_select: {
      position: 'relative',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: '22px',
    },
    select: {
      border: `1px solid ${theme.palette.light.platinum1}`,
      display: 'flex',
      backgroundColor: theme.palette.light.white,
      boxSizing: 'border-box',
      borderRadius: '2px',
      padding: '8px 16px',
      cursor: 'pointer',
      width: '100%',
      textAlign: 'left',
      color: theme.palette.dark.eerieBlack5,
      '& div:first-child': {
        verticalAlign: 'middle',
        display: 'inline',
        overflow: 'hidden',
        width: '90%',
        whiteSpace: 'nowrap',
        color: theme.palette.neutral.cosmicCobalt3,
      },
      '&:focus': {
        border: `1px solid ${theme.palette.neutral.cosmicCobalt3}`,
        outline: 'none',
        boxShadow: `0px 2px 4px ${theme.palette.light.boxShadow}`,
      },
    },
    filled: {
      '& $select': {
        border: `1px solid ${theme.palette.neutral.cosmicCobalt3}`,
        outline: 'none',
      },
      '&$textual $select': {
        color: theme.palette.neutral.cosmicCobalt3,
      },
    },
    icon: {
      position: 'absolute',
      right: '16px',
      width: '6%',
      '& svg': {
        verticalAlign: 'middle',
      },
    },
    menu: {
      position: 'absolute',
      backgroundColor: theme.palette.light.white,
      width: '100%',
      zIndex: 999,
      overflow: 'auto',
      maxHeight: '200px',
    },
    items_container: {
      border: `1px solid ${theme.palette.neutral.cosmicCobalt5}`,
      textAlign: 'left',
      '& div:first-child': {
        padding: '9px 16px',
      },
      '& div': {
        padding: '9px 16px 9px 30px',
        color: theme.palette.dark.eerieBlack1,
        '&:hover': {
          backgroundColor: theme.palette.neutral.dropdown,
          color: theme.palette.dark.eerieBlack1,
        },
      },
      '& $group': {
        padding: '20px 16px 9px 16px',
        pointerEvents: 'none',
        color: `${theme.palette.dark.eerieBlack5}`,
        fontSize: '13px',
      },
    },
    group: {},
    selected: {
      backgroundColor: theme.palette.neutral.cosmicCobalt5,
      color: theme.palette.neutral.cosmicCobalt2,
    },
    label: {
      fontSize: '13px',
      lineHeight: '19px',
      color: theme.palette.dark.eerieBlack5,
      margin: '4px',
      textAlign: 'left',
      width: 'max-content',
    },
    text_hint: {
      fontSize: '13px',
      lineHeight: '19px',
      color: theme.palette.dark.eerieBlack5,
      margin: '4px',
      textAlign: 'left',
    },
    error: {
      '& $select': {
        border: `1px solid ${theme.palette.correction.smokyTopaz2}`,
      },
      '& $label, $text_hint': {
        color: theme.palette.correction.smokyTopaz2,
      },
    },
    disabled: {
      '& $select': {
        backgroundColor: theme.palette.light.platinum4,
        border: `1px solid ${theme.palette.light.platinum1}`,
        color: theme.palette.dark.eerieBlack5,
        cursor: 'default',
        '& svg': {
          color: theme.palette.dark.disabled,
        },
        '&:focus': {
          border: `1px solid ${theme.palette.light.platinum1}`,
          outline: 'none',
          boxShadow: 'none',
        },
      },
    },
    buttoned: {
      '& $select': {
        backgroundColor: theme.palette.neutral.cosmicCobalt3,
        color: theme.palette.light.platinum5,
        '& svg': {
          fill: theme.palette.light.platinum5,
        },
      },
    },
    textual: {
      '& $select': {
        border: '1px solid transparent',
        backgroundColor: 'transparent',
        '& svg': {
          fill: theme.palette.neutral.cosmicCobalt3,
          color: theme.palette.neutral.cosmicCobalt3,
        },
        '&:focus': {
          border: '1px solid transparent',
          outline: 'none',
          boxShadow: 'none',
        },
      },
    },
    mandatory: {
      color: theme.palette.correction.smokyTopaz2,
      marginLeft: 4,
    },
  };
});
export { useStyles };
