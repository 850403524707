import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  close_button: {
    position: 'fixed',
    boxSizing: 'border-box',
    top: '10px',
    right: '470px',
    width: '40px',
    height: '40px',
    display: 'flex',
    padding: '4px',
    borderRadius: '2px',
    fontSize: '24px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.light.platinum5,
    boxShadow: '0px 2px 4px rgba(13, 19, 22, 0.3)',
    '&:hover': {
      boxShadow: '0px 0px 8px rgba(13, 19, 33, 0.5)',
    },
    zIndex: 11,
  },
}));

export { useStyles };
