import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  community_price_range_container: (careTypeStyle) => {
    if (careTypeStyle === 'tag') {
      return {
        alignItems: 'flex-end',
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        minWidth: 'fit-content',
        marginLeft: '2px',
      };
    }
    return {
      margin: '8px 18px 0 24px',
    };
  },
  price_range_link_container: (careTypeStyle) => {
    let style = {
      display: 'flex',
      height: '100%',
      width: '100%',
    };
    if (careTypeStyle === 'tag') {
      style.alignItems = 'flex-end';
      style.flexDirection = 'column';
    }
    return style;
  },
  price_range_rates: (careTypeStyle) => {
    if (careTypeStyle === 'tag') {
      return { marginBottom: '8px' };
    }
  },
  link_container: (careTypeStyle) => {
    let style = {
      flex: '1',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    };
    if (careTypeStyle === 'tag') {
      style.marginTop = '16px';
    }
    return style;
  },
  links: {
    display: 'flex',
    alignItems: 'center',
  },

  care_type: (careTypeStyle) => {
    let style = {
      alignSelf: 'flex-start',
      backgroundColor: theme.palette.care.regalia5,
      borderRadius: '50%',
      boxSizing: 'border-box',
      color: theme.palette.dark.eerieBlack1,
      fontSize: '12px',
      height: '20px',
      lineHeight: '19px',
      marginRight: '8px',
      textAlign: 'center',
      width: '20px',
    };
    if (careTypeStyle === 'map') {
      style.paddingTop = '1px';
    }
    return style;
  },
  tag: {
    marginTop: '10px',
    '&:first-of-type': {
      marginTop: '0',
    },
  },
  tag_container: {
    display: 'flex',
    alignItems: 'center',
  },
  link_divider: {
    margin: '0',
    transform: 'rotate(-90deg)',
    width: '12px',
  },

  modal_body: {
    width: '100%',
    '& tr$section_title:first-child': {
      '& td': {
        padding: '8px 0 8px 0',
      },
    },
    '& tr$section_title': {
      borderBottom: 'none',
      '&:hover': {
        backgroundColor: 'inherit',
      },
      '& td': {
        padding: '16px 0 8px 0',
      },
    },
    '& tr$section_header': {
      borderBottom: `1px solid ${theme.palette.dark.disabled}`,
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
  },

  column_header: {
    display: 'flex',
    alignItems: 'center',
  },
  care_type_tag: {
    borderRadius: '50%',
    backgroundColor: theme.palette.care.regalia5,
    padding: '4px',
    width: '28px',
    height: '28px',
    boxSizing: 'border-box',
    textAlign: 'center',
    marginRight: '8px',
  },
  section_title: {},
  section_header: {},

  homeCareFee: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      color: theme.palette.care.regalia2,
      fill: theme.palette.care.regalia2,
      marginRight: theme.spacing(1),
    },
  },
}));

export { useStyles };
