import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../common/Typography/Typography';
import { useStyles } from './SurpassingPoint.style';
import clsx from 'clsx';
import isSameSize from '../../utils/sizesHelper';

const SurpassingPoint = ({ points, onClick, size, labels }) => {
  const classes = useStyles();

  const useBetweenSMAndMDClass =
    isSameSize(size, 'sm') || isSameSize(size, 'md');

  const selectContainerClasses = clsx([
    classes.surpassing_point_container,
    useBetweenSMAndMDClass
      ? classes.surpassing_point_container_bellow_medium_size
      : '',
  ]);

  return (
    <div className={selectContainerClasses} onClick={onClick}>
      <Typography color="platinum5" level="h2" className={classes.header}>
        {points}
      </Typography>
      <Typography color="platinum5" level="small" className={classes.body}>
        {labels.POINTS_MTD}
      </Typography>
      <Typography color="platinum5" level="tiny" className={classes.footer}>
        <span>{labels.VIEW_MORE}</span>
      </Typography>
    </div>
  );
};

SurpassingPoint.propTypes = {
  points: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
};
export default SurpassingPoint;
