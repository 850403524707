import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => {
  const colorMap = {
    eerieBlack1: `${theme.palette.dark.eerieBlack1}`,
    eerieBlack2: `${theme.palette.dark.eerieBlack2}`,
    eerieBlack3: `${theme.palette.dark.eerieBlack3}`,
    eerieBlack4: `${theme.palette.dark.eerieBlack4}`,
    eerieBlack5: `${theme.palette.dark.eerieBlack5}`,
    disabled: `${theme.palette.dark.disabled}`,
    divider: `${theme.palette.light.divider}`,
    platinum1: `${theme.palette.light.platinum1}`,
    platinum2: `${theme.palette.light.platinum2}`,
    platinum3: `${theme.palette.light.platinum3}`,
    platinum4: `${theme.palette.light.platinum4}`,
    platinum5: `${theme.palette.light.platinum5}`,
    white: `${theme.palette.light.white}`,
    cosmicCobalt1: `${theme.palette.neutral.cosmicCobalt1}`,
    cosmicCobalt2: `${theme.palette.neutral.cosmicCobalt2}`,
    cosmicCobalt3: `${theme.palette.neutral.cosmicCobalt3}`,
    cosmicCobalt4: `${theme.palette.neutral.cosmicCobalt4}`,
    cosmicCobalt5: `${theme.palette.neutral.cosmicCobalt5}`,
    regalia1: `${theme.palette.care.regalia1}`,
    regalia2: `${theme.palette.care.regalia2}`,
    regalia3: `${theme.palette.care.regalia3}`,
    regalia4: `${theme.palette.care.regalia4}`,
    regalia5: `${theme.palette.care.regalia5}`,
    wintergreenDream1: `${theme.palette.positive.wintergreenDream1}`,
    wintergreenDream2: `${theme.palette.positive.wintergreenDream2}`,
    wintergreenDream3: `${theme.palette.positive.wintergreenDream3}`,
    wintergreenDream4: `${theme.palette.positive.wintergreenDream4}`,
    wintergreenDream5: `${theme.palette.positive.wintergreenDream5}`,
    smokyTopaz1: `${theme.palette.correction.smokyTopaz1}`,
    smokyTopaz2: `${theme.palette.correction.smokyTopaz2}`,
    smokyTopaz3: `${theme.palette.correction.smokyTopaz3}`,
    smokyTopaz4: `${theme.palette.correction.smokyTopaz4}`,
    smokyTopaz5: `${theme.palette.correction.smokyTopaz5}`,
    hotCinnamon1: `${theme.palette.miscellaneous1.hotCinnamon1}`,
    hotCinnamon2: `${theme.palette.miscellaneous1.hotCinnamon2}`,
    hotCinnamon3: `${theme.palette.miscellaneous1.hotCinnamon3}`,
    hotCinnamon4: `${theme.palette.miscellaneous1.hotCinnamon4}`,
    hotCinnamon5: `${theme.palette.miscellaneous1.hotCinnamon5}`,
    fieldDrab1: `${theme.palette.miscellaneous2.fieldDrab1}`,
    fieldDrab2: `${theme.palette.miscellaneous2.fieldDrab2}`,
    fieldDrab3: `${theme.palette.miscellaneous2.fieldDrab3}`,
    fieldDrab4: `${theme.palette.miscellaneous2.fieldDrab4}`,
    fieldDrab5: `${theme.palette.miscellaneous2.fieldDrab5}`,
  };
  const headerHeight = '135px';

  return {
    side_panel_container: {
      color: theme.palette.dark.eerieBlack1,
      '& .MuiDrawer-paper': {
        backgroundColor: (dynamicStyles) =>
          colorMap[dynamicStyles.backgroundColor],
        width: (dynamicStyles) => `${dynamicStyles.width}px`,
        overflow: 'hidden',
        top: (dynamicStyles) =>
          dynamicStyles.belowHeader ? headerHeight : '0',
      },
      '& .MuiBackdrop-root': {
        top: (dynamicStyles) =>
          dynamicStyles.belowHeader ? headerHeight : '0',
      },
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '22px 25px 0 32px',
      alignItems: 'center',
      '& div': {
        cursor: 'pointer',
        zIndex: 99,
        '& svg': {
          color: (dynamicStyles) => colorMap[dynamicStyles.xColor],
        },
      },
    },
  };
});

export { useStyles };
