import gql from 'graphql-tag';
export default gql`
  mutation($inquiryId: Int!, $phone: String!, $list: String!) {
    createQueuedCallWithClosedInquiry(
      input: { inquiryId: $inquiryId, phone: $phone, list: $list }
    ) {
      queuedCallWithClosedInquiryId
      inquiryId
      phone
      createdAt
      list
    }
  }
`;
