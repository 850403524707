import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => {
  return {
    buttons_container: {
      marginTop: '0.5rem',
      marginBottom: '0',
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'space-between',
    },
    button: {
      marginBottom: '0.5rem',
      '&:not(:last-child)': {
        marginRight: '0.5rem',
      },
    },
  };
});
export { useStyles };
