import React from 'react';
import PropTypes from 'prop-types';

const LightHouse = ({ className }) => {
  return (
    <svg
      className={className}
      width="734"
      height="442"
      viewBox="0 0 734 442"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M298.432 342.976L266.78 304.183C257.884 293.282 246.985 293.241 238.072 304.076L206.075 342.976H298.432Z"
        fill="url(#lh_paint0_linear)"
      />
      <path
        d="M414.947 292.498C399.815 278.313 381.274 278.26 366.113 292.359L311.682 342.975H468.79L414.947 292.498Z"
        fill="url(#lh_paint1_linear)"
      />
      <path
        d="M350.521 301.508H335.033C330.136 301.508 325.439 298.827 321.976 294.056C314.764 284.119 303.072 284.119 295.861 294.056L260.357 342.976H388.265L363.579 308.961C360.115 304.189 355.419 301.508 350.521 301.508Z"
        fill="url(#lh_paint2_linear)"
      />
      <path
        d="M363.579 308.961C360.116 304.189 355.419 301.508 350.521 301.508H335.033C330.186 301.508 325.48 298.958 322.079 294.199C317.848 288.278 312.042 285.825 306.503 286.826L327.355 315.558C331.009 320.593 335.966 323.422 341.135 323.422C346.304 323.422 351.261 326.251 354.916 331.287L363.399 342.975H388.266L363.579 308.961Z"
        fill="url(#lh_paint3_linear)"
      />
      <path
        d="M370.196 336.363C391.048 318.32 431.224 294.087 484.227 311.568C537.231 329.049 538.999 338.831 538.999 346.882C538.999 354.932 484.227 352.796 484.227 352.796H375.235C367.409 352.795 364.007 341.717 370.196 336.363Z"
        fill="url(#lh_paint4_linear)"
      />
      <path
        d="M356.757 429.904V425.902C356.757 419.62 352.009 414.528 346.152 414.528C346.152 407.141 340.569 401.152 333.682 401.152H188.21V426.004C188.21 434.44 194.586 441.278 202.451 441.278H346.152C352.009 441.278 356.757 436.186 356.757 429.904Z"
        fill="url(#lh_paint5_linear)"
      />
      <path
        d="M205.746 430.268C205.746 436.349 210.343 441.278 216.012 441.278H346.152C348.416 441.278 350.51 440.511 352.233 439.214C352.501 438.214 352.653 437.161 352.653 436.069V432.068C352.653 425.785 347.905 420.693 342.048 420.693C342.048 413.306 336.466 407.318 329.578 407.318H205.746V430.268Z"
        fill="url(#lh_paint6_linear)"
      />
      <path
        d="M342.048 420.693C342.048 413.306 336.465 407.318 329.578 407.318H228.364C217.121 407.318 208.007 417.093 208.007 429.152L219.313 441.279H346.152C348.416 441.279 350.51 440.511 352.233 439.215C352.501 438.215 352.653 437.161 352.653 436.069V432.068C352.653 425.786 347.905 420.693 342.048 420.693Z"
        fill="url(#lh_paint7_linear)"
      />
      <path
        d="M315.963 392.023C314.698 392.023 313.482 392.24 312.342 392.638C311.123 388.372 307.421 385.269 303.044 385.269C297.674 385.269 293.32 389.939 293.32 395.699C293.32 396.531 293.412 397.34 293.584 398.116C289.001 398.463 285.382 402.559 285.382 407.563C285.382 412.794 289.336 417.035 294.213 417.035H315.963C322.403 417.035 327.623 411.435 327.623 404.529C327.623 397.622 322.403 392.023 315.963 392.023Z"
        fill="url(#lh_paint8_linear)"
      />
      <path
        d="M304.302 404.53C304.302 405.037 304.331 405.537 304.386 406.028C303.947 406.095 303.498 406.128 303.044 406.128C297.674 406.128 293.32 401.458 293.32 395.698C293.32 389.939 297.674 385.269 303.044 385.269C307.421 385.269 311.123 388.372 312.342 392.639C307.675 394.271 304.302 398.978 304.302 404.53Z"
        fill="url(#lh_paint9_linear)"
      />
      <path
        d="M285.45 408.743C285.991 413.417 289.708 417.036 294.213 417.036H315.963C321.024 417.036 325.331 413.577 326.944 408.743H285.45Z"
        fill="url(#lh_paint10_linear)"
      />
      <path
        d="M733.999 438.5L295.776 212.97V183.447L733.999 0.5C733.999 0.5 733.999 186.328 733.999 242C733.999 314.421 733.999 438.5 733.999 438.5Z"
        fill="url(#lh_paint11_linear)"
      />
      <path
        d="M295.251 424.435H243.066C236.806 424.435 231.814 418.826 232.106 412.119L240.51 218.607H297.809L306.213 412.119C306.503 418.826 301.512 424.435 295.251 424.435Z"
        fill="url(#lh_paint12_linear)"
      />
      <path
        d="M301.243 297.712L297.808 218.607H240.51L239.93 231.948L301.243 297.712Z"
        fill="url(#lh_paint13_linear)"
      />
      <path
        d="M304.96 383.374H233.353L235.135 342.317H303.179L304.96 383.374Z"
        fill="url(#lh_paint14_linear)"
      />
      <path
        d="M301.398 301.254H236.921L238.703 260.197H299.611L301.398 301.254Z"
        fill="url(#lh_paint15_linear)"
      />
      <path
        d="M301.243 297.711L299.611 260.197H266.267L301.243 297.711Z"
        fill="url(#lh_paint16_linear)"
      />
      <path
        d="M241.625 178.093H296.692V221.21H241.625V178.093Z"
        fill="url(#lh_paint17_linear)"
      />
      <path
        d="M270.587 178.093H296.693V221.21H270.587V178.093Z"
        fill="url(#lh_paint18_linear)"
      />
      <path
        d="M241.625 196.712H296.692V221.21H241.625V196.712Z"
        fill="url(#lh_paint19_linear)"
      />
      <path
        d="M263.384 178.093H274.934V221.21H263.384V178.093Z"
        fill="url(#lh_paint20_linear)"
      />
      <path
        d="M247.982 218.607H238.658V208.759C238.658 206.079 240.682 203.908 243.18 203.908H243.46C245.957 203.908 247.982 206.079 247.982 208.759V218.607Z"
        fill="url(#lh_paint21_linear)"
      />
      <path
        d="M265.208 218.607H255.883V208.759C255.883 206.08 257.908 203.908 260.406 203.908H260.686C263.183 203.908 265.208 206.08 265.208 208.759V218.607Z"
        fill="url(#lh_paint22_linear)"
      />
      <path
        d="M282.434 218.607H273.109V208.759C273.109 206.08 275.134 203.908 277.632 203.908H277.911C280.409 203.908 282.434 206.08 282.434 208.759V218.607Z"
        fill="url(#lh_paint23_linear)"
      />
      <path
        d="M299.66 218.607H290.335V208.759C290.335 206.08 292.36 203.908 294.858 203.908H295.137C297.635 203.908 299.66 206.08 299.66 208.759V218.607Z"
        fill="url(#lh_paint24_linear)"
      />
      <path
        d="M296.876 232.113H241.441C237.326 232.113 233.99 228.534 233.99 224.12V220.394C233.99 215.979 237.326 212.401 241.441 212.401H296.876C300.992 212.401 304.328 215.98 304.328 220.394V224.12C304.328 228.534 300.992 232.113 296.876 232.113Z"
        fill="url(#lh_paint25_linear)"
      />
      <path
        d="M241.625 181.952L241.708 182.405L277.887 221.21H296.693V178.093H241.625V181.952Z"
        fill="url(#lh_paint26_linear)"
      />
      <path
        d="M267.464 143.6C252.258 143.6 239.93 156.822 239.93 173.132V178.093H294.998V173.132C294.998 156.822 282.671 143.6 267.464 143.6Z"
        fill="url(#lh_paint27_linear)"
      />
      <path
        d="M269.219 143.6H265.709C259.172 143.6 253.873 149.284 253.873 156.294V178.093H281.055V156.294C281.055 149.283 275.756 143.6 269.219 143.6Z"
        fill="url(#lh_paint28_linear)"
      />
      <path
        d="M300.654 182.405H237.663C235.634 182.405 233.99 180.64 233.99 178.464C233.99 176.288 235.634 174.524 237.663 174.524H300.654C302.683 174.524 304.328 176.288 304.328 178.464C304.328 180.64 302.684 182.405 300.654 182.405Z"
        fill="url(#lh_paint29_linear)"
      />
      <path
        d="M244.703 402.795C243.106 402.795 241.569 403.068 240.13 403.572C238.589 398.183 233.914 394.264 228.384 394.264C221.601 394.264 216.101 400.162 216.101 407.438C216.101 408.489 216.217 409.511 216.434 410.492C210.645 410.93 206.075 416.105 206.075 422.426C206.075 429.033 211.069 434.39 217.23 434.39H244.703C252.837 434.39 259.432 427.317 259.432 418.592C259.432 409.868 252.837 402.795 244.703 402.795Z"
        fill="url(#lh_paint30_linear)"
      />
      <path
        d="M229.974 418.593C229.974 419.234 230.009 419.865 230.079 420.486C229.525 420.57 228.958 420.612 228.383 420.612C221.599 420.612 216.101 414.714 216.101 407.438C216.101 400.163 221.599 394.265 228.383 394.265C233.912 394.265 238.589 398.184 240.128 403.573C234.234 405.635 229.974 411.58 229.974 418.593Z"
        fill="url(#lh_paint31_linear)"
      />
      <path
        d="M206.161 423.914C206.844 429.819 211.539 434.39 217.23 434.39H244.703C251.096 434.39 256.537 430.021 258.574 423.914H206.161Z"
        fill="url(#lh_paint32_linear)"
      />
      <path
        d="M210.253 248.732C212.492 248.732 214.307 246.846 214.307 244.519C214.307 242.192 212.492 240.306 210.253 240.306C208.014 240.306 206.199 242.192 206.199 244.519C206.199 246.846 208.014 248.732 210.253 248.732Z"
        fill="url(#lh_paint33_linear)"
      />
      <path
        d="M363.72 147.339C365.959 147.339 367.774 145.453 367.774 143.126C367.774 140.799 365.959 138.912 363.72 138.912C361.481 138.912 359.666 140.799 359.666 143.126C359.666 145.453 361.481 147.339 363.72 147.339Z"
        fill="url(#lh_paint34_linear)"
      />
      <path
        d="M164.911 438C167.15 438 168.965 436.114 168.965 433.787C168.965 431.46 167.15 429.573 164.911 429.573C162.672 429.573 160.857 431.46 160.857 433.787C160.857 436.114 162.672 438 164.911 438Z"
        fill="url(#lh_paint35_linear)"
      />
      <path
        d="M572.992 110.162C575.231 110.162 577.046 108.275 577.046 105.948C577.046 103.621 575.231 101.735 572.992 101.735C570.753 101.735 568.938 103.621 568.938 105.948C568.938 108.275 570.753 110.162 572.992 110.162Z"
        fill="url(#lh_paint36_linear)"
      />
      <path
        d="M691.58 56.0852C693.818 56.0852 695.633 54.1988 695.633 51.8718C695.633 49.5448 693.818 47.6584 691.58 47.6584C689.341 47.6584 687.526 49.5448 687.526 51.8718C687.526 54.1988 689.341 56.0852 691.58 56.0852Z"
        fill="url(#lh_paint37_linear)"
      />
      <path
        d="M4.46894 79.7437C6.70785 79.7437 8.52284 77.8573 8.52284 75.5303C8.52284 73.2033 6.70785 71.3169 4.46894 71.3169C2.23003 71.3169 0.415039 73.2033 0.415039 75.5303C0.415039 77.8573 2.23003 79.7437 4.46894 79.7437Z"
        fill="url(#lh_paint38_linear)"
      />
      <path
        d="M471.839 396.762C474.078 396.762 475.893 394.876 475.893 392.549C475.893 390.222 474.078 388.335 471.839 388.335C469.6 388.335 467.785 390.222 467.785 392.549C467.785 394.876 469.6 396.762 471.839 396.762Z"
        fill="url(#lh_paint39_linear)"
      />
      <path
        d="M70.7385 296.049C72.9774 296.049 74.7924 294.163 74.7924 291.836C74.7924 289.509 72.9774 287.623 70.7385 287.623C68.4996 287.623 66.6846 289.509 66.6846 291.836C66.6846 294.163 68.4996 296.049 70.7385 296.049Z"
        fill="url(#lh_paint40_linear)"
      />
      <path
        d="M384.647 59.4649C386.886 59.4649 388.701 57.5785 388.701 55.2515C388.701 52.9246 386.886 51.0382 384.647 51.0382C382.408 51.0382 380.593 52.9246 380.593 55.2515C380.593 57.5785 382.408 59.4649 384.647 59.4649Z"
        fill="url(#lh_paint41_linear)"
      />
      <path
        d="M566.016 45.9459C568.255 45.9459 570.07 44.0595 570.07 41.7325C570.07 39.4055 568.255 37.5191 566.016 37.5191C563.777 37.5191 561.962 39.4055 561.962 41.7325C561.962 44.0595 563.777 45.9459 566.016 45.9459Z"
        fill="url(#lh_paint42_linear)"
      />
      <path
        d="M628.798 346.746C631.037 346.746 632.852 344.86 632.852 342.533C632.852 340.206 631.037 338.319 628.798 338.319C626.559 338.319 624.744 340.206 624.744 342.533C624.744 344.86 626.559 346.746 628.798 346.746Z"
        fill="url(#lh_paint43_linear)"
      />
      <path
        d="M133.52 32.4268C135.759 32.4268 137.574 30.5404 137.574 28.2134C137.574 25.8864 135.759 24 133.52 24C131.281 24 129.466 25.8864 129.466 28.2134C129.466 30.5404 131.281 32.4268 133.52 32.4268Z"
        fill="url(#lh_paint44_linear)"
      />
      <path
        d="M511.978 239.724C514.217 239.724 516.032 237.837 516.032 235.51C516.032 233.183 514.217 231.297 511.978 231.297C509.739 231.297 507.924 233.183 507.924 235.51C507.924 237.837 509.739 239.724 511.978 239.724Z"
        fill="url(#lh_paint45_linear)"
      />
      <path
        d="M607.871 170.998C610.109 170.998 611.924 169.111 611.924 166.784C611.924 164.457 610.109 162.571 607.871 162.571C605.632 162.571 603.817 164.457 603.817 166.784C603.817 169.111 605.632 170.998 607.871 170.998Z"
        fill="url(#lh_paint46_linear)"
      />
      <path
        d="M729.946 116.921C732.185 116.921 734 115.035 734 112.708C734 110.381 732.185 108.495 729.946 108.495C727.707 108.495 725.892 110.381 725.892 112.708C725.892 115.035 727.707 116.921 729.946 116.921Z"
        fill="url(#lh_paint47_linear)"
      />
      <path
        d="M726.458 214.935C728.697 214.935 730.512 213.048 730.512 210.721C730.512 208.394 728.697 206.508 726.458 206.508C724.219 206.508 722.404 208.394 722.404 210.721C722.404 213.048 724.219 214.935 726.458 214.935Z"
        fill="url(#lh_paint48_linear)"
      />
      <path
        d="M468.356 113.541C470.595 113.541 472.41 111.655 472.41 109.328C472.41 107.001 470.595 105.115 468.356 105.115C466.117 105.115 464.302 107.001 464.302 109.328C464.302 111.655 466.117 113.541 468.356 113.541Z"
        fill="url(#lh_paint49_linear)"
      />
      <path
        d="M439.908 207.682C442.147 207.682 443.962 205.796 443.962 203.469C443.962 201.142 442.147 199.255 439.908 199.255C437.67 199.255 435.855 201.142 435.855 203.469C435.855 205.796 437.67 207.682 439.908 207.682Z"
        fill="url(#lh_paint50_linear)"
      />
      <path
        d="M245.132 100.022C247.371 100.022 249.186 98.1359 249.186 95.8089C249.186 93.4819 247.371 91.5955 245.132 91.5955C242.893 91.5955 241.078 93.4819 241.078 95.8089C241.078 98.1359 242.893 100.022 245.132 100.022Z"
        fill="url(#lh_paint51_linear)"
      />
      <defs>
        <linearGradient
          id="lh_paint0_linear"
          x1="252.253"
          y1="332.526"
          x2="252.253"
          y2="293.889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F73A0" stopOpacity="0" />
          <stop offset="0.5026" stopColor="#345278" stopOpacity="0.503" />
          <stop offset="1" stopColor="#3A2F4D" />
        </linearGradient>
        <linearGradient
          id="lh_paint1_linear"
          x1="390.236"
          y1="305.358"
          x2="390.236"
          y2="228.194"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F73A0" stopOpacity="0" />
          <stop offset="0.5026" stopColor="#345278" stopOpacity="0.503" />
          <stop offset="1" stopColor="#3A2F4D" />
        </linearGradient>
        <linearGradient
          id="lh_paint2_linear"
          x1="288.722"
          y1="263.577"
          x2="329.597"
          y2="342.844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F73A0" />
          <stop offset="1" stopColor="#3A2F4D" />
        </linearGradient>
        <linearGradient
          id="lh_paint3_linear"
          x1="352.072"
          y1="312.914"
          x2="319.94"
          y2="324.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3A2F4D" stopOpacity="0" />
          <stop offset="1" stopColor="#3A2F4D" />
        </linearGradient>
        <linearGradient
          id="lh_paint4_linear"
          x1="421.86"
          y1="310.96"
          x2="432.631"
          y2="350.346"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#798C00" />
          <stop offset="1" stopColor="#1B444F" />
        </linearGradient>
        <linearGradient
          id="lh_paint5_linear"
          x1="272.483"
          y1="403.164"
          x2="272.483"
          y2="429.083"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988575" />
          <stop offset="1" stopColor="#736357" />
        </linearGradient>
        <linearGradient
          id="lh_paint6_linear"
          x1="274.144"
          y1="436.074"
          x2="329.868"
          y2="308.045"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#754C24" stopOpacity="0" />
          <stop offset="1" stopColor="#754C24" />
        </linearGradient>
        <linearGradient
          id="lh_paint7_linear"
          x1="287.798"
          y1="455.132"
          x2="272.317"
          y2="409.359"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#754C24" stopOpacity="0" />
          <stop offset="1" stopColor="#754C24" />
        </linearGradient>
        <linearGradient
          id="lh_paint8_linear"
          x1="294.391"
          y1="392.428"
          x2="318.868"
          y2="415.248"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#798C00" />
          <stop offset="1" stopColor="#1B444F" />
        </linearGradient>
        <linearGradient
          id="lh_paint9_linear"
          x1="303.215"
          y1="395.65"
          x2="318.011"
          y2="392.845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B444F" stopOpacity="0" />
          <stop offset="1" stopColor="#1B444F" />
        </linearGradient>
        <linearGradient
          id="lh_paint10_linear"
          x1="306.197"
          y1="410.834"
          x2="306.197"
          y2="418.152"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B444F" stopOpacity="0" />
          <stop offset="1" stopColor="#1B444F" />
        </linearGradient>
        <linearGradient
          id="lh_paint11_linear"
          x1="297.947"
          y1="199.001"
          x2="741.488"
          y2="201.002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7D474" />
          <stop offset="1" stopColor="#4568DC" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="lh_paint12_linear"
          x1="252.824"
          y1="321.521"
          x2="276.954"
          y2="321.521"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C7E9FF" />
          <stop offset="1" stopColor="#7ACBFF" />
        </linearGradient>
        <linearGradient
          id="lh_paint13_linear"
          x1="278.053"
          y1="262.888"
          x2="254.006"
          y2="177.285"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F73A0" stopOpacity="0" />
          <stop offset="1" stopColor="#2F73A0" />
        </linearGradient>
        <linearGradient
          id="lh_paint14_linear"
          x1="234.344"
          y1="362.845"
          x2="303.902"
          y2="362.845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4181" />
          <stop offset="1" stopColor="#843561" />
        </linearGradient>
        <linearGradient
          id="lh_paint15_linear"
          x1="237.814"
          y1="280.726"
          x2="300.445"
          y2="280.726"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4181" />
          <stop offset="1" stopColor="#843561" />
        </linearGradient>
        <linearGradient
          id="lh_paint16_linear"
          x1="290.664"
          y1="300.862"
          x2="270.291"
          y2="207.642"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#843561" stopOpacity="0" />
          <stop offset="1" stopColor="#3A2F4D" />
        </linearGradient>
        <linearGradient
          id="lh_paint17_linear"
          x1="249.206"
          y1="178.25"
          x2="272.349"
          y2="199.828"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF5D9" />
          <stop offset="1" stopColor="#FFCE47" />
        </linearGradient>
        <linearGradient
          id="lh_paint18_linear"
          x1="282.791"
          y1="199.651"
          x2="262.691"
          y2="199.651"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F73A0" stopOpacity="0" />
          <stop offset="1" stopColor="#2F73A0" />
        </linearGradient>
        <linearGradient
          id="lh_paint19_linear"
          x1="269.159"
          y1="201.219"
          x2="269.159"
          y2="222.386"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9356" stopOpacity="0" />
          <stop offset="1" stopColor="#FF755C" />
        </linearGradient>
        <linearGradient
          id="lh_paint20_linear"
          x1="261.777"
          y1="199.651"
          x2="272.461"
          y2="199.651"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFDFDF" />
          <stop offset="1" stopColor="#9DA6AC" />
        </linearGradient>
        <linearGradient
          id="lh_paint21_linear"
          x1="243.32"
          y1="206.511"
          x2="243.32"
          y2="214.873"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFDFDF" />
          <stop offset="1" stopColor="#9DA6AC" />
        </linearGradient>
        <linearGradient
          id="lh_paint22_linear"
          x1="260.546"
          y1="206.511"
          x2="260.546"
          y2="214.873"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFDFDF" />
          <stop offset="1" stopColor="#9DA6AC" />
        </linearGradient>
        <linearGradient
          id="lh_paint23_linear"
          x1="277.772"
          y1="206.511"
          x2="277.772"
          y2="214.873"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFDFDF" />
          <stop offset="1" stopColor="#9DA6AC" />
        </linearGradient>
        <linearGradient
          id="lh_paint24_linear"
          x1="294.998"
          y1="206.511"
          x2="294.998"
          y2="214.873"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFDFDF" />
          <stop offset="1" stopColor="#9DA6AC" />
        </linearGradient>
        <linearGradient
          id="lh_paint25_linear"
          x1="269.159"
          y1="213.86"
          x2="269.159"
          y2="231.621"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFDFDF" />
          <stop offset="1" stopColor="#9DA6AC" />
        </linearGradient>
        <linearGradient
          id="lh_paint26_linear"
          x1="269.159"
          y1="195.487"
          x2="269.159"
          y2="167.989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9356" stopOpacity="0" />
          <stop offset="1" stopColor="#FF755C" />
        </linearGradient>
        <linearGradient
          id="lh_paint27_linear"
          x1="258.804"
          y1="153.808"
          x2="270.63"
          y2="175.48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4181" />
          <stop offset="1" stopColor="#843561" />
        </linearGradient>
        <linearGradient
          id="lh_paint28_linear"
          x1="261.53"
          y1="152.284"
          x2="284.854"
          y2="198.351"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#843561" stopOpacity="0" />
          <stop offset="1" stopColor="#3A2F4D" />
        </linearGradient>
        <linearGradient
          id="lh_paint29_linear"
          x1="269.159"
          y1="175.107"
          x2="269.159"
          y2="182.208"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFDFDF" />
          <stop offset="1" stopColor="#9DA6AC" />
        </linearGradient>
        <linearGradient
          id="lh_paint30_linear"
          x1="217.455"
          y1="403.306"
          x2="248.373"
          y2="432.132"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#798C00" />
          <stop offset="1" stopColor="#1B444F" />
        </linearGradient>
        <linearGradient
          id="lh_paint31_linear"
          x1="228.601"
          y1="407.376"
          x2="247.289"
          y2="403.834"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B444F" stopOpacity="0" />
          <stop offset="1" stopColor="#1B444F" />
        </linearGradient>
        <linearGradient
          id="lh_paint32_linear"
          x1="232.368"
          y1="426.556"
          x2="232.368"
          y2="435.801"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B444F" stopOpacity="0" />
          <stop offset="1" stopColor="#1B444F" />
        </linearGradient>
        <linearGradient
          id="lh_paint33_linear"
          x1="208.961"
          y1="243.176"
          x2="214.3"
          y2="248.313"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF5FF" />
          <stop offset="1" stopColor="#C7E9FF" />
        </linearGradient>
        <linearGradient
          id="lh_paint34_linear"
          x1="362.428"
          y1="141.783"
          x2="367.766"
          y2="146.92"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF5FF" />
          <stop offset="1" stopColor="#C7E9FF" />
        </linearGradient>
        <linearGradient
          id="lh_paint35_linear"
          x1="163.619"
          y1="432.444"
          x2="168.958"
          y2="437.58"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF5FF" />
          <stop offset="1" stopColor="#C7E9FF" />
        </linearGradient>
        <linearGradient
          id="lh_paint36_linear"
          x1="571.7"
          y1="104.606"
          x2="577.038"
          y2="109.742"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF5FF" />
          <stop offset="1" stopColor="#C7E9FF" />
        </linearGradient>
        <linearGradient
          id="lh_paint37_linear"
          x1="690.288"
          y1="50.5291"
          x2="695.626"
          y2="55.6656"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF5FF" />
          <stop offset="1" stopColor="#C7E9FF" />
        </linearGradient>
        <linearGradient
          id="lh_paint38_linear"
          x1="3.1771"
          y1="74.1876"
          x2="8.51569"
          y2="79.3241"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF5FF" />
          <stop offset="1" stopColor="#C7E9FF" />
        </linearGradient>
        <linearGradient
          id="lh_paint39_linear"
          x1="470.547"
          y1="391.206"
          x2="475.885"
          y2="396.343"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF5FF" />
          <stop offset="1" stopColor="#C7E9FF" />
        </linearGradient>
        <linearGradient
          id="lh_paint40_linear"
          x1="69.4466"
          y1="290.493"
          x2="74.7852"
          y2="295.63"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF5FF" />
          <stop offset="1" stopColor="#C7E9FF" />
        </linearGradient>
        <linearGradient
          id="lh_paint41_linear"
          x1="383.355"
          y1="53.9089"
          x2="388.694"
          y2="59.0454"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF5FF" />
          <stop offset="1" stopColor="#C7E9FF" />
        </linearGradient>
        <linearGradient
          id="lh_paint42_linear"
          x1="564.724"
          y1="40.3898"
          x2="570.063"
          y2="45.5263"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF5FF" />
          <stop offset="1" stopColor="#C7E9FF" />
        </linearGradient>
        <linearGradient
          id="lh_paint43_linear"
          x1="627.506"
          y1="341.19"
          x2="632.845"
          y2="346.327"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF5FF" />
          <stop offset="1" stopColor="#C7E9FF" />
        </linearGradient>
        <linearGradient
          id="lh_paint44_linear"
          x1="132.228"
          y1="26.8707"
          x2="137.567"
          y2="32.0072"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF5FF" />
          <stop offset="1" stopColor="#C7E9FF" />
        </linearGradient>
        <linearGradient
          id="lh_paint45_linear"
          x1="510.686"
          y1="234.168"
          x2="516.025"
          y2="239.304"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF5FF" />
          <stop offset="1" stopColor="#C7E9FF" />
        </linearGradient>
        <linearGradient
          id="lh_paint46_linear"
          x1="606.579"
          y1="165.442"
          x2="611.917"
          y2="170.578"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF5FF" />
          <stop offset="1" stopColor="#C7E9FF" />
        </linearGradient>
        <linearGradient
          id="lh_paint47_linear"
          x1="728.654"
          y1="111.365"
          x2="733.993"
          y2="116.502"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF5FF" />
          <stop offset="1" stopColor="#C7E9FF" />
        </linearGradient>
        <linearGradient
          id="lh_paint48_linear"
          x1="725.166"
          y1="209.379"
          x2="730.505"
          y2="214.515"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF5FF" />
          <stop offset="1" stopColor="#C7E9FF" />
        </linearGradient>
        <linearGradient
          id="lh_paint49_linear"
          x1="467.064"
          y1="107.985"
          x2="472.402"
          y2="113.122"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF5FF" />
          <stop offset="1" stopColor="#C7E9FF" />
        </linearGradient>
        <linearGradient
          id="lh_paint50_linear"
          x1="438.617"
          y1="202.126"
          x2="443.955"
          y2="207.263"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF5FF" />
          <stop offset="1" stopColor="#C7E9FF" />
        </linearGradient>
        <linearGradient
          id="lh_paint51_linear"
          x1="243.84"
          y1="94.4663"
          x2="249.179"
          y2="99.6028"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF5FF" />
          <stop offset="1" stopColor="#C7E9FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

LightHouse.propTypes = {
  className: PropTypes.string,
};

export default LightHouse;
