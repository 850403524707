import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  sortable_helper: {
    zIndex: 9999,
    background: `${theme.palette.neutral.cosmicCobalt4} !important`,
  },
  sortable_list_container: {
    marginTop: 16,
    position: 'relative',
    background: theme.palette.neutral.cosmicCobalt2,
    padding: 8,
    borderRadius: 8,
  },
  sortable_item: {
    cursor: 'row-resize',
    border: `solid 1px ${theme.palette.neutral.cosmicCobalt1}`,
    padding: 8,
    background: theme.palette.neutral.cosmicCobalt5,
    margin: 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 4,
  },
  sortable_item_title: {
    display: 'flex',
    alignItems: 'center',
  },
  sortable_item_icon: {
    marginRight: 8,
  },
  sortable_item_name: {
    marginLeft: 8,
  },
  sort_dialog_body: {
    display: 'block',
  },
}));

export { useStyles };
