import React from 'react';
import PropTypes from 'prop-types';

const Pulse = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="currentColor"
        d="M3.81818 2H20.1818C21.186 2 22 2.99492 22 4.22222V11V13V19.7778C22 21.0051 21.186 22 20.1818 22H3.81818C2.81403 22 2 21.0051 2 19.7778V4.22222C2 2.99492 2.81403 2 3.81818 2ZM20 11V4H4V11H7.41421L9.66269 13.2485L12.7622 6.01627L16.5 11H20ZM4 13H6.58579L10.3373 16.7515L13.2378 9.98373L15.5 13H20V20H4V13Z"
      />
    </svg>
  );
};

Pulse.propTypes = {
  className: PropTypes.string,
};

export default Pulse;
