import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  modal_container: {
    [theme.breakpoints.up('lg')]: {
      '&.modal_container': {
        width: 1024,
        minWidth: 1024,
      },
    },
    [theme.breakpoints.down('md')]: {
      '&.modal_container': {
        width: '90%',
        minWidth: '90%',
      },
    },
  },
  scheduled_tour_container: {
    height: '73vh',
    maxHeight: '704px',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
  },
  divider: {
    width: '100%',
  },
  inner_divider: {
    width: 'calc(100% - 48px)',
    marginLeft: 24,
    marginTop: theme.spacing(3),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    margin: '24px 24px 16px 24px',
  },

  actions_container: {
    padding: '0px 24px 16px 0',
  },
  actions: {
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(2),
    },
  },
  address_container: {
    marginTop: 8,
  },
  column: {
    display: 'flex',
    width: '50%',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.neutral.cosmicCobalt2,
    fill: theme.palette.neutral.cosmicCobalt2,
    fontSize: 16,
    marginRight: theme.spacing(1),
    '& svg': {
      fontSize: '14.67px',
    },
  },
  tour_types: {
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      width: '32%',
    },
    '& div': {
      width: '32%',
      '& button': {
        width: '100%',
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& button': {
        height: 60,
      },
    },
  },
  question: {
    flexDirection: 'column',
    alignItems: 'start',
  },
  selected_date: {
    textAlign: 'center',
    width: '100%',
  },
  times_section: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '24px',
  },
  times_container: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginLeft: '16px',
    },
  },
  time_btn: {
    width: '92px',
    marginTop: '8px',
    marginBottom: '8px',
    padding: '8px 12px',
    '&.active': {
      backgroundColor: theme.palette.neutral.cosmicCobalt3,
      color: theme.palette.light.platinum5,
    },
  },
  type_btn: {
    '&.active': {
      backgroundColor: theme.palette.neutral.cosmicCobalt3,
      color: theme.palette.light.platinum5,
      boxShadow: `0px 2px 4px ${theme.palette.light.boxShadow}`,
    },
  },
  time_title: {
    marginLeft: 16,
  },
  date_time_container: {
    marginBottom: 0,
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      '& > div:first-child': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  },
  tour_summary: {
    marginBottom: '24px',
  },
  label_bold: {
    fontWeight: 'bold',
  },
  message_container: {
    flexDirection: 'column',
    marginTop: '0',
    marginBottom: '1px',
  },
  message_header: {
    alignSelf: 'start',
    display: 'flex',
    fontWeight: 'bold',
    marginBottom: '8px',
  },
  tag_margin: {
    marginLeft: '2%',
  },
}));

export { useStyles };
