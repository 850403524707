import React, { useState, useEffect } from 'react';
import { useStyles } from './FamilyFile.style';
import LeftPanel from './LeftPanel/LeftPanel';
import Container from '../../common/Container/Container';
import PropTypes from 'prop-types';
import ContainerItem from '../../common/ContainerItem/ContainerItem';
import Sidebar from '../SendReferral/Sidebar/Sidebar';
import CenterPanel from './CenterPanel/CenterPanel';
import RightPanel from './RightPanel/RightPanel';
import NotesSidePanel from '../../components/NotesSidePanel/NotesSidePanel';

const FamilyFileXL = ({ labels, familyFileId, activeTab, flags }) => {
  const classes = useStyles();
  const [isNotesPanelOpen, setIsNotesPanelOpen] = useState(true);
  const [onlyShowCommunitiesTabFf, setOnlyShowCommunitiesTabFf] = useState(
    false,
  );
  let containerItems = (
    <>
      <ContainerItem xs={6}>
        <CenterPanel
          activeTab={activeTab}
          labels={labels}
          familyFileId={familyFileId}
        />
      </ContainerItem>
      <ContainerItem xs={3}>
        <RightPanel labels={labels} familyFileId={familyFileId} />
      </ContainerItem>
    </>
  );

  useEffect(() => {
    if (flags?.onlyShowCommunitiesTabFf) {
      setIsNotesPanelOpen(false);
      setOnlyShowCommunitiesTabFf(true);
    }
  }, [flags]);

  if (onlyShowCommunitiesTabFf) {
    containerItems = (
      <>
        <ContainerItem xs={9}>
          <CenterPanel
            activeTab={activeTab}
            labels={labels}
            familyFileId={familyFileId}
          />
          {!isNotesPanelOpen && (
            <Sidebar
              labels={labels}
              onClickShowNotes={() => {
                setIsNotesPanelOpen(true);
              }}
              onClickShowFamilyFile={() => false}
              hideFamilyFileIcon={true}
            />
          )}
          <NotesSidePanel
            familyFileId={familyFileId}
            labels={labels}
            isOpen={isNotesPanelOpen}
            onClose={() => setIsNotesPanelOpen(false)}
            screenName="family file"
          />
        </ContainerItem>
      </>
    );
  }

  return (
    <Container className={classes.family_file_container} spacing={4}>
      <ContainerItem xs={3}>
        <LeftPanel labels={labels} familyFileId={familyFileId} />
      </ContainerItem>
      {containerItems}
    </Container>
  );
};

FamilyFileXL.propTypes = {
  labels: PropTypes.object,
  familyFileId: PropTypes.number,
  activeTab: PropTypes.number,
  flags: PropTypes.shape({
    onlyShowCommunitiesTabFf: PropTypes.bool,
  }),
};

export default FamilyFileXL;
