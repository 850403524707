import gql from 'graphql-tag';

export default gql`
  query($familyFileId: Int) {
    findFamilyFileById(
      id: $familyFileId
      filters: { withoutToursDateRangeLimit: true }
    ) {
      familyFileId
      audienceKey
      oneId
      userId
      residents {
        profileData {
          firstName
          lastName
        }
      }
      contacts {
        profileData {
          firstName
          lastName
          relationToResident
          isPrimary
        }
      }
      communities {
        id
        name
        address
        city
        state
        zip
        phoneNumber
        careTypes {
          assistedLiving
          memoryCare
          nursingHome
          homeCare
          independentLiving
          respiteCare
        }
      }
      leadData {
        budgetDescription
        likelihoodToMovePct
        expectedMoveDate
      }
      questionsData {
        currentLiving
        rehab
      }
      familyDesiredLocations {
        city
        state
        zip
      }
      tourDetailsExtended {
        familyCommunityTourId
        communityId
        scheduledTourDate
        tourType
        currentStatus
        tourActivities {
          familyCommunityTourActivityId
          familyCommunityTourId
          activityDate
          tourType
          status
        }
      }
      tourDrafts {
        communityId
        familyCommunityTourDraftId
        activityDate
        tourType
      }
      referralNotes {
        summaryNote {
          referralNoteId
          note
        }
        propertyNotes {
          referralNoteId
          communityId
          note
        }
      }
      referralStatus {
        communityId
        isReferralStarted
        referralDate
        communityStatus
        isRejected
      }
    }
  }
`;
