import React from 'react';

const EndCall = () => {
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="60.6001" cy="62.7302" rx="57" ry="57" fill="#DDE3F8" />
      <g clipPath="url(#clip0)">
        <path
          d="M41.79 61.2544L41.8548 64.1777L14.9646 64.7741L14.7674 55.881C14.6534 50.7373 17.4552 45.969 22.0052 43.5669C46.0293 30.8816 74.6234 30.2476 99.1861 41.8557L99.192 41.8613C103.847 44.0603 106.858 48.7007 106.971 53.8482C107.067 58.2756 107.16 62.7299 107.16 62.7299L80.2696 63.3261L80.2048 60.4028C80.1197 56.5181 77.3026 53.2349 73.477 52.5568C65.0848 51.071 56.4818 51.2618 48.1635 53.1181C44.3706 53.9641 41.7029 57.3698 41.79 61.2544Z"
          fill="#4568DC"
        />
        <path
          d="M101.856 68.6128L85.8291 68.9681C82.829 69.0346 80.3434 66.6569 80.2769 63.6567L80.2696 63.3263L107.16 62.7302L107.167 63.0606C107.233 66.0598 104.855 68.5454 101.856 68.6128Z"
          fill="#DDE3F8"
        />
        <path
          d="M20.5241 70.4159L36.5507 70.0606C39.5509 69.994 41.9286 67.5085 41.8621 64.5083L41.8548 64.1779L14.9645 64.7741L14.9719 65.1045C15.0392 68.1038 17.5248 70.4815 20.5241 70.4159Z"
          fill="#DDE3F8"
        />
        <path
          d="M14.7675 55.8813L14.8853 61.1921C39.1272 38.8694 74.2893 33.0242 104.45 46.3032C103.109 44.392 101.298 42.8603 99.1902 41.8558L99.1843 41.8502C74.6216 30.244 46.0276 30.8798 22.0053 43.5671C17.4552 45.9693 14.6524 50.7366 14.7675 55.8813Z"
          fill="#2C438C"
        />
        <path
          d="M108.32 53.8072C108.245 49.8531 106.599 46.0933 103.743 43.3577C102.573 42.2421 101.231 41.3217 99.7699 40.6325C74.8077 28.9055 45.793 29.5468 21.372 42.366C16.3532 44.9947 13.2643 50.2478 13.4091 55.9116L13.6136 65.131C13.6957 68.8814 16.8036 71.8544 20.5539 71.7723L20.5559 71.7723L36.5788 71.417C40.3291 71.3348 43.3022 68.2269 43.22 64.4766L43.22 64.4746L43.1955 63.3701C43.1066 58.8879 46.1827 54.961 50.5567 53.9752C57.2363 52.4915 64.1783 52.6652 70.7763 54.48L72.0128 54.8206C76.0312 55.9085 78.8459 59.5223 78.9198 63.684C79.0019 67.4343 82.1098 70.4074 85.8602 70.3252L85.8622 70.3252L101.884 69.971C105.633 69.884 108.604 66.778 108.525 63.0287L108.32 53.8072ZM36.5185 68.7029L20.4956 69.0582C18.6468 69.0924 17.0056 67.8778 16.5002 66.0989L40.3789 65.5696C39.9517 67.3681 38.367 68.6552 36.5185 68.7029ZM72.7348 52.2027L71.4993 51.8611C64.473 49.928 57.0782 49.7442 49.9646 51.3259C44.5438 52.5511 40.6451 57.2947 40.4917 62.8499L16.2923 63.3863L16.1252 55.8513C16.0071 51.2169 18.5339 46.9188 22.64 44.7686C46.302 32.344 74.4167 31.7196 98.6081 43.0814C102.804 45.0485 105.519 49.2304 105.608 53.8644L105.772 61.4024L81.4743 61.9411C80.7334 57.2561 77.3119 53.4435 72.7348 52.2027ZM101.828 67.2501L85.8054 67.6054C83.9521 67.6522 82.305 66.432 81.81 64.6461L105.684 64.1216C105.258 65.921 103.672 67.2073 101.824 67.255L101.828 67.2501Z"
          fill="#0D1321"
        />
        <path
          d="M59.2153 69.902L59.2153 94.7404L51.6416 87.165L49.1743 89.6323L60.9775 101.434L72.7807 89.6323L70.3134 87.165L62.7397 94.7404L62.7397 69.902L59.2153 69.902Z"
          fill="#3956B5"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="80.0265"
            height="80.0265"
            fill="white"
            transform="translate(62.1826 114.347) rotate(-136.27)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EndCall;
