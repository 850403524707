export const inquiryManualObject = {
  inquiryContact: {
    firstName: null,
    lastName: null,
    oneId: null,
    phone: null,
    email: null,
    mergedOneId: null,
  },

  inquiryId: null,
  firstName: null,
  lastName: null,
  email: null,
  primaryPhone: null,
  desiredCity: null,
  desiredState: null,
  desiredPostalCode: null,
  desiredCountry: null,
  relationToResident: null,
  careTypeCode: [],
  lookingReason: [],
  moveDateRange: null,
  veteran: null,
  resident1FirstName: null,
  resident1LastName: null,
  resident1Age: null,
  resident1Gender: null,
  resident2FirstName: null,
  resident2LastName: null,
  resident2Age: null,
  resident2Gender: null,
  budgetMin: null,
  budgetMax: null,
  financialResources: [],
  medicaid: null,
  locationRange: null,
  roomPreference: [],
  getAround: null,
  diabetic: null,
  memoryLoss: null,
  behavioral: null,
  bathing: null,
  toileting: null,
  referrerUrl: null,
  submissionUrl: null,
  utmCampaign: null,
  utmMedium: null,
  utmSource: null,
  utmTerm: null,
  utmContent: null,
  status: null,
  sourceId: null,
  subSourceId: null,
  inquiryMethod: 'MANUAL',
  desiredCountryCode: null,
  addedToCallQueue: null,
  audienceKey: null,
  inquiryFormData: {
    slugId: null,
    versionId: null,
    contactConfirmation: null,
    disclaimer: null,
    firstName: null,
    lastName: null,
    email: null,
    primaryPhone: null,
    noAnswerCall: null,
    confirmInterest: null,
    relationToResident: null,
    resident1FirstName: null,
    resident1LastName: null,
    budget: null,
    medicalInfo: null,
    desiredCity: null,
    desiredState: null,
    desiredPostalCode: null,
    desiredCountry: null,
    desiredDistance: null,
    notInterestedReason: null,
    transferSla: null,
    unqualified: null,
    pipedaDisclosure: null,
    isCompleteCall: null,
    moveDateRange: null,
    careTypeCode: null,
    veteran: null,
    status: null,
    endCallReason: null,
    closeInquiryReason: null,
    budgetRange: null,
    toileting: null,
    bathing: null,
    behavioral: null,
    memoryLoss: null,
    diabetic: null,
    getAround: null,
    roomPreference: null,
    medicaId: null,
    financialResources: null,
  },
};
