import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles(() => {
  return {
    tab_panel_container: {
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  };
});

export { useStyles };
