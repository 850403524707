import { makeStyles } from '../../common/Theme/Theme';

const useStyles = () => {
  return makeStyles((theme) => ({
    surpassing_point_container: {
      height: theme.spacing(18),
      width: theme.spacing(12),
      boxShadow: `0px 0px 8px ${theme.palette.light.boxShadow}`,
      borderRadius: '4px',
      backgroundColor: theme.palette.care.regalia2,
      right: theme.spacing(2),
      cursor: 'pointer',
      float: 'right',
    },
    surpassing_point_container_bellow_medium_size: {
      width: theme.spacing(17),
    },
    header: {
      textAlign: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    body: {
      textAlign: 'center',
      marginTop: 0,
    },
    footer: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
    clickable_container: {
      cursor: 'pointer',
      display: 'flow-root',
    },
  }))();
};

export { useStyles };
