import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    filter_container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '14px 20px',
    },
    filter: {
      display: 'flex',
      alignItems: 'flex-start',
      '& > svg': {
        fontSize: '25px',
      },
      '& .MuiFormControlLabel-root': {
        marginRight: '0',
      },
    },
    checkbox_label: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: '12px',
        fontSize: '25px',
      },
    },
    checkbox: {
      margin: '0 0 18px 0',
      padding: 0,
      '& > span': {
        paddingTop: 0,
        paddingLeft: 0,
        paddingBottom: 0,
      },
    },
    row_title: {
      display: 'flex',
      '& svg': {
        fontSize: '25px',
        color: theme.palette.neutral.cosmicCobalt3,
        marginRight: '16px',
      },
    },
    filter_subgroup: {
      marginLeft: '40px',
      marginTop: '24px',
    },
    radio_filter: {
      paddingBottom: '8px',
    },
  };
});

export { useStyles };
