import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Bell from '../../common/Icons/notifications/Bell';
import Tag from '../../common/Tag/Tag';
import Notification, { NotificationPropType } from './Notification';
import { useStyles } from './NotificationsMenu.style';

const NotificationsMenu = ({
  notifications,
  onNotificationClicked,
  showUnreadOnly,
}) => {
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const unreadCount = notifications.filter((n) => !n.read).length;

  let sortedNotifications = notifications.sort((a, b) => {
    if (a.read === b.read) {
      return b.date > a.date ? 1 : -1;
    }
    return b.read && !a.read ? 1 : -1;
  });
  if (showUnreadOnly) {
    sortedNotifications = sortedNotifications.filter((n) => !n.read);
  }
  const hasNotifications = sortedNotifications.length > 0;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div
      className={clsx(
        classes.notifications,
        hasNotifications && 'clickable',
        isOpen && 'open',
      )}
      data-testid="notifications-menu"
      onClick={() => hasNotifications && setIsOpen(!isOpen)}
      ref={wrapperRef}
    >
      <div className={classes.bell_container}>
        <Bell />
        {unreadCount > 0 && (
          <Tag
            className={clsx('unread_count', isOpen && 'open')}
            text={unreadCount.toString()}
            color="smokyTopaz2"
          />
        )}
      </div>
      {isOpen && (
        <div className={classes.menu_container}>
          <div className={classes.menu}>
            {sortedNotifications.map((notification) => (
              <Notification
                key={notification.id}
                notification={notification}
                onClick={onNotificationClicked}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

NotificationsMenu.defaultProps = {
  showUnreadOnly: false,
  notifications: [],
  onNotificationClicked: () => {},
};

NotificationsMenu.propTypes = {
  showUnreadOnly: PropTypes.bool,
  notifications: PropTypes.arrayOf(NotificationPropType),
  onNotificationClicked: PropTypes.func,
};

export default NotificationsMenu;
