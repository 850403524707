import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  contact_info_container: {
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    flexGrow: 1,
  },
  contact_info_row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    lineHeight: '18px',
  },
  contact_info_column: {
    display: 'flex',
    marginRight: theme.spacing(2),
    alignItems: 'flex-end',
  },
  contact_info_text: {
    wordBreak: 'break-all',
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: '16px',
    color: theme.palette.neutral.cosmicCobalt2,
  },
  icon_disabled: {
    color: theme.palette.dark.disabled,
    fontSize: '16px',
  },
  contact: {
    display: 'inline-flex',
    alignItems: 'center',
    '& svg': {
      fontSize: theme.spacing(3),
      marginRight: '0px',
      paddingRight: '4px',
    },
    cursor: 'pointer',
  },
  actionItem: {
    display: 'flex',
    textAlign: 'left',
    cursor: 'pointer',
    color: theme.palette.dark.eerieBlack1,
    '& div': {
      padding: '9px 16px',
    },
    '& p': {
      padding: '9px 16px',
    },
    '&:hover': {
      backgroundColor: theme.palette.neutral.dropdown,
    },
    '& a, .link': {
      'text-decoration': 'none',
      color: theme.palette.dark.eerieBlack1,
    },
    '& svg': {
      fontSize: '24px',
      color: theme.palette.dark.eerieBlack1,
      marginRight: '12px',
    },
    padding: 0,
  },
}));

export { useStyles };
