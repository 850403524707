import gql from 'graphql-tag';

export default gql`
  query($familyFileId: Int) {
    findFamilyFileById(
      id: $familyFileId
      filters: { withoutToursDateRangeLimit: true }
    ) {
      audienceKey
      externalId
      familyFileId
      userId
      residents {
        profileData {
          firstName
          lastName
        }
      }
      communities {
        id
        name
        address
        address2
        city
        zip
        state
        phoneNumber
        ratingAverage
        numberOfReviews
        reviews {
          rating
          score
          content
          createdAt
        }
        employees {
          firstName
          lastName
          cellPhone
          workPhone
          role
          isPrimaryContact
        }
      }
      user {
        userId
        role
        email
        firstName
        lastName
        workPhone
      }
      contacts {
        profileData {
          firstName
          lastName
          relationToResident
          emailAddress
          isPrimary
          emailAddresses {
            isPrimary
            emailAddress
          }
        }
      }
      tourDetailsExtended {
        familyCommunityTourId
        communityId
        scheduledTourDate
        tourType
        currentStatus
      }
      familyLetterNotes {
        familyLetterNoteId
        communityId
        createdAt
        createdBy
        updatedAt
        updatedBy
        subject
        introduction
        communityIntroduction
        communityNote
        closingParagraph
        template
        links
        sendTo
        additionalNotes
      }
      referralStatus {
        communityId
        isReferralStarted
        communityStatus
      }
    }
  }
`;
