import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from './MedicallyUrgentFilter.style';
import ChevronBottom from '../../Icons/arrow/ChevronBottom';
import ChevronTop from '../../Icons/arrow/ChevronTop';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Checkbox from '../../Checkbox/Checkbox';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const MedicallyUrgentFilter = ({
  labels,
  onChange,
  disabled,
  className,
  filters,
  title,
  size,
  flags,
}) => {
  const initialElements = [
    {
      label: `${labels.MEMORY_CARE}`,
      value: labels.MEMORY_CARE,
      checked: false,
    },
    ...(flags.professionalSource
      ? [
          {
            label: `${labels.PROFESSIONAL_SOURCE}`,
            value: labels.PROFESSIONAL_SOURCE,
            checked: false,
          },
        ]
      : []),
    {
      label: `${labels.CURRENTLY_IN_REHAB}`,
      value: labels.REHAB,
      checked: false,
    },
    {
      label: `${labels.CURRENTLY_IN_HOSPITAL}`,
      value: labels.HOSPITAL,
      checked: false,
    },
    {
      label: `${labels.LOOKING_FOR_REHAB}`,
      value: labels.YES,
      checked: false,
    },
  ];
  const classes = useStyles();
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [elements, setElements] = useState(initialElements);
  const selectClasses = clsx([
    className,
    classes.custom_select,
    disabled && classes.disabled,
    elements.filter((element) => element.checked).length > 0 && classes.filled,
  ]);
  let isChanged = useRef(false);

  const medicalUrgentClass = clsx([
    size === 'xs' ? classes.medical_urgent_xs : classes.medical_urgent,
  ]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
        if (isChanged.current) {
          const selectedItems = elements.filter((element) => element.checked);
          isChanged.current = false;
          onChange(selectedItems.map((item) => item));
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [elements, onChange, wrapperRef]);

  useEffect(() => {
    if (filters) {
      if (filters.length !== 0) {
        for (let i = 0; i < elements.length; i++) {
          for (let j = 0; j < filters.length; j++) {
            if (elements[i].label === filters[j].label) {
              elements[i] = filters[j];
            }
          }
        }
      } else {
        elements.forEach((element) => {
          element.checked = false;
        });
      }
      setElements([...elements]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleOptionList = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const getSelectedText = () => {
    const selectedItems = elements.filter((element) => element.checked);

    if (selectedItems.length === elements.length) {
      return `${labels.ALL_URGENCY_TYPES} (${selectedItems.length})`;
    }
    if (selectedItems.length > 1) {
      return `${labels.URGENCY_TYPES} (${selectedItems.length})`;
    }
    if (selectedItems.length === 1) {
      return selectedItems[0].label;
    }
    return labels.PLEASE_SELECT;
  };

  const handleCheckboxChange = (e, item) => {
    item.checked = e.target.checked;
    isChanged.current = true;
    setElements([...elements]);
  };

  const getSelectAllText = () => {
    return elements.some((item) => item.checked)
      ? labels.DESELECT_ALL
      : labels.SELECT_ALL;
  };

  const handleSelectAllOnChange = (e) => {
    const value = e.target.checked;

    const newElements = elements.map((element) => {
      element.checked = value;
      return element;
    });

    isChanged.current = true;
    setElements(newElements);
  };

  return (
    <div className={medicalUrgentClass}>
      <div className={selectClasses} ref={wrapperRef} data-testid="dropdown-id">
        <div className={classes.label}>{title}</div>
        <div className={classes.select} onClick={handleOptionList} tabIndex="0">
          <div>{getSelectedText()}</div>
          <div className={classes.icon}>
            {isOpen ? <ChevronTop /> : <ChevronBottom />}
          </div>
        </div>
        {isOpen && !disabled && (
          <div className={classes.menu}>
            <div className={classes.items_container}>
              <Checkbox
                className={classes.checkbox}
                indeterminate={elements.some((item) => item.checked)}
                checked={elements.some((item) => item.checked)}
                onChange={handleSelectAllOnChange}
                label={getSelectAllText()}
              />
              {elements.map((item, key) => (
                <div key={key}>
                  <div className={classes.group_item} key={key}>
                    <Checkbox
                      className={clsx(
                        classes.checkbox,
                        item.checked && classes.selected,
                      )}
                      checked={item.checked}
                      label={item.label}
                      onChange={(e) => handleCheckboxChange(e, item)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

MedicallyUrgentFilter.propTypes = {
  labels: PropTypes.object,
  title: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      checked: PropTypes.bool,
    }),
  ),
  flags: PropTypes.shape({
    professionalSource: PropTypes.bool,
  }),
};

MedicallyUrgentFilter.defaultProps = {
  disabled: false,
  className: '',
  onChange: () => {},
};

export default withLDConsumer()(MedicallyUrgentFilter);
