import { logEvent } from 'services/analyticsService';
import { getCurrentUserFromSessionStorage } from 'utils/utils';

const inFamilyFilePage = (location) => {
  const pathname = location.pathname;
  const paths = pathname.split('/');
  if (paths[paths.length - 2] === 'family-file') {
    return true;
  }
  return false;
};

const inSendReferralPage = (location) => {
  return location.pathname?.toLowerCase().includes('/send-referral/');
};

const logFamilyFilePageViewed = (
  familyFileId,
  externalId,
  screenName,
  disclosureStatus,
) => {
  let crmUserId = getCurrentUserFromSessionStorage()?.userId ?? '';
  let crmUserRole = getCurrentUserFromSessionStorage()?.role ?? '';

  logEvent('Family File Page Viewed', {
    familyFileId,
    crmUserId,
    crmUserRole,
    leadId: externalId,
    eventType: 'view',
    platform: 'beacon',
    channel: 'beacon',
    section: 'family file',
    screenName,
    'disclosure not completed': disclosureStatus,
  });
};

export { inFamilyFilePage, inSendReferralPage, logFamilyFilePageViewed };
