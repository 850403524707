import React from 'react';
import PropTypes from 'prop-types';
import UserContext from '../context/UserContext';
import userQuery from './userQuery';
import useGraphQLQuery from '../hooks/useGraphQLQuery';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import DocumentHead from '../common/DocumentHead/DocumentHead';
import { initializeAmplitudeService } from './analyticsService';
import { useLDClient } from 'launchdarkly-react-client-sdk';

const CurrentUser = ({ children, flags, onUserChanged }) => {
  const { loading, data } = useGraphQLQuery(userQuery);
  const ldClient = useLDClient();

  if (loading) {
    return <div>Loading...</div>;
  }

  let currentUser;

  if (data !== undefined) {
    currentUser = data.findUser;
    localStorage.setItem('CURRENT_USER', JSON.stringify(currentUser));
    initializeAmplitudeService(currentUser?.userId);

    const user = {
      key: currentUser?.email?.toLowerCase() ?? 'Unknown Email',
      name: currentUser
        ? currentUser.firstName + ' ' + currentUser.lastName
        : 'Unknown Name',
      email: currentUser?.email?.toLowerCase() ?? 'Unknown Email',
    };
    if (ldClient) {
      ldClient.identify(user);
    }
    onUserChanged(currentUser);
  }

  const email = () => currentUser?.email || '';

  const setInspectlet = () => {
    const appEnv = window.ENV.REACT_APP_ENVIRONMENT;
    let inspectletId;

    if (window.location.host === 'beacon.aplaceformom.com') {
      inspectletId = 1566334682;
    }
    if (window.location.host.match(/prod./gi)) {
      inspectletId = 769824948;
    }
    if (window.location.host.match(/qa./gi)) {
      inspectletId = 643193811;
    }
    if (appEnv !== 'dev' && appEnv !== 'local') {
      window.__insp = window.__insp || [];
      window.__insp.push(['wid', inspectletId]);
      window.__insp.push(['identify', email()]);
      var ldinsp = function () {
        if (typeof window.__inspld != 'undefined') return;
        window.__inspld = 1;
        var insp = document.createElement('script');
        insp.type = 'text/javascript';
        insp.async = true;
        insp.id = 'inspsync';
        insp.src =
          ('https:' === document.location.protocol ? 'https' : 'http') +
          `://cdn.inspectlet.com/inspectlet.js?wid=${inspectletId}&r=` +
          Math.floor(new Date().getTime() / 3600000);
        var x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(insp, x);
      };
      setTimeout(ldinsp, 0);
    }
  };

  return (
    <UserContext.Provider value={currentUser}>
      <DocumentHead>
        <script type="text/javascript">
          {flags.inspectlet
            ? (function () {
                setInspectlet();
              })()
            : null}
        </script>
      </DocumentHead>
      {children}
    </UserContext.Provider>
  );
};

CurrentUser.propTypes = {
  children: PropTypes.element,
  flags: PropTypes.shape({
    inspectlet: PropTypes.bool,
  }),
  onUserChanged: PropTypes.func,
};

export default withLDConsumer()(CurrentUser);
