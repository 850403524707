import { PhoneContext } from '@aplaceformom/owl-connect-client';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CallAdd from 'common/Icons/call/CallAdd';
import CallHold from 'common/Icons/call/CallHold';
import MicNo from 'common/Icons/basic/MicNo';
import { withLabels } from 'context/LabelContext';
import CallButton from './CallButton/CallButton';
import KeypadCallButton from './CallButton/KeypadCallButton';
import { useStyles } from './ConferenceCallButtons.style';
import Redial from 'common/Icons/call/CallRedial';
import ConferenceCallButton from './ConferenceCallButton/ConferenceCallButton';
import { callBarScreens, CallBarScreenType } from '../callBarScreenEnum';
import { logConferenceCallClicked } from 'services/callCenter/amplitudeEventsService';
import { selectInquiry } from 'stateManagement/callcenter/inquiry/reducers/inquiryReducer';

const ConferenceCallButtons = ({
  labels,
  onEndCall,
  callStatus,
  onRedial,
  onConferenceCall,
  screen,
}) => {
  const classes = useStyles();
  const { callData, callState, setHolding, setMute } = useContext(PhoneContext);
  const { inquiry } = useSelector(selectInquiry);

  const [callHoldActive, setCallHoldActive] = useState(false);
  const [callMuteActive, setCallMuteActive] = useState(false);

  const isCallOngoing = callData && Object.keys(callData).length > 0;
  const isDisabledLeft =
    (callState !== 'TALKING' && callState !== 'ON_HOLD') ||
    screen === callBarScreens.WAITING;

  const isDisabledRight = !isCallOngoing || screen === callBarScreens.WAITING;

  useEffect(() => {
    if (callStatus === 'DISCONNECTED') {
      setCallHoldActive(false);
      setCallMuteActive(false);
    }
  }, [callStatus]);

  const onHoldCall = () => {
    setCallHoldActive(!callHoldActive);
    setHolding(!callHoldActive);
  };

  const onMuteCall = () => {
    setCallMuteActive(!callMuteActive);
    setMute(!callMuteActive);
  };

  const onCallEnded = () => {
    if (!isDisabledRight) {
      onEndCall('endCall', 'hangup');
    }
  };

  const onConferenceCallClicked = () => {
    logConferenceCallClicked(inquiry?.inquiryId, inquiry?.familyFileId);
    onConferenceCall();
  };

  return (
    <div className={classes.conference_call_buttons_container}>
      <div className={clsx(classes.left, isDisabledLeft && classes.disabled)}>
        <CallButton
          icon={<MicNo />}
          title={labels.MUTE}
          disabled={isDisabledLeft}
          active={callMuteActive}
          onClick={onMuteCall}
        />
        <CallButton
          icon={<CallHold />}
          title={labels.HOLD}
          disabled={isDisabledLeft}
          active={callHoldActive}
          onClick={onHoldCall}
        />
        <CallButton
          icon={<CallAdd />}
          title={labels.CONFERENCE_CALL}
          disabled={isDisabledLeft}
          onClick={onConferenceCallClicked}
        />
        <KeypadCallButton labels={labels} disabled={isDisabledLeft} />
      </div>
      <div>
        {callState === 'WRAP_UP' && (
          <ConferenceCallButton
            title={labels.REDIAL}
            icon={<Redial />}
            color="cosmicCobalt"
            disabled={isDisabledRight}
            onClick={onRedial}
          />
        )}
        {callState !== 'WRAP_UP' && (
          <ConferenceCallButton
            title={labels.HANG_UP}
            color="smokyTopaz"
            disabled={isDisabledRight}
            onClick={onCallEnded}
          />
        )}
      </div>
    </div>
  );
};

ConferenceCallButtons.propTypes = {
  labels: PropTypes.object,
  onEndCall: PropTypes.func,
  onConferenceCall: PropTypes.func,
  callStatus: PropTypes.string,
  onRedial: PropTypes.func,
  screen: CallBarScreenType.isRequired,
};

ConferenceCallButtons.defaultProps = {
  onEndCall: () => {},
  onConferenceCall: () => {},
  callStatus: '',
  onRedial: () => {},
};

export default withLabels(ConferenceCallButtons);
