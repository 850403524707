import React from 'react';
import PropTypes from 'prop-types';
import { withLabels } from '../../context/LabelContext';
import { useStyles } from './CongratulationsBox.style';
import SurpassingPoint from './SurpassingPoint';
import { MessagePoint } from './MessagePoint';
import Media from '../../common/Media/Media';

const CongratulationsBoxDisplay = ({
  userGivenName,
  points,
  onClick,
  size,
  labels,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.current_point_container}>
      <div className={classes.left_content}>
        <MessagePoint userGivenName={userGivenName} labels={labels} />
      </div>
      <div className={classes.right_content}>
        <SurpassingPoint
          points={points}
          onClick={onClick}
          size={size}
          labels={labels}
        />
      </div>
    </div>
  );
};

const CongratulationsBox = ({ userGivenName, points, onClick, labels }) => {
  return (
    <Media
      xs={
        <CongratulationsBoxDisplay
          userGivenName={userGivenName}
          points={points}
          onClick={onClick}
          size={'xs'}
          labels={labels}
        />
      }
      sm={
        <CongratulationsBoxDisplay
          userGivenName={userGivenName}
          points={points}
          onClick={onClick}
          size={'sm'}
          labels={labels}
        />
      }
      md={
        <CongratulationsBoxDisplay
          userGivenName={userGivenName}
          points={points}
          onClick={onClick}
          size={'md'}
          labels={labels}
        />
      }
      lg={
        <CongratulationsBoxDisplay
          userGivenName={userGivenName}
          points={points}
          onClick={onClick}
          labels={labels}
          size={'lg'}
        />
      }
    />
  );
};

CongratulationsBoxDisplay.propTypes = {
  labels: PropTypes.object.isRequired,
  userGivenName: PropTypes.string,
  points: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['default', 'xs', 'sm', 'md', 'lg']),
};

CongratulationsBoxDisplay.defaultProps = {
  size: 'default',
};

CongratulationsBox.propTypes = {
  labels: PropTypes.object.isRequired,
  userGivenName: PropTypes.string,
  points: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

export default withLabels(CongratulationsBox);
export { CongratulationsBox, CongratulationsBoxDisplay };
