import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  locations_container: {
    display: 'inline-flex',
  },
  pipe: {
    color: theme.palette.dark.disabled,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  more_than_two_locations_tooltip: {
    widt: '100%',
    lineHeight: '19px',
  },
  more_than_two_locations_label: {
    display: 'inline-flex',
  },
}));

export { useStyles };
