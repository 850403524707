import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/Modal/Modal';
import Typography from 'common/Typography/Typography';
import { useStyles } from './PipedaPopup.style';
import Button from 'common/Button/Button';

const PipedaPopup = ({ labels, isOpen, onClose }) => {
  const classes = useStyles();
  return (
    <Modal
      className={classes.modal_container}
      title="PIPEDA Script : If There is NO Email Address"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={classes.modal_body}>
        <Typography className={classes.pipeda_label} color="eerieBlack5">
          {labels.FIRST_PIPEDA_PARAGRAPH}
        </Typography>
        <Typography className={classes.pipeda_label} color="eerieBlack5" bold>
          {labels.PIPEDA_ENTIRETY}
        </Typography>
        <Typography className={classes.pipeda_label} color="eerieBlack5">
          {labels.SECOND_PIPEDA_PARAGRAPH}
        </Typography>
        <Typography className={classes.pipeda_label} color="eerieBlack5">
          {labels.THIRD_PIPEDA_PARAGRAPH}
        </Typography>
        <Typography className={classes.pipeda_label} color="eerieBlack5">
          {labels.FOURTH_PIPEDA_PARAGRAPH}
        </Typography>
        <Typography className={classes.pipeda_label} color="eerieBlack5">
          {labels.FIFTH_PIPEDA_PARAGRAPH}
        </Typography>
        <Typography className={classes.pipeda_label} color="eerieBlack5">
          {labels.SIXTH_PIPEDA_PARAGRAPH}
        </Typography>
        <Typography className={classes.pipeda_label} color="eerieBlack5">
          {labels.SEVENTH_PIPEDA_PARAGRAPH}
        </Typography>
        <Typography className={classes.pipeda_label} color="eerieBlack5">
          {labels.EIGHTH_PIPEDA_PARAGRAPH}
        </Typography>
        <Typography className={classes.pipeda_label} color="eerieBlack5">
          {labels.NINETH_PIPEDA_PARAGRAPH}
        </Typography>
        <Typography className={classes.pipeda_label} color="eerieBlack5">
          {labels.TENTH_PIPEDA_PARAGRAPH}
        </Typography>
        <Typography className={classes.pipeda_label} color="eerieBlack5">
          {labels.ELEVENTH_PIPEDA_PARAGRAPH}
        </Typography>
        <Typography className={classes.pipeda_label} color="eerieBlack5">
          {labels.TWELVETH_PIPEDA_PARAGRAPH}
        </Typography>
        <Typography className={classes.pipeda_label} color="eerieBlack5" bold>
          {labels.IF_INTERRUPTED}
        </Typography>
        <Typography className={classes.pipeda_label} color="eerieBlack5">
          {labels.THIRTEENTH_PIPEDA_PARAGRAPH}
        </Typography>
        <Typography className={classes.pipeda_label} color="eerieBlack5" bold>
          {labels.IF_ADDITIONAL_INFORMATION}
        </Typography>
        <Typography className={classes.pipeda_label} color="eerieBlack5">
          {labels.FOURTEENTH_PIPEDA_PARAGRAPH}
        </Typography>
        <ul>
          <li>
            <Typography className={classes.pipeda_label} color="eerieBlack5">
              {labels.FIFTEENTH_PIPEDA_PARAGRAPH}
            </Typography>
          </li>
          <li>
            <Typography className={classes.pipeda_label} color="eerieBlack5">
              {labels.SIXTEENTH_PIPEDA_PARAGRAPH}
            </Typography>
          </li>
          <li>
            <Typography className={classes.pipeda_label} color="eerieBlack5">
              {labels.SEVENTEENTH_PIPEDA_PARAGRAPH}
            </Typography>
          </li>
          <li>
            <Typography className={classes.pipeda_label} color="eerieBlack5">
              {labels.EIGHTEENTH_PIPEDA_PARAGRAPH}
            </Typography>
          </li>
          <li>
            <Typography className={classes.pipeda_label} color="eerieBlack5">
              {labels.NINETEENTH_PIPEDA_PARAGRAPH}
            </Typography>
          </li>
          <li>
            <Typography className={classes.pipeda_label} color="eerieBlack5">
              {labels.TWENTIETH_PIPEDA_PARAGRAPH}
            </Typography>
          </li>
        </ul>
      </div>
      <div className={classes.modal_footer}>
        <Button type="primary" size="small" onClick={onClose}>
          {labels.DISCLOSURE_READ}
        </Button>
      </div>
    </Modal>
  );
};
PipedaPopup.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  labels: PropTypes.object,
};
export default PipedaPopup;
