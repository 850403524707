const findInquiriesByParamsQuery = `
  query findInquiriesByParams($inquirySearch: InquirySearch!) {
    findInquiriesByParams(inquirySearch: $inquirySearch) {
      inquiries {
        inquiryId
        familyFileId
        firstName
        lastName
        phone
        email
        oneId
        relationToResident
        audienceKey
        status
        statusDetail
        closedReason
        createdAt
      }
      inquirySearchParam {
        size
        page
        orderBy
        sort
        count
      }
    }
  }`;

export default findInquiriesByParamsQuery;
