import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Divider from 'common/Divider/Divider';
import customChicletsService from 'services/callCenter/customChicletsService';
import { poolTypes } from 'services/callCenter/fieldsMapper';
import ButtonBar from '../common/ButtonBar/ButtonBar';
import ViewRow from '../common/ViewRow/ViewRow';
import { AnswerPropType, StepPropType } from '../scriptBuilderPropTypes';
import { useStyles } from './AnswerView.style';

const AnswerView = ({
  step,
  answer,
  steps,
  onEdit,
  onDelete,
  onDuplicate,
  labels,
  catalogs,
}) => {
  const classes = useStyles();

  const calculateNextStepsAndEndCall = (steps, answer, endsTheForm) => {
    const next = steps.find(
      (s) => s.number.toString() === answer.nextStep.toString(),
    );
    let nextStep = next ? `${next.order}. ${next.name}` : '';
    let endCall = labels.ANSWER_END_FORM;

    if (endsTheForm) {
      if (answer.nextStep.toString() === '-1') {
        nextStep = labels.ANSWER_END_FORM;
      } else {
        nextStep = next
          ? labels.ANSWER_END_CALL.replace(
              '{NEXT_STEP}',
              `${next.order}. ${next.name}`,
            )
          : '';
      }
      endCall = labels.ANSWER_END_FORM_VIEW.replace(
        '{DISPOSITION}',
        disposition ? disposition.label : labels.DISPOSITION_NOT_SET,
      );
    }
    return { nextStep, endCall };
  };

  const getSteps = (arrayName) => {
    return answer[arrayName]
      .sort((a, b) => a - b)
      .map((stepNumber) => {
        const step = steps.find(
          (s) => s.number.toString() === stepNumber.toString(),
        );
        if (step) {
          return (
            <div
              key={`${arrayName}_${stepNumber}`}
            >{`${step.order}. ${step.name}`}</div>
          );
        } else {
          return (
            <div
              key={`${arrayName}_${stepNumber}`}
            >{`Step with number ${stepNumber} not found`}</div>
          );
        }
      });
  };

  const disposition = catalogs.dispositions.find(
    (d) => d.value === answer.disposition,
  );

  const answerNextStep = steps.find(
    (s) => s.number.toString() === answer.nextStep.toString(),
  );

  const endsTheForm =
    answer.nextStep.toString() === '-1' || answerNextStep?.type === 'endCall';

  const { nextStep, endCall } = calculateNextStepsAndEndCall(
    steps,
    answer,
    endsTheForm,
  );

  const isCustomizedChiclet = customChicletsService.isCustomChiclet(step.type);

  const closeInquiry = answer.closeInquiry
    ? labels.CLOSES_INQUIRY_VIEW.replace('{REASON}', answer.closeReason)
    : labels.CLOSES_INQUIRY;

  const getPoolTypeLabel = () => {
    return poolTypes.find((s) => s.value === answer.poolType?.toString())
      ?.label;
  };

  return (
    <div className={clsx({ [classes.answer]: true })}>
      <ButtonBar
        onDelete={!isCustomizedChiclet ? () => onDelete(answer) : null}
        onDuplicate={() => onDuplicate(answer)}
        onEdit={() => onEdit(answer)}
        duplicateLabel={labels.DUPLICATE_ANSWER}
        labels={labels}
      />
      <div className={classes.answer_body}>
        <ViewRow label={labels.ORDER} value={answer.order} />
        <ViewRow label={labels.NEXT_STEP} value={nextStep} />
        <ViewRow label={labels.LABEL} value={answer.label} />
        <ViewRow label={labels.VALUE} value={answer.value} />
        <ViewRow label={labels.ACTION} value={answer.action} />
        <ViewRow label={labels.LINK} value={answer.urlLink} />
        <ViewRow label={labels.POOL_TYPE} value={getPoolTypeLabel()} />
        <ViewRow
          label={labels.SELECTED_RULE}
          value={answer.selectedRule.join(' ')}
        />
        <ViewRow label={labels.ANSWER_SHOWS} value={getSteps('shows')} />
        <ViewRow label={labels.ANSWER_HIDES} value={getSteps('hides')} />
        <ViewRow label={closeInquiry} value={answer.closeInquiry} />
        <ViewRow label={endCall} value={endsTheForm} />
        <ViewRow
          label={labels.ADD_TO_DO_NOT_CALL}
          value={answer.addToDoNotCall}
        />
      </div>
      <Divider />
      <div className={classes.answer_body}>
        <ViewRow
          label={labels.CREATED_BY}
          value={
            answer.createdBy?.firstName && answer.createdBy?.lastName
              ? `${answer.createdBy?.firstName} ${answer.createdBy?.lastName}`
              : undefined
          }
        />
        <ViewRow label={labels.CREATED_AT} value={answer.createdAt} date />
        <ViewRow
          label={labels.UPDATED_BY}
          value={
            answer.updatedBy?.firstName && answer.updatedBy?.lastName
              ? `${answer.updatedBy?.firstName} ${answer.updatedBy?.lastName}`
              : undefined
          }
        />
        <ViewRow label={labels.UPDATED_AT} value={answer.updatedAt} date />
      </div>
    </div>
  );
};

AnswerView.propTypes = {
  step: StepPropType,
  answer: AnswerPropType,
  steps: PropTypes.arrayOf(StepPropType),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onDuplicate: PropTypes.func,
  labels: PropTypes.object,
  catalogs: PropTypes.object,
};

export default AnswerView;
