import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './NotesSidePanel.style';
import Typography from '../../common/Typography/Typography';
import Link from '../../common/Link/Link';

const MAX_CHARS = 250;
const NoteCard = ({
  date,
  isKeynote,
  labels,
  text,
  userFullName,
  userIsActive,
}) => {
  const classes = useStyles();
  const isLongText = text.length > MAX_CHARS;
  const [showReadMore, setShowReadMore] = useState(isLongText);
  let notePreview = '';
  if (isLongText) {
    notePreview = text.substring(0, MAX_CHARS);
  }

  const displayNote = () => {
    if (showReadMore) {
      return (
        <>
          <Typography className={classes.note_text}>{notePreview}</Typography>
          <Link
            className={classes.read_more}
            onClick={(e) => {
              e.preventDefault();
              setShowReadMore(false);
            }}
          >
            <Typography level={'small'} color={'cosmicCobalt3'}>
              {` ${labels.READ_MORE.toLowerCase()}`}
            </Typography>
          </Link>
        </>
      );
    }
    if (isLongText && !showReadMore) {
      return (
        <>
          <Typography className={classes.note_text}>{text}</Typography>
          <Link
            className={classes.read_more}
            onClick={() => {
              setShowReadMore(true);
            }}
          >
            <Typography level={'small'} color={'cosmicCobalt3'}>
              {` ${labels.SHOW_LESS.toLowerCase()}`}
            </Typography>
          </Link>
        </>
      );
    }
    return <Typography className={classes.note_text}>{text}</Typography>;
  };

  return (
    <div className={classes.notes_row_container}>
      {isKeynote && (
        <>
          <div className={classes.keynote_header}>
            <div>
              {userIsActive ? (
                <Typography className={classes.keynote_title} level={'small'}>
                  {labels.KEYNOTE} {userFullName ? `by ${userFullName}` : ''}
                </Typography>
              ) : (
                <Typography className={classes.inactive_user} level={'small'}>
                  {labels.INACTIVE_USER}
                </Typography>
              )}
            </div>
            <div>
              <Typography level={'small'} color={'eerieBlack5'}>
                {labels.LAST_UPDATED}: {date}
              </Typography>
            </div>
          </div>
        </>
      )}
      {!isKeynote && (
        <div className={classes.note_header}>
          {userFullName && userIsActive ? (
            <Typography level={'small'} bold>
              {userFullName}
            </Typography>
          ) : (
            <Typography level={'small'} color={'smokyTopaz1'}>
              {labels.INACTIVE_USER}
            </Typography>
          )}
          <Typography level={'small'} color={'eerieBlack5'}>
            {date}
          </Typography>
        </div>
      )}
      {displayNote()}
    </div>
  );
};

NoteCard.propTypes = {
  date: PropTypes.string,
  isKeynote: PropTypes.bool,
  userIsActive: PropTypes.bool,
  labels: PropTypes.object,
  text: PropTypes.string,
  userFullName: PropTypes.string,
};

NoteCard.defaultProps = {
  isKeynote: false,
};

export default NoteCard;
