import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  header_title: {
    marginBottom: theme.spacing(1),
    '& > div': {
      paddingTop: '0',
    },
  },
  item: {
    padding: [['0'], '!important'],
  },
}));

export { useStyles };
