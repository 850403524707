import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './TopContainer.style';
import HeaderTitle from '../../components/HeaderTitle/HeaderTitle';
import Container from '../../common/Container/Container';
import ContainerItem from '../../common/ContainerItem/ContainerItem';
import isSameSize from '../../utils/sizesHelper';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const TopContainer = ({
  labels,
  size,
  quotaFlipHelper,
  scheduledToursDuringInitialConsult,
  secondRoundReferrals,
}) => {
  const classes = useStyles();

  const spacing = isSameSize(size, 'lg') ? 4 : 2;
  const spacingForMetrics =
    isSameSize(size, 'md') || isSameSize(size, 'sm') ? 3 : spacing;

  return (
    <>
      <HeaderTitle
        title={labels.YOUR_PERFORMANCE}
        subtitle={labels.HERES_WHAT_WE_HAVE}
        className={classes.header}
      />
      <Container spacing={spacing}>
        <ContainerItem
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
          className={classes.item}
        >
          {quotaFlipHelper}
        </ContainerItem>
        <ContainerItem
          item
          xs={12}
          sm={12}
          md={12}
          lg={8}
          className={classes.item}
        >
          <Container container spacing={spacingForMetrics}>
            <ContainerItem
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              className={classes.item}
            >
              {scheduledToursDuringInitialConsult}
            </ContainerItem>
            <ContainerItem
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              className={classes.item}
            >
              {secondRoundReferrals}
            </ContainerItem>
          </Container>
        </ContainerItem>
      </Container>
    </>
  );
};

TopContainer.propTypes = {
  labels: PropTypes.object.isRequired,
  size: PropTypes.string.isRequired,
  quotaFlipHelper: PropTypes.element.isRequired,
  scheduledToursDuringInitialConsult: PropTypes.element.isRequired,
  secondRoundReferrals: PropTypes.element.isRequired,
};

export default withLDConsumer()(TopContainer);
