import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  community_for_referral_container: {
    width: '100%',
    paddingLeft: 16,
  },
  additional_container: {
    display: 'flex',
    '& > *': {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '50%',
    },

    '& > .notes': {
      marginRight: '36px',
    },
    '& > .tour': {
      alignSelf: 'flex-end',

      '& > .include_tour': {
        marginBottom: '12px',
      },
    },
  },
  header: {
    width: '100%',
    flexDirection: 'column',
  },
  header_container: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    '& svg': {
      fontSize: '14.67px',
    },
  },
  scheduled_tour_toast: {
    maxWidth: '789px',
    marginBottom: '16px',
  },
  column: {
    width: '45%',
  },
  toast_message: {
    marginTop: theme.spacing(2),
  },
  tour_change_message: {
    marginBottom: '12px',
    marginTop: '16px',
  },
  radio_buttons_container: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    width: '100%',
  },
  selected_tour_time_container: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(4),
    width: '100%',
  },
  modify_tour_label: {
    marginBottom: theme.spacing(0.5),
  },
  modify_action_options: {
    '& label': {
      marginRight: theme.spacing(4),
    },
  },
  tour_schedule_column: {
    marginTop: 16,
  },
  tour_schedule_container: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  button_container: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '32px',
  },
  remove_tour_info_container: {
    marginLeft: '16px',
  },
  remove_tour_time: {
    cursor: 'pointer',
  },
  additional_message: {
    marginTop: 4,
  },
  referral_type: {
    marginTop: 4,
  },
  full_width: {
    width: '100%',
  },
  tag_margin: {
    marginLeft: '2%',
  },
}));

export { useStyles };
