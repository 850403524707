import gql from 'graphql-tag';

export default gql`
  mutation(
    $scriptId: Int!
    $stepId: Int
    $number: Int
    $order: Int!
    $name: String!
    $size: Int!
    $text: String
    $type: String!
    $isHidden: Boolean
    $isLegal: Boolean
    $isOptional: Boolean
    $isPipeda: Boolean
    $alternateText: String
    $property: String
    $answers: [AnswerInput]
  ) {
    saveStep(
      input: {
        scriptId: $scriptId
        stepId: $stepId
        number: $number
        order: $order
        name: $name
        size: $size
        text: $text
        type: $type
        isHidden: $isHidden
        isLegal: $isLegal
        isOptional: $isOptional
        isPipeda: $isPipeda
        alternateText: $alternateText
        property: $property
        answers: $answers
      }
    ) {
      scriptId
      stepId
    }
  }
`;
