import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { registerPage } from '../../services/Analytics';
import { useStyles } from './FamilyFileList.style';
import { withLabels } from '../../context/LabelContext';
import Container from '../../common/Container/Container';
import ContainerItem from '../../common/ContainerItem/ContainerItem';
import Typography from '../../common/Typography/Typography';
import { useGraphQLLazyQuery } from '../../hooks/useGraphQLQuery';
import familyFileListQuery from './FamilyFileListQuery';
import familyFileListToursQuery from './FamilyFileListToursQuery';
import familyFileListClosedQuery from './FamilyFileListClosedQuery';
import {
  toRowInformation,
  toTourRowInformation,
} from '../../components/HomeTable/HomeTableService';
import EmptyTable from '../../components/HomeTable/EmptyTable';
import Loading from '../../components/Loading/Loading';
import CaughtUp from '../../common/Graphics/CaughtUp';
import { registerEvent } from '../../services/Analytics';
import Media from '../../common/Media/Media';
import HomeTableWithRows, {
  DEFAULT_PAGE_SIZE,
} from '../../components/HomeTable/HomeTableWithRows';
import HomeTableWithRowsMobile from '../../components/HomeTable/HomeTableWithRowsMobile';
import HeaderTitle from '../../components/HeaderTitle/HeaderTitle';
import { SalesPhaseBucket } from '../../components/NavigationSidePanel/NavigationSidePanel';
import { getYearsAgoInDays } from '../../utils/dateUtils';

const TOUR_PRIOR_DAYS = 7;
const CLOSED_PRIOR_DAYS = getYearsAgoInDays(5);
const OLDER_REFERRALS_DAYS = 9999;
const RECENT_REFERRALS_DAYS = 26;

let bucketId;
let totalFamilyFilesAvailable = undefined;
let savedPageSize = DEFAULT_PAGE_SIZE;
let initialSalesPhases = [];
let initialSalesPhaseFilter = [];
let title = '';
let subTitle = '';
let salesPhases = [];
let medicallyUrgentStatuses = [];
let referralStatusType = '';
let columns = [];

const initialiseBucketDetails = (bucketId, labels) => {
  switch (bucketId) {
    case SalesPhaseBucket.NEW_PREFERRED:
      totalFamilyFilesAvailable = undefined;
      initialSalesPhases = ['New Lead', 'Pre Referral Cnslt.'];
      initialSalesPhaseFilter = [];
      title = labels.NEW_AND_PRE_REFERRED;
      subTitle = labels.NEW_AND_PRE_REFERRED_DESCRIPTION;
      medicallyUrgentStatuses = [];
      referralStatusType = '';
      break;
    case SalesPhaseBucket.RECENT_REFERRALS:
      totalFamilyFilesAvailable = undefined;
      initialSalesPhases = [
        'Referral Sent',
        'Scheduled Tour',
        'Post-Tour',
        'Deposit/Community Fee Paid',
      ];
      initialSalesPhaseFilter = [];
      title = labels.RECENTLY_REFERRED;
      subTitle = labels.RECENTLY_REFERRED_DESCRIPTION;
      medicallyUrgentStatuses = [];
      referralStatusType = 'RecentlyReferred';
      break;
    case SalesPhaseBucket.OLDER_REFERRALS:
      totalFamilyFilesAvailable = undefined;
      initialSalesPhases = [
        'Referral Sent',
        'Scheduled Tour',
        'Post-Tour',
        'Deposit/Community Fee Paid',
      ];
      initialSalesPhaseFilter = [];
      title = labels.OLDER_REFERRALS;
      subTitle = labels.OLDER_REFERRALS_DESCRIPTION;
      medicallyUrgentStatuses = [];
      referralStatusType = 'PostReferral';
      break;
    case SalesPhaseBucket.MOVEINS:
      totalFamilyFilesAvailable = undefined;
      initialSalesPhases = [
        'Wait List',
        'Move-In Process',
        'Awaiting Decision',
      ];
      initialSalesPhaseFilter = [];
      title = labels.MOVE_INS;
      subTitle = labels.MOVE_INS_DESCRIPTION;
      medicallyUrgentStatuses = [];
      referralStatusType = '';
      break;
    case SalesPhaseBucket.TOURING:
      totalFamilyFilesAvailable = undefined;
      initialSalesPhases = []; // These a defined in the service.
      initialSalesPhaseFilter = [];
      title = labels.TOURING;
      subTitle = labels.TOURING_DESCRIPTION;
      medicallyUrgentStatuses = [];
      referralStatusType = '';
      break;
    case SalesPhaseBucket.CLOSED:
      totalFamilyFilesAvailable = undefined;
      initialSalesPhases = []; // These a defined in the service.
      initialSalesPhaseFilter = [];
      title = labels.CLOSED;
      subTitle = labels.CLOSED_DESCRIPTION;
      medicallyUrgentStatuses = [];
      referralStatusType = '';
      break;
    default:
      totalFamilyFilesAvailable = undefined;
      initialSalesPhases = [];
      initialSalesPhaseFilter = [];
      title = '';
      subTitle = '';
      medicallyUrgentStatuses = [];
      referralStatusType = '';
      break;
  }
};

let emptyLabels = {
  EMPTY_HEADER: '',
  EMPTY_DESCRIPTION_LINE1: '',
  EMPTY_DESCRIPTION_LINE2: '',
};
const initialiseEmptyLabels = (labels) => {
  return {
    EMPTY_HEADER: labels.FAMILY_FILE_EMPTY_HEADER,
    EMPTY_DESCRIPTION_LINE1: labels.FAMILY_FILE_EMPTY_DESCRIPTION_LINE1,
    EMPTY_DESCRIPTION_LINE2: labels.FAMILY_FILE_EMPTY_DESCRIPTION_LINE2,
  };
};

const FamilyFileList = ({ labels }) => {
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    registerPage('/familyFileList');
    totalFamilyFilesAvailable = undefined;
  }, []);

  useEffect(() => {
    emptyLabels = initialiseEmptyLabels(labels);
  }, [labels]);

  useEffect(() => {
    bucketId = parseInt(location.search.split('=')[1], 10);

    initialiseBucketDetails(bucketId, labels);
    setSalesPhaseFilter(initialSalesPhaseFilter);
    setMedicallyUrgentFilter([]);

    if (bucketId === SalesPhaseBucket.CLOSED) {
      columns = ['contact', 'salesPhase', 'lastContacted'];
      getFamilyFiles({
        variables: {
          daysFrom: CLOSED_PRIOR_DAYS,
          lastCallSortOrder: defaultSort,
          medicallyUrgentStatuses,
          pageNumber,
          pageSize,
        },
      });
    } else if (bucketId === SalesPhaseBucket.TOURING) {
      columns = ['contact', 'salesPhase', 'tourDetails'];
      getFamilyFiles({
        variables: {
          dateSortOrder: defaultSort,
          daysFrom: TOUR_PRIOR_DAYS,
          medicallyUrgentStatuses,
          pageNumber,
          pageSize,
        },
      });
    } else if (bucketId === SalesPhaseBucket.OLDER_REFERRALS) {
      columns = ['contact', 'salesPhase', 'lastContacted'];
      getFamilyFiles({
        variables: {
          daysFrom: OLDER_REFERRALS_DAYS,
          lastCallSortOrder: defaultSort,
          medicallyUrgentStatuses,
          pageNumber,
          pageSize,
          referralStatusType,
          salesPhases: initialSalesPhases,
        },
      });
    } else {
      columns = ['contact', 'salesPhase', 'lastContacted'];
      getFamilyFiles({
        variables: {
          lastCallSortOrder: defaultSort,
          medicallyUrgentStatuses,
          pageNumber,
          pageSize,
          referralStatusType,
          salesPhases: initialSalesPhases,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, labels]);

  const [medicallyUrgentFilter, setMedicallyUrgentFilter] = useState([]);

  const [salesPhaseFilter, setSalesPhaseFilter] = useState(
    initialSalesPhaseFilter,
  );

  const defaultSort = 'desc';
  const [sortOrder, setSortOrder] = useState(defaultSort);

  const [pageSize, setPageSize] = useState(savedPageSize);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [familyFiles, setFamilyFiles] = useState([]);

  let query;
  if (bucketId === SalesPhaseBucket.CLOSED) {
    query = familyFileListClosedQuery;
  } else if (bucketId === SalesPhaseBucket.TOURING) {
    query = familyFileListToursQuery;
  } else {
    query = familyFileListQuery;
  }
  const [getFamilyFiles, { loading, error, data }] = useGraphQLLazyQuery(query);

  let loadingStatus = 'loading';
  if (loading) {
    loadingStatus = 'loading';
  } else {
    if (error) {
      loadingStatus = 'error';
      console.error(
        `FamilyFileList. Error loading. Error: ${JSON.stringify(error)}`,
      );
    } else {
      loadingStatus = 'loaded';
    }
  }

  const changeLastCallSortOrder = () => {
    if (sortOrder === 'desc') {
      registerEvent('FamilyFiles', 'Sort by last call', 'asc');
      setSortOrder('asc');
    } else {
      registerEvent('FamilyFiles', 'Sort by last call', 'desc');
      setSortOrder('desc');
    }
  };

  const changeTourDateSortOrder = () => {
    if (sortOrder === 'desc') {
      registerEvent('FamilyFiles', 'Sort by tour details', 'asc');
      setSortOrder('asc');
    } else {
      registerEvent('FamilyFiles', 'Sort by tour details', 'desc');
      setSortOrder('desc');
    }
  };

  const handleSortOrderChange = () => {
    if (bucketId === SalesPhaseBucket.TOURING) {
      changeTourDateSortOrder();
    } else {
      changeLastCallSortOrder();
    }
  };

  useEffect(() => {
    let selectedSalesPhases = salesPhaseFilter
      .filter((filter) => filter.checked)
      .map((filter) => filter.value);
    if (selectedSalesPhases.length === 0) {
      selectedSalesPhases = initialSalesPhases;
    }
    salesPhases = selectedSalesPhases;

    medicallyUrgentStatuses = medicallyUrgentFilter
      .filter((filter) => filter.checked)
      .map((filter) => filter.label);

    if (bucketId === SalesPhaseBucket.CLOSED) {
      columns = ['contact', 'salesPhase', 'lastContacted'];
      getFamilyFiles({
        variables: {
          daysFrom: CLOSED_PRIOR_DAYS,
          lastCallSortOrder: sortOrder,
          medicallyUrgentStatuses,
          pageNumber,
          pageSize,
        },
      });
    } else if (bucketId === SalesPhaseBucket.TOURING) {
      getFamilyFiles({
        variables: {
          dateSortOrder: sortOrder,
          daysFrom: TOUR_PRIOR_DAYS,
          medicallyUrgentStatuses,
          pageNumber,
          pageSize,
        },
      });
    } else if (bucketId === SalesPhaseBucket.RECENT_REFERRALS) {
      getFamilyFiles({
        variables: {
          daysFrom: RECENT_REFERRALS_DAYS,
          lastCallSortOrder: sortOrder,
          medicallyUrgentStatuses,
          pageNumber,
          pageSize,
          referralStatusType,
          salesPhases,
        },
      });
    } else {
      getFamilyFiles({
        variables: {
          lastCallSortOrder: sortOrder,
          medicallyUrgentStatuses,
          pageNumber,
          pageSize,
          referralStatusType,
          salesPhases,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sortOrder,
    medicallyUrgentFilter,
    pageNumber,
    pageSize,
    salesPhaseFilter,
  ]);

  useEffect(() => {
    if (data !== undefined) {
      processFamilyFileData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, pageSize, labels]);

  const onMedicallyUrgentFilterChange = (items) => {
    setMedicallyUrgentFilter(items);

    setPageNumber(1);
  };

  const onSalesPhaseFilterChange = (items) => {
    const newSalesPhaseFilter = salesPhaseFilter.map((filter) => {
      const item = items.find(
        (salesPhase) => filter.value === salesPhase.value,
      );
      return item || { ...filter, ...{ checked: false } };
    });
    setSalesPhaseFilter(newSalesPhaseFilter);

    setPageNumber(1);
  };

  const onPageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    savedPageSize = newPageSize;
    let filteredCount = 0;
    if (bucketId === SalesPhaseBucket.CLOSED) {
      filteredCount = data.findFamilyFilesClosed.filteredCount;
    } else if (bucketId === SalesPhaseBucket.TOURING) {
      filteredCount = data.findFamilyFilesWithTours.filteredCount;
    } else {
      filteredCount = data.findFamilyFilesByParams.filteredCount;
    }
    const newPageCount = Math.ceil(filteredCount / newPageSize);
    setPageCount(newPageCount);
    setPageNumber(1);
  };

  const onPageNumberChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const processFamilyFileData = () => {
    let familyFileData;
    if (bucketId === SalesPhaseBucket.CLOSED) {
      familyFileData = data.findFamilyFilesClosed;
    } else if (bucketId === SalesPhaseBucket.TOURING) {
      familyFileData = data.findFamilyFilesWithTours;
    } else {
      familyFileData = data.findFamilyFilesByParams;
    }

    if (totalFamilyFilesAvailable === undefined) {
      totalFamilyFilesAvailable = familyFileData.filteredCount;
    }

    let rows;
    if (bucketId === SalesPhaseBucket.CLOSED) {
      rows = toRowInformation(familyFileData.results, labels).filter(
        (familyFile) => typeof familyFile !== 'undefined',
      );
    } else if (bucketId === SalesPhaseBucket.TOURING) {
      rows = toTourRowInformation(familyFileData.results, labels).filter(
        (familyFile) => typeof familyFile !== 'undefined',
      );
    } else {
      rows = toRowInformation(familyFileData.results, labels).filter(
        (familyFile) => typeof familyFile !== 'undefined',
      );
    }
    setFamilyFiles(rows);

    const newPageCount = Math.ceil(familyFileData.filteredCount / pageSize);
    setPageCount(newPageCount);
  };

  return (
    <Container container>
      <HeaderTitle
        title={
          title +
          (totalFamilyFilesAvailable === undefined ||
          totalFamilyFilesAvailable === 0
            ? ''
            : ' (' + totalFamilyFilesAvailable.toLocaleString() + ')')
        }
        titleLevel="h4"
        subtitle={subTitle}
        className={classes.header_title}
      />

      {loadingStatus === 'loading' && <Loading height="calc(100vh - 500px)" />}

      {loadingStatus === 'loaded' && totalFamilyFilesAvailable > 0 && (
        <ContainerItem item xs={12} className={classes.item}>
          <Media
            xs={
              <HomeTableWithRowsMobile
                bucketId={bucketId}
                columns={columns}
                isSalesPhaseFixed={true}
                labels={labels}
                medicallyUrgentFilterItems={medicallyUrgentFilter}
                onMedicallyUrgentFilterChange={onMedicallyUrgentFilterChange}
                onPageNumberChange={onPageNumberChange}
                onPageSizeChange={onPageSizeChange}
                onSalesPhaseFilterChange={onSalesPhaseFilterChange}
                pageCount={pageCount}
                pageNumber={pageNumber}
                rows={familyFiles}
                salesPhaseFilterItems={salesPhaseFilter}
                showPaging={true}
              />
            }
            sm={
              <HomeTableWithRows
                bucketId={bucketId}
                columns={columns}
                handleSort={handleSortOrderChange}
                isSalesPhaseFixed={true}
                labels={labels}
                medicallyUrgentFilterItems={medicallyUrgentFilter}
                onMedicallyUrgentFilterChange={onMedicallyUrgentFilterChange}
                onPageNumberChange={onPageNumberChange}
                onPageSizeChange={onPageSizeChange}
                onSalesPhaseFilterChange={onSalesPhaseFilterChange}
                order={sortOrder}
                pageCount={pageCount}
                pageNumber={pageNumber}
                rows={familyFiles}
                salesPhaseFilterItems={salesPhaseFilter}
                showPaging={true}
              />
            }
          />
        </ContainerItem>
      )}
      {loadingStatus === 'loaded' && totalFamilyFilesAvailable === 0 && (
        <EmptyTable labels={emptyLabels}>
          <CaughtUp />
        </EmptyTable>
      )}
      {loadingStatus === 'error' && (
        <div>
          <Typography>{error.message}</Typography>
        </div>
      )}
    </Container>
  );
};

FamilyFileList.propTypes = {
  labels: PropTypes.object.isRequired,
  location: PropTypes.object,
};

FamilyFileList.defaultProps = {};

export default withLabels(FamilyFileList);
