import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => ({
  buttons_block: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
    marginBottom: '30px',
    '& :not(:first-child)': {
      marginLeft: 4,
    },
  },
}));

export { useStyles };
