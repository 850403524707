import gql from 'graphql-tag';

export default gql`
  mutation deleteInquiryContact($oneId: String, $inquiryContactId: Int) {
    deleteInquiryContact(
      input: { oneId: $oneId, inquiryContactId: $inquiryContactId }
    ) {
      deleted
    }
  }
`;
