import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './SearchResultRowXS.style';
import TableRow from 'common/Table/TableRow/TableRow';
import TableCell from 'common/Table/TableCell/TableCell';
import Typography from 'common/Typography/Typography';
import { formatPhoneWithParenthesis } from 'utils/phoneFormat';
import AudienceTag from 'components/AudienceTag/AudienceTag';
import LastContactedXS from 'components/LastContacted/LastContactedXS/LastContactedXS';

const SearchResultRowXS = ({ result, labels, onClick }) => {
  const classes = useStyles();

  const formatPhone = (phone) => {
    if (!phone) return '';
    return formatPhoneWithParenthesis(phone);
  };

  return (
    <TableRow className={classes.search_result_row_container} onClick={onClick}>
      <TableCell>
        <div className={classes.contact_container}>
          <div className={classes.row}>
            <Typography className={classes.name}>
              {`${labels.PRIMARY_CONTACT}:`}
            </Typography>
            &nbsp;
            <Typography className={classes.name}>
              <b>{result.primaryContact.name}</b>
              &nbsp;
              {`(${result.primaryContact.relation})`}
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography level="small" color="eerieBlack5">
              {`${labels.HOME}:`}
            </Typography>
            &nbsp;
            <Typography level="small" color="eerieBlack1">
              {formatPhone(result.primaryContact.homePhone)}
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography level="small" color="eerieBlack5">
              {`${labels.CELL_PHONE}:`}
            </Typography>
            &nbsp;
            <Typography level="small" color="eerieBlack1">
              {formatPhone(result.primaryContact.cellPhone)}
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography level="small" color="eerieBlack5">
              {`${labels.EMAIL}:`}
            </Typography>
            &nbsp;
            <Typography level="small" color="eerieBlack1">
              {result.primaryContact.email}
            </Typography>
          </div>
        </div>
        <div className={classes.resident_container}>
          <div className={classes.row}>
            <Typography className={classes.name}>
              {`${labels.RESIDENT}:`}
            </Typography>
            &nbsp;
            <Typography className={classes.name} bold>
              {result.resident.name}
            </Typography>
          </div>

          <div className={classes.row}>
            <Typography level="small" color="eerieBlack5">
              {`${labels.ID}:`}
            </Typography>
            &nbsp;
            <Typography level="small" color="eerieBlack1">
              {result.id}
            </Typography>
          </div>
          <Typography className={classes.row} level="small" color="eerieBlack5">
            {`${labels.PREFERRED_LOCATIONS}:`}
          </Typography>
          <div className={classes.row}>
            {result.preferredLocations.map((l, k) => (
              <div className={classes.preferred_location} key={k}>
                <Typography
                  level="small"
                  color="eerieBlack1"
                >{`${l.city}, ${l.state}`}</Typography>
                {k !== result.preferredLocations.length - 1 && (
                  <div className={classes.divider} />
                )}
              </div>
            ))}
          </div>
        </div>
        <div className={classes.last_contacted_container}>
          <LastContactedXS
            lastContacted={result.lastContacted}
            labels={labels}
          />
        </div>
        <div className={classes.tags_container}>
          {result.tags.map((t, k) => (
            <div className={classes.tag} key={k}>
              {t}
            </div>
          ))}
          <AudienceTag labels={labels} audience={result.audienceKey} />
        </div>
      </TableCell>
    </TableRow>
  );
};

SearchResultRowXS.propTypes = {
  result: PropTypes.object,
  labels: PropTypes.object,
  onClick: PropTypes.func,
};

SearchResultRowXS.defaultProps = {};

export default SearchResultRowXS;
