export const GET_SCRIPTS_REQUEST = 'scripts/get/request';
export const GET_SCRIPTS_SUCCESS = 'scripts/get/success';
export const GET_SCRIPTS_ERROR = 'scripts/get/error';
export const GET_SNAPSHOTS_REQUEST = 'snapshots/get/request';
export const GET_SNAPSHOTS_SUCCESS = 'snapshots/get/success';
export const GET_SNAPSHOTS_ERROR = 'snapshots/get/error';
export const CANCEL_PENDING_SNAPSHOT_REQUESTS = 'snapshots/cancel';

export const getScriptsRequest = () => ({ type: GET_SCRIPTS_REQUEST });

export const getSnapshotsRequest = (scriptId) => ({
  type: GET_SNAPSHOTS_REQUEST,
  payload: { scriptId },
});

export const getScriptsSuccess = (response) => {
  return { type: GET_SCRIPTS_SUCCESS, payload: { response } };
};

export const getScriptsError = (error) => {
  return { type: GET_SCRIPTS_ERROR, payload: { error } };
};

export const getSnapshotsSuccess = (response) => {
  return { type: GET_SNAPSHOTS_SUCCESS, payload: { response } };
};

export const getSnapshotsError = (error) => {
  return { type: GET_SNAPSHOTS_ERROR, payload: { error } };
};

export const cancelPendingSnapshotRequests = () => ({
  type: CANCEL_PENDING_SNAPSHOT_REQUESTS,
});
