import React from 'react';
import PropTypes from 'prop-types';

const PinReferred = ({ className, mouseEnter, mouseLeave }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 48 49"
      fill="none"
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <mask id="path-2-inside-1" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.0962 40.0226L24 41L22.9038 40.0226C13.6855 31.803 9 24.626 9 18.3174C9 9.38337 15.8284 3 24 3C32.1716 3 39 9.38337 39 18.3174C39 24.626 34.3145 31.803 25.0962 40.0226Z"
        />
      </mask>
      <path
        className={'insidePath'}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0962 40.0226L24 41L22.9038 40.0226C13.6855 31.803 9 24.626 9 18.3174C9 9.38337 15.8284 3 24 3C32.1716 3 39 9.38337 39 18.3174C39 24.626 34.3145 31.803 25.0962 40.0226Z"
        fill="#4568DC"
      />
      <path
        d="M25.0962 40.0226L23.7652 38.5298L25.0962 40.0226ZM24 41L22.669 42.4928L24 43.6796L25.331 42.4928L24 41ZM22.9038 40.0226L24.2348 38.5298L22.9038 40.0226ZM23.7652 38.5298L22.669 39.5072L25.331 42.4928L26.4272 41.5153L23.7652 38.5298ZM25.331 39.5072L24.2348 38.5298L21.5728 41.5153L22.669 42.4928L25.331 39.5072ZM24.2348 38.5298C15.0599 30.3489 11 23.6913 11 18.3174H7C7 25.5608 12.3111 33.2571 21.5728 41.5153L24.2348 38.5298ZM11 18.3174C11 10.499 16.9219 5 24 5V1C14.7349 1 7 8.26776 7 18.3174H11ZM24 5C31.0781 5 37 10.499 37 18.3174H41C41 8.26776 33.2651 1 24 1V5ZM37 18.3174C37 23.6913 32.9401 30.3489 23.7652 38.5298L26.4272 41.5153C35.6889 33.2571 41 25.5608 41 18.3174H37Z"
        fill="#2C438C"
        mask="url(#path-2-inside-1)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 38.3197C14.9906 30.2348 11 23.6452 11 18.3174C11 10.499 16.9219 5 24 5C31.0781 5 37 10.499 37 18.3174C37 23.6452 33.0094 30.2348 24 38.3197ZM24 41L25.0962 40.0226C34.3145 31.803 39 24.626 39 18.3174C39 9.38337 32.1716 3 24 3C15.8284 3 9 9.38337 9 18.3174C9 24.626 13.6855 31.803 22.9038 40.0226L24 41Z"
        fill="#2C438C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3334 10.9882L29.3452 18L22.3334 25.0118V21.1948C20.6297 21.0074 18.8969 21.7816 17.0893 23.5893L15.6667 25.0118V23C15.6667 17.8756 17.9491 15.0107 22.3334 14.6958V10.9882ZM24 15.0118V16.3333H23.1666C19.8149 16.3333 17.9587 17.8417 17.467 21.0687C19.3948 19.6682 21.3727 19.1925 23.3688 19.6916L24 19.8494V20.9882L26.9881 18L24 15.0118ZM25.6667 10.9882V13.4882L30.1785 18L25.6667 22.5118V25.0118L32.6785 18L25.6667 10.9882Z"
        fill="#FEFEFE"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="15"
        y="10"
        width="18"
        height="16"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.3334 10.9882L29.3452 18L22.3334 25.0118V21.1948C20.6297 21.0074 18.8969 21.7816 17.0893 23.5893L15.6667 25.0118V23C15.6667 17.8756 17.9491 15.0107 22.3334 14.6958V10.9882ZM24 15.0118V16.3333H23.1666C19.8149 16.3333 17.9587 17.8417 17.467 21.0687C19.3948 19.6682 21.3727 19.1925 23.3688 19.6916L24 19.8494V20.9882L26.9881 18L24 15.0118ZM25.6667 10.9882V13.4882L30.1785 18L25.6667 22.5118V25.0118L32.6785 18L25.6667 10.9882Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)"></g>
      <defs>
        <filter
          id="filter0_f"
          x="5"
          y="33"
          width="38"
          height="16"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur" />
        </filter>
      </defs>
    </svg>
  );
};

PinReferred.propTypes = {
  className: PropTypes.string,
  mouseEnter: PropTypes.func,
  mouseLeave: PropTypes.func,
};

PinReferred.defaultProps = {
  mouseEnter: () => {},
  mouseLeave: () => {},
};

export default PinReferred;
