import { makeStyles } from '../../common/Theme/Theme';

const useStyles = (inFamilyFilePageAndXSSize) => {
  return makeStyles((theme) => ({
    '@global': {
      body: {
        '& .main_container': {
          padding: inFamilyFilePageAndXSSize ? '32px 0px 32px 0' : '',
        },
      },
    },
    basic_information_container: {
      padding: '0 24px 16px',
      zIndex: 10,
      backgroundColor: theme.palette.light.platinum5,
      '& h4': {
        marginTop: inFamilyFilePageAndXSSize ? '24px' : -20,
        marginBottom: '0',
      },
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    inline: {
      display: 'inline-flex',
      '& p': {
        display: 'inline',
      },
      '& div': {
        marginRight: '8px',
      },
    },
    basic_information_title_container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    label: {
      paddingRight: '8px',
    },
    column: {
      width: '50%',
      float: 'left',
    },
    locations: {
      width: '80%',
    },
    age: {
      width: '20%',
    },
    oneLetterTag: {
      padding: '4px 10px !important',
      marginRight: '0 !important',
    },
    care_types_container: {},
    row_container: {
      marginTop: '10px',
    },
    do_not_call_container: {
      paddingLeft: theme.spacing(1),
    },
    family_file_id_container: {
      justifyContent: 'left',
    },
    family_file_id: {
      paddingLeft: '5px',
    },
    edit_resident_info_button: {
      width: 74,
      height: 40,
      marginTop: theme.spacing(3),
    },
    self_tag: {
      marginLeft: '8px',
      height: 'fit-content',
      '& svg': {
        fontSize: '16px',
      },
    },
  }))();
};

export { useStyles };
