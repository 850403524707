import React, { useState } from 'react';
import { useStyles } from './DesktopPagination.style';
import ChevronLeft from '../../Icons/arrow/ChevronLeft';
import ChevronRight from '../../Icons/arrow/ChevronRight';
import MoreHorizontal from '../../Icons/basic/MoreHorizontal';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const MAX_PAGE_LOCATIONS_TO_SHOW = 5;

const DesktopPagination = ({
  className,
  currentPage,
  pageCount,
  onPageChange,
  prevLabel,
  nextLabel,
  goToPageLabel,
}) => {
  const classes = useStyles();
  const [goToPage, setGoToPage] = useState(0);

  const handlePrevPageChange = () => {
    onPageChange(currentPage - 1);
  };

  const handleNextPageChange = () => {
    onPageChange(currentPage + 1);
  };

  const handleGoToPage = (page) => {
    onPageChange(page);
  };

  const renderButtonPage = (page) => {
    return (
      <button
        key={page}
        onClick={() => handleGoToPage(page)}
        className={clsx(classes.page, currentPage === page && classes.selected)}
      >
        {page}
      </button>
    );
  };

  const isMiddleCurrentPage = () =>
    currentPage > 2 && currentPage <= pageCount - 2;

  const getPosition1 = () => {
    return renderButtonPage(1);
  };

  const getPosition2 = () => {
    if (pageCount > 1) {
      if (pageCount <= MAX_PAGE_LOCATIONS_TO_SHOW) {
        return renderButtonPage(2);
      }
      if (isMiddleCurrentPage()) {
        return <MoreHorizontal />;
      }
      return renderButtonPage(2);
    }
    return '';
  };

  const getPosition3 = () => {
    if (pageCount > 2) {
      if (pageCount <= MAX_PAGE_LOCATIONS_TO_SHOW) {
        return renderButtonPage(3);
      }
      if (isMiddleCurrentPage()) {
        return renderButtonPage(currentPage);
      }
      return <MoreHorizontal />;
    }
    return '';
  };

  const getPosition4 = () => {
    if (pageCount > 3) {
      if (pageCount <= MAX_PAGE_LOCATIONS_TO_SHOW) {
        return renderButtonPage(4);
      }
      if (isMiddleCurrentPage()) {
        return <MoreHorizontal />;
      }
      return renderButtonPage(pageCount - 1);
    }
    return '';
  };

  const getPosition5 = () => {
    if (pageCount >= MAX_PAGE_LOCATIONS_TO_SHOW) {
      return renderButtonPage(pageCount);
    }
    return '';
  };

  const shouldEnableGoToPageButton = () => {
    return goToPage > 0 && goToPage <= pageCount;
  };

  return (
    <div className={clsx(classes.container, className)}>
      <button
        disabled={currentPage === 1}
        className={clsx(classes.prev, currentPage === 1 && classes.disabled)}
        onClick={handlePrevPageChange}
      >
        <ChevronLeft />
        <label className={classes.label}>{prevLabel}</label>
      </button>
      <div className={classes.pagesContainer}>
        {getPosition1()}
        {getPosition2()}
        {getPosition3()}
        {getPosition4()}
        {getPosition5()}
      </div>
      <button
        disabled={currentPage === pageCount}
        className={clsx(
          classes.next,
          currentPage === pageCount && classes.disabled,
        )}
        onClick={handleNextPageChange}
      >
        <label className={classes.label}>{nextLabel}</label>
        <ChevronRight />
      </button>

      <div className={classes.go_to_page}>
        <label>{goToPageLabel}</label>
        <input
          data-testid="goToPageInput"
          onChange={(e) => setGoToPage(parseInt(e.target.value))}
        />
        <button
          data-testid="goToPageBtn"
          disabled={!shouldEnableGoToPageButton()}
          className={clsx(
            classes.go_to_page_button,
            !shouldEnableGoToPageButton() && classes.disabled,
          )}
          onClick={() => handleGoToPage(goToPage)}
        >
          <ChevronRight />
        </button>
      </div>
    </div>
  );
};

DesktopPagination.propTypes = {
  className: PropTypes.string,
  currentPage: PropTypes.number,
  pageCount: PropTypes.number,
  goToPageLabel: PropTypes.string,
  onPageChange: PropTypes.func,
  prevLabel: PropTypes.string,
  nextLabel: PropTypes.string,
};

DesktopPagination.defaultProps = {
  currentPage: 1,
  onPageChange: () => {},
  prevLabel: 'Prev',
  nextLabel: 'Next',
  goToPageLabel: 'Go to page',
};

export default DesktopPagination;
