import gql from 'graphql-tag';

export default gql`
  mutation updateMedicalBackground(
    $formDataId: Int!
    $currentLiving: String
    $locationName: String
    $rehab: String
    $medications: String
    $memoryLoss: String
    $memoryDiagnosis: String
    $wandering: String
    $getAround: String
    $bathing: String
    $toileting: String
    $healthIssues: String
    $anythingElse: String
    $diabetic: String
    $oneId: String
    $familyFileId: Int
  ) {
    updateMedicalBackground(
      input: {
        formDataId: $formDataId
        currentLiving: $currentLiving
        locationName: $locationName
        rehab: $rehab
        medications: $medications
        memoryLoss: $memoryLoss
        memoryDiagnosis: $memoryDiagnosis
        wandering: $wandering
        getAround: $getAround
        bathing: $bathing
        toileting: $toileting
        diabetic: $diabetic
        healthIssues: $healthIssues
        anythingElse: $anythingElse
        oneId: $oneId
        familyFileId: $familyFileId
      }
    ) {
      formDataId
      currentLiving
      locationName
      rehab
      medications
      memoryLoss
      memoryDiagnosis
      getAround
      bathing
      diabetic
      healthIssues
      anythingElse
    }
  }
`;
