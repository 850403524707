import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'common/Typography/Typography';
import { useStyles } from './MedicalBackground.style';

const MedicalBackgroundColumn = ({ label, value, icon, unknownLabel }) => {
  const classes = useStyles();
  const getContent = () => (
    <Typography level="body">{value || unknownLabel}</Typography>
  );
  const getContentWithIcon = () => {
    return (
      <div className={classes.row}>
        {icon}
        <Typography className={classes.icon_text} level="body">
          {value || unknownLabel}
        </Typography>
      </div>
    );
  };
  return (
    <div className={classes.column}>
      <Typography className={classes.label} color="eerieBlack5" level="small">
        {label || unknownLabel}
      </Typography>
      {icon ? getContentWithIcon() : getContent()}
    </div>
  );
};

MedicalBackgroundColumn.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  icon: PropTypes.node,
  unknownLabel: PropTypes.string,
};

export default MedicalBackgroundColumn;
