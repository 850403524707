import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    marginTop: theme.spacing(14),
  },

  dashboardContainer: {
    display: 'flex',
    flexDirection: 'row',
    order: 1,
    justifyContent: 'left',
    marginLeft: theme.spacing(6),
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(4),
  },

  dashboardText: {
    paddingLeft: theme.spacing(1),
  },

  starDayContainer: {
    display: 'flex',
    flexDirection: 'column',
    order: 1,
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    position: 'absolute',
  },

  top: {
    display: 'flex',
    flexDirection: 'row',
    width: '95%',
    height: '196px',
    backgroundColor: theme.palette.neutral.cosmicCobalt3,
    alignItems: 'stretch',
    boxShadow: '0px 0px 8px rgba(186, 186, 184, 0.3)',
    borderRadius: '6px',
    justifyContent: 'space-between',
  },

  bottom: {
    display: 'flex',
    flexDirection: 'row',
    width: '95%',
    height: '441px',
    alignItems: 'stretch',
    backgroundColor: theme.palette.neutral.cosmicCobalt3,
    marginTop: theme.spacing(6),
    borderRadius: '6px',
    justifyContent: 'space-between',
  },

  welcomeMessageContainer: {
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(5),
  },

  callStatusContainer: {
    paddingTop: theme.spacing(18),
    paddingLeft: theme.spacing(23),
    textAlign: 'center',
  },

  callStatusDefaultText: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
  },

  welcomeBodyDefaultText: {
    paddingTop: theme.spacing(1),
  },

  oldPersonsContainer: {
    display: 'flex',
    backgroundColor: theme.palette.neutral.cosmicCobalt3,
    position: 'relative',
    flexGrow: 1,
    justifyContent: 'flex-end',
    borderRadius: '6px',
    '&:before': {
      background:
        'linear-gradient(270.15deg, #2C438C 46.85%, rgba(57, 86, 181, 0) 99.87%)',
      width: '100%',
      height: '100%',
      position: 'absolute',
      bottom: 0,
      right: 0,
      content: '""',
      borderTopLeftRadius: '100%',
      borderBottomRightRadius: '6px',
      borderTopRightRadius: '6px',
      zIndex: 0,
    },
  },

  lightHouseContainer: {
    display: 'flex',
    backgroundColor: theme.palette.neutral.cosmicCobalt3,
    position: 'relative',
    flexGrow: 1,
    justifyContent: 'flex-end',
    borderRadius: '6px',
  },

  oldWoman: {
    zIndex: 1,
    position: 'absolute',
    bottom: 0,
    right: theme.spacing(8),
  },

  oldMan: {
    zIndex: 2,
    position: 'absolute',
    bottom: 0,
    right: theme.spacing(19),
  },

  lightHouse: {
    borderRadius: '6px',
    position: 'absolute',
  },

  launchFive9Button: {
    marginTop: theme.spacing(5),
  },

  externalCallContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
  },

  externalCall: {
    marginRight: '20px',
  },

  successfullManualInquiry: {
    width: '640px',
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
}));

export { useStyles };
