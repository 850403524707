import React from 'react';
import labels from 'config/labels';
import Studio from 'common/Icons/lineIcons/bedroom/Studio';
import OneBedroom from 'common/Icons/lineIcons/bedroom/OneBedroom';
import TwoBedrooms from 'common/Icons/lineIcons/bedroom/TwoBedrooms';
import Shared from 'common/Icons/lineIcons/bedroom/Shared';
import Cottage from 'common/Icons/lineIcons/bedroom/Cottage';

const bedroomTypes = [
  {
    label: labels.STUDIO,
    value: 'Studio Units',
    icon: <Studio />,
    selected: false,
    is: labels.APARTMENT_UNIT_TYPES,
  },
  {
    label: labels.ONE_BEDROOM,
    value: '1 bedroom units',
    icon: <OneBedroom />,
    selected: false,
    is: labels.APARTMENT_UNIT_TYPES,
  },
  {
    label: labels.TWO_BEDROOM,
    value: '2 bedroom units',
    icon: <TwoBedrooms />,
    selected: false,
    is: labels.APARTMENT_UNIT_TYPES,
  },
  {
    label: labels.SHARED,
    value: 'Shared / Companion / Semi-Private Units',
    icon: <Shared />,
    selected: false,
    is: labels.APARTMENT_UNIT_TYPES,
  },
  {
    label: labels.COTTAGE_VILLA,
    value: 'Detached Cottages / Bungalows',
    icon: <Cottage />,
    selected: false,
    is: labels.APARTMENT_UNIT_TYPES,
  },
];

export default bedroomTypes;
