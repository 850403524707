import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from './ScheduleTourActivityTable.style';
import Table from 'common/Table/Table/Table';
import TableHead from 'common/Table/TableHead/TableHead';
import TableRow from 'common/Table/TableRow/TableRow';
import TableCell from 'common/Table/TableCell/TableCell';
import Typography from 'common/Typography/Typography';
import TableBody from 'common/Table/TableBody/TableBody';
import ErrorMap from 'common/Graphics/communities/ErrorMap';
import { formatTourActivityDate } from 'common/utils/dateFormat';

const ScheduleTourActivityTable = ({ className, labels, tourDetails }) => {
  const SCHEDULED_TOUR = 1;
  const COMPLETED = 2;
  const CANCELED = 3;
  const RESCHEDULED = 4;
  const DATE_FORMAT = 'MMM DD, YYYY @ HH:mm A';
  const DATE_FORMAT_FOR_COMPLETED = 'MMM DD, YYYY';

  const classes = useStyles();

  const isScheduled = (status) => status === SCHEDULED_TOUR;
  const isCompleted = (status) => status === COMPLETED;
  const isCanceled = (status) => status === CANCELED;
  const isRescheduled = (status) => status === RESCHEDULED;

  const sortedTourActivities = tourDetails?.tourActivities.sort(
    (a, b) => b?.updatedAt - a?.updatedAt,
  );

  const getTourActivityDate = (activity) => {
    if (isScheduled(activity.status)) {
      return formatTourActivityDate(activity.activityDate, DATE_FORMAT);
    }
    if (isCompleted(activity.status)) {
      return formatTourActivityDate(
        activity.activityDate,
        DATE_FORMAT_FOR_COMPLETED,
      );
    }
    if (isCanceled(activity.status)) {
      return formatTourActivityDate(activity.activityDate, DATE_FORMAT);
    }
    if (isRescheduled(activity.status)) {
      return formatTourActivityDate(activity.activityDate, DATE_FORMAT);
    }
  };

  const getTourType = (tourType) => {
    const tourTypes = {
      IP: labels.IN_PERSON_TOUR,
      VT: labels.CT_APP,
      VTX: labels.VIRTUAL_TOURS_OTHER,
    };
    const trimmedTourType = tourType?.trim() ?? '';

    return tourTypes[trimmedTourType]
      ? tourTypes[trimmedTourType]
      : labels.UNKNOWN;
  };

  return (
    <>
      <div
        className={clsx(classes.schedule_tour_activity_container, className)}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography level="small">{labels.TOUR_ACTIVITY}</Typography>
              </TableCell>
              <TableCell>
                <Typography level="small">{labels.DATE}</Typography>
              </TableCell>
              <TableCell>
                <Typography level="small">{labels.TOUR_TYPE}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tourDetails && sortedTourActivities.length > 0 ? (
              sortedTourActivities.map((activity, index) => (
                <>
                  {!activity.isHidden && (
                    <TableRow key={index}>
                      <TableCell>
                        {isCanceled(activity.status) && (
                          <Typography level="small" color="hotCinnamon3">
                            {labels.TOUR_STATUS_CANCELED}
                          </Typography>
                        )}
                        {isCompleted(activity.status) && (
                          <Typography level="small" color="wintergreenDream3">
                            {labels.TOUR_STATUS_COMPLETED}
                          </Typography>
                        )}
                        {isRescheduled(activity.status) && (
                          <Typography level="small" color="eerieBlack1">
                            {labels.TOUR_STATUS_RESCHEDULED}
                          </Typography>
                        )}
                        {isScheduled(activity.status) && (
                          <Typography level="small" color="cosmicCobalt3">
                            {labels.TOUR_STATUS_SCHEDULED}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography level="small">
                          {getTourActivityDate(activity)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography level="small">
                          {getTourType(activity.tourType)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        {(!tourDetails || tourDetails?.tourActivities?.length === 0) && (
          <div className={classes.empty_state}>
            <ErrorMap className={classes.icon} />
            <Typography level={'small'}>
              {labels.YOU_HAVE_NOT_SCHEDULED_A_TOUR}
            </Typography>
            <Typography level={'small'}>
              {labels.TOUR_HISTORY_WILL_SHOW_HERE}
            </Typography>
          </div>
        )}
      </div>
    </>
  );
};

ScheduleTourActivityTable.propTypes = {
  className: PropTypes.string,
  community: PropTypes.object,
  familyFileId: PropTypes.number,
  familyFileUserId: PropTypes.number,
  labels: PropTypes.object,
  tourDetails: PropTypes.object,
};

ScheduleTourActivityTable.defautProps = {
  className: '',
};

export default ScheduleTourActivityTable;
