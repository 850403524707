import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import User from 'common/Icons/basic/User';
import clsx from 'clsx';
import Typography from 'common/Typography/Typography';
import Tag from 'common/Tag/Tag';
import EditCard from 'common/Card/EditCard/EditCard';
import Card from 'common/Card/Card';
import { useStyles } from './ResidentInformation.style';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import CallMuted from 'common/Icons/call/CallMuted';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import AudienceTag from 'components/AudienceTag/AudienceTag';

const ResidentInformationMobile = ({
  labels,
  basicInformation,
  onEditMode,
  flags,
}) => {
  const classes = useStyles();
  const preferredLocationLabel = (preferredLocation) => {
    return `${preferredLocation.city}, ${preferredLocation.state} ${preferredLocation.zip}`;
  };

  const getAge = () => {
    if (basicInformation.age) {
      return `${basicInformation.age} ${labels.YEARS_OLD}`;
    }
    return labels.UNKNOWN;
  };

  const getLikeliHoodToMove = () => {
    if (basicInformation.likeliHoodToMove) {
      return `${basicInformation.likeliHoodToMove}${labels.PERCENTAGE}`;
    }
    return labels.UNKNOWN;
  };

  const ResidentInfo = () => {
    return (
      <Fragment>
        {basicInformation && (
          <Fragment>
            <div className={clsx(classes.row)}>
              <div className={classes.column}>
                <Typography level="tiny" color="eerieBlack5">
                  {labels.RESIDENT}
                </Typography>
                <Typography bold level="small">
                  {basicInformation.resident || labels.UNKNOWN}
                </Typography>
              </div>
              <div className={classes.column}>
                <Typography level="tiny" color="eerieBlack5">
                  {labels.AGE}
                </Typography>
                <Typography className={classes.location} bold level="small">
                  {getAge()}
                </Typography>
              </div>

              {basicInformation.doNotCall && (
                <div className={classes.row}>
                  <Typography
                    color="smokyTopaz2"
                    level="h4"
                    className={classes.do_not_call_container}
                  >
                    <SmartTooltip title={labels.DO_NOT_CALL} placement="bottom">
                      <CallMuted />
                    </SmartTooltip>
                  </Typography>
                </div>
              )}
            </div>

            <div className={classes.row}>
              <Typography level="tiny" color="eerieBlack5">
                {labels.FAMILY_FILE_ID}
              </Typography>

              <div className={classes.family_file_id}>
                <Typography level="tiny" bold>
                  {basicInformation.familyFileId}
                </Typography>
              </div>
            </div>
            {basicInformation.preferredLocations.length > 0 && (
              <div className={classes.row}>
                <div className={classes.column}>
                  <Typography level="tiny" color="eerieBlack5">
                    {labels.PREFERRED_LOCATIONS}
                  </Typography>
                  {basicInformation.preferredLocations.map((location, key) => (
                    <Typography
                      key={key}
                      className={classes.location}
                      bold
                      level="small"
                    >
                      {preferredLocationLabel(location)}
                    </Typography>
                  ))}
                </div>
              </div>
            )}
            <div className={classes.row}></div>

            <div className={`${classes.row} ${classes.row_container}`}>
              <div
                className={clsx(classes.inline, classes.care_types_container)}
              >
                <AudienceTag
                  audience={basicInformation.audienceKey}
                  labels={labels}
                />
                <Tag
                  color="fieldDrab5"
                  text={basicInformation.salesPhase}
                  className={classes.salesPhase}
                />
                {basicInformation.careTypes.length > 0
                  ? basicInformation.careTypes.map((careType, index) => {
                      return (
                        <SmartTooltip
                          key={index}
                          title={`${careType.name} (${careType.code})`}
                          placement="bottom"
                        >
                          <Tag
                            className={classes.oneLetterTag}
                            text={careType.code}
                            color="regalia5"
                          />
                        </SmartTooltip>
                      );
                    })
                  : []}
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.column}>
                <Typography level="tiny" color="eerieBlack5">
                  {labels.EXPECTED_MOVE_IN_DATE}
                </Typography>
                <Typography bold>{basicInformation.expectedMove}</Typography>
              </div>
              <div className={classes.column}>
                {(basicInformation.likeliHoodToMove ?? -1) >= 0 && (
                  <div className={classes.row}>
                    <div className={classes.column}>
                      <Typography level="tiny" color="eerieBlack5">
                        {labels.LIKELIHOOD_MOVE}
                      </Typography>
                      <Typography bold>{getLikeliHoodToMove()}</Typography>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {flags.updateContactsOwl && (
        <EditCard
          headerColor="cosmicCobalt2"
          editButtonColor="cosmicCobalt3"
          title={labels.RESIDENT_INFORMATION}
          icon={<User />}
          className={clsx(classes.resident, classes.card)}
          clickCallback={() => onEditMode(true)}
          flags={flags}
        >
          <ResidentInfo />
        </EditCard>
      )}
      {!flags.updateContactsOwl && (
        <Card
          headerColor="cosmicCobalt2"
          editButtonColor="cosmicCobalt3"
          title={labels.RESIDENT_INFORMATION}
          icon={<User />}
          className={clsx(classes.resident, classes.card)}
          clickCallback={() => onEditMode(true)}
        >
          <ResidentInfo />
        </Card>
      )}
    </Fragment>
  );
};

ResidentInformationMobile.propTypes = {
  labels: PropTypes.shape({
    AGE: PropTypes.string.isRequired,
    BACK_TO_EDITING: PropTypes.string.isRequired,
    DO_NOT_CALL: PropTypes.string.isRequired,
    DONT_MAKE_ANY_CHANGES: PropTypes.string.isRequired,
    EXPECTED_MOVE_IN_DATE: PropTypes.string.isRequired,
    FAMILY_FILE_ID: PropTypes.string.isRequired,
    LIKELIHOOD_MOVE: PropTypes.string.isRequired,
    OK: PropTypes.string.isRequired,
    PERCENTAGE: PropTypes.string.isRequired,
    PLEASE_CONFIRM: PropTypes.string.isRequired,
    PREFERRED_LOCATIONS: PropTypes.string.isRequired,
    RESIDENT: PropTypes.string.isRequired,
    RESIDENT_INFORMATION: PropTypes.string.isRequired,
    SUCCESS: PropTypes.string.isRequired,
    SUCCESSFULLY_UPDATED: PropTypes.string.isRequired,
    UNKNOWN: PropTypes.string.isRequired,
    YEARS_OLD: PropTypes.string.isRequired,
    YES_DONT_SAVE: PropTypes.string.isRequired,
  }).isRequired,
  basicInformation: PropTypes.shape({
    age: PropTypes.number,
    audienceKey: PropTypes.string,
    careTypes: PropTypes.arrayOf(PropTypes.object),
    doNotCall: PropTypes.bool.isRequired,
    expectedMove: PropTypes.any,
    familyFileId: PropTypes.number.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    likeliHoodToMove: PropTypes.number,
    preferredLocations: PropTypes.arrayOf(PropTypes.object),
    resident: PropTypes.string,
    salesPhase: PropTypes.string,
  }).isRequired,
  onEditMode: PropTypes.func,
  flags: PropTypes.shape({
    updateContactsOwl: PropTypes.bool,
  }),
};

ResidentInformationMobile.defaultProps = {};

export default withLDConsumer()(ResidentInformationMobile);
