module.exports = {
  CROSS_DOMAIN_STORAGE_URL:
    'https://sharedlogin.dev.aplaceformom.com/index.html',

  AUTH0_CLIENT_ID: 'I623AjOe0iuuiVacsWNtaSiFC3KOCqhb',
  AUTH0_DOMAIN: 'aplaceformom.auth0.com',

  LOGOUT_URL: 'http://localhost:12580/home',

  IMAGE_URL: 'https://www.aplaceformom.com/images',

  CASEMANAGEMENT_API_GET_CASES_URL:
    'https://casemanagement.qa.owl.aplaceformom.com/api/cases/?salesPhase={salesPhases}',
  CASEMANAGEMENT_API_GET_ACTIONS_BY_CASEID_URL:
    'https://casemanagement.owl.aplaceformom.com/api/cases/{caseId}/actions',

  YGL_TASK_PAGE_URL:
    'https://ygl.dev.aplaceformom.com/home.asp?tab=3&pg=te&LID={yglLeadID}',
  YGL_CASE_PAGE_URL:
    'https://ygl.dev.aplaceformom.com/home.asp?tab=2&pg=ld&LID={yglLeadID}',
  YGL_WORKING_LIST_PAGE_URL:
    'https://ygl.dev.aplaceformom.com/home.asp?tab=2&pg=wl&LID={yglLeadID}',
  YGL_PROFESSIONAL_SOURCE_URL:
    'https://ygl.dev.aplaceformom.com/Leads/ProfessionalSrcTracking.aspx?LID={yglLeadID}',
  YGL_PROPERTY_PROFILE_URL:
    'https://ygl.dev.aplaceformom.com/home.asp?tab=5&pg=pp&PID={yglPropertyID}',
  YGL_DISCLOSURE_URL:
    'https://salescrm-leads.internal.dev.aplaceformom.com/Lead/{yglLeadId}/Disclosure/Status',

  BEACON_FAMILY_FILE:
    'https://owl-main-ui.internal.dev.aplaceformom.com/family-file/{familyFileId}',

  // POINTS_URL: 'http://localhost:8085/metrics/points',
  // QUOTA_URL: 'http://localhost:8085/metrics/quotas',
  POINTS_URL:
    'https://owl-legacyproxy-service.internal.dev.aplaceformom.com/metrics/points',
  QUOTA_URL:
    'https://owl-legacyproxy-service.internal.dev.aplaceformom.com/metrics/quotas',

  // TOURS_SCHEDULED_METRICS_DURING_IC_URL:
  //   'http://localhost:8085/metrics/percentage-of-tours-scheduled-on-initial-consult',
  // SECOND_ROUND_REFERRALS_METRICS_URL:
  //   'http://localhost:8085/metrics/percentage-of-tours-scheduled-on-second-round-referral',
  TOURS_SCHEDULED_METRICS_DURING_IC_URL:
    'https://owl-legacyproxy-service.internal.dev.aplaceformom.com/metrics/percentage-of-tours-scheduled-on-initial-consult',
  SECOND_ROUND_REFERRALS_METRICS_URL:
    'https://owl-legacyproxy-service.internal.dev.aplaceformom.com/metrics/percentage-of-tours-scheduled-on-second-round-referral',

  // GRAPHQL_URL: 'http://localhost:4000/graphql',
  GRAPHQL_URL: 'https://owl-graph.internal.dev.aplaceformom.com/graphql',

  YGL_COMMUNICATION_SERVICE_TEXT_URL:
    'https://salescrm-communicationsupport.internal.dev.aplaceformom.com/Communication/acquireClickToTextUrl',
  TEXT_OVERRIDE_NUMBER: '',

  APFM_LIVE_SPLASH_URL:
    'https://www.dev.aplaceformom.com/community/{yglPropertyUrl}',

  TOUR_SCHEDULED_GOAL: 30,
  TOUR_SCHEDULED_LOW_LIMIT: 20,
  TOUR_SCHEDULED_HIGH_LIMIT: 29,
  TOUR_SCHEDULED_LOWEST_LIMIT: 0,

  SECOND_ROUND_GOAL: 35,
  SECOND_ROUND_LOW_LIMIT: 25,
  SECOND_ROUND_HIGH_LIMIT: 34,
  SECOND_ROUND_LOWEST_LIMIT: 0,
  TRACKING_ID: 'UA-165911491-1',

  AMPLITUDE_API_KEY: 'c0e95c4b27455eeac0ccff27833d4227',
  LD_CLIENT_SIDE_ID: '5ecfd6aca948ce09e7ea5877',

  WEBSOCKET_URL: 'wss://owl-push-notifications.internal.dev.aplaceformom.com',

  IS_DIALER_SERVICE_ENABLED: false,

  USE_SENTRY: false,
  SENTRY_APP_ENVIRONMENT: 'dev',
  OWL_CONNECT_ENVIRONMENT: 'qa',
  CALL_BACK_CAMPAIGN_NAME: 'RETRIEVE_FROM_CALL_DATA',
  CALL_BACK_LIST_NAME: 'CS Customer Callback',

  DEFAULT_CALLCENTER_USER_ID: '226',
};
