import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clonedeep from 'lodash.clonedeep';
import User from '../../common/Icons/basic/User';
import Typography from '../../common/Typography/Typography';
import Tag from '../../common/Tag/Tag';
import { useStyles } from './EditResidentInformation.style';
import Modal from '../../common/Modal/Modal';
import PlacesInput, {
  DELETE_STATE_FULL,
  DELETE_STATE_NONE,
  DELETE_STATE_PARTIAL,
} from '../../common/PlacesInput/PlacesInput';
import TextInput from '../../common/TextInput/TextInput';
import PinStart from '../../common/Icons/maps/PinStart';
import Delete from '../../common/Icons/basic/Delete';
import CircleChecked from '../../common/Icons/basic/CircleChecked';
import Divider from '../../common/Divider/Divider';
import Checkbox from '../../common/Checkbox/Checkbox';
import MonthAndYearPicker from '../../common/MonthAndYearPicker/MonthAndYearPicker';
import Button from '../../common/Button/Button';
import Slider from '../../common/Slider/Slider';
import clsx from 'clsx';
import isSameSize from '../../utils/sizesHelper';
import Toast from '../../common/Toast/Toast';
import { handleOnChange } from '../../services/formService';

const MAX_PREFERRED_LOCATIONS = 10;

let confirmLabels;

const getEmptyPreferredLocation = () => {
  const location = {
    city: '',
    familyDesiredLocationId: -Date.now(),
    deleteState: DELETE_STATE_NONE,
    isLastLocation: false,
    isPrimary: false,
    state: '',
    zip: '',
  };
  return location;
};

const preferredLocationText = (preferredLocation) => {
  if (
    preferredLocation.city.length === 0 &&
    preferredLocation.state.length === 0 &&
    preferredLocation.zip.length === 0
  ) {
    return '';
  }
  const text = `${preferredLocation.city}, ${preferredLocation.state} ${preferredLocation.zip}`;
  return text;
};

const EditResidentInformation = ({
  labels,
  basicInformation,
  onClose,
  isOpen,
  onSave,
  size,
  showErrorMessage,
  enableReferralAlertValidation,
}) => {
  const classes = useStyles();

  const [residentInformation, setResidentInformation] = useState(
    clonedeep(basicInformation),
  );
  const [isAllSelectCareTypeOn, setIsAllSelectCareTypeOn] = useState(
    basicInformation.careTypes.length > 7,
  );
  const [firstNameError, setFirstNameError] = useState();
  const [lastNameError, setLastNameError] = useState();
  const [deleteTimers, setDeleteTimers] = useState({});

  useEffect(() => {
    let information = clonedeep(basicInformation);

    if (information.preferredLocations.length === 0) {
      let location = { ...getEmptyPreferredLocation(), isPrimary: true };
      information.preferredLocations.push(location);
    }
    if (information.preferredLocations.length < MAX_PREFERRED_LOCATIONS) {
      information.preferredLocations[
        information.preferredLocations.length - 1
      ].isLastLocation = true;
    }

    setResidentInformation(information);
  }, [basicInformation]);

  useEffect(() => {
    confirmLabels = labels.REMOVE_PREFERRED_LOCATION_CONFIRMATION.split('|');
  }, [labels]);

  const setProperty = (name, value) => {
    setResidentInformation((residentInformation) => ({
      ...residentInformation,
      [name]: value,
    }));
  };

  const preferredLocationsActiveCount = () => {
    const count = residentInformation.preferredLocations.filter(
      (location) => location.deleteState === DELETE_STATE_NONE,
    ).length;
    return count;
  };

  const setLastLocation = () => {
    let lastActiveLocationIx = null;
    let activeLocationCount = 0;
    residentInformation.preferredLocations.forEach((location, index) => {
      location.isLastLocation = false;
      if (location.deleteState === DELETE_STATE_NONE) {
        activeLocationCount++;
        lastActiveLocationIx = index;
      }
    });
    if (lastActiveLocationIx !== null) {
      if (activeLocationCount < MAX_PREFERRED_LOCATIONS) {
        residentInformation.preferredLocations[
          lastActiveLocationIx
        ].isLastLocation = true;
      }
    }
  };

  const calculateBornYear = (age) => {
    if (age) {
      return (new Date().getFullYear() - age).toString();
    }
    return '';
  };
  const [yearOfBirth, setYearOfBirth] = useState(
    calculateBornYear(residentInformation.age),
  );

  const handleCancel = () => {
    clearAllDeleteTimers();

    onClose(residentInformation);
    setFirstNameError();
    setLastNameError();
  };

  const clearAllDeleteTimers = () => {
    for (const timer in deleteTimers) {
      clearTimeout(deleteTimers[timer]);
    }
  };

  const handleCareTypesSelection = (checked, careType) => {
    if (checked) {
      residentInformation.careTypes.push(careType);
    } else {
      residentInformation.careTypes = residentInformation.careTypes.filter(
        (type) => type.code !== careType.code,
      );
    }
    setResidentInformation({ ...residentInformation });
  };

  const handleAgeOnChange = (age) => {
    setYearOfBirth(calculateBornYear(age));
    setProperty('age', parseInt(age));
  };

  const isCareTypeChecked = (code) => {
    return !!residentInformation.careTypes.find(
      (careType) => careType.code === code,
    );
  };

  const handleSelectAllCareTypes = (e) => {
    if (!e.target.checked) {
      residentInformation.careTypes = [];
    } else {
      residentInformation.careTypes = [
        {
          code: 'Z',
          name: labels.MEMORY_CARE,
        },
        {
          code: 'A',
          name: labels.ASSISTED_LIVING,
        },
        {
          code: 'R',
          name: labels.RETIREMENT_COMMUNITY,
        },
        {
          code: 'P',
          name: labels.SENIOR_APARTMENTS,
        },
        {
          code: 'H',
          name: labels.HOME_CARE,
        },
        {
          code: 'G',
          name: labels.RESIDENTIAL_CARE_HOME,
        },
        {
          code: 'N',
          name: labels.NURSING_HOME,
        },
        {
          code: 'D',
          name: labels.ADULT_DAY_SERVICES,
        },
      ];
    }
    setResidentInformation({ ...residentInformation });
    setIsAllSelectCareTypeOn(e.target.checked);
  };

  const getMonthAndYear = () => {
    if (
      !basicInformation.expectedMove ||
      basicInformation.expectedMove === 'Unknown' ||
      basicInformation.expectedMove === labels.NO_RUSH ||
      basicInformation.expectedMove === labels.UNDECIDED
    ) {
      return {};
    }

    const date = new Date(basicInformation.expectedMove);

    const month = labels.MONTHS[date.getMonth()].substring(0, 3);
    const year = date.getFullYear().toString();
    return { month, year };
  };

  /*
    Preferred Location functionality
  */
  const handlePreferredLocationChange = (location, familyDesiredLocationId) => {
    let index = residentInformation.preferredLocations.findIndex((location) => {
      return location.familyDesiredLocationId === familyDesiredLocationId;
    });
    residentInformation.preferredLocations[index] = location;
    setProperty('preferredLocations', residentInformation.preferredLocations);
  };

  const handlePreferredLocationDelete = (
    deleteState,
    familyDesiredLocationId,
  ) => {
    let index = residentInformation.preferredLocations.findIndex((location) => {
      return location.familyDesiredLocationId === familyDesiredLocationId;
    });
    residentInformation.preferredLocations[index].deleteState = deleteState;

    if (deleteState === DELETE_STATE_PARTIAL) {
      setTimeoutForFullDelete(familyDesiredLocationId, index);
    }

    if (preferredLocationsActiveCount() === 0) {
      let location = { ...getEmptyPreferredLocation(), isPrimary: true };
      residentInformation.preferredLocations.push(location);
    } else {
      makeFirstNonDeletedLocationPrimary();
    }

    setLastLocation();
    setProperty('preferredLocations', residentInformation.preferredLocations);
  };

  const setTimeoutForFullDelete = (familyDesiredLocationId, index) => {
    const timer = setTimeout(() => {
      setDeleteTimers((deleteTimers) => ({
        ...deleteTimers,
        [familyDesiredLocationId]: null,
      }));
      residentInformation.preferredLocations[
        index
      ].deleteState = DELETE_STATE_FULL;
      setProperty('preferredLocations', residentInformation.preferredLocations);
    }, 5000);

    setDeleteTimers((deleteTimers) => ({
      ...deleteTimers,
      [familyDesiredLocationId]: timer,
    }));
  };

  const handleUnDelete = (familyDesiredLocationId) => {
    let index = residentInformation.preferredLocations.findIndex((location) => {
      return location.familyDesiredLocationId === familyDesiredLocationId;
    });
    residentInformation.preferredLocations[
      index
    ].deleteState = DELETE_STATE_NONE;

    const timer = deleteTimers[familyDesiredLocationId];
    clearTimeout(timer);

    setDeleteTimers((deleteTimers) => ({
      ...deleteTimers,
      [familyDesiredLocationId]: null,
    }));

    setProperty('preferredLocations', residentInformation.preferredLocations);
  };

  const handlePreferredLocationAdd = () => {
    residentInformation.preferredLocations.push(getEmptyPreferredLocation());
    setLastLocation();
    setProperty('preferredLocations', residentInformation.preferredLocations);
  };

  const makeFirstNonDeletedLocationPrimary = () => {
    residentInformation.preferredLocations
      .filter((location) => {
        return location.deleteState === DELETE_STATE_NONE;
      })
      .forEach((location, index) => {
        if (index === 0) {
          location.isPrimary = true;
        }
      });
  };

  const removeBlankLocations = () => {
    residentInformation.preferredLocations = residentInformation.preferredLocations.filter(
      (familyDesiredLocation) => {
        if (
          familyDesiredLocation.city.length === 0 &&
          familyDesiredLocation.state.length === 0 &&
          familyDesiredLocation.zip.length === 0
        ) {
          return false;
        }
        return true;
      },
    );
  };

  const pendingDeletesExist = () => {
    const partials = residentInformation.preferredLocations.filter(
      (location) => {
        return location.deleteState === DELETE_STATE_PARTIAL;
      },
    );
    return partials.length > 0 ? true : false;
  };

  /*
    Expected Move-in functionality 
  */
  const handleOnChangeExpectedMove = (date) => {
    if (
      date.month === undefined ||
      date.month === labels.MONTH ||
      date.year === undefined ||
      date.year === labels.YEAR
    ) {
      setProperty('expectedMove', null);
    } else {
      const expectedMoveDate = new Date(`${date.month} ${date.year}`);
      setProperty('expectedMove', expectedMoveDate.toISOString());
    }
  };

  const handleSubmit = () => {
    if (!residentInformation.firstName) {
      setFirstNameError(labels.REQUIRED);
    }
    if (!residentInformation.lastName) {
      setLastNameError(labels.REQUIRED);
    }
    if (residentInformation.lastName && residentInformation.firstName) {
      removeBlankLocations();
      onSave(residentInformation);
      setFirstNameError();
      setLastNameError();
    }
  };

  const classEditResidentContainer = clsx(
    classes.edit_resident_container,
    isSameSize(size, 'xl') ? classes.edit_resident_container_xl : '',
  );

  const getMarks = () => {
    return [
      {
        value: 10,
        label: '10%',
      },
      {
        value: 25,
        label: '25%',
      },
      {
        value: 50,
        label: '50%',
      },
      {
        value: 75,
        label: '75%',
      },
      {
        value: 90,
        label: '90%',
      },
    ];
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCancel}
      closeOnClickOutside={false}
      title={labels.RESIDENT_INFORMATION}
      icon={<User />}
      color="cosmicCobalt2"
      noBodyScroll={true}
      noBodyPadding={true}
      className={classes.edit_resident_modal_container}
    >
      <div className={classEditResidentContainer}>
        <div className={clsx(classes.row, classes.with_padding)}>
          <div className={classes.family_file_header}>
            <Typography>{labels.FAMILY_FILE_ID}</Typography>
            <div className={classes.family_file_id}>
              <Typography level="small" bold>
                {basicInformation.familyFileId}
              </Typography>
            </div>
          </div>
          <Tag color="fieldDrab5" text={basicInformation.salesPhase} />
        </div>
        {showErrorMessage && (
          <Toast
            type="error"
            className={classes.error_message}
            onClose={() => {}}
          >
            <Typography>{labels.UNABLE_TO_SAVE_DUE_TO_ERROR}</Typography>
          </Toast>
        )}
        <Typography bold className={classes.about_resident_title}>
          {labels.ABOUT_THE_RESIDENT}
        </Typography>
        <div className={clsx(classes.row, classes.basic_information_container)}>
          <div className={clsx(classes.names_container)}>
            <TextInput
              label={labels.FIRST_NAME}
              value={residentInformation.firstName}
              className={clsx(
                classes.default_input,
                classes.default_input_name,
              )}
              onChange={handleOnChange(
                'firstName',
                setFirstNameError,
                setProperty,
              )}
              error={!!firstNameError}
              textHint={firstNameError}
              id={'first-name'}
              isMandatory
              maxLength={45}
            />
            <TextInput
              label={labels.LAST_NAME}
              value={residentInformation.lastName}
              className={clsx(
                classes.default_input,
                classes.default_input_name,
              )}
              onChange={handleOnChange(
                'lastName',
                setLastNameError,
                setProperty,
              )}
              error={!!lastNameError}
              textHint={lastNameError}
              id={'last-name'}
              isMandatory
              maxLength={45}
            />
          </div>
          {enableReferralAlertValidation && (
            <div className={clsx(classes.age_container)}>
              <TextInput
                label={labels.AGE}
                value={
                  residentInformation.age
                    ? residentInformation.age.toString()
                    : ''
                }
                type="number"
                endIcon={
                  <Typography color="eerieBlack5">
                    {labels.YEARS_OLD}
                  </Typography>
                }
                className={classes.default_input}
                onChange={handleAgeOnChange}
                id={'age'}
                maxLength={3}
              />
              <TextInput
                label={labels.YEAR_OF_BIRTH}
                disabled
                value={yearOfBirth}
                className={classes.year_calculation}
                id={'year-calculation'}
              />
            </div>
          )}
        </div>
        <div
          className={clsx(classes.column, classes.preferred_location_container)}
        >
          <Typography level="small">{labels.PREFERRED_LOCATIONS}</Typography>
          {residentInformation.preferredLocations.map((location, key) => (
            <Fragment key={key}>
              {location.deleteState === DELETE_STATE_PARTIAL && (
                <Toast
                  type="warning"
                  className={classes.preferred_location_message}
                >
                  <Typography>
                    {confirmLabels[0]} &quot;{preferredLocationText(location)}
                    &quot; {confirmLabels[1]}
                    <span
                      onClick={() =>
                        handleUnDelete(location.familyDesiredLocationId)
                      }
                    >
                      {confirmLabels[2]}
                    </span>
                    {confirmLabels[3]}
                  </Typography>
                </Toast>
              )}
            </Fragment>
          ))}

          {residentInformation.preferredLocations.map((location, key) => (
            <Fragment key={key}>
              {location.deleteState === DELETE_STATE_NONE && (
                <PlacesInput
                  labels={labels}
                  preferredLocation={location}
                  startIcon={<PinStart />}
                  endIcon={<Delete />}
                  id={`preferred-location-${key}`}
                  onChange={(event) =>
                    handlePreferredLocationChange(
                      event,
                      location.familyDesiredLocationId,
                    )
                  }
                  onDelete={(state) =>
                    handlePreferredLocationDelete(
                      state,
                      location.familyDesiredLocationId,
                    )
                  }
                  onAdd={handlePreferredLocationAdd}
                />
              )}
            </Fragment>
          ))}
        </div>
        <Divider className={classes.divider} color="platinum1" />
        <div className={classes.move_in_date_container}>
          <Typography bold>{labels.MOVE_IN_QUESTION}</Typography>
          <div
            className={clsx(
              classes.row,
              classes.resident_likelihood_to_move_container,
            )}
          >
            <MonthAndYearPicker
              labels={labels}
              label={labels.EXPECTED_MOVE_IN_DATE}
              className={classes.move_in_date_picker}
              selectedMonth={getMonthAndYear().month}
              selectedYear={getMonthAndYear().year}
              onChange={handleOnChangeExpectedMove}
              basicInformation={basicInformation}
            />
            <Slider
              max={100}
              min={0}
              values={[parseInt(residentInformation.likeliHoodToMove)]}
              className={classes.likelihood_to_move_slider}
              label={labels.LIKELIHOOD_MOVE}
              step={null}
              onChange={(item) =>
                setProperty('likeliHoodToMove', parseInt(item))
              }
              marks={getMarks()}
            />
          </div>
        </div>

        {enableReferralAlertValidation && (
          <>
            <Divider className={classes.divider} color="platinum1" />
            <div className={classes.types_of_care_container}>
              <Typography bold>{labels.TYPE_OF_CARE}</Typography>
              <Typography
                level="small"
                color="eerieBlack5"
                className={classes.select_type_of_care}
              >
                {labels.SELECT_TYPE_OF_CARE}
              </Typography>
              <div className={classes.row}>
                <Checkbox
                  indeterminate={isAllSelectCareTypeOn}
                  label={
                    isAllSelectCareTypeOn
                      ? labels.DESELECT_ALL
                      : labels.SELECT_ALL
                  }
                  onChange={handleSelectAllCareTypes}
                  checked={isAllSelectCareTypeOn}
                />
              </div>
              <div
                className={clsx(classes.row, classes.type_of_cares_container)}
              >
                <div className={classes.column}>
                  <Checkbox
                    label={`${labels.MEMORY_CARE} (Z)`}
                    onChange={(item) =>
                      handleCareTypesSelection(item.target.checked, {
                        code: 'Z',
                        name: labels.MEMORY_CARE,
                      })
                    }
                    checked={isCareTypeChecked('Z')}
                  />
                  <Checkbox
                    label={`${labels.ASSISTED_LIVING} (A)`}
                    onChange={(item) =>
                      handleCareTypesSelection(item.target.checked, {
                        code: 'A',
                        name: labels.ASSISTED_LIVING,
                      })
                    }
                    checked={isCareTypeChecked('A')}
                  />
                  <Checkbox
                    label={`${labels.RETIREMENT_COMMUNITY} (R)`}
                    onChange={(item) =>
                      handleCareTypesSelection(item.target.checked, {
                        code: 'R',
                        name: labels.RETIREMENT_COMMUNITY,
                      })
                    }
                    checked={isCareTypeChecked('R')}
                  />
                  <Checkbox
                    label={`${labels.SENIOR_APARTMENTS} (P)`}
                    onChange={(item) =>
                      handleCareTypesSelection(item.target.checked, {
                        code: 'P',
                        name: labels.SENIOR_APARTMENTS,
                      })
                    }
                    checked={isCareTypeChecked('P')}
                  />
                </div>
                <div className={classes.column}>
                  <Checkbox
                    label={`${labels.HOME_CARE} (H)`}
                    onChange={(item) =>
                      handleCareTypesSelection(item.target.checked, {
                        code: 'H',
                        name: labels.HOME_CARE,
                      })
                    }
                    checked={isCareTypeChecked('H')}
                  />
                  <Checkbox
                    label={`${labels.RESIDENTIAL_CARE_HOME} (G)`}
                    onChange={(item) =>
                      handleCareTypesSelection(item.target.checked, {
                        code: 'G',
                        name: labels.RESIDENTIAL_CARE_HOME,
                      })
                    }
                    checked={isCareTypeChecked('G')}
                  />
                  <Checkbox
                    label={`${labels.NURSING_HOME} (N)`}
                    onChange={(item) =>
                      handleCareTypesSelection(item.target.checked, {
                        code: 'N',
                        name: labels.NURSING_HOME,
                      })
                    }
                    checked={isCareTypeChecked('N')}
                  />
                  <Checkbox
                    label={`${labels.ADULT_DAY_SERVICES} (D)`}
                    onChange={(item) =>
                      handleCareTypesSelection(item.target.checked, {
                        code: 'D',
                        name: labels.ADULT_DAY_SERVICES,
                      })
                    }
                    checked={isCareTypeChecked('D')}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <Divider className={classes.divider} color="platinum1" />
      <div className={classes.actions_container}>
        <div className={clsx(classes.actions)}>
          <Button size="small" type="light" onClick={handleCancel}>
            {labels.CANCEL}
          </Button>
          <Button
            type={pendingDeletesExist() ? 'disabled' : 'secondary'}
            size="small"
            onClick={handleSubmit}
            startIcon={<CircleChecked />}
          >
            {labels.SAVE}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

EditResidentInformation.propTypes = {
  labels: PropTypes.object.isRequired,
  basicInformation: PropTypes.shape({
    resident: PropTypes.string,
    salesPhase: PropTypes.string,
    likeliHoodToMove: PropTypes.number,
    expectedMove: PropTypes.any,
    age: PropTypes.number,
    familyFileId: PropTypes.number.isRequired,
    careTypes: PropTypes.arrayOf(PropTypes.object),
    preferredLocations: PropTypes.arrayOf(PropTypes.object),
    doNotCall: PropTypes.bool.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  size: PropTypes.string,
  showErrorMessage: PropTypes.bool,
  enableReferralAlertValidation: PropTypes.bool,
};

EditResidentInformation.defaultProps = {
  enableReferralAlertValidation: false,
};

export default EditResidentInformation;
