import React from 'react';
import NounMe from '../../common/Icons/basic/NounMe';
import MedicalCross from '../../common/Icons/various/MedicalCross';
import Mental from '../../common/Icons/various/Mental';
import CheckMarks from '../../common/Icons/basic/CheckMarks';

const audienceProperties = {
  self_urgent_financially_qualified: {
    label: 'SELF_URGENT_FINANCIALLY_QUALIFIED',
    icon: <NounMe />,
    urgent: true,
  },
  self_not_urgent_financially_qualified: {
    label: 'SELF_NOT_URGENT_FINANCIALLY_QUALIFIED',
    icon: <NounMe />,
    urgent: false,
  },
  self_not_urgent_not_veteran_financially_unqualified: {
    label: 'SELF_NOT_URGENT_NOT_VETERAN_FINANCIALLY_UNQUALIFIED',
    icon: <NounMe />,
    urgent: false,
  },
  not_self_financially_unqualified_no_rush: {
    label: 'NOT_SELF_FINANCIALLY_UNQUALIFIED_NO_RUSH',
    icon: <NounMe />,
    urgent: false,
  },
  test_screening_process: {
    label: 'TEST_SCREENING_PROCESS',
    icon: <CheckMarks />,
    urgent: false,
  },
  seo_beacon_screening_variant: {
    label: 'SEO_BEACON_SCREENING_VARIANT',
    urgent: false,
  },
  seo_opp_gen_screening_variant: {
    label: 'SEO_OPP_GEN_SCREENING_VARIANT',
    urgent: false,
  },
  memory_care_urgent: {
    label: 'MEMORY_CARE_URGENT',
    icon: <Mental />,
    urgent: true,
  },
  memory_care: {
    label: 'MEMORY_CARE',
    icon: <Mental />,
    urgent: false,
  },
  health_care_test_urgent: {
    label: 'HEALTH_CARE_URGENT',
    icon: <MedicalCross />,
    urgent: true,
  },
  health_care_test: {
    label: 'HEALTH_CARE',
    icon: <MedicalCross />,
    urgent: false,
  },
};

export const mapAudience = (audience) => {
  if (!audience) return null;
  const audienceObject = Object.keys(audienceProperties).find((key) =>
    audience.includes(key),
  );
  return audienceObject ? audienceProperties[audienceObject] : null;
};
