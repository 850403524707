import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => ({
  container: {
    width: '100% !important',
  },
  body: {
    display: 'grid',
    gridTemplateColumns: '200px 1fr',
    gridGap: '8px',
  },
  answers: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export { useStyles };
