import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'common/Modal/Modal';
import TextInput from 'common/TextInput/TextInput';

const ExportDialog = ({ onClose, isOpen, content, labels }) => {
  return (
    <Modal
      icon={null}
      title={labels.EXPORT}
      isOpen={isOpen}
      noBodyScroll={true}
      noBodyPadding={true}
      onClose={onClose}
    >
      <div>
        <TextInput multiline value={content} rowsMax={20} />
      </div>
    </Modal>
  );
};

ExportDialog.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  content: PropTypes.string,
  labels: PropTypes.object,
};

export default ExportDialog;
