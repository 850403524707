import gql from 'graphql-tag';

export default gql`
  query($familyFileId: Int) {
    findFamilyFileById(id: $familyFileId) {
      familyFileId
      externalId
      oneId
    }
  }
`;
