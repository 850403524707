import React from 'react';
import PropTypes from 'prop-types';

const CalendarPlus = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="currentColor"
        d="M6 6H8V5H16V6H18V5H20V8H4V5H6V6ZM4 10V20H20V10H4ZM8 3H16V2H18V3H20C21.1046 3 22 3.89543 22 5V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V5C2 3.89543 2.89543 3 4 3H6V2H8V3ZM8 16V14H11V11H13V14H16V16H13V19H11V16H8Z"
      />
    </svg>
  );
};

CalendarPlus.propTypes = {
  className: PropTypes.string,
};

export default CalendarPlus;
