import React from 'react';
import PropTypes from 'prop-types';

const NounMe = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M14.352 0.592184C14.08 0.320184 13.664 0.320184 13.392 0.592184L9.84001 4.16018V2.94418C9.84001 2.57618 9.53601 2.27218 9.16801 2.27218C8.80001 2.27218 8.48001 2.57618 8.48001 2.94418V5.79218C8.48001 6.16018 8.78401 6.46418 9.15201 6.46418H12C12.368 6.46418 12.672 6.16018 12.672 5.79218C12.672 5.42418 12.384 5.12018 12.016 5.12018H10.8L14.352 1.55218C14.624 1.29618 14.624 0.864184 14.352 0.592184Z"
          fill="currentColor"
        />
        <g clipPath="url(#clip1)">
          <path
            d="M7.12512 9.73616C6.59483 10.4175 5.76953 10.8582 4.8417 10.8582C3.91329 10.8582 3.0877 10.4172 2.55751 9.73596C1.2999 10.6246 0.484863 12.1594 0.484863 13.7241C0.484863 13.97 0.684314 14.1697 0.930336 14.1697H8.752C8.99812 14.1697 9.19757 13.97 9.19757 13.7241C9.19747 12.1594 8.38244 10.6246 7.12512 9.73616Z"
            fill="currentColor"
          />
          <path
            d="M7.29121 7.96235C7.29121 9.31581 6.19452 10.413 4.84164 10.413C3.48799 10.413 2.3912 9.31581 2.3912 7.96235C2.3912 6.60919 3.48789 5.51221 4.84164 5.51221C6.19452 5.51221 7.29121 6.60919 7.29121 7.96235Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1">
          <rect
            width="9.68208"
            height="9.68208"
            fill="white"
            transform="translate(0 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

NounMe.propTypes = {
  className: PropTypes.string,
};

export default NounMe;
