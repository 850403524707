import { makeStyles } from '../../Theme/Theme';

const useStyles = makeStyles(() => {
  return {
    cell: {
      display: 'table-cell',
      padding: '8px 16px',
    },
  };
});

export { useStyles };
