import { makeStyles } from 'common/Theme/Theme';
const topBarHeight = 72;

const useStyles = makeStyles((theme) => ({
  sidebar: {
    backgroundColor: theme.palette.light.platinum5,
    width: '27px',
    display: 'flex',
    boxShadow: '0px 0px 8px rgba(186, 186, 184, 0.3)',
  },
  sidebar_button: {
    width: '40px',
    height: '40px',
    boxSizing: 'border-box',
    display: 'flex',
    padding: '4px',
    borderRadius: '2px',
    fontSize: '24px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.light.platinum5,
    boxShadow: '0px 2px 4px rgba(13, 19, 22, 0.3)',
    '&:hover': {
      boxShadow: '0px 0px 8px rgba(13, 19, 33, 0.5)',
    },
    marginBottom: theme.spacing(2),
  },
  sidebar_button_btmMargin: {
    marginTop: '22px;',
    marginBottom: '0 !important',
  },
  icons_container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  icons_starting_position: {
    position: 'fixed',
    top: topBarHeight + 16,
    right: '7px',
  },
}));

export { useStyles };
