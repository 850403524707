import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  status_dropdown_container: {
    position: 'relative',
  },
  menu: {
    backgroundColor: theme.palette.light.platinum5,
    position: 'absolute',
    border: `1px solid ${theme.palette.neutral.cosmicCobalt5}`,
    boxShadow: '0px 0px 8px rgba(13, 19, 33, 0.2)',
    borderRadius: '2px',
    width: '240px',
    zIndex: 9,
    right: '0',
  },
  icon: {
    marginRight: '10px',
    '& svg': {
      fontSize: '24px',
      verticalAlign: 'middle',
    },
  },
  menu_item: {
    display: 'flex',
    alignItems: 'center',
    padding: '9px 18px',
  },
  menu_item_left: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '9px 18px',
  },
  selectable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.neutral.dropdown,
    },
  },
  wintergreenDream2: {
    '& svg': {
      color: theme.palette.positive.wintergreenDream2,
      fill: theme.palette.positive.wintergreenDream2,
    },
  },
  hotCinnamon3: {
    '& svg': {
      color: theme.palette.miscellaneous1.hotCinnamon3,
      fill: theme.palette.miscellaneous1.hotCinnamon3,
    },
  },
  eerieBlack1: {
    '& svg': {
      color: theme.palette.dark.eerieBlack1,
      fill: theme.palette.dark.eerieBlack1,
    },
  },
  eerieBlack1_disabled: {
    '& svg': {
      color: theme.palette.dark.disabled,
      fill: theme.palette.dark.disabled,
    },
  },
  smokyTopaz2: {
    '& svg': {
      color: theme.palette.correction.smokyTopaz2,
      fill: theme.palette.correction.smokyTopaz2,
    },
  },
  group_name: {
    padding: '10px 16px',
  },
  actions_container: {
    padding: '15px 0',
  },
  actions: {
    textAlign: 'center',
    '& button': {
      marginLeft: theme.spacing(2),
    },
  },
  radio_container: {
    display: 'flex',
  },
  radio_item: {
    margin: '0px',
    paddingLeft: '8px',
  },
}));
export { useStyles };
