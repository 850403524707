export const GET_INQUIRY_REQUEST = 'inquiry/get/request';
export const GET_INQUIRY_SUCCESS = 'inquiry/get/success';
export const GET_INQUIRY_ERROR = 'inquiry/get/error';

export const UPDATE_INQUIRY = 'inquiry/update';
export const UPDATE_INQUIRY_FAMILY_FILE_ID = 'inquiry/update/familyFileId';
export const UPDATE_LATEST_INQUIRY_ID = 'inquiry/updateLatestInquiryId';

export const CREATE_INQUIRY_REQUEST = 'inquiry/create/request';
export const CREATE_INQUIRY_SUCCESS = 'inquiry/create/success';
export const CREATE_INQUIRY_ERROR = 'inquiry/create/error';

export const GET_INQUIRY_DETAILS_REQUEST = 'inquiry/details/get/request';
export const GET_INQUIRY_DETAILS_SUCCESS = 'inquiry/details/get/success';
export const GET_INQUIRY_DETAILS_ERROR = 'inquiry/details/get/error';

export const UPDATE_CALL_ID = 'inquiry/updateCallId';

export const UPDATE_FINISHED_INBOUND_CALL = 'inquiry/updateFinishedInboundCall';

export const CANCEL_PENDING_INQUIRIES = 'inquiries/cancel';
export const FIND_INQUIRIES_BY_PARAMS_REQUEST =
  'inquiries/findByParams/request';
export const FIND_INQUIRIES_BY_PARAMS_SUCCESS =
  'inquiries/findByParams/success';
export const FIND_INQUIRIES_BY_PARAMS_ERROR = 'inquiries/findByParams/error';

export const SAVE_LAST_CONTACTED_DATES =
  'inquiries/saveLastContactedDates/success';

export const SAVE_IS_DNC = 'inquiries/saveIsDnc/success';
export const SAVE_IS_CALL_QUEUED = 'inquiries/saveIsCallQueued/success';

export const SET_REDIRECT_TO_WAITING_SCREEN =
  'inquiries/redirect/waitingScreen';

export const RESET_INQUIRY_STATE = 'inquiry/reset';

export const getInquiryRequest = (inquiryId, allowDisabledChiclets) => ({
  type: GET_INQUIRY_REQUEST,
  payload: { inquiryId, allowDisabledChiclets },
});

export const getInquirySuccess = (response) => {
  return { type: GET_INQUIRY_SUCCESS, payload: { response } };
};

export const getInquiryError = (error) => {
  return { type: GET_INQUIRY_ERROR, payload: { error } };
};

export const updateInquiry = (inquiry) => {
  return { type: UPDATE_INQUIRY, payload: { inquiry } };
};

export const updateInquiryFamilyFileId = (familyFileId) => {
  return { type: UPDATE_INQUIRY_FAMILY_FILE_ID, payload: { familyFileId } };
};

export const updateLatestInquiryId = (inquiryId) => {
  return { type: UPDATE_LATEST_INQUIRY_ID, payload: { inquiryId } };
};

export const createInquiryRequest = (primaryPhone, dnis) => ({
  type: CREATE_INQUIRY_REQUEST,
  payload: { primaryPhone, dnis },
});

export const createInquirySuccess = (inquiry) => {
  return { type: CREATE_INQUIRY_SUCCESS, payload: { inquiry } };
};

export const createInquiryError = (error) => {
  return { type: CREATE_INQUIRY_ERROR, payload: { error } };
};

export const updateCallId = (callId) => {
  return { type: UPDATE_CALL_ID, payload: { callId } };
};

export const updateFinishedInboundCall = (isInboundCallFinished) => {
  return {
    type: UPDATE_FINISHED_INBOUND_CALL,
    payload: { isInboundCallFinished },
  };
};

export const cancelPendingInquiries = () => ({
  type: CANCEL_PENDING_INQUIRIES,
});

export const findInquiriesByParamsRequest = (searchCriteria) => ({
  type: FIND_INQUIRIES_BY_PARAMS_REQUEST,
  payload: { searchCriteria },
});

export const findInquiriesByParamsSuccess = (response) => {
  return { type: FIND_INQUIRIES_BY_PARAMS_SUCCESS, payload: { response } };
};

export const findInquiriesByParamsError = (error) => {
  return { type: FIND_INQUIRIES_BY_PARAMS_ERROR, payload: { error } };
};

export const saveLastContactedDatesRequest = (
  inquiryId,
  lastContactedDates,
) => {
  return {
    type: SAVE_LAST_CONTACTED_DATES,
    payload: { inquiryId, lastContactedDates },
  };
};

export const saveIsDncRequest = (inquiryId, isDnc) => {
  return {
    type: SAVE_IS_DNC,
    payload: { inquiryId, isDnc },
  };
};

export const saveIsCallQueuedRequest = (inquiryId, isQueued, listName) => {
  return {
    type: SAVE_IS_CALL_QUEUED,
    payload: { inquiryId, isQueued, listName },
  };
};

export const getInquiryDetailsRequest = (inquiryId) => ({
  type: GET_INQUIRY_DETAILS_REQUEST,
  payload: { inquiryId },
});

export const getInquiryDetailsSuccess = (response) => {
  return { type: GET_INQUIRY_DETAILS_SUCCESS, payload: { response } };
};

export const getInquiryDetailsError = (error) => {
  return { type: GET_INQUIRY_DETAILS_ERROR, payload: { error } };
};

export const setRedirectToWaitingScreen = (isRedirectToWaitingScreen) => {
  return {
    type: SET_REDIRECT_TO_WAITING_SCREEN,
    payload: { isRedirectToWaitingScreen },
  };
};

export const resetInquiryState = () => ({ type: RESET_INQUIRY_STATE });
