import restClient from '../utils/restClient';
import withConfig from '../utils/withConfig';
// import { truncateString } from '../utils/truncateString';
import { logEvent } from './analyticsService';
import { formatPhoneWithParenthesis } from '../utils/phoneFormat';
// import { buildCommunityUrl } from './communityRatingsService';

const renderHelpfulResources = (linksToSend) => {
  if (linksToSend.length === 0) {
    return '';
  }

  const links = linksToSend.map((link) => {
    return `<li><a href="${link.link}" target="_blank">${link.name}</a></li>`;
  });

  const title = '<p><b>Helpful Resource Guides for Your Search</b></p>';
  const helpfulResourcesBody = `<div style='width: 100%; display: block;'>${title}<ul type='disc'>${links.join(
    '',
  )}</ul></div>`;
  return helpfulResourcesBody;
};

const getMySearchBlock = (mySearchLink) => {
  const searchBlock =
    '<p>In the meantime, I encourage you to take control of your search with ' +
    `<a href="${mySearchLink}" target="_blank">MySearch</a>` +
    ', an online tool to help you conveniently message and ' +
    'organize your options.</p>';
  return searchBlock;
};

const getSelfMySearchBlock = (mySearchLink) => {
  const selfSearchBlock =
    'In the meantime, I encourage you to start managing your search by using the ' +
    `<a href="${mySearchLink}" style="line-height:18px; color:#000000;">MySearch</a>` +
    ' tool, which will help you stay organized throughout the process.';
  return selfSearchBlock;
};

const getMySearchButton = (mySearchLink) => {
  const searchButton =
    `<a href="${mySearchLink}" target="_blank">` +
    '<span style="color: white; text-decoration: none;">' +
    '<img border="0" width="173" height="51" src="https://www.staging.aplaceformom.com/style/email/mysearch-button.gif" vspace="32" alt="Go to MySearch" class="CToWUd">' +
    '</span></a>';
  return searchButton;
};

const getMySearchLink = (mySearchLink) => {
  const searchLink = `<a href="${mySearchLink}" target="_blank">Go to MySearch</a>`;
  return searchLink;
};

const getMySearchPhrase = (mySearchLink) => {
  const searchLink = `<td style="line-height:20px" class="mobile_text1"><div style="font-size:14px; font-family:Georgia, serif; color:#000;" class="mobile_text1">Go to <a href="${mySearchLink}" style="font-size:14px; font-family:Georgia, serif; color:#1d778c; text-decoration:underline;">MySearch</a> <span style="font-size:14px; font-family:Georgia, serif; color:#000;" class="mobile_text1">to schedule a visit to a community automatically.</span></div></td>`;
  return searchLink;
};

const getFullName = (user) => {
  return `${user?.firstName} ${user?.lastName}`;
};

const renderSelfHelpfulResources = (linksToSend) => {
  if (linksToSend.length === 0) {
    return '';
  }

  const links = linksToSend.map((link) => {
    return `<li><a href="${link.link}" target="_blank">${link.name}</a></li>`;
  });

  const title = ' <strong>Helpful Resources for Your Search</strong>';
  const helpfulResourcesBody = `<td style="line-height:20px" class="mobile_text1"><div style="font-family:Georgia, serif; color:#333333; font-size:14px;" class="mobile_text1">${title}<ul>${links.join(
    '',
  )}</ul></div><br></td>`;
  return helpfulResourcesBody;
};

const getSLAHeadShotImage = (thumbnailImageId) => {
  if (!thumbnailImageId || thumbnailImageId === null) {
    return '';
  }
  const searchLink = `<img style="border: 0;" src="https://services-dev.aplaceformom.com/images/${thumbnailImageId}" alt="" width="92" height="100"/>`;
  return searchLink;
};

const renderCommunities = (communities, mySearchLink) => {
  const emptyTd =
    '<td width="5%" style=" width: 5%; border: none; padding: 0in 0in 0in 0in; " ></td>';
  const communitiesStartBody =
    '<table border="0" cellspacing="0" cellpadding="0" width="100%" style="width: 100%; background: whitesmoke;">' +
    '<tbody>' +
    '<tr style="height: 6pt;">' +
    '<td width="6%" style="width: 6%; padding: 0in 0in 0in 0in; height: 6pt;"></td>' +
    '<td width="87%" style="width: 87%; border: none; border-top: solid #e5e5e5 1pt; padding: 0in 0in 0in 0in; height: 6pt; "></td>' +
    '<td width="6%" style="width: 6%; padding: 0in 0in 0in 0in; height: 6pt;"></td>' +
    '</tr>' +
    '<tr>' +
    '<td width="6%" style="width: 6%; padding: 0in 0in 0in 0in;"></td>';
  const communitiesTitle =
    '<td width="87%" valign="top" style="width: 87%; padding: 0in 0in 0in 0in;"><h1>' +
    '<span style="font-size: 18pt; color: #f28a2c;">Senior Living & Care Options</span>' +
    '</h1></td>' +
    '<td width="6%" style="width: 6%; padding: 0in 0in 0in 0in;"></td></tr>' +
    '<tr><td width="6%" style="width: 6%; padding: 0in 0in 0in 0in;"></td>' +
    '<td width="87%" style="width: 87%; padding: 0in 0in 0in 0in;">';

  const getScheduledTourDateLabel = (scheduledTourDateLabel) => {
    if (!scheduledTourDateLabel) {
      return '';
    }

    return (
      '<tr style="height: 9pt;"><td colspan="3" style=" border: none; padding: 0in 0in 0in 0in; height: 9pt; "></td></tr>' +
      '<tr><td width="5%" style=" width: 5%; border: none; padding: 0in 0in 0in 0in;"></td>' +
      '<td width="87%" style=" width: 87%; border: none; padding: 0in 0in 0in 0in;">' +
      `<p class="MsoNormal">Tour: <b>${scheduledTourDateLabel}</b></p>` +
      '</td><td width="5%" style=" width: 5%; border: none; padding: 0in 0in 0in 0in;"></td>' +
      '</tr>'
    );
  };

  const getCommunityAddress = (community) => {
    if (!community) {
      return '';
    }

    return (
      '<tr><td width="5%" style=" width: 5%; border: none; padding: 0in 0in 0in 0in;"></td>' +
      '<td width="87%" style=" width: 87%; border: none; padding: 0in 0in 0in 0in;">' +
      `<p class="MsoNormal" style="color: #656871">${community.address} ${
        community.address1 || ''
      } ${community.city} ${community.state} ${community.zip}</p>` +
      '</td><td width="5%" style=" width: 5%; border: none; padding: 0in 0in 0in 0in;"></td>' +
      '</tr>'
    );
  };

  // TODO: The following sections have been commented out per BUB-2253 to be added later when a decision regarding format is made
  //
  // const buildStarRatings = (rating) => {
  //   if (!rating) {
  //     return '';
  //   }

  //   const defaultStars = [
  //     '&#9734;',
  //     '&#9734;',
  //     '&#9734;',
  //     '&#9734;',
  //     '&#9734;',
  //   ];
  //   const filledStar = '&#9733;';

  //   const roundedRating = Math.floor(rating);
  //   let ratings = defaultStars
  //     .map((star, idx) => (idx < roundedRating ? filledStar : star))
  //     .join('');

  //   return `<span style="color: #E9CE73">${ratings}</span>`;
  // };

  // const getCommunityRating = (
  //   { numberOfReviews, ratingAverage },
  //   communityUrl,
  // ) => {
  //   if (!numberOfReviews || ratingAverage < 4) {
  //     return '';
  //   }

  //   return (
  //     '<tr style="height: 6pt;"><td colspan="3" style=" border: none; padding: 0in 0in 0in 0in; height: 6pt; "></td></tr>' +
  //     '<tr><td width="5%" style=" width: 5%; border: none; padding: 0in 0in 0in 0in;"></td>' +
  //     '<td width="87%" style=" width: 87%; border: none; padding: 0in 0in 0in 0in;">' +
  //     `<p class="MsoNormal" style="color: #656871">` +
  //     buildStarRatings(ratingAverage) +
  //     `&nbsp;<a href="${communityUrl}" style="color: #1f8aac; font-weight: bold;" target="_blank">${numberOfReviews} Reviews</a></p>` +
  //     '</td><td width="5%" style=" width: 5%; border: none; padding: 0in 0in 0in 0in;"></td>' +
  //     '</tr>'
  //   );
  // };

  // const getCommunityReview = ({ reviews, ratingAverage }, communityUrl) => {
  //   if (ratingAverage < 4 || !reviews || reviews?.length === 0) {
  //     return '';
  //   }

  //   return (
  //     '<tr style="height: 6pt;"><td colspan="3" style=" border: none; padding: 0in 0in 0in 0in; height: 6pt; "></td></tr>' +
  //     '<tr>' +
  //     `${emptyTd}` +
  //     '<td width="89%" valign="top" style=" width: 89%; border: none; padding: 0in 0in 0in 0in; ">' +
  //     '<p class="MsoNormal"><strong>Helpful Review</strong></p>' +
  //     '</td>' +
  //     `${emptyTd}` +
  //     '</tr>' +
  //     '<tr style="height: 6pt;"><td colspan="3" style=" border: none; padding: 0in 0in 0in 0in; height: 6pt; "></td></tr>' +
  //     '<tr>' +
  //     `${emptyTd}` +
  //     '<td width="89%" valign="top" style=" width: 89%; border: none; padding: 0in 0in 0in 0in; " >' +
  //     `<p class="MsoNormal">"${truncateString(
  //       reviews[0]?.content,
  //       200,
  //       true,
  //     )}" <a href="${communityUrl}" style="color: #1f8aac;" target="_blank">more &raquo;</a></p>` +
  //     '</td>' +
  //     `${emptyTd}` +
  //     '</tr>'
  //   );
  // };

  const getAdditionalNote = (community) => {
    if (!community?.note) {
      return '';
    }

    return (
      '<tr style="height: 6pt;"><td colspan="3" style=" border: none; padding: 0in 0in 0in 0in; height: 6pt; "></td></tr>' +
      '<tr><td width="5%" style=" width: 5%; border: none; padding: 0in 0in 0in 0in;"></td>' +
      '<td width="87%" style=" width: 87%; border: none; padding: 0in 0in 0in 0in;">' +
      `<p class="MsoNormal">${community.note}</p>` +
      '</td><td width="5%" style=" width: 5%; border: none; padding: 0in 0in 0in 0in;"></td>' +
      '</tr>'
    );
  };

  const getPrimaryContactName = (community) => {
    if (
      !community.primaryContact?.firstName ||
      !community.primaryContact?.lastName
    ) {
      return '';
    }

    return (
      '<tr style="height: 6pt;"><td colspan="3" style=" border: none; padding: 0in 0in 0in 0in; height: 6pt; " ></td></tr>' +
      '<tr>' +
      `${emptyTd}` +
      '<td width="89%" valign="top" style=" width: 89%; border: none; padding: 0in 0in 0in 0in; " >' +
      `<p class="MsoNormal">${community.primaryContact?.firstName} ${community.primaryContact?.lastName}</p>` +
      '</td>' +
      `${emptyTd}` +
      '</tr>'
    );
  };

  const communitiesBlock = communities.map((community) => {
    // TODO: Uncomment this line when getCommunityRating/getCommunityReview are implemented
    // const communityUrl = buildCommunityUrl(community?.id, community?.name);

    return (
      '<table border="1" cellspacing="0" cellpadding="0" width="100%" style=" width: 100%; background: white; border: solid #e8dfec 2.25pt; margin-top: 10px;">' +
      '<tbody>' +
      '<tr style="height: 12pt;">' +
      '<td colspan="3" style=" border: none; padding: 0in 0in 0in 0in; height: 12pt;"></td></tr>' +
      '<tr>' +
      '<td width="5%" style="width: 5%; border: none; padding: 0in 0in 0in 0in;"></td>' +
      '<td width="89%" valign="top" style=" width: 89%; border: none; padding: 0in 0in 0in 0in;">' +
      '<p class="MsoNormal">' +
      `<a href="${mySearchLink}"` +
      'target="_blank">' +
      '<strong>' +
      '<span style="font-size: 13.5pt; color: #1f8aac; text-transform: uppercase; text-decoration: none;">' +
      `${community.name} </span>` +
      '</strong></a></p></td>' +
      '<td width="5%" style="width: 5%; border: none; padding: 0in 0in 0in 0in;"></td>' +
      '</tr>' +
      '<tr style="height: 6pt;"><td colspan="3" style=" border: none; padding: 0in 0in 0in 0in; height: 6pt; "></td></tr>' +
      getCommunityAddress(community) +
      // getCommunityRating(community, communityUrl) +
      // getCommunityReview(community, communityUrl) +
      getScheduledTourDateLabel(community.scheduledTourDateLabel) +
      getAdditionalNote(community) +
      '<tr style="height: 6pt;"><td colspan="3" style=" border: none; padding: 0in 0in 0in 0in; height: 6pt; " ></td></tr>' +
      '<tr>' +
      '<td width="5%" style=" width: 5%; border: none; padding: 0in 0in 0in 0in;" ></td>' +
      '<td width="89%" style=" width: 89%; border: none; padding: 0in 0in 0in 0in; ">' +
      '<div class="MsoNormal" align="center" style="text-align: center;" >' +
      '<hr size="1" width="100%" noshade="" style="color: #e5e5e5;" align="center" />' +
      '</div></td>' +
      '<td width="5%" style=" width: 5%; border: none; padding: 0in 0in 0in 0in;" ></td>' +
      '</tr>' +
      '<tr style="height: 6pt;"><td colspan="3" style=" border: none; padding: 0in 0in 0in 0in; height: 6pt; " ></td></tr>' +
      '<tr>' +
      `${emptyTd}` +
      '<td width="89%" valign="top" style=" width: 89%; border: none; padding: 0in 0in 0in 0in; ">' +
      '<p class="MsoNormal"><strong>Contact</strong></p>' +
      '</td>' +
      `${emptyTd}` +
      '</tr>' +
      getPrimaryContactName(community) +
      '<tr style="height: 12pt;"><td colspan="3" style=" border: none; padding: 0in 0in 0in 0in; height: 12pt; "></td></tr>' +
      '<tr>' +
      `${emptyTd}` +
      '<td width="89%" valign="top" style=" width: 89%; border: none; padding: 0in 0in 0in 0in; " >' +
      '<p><span>' +
      `<a href="tel:${community.phoneNumber}" target="_blank"><b><span style=" color: #f28a2c; text-decoration: none; " >Call ${community.phoneNumber}</span></b ></a ></span >` +
      '</p></td>' +
      `${emptyTd}` +
      '</tr>' +
      '<tr style="height: 9pt;">' +
      '<td colspan="3" style=" border: none; padding: 0in 0in 0in 0in; height: 9pt; " ></td> </tr>' +
      '</tbody></table>'
    );
  });

  const communitiesEndBody =
    '<tr style="height: 12pt;"><td colspan="3" style="padding: 0in 0in 0in 0in; height: 12pt;"></td></tr>' +
    '<tr style="height: 6pt;"><td colspan="3" style="padding: 0in 0in 0in 0in; height: 6pt;"></td></tr>' +
    '</tbody></table>';

  return `${communitiesStartBody}${communitiesTitle}${communitiesBlock.join(
    '',
  )}${communitiesEndBody}`;
};

const getFooterListText = () => {
  return `<table border="0" cellspacing="0" cellpadding="0" align="left" width="60%" style="width: 60%;">
    <tbody>
      <tr>
        <td width="5%" valign="top" style="width: 5%; padding: 0in 0in 0in 0in;">
          <p class="MsoNormal">&bull;</p>
        </td>
        <td width="95%" valign="top" style="width: 95%; padding: 0in 0in 0in 0in;">
          <p class="MsoNormal"> Organize your decisions by taking notes and rating your
            options<u></u><u></u></p>
        </td>
      </tr>
      <tr style="height: 3pt;">
        <td colspan="2" style="padding: 0in 0in 0in 0in; height: 3pt;"></td>
      </tr>
      <tr>
        <td width="5%" valign="top" style="width: 5%; padding: 0in 0in 0in 0in;">
          <p class="MsoNormal">&bull;</p>
        </td>
        <td width="95%" valign="top" style="width: 95%; padding: 0in 0in 0in 0in;">
          <p class="MsoNormal"> Schedule tours or connect with providers instantly<u></u><u></u></p>
        </td>
      </tr>
      <tr style="height: 3pt;">
        <td colspan="2" style="padding: 0in 0in 0in 0in; height: 3pt;"></td>
      </tr>
      <tr>
        <td width="5%" valign="top" style="width: 5%; padding: 0in 0in 0in 0in;">
          <p class="MsoNormal">&bull;</p>
        </td>
        <td width="95%" valign="top" style="width: 95%; padding: 0in 0in 0in 0in;">
          <p class="MsoNormal"> View your complete referral list with photos and information about each
            option<u></u><u></u></p>
        </td>
      </tr>
      <tr style="height: 3pt;">
        <td colspan="2" style="padding: 0in 0in 0in 0in; height: 3pt;"></td>
      </tr>
      <tr>
        <td width="5%" valign="top" style="width: 5%; padding: 0in 0in 0in 0in;">
          <p class="MsoNormal">&bull;</p>
        </td>
        <td width="95%" valign="top" style="width: 95%; padding: 0in 0in 0in 0in;">
          <p class="MsoNormal"> See your selections on a map<u></u><u></u></p>
        </td>
      </tr>
    </tbody>
  </table>`;
};

const getSenderContactMethod = (user) => {
  if (user?.workPhone) {
    return `<a href="tel:${
      user?.workPhone
    }" target="_blank"><b><span style=" color: #f28a2c; text-decoration: none; " >Call ${formatPhoneWithParenthesis(
      user?.workPhone,
      true,
    )}</a>`;
  }
  return `<a href="mailto:${user?.email}">${user?.email}</a>`;
};

const getMySearchUrl = async (familyFileId) => {
  const xmlBody = `<request> <leadId>${familyFileId}</leadId> <urlType>Beacon</urlType> </request>`;
  const getMySearchPromise = restClient.postWithoutToken(
    withConfig('MY_SEARCH_LINK'),
    xmlBody,
  );
  const link = await getMySearchPromise
    .then((response) => {
      const parser = new DOMParser();
      const xml = parser.parseFromString(response.data, 'text/xml');
      const url = decodeURIComponent(
        xml.getElementsByTagName('url')[0].childNodes[0].nodeValue,
      );
      return url;
    })
    .catch((error) => {
      console.error('xml error: ', error);
      return undefined;
    });

  return link;
};

const disclosureStates = {
  TX: 'Texas',
  MD: 'Maryland',
};

const getSpecialDisclosure = ({ shouldSkip, communityNames, postalCodes }) => {
  if (shouldSkip) return '';
  const introMessage = `<p> <span style="font-size: 9pt; color: #919191;" >Based on our conversation, we determined that the providers listed above best meet your financial, clinical, and geographic needs. Texas and Maryland law requires that we also provide a more comprehensive list of any other providers in the zip codes that you requested. A Place for Mom does not recommend or endorse any providers, including those in this broader listing. Some of the providers on the broader list below may participate in our referral network but are not in the price range you indicated, or cannot meet your clinical needs. Others may not be in our network because they have elected not to participate, or because they are unlicensed residential care homes (APFM only refers licensed assisted living communities and residential care homes). If you have any questions about any communities on this broader list, or if your needs change, please contact me and I will be happy to review the information we have on file for the community to help you determine whether it meets your needs.</span > </p>`;
  const availableCommunities =
    '<p> <span style="font-size: 9pt; color: #919191;" >The following options are also available in the area(s) you are looking for: <p> <span style="font-size: 9pt; color: #919191;" >';
  const formattedCommunityNames = communityNames.sort().map((name) => {
    return `<p style=" margin: 0px; "> <span style="font-size: 9pt; color: #919191;">${name}</span > </p>`;
  });
  const noResults = `<p> <span style="font-size: 9pt; color: #919191;" >No additional options were found for the following Postal codes: ${postalCodes.join(
    ', ',
  )}</span > </p>`;
  const results =
    communityNames.length > 0
      ? availableCommunities + formattedCommunityNames.join('')
      : noResults;
  return introMessage + results;
};

const buildTemplateContent = async (
  letterInfo,
  currentUser,
  primaryContactFullName,
  primaryContactFirstName,
  specialDisclosure,
) => {
  const mySearchLink = await getMySearchUrl(letterInfo.familyFileId);
  const fullName = getFullName(letterInfo.user);

  return [
    {
      name: 'salutation',
      content: letterInfo.salutation,
    },
    {
      name: 'introduction',
      content: letterInfo.introduction,
    },
    {
      name: 'community-introduction',
      content: letterInfo.communityIntroduction,
    },
    {
      name: 'closing-paragraph',
      content: letterInfo.closingParagraph,
    },
    {
      name: 'sender-name',
      content: fullName,
    },
    {
      name: 'sender-role',
      content: 'Senior Living Advisor',
    },
    {
      name: 'sender-phone',
      content: getSenderContactMethod(letterInfo.user),
    },
    {
      name: 'helpful-resources',
      content: renderHelpfulResources(letterInfo.linksToSend),
    },
    {
      name: 'communities',
      content: renderCommunities(letterInfo.communityNotes, mySearchLink),
    },
    {
      name: 'my-search-block',
      content: getMySearchBlock(mySearchLink),
    },
    {
      name: 'my-search-button',
      content: getMySearchButton(mySearchLink),
    },
    {
      name: 'my-search-link',
      content: getMySearchLink(mySearchLink),
    },
    {
      name: 'special-disclosure',
      content: getSpecialDisclosure(specialDisclosure),
    },
    {
      name: 'sender-email',
      content: letterInfo.communitySLA?.email,
    },
    {
      name: 'sla-headshot-image',
      content: getSLAHeadShotImage(currentUser.thumbnailImageId),
    },
    {
      name: 'primary-contact-first-name',
      content: primaryContactFirstName,
    },
    {
      name: 'my-search-phrase',
      content: getMySearchPhrase(mySearchLink),
    },
    {
      name: 'additional-notes',
      content: letterInfo.additionalNotes,
    },
    {
      name: 'self-mysearch-block',
      content: getSelfMySearchBlock(mySearchLink),
    },
    {
      name: 'self-helpful-resources',
      content: renderSelfHelpfulResources(letterInfo.linksToSend),
    },
    {
      name: 'sla-full-name',
      content: `${letterInfo.user?.firstName ?? ''} ${
        letterInfo.user?.lastName ?? ''
      }`,
    },
    {
      name: 'sla-first-name',
      content: letterInfo.user?.firstName,
    },
    {
      name: 'sla-last-name',
      content: letterInfo.user?.lastName,
    },
    {
      name: 'footer-list',
      content: getFooterListText(),
    },
  ];
};

const toNumericCommunityIds = (communityIds) => {
  const numericCommunityIds = communityIds.map((communityId) =>
    Number(communityId),
  );
  return numericCommunityIds;
};

export const logFamilyLetterViewed = (communityIds, familyFileId, userId) => {
  const numericCommunityIds = toNumericCommunityIds(communityIds);
  logEvent('Family Letter Viewed', {
    channel: 'community',
    communityIdList: numericCommunityIds,
    crmUserId: userId,
    eventPath: 'family letter',
    eventType: 'view',
    familyFileId: familyFileId,
    screenName: 'family letter',
    section: 'family letter',
  });
};

export const logFamilyLetterSendAttempted = (
  communityIds,
  familyFileId,
  letterContactCount,
  linkNames,
  templateType,
  userId,
) => {
  const numericCommunityIds = toNumericCommunityIds(communityIds);
  logEvent('Family Letter Send Attempted', {
    channel: 'community',
    communityIdList: numericCommunityIds,
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    linksAdded: linkNames,
    numCommunitiesReferred: communityIds.length,
    numContactsIncluded: letterContactCount,
    screenName: 'family letter',
    section: 'family letter',
    templateType: templateType,
  });
};

export const logFamilyLetterSettingsSaved = (
  communityIds,
  familyFileId,
  linkNames,
  templateType,
  userId,
) => {
  const numericCommunityIds = toNumericCommunityIds(communityIds);
  logEvent('Family Letter Settings Saved', {
    channel: 'community',
    communityIdList: numericCommunityIds,
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    linksAdded: linkNames,
    numCommunitiesReferred: communityIds.length,
    screenName: 'family letter',
    section: 'family letter',
    templateType: templateType,
  });
};

export const logFamilyLetterSettingsCleared = (
  communityIds,
  familyFileId,
  linkNames,
  templateType,
  userId,
) => {
  const numericCommunityIds = toNumericCommunityIds(communityIds);
  logEvent('Family Letter Settings Cleared', {
    channel: 'community',
    communityIdList: numericCommunityIds,
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    linksAdded: linkNames,
    numCommunitiesReferred: communityIds.length,
    screenName: 'family letter',
    section: 'family letter',
    templateType: templateType,
  });
};

export const logFamilyLetterSendSucceeded = (
  communityIds,
  familyFileId,
  letterContactCount,
  linkNames,
  templateType,
  userId,
) => {
  const numericCommunityIds = toNumericCommunityIds(communityIds);
  logEvent('Family Letter Send Succeeded', {
    channel: 'community',
    communityIdList: numericCommunityIds,
    crmUserId: userId,
    eventPath: 'family letter success',
    eventType: 'view',
    familyFileId: familyFileId,
    linksAdded: linkNames,
    numCommunitiesReferred: communityIds.length,
    numContactsIncluded: letterContactCount,
    screenName: 'family letter success',
    section: 'family letter',
    templateType: templateType,
  });
};

export const logFamilyLetterErrorViewed = (
  communityIds,
  errorMessages,
  familyFileId,
  linkNames,
  templateType,
  userId,
) => {
  const numericCommunityIds = toNumericCommunityIds(communityIds);
  logEvent('Family Letter Error Viewed', {
    channel: 'community',
    communityIdList: numericCommunityIds,
    crmUserId: userId,
    errorMessages: errorMessages,
    eventPath: 'family letter success',
    eventType: 'view',
    familyFileId: familyFileId,
    linksAdded: linkNames,
    numCommunitiesReferred: communityIds.length,
    screenName: 'family letter',
    section: 'family letter',
    templateType: templateType,
  });
};

export const logFamilyLetterValidationViewed = (
  errorMessages,
  familyFileId,
  userId,
) => {
  logEvent('Family Letter Validation Viewed', {
    alertNotification: errorMessages,
    channel: 'community',
    crmUserId: userId,
    eventPath: 'family letter validation',
    eventType: 'view',
    familyFileId: familyFileId,
    screenName: 'family letter',
    section: 'family letter',
  });
};

export const logFamilyLetterSuccessButtonClicked = (
  buttonText,
  familyFileId,
  userId,
) => {
  logEvent('Family Letter Success Button Clicked', {
    buttonText: buttonText,
    channel: 'community',
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    screenName: 'family letter',
    section: 'family letter',
  });
};

export { buildTemplateContent, disclosureStates };
