import { put, select, takeLeading } from 'redux-saga/effects';
import formService from 'services/callCenter/formService';
import {
  selectCallState,
  selectDispositionModalOpen,
} from '../reducers/telephonyReducer';
import {
  updateFormSteps,
  saveFormChicletsRequest2,
} from '../../callcenter/formChiclets/actions/formChicletsActions';
import { setRedirectToWaitingScreen } from '../../callcenter/inquiry/actions/inquiryActions';
import {
  selectCloseReason,
  selectDisposition,
  selectFormChiclets,
} from '../../callcenter/formChiclets/reducers/formChicletsReducer';
import allCallStates from 'config/callStates.config';
import {
  setCallOnWrapUpByCaller,
  setDispositionModalOpen,
  UPDATE_CALL_STATE,
  SET_DISPOSITION_TYPE,
} from '../actions/telephonyActions';
import { selectIsWarmTransferCompleted } from '../../callcenter/warmTransfer/reducers/warmTransferReducer';
import { telephonyEventReasons } from 'services/callCenter/enums';

const setDispositionMethod = (foundDisposition, setDisposition) => {
  return setDisposition(foundDisposition.id, foundDisposition.name).then(
    (response) => response?.data?.updatedList?.listRecordsDeleted,
  );
};

function* setUpdateCallStateSaga(action) {
  try {
    const { callState, activeContact, flags } = action.payload;
    const { formSteps } = yield select(selectFormChiclets);
    const { eventReason } = activeContact;

    if (
      formSteps &&
      (callState === allCallStates.RINGING_ON_OTHER_SIDE.key ||
        callState === allCallStates.RINGING_ON_OUR_SIDE.key ||
        callState === allCallStates.TALKING.key)
    ) {
      if (flags.allowDisabledChiclets) {
        const newFormSteps = formService.updateDisabledState(
          formSteps,
          callState,
          true,
        );
        yield put(updateFormSteps(newFormSteps));
      }
    } else if (
      callState === allCallStates.WRAP_UP.key &&
      eventReason === telephonyEventReasons.DISCONNECTED_BY_CALLER
    ) {
      yield put(setCallOnWrapUpByCaller(true));
    }
  } catch (err) {
    console.error('telephonySaga/setUpdateCallStateSaga error: ', err);
  }
}

function* doLeaveConference({
  deleteCallsFromLists,
  dispositionsModalOpen,
  foundDisposition,
  lastCloseInquiry,
  lastCloseReason,
  leaveConference,
  number,
  removeFromCampaign,
}) {
  leaveConference(foundDisposition.id, foundDisposition.name);

  yield put(saveFormChicletsRequest2(lastCloseInquiry, lastCloseReason));

  if (removeFromCampaign) {
    deleteCallsFromLists([number]);
  }
  if (dispositionsModalOpen) {
    yield put(setDispositionModalOpen(false));
  }
  yield put(setRedirectToWaitingScreen(true));
}

function* doSetDisposition({
  closeReason,
  deleteCallsFromLists,
  dispositionsModalOpen,
  foundDisposition,
  isLastCallAttempt,
  lastCloseInquiry,
  lastCloseReason,
  number,
  removeFromCampaign,
  setDisposition,
}) {
  const listRecordsDeleted = yield setDispositionMethod(
    foundDisposition,
    setDisposition,
  );

  if (listRecordsDeleted) {
    if (listRecordsDeleted >= 1 && closeReason === null && !isLastCallAttempt) {
      lastCloseReason = 'unspecified';
      lastCloseInquiry = 'closed';
    }
    yield put(saveFormChicletsRequest2(lastCloseInquiry, lastCloseReason));
    if (removeFromCampaign) {
      deleteCallsFromLists([number]);
    }
    if (dispositionsModalOpen) {
      yield put(setDispositionModalOpen(false));
    }
  }
  yield put(setRedirectToWaitingScreen(true));
}

function* setDispositionTypeSaga(action) {
  try {
    const {
      activeConference,
      deleteCallsFromLists,
      dispositions,
      isLastCallAttempt,
      leaveConference,
      number,
      setDisposition,
    } = action.payload;
    const { formSteps } = yield select(selectFormChiclets);
    const disposition = yield select(selectDisposition);
    const { closeInquiry, closeReason, removeFromCampaign } = yield select(
      selectCloseReason,
    );
    const callState = yield select(selectCallState);
    const isWarmTransferCompleted = yield select(selectIsWarmTransferCompleted);
    const dispositionsModalOpen = yield select(selectDispositionModalOpen);
    const foundDisposition = dispositions.find(
      (d) => d.name?.trim() === disposition?.trim() && d.id > 0,
    );
    const isInCompleteCallStep = formSteps.some(
      (fs) =>
        (fs.type === 'endCall' || fs.type === 'completeReschedule') &&
        fs.state === 'ANSWERED',
    );

    if (foundDisposition && isInCompleteCallStep) {
      let lastCloseInquiry = closeInquiry ? 'closed' : 'open';
      let lastCloseReason = closeReason;
      if (isWarmTransferCompleted) {
        if (
          activeConference.eventReason ===
          telephonyEventReasons.DISCONNECTED_BY_CALLER
        ) {
          yield doSetDisposition({
            closeReason,
            deleteCallsFromLists,
            dispositionsModalOpen,
            foundDisposition,
            isLastCallAttempt,
            lastCloseInquiry,
            lastCloseReason,
            number,
            removeFromCampaign,
            setDisposition,
          });
        } else {
          yield doLeaveConference({
            deleteCallsFromLists,
            dispositionsModalOpen,
            foundDisposition,
            lastCloseInquiry,
            lastCloseReason,
            leaveConference,
            number,
            removeFromCampaign,
          });
        }
      } else {
        if (callState === allCallStates.WRAP_UP.key) {
          yield doSetDisposition({
            closeReason,
            deleteCallsFromLists,
            dispositionsModalOpen,
            foundDisposition,
            isLastCallAttempt,
            lastCloseInquiry,
            lastCloseReason,
            number,
            removeFromCampaign,
            setDisposition,
          });
        }
      }
    }
  } catch (err) {
    console.error('telephonySaga/setDispositionTypeSaga error: ', err);
  }
}

function* watchTelephony() {
  yield takeLeading(UPDATE_CALL_STATE, setUpdateCallStateSaga);
  yield takeLeading(SET_DISPOSITION_TYPE, setDispositionTypeSaga);
}

export { watchTelephony, setUpdateCallStateSaga, setDispositionTypeSaga };
