import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import Divider from 'common/Divider/Divider';
import Link from 'common/Link/Link';
import Tag from 'common/Tag/Tag';
import Toast from 'common/Toast/Toast';
import CommunitiesTableRow from './CommunitiesTableRow';
import Typography from 'common/Typography/Typography';
import Loading from 'components/Loading/Loading';
import Map from 'components/Map/Map';
import YglLink from 'components/YglExternalId/YglLink';
import { useExternalId } from 'hooks/useExternalId';
import { useGraphQLLazyQuery } from 'hooks/useGraphQLQuery';
import disclosureService from 'services/disclosureService';
import findCommunitiesQuery from '../findCommunitiesQuery';
import NoResults from '../NoResults/NoResults';
import { useStyles } from './CommunitiesTable.style';
import { getZipCodeError, isZipCodeError } from '../CommunitiesService';
import { getSmallContainerTop } from 'services/communityMarkerService';
import {
  getQueryVariables,
  logCommunitySearchCommunitySaved,
  logCommunitySearchCommunityAttributeClicked,
  logCommunitySearchFiltered,
  logCommunitySearchProfileViewed,
  logCommunitySearchResultsViewed,
  logCommunitySearchSavedCommunityRemoved,
  logCommunitySearchViewed,
  logCommunitySearchViewErrorEncountered,
} from './communityService';

const scrollTo = (ref) => {
  if (ref) {
    ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

let isInitialSearch = true;

const CommunitiesTableMD = ({
  labels,
  locationFilter,
  distanceFilter,
  familyFileId,
  familyFileUserId,
  familyFileSalesPhase,
  onSave,
  onRemove,
  onMapChange,
  savedCommunities,
  referredCommunities,
  dataForSegment,
  otherFilters,
  attributeNamesFilter,
  groupNamesFilter,
  onRemoveFilters,
  onRemoveSingleFilter,
  careTypeFilter,
  budgetFilter,
  onAppError,
}) => {
  const classes = useStyles();
  const [communities, setCommunities] = useState([]);

  const [selectedCommunity, setSelectedCommunity] = useState({});
  const [hoveredCommunity, setHoveredCommunity] = useState({});
  const [openCardMapCommunity, setOpenCardMapCommunity] = useState({});
  const [isClickedInsideTheMap, setIsClickedInsideTheMap] = useState(false);
  const [saveCommunityErrorMessage, setSaveCommunityErrorMessage] = useState();
  const communityDivs = [];
  const [showDisclosure, setShowDisclosure] = useState(false);
  const { externalId, fetchError } = useExternalId(familyFileId);
  const [
    smallContainerPositionStyle,
    setSmallContainerPositionStyle,
  ] = useState({});

  const priorLocationFilter = useRef(locationFilter);
  const priorDistanceFilter = useRef(distanceFilter);
  const priorBudgetFilter = useRef(budgetFilter);
  const priorCareTypeFilter = useRef(careTypeFilter);
  const priorOtherFilters = useRef(otherFilters);
  const mapSmallContainer = useRef(null);

  const addSelectedCommunityDivToCommunityDivs = (ref, id) => {
    communityDivs.push({ id, ref });
  };

  const scrollToSelectedCommunity = (community) => {
    setSelectedCommunity(community);
    const selectedCommunityDiv = communityDivs.find(
      (ref) => ref.id === community.id,
    );
    scrollTo(selectedCommunityDiv.ref);
  };

  const [getCommunities, { loading, error, data }] = useGraphQLLazyQuery(
    findCommunitiesQuery,
  );

  const processCommunitiesData = (data) => {
    const communitiesinState = data.findOwlCommunities.filter(
      (communities) => communities.state === locationFilter.state,
    );

    if (communitiesinState.length === 0) {
      setCommunities([]);
      return [];
    } else {
      const allCommunities = data.findOwlCommunities.map((community, key) => {
        const isSaved = !!savedCommunities.find(
          (savedCommunity) => community.id === savedCommunity.id,
        );
        const isReferred = referredCommunities.includes(parseInt(community.id));
        return { ...community, ...{ isSaved }, isReferred, rank: key + 1 };
      });
      setCommunities([...allCommunities]);
      return allCommunities;
    }
  };

  useEffect(() => {
    if (data?.findOwlCommunities) {
      const allCommunities = processCommunitiesData(data);

      const communityIds = allCommunities.map((community) => {
        return community.id;
      });

      if (isInitialSearch) {
        isInitialSearch = false;
        logCommunitySearchViewed(
          familyFileId,
          externalId,
          familyFileUserId,
          allCommunities.length,
          showDisclosure ? 'disclosure' : '',
        );
      }

      if (!isEqual(priorLocationFilter.current, locationFilter)) {
        priorLocationFilter.current = locationFilter;
        createSearchFilteredLog(allCommunities.length);
      }
      if (!isEqual(priorDistanceFilter.current, distanceFilter)) {
        priorDistanceFilter.current = distanceFilter;
        createSearchFilteredLog(allCommunities.length);
      }
      if (!isEqual(priorBudgetFilter.current, budgetFilter)) {
        priorBudgetFilter.current = budgetFilter;
        createSearchFilteredLog(allCommunities.length);
      }
      if (priorCareTypeFilter.current !== careTypeFilter) {
        priorCareTypeFilter.current = careTypeFilter;
        createSearchFilteredLog(allCommunities.length);
      }
      if (!isEqual(priorOtherFilters.current, otherFilters)) {
        priorOtherFilters.current = otherFilters;
        createSearchFilteredLog(allCommunities.length);
      }

      logCommunitySearchResultsViewed(
        familyFileId,
        familyFileUserId,
        communityIds,
        {
          location: locationFilter,
          distance: distanceFilter,
          careTypes: careTypeFilter,
          budget: budgetFilter,
          other: otherFilters,
          healthcareServices: attributeNamesFilter,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data?.findOwlCommunities) {
      processCommunitiesData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedCommunities]);

  useEffect(() => {
    (async () => {
      if (disclosureService.isDisclosureState(locationFilter.state)) {
        const stateCodesWithCompletedDisclosures = await disclosureService.stateCodesWithCompletedDisclosures(
          familyFileId,
        );
        if (
          !stateCodesWithCompletedDisclosures.includes(locationFilter.state)
        ) {
          setShowDisclosure(true);
        } else {
          setShowDisclosure(false);
        }
      } else {
        setShowDisclosure(false);
      }
    })();
  }, [familyFileId, locationFilter]);

  const createSearchFilteredLog = (resultsCount) => {
    logCommunitySearchFiltered(familyFileId, familyFileUserId, resultsCount, {
      location: locationFilter,
      distance: distanceFilter,
      careTypes: careTypeFilter,
      budget: budgetFilter,
      other: otherFilters,
      healthcareServices: attributeNamesFilter,
    });
  };

  const updateSmallContainerPosition = (content) => {
    const top = getSmallContainerTop(content);
    setSmallContainerPositionStyle({ top: `-${top}px` });
  };

  const getCorrectAttributeNames = (attributeNames) => {
    return attributeNames.flatMap((filter) => {
      return filter.isGroup ? filter.attributeNames : filter.value;
    });
  };

  useEffect(() => {
    if (mapSmallContainer.current?.getBoundingClientRect().y) {
      mapSmallContainer.current.style.top = smallContainerPositionStyle.top;
    }
  });

  useEffect(() => {
    const variables = getQueryVariables(
      locationFilter,
      distanceFilter,
      careTypeFilter,
      budgetFilter,
      getCorrectAttributeNames(attributeNamesFilter),
      groupNamesFilter.map((e) => e.value),
    );
    getCommunities({ variables });
    // eslint-disable-next-line
  }, [
    locationFilter,
    distanceFilter,
    budgetFilter,
    careTypeFilter,
    attributeNamesFilter,
    groupNamesFilter,
  ]);

  const isThereFilters = () => {
    return (
      attributeNamesFilter.map((e) => e.value).length > 0 ||
      groupNamesFilter.map((e) => e.value).length > 0
    );
  };

  const onClick = (community) => {
    setOpenCardMapCommunity(community);
    setSelectedCommunity(community);
    updateSmallContainerPosition(community);
  };

  const onHover = (community) => {
    if (openCardMapCommunity.id === community.id) {
      setOpenCardMapCommunity({});
    } else {
      setOpenCardMapCommunity(community);
      setSelectedCommunity(community);
      updateSmallContainerPosition(community);
    }
    setHoveredCommunity(community);
  };

  const getCommunityDisclosureMessage = () => {
    const text = labels.COMMUNITY_DISCLOSURE_MESSAGE_1.replace(
      '{state}',
      disclosureService.getDisclosureStateName(locationFilter.state),
    );
    return (
      <>
        {text}
        <YglLink
          url="YGL_WORKING_LIST_PAGE_URL"
          externalId={externalId}
          hasError={fetchError}
          type="link"
        >
          {labels.COMMUNITY_DISCLOSURE_MESSAGE_2}
        </YglLink>
        {labels.COMMUNITY_DISCLOSURE_MESSAGE_3}
      </>
    );
  };

  const getCommunitySearchRank = (communityId) => {
    const communitySearchRank =
      communities
        .filter((community) => !community.isReferred)
        .findIndex((community) => {
          return community.id === communityId;
        }) + 1;
    return communitySearchRank;
  };

  const onSaveCommunityUpdate = (error, community) => {
    let message = null;

    if (error) {
      console.error(error);
      message = community.isSaved
        ? labels.UNABLE_TO_REMOVE_DUE_TO_ERROR
        : labels.UNABLE_TO_SAVE_DUE_TO_ERROR;
    }

    setSaveCommunityErrorMessage(message);
  };

  const onSaveCommunity = (community) => {
    const communityIds = communities.map((community) => {
      return community.id;
    });

    // Ordinal of where this community is in community list
    const communitySearchRank = getCommunitySearchRank(community.id);

    // Ordinal of community in list of saved communities
    const orderCommunitySaved = savedCommunities.length + 1;

    logCommunitySearchCommunitySaved(
      familyFileId,
      familyFileUserId,
      community.id,
      communitySearchRank,
      orderCommunitySaved,
      communityIds,
      {
        location: locationFilter,
        distance: distanceFilter,
        careTypes: careTypeFilter,
        budget: budgetFilter,
        other: otherFilters,
        healthcareServices: attributeNamesFilter,
      },
    );

    onSave(community);
  };

  const onRemoveCommunity = (community) => {
    logCommunitySearchSavedCommunityRemoved(familyFileId, familyFileUserId, [
      community.id,
    ]);

    onRemove(community);
  };

  const onClickCommunityProfile = (community) => {
    // Ordinal of where this community is in community list
    const communitySearchRank = getCommunitySearchRank(community.id);

    logCommunitySearchProfileViewed(
      familyFileId,
      familyFileUserId,
      community.id,
      communitySearchRank,
    );
  };

  const onClickAttribute = (community, attribute) => {
    // Ordinal of where this community is in community list
    const communitySearchRank = getCommunitySearchRank(community.id);

    logCommunitySearchCommunityAttributeClicked(
      familyFileId,
      familyFileUserId,
      community.id,
      communitySearchRank,
      familyFileSalesPhase,
      attribute,
    );
  };

  useEffect(() => {
    if (error && !isZipCodeError(error)) {
      logCommunitySearchViewErrorEncountered(
        familyFileId,
        familyFileUserId,
        error?.message,
      );
      onAppError(error);
    }
    // eslint-disable-next-line
  }, [error]);

  const loadZipCodeError = () => {
    return getZipCodeError(locationFilter?.zip, classes.error_icon);
  };

  if (loading) return <Loading />;
  if (isZipCodeError(error)) return loadZipCodeError();

  const nonReferredCommunitiesList = communities.filter(
    (community) => !community.isReferred,
  );
  const referredCommunitiesList = communities.filter(
    (community) => community.isReferred,
  );

  const getCommunitiesCount = () => {
    const count = communities ? communities.length : 0;

    return (
      <>
        <Typography>
          {labels.SHOWING} <b>({count})</b> {labels.COMMUNITIES}
        </Typography>
      </>
    );
  };

  return (
    <div className={classes.communities_table_lg_container}>
      <div className={classes.table_container}>
        <div className={classes.count_container}>{getCommunitiesCount()}</div>
        {isThereFilters() && (
          <div className={classes.filters_container}>
            <Typography>
              {labels.RESULTS_FILTERED_BY}
              {':'}
            </Typography>
            <Link
              onClick={() => onRemoveFilters()}
              className={classes.clear_all}
            >
              {labels.CLEAR_ALL}
            </Link>
            {attributeNamesFilter.map((t, k) => (
              <Tag
                onClose={() => onRemoveSingleFilter(t)}
                className={classes.tag}
                color="cosmicCobalt5"
                key={k}
                text={t.label}
              />
            ))}
            {groupNamesFilter.map((t, k) => (
              <Tag
                onClose={() => onRemoveSingleFilter(t)}
                className={classes.tag}
                color="cosmicCobalt5"
                key={k}
                text={t.label}
              />
            ))}
          </div>
        )}
        {showDisclosure && (
          <Toast className={classes.disclosure_message} type="error">
            <Typography>{getCommunityDisclosureMessage()}</Typography>{' '}
          </Toast>
        )}
        {saveCommunityErrorMessage && (
          <Toast className={classes.save_community_error} type="error">
            <Typography>{saveCommunityErrorMessage}</Typography>
          </Toast>
        )}
        {communities.length > 0 && (
          <>
            {nonReferredCommunitiesList.map((community, key) => (
              <div
                key={key}
                ref={(ref) =>
                  addSelectedCommunityDivToCommunityDivs(ref, community.id)
                }
              >
                <CommunitiesTableRow
                  labels={labels}
                  onClick={onClick}
                  onHover={onHover}
                  onSave={(community) => {
                    onSaveCommunity(community);
                  }}
                  onRemove={(community) => {
                    onRemoveCommunity(community);
                  }}
                  onProfileClick={() => {
                    onClickCommunityProfile(community);
                  }}
                  onAttributeClick={onClickAttribute}
                  familyFileId={familyFileId}
                  community={community}
                  isSelected={selectedCommunity.id === community.id}
                  dataForSegment={dataForSegment}
                  locationFilter={locationFilter}
                  onUpdate={onSaveCommunityUpdate}
                  otherFilters={otherFilters}
                  isSavable
                />
              </div>
            ))}
            {referredCommunitiesList.length > 0 && (
              <>
                <Divider spacing={3} />
                <Typography
                  className={classes.referred_communities_title}
                  level="large"
                  bold
                >
                  {labels.ALREADY_REFERRED_COMMUNITES}
                </Typography>
                {referredCommunitiesList.map((community, key) => (
                  <div
                    key={key}
                    ref={(ref) =>
                      addSelectedCommunityDivToCommunityDivs(ref, community.id)
                    }
                  >
                    <CommunitiesTableRow
                      labels={labels}
                      onClick={onClick}
                      onHover={onHover}
                      onSave={(community) => {
                        onSaveCommunity(community);
                      }}
                      onRemove={(community) => {
                        onRemoveCommunity(community);
                      }}
                      onProfileClick={() => {
                        onClickCommunityProfile(community);
                      }}
                      onAttributeClick={onClickAttribute}
                      familyFileId={familyFileId}
                      community={community}
                      isSelected={selectedCommunity.id === community.id}
                      dataForSegment={dataForSegment}
                      locationFilter={locationFilter}
                      otherFilters={otherFilters}
                    />
                  </div>
                ))}
              </>
            )}
          </>
        )}
        {communities.length === 0 && <NoResults labels={labels} />}
      </div>
      <ClickAwayListener onClickAway={() => setIsClickedInsideTheMap(false)}>
        <div
          className={classes.map_container}
          onClick={() => {
            setIsClickedInsideTheMap(true);
          }}
        >
          <Map
            address={locationFilter.address}
            communities={communities}
            labels={labels}
            onCommunitySelected={scrollToSelectedCommunity}
            mapSelectedCommunity={selectedCommunity}
            mapOpenCardCommunity={openCardMapCommunity}
            mapHoveredCommunity={hoveredCommunity}
            onSave={(community) => {
              onSaveCommunity(community);
            }}
            onRemove={(community) => {
              onRemoveCommunity(community);
            }}
            onMapChange={onMapChange}
            familyFileId={familyFileId}
            isClickedInsideTheMap={isClickedInsideTheMap}
            dataForSegment={dataForSegment}
            locationFilter={locationFilter}
            onSaveCommunityUpdate={onSaveCommunityUpdate}
            setHoveredCommunity={setHoveredCommunity}
            isSmallMap={true}
            setSmallContainerPositionStyle={setSmallContainerPositionStyle}
            smallContainerPositionStyle={smallContainerPositionStyle}
          />
        </div>
      </ClickAwayListener>
    </div>
  );
};

CommunitiesTableMD.propTypes = {
  locationFilter: PropTypes.object,
  distanceFilter: PropTypes.string,
  labels: PropTypes.object.isRequired,
  familyFileId: PropTypes.number,
  familyFileUserId: PropTypes.number,
  familyFileSalesPhase: PropTypes.string,
  savedCommunities: PropTypes.array,
  referredCommunities: PropTypes.array,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
  onMapChange: PropTypes.func,
  dataForSegment: PropTypes.shape({
    familyFileId: PropTypes.number,
    oneId: PropTypes.string,
  }),
  otherFilters: PropTypes.array,
  attributeNamesFilter: PropTypes.array,
  groupNamesFilter: PropTypes.array,
  onRemoveFilters: PropTypes.func,
  onRemoveSingleFilter: PropTypes.func,
  careTypeFilter: PropTypes.array,
  budgetFilter: PropTypes.object,
  onAppError: PropTypes.func,
};

CommunitiesTableMD.defaultProps = {
  onAppError: () => {},
  onRemoveFilters: () => {},
  referredCommunities: [],
};

export default withLDConsumer()(CommunitiesTableMD);
