import React from 'react';
import PropTypes from 'prop-types';

const MedicalCross = ({ className }) => {
  return (
    <svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1852 4.33203H9.66671V1.81351C9.66671 0.995313 9.12402 0.332031 8.45459 0.332031H5.5455C4.87606 0.332031 4.33337 0.995313 4.33337 1.81351V4.33203H1.81486C0.996656 4.33203 0.333374 4.87472 0.333374 5.54415V8.45324C0.333374 9.12268 0.996656 9.66537 1.81486 9.66537H4.33337V12.1839C4.33337 13.0021 4.87606 13.6654 5.5455 13.6654H8.45459C9.12402 13.6654 9.66671 13.0021 9.66671 12.1839V9.66537H12.1852C13.0034 9.66537 13.6667 9.12268 13.6667 8.45324V5.54415C13.6667 4.87472 13.0034 4.33203 12.1852 4.33203ZM12.3334 5.66536H8.33337V1.66536H5.66671V5.66536H1.66671V8.33203H5.66671V12.332H8.33337V8.33203H12.3334V5.66536Z"
        fill="#0D1321"
      />
    </svg>
  );
};

MedicalCross.propTypes = {
  className: PropTypes.string,
};

export default MedicalCross;
