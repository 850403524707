import { put, takeLeading } from 'redux-saga/effects';
import graphqlClient from 'stateManagement/graphqlClient';
import {
  getScriptsError,
  getScriptsSuccess,
  getSnapshotsSuccess,
  getSnapshotsError,
  GET_SCRIPTS_REQUEST,
  GET_SNAPSHOTS_REQUEST,
} from '../actions/scriptBuilderActions';
import findAllScriptsQuery from '../graphql/findAllScriptsQuery';
import findSnapshotsByScripIdQuery from '../graphql/findSnapshotsByScripIdQuery';

const getScripts = () => {
  return graphqlClient
    .makeGraphqlRequest({
      query: findAllScriptsQuery,
      operationName: 'findAllScripts',
    })
    .then((response) => response.data.data.findAllScripts);
};

const getSnapshotsByScriptId = (scriptId) => {
  return graphqlClient
    .makeGraphqlRequest({
      query: findSnapshotsByScripIdQuery,
      operationName: 'findSnapshotsByScripId',
      variables: { scriptId },
    })
    .then((response) => response.data.data.findSnapshotsByScripId);
};

function* getScriptsSaga() {
  try {
    const scripts = yield getScripts();

    yield put(getScriptsSuccess(scripts));
  } catch (err) {
    yield put(getScriptsError(err));
  }
}

function* getSnapshotsByScriptIdSaga(action) {
  try {
    const snapshots = yield getSnapshotsByScriptId(action.payload.scriptId);
    yield put(getSnapshotsSuccess(snapshots));
  } catch (err) {
    yield put(getSnapshotsError(err));
  }
}

function* watchScripts() {
  yield takeLeading(GET_SCRIPTS_REQUEST, getScriptsSaga);
  yield takeLeading(GET_SNAPSHOTS_REQUEST, getSnapshotsByScriptIdSaga);
}

export { watchScripts, getScriptsSaga, getSnapshotsByScriptIdSaga };
