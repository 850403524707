import React from 'react';

import { useStyles } from './HomeTable.style';
import Typography from '../../common/Typography/Typography';
import PropTypes from 'prop-types';

const EmptyTable = ({ children, labels }) => {
  const classes = useStyles();

  return (
    <div className={classes.empty_table_container}>
      <div className={classes.no_content}>
        {children}
        <Typography className={classes.empty_header} level="large">
          {labels.EMPTY_HEADER}
        </Typography>
        <div className={classes.empty_message}>
          <Typography>{labels.EMPTY_DESCRIPTION_LINE1}</Typography>
          <Typography>{labels.EMPTY_DESCRIPTION_LINE2}</Typography>
        </div>
      </div>
    </div>
  );
};

EmptyTable.propTypes = {
  children: PropTypes.element,
  labels: PropTypes.shape({
    EMPTY_HEADER: PropTypes.string.isRequired,
    EMPTY_DESCRIPTION_LINE1: PropTypes.string.isRequired,
    EMPTY_DESCRIPTION_LINE2: PropTypes.string.isRequired,
  }),
};

export default EmptyTable;
