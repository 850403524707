import React from 'react';
import PropTypes from 'prop-types';

const ArrowLeft = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M6.41436 13L12.7073 19.2929L11.293 20.7071L2.58594 12L11.293 3.29289L12.7073 4.70711L6.41436 11H21.0002V13H6.41436Z"
      />
    </svg>
  );
};

ArrowLeft.propTypes = {
  className: PropTypes.string,
};

export default ArrowLeft;
