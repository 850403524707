import React from 'react';
import PropTypes from 'prop-types';

const CallNo = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="currentColor"
        d="M1.29297 21.2929L2.70718 22.7071L7.53827 17.876C11.9732 21.9023 17.4403 23.2994 20.1042 20.6339C20.569 20.1859 20.9626 19.7921 21.2729 19.4641C22.168 18.5181 22.3039 16.8598 21.2671 15.7825C20.7355 15.2302 19.6505 14.3885 17.7331 13.017C17.1458 12.4919 16.4495 12.6057 15.8112 13.0245C15.5041 13.226 15.2806 13.4298 14.8587 13.8521L14.0924 14.6188C14.0104 14.7009 13.0896 14.2628 12.0334 13.3809L22.7072 2.70712L21.293 1.29291L1.29297 21.2929ZM10.6099 14.8044L8.95409 16.4602C12.6779 19.8027 16.9984 20.913 18.7039 19.2069C19.1614 18.7655 19.5343 18.3925 19.8213 18.0891C20.0287 17.8699 20.0658 17.4177 19.8272 17.1698C19.4299 16.7569 18.4571 15.9985 16.7774 14.7923C16.655 14.8908 16.5045 15.0338 16.2723 15.2662L15.5059 16.033C14.3536 17.186 12.4847 16.4498 10.6099 14.8044ZM9.37449 9.8987C9.33845 9.93473 9.4036 10.1342 9.5634 10.4367L8.09267 11.9074C7.28719 10.5666 7.1137 9.33178 7.9615 8.48404L8.72752 7.7176C8.8501 7.5949 8.90617 7.53806 8.97347 7.46733C9.06517 7.37096 9.14052 7.28632 9.20085 7.21142C8.03548 5.58808 7.24328 4.57372 6.81622 4.15185C6.59566 3.93398 6.1018 3.97409 5.92049 4.15274C5.43694 4.6292 5.278 4.78806 4.77634 5.29757C3.29599 6.77873 3.95997 10.2693 6.38451 13.6156L4.95415 15.0459C1.84985 10.9125 0.974781 6.2728 3.35761 3.8887C3.8613 3.37708 4.02737 3.2111 4.51793 2.72773C5.43126 1.8278 7.16603 1.68689 8.22058 2.72862C8.77529 3.27658 9.65963 4.41442 10.9746 6.2544C11.5605 6.90393 11.3748 7.63978 10.8567 8.33799C10.7338 8.50352 10.5922 8.66663 10.4212 8.84636C10.3391 8.93262 10.2751 8.9975 10.1413 9.13151L9.37449 9.8987Z"
      />
    </svg>
  );
};

CallNo.propTypes = {
  className: PropTypes.string,
};

export default CallNo;
