class ContactsFormModel {
  /**
   * Constructs an instance of ContactsFormModel.
   *
   * @param {Object} data - Object matching the structure of a ContactsFormModel.
   */
  constructor(data) {
    this.name = data.name;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.relationToResidentId = data.relationToResidentId;
    this.address1 = data.address1;
    this.address2 = data.address2;
    this.allowContact = data.allowContact;
    this.isInquirer = data.isInquirer;
    this.isPrimary = data.isPrimary;
    this.city = data.city;
    this.postalCode = data.postalCode;
    this.state = data.state;
    this.country = data.country;
    this.contactDetail = data.contactDetail || [];
    this.emailAddresses = data.emailAddresses || [];
    this.phones = data.phones || [];
  }
}

export default ContactsFormModel;
