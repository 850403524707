import gql from 'graphql-tag';

export default gql`
  mutation(
    $familyFileId: Int!
    $communityId: Int!
    $emailServiceStatus: String!
    $emailServiceRejectReason: String
  ) {
    addFamilyCommunityLetter(
      input: {
        familyFileId: $familyFileId
        communityId: $communityId
        emailServiceStatus: $emailServiceStatus
        emailServiceRejectReason: $emailServiceRejectReason
      }
    ) {
      familyFileId
      sentAt
    }
  }
`;
