import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './TopBar.style';
import { withAuthenticationContext } from '../../context/Authentication';
import Typography from '../../common/Typography/Typography';
import ChevronBottom from '../../common/Icons/arrow/ChevronBottom';
import ChevronTop from '../../common/Icons/arrow/ChevronTop';
import { withLabels } from '../../context/LabelContext';
import ArrowLeft from '../../common/Icons/arrow/ArrowLeft';
import { Link, useLocation } from 'react-router-dom';
import { withUser } from '../../context/UserContext';
import { getHomePage } from '../../config/roles';
import Menu from '../../common/Icons/basic/Menu';
import NavigationSidePanel from '../NavigationSidePanel/NavigationSidePanel';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import SquareStairs from '../../common/Icons/basic/SquareStairs';
import { registerEvent } from '../../services/Analytics';
import DropdownWithSearchInputXS from '../DropdownWithSearchInput/DropdownWithSearchInputXS';
import Search from '../../common/Icons/basic/Search';
import clsx from 'clsx';
import { getDropdownItems } from './topBarDropdownItems';
import getParams from '../../utils/queryString';
import Version from '../../components/Version';

const TopBarXS = ({ auth, labels, currentUser, flags, onSearch }) => {
  const location = useLocation();
  const classes = useStyles();
  const wrapperRef = useRef(null);
  const [isUserOpen, setIsUserOpen] = useState(false);
  const [isHome, setIsHome] = useState(false);
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { bucket, tab } = getParams();
  const tabCheck = tab !== undefined && tab >= 0;

  const dropDownItems = getDropdownItems(labels);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsUserOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    const path = location.pathname;
    const isHomeUrl = path === getHomePage(currentUser.role);
    setIsHome(isHomeUrl);
  }, [location, currentUser]);

  const handleHamburgerClick = () => {
    setIsNavigationOpen(true);
    registerEvent('Navigation', 'Navigation side panel is opened');
  };

  const handleSearchClick = () => {
    setIsSearchOpen(!isSearchOpen);
    registerEvent('Navigation', 'Search panel in toolbar is opened');
  };

  const searchStyle = clsx(
    classes.search,
    isSearchOpen && classes.search_opened,
  );
  const leftNavigation = clsx(
    classes.left_navigation,
    !isHome && classes.left_navigation_with_back,
  );

  return (
    <div className={classes.main_container_mobile}>
      <div className={classes.top_bar_container_mobile}>
        {!flags.onlyShowCommunitiesTabFf && (
          <>
            <div className={leftNavigation}>
              {flags.sidePanelNavigation && (
                <>
                  <div
                    className={classes.hamburger}
                    onClick={handleHamburgerClick}
                  >
                    <Menu />
                  </div>
                  {isNavigationOpen && (
                    <NavigationSidePanel
                      labels={labels}
                      backgroundColor="cosmicCobalt1"
                      width="240"
                      xColor="platinum5"
                      isOpen={isNavigationOpen}
                      onClose={() => setIsNavigationOpen(false)}
                    />
                  )}
                </>
              )}
              {!flags.sidePanelNavigation && isHome && <SquareStairs />}
              {!isHome && (
                <Link
                  to={
                    bucket && tabCheck
                      ? `/family-file-list?bucket=${bucket}`
                      : `/home`
                  }
                >
                  <ArrowLeft />
                  <Typography bold color="platinum5">
                    {labels.BACK}
                  </Typography>
                </Link>
              )}
            </div>
            <div className={classes.search_container}>
              <div className={searchStyle} onClick={handleSearchClick}>
                <Search />
              </div>
            </div>
          </>
        )}
        <div
          className={classes.user}
          data-testid="user-menu"
          onClick={() => setIsUserOpen(!isUserOpen)}
          ref={wrapperRef}
        >
          <img src={auth.user.picture} alt="user" />
          {isUserOpen ? <ChevronTop /> : <ChevronBottom />}
          {isUserOpen && (
            <div className={classes.user_menu}>
              <div
                data-testid="logout-button"
                className={classes.user_menu_item}
                onClick={() => auth.logout()}
              >
                <Typography color="platinum5">{labels.LOGOUT}</Typography>
              </div>
              <Version
                class={clsx(classes.user_menu_item, classes.version_info)}
              />
            </div>
          )}
        </div>
      </div>
      {isSearchOpen && (
        <div className={classes.dropdown_container_mobile}>
          <DropdownWithSearchInputXS
            items={dropDownItems}
            onEnterPressed={onSearch}
          ></DropdownWithSearchInputXS>
        </div>
      )}
    </div>
  );
};

TopBarXS.propTypes = {
  labels: PropTypes.object.isRequired,
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.string,
    __typename: PropTypes.string,
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      given_name: PropTypes.string,
      family_name: PropTypes.string,
      picture: PropTypes.string.isRequired,
      name: PropTypes.string,
    }).isRequired,
    logout: PropTypes.func.isRequired,
  }).isRequired,
  flags: PropTypes.shape({
    sidePanelNavigation: PropTypes.bool,
    onlyShowCommunitiesTabFf: PropTypes.bool,
  }),
  onSearch: PropTypes.func.isRequired,
};

export default withLDConsumer()(
  withUser(withLabels(withAuthenticationContext(TopBarXS))),
);
export { TopBarXS as TopBar };
