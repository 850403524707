import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from '../Marker.style';
import clsx from 'clsx';
import CommunitiesTableMapPinPoint from 'pages/Communities/CommunitiesTable/CommunitiesTableMapPinPoint';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import PinAdded from 'common/Icons/maps/PinAdded';
import SelectedHighlighted from 'common/Icons/maps/SelectedHighlighted';

const DefaultMarker = ({
  community,
  labels,
  onSave,
  onRemove,
  onSaveCommunityUpdate,
  familyFileId,
  isClickedInsideTheMap,
  isCommunitySelected,
  isPinPointClicked,
  dataForSegment,
  locationFilter,
  setIsPinPointClicked,
  changeState,
  isHovered,
  $hover,
  isSmallMap,
  mapSmallContainer,
  updateSmallContainerPositionOnHover,
}) => {
  const classes = useStyles($hover);
  const [isMapPinPointHovered, setIsMapPinPointHovered] = useState(false);

  useEffect(() => {
    if (isPinPointClicked) {
      setIsMapPinPointHovered(false);
    }
  }, [isPinPointClicked]);

  return (
    <>
      <CommunitiesTableMapPinPoint
        community={community}
        labels={labels}
        onSave={onSave}
        onRemove={onRemove}
        familyFileId={familyFileId}
        isClickedInsideTheMap={isClickedInsideTheMap}
        isCommunitySelected={isCommunitySelected}
        isPinPointClicked={isPinPointClicked}
        dataForSegment={dataForSegment}
        locationFilter={locationFilter}
        onSaveCommunityUpdate={onSaveCommunityUpdate}
        isSmallMap={isSmallMap}
        mapSmallContainer={mapSmallContainer}
        isMapPinPointHovered={isMapPinPointHovered}
        isHovered={isHovered}
      />
      <ClickAwayListener onClickAway={() => setIsPinPointClicked(false)}>
        <button
          className={classes.pinpoint_button}
          onClick={() => changeState(community)}
        >
          {isCommunitySelected || isHovered ? (
            <SelectedHighlighted
              className={clsx(classes.default_highlight, classes.common)}
            />
          ) : (
            <PinAdded
              className={clsx(classes.default, classes.common)}
              mouseEnter={() => {
                updateSmallContainerPositionOnHover(community);
                setIsMapPinPointHovered(true);
              }}
              mouseLeave={() => setIsMapPinPointHovered(false)}
            />
          )}
        </button>
      </ClickAwayListener>
      <div className={classes.shadow} />
    </>
  );
};

DefaultMarker.propTypes = {
  labels: PropTypes.object,
  $hover: PropTypes.bool,
  community: PropTypes.object,
  onCommunitySelected: PropTypes.func,
  onSaveCommunityUpdate: PropTypes.func,
  isSelected: PropTypes.bool,
  isPinPointClicked: PropTypes.bool,
  isPinpointCardOpen: PropTypes.bool,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
  setIsPinPointClicked: PropTypes.func,
  changeState: PropTypes.func,
  isHovered: PropTypes.bool,
  isCommunitySelected: PropTypes.bool,
  familyFileId: PropTypes.number,
  isClickedInsideTheMap: PropTypes.bool,
  locationFilter: PropTypes.object,
  dataForSegment: PropTypes.shape({
    familyFileId: PropTypes.number,
    oneId: PropTypes.string,
  }),
  isSmallMap: PropTypes.bool,
  mapSmallContainer: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  updateSmallContainerPositionOnHover: PropTypes.func,
};

export default DefaultMarker;
