const findAllScriptsQuery = `
  query findAllScripts {
    findAllScripts {
      scriptId
      slug
      name
      description
      target
      type
      version
      versionName
      status
      updatedBy {
        firstName
        lastName
        userId
      }
      createdBy {
        firstName
        lastName
        userId
      }
      updatedAt
      createdAt
      steps {
        stepId
        number
        order
        name
        size
        text
        type
        isHidden
        isLegal
        isOptional
        isPipeda
        alternateText
        property
        updatedBy {
          firstName
          lastName
          userId
        }
        createdBy {
          firstName
          lastName
          userId
        }
        updatedAt
        createdAt
        answers {
          answerId
          order
          label
          value
          nextStep
          closeInquiry
          closeReason
          disposition
          addToDoNotCall
          shows
          hides
          selectedRule
          action
          urlLink
          poolType
          updatedBy {
            firstName
            lastName
            userId
          }
          createdBy {
            firstName
            lastName
            userId
          }
          updatedAt
          createdAt
        }
      }
    }
  }
`;

export default findAllScriptsQuery;
