import { getReadableDateWithTime } from '../../utils/dateFormat';
import { cloneDeep } from 'lodash';
import { formatPhoneWithParenthesis } from '../../utils/phoneFormat';
import { format } from 'date-fns';

const NOT_CONFIRMED = 'Not confirmed';
const INPUT_DATE_FORMAT = 'EEE, MMM d @ h:mm a';

const buildDetail = (order, title, content) => {
  const detail = {
    order,
    title,
    content,
  };
  if (!content) {
    detail.content = NOT_CONFIRMED;
  }
  return detail;
};

const buildInquiryDetails = (inquiry, labels) => {
  const details = [];
  if (inquiry) {
    let { inquiryFormData: data } = inquiry;
    if (data === null) {
      data = {};
    }
    details.push(buildDetail(1, labels.INTRODUCTION, data.contactConfirmation));
    details.push(buildDetail(2, labels.DISCLAIMER, data.disclaimer));

    details.push(
      buildDetail(
        3,
        labels.CONTACT_INFO,
        data.firstName && data.lastName
          ? `${data.firstName} ${data.lastName}`
          : undefined,
      ),
    );
    details.push(buildDetail(4, labels.CONFIRM_INTEREST, data.confirmInterest));
    details.push(
      buildDetail(
        5,
        labels.FAMILY_INFO,
        data.resident1FirstName && data.resident1LastName
          ? `${data.resident1FirstName} ${data.resident1LastName}`
          : undefined,
      ),
    );
    details.push(buildDetail(6, labels.MEDICAL_INFO, data.relationToResident));
    details.push(buildDetail(7, labels.LOCATION, data.desiredPostalCode));
    details.push(buildDetail(8, labels.TRANSFER_TO_SLA, data.transferSla));
    details.push(buildDetail(9, labels.SLA_MATCH, data.slaMatchName));
    details.push(
      buildDetail(
        10,
        labels.SLA_INTRODUCTION,
        data.completeWarmTransfer,
        labels,
      ),
    );
    details.push(buildDetail(11, labels.CALL_COMPLETED, data.isCompleteCall));
  }
  return details;
};

const buildCallAttemptSearchDetails = (callAttempt) => {
  const callRecord = callAttempt[0];
  const result = {
    callId: '',
    lastContactedDate: '',
    dispositionText: 'No disposition set',
    attemptNumber: '',
  };

  if (callRecord?.callId) {
    result.callId = callRecord.callId.toString();
  }

  if (callRecord?.updatedAt) {
    result.lastContactedDate = format(callRecord.updatedAt, INPUT_DATE_FORMAT);
  }

  if (callRecord?.dispositionName) {
    result.dispositionText = callRecord.dispositionName.trim().toUpperCase();
  }

  if (callRecord?.attemptNumber) {
    result.attemptNumber = callRecord.attemptNumber;
  }

  return result;
};

const buildCallAttemptsDetails = (callAttempts, labels) => {
  const attempts = cloneDeep(callAttempts);
  let results = [];
  if (attempts?.length > 0) {
    results = attempts.map((ca, index) => {
      const agentName = buildDetail(
        index + 1,
        labels.AGENT_NAME,
        ca.agentDetails?.agentName,
      );
      const phoneNumber = buildDetail(
        index + 2,
        labels.PHONE_NUMBER,
        formatPhoneWithParenthesis(ca.phoneNumber),
      );
      const disposition = buildDetail(
        index + 3,
        labels.DIAL_OUTCOME,
        ca.dispositionName,
      );
      return {
        title: `Call ID ${ca.callId} - Attempt ${ca.attemptNumber}/${ca.maxAttempts}`,
        date: getReadableDateWithTime(ca?.updatedAt),
        details: [agentName, phoneNumber, disposition],
      };
    });
  }
  return results;
};

const getSlugId = (inquiry) => {
  let slugId = null;
  if (inquiry) {
    const { inquiryFormData: data } = inquiry;
    slugId = data?.slugId;
  }
  return slugId;
};

const getInquiryDetailsTitleBar = (scriptPreview, labels) => {
  let title = labels.SRC_HAS_NOT_STARTED;
  let date = '';
  if (scriptPreview) {
    const { name, version, updatedAt } = scriptPreview;
    date = getReadableDateWithTime(updatedAt);
    title = `${name}, Version ${version}`;
  }
  return { title, date };
};

const getInquiryLabelMenu = (inquiry) => {
  let label = '';
  if (inquiry) {
    label = `${inquiry.inquiryContact.firstName} ${inquiry.inquiryContact.lastName} ${inquiry.inquiryId}`;
  }
  return label;
};

const inquirySearchService = {
  buildInquiryDetails,
  buildCallAttemptsDetails,
  buildCallAttemptSearchDetails,
  getSlugId,
  getInquiryDetailsTitleBar,
  getInquiryLabelMenu,
};

export default inquirySearchService;
