import { zonedTimeToUtc, utcToZonedTime, format } from 'date-fns-tz';
import { isBefore, parseISO } from 'date-fns';

//const formats = ['MMM dd, yyyy (EEE) @ h:mm aaaa'];

/** Date Functions */

/**
 * The date parameter can be a datetime without a timezone. Ex. '2021-01-07 08:00:00'.
 * The timezone parameter is the TZ value. Ex. 'America/Los_Angeles'.
 * The zonedTimeToUtc method creates a zoned datetime and then outputs the UTC.
 * Returns the UTC date as a string value in ISO format.
 * @param {*} date
 * @param {*} timeZone
 */
const getUTCFromZonedDateTime = (date, timeZone) => {
  const utcDate = zonedTimeToUtc(date, timeZone);
  return utcDate.toISOString();
};

/**
 * The utcDate parameter can be a string or Date in UTC.
 * The timezone parameter is the TZ value. Ex. 'America/Los_Angeles'.
 * The dateFormat follows the date-fns-tz format specification.
 * @param {*} utcDate
 * @param {*} timeZone
 * @param {*} dateFormat
 */
const getZonedDateTimeFromUTC = (utcDate, timeZone, dateFormat) => {
  const zonedTime = utcToZonedTime(utcDate, timeZone);

  return format(zonedTime, dateFormat, {
    timeZone: timeZone,
  });
};

/**
 * Returns zoned date object
 * @param {*} utcDate
 * @param {*} timeZone
 */
const getZonedDateTimeObjectFromUTC = (utcDate, timeZone) => {
  return utcToZonedTime(utcDate, timeZone);
};

/**
 * This method accepts the selected date and selected time values from
 * the scheduled tour modal to create a a zoned datetime and return the UTC datetime.
 * @param {*} selectedDate
 * @param {*} selectedTime
 */
const getUTCDateTimeFromScheduledTourModal = (
  selectedDate,
  selectedTime,
  timeZone,
) => {
  //selected date and time from modal
  const dateTime = getBasicDateTime(selectedDate, selectedTime);

  //convert dateTime to utc
  const utcDateTime = getUTCFromZonedDateTime(dateTime, timeZone);

  return utcDateTime;
};

const getBasicDateTime = (selectedDate, selectedTime) => {
  const rawSelectedDate = new Date(selectedDate);

  const year = rawSelectedDate.getFullYear();
  const month = rawSelectedDate.getMonth() + 1;
  const day = rawSelectedDate.getDate();

  const monthWithLeadingZero = `${month}`.padStart(2, '0');
  const dayWithLeadingZero = `${day}`.padStart(2, '0');

  //selected date and time from modal
  const dateTime = `${year}-${monthWithLeadingZero}-${dayWithLeadingZero} ${selectedTime}`;

  return dateTime;
};

const getUTCDateObjectFromZonedDateTime = (date, timeZone) => {
  return zonedTimeToUtc(date, timeZone);
};

const getParseISODateObject = (dateString) => {
  return parseISO(dateString);
};

const isDateTimeBefore = (date, dateToCompare) => {
  return isBefore(date, dateToCompare);
};

const formatDate = (utcDate, timeZone, format) => {
  const zonedTime = getZonedDateTimeFromUTC(utcDate, timeZone, format);

  return zonedTime;
};

const getIANATimeZone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (err) {
    return null;
  }
};

/** End Date Functions */

export default {
  getUTCFromZonedDateTime,
  getZonedDateTimeFromUTC,
  getZonedDateTimeObjectFromUTC,
  getUTCDateTimeFromScheduledTourModal,
  getUTCDateObjectFromZonedDateTime,
  getParseISODateObject,
  getBasicDateTime,
  isDateTimeBefore,
  formatDate,
  getIANATimeZone,
};
