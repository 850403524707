import React from 'react';
import PropTypes from 'prop-types';

const Minus = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="currentColor" d="M22 11V13H2V11H22Z" />
    </svg>
  );
};

Minus.propTypes = {
  className: PropTypes.string,
};

export default Minus;
