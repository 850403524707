export const careTypeDisplayLookup = {
  adultDayServices: 'Adult Day Services (D)',
  assistedLiving: 'Assisted Living (A)',
  homeCare: 'Home Care (H)',
  independentLiving: 'Retirement Community (R)', // Independent Living is the new term for Retirement Community
  memoryCare: 'Memory Care (Z)',
  notSure: 'Not Sure (O)',
  nursingHome: 'Nursing Home (N)',
  residentialCareHome: 'Residential Care Home (G)',
  respiteCare: 'Respite Care (M)',
  seniorApartments: 'Senior Appartments (P)',
};

const toDisplayFormatArray = (careTypes) => {
  const displayCareTypes = Object.entries(careTypes)
    .filter(([, enabled]) => enabled === true)
    .map(([careType]) => careTypeDisplayLookup[careType]);

  return displayCareTypes;
};

export default {
  toDisplayFormatArray,
};
