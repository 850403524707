import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  conference_call_button: {
    padding: '9px 18px',
    color: theme.palette.light.platinum5,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    borderRadius: '0 30px 30px 0',
    '& svg': {
      marginRight: '10px',
      fontSize: '16px',
    },
    '&.smokyTopaz': {
      backgroundColor: theme.palette.correction.smokyTopaz2,
      '&:not(.is_disabled):hover': {
        backgroundColor: theme.palette.correction.smokyTopaz1,
      },
    },
    '&.cosmicCobalt': {
      backgroundColor: theme.palette.neutral.cosmicCobalt3,
      '&:not(.is_disabled):hover': {
        backgroundColor: theme.palette.neutral.cosmicCobalt2,
      },
    },
    '&.is_disabled': {
      backgroundColor: theme.palette.dark.disabled,
      color: theme.palette.light.platinum5,
      cursor: 'default',
    },
  },
}));

export { useStyles };
