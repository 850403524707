import React, { Fragment } from 'react';
import {
  getTodayOrYesterdayOrPastDate,
  getHourAndMinutes12HourFormat,
} from '../../utils/dateFormat';

const getPrimaryTourFormattedDate = (item) => {
  if (item.mainTour) {
    const date = getTodayOrYesterdayOrPastDate(item.mainTour.scheduledTourDate);
    const time = getHourAndMinutes12HourFormat(item.mainTour.scheduledTourDate);
    return date + ', ' + time;
  }
};

const getNextToursInformation = (item) => {
  let toolTipTexts = [];
  if (item.nextTours) {
    item.nextTours.forEach((tour) => {
      toolTipTexts.push(`
          ${tour.communityName} - ${getHourAndMinutes12HourFormat(
        tour.scheduledTourDate,
      )}`);
    });
  }
  return (
    <Fragment>
      {toolTipTexts.map((tooltipText, index) => (
        <div key={index}> {tooltipText} </div>
      ))}
    </Fragment>
  );
};

export default { getPrimaryTourFormattedDate, getNextToursInformation };
