import React from 'react';
import PropTypes from 'prop-types';

const Cottage = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0011 18.8856C20.5414 18.4723 19.9441 18.2129 19.2526 18.1204V12.1326L19.8021 12.5623L21.9675 9.79265L19.2526 7.67012V2.66492H15.5807V4.79941L12 2L2.0325 9.79262L4.19781 12.5623L4.74742 12.1326V17.216C3.1027 17.6357 2 18.948 2 20.4925V22.0009H22V21.4149C22 20.3476 21.6546 19.473 21.0011 18.8856ZM3.17188 20.829V20.4925C3.17188 19.9893 3.3532 19.5206 3.69617 19.1371C4.03332 18.7601 4.51852 18.4786 5.06238 18.3446C6.79031 17.9189 9.4248 18.3186 11.5416 18.6398C12.0132 18.7113 12.4585 18.7789 12.8598 18.8318C14.9824 19.1116 16.9362 19.2534 18.6667 19.2534C20.1376 19.2534 20.6298 20.0818 20.7745 20.8289H3.17188V20.829ZM12 3.4875L20.3225 9.99406L19.6008 10.9173L12 4.975L4.39926 10.9173L3.6775 9.9941L12 3.4875ZM12 6.4625L18.0807 11.2164V13.9149H14.6693V11.8316H9.33074V13.9149H5.9193V11.2164L12 6.4625ZM13.4974 17.7315C13.3368 17.7117 12.1831 17.5518 11.7174 17.4812C11.3269 17.4219 10.9194 17.3602 10.5026 17.3012V13.0035H13.4974V17.7315ZM14.6693 17.863V15.0868H18.0807V18.0759C17.0209 18.0565 15.8796 17.9852 14.6693 17.863ZM5.91926 15.0868H9.3307V17.1493C8.1634 17.015 6.97664 16.9385 5.91926 17.028V15.0868ZM16.7526 3.8368H18.0807V6.75391L16.7526 5.71559V3.8368Z"
        fill="currentColor"
      />
      <path
        d="M11.4141 8.92798H12.5859V10.0998H11.4141V8.92798Z"
        fill="currentColor"
      />
    </svg>
  );
};

Cottage.propTypes = {
  className: PropTypes.string,
};

export default Cottage;
