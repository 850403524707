import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  top_bar_container: {
    display: 'flex',
    backgroundColor: theme.palette.neutral.cosmicCobalt1,
    height: '72px',
    boxShadow: '0px 0px 8px rgba(13, 19, 33, 0.5)',
    zIndex: '1',
  },
  top_bar_container_mobile: {
    display: 'flex',
    height: '72px',
  },
  main_container_mobile: {
    backgroundColor: theme.palette.neutral.cosmicCobalt1,
    height: 'auto',
    zIndex: '1',
    boxShadow: '0px 0px 8px rgba(13, 19, 33, 0.5)',
  },
  left_navigation: {
    display: 'flex',
    width: '20%',
    '& a': {
      display: 'flex',
      alignItems: 'center',
      padding: '26px',
      textDecoration: 'none',
      '& svg': {
        color: theme.palette.light.platinum5,
        marginRight: '19px',
        fontSize: '24px',
      },
    },
  },
  left_navigation_with_back: {
    width: '30%',
  },
  search_container: {
    width: '50%',
    borderLeft: `1px solid ${theme.palette.neutral.cosmicCobalt2}`,
    display: 'flex',
  },
  hamburger: {
    display: 'flex',
    '& :hover': {
      cursor: 'pointer',
    },
    '& svg': {
      alignSelf: 'center',
      marginLeft: '24px',
      fontSize: '24px',
      color: theme.palette.light.platinum5,
    },
  },
  search: {
    justifyContent: 'center',
    display: 'flex',
    '& :hover': {
      cursor: 'pointer',
    },
    '& svg': {
      alignSelf: 'center',
      fontSize: '24px',
      color: theme.palette.light.platinum5,
      width: '72px',
    },
  },
  search_opened: {
    backgroundColor: theme.palette.neutral.cosmicCobalt2,
  },
  user: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '16px 24px',
    cursor: 'pointer',
    borderLeft: `1px solid ${theme.palette.neutral.cosmicCobalt2}`,
    '& img': {
      marginRight: '16px',
      borderRadius: '50%',
      height: '40px',
      width: '40px',
    },
    '& svg': {
      color: theme.palette.light.platinum5,
      marginLeft: '12px',
      fontSize: '16px',
    },
  },
  user_menu: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '72px',
    left: 0,
    right: 0,
    backgroundColor: theme.palette.neutral.cosmicCobalt1,
    zIndex: '1300',
  },
  user_menu_item: {
    cursor: 'pointer',
    padding: '16px 24px',
    marginTop: '16px',
    '&:hover': {
      backgroundColor: theme.palette.neutral.cosmicCobalt3,
    },
  },
  dropdown_container: {
    display: 'flex',
    width: '50%',
  },
  dropdown_container_mobile: {
    width: '100%',
    backgroundColor: theme.palette.neutral.cosmicCobalt2,
  },
  version_info: {
    '& span': {
      color: theme.palette.neutral.cosmicCobalt4,
      fontSize: '11px',
    },
    textAlign: 'center',
  },
}));

export { useStyles };
