import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    top_panel: {
      fontWeight: 'bold',
      fontSize: '13px',
      lineHeight: '19px',
      borderBottom: `1px solid ${theme.palette.light.platinum1}`,
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '4px 4px 0 0',
      cursor: 'pointer',
      justifyContent: 'space-between',
      '& div': {
        paddingLeft: '8px',
      },
      '& img': {
        marginRight: '16px',
        borderRadius: '50%',
        height: '40px',
        width: '40px',
      },
      '& svg': {
        color: theme.palette.light.platinum5,
        marginLeft: '12px',
        fontSize: '20px',
      },
    },
    top_panel_right: {
      display: 'flex',
      alignItems: 'right',
    },
    main_panel_details: {
      backgroundColor: theme.palette.light.platinum5,
      paddingTop: '15px',
      paddingBottom: '15px',
    },
  };
});

export { useStyles };
