import FamilyFileFormModel from '../FamilyFileFormModel';

/**
 * Class defining the general structure of the values stored within the Medical Background form.
 */
class MedicalBackgroundFormModel extends FamilyFileFormModel {
  /**
   * Constructs an instance of MedicalBackgroundFormModel.
   *
   * @param {Object} data - Object matching the structure of a MedicalBackgroundFormModel.
   */
  constructor(data) {
    super();

    // Various identifiers
    this.formDataId = data.formDataId;
    this.oneId = data.oneId;
    this.familyFileId = data.familyFileId;

    this.rehabSkilledNursingRequired = data.rehabSkilledNursingRequired;

    // String indicating whether the potential resident currently suffers from memory loss -- values can be 'Yes' or 'No'
    this.memoryLoss = data.memoryLoss;
    this.memoryDiagnosis = data.memoryDiagnosis;
    this.currentSituation = data.currentSituation;
    this.locationName = data.locationName;
    this.currentMedication = data.currentMedication;

    // String that is auto-calculated based on the potential resident wandering or combative issues
    this.otherIssues = data.otherIssues;
    this.moreHelp = data.moreHelp;

    // Boolean indicating the potential resident requires help showering
    this.helpInShower = data.helpInShower;

    // Boolean indicating whether the potential resident requires help toileting
    this.helpInToileting = data.helpInToileting;
    this.diabeticCare = data.diabeticCare;
    this.gettingAroundDescription = data.gettingAroundDescription;
    this.healthIssues = data.healthIssues;
    this.anythingElse = data.anythingElse;
  }
}

export default MedicalBackgroundFormModel;
