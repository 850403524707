import React from 'react';
import PetFriendly from 'common/Icons/lineIcons/amenities/PetFriendly';
import Smoking from 'common/Icons/lineIcons/amenities/Smoking';
import SwimmingPool from 'common/Icons/lineIcons/amenities/SwimmingPool';
import labels from 'config/labels';

const communityAmenities = [
  {
    label: labels.PET_FRIENDLY,
    value: 'Accepts Pets',
    isGroup: true,
    attributeNames: ['Cats', 'Dogs'],
    icon: <PetFriendly />,
    selected: false,
    is: labels.COMMUNITY_AMENITIES,
  },
  {
    label: labels.SMOKING,
    value: 'Smoking',
    isGroup: true,
    attributeNames: [
      'Allowed in Private Areas Indoors',
      'Allowed in Public Areas Indoors',
      'Allowed Outdoors',
    ],
    icon: <Smoking />,
    selected: false,
    is: labels.COMMUNITY_AMENITIES,
  },
  {
    label: labels.SWIMMING_POOL,
    value: 'Swimming Pool / Hot Tub',
    icon: <SwimmingPool />,
    selected: false,
    is: labels.COMMUNITY_AMENITIES,
  },
];

export default communityAmenities;
