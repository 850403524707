const createInquiryFormDataMutation = `
  mutation createInquiryFormData(
    $inquiryId: Int
    $slugId: String
    $versionId: Int
    $status: String
    $formData: [FormQuestionInput!]!
    $closeReason: String
  ) {
    createInquiryFormData(
      input: {
        inquiryId: $inquiryId
        slugId: $slugId
        versionId: $versionId
        status: $status
        formData: $formData
        closeReason:$closeReason
      }
    ) {
      inquiryFormDataId
    }
  }
`;

export default createInquiryFormDataMutation;
