import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  map_container: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  search_as_i_move: {
    position: 'absolute',
    padding: '0 18px',
    top: '50px',
    left: 'calc(50% - 130px)',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.neutral.cosmicCobalt3}`,
    backgroundColor: theme.palette.light.platinum5,
    zIndex: '1',
  },
}));

export { useStyles };
