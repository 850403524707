import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    custom_select: {
      position: 'relative',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: '22px',
    },
    select: {
      border: `1px solid ${theme.palette.light.platinum1}`,
      backgroundColor: theme.palette.light.white,
      boxSizing: 'border-box',
      borderRadius: '2px',
      padding: '9px 16px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      color: theme.palette.dark.eerieBlack5,
      '& div': {
        verticalAlign: 'middle',
        display: 'inline',
      },
      '&:focus': {
        border: `1px solid ${theme.palette.neutral.cosmicCobalt3}`,
        outline: 'none',
        boxShadow: `0px 2px 4px ${theme.palette.light.boxShadow}`,
      },
    },
    filled: {
      '& $select': {
        border: `1px solid ${theme.palette.neutral.cosmicCobalt3}`,
        outline: 'none',
      },
      '&$textual $select': {
        color: theme.palette.neutral.cosmicCobalt3,
      },
    },
    icon: {
      paddingLeft: '10px',
      '& svg': {
        verticalAlign: 'middle',
      },
    },
    menu: {
      position: 'absolute',
      backgroundColor: theme.palette.light.white,
      width: '100%',
    },
    items_container: {
      border: `1px solid ${theme.palette.neutral.cosmicCobalt5}`,
      textAlign: 'left',
      '& div': {
        color: theme.palette.dark.eerieBlack1,
      },
    },
    group_item: {
      padding: '0 18px',
      '&:hover': {
        backgroundColor: theme.palette.neutral.dropdown,
        color: theme.palette.dark.eerieBlack1,
      },
    },
    group_title: {
      padding: '8px 16px',
    },
    selected: {
      backgroundColor: theme.palette.neutral.cosmicCobalt5,
      color: theme.palette.neutral.cosmicCobalt2,
    },
    label: {
      fontSize: '13px',
      lineHeight: '19px',
      color: theme.palette.dark.eerieBlack5,
      margin: '4px',
      textAlign: 'left',
    },
    text_hint: {
      fontSize: '13px',
      lineHeight: '19px',
      color: theme.palette.dark.eerieBlack5,
      margin: '4px',
      textAlign: 'left',
    },
    error: {
      '& $select': {
        border: `1px solid ${theme.palette.correction.smokyTopaz2}`,
      },
      '& $label, $text_hint': {
        color: theme.palette.correction.smokyTopaz2,
      },
    },
    disabled: {
      '& $select': {
        backgroundColor: theme.palette.light.platinum4,
        border: `1px solid ${theme.palette.light.platinum1}`,
        color: theme.palette.dark.eerieBlack5,
        cursor: 'default',
        '& svg': {
          color: theme.palette.dark.disabled,
        },
        '&:focus': {
          border: `1px solid ${theme.palette.light.platinum1}`,
          outline: 'none',
          boxShadow: 'none',
        },
      },
    },
    buttoned: {
      '& $select': {
        backgroundColor: theme.palette.neutral.cosmicCobalt3,
        color: theme.palette.light.platinum5,
        '& svg': {
          fill: theme.palette.light.platinum5,
        },
      },
    },
    textual: {
      '& $select': {
        border: '1px solid transparent',
        backgroundColor: 'transparent',
        color: theme.palette.dark.eerieBlack1,
        '& svg': {
          fill: theme.palette.neutral.cosmicCobalt3,
          color: theme.palette.neutral.cosmicCobalt3,
        },
        '&:focus': {
          border: '1px solid transparent',
          outline: 'none',
          boxShadow: 'none',
        },
      },
    },
  };
});

export { useStyles };
