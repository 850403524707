import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  send_letter_container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  top_bar: {
    height: '72px',
    backgroundColor: theme.palette.neutral.cosmicCobalt1,
    width: '100%',
    boxShadow: '0px 0px 8px rgba(13, 19, 33, 0.5)',
    zIndex: 2,
    padding: '14px 0',
    display: 'flex',
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  back_container: {
    display: 'flex',
    position: 'absolute',
    left: '24px',
  },
  back_link: {
    display: 'flex',
    textDecoration: 'none',
    color: theme.palette.light.platinum3,
    '& svg': {
      verticalAlign: 'middle',
      fontSize: '24px',
      color: theme.palette.light.platinum3,
      marginRight: '16px',
    },
    '& p': {
      color: theme.palette.light.platinum5,
      fontWeight: 'bold',
      textDecoration: 'none',
    },
  },
  body: {
    flexGrow: 1,
    backgroundColor: theme.palette.light.platinum3,
    padding: '32px',
    display: 'flex',
  },
  left_item: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.palette.light.platinum5,
    boxShadow: '0px 0px 8px rgba(186, 186, 184, 0.3)',
    borderRadius: '4px',
    height: 'fit-content',
  },
  left_item_content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
  },
  left_item_footer: {
    display: 'flex',
    padding: '16px',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${theme.palette.light.platinum1}`,
    '& > *': {
      marginLeft: '24px',
    },
  },
  right_item: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: 'fit-content',
    marginLeft: '32px',
    '& div:last-child': {
      paddingTop: 0,
      overflow: 'auto',
      height: '80vh',
      backgroundColor: theme.palette.light.divider,
      display: 'flex',
      justifyContent: 'center',
    },
    '& img': {
      height: '2006px',
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  note: {
    display: 'flex',
    marginTop: '8px',
  },
  items: {
    marginTop: '4px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      color: theme.palette.positive.wintergreenDream2,
      verticalAlign: 'middle',
      marginRight: '10px',
    },
  },
  about_the_family: {
    marginTop: '15px',
  },
  communities: {
    marginTop: '40px',
  },
  single_community: {
    marginTop: '16px',
  },
  toast: {
    marginTop: '8px',
    marginBottom: '16px',
  },
  divider: {
    marginTop: '24px',
  },
  clear: {
    cursor: 'pointer',
    padding: '8px 16px',
    width: 'fit-content',
  },
  warning_toast: {
    marginTop: '8px',
  },
  link: {
    marginTop: '40px',
    display: 'block',
  },

  family_file_toast_part_2: {
    marginTop: theme.spacing(4),
  },
  email_template_dropdown: {
    width: theme.spacing(34),
    marginTop: theme.spacing(2),
  },
  subject_input: {
    width: '70%',
    [theme.breakpoints.up(1280)]: {
      width: '50%',
    },
    marginTop: theme.spacing(2),
  },
  default_text_area: {
    width: '95%',
    [theme.breakpoints.up(1024)]: {
      width: '85%',
    },
    marginTop: theme.spacing(2),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginRight: 18,
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  links: {
    marginTop: theme.spacing(1),
  },
  buttons_container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  disabled_button: {
    pointerEvents: 'none',
  },
  empty_body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '130px',
  },
  no_referrals_container: {
    width: '420px',
    textAlign: 'center',
  },
  referral_icon: {
    fontSize: '100px',
    marginBottom: theme.spacing(3),
  },
  no_referrals_text: {
    marginTop: theme.spacing(2),
  },
  filter: {
    width: '50%',
    'z-index': '1',
    marginRight: '24px',
  },
}));

export { useStyles };
