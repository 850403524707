import React from 'react';
import PropTypes from 'prop-types';

const HighAcuityCare = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M1 25.0002L2.7 20.2002C2.8 19.5002 3.3 19.0002 3.9 18.8002L7.3 17.7002C8 17.5002 8.7 17.6002 9.2 18.1002L13 21.9002L16.8 18.1002C17.3 17.6002 18 17.4002 18.7 17.7002L22.1 18.8002C22.7 19.0002 23.2 19.6002 23.3 20.2002L25 25.0002" 
        stroke="currentColor" 
        stroke-miterlimit="10" 
        stroke-linecap="round" 
        stroke-linejoin="round"/>
      <path d="M7.8999 7.50012V10.3001C7.8999 13.3001 10.1999 15.7001 12.9999 15.7001C15.7999 15.7001 18.0999 13.3001 18.0999 10.3001V7.50012" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.9002 3.00012C19.3002 1.00012 18.0002 1.00012 12.9002 1.00012C7.80022 1.00012 6.60022 1.00012 7.00022 3.10012L7.90022 7.50012H18.1002L18.9002 3.00012Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13 2.6001V5.8001" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14.5999 4.20007H11.3999" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

HighAcuityCare.propTypes = {
  className: PropTypes.string,
};

export default HighAcuityCare;
