import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/Modal/Modal';
import Typography from 'common/Typography/Typography';
import { useStyles } from './FindingSlaPopup.styles';
import Searching from 'common/Graphics/animations/searching/searching';
import Button from 'common/Button/Button';
import {
  warmTransferStates,
  warmTransferTypes,
} from 'services/callCenter/warmTransferService';
import { useSelector } from 'react-redux';
import {
  selectWarmTransferState,
  selectWarmTransferType,
} from 'stateManagement/callcenter/warmTransfer/reducers/warmTransferReducer';
import allCallStates from 'config/callStates.config';
import { withLabels } from 'context/LabelContext';

const DEFAULT = 'default';
const TYPES = {
  [warmTransferStates.SEARCHING]: {
    title: (labels) => labels.FINDING_BEST_SLA,
    subtitle: (labels) => labels.REQUESTING_SLAS,
    content: function content(labels) {
      return (
        <Fragment>
          <Typography color="eerieBlack5">{labels.SLA_SEARCH_P1}</Typography>
          <Typography color="eerieBlack5">{labels.SLA_SEARCH_P2}</Typography>
        </Fragment>
      );
    },
    canCancel: false,
  },
  [warmTransferStates.SEARCHING_EXISTING]: {
    title: (labels) => labels.SEARCHING_EXISTING,
    subtitle: (labels) => labels.REQUESTING_EXISTING_SLA,
    content: () => null,
    canCancel: false,
  },
  [warmTransferStates.WAITING_SLA]: {
    title: (labels) => labels.WAITING_SLA,
    subtitle: (labels) => labels.TRYING_TO_CONTACT_SLA,
    content: () => null,
    canCancel: false,
  },
  [warmTransferStates.RETRYING_SLA]: {
    title: (labels) => labels.RETRYING_SLA,
    subtitle: (labels) => labels.FAILED_TO_CONNECT_SLA,
    content: () => null,
    canCancel: false,
  },
  default: {
    title: () => '',
    subtitle: () => '',
    content: () => '',
    canCancel: false,
  },
};

const getType = (warmTransferState, warmTransferType) => {
  if (
    warmTransferState === warmTransferStates.SEARCHING ||
    warmTransferState === warmTransferStates.STARTED
  ) {
    if (warmTransferType === warmTransferTypes.REINQUIRY_OR_DUPLICATE) {
      return warmTransferStates.SEARCHING_EXISTING;
    }

    return warmTransferStates.SEARCHING;
  }
  if (
    warmTransferState === warmTransferStates.WAITING_SLA ||
    warmTransferState === warmTransferStates.RETRYING_SLA
  ) {
    return warmTransferState;
  }
  return DEFAULT;
};

const FindingSlaPopup = ({
  labels,
  buttonEnabled,
  onCancelTransfer,
  callState,
}) => {
  const classes = useStyles();
  const warmTransferState = useSelector(selectWarmTransferState);
  const warmTransferType = useSelector(selectWarmTransferType);

  const type = getType(warmTransferState, warmTransferType);
  const currentType = TYPES[type];
  const isOpen =
    callState === allCallStates.TALKING.key &&
    (warmTransferState === warmTransferStates.STARTED ||
      warmTransferState === warmTransferStates.SEARCHING ||
      warmTransferState === warmTransferStates.WAITING_SLA ||
      warmTransferState === warmTransferStates.RETRYING_SLA);

  return (
    <Modal
      className={classes.modal_container}
      noBodyPadding
      title={currentType?.title(labels)}
      isOpen={isOpen}
      color="cosmicCobalt2"
      showCloseIcon={false}
      noBodyScroll
    >
      <div className={classes.modal_body}>
        {currentType?.content(labels)}
        <div className={classes.search_animation}>
          <Searching />
          <Typography
            level="tiny"
            color="cosmicCobalt3"
            className={classes.subtitle}
          >
            {currentType?.subtitle(labels)}
          </Typography>
        </div>
      </div>
      {currentType?.canCancel && (
        <div className={classes.modal_footer}>
          <Button
            type={buttonEnabled ? 'outlinedWarning' : 'outlinedDisabled'}
            onClick={onCancelTransfer}
          >
            {labels.CANCEL_SEARCH}
          </Button>
        </div>
      )}
    </Modal>
  );
};

FindingSlaPopup.defaultProps = {
  onCancelTransfer: () => {},
};

FindingSlaPopup.propTypes = {
  buttonEnabled: PropTypes.bool,
  onCancelTransfer: PropTypes.func,
  labels: PropTypes.object,
  callState: PropTypes.oneOfType(PropTypes.string, PropTypes.shape({})),
};

export default withLabels(FindingSlaPopup);
