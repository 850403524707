import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './NotesSidePanel.style';
import Typography from '../../common/Typography/Typography';
import PencilEdit from '../../common/Icons/basic/PencilEdit';
import TextInput from '../../common/TextInput/TextInput';
import Button from '../../common/Button/Button';
import datetimeService from '../../utils/DateTime/datetime';
import useGraphQLMutation from '../../hooks/useGraphQLMutation';
import createKeyNoteMutation from '../FamilyFile/Overview/createKeyNoteMutation';
import updateKeyNoteMutation from '../FamilyFile/Overview/updateKeyNoteMutation';
import Toast from '../../common/Toast/Toast';
import NoteLogService from './NoteLogService';
import { useExternalId } from '../../hooks/useExternalId';

const KeyNoteCard = ({
  date,
  labels,
  text,
  userFullName,
  userIsActive,
  keyNoteId,
  onKeyNoteSave,
  familyFileId,
  screenName,
  userId,
  editKeyNote,
}) => {
  const classes = useStyles();

  const [isEditKeyNote, setIsEditKeyNote] = useState(false);

  const { fetchedFamilyId } = useExternalId(familyFileId);

  const [
    createKeyNote,
    { loading: createKeyNoteLoading, error: createKeyNoteError },
  ] = useGraphQLMutation(createKeyNoteMutation);

  const [
    updateKeyNote,
    { loading: updateKeyNoteLoading, error: updateKeyNoteError },
  ] = useGraphQLMutation(updateKeyNoteMutation);

  const [isSaveKeyNoteSuccess, setIsSaveKeyNoteSuccess] = useState(false);

  const maxInputTextLength = 500;
  const maxRowsCount = 4;

  const getKeyNoteText = () => {
    return text === labels.NO_KEYNOTE_YET ? '' : text;
  };

  const [keyNoteText, setKeyNoteText] = useState();

  useEffect(() => {
    setKeyNoteText(getKeyNoteText());
    // eslint-disable-next-line
  }, [text]);

  const saveKeyNote = () => {
    setIsSaveKeyNoteSuccess(false);

    const utcDateTime = datetimeService.getUTCFromZonedDateTime(
      new Date(),
      null,
    );

    const keyNoteInput = getKeyNoteInput(utcDateTime);

    if (keyNoteId) {
      updateKeyNoteRequest(keyNoteInput);
    } else {
      createKeyNoteRequest(keyNoteInput);
    }
  };

  const updateKeyNoteRequest = (updateKeyNoteInput) => {
    updateKeyNote(updateKeyNoteInput)
      .then((response) => {
        const data = response?.data?.updateKeyNote;
        onKeyNoteSave(data);
        setIsEditKeyNote(false);
        setIsSaveKeyNoteSuccess(true);
        NoteLogService.logOnNoteEvent(getEventData(null, 'Note Modified'));
      })
      .catch((err) => {
        console.log(err);
        NoteLogService.logOnNoteEvent(
          getEventData(err.message, 'Note Error Viewed'),
        );
      });
  };

  const createKeyNoteRequest = (createKeyNoteInput) => {
    createKeyNote(createKeyNoteInput)
      .then((response) => {
        const data = response?.data?.createKeyNote;
        onKeyNoteSave(data);
        setIsEditKeyNote(false);
        setIsSaveKeyNoteSuccess(true);
        NoteLogService.logOnNoteEvent(getEventData(null, 'Note Modified'));
      })
      .catch((err) => {
        console.log(err);
        NoteLogService.logOnNoteEvent(
          getEventData(err.message, 'Note Error Viewed'),
        );
      });
  };

  const getKeyNoteInput = (utcDateTime) => {
    return {
      variables: {
        keyNoteId: keyNoteId,
        familyFileId: familyFileId,
        noteText: keyNoteText,
        updatedAt: utcDateTime,
      },
    };
  };

  const cancelKeyNote = () => {
    setIsEditKeyNote(false);
    setKeyNoteText(getKeyNoteText());
  };

  const getEventData = (error, type) => {
    return {
      familyFileId: fetchedFamilyId,
      noteType: 'key note',
      id: keyNoteId,
      error,
      userId,
      screenName,
      type,
    };
  };

  const displayKeyNote = (
    <div>
      {isEditKeyNote ? (
        <>
          <TextInput
            id="keynote_edit"
            className={classes.keynote_edit_text_input_container}
            maxLength={maxInputTextLength}
            rows={maxRowsCount}
            multiline={true}
            value={keyNoteText}
            placeholder={labels.TYPE_YOUR_KEYNOTES_HERE}
            onChange={(val) => setKeyNoteText(val)}
            disabled={createKeyNoteLoading}
          />
          <div className={classes.keynote_edit_button_container}>
            <Button
              className={classes.keynote_cancel_button}
              type="light"
              size="medium"
              onClick={cancelKeyNote}
            >
              {labels.CANCEL}
            </Button>
            <Button
              type={
                createKeyNoteLoading || updateKeyNoteLoading
                  ? 'disabled'
                  : 'secondary'
              }
              size="medium"
              onClick={saveKeyNote}
              disabled
            >
              {labels.SAVE}
            </Button>
          </div>
        </>
      ) : (
        <Typography className={classes.note_text}>{text}</Typography>
      )}
    </div>
  );

  return (
    <div className={classes.notes_row_container}>
      <div className={classes.keynote_toast_container}>
        {createKeyNoteError ||
          (updateKeyNoteError && (
            <Toast type="error" onClose={() => ''}>
              <Typography>{labels.LEAD_KEYNOTE_ERROR}</Typography>
            </Toast>
          ))}
        {isSaveKeyNoteSuccess && (
          <Toast type="success" onClose={() => ''}>
            <Typography>{labels.LEAD_KEYNOTE_SUCCESS}</Typography>
          </Toast>
        )}
      </div>

      <div className={classes.keynote_container}>
        <div className={classes.keynote_header}>
          <div>
            {userIsActive ? (
              <Typography className={classes.keynote_title} level={'small'}>
                {labels.LEAD_KEYNOTE_BY} {userFullName ? `${userFullName}` : ''}
              </Typography>
            ) : (
              <Typography className={classes.inactive_user} level={'small'}>
                {labels.INACTIVE_USER}
              </Typography>
            )}
          </div>
          <div>
            {keyNoteId && (
              <Typography level={'small'} color={'eerieBlack5'}>
                {labels.LAST_UPDATED}: {date}
              </Typography>
            )}
          </div>
        </div>
        {editKeyNote && (
          <div
            className={classes.keynote_edit}
            onClick={() => setIsEditKeyNote(true)}
          >
            <PencilEdit />
          </div>
        )}
      </div>
      {displayKeyNote}
    </div>
  );
};

KeyNoteCard.propTypes = {
  date: PropTypes.string,
  userIsActive: PropTypes.bool,
  labels: PropTypes.object,
  text: PropTypes.string,
  userFullName: PropTypes.string,
  keyNoteId: PropTypes.number,
  onKeyNoteSave: PropTypes.func,
  familyFileId: PropTypes.number,
  screenName: PropTypes.string,
  userId: PropTypes.number,
  editKeyNote: PropTypes.bool,
};

KeyNoteCard.defaultProps = {
  onKeyNoteSave: () => {},
};

export default KeyNoteCard;
