import gql from 'graphql-tag';

export default gql`
  mutation($inquiryId: Int, $activeStatus: Boolean, $phoneNumber: String) {
    upsertDoNotCall(
      input: {
        inquiryId: $inquiryId
        activeStatus: $activeStatus
        phoneNumber: $phoneNumber
      }
    ) {
      doNotCallId
      phoneNumber
      inquiryId
      createdAt
      createdBy
      updatedAt
      updatedBy
      activeStatus
    }
  }
`;
