import React from 'react';
import PropTypes from 'prop-types';

const Win = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 80 80"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.733 23.334L45.6002 21.7337L52.8001 12.6673C53.8665 11.4668 54.1334 9.73371 53.4668 8.26692C52.6666 6.00064 50.1334 4.93358 47.8665 5.73371L47.0664 6.00064L46.1334 3.46679L46.9336 3.20051C50.5332 1.73371 54.6666 3.60025 56 7.20051C56.9336 9.60025 56.5332 12.2669 54.9336 14.2669L47.733 23.334Z"
        fill="#E8821C"
      />
      <path
        d="M25.1999 31.8672L14.8001 30.6673C10.8001 30.2669 7.86653 26.5339 8.26692 22.5339C8.53319 19.7337 10.3997 17.4668 12.9336 16.4004L14.1335 15.8672L15.0664 18.4004L13.8665 18.9336C11.4668 19.8672 10.2669 22.6673 11.1999 25.0671C11.8665 26.6673 13.3333 27.8672 15.0664 28.0007L25.4668 29.2005L25.1999 31.8672Z"
        fill="#E8821C"
      />
      <path
        d="M35.1998 33.7337L30 33.2005C24 32.6673 18.8001 28.8001 16.5332 23.0671L13.0664 14.2669C12.5332 12.9336 13.1998 11.334 14.5332 10.8001H14.6666L42.8001 0.667309C44.1334 0.133455 45.733 0.933585 46.2669 2.26692L49.1998 11.067C50.9336 16.4004 49.8665 22.2669 46.1334 26.5338L42.5332 30.6673L56.8001 68.0006H62.1334C63.1998 68.0006 64.2669 68.6673 64.5332 69.7337L65.3333 71.7337L45.733 79.4668L44.9336 77.4668C44.5332 76.4004 44.8001 75.334 45.6002 74.5338L49.4668 70.8001L35.1998 33.7337Z"
        fill="#F4A422"
      />
      <path
        d="M40.9336 24.8002L39.0664 22.9336C44.9336 17.0671 38.9336 7.33403 38.9336 7.20056L41.1999 5.73376C41.4668 6.26697 48.2669 17.4668 40.9336 24.8002Z"
        fill="#EDC17C"
      />
      <path
        d="M64.5331 69.8671C64.1334 68.8001 63.1998 68.1334 62.1334 68.1334H56.8L49.4667 71.067L45.6001 74.8001C44.8 75.6002 44.5331 76.6673 44.9335 77.7337L45.733 79.7337L65.3332 72.0006L64.5331 69.8671Z"
        fill="#E8821C"
      />
      <path
        d="M61.8665 53.6002L54.2669 33.2005C53.7331 31.8671 52.2669 31.067 50.8001 31.6002H50.6667L36 38.0006C35.1999 38.4003 34.6667 39.067 34.3997 39.8671L33.3333 45.2005L0 58.5338V77.2005L38.6667 61.2005L48.5332 62.4003C49.0664 62.4003 49.6003 62.4003 50 62.1334L60.5332 56.9335C61.7331 56.2669 62.2669 54.9335 61.8665 53.6002Z"
        fill="#EFCCBF"
      />
      <path
        d="M33.3333 45.2006L20 50.5339L25.3333 66.6674L38.6667 61.2006L33.3333 45.2006Z"
        fill="#676B7F"
      />
      <path
        d="M66.6666 5.20044V6.53377C66.6666 10.9335 63.0664 14.5338 58.6666 14.5338C63.0664 14.5338 66.6666 18.1334 66.6666 22.5338V23.8671V22.5338C66.6666 18.1334 70.2669 14.5338 74.6666 14.5338C70.2669 14.5338 66.6666 10.9335 66.6666 6.53377V5.20044Z"
        fill="#F4C167"
      />
      <path
        d="M8 31.8671V33.2004C8 37.6001 4.39974 41.2004 0 41.2004C4.39974 41.2004 8 44.8 8 49.2004V50.5337V49.2004C8 44.8 11.6003 41.2004 16 41.2004C11.6003 41.2004 8 37.6001 8 33.2004V31.8671Z"
        fill="#F4C167"
      />
      <path
        d="M72 31.8671V33.2004C72 37.6001 68.3997 41.2004 64 41.2004C68.3997 41.2004 72 44.8 72 49.2004V50.5337V49.2004C72 44.8 75.6003 41.2004 80 41.2004C75.6003 41.2004 72 37.6001 72 33.2004V31.8671Z"
        fill="#F4C167"
      />
      <path
        d="M25.3333 66.6673L0 77.2005V58.5338L20 50.5338L25.3333 66.6673Z"
        fill="#4A4A56"
      />
      <path
        d="M28.1335 60.2669L30.5332 59.4668L26.6667 47.8672L24.267 48.8001L28.1335 60.2669Z"
        fill="#4A4A56"
      />
      <path
        d="M55.3333 36.0007L47.6002 38.6674L48.3997 41.0671L56.2669 38.5339L55.3333 36.0007Z"
        fill="#D8B4AD"
      />
      <path
        d="M57.8665 42.8002L50.2668 45.334L51.0663 47.7337L58.8 45.2006L57.8665 42.8002Z"
        fill="#D8B4AD"
      />
      <path
        d="M60.2669 49.4668L52.9336 52.0007L53.7331 54.4004L61.1999 52.0007L60.2669 49.4668Z"
        fill="#D8B4AD"
      />
      <path
        d="M47.733 23.334L45.6002 21.7337L52.8001 12.6673C53.8665 11.4668 54.1334 9.73371 53.4668 8.26692C52.6666 6.00064 50.1334 4.93358 47.8665 5.73371L47.0664 6.00064L46.1334 3.46679L46.9336 3.20051C50.5332 1.73371 54.6666 3.60025 56 7.20051C56.9336 9.60025 56.5332 12.2669 54.9336 14.2669L47.733 23.334Z"
        fill="#9C4814"
      />
      <path
        d="M25.1999 31.8672L14.8001 30.6673C10.8001 30.2669 7.86653 26.5339 8.26692 22.5339C8.53319 19.7337 10.3997 17.4668 12.9336 16.4004L14.1335 15.8672L15.0664 18.4004L13.8665 18.9336C11.4668 19.8672 10.2669 22.6673 11.1999 25.0671C11.8665 26.6673 13.3333 27.8672 15.0664 28.0007L25.4668 29.2005L25.1999 31.8672Z"
        fill="#9C4814"
      />
      <path
        d="M35.1998 33.7337L30 33.2005C24 32.6673 18.8001 28.8001 16.5332 23.0671L13.0664 14.2669C12.5332 12.9336 13.1998 11.334 14.5332 10.8001H14.6666L42.8001 0.667309C44.1334 0.133455 45.733 0.933585 46.2669 2.26692L49.1998 11.067C50.9336 16.4004 49.8665 22.2669 46.1334 26.5338L42.5332 30.6673L56.8001 68.0006H62.1334C63.1998 68.0006 64.2669 68.6673 64.5332 69.7337L65.3333 71.7337L45.733 79.4668L44.9336 77.4668C44.5332 76.4004 44.8001 75.334 45.6002 74.5338L49.4668 70.8001L35.1998 33.7337Z"
        fill="#D6621B"
      />
      <path
        d="M40.9336 24.8002L39.0664 22.9336C44.9336 17.0671 38.9336 7.33403 38.9336 7.20056L41.1999 5.73376C41.4668 6.26697 48.2669 17.4668 40.9336 24.8002Z"
        fill="#F7EED0"
      />
      <path
        d="M64.5331 69.8671C64.1334 68.8001 63.1998 68.1334 62.1334 68.1334H56.8L49.4667 71.067L45.6001 74.8001C44.8 75.6002 44.5331 76.6673 44.9335 77.7337L45.733 79.7337L65.3332 72.0006L64.5331 69.8671Z"
        fill="#E49B6D"
      />
      <path
        d="M61.8665 53.6002L54.2669 33.2005C53.7331 31.8671 52.2669 31.067 50.8001 31.6002H50.6667L36 38.0006C35.1999 38.4003 34.6667 39.067 34.3997 39.8671L33.3333 45.2005L0 58.5338V77.2005L38.6667 61.2005L48.5332 62.4003C49.0664 62.4003 49.6003 62.4003 50 62.1334L60.5332 56.9335C61.7331 56.2669 62.2669 54.9335 61.8665 53.6002Z"
        fill="#F3E0D4"
      />
      <path
        d="M33.3333 45.2006L20 50.5339L25.3333 66.6674L38.6667 61.2006L33.3333 45.2006Z"
        fill="#4F535D"
      />
      <path
        d="M66.6666 5.20044V6.53377C66.6666 10.9335 63.0664 14.5338 58.6666 14.5338C63.0664 14.5338 66.6666 18.1334 66.6666 22.5338V23.8671V22.5338C66.6666 18.1334 70.2669 14.5338 74.6666 14.5338C70.2669 14.5338 66.6666 10.9335 66.6666 6.53377V5.20044Z"
        fill="#E9CE73"
      />
      <path
        d="M8 31.8672V33.2005C8 37.6003 4.39974 41.2005 0 41.2005C4.39974 41.2005 8 44.8001 8 49.2005V50.5338V49.2005C8 44.8001 11.6003 41.2005 16 41.2005C11.6003 41.2005 8 37.6003 8 33.2005V31.8672Z"
        fill="#E9CE73"
      />
      <path
        d="M72 31.8672V33.2005C72 37.6003 68.3997 41.2005 64 41.2005C68.3997 41.2005 72 44.8001 72 49.2005V50.5338V49.2005C72 44.8001 75.6003 41.2005 80 41.2005C75.6003 41.2005 72 37.6003 72 33.2005V31.8672Z"
        fill="#E9CE73"
      />
      <path
        d="M25.3333 66.6673L0 77.2005V58.5338L20 50.5338L25.3333 66.6673Z"
        fill="#656871"
      />
      <path
        d="M29.9008 59.1506L28.4481 59.635L24.8934 49.0931L26.3584 48.5235L29.9008 59.1506Z"
        fill="#F9F9F9"
        stroke="#D7D7D8"
      />
      <path
        d="M55.3334 36L47.3334 38.6667L48.1329 41.0664L56.3334 38.6667L55.3334 36Z"
        fill="#2C438C"
      />
      <path
        d="M57.8665 42.8002L50.2668 45.334L51.0663 47.7337L58.8 45.2006L57.8665 42.8002Z"
        fill="#2C438C"
      />
      <path
        d="M60.3333 49.6667L52.9336 52.0007L53.7331 54.4005L61.1999 52.0007L60.3333 49.6667Z"
        fill="#2C438C"
      />
    </svg>
  );
};

Win.propTypes = {
  className: PropTypes.string,
};

export default Win;
