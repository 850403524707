import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  side_panel: {
    backgroundColor: theme.palette.light.platinum5,
  },
  notes_panel: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: theme.palette.light.platinum5,
    padding: '1rem',
    '& .notes_history': {
      height: 'calc(100vh - 507px)',
      overflowY: 'scroll',
    },
  },
  panel_header: {
    paddingBottom: '1rem',
  },
  label: {
    padding: '1rem 0',
    fontSize: '12px',
    '&.info': { color: theme.palette.neutral.cosmicCobalt2 },
    '&.danger': { color: theme.palette.miscellaneous1.hotCinnamon2 },
  },
  input: {
    fontFamily: 'Poppins',
    width: '98%',
    padding: '.5rem',
  },
  note: {
    paddingBottom: '1rem',
    '& .note_text': {
      margin: '.5rem 0',
      background: theme.palette.light.platinum1,
      fontSize: '15px',
      color: theme.palette.dark.eerieBlack,
      minHeight: '131px',
      height: 'auto',
      padding: '.5rem',
      overflowWrap: 'break-word',
      '& .right': {
        color: theme.palette.dark.eerieBlack5,
        paddingBottom: '.5rem',
        textAlign: 'right',
        '& svg': {
          color: theme.palette.correction.smokyTopaz2,
          fill: theme.palette.correction.smokyTopaz2,
        },
      },
    },
  },
}));

export { useStyles };
