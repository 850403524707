import React from 'react';
import PropTypes from 'prop-types';

const StarNo = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="currentColor"
        d="M6.8514 8.26551L1.293 2.70711L2.70721 1.29289L22.7072 21.2929L21.293 22.7071L18.7304 20.1445L19.2061 22.9182L12.0001 19.1298L4.79412 22.9182L6.17034 14.8942L0.340576 9.21159L6.8514 8.26551ZM16.2811 17.6952L16.5498 19.2622L12.0001 16.8702L7.45038 19.2622L8.3193 14.196L4.6385 10.6081L8.61599 10.0301L16.2811 17.6952ZM19.3617 10.6081L17.063 12.8487L18.4774 14.263L23.6596 9.21159L15.6031 8.04091L12.0001 0.740448L9.672 5.45769L11.1653 6.951L12.0001 5.25955L14.275 9.86892L19.3617 10.6081Z"
      />
    </svg>
  );
};

StarNo.propTypes = {
  className: PropTypes.string,
};

export default StarNo;
