import React from 'react';
import { useStyles } from './NoResults.style';
import Question from 'common/Graphics/other/Question';
import Typography from 'common/Typography/Typography';
import PropTypes from 'prop-types';

const NoResults = ({ labels }) => {
  const classes = useStyles();
  return (
    <div className={classes.no_result_container}>
      <Question />
      <Typography className={classes.title} level="large" bold>
        {labels.NO_RESULTS_FOUND}
      </Typography>
      <Typography className={classes.text}>
        {labels.WE_DONT_HAVE_ANY_COMMUNITY}
      </Typography>
      <Typography>{labels.PLEASE_ADJUST_YOUR_SEARCH}</Typography>
    </div>
  );
};

NoResults.propTypes = {
  labels: PropTypes.object.isRequired,
};

export default NoResults;
