import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'common/Alert/Alert';
import { withLabels } from 'context/LabelContext';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectWarmTransferCancelError,
  selectWarmTransferState,
} from 'stateManagement/callcenter/warmTransfer/reducers/warmTransferReducer';
import { warmTransferStates } from 'services/callCenter/warmTransferService';
import {
  setCancelWarmTransferError,
  updateWarmTransferState,
} from 'stateManagement/callcenter/warmTransfer/actions/warmTransferActions';

const WarmTransferAlerts = ({ labels }) => {
  const dispatch = useDispatch();
  const warmTransferState = useSelector(selectWarmTransferState);
  const warmTransferCancelError = useSelector(selectWarmTransferCancelError);

  const hasError =
    warmTransferState === warmTransferStates.ERROR_SLA ||
    warmTransferState === warmTransferStates.ERROR_CALL ||
    warmTransferState === warmTransferStates.ERROR_ZIP ||
    warmTransferState === warmTransferStates.ERROR_RELATIONSHIP;

  const close = () =>
    dispatch(updateWarmTransferState(warmTransferStates.NOT_STARTED));

  const defaultAlert = {
    title: labels.WARM_TRANSFER_ERROR,
    confirmText: labels.CONTINUE,
    onClose: close,
    type: 'delete',
    description: 'default description',
    isOpen: false,
  };

  const alertsConfig = [
    {
      ...defaultAlert,
      description: hasError ? labels[warmTransferState] : '',
      isOpen: hasError,
    },
    {
      ...defaultAlert,
      isOpen: warmTransferCancelError,
      onClose: () => dispatch(setCancelWarmTransferError(false)),
      title: labels.UNABLE_TO_CANCEL_WT,
      description: labels.DESCRIPTION_UNABLE_TO_CANCEL_WT,
    },
    {
      ...defaultAlert,
      description: labels.THERE_WAS_AN_ERROR_DURING_WARM_TRANSFER,
      isOpen: warmTransferState === warmTransferStates.ERROR,
      type: 'error',
    },
    {
      ...defaultAlert,
      description: labels.CONTEXT_EMPTY,
      isOpen: warmTransferState === warmTransferStates.ERROR_CONTEXT,
    },
    {
      ...defaultAlert,
      title: labels.WARM_TRANSFER_CANCELLED,
      description: labels.DESCRIPTION_WARM_TRANSFER_CANCELLED,
      isOpen: warmTransferState === warmTransferStates.CANCEL_SUCCESS,
      type: 'success',
    },
  ];

  const alert = alertsConfig.find((alert) => alert.isOpen);
  if (!alert) return null;
  return (
    <Alert
      title={alert.title}
      description={alert.description}
      isOpen={alert.isOpen}
      onClose={alert.onClose}
      type={alert.type}
      confirmText={alert.confirmText}
      onConfirm={alert.onClose}
    />
  );
};

WarmTransferAlerts.propTypes = {
  labels: PropTypes.object,
};

export default withLabels(WarmTransferAlerts);
