import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './TimelineItem.style';
import { colorReference } from '../Theme/themes/default';

const TimelineItem = ({ color, children }) => {
  const classes = useStyles(color);
  return (
    <div className={classes.container}>
      <div className={classes.graphic_column}>
        <div className={classes.ball} />
        <div className={classes.line} />
      </div>
      <div className={classes.card_column}>{children}</div>
    </div>
  );
};

TimelineItem.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(colorReference).isRequired,
};

export default TimelineItem;
