import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  current_point_container: {
    height: theme.spacing(18),
    boxShadow: `0px 0px 8px ${theme.palette.light.boxShadow}`,
    padding: theme.spacing(3),
    paddingLeft: 0,
    borderRadius: '4px',
    background: 'linear-gradient(90deg, #3956B5 0%, #492D7C 90.58%);',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  left_content: {
    width: '67%',
  },
  right_content: {
    width: '33%',
  },
}));

export { useStyles };
