import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      width: 'auto',
      flexGrow: 1,
      padding: '16px',
      borderLeft: (color) => `4px solid ${theme.palette.getColor(color)}`,
      borderRadius: '4px',
      backgroundColor: theme.palette.light.platinum5,
      boxShadow: '0px 0px 8px rgba(186, 186, 184, 0.3)',
      '&:hover': {
        boxShadow: '0px 0px 8px rgba(13, 19, 33, 0.5)',
      },
    },
  };
});

export { useStyles };
