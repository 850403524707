import {
  GET_INQUIRY_ERROR,
  GET_INQUIRY_REQUEST,
  GET_INQUIRY_SUCCESS,
  UPDATE_INQUIRY,
  UPDATE_INQUIRY_FAMILY_FILE_ID,
  UPDATE_LATEST_INQUIRY_ID,
  CREATE_INQUIRY_REQUEST,
  CREATE_INQUIRY_SUCCESS,
  CREATE_INQUIRY_ERROR,
  UPDATE_CALL_ID,
  UPDATE_FINISHED_INBOUND_CALL,
  FIND_INQUIRIES_BY_PARAMS_ERROR,
  FIND_INQUIRIES_BY_PARAMS_SUCCESS,
  FIND_INQUIRIES_BY_PARAMS_REQUEST,
  SAVE_LAST_CONTACTED_DATES,
  SAVE_IS_DNC,
  SAVE_IS_CALL_QUEUED,
  GET_INQUIRY_DETAILS_ERROR,
  GET_INQUIRY_DETAILS_REQUEST,
  GET_INQUIRY_DETAILS_SUCCESS,
  SET_REDIRECT_TO_WAITING_SCREEN,
  RESET_INQUIRY_STATE,
} from '../actions/inquiryActions';

const initialState = {
  loading: false,
  isInboundCallFinished: false,
  error: null,
  data: null,
  callAttempts: null,
  latestInquiryId: null,
  callId: null,
  searchCriteriaLoading: false,
  searchCriteriaError: null,
  searchCriteria: null,
  isRedirectToWaitingScreen: false,
  isInboundCallInquiryCreating: false,
  isInboundCallInquiryCreated: false,
  isInboundCallInquiryError: null,
};

const inquiryReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_INQUIRY_STATE:
      return initialState;
    case GET_INQUIRY_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        data: null,
        latestInquiryId: null,
      };
    case GET_INQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.response,
        latestInquiryId: state.latestInquiryId,
        callId: state.callId,
        isInboundCallFinished: state.isInboundCallFinished,
      };
    case GET_INQUIRY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        data: null,
        latestInquiryId: state.latestInquiryId,
        callId: state.callId,
        isInboundCallFinished: state.isInboundCallFinished,
      };
    case UPDATE_INQUIRY:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.inquiry,
        latestInquiryId: state.latestInquiryId,
        callId: state.callId,
      };
    case UPDATE_INQUIRY_FAMILY_FILE_ID:
      return {
        ...state,
        data: { ...state.data, familyFileId: action.payload.familyFileId },
      };
    case UPDATE_LATEST_INQUIRY_ID:
      return {
        ...state,
        latestInquiryId: action.payload.inquiryId,
        callId: state.callId,
        isInboundCallFinished: state.isInboundCallFinished,
      };
    case CREATE_INQUIRY_REQUEST:
      return {
        ...state,
        data: null,
        error: null,
        loading: true,
        callId: null,
        isInboundCallFinished: false,
        isInboundCallInquiryCreating: true,
        isInboundCallInquiryCreated: false,
        isInboundCallInquiryError: null,
      };
    case CREATE_INQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        isInboundCallInquiryCreating: false,
        isInboundCallInquiryCreated: true,
        inboundCallInquiry: action.payload.inquiry,
        isInboundCallInquiryError: null,
      };
    case CREATE_INQUIRY_ERROR:
      return {
        ...state,
        isInboundCallInquiryCreating: false,
        isInboundCallInquiryCreated: false,
        isInboundCallInquiryError: action.payload.error,
      };
    case UPDATE_CALL_ID:
      return {
        ...state,
        callId: action.payload.callId,
      };
    case UPDATE_FINISHED_INBOUND_CALL:
      return {
        ...state,
        isInboundCallFinished: action.payload.isInboundCallFinished,
      };
    case FIND_INQUIRIES_BY_PARAMS_REQUEST:
      return {
        ...state,
        searchCriteriaLoading: true,
      };
    case FIND_INQUIRIES_BY_PARAMS_SUCCESS:
      return {
        ...state,
        searchCriteriaLoading: false,
        searchCriteria: action.payload.response,
        searchCriteriaError: null,
      };
    case FIND_INQUIRIES_BY_PARAMS_ERROR:
      return {
        ...state,
        searchCriteriaLoading: false,
        searchCriteria: null,
        searchCriteriaError: action.payload.error,
      };
    case GET_INQUIRY_DETAILS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        data: null,
        latestInquiryId: null,
        callAttempts: null,
      };
    case GET_INQUIRY_DETAILS_SUCCESS:
      return {
        loading: false,
        error: null,
        data: action.payload.response.findInquiryById,
        latestInquiryId: state.latestInquiryId,
        callId: state.callId,
        isInboundCallFinished: state.isInboundCallFinished,
        callAttempts: action.payload.response.getCallAttempt,
      };
    case GET_INQUIRY_DETAILS_ERROR:
      return {
        loading: false,
        error: action.payload.error,
        data: null,
        latestInquiryId: state.latestInquiryId,
        callId: state.callId,
        isInboundCallFinished: state.isInboundCallFinished,
        callAttempts: null,
      };
    case SAVE_IS_DNC: {
      const inquiries = state.searchCriteria?.inquiries?.map((inquiry) => {
        if (inquiry.inquiryId === action.payload.inquiryId) {
          inquiry.isDnc = action.payload.isDnc;
        }
        return inquiry;
      });
      return {
        ...state,
        searchCriteria: {
          ...state.searchCriteria,
          inquiries,
        },
      };
    }
    case SAVE_IS_CALL_QUEUED: {
      const inquiries = state.searchCriteria?.inquiries?.map((inquiry) => {
        if (inquiry.inquiryId === action.payload.inquiryId) {
          inquiry.isQueued = action.payload.isQueued;
          inquiry.listName = action.payload.listName;
        }
        return inquiry;
      });
      return {
        ...state,
        searchCriteria: {
          ...state.searchCriteria,
          inquiries,
        },
      };
    }
    case SAVE_LAST_CONTACTED_DATES: {
      const inquiries = state.searchCriteria?.inquiries?.map((inquiry) => {
        if (
          inquiry.inquiryId === action.payload.inquiryId &&
          !inquiry.lastContactedDates
        ) {
          inquiry.lastContactedDates = action.payload.lastContactedDates;
        }
        return inquiry;
      });
      return {
        ...state,
        searchCriteria: {
          ...state.searchCriteria,
          inquiries,
        },
      };
    }

    case SET_REDIRECT_TO_WAITING_SCREEN:
      return {
        ...state,
        isRedirectToWaitingScreen: action.payload.isRedirectToWaitingScreen,
      };
    default:
      return state;
  }
};

export const selectInquiry = (state) => ({
  inquiryLoading: state?.callcenter?.inquiry?.loading,
  inquiryError: state?.callcenter?.inquiry?.error,
  inquiry: state?.callcenter?.inquiry?.data,
});
export const selectFindInquiriesByParams = (state) => ({
  inquiriesByParamsLoading: state?.callcenter?.inquiry?.searchCriteriaLoading,
  inquiriesByParamsError: state?.callcenter?.inquiry?.searchCriteriaError,
  inquiriesByParams: state?.callcenter?.inquiry?.searchCriteria,
});
export const selectCreatedInquiry = (state) => ({
  isInboundCallInquiryCreating:
    state?.callcenter?.inquiry?.isInboundCallInquiryCreating,
  isInboundCallInquiryCreated:
    state?.callcenter?.inquiry?.isInboundCallInquiryCreated,
  isInboundCallInquiryError:
    state?.callcenter?.inquiry?.isInboundCallInquiryError,
  inboundCallInquiry: state?.callcenter?.inquiry?.inboundCallInquiry,
});
export const selectLatestInquiryId = (state) =>
  state?.callcenter?.inquiry?.latestInquiryId;

export const selectCallId = (state) => state?.callcenter?.inquiry?.callId;

export const selectIsInboundCallFinished = (state) =>
  state?.callcenter?.inquiry?.isInboundCallFinished;

export const selectInquiryDetailsById = (state) => ({
  inquiryDetailsLoading: state?.callcenter?.inquiry?.loading,
  inquiryDetailsError: state?.callcenter?.inquiry?.error,
  inquiryDetails: state?.callcenter?.inquiry?.data,
  inquiryCallAttempts: state?.callcenter?.inquiry?.callAttempts,
});

export const selectRedirectToWaitingScreen = (state) =>
  state?.callcenter?.inquiry?.isRedirectToWaitingScreen;

export default inquiryReducer;
