export default [
  {
    label: '08:00 AM',
    value: '08:00',
    isMorning: true,
  },
  {
    label: '08:30 AM',
    value: '08:30',
    isMorning: true,
  },
  {
    label: '09:00 AM',
    value: '09:00',
    isMorning: true,
  },
  {
    label: '09:30 AM',
    value: '09:30',
    isMorning: true,
  },
  {
    label: '10:00 AM',
    value: '10:00',
    isMorning: true,
  },
  {
    label: '10:30 AM',
    value: '10:30',
    isMorning: true,
  },
  {
    label: '11:00 AM',
    value: '11:00',
    isMorning: true,
  },
  {
    label: '11:30 AM',
    value: '11:30',
    isMorning: true,
  },
  {
    label: '12:00 PM',
    value: '12:00',
    isMorning: false,
  },
  {
    label: '12:30 PM',
    value: '12:30',
    isMorning: false,
  },
  {
    label: '01:00 PM',
    value: '13:00',
    isMorning: false,
  },
  {
    label: '01:30 PM',
    value: '13:30',
    isMorning: false,
  },
  {
    label: '02:00 PM',
    value: '14:00',
    isMorning: false,
  },
  {
    label: '02:30 PM',
    value: '14:30',
    isMorning: false,
  },
  {
    label: '03:00 PM',
    value: '15:00',
    isMorning: false,
  },
  {
    label: '03:30 PM',
    value: '15:30',
    isMorning: false,
  },
  {
    label: '04:00 PM',
    value: '16:00',
    isMorning: false,
  },
  {
    label: '04:30 PM',
    value: '16:30',
    isMorning: false,
  },
  {
    label: '05:00 PM',
    value: '17:00',
    isMorning: false,
  },
  {
    label: '05:30 PM',
    value: '17:30',
    isMorning: false,
  },
];
