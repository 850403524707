import gql from 'graphql-tag';
import { Community } from 'queries/communityQueryFragments';

export default gql`
  query($familyFileId: Int) {
    findFamilyFileById(
      id: $familyFileId
      filters: { withoutToursDateRangeLimit: true }
    ) {
      userId
      referralStatus {
        communityId
        isReferralStarted
        referralDate
        communityStatus
        isRejected
      }
      communities {
        id
        name
        address
        address2
        phoneNumber
        distance
        city
        state
        zip
        primaryContact
        organizationName
        ...CommunityCareTypes
        roomCharges {
          careType
          amount
          apartmentType {
            description
          }
        }
        roomFees {
          amount
          careType
          feeType {
            name
          }
        }
        geoLocation {
          lat
          lon
        }
        title
        images {
          isDefault
          assets {
            id
            sizeId
          }
        }
        employees {
          isPrimaryContact
          firstName
          lastName
          email
          workPhone
          cellPhone
          fax
        }
      }
      tourDetailsExtended {
        familyCommunityTourId
        communityId
        scheduledTourDate
        tourType
        currentStatus
        tourActivities {
          familyCommunityTourActivityId
          familyCommunityTourId
          activityDate
          updatedAt
          tourType
          status
          isHidden
        }
      }
      completedTourDetails {
        communityId
        completedActivityDate
        completedTourType
      }
      tourDrafts {
        communityId
        familyCommunityTourDraftId
        activityDate
        tourType
      }
    }
  }
  ${Community.fragments.careTypes}
`;
