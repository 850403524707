import { camelCase, cloneDeep, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import Checkbox from 'common/Checkbox/Checkbox';
import Dropdown from 'common/Dropdown/Dropdown';
import Switch from 'common/Switch/Switch';
import TextInput from 'common/TextInput/TextInput';
import Typography from 'common/Typography/Typography';
import {
  actions,
  inquiryFields,
  operations,
  poolTypes,
} from 'services/callCenter/fieldsMapper';
import ScriptBuilderModal from '../common/ScriptBuilderModal/ScriptBuilderModal';
import { AnswerPropType, StepPropType } from '../scriptBuilderPropTypes';
import { useStyles } from './AnswerDialog.style';

const INQUIRY_FIELDS = Object.entries(inquiryFields).map(([name, obj]) => ({
  value: name,
  label: obj.description,
}));

const OPERATIONS = Object.entries(operations).map(([name, obj]) => ({
  value: name,
  label: obj.label,
}));

const ACTIONS = Object.entries(actions).map(([name, obj]) => ({
  value: name,
  label: obj.label,
}));

const DEFAULT_ANSWER = {
  order: 0,
  label: '',
  value: null,
  nextStep: -1,
  closeInquiry: false,
  addToDoNotCall: false,
  shows: [],
  hides: [],
  selectedRule: [],
  closeReason: null,
  action: null,
  disposition: null,
  urlLink: null,
};

const AnswerDialog = ({
  onClose,
  step,
  steps,
  onSaveAnswer,
  open,
  answer,
  labels,
  properties,
  catalogs,
}) => {
  const classes = useStyles();
  const [errors, setErrors] = useState([]);
  const [selectedRule, setSelectedRule] = useState([
    INQUIRY_FIELDS[0].value,
    OPERATIONS[0].value,
    '',
  ]);
  const [actualAnswer, setActualAnswer] = useState(DEFAULT_ANSWER);
  const [hasLink, setHasLink] = useState(false);
  const [poolType, setPoolType] = useState(false);

  useEffect(() => {
    if (step) {
      const defaultOrder = step.answers ? step.answers.length + 1 : 1;
      const newAnswer = isEmpty(answer)
        ? cloneDeep(DEFAULT_ANSWER)
        : cloneDeep(answer);
      if (!newAnswer.order) newAnswer.order = defaultOrder;
      setActualAnswer(newAnswer);
      setHasLink(newAnswer.urlLink !== null);
      setPoolType(newAnswer.poolType && newAnswer.poolType !== 1);
    }
  }, [answer, step]);

  const foundProperty =
    properties && step
      ? properties.find((p) => p.value === step.property)
      : null;

  if (!open) return null;

  const availableSteps = steps
    .filter((s) => s.number !== step.number)
    .sort((a, b) => a.order - b.order);

  const nextSteps = availableSteps.map((s) => ({
    label: `${s.order}. ${s.name}`,
    value: s.number.toString(),
  }));

  nextSteps.unshift({
    label: labels.STAY_IN_THE_SAME_STEP,
    value: step.number.toString(),
  });

  if (step.type === 'endCall' || step.type === 'completeReschedule') {
    nextSteps.unshift({ label: labels.ANSWER_END_FORM, value: '-1' });
  }

  const DISPOSITIONS = catalogs.dispositions
    .map((d) => ({
      value: d.value,
      label: d.label,
    }))
    .sort((a, b) => (a.last_nom > b.label ? 1 : b.label > a.label ? -1 : 0));

  const CLOSE_REASONS = catalogs.closeReasons
    .map((c) => ({
      value: c.value,
      label: c.label,
    }))
    .sort((a, b) => (a.last_nom > b.label ? 1 : b.label > a.label ? -1 : 0));

  const changeValue = (id, value, toInt = false) => {
    actualAnswer[id] = toInt ? parseInt(value, 10) : value;
    setActualAnswer({ ...actualAnswer });
  };

  const onCloseAnswerDialog = () => {
    setActualAnswer({ ...answer });
    setErrors([]);
    onClose();
  };

  const changeSelectedRule = (id, value) => {
    const newSelectedRule = [...selectedRule];
    switch (id) {
      case 'selectedRuleField':
        newSelectedRule[0] = value;
        break;
      case 'selectedRuleOperation':
        newSelectedRule[1] = value;
        break;
      case 'selectedRuleValue':
        newSelectedRule[2] = value;
        break;
      default:
        break;
    }
    setSelectedRule(newSelectedRule);
    changeValue(id, value);
  };

  const changeToggle = (name) => {
    actualAnswer[name] = !actualAnswer[name];
    setActualAnswer({ ...actualAnswer });
  };

  const printSelectedRule = () => {
    const field = INQUIRY_FIELDS.find((f) => f.value === selectedRule[0]).label;
    const operation = OPERATIONS.find((o) => o.value === selectedRule[1]).value;
    const value = selectedRule[2];

    return labels.ANSWER_SELECTED_RULE.replace('{FIELD}', field)
      .replace('{OPERATION}', operation)
      .replace('{VALUE}', value);
  };

  const toggleShowHideQuestion = (currentArray, number) => {
    const otherArray = currentArray === 'shows' ? 'hides' : 'shows';
    if (!actualAnswer.shows) {
      actualAnswer.shows = [];
    }
    if (!actualAnswer.hides) {
      actualAnswer.hides = [];
    }
    if (actualAnswer[currentArray].includes(number)) {
      actualAnswer[currentArray] = actualAnswer[currentArray].filter(
        (q) => q !== number,
      );
    } else {
      actualAnswer[currentArray].push(number);
    }
    if (actualAnswer[otherArray].includes(number)) {
      actualAnswer[otherArray] = actualAnswer[otherArray].filter(
        (q) => q !== number,
      );
    }
    setActualAnswer({ ...actualAnswer });
  };

  const isDuplicated = () => {
    if (!step.answers) return false;
    const foundAnswersByValue = step.answers.filter(
      (a) => a.value === actualAnswer.value,
    ).length;
    if (foundAnswersByValue === 0) return false;
    if (Object.keys(actualAnswer).length === 0 && foundAnswersByValue === 0) {
      return false;
    }
    if (Object.keys(actualAnswer).length === 0 && foundAnswersByValue > 0) {
      return true;
    }
    if (Object.keys(actualAnswer).length !== 0 && foundAnswersByValue === 0) {
      return false;
    }
    if (Object.keys(actualAnswer).length !== 0 && foundAnswersByValue === 1) {
      return false;
    }
    console.error('Check the duplicated answer conditions');
    return true;
  };

  const isIncomplete = () => {
    if (!actualAnswer.label) return true;
    return false;
  };

  const isCloseReasonOk = () => {
    if (actualAnswer.closeInquiry && !actualAnswer.closeReason) return false;
    return true;
  };

  const isDispositionOk = () => {
    const next = steps.find(
      (s) => s.number?.toString() === actualAnswer.nextStep?.toString(),
    );
    return !(next?.type === 'endCall' && !actualAnswer.disposition);
  };

  const check = (condition, err, errorKey, errorLabel) => {
    let hasErrors = err.hasErrors;
    let errors = cloneDeep(err.errors);
    if (condition) {
      if (!errors.find((e) => e.key === errorKey)) {
        errors.push({
          key: errorKey,
          text: labels[errorLabel],
        });
      }
      hasErrors = true;
    } else {
      errors = errors.filter((e) => e.key !== errorKey);
    }
    return { errors, hasErrors };
  };

  const checkForErrors = () => {
    let err = {
      hasErrors: false,
      errors: [],
    };

    err = check(isDuplicated(), err, 'duplicated', 'ANSWER_EXISTING_VALUE');
    err = check(isIncomplete(), err, 'incomplete', 'ANSWER_INCOMPLETE');
    err = check(!isCloseReasonOk(), err, 'addCloseReason', 'ADD_CLOSE_REASON');
    err = check(!isDispositionOk(), err, 'disposition', 'MISSING_DISPOSITION');
    err = check(!isValidHttpUrl(), err, 'urlLink', 'URL_LINK_INVALID');

    return err;
  };

  const addAnswer = async () => {
    const { hasErrors, errors } = checkForErrors();
    setErrors(errors);

    if (!hasErrors) {
      if (!actualAnswer.value) {
        actualAnswer.value = camelCase(actualAnswer.label);
      }
      if (actualAnswer.selectedRuleField) {
        actualAnswer.selectedRule = [
          actualAnswer.selectedRuleField,
          actualAnswer.selectedRuleOperation,
          actualAnswer.selectedRuleValue,
        ];
      }
      if (!hasLink) {
        actualAnswer.urlLink = null;
      }
      actualAnswer.poolType = parseInt(actualAnswer.poolType);
      const next = steps.find(
        (s) => s.number?.toString() === actualAnswer.nextStep?.toString(),
      );
      if (next?.type !== 'endCall' && step.type !== 'completeReschedule') {
        actualAnswer.disposition = null;
      }
      onSaveAnswer(actualAnswer);
    }
  };

  const getErrors = () => {
    if (errors.length === 0) return null;
    return (
      <div className={classes.errors}>
        {errors.map((e) => (
          <div key={e.key}>{e.text}</div>
        ))}
      </div>
    );
  };

  const getSelectedStep = () => {
    const nextStep = actualAnswer.nextStep || '-1';
    return nextSteps.find((s) => s.value.toString() === nextStep.toString());
  };

  const getSelectedDisposition = () => {
    if (!actualAnswer.disposition) return null;
    const disposition = actualAnswer.disposition;
    return DISPOSITIONS.find((s) => s.value === disposition);
  };
  const getSelectedPoolType = () => {
    if (!actualAnswer.poolType) return null;
    const poolType = actualAnswer.poolType?.toString();
    return poolTypes.find((s) => s.value === poolType);
  };

  const getSelectedField = () => {
    if (!actualAnswer.selectedRule) return null;
    const rule = actualAnswer.selectedRule[0];
    return INQUIRY_FIELDS.find((s) => s.value === rule);
  };

  const getSelectedOperation = () => {
    if (!actualAnswer.selectedRule) return null;
    const rule = actualAnswer.selectedRule[1];
    return OPERATIONS.find((s) => s.value === rule);
  };

  const getSelectedAction = () => {
    if (!actualAnswer.action) return null;
    return ACTIONS.find((s) => s.value === actualAnswer.action);
  };

  const showAction = () => {
    if (step.type === 'contactInfo' || step.type === 'familyInfo') return null;
    return (
      <Dropdown
        items={ACTIONS}
        label={labels.ACTION}
        emptyLabel={labels.ACTION}
        selectedItem={getSelectedAction()}
        onChange={(value) => changeValue('action', value.value)}
      />
    );
  };
  const title = actualAnswer.answerId ? labels.EDIT_ANSWER : labels.NEW_ANSWER;

  const getSelectedReasonToClose = () => {
    if (!actualAnswer.closeReason) return null;
    const reason = actualAnswer.closeReason;
    return CLOSE_REASONS.find((s) => s.value.toString() === reason.toString());
  };
  const getSelectedValue = () => {
    return foundProperty.values.find((s) => s.value === answer.value);
  };

  const getAvailableAnswers = () => {
    const filledAnswers = step?.answers ? step.answers : [];
    const available = foundProperty.values.filter(
      (elem) => !filledAnswers.find(({ value }) => elem.value === value),
    );
    if (available.length === 0) return available;
    if (answer.value) available.push(getSelectedValue());
    return available;
  };

  const changeEnumAnswer = (value) => {
    setActualAnswer({
      ...actualAnswer,
      label: value.label,
      value: value.value,
    });
  };
  const enumAnswer = () => {
    return (
      <Dropdown
        items={getAvailableAnswers()}
        label={labels.VALUE}
        selectedItem={getSelectedValue()}
        onChange={(value) => changeEnumAnswer(value)}
      />
    );
  };

  const openAnswer = () => {
    return (
      <Fragment>
        <TextInput
          className={classes.input}
          id="label"
          label={labels.LABEL}
          variant="outlined"
          color="primary"
          name="label"
          value={actualAnswer.label || ''}
          onChange={(value) => changeValue('label', value)}
        />
      </Fragment>
    );
  };

  const isValidHttpUrl = () => {
    let url;
    if (!hasLink) return true;
    try {
      url = new URL(actualAnswer.urlLink);
    } catch (_) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  };

  const next = steps.find(
    (s) => s.number?.toString() === actualAnswer.nextStep?.toString(),
  );

  const showDispositions = () => {
    const nextIsEndCall = next?.type === 'endCall';
    const isCompleteReschedule = step?.type === 'completeReschedule';
    return nextIsEndCall || isCompleteReschedule;
  };

  const bodyComponent = (
    <Fragment>
      {getErrors()}
      <div className={classes.column}>
        {!foundProperty && openAnswer()}
        {foundProperty && foundProperty.type === 'enum' && enumAnswer()}
        {foundProperty && foundProperty.type === 'open' && openAnswer()}
        <TextInput
          className={classes.input}
          id="order"
          label={labels.ORDER}
          variant="outlined"
          color="primary"
          name="order"
          value={actualAnswer.order.toString()}
          onChange={(value) => changeValue('order', value, true)}
        />
        <div style={{ marginBottom: 19 }}>
          <Dropdown
            items={nextSteps}
            label={labels.NEXT_STEP}
            selectedItem={getSelectedStep()}
            onChange={(value) => changeValue('nextStep', value.value, true)}
          />
          <Typography>
            {labels.CURRENT_STEP.replace(
              '{STEP}',
              `${step.number}. ${step.name}`,
            )}
          </Typography>
        </div>
        {showDispositions() ? (
          <Dropdown
            items={DISPOSITIONS}
            label={labels.DISPOSITION}
            emptyLabel={labels.DISPOSITION}
            selectedItem={getSelectedDisposition()}
            onChange={(item) => changeValue('disposition', item.value)}
            isMandatory
          />
        ) : null}
      </div>
      <div className={classes.column}>
        {showAction()}
        <div className={classes.conditional_input_container}>
          <Dropdown
            items={INQUIRY_FIELDS}
            label={labels.FIELD}
            emptyLabel={labels.FIELD}
            selectedItem={getSelectedField()}
            onChange={(value) =>
              changeSelectedRule('selectedRuleField', value.value)
            }
          />
          <Dropdown
            items={OPERATIONS}
            label={labels.OPERATOR}
            emptyLabel={labels.OPERATOR}
            selectedItem={getSelectedOperation()}
            onChange={(value) =>
              changeSelectedRule('selectedRuleOperation', value.value)
            }
          />
          <TextInput
            className={classes.input}
            id="selectedRuleValue"
            label={labels.VALUE}
            variant="outlined"
            color="primary"
            name="selectedRuleValue"
            value={
              actualAnswer.selectedRule ? actualAnswer.selectedRule[2] : ''
            }
            onChange={(value) => changeSelectedRule('selectedRuleValue', value)}
          />
        </div>
        <Typography>{printSelectedRule()}</Typography>
        <div className={classes.switch_container}>
          <Switch
            label={labels.CLOSES_INQUIRY}
            checked={actualAnswer.closeInquiry || false}
            onChange={() => changeToggle('closeInquiry')}
          />
          {actualAnswer.closeInquiry && (
            <Dropdown
              className={classes.input_close_reason}
              label={labels.ClOSE_REASON}
              items={CLOSE_REASONS}
              selectedItem={getSelectedReasonToClose()}
              onChange={(item) => changeValue('closeReason', item.value)}
              isMandatory
            />
          )}
          <Switch
            label={labels.ADD_TO_DO_NOT_CALL}
            checked={actualAnswer.addToDoNotCall || false}
            onChange={() => changeToggle('addToDoNotCall')}
          />

          <Switch
            label={labels.CHANGE_DEFAULT_POOL_TYPE}
            checked={poolType}
            onChange={() => setPoolType(!poolType)}
          />
          {poolType && (
            <Dropdown
              className={classes.input_pool_type}
              label={labels.POOL_TYPE}
              items={poolTypes}
              selectedItem={getSelectedPoolType()}
              onChange={(item) => changeValue('poolType', item.value)}
              isMandatory
            />
          )}
          <Switch
            label={labels.LINK}
            checked={hasLink}
            onChange={() => setHasLink(!hasLink)}
          />
          {hasLink && (
            <TextInput
              className={classes.input}
              id="answerLink"
              label={labels.LINK}
              variant="outlined"
              color="primary"
              name="answerLink"
              value={actualAnswer.urlLink || ''}
              onChange={(value) => changeValue('urlLink', value)}
            />
          )}
        </div>
      </div>
      <div className={classes.column}>
        <Typography component="legend">{labels.ANSWER_SHOWS}</Typography>
        {availableSteps.map((step) => (
          <Checkbox
            key={`sq_${step.number}`}
            label={`${step.order}. ${step.name}`}
            checked={
              actualAnswer.shows
                ? actualAnswer.shows.includes(step.number)
                : false
            }
            onChange={() => toggleShowHideQuestion('shows', step.number)}
          />
        ))}
      </div>
      <div className={classes.column}>
        <Typography component="legend">{labels.ANSWER_HIDES}</Typography>
        {availableSteps.map((step) => (
          <Checkbox
            key={`hq_${step.number}`}
            label={`${step.order}. ${step.name}`}
            checked={
              actualAnswer.hides
                ? actualAnswer.hides.includes(step.number)
                : false
            }
            onChange={() => toggleShowHideQuestion('hides', step.number)}
          />
        ))}
      </div>
    </Fragment>
  );

  return (
    <ScriptBuilderModal
      title={title}
      iconComponent={null}
      bodyComponent={bodyComponent}
      onSave={addAnswer}
      onClose={onCloseAnswerDialog}
      open={open}
      labels={labels}
    />
  );
};

AnswerDialog.propTypes = {
  onClose: PropTypes.func,
  step: StepPropType,
  steps: PropTypes.arrayOf(StepPropType),
  onSaveAnswer: PropTypes.func,
  open: PropTypes.bool,
  answer: AnswerPropType,
  labels: PropTypes.object,
  properties: PropTypes.array,
  catalogs: PropTypes.object,
};

export default AnswerDialog;
