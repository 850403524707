import Five9CTI from './Five9CTI-1.0.0';
import withConfig from 'utils/withConfig';

class DialerService {
  constructor() {
    this.initiate();
  }

  setTestNumber(testNumber = null) {
    this.testNumber = testNumber;
  }

  initiate() {
    this.five9cti = null;

    const isDialerServiceEnabled =
      withConfig('IS_DIALER_SERVICE_ENABLED') ?? true;
    if (isDialerServiceEnabled) {
      this.connectToCtiService();
    }
  }

  async connectToCtiService() {
    const ctiurl = 'http://localhost:8080/agent/v2';
    try {
      this.five9cti = new Five9CTI(ctiurl);
      if (this.five9cti) {
        if (!this.five9cti.Connected) {
          this.five9cti.setDebug(true);
          this.five9cti.connect();
          this.five9cti.setDebug(false);
          return {
            successFlag: true,
          };
        }
      } else {
        return {
          successFlag: false,
          error: 'Could not connect to Five9 Cti services',
        };
      }
    } catch (error) {
      console.error(error);
      if (this.five9cti) {
        this.five9cti.setDebug(false);
      }
      return {
        successFlag: false,
        error: error.message,
      };
    }
  }

  async validateStatus() {
    if (!this.five9cti || !this.five9cti.Connected) {
      return {
        successFlag: false,
        error: 'Could not connect to Five9 Cti services',
      };
    }
    const status = await this.five9cti.getStatus();
    if (!status) {
      return {
        successFlag: false,
        error: 'Could not connect to Five9 Cti services',
      };
    }
    if (!status.loggedIn) {
      return {
        successFlag: false,
        error: 'You are not logged in to the Five9 Toolbar',
      };
    }
    if (status.onCall) {
      return {
        successFlag: false,
        error: 'You are already on a call',
      };
    }
    if (status.callConnected) {
      return {
        successFlag: false,
        error: 'You are still connected on another call',
      };
    }
    return {
      successFlag: true,
    };
  }

  async dialCall(phoneNumber, username) {
    var result = await this.validateStatus();
    if (!result.successFlag) {
      return { successFlag: false, error: result.error };
    }
    if (phoneNumber === undefined || phoneNumber == null) {
      return { successFlag: false, error: 'No phone number was provided' };
    }
    if (phoneNumber.replace(/\D/g, '').length < 10) {
      return {
        successFlag: false,
        error: 'Invalid phone number, has fewer than 10 digits',
      };
    }
    var numberToDial =
      this.testNumber?.length === 10 ? this.testNumber : phoneNumber;

    const campaignIdResponse = await this.getCampaignIdForUser(username);
    let campaignId = campaignIdResponse ? campaignIdResponse : 0;

    var response = await this.five9cti.makeCall(
      numberToDial,
      campaignId,
      false,
    );

    if (response === undefined) {
      return { successFlag: false, error: 'Error dialing number' };
    } else if (response !== '') {
      return { successFlag: false, error: `Error dialing number: ${response}` };
    }
    return { successFlag: true };
  }

  async getCampaignIdForUser(username) {
    if (this.five9cti) {
      var response = await this.five9cti.getCampaigns();
      // in case of error response object is of type string so added additional check here
      if (Array.isArray(response)) {
        const campaigns = response;
        if (campaigns.length > 0) {
          const userCampaign = campaigns.find((campaign) =>
            campaign.name.includes(username),
          );
          return userCampaign ? userCampaign.id : null;
        }
      }
      return null;
    }
  }
}

export const dialerService = new DialerService();
