import inquiryService from '../../services/callCenter/inquiryService';
import mandrillClient from '../../utils/mandrillClient';
import withConfig from '../../utils/withConfig';

const sendPipedaEmail = async (
  inquiry,
  labels,
  currentUser,
  createInquiryPipedaEmailLog,
) => {
  const contactFullName = inquiryService.getInfo(
    inquiry,
    'contactFullName',
    labels,
  );
  const recipient = [
    { email: inquiry.inquiryContact.email, name: contactFullName },
  ];
  const sendEmailResponse = await mandrillClient.sendEmailThroughTemplate(
    withConfig('MANDRILL_SEND_MESSAGE_THROUGH_TEMPLATE'),
    null,
    currentUser,
    withConfig('PIPEDA_EMAIL_TEMPLATE'),
    [],
    recipient,
    inquiry,
  );
  const statusEmailResponse =
    (sendEmailResponse.data &&
      sendEmailResponse.data[0] &&
      sendEmailResponse.data[0].status) ||
    'Unexpected error';
  const rejectedReason =
    sendEmailResponse.data &&
    sendEmailResponse.data[0] &&
    sendEmailResponse.data[0].reject_reason;
  if (sendEmailResponse.code && sendEmailResponse.code === 421) {
    createInquiryPipedaEmailLog({
      variables: {
        inquiryId: inquiry.inquiryId,
        recipientEmail: inquiry.inquiryContact.email,
        status: statusEmailResponse,
        rejectedReason:
          (sendEmailResponse.error &&
            sendEmailResponse.error.response &&
            sendEmailResponse.error.response.data &&
            sendEmailResponse.error.response.data.message) ||
          'Unexpected error',
      },
    });
  }
  if (sendEmailResponse.data) {
    createInquiryPipedaEmailLog({
      variables: {
        inquiryId: inquiry.inquiryId,
        recipientEmail: inquiry.inquiryContact.email,
        status: statusEmailResponse,
        ...(rejectedReason && {
          rejectedReason: rejectedReason,
        }),
      },
    });
  }
};

const emailService = {
  sendPipedaEmail,
};

export default emailService;
