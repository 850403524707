import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => ({
  filter_content: {
    display: 'flex',
    marginLeft: '180px',
    marginTop: '16px',
    justifyContent: 'flex-end',
  },
  amenity_icon: {
    '& > svg': {
      fontSize: '24px',
      marginLeft: '15px',
    },
  },
  more_container: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '18px',
  },
}));

export { useStyles };
