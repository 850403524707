import PropTypes from 'prop-types';

export const inquiryPropType = PropTypes.shape({
  inquiryId: PropTypes.number,
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  inquiryData: PropTypes.shape({
    rawData: PropTypes.object,
  }),
  inquiryContact: PropTypes.shape({
    oneId: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    lastContactedAt: PropTypes.number,
  }),
  resident1FirstName: PropTypes.string,
  resident1LastName: PropTypes.string,
  budgetMax: PropTypes.number,
});

export const originalFormStepAnswerPropType = PropTypes.shape({
  value: PropTypes.any,
  label: PropTypes.string,
  message: PropTypes.string,
  tooltip: PropTypes.string,
  urlSlug: PropTypes.string,
  nextQuestion: PropTypes.number,
});

export const originalFormStepPropType = PropTypes.shape({
  type: PropTypes.string,
  name: PropTypes.string,
  number: PropTypes.number,
  message: PropTypes.string,
  topText: PropTypes.string,
  extraInfo: PropTypes.object,
  question: PropTypes.string,
  answers: PropTypes.arrayOf(originalFormStepAnswerPropType),
});

export const formStepAnswerPropType = PropTypes.shape({
  value: PropTypes.any,
  label: PropTypes.string,
  nextQuestion: PropTypes.number,
  extraInfo: PropTypes.object,
});

export const formStepPropType = PropTypes.shape({
  type: PropTypes.string,
  name: PropTypes.string,
  number: PropTypes.number,
  extraInfo: PropTypes.object,
  question: PropTypes.string,
  answers: PropTypes.arrayOf(formStepAnswerPropType),
  answer: formStepAnswerPropType,
});

export const formSummaryAnswerPropType = PropTypes.shape({
  value: PropTypes.string,
  userInputValues: PropTypes.object,
  closeInquiry: PropTypes.bool,
  addToDoNotCall: PropTypes.bool,
});

export const formSummaryStepPropType = PropTypes.shape({
  number: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  answer: formSummaryAnswerPropType.isRequired,
});
