import React from 'react';
import { useStyles } from './ScreeningNotes.style';
import PropTypes from 'prop-types';
import Typography from 'common/Typography/Typography';
import { format } from 'common/utils/dateFormat';
import TextInput from 'common/TextInput/TextInput';

const SCREENING_NOTES_CODE = 'FAMU';

const ScreeningNotes = ({ notes, labels, editMode }) => {
  const classes = useStyles();
  const screeningNotes = (notes || []).filter(
    (note) => note.code === SCREENING_NOTES_CODE,
  );

  const getContent = (notes) => {
    return notes.map((note, key) => (
      <div key={key}>
        <Typography>{`${note.createdBy}: ${note.note} (${format(
          note.createdAt,
          'MMM DD, YYYY: HH:mm a',
        )})`}</Typography>
      </div>
    ));
  };

  const getDisabledContent = (notes) => {
    const text = notes.reduce(
      (notesText, currentNote) =>
        `${notesText}${currentNote.createdBy}: ${currentNote.note} (${format(
          currentNote.createdAt,
          'MMM DD, YYYY: HH:mm a',
        )})\n`,
      '',
    );
    return <TextInput multiline disabled value={text} />;
  };
  if (screeningNotes.length === 0) {
    return <></>;
  }
  return (
    <div className={classes.screening_notes_container}>
      <div className={classes.header}>
        <Typography level="small" bold>
          {labels.NOTES_FROM_SCREENING}
        </Typography>
      </div>
      {!editMode && (
        <div className={classes.notes_container}>
          {getContent(screeningNotes)}
        </div>
      )}
      {editMode && (
        <div className={classes.notes_container}>
          {getDisabledContent(screeningNotes)}
        </div>
      )}
    </div>
  );
};

ScreeningNotes.propTypes = {
  labels: PropTypes.object.isRequired,
  editMode: PropTypes.bool,
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      note: PropTypes.string,
      createdBy: PropTypes.string,
      code: PropTypes.string,
      createdAt: PropTypes.number,
    }),
  ),
};

ScreeningNotes.defauldProps = {
  editMode: false,
};

export default ScreeningNotes;
