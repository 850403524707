import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  quota_container: {
    height: theme.spacing(18),
    boxShadow: `0px 0px 8px ${theme.palette.light.boxShadow}`,
    padding: theme.spacing(3),
    borderRadius: '4px',
    backgroundColor: theme.palette.light.white,
  },
  quota_header_container: {
    display: 'flex',
  },
  metric_header_title: {
    fontWeight: 'bold',
    order: 1,
    '& p, div': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  month_to_date: {
    textAlign: 'right',
    order: 2,
    verticalAlign: 'top',
    '& p, div': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  rate: {
    '& h2': {
      margin: `${theme.spacing(2)}px 0 0 0`,
    },
  },
  bar_container: {
    position: 'relative',
  },
  bar_empty: {
    position: 'absolute',
    width: '100%',
    height: theme.spacing(2),
    backgroundColor: theme.palette.light.platinum2,
    borderRadius: '4px',
  },
  bar_filled: {
    width: (progress) => `${progress}%`,
    position: 'absolute',
    height: theme.spacing(2),
    backgroundColor: theme.palette.neutral.cosmicCobalt3,
    borderRadius: '4px 0 0 4px',
  },
  line: {
    left: (progress) => `${progress}%`,
    width: '2px',
    height: theme.spacing(3),
    position: 'absolute',
    backgroundColor: theme.palette.neutral.cosmicCobalt3,
  },
  label_container: {
    position: 'relative',
    marginTop: theme.spacing(3),
  },
  start_label: {
    position: 'absolute',
    left: 0,
    '& p, div': {
      margin: 0,
    },
  },
  mtd_label: {
    position: 'absolute',
    left: (progress) => `calc(${progress}% - 28px)`,
    '& p, div': {
      margin: 0,
      fontSize: '12px',
    },
  },
  mtd_label_filled: {
    color: theme.palette.light.white,
  },
  mtd_label_empty: {
    color: theme.palette.dark.eerieBlack1,
  },
  mtd_almost_at_limit_label: {
    '& p, div': {
      color: theme.palette.light.platinum5,
    },
  },
  end_label: {
    position: 'absolute',
    color: theme.palette.dark.eerieBlack1,
    left: 'calc(100% - 15px)',
    '& p, div': {
      margin: 0,
    },
  },
  quotas_footer_container: {
    marginTop: theme.spacing(7),
    position: 'relative',
  },
  quotas_footer: {
    borderTop: `1px solid ${theme.palette.light.platinum1}`,
    position: 'absolute',
    left: -theme.spacing(3),
    width: `calc(100% + ${theme.spacing(6)}px)`,
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    '& p, div': {
      color: theme.palette.dark.eerieBlack5,
      margin: 0,
      marginTop: '4px',
    },
  },
  quota_flag_container: {
    position: 'absolute',
    right: 0,
    top: -theme.spacing(6),
  },
  points_in_progressive_bar: {
    position: 'absolute',
    left: (progress) => `calc(${progress}% - 5px)`,
    '& p, div': {
      margin: 0,
    },
  },
  clickable_container: {
    cursor: 'pointer',
    display: 'flow-root',
  },
}));

export { useStyles };
