import gql from 'graphql-tag';

export default gql`
  mutation(
    $familyFileId: Int!
    $familyDesiredLocations: [FamilyDesiredLocationInput!]!
    $oneId: String
  ) {
    upsertFamilyDesiredLocations(
      input: {
        familyFileId: $familyFileId
        familyDesiredLocations: $familyDesiredLocations
        oneId: $oneId
      }
    ) {
      familyDesiredLocationId
      isPrimary
      city
      state
      zip
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;
