import React, { createContext, useContext } from 'react';

const LabelContext = createContext({});

const withLabels = (WrappedComponent) => {
  const ComponentWithLabels = (props) => {
    const labels = useContext(LabelContext);
    return <WrappedComponent {...props} labels={labels} />;
  };
  return ComponentWithLabels;
};

export default LabelContext;
export { withLabels };
