export const STATUS_DETAILS = {
  ATTEMPTED: 'ATTEMPTED',
  CONNECTED: 'CONNECTED',
  CONTACTED: 'CONTACTED',
  TRANSFERRED: 'TRANSFERRED',
  DISCONNECTED: 'DISCONNECTED',
};

export const telephonyEventReasons = {
  DISCONNECTED_BY_CALLER: 'DISCONNECTED_BY_CALLER',
  DISCONNECTED_BY_AGENT: 'DISCONNECTED_BY_AGENT',
  CONFERENCE_PARTICIPANT_ADDED: 'CONFERENCE_PARTICIPANT_ADDED',
};

export const familyFileStatus = {
  OPEN: 'Open',
  CLOSED: 'Closed',
  MOVE_IN: 'Move-In',
};
