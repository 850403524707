import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'common/Card/Card';
import Container from 'common/Container/Container';
import Divider from 'common/Divider/Divider';
import CirclePlus from 'common/Icons/basic/CirclePlus';
import User from 'common/Icons/basic/User';
import CallPhone from 'common/Icons/call/CallPhone';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import Tag from 'common/Tag/Tag';
import Typography from 'common/Typography/Typography';
import { withLabels } from 'context/LabelContext';
import { mapAudience } from 'services/callCenter/audienceService';
import formService from 'services/callCenter/formService';
import inquiryService from 'services/callCenter/inquiryService';
import { selectCatalogs } from 'stateManagement/callcenter/catalogs/reducers/catalogsReducer';
import { updateFormSteps } from 'stateManagement/callcenter/formChiclets/actions/formChicletsActions';
import { formatPhoneWithParenthesis } from 'utils/phoneFormat';
import InfoRow from './InfoRow';
import { formStepPropType, inquiryPropType } from './inquiryPropTypes';
import { useStyles } from './NewInquiry.style';
import CopyClipboard from 'common/CopyClipboard/CopyClipboard';
import cloneDeep from 'lodash.clonedeep';

const UNKNOWN = 'Unknown';

const SideBar = ({
  inquiry,
  formSteps,
  formChiclets,
  labels,
  displayOptionalChiclets,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { catalogs } = useSelector(selectCatalogs);

  const resident1FullName = inquiryService.getInfo(
    inquiry,
    'resident1FullName',
    labels,
  );
  const contactFullName = inquiryService.getInfo(
    inquiry,
    'contactFullName',
    labels,
  );
  const source = inquiryService.getInfo(inquiry, 'source', labels);
  const subSourceId = inquiryService.getInfo(inquiry, 'subSourceId', labels);
  const submissionUrl = inquiryService.getInfo(
    inquiry,
    'submissionUrl',
    labels,
  );
  const getSubSourceAndURL = () => {
    const tooltipText = `${subSourceId} ${submissionUrl}`;
    return tooltipText;
  };

  const relation = inquiryService.getInfo(
    inquiry,
    'relation',
    labels,
    catalogs?.familyRelationships,
  );

  const audience = mapAudience(inquiry?.audienceKey);

  const includeStep = (stepToInclude) => {
    const newFormSteps = cloneDeep(formSteps);
    const updatedSteps = newFormSteps.map((step) => {
      if (step.hidden && step.number === stepToInclude.number) {
        step.hidden = false;
      }
      return step;
    });
    const newSteps = formService.updateStepStates(updatedSteps, {}, 'CURRENT');
    dispatch(updateFormSteps(newSteps));
  };

  const ShowSourceLabel = () => {
    return source && subSourceId === '' && submissionUrl === '' ? (
      <Typography
        color={source === UNKNOWN ? 'disabled' : 'eerieBlack2'}
        className={classes.right_spaced}
        level="small"
      >
        {source}
      </Typography>
    ) : (
      <SmartTooltip title={getSubSourceAndURL()}>
        <Typography
          color={source === UNKNOWN ? 'disabled' : 'eerieBlack2'}
          className={classes.right_spaced}
          level="small"
        >
          {source}
        </Typography>
      </SmartTooltip>
    );
  };

  return (
    <>
      <Typography bold className={classes.script_name}>
        {formChiclets?.name}
      </Typography>
      <Card
        title={labels.PRIMARY_CONTACT}
        headerColor="cosmicCobalt2"
        data-testid="contact-card"
        icon={<CallPhone />}
      >
        <div className={classes.info_row} data-testid="contact-name">
          <div className={classes.name_row}>
            <Typography
              level="h4"
              color={contactFullName === UNKNOWN ? 'disabled' : 'eerieBlack2'}
              bold
            >
              {contactFullName}
            </Typography>
            &nbsp;
            <Typography
              level="small"
              color={relation === UNKNOWN ? 'disabled' : 'eerieBlack4'}
            >
              {`(${relation})`}
            </Typography>
          </div>
        </div>
        <div className={classes.info_row} data-testid="one-id">
          <div className={classes.name_row}>
            <Typography
              level="tiny"
              color="eerieBlack5"
              className={classes.right_spaced}
            >
              {labels.ONE_ID}:
            </Typography>
            <Typography level="tiny" bold color="eerieBlack1">
              {inquiryService.getInfo(inquiry, 'oneId', labels)}
            </Typography>
            {inquiryService.getInfo(inquiry, 'oneId', labels) !== UNKNOWN && (
              <CopyClipboard
                color="#B5C3F1"
                label={labels.ONE_ID}
                textToCopy={inquiryService.getInfo(inquiry, 'oneId', labels)}
              ></CopyClipboard>
            )}
          </div>
        </div>
        <div className={classes.two_cols}>
          <InfoRow
            title={labels.SOURCE}
            info={<ShowSourceLabel />}
            testId="source"
          />
          <InfoRow
            title={labels.PRIMARY_PHONE}
            info={formatPhoneWithParenthesis(
              inquiryService.getInfo(inquiry, 'phone', labels),
            )}
            icon={<CallPhone />}
            testId="phone"
          />
        </div>
        <InfoRow
          title={labels.EMAIL}
          info={inquiryService.getInfo(inquiry, 'email', labels)}
          testId="email"
        />
      </Card>
      <Card
        title={labels.RESIDENT_INFORMATION}
        headerColor="cosmicCobalt2"
        data-testid="resident-card"
        icon={<User />}
      >
        <div className={classes.info_row} data-testid="resident-name">
          <Typography
            level="h4"
            color={resident1FullName === UNKNOWN ? 'disabled' : 'eerieBlack2'}
            bold
          >
            {resident1FullName}
          </Typography>
        </div>

        <div className={classes.info_row} data-testid="inquiry-id">
          <div className={classes.name_row}>
            <Typography
              level="tiny"
              color="eerieBlack5"
              className={classes.right_spaced}
            >
              {labels.INQUIRY_ID}:
            </Typography>
            <Typography level="tiny" bold color="eerieBlack1">
              {inquiryService.getInfo(inquiry, 'inquiryId', labels)}
            </Typography>
            {inquiryService.getInfo(inquiry, 'inquiryId', labels) !==
              UNKNOWN && (
              <CopyClipboard
                label={labels.INQUIRY_ID}
                textToCopy={inquiryService
                  .getInfo(inquiry, 'inquiryId', labels)
                  ?.toString()}
              ></CopyClipboard>
            )}
          </div>
        </div>

        <div className={classes.info_row} data-testid="familyfile-id">
          <div className={classes.name_row}>
            <Typography
              level="tiny"
              color="eerieBlack5"
              className={classes.right_spaced}
            >
              {labels.FAMILY_FILE_ID}:
            </Typography>
            <Typography level="tiny" bold color="eerieBlack1">
              {inquiryService.getInfo(inquiry, 'familyFileId', labels)}
            </Typography>
            {inquiryService.getInfo(inquiry, 'familyFileId', labels) !==
              UNKNOWN && (
              <CopyClipboard
                label={labels.FAMILY_FILE_ID}
                textToCopy={inquiryService
                  .getInfo(inquiry, 'familyFileId', labels)
                  ?.toString()}
              ></CopyClipboard>
            )}
          </div>
        </div>

        <InfoRow
          title={labels.PREFERRED_LOCATIONS}
          info={inquiryService.getInfo(inquiry, 'preferredLocation', labels)}
          testId="preferred-location"
          bold
        />
        {audience && (
          <Tag
            className={classes.audience_tag}
            text={labels[audience.label]}
            color={audience.urgent ? 'smokyTopaz5' : 'cosmicCobalt5'}
            icon={audience.icon}
          />
        )}

        <div className={classes.two_cols}>
          <InfoRow
            title={labels.EXPECTED_MOVE_IN}
            info={inquiryService.getInfo(
              inquiry,
              'expectedMoveIn',
              labels,
              catalogs?.moveDateRanges,
            )}
            testId="expected-movein"
            bold
          />
          <InfoRow
            title={labels.LIKELIHOOD}
            info={inquiryService.getInfo(inquiry, 'likelihood', labels)}
            testId="likelihood"
            bold
            infoRightMargin={60}
          />
        </div>
      </Card>
      <Divider className={classes.divider} color="platinum1" spacing={4} />

      <Container>
        {formSteps
          .filter(
            (step) =>
              displayOptionalChiclets === true &&
              step.extraFields &&
              step.extraFields.isOptional === true &&
              step.hidden === true,
          )
          .map((step) => {
            return (
              <div
                className={classes.optional_chiclet}
                onClick={() => includeStep(step)}
                key={step.number}
              >
                <Typography color="eerieBlack1" level="small">
                  <b>{step.name}</b>
                </Typography>
                <SmartTooltip title="Optional" placement="bottom">
                  <CirclePlus />
                </SmartTooltip>
              </div>
            );
          })}
      </Container>
    </>
  );
};

SideBar.propTypes = {
  labels: PropTypes.object,
  inquiry: inquiryPropType,
  formSteps: PropTypes.arrayOf(formStepPropType),
  formChiclets: PropTypes.object,
  displayOptionalChiclets: PropTypes.bool,
};

export default withLabels(SideBar);
