import React from 'react';
import { withLabels } from 'context/LabelContext';
import AboveGoal from './AboveGoal';
import AlmostAtGoal from './AlmostAtGoal';
import FarFromGoal from './FarFromGoal';
import PropTypes from 'prop-types';

const MetricBody = ({ percentageAccomplished, metricLimits, size, labels }) => {
  const toReachTheGoal = metricLimits.goal - percentageAccomplished;

  const isAboveGoal = () => {
    return percentageAccomplished >= metricLimits.goal;
  };

  const isAlmostAtGoal = () => {
    return (
      percentageAccomplished >= metricLimits.low &&
      percentageAccomplished <= metricLimits.high
    );
  };

  const isFarFromGoal = () => {
    return (
      percentageAccomplished >= metricLimits.lowest &&
      percentageAccomplished < metricLimits.low
    );
  };

  if (isAboveGoal()) {
    return (
      <AboveGoal
        percentageAccomplished={percentageAccomplished}
        labels={labels}
        size={size}
      />
    );
  }

  if (isAlmostAtGoal()) {
    return (
      <AlmostAtGoal
        percentageAccomplished={percentageAccomplished}
        toReachTheGoal={toReachTheGoal}
        labels={labels}
        size={size}
      />
    );
  }

  if (isFarFromGoal()) {
    return (
      <FarFromGoal
        percentageAccomplished={percentageAccomplished}
        toReachTheGoal={toReachTheGoal}
        labels={labels}
        size={size}
      />
    );
  }
};

MetricBody.propTypes = {
  percentageAccomplished: PropTypes.number,
  metricLimits: PropTypes.shape({
    low: PropTypes.number.isRequired,
    goal: PropTypes.number.isRequired,
    high: PropTypes.number.isRequired,
    lowest: PropTypes.number.isRequired,
  }),
  size: PropTypes.string,
  labels: PropTypes.object,
};

export default withLabels(MetricBody);
