import { STATUS_DETAILS } from 'services/callCenter/enums';
import { warmTransferStates } from 'services/callCenter/warmTransferService';
import {
  CANCEL_WARM_TRANSFER,
  RESET_WARM_TRANSFER_STATE,
  SEND_FAMILY_FILE_EVENT,
  SET_CANCEL_WARM_TRANSFER_ERROR,
  SET_FAMILY_CONNECTED,
  SET_FAMILY_DISCONNECTED,
  SET_FAMILY_DISCONNECTED_WT,
  SET_DUPLICATE_FAMILY_FILE,
  SET_SLA_CONNECTED,
  SET_SLA_DISCONNECTED,
  SET_WARM_TRANSFER_COMPLETED,
  UPDATE_FIND_BEST_SLA_ATTEMPTS_COUNT,
  UPDATE_SLA_CONTACT_ATTEMPTS_COUNT,
  UPDATE_WARM_TRANSFER_ATTEMPTS_COUNT,
  UPDATE_WARM_TRANSFER_STATE,
  SET_WARM_TRANSFER_TYPE,
  RESTART_WARM_TRANSFER,
} from '../actions/warmTransferActions';

const initialState = {
  familyState: null,
  slaState: null,
  warmTransferState: warmTransferStates.NOT_STARTED,
  warmTransferType: null,
  slaContactAttemptsCount: 1,
  warmTransferAttemptsCount: 1,
  shouldSendFamilyFileEvent: false,
  warmTransferCancel: false,
  warmTransferCancelError: false,
  isWarmTransferCompleted: false,
  duplicateFamilyFile: {
    familyFileStatus: null,
    hasDuplicate: false,
    phoneProviderUsername: null,
  },
  findBestSlaAttemptsCount: 0,
};

const warmTransferReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_WARM_TRANSFER_STATE:
      return initialState;

    case RESTART_WARM_TRANSFER:
      return {
        ...state,
        warmTransferType: null,
      };
    case UPDATE_WARM_TRANSFER_STATE:
      return { ...state, warmTransferState: action.payload.warmTransferState };

    case SET_WARM_TRANSFER_TYPE:
      return { ...state, warmTransferType: action.payload.warmTransferType };

    case SET_FAMILY_CONNECTED:
      return { ...state, familyState: STATUS_DETAILS.CONNECTED };
    case SET_FAMILY_DISCONNECTED:
      return { ...state, familyState: STATUS_DETAILS.DISCONNECTED };
    case SET_FAMILY_DISCONNECTED_WT:
      return { ...state, familyState: STATUS_DETAILS.DISCONNECTED };

    case SET_SLA_CONNECTED:
      return { ...state, slaState: STATUS_DETAILS.CONNECTED };
    case SET_SLA_DISCONNECTED:
      return { ...state, slaState: STATUS_DETAILS.DISCONNECTED };

    case UPDATE_WARM_TRANSFER_ATTEMPTS_COUNT:
      return {
        ...state,
        warmTransferAttemptsCount: state.warmTransferAttemptsCount + 1,
      };
    case UPDATE_SLA_CONTACT_ATTEMPTS_COUNT:
      return {
        ...state,
        slaContactAttemptsCount: state.slaContactAttemptsCount + 1,
      };
    case SEND_FAMILY_FILE_EVENT:
      return {
        ...state,
        shouldSendFamilyFileEvent: true,
      };
    case CANCEL_WARM_TRANSFER:
      return {
        ...state,
        warmTransferCancel: true,
      };
    case SET_CANCEL_WARM_TRANSFER_ERROR:
      return {
        ...state,
        warmTransferCancelError: action.payload.hasError,
      };
    case SET_WARM_TRANSFER_COMPLETED:
      return {
        ...state,
        isWarmTransferCompleted: true,
      };
    case SET_DUPLICATE_FAMILY_FILE:
      return {
        ...state,
        duplicateFamilyFile: {
          familyFileStatus: action.payload.familyFileStatus,
          hasDuplicate: action.payload.hasDuplicate,
          phoneProviderUsername: action.payload.phoneProviderUsername,
        },
      };
    case UPDATE_FIND_BEST_SLA_ATTEMPTS_COUNT:
      return {
        ...state,
        findBestSlaAttemptsCount: state.findBestSlaAttemptsCount + 1,
      };
    default:
      return state;
  }
};

export const selectIsFamilyConnected = (state) =>
  state?.callcenter?.warmTransfer?.familyState === STATUS_DETAILS.CONNECTED;

export const selectIsSlaConnected = (state) =>
  state?.callcenter?.warmTransfer?.slaState === STATUS_DETAILS.CONNECTED;

export const selectWarmTransferState = (state) =>
  state?.callcenter?.warmTransfer?.warmTransferState;

export const selectWarmTransferType = (state) =>
  state?.callcenter?.warmTransfer?.warmTransferType;

export const selectWarmTransferAttempt = (state) =>
  state?.callcenter?.warmTransfer?.warmTransferAttemptsCount;

export const selectSlaContactAttemptsCount = (state) =>
  state?.callcenter?.warmTransfer?.slaContactAttemptsCount;

export const selectShouldSendFamilyFileEvent = (state) =>
  state?.callcenter?.warmTransfer?.shouldSendFamilyFileEvent;

export const selectWarmTransferCancel = (state) =>
  state?.callcenter?.warmTransfer?.warmTransferCancel;

export const selectWarmTransferCancelError = (state) =>
  state?.callcenter?.warmTransfer?.warmTransferCancelError;

export const selectIsWarmTransferCompleted = (state) =>
  state?.callcenter?.warmTransfer?.isWarmTransferCompleted;

export const selectDuplicateFamilyFile = (state) =>
  state?.callcenter?.warmTransfer?.duplicateFamilyFile;

export const selectFindBestSlaAttemptsCount = (state) =>
  state?.callcenter?.warmTransfer?.findBestSlaAttemptsCount;

export default warmTransferReducer;
