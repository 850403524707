import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    dashboardContainer: {
      display: 'flex',
      flexDirection: 'row',
      order: 1,
      justifyContent: 'left',
      marginBottom: theme.spacing(2),
    },
    contact_cell: {
      display: 'flex',
      '& > p': {
        marginRight: '4px',
      },
    },
    header_title: {
      fontSize: theme.spacing(3),
      marginBottom: theme.spacing(2),
      paddingTop: theme.spacing(3),
      '& > div': {
        paddingTop: '0',
      },
    },
    header_title_icon: {
      display: 'inline',
    },
    chevron: {
      marginLeft: '11px',
      borderRadius: '50%',
      backgroundColor: theme.palette.neutral.cosmicCobalt3,
      color: theme.palette.light.platinum5,
      padding: '1px',
      marginBottom: '-3px',
    },
    header_result_title: {
      marginTop: '27px',
      marginBottom: theme.spacing(2),
      '& > div': {
        paddingTop: '0',
      },
    },
    header_result_sub_title: {
      display: 'flex',
    },
    filter_row: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    filter_row_date_range: {
      display: 'flex',
      '& > *': {
        marginRight: '12px',
      },
    },
    filter_container: {
      backgroundColor: theme.palette.light.platinum5,
      paddingLeft: '24px',
      paddingRight: '54px',
      paddingTop: '36px',
    },
    filter_button: {
      display: 'flex',
      justifyContent: 'flex-end',
      /* width: 250px; */
      /* justify-items: right; */
      paddingBottom: '30px',
    },
    button_clear: {
      paddingRight: '24px',
    },
    filter_label: {
      // marginRight: '20px',
      marginTop: '15px',
    },
    filter_input: {
      width: '240px',
    },
    names_container: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '384px',
      marginBottom: '27px',
    },
    names_container_date: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '425px',
    },
    dashboardText: {
      paddingLeft: theme.spacing(1),
    },

    main_container: {
      backgroundColor: theme.palette.light.platinum1,
      padding: '32px',
      overflow: 'auto',
      flexGrow: 1,
      height: 'calc(100vh - 136px)',
      marginTop: theme.spacing(9),
    },
    home_table_header: {
      '& $icon svg': {
        fontSize: '15px',
      },
      height: '20px',
      alignItems: 'center',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: theme.palette.light.platinum5,
      },
      padding: '12px',
    },
    home_table: {
      marginTop: '12px',
      height: 'calc(100vh -20px)',
    },
    home_table_row: {
      verticalAlign: 'top',
    },
    clipboard_text: {
      color: theme.palette.neutral.cosmicCobalt3,
      fontSize: '24px',
    },
    settings: {
      color: theme.palette.neutral.cosmicCobalt3,
      paddingRight: '20px',
      fontSize: '24px',
    },

    action: {
      display: 'flex',
    },
    contact_info: {
      display: 'flex',
    },
    last_contacted: {
      display: 'block',
      verticalAlign: 'top',
      '& .contact_action': {
        display: 'inline-flex',
        '& svg': {
          marginLeft: '-1.25em',
          marginTop: '.2em',
          paddingRight: '.25em',
          color: theme.palette.correction.smokyTopaz2,
          fill: theme.palette.correction.smokyTopaz2,
          '&.green': {
            color: theme.palette.positive.wintergreenDream2,
            fill: theme.palette.positive.wintergreenDream2,
          },
        },
      },
    },
    pagination_row: {
      height: '76px',
      '&:hover': {
        backgroundColor: `${theme.palette.light.platinum5} !important`,
      },
    },
    pagination_row_without_result: {
      height: '600px',
      textAlign: 'center',
    },
    pager: {
      marginTop: [['0'], '!important'],
      textAlign: 'center',
    },

    empty_row: {
      height: '55px',
      '&:hover': {
        backgroundColor: `${theme.palette.light.platinum5} !important`,
      },
    },
    page_size: {
      width: theme.spacing(10),
      float: 'right',
      '& > div:first-child': {
        marginLeft: '0',
      },
    },
    page_size_container: {
      marginTop: '14px',
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '12px',
    },
    title_hide_icon: {
      display: 'flex',
    },
    empty_page_text: {
      marginBottom: theme.spacing(2),
    },
    error_message: {
      display: 'flex',
      justifyContent: 'center',
    },
    link: {
      color: `${theme.palette.dark.eerieBlack1} !important`,
    },
    toast_message: {
      marginTop: '24px',
      marginBottom: '26px',
    },
    alert_description: {
      padding: '8px 16px',
      color: theme.palette.dark.eerieBlack5,
    },
    close_reason: {
      width: '280px',
      marginTop: theme.spacing(3),
    },
    close_reason_dropdown: {
      border: `1px solid ${theme.palette.neutral.cosmicCobalt5}`,
      textAlign: 'left',
      '& div': {
        padding: '9px 16px',
        color: theme.palette.dark.eerieBlack1,
        '&:hover': {
          backgroundColor: theme.palette.neutral.dropdown,
          color: theme.palette.dark.eerieBlack1,
        },
      },
      overflow: 'auto',
      height: '250px',
    },
  };
});

export { useStyles };
