import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ChevronBottom from '../../common/Icons/arrow/ChevronBottom';
import ChevronTop from '../../common/Icons/arrow/ChevronTop';
import Typography from '../../common/Typography/Typography';
import Version from '../Version';
import { useStyles } from './TopBar.style';
import labels from '../../config/labels';
import useMediaQuery from '../../hooks/useMediaQuery';

const TopBarUser = ({ auth, className }) => {
  const classes = useStyles();
  const wrapperRef = useRef(null);
  const isMDUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const [isUserOpen, setIsUserOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsUserOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const getDisplayName = () => {
    const familyName = auth.user.family_name || '';
    const name = auth.user.given_name || auth.user.name;
    return `${name} ${familyName.substring(0, 1).toUpperCase()}.`;
  };

  return (
    <div
      className={clsx(classes.user, className)}
      data-testid="user-menu"
      onClick={() => setIsUserOpen(!isUserOpen)}
      ref={wrapperRef}
    >
      <img src={auth.user.picture} alt="user" />
      {isMDUp && (
        <Typography bold color="platinum5">
          {getDisplayName()}
        </Typography>
      )}
      {isUserOpen ? <ChevronTop /> : <ChevronBottom />}
      {isUserOpen && (
        <div className={classes.user_menu}>
          <div
            data-testid="logout-button"
            className={classes.user_menu_item}
            onClick={() => auth.logout()}
          >
            <Typography color="platinum5">{labels.LOGOUT}</Typography>
          </div>
          <Version class={clsx(classes.user_menu_item, classes.version_info)} />
        </div>
      )}
    </div>
  );
};

TopBarUser.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      given_name: PropTypes.string,
      family_name: PropTypes.string,
      picture: PropTypes.string.isRequired,
      name: PropTypes.string,
    }).isRequired,
    logout: PropTypes.func.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

TopBarUser.defaultProps = {
  className: '',
};

export default TopBarUser;
