import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => ({
  link_container: (careTypeStyle) => {
    let style = {
      flex: '1',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    };
    if (careTypeStyle === 'tag') {
      style.marginTop = '16px';
    }
    return style;
  },
  links: {
    display: 'flex',
    alignItems: 'center',
  },
  link_divider: {
    margin: '0',
    transform: 'rotate(-90deg)',
    width: '12px',
  },
}));

export { useStyles };
