import { useEffect, useState } from 'react';
import restClient from '../utils/restClient';
import math from '../utils/math';

const GetRLToursMetrics = (url) => {
  const [numberOfTours, setNumberOfTours] = useState(0);
  const [totalOfReferredContacts, setTotalOfReferredContacts] = useState(0);
  const [percentageAccomplished, setPercentageAccomplished] = useState(0);

  useEffect(() => {
    const getRLToursMetrics = async () => {
      const toursMetricUrl = url;
      let toursMetricUrlResult = null;
      try {
        toursMetricUrlResult = await restClient.get(toursMetricUrl);
      } catch (error) {
        console.error('Error getting tour metrics ', error);
      }
      const numberOfToursValidated = math.validateNumber(
        toursMetricUrlResult ? toursMetricUrlResult.data.numberOfTours : 0,
      );
      const totalOfReferredContactsValidated = math.validateNumber(
        toursMetricUrlResult
          ? toursMetricUrlResult.data.totalOfReferredContacts
          : 0,
      );
      const percentageAccomplishedValidated = math.validateNumber(
        toursMetricUrlResult
          ? toursMetricUrlResult.data.percentageAccomplished
          : 0,
      );
      setNumberOfTours(numberOfToursValidated);
      setTotalOfReferredContacts(totalOfReferredContactsValidated);
      setPercentageAccomplished(percentageAccomplishedValidated);
    };
    getRLToursMetrics();
  }, [url]);

  const rLToursMetric = {
    numberOfTours: numberOfTours,
    totalOfReferredContacts: totalOfReferredContacts,
    percentageAccomplished,
  };
  return rLToursMetric;
};

export { GetRLToursMetrics };
