import { PhoneContext } from '@aplaceformom/owl-connect-client';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'common/Button/Button';
import { showTelephonyIframe } from 'stateManagement/telephony/actions/telephonyActions';
import StatusDropDown from '../StatusDropDown/StatusDropDown';
import TopBar from './TopBar';
import { getLogoutOption } from '../StatusDropDown/items';
import Typography from 'common/Typography/Typography';
import Toast from 'common/Toast/Toast';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const NewInquiryTopBar = ({ flags }) => {
  const dispatch = useDispatch();

  const {
    agentState,
    agentStates,
    setAgentState,
    isAuthenticated,
    isFetching,
    isLoading,
    skills,
    skillIds,
    logout,
    logoutReasonCodes,
    callState,
  } = useContext(PhoneContext);

  const callStateValue = typeof callState === 'string' ? callState : '';

  const isProcessing = isFetching || isLoading;

  const logoutState = getLogoutOption();

  const onButtonClick = useCallback(() => {
    dispatch(showTelephonyIframe());
  }, [dispatch]);

  const onChangeStatus = useCallback(
    (selectedStatus) => {
      if (selectedStatus.id !== logoutState.id) {
        setAgentState(selectedStatus.id);
      }
    },
    [setAgentState, logoutState],
  );

  const logoutOption = agentStates.filter((s) => s.id === logoutState.id);
  if (logoutOption.length === 0) {
    agentStates.push(logoutState);
  }

  const logoutReasons = logoutReasonCodes
    .filter((e) => e.selectable)
    .map((reason) => {
      return {
        value: reason.id,
        label: reason.name,
        disabled: false,
      };
    });

  const onClickLogout = async (logoutReasonSelected) => {
    if (logoutReasonSelected) {
      await logout(logoutReasonSelected.value);
    } else {
      return (
        <Toast type="error">
          <Typography>Error reading the reason for logout</Typography>
        </Toast>
      );
    }
  };

  const getButton = () => {
    if (isAuthenticated) {
      return (
        <Fragment>
          <StatusDropDown
            agentState={agentState}
            agentStates={agentStates}
            onChange={(selected) => onChangeStatus(selected)}
            isLoading={isProcessing}
            logoutReasons={logoutReasons}
            onClickLogout={onClickLogout}
            callState={callStateValue}
          />
          {flags.showFive9Frame && (
            <Button color="primary" onClick={onButtonClick} variant="contained">
              Five9
            </Button>
          )}
        </Fragment>
      );
    }
    return (
      <Button color="primary" onClick={onButtonClick} variant="contained">
        Log In to Five9
      </Button>
    );
  };

  return (
    <Fragment>
      <TopBar
        button={getButton()}
        skills={skills}
        selectedSkills={skillIds}
        callState={callStateValue}
        agentState={agentState}
      />
    </Fragment>
  );
};

NewInquiryTopBar.propTypes = {
  flags: PropTypes.shape({
    showFive9Frame: PropTypes.bool,
  }),
};

NewInquiryTopBar.defaultProps = {};

export default withLDConsumer()(NewInquiryTopBar);
