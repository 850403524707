import React, { useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import clsx from 'clsx';
import { useStyles } from './PlacesInput.style';
import PropTypes from 'prop-types';
import Typography from '../Typography/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { generateId } from '../utils/random';
import withConfig from '../../utils/withConfig';
import Plus from '../../common/Icons/basic/Plus';
import { lookupLocation } from './PlacesInputService';

const MAX_SUGGESTIONS = 5;
const DELETE_STATE_FULL = 'full';
const DELETE_STATE_PARTIAL = 'pending';
const DELETE_STATE_NONE = '';
let autocompleteService;
let placesService;
let geocoderService;
let sessionToken;

const loadScript = (url, callback) => {
  const mapApiScripts = document.querySelectorAll('[data-mapsapi="true"]');
  if (mapApiScripts.length > 0) {
    return;
  }

  let script = document.createElement('script');
  script.type = 'text/javascript';

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  script.setAttribute('data-mapsapi', true);
  document.getElementsByTagName('head')[0].appendChild(script);
};

const handleScriptLoad = () => {
  autocompleteService = new window.google.maps.places.AutocompleteService();
  let dummyMap = new window.google.maps.Map(document.createElement('div'));
  placesService = new window.google.maps.places.PlacesService(dummyMap);
  geocoderService = new window.google.maps.Geocoder();

  generateSessionToken();
};

const generateSessionToken = () => {
  sessionToken = new window.google.maps.places.AutocompleteSessionToken();
  return sessionToken;
};

const preferredLocationText = (preferredLocation) => {
  if (
    preferredLocation.city.length === 0 &&
    preferredLocation.state.length === 0 &&
    preferredLocation.zip.length === 0
  ) {
    return '';
  }
  const text = `${preferredLocation.city}, ${preferredLocation.state} ${preferredLocation.zip}`;
  return text;
};

const PlacesInput = ({
  className,
  disabled,
  endIcon: EndIcon,
  error,
  labels,
  onChange,
  onDelete,
  onAdd,
  placeholder,
  preferredLocation,
  startIcon: StartIcon,
}) => {
  const classes = useStyles();

  const containerClasses = clsx(
    className,
    classes.places_input,
    error && classes.error,
    disabled && classes.disabled,
  );

  const wrapperRef = useRef(null);
  const autoCompleteRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selections, setSelections] = useState([]);
  const [currentValue, setCurrentValue] = useState('');

  useEffect(() => {
    const apiKey = withConfig('GOOGLE_GEOCODE_API_KEY');
    const url = `${withConfig(
      'GOOGLE_MAPS_API',
    )}?key=${apiKey}&libraries=places`;
    loadScript(url, () => handleScriptLoad());
  }, []);

  useEffect(() => {
    setCurrentValue(preferredLocationText(preferredLocation));
  }, [preferredLocation]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const getSuggestions = (searchText) => {
    var request = {
      input: searchText,
      componentRestrictions: { country: ['ca', 'us'] },
      sessionToken: sessionToken,
      types: ['(regions)'],
    };
    autocompleteService.getPlacePredictions(request, (selections, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setSelections(selections.slice(0, MAX_SUGGESTIONS));
        setIsOpen(true);
      }
    });
  };

  const getSuggestionsDebounced = useRef(
    debounce((searchText) => getSuggestions(searchText), 750),
  ).current;

  const onClickSelection = (selection) => {
    (async () => {
      let selectedLocation;
      try {
        selectedLocation = await lookupLocation(
          placesService,
          geocoderService,
          preferredLocation,
          selection.place_id,
        );
      } catch {
        selectedLocation = preferredLocation;
      }
      setCurrentValue(preferredLocationText(selectedLocation));

      onChange(selectedLocation);
      setIsOpen(false);

      generateSessionToken();
    })();
  };

  const onChangeSearchLocation = (event) => {
    const searchText = event.target.value;
    setCurrentValue(searchText);

    if (searchText.length === 0) {
      setIsOpen(false);
      return;
    }

    getSuggestionsDebounced(searchText);
  };

  const onClickSearchLocation = () => {
    if (currentValue.length === 0) {
      return;
    }
    setIsOpen(true);
  };

  const onClickEndEndornment = () => {
    if (currentValue.trim().length === 0) {
      onDelete(DELETE_STATE_FULL);
      return;
    }
    onDelete(DELETE_STATE_PARTIAL);
  };

  const AddLocationButton = () => {
    return (
      <div className={classes.add_location} onClick={onAdd}>
        <Typography>{labels.ADD_LOCATION}</Typography>
        <Plus />
      </div>
    );
  };

  return (
    <div className={containerClasses} ref={wrapperRef}>
      <div>
        <div className={classes.places_entry}>
          <InputAdornment className={classes.startIcon}>
            {StartIcon}
          </InputAdornment>
          <input
            ref={autoCompleteRef}
            onChange={onChangeSearchLocation}
            onClick={onClickSearchLocation}
            placeholder={placeholder}
            value={currentValue}
          />
          <InputAdornment
            className={classes.endIcon}
            onClick={onClickEndEndornment}
          >
            {EndIcon}
          </InputAdornment>
        </div>

        {isOpen && selections.length > 0 && (
          <div className={classes.menu}>
            <div className={classes.menu_items_container}>
              <Typography
                className={classes.menu_group_name}
                level="small"
                color="eerieBlack3"
              >
                {labels.SUGGESTED_PLACES_TITLE}
              </Typography>
              <div>
                {selections.map((selection, key) => (
                  <div
                    className={classes.menu_group_item}
                    key={key}
                    onClick={() => onClickSelection(selection)}
                  >
                    <Typography>{selection.description}</Typography>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <div>{preferredLocation.isLastLocation && <AddLocationButton />}</div>
    </div>
  );
};

PlacesInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  endIcon: PropTypes.element,
  error: PropTypes.bool,
  id: PropTypes.string,
  labels: PropTypes.object,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onAdd: PropTypes.func,
  placeholder: PropTypes.string,
  preferredLocation: PropTypes.object,
  startIcon: PropTypes.element,
};

PlacesInput.defaultProps = {
  className: '',
  disabled: false,
  error: false,
  id: generateId(),
  onChange: () => {},
  onDelete: () => {},
  onAdd: () => {},
  placeholder: 'Enter location',
};

export default PlacesInput;
export { DELETE_STATE_FULL, DELETE_STATE_PARTIAL, DELETE_STATE_NONE };
