import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import clsx from 'clsx';

import { useStyles } from './SendFamilyFileLetter.style';
import CaseProfileSidePanel from '../../components/CaseProfileSidePanel/CaseProfileSidePanel';
import Button from '../../common/Button/Button';
import User from '../../common/Icons/basic/User';
import Typography from '../../common/Typography/Typography';
import { withLabels } from '../../context/LabelContext';
import useGraphQLQuery from '../../hooks/useGraphQLQuery';
import sendFamilyFileLetterQuery from './sendFamilyFileLetterQuery';
import Loading from '../../components/Loading/Loading';
import TextInput from '../../common/TextInput/TextInput';
import Toast from '../../common/Toast/Toast';
import Send from '../../common/Icons/basic/Send';
import EmailFamilyLetterFilter from '../../common/Filters/EmailFamilyLetterFilter/EmailFamilyLetterFilter';
import Dropdown from '../../common/Dropdown/Dropdown';
import Checkbox from '../../common/Checkbox/Checkbox';
import Link from '../../common/Link/Link';
import Alert from '../../common/Alert/Alert';
import Refer from '../../common/Graphics/communities/Refer';
import { withUser } from '../../context/UserContext';
import {
  buildTemplateContent,
  disclosureStates,
  logFamilyLetterErrorViewed,
  logFamilyLetterSendAttempted,
  logFamilyLetterSendSucceeded,
  logFamilyLetterSettingsCleared,
  logFamilyLetterSettingsSaved,
  logFamilyLetterValidationViewed,
  logFamilyLetterViewed,
} from '../../services/sendFamilyFileLetterService';
import mandrillClient from '../../utils/mandrillClient';
import withConfig from '../../utils/withConfig';
import addFamilyCommunityLetterMutation from './addFamilyCommunityLetterMutation';
import upsertFamilyLetterNotesMutation from './upsertFamilyLetterNotesMutation';
import useGraphQLMutation from '../../hooks/useGraphQLMutation';
import documentLinks from './documentLinks.json';
import familyLetterTemplates from './familyLetterTemplates.json';
import { registerEvent } from '../../services/Analytics';
import { format } from '../../common/utils/dateFormat';
import { getHourAndMinutes12HourFormat } from '../../utils/dateFormat';
import getNonPartnerCommunitiesQuery from './getNonPartnerCommunitiesQuery';
import YglLink from '../../components/YglExternalId/YglLink';
import { useExternalId } from '../../hooks/useExternalId';
import DocumentHead from '../../common/DocumentHead/DocumentHead';
import SendSuccessMessage from '../../components/FamilyLetter/SendSuccessMessage';
import _ from 'lodash';
import { getFamilyFileIdFromUrl } from '../../utils/utils';

const SendFamilyFileLetter = ({ labels, currentUser, flags }) => {
  let isInitialLoad = useRef(true);
  let familyFileUserId = useRef();
  const emailLink = `mailto:${labels.PRIMARY_CONTACT_EMAIL}?subject=${labels.HELP_SENDING_FAMILY_LETTER}`;
  const emailTemplates = familyLetterTemplates.map((letter) => {
    return { value: letter.name, label: letter.name };
  });

  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [communities, setCommunities] = useState([]);
  const [salutationError, setSalutationError] = useState();
  const [subjectError, setSubjectError] = useState();
  const [introductionError, setIntroductionError] = useState();
  const [closingParagraphError, setClosingParagraphError] = useState();
  const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false);
  const [isNoteAlertOpen, setIsNoteAlertOpen] = useState(false);
  const [sendButtonEnabled, setSendButtonEnabled] = useState(true);
  const [errorSendingEmail, setErrorSendingEmail] = useState(false);
  const [errorSavingSettings, setErrorSavingSettings] = useState(false);
  const [elementsSend, setElementsSend] = useState([]);

  const [
    specialCommunityPostalCodes,
    setSpecialCommunityPostalCodes,
  ] = useState([]);
  const [nonPartnerCommunityNames, setNonPartnerCommunityNames] = useState([]);
  const [isSelfLead, setIsSelfLead] = useState(null);
  const [savedNotes, setSavedNotes] = useState([]);
  const [familyFileId, setFamilyFileId] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const selectedAdditional = useRef([]);
  const selectedPrimary = useRef([]);

  const [addFamilyCommunityLetter] = useGraphQLMutation(
    addFamilyCommunityLetterMutation,
  );

  const familyFileIdFromUrl = getFamilyFileIdFromUrl(location);

  const { externalId, fetchError } = useExternalId(familyFileIdFromUrl);

  const { loading, error, data } = useGraphQLQuery(sendFamilyFileLetterQuery, {
    variables: { familyFileId: familyFileIdFromUrl },
  });
  const getPrimaryContactEmail = () => {
    const primaryContact = data.findFamilyFileById.contacts.find(
      (contact) => contact.profileData && contact.profileData.isPrimary,
    );

    return primaryContact?.profileData?.emailAddress;
  };

  useEffect(() => {
    if (data?.findFamilyFileById) {
      setFamilyFileId(data.findFamilyFileById.familyFileId);
    }
    const getScheduledTourDateLabel = (tourDetail) => {
      const scheduledTourDate = tourDetail?.scheduledTourDate;
      const tourStatus = tourDetail?.currentStatus;
      const now = new Date();

      if (
        !scheduledTourDate ||
        scheduledTourDate < now ||
        tourStatus === 'Canceled' ||
        tourStatus === 'Completed'
      ) {
        return;
      }

      const scheduledTourDateFormat = format(
        scheduledTourDate,
        'MMMM X, YYYY (DDD)',
      );
      const selectedTime = getHourAndMinutes12HourFormat(
        scheduledTourDate,
        true,
      );

      let selectedTourType = '';

      switch (tourDetail.tourType) {
        case 'VT':
          selectedTourType = labels.CT_APP;
          break;
        case 'VTX':
          selectedTourType = labels.VIRTUAL_TOURS_OTHER;
          break;
        case 'IP':
          selectedTourType = labels.IN_PERSON_TOUR;
          break;
        default:
          break;
      }

      return `${scheduledTourDateFormat} @ ${selectedTime} - ${selectedTourType}`;
    };

    if (data?.findFamilyFileById) {
      familyFileUserId.current = data.findFamilyFileById.userId;
    }

    if (data?.findFamilyFileById?.communities) {
      const referredCommunities = data?.findFamilyFileById?.referralStatus
        .filter((referralStatus) => referralStatus.isReferralStarted)
        .map((referralStatus) => referralStatus.communityId.toString());

      const filteredCommunities = data.findFamilyFileById.communities
        .filter((community) => {
          const closedCommunityIds = data?.findFamilyFileById?.referralStatus
            .filter((status) => status.communityStatus === labels.CLOSED)
            .map((status) => status.communityId);
          return (
            getSelectedCommunitiesId().indexOf(community.id) >= 0 &&
            referredCommunities.indexOf(community.id) >= 0 &&
            !closedCommunityIds.includes(Number(community.id))
          );
        })
        .map((community) => {
          return {
            id: community.id,
            name: community.name,
            address: community.address,
            address1: community.address1,
            city: community.city,
            state: community.state,
            zip: community.zip,
            employees: community.employees,
            phoneNumber: community.phoneNumber,
            ratingAverage: community.ratingAverage,
            numberOfReviews: community.numberOfReviews,
            // TODO: filter/sort will be replaced by future API changes
            reviews: community.reviews
              // Only use reviews with 4 or more stars
              ?.filter((review) => review.score >= 4)
              // Order by most recent
              .sort((a, b) => new Date(a.createdAt) > new Date(b.createdAt)),
            note: '',
            scheduledTourDateLabel: getScheduledTourDateLabel(
              data.findFamilyFileById.tourDetailsExtended.find(
                (tourDetails) =>
                  parseInt(tourDetails.communityId) === parseInt(community.id),
              ),
            ),
            primaryContact: community.employees?.find(
              (employee) => employee?.isPrimaryContact,
            ),
          };
        });

      letterInfo.communityNotes = filteredCommunities;

      setCommunities(filteredCommunities);

      if (isInitialLoad.current) {
        isInitialLoad.current = false;
        createViewedAnalyticsEvents(filteredCommunities);
      }

      const specialCommunityZips = filteredCommunities
        // eslint-disable-next-line array-callback-return
        .map((community) => {
          if (Object.keys(disclosureStates).includes(community.state)) {
            return community.zip;
          }
        })
        .filter((zipCode) => !!zipCode);
      setSpecialCommunityPostalCodes(specialCommunityZips);
    }

    // Current SLA
    letterInfo.user = data?.findFamilyFileById?.user;

    if (data?.findFamilyFileById?.externalId) {
      letterInfo.familyFileId = data.findFamilyFileById.externalId;
    }

    const selfLeadsAudicences = [
      'self_not_urgent_veteran_financially_unqualified',
      'self_urgent_financially_unqualified',
      'self_not_urgent_financially_qualified',
    ];

    if (data?.findFamilyFileById?.audienceKey) {
      let audienceKey = data.findFamilyFileById.audienceKey;
      let isSelfLead = selfLeadsAudicences.some((selfAudience) =>
        audienceKey?.includes(selfAudience),
      );
      if (isSelfLead) {
        letterInfo.subject = familyLetterTemplates[2].subject;
        letterInfo.introduction = familyLetterTemplates[2].introduction;
        letterInfo.communityIntroduction =
          familyLetterTemplates[2].communityIntroduction;
        letterInfo.closingParagraph = familyLetterTemplates[2].closingParagraph;
        letterInfo.selectedTemplate = familyLetterTemplates[2].name;
        letterInfo.mailTemplate = familyLetterTemplates[2].mailTemplate;
      }
      setIsSelfLead(isSelfLead);
    }

    if (data?.findFamilyFileById?.familyLetterNotes.length > 0) {
      const filteredFamilyLetterNotes = data.findFamilyFileById.familyLetterNotes.filter(
        (familyLetterNote) =>
          getSelectedCommunitiesId().indexOf(
            familyLetterNote.communityId.toString(),
          ) >= 0,
      );

      if (filteredFamilyLetterNotes.length > 0) {
        setSavedNotes(filteredFamilyLetterNotes);
        let familyLetterNoteSettings = filteredFamilyLetterNotes[0];
        letterInfo.subject = familyLetterNoteSettings.subject;
        letterInfo.introduction = familyLetterNoteSettings.introduction;
        letterInfo.communityIntroduction =
          familyLetterNoteSettings.communityIntroduction;
        letterInfo.closingParagraph = familyLetterNoteSettings.closingParagraph;

        if (familyLetterNoteSettings.sendTo.length > 0) {
          letterInfo.sendTo = familyLetterNoteSettings.sendTo.split(',');
        }

        const selectedTemplate = getSelectedTemplateByName(
          familyLetterNoteSettings.template,
        );
        letterInfo.selectedTemplate = selectedTemplate.name;
        letterInfo.mailTemplate = selectedTemplate.mailTemplate;
        try {
          letterInfo.linksToSend = JSON.parse(familyLetterNoteSettings.links);
        } catch (error) {
          letterInfo.linksToSend = {};
          console.error(
            `SendfamilyLetter. JSON.parse failed. Error: ${error}. Data: ${familyLetterNoteSettings.links}`,
          );
        }

        if (selectedTemplate.isSelfLead) {
          letterInfo.additionalNotes = familyLetterNoteSettings.additionalNotes;
        }

        const communityNotesDb = filteredFamilyLetterNotes.map(
          (familyLetterNote) => {
            return {
              communityId: familyLetterNote.communityId,
              note: familyLetterNote.communityNote,
            };
          },
        );
        const communitiesWithAddedNotes = letterInfo.communityNotes.map(
          (community) => {
            const noteText = communityNotesDb.find(
              (communityNote) =>
                communityNote.communityId === Number(community.id),
            )?.note;
            return {
              ...community,
              note: noteText || '',
            };
          },
        );

        letterInfo.communityNotes = communitiesWithAddedNotes;
        setCommunities(communitiesWithAddedNotes);
        setIsSelfLead(selectedTemplate.isSelfLead);
      }
    }
    letterInfo.salutation = getSalutation();
    setLetterInfo({ ...letterInfo });

    if (data?.findFamilyFileById) {
      const primaryContactEmail = getPrimaryContactEmail();
      if (!primaryContactEmail) {
        setIsDisabled(true);
        createValidationViewedAnalyticsEvents(
          data.findFamilyFileById.familyFileId,
          !!primaryContactEmail,
        );
      } else {
        setIsDisabled(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getPrimaryContactFirstName = () => {
    const primaryContact = data?.findFamilyFileById?.contacts?.find(
      (contact) => contact.profileData && contact.profileData.isPrimary,
    );
    return primaryContact?.profileData?.firstName;
  };

  const getSalutation = () => {
    const primaryContactFirstName = getPrimaryContactFirstName();
    return primaryContactFirstName ? `Dear ${primaryContactFirstName},` : null;
  };

  const setInitialInfoState = () => {
    return {
      additionalNotes: '',
      closingParagraph: familyLetterTemplates[0].closingParagraph,
      communityIntroduction: familyLetterTemplates[0].communityIntroduction,
      communityNotes: [],
      familyFileId: familyFileId,
      introduction: familyLetterTemplates[0].introduction,
      linksToSend: [],
      mailTemplate: familyLetterTemplates[0].mailTemplate,
      salutation: null,
      selectedTemplate: familyLetterTemplates[0].name,
      sendTo: [],
      subject: familyLetterTemplates[0].subject,
    };
  };
  const letterInformation = setInitialInfoState();
  const [letterInfo, setLetterInfo] = useState({ ...letterInformation });
  const [saveFamilyLetterNotesMutation] = useGraphQLMutation(
    upsertFamilyLetterNotesMutation,
  );

  const [isAllSelectLinksOn, setIsAllSelectLinksOn] = useState(
    letterInfo.linksToSend.length > 7,
  );

  const getSelectedCommunitiesId = () => {
    const params = new URLSearchParams(location.search);
    return params.get('communities').split(',');
  };

  const getSelectedTemplateByName = (templateName) => {
    return familyLetterTemplates.find((letter) => letter.name === templateName);
  };

  const shouldSkip = specialCommunityPostalCodes.length === 0;

  const {
    loading: nonPartnerCommunitiesLoading,
    error: nonPartnerCommunitiesError,
    data: nonPartnerCommunitiesData,
  } = useGraphQLQuery(getNonPartnerCommunitiesQuery, {
    variables: { zip: specialCommunityPostalCodes },
    skip: shouldSkip,
  });

  useEffect(() => {
    if (nonPartnerCommunitiesData?.findOwlCommunities) {
      const names = nonPartnerCommunitiesData.findOwlCommunities.map(
        (community) => community.name,
      );

      setNonPartnerCommunityNames(names);
    }
  }, [nonPartnerCommunitiesData]);

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  if (nonPartnerCommunitiesLoading) return <Loading />;
  if (nonPartnerCommunitiesError) {
    return `Error! ${nonPartnerCommunitiesError.message}`;
  }

  const getPrimaryContactFullName = () => {
    const primaryContact = data.findFamilyFileById.contacts.find(
      (contact) => contact.profileData && contact.profileData.isPrimary,
    );
    if (primaryContact) {
      return `${primaryContact.profileData.firstName} ${primaryContact.profileData.lastName}`;
    }
  };

  const getPrimaryContactRelationship = () => {
    const primaryContact = data.findFamilyFileById.contacts.find(
      (contact) => contact.profileData && contact.profileData.isPrimary,
    );
    if (primaryContact) {
      const relation = primaryContact.profileData.relationToResident;
      return relation ? relation : labels.UNKNOWN_RELATION;
    }
  };

  const handleCommunityTextChange = (value, community) => {
    community.note = value;
    setCommunities([...communities]);
  };

  const getClearCommunities = () => {
    return communities.map((community) => ({
      ...community,
      ...{
        note: '',
      },
    }));
  };

  const clearChanges = () => {
    const clearCommunities = getClearCommunities();

    const defaultLetterInfo = {
      ...setInitialInfoState(),
      salutation: getSalutation(),
    };
    defaultLetterInfo.communityNotes = clearCommunities;

    setLetterInfo(defaultLetterInfo);
    clearErrors();
    setCommunities(clearCommunities);

    return [defaultLetterInfo];
  };

  const handleClearChanges = () => {
    createSettingsClearedAnalyticsEvents();
    clearChanges();
  };

  const handleSelectAllDocumentsLinks = (e) => {
    if (!e.target.checked) {
      letterInfo.linksToSend = [];
    } else {
      letterInfo.linksToSend = documentLinks;
    }
    setLetterInfo({ ...letterInfo });
    setIsAllSelectLinksOn(e.target.checked);
  };

  const handleSavingSettingsError = () => {
    createErrorViewedAnalyticsEvents(labels.UNABLE_TO_SAVE_NOTE_DUE_TO_ERROR);

    setErrorSavingSettings(true);
    window.scrollTo(0, 0);
  };

  const saveFamilyLetterNotes = async () => {
    const selectedTemplate = getSelectedTemplateByName(
      letterInfo.selectedTemplate,
    );
    if (selectedTemplate.isSelfLead) {
      letterInfo.communityIntroduction = '';
      const clearCommunities = getClearCommunities();
      letterInfo.communityNotes = clearCommunities;
      setCommunities(clearCommunities);
    } else {
      letterInfo.additionalNotes = '';
    }
    const communityNotes = letterInfo.communityNotes.map((community) => {
      return {
        communityId: Number(community.id),
        note: community.note,
      };
    });

    const familyLetterNotes = communities.map((community) => {
      const savedFamilyLetterNote = savedNotes.find(
        (familyLetterNote) =>
          familyLetterNote.communityId === Number(community.id),
      );

      return {
        familyLetterNoteId: savedFamilyLetterNote?.familyLetterNoteId || null,
        subject: letterInfo.subject,
        introduction: letterInfo.introduction,
        communityIntroduction: letterInfo.communityIntroduction,
        communityId: Number(community.id),
        communityNote: communityNotes.find(
          (communityNote) => communityNote.communityId === Number(community.id),
        ).note,
        closingParagraph: letterInfo.closingParagraph,
        template: selectedTemplate.name,
        links: JSON.stringify(letterInfo.linksToSend),
        sendTo: letterInfo.sendTo.toString(),
        additionalNotes: letterInfo.additionalNotes,
      };
    });

    setLetterInfo({ ...letterInfo });

    const familyLetterNotesBody = {
      familyFileId,
      familyLetterNotes,
    };

    return saveFamilyLetterNotesMutation({
      variables: { input: familyLetterNotesBody },
    });
  };

  const handleSaveSettings = async () => {
    createSettingsSavedAnalyticsEvents();
    let { data: response } = await saveFamilyLetterNotes();
    if (response.upsertFamilyLetterNotes) {
      setSavedNotes(response.upsertFamilyLetterNotes.familyLetterNotes);
      setIsNoteAlertOpen(true);
    } else {
      handleSavingSettingsError();
    }
  };

  const isDocumentLinkChecked = (name) => {
    return !!letterInfo.linksToSend.find(
      (linkToSend) => linkToSend.name === name,
    );
  };

  const handleDocumentLinkSelection = (checked, documentLink) => {
    if (checked) {
      letterInfo.linksToSend.push(documentLink);
    } else {
      letterInfo.linksToSend = letterInfo.linksToSend.filter(
        (linkDocument) => linkDocument.name !== documentLink.name,
      );
    }
    setLetterInfo({ ...letterInfo });
  };

  const setProperty = (name, value) => {
    letterInfo[name] = value;
    setLetterInfo({ ...letterInfo });
  };

  const handleTemplateSelection = (item) => {
    const selectedNow = getSelectedTemplateByName(item.value);
    setProperty('selectedTemplate', selectedNow.name);
    setProperty('subject', selectedNow.subject);
    setProperty('introduction', selectedNow.introduction);
    setProperty('communityIntroduction', selectedNow.communityIntroduction);
    setProperty('closingParagraph', selectedNow.closingParagraph);
    setProperty('mailTemplate', selectedNow.mailTemplate);
    setIsSelfLead(selectedNow.isSelfLead);
  };

  const handleAdditionalNotes = (additionalNotes) => {
    setProperty('additionalNotes', additionalNotes);
  };

  const handleSend = async () => {
    createSendAttemptedAnalyticsEvents();

    if (!letterInfo.subject) {
      setSubjectError(labels.REQUIRED);
      window.scrollTo(0, 0);
    } else {
      setSubjectError();
    }
    if (!letterInfo.introduction) {
      setIntroductionError(labels.REQUIRED);
      window.scrollTo(0, 0);
    } else {
      setIntroductionError();
    }
    if (!letterInfo.closingParagraph) {
      setClosingParagraphError(labels.REQUIRED);
    } else {
      setClosingParagraphError();
    }
    if (!letterInfo.salutation) {
      setSalutationError(labels.REQUIRED);
      window.scrollTo(0, 0);
    } else {
      setSalutationError();
    }

    if (
      letterInfo.subject &&
      letterInfo.introduction &&
      letterInfo.salutation &&
      letterInfo.closingParagraph
    ) {
      setSendButtonEnabled(false);
      const { data: response } = await saveFamilyLetterNotes();
      if (response.upsertFamilyLetterNotes) {
        setSavedNotes(response.upsertFamilyLetterNotes.familyLetterNotes);
        await handleEmailSending();
      } else {
        handleSavingSettingsError();
      }
      setSendButtonEnabled(true);
    } else {
      createValidationErrorsAnalyticsEvents(
        !!letterInfo.subject,
        !!letterInfo.introduction,
        !!letterInfo.closingParagraph,
      );
    }
  };

  const clearErrors = () => {
    setSubjectError('');
    setIntroductionError('');
    setClosingParagraphError('');
  };

  const handleEmailSending = async () => {
    let recipients = [];
    const recipientPrimary = {
      email: getPrimaryContactEmail(),
      name: getPrimaryContactFullName(),
      relationToResident: getPrimaryContactRelationship(),
    };

    const recipientAdditional = selectedAdditional.current
      .filter((contact) => contact.checked)
      .map((contact) => {
        return {
          name: contact.name,
          email: contact.emailAddress,
          relationToResident: contact.relationToResident,
        };
      });
    if (selectedAdditional.length === 0) {
      recipientAdditional.clear();
    }

    if (selectedPrimary.current.length > 1) {
      //combine default and secondary emails into one, to be pass onto success alert
      const sucessAlertElements = _.cloneDeep(recipientAdditional);
      const copyPrimary = _.cloneDeep(recipientPrimary);
      copyPrimary.email = selectedPrimary.current
        .filter((contact) => !!contact.checked)
        .map((contact) => contact.emailAddress)
        .join(', ');
      sucessAlertElements.push(copyPrimary);
      setElementsSend(sucessAlertElements);

      //find secondaryEmail
      const secondaryEmailSelected = selectedPrimary.current.find(
        (secondaryEmail) => !secondaryEmail.isPrimary,
      );
      if (secondaryEmailSelected?.checked) {
        recipientAdditional.push({
          email: secondaryEmailSelected.emailAddress,
          name: secondaryEmailSelected.name,
          relationToResident: secondaryEmailSelected.relationToResident,
        });
      }
      recipientAdditional.push(recipientPrimary);
      recipients = [recipientAdditional];
    } else {
      //default behavior when secondary email not included
      recipientAdditional.push(recipientPrimary);
      recipients = [recipientAdditional];
      setElementsSend(recipients[0]);
    }
    const specialCommunityData = {
      shouldSkip,
      communityNames: nonPartnerCommunityNames,
      postalCodes: specialCommunityPostalCodes,
    };

    const templateContent = await buildTemplateContent(
      letterInfo,
      currentUser,
      getPrimaryContactFullName(),
      getPrimaryContactFirstName(),
      specialCommunityData,
    );

    const sendRecipientPromises = [];
    for (const recipient of recipients) {
      const recipientPromise = mandrillClient.sendEmailThroughTemplate(
        withConfig('MANDRILL_SEND_MESSAGE_THROUGH_TEMPLATE'),
        letterInfo.subject,
        letterInfo?.user,
        withConfig(letterInfo.mailTemplate),
        templateContent,
        recipient,
      );
      sendRecipientPromises.push(recipientPromise);
    }
    Promise.all(sendRecipientPromises)
      .then((sendEmailResponse) => {
        const dataEmailResponses = sendEmailResponse?.flat();
        for (const dataEmailResponse of dataEmailResponses) {
          if (dataEmailResponse?.data) {
            communities.map(async (community) => {
              return await addFamilyCommunityLetter({
                variables: {
                  familyFileId: familyFileId,
                  communityId: parseInt(community.id),
                  emailServiceStatus: dataEmailResponse.data[0].status,
                  emailServiceRejectReason:
                    dataEmailResponse.data[0].reject_reason,
                },
              }).catch((error) => {
                console.error(`Error inserting action ${error}`);
              });
            });
          }
        }
        if (window.ENV.REACT_APP_ENVIRONMENT !== 'prod') {
          console.log('ANSWER-MAIL', sendEmailResponse);
        }
        createSendSucceededAnalyticsEvents();
      })
      .catch((sendEmailResponse) => {
        if (sendEmailResponse?.code === 421) {
          handleSendingError();
          return;
        }
      });

    clearChanges();
    setIsSuccessAlertOpen(true);
  };

  const handleSendingError = () => {
    createErrorViewedAnalyticsEvents(labels.UNABLE_TO_SEND_DUE_TO_ERROR);

    setErrorSendingEmail(true);
    window.scrollTo(0, 0);
  };

  const createViewedAnalyticsEvents = (filteredCommunities) => {
    const communityIds = filteredCommunities.map((community) => community.id);
    logFamilyLetterViewed(
      communityIds,
      data.findFamilyFileById.familyFileId,
      familyFileUserId.current,
    );
  };

  const createSendAttemptedAnalyticsEvents = () => {
    const communityIds = communities.map((community) => community.id);
    const linkNames = letterInfo.linksToSend.map((link) => link.name);
    const letterContactCount =
      selectedAdditional.current.filter((additionalContact) => {
        return additionalContact.checked;
      }).length + 1; // Add Primary Contact

    logFamilyLetterSendAttempted(
      communityIds,
      familyFileId,
      letterContactCount,
      linkNames,
      letterInfo.selectedTemplate,
      familyFileUserId.current,
    );
  };

  const createSettingsSavedAnalyticsEvents = () => {
    const communityIds = communities.map((community) => community.id);
    const linkNames = letterInfo.linksToSend.map((link) => link.name);
    logFamilyLetterSettingsSaved(
      communityIds,
      familyFileId,
      linkNames,
      letterInfo.selectedTemplate,
      familyFileUserId.current,
    );
  };

  const createSettingsClearedAnalyticsEvents = () => {
    const communityIds = communities.map((community) => community.id);
    const linkNames = letterInfo.linksToSend.map((link) => link.name);
    logFamilyLetterSettingsCleared(
      communityIds,
      familyFileId,
      linkNames,
      letterInfo.selectedTemplate,
      familyFileUserId.current,
    );
  };

  const createSendSucceededAnalyticsEvents = () => {
    registerEvent('FamilyFiles', 'Send Family Letter', new Date().toString());

    const communityIds = communities.map((community) => community.id);
    const linkNames = letterInfo.linksToSend.map((link) => link.name);
    const letterContactCount =
      selectedAdditional.current.filter((additionalContact) => {
        return additionalContact.checked;
      }).length + 1; // Add Primary Contact
    logFamilyLetterSendSucceeded(
      communityIds,
      familyFileId,
      letterContactCount,
      linkNames,
      letterInfo.selectedTemplate,
      familyFileUserId.current,
    );
  };

  const createErrorViewedAnalyticsEvents = (errorMessages) => {
    const communityIds = communities.map((community) => community.id);
    const linkNames = letterInfo.linksToSend.map((link) => link.name);
    logFamilyLetterErrorViewed(
      communityIds,
      errorMessages,
      familyFileId,
      linkNames,
      letterInfo.selectedTemplate,
      familyFileUserId.current,
    );
  };

  const createValidationErrorsAnalyticsEvents = (
    hasSubject,
    hasIntroduction,
    hasClosingParagraph,
    hasSalutation,
  ) => {
    const communityIds = communities.map((community) => community.id);
    const linkNames = letterInfo.linksToSend.map((link) => link.name);

    let errorMessages = [];
    if (!hasSubject) {
      errorMessages.push('Subject required');
    }
    if (!hasIntroduction) {
      errorMessages.push('Introduction required');
    }
    if (!hasClosingParagraph) {
      errorMessages.push('Closing paragraph required');
    }
    if (!hasSalutation) {
      errorMessages.push('Salutation is required');
    }

    logFamilyLetterErrorViewed(
      communityIds,
      errorMessages,
      familyFileId,
      linkNames,
      letterInfo.selectedTemplate,
      familyFileUserId.current,
    );
  };

  const createValidationViewedAnalyticsEvents = (
    familyFileId,
    hasPrimaryEmail,
  ) => {
    let errorMessages = [];
    if (!hasPrimaryEmail) {
      errorMessages.push('Primary Contact Email Address');
    }

    logFamilyLetterValidationViewed(
      errorMessages,
      familyFileId,
      familyFileUserId.current,
    );
  };

  const onContactsSelected = (additionalContacts, primaryContact) => {
    selectedAdditional.current = additionalContacts;
    selectedPrimary.current = primaryContact;
  };

  const renderIsNotSelfLead = () => {
    return (
      <>
        <TextInput
          label={labels.SUBJECT}
          value={letterInfo.subject}
          className={classes.subject_input}
          onChange={(value) => setProperty('subject', value)}
          id={'subject'}
          error={!!subjectError}
          textHint={subjectError}
          maxLength={255}
          autoComplete="off"
          isMandatory
          multiline
        />
        <br></br>
        <EmailFamilyLetterFilter
          className={classes.filter}
          labels={labels}
          contacts={data.findFamilyFileById.contacts}
          disabled={isDisabled}
          onContactsSelected={onContactsSelected}
          multiline
          isMandatory
        />
        <TextInput
          label={labels.SALUTATION}
          value={letterInfo.salutation}
          className={classes.subject_input}
          onChange={(value) => setProperty('salutation', value)}
          id="salutation"
          error={!!salutationError}
          textHint={salutationError}
          maxLength={50}
          autoComplete="off"
          isMandatory
        />
        <TextInput
          label={labels.INTRODUCTION}
          value={letterInfo.introduction}
          error={!!introductionError}
          textHint={introductionError}
          className={classes.default_text_area}
          onChange={(value) => setProperty('introduction', value)}
          id={'introduction'}
          autoComplete="off"
          rows={3}
          isMandatory
          multiline
        />
        <TextInput
          label={labels.COMMUNITY_INTRODUCTION}
          value={letterInfo.communityIntroduction}
          className={classes.default_text_area}
          onChange={(value) => setProperty('communityIntroduction', value)}
          id={'community-introduction'}
          autoComplete="off"
          rows={3}
          multiline
        />
        <Typography className={classes.communities} bold level="large">
          {labels.COMMUNITY_NOTES}
        </Typography>
        <Toast
          type="warning"
          className={classes.warning_toast}
          onClose={() => {}}
        >
          <Typography>{labels.FAMILY_FILE_LETTER_NOTE}</Typography>
        </Toast>
        {letterInfo.communityNotes.map((community, key) => (
          <div className={classes.single_community} key={key}>
            <Typography bold>{community.name}</Typography>
            <TextInput
              className={classes.default_text_area}
              multiline
              maxLength={2500}
              value={community.note}
              rows={3}
              onChange={(value) => handleCommunityTextChange(value, community)}
              label={labels.ADDITIONAL_NOTES}
            />
          </div>
        ))}
        <TextInput
          label={labels.CLOSING_PARAGRAPH}
          value={letterInfo.closingParagraph}
          error={!!closingParagraphError}
          textHint={closingParagraphError}
          className={classes.default_text_area}
          onChange={(value) => setProperty('closingParagraph', value)}
          id={'closing-paragraph'}
          autoComplete="off"
          rows={3}
          multiline
          isMandatory
        />
        {renderHelpfulLinks()}
      </>
    );
  };

  const renderHelpfulLinks = () => {
    return (
      <>
        <Typography className={classes.communities} bold level="large">
          {labels.LINKS}
        </Typography>
        <Typography color="eerieBlack5" level="small">
          {labels.SELECT_DOCUMENT_TO_LINK_FAMILY_LETTER}
        </Typography>
        <div className={classes.row}>
          <Checkbox
            indeterminate={isAllSelectLinksOn}
            label={isAllSelectLinksOn ? labels.DESELECT_ALL : labels.SELECT_ALL}
            onChange={handleSelectAllDocumentsLinks}
            checked={isAllSelectLinksOn}
          />
        </div>
        <div className={classes.row}>
          <div className={classes.column}>
            {documentLinks.map((documentLink, index) => {
              if (index < 4) {
                return (
                  <Checkbox
                    key={`document-link-${index}`}
                    className={classes.links}
                    label={
                      <Link to={documentLink.link} openInNewTab>
                        {documentLink.name}
                      </Link>
                    }
                    onChange={(item) =>
                      handleDocumentLinkSelection(item.target.checked, {
                        code: documentLink.name,
                        name: documentLink.name,
                        link: documentLink.link,
                      })
                    }
                    checked={isDocumentLinkChecked(documentLink.name)}
                  />
                );
              } else {
                return '';
              }
            })}
          </div>
          <div className={classes.column}>
            {documentLinks.map((documentLink, index) => {
              if (index > 3) {
                return (
                  <Checkbox
                    key={`document-link-${index}`}
                    className={classes.links}
                    label={
                      <Link to={documentLink.link} openInNewTab>
                        {documentLink.name}
                      </Link>
                    }
                    onChange={(item) =>
                      handleDocumentLinkSelection(item.target.checked, {
                        code: documentLink.name,
                        name: documentLink.name,
                        link: documentLink.link,
                      })
                    }
                    checked={isDocumentLinkChecked(documentLink.name)}
                  />
                );
              } else {
                return '';
              }
            })}
          </div>
        </div>
      </>
    );
  };

  const renderIsSelfLead = () => {
    return (
      <>
        <br></br>
        <EmailFamilyLetterFilter
          className={classes.filter}
          labels={labels}
          contacts={data.findFamilyFileById.contacts}
          disabled={isDisabled}
          onContactsSelected={onContactsSelected}
          multiline
          isMandatory
        />
        <TextInput
          className={classes.default_text_area}
          multiline
          maxLength={2500}
          value={letterInfo.additionalNotes}
          onChange={(additionalNotes) => handleAdditionalNotes(additionalNotes)}
          rows={3}
          label={labels.ADDITIONAL_NOTES_FOR_THE_FAMILY}
        />
        {renderHelpfulLinks()}
      </>
    );
  };

  const goToCommunitiesPage = () => {
    history.push(`/family-file/${familyFileId}`);
  };

  const goToCommunitiesPageButton = (type) => (
    <Button size="small" type={type} onClick={goToCommunitiesPage}>
      {labels.GO_TO_COMMUNITIES_PAGE}
    </Button>
  );

  const renderSuccessModalBody = () => {
    return (
      <>
        <SendSuccessMessage labels={labels} contacts={elementsSend} />
        <div className={classes.buttons_container}>
          {goToCommunitiesPageButton()}
        </div>
      </>
    );
  };

  const renderDocumentTitle = () => {
    return labels.PRIMARY_CONTACT_NAME_FAMILY_LETTER_TITLE.replace(
      '{primaryContactName}',
      getPrimaryContactFullName(),
    );
  };

  return (
    <>
      <DocumentHead title={renderDocumentTitle()} />
      <Alert
        type="success"
        title={labels.NOTES_SAVED}
        description={labels.SUCCESSFULLY_SAVED_FAMILY_LETTER_NOTES}
        confirmText={labels.OK}
        onConfirm={() => {
          setIsNoteAlertOpen(false);
        }}
        onClose={() => {
          setIsNoteAlertOpen(false);
        }}
        isOpen={isNoteAlertOpen}
      />
      <Alert
        isOpen={isSuccessAlertOpen}
        modalBody={renderSuccessModalBody()}
        showCloseButton={false}
        title={labels.SUCCESS}
        type="success"
      />
      <div className={classes.send_letter_container}>
        <CaseProfileSidePanel
          familyFileId={familyFileId}
          labels={labels}
          isOpen={isSidePanelOpen}
          onClose={() => setIsSidePanelOpen(false)}
        />
        <div className={classes.top_bar}>
          <Button
            className={clsx(
              flags.communitySearchUiFromYgl && classes.disabled_button,
            )}
            startIcon={<User />}
            onClick={() => setIsSidePanelOpen(true)}
          >
            <Typography color="platinum5">
              {`${getPrimaryContactFullName()} (${labels.ID}: ${familyFileId})`}
            </Typography>
          </Button>
        </div>
        {communities.length > 0 && (
          <div className={classes.body}>
            <div className={classes.left_item}>
              <div className={classes.left_item_content}>
                <Typography bold level="large">
                  {labels.FAMILY_LETTER_MANAGER}
                </Typography>
                {errorSendingEmail && (
                  <Toast type="error" className={classes.warning_toast}>
                    <Typography>
                      {labels.UNABLE_TO_SEND_DUE_TO_ERROR}
                    </Typography>
                  </Toast>
                )}
                {errorSavingSettings && (
                  <Toast type="error" className={classes.warning_toast}>
                    <Typography>
                      {labels.UNABLE_TO_SAVE_COMMUNITY_NOTE_DUE_TO_ERROR}
                    </Typography>
                  </Toast>
                )}
                {!getPrimaryContactEmail() && (
                  <Toast type="error" className={classes.warning_toast}>
                    <div className={classes.column}>
                      <Typography>
                        {labels.YOU_ARE_MISSING_FAMILYFILE_INFORMATION}
                        <br />
                        <br />
                        {labels.PRIMARY_CONTACT_EMAIL_PART1}{' '}
                        <Link to={emailLink} openInNewTab>
                          {labels.HELP_SENDING_FAMILY_LETTER_EMAIL}
                        </Link>{' '}
                        {labels.PRIMARY_CONTACT_EMAIL_PART2}
                        <br />
                        <br />
                        {flags?.updateContactsOwl ? (
                          <Link to={`/family-file/${familyFileId}`}>
                            {labels.GO_BACK_TO_COMMUNITY_PAGE_TO_COMPLETE_INFO}
                          </Link>
                        ) : (
                          <YglLink
                            url="YGL_CASE_PAGE_URL"
                            externalId={externalId}
                            hasError={fetchError}
                            type="link"
                          >
                            {labels.GO_BACK_TO_YGL_TO_COMPLETE_INFO}
                          </YglLink>
                        )}
                        <br />
                      </Typography>
                    </div>
                  </Toast>
                )}
                <Dropdown
                  items={emailTemplates}
                  label={labels.SELECT_EMAIL_TEMPLATE}
                  selectedItem={{
                    label: letterInfo.selectedTemplate,
                    value: letterInfo.selectedTemplate,
                  }}
                  className={classes.email_template_dropdown}
                  onChange={handleTemplateSelection}
                />
                {isSelfLead ? renderIsSelfLead() : renderIsNotSelfLead()}
              </div>
              <div className={classes.left_item_footer}>
                <div className={classes.clear} onClick={handleClearChanges}>
                  <Typography level="small">{labels.CLEAR_CHANGES}</Typography>
                </div>
                <Button type={'light'} onClick={handleSaveSettings}>
                  <Typography level="small">{labels.SAVE_SETTINGS}</Typography>
                </Button>
                <Button
                  type={
                    sendButtonEnabled && getPrimaryContactEmail()
                      ? 'secondary'
                      : 'disabled'
                  }
                  startIcon={<Send />}
                  onClick={handleSend}
                >
                  <Typography level="small" color="platinum5">
                    {labels.SEND_NOW}
                  </Typography>
                </Button>
              </div>
            </div>
          </div>
        )}
        {communities.length === 0 && (
          <div className={classes.empty_body}>
            <div className={classes.no_referrals_container}>
              <Refer className={classes.referral_icon} />
              <Typography level={'large'} bold>
                {labels.NO_REFERRED_COMMUNITIES}
              </Typography>
              <Typography className={classes.no_referrals_text}>
                {
                  labels.TRYING_TO_SEND_FAMILY_LETTER_WITH_NO_REFERRED_COMMUNITITES
                }
              </Typography>
              <Typography className={classes.no_referrals_text}>
                {labels.PLEASE_MAKE_SURE_YOU_HAVE_SENT_THE_REFERRAL_FIRST}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

SendFamilyFileLetter.propTypes = {
  labels: PropTypes.object,
  flags: PropTypes.shape({
    communitySearchUiFromYgl: PropTypes.bool,
    updateContactsOwl: PropTypes.bool,
  }),
  currentUser: PropTypes.object,
};

export default withLDConsumer()(withUser(withLabels(SendFamilyFileLetter)));
