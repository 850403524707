import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles(() => {
  return {
    divider: {
      width: '100%',
      height: '1px',
      margin: (spacing) => `${spacing * 8}px 0`,
    },
  };
});

export { useStyles };
