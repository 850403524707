import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './LastContactedLG.style';
import Typography from 'common/Typography/Typography';
import { format } from 'common/utils/dateFormat';
import { getText } from '../services/getActionTextService';
import { getIcon } from '../services/getActionIconService';

const LastContactedLG = ({ lastContacted, labels }) => {
  const classes = useStyles();
  return (
    <div className={classes.last_contacted_container}>
      {!lastContacted && (
        <Typography color="eerieBlack5">{labels.NOT_CONTACTED_YET}</Typography>
      )}
      {lastContacted && (
        <>
          <div className={classes.title}>
            {getIcon(lastContacted, classes)}
            <Typography bold>{getText(lastContacted)}</Typography>
          </div>
          <div className={classes.date}>
            <Typography color="eerieBlack5">
              {format(lastContacted.date, 'DDD, MMM DD')}
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};

LastContactedLG.propTypes = {
  lastContacted: PropTypes.object,
  labels: PropTypes.object,
};

export default LastContactedLG;
