import React from 'react';
import PropTypes from 'prop-types';
import FamilyFileHistory from './FamilyFileHistory/FamilyFileHistory';
import ToDoTasks from './PendingTasks/ToDoTasks';
import Divider from '../../common/Divider/Divider';
import { useStyles } from './Activity.style';

const ActivityMobile = ({
  labels,
  familyFileId,
  toDoTasks,
  pastActions,
  handleRefetchState,
  completedActionMessage,
  moreTasks,
  dataForSegment,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.activity_mobile_container}>
      <ToDoTasks
        familyFileId={familyFileId}
        labels={labels}
        toDoTasks={toDoTasks}
        moreTasks={moreTasks}
        shouldRefetchCallback={handleRefetchState}
        completedActionMessage={completedActionMessage}
        dataForSegment={dataForSegment}
        yglLinkType={'link'}
      />

      {pastActions.length > 0 && <Divider spacing={4} />}
      <FamilyFileHistory labels={labels} items={pastActions} />
    </div>
  );
};

ActivityMobile.propTypes = {
  labels: PropTypes.object.isRequired,
  familyFileId: PropTypes.number.isRequired,
  toDoTasks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      dueDate: PropTypes.number.isRequired,
      actionId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  moreTasks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      dueDate: PropTypes.number.isRequired,
      actionId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  pastActions: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.number,
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          dueDate: PropTypes.number.isRequired,
          actionId: PropTypes.number.isRequired,
        }),
      ),
    }),
  ),
  handleRefetchState: PropTypes.func,
  completedActionMessage: PropTypes.string,
  dataForSegment: PropTypes.shape({
    familyFileId: PropTypes.number,
    oneId: PropTypes.string,
  }),
};

export default ActivityMobile;
