import gql from 'graphql-tag';

export default gql`
  query($familyFileId: Int!) {
    findFamilyFileById(id: $familyFileId) {
      familyFileId
      oneId
      audienceKey
      salesPhase
      residents {
        profileFormDataId
        residentId
        careTypes {
          careTypeId
          code
          name
        }
        profileData {
          firstName
          lastName
          address1
          address2
          city
        }
      }
      notes {
        note
        createdAt
        noteType {
          noteTypeCode
        }
        createdBy {
          firstName
          lastName
        }
      }
      contacts {
        profileFormDataId
        contactId
        profileData {
          formDataId
          firstName
          lastName
          isPrimary
          relationToResidentId
          relationToResident
          primaryPhone
          emailAddress
          secondaryPhone
          secondaryPhoneType
          primaryPhoneType
          address1
          address2
          isInquirer
          allowMailing
          allowContact
          postalCode
          city
          state
          country
          phones {
            isPrimaryForCategory
            areaCode
            localNumber
            subCategoryCode
            countryCode
          }
          emailAddresses {
            isPrimary
            emailAddress
          }
        }
      }
      keyNote {
        keyNoteId
        noteText
      }
      questionsData {
        formDataId
        age
        rehab
        memoryLoss
        memoryDiagnosis
        wandering
        currentLiving
        locationName
        medications
        bathing
        toileting
        diabetic
        getAround
      }
      leadData {
        formDataId
        doNotCall
        expectedMoveDate
        budgetDescription
        financialResources
        likelihoodToMovePct
        longTermCare
        vaAid
        veteran
      }
      tourDetailsExtended {
        communityId
        scheduledTourDate
      }
      tourCommunitiesExtended {
        communities {
          id
          name
        }
      }
      familyFileCommunityPreferences {
        locationRange
        roomPreference
      }
      familyDesiredLocations {
        familyDesiredLocationId
        isPrimary
        city
        state
        zip
      }
      oneId
      medicaid
      createdAt
      user {
        firstName
        lastName
      }
    }
  }
`;
