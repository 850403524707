import gql from 'graphql-tag';

export default gql`
  mutation(
    $familyCommunityTourId: Int!
    $activityDate: Date!
    $tourType: String!
    $activityTypeCode: String!
    $timeOffset: Int!
    $referral: ReferralInput
    $userName: String!
    $userRole: String!
  ) {
    updateScheduledTour(
      input: {
        familyCommunityTourId: $familyCommunityTourId
        activityDate: $activityDate
        tourType: $tourType
        activityTypeCode: $activityTypeCode
        timeOffset: $timeOffset
        referral: $referral
        userName: $userName
        userRole: $userRole
      }
    ) {
      familyFileId
      familyCommunityTourId
      communityId
      activityDate
      activityTypeCode
      tourType
      currentStatus
      latestActivity {
        familyCommunityTourActivityId
        familyCommunityTourId
        status
        activityDate
        tourType
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
