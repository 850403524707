import React from 'react';

const NoActiveCall = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.6332 32.7771L59.4371 30.581L79.6379 10.3799L86.3187 17.0607C90.1829 20.9249 91.5335 26.6418 89.8049 31.8261C80.6798 59.2013 59.1989 80.6823 31.8237 89.8073H31.8151C26.6277 91.5374 20.9093 90.1869 17.0438 86.3184C13.7193 82.9909 10.3774 79.6405 10.3774 79.6405L30.5784 59.4396L32.7745 61.6356C35.692 64.5547 40.2648 65.0062 43.6988 62.7179C51.2309 57.6966 57.6938 51.2336 62.7153 43.7014C65.0053 40.2672 64.5522 35.6946 61.6332 32.7771Z"
        fill="#E9CE73"
      />
      <path
        d="M10.1291 71.2307L22.1689 59.1909C24.4227 56.937 28.0762 56.937 30.3301 59.1909L30.5783 59.4391L10.3771 79.64L10.1289 79.3919C7.87659 77.138 7.87659 73.4845 10.1291 71.2307Z"
        fill="#B5C3F1"
      />
      <path
        d="M71.2284 10.1313L59.1885 22.1711C56.9347 24.4249 56.9347 28.0784 59.1885 30.3322L59.4367 30.5804L79.6377 10.3795L79.3895 10.1313C77.1357 7.87877 73.4822 7.87877 71.2284 10.1313Z"
        fill="#B5C3F1"
      />
      <path
        d="M86.3186 17.0605L82.3289 13.0708C80.1126 48.0027 57.4167 78.3057 24.5178 90.2589C26.9506 90.7408 29.4656 90.5864 31.8205 89.8101H31.8292C59.203 80.6836 80.6825 59.2013 89.8047 31.8259C91.5333 26.6414 90.1842 20.9245 86.3186 17.0605Z"
        fill="#D8A600"
      />
      <path
        d="M16.0377 87.3408C18.9986 90.3204 23.023 91.9972 27.2234 92C28.9405 91.9972 30.646 91.7186 32.2736 91.1746C60.0427 81.8475 81.8411 60.0521 91.1725 32.2828C93.0945 26.5803 91.6083 20.2805 87.3387 16.0398L80.4127 9.11378C77.5961 6.29566 73.0278 6.29566 70.2097 9.11228L70.2082 9.11378L58.1712 21.1508C55.3531 23.9674 55.3531 28.5356 58.1697 31.3538L58.1712 31.3553L59.001 32.185C62.3601 35.5599 62.8824 40.8324 60.2491 44.8005C56.2117 50.8435 50.7561 55.8072 44.3595 59.2587L43.1604 59.9052C39.2775 62.0205 34.4638 61.3135 31.3515 58.1736C28.5349 55.3555 23.9667 55.3555 21.1485 58.1721L21.147 58.1736L9.11023 70.2089C6.29659 73.0284 6.29659 77.5938 9.11023 80.4133L16.0377 87.3408ZM60.2102 23.1896L72.2472 11.1526C73.6411 9.76888 75.7925 9.49611 77.4865 10.4904L59.5478 28.4289C58.5552 26.7349 58.8265 24.5835 60.2102 23.1896ZM44.5284 62.4445L45.7275 61.7996C52.5396 58.1245 58.3502 52.836 62.6487 46.3991C65.9097 41.4787 65.4192 34.9754 61.4582 30.5991L79.6377 12.4197L85.2984 18.0803C88.7917 21.5505 90.0081 26.7047 88.4353 31.3697C79.3967 58.2789 58.2766 79.4006 31.3673 88.442C26.7023 90.0133 21.5481 88.7969 18.0779 85.3051L12.4173 79.6401L30.6705 61.3869C34.679 64.4387 40.1044 64.8528 44.5284 62.4445ZM11.1506 72.2564L23.1875 60.2194C24.5756 58.8227 26.7356 58.55 28.4268 59.5572L10.4883 77.4886C9.49422 75.7945 9.76682 73.6431 11.1506 72.2493V72.2564Z"
        fill="#0D1321"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.8679 36.6085C35.8679 38.6014 34.2523 40.217 32.2594 40.217C30.2665 40.217 28.6509 38.6014 28.6509 36.6085C28.6509 34.6156 30.2665 33 32.2594 33C34.2523 33 35.8679 34.6156 35.8679 36.6085ZM24.6416 37.0711C24.6416 39.0641 23.026 40.6796 21.0331 40.6796C19.0401 40.6796 17.4246 39.0641 17.4246 37.0711C17.4246 35.0782 19.0401 33.4626 21.0331 33.4626C23.026 33.4626 24.6416 35.0782 24.6416 37.0711ZM43.4859 40.217C45.4789 40.217 47.0944 38.6014 47.0944 36.6085C47.0944 34.6156 45.4789 33 43.4859 33C41.493 33 39.8774 34.6156 39.8774 36.6085C39.8774 38.6014 41.493 40.217 43.4859 40.217Z"
        fill="#A37D00"
      />
    </svg>
  );
};

export default NoActiveCall;
