import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './ExpandableRow.style';
import Typography from '../Typography/Typography';
import Plus from '../Icons/basic/Plus';
import Minus from '../Icons/basic/Minus';
import clsx from 'clsx';

const ExpandableRow = ({
  title,
  children,
  className,
  defaultState,
  expandText,
  collapseText,
  onClickHandler,
}) => {
  const classes = useStyles();
  const containerClasses = clsx(classes.container, className);

  const [isExpanded, setIsExpanded] = useState(defaultState);

  useEffect(() => {
    setIsExpanded(defaultState);
    // eslint-disable-next-line
  }, [defaultState]);

  const handleClickEvent = () => {
    setIsExpanded(!isExpanded);
    onClickHandler(!isExpanded);
  };

  const ViewMore = () => {
    return (
      <Fragment>
        <div className={classes.view_more}>
          <Typography level="small">{expandText}</Typography>
          <Plus />
        </div>
      </Fragment>
    );
  };

  const ViewLess = () => {
    return (
      <Fragment>
        <div className={classes.view_more}>
          <Typography level="small">{collapseText}</Typography>
          <Minus />
        </div>
      </Fragment>
    );
  };

  return (
    <div className={containerClasses}>
      <div
        data-testid="expandable-title-id"
        className={clsx(
          classes.title,
          isExpanded ? classes.title_expanded : '',
        )}
        onClick={handleClickEvent}
      >
        <div className={classes.left_title}>{title}</div>
        <div className={classes.icon}>
          {isExpanded ? <ViewLess /> : <ViewMore />}
        </div>
      </div>
      {isExpanded && <div className={classes.body}>{children}</div>}
    </div>
  );
};

ExpandableRow.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
  expandText: PropTypes.string,
  collapseText: PropTypes.string,
  defaultState: PropTypes.bool,
  onClickHandler: PropTypes.func,
};

ExpandableRow.defaultProps = {
  className: '',
  expandText: 'view more',
  collapseText: 'view less',
  defaultState: false,
  onClickHandler: () => '',
};

export default ExpandableRow;
