const stringComparator = (a, b) => {
  const sanitizedA = a.toLowerCase();
  const sanitizedB = b.toLowerCase();

  if (sanitizedA < sanitizedB) {
    return -1;
  }
  if (sanitizedA > sanitizedB) {
    return 1;
  }

  return 0;
};

/**
 * Converts a String array to a single string that delimits the entries with a pipe character. This is helpful when
 * working with analytics, where the tooling makes it difficult to analyze full arrays.
 */
function convertArrayToSortedPipeDelimitedString(list) {
  return [...list].sort(stringComparator).join('|');
}

export default convertArrayToSortedPipeDelimitedString;
