import withConfig from 'utils/withConfig';

const getItems = (labels) => {
  return [
    {
      label: labels.RESOURCE_LINE,
      value: 'resourceLine',
      phoneNumber: withConfig('RESOURCE_LINE_PHONE_NUMBER'),
      closeInquiryReason: null,
    },
    {
      label: labels.CORPORATE,
      value: 'corporate',
      phoneNumber: withConfig('CORPORATE_PHONE_NUMBER'),
      closeInquiryReason: labels.CORPORATE_INQUIRY_REASON,
    },
    {
      label: labels.CUSTOMER_EXPERIENCE,
      value: 'customerExperience',
      phoneNumber: withConfig('CUSTOMER_EXPERIENCE_TEAM_PHONE_NUMBER'),
      closeInquiryReason: labels.COMMUNITY_CUSTOMER_INQUIRY_REASON,
    },
    {
      label: labels.SENIOR_ADVISOR_REVIEW,
      value: 'seniorAdvisorReview',
      phoneNumber: withConfig('SENIOR_ADVISOR_TEAM_PHONE_NUMBER'),
      closeInquiryReason: labels.NOT_SEARCHING_OTHER_REASON,
    },
    {
      label: labels.SPANISH_LINE,
      value: 'spanishLine',
      phoneNumber: withConfig('SPANISH_LINE_PHONE_NUMBER'),
      closeInquiryReason: labels.NON_ENGLISH_SPEAKER_REASON,
    },
  ];
};

export { getItems };
