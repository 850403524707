import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CircleFilledChecked from 'common/Icons/basic/CircleFilledChecked';
import AlertCircle from 'common/Icons/notifications/AlertCircle';
import { useBackgroundColorStyles } from 'common/style/backgroundColor';
import Typography from 'common/Typography/Typography';
import { useStyles } from './ChicletCard.style';
import CircleMinus from 'common/Icons/basic/CircleMinus';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import { selectCollapsedState } from 'stateManagement/callcenter/formChiclets/reducers/formChicletsReducer';

export const CHICLET_CARD_STATES = {
  CURRENT: {
    value: 'CURRENT',
    icon: <AlertCircle />,
    tooltip: 'REQUIRED',
  },
  ANSWERED: {
    value: 'ANSWERED',
    icon: <CircleFilledChecked />,
    tooltip: 'COMPLETED',
  },
  NOT_CURRENT: {
    value: 'NOT_CURRENT',
    icon: <AlertCircle />,
    tooltip: 'REQUIRED',
  },
  INACTIVE: {
    value: 'INACTIVE',
    icon: <AlertCircle />,
    tooltip: 'REQUIRED',
  },
  ERROR: {
    value: 'ERROR',
    icon: <AlertCircle />,
    tooltip: 'REQUIRED',
  },
  CURRENT_WITH_ERROR: {
    value: 'CURRENT_WITH_ERROR',
    icon: <AlertCircle />,
    tooltip: 'REQUIRED',
  },
};

const ChicletCard = ({
  title,
  children,
  className,
  state,
  actions,
  span,
  hidden,
  testId,
  optional,
  onClose,
  collapsed,
  id,
  labels,
  disabled,
}) => {
  const collapsedState = useSelector(selectCollapsedState);
  const classes = useStyles();
  const backgroundClasses = useBackgroundColorStyles();
  const stateClass = classes[state.toLowerCase()];
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const visibilityClass = hidden ? classes.hidden : classes.shown;

  useEffect(() => {
    setIsCollapsed(collapsed);
  }, [collapsed]);
  const inTransferOrRescheduleFlow = disabled || !isCollapsed;
  const inNormalFlow =
    disabled || !isCollapsed || state !== CHICLET_CARD_STATES.ANSWERED.value;

  const showChicletBody = collapsedState
    ? inTransferOrRescheduleFlow
    : inNormalFlow;
  return (
    <div
      className={clsx(
        classes.chiclet_container,
        !collapsed && classes.chiclet_container_shadow,
        visibilityClass,
        disabled && 'disabled',
      )}
      style={{ gridColumnStart: `span ${span}` }}
    >
      {disabled && !hidden && <div className={classes.disabled} />}
      <div
        data-testid={testId}
        className={clsx(classes.container, className, stateClass)}
        id={id}
      >
        <div
          className={clsx(
            !optional ? classes.head : classes.optional_head,
            backgroundClasses.platinum4,
          )}
          data-testid={`${testId}_header`}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <Typography color="eerieBlack1" level="small">
            <b>{title}</b>
          </Typography>
          {!optional && (
            <SmartTooltip title={labels[CHICLET_CARD_STATES[state].tooltip]}>
              {CHICLET_CARD_STATES[state].icon}
            </SmartTooltip>
          )}
          {optional && (
            <div onClick={() => onClose()}>
              <SmartTooltip title={labels.OPTIONAL}>
                <CircleMinus />
              </SmartTooltip>
            </div>
          )}
        </div>
        {showChicletBody && (
          <div className={classes.body}>
            <div
              data-testid={`${testId}_body`}
              className={classes.chiclet_body}
            >
              {children}
            </div>
            <div data-testid={`${testId}_actions`}>{actions}</div>
          </div>
        )}
      </div>
    </div>
  );
};

ChicletCard.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  state: PropTypes.oneOf(Object.keys(CHICLET_CARD_STATES)),
  children: PropTypes.node.isRequired,
  actions: PropTypes.node.isRequired,
  testId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  span: PropTypes.number,
  hidden: PropTypes.bool,
  optional: PropTypes.bool,
  collapsed: PropTypes.bool,
  labels: PropTypes.object,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
};

ChicletCard.defaultProps = {
  stateIcon: <div />,
  className: '',
  span: 1,
  hidden: false,
  optional: false,
  collapsed: false,
  disabled: false,
};

export default ChicletCard;
