import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles(() => {
  return {
    common: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
    h1: {
      fontWeight: 800,
      fontSize: '48px',
      lineHeight: '1.1',
      '& div': {
        display: 'inline',
      },
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '38px',
      lineHeight: '1.1',
      '& div': {
        display: 'inline',
      },
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '28px',
      lineHeight: '1.1',
      '& div': {
        display: 'inline',
      },
    },
    h4: {
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: '1.45',
      '& div': {
        display: 'inline',
      },
    },
    body: {
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: '1.45',
      '& div': {
        display: 'inline',
      },
    },
    large: {
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '1.45',
      '& div': {
        display: 'inline',
      },
    },
    small: {
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '1.45',
      '& div': {
        display: 'inline',
      },
    },
    tiny: {
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '1.6',
      '& div': {
        display: 'inline',
      },
    },
    underlined: {
      textDecoration: 'underline',
    },
  };
});

export { useStyles };
