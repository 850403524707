import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RowCard from 'common/RowCard/RowCard';
import HeaderActivityCard from './HeaderActivityCard';
import Divider from 'common/Divider/Divider';
import Typography from 'common/Typography/Typography';
import labels from 'config/labels';
import Radio from 'common/Radio/Radio';
import Button from 'common/Button/Button';
import { useStyles } from './EditActivityCard.style';
import completeActionMutation from './completeActionMutation';
import completeFamilyCallMutation from './completeFamilyCallMutation';
import useGraphQLMutation from 'hooks/useGraphQLMutation';

const EditActivityCard = ({
  mode,
  className,
  type,
  date,
  color,
  dueLabel,
  description,
  onCancel,
  actionId,
  shouldRefetchCallback,
  dataForSegment,
}) => {
  const classes = useStyles();

  const [selectedItem, setSelectedItem] = useState({
    value: '',
  });
  const [actionCompleted, setActionCompleted] = useState(false);
  const isCallFamilyType = type === 'Call - Family';

  const [submitButtonStatus, setSubmitButtonStatus] = useState(
    isCallFamilyType ? 'disabled' : 'secondary',
  );

  const [completeAction] = useGraphQLMutation(completeActionMutation);
  const [completeFamilyCall] = useGraphQLMutation(completeFamilyCallMutation);

  useEffect(() => {
    const triggerCompleteCallFamily = async () => {
      await completeFamilyCall({
        variables: {
          actionId: actionId,
          madeContactCode: selectedItem.value,
          familyFileId: dataForSegment.familyFileId,
          oneId: dataForSegment.oneId,
        },
      })
        .then((data) => {
          if (data.data.completeFamilyCall.madeContactCode) refetchCallback();
        })
        .catch((error) => {
          console.error(`Error completing action ${error}`);
        });
    };

    const triggerCompleteAction = async () => {
      await completeAction({
        variables: {
          actionId: actionId,
          familyFileId: dataForSegment.familyFileId,
          oneId: dataForSegment.oneId,
        },
      })
        .then((data) => {
          if (data.data.completeAction.actionId) refetchCallback();
        })
        .catch((error) => {
          console.error(`Error completing action ${error}`);
        });
    };

    const refetchCallback = () => {
      shouldRefetchCallback({
        completed: actionCompleted,
        type: type,
      });
    };

    const runMutation = async () => {
      if (actionCompleted) {
        isCallFamilyType
          ? triggerCompleteCallFamily()
          : triggerCompleteAction();
      }
    };

    runMutation();
    // eslint-disable-next-line
  }, [actionCompleted]);

  const handleSubmitAction = () => {
    setActionCompleted(true);
  };

  const handleOnChange = (item) => {
    setSelectedItem(item);
    setSubmitButtonStatus('secondary');
  };

  return (
    <RowCard className={className} color={color}>
      <HeaderActivityCard
        mode={mode}
        type={type}
        date={date}
        color={color}
        dueLabel={dueLabel}
      />
      <div className={classes.main_container}>
        <div className={classes.row}>
          <div className={classes.description_container}>
            <div className={classes.description}>
              <Typography color="eerieBlack5">{description}</Typography>
            </div>
          </div>
        </div>
        <div className={classes.complete_actions_container}>
          <Divider color="platinum1" />
          <div>
            <Typography level="small" color="eerieBlack5">
              {labels.RESULT}
            </Typography>
            {isCallFamilyType && (
              <Radio
                className={classes.complete_action_options}
                name="complete-options"
                selected={selectedItem}
                onChange={handleOnChange}
                items={[
                  { label: 'Talked to family', value: 'Yes' },
                  { label: 'Left VM', value: 'LM' },
                  { label: 'No contact', value: 'No' },
                ]}
              />
            )}
          </div>
          <div className={classes.actions}>
            <Button type={submitButtonStatus} onClick={handleSubmitAction}>
              {labels.COMPLETE_TASK}
            </Button>
            <Button type="light" onClick={onCancel}>
              {labels.CANCEL}
            </Button>
          </div>
        </div>
      </div>
    </RowCard>
  );
};

EditActivityCard.propTypes = {
  className: PropTypes.string,
  dueLabel: PropTypes.string,
  mode: PropTypes.oneOf(['todo', 'upcoming', 'history']),
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.number.isRequired,
  onCancel: PropTypes.func,
  shouldRefetchCallback: PropTypes.func,
  color: PropTypes.string,
  actionId: PropTypes.number.isRequired,
  dataForSegment: PropTypes.shape({
    familyFileId: PropTypes.number,
    oneId: PropTypes.string,
  }),
};

EditActivityCard.defaultProps = {
  color: 'transparent',
  className: '',
  dueLabel: 'Due',
  mode: 'history',
};

export default EditActivityCard;
