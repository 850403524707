import React from 'react';
import PropTypes from 'prop-types';

const PinStart = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M12.6577 22.7533L12 23.3276L11.3423 22.7533C5.81131 17.9237 3 13.7068 3 10C3 4.75066 7.09705 1 12 1C16.9029 1 21 4.75066 21 10C21 13.7068 18.1887 17.9237 12.6577 22.7533ZM5 10C5 12.8492 7.30661 16.4335 12 20.6634C16.6934 16.4335 19 12.8492 19 10C19 5.8966 15.8359 3 12 3C8.16411 3 5 5.8966 5 10ZM7 11H13.5858L11.2929 13.2929L12.7071 14.7071L17.4142 10L12.7071 5.29289L11.2929 6.70711L13.5858 9H7V11Z"
      />
    </svg>
  );
};

PinStart.propTypes = {
  className: PropTypes.string,
};

export default PinStart;
