const getDropdownItems = () => {
  return [
    {
      label: 'Family File ID',
      value: 'FAMILY_FILE_ID',
      placeholder: 'Enter Family File ID number',
    },
    {
      label: 'Resident Name',
      value: 'RESIDENT_NAME',
      placeholder: "Enter Resident's name",
    },
    {
      label: 'Primary Contact Name',
      value: 'PRIMARY_CONTACT_NAME',
      placeholder: "Enter Primary Contact's name",
    },
    {
      label: 'Email Address',
      value: 'EMAIL_ADDRESS',
      placeholder: 'Enter Email address',
    },
    {
      label: 'Phone Number',
      value: 'PHONE_NUMBER',
      placeholder: 'Enter Contact number (ex: Home Phone)',
    },
  ];
};

export { getDropdownItems };
