import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './Timeline.style';
import TimelineItem from '../TimelineItem/TimelineItem';

const Timeline = ({ items }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {items.map((item, index) => (
        <TimelineItem key={index} color={item.color}>
          {item.component}
        </TimelineItem>
      ))}
    </div>
  );
};

Timeline.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.oneOf([
        'eerieBlack1',
        'eerieBlack2',
        'eerieBlack3',
        'eerieBlack4',
        'eerieBlack5',
        'disabled',
        'divider',
        'platinum1',
        'platinum2',
        'platinum3',
        'platinum4',
        'platinum5',
        'white',
        'cosmicCobalt1',
        'cosmicCobalt2',
        'cosmicCobalt3',
        'cosmicCobalt4',
        'cosmicCobalt5',
        'regalia1',
        'regalia2',
        'regalia3',
        'regalia4',
        'regalia5',
        'wintergreenDream1',
        'wintergreenDream2',
        'wintergreenDream3',
        'wintergreenDream4',
        'wintergreenDream5',
        'smokyTopaz1',
        'smokyTopaz2',
        'smokyTopaz3',
        'smokyTopaz4',
        'smokyTopaz5',
        'hotCinnamon1',
        'hotCinnamon2',
        'hotCinnamon3',
        'hotCinnamon4',
        'hotCinnamon5',
        'fieldDrab1',
        'fieldDrab2',
        'fieldDrab3',
        'fieldDrab4',
        'fieldDrab5',
      ]).isRequired,
      component: PropTypes.node.isRequired,
    }),
  ).isRequired,
};

Timeline.defaultProps = {};

export default Timeline;
