export const LEVEL = {
  IMPORTANT: 'IMPORTANT',
  WARNING: 'WARNING',
  INFORMATIONAL: 'INFORMATIONAL',
};

export const ADVISORY_TYPE = {
  NATURAL_DISASTER: 'NATURAL DISASTER ADVISORY',
  HEALTH: 'HEALTH ADVISORY',
  CONSTRUCTION: 'CONSTRUCTION ADVISORY',
};

export const ADVISORY_TYPE_HEADER = {
  HEALTH: 'Health: COVID-19',
  NATURAL_DISASTER: 'Natural Disaster',
  CONSTRUCTION: 'Construction',
};

const isWithinEffectiveDate = (effectiveEndDate) => {
  if (effectiveEndDate) {
    const today = new Date().setHours(0, 0, 0, 0);
    return new Date(effectiveEndDate) >= today;
  }
  return true;
};

const getRestrictionDetailsByLevel = (level, advisory) => {
  let details = [];
  if (
    advisory.activityRestrictionLevel.toUpperCase() === level &&
    advisory.activityRestrictionDetails &&
    advisory.activityRestriction !== 'no' &&
    isWithinEffectiveDate(advisory.activityRestrictionEndDate)
  ) {
    details.push(advisory.activityRestrictionDetails);
  }
  if (
    advisory.evacuationInformationLevel.toUpperCase() === level &&
    advisory.evacuationInformationDetails &&
    advisory.evacuationInformation !== 'no' &&
    isWithinEffectiveDate(advisory.evacuationInformationEndDate)
  ) {
    details.push(advisory.evacuationInformationDetails);
  }
  if (
    advisory.tourRestrictionLevel.toUpperCase() === level &&
    advisory.tourRestrictionDetails &&
    advisory.tourRestriction !== 'no' &&
    isWithinEffectiveDate(advisory.tourRestrictionEndDate)
  ) {
    details.push(advisory.tourRestrictionDetails);
  }
  if (
    advisory.moveInRestrictionLevel.toUpperCase() === level &&
    advisory.moveInRestrictionDetails &&
    advisory.moveInRestriction !== 'no' &&
    isWithinEffectiveDate(advisory.moveInRestrictionEndDate)
  ) {
    details.push(advisory.moveInRestrictionDetails);
  }
  if (
    advisory.assessmentRestrictionLevel.toUpperCase() === level &&
    advisory.assessmentRestrictionDetails &&
    advisory.assessmentRestriction !== 'no' &&
    isWithinEffectiveDate(advisory.assessmentRestrictionEndDate)
  ) {
    details.push(advisory.assessmentRestrictionDetails);
  }
  if (
    advisory.diningRestrictionLevel.toUpperCase() === level &&
    advisory.diningRestrictionDetails &&
    advisory.diningRestriction !== 'no' &&
    isWithinEffectiveDate(advisory.diningRestrictionEndDate)
  ) {
    details.push(advisory.diningRestrictionDetails);
  }
  if (
    advisory.visitorRestrictionLevel.toUpperCase() === level &&
    advisory.visitorRestrictionDetails &&
    advisory.visitorRestriction !== 'no' &&
    isWithinEffectiveDate(advisory.visitorRestrictionEndDate)
  ) {
    details.push(advisory.visitorRestrictionDetails);
  }
  return details;
};

const findAdvisoryType = (advisories, advisoryType) => {
  return advisories.find(
    (advisory) => advisory.advisoryType.toUpperCase() === advisoryType,
  );
};

export const getActiveAdvisoryTypeLabels = (advisories) => {
  let advisoryLabels = [];
  if (advisories.length === 0) return [];

  const activeAdvisories = advisories.filter((advisory) => {
    return (
      advisory.advisoryStatus.toUpperCase() === 'OPEN' &&
      isWithinEffectiveDate(advisory.activityRestrictionEndDate)
    );
  });
  if (activeAdvisories.length > 0) {
    const healthAdvisory = findAdvisoryType(
      activeAdvisories,
      ADVISORY_TYPE.HEALTH,
    );
    if (healthAdvisory) {
      advisoryLabels.push('Health');
    }

    const constructionAdvisory = findAdvisoryType(
      activeAdvisories,
      ADVISORY_TYPE.CONSTRUCTION,
    );
    if (constructionAdvisory) {
      advisoryLabels.push('Construction');
    }

    const naturalDisasterAdvisory = findAdvisoryType(
      activeAdvisories,
      ADVISORY_TYPE.NATURAL_DISASTER,
    );
    if (naturalDisasterAdvisory) {
      advisoryLabels.push('Natural Disaster');
    }
  }
  return advisoryLabels;
};

export const getAdvisoryByType = (advisoryType, communityAdvisories) => {
  const activeAdvisory = communityAdvisories.find((advisory) => {
    return (
      advisory.advisoryType.toUpperCase() === advisoryType &&
      advisory.advisoryStatus.toUpperCase() === 'OPEN' &&
      isWithinEffectiveDate(advisory.advisoryEndDate)
    );
  });

  if (activeAdvisory) {
    return {
      effectiveStartDate: activeAdvisory.advisoryStartDate,
      effectiveEndDate: activeAdvisory.advisoryEndDate,
      description: activeAdvisory.advisoryDescription,
      restrictionDetails: {
        important: getRestrictionDetailsByLevel(
          LEVEL.IMPORTANT,
          activeAdvisory,
        ),
        warning: getRestrictionDetailsByLevel(LEVEL.WARNING, activeAdvisory),
        informational: getRestrictionDetailsByLevel(
          LEVEL.INFORMATIONAL,
          activeAdvisory,
        ),
      },
    };
  }
};

export const getCovidVaccineInformation = (advisories) => {
  let vaccineDetails = {
    isVaccineAvailable: false,
    details: '',
  };

  const vaccineAdvisories = advisories.filter((advisory) => {
    return advisory.vaccineInformation?.toUpperCase() === 'YES';
  });

  if (vaccineAdvisories.length > 0) {
    vaccineDetails = {
      isVaccineAvailable: true,
      details: vaccineAdvisories[0].vaccineInformationDetails || '',
    };
  }
  return vaccineDetails;
};
