import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './TableBody.style';
import clsx from 'clsx';

const TableBody = ({ className, children }) => {
  const classes = useStyles();
  const containerClasses = clsx(className, classes.t_body);
  return <tbody className={containerClasses}>{children}</tbody>;
};

TableBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default TableBody;
