import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ActionableTooltip from 'common/ActionableTooltip/ActionableTooltip.js';
import Copy from 'common/Icons/basic/Copy';
import Mail from 'common/Icons/basic/Mail';
import Send from 'common/Icons/basic/Send';
import CallPhone from 'common/Icons/call/CallPhone';
import CommentText from 'common/Icons/chatting/CommentText';
import Link from 'common/Link/Link';
import Typography from 'common/Typography/Typography';
import { withUser } from 'context/UserContext';
import chatboxClient from 'services/communication/text/Chatbox';
import withConfig from 'utils/withConfig';
import { useStyles } from './ContactInformation.style';
import Toast from 'common/Toast/Toast.js';
import { registerEvent } from 'services/Analytics';
import { formatPhoneWithParenthesis } from 'utils/phoneFormat.js';

const ContactInformationDisplay = ({
  labels,
  familyFileInfo,
  contact,
  title,
  currentUser,
  flags,
  dialerService,
}) => {
  const [telephonyError, setTelephonyError] = useState(null);
  const familyFileId = familyFileInfo.familyFileId;
  const careTypes = familyFileInfo.careTypes.map((careType) => {
    return careType.code;
  });
  const careTypeCodes = careTypes.toString();
  const doNotCall = familyFileInfo.doNotCall;

  let contactFirstName = '';
  if (contact && contact.firstName) {
    contactFirstName =
      contact.firstName.toUpperCase() === labels.UNKNOWN.toUpperCase()
        ? ''
        : contact.firstName;
  }

  const firstPhone = contact.contactDetail.phonePrimary;
  const secondPhone = contact.contactDetail.phoneSecondary;
  const tertiaryPhone = contact.contactDetail.tertiaryPhone;
  const email = contact.contactDetail.email;
  const firstPhoneType = contact.contactDetail.primaryPhoneType;
  const secondPhoneType = contact.contactDetail.secondaryPhoneType;
  const tertiaryPhoneType = contact.contactDetail.tertiaryPhoneType;
  const advisorFirstName = currentUser.firstName;
  const advisorEmail = `${currentUser.email}`;
  const classes = useStyles();

  const getPhoneType = (phoneType) => {
    if (phoneType === 'WP') return 'Work Phone';
    if (phoneType === 'HP') return 'Home Phone';
    if (phoneType === 'CP') return 'Cell Phone';
    return '';
  };

  const firstPhoneTitle = getPhoneType(firstPhoneType);
  const secondPhoneTitle = getPhoneType(secondPhoneType);
  const tertiaryPhoneTitle = getPhoneType(tertiaryPhoneType);

  const getDisplayContactRelationship = (contact) => {
    let ContactRelationship = '';
    if (contact.relationship) {
      return `${labels.OPENNING_PARENTHESIS}${contact.relationship}${labels.CLOSING_PARENTHESIS}`;
    }
    return ContactRelationship;
  };

  const getActionableTitleEmail = () => {
    return (title = (
      <div>
        <div className={classes.actionItem}>
          <CopyToClipboard text={email}>
            <span className={classes.pointer_show}>
              <Typography level="small" className={classes.contact}>
                <Copy className={classes.actionicon} />
                {labels.COPY}
              </Typography>
            </span>
          </CopyToClipboard>
        </div>
        <div className={classes.actionItem}>
          <Typography level="small">
            <Link
              className={classes.contact}
              to={`mailto:${email}?subject=${labels.ADVISOR_EMAIL_SUBJECT.replace(
                '{SLA_NAME}',
                advisorFirstName,
              )}`}
              onClick={() => {
                registerEvent('FamilyFiles', 'Click to Email');
              }}
            >
              <Send className={classes.icon} />
              {labels.SEND_EMAIL}
            </Link>
          </Typography>
        </div>
      </div>
    ));
  };

  const clickToDial = async (phoneNumber) => {
    try {
      setTelephonyError(null);
      const response = await dialerService.dialCall(
        phoneNumber,
        currentUser.username,
      );
      if (!response.success) {
        setTelephonyError(response.error);
      }
    } catch (err) {
      setTelephonyError(err.message);
    }
  };

  const getTextUrlResponse = (phoneNumber) => {
    const textOverrideNumber = textOverriddenNumber();
    const textToPhoneNumber = textOverrideNumber.trim()
      ? textOverrideNumber
      : phoneNumber;

    //Care Type is mandatory to be sent to get the chat url. So if not care type is present send a dummy value of X
    var careTypeCodesList = careTypeCodes ? careTypeCodes : 'X';

    const contactDetails = {
      fullName: contact.name,
      leadId: familyFileId,
      phoneNumber: textToPhoneNumber,
      slaEmail: advisorEmail,
      careTypeList: careTypeCodesList,
    };

    let response = chatboxClient.getTextingServiceUrl(
      withConfig('YGL_COMMUNICATION_SERVICE_TEXT_URL'),
      contactDetails,
    );
    return response;
  };

  const sendTextClickHandler = async (phoneNumber) => {
    await getTextUrlResponse(phoneNumber).then((response) => {
      window.open(response, '_blank');
    });
  };

  const enableOneClickDial = () => {
    return flags.enableOneClickDial && !doNotCall;
  };

  const textOverriddenNumber = () => {
    return flags.textOverrideNumber;
  };

  const getActionableTitlePhone = (phoneNumber) => {
    return (
      <div>
        <div className={classes.actionItem}>
          <CopyToClipboard text={phoneNumber}>
            <span className={classes.pointer_show}>
              <Typography level="small" className={classes.contact}>
                <Copy className={classes.actionicon} />
                {labels.COPY}
              </Typography>
            </span>
          </CopyToClipboard>
        </div>
        {enableOneClickDial() && (
          <div className={classes.actionItem}>
            <Typography level="small">
              <Link
                onClick={(event) => {
                  event.preventDefault();
                  registerEvent('FamilyFiles', 'Click to Call');
                  clickToDial(phoneNumber);
                }}
                className={classes.contact}
              >
                <CallPhone className={classes.icon} />
                {labels.CALL} {contactFirstName}
              </Link>
            </Typography>
          </div>
        )}
        <div
          className={classes.actionItem}
          onClick={() => {
            registerEvent('FamilyFiles', 'Click to Text');
            sendTextClickHandler(phoneNumber);
          }}
        >
          <Typography level="small" className={classes.contact}>
            <CommentText className={classes.actionicon} />
            {labels.SEND_TEXT}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div className={[classes.row]}>
        {telephonyError && (
          <Toast
            className={classes.toast_error}
            type="error"
            onClose={() => {
              setTelephonyError(null);
            }}
          >
            {telephonyError}
          </Toast>
        )}
      </div>
      <div className={classes.row}>
        <div className={classes.row}>
          <Typography
            className={classes.label}
            color="eerieBlack5"
            level="small"
          >
            {title}
          </Typography>
        </div>
        <div className={classes.column_name}>
          <Typography level="body">
            <b>{contact.name}</b> {getDisplayContactRelationship(contact)}
          </Typography>
        </div>
      </div>

      {firstPhone && (
        <div className={classes.row_phone}>
          <div className={classes.column}>
            <Typography
              className={classes.label}
              color="eerieBlack5"
              level="small"
            >
              {firstPhoneTitle}
            </Typography>
            <div>
              <ActionableTooltip
                title={getActionableTitlePhone(firstPhone)}
                placement="bottom-start"
              >
                <div className={classes.contact}>
                  <CallPhone className={classes.icon} />
                  <Typography level="small">
                    {formatPhoneWithParenthesis(firstPhone)}
                  </Typography>
                </div>
              </ActionableTooltip>
            </div>
          </div>

          {secondPhone && (
            <div className={classes.column}>
              <Typography
                className={classes.label}
                color="eerieBlack5"
                level="small"
              >
                {secondPhoneTitle}
              </Typography>
              <div>
                <ActionableTooltip
                  title={getActionableTitlePhone(secondPhone)}
                  placement="bottom-start"
                >
                  <div className={classes.contact}>
                    <CallPhone className={classes.icon} />
                    <Typography level="small">{secondPhone}</Typography>
                  </div>
                </ActionableTooltip>
              </div>
            </div>
          )}
          {email && (
            <div className={classes.column}>
              <Typography
                className={classes.label}
                color="eerieBlack5"
                level="small"
              >
                {labels.EMAIL}
              </Typography>
              <div>
                <ActionableTooltip
                  title={getActionableTitleEmail()}
                  placement="bottom-start"
                >
                  <div className={classes.contact}>
                    <Mail className={classes.icon} />
                  </div>
                </ActionableTooltip>
              </div>
            </div>
          )}
        </div>
      )}
      {tertiaryPhone && (
        <div className={classes.row_phone}>
          <div className={classes.column}>
            <Typography
              className={classes.label}
              color="eerieBlack5"
              level="small"
            >
              {tertiaryPhoneTitle}
            </Typography>
            <div>
              <ActionableTooltip
                title={getActionableTitlePhone(tertiaryPhone)}
                placement="bottom-start"
              >
                <div className={classes.contact}>
                  <CallPhone className={classes.icon} />
                  <Typography level="small">
                    {formatPhoneWithParenthesis(tertiaryPhone)}
                  </Typography>
                </div>
              </ActionableTooltip>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

ContactInformationDisplay.propTypes = {
  flags: PropTypes.object,
  labels: PropTypes.object.isRequired,
  familyFileInfo: PropTypes.shape({
    familyFileId: PropTypes.number,
    careTypes: PropTypes.arrayOf(PropTypes.object),
    doNotCall: PropTypes.bool,
  }),
  contact: PropTypes.shape({
    firstName: PropTypes.string,
    name: PropTypes.string,
    relationship: PropTypes.string,
    contactDetail: PropTypes.shape({
      phonePrimary: PropTypes.string,
      phoneSecondary: PropTypes.string,
      email: PropTypes.string,
      primaryPhoneType: PropTypes.string,
      secondaryPhoneType: PropTypes.string,
      tertiaryPhone: PropTypes.string,
      tertiaryPhoneType: PropTypes.string,
    }),
  }),
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    username: PropTypes.string,
    __typename: PropTypes.string,
  }),
  title: PropTypes.string,
  dialerService: PropTypes.object,
};

export default withLDConsumer()(withUser(ContactInformationDisplay));
