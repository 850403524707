import React, { useEffect, useRef, useState } from 'react';
import PinAdd from 'common/Icons/maps/PinAdd';
import { useStyles } from './SaveCommunity.style';
import PropTypes from 'prop-types';
import useGraphQLMutation from 'hooks/useGraphQLMutation';
import deleteCommunitiesMutation from './deleteCommunityMutation';
import saveCommunitiesMutation from './saveCommunityMutation';
import PinAddFilled from 'common/Icons/maps/PinAddFilled';
import PinNo from 'common/Icons/maps/PinNo';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import { CircularProgress } from '@material-ui/core';

const SaveCommunity = ({
  familyFileId,
  community,
  onSave,
  onRemove,
  labels,
  dataForSegment,
  locationFilter,
  onUpdate,
}) => {
  const classes = useStyles();
  const [deleteCommunitiesCall] = useGraphQLMutation(deleteCommunitiesMutation);
  const [saveCommunitiesCall] = useGraphQLMutation(saveCommunitiesMutation);
  const [isHover, setIsHover] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsHover(false);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [wrapperRef]);

  const toggleCommunity = async () => {
    if (isPending) return;
    setIsPending(true);
    if (community.isSaved) {
      onRemove(community);
      deleteCommunitiesCall({
        variables: {
          communityIds: [Number(community.id)],
          familyFileId,
          oneId: dataForSegment.oneId,
        },
      })
        .then(() => {
          setIsPending(false);
          setIsHover(false);
          onUpdate();
        })
        .catch((error) => {
          onSave(community);
          setIsPending(false);
          console.error(error);
          onUpdate(error, community);
        });
    } else {
      onSave(community);
      saveCommunitiesCall({
        variables: {
          communityId: Number(community.id),
          familyFileId,
          oneId: dataForSegment.oneId,
          desiredZipCode: locationFilter.zip,
        },
      })
        .then(() => {
          setIsPending(false);
          setIsHover(false);
          onUpdate();
        })
        .catch((error) => {
          onRemove(community);
          console.error(error);
          setIsPending(false);
          onUpdate(error, community);
        });
    }
  };

  return (
    <>
      {isPending && (
        <div className={classes.loading_spinner}>
          <CircularProgress size={'24px'} disableShrink />
        </div>
      )}
      {!isPending && (
        <div
          ref={wrapperRef}
          className={classes.saved_community_container}
          onClick={toggleCommunity}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {community.isSaved &&
            (isHover ? (
              <SmartTooltip
                placement="right"
                title={labels.REMOVE_FROM_SAVED_COMMUNITIES}
              >
                <PinNo className={classes.remove} />
              </SmartTooltip>
            ) : (
              <PinAddFilled />
            ))}
          {!community.isSaved &&
            (isHover ? (
              <SmartTooltip
                placement="right"
                title={labels.SAVE_COMMUNITY_FOR_REFERRAL}
              >
                <PinAddFilled className={classes.not_saved_hover} />
              </SmartTooltip>
            ) : (
              <PinAdd />
            ))}
        </div>
      )}
    </>
  );
};

SaveCommunity.propTypes = {
  familyFileId: PropTypes.number,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
  community: PropTypes.object,
  labels: PropTypes.object,
  dataForSegment: PropTypes.shape({
    familyFileId: PropTypes.number,
    oneId: PropTypes.string,
  }),
  locationFilter: PropTypes.object,
  onUpdate: PropTypes.func,
};

SaveCommunity.defaultProps = {
  onUpdate: () => {},
};

export default SaveCommunity;
