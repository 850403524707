import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    getColor: (colorName) => {
      for (const property in theme.palette) {
        if (theme.palette[property][colorName]) {
          return theme.palette[property][colorName];
        }
      }
      return 'transparent';
    },
    dark: {
      eerieBlack1: '#0D1321',
      eerieBlack2: '#232835',
      eerieBlack3: '#393D49',
      eerieBlack4: '#4F535D',
      eerieBlack5: '#656871',
      disabled: '#BFBFC0',
    },
    light: {
      boxShadow: '#0D13214C',
      divider: '#D7D7D8',
      platinum1: '#E9E9EA',
      platinum2: '#EDEDED',
      platinum3: '#F1F1F1',
      platinum4: '#F9F9F9',
      platinum5: '#FEFEFE',
      white: '#FFF',
    },
    neutral: {
      cosmicCobalt1: '#2C438C',
      cosmicCobalt2: '#3956B5',
      cosmicCobalt3: '#4568DC',
      cosmicCobalt4: '#B5C3F1',
      cosmicCobalt5: '#DDE3F8',
      dropdown: '#F3F5FC',
    },
    care: {
      regalia1: '#492D7C',
      regalia2: '#9149A8',
      regalia3: '#A15EB5',
      regalia4: '#C592C7',
      regalia5: '#E9D6EA',
    },
    positive: {
      wintergreenDream1: '#2B6857',
      wintergreenDream2: '#3B8E77',
      wintergreenDream3: '#5EA28F',
      wintergreenDream4: '#82B7A8',
      wintergreenDream5: '#D5ECE8',
    },
    correction: {
      smokyTopaz1: '#A03E47',
      smokyTopaz2: '#DB5461',
      smokyTopaz3: '#E1737D',
      smokyTopaz4: '#EBA1A8',
      smokyTopaz5: '#F5D0D3',
    },
    miscellaneous1: {
      hotCinnamon1: '#9C4814',
      hotCinnamon2: '#C35A19',
      hotCinnamon3: '#D6621B',
      hotCinnamon4: '#E49B6D',
      hotCinnamon5: '#F3E0D4',
    },
    miscellaneous2: {
      fieldDrab1: '#6B5200',
      fieldDrab2: '#A37D00',
      fieldDrab3: '#D8A600',
      fieldDrab4: '#E9CE73',
      fieldDrab5: '#F7EED0',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1920,
    },
  },
  menuSize: '300px',
});

const colorTypes = [
  'dark',
  'light',
  'neutral',
  'care',
  'positive',
  'correction',
  'miscellaneous1',
  'miscellaneous2',
];
// Color name reference array, primarily used in PropTypes (e.g. `PropTypes.oneOf(colorReference)` or `PropTypes.oneOf([...colorReference, 'transparent'])`)
export const colorReference = colorTypes.reduce(
  (acc, type) => [...acc, ...Object.keys(theme?.palette[type])],
  [],
);

export default theme;
