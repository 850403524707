const allCallStates = {
  RINGING_ON_OTHER_SIDE: {
    key: 'RINGING_ON_OTHER_SIDE',
    label: 'Ringing on Other Side',
    status: 'CONNECTING',
  },
  RINGING_ON_OUR_SIDE: {
    key: 'RINGING_ON_OUR_SIDE',
    label: 'Ringing on Our Side',
    status: 'CONNECTING',
  },
  OFFERED: {
    key: 'OFFERED',
    label: 'Offered',
    status: 'CONNECTING',
  },
  WRAP_UP: {
    key: 'WRAP_UP',
    label: 'Wrap Up',
    status: 'DISCONNECTED',
  },
  FINISHED: {
    key: 'FINISHED',
    label: 'Finished',
    status: 'DISCONNECTED',
  },
  TALKING: {
    key: 'TALKING',
    label: 'Talking',
    status: 'OTHER',
  },
  CONFERENCE_PARTICIPANT_RINGING: {
    key: 'CONFERENCE_PARTICIPANT_RINGING',
    label: 'Conference Participant Ringing',
    status: 'OTHER',
  },
  CONFERENCE_PARTICIPANT_CONSULTING: {
    key: 'CONFERENCE_PARTICIPANT_CONSULTING',
    label: 'Conference Participant Consulting',
    status: 'OTHER',
  },
  CONFERENCE_PARTICIPANT_TALKING: {
    key: 'CONFERENCE_PARTICIPANT_TALKING',
    label: 'Conference Participant Talking',
    status: 'OTHER',
  },
  PARKED: {
    key: 'PARKED',
    label: 'Parked',
    status: 'OTHER',
  },
  QUEUED: {
    key: 'QUEUED',
    label: 'Queued',
    status: 'OTHER',
  },
  ON_HOLD: {
    key: 'ON_HOLD',
    label: 'On Hold',
    status: 'ON_HOLD',
  },
};

const callPreviewStates = {
  ON_CALL: { key: 'ON_CALL', label: 'On Call' },
  PREVIEW: { key: 'PREVIEW', label: 'Preview' },
  FINISHED: { key: 'FINISHED', label: 'FINISHED' },
  WAITING_CALL: { key: 'WAITING_CALL', label: 'WAITING_CALL' },
};

const activeConferenceStates = {
  FINISHED: { label: 'FINISHED', key: 'FINISHED' },
  CONFERENCE_PARTICIPANT_TALKING: {
    label: 'CONFERENCE_PARTICIPANT_TALKING',
    key: 'CONFERENCE_PARTICIPANT_TALKING',
  },
};

const callTypes = {
  INBOUND: { label: 'Inbound Call', value: 'INBOUND' },
  OUTBOUND: { label: 'Outbound Call', value: 'OUTBOUND' },
  DISCONNECTED: { label: '', value: 'DISCONNECTED' },
};

const getCallStatus = (callState, callPreview) => {
  if (
    (callPreview?.state === callPreviewStates.ON_CALL.key &&
      callState === allCallStates.RINGING_ON_OTHER_SIDE.key) ||
    callPreview?.state === callPreviewStates.PREVIEW.key
  ) {
    return 'DIALING';
  }
  if (
    callPreview?.state === callPreviewStates.ON_CALL.key &&
    callState === null
  ) {
    return 'DISCONNECTED';
  }

  if (typeof callState !== 'string') {
    return '';
  }
  return allCallStates[callState].status;
};

const getCallType = (activeCampaigns, callData, callPreview, callState) => {
  const callDataCampaignId = callData?.campaignId;
  const callPreviewCampaignId = callPreview?.campaignId;
  let currentCallType = callTypes.DISCONNECTED;

  let currentCampaignId =
    callState !== 'FINISHED' ? callDataCampaignId : undefined;

  if (
    callPreview?.state === callPreviewStates.PREVIEW.key ||
    callPreview?.state === callPreviewStates.WAITING_CALL.key
  ) {
    currentCampaignId = callPreviewCampaignId;
  }
  const currentCampaign = activeCampaigns.find(
    (campaign) => campaign?.id === currentCampaignId,
  );
  currentCallType = callTypes[currentCampaign?.campaignType] || currentCallType;
  return currentCallType;
};

const getCallStateLabel = (callState) => allCallStates[callState]?.label;
export default allCallStates;
export {
  getCallStatus,
  getCallType,
  getCallStateLabel,
  callPreviewStates,
  activeConferenceStates,
  callTypes,
};
