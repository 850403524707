import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    form_control: {
      '& .MuiFormControlLabel-label': {
        marginLeft: '8px',
        fontFamily: 'Poppins',
        cursor: 'default',
      },
    },
    switch: {
      width: '32px',
      height: '20px',
      padding: 0,
      display: 'flex',
      '& label span': {
        fontFamily: 'Poppins',
      },
      '& .MuiSwitch-switchBase': {
        padding: '2px',
        color: theme.palette.light.platinum5,
        '&.Mui-checked': {
          transform: 'translateX(12px)',
          color: theme.palette.common.white,
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.neutral.cosmicCobalt3,
            borderColor: theme.palette.neutral.cosmicCobalt3,
          },
        },
      },
      '& .MuiSwitch-thumb': {
        width: '16px',
        height: '16px',
        boxShadow: 'none',
      },
      '& .MuiSwitch-track': {
        height: 'auto',
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: '16px',
        opacity: 1,
        backgroundColor: theme.palette.dark.eerieBlack5,
      },
    },
    disabled: {
      '& .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: theme.palette.dark.disabled,
        opacity: 1,
        border: `1px solid ${theme.palette.dark.disabled}`,
      },
    },
  };
});

export { useStyles };
