import { makeStyles } from '../../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      fontFamily: 'Poppins',
      backgroundColor: theme.palette.light.platinum5,
      borderRadius: '4px',
      boxShadow: '0px 0px 8px rgba(186, 186, 184, 0.3)',
    },
    head_container: {
      display: 'flex',
    },
    head: {
      fontWeight: 'bold',
      fontSize: '13px',
      lineHeight: '19px',
      borderBottom: `1px solid ${theme.palette.light.platinum1}`,
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '4px 0 0 0',
      width: `calc(100% - 80px)`,
      '& div': {
        paddingLeft: '8px',
      },
      '& svg': {
        fontSize: '18px',
      },
    },
    head_flush: {
      width: '100% !important',
    },
    body: {
      padding: '24px',
    },
    card_title: {
      width: `calc(100% - ${theme.spacing(10)}px)`,
      display: 'flex',
    },
    edit_container: {
      justifyContent: 'center',
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: '13px',
      lineHeight: '19px',
      borderBottom: `1px solid ${theme.palette.light.platinum1}`,
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '0 4px 0 0',
      width: theme.spacing(10),
      '& div': {
        paddingLeft: '8px',
      },
      '& svg': {
        fontSize: '18px',
      },
    },
    with_left_border: {
      borderLeft: `1px solid ${theme.palette.light.platinum1}`,
    },
  };
});

export { useStyles };
