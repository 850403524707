import PropTypes from 'prop-types';
import React from 'react';
import { cloneDeep } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import Media from 'common/Media/Media';
import { useStyles } from './ResidentPopup.style';
import Duplicate from 'common/Icons/basic/Duplicate';
import Modal from 'common/Modal/Modal';
import { inquiryPropType } from '../../inquiryPropTypes';
import { changeState } from 'services/callCenter/duplicateFamilyFilePopupService';
import inquiryService from 'services/callCenter/inquiryService';
import { selectCatalogs } from 'stateManagement/callcenter/catalogs/reducers/catalogsReducer';
import DuplicateFamilyFilePopupSm from 'components/FamilyFile/FamilyFilePopup/DuplicateFamilyFilePopupSm';
import DuplicateFamilyFilePopupXs from 'components/FamilyFile/FamilyFilePopup/DuplicateFamilyFilePopupXs';
import { logResidentDupCheckCompleted } from 'services/callCenter/amplitudeEventsService';
import {
  setDuplicateFamilyFile,
  setWarmTransferType,
} from 'stateManagement/callcenter/warmTransfer/actions/warmTransferActions';

import { warmTransferTypes } from 'services/callCenter/warmTransferService.js';

let currentResidentAndLocation;

const orderingFoundedResident = (foundResident, orderField) => {
  const sortedResident =
    foundResident?.sort(function (a, b) {
      return b[orderField] - a[orderField];
    }) || [];
  return sortedResident;
};
const ResidentPopup = ({
  userInputValues,
  isOpen,
  inquiry,
  foundResident,
  onClose,
  labels,
  sendUpdateInquiryPayload,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  let orderedResident;

  const { catalogs } = useSelector(selectCatalogs);

  const relation = inquiryService.getInfo(
    inquiry,
    'relation',
    labels,
    catalogs?.familyRelationships,
  );

  currentResidentAndLocation = {
    residentFirstName:
      inquiry.resident1FirstName || inquiry.inquiryFormData?.resident1FirstName,
    residentLastName:
      inquiry.resident1LastName || inquiry.inquiryFormData?.resident1LastName,
    contactFirstName: inquiry.inquiryContact.firstName,
    contactLastName: inquiry.inquiryContact.lastName,
    relationToResident: relation,
    oneId: inquiry.inquiryContact.oneId,
    location: [
      `${userInputValues.city}, ${userInputValues.state} ${
        userInputValues.zip || ''
      }`,
    ],
    phone: inquiry.inquiryContact.phone,
    email: inquiry.inquiryContact.email,
  };

  const newFoundResident = changeState(
    foundResident,
    currentResidentAndLocation,
  );

  orderedResident = orderingFoundedResident(newFoundResident, 'count');

  const validateIsOpenResident = () => {
    let residentToOrder = cloneDeep(newFoundResident);
    const filteredOpenResidents = newFoundResident?.filter(
      (resident) => resident.familyFileStatus === 'Open',
    );

    if (filteredOpenResidents.length !== 0) {
      residentToOrder = filteredOpenResidents;
    }
    const residentsOrderByDate = orderingFoundedResident(
      residentToOrder,
      'familyFileCreation',
    );
    const selectLatestDuplicate = residentsOrderByDate?.[0];
    dispatch(
      setDuplicateFamilyFile({
        familyFileStatus: selectLatestDuplicate.familyFileStatus,
        hasDuplicate: true,
        phoneProviderUsername: selectLatestDuplicate.phoneProviderUsername,
      }),
    );
    return selectLatestDuplicate;
  };
  const doMerging = async (merge) => {
    const familyFilesFound = orderedResident.map((familyFile) => ({
      familyFileId: familyFile.familyFileId,
      status: familyFile.familyFileStatus.toLowerCase(),
    }));
    let updatingInquiryVariables = {
      familyFilesFound,
      inquiryId: inquiry.inquiryId,
      oneId: inquiry.inquiryContact.oneId,
    };
    if (merge) {
      const residentToSelect = validateIsOpenResident();
      updatingInquiryVariables = {
        duplicateFamilyFileId: residentToSelect.familyFileId,
        inquiryId: inquiry.inquiryId,
        createFamilyFile: false,
        familyFileStatus: residentToSelect.familyFileStatus,
        externalId: residentToSelect.externalId,
        phoneProviderUsername: residentToSelect.phoneProviderUsername,
        slaName: residentToSelect.sla,
        familyFilesFound: familyFilesFound,
        oneId: inquiry.inquiryContact.oneId,
      };
      logResidentDupCheckCompleted({
        action: 'same_resident',
        externalId: residentToSelect.externalId,
        familyFileId: residentToSelect.familyFileId,
        inquiryId: inquiry.inquiryId,
        slaName: residentToSelect.sla,
      });
      sendUpdateInquiryPayload(updatingInquiryVariables);
      dispatch(setWarmTransferType(warmTransferTypes.REINQUIRY_OR_DUPLICATE));
    } else {
      logResidentDupCheckCompleted({
        action: 'not_duplicate',
        inquiryId: inquiry.inquiryId,
      });
      dispatch(setWarmTransferType(warmTransferTypes.ANY_SLA));
    }
    sendUpdateInquiryPayload(updatingInquiryVariables);
    onClose();
  };

  const renderModal = (content, size) => {
    const className = classes[`modal_${size}`];
    return (
      <Modal
        title={labels.RESIDENT_DUPLICATE_CHECK}
        isOpen={isOpen}
        color="cosmicCobalt2"
        noBodyScroll={false}
        className={className}
        icon={<Duplicate />}
        showCloseIcon={false}
      >
        {content}
      </Modal>
    );
  };

  return (
    <Media
      sm={renderModal(
        <DuplicateFamilyFilePopupSm
          foundFamilyFile={orderedResident}
          labels={labels}
          currentFamilyFile={currentResidentAndLocation}
          type="resident"
          doMerging={doMerging}
        />,
        'sm',
      )}
      xs={renderModal(
        <DuplicateFamilyFilePopupXs
          foundFamilyFile={orderedResident}
          labels={labels}
          currentFamilyFile={currentResidentAndLocation}
          type="resident"
          doMerging={doMerging}
        />,
        'xs',
      )}
    />
  );
};

ResidentPopup.propTypes = {
  userInputValues: PropTypes.object,
  isOpen: PropTypes.bool,
  inquiry: inquiryPropType,
  foundResident: PropTypes.array,
  onClose: PropTypes.func,
  labels: PropTypes.object,
  sendToSla: PropTypes.func,
  sendUpdateInquiryPayload: PropTypes.func,
};
export default ResidentPopup;
