import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  community_info_container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    marginLeft: '24px',
  },
  community_info_container_xs: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    marginLeft: '24px',
  },
  community_name: {
    display: 'flex',
    alignItems: 'center',
  },
  community_vaccine: {
    marginLeft: '11px',
    marginRight: '11px',
    '& svg': {
      '& path': {
        fill: theme.palette.miscellaneous1.hotCinnamon3,
      },
    },
  },
  company_name: {
    marginTop: '4px',
  },
  contact_container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '8px',
  },
  contact: {
    display: 'flex',
    '& svg': {
      marginRight: '6px',
      color: theme.palette.neutral.cosmicCobalt1,
      fill: theme.palette.neutral.cosmicCobalt1,
    },
  },
  tags: {
    display: 'flex',
    marginTop: '12px',
    alignItems: 'center',
    '& div:last-child': {
      marginLeft: '5px',
    },
    '& svg': {
      fontSize: '16px',
    },
  },
  empty: {
    color: theme.palette.dark.eerieBlack5,
    '& svg': {
      color: `${theme.palette.dark.eerieBlack5} !important`,
      fill: `${theme.palette.dark.eerieBlack5} !important`,
    },
  },
  care_type: {
    alignSelf: 'flex-start',
    height: '28px',
    width: '28px',
    boxSizing: 'border-box',
    borderRadius: '50%',
    textAlign: 'center',
    color: theme.palette.dark.eerieBlack1,
    backgroundColor: theme.palette.care.regalia5,
    marginRight: '4px',
  },
  miles: {
    color: theme.palette.dark.eerieBlack5,
  },
  distance: {
    paddingLeft: '4px',
    minWidth: '55px',
  },
  referred: {
    color: `${theme.palette.miscellaneous2.fieldDrab2} !important`,
  },
  selected_tour_button: {
    color: theme.palette.dark.eerieBlack1,
  },
  selected_tour_time: {
    marginBottom: 4,
  },
  button_container: {
    display: 'block',
    alignItems: 'center',
  },
  remove_tour_info_container: {
    cursor: 'pointer',
  },
  remove_tour_time: {
    marginTop: theme.spacing(1),
  },
  rating_container: {
    display: 'flex',
    marginTop: '4px',
    alignItems: 'center',
  },
  rating_star_container: {
    display: 'flex',
    marginLeft: '4px',
  },
  no_rating_container: {
    display: 'flex',
    alignItems: 'center',
  },
  rating_star: {
    '& path': {
      fill: theme.palette.miscellaneous2.fieldDrab4,
    },
  },
  review_count: {
    marginLeft: '4px',
    '& > a': {
      fontSize: '14px',
      color: theme.palette.dark.eerieBlack4,
      '&:active': {
        color: theme.palette.dark.eerieBlack4,
      },
      '&:visited': {
        color: theme.palette.dark.eerieBlack4,
      },
    },
  },
  no_rating: {
    marginLeft: '4px',
    '& path': {
      fill: theme.palette.dark.disabled,
    },
  },
  filter_content: {
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  amenity_icon: {
    '& > svg': {
      fontSize: '20px',
      marginLeft: '15px',
    },
  },
  close_button: {
    marginTop: theme.spacing(3),
    '&:hover': {
      backgroundColor: theme.palette.light.platinum2,
      color: theme.palette.dark.eerieBlack1,
    },
  },
  close_button_container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  modal_container: {
    width: '640px',
  },
  vaccine_details: {
    marginTop: theme.spacing(2),
  },
}));

export { useStyles };
