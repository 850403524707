import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => ({
  body: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
    gridGap: '8px',
  },
}));

export { useStyles };
