import React from 'react';
import PropTypes from 'prop-types';

const WashingMachine = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 46H43.6719V2H5V46ZM7.57812 43.4219V14.8906H41.0938V43.4219H7.57812ZM41.0938 4.57812V12.3125H7.57812V4.57812H41.0938Z"
        fill="currentColor"
      />
      <path
        d="M24.3359 17.4688C17.9388 17.4688 12.7344 22.7117 12.7344 29.1562C12.7344 35.6008 17.9388 40.8438 24.3359 40.8438C30.733 40.8438 35.9375 35.6008 35.9375 29.1562C35.9375 22.7117 30.733 17.4688 24.3359 17.4688ZM15.3125 29.1562C15.3125 28.1435 15.4772 27.1689 15.7808 26.2582C16.7996 25.6237 17.9666 25.2891 19.1797 25.2891C20.3983 25.2891 21.5788 25.6346 22.5927 26.2673C21.2917 27.209 20.4688 28.7373 20.4688 30.4453C20.4688 33.2885 22.7818 35.6016 25.625 35.6007H30.7064C29.0731 37.2468 26.8201 38.2656 24.3359 38.2656C19.3604 38.2656 15.3125 34.1792 15.3125 29.1562ZM32.5046 33.0234H25.625C24.2034 33.0234 23.0469 31.8669 23.0469 30.4453C23.0469 29.1775 23.9526 28.1077 25.2006 27.9017L27.4926 27.5234L25.9587 25.7787C24.2447 23.8292 21.7739 22.7109 19.1797 22.7109C18.7392 22.7109 18.303 22.7436 17.8735 22.8057C19.5137 21.105 21.8046 20.0469 24.3359 20.0469C29.3115 20.0469 33.3594 24.1333 33.3594 29.1562C33.3594 30.5382 33.0524 31.8489 32.5046 33.0234Z"
        fill="currentColor"
      />
      <path
        d="M10.1562 7.15625H12.7344V9.73438H10.1562V7.15625Z"
        fill="currentColor"
      />
      <path
        d="M15.3125 7.15625H17.8906V9.73438H15.3125V7.15625Z"
        fill="currentColor"
      />
      <path
        d="M28.2031 7.15625H38.5156V9.73438H28.2031V7.15625Z"
        fill="currentColor"
      />
    </svg>
  );
};

WashingMachine.propTypes = {
  className: PropTypes.string,
};

export default WashingMachine;
