import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles(() => ({
  side_panel_container: {
    display: 'flex',
    flexDirection: 'column',
    '& h4': {
      marginTop: '-20px',
    },
    '& .basic_information_container': {},
  },
  tabs: {
    flexGrow: 1,
  },
  additional_toolbar: {
    marginRight: '20px',
    zIndex: 99,
    color: 'inherit',
    textDecoration: 'none',
  },
  side_panel_footer: {
    '& button': {
      width: '100%',
      borderRadius: 0,
    },
    alignItems: 'flex-end',
  },
}));

export { useStyles };
