import React from 'react';
import Typography from 'common/Typography/Typography';
import Send from 'common/Icons/basic/Send';
import FileText from 'common/Icons/file/FileText';
import PinChecked from 'common/Icons/maps/PinChecked';
import { registerEvent } from 'services/Analytics';
import CalendarDates from 'common/Icons/time/CalendarDates';
import CalendarDelete from 'common/Icons/time/CalendarDelete';

const COMPLETED = 'Completed';
const CANCELED = 'Canceled';
const CLOSED = 'Closed';

const getCommunityAddress = (community) => {
  const fullAddress = `${community.address} ${community.city}, ${community.state} ${community.zip}`;
  if (fullAddress.length < 30) {
    return (
      <Typography level="small" color="eerieBlack5">
        {`${community.address} ${community.city}, ${community.state} ${community.zip}`}
      </Typography>
    );
  }
  return (
    <>
      <Typography level="small" color="eerieBlack5">
        {community.address}
      </Typography>
      &nbsp;
      <Typography level="small" color="eerieBlack5">
        {`${community.city}, ${community.state} ${community.zip}`}
      </Typography>
    </>
  );
};

const handleSendReferralUpdates = (familyFileId, community) => {
  registerEvent('ReferredCommunities', 'Clicked on send referral updates');
  window.open(
    `/send-referral/${familyFileId}?communities=${community.id}`,
    '_self',
  );
};

const handleSendFamilyLetter = (familyFileId, community) => {
  registerEvent('ReferredCommunities', 'Clicked on send family letter');
  window.open(
    `/send-family-file-letter/${familyFileId}?communities=${community.id}`,
    '_self',
  );
};

const handleOpenMarkTourCompletedModal = (openMarkTourCompletedModal) => {
  registerEvent('ReferredCommunities', 'Clicked on mark tour as completed');
  openMarkTourCompletedModal();
};

const handleCancelTour = (familyFileId, community) => {
  registerEvent('ReferredCommunities', 'Clicked on cancel tour');
  window.open(
    `/send-referral/${familyFileId}?communities=${community.id}&forCancel=${community.id}`,
    '_self',
  );
};

const handleRescheduleTour = (familyFileId, community) => {
  registerEvent('ReferredCommunities', 'Clicked on reschedule tour');
  window.open(
    `/send-referral/${familyFileId}?rescheduleTour=true&communities=${community.id}`,
    '_self',
  );
};

const getCommunityActionIcons = (
  familyFileId,
  community,
  classes,
  labels,
  flags,
  openMarkTourCompletedModal,
  isTourScheduled,
) => {
  const enableCompleteAction =
    (community?.tourDetails &&
      !isLatestStatusCompleted(community?.tourDetails?.currentStatus) &&
      !isLatestStatusCanceled(community?.tourDetails?.currentStatus) &&
      !isCommunityStatusClosed(community?.communityStatus) &&
      !community.isRejected) ||
    (community?.tourDetails && isTourScheduled);

  const communityActionIcons = [
    {
      action: () => handleSendReferralUpdates(familyFileId, community),
      label: (
        <>
          <Send className={classes.icon} />
        </>
      ),
      value: labels.SEND_SINGLE_REFERRAL_UPDATE,
      disabled:
        community.isRejected ||
        community.disabled ||
        isCommunityStatusClosed(community.communityStatus),
      disabledToolTipText: '',
    },
    {
      action: () => handleSendFamilyLetter(familyFileId, community),
      label: (
        <>
          <FileText className={classes.icon} />
        </>
      ),
      value: labels.SEND_FAMILY_LETTER,
      disabled:
        community.isRejected ||
        community.disabled ||
        isCommunityStatusClosed(community.communityStatus),
      disabledToolTipText: '',
    },
  ];

  if (!flags.isTourWorkflowV2) {
    communityActionIcons.push({
      action: () =>
        handleOpenMarkTourCompletedModal(openMarkTourCompletedModal),
      label: (
        <>
          <PinChecked className={classes.icon} />
        </>
      ),
      value: labels.MARK_TOUR_COMPLETED,
      disabled:
        community.isRejected || !enableCompleteAction || community.disabled,
      disabledToolTipText: labels.UNABLE_TO_MARK_TOUR_COMPLETE_WITHOUT_TOUR,
    });
  }

  return communityActionIcons;
};

const validateEnableCancelOrRescheduleTourAction = (
  community,
  isTourScheduled,
) => {
  return (
    community?.tourDetails &&
    !isLatestStatusCompleted(community?.tourDetails?.currentStatus) &&
    !isLatestStatusCanceled(community?.tourDetails?.currentStatus) &&
    !isCommunityStatusClosed(community?.communityStatus) &&
    isTourScheduled
  );
};
const getCancelAction = (
  familyFileId,
  labels,
  community,
  classes,
  isTourScheduled,
) => {
  const enableCancelAction = validateEnableCancelOrRescheduleTourAction(
    community,
    isTourScheduled,
  );

  const cancelAction = {
    action: () => handleCancelTour(familyFileId, community),
    label: (
      <>
        <CalendarDelete className={classes.icon} />
        {labels.CANCEL_TOUR}
      </>
    ),
    value: labels.CANCEL_TOUR,
    disabled: community.isRejected || !enableCancelAction || community.disabled,
  };

  return cancelAction;
};

const getRescheduleTourAction = (
  familyFileId,
  labels,
  community,
  classes,
  isTourScheduled,
) => {
  const enableRescheduleTourAction = validateEnableCancelOrRescheduleTourAction(
    community,
    isTourScheduled,
  );

  const rescheduleTourAction = {
    action: () => handleRescheduleTour(familyFileId, community),
    label: (
      <>
        <CalendarDates className={classes.icon} />
        {labels.RESCHEDULE_TOUR}
      </>
    ),
    value: labels.RESCHEDULE_TOUR,
    disabled:
      community.isRejected || !enableRescheduleTourAction || community.disabled,
  };
  return rescheduleTourAction;
};

const getCommunityActions = (
  familyFileId,
  community,
  labels,
  classes,
  isTourScheduled,
) => {
  const cancelAction = getCancelAction(
    familyFileId,
    labels,
    community,
    classes,
    isTourScheduled,
  );

  const rescheduleTourAction = getRescheduleTourAction(
    familyFileId,
    labels,
    community,
    classes,
    isTourScheduled,
  );

  const actionArray = [cancelAction, rescheduleTourAction];

  return actionArray;
};

const isLatestStatusCompleted = (status) => {
  return status === COMPLETED;
};

const isLatestStatusCanceled = (status) => {
  return status === CANCELED;
};

const isCommunityStatusClosed = (status) => {
  return status === CLOSED;
};
export {
  getCommunityAddress,
  getCommunityActions,
  isLatestStatusCompleted,
  isLatestStatusCanceled,
  getCommunityActionIcons,
};
