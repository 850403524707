import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => ({
  filter_bar: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  pull_right: {},
}));

export { useStyles };
