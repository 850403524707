import gql from 'graphql-tag';

export default gql`
  query(
    $daysFrom: Int = 9999
    $lastCallSortOrder: String!
    $medicallyUrgentStatuses: [String!]
    $salesPhases: [String!]
    $pageNumber: Int
    $pageSize: Int
    $referralStatusType: String
  ) {
    findFamilyFilesByParams(
      params: {
        daysFrom: $daysFrom
        lastCallSortOrder: $lastCallSortOrder
        medicallyUrgentStatuses: $medicallyUrgentStatuses
        salesPhases: $salesPhases
        pageNumber: $pageNumber
        pageSize: $pageSize
        referralStatusType: $referralStatusType
      }
    ) {
      filteredCount
      results {
        familyFileId
        audienceKey
        contacts {
          profileData {
            firstName
            lastName
            isPrimary
            relationToResident
          }
        }
        residents {
          careTypes {
            name
          }
          profileData {
            firstName
            lastName
          }
        }
        createdAt
        salesPhase
        questionsData {
          rehab
          currentLiving
        }
        isProfessionalSource
        lastCall {
          actionTypeName
          completedAt
          result
        }
      }
    }
  }
`;
