import React from 'react';
import PropTypes from 'prop-types';

const ChevronBottom = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M19.293 7.29289L20.7072 8.70711L12.0001 17.4142L3.29297 8.70711L4.70718 7.29289L12.0001 14.5858L19.293 7.29289Z"
      />
    </svg>
  );
};

ChevronBottom.propTypes = {
  className: PropTypes.string,
};

export default ChevronBottom;
