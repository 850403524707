import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  mobile_container: {
    height: 'calc(100vh - 392px)',
    padding: '24px',
    '& > div:first-child': {
      marginTop: 0,
    },
  },
  desktop_container: {
    marginTop: '32px',
    '& > div:first-child': {
      marginTop: 0,
    },
  },
  working_list_link: {
    color: theme.palette.neutral.cosmicCobalt3,
  },
  tours_info_container: {
    marginBottom: theme.spacing(3),
  },
  lead_link_container: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > a': {
      color: theme.palette.neutral.cosmicCobalt3,
      display: 'flex',
      alignItems: 'center',
      '&:active': {
        color: theme.palette.neutral.cosmicCobalt3,
      },
      '&:visited': {
        color: theme.palette.neutral.cosmicCobalt3,
      },
    },
    '& > a > svg': {
      marginLeft: theme.spacing(1),
    },
  },
}));

export { useStyles };
