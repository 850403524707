import React from 'react';
import ScheduledTourMetricLimit from '../../config/MetricConfig/ScheduledTourMetricLimit.config';
import Media from '../../common/Media/Media';
import Metric from './Metric/Metric';
import PropTypes from 'prop-types';

const ScheduledToursDuringInitialConsult = ({
  numberOfTours,
  totalOfReferredContacts,
  percentageAccomplished,
  labels,
}) => {
  const metricLimits = new ScheduledTourMetricLimit();

  metricLimits.setToursScheduledMetricLimit();

  return (
    <Media
      xs={
        <Metric
          size={'xs'}
          metricTitle={labels.TOURS_SCHEDULED_DURING_INITIAL_CONSULT}
          numberOfTours={numberOfTours}
          totalOfReferredContacts={totalOfReferredContacts}
          metricLimits={metricLimits}
          percentageAccomplished={percentageAccomplished}
          labels={labels}
        />
      }
      md={
        <Metric
          size={'md'}
          metricTitle={labels.TOURS_SCHEDULED_DURING_INITIAL_CONSULT}
          numberOfTours={numberOfTours}
          totalOfReferredContacts={totalOfReferredContacts}
          metricLimits={metricLimits}
          percentageAccomplished={percentageAccomplished}
          labels={labels}
        />
      }
      lg={
        <Metric
          size={'lg'}
          metricTitle={labels.TOURS_SCHEDULED_DURING_INITIAL_CONSULT}
          numberOfTours={numberOfTours}
          totalOfReferredContacts={totalOfReferredContacts}
          metricLimits={metricLimits}
          percentageAccomplished={percentageAccomplished}
          labels={labels}
        />
      }
    />
  );
};

ScheduledToursDuringInitialConsult.propTypes = {
  numberOfTours: PropTypes.number,
  totalOfReferredContacts: PropTypes.number,
  percentageAccomplished: PropTypes.number,
  labels: PropTypes.object,
};

const SecondRoundReferrals = ({
  numberOfTours,
  totalOfReferredContacts,
  percentageAccomplished,
  labels,
}) => {
  const metricLimits = new ScheduledTourMetricLimit();

  metricLimits.setSecondRoundMetricLimit();

  return (
    <Media
      xs={
        <Metric
          size={'xs'}
          metricTitle={labels.SECOND_ROUND_REFERRALS}
          numberOfTours={numberOfTours}
          totalOfReferredContacts={totalOfReferredContacts}
          metricLimits={metricLimits}
          percentageAccomplished={percentageAccomplished}
          labels={labels}
        />
      }
      md={
        <Metric
          size={'md'}
          metricTitle={labels.SECOND_ROUND_REFERRALS}
          numberOfTours={numberOfTours}
          totalOfReferredContacts={totalOfReferredContacts}
          metricLimits={metricLimits}
          percentageAccomplished={percentageAccomplished}
          labels={labels}
        />
      }
      lg={
        <Metric
          size={'lg'}
          metricTitle={labels.SECOND_ROUND_REFERRALS}
          numberOfTours={numberOfTours}
          totalOfReferredContacts={totalOfReferredContacts}
          metricLimits={metricLimits}
          percentageAccomplished={percentageAccomplished}
          labels={labels}
        />
      }
    />
  );
};

SecondRoundReferrals.propTypes = {
  numberOfTours: PropTypes.number,
  totalOfReferredContacts: PropTypes.number,
  percentageAccomplished: PropTypes.number,
  labels: PropTypes.object,
};

export { ScheduledToursDuringInitialConsult, SecondRoundReferrals };
