import React from 'react';
import Card from 'common/Card/Card';
import MedicalBackground from 'common/Graphics/MedicalBackground';
import Button from 'common/Button/Button';
import { useStyles } from './MedicalBackgroundDefaultPage.style';
import Typography from 'common/Typography/Typography';
import PropTypes from 'prop-types';
import Pulse from 'common/Icons/basic/Pulse';
const MedicalBackgroundDefaultPage = ({
  labels,
  toggleMedicalBackgroundDefaultPage,
  toggleMedicalBackgroundEditPage,
}) => {
  const classes = useStyles();
  const changingToggles = () => {
    toggleMedicalBackgroundDefaultPage(false);
    toggleMedicalBackgroundEditPage(true);
  };
  return (
    <Card
      className={classes.container}
      title={labels.MEDICAL_BACKGROUND}
      icon={<Pulse />}
    >
      <div className={classes.icon}>
        <MedicalBackground />
        <Typography level="body" bold>
          {labels.EMPTY_KEYNOTE_HEADER}
        </Typography>
        <div className={classes.empty_medical_background}>
          <Typography>
            {labels.MEDICAL_BACKGROUND_EMPTY_KEYNOTE_DESCRIPTION_PART1}
          </Typography>
          <Typography>
            {labels.MEDICAL_BACKGROUND_EMPTY_KEYNOTE_DESCRIPTION_PART2}
          </Typography>
        </div>
        <Button
          className={classes.medical_background_assessment_button}
          onClick={changingToggles}
        >
          {labels.COMPLETE_ASSESSMENT_NOW}
        </Button>
      </div>
    </Card>
  );
};

MedicalBackgroundDefaultPage.propTypes = {
  labels: PropTypes.object.isRequired,
  toggleMedicalBackgroundDefaultPage: PropTypes.func.isRequired,
  toggleMedicalBackgroundEditPage: PropTypes.func.isRequired,
};

export default MedicalBackgroundDefaultPage;
