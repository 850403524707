import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from 'common/Button/Button';
import Modal from 'common/Modal/Modal';
import TextInput from 'common/TextInput/TextInput';
import { useStyles } from './ImportDialog.style';
import Checkbox from 'common/Checkbox/Checkbox';

const ImportDialog = ({ onClose, onImport, isOpen, labels }) => {
  const [content, setContent] = useState('');
  const [overwrite, setOverwrite] = useState(false);
  const classes = useStyles();

  return (
    <Modal
      icon={null}
      title={labels.IMPORT}
      isOpen={isOpen}
      noBodyScroll={true}
      noBodyPadding={true}
      onClose={onClose}
    >
      <div className={classes.modal_body}>
        <TextInput
          multiline
          value={content}
          rowsMax={20}
          onChange={(value) => {
            setContent(value);
          }}
        />
      </div>
      <div className={classes.modal_footer}>
        <Checkbox
          label={labels.OVERWRITE}
          checked={overwrite}
          onChange={() => setOverwrite(!overwrite)}
        />
        <Button
          onClick={() => {
            onImport(content, overwrite);
            setContent('');
          }}
          style={{ marginLeft: 10 }}
        >
          {labels.IMPORT}
        </Button>
      </div>
    </Modal>
  );
};

ImportDialog.propTypes = {
  onClose: PropTypes.func,
  onImport: PropTypes.func,
  isOpen: PropTypes.bool,
  content: PropTypes.string,
  labels: PropTypes.object,
};

export default ImportDialog;
