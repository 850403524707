import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  saved_communities_container: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '32px',
    position: 'relative',
    '& .communities_container': {
      overflow: 'auto',
      maxHeight: '440px',
    },
  },
  title: {
    display: 'flex',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      width: '24px',
      height: '24px',
      fontSize: '13px',
      backgroundColor: theme.palette.correction.smokyTopaz2,
      marginLeft: '8px',
    },
  },
  empty_container: {
    position: 'absolute',
    top: '40px',
    height: '490px',
    display: 'flex',
    flexDirection: 'column',
    left: 0,
    width: '650px',
    padding: '16px',
    backgroundColor: theme.palette.light.platinum5,
    border: `1px solid ${theme.palette.neutral.cosmicCobalt5}`,
    borderRadius: '2px',
    boxShadow: '0px 0px 8px rgba(186, 186, 184, 0.3)',
  },
  communities_container: {
    position: 'absolute',
    top: '40px',
    maxHeight: '650px',
    width: '650px',
    padding: '16px 16px 0 16px',
    backgroundColor: theme.palette.light.platinum5,
    border: `1px solid ${theme.palette.neutral.cosmicCobalt5}`,
    borderRadius: '2px',
    boxShadow: '0px 0px 8px rgba(186, 186, 184, 0.3)',
    [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
      left: 0,
    },
    [theme.breakpoints.between(
      theme.breakpoints.values.sm,
      theme.breakpoints.values.lg,
    )]: {
      right: 0,
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      left: 0,
      transform: 'translateX(-53%)',
      webkitTransform: 'translateX(-53%)',
    },
  },
  empty_text: {
    marginBottom: '13px',
  },
  community_container: {
    display: 'flex',
    padding: '24px 13px',
    borderBottom: `1px solid ${theme.palette.light.platinum1}`,
  },
  remove_icon: {
    flexGrow: 1,
    textAlign: 'right',
    alignSelf: 'flex-start',
    cursor: 'pointer',
    '& svg': {
      color: theme.palette.correction.smokyTopaz2,
    },
  },
  checkbox: {
    marginRight: 0,
    alignSelf: 'flex-start',
    '& span:first-child': {
      marginTop: '-5px',
    },
  },
  community_info: {
    width: '272px',
  },
  // communities_header: {
  //   maxHeight: '615px',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   top: '40px',
  //   position: 'absolute',
  //   backgroundColor: theme.palette.light.platinum5,
  //   border: `1px solid ${theme.palette.neutral.cosmicCobalt5}`,
  //   borderRadius: '2px',
  //   boxShadow: '0px 0px 8px rgba(186, 186, 184, 0.3)',
  // },
  communities_footer: {
    height: '57px',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: '16px',
    },
  },
  header_row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  saved_communities_error: {
    marginTop: theme.spacing(2),
  },
}));

export { useStyles };
