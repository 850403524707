import React from 'react';
import Media from '../Media/Media';
import DesktopPagination from './DesktopPagination/DesktopPagination';
import MobilePagination from './MobilePagination/MobilePagination';
import PropTypes from 'prop-types';

const Pagination = ({
  className,
  pageCount,
  currentPage,
  goToPageLabel,
  nextLabel,
  prevLabel,
  onPageChange,
}) => {
  currentPage = Math.min(currentPage, pageCount);
  currentPage = Math.max(currentPage, 1);

  return (
    <Media
      xs={
        <MobilePagination
          className={className}
          pageCount={pageCount}
          currentPage={currentPage}
          goToPageLabel={goToPageLabel}
          onPageChange={onPageChange}
        />
      }
      sm={
        <DesktopPagination
          className={className}
          pageCount={pageCount}
          currentPage={currentPage}
          goToPageLabel={goToPageLabel}
          onPageChange={onPageChange}
          nextLabel={nextLabel}
          prevLabel={prevLabel}
        />
      }
    />
  );
};

Pagination.propTypes = {
  className: PropTypes.string,
  pageCount: PropTypes.number,
  currentPage: PropTypes.number,
  goToPageLabel: PropTypes.string,
  onPageChange: PropTypes.func,
  prevLabel: PropTypes.string,
  nextLabel: PropTypes.string,
};

Pagination.defaultProps = {
  currentPage: 1,
  onPageChange: () => {},
  prevLabel: 'Prev',
  nextLabel: 'Next',
  goToPageLabel: 'Go to page',
};

export default Pagination;
