import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './TopBar.style';
import { withAuthenticationContext } from '../../context/Authentication';
import Typography from '../../common/Typography/Typography';
import { withLabels } from '../../context/LabelContext';
import ArrowLeft from '../../common/Icons/arrow/ArrowLeft';
import { Link, useLocation } from 'react-router-dom';
import { withUser } from '../../context/UserContext';
import { getHomePage } from '../../config/roles';
import Menu from '../../common/Icons/basic/Menu';
import NavigationSidePanel from '../NavigationSidePanel/NavigationSidePanel';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import SquareStairs from '../../common/Icons/basic/SquareStairs';
import { registerEvent } from '../../services/Analytics';
import DropdownWithSearchInput from '../DropdownWithSearchInput/DropdownWithSearchInput';
import clsx from 'clsx';
import { getDropdownItems } from './topBarDropdownItems';
import getParams from '../../utils/queryString';
import TopBarUser from './TopBarUser';

const TopBarXL = ({ auth, labels, currentUser, flags, onSearch }) => {
  const location = useLocation();
  const classes = useStyles();
  const [isHome, setIsHome] = useState(false);
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const { bucket, tab } = getParams();
  const tabCheck = tab !== undefined && tab >= 0;

  const dropDownItems = getDropdownItems(labels);

  let leftNavigation = clsx(
    classes.left_navigation,
    !isHome && classes.left_navigation_with_back,
  );

  useEffect(() => {
    const path = location.pathname;
    setIsHome(path === getHomePage(currentUser.role));
  }, [location, currentUser]);

  const handleHamburgerClick = () => {
    setIsNavigationOpen(true);
    registerEvent('Navigation', 'Navigation side panel is opened');
  };

  return (
    <div className={classes.top_bar_container}>
      {!flags.onlyShowCommunitiesTabFf && (
        <>
          <div className={leftNavigation}>
            {flags.sidePanelNavigation && (
              <>
                <div
                  className={classes.hamburger}
                  onClick={handleHamburgerClick}
                >
                  <Menu />
                </div>
                {isNavigationOpen && (
                  <NavigationSidePanel
                    labels={labels}
                    backgroundColor="cosmicCobalt1"
                    width="240"
                    xColor="platinum5"
                    isOpen={isNavigationOpen}
                    onClose={() => setIsNavigationOpen(false)}
                  />
                )}
              </>
            )}
            {!flags.sidePanelNavigation && isHome && <SquareStairs />}
            {!isHome && (
              <Link
                to={
                  bucket && tabCheck
                    ? `/family-file-list?bucket=${bucket}`
                    : `/home`
                }
              >
                <ArrowLeft />
                <Typography bold color="platinum5">
                  {labels.BACK}
                </Typography>
              </Link>
            )}
          </div>
          <div className={classes.dropdown_container}>
            <DropdownWithSearchInput
              items={dropDownItems}
              onEnterPressed={onSearch}
            ></DropdownWithSearchInput>
          </div>
        </>
      )}
      <TopBarUser auth={auth} />
    </div>
  );
};

TopBarXL.propTypes = {
  labels: PropTypes.object.isRequired,
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.string,
    __typename: PropTypes.string,
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      given_name: PropTypes.string,
      family_name: PropTypes.string,
      picture: PropTypes.string.isRequired,
      name: PropTypes.string,
    }).isRequired,
    logout: PropTypes.func.isRequired,
  }).isRequired,
  flags: PropTypes.shape({
    sidePanelNavigation: PropTypes.bool,
    onlyShowCommunitiesTabFf: PropTypes.bool,
  }),
  onSearch: PropTypes.func.isRequired,
};

export default withLDConsumer()(
  withUser(withLabels(withAuthenticationContext(TopBarXL))),
);
export { TopBarXL };
