import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './Sidebar.style';
import ShowNotesButton from './ShowNotesButton';
import SidebarIcon from './SidebarIcon';
import Folder from 'common/Icons/file/Folder';

const Sidebar = ({
  labels,
  onClickShowNotes,
  onClickShowFamilyFile,
  hideFamilyFileIcon,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.sidebar}>
      <div className={classes.icons_container}>
        <div className={classes.icons_starting_position}>
          <ShowNotesButton labels={labels} onClick={onClickShowNotes} />

          {!hideFamilyFileIcon && (
            <SidebarIcon
              tooltipText={labels.OPEN_FAMILY_FILE}
              onClick={onClickShowFamilyFile}
              icon={<Folder />}
            />
          )}
        </div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  labels: PropTypes.object,
  onClickShowNotes: PropTypes.func,
  onClickShowFamilyFile: PropTypes.func,
  hideFamilyFileIcon: PropTypes.bool,
};

Sidebar.default = {
  onShowNotesClick: () => {},
  onClickShowFamilyFile: () => {},
};

export default Sidebar;
