export default {
  APFM_CORPORATE_INQUIRY: 'APFM Corporate Inquiry',
  COMMUNICATION_NO_ENGLISH: 'Communication (No English)',
  CONNECTED_WITH_WRONG_NUMBER: 'Connected with Wrong Number',
  DO_NOT_CALL_FAMILY_REQUESTED: 'Do Not Call - Family Requested',
  END_CALL: 'End Call',
  HANG_UP: 'Hang Up',
  INTERESTED_BUT_DECLINED_TRANSFER: 'Interested but Declined Transfer',
  JOB_INQUIRY: 'Job Inquiry',
  LOOKING_FOR_SPECIFIC_STAFF: 'Looking for Specific Staff/Resident',
  MERGED_DUPLICATE: 'Merged/Duplicate',
  NOT_CONNECTION: 'Not Connection - Disconnected',
  NOT_LOOKING_FOR_SENIOR_CARE: 'Not Looking for Senior Care',
  PARTNER_INQUIRY: 'Partner Inquiry',
  REDIAL: 'Redial',
  TEST_CALL: 'Test Call',
  UNQUALIFIED_AGE: 'Unqualified - Age',
  DISCONNECTED_BY_CALLER: 'Disconnected by Caller',
  CALL_ENDED: 'Call Ended',
  NEXT_CALL_WILL_START_SOON: 'Next call will start soon…',
  WAITING_ROOM: 'Waiting Room',
};
