import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './RoomAmenities.style';
import Checkbox from 'common/Checkbox/Checkbox';

const RoomAmenities = ({
  labels,
  filters,
  onChange,
  shouldClear,
  onClear,
  filterToRemove,
}) => {
  const classes = useStyles();
  const [roomAmenities, setRoomAmenities] = useState(filters);
  const [allRoomAmenitiesSelected, setAllRoomAmenitiesSelected] = useState(
    false,
  );

  useEffect(() => {
    setRoomAmenities(filters);
  }, [filters]);

  useEffect(() => {
    shouldClear && clearFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldClear]);

  useEffect(() => {
    if (filterToRemove) {
      const updatedFilters = roomAmenities.map((type) => {
        if (type.label === filterToRemove) {
          type.selected = false;
        }
        return type;
      });
      setRoomAmenities(updatedFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterToRemove]);

  useEffect(() => {
    const allSelected = !roomAmenities.find((amenity) => !amenity.selected);
    setAllRoomAmenitiesSelected(allSelected);
  }, [roomAmenities]);

  const handleToggleAll = () => {
    setAllRoomAmenitiesSelected(!allRoomAmenitiesSelected);
    const newAmenities = roomAmenities.map((amenity) => ({
      ...amenity,
      ...{ selected: !allRoomAmenitiesSelected },
    }));
    setRoomAmenities([...newAmenities]);
    onChange(newAmenities);
  };

  const handleSelectedItem = (amenity) => {
    amenity.selected = !amenity.selected;
    setRoomAmenities([...roomAmenities]);
    onChange(roomAmenities);
  };

  const clearFilters = () => {
    const clearRoomAmenities = roomAmenities.map((amenity) => ({
      ...amenity,
      ...{ selected: false },
    }));
    setRoomAmenities([...clearRoomAmenities]);
    onClear();
  };

  return (
    <div className={classes.filter_container}>
      <Checkbox
        checked={allRoomAmenitiesSelected}
        indeterminate={allRoomAmenitiesSelected}
        className={classes.checkbox}
        label={
          allRoomAmenitiesSelected ? labels.DESELECT_ALL : labels.SELECT_ALL
        }
        onChange={handleToggleAll}
      />
      {roomAmenities.map((amenity, key) => (
        <div key={key} className={classes.amenity}>
          <Checkbox
            key={key}
            className={classes.checkbox}
            checked={amenity.selected}
            onChange={() => handleSelectedItem(amenity)}
          />
          <div className={classes.checkbox_label}>
            {amenity.icon} {amenity.label}
          </div>
        </div>
      ))}
    </div>
  );
};

RoomAmenities.propTypes = {
  labels: PropTypes.object,
  filters: PropTypes.array,
  filterToRemove: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  shouldClear: PropTypes.bool,
};

export default RoomAmenities;
