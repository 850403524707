import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { arrayMove, SortableContainer } from 'react-sortable-hoc';
import NoteText from 'common/Icons/file/NoteText';
import Typography from 'common/Typography/Typography';
import ScriptBuilderModal from '../../common/ScriptBuilderModal/ScriptBuilderModal';
import { ScriptPropType } from '../../scriptBuilderPropTypes';
import List from './List';
import { useStyles } from './SortStepsDialog.style';

const SortableList = SortableContainer(List);

const SortStepsDialog = ({ onClose, script, onSave, open, labels }) => {
  const classes = useStyles();
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    const newSteps = cloneDeep(script?.steps)?.map((step, index) => ({
      ...step,
      newOrder: index + 1,
    }));
    setSteps(newSteps);
  }, [script]);

  if (!open) return null;

  const onSortEnd = (e) => {
    let newSteps = arrayMove(cloneDeep(steps), e.oldIndex, e.newIndex);
    newSteps = newSteps.map((step, index) => ({
      ...step,
      newOrder: index + 1,
    }));
    setSteps(newSteps);
  };

  const onCloseDialog = () => {
    const newSteps = cloneDeep(script?.steps)?.map((step, index) => ({
      ...step,
      newOrder: index + 1,
    }));
    setSteps(newSteps);
    onClose();
  };

  const title = 'Sort steps';

  const bodyComponent = (
    <>
      <Typography>
        {labels.SORT_INSTRUCTIONS.replace('{TYPE}', 'steps')}
      </Typography>
      <SortableList
        helperClass={classes.sortable_helper}
        lockAxis="y"
        items={steps}
        onSortEnd={onSortEnd}
        labels={labels}
      />
    </>
  );

  return (
    <ScriptBuilderModal
      title={title}
      iconComponent={<NoteText />}
      bodyComponent={bodyComponent}
      onSave={() => onSave(steps)}
      onClose={onCloseDialog}
      open={open}
      labels={labels}
      bodyClass={classes.sort_dialog_body}
    />
  );
};

SortStepsDialog.propTypes = {
  onClose: PropTypes.func,
  script: ScriptPropType,
  onSave: PropTypes.func,
  open: PropTypes.bool,
  labels: PropTypes.object,
};

export default SortStepsDialog;
