import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './SearchResultsTable.style';
import Table from 'common/Table/Table/Table';
import TableHead from 'common/Table/TableHead/TableHead';
import TableRow from 'common/Table/TableRow/TableRow';
import TableCell from 'common/Table/TableCell/TableCell';
import TableBody from 'common/Table/TableBody/TableBody';
import SearchResultRowLG from './SearchResultRowLG/SearchResultRowLG';
import Pagination from 'common/Pagination/Pagination';
import Loading from 'components/Loading/Loading';
import NoSearchResult from './NoSearchResult/NoSearchResult';
import CaseProfileSidePanel from 'components/CaseProfileSidePanel/CaseProfileSidePanel';

const SearchResultsTableLG = ({
  data,
  labels,
  currentPage,
  pageCount,
  onPageChange,
  loading,
}) => {
  const classes = useStyles();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [familyFileId, setFamilyFileId] = useState();

  const openSidePanel = (id) => {
    setFamilyFileId(id);
    setIsSidePanelOpen(true);
  };

  return (
    <div className={classes.search_results_table_container}>
      <CaseProfileSidePanel
        labels={labels}
        isOpen={isSidePanelOpen}
        familyFileId={familyFileId}
        onClose={() => setIsSidePanelOpen(false)}
      />
      <Table>
        <TableHead>
          <TableRow withHover={false}>
            <TableCell>{labels.PRIMARY_CONTACT}</TableCell>
            <TableCell>{labels.RESIDENT}</TableCell>
            <TableCell>{labels.TAGS}</TableCell>
            <TableCell>{labels.LAST_CONTACTED}</TableCell>
            {/*<TableCell>{labels.ACTIONS}</TableCell>*/}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length === 0 && !loading && (
            <TableRow withHover={false}>
              <TableCell colSpan={5}>
                <NoSearchResult labels={labels} />
              </TableCell>
            </TableRow>
          )}
          {loading && (
            <TableRow withHover={false}>
              <TableCell colSpan={5}>
                <Loading height="300px" />
              </TableCell>
            </TableRow>
          )}
          {!loading &&
            data.map((result, key) => (
              <SearchResultRowLG
                onClick={() => openSidePanel(result.id)}
                key={key}
                labels={labels}
                result={result}
              />
            ))}
          {data.length > 0 && (
            <TableRow withHover={false}>
              <TableCell colSpan={5}>
                <Pagination
                  className={classes.pagination_container}
                  currentPage={currentPage}
                  pageCount={pageCount}
                  onPageChange={onPageChange}
                />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

SearchResultsTableLG.propTypes = {
  data: PropTypes.array,
  labels: PropTypes.object,
  currentPage: PropTypes.number,
  pageCount: PropTypes.number,
  onPageChange: PropTypes.func,
  loading: PropTypes.bool,
};

SearchResultsTableLG.defaultProps = {
  data: [],
  currentPage: 1,
  pageCount: 10,
};

export default SearchResultsTableLG;
