const formatCurrency = (number, fractionDigits) => {
  let numberStr = Math.abs(number).toString();
  if (fractionDigits) {
    numberStr = Math.abs(number).toFixed(fractionDigits).toString();
  }
  return numberStr.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const toCurrency = (number) => {
  const formattedCurrency = formatCurrency(number);
  return `$ ${formattedCurrency}`;
};

const toDecimalCurrency = (number) => {
  const formattedCurrency = formatCurrency(number, 2);
  return `$ ${formattedCurrency}`;
};

export { formatCurrency, toCurrency, toDecimalCurrency };
