import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  icon: {
    textAlign: 'center',
    '& svg': {
      fontSize: '60px',
      margin: theme.spacing(2),
    },
  },
  overview_key_note_button: {
    margin: theme.spacing(2),
  },
  empty_keynote: {
    width: '100%',
    padding: theme.spacing(1),
  },
}));

export { useStyles };
