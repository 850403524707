import gql from 'graphql-tag';

export default gql`
  mutation($script: ScriptImportInput!, $overwrite: Boolean!) {
    importSteps(input: { script: $script, overwrite: $overwrite }) {
      scriptId
      slug
      version
    }
  }
`;
