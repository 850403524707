import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    material_tabs_container: {
      borderTop: `1px solid ${theme.palette.light.platinum1}`,
      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.neutral.cosmicCobalt3,
      },
    },
    tab: {
      maxWidth: 'inherit',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontSize: '15px',
      flex: '1 1 0',
      lineHeight: '22px',
      '& span': {
        textTransform: 'capitalize',
      },
      '&.Mui-selected span': {
        color: theme.palette.neutral.cosmicCobalt3,
        fontWeight: 'bold',
      },
    },
    dark_material_tabs_container: {
      borderTop: `1px solid ${theme.palette.light.platinum1}`,
      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.neutral.cosmicCobalt3,
      },
    },
    dark_tab: {
      color: theme.palette.light.platinum1,
      maxWidth: 'inherit',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontSize: '15px',
      flex: '1 1 0',
      lineHeight: '22px',
      backgroundColor: theme.palette.neutral.cosmicCobalt2,
      '& span': {
        textTransform: 'capitalize',
        fontWeight: '100',
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.neutral.cosmicCobalt3,
      },
      '&.Mui-selected span': {
        color: theme.palette.light.platinum1,
        fontWeight: '700',
      },
    },
  };
});

export { useStyles };
