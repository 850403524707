import React from 'react';
import PropTypes from 'prop-types';

const Delete = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M7.99995 4H21C22.1045 4 23 4.89543 23 6V18C23 19.1046 22.1045 20 21 20H7.99995C7.49474 20 7.26683 19.8135 6.8034 19.3177C6.75708 19.2681 6.70988 19.216 6.66187 19.1616C6.47802 18.9533 6.31417 18.7494 6.23173 18.6402L0.698242 12L1.23173 11.3598L6.18172 5.42511C6.2858 5.27697 6.44358 5.0672 6.62698 4.85178C6.70019 4.76577 6.77247 4.68523 6.84396 4.6108C7.22958 4.20929 7.51568 4 7.99995 4ZM8.14988 6.14822C8.01403 6.3078 7.88975 6.47303 7.76817 6.64018L3.3016 12.0001L7.79431 17.3926C7.87691 17.5006 8.01452 17.6717 8.16155 17.8384C8.19741 17.879 8.23189 17.9171 8.26456 17.952C8.28039 17.969 8.29563 17.985 8.31016 18H21V6H8.28277C8.24193 6.0427 8.19718 6.09265 8.14988 6.14822ZM16.2928 8.29289L14 10.5858L11.7071 8.29289L10.2928 9.70711L12.5857 12L10.2928 14.2929L11.7071 15.7071L14 13.4142L16.2928 15.7071L17.7071 14.2929L15.4142 12L17.7071 9.70711L16.2928 8.29289Z"
      />
    </svg>
  );
};

Delete.propTypes = {
  className: PropTypes.string,
};

export default Delete;
