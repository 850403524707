import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch } from 'react-router-dom';
import PrivateRouteWithLayout from './components/PrivateRouteWithLayout/PrivateRouteWithLayout';
import roles from './config/roles';
import HomeContent from './pages/callCenter/Home/HomeContent';
import ManualInquiry from './pages/callCenter/Inquiry/ManualInquiry/ManualInquiry';
import NewInquiry from './pages/callCenter/Inquiry/NewInquiry';
import InquiryDetails from './pages/callCenter/InquirySearch/InquiryDetails';
import InquirySearch from './pages/callCenter/InquirySearch/InquirySearch';
import WaitingScreen from './pages/callCenter/WaitingScreen/WaitingScreen';
import CallcenterLayout from './pages/CallcenterLayout/CallcenterLayout';
import { getCatalogsRequest } from './stateManagement/callcenter/catalogs/actions/catalogsActions';

const CallcenterRoutesWithRedux = ({ match }) => {
  const srcScreensAllowedRoles = [
    roles.CALLCENTER,
    roles.MANAGER,
    roles.PARTNERDEV,
    roles.TRAINER,
  ];
  const inquirySearchAllowedRoles = [
    roles.CALLCENTER,
    roles.TRAINER,
    roles.MANAGER,
    roles.PARTNERDEV,
    roles.SLA,
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCatalogsRequest());
  }, [dispatch]);
  return (
    <Switch>
      <PrivateRouteWithLayout
        path={`${match.url}/home`}
        component={HomeContent}
        layout={CallcenterLayout}
        allowedRoles={srcScreensAllowedRoles}
      />
      <PrivateRouteWithLayout
        path={`${match.url}/manual-inquiry`}
        component={ManualInquiry}
        layout={CallcenterLayout}
        allowedRoles={srcScreensAllowedRoles}
      />
      <PrivateRouteWithLayout
        path={`${match.url}/inquiry/:inquiryId`}
        component={NewInquiry}
        layout={CallcenterLayout}
        allowedRoles={srcScreensAllowedRoles}
      />
      <PrivateRouteWithLayout
        path={`${match.url}/waiting`}
        component={WaitingScreen}
        layout={CallcenterLayout}
        allowedRoles={srcScreensAllowedRoles}
      />
      <PrivateRouteWithLayout
        path={`${match.url}/inquiry-search`}
        component={InquirySearch}
        layout={CallcenterLayout}
        allowedRoles={inquirySearchAllowedRoles}
      />
      <PrivateRouteWithLayout
        path={`${match.url}/inquiry-details/:inquiryId`}
        component={InquiryDetails}
        layout={CallcenterLayout}
        allowedRoles={srcScreensAllowedRoles}
      />
    </Switch>
  );
};

CallcenterRoutesWithRedux.propTypes = {
  currentUser: PropTypes.object,
  match: PropTypes.object,
};

export default CallcenterRoutesWithRedux;
