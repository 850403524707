import React from 'react';
import PropTypes from 'prop-types';

const CallPhone = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M10.8565 8.33796C11.3746 7.63975 11.5604 6.9039 10.9744 6.25437C9.65949 4.41439 8.77515 3.27655 8.22044 2.7286C7.16589 1.68687 5.43112 1.82778 4.51779 2.72771C4.02723 3.21107 3.86116 3.37706 3.35747 3.88867C0.551816 6.69583 2.26285 12.6301 6.81139 17.1831C11.3589 21.7351 17.2926 23.447 20.1041 20.6339C20.5689 20.1858 20.9624 19.7921 21.2728 19.464C22.1678 18.518 22.3037 16.8598 21.2669 15.7825C20.7354 15.2302 19.6503 14.3885 17.7329 13.017C17.1457 12.4919 16.4494 12.6057 15.811 13.0245C15.504 13.226 15.2805 13.4297 14.8585 13.8521L14.0923 14.6188C13.9914 14.7198 12.621 14.0335 11.2907 12.7019C9.95972 11.3696 9.2739 9.99912 9.37435 9.89868L10.1411 9.13148C10.275 8.99748 10.339 8.9326 10.4211 8.84633C10.5921 8.6666 10.7337 8.5035 10.8565 8.33796ZM15.5057 16.033L16.2721 15.2662C16.5044 15.0337 16.6549 14.8908 16.7773 14.7923C18.457 15.9985 19.4297 16.7569 19.8271 17.1698C20.0656 17.4176 20.0286 17.8699 19.8212 18.0891C19.5342 18.3924 19.1613 18.7655 18.7037 19.2069C16.8857 21.0257 12.0959 19.6437 8.22513 15.7692C4.35315 11.8934 2.97188 7.10285 4.7762 5.29755C5.27786 4.78804 5.4368 4.62918 5.92035 4.15271C6.10166 3.97407 6.59552 3.93395 6.81608 4.15182C7.24314 4.5737 8.03534 5.58805 9.20071 7.21139C9.14038 7.28629 9.06503 7.37093 8.97333 7.4673C8.90603 7.53804 8.84996 7.59488 8.72738 7.71758L7.96136 8.48402C6.65821 9.78706 7.76802 12.0048 9.87697 14.1158C11.9845 16.2254 14.203 17.3364 15.5057 16.033Z"
      />
    </svg>
  );
};

CallPhone.propTypes = {
  className: PropTypes.string,
};

export default CallPhone;
