import React from 'react';
import PropTypes from 'prop-types';

const AmbulatoryAssistance = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M20.356 16.8844C22.1417 17.0034 23.0941 17.7177 24.4036 18.432L20.8322 24.7415L18.5703 23.432L20.356 16.8844Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.4278 22.2415C16.4278 22.2415 11.3088 21.051 11.0707 20.9319C10.7136 20.8129 10.2374 20.5748 9.88023 20.0986C9.52308 19.6224 5.35642 14.1462 5.23737 14.0272C5.11832 13.9081 5.35642 13.4319 5.83261 13.3129C6.3088 13.1939 7.49927 13.3129 8.21356 14.1462C8.68975 14.7415 11.3088 17.5986 11.3088 17.5986L15.8326 19.1462"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.9992 18.3128C19.9992 17.4795 19.404 16.8842 18.6897 16.7652C17.9754 16.5271 12.6183 15.0985 11.904 14.9795C11.0706 14.8604 10.4754 15.4557 10.2373 16.17"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.64332 8.78903C2.73856 8.66998 1.90523 7.9557 0.595703 7.24141L4.16713 0.931885L6.42904 2.24141L4.64332 8.78903Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.57227 3.43188C8.57227 3.43188 13.6913 4.62236 13.9294 4.74141C14.2866 4.86046 14.7627 5.09855 15.1199 5.57474C15.477 6.05093 19.6437 11.5271 19.7627 11.6462C19.8818 11.8843 19.6437 12.2414 19.1675 12.3605C18.6913 12.4795 17.5008 12.3605 16.7866 11.5271C16.3104 10.9319 13.6913 8.07474 13.6913 8.07474L9.1675 6.76522"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.00098 7.36035C5.00098 8.19369 5.59622 8.78892 6.3105 8.90797C7.02479 9.14607 12.3819 10.5746 13.0962 10.6937C13.9295 10.8127 14.5248 10.2175 14.7629 9.50321"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0 0.33667)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

AmbulatoryAssistance.propTypes = {
  className: PropTypes.string,
};

export default AmbulatoryAssistance;
