import React from 'react';
import PropTypes from 'prop-types';
const Cross = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3332 8.00002C15.3332 12.0501 12.0499 15.3334 7.99984 15.3334C3.94975 15.3334 0.666504 12.0501 0.666504 8.00002C0.666504 3.94993 3.94975 0.666687 7.99984 0.666687C12.0499 0.666687 15.3332 3.94993 15.3332 8.00002ZM1.99984 8.00002C1.99984 11.3137 4.68613 14 7.99984 14C11.3135 14 13.9998 11.3137 13.9998 8.00002C13.9998 4.68631 11.3135 2.00002 7.99984 2.00002C4.68613 2.00002 1.99984 4.68631 1.99984 8.00002ZM5.33317 4.66669C5.33317 4.14306 5.90914 3.82383 6.35317 4.10135L11.6865 7.43469C12.1043 7.6958 12.1043 8.30424 11.6865 8.56535L6.35317 11.8987C5.90914 12.1762 5.33317 11.857 5.33317 11.3334V4.66669ZM6.6665 5.86952L10.0753 8.00002L6.6665 10.1305V5.86952Z"
        fill="#FEFEFE"
      />
    </svg>
  );
};
Cross.propTypes = {
  className: PropTypes.string,
};
export default Cross;
