import {
  getMonthAndYearDate,
  getDateWithTimezoneOffset,
} from '../../utils/dateFormat';
import labels from '../../config/labels';

const _getPrimaryContact = (contacts) => {
  const primaryContact = contacts.find(
    (contact) => contact.profileData.isPrimary,
  );
  if (primaryContact) {
    return `${primaryContact.profileData.firstName} ${primaryContact.profileData.lastName}`;
  }
  return labels.UNKNOWN;
};

const _getPrimaryContactRelationWithResident = (contacts) => {
  const primaryContact = contacts.find(
    (contact) => contact.profileData.isPrimary,
  );
  if (primaryContact) {
    return primaryContact.profileData.relationToResident;
  }
  return labels.UNKNOWN;
};

const selfLeadsAudicences = [
  'self_not_urgent_veteran_financially_unqualified',
  'self_urgent_financially_unqualified',
  'self_not_urgent_financially_qualified',
];

const _getExpectedMoveInInfo = (expectedMoveDate, audienceKey) => {
  let isSelfLead = selfLeadsAudicences.some((selfAudience) =>
    audienceKey?.includes(selfAudience),
  );
  if (expectedMoveDate === null) {
    if (isSelfLead) {
      return labels.NO_RUSH;
    } else {
      return labels.UNDECIDED;
    }
  }

  return getMonthAndYearDate(
    getDateWithTimezoneOffset(new Date(expectedMoveDate).getTime()),
  );
};

const toBasicInformation = (familyFile) => {
  return {
    familyFileId: familyFile.familyFileId,
    audienceKey: familyFile.audienceKey,
    professionalSourceContactId: familyFile.professionalSourceContactId,
    professionalSourceOrganizationName:
      familyFile.professionalSourceOrganizationName,

    resident:
      familyFile.residents.length > 0
        ? `${familyFile.residents[0].profileData.firstName} ${familyFile.residents[0].profileData.lastName}`
        : labels.UNKNOWN,
    location: familyFile.residents[0]
      ? familyFile.residents[0].profileData.address1
      : labels.UNKNOWN,
    residentsProfileFormDataId:
      familyFile.residents.length > 0
        ? familyFile.residents[0].profileFormDataId
        : undefined,
    residentId:
      familyFile.residents.length > 0
        ? familyFile.residents[0].residentId
        : undefined,
    firstName:
      familyFile.residents.length > 0
        ? familyFile.residents[0].profileData.firstName
        : labels.UNKNOWN,
    lastName:
      familyFile.residents.length > 0
        ? familyFile.residents[0].profileData.lastName
        : labels.UNKNOWN,

    careTypes:
      familyFile.residents.length > 0 ? familyFile.residents[0].careTypes : [],
    salesPhase: familyFile.salesPhase,
    primaryContact: _getPrimaryContact(familyFile.contacts),
    primaryContactRelationWithResident: _getPrimaryContactRelationWithResident(
      familyFile.contacts,
    ),
    age: Number(familyFile.questionsData.age),
    questionsDataFormDataId: familyFile.questionsData.formDataId,
    likeliHoodToMove: Number(familyFile.leadData.likelihoodToMovePct) || 0,
    expectedMove: _getExpectedMoveInInfo(
      familyFile.leadData.expectedMoveDate,
      familyFile.audienceKey,
    ),
    leadDataFormDataId: familyFile.leadData.formDataId,

    preferredLocations: familyFile.familyDesiredLocations
      ? familyFile.familyDesiredLocations
          .sort((a, b) => {
            return a.isPrimary > b.isPrimary ? -1 : 1;
          })
          .map((location) => {
            location.deleteState = '';
            return location;
          })
      : [],

    doNotCall: !!familyFile.leadData.doNotCall,
    oneId: familyFile.oneId,
  };
};

export default toBasicInformation;
