import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SidePanel from '../../common/SidePanel/SidePanel';
import CaseProfileMobile from '../CaseProfile/CaseProfileMobile';
import Maximize from '../../common/Icons/basic/Maximize';
import { useStyles } from './CaseProfileSidePanel.style';
import { Link } from 'react-router-dom';
import Button from '../../common/Button/Button';
import { registerEvent } from '../../services/Analytics';
import getParams from '../../utils/queryString';

const CaseProfileSidePanel = ({
  isOpen,
  familyFileId,
  onClose,
  labels,
  showCommunitiesTab,
}) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [backNavigation, setBackNavigation] = useState('');
  useEffect(() => {
    const { bucket } = getParams();
    if (bucket) {
      setBackNavigation(`&bucket=${bucket}`);
    }
  }, [setBackNavigation]);

  const additionalToolbar = (
    <Link
      to={`/family-file/${familyFileId}?tab=${activeTab}${backNavigation}`}
      className={classes.additional_toolbar}
    >
      <div
        onClick={() => {
          registerEvent(
            'FamilyFiles',
            'Click to go to Family File',
            'Upper Right Button',
          );
        }}
      >
        <Maximize />
      </div>
    </Link>
  );

  const changeTab = (tab) => {
    const activityTab = 1;
    if (tab === activityTab) {
      registerEvent('FamilyFiles', 'Click activity tab inside Side Panel');
    }
    setActiveTab(tab);
  };

  return (
    <SidePanel
      onClose={onClose}
      isOpen={isOpen}
      additionalToolbar={additionalToolbar}
    >
      <div className={classes.side_panel_container}>
        <CaseProfileMobile
          onChange={changeTab}
          familyFileId={familyFileId}
          labels={labels}
          showCommunitiesTab={showCommunitiesTab}
          isInSidePanel={true}
        />
        <div className={classes.side_panel_footer}>
          <Link
            to={`/family-file/${familyFileId}?tab=${activeTab}`}
            className={classes.additional_toolbar}
          >
            <div
              onClick={() => {
                registerEvent(
                  'FamilyFiles',
                  'Click to go to Family File',
                  'Footer Button',
                );
              }}
            >
              <Button endIcon={<Maximize />}>Show More Details</Button>
            </div>
          </Link>
        </div>
      </div>
    </SidePanel>
  );
};

CaseProfileSidePanel.propTypes = {
  isOpen: PropTypes.bool,
  familyFileId: PropTypes.number,
  onClose: PropTypes.func,
  labels: PropTypes.object,
  showCommunitiesTab: PropTypes.bool,
};

export default CaseProfileSidePanel;
