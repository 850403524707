import gql from 'graphql-tag';

export default gql`
  mutation(
    $familyFileId: Int
    $familyDesiredLocationZipCodes: [String!]!
    $oneId: String
  ) {
    deleteFamilyDesiredLocations(
      input: {
        familyFileId: $familyFileId
        familyDesiredLocationZipCodes: $familyDesiredLocationZipCodes
        oneId: $oneId
      }
    )
  }
`;
