import gql from 'graphql-tag';
import { Community } from '../../queries/communityQueryFragments';

export default gql`
  query(
    $zip: String!
    $distance: Float
    $careTypes: [String!]
    $budgetLte: Int
    $budgetGte: Int
    $groupAttributeNames: [String!]
    $groupNames: [String!]
  ) {
    findOwlCommunities(
      zipCodes: [$zip]
      distance: $distance
      careTypes: $careTypes
      budgetLte: $budgetLte
      budgetGte: $budgetGte
      groupAttributeNames: $groupAttributeNames
      groupNames: $groupNames
      size: 40
      sort: [{ distance: "asc" }]
      isReferrable: true
    ) {
      id
      name
      organizationName
      address
      address2
      city
      distance
      phoneNumber
      countReferred
      countMovedIn
      state
      zip
      primaryContact
      images {
        isDefault
        assets {
          id
          sizeId
        }
      }
      ...CommunityCareTypes
      roomCharges {
        careType
        amount
        apartmentType {
          description
        }
      }
      roomFees {
        amount
        careType
        feeType {
          name
        }
      }
      geoLocation {
        lat
        lon
      }
      title
      ratingAverage
      numberOfReviews
      attributes {
        groupName
        groupAttributes {
          name
          isAvailable
        }
      }
      advisories {
        advisoryId
        advisoryType
        advisoryStatus
        advisoryEndDate
        advisoryStartDate
        vaccineInformation
        vaccineInformationDetails
      }
    }
  }
  ${Community.fragments.careTypes}
`;
