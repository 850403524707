import { makeStyles } from '../Theme/Theme';

const padding = {
  medium: '24px',
  extended: '16px',
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      fontFamily: 'Poppins',
      backgroundColor: theme.palette.light.platinum5,
      borderRadius: '4px',
      boxShadow: '0px 0px 8px rgba(186, 186, 184, 0.3)',
    },
    head: {
      fontWeight: 'bold',
      fontSize: '13px',
      lineHeight: '19px',
      borderBottom: `1px solid ${theme.palette.light.platinum1}`,
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '4px 4px 0 0',
      '& div': {
        paddingLeft: '8px',
      },
      '& svg': {
        fontSize: '18px',
      },
    },
    body: (props) => ({
      padding: `24px ${padding[props.width]}`,
    }),
  };
});

export { useStyles };
