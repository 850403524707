import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  referred_communities_row_container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '24px',
    paddingBottom: '0',
    borderBottom: `1px solid ${theme.palette.light.platinum1}`,
  },
  referred_communities_row: {
    display: 'flex',
  },
  referred_communities_details: {
    paddingLeft: theme.spacing(4),
  },
  checkbox: {
    alignSelf: 'flex-start',
    marginRight: 0,
    marginTop: '4px',
    '& span:first-child': {
      marginTop: '-5px',
    },
  },
  distance: {
    paddingLeft: '4px',
    minWidth: '55px',
  },
  info_and_schedule_tour_container: {
    width: '100%',
  },
  community_info_container: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    paddingRight: theme.spacing(1),
  },
  community_name_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  community_basic_info_container: {
    display: 'flex',
    alignItems: 'center',
  },
  community_name: {
    width: 'fit-content',
    marginRight: '4px',
  },
  community_types_and_details: (props) => ({
    display: 'flex',
    marginTop: theme.spacing(0.5),
    marginBottom: props?.isRowBottomMarginSmall
      ? theme.spacing(2)
      : theme.spacing(3),
  }),
  community_details: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  contact: {
    display: 'flex',
    '& svg': {
      marginRight: '6px',
      color: theme.palette.dark.eerieBlack5,
      fill: theme.palette.dark.eerieBlack5,
    },
  },
  miles: {
    color: theme.palette.dark.eerieBlack5,
  },
  referral_sent_date: {
    width: '25%',
    textAlign: 'center',
  },
  family_letter_sent: {
    width: '25%',
    textAlign: 'center',
  },
  not_sent: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '9px',
      fontSize: '22px',
      verticalAlign: 'middle',
      color: theme.palette.correction.smokyTopaz2,
    },
  },
  actions_container: {
    display: 'flex',
    width: '25%',
    justifyContent: 'center',
    alignSelf: 'baseline',
  },
  actions_container_2: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  action_button: {
    alignSelf: 'left',
    '& svg': {
      fontSize: theme.spacing(2.5),
      width: '30px',
    },
    '&:hover': {
      cursor: 'pointer',
    },
    '& > .custom_tooltip_container': {
      width: '30px',
    },
  },
  action_item: {
    display: 'flex',
    textAlign: 'left',
    cursor: 'pointer',
    color: theme.palette.dark.eerieBlack1,
    '& div': {
      padding: '9px 16px',
    },
    '& p': {
      padding: '9px 16px',
    },
    '&:hover': {
      backgroundColor: theme.palette.neutral.dropdown,
    },
    '& a, .link': {
      'text-decoration': 'none',
      color: theme.palette.dark.eerieBlack1,
    },
    '& svg': {
      fontSize: '24px',
      color: theme.palette.dark.eerieBlack1,
      marginRight: '12px',
    },
    padding: 0,
  },
  disabled_action_item: {
    color: '',
  },
  icon: {
    fontSize: theme.spacing(2.5),
    cursor: 'pointer',
  },
  display_icon_container: {
    marginRight: theme.spacing(1.5),
    width: '30px',
  },
  icon_disabled: {
    '& > svg': {
      color: theme.palette.dark.disabled,
      cursor: 'default',
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
  },
  selected_tour_button: {
    '& p': {
      color: theme.palette.neutral.cosmicCobalt2,
    },
    '&:hover': {
      '& p': {
        color: theme.palette.light.white,
      },
    },
  },
  scheduled_tour_button_container: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
  },
  selected_tour_time: {
    marginBottom: 4,
  },
  button_container: {
    display: 'flex',
    alignItems: 'center',
  },
  remove_tour_info_container: {
    marginLeft: theme.spacing(2),
    cursor: 'pointer',
  },
  schedule_tour_activity_table: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  status_container: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '25%',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  referral_rejected: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    justifyContent: 'center',
    width: '100%',
    '& svg': {
      marginRight: '9px',
      fontSize: '22px',
      verticalAlign: 'middle',
      color: theme.palette.correction.smokyTopaz2,
    },
  },
}));

export { useStyles };
