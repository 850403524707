const getTags = (community) => {
  const tags = [];
  if (community?.careTypes?.homeCare) {
    tags.push({ text: 'HOME CARE', color: 'regalia2' });
  }
  if (community?.isReferred) {
    tags.push({ text: 'REFERRED', color: 'cosmicCobalt2' });
  }
  return tags;
};

export { getTags };
