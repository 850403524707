import React, { useEffect } from 'react';
import { useStyles } from './InquiryDetails.style';
import { useParams } from 'react-router-dom';
import { getInquiryDetailsRequest } from 'stateManagement/callcenter/inquiry/actions/inquiryActions';
import { useDispatch, useSelector } from 'react-redux';
import { selectInquiryDetailsById } from 'stateManagement/callcenter/inquiry/reducers/inquiryReducer';
import Typography from 'common/Typography/Typography';
import inquirySearchService from 'services/callCenter/inquirySearchService';
import List from 'common/Icons/basic/List';
import { getScriptBySlugRequest } from 'stateManagement/admin/scriptPreview/actions/scriptPreviewActions';
import { selectScriptPreview } from 'stateManagement/admin/scriptPreview/reducers/scriptPreviewReducer';
import Home from 'common/Icons/basic/Home';
import TableDetails from './TableDetails';
import Toast from 'common/Toast/Toast';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import Container from 'common/Container/Container';
import ContainerItem from 'common/ContainerItem/ContainerItem';
import SideBarAdmin from '../Inquiry/SideBarAdmin';
import PropTypes from 'prop-types';
import { withLabels } from 'context/LabelContext';
import Loading from 'components/Loading/Loading';
import CallPhone from 'common/Icons/call/CallPhone';

const InquiryDetails = ({ labels }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { inquiryId } = useParams();

  const {
    inquiryDetails: inquiry,
    inquiryCallAttempts: callAttempts,
    inquiryDetailsLoading: inquiryLoading,
    inquiryDetailsError: inquiryError,
  } = useSelector(selectInquiryDetailsById);

  const { scriptPreviewLoading, scriptPreview } = useSelector(
    selectScriptPreview,
  );

  const slugId = inquirySearchService.getSlugId(inquiry);

  const {
    title: inquiryDetailsTitle,
    date: inquiryDateTitle,
  } = inquirySearchService.getInquiryDetailsTitleBar(scriptPreview, labels);

  useEffect(() => {
    dispatch(getInquiryDetailsRequest(inquiryId));
  }, [inquiryId, dispatch]);

  useEffect(() => {
    if (slugId) {
      dispatch(getScriptBySlugRequest(slugId));
    }
  }, [slugId, dispatch]);

  const inquiryDetails = inquirySearchService.buildInquiryDetails(
    inquiry,
    labels,
  );

  const callDetails = inquirySearchService.buildCallAttemptsDetails(
    callAttempts,
    labels,
  );

  const itemsMenu = [
    {
      icon: <Home />,
      to: '/callcenter/home',
    },
    {
      label: 'Inquiry Search',
      to: '/callcenter/inquiry-search',
    },
    {
      label: inquirySearchService.getInquiryLabelMenu(inquiry),
      to: '#',
    },
  ];

  const getInquiryInfoDetails = () => {
    return (
      <TableDetails
        details={inquiryDetails}
        headerClass={classes.top_inquiry_container}
        detailColor={'cosmicCobalt3'}
      >
        <div className={classes.top_panel}>
          <div className={classes.top_panel_left}>
            <List />
            <Typography
              color="platinum5"
              level="small"
              className={classes.label}
              bold
            >
              {inquiryDetailsTitle}
            </Typography>
          </div>
          <div className={classes.top_panel_right}>
            <Typography
              color="platinum5"
              level="small"
              className={classes.label}
              bold
            >
              {inquiryDateTitle}
            </Typography>
          </div>
        </div>
      </TableDetails>
    );
  };

  const getCallDataInfoDetails = () => {
    return callDetails.map((detail, key) => (
      <div key={key}>
        <TableDetails
          key={detail.order}
          details={detail.details}
          headerClass={classes.top_call_data_container}
          detailColor={'wintergreenDream3'}
        >
          <div className={classes.top_panel}>
            <div className={classes.top_panel_left}>
              <CallPhone />
              <Typography color="platinum5" level="small">
                <b>{detail.title}</b>
              </Typography>
            </div>
            <div className={classes.top_panel_right}>
              <Typography color="platinum5" level="small">
                <b>{detail.date}</b>
              </Typography>
            </div>
          </div>
        </TableDetails>
        <div className={classes.blank_space} />
      </div>
    ));
  };

  if (scriptPreviewLoading || inquiryLoading) return <Loading />;
  if (inquiryError) {
    return (
      <Toast type="error">
        <Typography>{labels.DATA_WAS_NOT_LOADED}</Typography>
      </Toast>
    );
  }

  return (
    <div className={classes.main_container}>
      <div className={classes.body}>
        <div className={classes.breadcrumbs}>
          <Breadcrumb separator="/" items={itemsMenu} />
        </div>

        <Container container spacing={2}>
          <ContainerItem item xs={8} sm={3} xl={2}>
            <SideBarAdmin inquiry={inquiry} />
          </ContainerItem>
          <ContainerItem item xs={12} sm={9} xl={10}>
            {getInquiryInfoDetails()}
            <div className={classes.blank_space} />
            {getCallDataInfoDetails()}
          </ContainerItem>
        </Container>
      </div>
    </div>
  );
};

InquiryDetails.propTypes = {
  labels: PropTypes.object,
};

export default withLabels(InquiryDetails);
