import React from 'react';
import PropTypes from 'prop-types';
import MaterialSwitch from '@material-ui/core/Switch';
import { useStyles } from './Switch.style';
import clsx from 'clsx';
import { FormControlLabel } from '@material-ui/core';

const Switch = ({ disabled, checked, onChange, label }) => {
  const classes = useStyles();
  const switchClasses = clsx(classes.switch, disabled && classes.disabled);
  return (
    <FormControlLabel
      className={classes.form_control}
      label={label}
      disabled={disabled}
      control={
        <MaterialSwitch
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          className={switchClasses}
        />
      }
    />
  );
};

Switch.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
};

Switch.defaultProps = {
  disabled: false,
  checked: false,
  onChange: () => {},
};

export default Switch;
