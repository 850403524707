import React from 'react';
import Kitchenette from 'common/Icons/lineIcons/amenities/Kitchenette';
import FullKitchen from 'common/Icons/lineIcons/amenities/FullKitchen';
import labels from 'config/labels';

const roomAmenities = [
  {
    label: labels.KITCHENETTE,
    value: 'Kitchenette',
    icon: <Kitchenette />,
    selected: false,
    is: labels.ROOM_AMENITIES,
  },
  {
    label: labels.FULL_KITCHEN,
    value: 'Full kitchen',
    icon: <FullKitchen />,
    selected: false,
    is: labels.ROOM_AMENITIES,
  },
];

export default roomAmenities;
