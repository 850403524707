import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './NoSearchResult.style';
import Question from 'common/Graphics/other/Question';
import Typography from 'common/Typography/Typography';

const NoSearchResult = ({ labels }) => {
  const classes = useStyles();
  return (
    <div className={classes.no_search_result_container}>
      <Question />
      <Typography className={classes.title} bold level="large">
        {labels.NO_FAMILY_FILE_FOUND}
      </Typography>
      <Typography>{labels.NO_FAMILY_FILE_TO_SHOW}</Typography>
      <Typography>{labels.PLEASE_TRY_SEARCHING_AGAIN}</Typography>
    </div>
  );
};

NoSearchResult.propTypes = {
  labels: PropTypes.object,
};

export default NoSearchResult;
