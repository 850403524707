export default {
  ACTION: 'Action',
  ADD_ANSWER: 'Add an answer',
  ADD_CLOSE_REASON: 'Add close reason',
  ADD_STEP: 'Add a step',
  ADD_TO_DO_NOT_CALL: "Adds contact's number to do not call list",
  ALTERNATE_TEXT: 'Alternate Text',
  ALZHEIMER_DEMENTIA_DIAGNOSIS: 'Alzheimer or Dementia Diagnosis',
  ANSWER_END_CALL: '- Ends the form after: {NEXT_STEP} -',
  ANSWER_END_FORM: '- End the form -',
  ANSWER_END_FORM_VIEW: 'Ends the call (Disposition: {DISPOSITION})',
  ANSWER_EXISTING_VALUE: 'The value already exists',
  ANSWER_HIDES: 'Steps not visible after this answer',
  ANSWER_INCOMPLETE: 'You need to fill the label',
  ANSWER_SELECTED_RULE:
    "Select this answer when the inquiry's {FIELD} is {OPERATION} {VALUE}",
  ANSWER_SHOWS: 'Steps visible after this answer',
  AROUND_CLOCK_MEDICAL_CARE_NEEDED: 'Around the clock medical care needed',
  BATHING_NO: 'No',
  BATHING_PROP: 'Bathing',
  BATHING_YES: 'Yes',
  BEHAVIORAL_COMBATIVE: 'Combative issues',
  BEHAVIORAL_NO: 'No',
  BEHAVIORAL_PROP: 'Behavioral',
  BEHAVIORAL_WANDERING: 'Wandering issues',
  BUDGET_BETWEEN_1000_1499: '$1000-$1499',
  BUDGET_BETWEEN_1500_1999: '$1500-$1999',
  BUDGET_BETWEEN_2000_2499: '$2000-$2499',
  BUDGET_BETWEEN_2500_2999: '$2500-$2999',
  BUDGET_BETWEEN_3000_3999: '$3000-$3999',
  BUDGET_BETWEEN_4000_4999: '$4000-$4999',
  BUDGET_BETWEEN_5000_5999: '$5000-$5999',
  BUDGET_BETWEEN_500_999: '$500-$999',
  BUDGET_BETWEEN_6000_6999: '$6000-$6999',
  BUDGET_BETWEEN_7000_7999: '$7000-$7999',
  BUDGET_BETWEEN_8000_8999: '$8000-$8999',
  BUDGET_BETWEEN_9000_9999: '$9000-$9999',
  BUDGET_OVER_10000: '$10000 and over',
  BUDGET_PROP: 'Budget',
  BUDGET_RANGE_PROP: 'Budget Range',
  BUDGET_UNDER_500: 'Under $500',
  CARE_TYPE_ADULT_DAY_SERVICES: 'Adult Day Services',
  CARE_TYPE_ASSISTED_LIVING: 'Assisted Living',
  CARE_TYPE_CODE_PROP: 'Care Type Code',
  CARE_TYPE_HOME: 'Home Care',
  CARE_TYPE_MEMORY: 'Memory Care',
  CARE_TYPE_NURSING_HOME: 'Nursing Home',
  CARE_TYPE_RESIDENTIAL: 'Residential Care Home',
  CARE_TYPE_RETIREMENT_COMUNITY: 'Retirement Community',
  CARE_TYPE_SENIOR_APARTMENTS: 'Senior Apartments',
  CAREGIVER_NO_LONGER_PROVIDING_SUPPORT:
    'Caregiver no longer providing support',
  CHANGE_DEFAULT_POOL_TYPE: 'Changes default Warm Transfer poolType',
  CHANGE_IN_LIFESTYLE: 'Change in lifestyle',
  ClOSE_REASON: 'Reason to close',
  CLOSES_INQUIRY: 'Closes the inquiry',
  CLOSES_INQUIRY_VIEW: 'Closes the inquiry (Reason: {REASON})',
  COMMUNITY_CUSTOMER_INQUIRY_REASON: 'Community Customer Inquiry',
  COMPLETE_CALL_ACTION: 'Complete call',
  COMPLETE_RESCHEDULE: 'Complete Reschedule',
  COMPLETE_WARM_TRANSFER: 'SLA Introduction',
  COMPLETE_WARM_TRANSFER_BUTTON: 'Introduction Complete',
  COMPLETE_WARM_TRANSFER_TEXT: `<p style="color: #3956B5">SLA is now Connected</p>
  <p>Hi <strong>{{winnerSlaName}}</strong>, I have {{contactFirstName}} from <strong>{{preferredLocation}}</strong> on the line. {{contactFirstName}} is searching for senior care for their <strong>{{relation}}</strong>.</p>
  <p><strong>{{contactFirstName}}</strong>, {{winnerSlaName}} will take great care of you. Have a great day.</p>`,
  COMPLETE_WT_FAMILY_DISCONNECTED: 'Complete Warm Transfer Family Disconnected',
  COMPLETE_WT_FAMILY_DISCONNECTED_DESCRIPTION:
    'Complete Warm Transfer after Family Disconnected.',
  CONFIRM_DELETE: 'Are you sure you want to delete this {TYPE}?',
  CONFIRM_DELETE_TITLE: 'Confirm delete',
  CONFIRM_INTEREST_PROP: 'Confirm Interest',
  CONTACT_CONFIRMATION_PROP: 'Contact Confirmation',
  CONTACT_INFO_DESCRIPTION:
    'Shows inputs for first name, last name, phone number and email',
  CONTACT_INFO_PROP: 'Contact Info',
  CORPORATE_INQUIRY_REASON: 'Corporate Inquiry',
  CREATED_AT: 'Created at',
  CREATED_BY: 'Created by',
  CURRENT_DATE_DEFAULT_FORMAT: 'E MM/dd/yyyy',
  CURRENT_STEP: 'Current Step: {STEP}',
  CURRENTLY_NURSING_HOME: 'Currently in a nursing home',
  DEFAULT_POOL_PROP: 'Default',
  DIABETIC_BLOOD_SUGAR_MONITORED: 'Blood sugar monitored',
  DIABETIC_INSULIN_INJECTIONS: 'Insulin injections',
  DIABETIC_NOT: 'Not diabetic',
  DIABETIC_PROP: 'Diabetic',
  DIABETIC_SELF: 'Self management',
  DIABETIC_SLIDING_SCALE: 'Sliding scale',
  DISCHARGE_HOSPITAL_REHAB: 'Discharge from Hospital or Rehab',
  DISCLAIMER_PROP: 'Disclaimer',
  DISPOSITION: 'Disposition',
  DISPOSITION_NOT_SET: 'Not set',
  DUPLICATE_ANSWER: 'Duplicate this answer',
  DUPLICATE_SCRIPT: 'Duplicate this script',
  DUPLICATE_STEP: 'Duplicate this step',
  EDIT_ANSWER: 'Edit Answer',
  EDIT_SCRIPT: 'Edit Script',
  EDIT_STEP: 'Edit Step',
  ENABLE_FOLLOWING_STEPS: 'Enable following steps',
  END_CALL_DESCRIPTION: 'Shows the text and an End Call image below',
  ERASE_CONTACT_INFO_FIELDS_ACTION: 'Erase Contact info fields',
  FAMILY_DISCONNECTED_COMPLETE_WT_TEXT:
    'Family has disconnected. Complete warm transfer.',
  FAMILY_DISCONNECTED_COMPLETE_WT_TITLE: 'Complete Warm Transfer',
  FAMILY_DISCONNECTED_PROP: 'Family Disconnected',
  FAMILY_INFO_DESCRIPTION:
    'Shows inputs for first and last name of the resident and the relationship to the caller',
  FAMILY_INFO_PROP: 'Family Info',
  FIELD: 'Field',
  FINANCIAL_FAMILY_SUPPORT: 'Family financial support',
  FINANCIAL_HOME_TO_SELL: 'Home to sell',

  FINANCIAL_LONG_TERM: 'Long term care insurance',
  FINANCIAL_MONTHLY_ADDITIONAL_INCOME: 'Monthly additional income',
  FINANCIAL_NONE: 'None',
  FINANCIAL_RESOURCES_PROP: 'Financial Resources',
  FINANCIAL_SAVINGS: 'Savings',
  FINANCIAL_STOCKS: 'Stocks/bonds/401ks/CDs/IRAs',
  FINANCIAL_VA_BENEFITS: 'VA benefits',
  FIND_BEST_SLA: 'Find Best SLA',
  FIND_BEST_SLA_ACTION: 'Find Best SLA',
  GET_AROUND_BEDRIDDEN: 'Bedridden unable to bear weight',
  GET_AROUND_CANE: 'Cane',
  GET_AROUND_INDEPENDENT: 'Independent',
  GET_AROUND_PROP: 'Get Around',
  GET_AROUND_TEMPORARILY: 'Temporarily immobile due to injury',
  GET_AROUND_WALKER: 'Walker',
  GET_AROUND_WHEELCHAIR: 'Wheelchair',
  HEALTH_CARE_PROP: 'Health Care',
  INFO_ADDED_TO_NOTES_ACTION: 'Info added to Notes',
  IS_COMPLETE_WARM_TRANSFER: 'Complete Warm Transfer',
  IS_COMPLETE_WARM_TRANSFER_DESCRIPTION: 'Complete Warm Transfer.',
  KEEP_CONTACT_INFO_FIELDS_ACTION: 'Keep Contact info fields',
  KEEP_REFERRAL_SOURCE_FIELDS_ACTION: 'Keep referral source fields',
  LABEL: 'Label',
  LESS_ONE_THOUSAND: 'Less than $1000',
  LINK: 'Link',
  LOCATION_DESCRIPTION: 'Shows input for location search with radius.',
  LOCATION_INFO_PROP: 'Location Info',
  MANY_CHOICES: 'Many Choices',
  MANY_CHOICES_DESCRIPTION: 'Shows the different choices as checkboxes',
  MAX_ATTEMPTS_REASON: 'Max Attempts',
  MEDICAID_CURRENTLY_APPLYING: 'Currently applying',
  MEDICAID_NO: 'No',
  MEDICAID_PROP: 'Medicaid',
  MEDICAID_YES: 'Yes',
  MEDICAL_INFO_PROP: 'Medical Info',
  MEMORY_LOSS_ALZHEIMER: 'Alzheimer',
  MEMORY_LOSS_DEMENTIA: 'Dementia',
  MEMORY_LOSS_NO: 'No',
  MEMORY_LOSS_OTHER: 'Other',
  MEMORY_LOSS_PROP: 'Memory Loss',
  MEMORY_LOSS_SPECIAL_MEMORY: 'Special memory care',
  MISSING_DISPOSITION: 'Select a disposition',
  MULTIPLE_CHOICE: 'Multiple Choice',
  MULTIPLE_CHOICE_DESCRIPTION: 'Shows the different choices as buttons',
  NAME: 'Name',
  NEW_ANSWER: 'New Answer',
  NEW_ORDER: '[new: {ORDER}]',
  NEW_SCRIPT: 'New Script',
  NEW_STEP: 'New Step',
  NEXT_STEP: 'Next Step',
  NO_CLOSE_INQUIRY: ' No, Close Inquiry',
  NON_ENGLISH_SPEAKER_REASON: 'Non-English Speaking',
  NOT_ANSWER_CALL_PROP: 'Not Answer Call',
  NOT_INTERESTED_REASON_PROP: 'Not Interested Reason',
  NOT_SEARCHING_OTHER_REASON: 'Not Searching - Other',
  NOTES_PROP: 'Notes',
  NUMBER: 'Number',
  OLD_ORDER: '[old: {ORDER}]',
  ONE_THOUSAND_AND_LESS_TWO_THOUSAND: '$1000-$1999',
  OPERATOR: 'Operator',
  ORDER: 'Order',
  OTHER_PROP: 'Other',
  PIPEDA_DISCLOSURE_PROP: 'Pipeda Disclosure',
  POOL_TYPE: 'Pool type',
  PREVIOUS_SLA_ACCEPTED_TEXT:
    '{{existingSlaName}} has accepted the transfer. Add them to the call',
  PREVIOUS_SLA_ACCEPTED_TITLE: 'Previous SLA Accepted',
  PREVIOUS_SLA_NOT_ACCEPTED: 'Previous SLA Not Accepted',
  PREVIOUS_SLA_NOT_ACCEPTED_TEXT:
    '{{existingSlaName}} has not accepted the transfer. Please try searching a new SLA',
  PREVIOUS_SLA_NOT_ACCEPTED_TITLE: 'Previous SLA did Not Accept',
  PROFESSIONAL_REFERRAL_PROP: 'Professional Referral',
  PROPERTY: 'Property',
  READ_PIPEDA_ACTION: 'Read Pipeda',
  REASON: 'Reason',
  RECORDING_DISCLAIMER: 'Recording Disclaimer',
  REFERRAL_SOURCE_PROP: 'Referral Source',
  ROOM_PREFERENCE_PRIVATE: 'Private',
  ROOM_PREFERENCE_PRIVATE_1_BEDROOM: 'Private one bedroom',
  ROOM_PREFERENCE_PRIVATE_2_BEDROOM: 'Private two bedroom',
  ROOM_PREFERENCE_PROP: 'Room Preference',
  ROOM_PREFERENCE_SHARED: 'Shared',
  ROOM_PREFERENCE_STUDIO: 'Studio',
  SCRIPT_EXISTING_SLUG: 'The slug already exists',
  SCRIPT_INCOMPLETE: 'You need to fill all the fields',
  SCRIPT_SNAPSHOTS: 'Snapshots by Script',
  SELECTED_RULE: 'Selected Rule',
  SEND_PIPEDA_EMAIL_ACTION: 'Send Pipeda Email',
  SET_SCRIPT_AS_ACTIVE: 'Set script as active',
  SETTING_THIS_SCRIPT_AS_ACTIVE:
    'Setting this script as active will deactivate: {SCRIPT_NAME}',
  SIZE: 'Size',
  SLA_IS_NOT_FOUND: 'SLA Not Found',
  SLA_IS_NOT_FOUND_DESCRIPTION: 'Shows message when no SLA was found.',
  SLA_MATCH_DESCRIPTION: 'Shows SLA match.',
  SLA_MATCH_FOUND: 'SLA Match',
  SLA_MATCH_FOUND_TEXT: `<p>Your Senior Living Advisor is <strong>{{winnerSlaName}}</strong>. {{winnerSlaName}} is very knowledgeable about the senior living/care options in your area.</p>
  <p>I’m going to go ahead an add them to this call and introduce you.</p>`,
  SLA_MATCH_NOT_FOUND:
    'Lead auto-assigned to Centralized box. Please let the family know we will follow up with them within 24h',
  SLUG: 'Slug',
  SNAPSHOT: 'Snapshot',
  SORT_INSTRUCTIONS: 'Drag and drop the {TYPE} to reorder them.',
  SORT_STEPS: 'Sort steps',
  STAY_IN_THE_SAME_STEP: '- Stay in the same step -',
  STEP_EXISTING_NUMBER: "Can't add a step with an existing number",
  STEP_HIDDEN: 'Initially hidden',
  STEP_INCOMPLETE: 'You need to fill all the fields',
  STEP_LEGAL: 'Is legal',
  STEP_OPTIONAL: 'Is optional',
  STEP_PIPEDA: 'Is PIPEDA',
  STEP_TYPE: 'Step type',
  TEXT: 'Text',
  TEXT_BOX_GROUP: 'Text Box Group',
  TEXT_BOX_GROUP_DESCRIPTION: 'Shows questions and answers as text boxes',
  TOILETING_NO: 'No',
  TOILETING_PROP: 'Toileting',
  TOILETING_YES: 'Yes',
  TRANSFER_SLA_PROP: 'Transfer SLA',
  TRANSFER_SUCCESSFUL_PROP: 'Transfer Successful',
  TRANSFER_TO_SLA: 'Add SLA to Call',
  TRANSFER_TO_SLA_ACTION: 'Transfer to SLA',
  TRANSFER_TO_SLA_DESCRIPTION:
    'Searches for SLA, produces a match and allows to transfer.',
  TRY_PREVIOUS_SLA: 'Try Previous SLA',
  TRY_PREVIOUS_SLA_BUTTON: 'Try Contacting SLA',
  TRY_PREVIOUS_SLA_TEXT:
    'We have found that you have been communicating with {{existingSlaName}}. We will try to contact them',
  TRY_PREVIOUS_SLA_TITLE: 'Try Previous SLA',
  TWO_THOUSAND_OR_GREATER: '$2000 or Greater',
  UNQUALIFIED_LOCATION: 'Unqualified - Location',
  UNQUALIFIED_PROP: 'Unqualified',
  UPDATE_PREFERRED_LOCATION_ACTION: 'Update Preferred Location',
  UPDATED_AT: 'Updated at',
  UPDATED_BY: 'Updated by',
  URGENCY_IMMEDIATELY: 'Immediately',
  URGENCY_LESS_30_DAYS: 'Less than 30 days',
  URGENCY_LESS_60_DAYS: 'Less than 60 days',
  URGENCY_LESS_90_DAYS: 'Less than 90 days',
  URGENCY_MORE_90_DAYS: 'More than 90 days',
  URGENCY_PROP: 'Urgency',
  URL_LINK_INVALID: 'The URL link is invalid',
  USER: 'User',
  VALUE: 'Value',
  VERSION: 'Version',
  VERSION_NAME: 'Version Name',
  VETERAN_NO: 'No',
  VETERAN_PROP: 'Veteran',
  VETERAN_YES: 'Yes',
  WHEN: 'When',
};
