import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    modal_sm: {
      width: '80%',
      height: '100%',
      '& div > p > b ': {
        fontSize: '13px',
      },
    },
    modal_xs: {
      width: '80%',
      height: '100%',
      '& div > p > b ': {
        fontSize: '13px',
      },
    },

    table_cell_current_call: {
      backgroundColor: theme.palette.neutral.dropdown,
      borderRight: `1px solid ${theme.palette.light.platinum1}`,
      padding: '0px 16px !important',
      height: '45px !important',
      justifyItems: 'center',
      wordBreak: 'break-all',
      [theme.breakpoints.down('xs')]: {
        borderRight: `2px solid ${theme.palette.dark.disabled}`,
        backgroundColor: theme.palette.neutral.dropdown,
        padding: '0px 16px !important',
        height: '45px !important',
        justifyItems: 'center',
      },
    },
    table_cell_current_call_location: {
      backgroundColor: theme.palette.neutral.dropdown,
      borderRight: `1px solid ${theme.palette.light.platinum1}`,
      padding: '0px !important',
      height: (progress) => `calc(45px + (9px * ${progress}))`,
      justifyItems: 'center',
      '& p': {
        width: '192px',
        overflowWrap: 'break-word',
      },
      [theme.breakpoints.down('xs')]: {
        borderRight: `2px solid ${theme.palette.dark.disabled}`,
        backgroundColor: theme.palette.neutral.dropdown,
        padding: '0px !important',
        height: (progress) => `calc(45px + (9px * ${progress}))`,
        justifyItems: 'center',
        '& p': {
          width: '192px',
          overflowWrap: 'break-word',
        },
      },
    },

    table_cell_matched_contact: {
      backgroundColor: theme.palette.neutral.dropdown,
      borderRight: `1px solid ${theme.palette.light.platinum1}`,
      padding: '0px 16px !important',
      height: '45px !important',
      justifyItems: 'center',
    },
    table_cell_default: {
      borderRight: `1px solid ${theme.palette.light.platinum1}`,
      padding: '0px 16px !important',
      height: '45px !important',
      justifyItems: 'center',
    },
    table_cell_default_location: {
      display: 'flex',
      padding: '0px 0px 0px 16px !important',
      justifyItems: 'center',
    },

    table_cell_default_location_family_file: {
      display: 'flex !important',
      padding: '0px 0px 0px 16px !important',
      justifyItems: 'center',
      '& p': {
        width: '192px',
        overflowWrap: 'break-word',
      },
    },
    table_cell_resident_location: {
      borderRight: `1px solid ${theme.palette.light.platinum1}`,
      padding: '0px!important',
      height: '45px !important',
      justifyItems: 'center',
    },
    table_cell_family_file_location: {
      borderRight: `1px solid ${theme.palette.light.platinum1}`,
      padding: '0px!important',
      height: (progress) => `calc(45px + (9px * ${progress}))`,
      justifyItems: 'center',
      '& p': {
        width: '192px',
        overflowWrap: 'break-word',
      },
    },
    table_cell_resident_matched_location: {
      borderRight: `1px solid ${theme.palette.light.platinum1}`,
      backgroundColor: theme.palette.neutral.dropdown,
      padding: '0px!important',
      height: '45px !important',
      justifyItems: 'center',
    },
    table_cell_resident_matched_location_family_file: {
      borderRight: `1px solid ${theme.palette.light.platinum1}`,
      backgroundColor: theme.palette.neutral.dropdown,
      padding: '0px!important',
      height: (progress) => `calc(45px + (9px * ${progress}))`,
      fontSize: '15px',
      justifyItems: 'center',
      '& p': {
        width: '192px',
        overflowWrap: 'break-word',
      },
    },

    table_cell_resident_more_locations: {
      borderRight: `1px solid ${theme.palette.light.platinum1}`,
      padding: '0px 0px 2px 0px !important',
      height: '45px !important',
      justifyItems: 'center',
    },

    table_cell_matched_contact_location: {
      display: 'flex',
      backgroundColor: theme.palette.neutral.dropdown,
      padding: '0px 0px 0px 16px !important',
      justifyItems: 'center',
    },
    table_cell_matched_contact_location_family_file: {
      display: 'flex !important',
      backgroundColor: theme.palette.neutral.dropdown,
      padding: '0px 0px 0px 16px !important',
      justifyItems: 'center',
      '& p': {
        width: '192px',
        overflowWrap: 'break-word',
      },
    },

    table_cell_current_call_title: {
      borderRight: `1px solid ${theme.palette.light.platinum1}`,
      height: '22px',
      [theme.breakpoints.down('xs')]: {
        borderRight: `2px solid ${theme.palette.dark.disabled}`,
      },
    },

    table_cell_title: {
      borderRight: `2px solid ${theme.palette.dark.disabled}`,
      minWidth: '111px',
    },
    table_cell_check_box: {
      textAlign: 'center',
      borderRight: `1px solid ${theme.palette.light.platinum1}`,
      height: '21px',
    },
    check_box: {
      width: '20px',
      height: '10px',
    },

    column_button: {
      marginTop: '38px',
      [theme.breakpoints.down('xl')]: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& div': {
          paddingLeft: '24px',
          paddingTop: '10px',
        },
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& div': {
          paddingLeft: '24px',
          paddingTop: '10px',
        },
      },

      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        '& div': {
          paddingTop: '12px',
          justifyItems: 'center',
        },
      },
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
        display: 'block',
        '& div': {
          paddingTop: theme.spacing(1),
          paddingLeft: '127px',
          justifyItems: 'center',
        },
      },
    },

    header_content: {
      [theme.breakpoints.down('xl')]: {
        paddingBottom: '20px',
      },
      [theme.breakpoints.down('md')]: {
        paddingBottom: '20px',
      },
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '10px',
      },
      [theme.breakpoints.down('xs')]: {
        paddingBottom: '10px',
      },
    },
    table_container: {
      display: 'flex',
    },

    scrollable_table: {
      overflowX: 'scroll',
    },
    header: {
      '& tr': {
        borderBottom: `1px solid ${theme.palette.light.platinum1}`,
      },
    },
    button_not_merge: {
      [theme.breakpoints.down('xs')]: {
        marginLeft: '-14px',
        marginTop: '8px',
      },
    },

    title_label: {
      paddingTop: '1.5px',
      paddingBottom: '1.5px',
    },
    resident_message: {
      paddingTop: '10px',
    },
    typography: {
      display: 'inline',
    },
    custom_label: {
      width: '192px',
      overflowWrap: 'break-word',
    },
    typography_current: {
      display: 'inline',
      paddingRight: '20px',
    },
    longTag: {
      overflow: 'hidden',
      width: '170px',
      whiteSpace: 'nowrap',
    },
    bold_blue: {
      color: theme.palette.neutral.cosmicCobalt3,
      fontWeight: 'bold',
    },
    toast: {
      [theme.breakpoints.down('xl')]: {
        paddingBottom: '40px',
      },
      [theme.breakpoints.down('md')]: {
        paddingBottom: '40px',
      },
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '30px',
      },
      [theme.breakpoints.down('xs')]: {
        paddingBottom: '10px',
      },
    },
    link: {
      color: theme.palette.correction.smokyTopaz2,
      '&:visited': {
        color: theme.palette.correction.smokyTopaz2,
      },
    },
    link_beacon: {
      color: theme.palette.neutral.cosmicCobalt3,
      '&:visited': {
        color: theme.palette.neutral.cosmicCobalt3,
      },
    },
    status_active: {
      color: theme.palette.dark.eerieBlack1,
    },
    status_active_matched: {
      backgroundColor: theme.palette.neutral.dropdown,
    },
  };
});

export { useStyles };
