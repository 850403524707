import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'common/Typography/Typography';
import { useStyles } from './FinancialCriteria.style';
import Toast from 'common/Toast/Toast';

const FinancialCriteriaErrorMessage = ({
  showSuccessMessage,
  labels,
  handleClose,
}) => {
  const classes = useStyles();

  if (showSuccessMessage) {
    return (
      <div className={classes.show_message}>
        <Toast type="error" onClose={handleClose}>
          <Typography>{labels.FIELDS_SHOULD_NOT_BE_EMPTY}</Typography>
        </Toast>
      </div>
    );
  }
};

FinancialCriteriaErrorMessage.propTypes = {
  labels: PropTypes.object.isRequired,
  showSuccessMessage: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default FinancialCriteriaErrorMessage;
