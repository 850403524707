import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles(() => {
  return {
    tag_container: {
      display: 'flex',
      width: 'fit-content',
      fontSize: '12px',
      lineHeight: '19px',
      textAlign: 'center',
      padding: '4px 10px',
      borderRadius: '16px',
      alignItems: 'center',
      margin: '3px 0px',
      '&.dnc': {
        color: 'white',
      },
      '&:not(:first)': {
        margin: '.5rem',
      },
    },
    close_icon: {
      display: 'flex',
      cursor: 'pointer',
      marginLeft: '6px',
    },
    icon: {
      display: 'flex',
      marginRight: '8px',
    },
  };
});

export { useStyles };
