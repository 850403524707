import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Button from 'common/Button/Button';
import Modal from 'common/Modal/Modal';
import useGraphQLQuery from 'hooks/useGraphQLQuery';
import { useStyles } from './CommunityAdvisory.style';
import CommunityAdvisoryDetail from './CommunityAdvisoryDetail';
import { ADVISORY_TYPE, getAdvisoryByType } from './CommunityAdvisoryService';
import findAdvisoriesQuery from './findCommunityAdvisoryDataQuery';

const CommunityAdvisoryModal = ({
  labels,
  isModalOpen,
  onClose,
  communityName,
  communityId,
}) => {
  const classes = useStyles();
  const [naturalDisasterAdvisory, setNaturalDisasterAdvisory] = useState();
  const [healthAdvisory, setHealthAdvisory] = useState();
  const [constructionAdvisory, setConstructionAdvisory] = useState();

  const { data } = useGraphQLQuery(findAdvisoriesQuery, {
    variables: { communityId: parseInt(communityId) },
  });

  const getTitle = () => {
    return `Advisories for ${communityName}`;
  };

  useEffect(() => {
    if (data?.findAdvisoryData) {
      let communityAdvisories = data.findAdvisoryData;
      setNaturalDisasterAdvisory(
        getAdvisoryByType(ADVISORY_TYPE.NATURAL_DISASTER, communityAdvisories),
      );
      setHealthAdvisory(
        getAdvisoryByType(ADVISORY_TYPE.HEALTH, communityAdvisories),
      );
      setConstructionAdvisory(
        getAdvisoryByType(ADVISORY_TYPE.CONSTRUCTION, communityAdvisories),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Modal isOpen={isModalOpen} onClose={onClose} title={getTitle()}>
      <div className={classes.advisory_modal_container}>
        <div>{labels.PLEASE_INFORM_FAMILY_ABOUT_RESTRICTIONS}</div>
        {naturalDisasterAdvisory && (
          <CommunityAdvisoryDetail
            type={ADVISORY_TYPE.NATURAL_DISASTER}
            advisory={naturalDisasterAdvisory}
          />
        )}
        {healthAdvisory && (
          <CommunityAdvisoryDetail
            type={ADVISORY_TYPE.HEALTH}
            advisory={healthAdvisory}
          />
        )}
        {constructionAdvisory && (
          <CommunityAdvisoryDetail
            type={ADVISORY_TYPE.CONSTRUCTION}
            advisory={constructionAdvisory}
          />
        )}
      </div>
      <div className={classes.btn_container}>
        <Button className={classes.close_button} onClick={onClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

CommunityAdvisoryModal.propTypes = {
  labels: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool,
  onClose: PropTypes.func,
  communityName: PropTypes.string,
  communityId: PropTypes.string.isRequired,
};

CommunityAdvisoryModal.defaultProps = {
  isModalOpen: false,
  onClose: () => {},
  communityName: '',
};

export default CommunityAdvisoryModal;
