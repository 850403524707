export const lookupLocation = (
  placesService,
  geocoderService,
  preferredLocation,
  placeId,
) => {
  return new Promise((resolve, reject) => {
    const request = {
      fields: ['geometry'],
      placeId: placeId.toString(),
    };
    placesService.getDetails(request, (place, status) => {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        const errorMessage = `PlacesInput. Error finding place details: ${placeId}, status: ${status}`;
        console.error(errorMessage);
        reject(new Error(errorMessage));
        return;
      }

      geocoderService.geocode(
        { location: place.geometry.location },
        (results, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
            const errorMessage =
              `PlacesInput. Error finding locations for geocode: ` +
              `${place.geometry.location.lat()}/${place.geometry.location.lng()}, status: ${status}`;
            console.error(errorMessage);
            reject(new Error(errorMessage));
            return;
          }

          if (results.length === 0) {
            const errorMessage =
              `PlacesInput. No locations for geocode: ` +
              `${place.geometry.location.lat()}/${place.geometry.location.lng()}`;
            console.error(errorMessage);
            reject(new Error(errorMessage));
            return;
          }

          const updatedLocation = getStructuredLocation(
            preferredLocation,
            results[0].address_components,
          );
          resolve(updatedLocation);
          return;
        },
      );
    });
  });
};

const getStructuredLocation = (preferredLocation, addressComponents) => {
  let location = preferredLocation;
  addressComponents.forEach((component) => {
    if (component.types.includes('locality')) {
      location.city = component.long_name;
    }
    if (component.types.includes('administrative_area_level_1')) {
      location.state = component.short_name;
    }
    if (component.types.includes('postal_code')) {
      location.zip = component.long_name;
    }
    if (component.types.includes('country')) {
      location.country = component.long_name;
    }
  });

  return location;
};
