import React, { Fragment, useState, useEffect } from 'react';
import { useStyles } from './CaseBasicInformation.style';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '../../common/Typography/Typography';
import Tag from '../../common/Tag/Tag';
import PropTypes from 'prop-types';
import clonedeep from 'lodash.clonedeep';
import SmartTooltip from '../../common/SmartTooltip/SmartTooltip';
import PreferredLocation from './PreferredLocation';
import CallMuted from '../../common/Icons/call/CallMuted';
import Button from '../../common/Button/Button';
import PencilEdit from '../../common/Icons/basic/PencilEdit';
import ResidentUpdate from '../../services/Resident/ResidentUpdate';
import { inFamilyFilePage } from '../../services/familyFileService';

import ShowNotesButton from '../../pages/SendReferral/Sidebar/ShowNotesButton';
import NotesSidePanel from '../NotesSidePanel/NotesSidePanel';

import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import AudienceTag from '../AudienceTag/AudienceTag';

const CaseBasicInformation = ({
  labels,
  basicInformation,
  size,
  shouldRefetch,
  flags,
}) => {
  const location = useLocation();
  const [
    editResidentInformationOpen,
    setEditResidentInformationOpen,
  ] = useState(false);

  const [isNotesPanelOpen, setIsNotesPanelOpen] = useState(true);
  const [residentInformation] = useState(clonedeep(basicInformation));
  const isScreenSizeLarge = useMediaQuery('(max-width:1440px)');

  useEffect(() => {
    if (isScreenSizeLarge && flags.onlyShowCommunitiesTabFf) {
      setIsNotesPanelOpen(false);
    }
  }, [isScreenSizeLarge, flags]);

  const inFamilyFilePageAndXSSize = () => {
    if (inFamilyFilePage(location) && size === 'xs') {
      return true;
    }
    return false;
  };

  const classes = useStyles(inFamilyFilePageAndXSSize());

  const refetchStateHandler = () => {
    shouldRefetch(true);
  };

  const getDisplayContactRelationship = () => {
    const { primaryContactRelationWithResident } = residentInformation;
    return primaryContactRelationWithResident
      ? ` (${primaryContactRelationWithResident})`
      : '';
  };

  return (
    <Fragment>
      <ResidentUpdate
        labels={labels}
        basicInformation={residentInformation}
        handleRefetchState={refetchStateHandler}
        editResidentInformationOpen={editResidentInformationOpen}
      >
        <div
          className={clsx(
            classes.basic_information_container,
            'basic_information_container',
          )}
        >
          <div
            className={clsx(
              classes.inline,
              classes.basic_information_title_container,
            )}
          >
            <Typography level="h4">
              {residentInformation.primaryContact}
              <Typography level="small">
                {getDisplayContactRelationship()}
              </Typography>
            </Typography>

            {residentInformation.doNotCall && (
              <Typography
                color="smokyTopaz2"
                level="h4"
                className={classes.do_not_call_container}
              >
                <SmartTooltip title={labels.DO_NOT_CALL} placement="bottom">
                  <CallMuted />
                </SmartTooltip>
              </Typography>
            )}

            {inFamilyFilePageAndXSSize() ? (
              flags.onlyShowCommunitiesTabFf ? (
                <div
                  className={clsx(
                    classes.inline,
                    classes.basic_information_title_container,
                  )}
                >
                  <ShowNotesButton
                    labels={labels}
                    onClick={() => setIsNotesPanelOpen(true)}
                    onlyShowCommunitiesTabFf={flags.onlyShowCommunitiesTabFf}
                  />

                  <NotesSidePanel
                    familyFileId={residentInformation.familyFileId}
                    labels={labels}
                    isOpen={isNotesPanelOpen}
                    onClose={() => setIsNotesPanelOpen(false)}
                  />
                </div>
              ) : (
                <Button
                  className={classes.edit_resident_info_button}
                  type="primary"
                  onClick={() => setEditResidentInformationOpen(true)}
                  startIcon={<PencilEdit />}
                >
                  {labels.EDIT}
                </Button>
              )
            ) : (
              ''
            )}
          </div>

          <div className={`${classes.row} ${classes.family_file_id_container}`}>
            <Typography level="small" color="eerieBlack5">
              {labels.FAMILY_FILE_ID}
            </Typography>

            <div className={classes.family_file_id}>
              <Typography level="small" bold>
                {residentInformation.familyFileId}
              </Typography>
            </div>
          </div>

          <div className={`${classes.row} ${classes.row_container}`}>
            <div className={`${classes.column} ${classes.locations}`}>
              <Typography
                className={classes.label}
                color="eerieBlack5"
                level="small"
              >
                {labels.PREFERRED_LOCATIONS || ''}
              </Typography>
              <div>
                <PreferredLocation
                  preferredLocations={residentInformation.preferredLocations}
                />
              </div>
            </div>
          </div>

          <div className={`${classes.row} ${classes.row_container}`}>
            <div className={clsx(classes.inline, classes.care_types_container)}>
              <AudienceTag
                audience={residentInformation.audienceKey}
                labels={labels}
              />

              {/*<Tag text="Medical 3B" color="cosmicCobalt2" />*/}
              <Tag text={residentInformation.salesPhase} color="fieldDrab5" />
              {residentInformation.careTypes.length > 0
                ? residentInformation.careTypes.map((careType, index) => {
                    return (
                      <SmartTooltip
                        key={index}
                        title={`${careType.name} (${careType.code})`}
                        placement="bottom"
                      >
                        <Tag
                          className={classes.oneLetterTag}
                          text={careType.code}
                          color="regalia5"
                        />
                      </SmartTooltip>
                    );
                  })
                : []}
            </div>
          </div>
          <div className={`${classes.row} ${classes.row_container}`}>
            <div className={classes.column}>
              <Typography
                className={classes.label}
                color="eerieBlack5"
                level="small"
              >
                {labels.RESIDENT}
              </Typography>
              <Typography level="body">
                <b>
                  {residentInformation.resident || labels.UNKNOWN},{' '}
                  {residentInformation.age}
                </b>
              </Typography>
            </div>
            <div className={classes.column}>
              <Typography
                className={classes.label}
                color="eerieBlack5"
                level="small"
              >
                {labels.EXPECTED_MOVE_IN_DATE}
              </Typography>
              <Typography bold level="body">
                {residentInformation.expectedMove} (
                {residentInformation.likeliHoodToMove}
                {labels.PERCENTAGE})
              </Typography>
            </div>
          </div>
        </div>
      </ResidentUpdate>
    </Fragment>
  );
};

CaseBasicInformation.propTypes = {
  labels: PropTypes.object.isRequired,
  basicInformation: PropTypes.shape({
    familyFileId: PropTypes.number.isRequired,
    resident: PropTypes.string,
    salesPhase: PropTypes.string,
    location: PropTypes.string,
    primaryContact: PropTypes.string,
    expectedMove: PropTypes.string,
    likeliHoodToMove: PropTypes.number,
    age: PropTypes.number,
    careTypes: PropTypes.arrayOf(PropTypes.object),
    preferredLocations: PropTypes.arrayOf(PropTypes.object),
    doNotCall: PropTypes.bool.isRequired,
  }).isRequired,
  size: PropTypes.string,
  shouldRefetch: PropTypes.func,
  flags: PropTypes.shape({
    onlyShowCommunitiesTabFf: PropTypes.bool,
  }),
};

export default CaseBasicInformation;
