import ReactGA from 'react-ga';
import labels from '../config/labels';
import withConfig from '../../src/utils/withConfig';
import { getAnalyticsRoles } from '../config/roles';
import { getCurrentUserFromSessionStorage } from '../utils/utils';

import dimensionsGa from '../config/dimensionsGA';

const enableMetrics = (currentUser) =>
  getAnalyticsRoles().includes(currentUser?.role);

export const initializeAnalyticsService = () => {
  const currentUser = getCurrentUserFromSessionStorage();
  if (enableMetrics(currentUser)) {
    const tracking_id = withConfig('TRACKING_ID');
    ReactGA.initialize(tracking_id, {
      titleCase: false,
      gaOptions: {
        siteSpeedSampleRate: 100,
      },
    });
  } else {
    console.log(labels.METRICS_ROLE_WARNING);
  }
};

const sendEventWithDimensions = (currentUser, event, customDimensions = []) => {
  ReactGA.ga(() => {
    ReactGA.set({ [dimensionsGa.userRole]: currentUser.role });
    ReactGA.set({ [dimensionsGa.userEmail]: currentUser.email });
    if (customDimensions.length > 0) {
      customDimensions.forEach((customDimension) => {
        if (customDimension.name === 'COMMUNITY_ID') {
          ReactGA.set({ [dimensionsGa.communityId]: customDimension.value });
        }
      });
    }
    event();
  });
};

export const registerEvent = (
  category,
  action,
  label = '',
  nonInteraction = false,
  customDimensions = [],
) => {
  const currentUser = getCurrentUserFromSessionStorage();
  if (enableMetrics(currentUser)) {
    sendEventWithDimensions(
      currentUser,
      () => ReactGA.event({ category, action, label, nonInteraction }),
      customDimensions,
    );
  } else {
    console.log(labels.METRICS_ROLE_WARNING);
  }
};

export const registerPage = (page) => {
  const currentUser = getCurrentUserFromSessionStorage();
  if (enableMetrics(currentUser)) {
    sendEventWithDimensions(currentUser, () => ReactGA.pageview(page));
  } else {
    console.log(labels.METRICS_ROLE_WARNING);
  }
};
