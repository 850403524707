import React from 'react';
import { CongratulationsBox } from '../CongratulationsBox/CongratulationsBox';
import FlipEffect from '../FlipEffect/FlipEffect';
import Quota from './Quota';
import SurpassingQuota from '../SurpassingQuota/SurpassingQuota';
import PropTypes from 'prop-types';

const QuotaFlipHelper = ({ userGivenName, labels, quota, points }) => {
  if (points > quota) {
    return (
      <FlipEffect
        frontComponent={
          <CongratulationsBox
            userGivenName={userGivenName}
            points={points}
            labels={labels}
          />
        }
        backComponent={
          <SurpassingQuota labels={labels} points={points} quota={quota} />
        }
      />
    );
  }

  if (points === quota && points !== 0) {
    return (
      <FlipEffect
        frontComponent={
          <CongratulationsBox
            userGivenName={userGivenName}
            points={points}
            labels={labels}
          />
        }
        backComponent={<Quota labels={labels} points={points} quota={quota} />}
      />
    );
  }

  return <Quota labels={labels} points={points} quota={quota} />;
};

QuotaFlipHelper.propTypes = {
  userGivenName: PropTypes.string,
  labels: PropTypes.object,
  quota: PropTypes.number,
  points: PropTypes.number,
};

export default QuotaFlipHelper;
