import { SalesPhaseBucket } from '../../components/NavigationSidePanel/NavigationSidePanel';

const getLabelColor = (bucketId) => {
  if (bucketId === SalesPhaseBucket.NEW_PREFERRED) {
    return 'fieldDrab5';
  }
  if (bucketId === SalesPhaseBucket.RECENT_REFERRALS) {
    return 'wintergreenDream5';
  }
  if (bucketId === SalesPhaseBucket.OLDER_REFERRALS) {
    return 'wintergreenDream5';
  }
  if (bucketId === SalesPhaseBucket.TOURING) {
    return 'wintergreenDream5';
  }
  if (bucketId === SalesPhaseBucket.MOVEINS) {
    return 'hotCinnamon5';
  }
  if (bucketId === SalesPhaseBucket.CLOSED) {
    return 'smokyTopaz2';
  }
};

export default { getLabelColor };
