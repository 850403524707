import gql from 'graphql-tag';

export default gql`
  mutation(
    $inquiryId: Int!
    $recipientEmail: String
    $status: String!
    $rejectedReason: String
  ) {
    createInquiryPipedaEmailLog(
      input: {
        inquiryId: $inquiryId
        recipientEmail: $recipientEmail
        status: $status
        rejectedReason: $rejectedReason
      }
    ) {
      pipedaEmailLogId
    }
  }
`;
