import React from 'react';
import PropTypes from 'prop-types';

const ClipboardDelete = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="currentColor"
        d="M8.99592 2C9.7482 1.36297 10.8391 1 12 1C13.1609 1 14.2518 1.36297 15.0041 2H16C17.1046 2 18 2.89543 18 4H19C20.1046 4 21 4.89543 21 6V21C21 22.1046 20.1046 23 19 23H5C3.89543 23 3 22.1046 3 21V6C3 4.89543 3.89543 4 5 4H6C6 2.89543 6.89543 2 8 2H8.99592ZM16 7C16.7403 7 17.3866 6.5978 17.7324 6H19V21H5V6H6.26756C6.61337 6.5978 7.25972 7 8 7H16ZM14.2929 10.2929L12 12.5858L9.70711 10.2929L8.29289 11.7071L10.5858 14L8.29289 16.2929L9.70711 17.7071L12 15.4142L14.2929 17.7071L15.7071 16.2929L13.4142 14L15.7071 11.7071L14.2929 10.2929ZM9.85762 4L10.1566 3.65538C10.4852 3.27674 11.1894 3 12 3C12.8106 3 13.5148 3.27674 13.8434 3.65538L14.1424 4H16V5H8V4H9.40135H9.85762Z"
      />
    </svg>
  );
};

ClipboardDelete.propTypes = {
  className: PropTypes.string,
};

export default ClipboardDelete;
