import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './LastContactedXS.style';
import { getIcon } from '../services/getActionIconService';
import Typography from 'common/Typography/Typography';
import { getText } from '../services/getActionTextService';
import { format } from 'common/utils/dateFormat';

const LastContactedXS = ({ lastContacted, labels }) => {
  const classes = useStyles();
  return (
    <div className={classes.last_contacted_container}>
      <Typography color="eerieBlack5" level="tiny">
        {`${labels.LAST_CONTACTED}:`}
      </Typography>
      {!lastContacted && (
        <Typography level="tiny" color="eerieBlack5">
          {labels.NOT_CONTACTED_YET}
        </Typography>
      )}
      {lastContacted && (
        <>
          {getIcon(lastContacted, classes)}
          <Typography level="tiny" bold>
            {getText(lastContacted)}
          </Typography>
          <Typography level="tiny" color="eerieBlack5">
            {format(lastContacted.date, 'DDD, MMM DD')}
          </Typography>
        </>
      )}
    </div>
  );
};

LastContactedXS.propTypes = {
  lastContacted: PropTypes.object,
  labels: PropTypes.object,
};

export default LastContactedXS;
