import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => ({
  search_results_table_container: {
    marginTop: '24px',
  },
  pagination_container: {
    display: 'flex',
    paddingBottom: '16px',
    justifyContent: 'center',
  },
}));

export { useStyles };
