import React from 'react';
import PropTypes from 'prop-types';

const Logout = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9602 21.927H2.99002C2.43984 21.927 1.99336 21.4804 1.99336 20.9303V2.99006C1.99336 2.43989 2.43989 1.99341 2.99002 1.99341H11.9602C12.5113 1.99341 12.9568 1.54791 12.9568 0.99675C12.9568 0.445594 12.5113 0 11.9602 0H2.99002C1.34152 0 0 1.34156 0 2.99006V20.9303C0 22.5788 1.34152 23.9203 2.99002 23.9203H11.9602C12.5113 23.9203 12.9568 23.4748 12.9568 22.9236C12.9568 22.3725 12.5113 21.927 11.9602 21.927Z"
        fill="currentColor"
      />
      <path
        d="M23.7027 11.2514L17.6429 5.27131C17.2522 4.88459 16.6203 4.88961 16.2336 5.2813C15.8469 5.67298 15.8508 6.30387 16.2436 6.69059L20.5741 10.9643H8.9698C8.41864 10.9643 7.97314 11.4098 7.97314 11.961C7.97314 12.5121 8.41864 12.9577 8.9698 12.9577H20.5741L16.2436 17.2314C15.8509 17.6181 15.8479 18.249 16.2336 18.6407C16.4289 18.8381 16.6861 18.9377 16.9432 18.9377C17.1964 18.9377 17.4495 18.842 17.6429 18.6507L23.7027 12.6706C23.8921 12.4832 23.9997 12.228 23.9997 11.9609C23.9997 11.6939 23.893 11.4398 23.7027 11.2514Z"
        fill="currentColor"
      />
    </svg>
  );
};

Logout.propTypes = {
  className: PropTypes.string,
};

export default Logout;
