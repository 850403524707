import {
  TELEPHONY_IFRAME_HIDE,
  TELEPHONY_IFRAME_SHOW,
  HAS_REDIALED,
  UPDATE_CALL_STATE,
  TELEPHONY_WARM_TRANSFER_UPDATE,
  SET_CALL_ON_WRAP_UP_BY_AGENT,
  SET_CALL_ON_WRAP_UP_BY_CALLER,
  RESET_TELEPHONY_STATE,
  DISPOSITION_MODAL_OPEN,
} from '../actions/telephonyActions';

const initialState = {
  iframeVisible: false,
  hasRedialed: false,
  callState: null,
  previousCallState: null,
  warmTransfer: {},
  isWrapUpByAgent: false,
  isWrapUpByCaller: false,
  dispositionModalOpen: false,
};

const telephonyReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_TELEPHONY_STATE:
      return initialState;
    case TELEPHONY_IFRAME_SHOW:
      return { ...state, iframeVisible: true };
    case TELEPHONY_IFRAME_HIDE:
      return { ...state, iframeVisible: false };
    case HAS_REDIALED:
      return { ...state, hasRedialed: action.payload.hasRedialed };
    case UPDATE_CALL_STATE:
      return { ...state, callState: action.payload.callState };
    case TELEPHONY_WARM_TRANSFER_UPDATE:
      return { ...state, warmTransfer: action.payload.warmTransfer };
    case SET_CALL_ON_WRAP_UP_BY_AGENT:
      return { ...state, isWrapUpByAgent: action.payload.isWrapUpByAgent };
    case SET_CALL_ON_WRAP_UP_BY_CALLER:
      return { ...state, isWrapUpByCaller: action.payload.isWrapUpByCaller };
    case DISPOSITION_MODAL_OPEN:
      return {
        ...state,
        dispositionModalOpen: action.payload.dispositionModalOpen,
      };
    default:
      return state;
  }
};

export const selectTelephonyIframeVisibility = (state) =>
  state?.telephony?.iframeVisible;

export const selectHasRedialed = (state) => state?.telephony?.hasRedialed;
export const selectCallState = (state) => state?.telephony?.callState;

export const selectCallOnWrapUpByAgent = (state) =>
  state?.telephony?.isWrapUpByAgent;
export const selectCallOnWrapUpByCaller = (state) =>
  state?.telephony?.isWrapUpByCaller;

export const selectTelephonyWarmTransfer = (state) =>
  state?.telephony?.warmTransfer;

export const selectDispositionModalOpen = (state) =>
  state?.telephony?.dispositionModalOpen;

export default telephonyReducer;
