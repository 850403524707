const isCallAction = (type) => {
  return type.match(new RegExp('Call', 'gi'));
};

const isEmailAction = (type) => {
  return type.match(new RegExp('Mail', 'gi'));
};

const isTextAction = (type) => {
  return type.match(new RegExp('Text', 'gi'));
};

const isOutReachAction = (type) => {
  return type.match(new RegExp('Outreach', 'gi'));
};

export { isCallAction, isEmailAction, isTextAction, isOutReachAction };
