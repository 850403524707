import { calculateDiff } from 'utils/calculateDiff';

class FamilyFileFormModel {
  calculateDiff(other) {
    const keysToCompare = [...Object.keys(this)].filter(
      (key) => key !== 'calculateDiff',
    );

    return calculateDiff(this, other, keysToCompare);
  }
}

export default FamilyFileFormModel;
