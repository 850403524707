import React, { useRef } from 'react';
import MaterialModal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from './Modal.style';
import Typography from '../Typography/Typography';
import X from '../Icons/basic/X';
import Overlay from '../Overlay/Overlay';
import { useBackgroundColorStyles } from '../style/backgroundColor';
import { colorReference } from '../Theme/themes/default';
import useOnClickOutside from '../../hooks/useOnClickOutside';

const Modal = ({
  children,
  isOpen,
  onClose,
  className,
  icon,
  title,
  color,
  noBodyScroll,
  noBodyPadding,
  showCloseIcon,
  closeOnClickOutside,
}) => {
  const classes = useStyles();
  const bodyRef = useRef();
  const backgroundClasses = useBackgroundColorStyles();

  useOnClickOutside(bodyRef, () => {
    if (closeOnClickOutside) {
      onClose();
    }
  });

  const handleOnClose = (e) => {
    if (bodyRef.current && !bodyRef.current.contains(e.target)) {
      onClose();
    }
  };

  const bodyClass = clsx(
    classes.body_container,
    noBodyScroll ? classes.no_scroll : '',
    noBodyPadding ? classes.no_body_padding : '',
  );
  return (
    <MaterialModal open={isOpen} onClose={onClose} BackdropComponent={Overlay}>
      <div
        className={clsx(className, classes.modal_container, 'modal_container')}
      >
        <div
          className={clsx(classes.title_container, backgroundClasses[color])}
        >
          <div className={classes.title}>
            {icon}
            <Typography bold color="inherit">
              {title}
            </Typography>
          </div>
          {showCloseIcon && (
            <div
              className={clsx(classes.close_icon, backgroundClasses[color])}
              onClick={handleOnClose}
            >
              <X />
            </div>
          )}
        </div>
        <div ref={bodyRef} className={bodyClass}>
          {children}
        </div>
      </div>
    </MaterialModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  closeOnClickOutside: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.oneOf([...colorReference, 'transparent']),
  noBodyScroll: PropTypes.bool,
  noBodyPadding: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
};

Modal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  className: '',
  color: 'platinum4',
  noBodyScroll: false,
  noBodyPadding: false,
  showCloseIcon: true,
};

export default Modal;
