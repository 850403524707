import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  navigation_side_panel_container: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: theme.palette.neutral.cosmicCobalt1,
  },
  manual_inquiry_button: {
    width: '183px',
    height: '40px',
    fontSize: '14px',
    padding: 0,
  },
  button_items: {
    display: 'flex',
    alignItems: 'center',
    padding: '13px 20px',
  },
  list_item: {
    display: 'flex',
    alignItems: 'center',
    padding: '13px 20px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.neutral.cosmicCobalt2,
    },
    '& svg': {
      color: theme.palette.light.platinum5,
      fill: theme.palette.light.platinum5,
      fontSize: '24px',
    },
    '& div': {
      marginLeft: '20px',
    },
  },
  square_stairs: {
    marginRight: 'auto',
    marginTop: 'auto',
    color: theme.palette.neutral.cosmicCobalt2,
    fontSize: '58px',
  },
  item_active: {
    backgroundColor: theme.palette.neutral.cosmicCobalt2,
  },
}));

export { useStyles };
