/* eslint-disable no-unused-vars */
import { getMonthAndYearDate } from '../utils/dateFormat';

const handleOnChange = (propertyName, setFieldError, setProperty) => (
  value,
) => {
  setProperty(propertyName, value);
  if (value.trim()) {
    setFieldError(null);
  }
};

const isEmptyContact = (contactInfo) => {
  return (
    contactInfo === undefined ||
    (!contactInfo.firstName && !contactInfo.lastName)
  );
};

const isContactsFormChanged = (original, edited) => {
  if (isEmptyContact(edited?.secondaryContact)) {
    delete original?.secondaryContact;
    delete edited?.secondaryContact;
  }
  if (isEmptyContact(edited?.additionalContact)) {
    delete original?.additionalContact;
    delete edited?.additionalContact;
  }
  return JSON.stringify(original) !== JSON.stringify(edited);
};

const isResidentsFormChanged = (original, edited) => {
  let {
    doNotCall: _unused1,
    oneId: _unused2,
    expectedMove: originalExpectedMove,
    preferredLocations: originalPreferredLocations,
    ...originalInfo
  } = original;

  const originalLocations = originalPreferredLocations.map(
    ({ isLastLocation, ...keepAttrs }) => keepAttrs,
  );

  let {
    doNotCall: _unused3,
    oneId: _unused4,
    expectedMove: editedExpectedMove,
    preferredLocations: editedPreferredLocations,
    ...editedInfo
  } = edited;

  let editedLocations = editedPreferredLocations.map((location) => {
    if (location.city) {
      const { isLastLocation, ...keepAttrs } = location;
      return keepAttrs;
    }
    return null;
  });

  editedLocations = editedLocations.filter((location) => location !== null);

  if (originalExpectedMove === 'Undecided') {
    originalExpectedMove = null;
  }
  if (editedExpectedMove) {
    editedExpectedMove = getMonthAndYearDate(editedExpectedMove);
  }

  return (
    JSON.stringify(originalInfo) !== JSON.stringify(editedInfo) ||
    originalExpectedMove !== editedExpectedMove ||
    JSON.stringify(originalLocations) !== JSON.stringify(editedLocations)
  );
};

export {
  handleOnChange,
  isEmptyContact,
  isContactsFormChanged,
  isResidentsFormChanged,
};
