import axios from 'axios';
import cache from '../utils/cache';
import withConfig from '../utils/withConfig';

const getHeaders = () => {
  const headers = {
    authorization: cache.get('ID_TOKEN'),
  };
  const additionalEmail = localStorage.getItem('additional_email');
  if (additionalEmail) {
    headers['additional_email'] = additionalEmail;
  }

  const email = localStorage.getItem('email');
  if (email) {
    headers['email'] = email;
  }

  return headers;
};

const makeGraphqlRequest = ({ query, operationName, variables }) => {
  const url = withConfig('GRAPHQL_URL');
  const data = {
    query,
    operationName,
    variables,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  };
  const config = {
    headers: getHeaders(),
  };
  return axios.post(url, data, config);
};

const graphqlClient = {
  makeGraphqlRequest,
};

export default graphqlClient;
