import gql from 'graphql-tag';

export default gql`
  mutation updateFinancialCriteria(
    $formDataId: Int!
    $budgetDescription: String
    $financialResources: [String!]!
    $longTermCare: String
    $veteran: Boolean
    $vaAid: String
    $oneId: String
    $familyFileId: Int
    $medicaid: MedicaidFamilyFile
  ) {
    updateFinancialCriteria(
      input: {
        formDataId: $formDataId
        budgetDescription: $budgetDescription
        financialResources: $financialResources
        longTermCare: $longTermCare
        veteran: $veteran
        vaAid: $vaAid
        oneId: $oneId
        familyFileId: $familyFileId
        medicaid: $medicaid
      }
    ) {
      formDataId
      budgetDescription
      financialResources
      longTermCare
      veteran
      vaAid
    }
  }
`;
