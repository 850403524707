import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    main_container: {
      display: 'flex',
      flexDirection: 'column',
    },
    body: {
      padding: '32px',
      backgroundColor: theme.palette.light.platinum3,
    },
    top_inquiry_container: {
      fontFamily: 'Poppins',
      backgroundColor: theme.palette.neutral.cosmicCobalt2,
      borderRadius: '4px',
      boxShadow: '0px 0px 8px rgba(186, 186, 184, 0.3)',
    },
    top_call_data_container: {
      fontFamily: 'Poppins',
      backgroundColor: theme.palette.positive.wintergreenDream2,
      borderRadius: '4px',
      boxShadow: '0px 0px 8px rgba(186, 186, 184, 0.3)',
    },
    top_panel: {
      fontWeight: 'bold',
      fontSize: '13px',
      lineHeight: '19px',
      display: 'flex',
      borderRadius: '4px 4px 0 0',
      cursor: 'pointer',
      justifyContent: 'space-between',
      width: '100%',
    },
    top_panel_left: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    top_panel_right: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    breadcrumbs: {
      marginBottom: '27px',
    },
    blank_space: {
      height: '15px',
    },
    label: {
      paddingLeft: '8px',
    },
  };
});

export { useStyles };
