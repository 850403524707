import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './ContactInformation.style';
import ContactInformationDisplay from './ContactInformationDisplay';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { dialerService } from 'services/communication/telephony/DialerService';
import ContactsUpdate from 'services/Contacts/ContactsUpdate';
import { registerEvent } from 'services/Analytics';
import Card from 'common/Card/Card';
import EditCard from 'common/Card/EditCard/EditCard';
import CallPhone from 'common/Icons/call/CallPhone';
import {
  inFamilyFilePage,
  inSendReferralPage,
} from 'services/familyFileService';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

const ContactInformation = ({
  labels,
  contactInformationData,
  flags,
  handleRefetch,
  allowEdit,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const [editContactInformationOpen, setEditContactInformationOpen] = useState(
    false,
  );

  useEffect(() => {
    dialerService.setTestNumber(flags.dialOverrideNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let familyFileInfo;
  let primaryContact;
  let secondaryContact;
  let additionalContact;
  if (contactInformationData) {
    familyFileInfo = {
      familyFileId: contactInformationData.familyFileId,
      careTypes: contactInformationData.careTypes,
      doNotCall: contactInformationData.doNotCall,
    };
    primaryContact = contactInformationData.primaryContact;
    secondaryContact = contactInformationData.secondaryContact;
    additionalContact = contactInformationData.additionalContact;
  }

  const handleOnEditMode = (open) => {
    registerEvent('FamilyFiles', 'Edit contact info is opened');
    setEditContactInformationOpen(open);
  };

  const closeEditHandler = () => {
    setEditContactInformationOpen(false);
  };

  const shouldBeEditable = () => {
    return (
      allowEdit ||
      (flags.updateContactsOwl && inFamilyFilePage(location)) ||
      enableEditForReferralAlertValidation
    );
  };

  const enableEditForReferralAlertValidation = inSendReferralPage(location);

  const ContactsInfo = () => {
    return (
      <Fragment>
        <ContactsUpdate
          labels={labels}
          contact={contactInformationData}
          handleRefetchState={handleRefetch}
          familyFileId={contactInformationData.familyFileId}
          editContactInformationOpen={editContactInformationOpen}
          handleClosingEdit={closeEditHandler}
          enableReferralAlertValidation={enableEditForReferralAlertValidation}
        >
          <Fragment>
            {primaryContact && (
              <ContactInformationDisplay
                labels={labels}
                familyFileInfo={familyFileInfo}
                contact={primaryContact}
                title={labels.PRIMARY_CONTACT}
                dialerService={dialerService}
              />
            )}
            {secondaryContact && <div className={classes.divider}></div>}

            {secondaryContact && (
              <ContactInformationDisplay
                labels={labels}
                familyFileInfo={familyFileInfo}
                contact={secondaryContact}
                title={labels.SECONDARY_CONTACT}
                dialerService={dialerService}
              />
            )}
            {additionalContact && <div className={classes.divider}></div>}

            {additionalContact && (
              <ContactInformationDisplay
                labels={labels}
                familyFileInfo={familyFileInfo}
                contact={additionalContact}
                title={labels.ADDITIONAL_CONTACT}
                dialerService={dialerService}
              />
            )}
          </Fragment>
        </ContactsUpdate>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {shouldBeEditable() && (
        <EditCard
          headerColor="platinum4"
          type="light"
          title={labels.CONTACTS}
          icon={<CallPhone />}
          className={clsx(
            classes.container,
            enableEditForReferralAlertValidation && 'send_referral_container',
          )}
          allowEdit={shouldBeEditable()}
          clickCallback={() => handleOnEditMode(true)}
        >
          <ContactsInfo />
        </EditCard>
      )}
      {!shouldBeEditable() && (
        <Card
          className={clsx(classes.container)}
          headerColor={
            inFamilyFilePage(location) ? 'cosmicCobalt2' : 'platinum4'
          }
          title={labels.CONTACTS}
          icon={<CallPhone />}
        >
          <ContactsInfo />
        </Card>
      )}
    </Fragment>
  );
};

ContactInformation.propTypes = {
  labels: PropTypes.object.isRequired,
  contactInformationData: PropTypes.shape({
    familyFileId: PropTypes.number,
    careTypes: PropTypes.arrayOf(PropTypes.object),
    doNotCall: PropTypes.bool,
    primaryContact: PropTypes.shape({
      name: PropTypes.string,
      relationship: PropTypes.string,
      contactDetail: PropTypes.shape({
        phonePrimary: PropTypes.string,
        phoneSecondary: PropTypes.string,
        email: PropTypes.string,
        primaryPhoneType: PropTypes.string,
        secondaryPhoneType: PropTypes.string,
      }),
    }),
    secondaryContact: PropTypes.shape({
      name: PropTypes.string,
      relationship: PropTypes.string,
      contactDetail: PropTypes.shape({
        phonePrimary: PropTypes.string,
        phoneSecondary: PropTypes.string,
        email: PropTypes.string,
        primaryPhoneType: PropTypes.string,
        secondaryPhoneType: PropTypes.string,
      }),
    }),
    additionalContact: PropTypes.object,
  }),
  flags: PropTypes.shape({
    dialOverrideNumber: PropTypes.string,
    updateContactsOwl: PropTypes.bool,
  }),
  allowEdit: PropTypes.bool,
  handleRefetch: PropTypes.func,
};

ContactInformation.defaultProps = {
  allowEdit: false,
};

export default withLDConsumer()(ContactInformation);
