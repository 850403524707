import React, { Fragment } from 'react';
import Typography from '../../common/Typography/Typography';

const HealthCheck = () => {
  return (
    <Fragment>
      <Typography>It Works!</Typography>
    </Fragment>
  );
};

export default HealthCheck;
