import { useMutation } from '@apollo/react-hooks';
import cache from '../utils/cache';

const useGraphQLMutation = (mutation, variables) => {
  const options = {
    ...variables,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    context: {
      headers: getHeaders(),
    },
  };

  return useMutation(mutation, options);
};

const getHeaders = () => {
  const headers = {
    authorization: cache.get('ID_TOKEN'),
  };
  const additionalEmail = localStorage.getItem('additional_email');
  if (additionalEmail) {
    headers['additional_email'] = additionalEmail;
  }

  const email = localStorage.getItem('email');
  if (email) {
    headers['email'] = email;
  }

  return headers;
};

export default useGraphQLMutation;
