import gql from 'graphql-tag';

export default gql`
  mutation(
    $formDataId: Int!
    $expectedMoveDate: String
    $likelihoodToMovePct: Int
    $oneId: String
    $familyFileId: Int
  ) {
    updateResidentMovingInformation(
      input: {
        formDataId: $formDataId
        expectedMoveDate: $expectedMoveDate
        likelihoodToMovePct: $likelihoodToMovePct
        oneId: $oneId
        familyFileId: $familyFileId
      }
    ) {
      formDataId
    }
  }
`;
