import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './FinancialCriteria.style';
import ConditionalTooltip from './ConditionalTooltip';
import Typography from 'common/Typography/Typography';

const Field = ({ label, value, isBold, labels, isEditable, isVisible }) => {
  const classes = useStyles();
  if (!isVisible) return null;
  return (
    <div className={classes.row}>
      <div>
        <ConditionalTooltip labels={labels} withTooltip={isEditable}>
          <Typography
            className={classes.label}
            color="eerieBlack5"
            level="small"
          >
            {label}
          </Typography>
        </ConditionalTooltip>
        <ConditionalTooltip labels={labels} withTooltip={isEditable}>
          <Typography bold={isBold} level="body">
            {value}
          </Typography>
        </ConditionalTooltip>
      </div>
    </div>
  );
};

Field.defaultProps = {
  isBold: false,
  isEditable: false,
  isVisible: true,
};

Field.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  labels: PropTypes.object.isRequired,
  isBold: PropTypes.bool,
  isEditable: PropTypes.bool,
  isVisible: PropTypes.bool,
};

export default Field;
