import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './ButtonBar.style';
import Button from 'common/Button/Button';

const ButtonBar = ({
  onAdd,
  onEdit,
  onDelete,
  onExport,
  onImport,
  withPreview,
  addLabel,
  onSort,
  sortLabel,
  labels,
  onViewHistory,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.buttons_block}>
      {onDelete ? (
        <Button size="small" type="outlined" onClick={onDelete}>
          {labels.DELETE}
        </Button>
      ) : null}
      {withPreview ? (
        <Button
          size="small"
          to={`/admin/script-preview/${withPreview}`}
          openInNewTab
        >
          {labels.PREVIEW}
        </Button>
      ) : null}
      {onSort && (
        <Button size="small" onClick={onSort}>
          {sortLabel}
        </Button>
      )}
      {onExport ? (
        <Button size="small" onClick={onExport}>
          {labels.EXPORT}
        </Button>
      ) : null}
      {onImport ? (
        <Button size="small" onClick={onImport}>
          {labels.IMPORT}
        </Button>
      ) : null}
      {onAdd ? (
        <Button size="small" onClick={onAdd}>
          {addLabel}
        </Button>
      ) : null}
      <Button size="small" onClick={onEdit}>
        {labels.EDIT}
      </Button>
      {onViewHistory ? (
        <Button size="small" onClick={onViewHistory}>
          {labels.HISTORY}
        </Button>
      ) : null}
    </div>
  );
};

ButtonBar.propTypes = {
  onDelete: PropTypes.func,
  onAdd: PropTypes.func,
  onDuplicate: PropTypes.func,
  onEdit: PropTypes.func,
  onExport: PropTypes.func,
  onImport: PropTypes.func,
  withPreview: PropTypes.string,
  addLabel: PropTypes.string,
  onSort: PropTypes.func,
  sortLabel: PropTypes.string,
  duplicateLabel: PropTypes.string,
  labels: PropTypes.object,
  onViewHistory: PropTypes.func,
};

export default ButtonBar;
