import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { sentryExceptionWithData } from 'utils/sentryExceptionWithData';
import Button from '../../common/Button/Button';
import TextInput from '../../common/TextInput/TextInput';
import Typography from '../../common/Typography/Typography';
import Loading from '../../components/Loading/Loading';
import { withLabels } from '../../context/LabelContext';
import { withUser } from '../../context/UserContext';
import formService from '../../services/callCenter/formService';
import {
  getScriptBySlugRequest,
  updatePreviewFormSteps,
} from '../../stateManagement/admin/scriptPreview/actions/scriptPreviewActions';
import { selectScriptPreview } from '../../stateManagement/admin/scriptPreview/reducers/scriptPreviewReducer';
import { selectCatalogs } from '../../stateManagement/callcenter/catalogs/reducers/catalogsReducer';
import { CHICLET_CARD_STATES } from '../callCenter/Inquiry/FormChiclets/ChicletCard/ChicletCard';
import Chiclets from '../callCenter/Inquiry/FormChiclets/Chiclets';
import { useStyles } from './ScriptPreview.style';

const ScriptPreview = ({ labels, flags, currentUser }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [slug, setSlug] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [currentState, setCurrentState] = useState(
    CHICLET_CARD_STATES.CURRENT.value,
  );

  const {
    scriptPreviewLoading,
    scriptPreviewError,
    scriptPreview,
    scriptPreviewSteps: formSteps,
  } = useSelector(selectScriptPreview);

  const { catalogs } = useSelector(selectCatalogs);

  const { slug: slugParams } = useParams();

  useEffect(() => {
    if (slugParams && catalogs) {
      setSlug(slugParams);
      dispatch(getScriptBySlugRequest(slugParams));
    }
  }, [slugParams, setSlug, dispatch, catalogs]);

  useEffect(() => {
    if (formSteps) {
      for (const step of formSteps) {
        const { state, number, extraFields } = step;
        const hidden = extraFields?.hidden;
        const isOptional = extraFields?.isOptional;

        if (
          !hidden &&
          !isOptional &&
          state !== CHICLET_CARD_STATES.ANSWERED.value
        ) {
          setCurrentQuestion(number);
          break;
        }
      }
    }
  }, [formSteps]);

  if (!flags.scriptBuilderAccess) {
    const errorMessage = new Error(
      'Error 401: Unauthorized Role - Script Builder Access feature flag is TURNED OFF',
    );
    sentryExceptionWithData(errorMessage, {
      scriptBuilderAccessFlag: flags.scriptBuilderAccess,
    });

    return <Redirect to="/error" />;
  }

  const handleAnswer = async (selectedStep, selectedOption) => {
    const { nextQuestion } = selectedOption;
    let state = currentState;
    let currentFormSteps = cloneDeep(formSteps);

    const steps = formService.updateStepStates(
      currentFormSteps,
      nextQuestion,
      state,
    );
    const newFormSteps = formService.updateSteps(
      steps,
      selectedStep,
      selectedOption,
    );

    dispatch(updatePreviewFormSteps(newFormSteps));

    setCurrentQuestion(nextQuestion);
    setCurrentState(state);
  };

  const onUserInputChange = (step, answer) => {
    answer.label = answer.label ? answer.label : labels.SAVE;
    step.answer = { ...answer };
    const steps = formService.updateStepAnswer(formSteps, step, answer);
    dispatch(updatePreviewFormSteps(steps));
  };

  const blankInquiry = { inquiryContact: {} };

  const steps = formSteps
    ? formService.updateStepStates(formSteps, currentQuestion, currentState)
    : [];

  return (
    <div className={classes.root}>
      <div className={classes.slug_container}>
        {slugParams ? (
          <Typography level="h3">{`Previewing ${
            scriptPreview ? scriptPreview.name : ''
          } (${slugParams})`}</Typography>
        ) : (
          <Fragment>
            <TextInput
              label="Slug"
              value={slug}
              onChange={(newSlug) => setSlug(newSlug)}
            />
            <Button
              onClick={() => {
                dispatch(getScriptBySlugRequest(slug));
              }}
            >
              {labels.PREVIEW}
            </Button>
          </Fragment>
        )}
      </div>

      {scriptPreviewLoading && <Loading />}
      {scriptPreviewError && <div>Error</div>}
      {steps && (
        <Chiclets
          steps={steps}
          inquiry={blankInquiry}
          currentUser={currentUser}
          labels={labels}
          warmTransfer={{}}
          handleAnswer={handleAnswer}
          closeOptionalStep={(stepToInclude) =>
            console.log('closeOptionalStep', { stepToInclude })
          }
          onUserInputChange={onUserInputChange}
          onManyChoiceChange={(step, selectedOptions) =>
            console.log('onManyChoiceChange', { step, selectedOptions })
          }
          onTextInputGroupSave={(step, textAnswer) =>
            console.log('onTextInputGroupSave', { step, textAnswer })
          }
        />
      )}
    </div>
  );
};

ScriptPreview.propTypes = {
  labels: PropTypes.object,
  flags: PropTypes.shape({
    scriptBuilderAccess: PropTypes.bool,
  }),
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    __typename: PropTypes.string,
  }),
};
export default withLDConsumer()(withLabels(withUser(ScriptPreview)));
