import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './CommunitiesDropDown.style';
import Button from 'common/Button/Button';
import Typography from 'common/Typography/Typography';
import PinAdd from 'common/Icons/maps/PinAdd';
import ChevronBottom from 'common/Icons/arrow/ChevronBottom';
import ChevronTop from 'common/Icons/arrow/ChevronTop';
import SavedCommunities from 'components/SavedCommunities/SavedCommunities';
import EmptyCommunities from 'components/EmptyCommunities/EmptyCommunities';
import toursQuery from './toursQuery';
import useGraphQLQuery from 'hooks/useGraphQLQuery';
import getParams from 'utils/queryString';
import Toast from 'common/Toast/Toast';
import { logCommunitySearchSavedCommunitiesViewed } from '../CommunitiesTable/communityService';

const CommunitiesDropDown = ({
  labels,
  savedCommunities,
  familyFileId,
  familyFileUserId,
  flags,
  onRemove,
  dataForSegment,
  communitySearchUiFromYgl,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenFromUrl, setIsOpenFromUrl] = useState(false);
  const wrapperRef = useRef(null);
  const [communities, setCommunities] = useState([]);
  const [
    savedCommunitiesErrorMessage,
    setSavedCommunitiesErrorMessage,
  ] = useState();

  const { error, data, refetch } = useGraphQLQuery(toursQuery, {
    variables: { familyFileId: familyFileId },
  });

  useEffect(() => {
    setCommunities(savedCommunities);
  }, [savedCommunities]);

  useEffect(() => {
    if (isOpen) {
      if (communities.length === 0) {
        // This is also executed in SavedCommunities (when there are communities & disclosure information is known)
        logCommunitySearchSavedCommunitiesViewed(
          familyFileId,
          familyFileUserId,
          '',
        );
      }
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        document.getElementsByClassName('modal_container').length === 0
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    if (data) {
      const { displayCommunities } = getParams();
      if (displayCommunities && !isOpen && !isOpenFromUrl) {
        setIsOpen(true);
      }
    }
  }, [data, isOpen, setIsOpen, isOpenFromUrl]);

  if (error) return `Error! ${error.message}`;

  const getTitle = () => {
    if (communitySearchUiFromYgl) {
      return labels.WORKING_LIST;
    }

    return communities.length > 0
      ? labels.SAVED_COMMUNITIES
      : labels.NO_SAVED_COMMUNITIES;
  };

  const handleOnRemove = (newCommunities) => {
    setCommunities(newCommunities);
    onRemove(newCommunities);
  };

  const handleOnClose = () => {
    setIsOpen(false);
  };

  const getForReferralLabel = () => {
    return communitySearchUiFromYgl
      ? labels.WORKING_LIST_FOR_REFERRAL
      : labels.SAVED_COMMUNITIES_FOR_REFERRAL;
  };

  const onSavedCommunitiesUpdate = (error) => {
    const message = error ? labels.UNABLE_TO_REMOVE_DUE_TO_ERROR : null;
    setSavedCommunitiesErrorMessage(message);
  };

  return (
    <Fragment>
      <div className={classes.saved_communities_container} ref={wrapperRef}>
        <Button
          startIcon={<PinAdd />}
          onClick={() => {
            setIsOpen(!isOpen);
            setIsOpenFromUrl(!isOpenFromUrl);
          }}
          endIcon={isOpen ? <ChevronTop /> : <ChevronBottom />}
        >
          <div className={classes.title}>
            <Typography color="platinum5">{getTitle()}</Typography>
            {communities.length > 0 && <span>{communities.length}</span>}
          </div>
        </Button>
        {isOpen && communities.length === 0 && (
          <div className={classes.empty_container}>
            {!communitySearchUiFromYgl && (
              <Typography bold>{getForReferralLabel()}</Typography>
            )}
            <EmptyCommunities
              labels={labels}
              familyFileId={familyFileId}
              isInCommunityPage={true}
            />
          </div>
        )}
        {isOpen && communities.length > 0 && (
          <div className={classes.communities_container}>
            <Typography bold>{getForReferralLabel()}</Typography>
            {savedCommunitiesErrorMessage && (
              <Toast className={classes.saved_communities_error} type="error">
                <Typography>{savedCommunitiesErrorMessage}</Typography>
              </Toast>
            )}
            <SavedCommunities
              dataForAnalytics={{
                originatingPage: flags.isTourWorkflowV2
                  ? 'community search'
                  : 'search page',
              }}
              dataForSegment={dataForSegment}
              familyFileId={familyFileId}
              familyFileUserId={familyFileUserId}
              isInCommunityPage={true}
              labels={labels}
              onClose={handleOnClose}
              onRemove={handleOnRemove}
              onUpdate={onSavedCommunitiesUpdate}
              savedCommunities={savedCommunities}
              tourDetails={data.findFamilyFileById.tourDetailsExtended}
              tourDrafts={data.findFamilyFileById.tourDrafts}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

CommunitiesDropDown.propTypes = {
  dataForSegment: PropTypes.shape({
    familyFileId: PropTypes.number,
    oneId: PropTypes.string,
  }),
  communitySearchUiFromYgl: PropTypes.bool,
  familyFileId: PropTypes.number.isRequired,
  familyFileUserId: PropTypes.number.isRequired,
  flags: PropTypes.shape({
    isTourWorkflowV2: PropTypes.bool.isRequired,
  }),
  labels: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onRemove: PropTypes.func,
  savedCommunities: PropTypes.array,
};

CommunitiesDropDown.defaultProps = {
  savedCommunities: [],
};

export default CommunitiesDropDown;
