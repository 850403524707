import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => {
  return {
    filter_container: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '12px 16px',
    },
    single_button: {
      margin: '4px',
      width: 'fit-content',
    },
  };
});

export { useStyles };
