import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Case from 'common/Icons/basic/Case';
import Typography from 'common/Typography/Typography';
import TextInput from 'common/TextInput/TextInput';
import { useStyles } from './Overview.style';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import Button from 'common/Button/Button';
import useGraphQLMutation from 'hooks/useGraphQLMutation';
import createKeyNoteMutation from './createKeyNoteMutation';
import updateKeyNoteMutation from './updateKeyNoteMutation';
import KeyNoteDefaultPage from './KeyNoteDefaultPage';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { registerEvent } from 'services/Analytics';
import Toast from 'common/Toast/Toast';
import ScreeningNotes from './ScreeningNotes/ScreeningNotes';
import CircleChecked from 'common/Icons/basic/CircleChecked';
import EditCard from 'common/Card/EditCard/EditCard';

const Overview = ({ flags, labels, notes, overviewData }) => {
  const classes = useStyles();

  const [showKeyNoteDefaultPage, setShowKeyNoteDefaultPage] = useState(
    overviewData.description === '' && (!notes || notes.length === 0),
  );
  const [disableTextInput, setDisableTextInput] = useState(true);
  const [enableTooltip, setEnableTooltip] = useState(true);
  const [showSucessOrErrorField, setShowSucessOrFailureField] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(true);
  const [keyNoteDescription, setKeyNoteDescription] = useState(
    overviewData.description,
  );
  const [createKeyNote] = useGraphQLMutation(createKeyNoteMutation);
  const [updateKeyNote] = useGraphQLMutation(updateKeyNoteMutation);
  const maxInputTextLength = 200;
  const maxRowsCount = 4;
  const blurredTextAreaId = 'blurredTextAreaId';

  const handleTextInputClicked = () => {
    setDisableTextInput(false);
    setEnableTooltip(false);
    setShowKeyNoteDefaultPage(false);
    registerEvent('FamilyFiles', 'Edit overview section is opened');
  };

  const saveKeyNote = () => {
    if (overviewData.keyNoteId === null) {
      const createKeyNoteInput = {
        variables: {
          familyFileId: overviewData.familyFileId,
          noteText: keyNoteDescription,
        },
      };
      createKeyNote(createKeyNoteInput)
        .then(() => {
          setShowSucessOrFailureField(true);
          setShowSuccessMessage(true);
          setEnableTooltip(true);
        })
        .catch((exception) => {
          console.error(exception.message);
          setShowSucessOrFailureField(true);
          setShowSuccessMessage(false);
        });
    } else {
      const updateKeyNoteInput = {
        variables: {
          keyNoteId: overviewData.keyNoteId,
          noteText: keyNoteDescription,
          familyFileId: overviewData.familyFileId,
        },
      };
      updateKeyNote(updateKeyNoteInput)
        .then(() => {
          setShowSucessOrFailureField(true);
          setShowSuccessMessage(true);
          setEnableTooltip(true);
        })
        .catch((exception) => {
          console.error(exception.message);
          setShowSucessOrFailureField(true);
          setShowSuccessMessage(false);
        });
    }
  };

  const handleSaveClicked = () => {
    registerEvent('FamilyFiles', 'Edit overview section is saved');
    setDisableTextInput(true);
    saveKeyNote();
  };

  const handleCancelClicked = () => {
    setDisableTextInput(true);
    setEnableTooltip(true);
    const description = keyNoteDescription || overviewData.description;
    setKeyNoteDescription(description);
    setShowKeyNoteDefaultPage(
      description === '' && (!notes || notes.length === 0),
    );
  };

  const handleTextInputBlur = (text) => {
    setKeyNoteDescription(text);
  };

  const ShowInputTextWithOrWithoutTooltip = () => {
    if (enableTooltip) {
      return (
        <div onClick={handleTextInputClicked} data-testid="textarea-id">
          <SmartTooltip
            title={labels.CLICK_TO_EDIT_OR_UPDATE_KEYNOTE}
            isInteractive={true}
          >
            {keyNoteDescription && (
              <div className={classes.key_note_container}>
                <Typography level="small" color="eerieBlack5">
                  {labels.KEY_NOTE}
                </Typography>
                <Typography>{keyNoteDescription}</Typography>
              </div>
            )}
          </SmartTooltip>
          <ScreeningNotes labels={labels} notes={notes} />
        </div>
      );
    }
    return (
      <>
        <div className={classes.edit_key_note_container}>
          <TextInput
            id={blurredTextAreaId}
            className={classes.overview_text_input_container}
            maxLength={maxInputTextLength}
            rows={maxRowsCount}
            multiline={true}
            disabled={disableTextInput}
            value={keyNoteDescription}
            onBlur={handleTextInputBlur}
            placeholder={labels.TYPE_YOUR_KEYNOTES_HERE}
          />
        </div>
        <ScreeningNotes labels={labels} notes={notes} editMode />
      </>
    );
  };

  const ShowSaveAndCancelButtons = () => {
    if (!enableTooltip) {
      return (
        <div className={classes.overview_description_button}>
          <Button
            className={classes.overview_button}
            type="light"
            size="medium"
            onClick={handleCancelClicked}
          >
            {labels.CANCEL}
          </Button>
          <Button
            className={classes.overview_button}
            type="secondary"
            size="medium"
            startIcon={<CircleChecked />}
            onClick={handleSaveClicked}
          >
            {labels.SAVE}
          </Button>
        </div>
      );
    }
    return <div />;
  };

  const ShowSuccessOrErrorMessage = () => {
    if (showSucessOrErrorField) {
      if (showSuccessMessage) {
        return (
          <div className={classes.success_error_message}>
            <Toast
              onClose={() => {
                setShowSucessOrFailureField(false);
                setShowSuccessMessage(false);
              }}
            >
              <Typography>{labels.OVERVIEW_SUCCESS_MESSAGE}</Typography>
            </Toast>
          </div>
        );
      } else {
        return (
          <div className={classes.success_error_message}>
            <Toast
              type="error"
              onClose={() => {
                setShowSucessOrFailureField(false);
                setShowSuccessMessage(false);
              }}
            >
              <Typography>{labels.OVERVIEW_ERROR_MESSAGE}</Typography>
            </Toast>
          </div>
        );
      }
    }
    return <div />;
  };

  if (showKeyNoteDefaultPage) {
    return (
      <KeyNoteDefaultPage
        labels={labels}
        toggleKeyNoteDefaultPage={handleTextInputClicked}
      />
    );
  }

  return (
    <EditCard
      headerColor="platinum4"
      type="light"
      title={labels.OVERVIEW}
      icon={<Case />}
      clickCallback={handleTextInputClicked}
      className={classes.overview_card_container}
      allowEdit={true}
      flags={flags}
    >
      <ShowSaveAndCancelButtons />
      <ShowSuccessOrErrorMessage />
      <ShowInputTextWithOrWithoutTooltip />
    </EditCard>
  );
};

Overview.propTypes = {
  labels: PropTypes.object.isRequired,
  overviewData: PropTypes.shape({
    description: PropTypes.string,
    typeOfCare: PropTypes.string,
    keyNoteId: PropTypes.number,
    familyFileId: PropTypes.number,
  }),
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      note: PropTypes.string,
      createdBy: PropTypes.string,
      code: PropTypes.string,
      createdAt: PropTypes.number,
    }),
  ),
  flags: PropTypes.object,
};

export default withLDConsumer()(Overview);
