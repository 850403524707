import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => ({
  centered: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'flex-start',
  },
}));

export { useStyles };
