import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { originalFormStepAnswerPropType } from '../../inquiryPropTypes';
import { useStyles } from './TextInputGroup.style';
import TextInput from 'common/TextInput/TextInput';
import Button from 'common/Button/Button';
import useGraphQLMutation from 'hooks/useGraphQLMutation';
import createNoteMutation from '../../CallBar/ConferenceCallButtons/NotesDropdown/createNoteMutation';
import { selectInquiry } from 'stateManagement/callcenter/inquiry/reducers/inquiryReducer';
import { Fragment } from 'react';
import Toast from 'common/Toast/Toast';
import Typography from 'common/Typography/Typography';

const TextInputGroup = ({
  options,
  disabled,
  onTextInputGroupSave,
  testId,
  labels,
}) => {
  const classes = useStyles();
  const { inquiry } = useSelector(selectInquiry);
  const inquiryId = inquiry?.inquiryId;
  const [isSaved, setSaved] = useState(false);
  const [errorMessage, setError] = useState(false);
  const [createNote] = useGraphQLMutation(createNoteMutation);
  const sortedOptions = options.sort((a, b) => a.order - b.order);
  const [textOptions, setTextOptions] = useState(
    sortedOptions.map((opt) => {
      return { id: opt.answerId, name: opt.label, text: '' };
    }),
  );
  let type = disabled ? 'outlinedDisabled' : 'outlined';

  const handleOnChange = (id, value) => {
    let newOption = textOptions.find((opt) => opt.id === id);
    const newOptions = textOptions.map((opt) => {
      if (opt.id === id) {
        setSaved(false);
        return { id: opt.id, name: opt.name, text: value };
      } else {
        return opt;
      }
    });
    newOption.text = value;
    setTextOptions(newOptions);
  };

  const handleOnClick = async () => {
    let notesText = '';
    let comma = '';
    textOptions.map((opt) => {
      notesText += `${comma}${opt.name}: ${opt.text}`;
      comma = ', ';
      return opt;
    });
    try {
      await createNote({
        variables: {
          inquiryId: Number(inquiryId),
          note: notesText,
        },
      });
      setSaved(true);
    } catch (err) {
      console.error('There was an error saving the note.', err);
      setError(true);
    }
    onTextInputGroupSave(notesText);
  };

  return (
    <Fragment>
      <div className={classes.textbox_container}>
        {errorMessage && (
          <Toast type="error">
            <Typography level="tiny">
              There was a problem saving this information.
            </Typography>
          </Toast>
        )}
        {sortedOptions.map((option, key) => {
          return (
            <TextInput
              type="text"
              id={`textInput_${option.answerId}`}
              placeholder={option.label}
              label={option.label}
              key={key}
              value={textOptions[key].text}
              className={classes.textbox}
              onChange={(value) => handleOnChange(option.answerId, value)}
              testId={`${testId}_actions_${option.value}`}
              maxLength={40}
              autoComplete="off"
            />
          );
        })}
        {isSaved === false && (
          <Fragment>
            <Button
              className={classes.textbox_saveBtn}
              type={type}
              onClick={handleOnClick}
            >
              {labels.SAVE}
            </Button>
          </Fragment>
        )}
        {isSaved === true && (
          <Fragment>
            <Button disabled type="outlinedDisabled">
              {labels.SAVED}
            </Button>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

TextInputGroup.defaultProps = {
  disabled: false,
};

TextInputGroup.propTypes = {
  options: PropTypes.arrayOf(originalFormStepAnswerPropType).isRequired,
  disabled: PropTypes.bool,
  onTextInputGroupSave: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
};

export default TextInputGroup;
