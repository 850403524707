import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from './BudgetFilter.style';
import PropTypes from 'prop-types';
import ChevronTop from '../../Icons/arrow/ChevronTop';
import ChevronBottom from '../../Icons/arrow/ChevronBottom';
import Typography from '../../Typography/Typography';
import Slider from '../../Slider/Slider';
import Radio from '../../Radio/Radio';
import TextInput from '../../TextInput/TextInput';
import { formatCurrency, toCurrency } from 'utils/currencyFormat';
import clsx from 'clsx';
import Divider from 'common/Divider/Divider';
import Button from 'common/Button/Button';

const BUDGET_MAX_VALUE = 10000;
const BUDGET_MIN_VALUE = 0;
const BUDGET_STEP_SIZE = 100;
const BUDGET_MORE_LOWER_LIMIT = 10000;
const BUDGET_MORE_UPPER_LIMIT = 99999999;

const BudgetFilter = ({
  labels,
  onChange,
  disabled,
  className,
  minInitialValue,
  maxInitialValue,
}) => {
  const classes = useStyles();
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [min, setMin] = useState(minInitialValue);
  const [max, setMax] = useState(maxInitialValue);
  const [rangeType, setRangeType] = useState('slider');
  const [previousRangeType, setPreviousRangeType] = useState('slider');
  const [previousMin, setPreviousMin] = useState();
  const [previousMax, setPreviousMax] = useState();

  const budgetFilterContainer = clsx(
    className,
    classes.budget_filter_container,
    (min !== BUDGET_MIN_VALUE || max !== BUDGET_MAX_VALUE) && classes.filled,
  );

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (isOpen) {
          setMin(previousMin);
          setMax(previousMax);
          setRangeType(previousRangeType);
        }
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, previousMin, previousMax, previousRangeType, isOpen]);

  useEffect(() => {
    if (maxInitialValue === BUDGET_MORE_UPPER_LIMIT) {
      setPreviousMin(0);
      setPreviousMax(0);
      setMin(0);
      setMax(0);
      setRangeType('10k');
      setPreviousRangeType('10k');
    } else {
      setPreviousMin(minInitialValue);
      setPreviousMax(maxInitialValue);
      setMin(minInitialValue);
      setMax(maxInitialValue);
      setRangeType('slider');
    }
  }, [minInitialValue, maxInitialValue]);

  useEffect(() => {
    if (!isOpen) {
      if (rangeType === 'slider') {
        onChange({ min, max });
      } else {
        onChange({
          min: BUDGET_MORE_LOWER_LIMIT,
          max: BUDGET_MORE_UPPER_LIMIT,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min, max, isOpen]);

  const getSelectedText = () => {
    if (rangeType === 'slider') {
      return `${toCurrency(min)} - ${toCurrency(max)}`;
    }
    return `$10,000 or more`;
  };

  const handleSliderChange = (values) => {
    const min = values[0];
    const max = values[1];
    setMax(max);
    setMin(min);
  };

  const handleMinValueChange = (value) => {
    const number = Number(value);
    setMin(number);
  };

  const handleMaxValueChange = (value) => {
    const number = Number(value);
    setMax(number);
  };

  const handleRangeTypeClick = (rangeType) => {
    setRangeType(rangeType);
  };

  const handleApply = () => {
    setPreviousMin(min);
    setPreviousMax(max);
    setPreviousRangeType(rangeType);
    setIsOpen(false);
  };

  const handleClose = () => {
    setMin(previousMin);
    setMax(previousMax);
    setRangeType(previousRangeType);
    setIsOpen(false);
  };

  return (
    <div className={budgetFilterContainer} ref={wrapperRef}>
      <div className={classes.label}>{labels.FINANCIAL_RANGE}</div>
      <div
        className={classes.select}
        tabIndex="0"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>{getSelectedText()}</div>
        <div className={classes.icon}>
          {isOpen ? <ChevronTop /> : <ChevronBottom />}
        </div>
      </div>
      {isOpen && !disabled && (
        <div className={classes.menu}>
          <div className={classes.items_container}>
            <div>
              <Radio
                name={'rangetype'}
                items={[
                  { label: labels.PRICE_WITHIN_RANGE || '', value: 'slider' },
                ]}
                onClick={(event) => handleRangeTypeClick(event)}
                selected={{ value: rangeType }}
              ></Radio>
            </div>

            <Slider
              className={classes.slider}
              disabled={rangeType !== 'slider'}
              max={BUDGET_MAX_VALUE}
              min={BUDGET_MIN_VALUE}
              onChange={handleSliderChange}
              step={BUDGET_STEP_SIZE}
              values={[min, max]}
            />
            <div className={classes.inputs_container}>
              <TextInput
                className={classes.input}
                disabled={rangeType !== 'slider'}
                id="min_value_input"
                label={labels.MIN.toUpperCase()}
                max={max}
                min={BUDGET_MIN_VALUE}
                onChange={handleMinValueChange}
                startIcon={<span>$</span>}
                type="number"
                value={formatCurrency(min)}
              />
              <TextInput
                className={classes.input}
                disabled={rangeType !== 'slider'}
                id="max_value_input"
                label={labels.MAX.toUpperCase()}
                max={BUDGET_MAX_VALUE}
                min={min}
                onChange={handleMaxValueChange}
                startIcon={<span>$</span>}
                type="number"
                value={formatCurrency(max)}
              />
            </div>

            <div className={classes.price_over}>
              <Radio
                className={classes.price_over_radio}
                name={'rangetype'}
                items={[{ label: '', value: '10k' }]}
                selected={{ value: rangeType }}
                onClick={(event) => handleRangeTypeClick(event)}
              ></Radio>
              <label
                className={classes.price_over_label}
                onClick={() => handleRangeTypeClick('10k')}
              >
                <Typography color="eerieBlack3">
                  {labels.PRICE_10K_AND_OVER_1}
                </Typography>
                &nbsp;
                <Typography color="eerieBlack3" bold>
                  {labels.PRICE_10K_AND_OVER_2}
                </Typography>
              </label>
            </div>
            <Divider className={classes.divider} color="platinum1" />
            <div className={classes.actions_container}>
              <div className={clsx(classes.actions)}>
                <Button size="small" type="light" onClick={handleClose}>
                  {labels.CLOSE}
                </Button>
                <Button type="secondary" size="small" onClick={handleApply}>
                  {labels.APPLY}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

BudgetFilter.propTypes = {
  labels: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  minInitialValue: PropTypes.number,
  maxInitialValue: PropTypes.number,
};

BudgetFilter.defaultProps = {
  disabled: false,
  className: '',
  minInitialValue: BUDGET_MIN_VALUE,
  maxInitialValue: BUDGET_MAX_VALUE,
  onChange: () => {},
};

export default BudgetFilter;
