import { makeStyles } from '../Theme/Theme';

const useTextColorStyles = makeStyles((theme) => {
  return {
    eerieBlack1: {
      color: theme.palette.dark.eerieBlack1,
    },
    eerieBlack2: {
      color: theme.palette.dark.eerieBlack2,
    },
    eerieBlack3: {
      color: theme.palette.dark.eerieBlack3,
    },
    eerieBlack4: {
      color: theme.palette.dark.eerieBlack4,
    },
    eerieBlack5: {
      color: theme.palette.dark.eerieBlack5,
    },
    disabled: {
      color: theme.palette.dark.disabled,
    },
    divider: {
      color: theme.palette.light.divider,
    },
    platinum1: {
      color: theme.palette.light.platinum1,
    },
    platinum2: {
      color: theme.palette.light.platinum2,
    },
    platinum3: {
      color: theme.palette.light.platinum3,
    },
    platinum4: {
      color: theme.palette.light.platinum4,
    },
    platinum5: {
      color: theme.palette.light.platinum5,
    },
    white: {
      color: theme.palette.light.white,
    },
    cosmicCobalt1: {
      color: theme.palette.neutral.cosmicCobalt1,
    },
    cosmicCobalt2: {
      color: theme.palette.neutral.cosmicCobalt2,
    },
    cosmicCobalt3: {
      color: theme.palette.neutral.cosmicCobalt3,
    },
    cosmicCobalt4: {
      color: theme.palette.neutral.cosmicCobalt4,
    },
    cosmicCobalt5: {
      color: theme.palette.neutral.cosmicCobalt5,
    },
    regalia1: {
      color: theme.palette.care.regalia1,
    },
    regalia2: {
      color: theme.palette.care.regalia2,
    },
    regalia3: {
      color: theme.palette.care.regalia3,
    },
    regalia4: {
      color: theme.palette.care.regalia4,
    },
    regalia5: {
      color: theme.palette.care.regalia5,
    },
    wintergreenDream1: {
      color: theme.palette.positive.wintergreenDream1,
    },
    wintergreenDream2: {
      color: theme.palette.positive.wintergreenDream2,
    },
    wintergreenDream3: {
      color: theme.palette.positive.wintergreenDream3,
    },
    wintergreenDream4: {
      color: theme.palette.positive.wintergreenDream4,
    },
    wintergreenDream5: {
      color: theme.palette.positive.wintergreenDream5,
    },
    smokyTopaz1: {
      color: theme.palette.correction.smokyTopaz1,
    },
    smokyTopaz2: {
      color: theme.palette.correction.smokyTopaz2,
    },
    smokyTopaz3: {
      color: theme.palette.correction.smokyTopaz3,
    },
    smokyTopaz4: {
      color: theme.palette.correction.smokyTopaz4,
    },
    smokyTopaz5: {
      color: theme.palette.correction.smokyTopaz5,
    },
    hotCinnamon1: {
      color: theme.palette.miscellaneous1.hotCinnamon1,
    },
    hotCinnamon2: {
      color: theme.palette.miscellaneous1.hotCinnamon2,
    },
    hotCinnamon3: {
      color: theme.palette.miscellaneous1.hotCinnamon3,
    },
    hotCinnamon4: {
      color: theme.palette.miscellaneous1.hotCinnamon4,
    },
    hotCinnamon5: {
      color: theme.palette.miscellaneous1.hotCinnamon5,
    },
    fieldDrab1: {
      color: theme.palette.miscellaneous2.fieldDrab1,
    },
    fieldDrab2: {
      color: theme.palette.miscellaneous2.fieldDrab2,
    },
    fieldDrab3: {
      color: theme.palette.miscellaneous2.fieldDrab3,
    },
    fieldDrab4: {
      color: theme.palette.miscellaneous2.fieldDrab4,
    },
    fieldDrab5: {
      color: theme.palette.miscellaneous2.fieldDrab5,
    },
    inherit: {
      color: 'inherit',
    },
  };
});

export { useTextColorStyles };
