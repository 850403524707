import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './CommunityImage.style';
import clsx from 'clsx';
import Tag from 'common/Tag/Tag';

const CommunityImage = ({ className, imageId, imageUrl, tags, size }) => {
  const classes = useStyles(size);

  const imageStyle = {
    backgroundImage: `url('${imageUrl}/${imageId}'), url(/images/community_placeholder.png)`,
  };

  return (
    <div
      className={clsx(classes.community_image_container, className)}
      style={imageStyle}
    >
      <div className={classes.tags_container}>
        {tags.map((tag, key) => (
          <Tag key={key} text={tag.text} color={tag.color} />
        ))}
      </div>
    </div>
  );
};

CommunityImage.propTypes = {
  className: PropTypes.string,
  imageId: PropTypes.number,
  imageUrl: PropTypes.string,
  tags: PropTypes.array,
  size: PropTypes.oneOf(['xs', 's', 'lg', 'xl', 'map', 'referred']),
};

CommunityImage.defaultProps = {
  className: '',
  tags: [],
  size: 'xl',
};

export default CommunityImage;
