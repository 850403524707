import withConfig from '../utils/withConfig';

const GOOGLE_SCRIPT_ID = 'search-places-script';
let autocompleteService;
let placesService;
let geocoderService;
let sessionToken;

const loadScript = async (url) => {
  return new Promise((resolve) => {
    if (window.google && window.google.maps && window.google.maps.places) {
      resolve();
      return;
    }
    const mapApiScripts = document.querySelectorAll('[data-mapsapi="true"]');
    if (mapApiScripts.length > 0) {
      return;
    }

    let script = document.createElement('script');
    script.type = 'text/javascript';

    if (script.readyState) {
      script.onreadystatechange = () => {
        if (
          script.readyState === 'loaded' ||
          script.readyState === 'complete'
        ) {
          script.onreadystatechange = null;
          resolve();
        }
      };
    } else {
      script.onload = () => resolve();
    }

    script.src = url;

    script.async = true;
    script.defer = true;
    script.setAttribute('data-mapsapi', true);
    script.setAttribute('id', GOOGLE_SCRIPT_ID);
    document.getElementsByTagName('head')[0].appendChild(script);
  });
};

const handleScriptLoad = () => {
  autocompleteService = new window.google.maps.places.AutocompleteService();
  let dummyMap = new window.google.maps.Map(document.createElement('div'));
  placesService = new window.google.maps.places.PlacesService(dummyMap);
  geocoderService = new window.google.maps.Geocoder();
  generateSessionToken();
};

const generateSessionToken = async () => {
  sessionToken = new window.google.maps.places.AutocompleteSessionToken();
  return sessionToken;
};

const prepareCall = async () => {
  const apiKey = withConfig('GOOGLE_GEOCODE_API_KEY');
  const url = `${withConfig('GOOGLE_MAPS_API')}?key=${apiKey}&libraries=places`;

  if (!window.google || !window.google.maps || !window.google.maps.places) {
    await loadScript(url);
    handleScriptLoad();
  } else {
    handleScriptLoad();
  }
};

const getSuggestions = async (searchText) => {
  await prepareCall();

  const request = {
    input: searchText,
    componentRestrictions: { country: ['ca', 'us'] },
    sessionToken: sessionToken,
    types: ['(regions)'],
  };
  return getPlacePredictions(request);
};

const getPlacePredictions = (request) => {
  return new Promise((resolve) => {
    autocompleteService.getPlacePredictions(request, (selections, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const filteredSuggestions = selections.filter(
          (place) => !place.types?.includes('administrative_area_level_1'),
        );
        resolve(filteredSuggestions);
        return;
      } else {
        resolve([]);
        return;
      }
    });
  });
};

const getLocationWithPostalCode = (item) => {
  const placeId = item.place_id;
  return new Promise((resolve, reject) => {
    const request = {
      fields: ['geometry'],
      placeId: placeId.toString(),
    };
    placesService.getDetails(request, (place, status) => {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        reject(
          new Error(
            `PlacesInput. Error finding place details: ${placeId}, status: ${status}`,
          ),
        );
        return;
      }
      geocoderService.geocode(
        { location: place.geometry.location },
        (results, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
            reject(
              new Error(
                `PlacesInput. Error finding locations for geocode: ${place.geometry.location.lat()}/${place.geometry.location.lng()}, status: ${status}`,
              ),
            );
            return;
          }
          if (results.length === 0) {
            reject(
              new Error(
                `PlacesInput. No locations for geocode: ${place.geometry.location.lat()}/${place.geometry.location.lng()}`,
              ),
            );
            return;
          }
          const updatedLocation = getStructuredLocation(
            results[0].address_components,
          );
          resolve(updatedLocation);
          return;
        },
      );
    });
  });
};

const getStructuredLocation = (addressComponents) => {
  const location = {};

  addressComponents.forEach((component) => {
    if (component.types.includes('locality')) {
      location.city = component.long_name;
    }
    if (component.types.includes('administrative_area_level_1')) {
      location.state = component.short_name;
    }
    if (component.types.includes('postal_code')) {
      location.zip = component.short_name.replace(' ', '');
    }
    if (component.types.includes('country')) {
      location.country = component.short_name.replace(' ', '');
    }
  });

  return location;
};

export { getSuggestions, getLocationWithPostalCode };
