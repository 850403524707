import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  metrics_header_container: {
    display: 'flex',
    height: theme.spacing(9),
  },
  metrics_header_container_bellow_small_size: {
    height: theme.spacing(11),
  },
  metric_header_title: {
    order: 1,
    '& p': {
      marginTop: 0,
      marginBottom: 0,
    },
  },

  month_to_date: {
    textAlign: 'right',
    order: 2,
    verticalAlign: 'top',
    '& p': {
      marginTop: 0,
      marginBottom: 0,
    },
  },

  month_to_date_bellow_medium_size: {
    order: 1,
    textAlign: 'left',
  },
}));

export { useStyles };
