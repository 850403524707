import withConfig from './../utils/withConfig';

export const buildCommunityUrl = (communityId, name) => {
  const friendlyURL = name.split(' ').join('-').concat('-', communityId);
  return withConfig('APFM_LIVE_SPLASH_URL', {
    yglPropertyUrl: friendlyURL,
  });
};

const handleViewReviewsClick = (event, communityId, name) => {
  event.preventDefault();
  window.open(buildCommunityUrl(communityId, name), '_blank');
};

const getStarInfo = (ratingAverage, roundedRating) => {
  let wholeStars = Math.trunc(ratingAverage);
  let halfStar = false;

  const decimal = parseFloat((roundedRating % 1).toFixed(1));
  if (decimal >= 0.3 && decimal <= 0.7) {
    halfStar = true;
  } else if (decimal > 0.7) {
    wholeStars++;
  }
  return { wholeStars, halfStar };
};

export default { buildCommunityUrl, getStarInfo, handleViewReviewsClick };
