import React from 'react';
import PropTypes from 'prop-types';

const DotDefault = ({ className, mouseEnter, mouseLeave }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <mask id="path-1-inside-1" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.0962 40.0226L24 41L22.9038 40.0226C13.6855 31.803 9 24.626 9 18.3174C9 9.38337 15.8284 3 24 3C32.1716 3 39 9.38337 39 18.3174C39 24.626 34.3145 31.803 25.0962 40.0226Z"
        />
      </mask>
      <path
        className={'insidePath'}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0962 40.0226L24 41L22.9038 40.0226C13.6855 31.803 9 24.626 9 18.3174C9 9.38337 15.8284 3 24 3C32.1716 3 39 9.38337 39 18.3174C39 24.626 34.3145 31.803 25.0962 40.0226Z"
        fill="currentColor"
      />
      <path
        d="M25.0962 40.0226L23.7652 38.5298L23.7652 38.5298L25.0962 40.0226ZM24 41L22.669 42.4928L24 43.6796L25.331 42.4928L24 41ZM22.9038 40.0226L24.2348 38.5298L24.2348 38.5298L22.9038 40.0226ZM23.7652 38.5298L22.669 39.5072L25.331 42.4928L26.4272 41.5153L23.7652 38.5298ZM25.331 39.5072L24.2348 38.5298L21.5728 41.5153L22.669 42.4928L25.331 39.5072ZM24.2348 38.5298C15.0599 30.3489 11 23.6913 11 18.3174H7C7 25.5608 12.3111 33.2571 21.5728 41.5153L24.2348 38.5298ZM11 18.3174C11 10.499 16.9219 5 24 5V1C14.7349 1 7 8.26776 7 18.3174H11ZM24 5C31.0781 5 37 10.499 37 18.3174H41C41 8.26776 33.2651 1 24 1V5ZM37 18.3174C37 23.6913 32.9401 30.3489 23.7652 38.5298L26.4272 41.5153C35.6889 33.2571 41 25.5608 41 18.3174H37Z"
        fill="#2C438C"
        mask="url(#path-1-inside-1)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 38.3197C14.9906 30.2348 11 23.6452 11 18.3174C11 10.499 16.9219 5 24 5C31.0781 5 37 10.499 37 18.3174C37 23.6452 33.0094 30.2348 24 38.3197ZM24 41L25.0962 40.0226C34.3145 31.803 39 24.626 39 18.3174C39 9.38337 32.1716 3 24 3C15.8284 3 9 9.38337 9 18.3174C9 24.626 13.6855 31.803 22.9038 40.0226L24 41Z"
        fill="#2C438C"
      />
    </svg>
  );
};

DotDefault.propTypes = {
  className: PropTypes.string,
  mouseEnter: PropTypes.func,
  mouseLeave: PropTypes.func,
};

DotDefault.defaultProps = {
  mouseEnter: () => {},
  mouseLeave: () => {},
};

export default DotDefault;
