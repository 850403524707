import clsx from 'clsx';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import clonedeep from 'lodash.clonedeep';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../../common/Button/Button';
import Checkbox from '../../common/Checkbox/Checkbox';
import Send from '../../common/Icons/basic/Send';
import Link from '../../common/Link/Link';
import Toast from '../../common/Toast/Toast';
import Typography from '../../common/Typography/Typography';
import { useExternalId } from '../../hooks/useExternalId';
import useGraphQLMutation from '../../hooks/useGraphQLMutation';
import { registerEvent } from '../../services/Analytics';
import disclosureService from '../../services/disclosureService';
import withConfig from '../../utils/withConfig';
import Loading from '../Loading/Loading';
import YglLink from '../YglExternalId/YglLink';
import Community from './Community';
import deleteCommunitiesMutation from './deleteCommunitiesMutation';
import { useStyles } from './SavedCommunities.style';
import {
  logCommunitySearchSavedCommunitiesViewed,
  logCommunitySearchSavedCommunityRemoved,
  logCommunitySearchWorkingListSaved,
} from '../../pages/Communities/CommunitiesTable/communityService';

const imageUrl = withConfig('IMAGE_URL');

const SavedCommunities = ({
  className,
  dataForAnalytics,
  dataForSegment,
  familyFileId,
  familyFileUserId,
  flags,
  isInCommunityPage,
  labels,
  onClose,
  onRemove,
  onUpdate,
  savedCommunities,
  tourDetails,
  tourDrafts,
}) => {
  const classes = useStyles();
  const [communities, setCommunities] = useState([...savedCommunities]);
  const [allSelected, setAllSelected] = useState(false);
  const [saving, setSaving] = useState(false);
  const [deleteCommunitiesCall] = useGraphQLMutation(deleteCommunitiesMutation);
  const location = useLocation();
  const [showDisclosure, setShowDisclosure] = useState(false);
  const { externalId, fetchError } = useExternalId(familyFileId);
  const [
    stateCodesWithCompletedDisclosures,
    setStateCodesWithCompletedDisclosures,
  ] = useState([]);
  const [
    stateCodesWithoutCompletedDisclosures,
    setStateCodesWithoutCompletedDisclosures,
  ] = useState([]);

  useEffect(() => {
    const stateCodes = savedCommunities.map((community) => {
      return community.state;
    });
    const disclosureStateCodes = disclosureService.filterDisclosureStates(
      stateCodes,
    );

    (async () => {
      let isDisclosureShown = false;
      if (disclosureStateCodes.length > 0) {
        const stateCodesWith = await disclosureService.stateCodesWithCompletedDisclosures(
          familyFileId,
        );
        setStateCodesWithCompletedDisclosures(stateCodesWith);

        const stateCodesWithout = disclosureStateCodes.filter(
          (stateCode) => !stateCodesWith.includes(stateCode),
        );
        setStateCodesWithoutCompletedDisclosures(stateCodesWithout);

        if (stateCodesWithout.length > 0) {
          isDisclosureShown = true;
        }
      }
      setShowDisclosure(isDisclosureShown);

      // This is also executed in CommunitiesDropDown when there are no Communities to display.
      // (Needed here for disclosure details).
      if (isInCommunityPage) {
        logCommunitySearchSavedCommunitiesViewed(
          familyFileId,
          familyFileUserId,
          isDisclosureShown ? 'disclosure' : '',
        );
      }
    })();
    // eslint-disable-next-line
  }, [familyFileId, familyFileUserId, savedCommunities]);

  const removeCommunities = async (communityIds) => {
    try {
      setSaving(true);
      await deleteCommunitiesCall({
        variables: {
          communityIds: communityIds.map((id) => Number(id)),
          familyFileId,
          oneId: dataForSegment.oneId,
        },
      });
      const newCommunities = communities.filter((community) => {
        return communityIds.indexOf(Number(community.id)) < 0;
      });
      setCommunities([...newCommunities]);

      logCommunitySearchSavedCommunityRemoved(
        familyFileId,
        familyFileUserId,
        communityIds,
      );

      onRemove(newCommunities);
      setSaving(false);
      onUpdate();
    } catch (error) {
      setSaving(false);
      onUpdate(error);
    }
  };

  const toggleCommunity = (checked, community) => {
    community.isChecked = checked;
    setCommunities([...communities]);

    if (!checked) {
      setAllSelected(false);
    } else {
      if (areAllCommunitiesSelected()) {
        setAllSelected(true);
      }
    }
  };

  const anySelected = () => {
    return !!communities.find((community) => {
      return community.isChecked;
    });
  };

  const areAllCommunitiesSelected = () => {
    let allSelected = true;
    communities.forEach((community) => {
      if (
        disclosureService.isDisclosureStateWithoutDisclosure(
          community.state,
          stateCodesWithCompletedDisclosures,
        )
      ) {
        // continue
      } else {
        if (community.isChecked === false) {
          allSelected = false;
        }
      }
    });
    return allSelected;
  };

  const toggleAll = (e) => {
    setAllSelected(!allSelected);

    let updatedCommunities = clonedeep(communities);
    updatedCommunities.forEach((community) => {
      if (
        disclosureService.isDisclosureState(community.state) &&
        !stateCodesWithCompletedDisclosures.includes(community.state)
      ) {
        community.isChecked = false;
      } else {
        community.isChecked = e.target.checked;
      }

      return community;
    });
    setCommunities(updatedCommunities);
  };

  const onRemoveCommunity = (community) => {
    removeCommunities([community.id]);
  };

  const removeSelectedCommunities = () => {
    removeCommunities(getSelectedCommunities());
  };

  const removeAllCommunities = () => {
    const allCommunities = communities.map((community) => Number(community.id));
    removeCommunities(allCommunities);
  };

  const getSelectedCommunities = () => {
    return communities
      .filter((community) => community.isChecked)
      .map((community) => Number(community.id));
  };

  const generateSendReferralLink = () => {
    return `/send-referral/${familyFileId}?communities=${getSelectedCommunities().join(
      ',',
    )}`;
  };

  const getCommunityDisclosureMessage = () => {
    const stateNames = disclosureService
      .getDisclosureStateNames(stateCodesWithoutCompletedDisclosures)
      .join(', ');

    const text = labels.COMMUNITY_DISCLOSURE_MESSAGE_1.replace(
      '{state}',
      stateNames,
    );

    return (
      <Fragment>
        {text}
        <YglLink
          url="YGL_WORKING_LIST_PAGE_URL"
          externalId={externalId}
          hasError={fetchError}
          type="link"
          onClick={onClose}
        >
          {labels.COMMUNITY_DISCLOSURE_MESSAGE_2}
        </YglLink>
        {labels.COMMUNITY_DISCLOSURE_MESSAGE_3}
      </Fragment>
    );
  };

  const getTourDetails = (communityId) => {
    const tourDetail = tourDetails.find(
      (tour) => parseInt(tour.communityId) === parseInt(communityId),
    );
    return tourDetail;
  };

  const getTourDraft = (communityId) => {
    const tourDraft = tourDrafts.find(
      (draft) => draft.communityId === parseInt(communityId),
    );
    return tourDraft;
  };

  const getRecentlySavedCommunities = (communities) => {
    return communities.filter((community) => community.rank);
  };

  const getYGLWorkingListButton = () => {
    return (
      <YglLink
        url="YGL_WORKING_LIST_PAGE_URL"
        externalId={externalId}
        hasError={fetchError}
        color={isInCommunityPage ? 'light' : 'secondary'}
        target={'self'}
        textColor={isInCommunityPage ? 'eerieBlack1' : 'platinum5'}
        type="button"
        registerEventOverride={() => {
          logReferralEvent();

          if (location.pathname.includes('/communities')) {
            const recentlySavedCommunities = getRecentlySavedCommunities(
              communities,
            );
            registerEvent(
              'CommunitySearchPage',
              'Opened Working List in YGL',
              `${recentlySavedCommunities.length} saved communities`,
              true,
            );
          }
        }}
      >
        {isInCommunityPage
          ? labels.OPEN_WORKING_LIST_IN_YGL
          : labels.GO_TO_WORKING_LIST}
      </YglLink>
    );
  };

  const logReferralEvent = () => {
    const communityIds = communities.map((community) => community.id);
    const selectedCount = getSelectedCommunities().length;
    logCommunitySearchWorkingListSaved(
      familyFileId,
      familyFileUserId,
      communityIds,
      selectedCount,
    );
  };

  return (
    <div className={clsx(className, classes.saved_communities_container)}>
      {saving && (
        <div className={classes.saving_container}>
          <Loading />
        </div>
      )}
      {communities.length > 0 && (
        <div className={clsx(classes.items_container, 'items_container')}>
          <div className={classes.communities_header}>
            {showDisclosure && (
              <div className={classes.header_row}>
                <Toast className={classes.disclosure_message} type="error">
                  <Typography>{getCommunityDisclosureMessage()}</Typography>{' '}
                </Toast>
              </div>
            )}
            <div className={classes.header_row}>
              <Checkbox
                checked={allSelected}
                indeterminate={allSelected}
                label={allSelected ? labels.DESELECT_ALL : labels.SELECT_ALL}
                onChange={toggleAll}
              />
              <div>
                {flags.communitySearchUiFromYgl && getYGLWorkingListButton()}
                <Button
                  onClick={() => {
                    logReferralEvent();
                  }}
                  to={generateSendReferralLink()}
                  type={anySelected() ? 'secondary' : 'disabled'}
                  size="small"
                  startIcon={<Send />}
                  className={classes.button_spacing}
                >
                  <Typography
                    className={classes.button_text}
                    color={anySelected() ? 'platinum5' : 'eerieBlack5'}
                  >
                    {labels.SEND_REFERRAL_ALERT}
                  </Typography>
                </Button>
              </div>
            </div>
          </div>
          <div
            className={clsx(
              classes.communities_container,
              'communities_container',
            )}
          >
            {communities.map((community, key) => (
              <Community
                community={{ ...community, id: Number(community.id) }}
                dataForAnalytics={{
                  ...dataForAnalytics,
                  careTypes: community.careTypes,
                }}
                familyFileId={familyFileId}
                familyFileUserId={familyFileUserId}
                imageUrl={imageUrl}
                key={key}
                labels={labels}
                onChange={(e) => toggleCommunity(e.target.checked, community)}
                onRemove={onRemoveCommunity}
                stateCodesWithCompletedDisclosures={
                  stateCodesWithCompletedDisclosures
                }
                tourDetails={getTourDetails(community.id)}
                tourDraft={getTourDraft(community.id)}
              />
            ))}
          </div>
          <div className={classes.communities_footer}>
            <Link disabled={!anySelected()} onClick={removeSelectedCommunities}>
              <Typography level="tiny">{labels.REMOVE_SELECTED}</Typography>
            </Link>
            <Link onClick={removeAllCommunities}>
              <Typography level="tiny">{labels.CLEAR_LIST}</Typography>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

SavedCommunities.propTypes = {
  className: PropTypes.string,
  dataForAnalytics: PropTypes.shape({
    originatingPage: PropTypes.string,
  }),
  dataForSegment: PropTypes.shape({
    familyFileId: PropTypes.number,
    oneId: PropTypes.string,
  }),
  familyFileId: PropTypes.number,
  familyFileUserId: PropTypes.number,
  flags: PropTypes.shape({
    communitySearchUiFromYgl: PropTypes.bool,
  }),
  isInCommunityPage: PropTypes.bool,
  labels: PropTypes.object.isRequired,
  savedCommunities: PropTypes.array,
  onClose: PropTypes.func,
  onRemove: PropTypes.func,
  onUpdate: PropTypes.func,
  tourDetails: PropTypes.arrayOf(
    PropTypes.shape({
      scheduledTourDate: PropTypes.number,
      tourType: PropTypes.string,
      familyCommunityTourId: PropTypes.number,
      communityId: PropTypes.number,
    }),
  ),
  tourDrafts: PropTypes.arrayOf(PropTypes.object),
};

SavedCommunities.defaultProps = {
  savedCommunities: [],
  className: '',
  onClose: () => {},
  onRemove: () => {},
  onUpdate: () => {},
  isInCommunityPage: false,
};

export default withLDConsumer()(SavedCommunities);
