import React from 'react';
import PropTypes from 'prop-types';

const FullKitchen = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M23.8098 24.5728H12.1431C11.7859 24.5728 11.5479 24.3347 11.5479 23.9776V12.1918C11.5479 11.4776 12.1431 10.8823 12.9764 10.8823H23.2145C23.9288 10.8823 24.6431 11.4776 24.6431 12.1918V23.9776C24.405 24.3347 24.1669 24.5728 23.8098 24.5728Z"
          fill="white"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M13.81 13.1441C14.1671 13.1441 14.5243 12.906 14.5243 12.5489C14.5243 12.1917 14.1671 11.9536 13.81 11.9536C13.4528 11.9536 13.0957 12.1917 13.0957 12.5489C13.0957 12.906 13.4528 13.1441 13.81 13.1441Z"
          fill="currentColor"
        />
        <path
          d="M22.0239 13.1441C22.381 13.1441 22.7381 12.906 22.7381 12.5489C22.7381 12.1917 22.381 11.9536 22.0239 11.9536C21.6667 11.9536 21.3096 12.1917 21.3096 12.5489C21.3096 12.906 21.6667 13.1441 22.0239 13.1441Z"
          fill="currentColor"
        />
        <path
          d="M19.2856 13.1441C19.6427 13.1441 19.9999 12.906 19.9999 12.5489C19.9999 12.1917 19.6427 11.9536 19.2856 11.9536C18.9284 11.9536 18.5713 12.1917 18.5713 12.5489C18.5713 12.906 18.9284 13.1441 19.2856 13.1441Z"
          fill="currentColor"
        />
        <path
          d="M16.5473 13.1441C16.9044 13.1441 17.2616 12.906 17.2616 12.5489C17.2616 12.1917 16.9044 11.9536 16.5473 11.9536C16.1901 11.9536 15.833 12.1917 15.833 12.5489C15.833 12.906 16.1901 13.1441 16.5473 13.1441Z"
          fill="currentColor"
        />
        <path
          d="M21.6664 22.6681H14.1664C13.8093 22.6681 13.4521 22.43 13.4521 22.0728V16.8347C13.4521 16.4776 13.8093 16.2395 14.1664 16.2395H21.6664C22.0236 16.2395 22.3807 16.4776 22.3807 16.8347V21.9538C22.3807 22.3109 22.0236 22.6681 21.6664 22.6681Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M11.667 14.2156H24.0479"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M10.3576 23.9775H1.66713C1.07189 23.9775 0.595703 23.5013 0.595703 23.0251V2.07274C0.595703 1.59655 1.07189 1.12036 1.66713 1.12036H10.4767C11.0719 1.12036 11.5481 1.59655 11.5481 2.07274V23.0251C11.429 23.5013 10.9528 23.9775 10.3576 23.9775Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M0.595703 7.90601H11.429"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M2.61914 23.9775V24.9299"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M9.40527 23.9775V24.9299"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M2.61914 10.7632V15.1679"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M2.61914 5.04896V3.02515"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M22.7386 6.12048H13.0957L14.2862 3.14429H21.5481L22.7386 6.12048Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.548 1.12036H14.2861V3.14417H21.548V1.12036Z"
          fill="white"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.2379 5.16805C15.476 5.16805 15.7141 4.92995 15.7141 4.69186C15.7141 4.45376 15.476 4.33472 15.2379 4.33472C14.9998 4.33472 14.7617 4.57281 14.7617 4.81091C14.7617 5.049 14.8808 5.16805 15.2379 5.16805Z"
          fill="currentColor"
        />
        <path
          d="M16.5475 5.16796C16.7856 5.16796 17.0237 4.92986 17.0237 4.69177C17.0237 4.45367 16.7856 4.21558 16.5475 4.21558C16.3094 4.21558 16.0713 4.45367 16.0713 4.69177C16.0713 4.92986 16.3094 5.16796 16.5475 5.16796Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0 0.525146)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

FullKitchen.propTypes = {
  className: PropTypes.string,
};

export default FullKitchen;
