import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './Expandable.style';
import Typography from '../Typography/Typography';
import Plus from '../Icons/basic/Plus';
import Minus from '../Icons/basic/Minus';
import ChevronTop from '../Icons/arrow/ChevronTop';
import ChevronBottom from '../Icons/arrow/ChevronBottom';
import clsx from 'clsx';

const Expandable = (props) => {
  const {
    accordionColor,
    children,
    className,
    closeContentIcon,
    disableToggleClick,
    isExpanded,
    onToggle,
    openContentIcon,
    themeStyle,
    title,
    titleIcon,
  } = props;

  const classes = useStyles(props);
  const containerClasses = clsx(classes.container, className);

  const [isOpen, setIsOpen] = useState(isExpanded);

  const isThemeBasic = themeStyle === 'basic';

  const titleIconRendered = titleIcon && (
    <span className={classes.titleIcon}>{titleIcon}</span>
  );

  const handleToggleClick = () => {
    if (!disableToggleClick) {
      setIsOpen(!isOpen);
      onToggle(!isOpen);
    }
  };

  const openIcon = !isThemeBasic ? <ChevronTop /> : openContentIcon;

  const closeIcon = !isThemeBasic ? <ChevronBottom /> : closeContentIcon;

  return (
    <div
      className={clsx(
        containerClasses,
        !isThemeBasic && classes.accordionContainer,
      )}
    >
      <div
        data-testid="expandable-title-id"
        className={clsx(
          isThemeBasic && classes.basicTitle,
          !isThemeBasic && classes.accordionTitle,
        )}
        onClick={handleToggleClick}
      >
        <Typography
          level="small"
          bold
          color={
            isThemeBasic || (!isThemeBasic && accordionColor !== 'blue')
              ? 'eerieBlack1'
              : 'white'
          }
        >
          {titleIconRendered}
          {title}
        </Typography>
        <div className={clsx(classes.icon, 'icon')}>
          {isOpen ? openIcon : closeIcon}
        </div>
      </div>
      {isOpen && <div className={classes.body}>{children}</div>}
    </div>
  );
};

Expandable.propTypes = {
  accordionColor: PropTypes.oneOf(['blue', 'gray']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  closeContentIcon: PropTypes.node,
  disableToggleClick: PropTypes.bool,
  isExpanded: PropTypes.bool,
  onToggle: PropTypes.func,
  openContentIcon: PropTypes.node,
  themeStyle: PropTypes.oneOf(['basic', 'accordion']),
  title: PropTypes.node.isRequired,
  titleIcon: PropTypes.node,
};

Expandable.defaultProps = {
  className: '',
  closeContentIcon: <Plus />,
  disableToggleClick: false,
  isExpanded: false,
  onToggle: () => {},
  openContentIcon: <Minus />,
  themeStyle: 'basic',
};

export default Expandable;
