import React, { useEffect, useState } from 'react';
import Tabs from '../../common/Tabs/Tabs';
import PropTypes from 'prop-types';
import { useStyles } from './CaseProfileDesktop.style';
import FamilyFileDesktop from '../FamilyFile/FamilyFileDesktop';
import Activity from '../Activity/Activity';
import CommunityLG from '../Community/CommunityLG';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const CaseProfileDesktop = ({
  familyFileId,
  labels,
  activeTab,
  showCommunitiesTab,
  flags,
}) => {
  const classes = useStyles();
  const tabs = [];
  const [activeTabIndex, setActiveTabIndex] = useState(activeTab);

  // flags.onlyShowCommunitiesTabFf will 'hide' Activity tab; see LD description
  if (!flags.onlyShowCommunitiesTabFf) {
    tabs.push(
      {
        label: labels.FAMILY_FILE,
        component: (
          <FamilyFileDesktop labels={labels} familyFileId={familyFileId} />
        ),
      },
      {
        label: labels.ACTIVITY,
        component: <Activity labels={labels} familyFileId={familyFileId} />,
      },
    );
  }

  if (flags.communitiesOwl && showCommunitiesTab) {
    tabs.push({
      label: labels.COMMUNITIES,
      component: <CommunityLG labels={labels} familyFileId={familyFileId} />,
    });
  }

  useEffect(() => {
    setActiveTabIndex(activeTab);
    const activeTabInfo = tabs[activeTabIndex];
    if (activeTabInfo) {
      sessionStorage.setItem(
        'CASE_PROFILE_ACTIVE_TAB',
        `${activeTabInfo.label} tab`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <div className={classes.case_profile_container}>
      <Tabs activeTab={activeTab} className={classes.tabs} tabs={tabs} />
    </div>
  );
};

CaseProfileDesktop.propTypes = {
  isOpen: PropTypes.bool,
  flags: PropTypes.object,
  familyFileId: PropTypes.number,
  activeTab: PropTypes.number,
  onClose: PropTypes.func,
  labels: PropTypes.object,
  showCommunitiesTab: PropTypes.bool,
};

CaseProfileDesktop.defaultProps = {
  showCommunitiesTab: false,
};

export default withLDConsumer()(CaseProfileDesktop);
