import React from 'react';
import Mail from 'common/Icons/basic/Mail';
import CallPhone from 'common/Icons/call/CallPhone';
import ClipboardText from 'common/Icons/file/ClipboardText';
import Bread from 'common/Icons/food/Bread';
import Coffee from 'common/Icons/food/Coffee';
import Laptop from 'common/Icons/gadgets/Laptop';
import CalendarDates from 'common/Icons/time/CalendarDates';
import Logout from 'common/Icons/logout/Logout';

const getLogoutOption = () => {
  return {
    id: '900000',
    isSelectable: true,
    name: 'Log Out of Five9',
  };
};

const getItems = (agentStates) => {
  if (agentStates.length === 0) {
    return [
      {
        color: 'hotCinnamon3',
        id: '-1',
        isSelectable: false,
        name: 'Not Ready',
      },
    ];
  }

  const mapper = {
    0: {
      icon: <CallPhone />,
      color: 'wintergreenDream2',
    },
    217707: {
      icon: <Coffee />,
      color: 'hotCinnamon3',
    },
    217708: {
      icon: <Bread />,
      color: 'hotCinnamon3',
    },
    217710: {
      icon: <CalendarDates />,
      color: 'hotCinnamon3',
    },
    217709: {
      icon: <CalendarDates />,
      color: 'hotCinnamon3',
    },
    217711: {
      icon: <ClipboardText />,
      color: 'hotCinnamon3',
    },
    217713: {
      icon: <Mail />,
      color: 'hotCinnamon3',
    },
    217712: {
      icon: <Laptop />,
      color: 'hotCinnamon3',
    },
    900000: {
      icon: <Logout />,
      color: 'eerieBlack1',
    },
  };
  return agentStates.map((status) => {
    const extra = mapper[status.id] || { color: 'hotCinnamon3' };
    return { ...status, ...extra };
  });
};

export { getItems, getLogoutOption };
