import React from 'react';
import PropTypes from 'prop-types';

const ChevronLeft = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M9.41436 12L16.7073 19.2929L15.293 20.7071L6.58594 12L15.293 3.29289L16.7073 4.70711L9.41436 12Z"
      />
    </svg>
  );
};

ChevronLeft.propTypes = {
  className: PropTypes.string,
};

export default ChevronLeft;
