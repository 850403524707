import React, { useState } from 'react';
import { useStyles } from './ReferredCommunityLinks.style';
import PropTypes from 'prop-types';
import Typography from 'common/Typography/Typography';
import Link from 'common/Link/Link';
import Divider from 'common/Divider/Divider';
import RoomChargesModal from 'pages/Communities/CommunityPriceRange/RoomChargesModal';
import {
  getRoomCharges,
  getRoomFees,
} from 'pages/Communities/CommunitiesTable/communityService';

const ReferredCommunityLinks = ({
  community,
  careTypeName,
  isShowMoreClicked,
  toggleShowMoreClick,
  labels,
}) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <RoomChargesModal
        careTypeNames={careTypeName}
        careTypes={community.careTypes}
        careTypeStyle="tag"
        communityName={community.name}
        isModalOpen={isModalOpen}
        labels={labels}
        onClose={() => setIsModalOpen(false)}
        roomCharges={getRoomCharges(community.roomCharges)}
        roomFees={getRoomFees(community.roomFees)}
      />
      <div className={classes.link_container}>
        <div className={classes.links}>
          <Link onClick={() => setIsModalOpen(true)}>
            <Typography color="cosmicCobalt3" level="small">
              {labels.DETAILED_RATES.toLowerCase()}
            </Typography>
          </Link>
          <Divider className={classes.link_divider} color="disabled"></Divider>
          <Link onClick={toggleShowMoreClick}>
            <Typography color="cosmicCobalt3" level="small">
              {isShowMoreClicked
                ? labels.SHOW_LESS.toLowerCase()
                : labels.SHOW_MORE.toLowerCase()}
            </Typography>
          </Link>
        </div>
      </div>
    </>
  );
};

ReferredCommunityLinks.propTypes = {
  careTypeStyle: PropTypes.oneOf(['tag', 'map']),
  careTypeName: PropTypes.object,
  community: PropTypes.object,
  isShowMoreClicked: PropTypes.bool,
  toggleShowMoreClick: PropTypes.func,
  labels: PropTypes.object,
};

export default ReferredCommunityLinks;
