import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  modal_body: {
    width: '100%',
    height: '30vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: theme.spacing(1),
    boxSizing: 'border-box',
  },
  title_icon: {
    color: theme.palette.success.main,
  },
  radio_buttons_container: {
    marginBottom: theme.spacing(2),
  },
  enter_tour_detail: {
    marginBottom: theme.spacing(2),
  },
  complete_action_options: {
    '& label': {
      marginRight: theme.spacing(4),
    },
  },
  input_container: {
    maxWidth: '220px',
    marginBottom: theme.spacing(2),
    '& div': {
      maxWidth: '220px',
    },
    position: 'relative',
  },
  error_text: {
    color: theme.palette.correction.smokyTopaz2,
  },
  divider: {
    width: '100%',
    margin: theme.spacing(1, 0),
  },
  buttons_container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2, 2),
    boxSizing: 'border-box',
  },
  cancel_button: {
    marginRight: theme.spacing(2),
  },
  error: {
    marginBottom: theme.spacing(1),
  },
}));

export { useStyles };
