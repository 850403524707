import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    more_filters_container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    title: {
      display: 'flex',
      flexGrow: '1',
      userSelect: 'none',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '8px 16px',
      backgroundColor: theme.palette.light.platinum4,
      borderBottom: `1px solid ${theme.palette.light.platinum1}`,
    },
  };
});

export { useStyles };
