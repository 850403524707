import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CheckMark from 'common/Icons/basic/CheckMark';
import X from 'common/Icons/basic/X';
import { useStyles } from './Contacts.style';
import Typography from 'common/Typography/Typography';

const ReferralPreferencesDisplay = ({ labels, contact }) => {
  const isInquirer = contact.isInquirer;
  const allowContact = contact.allowContact;
  const allowMailing = contact.allowMailing;

  const classes = useStyles();

  return (
    <div>
      <div className={classes.row}>
        <div className={classes.column}>
          <Typography level="tiny" color="eerieBlack5">
            {`${labels.REFERRAL_PREFERENCES}`}
          </Typography>
          <div className={classes.contact}>
            <ChoosingCheckMark
              preference={isInquirer}
              title={labels.INQUIRER}
            />
          </div>
          <div className={classes.contact}>
            <ChoosingCheckMark
              preference={allowContact}
              title={labels.ALLOW_CONTACT}
            />
          </div>
          <div className={classes.contact}>
            <ChoosingCheckMark
              preference={allowMailing}
              title={labels.MAILINGS}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ChoosingCheckMark = ({ title, preference }) => {
  const classes = useStyles();
  if (preference === true) {
    return (
      <Fragment>
        <CheckMark className={classes.icon} />
        <Typography level="small" color="eerieBlack3">
          {title}
        </Typography>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <X className={classes.icon_x} />
      <Typography level="small" color="smokyTopaz2">
        {title}
      </Typography>
    </Fragment>
  );
};

ReferralPreferencesDisplay.propTypes = {
  labels: PropTypes.shape({
    CONTACTS: PropTypes.string.isRequired,
    HOME_PHONE: PropTypes.string.isRequired,
    CELL_PHONE: PropTypes.string.isRequired,
    EMAIL: PropTypes.string.isRequired,
    SECONDARY_CONTACT: PropTypes.string.isRequired,
    PRIMARY_CONTACT: PropTypes.string.isRequired,
    UNKNOWN: PropTypes.string.isRequired,
    REFERRAL_PREFERENCES: PropTypes.string.isRequired,
    INQUIRER: PropTypes.string.isRequired,
    ALLOW_CONTACT: PropTypes.string.isRequired,
    MAILINGS: PropTypes.string.isRequired,
  }).isRequired,
  contact: PropTypes.shape({
    name: PropTypes.string,
    relationship: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    allowContact: PropTypes.bool,
    isInquirer: PropTypes.bool,
    allowMailing: PropTypes.bool,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    state: PropTypes.string,
    contactDetail: PropTypes.shape({
      phonePrimary: PropTypes.string,
      phoneSecondary: PropTypes.string,
      email: PropTypes.string,
      primaryPhoneType: PropTypes.string,
      secondaryPhoneType: PropTypes.string,
    }),
  }),
};

ChoosingCheckMark.propTypes = {
  title: PropTypes.string,
  preference: PropTypes.bool,
};
ReferralPreferencesDisplay.defaultProps = {};

export default ReferralPreferencesDisplay;
