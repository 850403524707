import { formatPhoneWithParenthesis } from '../../utils/phoneFormat';
import { parsePhoneNumber } from 'libphonenumber-js';

const phonesAreEquals = (phone1, phone2) => {
  const formattedPhone1 = formatPhoneWithParenthesis(
    parsePhoneNumber(phone1.trim(), 'US').formatNational(),
  );
  const formattedPhone2 = formatPhoneWithParenthesis(
    parsePhoneNumber(phone2.trim(), 'US').formatNational(),
  );
  return formattedPhone1 === formattedPhone2;
};

const mapMatchedFieldsContact = (contact, userInputValues) => {
  contact.checked = false;
  contact.nameMatch = false;
  contact.phoneMatch = false;
  contact.emailMatch = false;
  contact.oneIdMatch = false;
  if (
    contact.firstName === userInputValues.firstName &&
    contact.lastName === userInputValues.lastName
  ) {
    contact.nameMatch = true;
  }

  if (phonesAreEquals(contact.phone, userInputValues.phone)) {
    contact.phoneMatch = true;
  }
  if (contact.email === userInputValues.email) {
    contact.emailMatch = true;
  }
  if (contact.oneId === userInputValues.oneId) {
    contact.oneIdMatch = true;
  }

  return contact;
};

const buildCurrentContact = (userInputValues) => {
  return {
    firstName: userInputValues.firstName,
    lastName: userInputValues.lastName,
    phone: userInputValues.phone,
    email: userInputValues.email,
    oneId: userInputValues.oneId,
  };
};

const getValidInputValue = (shouldErase, inputValue, answer, idField) => {
  if (shouldErase || inputValue === null || inputValue === undefined) {
    return '';
  }
  if (
    answer.userInputValues &&
    inputValue !== answer.userInputValues[idField]
  ) {
    return answer.userInputValues[idField];
  }
  return inputValue;
};

export default {
  mapMatchedFieldsContact,
  buildCurrentContact,
  getValidInputValue,
};
