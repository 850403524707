import gql from 'graphql-tag';

export default gql`
  query($zip: [String!]) {
    findOwlCommunities(
      zipCodes: $zip
      distance: 3
      size: 25
      sort: [{ distance: "asc" }]
      isReferrable: false
    ) {
      name
      isReferrable
      city
      state
      zip
      geoLocation {
        lat
        lon
      }
    }
  }
`;
