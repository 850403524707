import React from 'react';
import { useStyles } from './SortButton.style';
import ArrowBottom from '../Icons/arrow/ArrowBottom';
import ArrowTop from '../Icons/arrow/ArrowTop';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const SortButton = ({
  filter,
  emptyLabel,
  label,
  type,
  onClick,
  className,
}) => {
  const classes = useStyles();
  const selectClasses = clsx([classes.custom_select, classes[type]], className);

  return (
    <div className={selectClasses} data-testid="sort-header-id">
      <div className={classes.label}>{label}</div>
      <div className={classes.select} onClick={onClick} tabIndex="0">
        <div>{emptyLabel}</div>
        <div className={classes.icon}>
          {filter === 'asc' ? <ArrowTop /> : <ArrowBottom />}
        </div>
      </div>
    </div>
  );
};

SortButton.propTypes = {
  filter: PropTypes.string,
  className: PropTypes.string,
  emptyLabel: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['default', 'textual', 'buttoned']),
  onClick: PropTypes.func,
};

SortButton.defaultProps = {
  filter: 'desc',
  emptyLabel: 'Select from list',
  type: 'textual',
  onClick: () => {},
};

export default SortButton;
