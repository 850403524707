const getCallAttemptsQuery = `
  query GetCallAttempt($input: GetCallAttemptInput!) {
    getCallAttempt(input: $input) {
      callId
      dispositionId
      dispositionName
      systemCallId
      attemptNumber
      updatedAt
      maxAttempts
      phoneNumber
      agentDetails {
        agentName
        agentId
      }
    }
  }
`;

export default getCallAttemptsQuery;
