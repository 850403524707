import React, { useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import { useStyles } from './LocationFilter.style';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import TextInput from '../../TextInput/TextInput';
import Search from '../../Icons/basic/Search';
import Delete from '../../Icons/basic/Delete';
import Typography from '../../Typography/Typography';
import PinStart from '../../Icons/maps/PinStart';
import {
  getSuggestions,
  getLocationWithPostalCode,
} from 'services/placesService';

let savedText = '';
let savedPostalCode = '';

const LocationFilter = ({
  className,
  disabled,
  labels,
  minLength,
  onChange,
  placeholder,
  preferredLocations,
  selectedValue,
  loadLastSearch,
}) => {
  const classes = useStyles();
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showEndIcon, setShowEndIcon] = useState(true);
  const [inputError, setInputError] = useState('');

  const loadLastSearchRef = useRef(loadLastSearch);

  const selectClasses = clsx([
    className,
    classes.location_search_container,
    disabled && classes.disabled,
  ]);

  const getState = (value) => value.replace(/.*,\s([A-Z]{2})\s.*/, '$1');

  const EndIcon = () => {
    if (showEndIcon) {
      return (
        <div onClick={handleDeleteText} className={classes.delete_icon}>
          <Delete />
        </div>
      );
    } else {
      return <></>;
    }
  };

  const checkWhetherToShowEndIcon = () => {
    if (postalCode && postalCode.length) {
      setShowEndIcon(false);
      return;
    }
    if (text.length > 0) {
      setShowEndIcon(true);
      return;
    }
    setShowEndIcon(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
        if (loadLastSearchRef.current) {
          setText(savedText);
          setPostalCode(savedPostalCode);
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    checkWhetherToShowEndIcon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, postalCode]);

  useEffect(() => {
    if (preferredLocations.length > 0 && !postalCode) {
      setText(preferredLocations[0].label);
      setPostalCode(preferredLocations[0].value);
      const initialText = selectedValue
        ? selectedValue
        : preferredLocations[0].label;
      saveSelectedLocation(initialText, preferredLocations[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferredLocations]);

  useEffect(() => {
    if (selectedValue) {
      setText(selectedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const saveSelectedLocation = (text, postalCode) => {
    savedText = text;
    savedPostalCode = postalCode;
  };

  const handleDeleteText = () => {
    setText('');
    setPostalCode('');
  };

  const zipCodeCheck = (text) => {
    if (!isNaN(Number(text)) && text.length >= 5 && text.length <= 6) {
      return text;
    }
  };

  const handleInputChange = async (value) => {
    setInputError('');
    const isValueAtMinimumLength = value.length >= minLength;
    if (isValueAtMinimumLength) {
      const suggestions = await getSuggestions(value);
      if (suggestions && suggestions.length === 0) {
        setSuggestions([]);
        return;
      }
      setIsOpen(true);

      try {
        let location = await getLocationWithPostalCode(suggestions[0]);
        if (location.zip === undefined) {
          setInputError('This location is not supported.');
          suggestions.shift();
          setSuggestions(suggestions);
          setIsOpen(false);
          return;
        }
        if (zipCodeCheck(value) && !location.zip.match(value)) {
          setInputError(`${value} is not a valid zipcode`);
          setIsOpen(false);
          return;
        }
        setSuggestions(suggestions);
      } catch (error) {
        console.error('ERROR finding location.', error);
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleInputChangeDebounced = useRef(
    debounce((searchText) => handleInputChange(searchText), 750),
  ).current;

  const handleInputClick = () => {
    setText('');
    setInputError('');
    setSuggestions([]);
  };

  const handlePreferredLocationClick = (item) => {
    setText(item.label);
    setPostalCode(item.value);
    setIsOpen(false);

    loadLastSearchRef.current = true;
    saveSelectedLocation(item.label, item.value, '');
    onChange({
      zip: item.value,
      address: item.label,
      state: getState(item.label),
      country: item.country ? item.country : '',
    });
  };

  const handleSuggestionClick = async (item) => {
    const location = await getLocationWithPostalCode(item);
    const locationText = `${location.city}, ${location.state} ${location.zip}`;
    setText(locationText);
    setPostalCode(location.zip);
    setIsOpen(false);

    loadLastSearchRef.current = true;
    saveSelectedLocation(locationText, location.zip, location.country);
    onChange({
      zip: location.zip,
      address: locationText,
      state: location.state,
      country: location.country,
      city: location.city,
    });
  };

  const onInputFocus = () => {
    if (inputError) {
      setInputError('');
      setText('');
    }
    if (preferredLocations.length > 0) {
      setIsOpen(true);
    }
  };

  return (
    <div className={selectClasses} ref={wrapperRef}>
      <div>
        <TextInput
          textHint={inputError}
          error={!!inputError}
          autoComplete="off"
          endIcon={<EndIcon />}
          id="location_filter_input"
          label={labels.LOCATION}
          onChange={handleInputChangeDebounced}
          onBlur={onInputFocus}
          onClick={handleInputClick}
          onFocus={onInputFocus}
          placeholder={placeholder}
          startIcon={<Search />}
          value={text}
        />
      </div>
      {isOpen && !disabled && (
        <div className={classes.menu}>
          <div className={classes.items_container}>
            {preferredLocations.length > 0 && (
              <div>
                <Typography
                  className={classes.group_name}
                  level="small"
                  color="eerieBlack3"
                >
                  {labels.PREFERRED_LOCATIONS}
                </Typography>
                {preferredLocations.map((location, key) => (
                  <div
                    className={classes.group_item}
                    key={key}
                    onClick={() => handlePreferredLocationClick(location)}
                  >
                    <PinStart />
                    <Typography>{location.label}</Typography>
                  </div>
                ))}
              </div>
            )}
            <div>
              {suggestions?.length > 0 && (
                <>
                  <Typography
                    className={classes.group_name}
                    level="small"
                    color="eerieBlack3"
                  >
                    {labels.SUGGESTED}
                  </Typography>
                  {suggestions.map((location, key) => (
                    <div
                      className={classes.group_item}
                      key={key}
                      onClick={() => handleSuggestionClick(location)}
                    >
                      <Typography>{location.description}</Typography>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

LocationFilter.propTypes = {
  placeholder: PropTypes.string,
  preferredLocations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  className: PropTypes.string,
  minLength: PropTypes.number,
  labels: PropTypes.object,
  onChange: PropTypes.func,
  selectedItem: PropTypes.object,
  disabled: PropTypes.bool,
  selectedValue: PropTypes.string,
  loadLastSearch: PropTypes.bool,
};

LocationFilter.defaultProps = {
  placeholder: 'No Preferred Location',
  className: '',
  preferredLocations: [],
  disabled: false,
  minLength: 3,
  onChange: () => {},
  loadLastSearch: true,
};

export default LocationFilter;
