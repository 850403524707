import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  case_profile_container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.light.platinum4,
  },
  tabs: {
    flexGrow: 1,
    '& .tabs_container': {
      boxShadow: '0px 0px 8px rgba(186, 186, 184, 0.3)',
      backgroundColor: theme.palette.light.platinum5,
    },
  },
}));

export { useStyles };
