import React from 'react';
import PropTypes from 'prop-types';

const ChevronsRight = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M7.70718 5.29289L6.29297 6.70711L11.5859 12L6.29297 17.2929L7.70718 18.7071L14.4143 12L7.70718 5.29289ZM12.7072 5.29289L11.293 6.70711L16.5859 12L11.293 17.2929L12.7072 18.7071L19.4143 12L12.7072 5.29289Z"
      />
    </svg>
  );
};

ChevronsRight.propTypes = {
  className: PropTypes.string,
};

export default ChevronsRight;
