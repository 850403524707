import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: 'collapse',
    fontSize: '12px',
    '& th': {
      padding: theme.spacing(1),
    },
    '& td': {
      padding: theme.spacing(1),
    },
  },
  modal_container: {
    width: '960px',
  },
  modal_body: {
    maxHeight: '68vh',
    overflow: 'auto',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '40px 10px',
    padding: theme.spacing(3),
  },
  modal_footer: {
    borderTop: `1px solid ${theme.palette.light.platinum1}`,
    display: 'flex',
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(3),
    },
  },
}));

export { useStyles };
