import { useLocation } from 'react-router-dom';

const useQueryParams = (variableName) => {
  const location = useLocation();
  const search = location.search;
  const urlParams = new URLSearchParams(search);
  return urlParams.get(variableName);
};

export default useQueryParams;
