import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { useStyles } from './NavigationSidePanel.style';
import SidePanel from '../../common/SidePanel/SidePanel';
import Typography from '../../common/Typography/Typography';
import Dashboard from '../../common/Icons/backup/Dashboard';
import ChevronTop from '../../common/Icons/arrow/ChevronTop';
import ChevronBottom from '../../common/Icons/arrow/ChevronBottom';
import FolderOpened from '../../common/Icons/file/FolderOpened';
import SquareStairs from '../../common/Icons/basic/SquareStairs';
import { registerEvent } from '../../services/Analytics';
import { colorReference } from '../../common/Theme/themes/default';

const SalesPhaseBucket = {
  NEW_PREFERRED: 1,
  RECENT_REFERRALS: 2,
  OLDER_REFERRALS: 3,
  MOVEINS: 4,
  TOURING: 5,
  CLOSED: 6,
};

const NavigationSidePanel = ({
  labels,
  isOpen,
  onClose,
  backgroundColor,
  width,
  xColor,
}) => {
  const classes = useStyles();
  const [isFamilyFilesOpen, setIsFamilyFilesOpen] = useState(true);

  const history = useHistory();

  const onClickDashboard = (event) => {
    event.stopPropagation();
    onClose();
    history.push(`/dashboard`);
  };

  const onClickFamilyFileBucket = (bucketId, event) => {
    switch (bucketId) {
      case SalesPhaseBucket.NEW_PREFERRED:
        registerEvent(
          'FamilyFiles',
          'Side navigation clicked - New and Pre-Referred',
        );
        break;
      case SalesPhaseBucket.RECENT_REFERRALS:
        registerEvent(
          'FamilyFiles',
          'Side navigation clicked - Recently Referred',
        );
        break;
      case SalesPhaseBucket.OLDER_REFERRALS:
        registerEvent(
          'FamilyFiles',
          'Side navigation clicked - Older Referrals',
        );
        break;
      case SalesPhaseBucket.MOVEINS:
        registerEvent('FamilyFiles', 'Side navigation clicked - Move-ins');
        break;
      case SalesPhaseBucket.TOURING:
        registerEvent('FamilyFiles', 'Side navigation clicked - Touring');
        break;
      case SalesPhaseBucket.CLOSED:
        registerEvent('FamilyFiles', 'Side navigation clicked - Closed');
        break;
      default:
        break;
    }
    event.stopPropagation();
    onClose();
    history.push(`/family-file-list?bucket=${bucketId}`);
  };

  const isMenuItemActive = (sourceElement) => {
    let currentLocationCode = '';
    switch (history.location.pathname) {
      case '/dashboard':
        currentLocationCode = 'dash';
        break;
      case '/family-file-list':
        if (sourceElement === 'ff') {
          return true;
        }
        currentLocationCode = 'ff_' + history.location.search.split('=')[1];
        break;
      default:
        break;
    }
    if (sourceElement === currentLocationCode) {
      return true;
    }
    return false;
  };

  return (
    <SidePanel
      position="left"
      width={width}
      xColor={xColor}
      isOpen={isOpen}
      onClose={onClose}
      backgroundColor={backgroundColor}
    >
      <div className={classes.navigation_side_panel_container}>
        <Typography
          className={classes.my_work_list}
          color="cosmicCobalt4"
          level="tiny"
        >
          {labels.MY_WORK_LIST}
        </Typography>

        <div
          className={clsx(classes.list_item, {
            [classes.item_active]: isMenuItemActive(`dash`),
          })}
          onClick={(event) => onClickDashboard(event)}
        >
          <Dashboard />
          <Typography color="platinum5">{labels.DASHBOARD}</Typography>
        </div>

        <div
          className={clsx(classes.list_item, {
            [classes.item_active]: isMenuItemActive('ff'),
          })}
          onClick={() => setIsFamilyFilesOpen(!isFamilyFilesOpen)}
        >
          <FolderOpened />
          <Typography color="platinum5">{labels.FAMILY_FILES}</Typography>
          <div className={classes.family_files_arrow}>
            {isFamilyFilesOpen ? <ChevronTop /> : <ChevronBottom />}
          </div>
        </div>

        {isFamilyFilesOpen && (
          <>
            <div
              className={clsx(classes.family_files_items, {
                [classes.item_active]: isMenuItemActive(
                  `ff_${SalesPhaseBucket.NEW_PREFERRED}`,
                ),
              })}
              onClick={(event) =>
                onClickFamilyFileBucket(SalesPhaseBucket.NEW_PREFERRED, event)
              }
            >
              <div className={classes.yellow_circle} />
              <Typography color="platinum5">
                {labels.NEW_AND_PRE_REFERRED}
              </Typography>
            </div>

            <div
              className={clsx(classes.family_files_items, {
                [classes.item_active]: isMenuItemActive(
                  `ff_${SalesPhaseBucket.RECENT_REFERRALS}`,
                ),
              })}
              onClick={(event) =>
                onClickFamilyFileBucket(
                  SalesPhaseBucket.RECENT_REFERRALS,
                  event,
                )
              }
            >
              <div className={classes.green_circle} />
              <Typography color="platinum5">
                {labels.RECENTLY_REFERRED}
              </Typography>
            </div>

            <div
              className={clsx(classes.family_files_items, {
                [classes.item_active]: isMenuItemActive(
                  `ff_${SalesPhaseBucket.TOURING}`,
                ),
              })}
              onClick={(event) =>
                onClickFamilyFileBucket(SalesPhaseBucket.TOURING, event)
              }
            >
              <div className={classes.green_circle} />
              <Typography color="platinum5">{labels.TOURING}</Typography>
            </div>

            <div
              className={clsx(classes.family_files_items, {
                [classes.item_active]: isMenuItemActive(
                  `ff_${SalesPhaseBucket.OLDER_REFERRALS}`,
                ),
              })}
              onClick={(event) =>
                onClickFamilyFileBucket(SalesPhaseBucket.OLDER_REFERRALS, event)
              }
            >
              <div className={classes.green_circle} />
              <Typography color="platinum5">
                {labels.OLDER_REFERRALS}
              </Typography>
            </div>

            <div
              className={clsx(classes.family_files_items, {
                [classes.item_active]: isMenuItemActive(
                  `ff_${SalesPhaseBucket.MOVEINS}`,
                ),
              })}
              onClick={(event) =>
                onClickFamilyFileBucket(SalesPhaseBucket.MOVEINS, event)
              }
            >
              <div className={classes.amber_circle} />
              <Typography color="platinum5">{labels.MOVE_INS}</Typography>
            </div>

            <div
              className={clsx(classes.family_files_items, {
                [classes.item_active]: isMenuItemActive(
                  `ff_${SalesPhaseBucket.CLOSED}`,
                ),
              })}
              onClick={(event) =>
                onClickFamilyFileBucket(SalesPhaseBucket.CLOSED, event)
              }
            >
              <div className={classes.red_circle} />
              <Typography color="platinum5">{labels.CLOSED}</Typography>
            </div>
          </>
        )}

        <SquareStairs className={classes.square_stairs} />
      </div>
    </SidePanel>
  );
};

NavigationSidePanel.propTypes = {
  labels: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  width: PropTypes.string,
  xColor: PropTypes.oneOf(colorReference),
  backgroundColor: PropTypes.oneOf(colorReference),
};

export default NavigationSidePanel;
export { SalesPhaseBucket };
