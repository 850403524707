import gql from 'graphql-tag';

export default gql`
  mutation createInquiry(
    $firstName: String
    $lastName: String
    $email: String
    $primaryPhone: String!
    $desiredPostalCode: String
    $desiredCity: String
    $desiredState: String
    $relationToResident: familiarRelationship
    $moveDateRange: moveDateRange
    $veteran: Boolean
    $resident1FirstName: String
    $resident1LastName: String
    $resident1Age: Int
    $resident1Gender: gender
    $resident2FirstName: String
    $resident2LastName: String
    $resident2Age: Int
    $resident2Gender: gender
    $budgetMin: Int
    $budgetMax: Int
    $medicaid: medicaid
    $locationRange: Int
    $getAround: getAround
    $diabetic: diabetic
    $memoryLoss: memoryLoss
    $behavioral: behavioral
    $bathing: Boolean
    $toileting: Boolean
    $referrerUrl: String
    $submissionUrl: String
    $utmCampaign: String
    $utmMedium: String
    $utmSource: String
    $utmTerm: String
    $utmContent: String
    $sourceId: Int
    $subSourceId: Int
    $inquiryMethod: InquiryMethod
    $desiredCountryCode: DesiredCountryCode
    $roomPreference: [roomPreference]
    $financialResources: [additionalResources]
    $careTypeCode: [careType]
    $lookingReason: [lookingReason]
    $formData: FormManualData
    $screeningProcess: Boolean
  ) {
    createInquiry(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        primaryPhone: $primaryPhone
        desiredPostalCode: $desiredPostalCode
        desiredCity: $desiredCity
        desiredState: $desiredState
        relationToResident: $relationToResident
        careTypeCode: $careTypeCode
        lookingReason: $lookingReason
        moveDateRange: $moveDateRange
        veteran: $veteran
        resident1FirstName: $resident1FirstName
        resident1LastName: $resident1LastName
        resident1Age: $resident1Age
        resident1Gender: $resident1Gender
        resident2FirstName: $resident2FirstName
        resident2LastName: $resident2LastName
        resident2Age: $resident2Age
        resident2Gender: $resident2Gender
        budgetMin: $budgetMin
        budgetMax: $budgetMax
        financialResources: $financialResources
        medicaid: $medicaid
        locationRange: $locationRange
        roomPreference: $roomPreference
        getAround: $getAround
        diabetic: $diabetic
        memoryLoss: $memoryLoss
        behavioral: $behavioral
        bathing: $bathing
        toileting: $toileting
        referrerUrl: $referrerUrl
        submissionUrl: $submissionUrl
        utmCampaign: $utmCampaign
        utmMedium: $utmMedium
        utmSource: $utmSource
        utmTerm: $utmTerm
        utmContent: $utmContent
        sourceId: $sourceId
        subSourceId: $subSourceId
        inquiryMethod: $inquiryMethod
        desiredCountryCode: $desiredCountryCode
        formData: $formData
        screeningProcess: $screeningProcess
      }
    ) {
      oneId
      matchCriteria
      inquiryId
      firstName
      lastName
      email
      primaryPhone
      desiredPostalCode
      desiredCity
      desiredState
      relationToResident
      careTypeCode
      lookingReason
      moveDateRange
      veteran
      resident1FirstName
      resident1LastName
      resident1Age
      resident1Gender
      resident2FirstName
      resident2LastName
      resident2Age
      resident2Gender
      budgetMin
      budgetMax
      financialResources
      medicaid
      locationRange
      roomPreference
      getAround
      diabetic
      memoryLoss
      behavioral
      toileting
      bathing
      medicaid
      referrerUrl
      utmCampaign
      utmMedium
      utmSource
      utmTerm
      utmContent
      status
      createdBy
      createdAt
      updatedBy
      updatedAt
      audienceKey
      sourceId
      subSourceId
      inquiryMethod
      submissionUrl
      inquiryFormData {
        inquiryFormDataId
        inquiryId
        createdAt
        createdBy
        updatedAt
        updatedBy
        slugId
        versionId
        contactConfirmation
        disclaimer
        firstName
        lastName
        email
        primaryPhone
        noAnswerCall
        confirmInterest
        relationToResident
        resident1FirstName
        resident1LastName
        budget
        medicalInfo
        desiredCity
        desiredState
        desiredPostalCode
        desiredCountry
        desiredDistance
        notInterestedReason
        transferSla
        unqualified
        pipedaDisclosure
        isCompleteCall
        moveDateRange
        careTypeCode
        veteran
      }
      inquiryContact {
        firstName
        lastName
        phone
        oneId
        email
      }
    }
  }
`;
