import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import yglAuthHelper from '../utils/yglAuthHelper';
import cache from '../utils/cache';
import { initializeAnalyticsService } from '../services/Analytics';
import withConfig from '../utils/withConfig';
import { getHomePage } from '../config/roles';
import { getCurrentUserFromSessionStorage } from '../utils/utils';
import * as Sentry from '@sentry/react';
import history from '../utils/history';
import userQueryParam from '../services/userQueryParam';
import useGraphQLQuery from '../hooks/useGraphQLQuery';
import EmailFormInput from '../pages/Backdoor/EmailFormInput';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import Loading from 'components/Loading/Loading';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

const AUTH0_CUSTOMAPI_FAMILYFILE = withConfig('AUTH0_CUSTOMAPI_FAMILYFILE');

let authBypass = false;
const Auth0Context = React.createContext();
const useAuth0 = () => useContext(Auth0Context);

const onAuthBypassLogout = () => {
  localStorage.removeItem('email');
  window.location.replace('/dashboard');
};

const Auth0Provider = ({
  flags,
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [authInitialized, setAuthInitialized] = useState(false);

  authBypass = flags.auth0Switch;

  const handleAuth0Redirect = async (auth0FromHook) => {
    if (
      window.location.search.includes('code=') &&
      window.location.search.includes('state=')
    ) {
      const { appState } = await auth0FromHook.handleRedirectCallback();
      onRedirectCallback(appState);
    }
  };

  const handleYglAuthentication = async (auth0FromHook) => {
    if ((await yglAuthHelper.isAuthenticated()) && !isAuthenticated) {
      await yglAuthHelper.updateAuth0HookFromYgl(auth0FromHook);
      await setAuth0User(auth0FromHook);
      setIsAuthenticated(true);
    }
  };

  /**
   * Set the Auth0 user and silently get the auth0 token.
   * The ID_TOKEN and ACCESS_TOKEN will be set with the same
   * value because both values should refer to the jwt token
   * that contain user permissions, etc..
   * @param {*} auth0FromHook
   */
  const setAuth0User = async (auth0FromHook) => {
    const user = await auth0FromHook.getUser();
    const accessToken = await getToken(auth0FromHook);

    cache.set('ID_TOKEN', accessToken);
    cache.set('ACCESS_TOKEN', accessToken);
    setUser(user);
    initializeAnalyticsService();
  };

  const getToken = async (auth0FromHook) => {
    let accessToken = null;

    try {
      accessToken = await auth0FromHook.getTokenSilently({
        audience: AUTH0_CUSTOMAPI_FAMILYFILE,
      });
    } catch (err) {
      Sentry.captureException(err);
      accessToken = await auth0GetTokenWithPopup(auth0FromHook);
    }

    return accessToken;
  };

  const auth0GetTokenWithPopup = async (auth0FromHook) => {
    let accessToken = null;

    try {
      accessToken = await auth0FromHook.getTokenWithPopup({
        audience: AUTH0_CUSTOMAPI_FAMILYFILE,
      });
    } catch (err) {
      if (err.error === 'timeout') {
        //try again
        accessToken = await auth0GetTokenWithPopup(auth0FromHook);
      }
    }

    return accessToken;
  };

  const initAuth0 = async () => {
    const auth0FromHook = await createAuth0Client(initOptions);
    setAuth0(auth0FromHook);

    await handleAuth0Redirect(auth0FromHook);
    const isAuthenticated = await auth0FromHook.isAuthenticated();

    setIsAuthenticated(isAuthenticated);
    if (isAuthenticated) {
      await setAuth0User(auth0FromHook);
      const role = getCurrentUserFromSessionStorage()?.role;
      if (window.location.pathname === '/' && role) {
        const homePage = getHomePage(role);
        history.push(homePage);
      }
    } else {
      localStorage.setItem(
        'PAGE_TO_REDIRECT',
        initOptions.data.pathname + initOptions.data.search,
      );
      await handleYglAuthentication(auth0FromHook);
    }
    setLoading(false);
  };

  const initNonAuth0 = () => {
    setLoading(false);
    setIsAuthenticated(true);
  };

  useEffect(() => {
    if (authBypass) {
      initNonAuth0();

      (async () => {
        const result = await yglAuthHelper.getEmailFromLocalStorage();
        if (result !== 'NULL') {
          localStorage.setItem('email', result);
        }
        setAuthInitialized(true);
      })();
    } else {
      localStorage.removeItem('email');
      setAuthInitialized(true);
      initAuth0();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
      Sentry.captureException(`Login With Pop-Up Error: ${error}`);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  const onLogout = (...p) => {
    cache.remove('ACCESS_TOKEN');
    cache.remove('ID_TOKEN');
    localStorage.removeItem('CURRENT_USER');
    localStorage.removeItem('PAGE_TO_REDIRECT');
    localStorage.removeItem('email');
    setIsAuthenticated(false);
    auth0Client.logout(...p);
  };

  if (!authInitialized) {
    return <Loading />;
  }

  if (authBypass && !localStorage.getItem('email')) {
    return <EmailFormInput />;
  }

  if (authBypass) {
    return (
      <Auth0Context.Provider
        value={{
          isAuthenticated,
          user,
          loading: false,
          popupOpen: false,
          loginWithPopup: false,
          handleRedirectCallback: false,
          getIdTokenClaims: () => '',
          loginWithRedirect: () => '',
          getTokenSilently: () => '',
          getTokenWithPopup: () => '',
          logout: () => onAuthBypassLogout(),
        }}
      >
        {children}
      </Auth0Context.Provider>
    );
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => onLogout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

const withAuthenticationContext = (WrappedComponent) => {
  const ComponentWithAuthentication = (props) => {
    let auth = useContext(Auth0Context);
    let userEmail = localStorage.getItem('email') || '';

    const { loading, data, error } = useGraphQLQuery(userQueryParam, {
      variables: { userEmail },
    });

    if (error) {
      Sentry.captureException(`Authentication findUserByParams error: ${error}`);
    }
    
    if (authBypass) {
      if (!loading && data) {
        let foundUserData = data['findUsersByParams'][0];
        auth = {
          user: {
            given_name: foundUserData.firstName,
            family_name: foundUserData.lastName,
            picture: foundUserData.thumbnailImageId || '',
            name: foundUserData.firstName,
          },
          logout: () => onAuthBypassLogout(),
        };
      } else {
        auth = {
          user: {
            given_name: '',
            family_name: '',
            picture: '',
            name: '',
          },
          logout: () => onAuthBypassLogout(),
        };
      }
    }

    return <WrappedComponent {...props} auth={auth} />;
  };
  return ComponentWithAuthentication;
};

Auth0Provider.propTypes = {
  flags: PropTypes.shape({
    auth0Switch: PropTypes.bool,
  }),
};

export default withLDConsumer()(Auth0Provider);
export { Auth0Context, useAuth0, withAuthenticationContext };
