const getCommunityName = (communityId, familyFile) => {
  if (
    familyFile.tourCommunities &&
    familyFile.tourCommunities.communities &&
    familyFile.tourCommunities.communities.length > 0
  ) {
    const { communities } = familyFile.tourCommunities;
    let foundCommunity = communities.find((community) => {
      return community.id === communityId.toString();
    });
    return foundCommunity && foundCommunity.name && foundCommunity.name !== ''
      ? foundCommunity.name
      : 'Community Name Unknown';
  } else {
    return 'Community Name Unknown';
  }
};

const getLastCall = (familyFile) => {
  if (familyFile.lastCall) {
    return {
      type: familyFile.lastCall.actionTypeName,
      completedAt: familyFile.lastCall.completedAt,
      result: familyFile.lastCall.result,
    };
  }
  return null;
};

const getErrorOnProfileData = (familyFile) => {
  const errorOnContacts = familyFile.contacts.some((contact) => {
    return !contact.profileData;
  });
  const errorOnResidents = familyFile.residents.some((resident) => {
    return !resident.profileData;
  });
  return !(errorOnContacts || errorOnResidents);
};

const getRelationship = (familyFile, labels) => {
  const primaryContact = familyFile.contacts.find(
    (contact) => contact.profileData && contact.profileData.isPrimary,
  );
  return primaryContact?.profileData?.relationToResident || labels.UNKNOWN;
};

const getPrimaryContact = (familyFile) => {
  const primaryContact = familyFile.contacts.find(
    (contact) => contact.profileData && contact.profileData.isPrimary,
  );
  if (primaryContact) {
    if (primaryContact.profileData) {
      if (primaryContact.profileData.firstName.includes('Unknown')) {
        return 'Unknown primary contact';
      } else {
        return `${primaryContact.profileData.firstName} ${primaryContact.profileData.lastName}`;
      }
    }
    return 'Error retrieving resident info';
  }
  return 'Unknown primary contact';
};

const getProfielDataErrorMessage = (familyFile) => {
  return `Error retrieving info ID: ${familyFile.familyFileId}`;
};

const getResidentName = (familyFile, labels) => {
  if (getRelationship(familyFile, labels) === 'Self') {
    return 'Self';
  }

  const errorOnResidents = familyFile.residents.some((resident) => {
    return !resident.profileData;
  });

  if (errorOnResidents) {
    return 'Error retrieving resident info';
  }

  let residentName = familyFile.residents[0]
    ? familyFile.residents
        .map((resident) => {
          if (resident.profileData.firstName.includes('Unknown')) {
            return 'unknown';
          }
          return `${resident.profileData.firstName} ${resident.profileData.lastName}`;
        })
        .join(` ${labels.AND.toLowerCase()} `)
    : 'unknown';

  if (residentName === 'unknown') {
    residentName = 'Unknown resident name';
  }
  return residentName;
};

const getMainTourInformation = (familyFile) => {
  if (familyFile.tourDetails && familyFile.tourDetails.length > 0) {
    return {
      communityName: getCommunityName(
        familyFile.tourDetails[0].communityId,
        familyFile,
      ),
      scheduledTourDate: familyFile.tourDetails[0].scheduledTourDate,
    };
  }
  return null;
};

const getNextToursInformation = (familyFile) => {
  if (familyFile.tourDetails && familyFile.tourDetails.length > 1) {
    const modifiedTours = familyFile.tourDetails.slice(1);
    return modifiedTours.map((tour) => {
      return {
        communityName: getCommunityName(tour.communityId, familyFile),
        scheduledTourDate: tour.scheduledTourDate,
      };
    });
  }
  return [];
};

const toRowInformation = (familyFiles, labels) => {
  const all = familyFiles.map((file) => {
    return {
      familyFileId: file.familyFileId,
      audienceKey: file.audienceKey,
      primaryContact: getPrimaryContact(file),
      relationship: getRelationship(file, labels),
      residents: getResidentName(file, labels),
      salesPhase: file.salesPhase,
      lastCall: getLastCall(file),
      isProfileDataAvailable: getErrorOnProfileData(file),
      profileDataErrorMessage: getProfielDataErrorMessage(file),
    };
  });
  const notContacted = all.filter((familyFile) => !familyFile.lastCall);
  const contacted = all.filter((familyFile) => familyFile.lastCall);
  return [...notContacted, ...contacted];
};

const toTourRowInformation = (familyFiles, labels) => {
  return familyFiles.map((file) => {
    return {
      familyFileId: file.familyFileId,
      primaryContact: getPrimaryContact(file),
      relationship: getRelationship(file, labels),
      residents: getResidentName(file, labels),
      salesPhase: file.salesPhase,
      mainTour: getMainTourInformation(file),
      nextTours: getNextToursInformation(file),
      isProfileDataAvailable: getErrorOnProfileData(file),
      profileDataErrorMessage: getProfielDataErrorMessage(file),
    };
  });
};

export { toRowInformation, toTourRowInformation };
