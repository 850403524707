const emptySmallCardHeight = 79;
const maxCharsPerLine = 29;
const lineHeight = 21;
const pinHeight = 48;

const getSmallContainerTop = (content) => {
  const nameLength = content.name.length;
  const orgNameLength = content.organizationName.length;

  const nameLines = Math.ceil(nameLength / maxCharsPerLine);
  const orgNameLines = Math.ceil(orgNameLength / maxCharsPerLine);

  const nameHeight = nameLines * lineHeight;
  const orgNameHeight = orgNameLines * lineHeight;

  const mapSmallCardHeight = emptySmallCardHeight + nameHeight + orgNameHeight;
  return mapSmallCardHeight + pinHeight;
};

export { getSmallContainerTop };
