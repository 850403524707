import { useEffect } from 'react';
import { hideTelephonyIframe } from '../stateManagement/telephony/actions/telephonyActions';

export const useAutoCloseFive9 = (isAuthenticated, skills, dispatch) => {
  useEffect(() => {
    if (isAuthenticated && skills?.length > 0) {
      dispatch(hideTelephonyIframe());
    }
  }, [isAuthenticated, skills, dispatch]);
};
