import React from 'react';
import { useStyles } from './CloseSidePanelButton.style';
import ChevronsRight from '../../common/Icons/arrow/ChevronsRight';
import SmartTooltip from '../../common/SmartTooltip/SmartTooltip';
import PropTypes from 'prop-types';

const CloseSidePanelButton = ({ onClick, toolTipText }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.close_button}
      onClick={() => {
        onClick();
      }}
    >
      <SmartTooltip
        className={classes.close_button_tooltip}
        title={toolTipText}
        placement="left"
      >
        <ChevronsRight />
      </SmartTooltip>
    </div>
  );
};

CloseSidePanelButton.propTypes = {
  onClick: PropTypes.func,
  labels: PropTypes.object,
  toolTipText: PropTypes.string,
};

CloseSidePanelButton.defaultProps = {
  onClick: () => {},
};

export default CloseSidePanelButton;
