const findEnumQuery = `
  query findEnumValuesByFieldName($fieldName: String!) {
    findEnumValuesByFieldName(fieldName: $fieldName) {
      enumKey
      enumValue
      enumLabel
      enumOrder
    }
  }`;

export default findEnumQuery;
