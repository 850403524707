import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => ({
  row: {
    '& p': {
      display: 'inline',
      margin: 0,
    },
  },
  inline: {
    display: 'inline',
    '& div': {
      float: 'right',
    },
    '& svg, p:first-child': {
      marginRight: '4px',
      verticalAlign: 'middle',
    },
    '& p': {
      marginRight: '8px',
    },
  },
}));

export { useStyles };
