import React from 'react';
import PropTypes from 'prop-types';
import ViewCard from 'common/Card/ViewCard/ViewCard';
import Case from 'common/Icons/basic/Case';
import { useStyles } from './ProfessionalSource.style';
import withConfig from 'utils/withConfig';
import { useExternalId } from 'hooks/useExternalId';

const ProfessionalSource = ({
  familyFileId,
  labels,
  professionalSourceExists,
}) => {
  const classes = useStyles();
  const { externalId, fetchError } = useExternalId(familyFileId);
  let label = professionalSourceExists
    ? labels.VIEW_IN_YGL
    : labels.ASSIGN_IN_YGL;
  let buttonDisabled = false;
  if (fetchError) {
    label = labels.FAILED_TO_LOAD_EXTERNAL_ID;
    buttonDisabled = true;
  }
  if (!fetchError && !externalId) {
    label = labels.TRYING_TO_LOAD_EXTERNAL_ID;
    buttonDisabled = true;
  }
  let buttonColor = professionalSourceExists ? 'cosmicCobalt3' : 'smokyTopaz2';

  const onClickViewProfessionalSource = () => {
    if (externalId) {
      const url = withConfig('YGL_PROFESSIONAL_SOURCE_URL', {
        yglLeadID: externalId,
      });

      // These details are kept the same as defined in YGL
      window.open(
        url,
        'ProfessionalSrcTracking',
        'width=650, height=575, top=125, left=237, resizable=1, scrollbars=1, status=0',
      );
    }
  };

  return (
    <ViewCard
      className={classes.professional_source}
      clickCallback={onClickViewProfessionalSource}
      headerColor="cosmicCobalt2"
      viewButtonColor={buttonColor}
      buttonDisabled={buttonDisabled}
      title={labels.PROFESSIONAL_SOURCE}
      buttonTitle={label}
      icon={<Case />}
    >
      <></>
    </ViewCard>
  );
};

ProfessionalSource.propTypes = {
  familyFileId: PropTypes.number,
  labels: PropTypes.object.isRequired,
  professionalSourceExists: PropTypes.bool,
};

export default ProfessionalSource;
