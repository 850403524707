import { PhoneContext } from '@aplaceformom/owl-connect-client';
import React, { useEffect, useRef, useState, useContext } from 'react';
import CallNumbers from 'common/Icons/call/CallNumbers';
import PhoneDialpad from '../PhoneDialpad/PhoneDialpad';
import { useStyles } from './ExternalCall.style';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { getCallStatus } from 'config/callStates.config';
import withConfig from 'utils/withConfig';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import labels from 'config/labels';

const ExternalCall = ({ className }) => {
  const classes = useStyles();
  const { callState, makeExternalCall, error } = useContext(PhoneContext);
  const [isOpenDialpad, setIsOpenDialpad] = useState(false);
  const [errorState, setErrorState] = useState(null);
  const wrapperRef = useRef(null);
  const callClass = getCallStatus(callState);

  useEffect(() => {
    setErrorState(error);
  }, [setErrorState, error]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpenDialpad(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const onMakeExternalCall = (phoneNumber) => {
    setErrorState(null);
    makeExternalCall(withConfig('EXTERNAL_CALL_CAMPAIGN_ID'), phoneNumber);
  };

  const verifyCallClassStatus = () => {
    return callClass === 'DISCONNECTED' || callClass === '';
  };

  const toggleDialPad = () => {
    setIsOpenDialpad(!isOpenDialpad);
    setErrorState(null);
  };

  return (
    <div
      ref={wrapperRef}
      className={clsx(
        className,
        classes.external_call_container,
        verifyCallClassStatus ? '' : classes.hide_component,
      )}
      data-testid="external_call_container"
    >
      <SmartTooltip title={labels.DIALPAD} placement="left">
        <div
          className={clsx(
            classes.external_call_button,
            isOpenDialpad && classes.active,
          )}
          onClick={toggleDialPad}
          data-testid="external_call_button"
        >
          <CallNumbers />
        </div>
      </SmartTooltip>
      {isOpenDialpad && (
        <PhoneDialpad
          startCallButton
          error={errorState}
          onMakeCall={onMakeExternalCall}
        />
      )}
    </div>
  );
};

ExternalCall.defaultProps = {
  className: '',
};

ExternalCall.propTypes = {
  className: PropTypes.string,
};
export default ExternalCall;
