import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './CommunityCareTypes.style';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import Tag from 'common/Tag/Tag';

const CommunityCareTypes = ({ careTypeLabels, careTypeNames }) => {
  const classes = useStyles();

  return (
    <div className={classes.community_care_types_container}>
      {careTypeLabels &&
        careTypeLabels.map((careType, key) => (
          <SmartTooltip
            key={key}
            title={`${careTypeNames[careType]} (${careType})`}
            placement="bottom"
          >
            <Tag
              className={classes.oneLetterTag}
              text={careType}
              color="regalia5"
            />
          </SmartTooltip>
        ))}
    </div>
  );
};

CommunityCareTypes.propTypes = {
  careTypeLabels: PropTypes.array,
  careTypeNames: PropTypes.object,
};

CommunityCareTypes.defaultProps = {};

export default CommunityCareTypes;
