import React, { useEffect } from 'react';
import ErrorLayout from '../ErrorLayout/ErrorLayout';
import { useStyles } from './AuthError.style';
import NotAuthorized from '../../common/Graphics/error/NotAuthorized';
import { withLabels } from '../../context/LabelContext';
import PropTypes from 'prop-types';
import Typography from '../../common/Typography/Typography';
import Link from '../../common/Link/Link';
import Button from '../../common/Button/Button';
import { useHistory } from 'react-router-dom';
import { sentryExceptionWithData } from 'utils/sentryExceptionWithData';
import { withUser } from 'context/UserContext';

const AuthError = ({ labels, currentUser }) => {
  const history = useHistory();

  const handleGoBackClick = () => {
    history.go(-2);
  };

  useEffect(() => {
    const error = new Error(
      'Error 401: Unauthorized Role - redirected to AuthError page',
    );
    sentryExceptionWithData(error, currentUser);
  }, [currentUser]);

  const classes = useStyles();
  return (
    <ErrorLayout>
      <div className={classes.error_container}>
        <NotAuthorized className={classes.not_authorized_image} />
        <Typography level="large" bold className={classes.not_authorized_title}>
          <>{labels.NOT_AUTHORIZED_TITLE}</>
        </Typography>
        <Typography>
          <>{labels.NOT_AUTHORIZED_MESSAGE_1}</>
        </Typography>
        <Typography>
          <>{labels.NOT_AUTHORIZED_MESSAGE_2}</>
        </Typography>
        <Typography className={classes.not_authorized_bottom}>
          {labels.NOT_AUTHORIZED_MESSAGE_3_1}
          <Link to="mailto:owl-feedback@thoughtworks.com?subject=Feedback">
            {labels.NOT_AUTHORIZED_MESSAGE_3_LINK}
          </Link>
          {labels.NOT_AUTHORIZED_MESSAGE_3_3}
        </Typography>
        <Button onClick={handleGoBackClick}>{labels.BACK}</Button>
      </div>
    </ErrorLayout>
  );
};

AuthError.propTypes = {
  labels: PropTypes.shape({
    NOT_AUTHORIZED_TITLE: PropTypes.string,
    NOT_AUTHORIZED_MESSAGE_1: PropTypes.string,
    NOT_AUTHORIZED_MESSAGE_2: PropTypes.string,
    NOT_AUTHORIZED_MESSAGE_3_1: PropTypes.string,
    NOT_AUTHORIZED_MESSAGE_3_LINK: PropTypes.string,
    NOT_AUTHORIZED_MESSAGE_3_3: PropTypes.string,
    BACK: PropTypes.string,
  }).isRequired,
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.string,
    __typename: PropTypes.string,
  }),
};

export default withUser(withLabels(AuthError));
