import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import React from 'react';
import { getCurrentFamilyFile } from 'services/callCenter/duplicateFamilyFilePopupService';
import ExternalLink from '../../common/Icons/basic/ExternalLink';
import Toast from '../../common/Toast/Toast';
import Typography from '../../common/Typography/Typography';
import { useExternalId } from '../../hooks/useExternalId';
import useGraphQLQuery from '../../hooks/useGraphQLQuery';
import { toContacts } from '../../pages/FamilyFile/LeftPanel/contactsService';
import Loading from '../Loading/Loading';
import YglLink from '../YglExternalId/YglLink';
import CommunityPreferences from './CommunityPreferences/CommunityPreferences';
import ContactInformation from './ContactInformation/ContactInformation';
import { useStyles } from './FamilyFile.style';
import DuplicateFamilyFile from './FamilyFilePopup/DuplicateFamilyFile';
import familyFileQuery from './familyFileQuery';
import toFamilyFileInformation from './familyFileService';
import FinancialCriteria from './FinancialCriteria/FinancialCriteria';
import MedicalBackgroundDesktop from './MedicalBackground/MedicalBackgroundDesktop';
import Overview from './Overview/Overview';

const FamilyFileMobile = ({ labels, familyFileId, flags }) => {
  const classes = useStyles();

  const { loading, error, data, refetch } = useGraphQLQuery(familyFileQuery, {
    variables: { familyFileId },
  });
  const { externalId, fetchError } = useExternalId(familyFileId);

  if (loading) return <Loading height="calc(100vh - 197px)" />;
  if (error) return `Error! ${error.message}`;

  const {
    overview,
    medicalBackground,
    contactInformation,
    financialCriteria,
    tourDetails,
    notes,
    communityPreferences,
    isSelfLead,
  } = toFamilyFileInformation(data.findFamilyFileById);

  const currentFamilyFile = getCurrentFamilyFile(
    contactInformation,
    communityPreferences,
  );

  const contacts = toContacts(
    data.findFamilyFileById.contacts,
    data.findFamilyFileById.residents,
    data.findFamilyFileById.leadData,
    familyFileId,
  );

  const handleRefetch = () => {
    refetch({ familyFileId });
  };

  const includeTourDetails = tourDetails.length > 0;

  return (
    <div className={classes.mobile_container}>
      {includeTourDetails ? (
        <div className={classes.tours_info_container}>
          <Toast type="informative" onClose={() => {}}>
            <div>
              <Typography>Tours Scheduled:</Typography>
              {tourDetails.map((tour, index) => (
                <Typography key={index} bold>
                  {tour.scheduledTourDate}, {tour.communityName}
                </Typography>
              ))}
              <YglLink
                className={classes.working_list_link}
                url="YGL_WORKING_LIST_PAGE_URL"
                externalId={externalId}
                hasError={fetchError}
                type="link"
              >
                {labels.GO_TO_WORKING_LIST}
              </YglLink>
            </div>
          </Toast>
        </div>
      ) : (
        <div></div>
      )}

      <div className={classes.lead_link_container}>
        <YglLink
          url="YGL_CASE_PAGE_URL"
          externalId={externalId}
          hasError={fetchError}
          type="link"
        >
          {labels.OPEN_LEAD_SUMMARY_IN_YGL}
          <ExternalLink />
        </YglLink>
      </div>
      {flags.showDuplicatesFamilyFiles && (
        <DuplicateFamilyFile
          labels={labels}
          params={currentFamilyFile}
          externalId={externalId}
          familyFileId={familyFileId}
        />
      )}
      <Overview labels={labels} overviewData={overview} notes={notes} />
      <ContactInformation
        labels={labels}
        contactInformationData={contacts}
        handleRefetch={handleRefetch}
      />
      <MedicalBackgroundDesktop
        labels={labels}
        medicalBackgroundData={medicalBackground}
        enableReferralAlertValidation={false}
        onCancelEdit={() => refetch()}
        isSelfLead={isSelfLead}
        showSidePanel
      />
      <FinancialCriteria
        labels={labels}
        financialCriteriaData={financialCriteria}
        onCancelEdit={() => refetch()}
      />
      <CommunityPreferences
        labels={labels}
        communityPreferencesData={communityPreferences}
      />
    </div>
  );
};

FamilyFileMobile.propTypes = {
  labels: PropTypes.object.isRequired,
  familyFileId: PropTypes.number.isRequired,
  flags: PropTypes.shape({
    showDuplicatesFamilyFiles: PropTypes.bool,
  }),
};

FamilyFileMobile.defaultProps = {};

export default withLDConsumer()(FamilyFileMobile);
