import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => ({
  location_main_container: {
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  location_child_container: {
    display: 'inline-flex;',
    marginTop: '32px',
    '& .search_box': {
      marginTop: '4px',
      display: 'unset',
    },
  },
  no_partner_script: {
    marginTop: '20px',
  },
  location_place_container: {
    width: '50%',
    paddingRight: '25px',
  },
  distance_container: {
    width: '30%',
  },
  where_looking_text: {
    fontSize: '16px',
  },
  distance_text: {
    fontStyle: 'italic',
    margin: '10px 0',
  },
}));

export { useStyles };
