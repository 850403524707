import React from 'react';
import PropTypes from 'prop-types';

const Dollar = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6667 8C14.6667 11.6819 11.6819 14.6667 8 14.6667C4.3181 14.6667 1.33333 11.6819 1.33333 8C1.33333 4.3181 4.3181 1.33333 8 1.33333C11.6819 1.33333 14.6667 4.3181 14.6667 8ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM10.6667 9.73333C10.6667 10.7637 9.74901 11.6253 8.53333 11.8235V12.4052C8.53333 12.7 8.29479 12.9385 8 12.9385C7.70521 12.9385 7.46667 12.7 7.46667 12.4052V11.8235C6.25099 11.6253 5.33333 10.7637 5.33333 9.73333C5.33333 9.43854 5.57188 9.2 5.86667 9.2C6.16146 9.2 6.4 9.43854 6.4 9.73333C6.4 10.1869 6.85351 10.5816 7.46667 10.7322V8.6235C6.25099 8.42532 5.33333 7.56373 5.33333 6.53333C5.33333 5.50293 6.25099 4.64135 7.46667 4.44316V3.86667C7.46667 3.57188 7.70521 3.33333 8 3.33333C8.29479 3.33333 8.53333 3.57188 8.53333 3.86667V4.44316C9.74901 4.64135 10.6667 5.50293 10.6667 6.53333C10.6667 6.82812 10.4281 7.06667 10.1333 7.06667C9.83854 7.06667 9.6 6.82812 9.6 6.53333C9.6 6.07975 9.14649 5.68508 8.53333 5.53451V7.64316C9.74901 7.84132 10.6667 8.7029 10.6667 9.73333ZM6.39998 6.53327C6.39998 6.07969 6.85349 5.68502 7.46664 5.53445V7.5321C6.85349 7.38152 6.39998 6.98686 6.39998 6.53327ZM9.59998 9.73337C9.59998 10.1869 9.14646 10.5816 8.53331 10.7322V8.73454C9.14646 8.88512 9.59998 9.27978 9.59998 9.73337Z"
      />
    </svg>
  );
};

Dollar.propTypes = {
  className: PropTypes.string,
};

export default Dollar;
