import React, { createContext, useContext } from 'react';

const UserContext = createContext({});

const withUser = (WrappedComponent) => {
  const ComponentWithUser = (props) => {
    const currentUser = useContext(UserContext);
    return <WrappedComponent {...props} currentUser={currentUser} />;
  };
  return ComponentWithUser;
};

export default UserContext;
export { withUser };
