/*
  Implements Amplitude (https://amplitude.com/) functionality.
*/
import amplitude from 'amplitude-js';

import withConfig from '../utils/withConfig';

export const initializeAmplitudeService = (userId) => {
  const apiKey = withConfig('AMPLITUDE_API_KEY');

  if (process.env.REACT_APP_ENVIRONMENT !== 'prod') {
    console.log(`Amplitude API key: ${apiKey}`);
  }

  amplitude.getInstance().init(apiKey, userId);
};

export const logEvent = (eventName, context = {}) => {
  context.platform = 'beacon';
  amplitude.getInstance().logEvent(eventName, context);
};
