import React from 'react';
import PropTypes from 'prop-types';

const PinNo = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M12.0001 1C10.0791 1 8.28178 1.57577 6.80616 2.59187L8.25143 4.03714C9.33845 3.37159 10.6262 3 12.0001 3C15.836 3 19.0001 5.8966 19.0001 10C19.0001 11.123 18.6418 12.3601 17.9203 13.706L19.3888 15.1745C20.4604 13.346 21.0001 11.624 21.0001 10C21.0001 4.75066 16.903 1 12.0001 1ZM4.12569 5.53983L1.29297 2.70711L2.70718 1.29289L22.7072 21.2929L21.293 22.7071L17.0178 18.4319C15.8281 19.8154 14.3738 21.2549 12.6578 22.7533L12.0001 23.3276L11.3423 22.7533C5.81138 17.9237 3.00008 13.7068 3.00008 10C3.00008 8.33957 3.41 6.82909 4.12569 5.53983ZM15.599 17.0131L5.61691 7.03104C5.22101 7.91564 5.00008 8.91569 5.00008 10C5.00008 12.8492 7.30669 16.4335 12.0001 20.6634C13.4163 19.387 14.6152 18.1695 15.599 17.0131Z"
      />
    </svg>
  );
};

PinNo.propTypes = {
  className: PropTypes.string,
};

export default PinNo;
