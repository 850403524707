import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    link: {
      '& h1, h2, h3, h4, p, div': {
        color: theme.palette.neutral.cosmicCobalt3,
        textDecoration: 'underline',
      },
    },
    disabled: {
      cursor: 'inherit',
      color: `${theme.palette.dark.disabled} !important`,
      '& h1, h2, h3, h4, p, div': {
        color: theme.palette.dark.disabled,
        textDecoration: 'underline',
      },
    },
  };
});

export { useStyles };
