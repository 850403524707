import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  not_supported_msg_container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.light.platinum5,
    height: '404px',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 0px 8px rgba(186, 186, 184, 0.3)',
    borderRadius: '4px',
    '& svg': {
      fontSize: '96px',
    },
  },
  title: {
    marginTop: '17px',
  },
  text: {
    marginTop: '13px',
  },
}));

export { useStyles };
