import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Button from 'common/Button/Button';
import CalendarDates from 'common/Icons/time/CalendarDates';
import CallPhone from 'common/Icons/call/CallPhone';
import Cross from 'common/Icons/various/Cross';
import Divider from 'common/Divider/Divider';
import Pin from 'common/Icons/maps/Pin';
import Send from 'common/Icons/basic/Send';
import Tag from 'common/Tag/Tag';
import Modal from 'common/Modal/Modal';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import StaticDatePicker from 'common/DatePicker/StaticDatePicker';
import Toast from 'common/Toast/Toast';
import Typography from 'common/Typography/Typography';
import Radio from 'common/Radio/Radio';
import { format } from 'common/utils/dateFormat';
import times from 'components/SavedCommunities/times';
import {
  availableTourTypes,
  timeToDisplayFormat,
} from 'services/tourScheduleService';
import {
  ACTION_BUTTON_TYPE,
  ACTION_TYPE,
  CT_APP,
  IN_PERSON,
  STATUS_QUESTION_OPTION,
  UPDATE_TYPE_QUESTION_OPTION,
  VIRTUAL_TOUR,
} from 'services/tourScheduleService';

import { useStyles } from './TourActivity.style';

const YES = 'yes';
const NO = 'no';

const getTimeButtonType = (isTimeEnabled, isSelectedTime) => {
  if (!isTimeEnabled) return 'disabled';
  if (isSelectedTime) return 'primary';
  return 'outlined';
};

const TourActivity = ({
  community,
  configuration,
  isCtAppEnabled,
  isOpen,
  labels,
  onClose,
  onSave,
  tourInfo,
}) => {
  const classes = useStyles();

  //#region Hooks

  const [selectedDetailsLabel, setSelectedDetailsLabel] = useState('');

  const [schedulingQuestion, setSchedulingQuestion] = useState(
    configuration.schedulingQuestion,
  );
  const [statusQuestion, setStatusQuestion] = useState(
    configuration.statusQuestion,
  );
  const [updateTypeQuestion, setUpdateTypeQuestion] = useState(
    configuration.updateTypeQuestion,
  );
  const [updateQuestion, setUpdateQuestion] = useState(
    configuration.updateQuestion,
  );
  const [actionButtonType, setActionButtonType] = useState(
    configuration.action ?? ACTION_BUTTON_TYPE.SAVE_AND_CLOSE,
  );
  const [showReferralAlertMessage, setShowReferralAlertMessage] = useState(
    configuration.allowReferralAlertMessage,
  );

  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [selectedTourType, setSelectedTourType] = useState();

  const [areTourTypeButtonsEnabled, setAreTourTypeButtonsEnabled] = useState(
    false,
  );
  const [isDatePickerEnabled, setIsDatePickerEnabled] = useState(false);
  const [isTimeEnabled, setIsTimeEnabled] = useState(false);
  const [areFutureDatesEnabled, setAreFutureDatesEnabled] = useState(false);
  const [arePastDatesEnabled, setArePastDatesEnabled] = useState(false);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  const setTourState = () => {
    const selectedDate = tourInfo?.selectedDate
      ? new Date(tourInfo.selectedDate)
      : new Date();
    setSelectedDate(selectedDate);
    setSelectedTime(tourInfo?.selectedTime);
    setSelectedTourType(tourInfo?.selectedType);
  };

  useEffect(() => {
    setSchedulingQuestion(configuration.schedulingQuestion);
    setStatusQuestion(configuration.statusQuestion);
    setUpdateTypeQuestion(configuration.updateTypeQuestion);
    setUpdateQuestion(configuration.updateQuestion);

    setTourState();
    setIsSaveButtonEnabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration, isOpen]);

  useEffect(() => {
    setTourState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let label = '';
    if (selectedDate) {
      label = format(selectedDate, 'MMMM DD, YYYY (DDD)');
    }
    if (selectedTime) {
      label = label + ' @ ' + timeToDisplayFormat(selectedTime);
    }
    if (selectedTourType) {
      label = label + ' - ' + availableTourTypes[selectedTourType];
    }
    setSelectedDetailsLabel(label);
  }, [selectedDate, selectedTime, selectedTourType]);

  useEffect(() => {
    if (!schedulingQuestion.show) return;

    if (schedulingQuestion.value === YES) {
      setStatusQuestion((statusQuestion) => {
        return {
          ...statusQuestion,
          isNoEnabled: false,
          value: STATUS_QUESTION_OPTION.SCHEDULED,
        };
      });
    } else if (schedulingQuestion.value === NO) {
      setStatusQuestion((statusQuestion) => {
        return {
          ...statusQuestion,
          isNoEnabled: true,
          value: STATUS_QUESTION_OPTION.SCHEDULED,
        };
      });
    }
  }, [schedulingQuestion]);

  useEffect(() => {
    if (!statusQuestion.show) return;

    if (statusQuestion.value === STATUS_QUESTION_OPTION.SCHEDULED) {
      setIsTimeEnabled(true);
    } else {
      setIsTimeEnabled(false);
      setSelectedTime();
    }
  }, [statusQuestion]);

  useEffect(() => {
    if (!schedulingQuestion.show) return;

    setAreTourTypeButtonsEnabled(true);
    setIsDatePickerEnabled(true);

    if (schedulingQuestion.value === YES) {
      setAreFutureDatesEnabled(true);
      setArePastDatesEnabled(false);
    } else {
      if (statusQuestion.show) {
        if (statusQuestion.value === STATUS_QUESTION_OPTION.SCHEDULED) {
          setAreFutureDatesEnabled(true);
          setArePastDatesEnabled(false);
        } else {
          setAreFutureDatesEnabled(false);
          setArePastDatesEnabled(true);
        }
      }
    }
  }, [schedulingQuestion, statusQuestion]);

  useEffect(() => {
    if (!updateTypeQuestion.show) return;

    switch (updateTypeQuestion.value) {
      case UPDATE_TYPE_QUESTION_OPTION.COMPLETE:
        processQuestionTypeCompleteUpdate();
        break;

      case UPDATE_TYPE_QUESTION_OPTION.CANCEL:
        processQuestionTypeCancelUpdate();
        break;

      case UPDATE_TYPE_QUESTION_OPTION.RESCHEDULE:
        processQuestionTypeRescheduleUpdate();
        break;

      default:
        setAreTourTypeButtonsEnabled(false);
        setIsDatePickerEnabled(false);
        setIsTimeEnabled(false);
        setShowReferralAlertMessage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTypeQuestion]);

  //#endregion Hooks

  const processQuestionTypeCompleteUpdate = () => {
    if (updateQuestion.isAllowed) {
      setUpdateQuestion((updateQuestion) => {
        return { ...updateQuestion, show: false };
      });
    }
    setSelectedDate(new Date());
    setSelectedTime('');
    setAreTourTypeButtonsEnabled(true);
    setIsDatePickerEnabled(true);
    setAreFutureDatesEnabled(false);
    setArePastDatesEnabled(true);
    setIsTimeEnabled(false);
    if (configuration.allowReferralAlertMessage) {
      setShowReferralAlertMessage(true);
    }
    setActionButtonType(ACTION_BUTTON_TYPE.SAVE_AND_CLOSE);
  };

  const processQuestionTypeCancelUpdate = () => {
    if (updateQuestion.isAllowed) {
      setUpdateQuestion((updateQuestion) => {
        return { ...updateQuestion, show: true, value: YES };
      });
      setActionButtonType(ACTION_BUTTON_TYPE.SAVE_AND_REFER);
    }
    setAreTourTypeButtonsEnabled(false);
    setIsDatePickerEnabled(false);
    setIsTimeEnabled(false);
    if (configuration.allowReferralAlertMessage) {
      setShowReferralAlertMessage(true);
    }
  };

  const processQuestionTypeRescheduleUpdate = () => {
    if (updateQuestion.isAllowed) {
      setUpdateQuestion((updateQuestion) => {
        return { ...updateQuestion, show: true, value: YES };
      });
      setActionButtonType(ACTION_BUTTON_TYPE.SAVE_AND_REFER);
    }
    setSelectedDate(null);
    setSelectedTime(null);
    setAreTourTypeButtonsEnabled(true);
    setIsDatePickerEnabled(true);
    setAreFutureDatesEnabled(true);
    setArePastDatesEnabled(false);
    setIsTimeEnabled(true);
    if (configuration.allowReferralAlertMessage) {
      setShowReferralAlertMessage(true);
    }
  };

  const isActionButtonEnabled = () => {
    if (statusQuestion.show) {
      if (isTimeEnabled) {
        return selectedDate && selectedTime && selectedTourType;
      }
      return selectedDate && selectedTourType;
    }
    if (updateTypeQuestion.show) {
      if (updateTypeQuestion.value === UPDATE_TYPE_QUESTION_OPTION.COMPLETE) {
        return selectedDate && selectedTourType;
      }
      if (updateTypeQuestion.value === UPDATE_TYPE_QUESTION_OPTION.CANCEL) {
        return true;
      }
      if (updateTypeQuestion.value === UPDATE_TYPE_QUESTION_OPTION.RESCHEDULE) {
        return selectedDate && selectedTime && selectedTourType;
      }
    }
    return false;
  };

  const isCompleteCancelRescheduleQuestionShown = () => {
    return updateTypeQuestion.show;
  };

  const isUpdateOrRecordingQuestionShown = () => {
    return updateQuestion.show;
  };

  const determineAction = () => {
    if (schedulingQuestion.show) {
      if (schedulingQuestion.value === NO) {
        return statusQuestion.value === STATUS_QUESTION_OPTION.SCHEDULED
          ? ACTION_TYPE.RECORD_SCHEDULED
          : ACTION_TYPE.RECORD_COMPLETED;
      }
      return statusQuestion.value === STATUS_QUESTION_OPTION.SCHEDULED
        ? ACTION_TYPE.DRAFT_SCHEDULED
        : ACTION_TYPE.DRAFT_COMPLETED;
    }

    if (isCompleteCancelRescheduleQuestionShown()) {
      if (updateTypeQuestion.value === UPDATE_TYPE_QUESTION_OPTION.COMPLETE) {
        return ACTION_TYPE.COMPLETE;
      }

      if (isUpdateOrRecordingQuestionShown()) {
        if (updateTypeQuestion.value === UPDATE_TYPE_QUESTION_OPTION.CANCEL) {
          return ACTION_TYPE.CANCEL;
        }
        if (
          updateTypeQuestion.value === UPDATE_TYPE_QUESTION_OPTION.RESCHEDULE
        ) {
          return ACTION_TYPE.RESCHEDULE;
        }
        throw new Error(`Unable to determine action`);
      }

      if (updateTypeQuestion.value === UPDATE_TYPE_QUESTION_OPTION.CANCEL) {
        return ACTION_TYPE.DRAFT_CANCEL;
      }

      if (updateTypeQuestion.value === UPDATE_TYPE_QUESTION_OPTION.RESCHEDULE) {
        return ACTION_TYPE.DRAFT_SCHEDULED;
      }
    }

    throw new Error(`Unable to determine action`);
  };

  //#region Handlers

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    setIsSaveButtonEnabled(false);
    const action = determineAction();

    onSave(
      selectedDate,
      selectedTime,
      selectedTourType,
      action,
      actionButtonType,
    );
  };

  const handleTimeClick = (time) => {
    setSelectedTime(time);
  };

  //#endregion Handlers

  //#region Render Fragments

  const ReferralAlertInformationMessage = () => {
    return (
      <>
        <Typography level="body" className={classes.message_header}>
          {labels.MAKE_SURE_YOU_FOLLOWUP}
        </Typography>
        <Toast type="warning">
          {statusQuestion.value === STATUS_QUESTION_OPTION.COMPLETED ||
          updateTypeQuestion.value === UPDATE_TYPE_QUESTION_OPTION.COMPLETE ? (
            <Typography>{labels.TOUR_ACTIVITY_MESSAGE_COMPLETED}</Typography>
          ) : (
            <Typography>{labels.TOUR_ACTIVITY_MESSAGE}</Typography>
          )}
        </Toast>
      </>
    );
  };

  const TourTypeButtons = () => {
    const getButtonType = (tourType) => {
      if (!areTourTypeButtonsEnabled) {
        return 'disabled';
      }
      return selectedTourType === tourType ? 'primary' : 'outlined';
    };
    const getCtAppButtonType = () => {
      if (!areTourTypeButtonsEnabled || !isCtAppEnabled) {
        return 'disabled';
      }
      return selectedTourType === CT_APP ? 'primary' : 'outlined';
    };
    return (
      <>
        <Button
          className={clsx(
            classes.type_btn,
            selectedTourType === IN_PERSON && classes.active,
          )}
          type={getButtonType(IN_PERSON)}
          size="small"
          onClick={() => setSelectedTourType(IN_PERSON)}
        >
          {labels.IN_PERSON_TOUR}
        </Button>
        {!isCtAppEnabled ? (
          <SmartTooltip
            title={labels.COMMUNITY_DOES_NOT_USE_CT_APP}
            placement="top"
          >
            <Button
              className={clsx(
                classes.type_btn,
                selectedTourType === CT_APP && classes.active,
              )}
              type={getCtAppButtonType(isCtAppEnabled)}
              size="small"
              onClick={() => setSelectedTourType(CT_APP)}
            >
              {labels.CT_APP}
            </Button>
          </SmartTooltip>
        ) : (
          <Button
            className={clsx(
              classes.type_btn,
              selectedTourType === CT_APP && classes.active,
            )}
            type={getCtAppButtonType(isCtAppEnabled)}
            size="small"
            onClick={() => setSelectedTourType(CT_APP)}
          >
            {labels.CT_APP}
          </Button>
        )}
        <Button
          className={clsx(
            classes.type_btn,
            selectedTourType === VIRTUAL_TOUR && classes.active,
          )}
          type={getButtonType(VIRTUAL_TOUR)}
          size="small"
          onClick={() => setSelectedTourType(VIRTUAL_TOUR)}
        >
          {labels.VIRTUAL_TOURS_OTHER}
        </Button>
      </>
    );
  };

  const SchedulingQuestion = () => {
    const handleClick = (value) => {
      const item = items.find((item) => {
        return item.value === value;
      });
      if (item.disabled) {
        return;
      }
      setSchedulingQuestion((schedulingQuestion) => {
        return { ...schedulingQuestion, value: item.value };
      });
      setActionButtonType(
        item.value === YES
          ? ACTION_BUTTON_TYPE.SAVE_AND_REFER
          : ACTION_BUTTON_TYPE.SAVE_AND_CLOSE,
      );
    };
    const items = [
      {
        label: labels.YES,
        value: YES,
        disabled: !schedulingQuestion.isYesEnabled,
        tooltip: schedulingQuestion.yesTooltip,
      },
      {
        label: labels.NO_I_AM_RECORDING,
        value: NO,
        disabled: !schedulingQuestion.isNoEnabled,
        tooltip: schedulingQuestion.noTooltip,
      },
    ];
    return (
      <>
        <Typography bold>{labels.TOUR_QUESTION_SCHEDULING}</Typography>
        <Radio
          className={classes.radio_container}
          name="schedulingQuestion"
          items={items}
          onClick={(value) => {
            handleClick(value);
          }}
          selected={{ value: schedulingQuestion.value }}
        />
      </>
    );
  };

  const StatusQuestion = () => {
    const handleClick = (value) => {
      const item = items.find((item) => {
        return item.value === value;
      });
      if (item.disabled) {
        return;
      }
      setStatusQuestion((statusQuestion) => {
        return { ...statusQuestion, value: item.value };
      });
    };
    const items = [
      {
        label: labels.SCHEDULED,
        value: STATUS_QUESTION_OPTION.SCHEDULED,
        disabled: !statusQuestion.isYesEnabled,
        tooltip: statusQuestion.yesTooltip,
      },
      {
        label: labels.COMPLETED,
        value: STATUS_QUESTION_OPTION.COMPLETED,
        disabled: !statusQuestion.isNoEnabled,
        tooltip: statusQuestion.isNoEnabled ? '' : statusQuestion.noTooltip,
      },
    ];
    return (
      <>
        <Typography bold>{labels.TOUR_QUESTION_STATUS}</Typography>
        <Radio
          className={classes.radio_container}
          name="statusQuestion"
          items={items}
          onClick={(value) => {
            handleClick(value);
          }}
          selected={{ value: statusQuestion.value }}
        />
      </>
    );
  };

  const UpdateTypeQuestion = () => {
    const renderTourSummary = () => {
      if (!tourInfo.originalTourButtonLabel) {
        return;
      }
      let parts = tourInfo.originalTourButtonLabel.split(' - ');
      let prefix = parts[1] === labels.IN_PERSON_TOUR ? 'an' : 'a';
      return (
        <Toast type="informative" className={classes.tour_summary}>
          <div>
            {labels.TOUR_SUMMARY_THERE_IS} {prefix}{' '}
            <span className={classes.label_bold}>{parts[1]}</span>{' '}
            {labels.TOUR_SUMMARY_SCHEDULED_ON}{' '}
            <span className={classes.label_bold}>{parts[0]}</span>.
          </div>
        </Toast>
      );
    };
    const handleClick = (value) => {
      const item = items.find((item) => {
        return item.value === value;
      });
      if (item.disabled) {
        return;
      }
      setUpdateTypeQuestion((updateTypeQuestion) => {
        return { ...updateTypeQuestion, value: item.value };
      });
    };
    const items = [
      {
        label: labels.COMPLETE,
        value: UPDATE_TYPE_QUESTION_OPTION.COMPLETE,
        disabled: !updateTypeQuestion.isCompleteEnabled,
      },
      {
        label: labels.CANCEL,
        value: UPDATE_TYPE_QUESTION_OPTION.CANCEL,
      },
      {
        label: labels.RESCHEDULE,
        value: UPDATE_TYPE_QUESTION_OPTION.RESCHEDULE,
      },
    ];
    return (
      <>
        {renderTourSummary()}
        <Typography bold>{labels.TOUR_QUESTION_UPDATE_TYPE}</Typography>
        <Radio
          className={classes.radio_container}
          name="updateTypeQuestion"
          items={items}
          onClick={(value) => {
            handleClick(value);
          }}
          selected={{ value: updateTypeQuestion.value }}
        />
      </>
    );
  };

  const UpdateQuestion = () => {
    const handleClick = (value) => {
      const item = items.find((item) => {
        return item.value === value;
      });
      if (item.disabled) {
        return;
      }
      setUpdateQuestion((updateQuestion) => {
        return { ...updateQuestion, value: item.value };
      });
      setActionButtonType(
        item.value === YES
          ? ACTION_BUTTON_TYPE.SAVE_AND_REFER
          : ACTION_BUTTON_TYPE.SAVE_AND_CLOSE,
      );
    };
    const items = [
      {
        label: labels.YES,
        value: YES,
      },
      {
        label: labels.NO_I_AM_RECORDING,
        value: NO,
      },
    ];
    return (
      <>
        <Typography bold>{labels.TOUR_QUESTION_UPDATE}</Typography>
        <Radio
          className={classes.radio_container}
          name="updateQuestion"
          items={items}
          onClick={(value) => {
            handleClick(value);
          }}
          selected={{ value: updateQuestion.value }}
        />
      </>
    );
  };

  const TimeButton = ({ time }) => {
    return (
      <SmartTooltip
        title={isTimeEnabled ? '' : labels.CANNOT_SCHEDULE_TIMES}
        placement="top"
        cursor="not-allowed"
      >
        <Button
          className={clsx(
            classes.time_btn,
            time.value === selectedTime && classes.active,
          )}
          type={getTimeButtonType(isTimeEnabled, time.value === selectedTime)}
          size="small"
          onClick={() => handleTimeClick(time.value)}
        >
          {time.label}
        </Button>
      </SmartTooltip>
    );
  };
  TimeButton.propTypes = {
    time: PropTypes.shape({
      isMorning: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  };

  //#endregion Render Fragments

  //#region Render

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={labels.TOUR_ACTIVITY}
      icon={<CalendarDates />}
      color="cosmicCobalt2"
      noBodyScroll={true}
      noBodyPadding={true}
      className={classes.modal_container}
    >
      <div className={classes.scheduled_tour_container}>
        <div className={classes.row}>
          <Typography bold level="large">
            {community?.name}
          </Typography>
          {isCtAppEnabled && (
            <Tag
              key="1"
              text={labels.CT_APP_ENABLED}
              color="cosmicCobalt3"
              icon={<Cross />}
              className={classes.tag_margin}
            />
          )}
        </div>
        <div className={clsx(classes.row, classes.address_container)}>
          {community?.address && (
            <div className={classes.column}>
              <Pin className={classes.icon} />
              <Typography>{`${community.address} ${community.city}, ${
                community.state || ''
              } ${community.postalCode || ''}`}</Typography>
            </div>
          )}
          {community?.phone && (
            <div className={classes.column}>
              <CallPhone className={classes.icon} />
              <Typography>{community.phone}</Typography>
            </div>
          )}
        </div>
        <Divider className={classes.divider} color="platinum1" />
        {schedulingQuestion.show && (
          <>
            <div className={clsx(classes.row, classes.question)}>
              <SchedulingQuestion />
            </div>
          </>
        )}
        {statusQuestion.show && (
          <>
            <div className={clsx(classes.row, classes.question)}>
              <StatusQuestion />
            </div>
          </>
        )}
        {updateTypeQuestion.show && (
          <div className={clsx(classes.row, classes.question)}>
            <UpdateTypeQuestion />
          </div>
        )}
        {updateQuestion.show && (
          <div className={clsx(classes.row, classes.question)}>
            <UpdateQuestion />
          </div>
        )}
        <Divider className={classes.divider} color="platinum1" />
        <div className={clsx(classes.row, classes.tour_types)}>
          <TourTypeButtons />
        </div>

        {isDatePickerEnabled && (
          <div className={classes.row}>
            <div className={classes.selected_date}>
              <Typography>
                {selectedDate ? (
                  <>
                    {labels.YOU_HAVE_SELECTED}
                    <b>{selectedDetailsLabel}</b>
                  </>
                ) : (
                  <>{labels.PLEASE_SELECT_A_DATE}</>
                )}
              </Typography>
            </div>
          </div>
        )}

        <div className={clsx(classes.row, classes.date_time_container)}>
          <StaticDatePicker
            disable={!isDatePickerEnabled}
            disableFuture={!areFutureDatesEnabled}
            disablePast={!arePastDatesEnabled}
            futureDatesTooltipTitle={labels.CANNOT_SCHEDULE_IN_THE_FUTURE}
            onChange={(value) => setSelectedDate(value)}
            pastDatesTooltipTitle={labels.CANNOT_SCHEDULE_IN_THE_PAST}
            value={selectedDate}
          />
          <div className={classes.timezone_container}>
            <div className={classes.times_section}>
              <Typography
                color="eerieBlack5"
                level="small"
                className={classes.time_title}
              >
                {labels.MORNING_TIMES}
              </Typography>
              <div className={classes.times_container}>
                {times
                  .filter((timeItem) => timeItem.isMorning)
                  .map((timeItem, key) => (
                    <TimeButton key={key} time={timeItem} />
                  ))}
              </div>
            </div>
            <div className={classes.times_section}>
              <Typography
                color="eerieBlack5"
                level="small"
                className={classes.time_title}
              >
                {labels.AFTERNOON_TIMES}
              </Typography>
              <div className={classes.times_container}>
                {times
                  .filter((timeItem) => !timeItem.isMorning)
                  .map((timeItem, key) => (
                    <TimeButton key={key} time={timeItem} />
                  ))}
              </div>
            </div>
          </div>
        </div>
        {showReferralAlertMessage && (
          <div className={clsx(classes.row, classes.message_container)}>
            <Divider className={classes.divider} color="platinum1" />
            <ReferralAlertInformationMessage />
          </div>
        )}
      </div>

      <Divider className={classes.divider} color="platinum1" />

      <div className={classes.actions_container}>
        <div className={classes.actions}>
          <Button size="small" type="light" onClick={handleClose}>
            {labels.CANCEL}
          </Button>
          {actionButtonType === ACTION_BUTTON_TYPE.SAVE_AND_CLOSE && (
            <Button
              type={
                isActionButtonEnabled() && isSaveButtonEnabled
                  ? 'secondary'
                  : 'disabled'
              }
              size="small"
              onClick={handleSave}
            >
              {labels.SAVE_AND_CLOSE}
            </Button>
          )}
          {actionButtonType === ACTION_BUTTON_TYPE.SAVE_AND_REFER && (
            <Button
              type={
                isActionButtonEnabled() && isSaveButtonEnabled
                  ? 'secondary'
                  : 'disabled'
              }
              size="small"
              onClick={handleSave}
            >
              <Send className={classes.icon} />
              {labels.SAVE_AND_REFER}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );

  //#endregion Render
};

TourActivity.propTypes = {
  community: PropTypes.object.isRequired,
  configuration: PropTypes.object.isRequired,
  isCtAppEnabled: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  labels: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  tourInfo: PropTypes.shape({
    familyCommunityTourDraftId: PropTypes.number,
    familyCommunityTourId: PropTypes.number,
    originalTourButtonLabel: PropTypes.string,
    selectedDate: PropTypes.string,
    selectedTime: PropTypes.string,
    selectedType: PropTypes.string,
    tourButtonLabel: PropTypes.string,
  }),
};

TourActivity.defaultProps = {
  onClose: () => {},
  onSave: () => {},
};

export default TourActivity;
