import gql from 'graphql-tag';

export default gql`
  query($familyFileId: Int) {
    findFamilyFileById(
      id: $familyFileId
      filters: { withoutToursDateRangeLimit: true }
    ) {
      tourDetailsExtended {
        familyCommunityTourId
        communityId
        scheduledTourDate
        tourType
      }
      tourDrafts {
        communityId
        familyCommunityTourDraftId
        activityDate
        tourType
      }
    }
  }
`;
