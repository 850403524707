import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    body_call_end: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    container_call_end: {
      flexGrow: 1,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    call_end: {
      minWidth: '80px',
      minHeight: '80px',
      backgroundColor: theme.palette.neutral.cosmicCobalt5,
      borderRadius: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      marginLeft: 'auto',
      marginRight: 'auto',
      '& svg': {
        color: theme.palette.neutral.cosmicCobalt3,
        fill: theme.palette.neutral.cosmicCobalt3,
        fontSize: '97px',
      },
    },
    error_step: {
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      hyphens: 'out',
    },
  };
});

export { useStyles };
