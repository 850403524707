import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  notification: {
    padding: '9px 12px 9px 16px',
    borderBottom: `solid 1px ${theme.palette.light.platinum1}`,
    '&.unread': {
      backgroundColor: theme.palette.neutral.dropdown,
    },
    '&:hover': {
      backgroundColor: theme.palette.neutral.cosmicCobalt5,
    },
  },
}));

export { useStyles };
