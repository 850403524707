import React from 'react';
import PropTypes from 'prop-types';
import ToDoTasks from './PendingTasks/ToDoTasks';
import { useStyles } from './Activity.style';

const ActivityXL = ({
  labels,
  familyFileId,
  toDoTasks,
  handleRefetchState,
  completedActionMessage,
  moreTasks,
  dataForSegment,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.activity_xl_container}>
      <ToDoTasks
        familyFileId={familyFileId}
        labels={labels}
        toDoTasks={toDoTasks}
        moreTasks={moreTasks}
        shouldRefetchCallback={handleRefetchState}
        completedActionMessage={completedActionMessage}
        dataForSegment={dataForSegment}
        yglLinkType={'button'}
      />
    </div>
  );
};

ActivityXL.propTypes = {
  labels: PropTypes.object.isRequired,
  familyFileId: PropTypes.number.isRequired,
  toDoTasks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      dueDate: PropTypes.number.isRequired,
      actionId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  moreTasks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      dueDate: PropTypes.number.isRequired,
      actionId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  handleRefetchState: PropTypes.func,
  completedActionMessage: PropTypes.string,
  dataForSegment: PropTypes.shape({
    familyFileId: PropTypes.number,
    oneId: PropTypes.string,
  }),
};

export default ActivityXL;
