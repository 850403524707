import React from 'react';
import PropTypes from 'prop-types';

const Studio = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5914 2.10454C23.8172 2.10454 24.0002 1.9215 24.0002 1.69575V0.408796C24.0002 0.183047 23.8173 0 23.5914 0H5.09239C4.86664 0 4.68359 0.183047 4.68359 0.408796V18.9077C4.68359 19.1335 4.86664 19.3165 5.09239 19.3165H23.5913C23.8171 19.3165 24.0001 19.1335 24.0001 18.9077V3.05835C24.0001 2.8326 23.8172 2.64956 23.5913 2.64956C23.3655 2.64956 23.1825 2.8326 23.1825 3.05835V10.0622H21.5591C21.3333 10.0622 21.1503 10.2453 21.1503 10.471C21.1503 10.6968 21.3332 10.8798 21.5591 10.8798H23.1825V18.499H11.503V16.521C11.503 16.2953 11.32 16.1122 11.0942 16.1122C10.8685 16.1122 10.6854 16.2953 10.6854 16.521V18.499H5.50119V0.817592H10.6855V13.5778C10.6855 13.8035 10.8685 13.9866 11.0943 13.9866C11.32 13.9866 11.5031 13.8035 11.5031 13.5778V10.8798H18.7794C19.0052 10.8798 19.1882 10.6968 19.1882 10.471C19.1882 10.2453 19.0052 10.0622 18.7794 10.0622H11.503V0.817592H23.1826V1.69575C23.1826 1.9215 23.3656 2.10454 23.5914 2.10454Z"
        fill="currentColor"
      />
      <path
        d="M3.45368 2.35926L2.10476 0.19275C2.03014 0.0728905 1.89889 0 1.75775 0C1.61656 0 1.48536 0.0728905 1.41073 0.19275L0.0617686 2.35926C-0.0167 2.48531 -0.0206844 2.64398 0.0514092 2.77378C0.123503 2.90357 0.260331 2.9841 0.408783 2.9841H1.34895V4.69354C1.34895 4.91929 1.532 5.10233 1.75775 5.10233C1.9835 5.10233 2.16655 4.91929 2.16655 4.69354V2.9841H3.10672C3.25517 2.9841 3.392 2.90362 3.46409 2.77378C3.53618 2.64393 3.5322 2.48526 3.45368 2.35926ZM1.14486 2.16651L1.7577 1.18223L2.37054 2.16651H1.14486Z"
        fill="currentColor"
      />
      <path
        d="M3.46404 16.5428C3.39195 16.413 3.25512 16.3325 3.10667 16.3325H2.1665V5.78355C2.1665 5.5578 1.98345 5.37476 1.7577 5.37476C1.53195 5.37476 1.34891 5.5578 1.34891 5.78355V16.3324H0.408784C0.260331 16.3324 0.123503 16.4129 0.0514092 16.5428C-0.0206844 16.6726 -0.0167 16.8313 0.0617686 16.9573L1.41069 19.1238C1.48531 19.2436 1.61652 19.3165 1.7577 19.3165C1.89894 19.3165 2.03014 19.2436 2.10472 19.1238L3.45364 16.9573C3.5322 16.8312 3.53618 16.6725 3.46404 16.5428ZM1.75775 18.1343L1.14486 17.1501H2.37055L1.75775 18.1343Z"
        fill="currentColor"
      />
      <path
        d="M23.8075 21.8953L21.641 20.5464C21.5149 20.4679 21.3563 20.464 21.2265 20.536C21.0967 20.6081 21.0162 20.7449 21.0162 20.8934V21.8336H7.6677V20.8934C7.6677 20.7449 7.58721 20.6081 7.45742 20.536C7.32762 20.464 7.169 20.4679 7.04286 20.5464L4.87634 21.8953C4.75648 21.9699 4.68359 22.1011 4.68359 22.2423C4.68359 22.3835 4.75648 22.5147 4.87634 22.5893L7.04286 23.9382C7.10885 23.9793 7.18386 24 7.2589 24C7.3272 24 7.39554 23.9829 7.45737 23.9486C7.58721 23.8765 7.6677 23.7397 7.6677 23.5912V22.6511H21.0161V23.5912C21.0161 23.7397 21.0967 23.8765 21.2264 23.9486C21.2883 23.983 21.3566 24 21.4249 24C21.5 24 21.575 23.9793 21.641 23.9382L23.8075 22.5893C23.9273 22.5147 24.0002 22.3835 24.0002 22.2423C24.0002 22.1011 23.9273 21.9699 23.8075 21.8953ZM6.85011 22.8551L5.86583 22.2423L6.85011 21.6295V22.8551ZM21.8337 22.8551V21.6295L22.8181 22.2423L21.8337 22.8551Z"
        fill="currentColor"
      />
    </svg>
  );
};

Studio.propTypes = {
  className: PropTypes.string,
};

export default Studio;
