import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  transfer_dropdown_container: {
    position: 'relative',
  },
  menu: {
    backgroundColor: theme.palette.light.platinum5,
    position: 'absolute',
    border: `1px solid ${theme.palette.neutral.cosmicCobalt5}`,
    boxShadow: '0px 0px 8px rgba(13, 19, 33, 0.2)',
    borderRadius: '2px',
    width: '240px',
    zIndex: 99,
    right: 0,
  },
  menu_item: {
    display: 'flex',
    alignItems: 'center',
    padding: '9px 0px 9px 47px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.neutral.dropdown,
    },
  },
  group_name: {
    padding: '10px 16px',
  },
  transfer_button: {
    padding: '9px 10px',
    backgroundColor: theme.palette.light.platinum4,
    color: theme.palette.dark.eerieBlack1,
    fontSize: '14px',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    boxShadow: '0px 2px 4px rgba(13, 19, 33, 0.3)',
    borderRadius: '2px',
    '& svg:first-child': {
      marginRight: '10px',
    },
  },
  icon: {
    fontSize: '24px',
    color: theme.palette.dark.eerieBlack1,
  },
  arrow: {
    marginLeft: '14px',
  },
  active: {
    backgroundColor: theme.palette.light.platinum2,
    color: theme.palette.neutral.cosmicCobalt2,
    '& svg': {
      color: theme.palette.neutral.cosmicCobalt2,
    },
  },
  disabled: {
    cursor: 'default',
    '& svg': {
      color: theme.palette.dark.disabled,
      fill: theme.palette.dark.disabled,
    },
  },
}));

export { useStyles };
