import { all } from 'redux-saga/effects';
import { watchAdminCatalogs } from './admin/catalogs/sagas/adminCatalogsSaga';
import { watchScripts } from './admin/scriptBuilder/sagas/scriptBuilderSaga';
import { watchScriptPreview } from './admin/scriptPreview/sagas/scriptPreviewSaga';
import { watchCatalogs } from './callcenter/catalogs/sagas/catalogsSaga';
import { watchFormChiclets } from './callcenter/formChiclets/sagas/formChicletsSaga';
import { watchInquiry } from './callcenter/inquiry/sagas/inquirySaga';
import { watchWarmTransfer } from './callcenter/warmTransfer/sagas/warmTransferSaga';
import { watchTelephony } from './telephony/sagas/telephonySaga';

function* rootSaga() {
  yield all([
    watchCatalogs(),
    watchInquiry(),
    watchFormChiclets(),
    watchScripts(),
    watchAdminCatalogs(),
    watchScriptPreview(),
    watchWarmTransfer(),
    watchTelephony(),
  ]);
}

export default rootSaga;
