import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '4px',
    paddingRight: theme.spacing(2),
  },
  subtitle: {
    width: '100%',
    paddingTop: theme.spacing(1),
  },
}));

export { useStyles };
