import React from 'react';
import PropTypes from 'prop-types';

const PinAddFilled = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23.3276L12.6577 22.7533C18.1887 17.9237 21 13.7068 21 10C21 4.75066 16.9029 1 12 1C7.09705 1 3 4.75066 3 10C3 13.7068 5.81131 17.9237 11.3423 22.7533L12 23.3276ZM11 6V9H8V11H11V14H13V11H16V9H13V6H11Z"
        fill="#4568DC"
      />
    </svg>
  );
};

PinAddFilled.propTypes = {
  className: PropTypes.string,
};

export default PinAddFilled;
