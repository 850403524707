import React, { useEffect, useState } from 'react';
import { useStyles } from './NotesSidePanel.style';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import useGraphQLQuery from '../../hooks/useGraphQLQuery';
import notesQuery from './notesQuery';
import useGraphQLMutation from '../../hooks/useGraphQLMutation';
import addNoteMutation from './addNoteMutation';
import NoNotes from '../../common/Graphics/other/NoNotes';
import Typography from '../../common/Typography/Typography';
import Toast from '../../common/Toast/Toast';
import Loading from '../Loading/Loading';
import NoteCard from './NoteCard';
import TextInput from '../../common/TextInput/TextInput';
import Button from '../../common/Button/Button';
import datetimeService from '../../utils/DateTime/datetime';
import KeyNoteCard from './KeyNoteCard';
import NoteLogService from './NoteLogService';
import { useExternalId } from '../../hooks/useExternalId';

const getUsersFullName = (user) => {
  return user ? `${user.firstName} ${user.lastName}` : '';
};

const getCurrentUser = () => {
  let currentUser = null;
  try {
    currentUser = JSON.parse(localStorage.getItem('CURRENT_USER'));
  } catch (err) {
    currentUser = {};
  }

  return currentUser;
};

const getCurrentUserFullName = () => {
  const user = getCurrentUser();
  return `${user.firstName} ${user.lastName}`;
};

const Notes = ({ familyFileId, isOpen, labels, flags, screenName }) => {
  const classes = useStyles(isOpen);
  const [keyNote, setKeyNote] = useState({});
  const [notes, setNotes] = useState([]);
  const [userNoteText, setUserNoteText] = useState('');
  const [showSavedNoteSuccessfully, setShowSavedNoteSuccessfully] = useState(
    false,
  );
  const [showSavedNoteError, setShowSavedNoteError] = useState(false);
  const [primaryPersonId, setPrimaryPersonId] = useState(0);

  const { data, loading, error, refetch } = useGraphQLQuery(notesQuery, {
    variables: { familyFileId: parseInt(familyFileId) },
  });

  const { fetchedFamilyId } = useExternalId(familyFileId);

  useEffect(() => {
    if (data?.findFamilyFileById?.notes) {
      let leadNotes = data.findFamilyFileById.notes.filter((note) => {
        return note.noteType.noteTypeCode === 'LD';
      });

      leadNotes = leadNotes.map((note) => {
        return {
          text: note.note,
          noteTypeCode: note.noteType.noteTypeCode,
          creatorName: getUsersFullName(note.createdBy),
          createdDate: getUpdatedByDateTime(note.createdAt),
          userIsActive: data?.findFamilyFileById?.user.isActive,
        };
      });
      setNotes(leadNotes);
    }
    const keyNoteData = data?.findFamilyFileById?.keyNote;

    let keyNote = {};

    keyNote.text = keyNoteData?.noteText || labels.NO_KEYNOTE_YET;
    keyNote.updatedByFullName = keyNoteData
      ? getUsersFullName(keyNoteData.updatedBy)
      : getCurrentUserFullName();
    keyNote.keyNoteId = keyNoteData ? keyNoteData.keyNoteId : null;
    keyNote.userIsActive = data?.findFamilyFileById?.user.isActive;
    keyNote.updatedByDateTime = keyNoteData
      ? getUpdatedByDateTime(keyNoteData.updatedAt)
      : null;

    setKeyNote(keyNote);

    if (data?.findFamilyFileById?.contacts) {
      const primaryContact = data.findFamilyFileById.contacts.find(
        (contact) => contact.profileData?.isPrimary,
      );
      if (primaryContact?.profileData?.personId) {
        setPrimaryPersonId(primaryContact.profileData.personId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [saveNote] = useGraphQLMutation(addNoteMutation);

  const onKeyNoteSave = (keyNote) => {
    setKeyNote((data) => ({
      ...data,
      text: keyNote.noteText,
      keyNoteId: keyNote.keyNoteId,
      updatedByDateTime: getUpdatedByDateTime(keyNote.updatedAt),
      updatedBy: getCurrentUser(),
    }));
  };

  const getUpdatedByDateTime = (updatedAtDateTimeNumber) => {
    const timezone = datetimeService.getIANATimeZone();
    const zonedDateTime = datetimeService.getZonedDateTimeFromUTC(
      updatedAtDateTimeNumber,
      timezone,
      'MMM dd, yyyy - h:mm aaaa',
    );
    return zonedDateTime;
  };

  const displayNoteTextInput = () => {
    return (
      <div className={classes.note_text_input_container}>
        {showSavedNoteSuccessfully && (
          <Toast onClose={() => setShowSavedNoteSuccessfully(false)}>
            {labels.NEW_NOTE_SAVED_SUCCESSFULLY}
          </Toast>
        )}
        {showSavedNoteError && (
          <Toast type="error" onClose={() => setShowSavedNoteError(false)}>
            {labels.UNABLE_TO_SAVE_NOTE_DUE_TO_ERROR}
          </Toast>
        )}
        <TextInput
          className={classes.note_input_area}
          rows={3}
          rowsMax={3}
          multiline={true}
          placeholder={labels.ENTER_YOUR_NOTES_HERE}
          value={userNoteText}
          onChange={(value) => {
            setUserNoteText(value);
          }}
        />
        <div className={classes.add_note_button}>
          {userNoteText.trim().length > 0 ? (
            <Button size="small" type="secondary" onClick={handleSaveNote}>
              {labels.ADD_NOTES}
            </Button>
          ) : (
            <Button size="small" type="disabled">
              {labels.ADD_NOTES}
            </Button>
          )}
        </div>
      </div>
    );
  };

  const displayNotes = () => {
    return (
      <div className={classes.body}>
        {flags.addNoteEnabled && displayNoteTextInput()}
        <KeyNoteCard
          familyFileId={familyFileId}
          date={keyNote?.updatedByDateTime}
          labels={labels}
          text={keyNote.text}
          userFullName={keyNote.updatedByFullName}
          userIsActive={keyNote.userIsActive}
          keyNoteId={keyNote.keyNoteId}
          onKeyNoteSave={onKeyNoteSave}
          userId={getCurrentUser().userId}
          screenName={screenName}
          editKeyNote={flags.editKeyNote}
        />
        {notes.length !== 0
          ? notes.map((note, key) => (
              <div key={key}>
                <NoteCard
                  date={note.createdDate}
                  labels={labels}
                  text={note.text}
                  userFullName={note.creatorName}
                  userIsActive={note.userIsActive}
                />
              </div>
            ))
          : showEmptyNotesMessage()}
      </div>
    );
  };

  const showEmptyNotesMessage = () => {
    return (
      <div className={classes.error_container}>
        <div className={classes.error_msg}>
          <NoNotes />
          <Typography level={'large'} bold>
            {labels.NO_NOTES_YET}
          </Typography>
          <Typography>{labels.ALL_YOUR_NOTES_WILL_SHOW_UP}</Typography>
        </div>
      </div>
    );
  };

  const getEventData = (error, type) => {
    return {
      familyFileId: fetchedFamilyId,
      noteType: 'note',
      userId: getCurrentUser().userId,
      id: null,
      error,
      screenName,
      type,
    };
  };

  const handleSaveNote = async () => {
    setShowSavedNoteError(false);

    const utcDateTime = datetimeService.getUTCFromZonedDateTime(
      new Date(),
      null,
    );

    const saveNoteBody = {
      note: userNoteText,
      noteTypeCode: 'LD',
      familyFileId: parseInt(familyFileId),
      personId: primaryPersonId,
      createdAt: utcDateTime,
    };
    try {
      const { data: response } = await saveNote({
        variables: { input: saveNoteBody },
      });

      if (response?.createFamilyFileNote?.noteFamilyFileId) {
        setUserNoteText('');
        setShowSavedNoteSuccessfully(true);
        refetch({ familyFileId: parseInt(familyFileId) });
        NoteLogService.logOnNoteEvent(getEventData(null, 'Note Modified'));
      }
    } catch (exception) {
      setShowSavedNoteError(true);
      NoteLogService.logOnNoteEvent(getEventData(null, 'Note Error Viewed'));
    }
  };

  return (
    <>
      <div className={classes.header}>
        <Typography level={'large'} bold>
          {labels.NOTES}
        </Typography>
      </div>
      {error && <div>Error! ${error.message}</div>}
      {loading && <Loading />}
      {!loading && displayNotes()}
    </>
  );
};

Notes.propTypes = {
  isOpen: PropTypes.bool,
  familyFileId: PropTypes.number,
  onClose: PropTypes.func,
  labels: PropTypes.object,
  incomingNotes: PropTypes.array,
  flags: PropTypes.shape({
    addNoteEnabled: PropTypes.bool,
    editKeyNote: PropTypes.bool,
  }),
  screenName: PropTypes.string,
};

Notes.defaultProps = {
  incomingNotes: [],
  isOpen: false,
  screenName: 'family file',
};

export default withLDConsumer()(Notes);
