import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import Card from 'common/Card/Card';
import User from 'common/Icons/basic/User';
import CallPhone from 'common/Icons/call/CallPhone';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import Tag from 'common/Tag/Tag';
import Typography from 'common/Typography/Typography';
import { withLabels } from 'context/LabelContext';
import { mapAudience } from 'services/callCenter/audienceService';
import inquiryService from 'services/callCenter/inquiryService';
import { selectCatalogs } from 'stateManagement/callcenter/catalogs/reducers/catalogsReducer';
import { formatPhoneWithParenthesis } from 'utils/phoneFormat';
import InfoRow from './InfoRow';
import { inquiryPropType } from './inquiryPropTypes';
import { useStyles } from './SideBarAdmin.style';
import Loading from 'components/Loading/Loading';
import Toast from 'common/Toast/Toast';

const SideBarAdmin = ({ labels, inquiry }) => {
  const classes = useStyles();

  const { catalogs, catalogsLoading, catalogsError } = useSelector(
    selectCatalogs,
  );

  const resident1FullName = inquiryService.getInfo(
    inquiry,
    'resident1FullName',
    labels,
  );
  const contactFullName = inquiryService.getInfo(
    inquiry,
    'contactFullName',
    labels,
  );
  const source = inquiryService.getInfo(inquiry, 'source', labels);
  const subSourceId = inquiryService.getInfo(inquiry, 'subSourceId', labels);
  const submissionUrl = inquiryService.getInfo(
    inquiry,
    'submissionUrl',
    labels,
  );
  const getSubSourceAndURL = () => {
    const tooltipText = `${subSourceId} ${submissionUrl}`;
    return tooltipText;
  };

  const relation = inquiryService.getInfo(
    inquiry,
    'relation',
    labels,
    catalogs?.familyRelationships,
  );

  const audience = mapAudience(inquiry?.audienceKey);

  const ShowSourceLabel = () => {
    return source && subSourceId === '' && submissionUrl === '' ? (
      <Typography
        color={source === 'Unknown' ? 'disabled' : 'eerieBlack2'}
        className={classes.source_info}
        bold
      >
        {source}
      </Typography>
    ) : (
      <SmartTooltip title={getSubSourceAndURL()}>
        <Typography
          color={source === 'Unknown' ? 'disabled' : 'eerieBlack2'}
          className={classes.source_info}
          bold
        >
          {source}
        </Typography>
      </SmartTooltip>
    );
  };

  if (catalogsLoading) return <Loading />;
  if (catalogsError) {
    return (
      <Toast type="error">
        <Typography>The catalogs were not loaded.</Typography>
      </Toast>
    );
  }

  return (
    <>
      <Card
        title={labels.PRIMARY_CONTACT}
        headerColor="cosmicCobalt2"
        data-testid="contact-card"
        icon={<CallPhone />}
      >
        <div className={classes.info_row} data-testid="contact-name">
          <Typography
            bold
            color={contactFullName === 'Unknown' ? 'disabled' : 'eerieBlack2'}
            className={classes.title_contact}
          >
            {contactFullName}
          </Typography>
          <Typography
            level="small"
            color={relation === 'Unknown' ? 'disabled' : 'eerieBlack4'}
            className={classes.title_relation}
          >
            {`(${relation})`}
          </Typography>
        </div>

        <div className={classes.info_row} data-testid="source">
          <InfoRow
            title={labels.PRIMARY_PHONE}
            info={formatPhoneWithParenthesis(
              inquiryService.getInfo(inquiry, 'phone', labels),
            )}
            icon={<CallPhone />}
            testId="phone"
          />

          <Typography
            level="tiny"
            color="eerieBlack5"
            className={classes.label_info}
          >
            {labels.SOURCE}
          </Typography>
          <div className={classes.name_row}>
            <ShowSourceLabel />
          </div>
        </div>

        <InfoRow
          title={labels.EMAIL}
          info={inquiryService.getInfo(inquiry, 'email', labels)}
          testId="email"
        />

        <div className={classes.info_row} data-testid="one-id">
          <InfoRow
            title={labels.INQUIRY_ID}
            info={`${inquiryService.getInfo(inquiry, 'inquiryId', labels)}`}
            testId="email"
            bold
          />

          <InfoRow
            title={labels.ONE_ID}
            info={`${inquiryService.getInfo(inquiry, 'oneId', labels)}`}
            testId="email"
            bold
          />
        </div>
      </Card>

      <Card
        title={labels.RESIDENT_INFORMATION}
        headerColor="cosmicCobalt2"
        data-testid="resident-card"
        icon={<User />}
      >
        <div className={classes.info_row} data-testid="source">
          <Typography
            level="tiny"
            color="eerieBlack5"
            className={classes.label_info}
          >
            {labels.RESIDENT}
          </Typography>
          <Typography
            color={resident1FullName === 'Unknown' ? 'disabled' : 'eerieBlack2'}
            bold
            className={classes.resident_info}
          >
            {resident1FullName}
          </Typography>
        </div>

        <InfoRow
          title={labels.PREFERRED_LOCATIONS}
          info={inquiryService.getInfo(inquiry, 'preferredLocation', labels)}
          testId="preferred-location"
          bold
        />
        {audience && (
          <Tag
            className={classes.audience_tag}
            text={labels[audience.label]}
            color={audience.urgent ? 'smokyTopaz5' : 'cosmicCobalt5'}
            icon={audience.icon}
          />
        )}

        <div className={classes.two_cols}>
          <InfoRow
            title={labels.EXPECTED_MOVE_IN}
            info={inquiryService.getInfo(
              inquiry,
              'expectedMoveIn',
              labels,
              catalogs?.moveDateRanges,
            )}
            testId="expected-movein"
            bold
          />
          <InfoRow
            title={labels.LIKELIHOOD}
            info={inquiryService.getInfo(inquiry, 'likelihood', labels)}
            testId="likelihood"
            bold
          />
        </div>
      </Card>
    </>
  );
};

SideBarAdmin.propTypes = {
  labels: PropTypes.object,
  inquiry: inquiryPropType,
};

export default withLabels(SideBarAdmin);
