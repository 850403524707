import React from 'react';
import Typography from 'common/Typography/Typography';
import { useStyles } from './MetricFooter.style';
import PropTypes from 'prop-types';

const MetricFooter = ({ numberOfTours, totalOfReferredContacts, labels }) => {
  const classes = useStyles();

  return (
    <div className={classes.referred_leads}>
      <Typography level="small" color="eerieBlack4">
        {numberOfTours} {labels.OF} {totalOfReferredContacts}{' '}
        {labels.REFERRED_FAMILY_FILES}
      </Typography>
    </div>
  );
};

MetricFooter.propTypes = {
  numberOfTours: PropTypes.number,
  totalOfReferredContacts: PropTypes.number,
  labels: PropTypes.object,
};

export default MetricFooter;
