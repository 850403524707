import React from 'react';
import PropTypes from 'prop-types';

const EyeNo = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="currentColor"
        d="M2.70713 1.29289L1.29292 2.70711L5.35774 6.77193C4.97908 7.08574 4.60434 7.42275 4.23373 7.78115C3.40451 8.58305 2.65433 9.43916 1.99393 10.2956C1.59543 10.8124 1.3125 11.219 1.15759 11.4612L0.812988 12L1.15759 12.5388C1.3125 12.781 1.59543 13.1876 1.99393 13.7044C2.65433 14.5608 3.40451 15.417 4.23373 16.2189C6.66434 18.5694 9.27247 20 12 20C13.7209 20 15.3943 19.4305 17.0056 18.4198L21.2929 22.7071L22.7071 21.2929L2.70713 1.29289ZM15.5447 16.9588L14.032 15.4462C13.4365 15.7981 12.7418 16 12 16C9.79088 16 8.00002 14.2091 8.00002 12C8.00002 11.2582 8.20195 10.5635 8.55384 9.96803L6.77974 8.19393C6.39235 8.50634 6.0068 8.84872 5.62406 9.21885C4.86986 9.9482 4.18241 10.7327 3.57777 11.5169C3.44718 11.6862 3.32651 11.8478 3.21619 12C3.32651 12.1522 3.44718 12.3138 3.57777 12.4831C4.18241 13.2673 4.86986 14.0518 5.62406 14.7811C7.71751 16.8056 9.89495 18 12 18C13.1682 18 14.3586 17.6322 15.5447 16.9588ZM10.0678 11.482C10.0236 11.6472 10 11.8208 10 12C10 13.1046 10.8955 14 12 14C12.1792 14 12.3528 13.9764 12.5181 13.9323L10.0678 11.482ZM20.0981 15.891L18.6838 14.4767C19.318 13.8356 19.9009 13.1593 20.4223 12.4831C20.5529 12.3138 20.6735 12.1522 20.7839 12C20.6735 11.8478 20.5529 11.6862 20.4223 11.5169C19.8176 10.7327 19.1302 9.9482 18.376 9.21885C16.2825 7.19438 14.1051 6 12 6C11.4777 6 10.951 6.07353 10.4221 6.21502L8.84017 4.63304C9.87262 4.22138 10.9271 4 12 4C14.7276 4 17.3357 5.43062 19.7663 7.78115C20.5955 8.58305 21.3457 9.43916 22.0061 10.2956C22.4046 10.8124 22.6875 11.219 22.8425 11.4612L23.1871 12L22.8425 12.5388C22.6875 12.781 22.4046 13.1876 22.0061 13.7044C21.4349 14.4451 20.7966 15.1856 20.0981 15.891Z"
      />
    </svg>
  );
};

EyeNo.propTypes = {
  className: PropTypes.string,
};

export default EyeNo;
