import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import debounce from 'lodash.debounce';

import { useStyles } from './CommunityForReferral.style';
import Typography from '../../common/Typography/Typography';
import TextInput from '../../common/TextInput/TextInput';
import Checkbox from '../../common/Checkbox/Checkbox';
import Tag from '../../common/Tag/Tag';
import Cross from '../../common/Icons/various/Cross';
import Toast from '../../common/Toast/Toast';

import { isCtAppEnabled } from '../../config/ctAppCommunities';
import tourScheduleService, {
  TOUR_STATUS,
} from '../../services/tourScheduleService';
import TourScheduler, {
  forReferralSchedulerConfiguration,
  forReferralTourUpdateSchedulerConfiguration,
} from '../../components/Community/TourScheduler/TourScheduler';

const CommunityForReferralV2 = ({
  className,
  community,
  dataForAnalytics,
  familyFileId,
  familyFileUserId,
  labels,
  onIncludeTourInformationChange,
  onMessageChange,
  onTourChange,
  tourDetail,
  tourDraft,
}) => {
  const classes = useStyles();

  //#region Hooks

  const [additionalMessage, setAdditionalMessage] = useState('');

  const [includeTourInformation, setIncludeTourInformation] = useState();
  const [
    isIncludeTourInformationVisible,
    setIsIncludeTourInformationVisible,
  ] = useState(false);
  const [
    isIncludeTourInformationEnabled,
    setIsIncludeTourInformationEnabled,
  ] = useState(true);
  const [tourInfo, setTourInfo] = useState();

  useEffect(() => {
    const newTourInfo = tourScheduleService.tourDataToTourInfo(
      community.id,
      tourDetail,
      tourDraft,
    );

    if (tourInfo === undefined) {
      const message = getAdditionalMessage(newTourInfo, true);
      setAdditionalMessage(message);
    }

    setTourInfo(newTourInfo);

    let includeTourInformation =
      community?.referralStatus?.includeTourInformation ?? true;

    if (isTourChanged(newTourInfo)) {
      includeTourInformation =
        newTourInfo.tourButtonLabel === '' ? false : true;
    }
    setIncludeTourInformation(includeTourInformation);

    if (
      tourDetail?.familyCommunityTourId ||
      tourDraft?.familyCommunityTourDraftId
    ) {
      setIsIncludeTourInformationVisible(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community.id, tourDetail, tourDraft]);

  useEffect(() => {
    if (tourInfo) {
      if (isPendingCancellation(tourDraft, tourInfo)) {
        setIncludeTourInformation(false);
        setIsIncludeTourInformationEnabled(false);
      } else {
        setIncludeTourInformation(true);
        setIsIncludeTourInformationEnabled(true);
      }
    } else {
      setIncludeTourInformation(false);
      setIsIncludeTourInformationEnabled(false);
    }
  }, [tourDraft, tourInfo]);

  useEffect(() => {
    if (tourInfo) {
      setAdditionalMessage(community?.referralNote?.text ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community.referralNote.text]);

  useEffect(() => {
    onIncludeTourInformationChange(includeTourInformation, community);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeTourInformation]);

  //#endregion Hooks

  //#region Functions

  const isTourChanged = (tourInfo) => {
    return tourInfo.originalTourButtonLabel !== tourInfo.tourButtonLabel;
  };

  const isPendingCancellation = (tourDraft, tourInfo) => {
    const isPending =
      tourDraft &&
      tourInfo.originalTourButtonLabel &&
      !tourInfo.tourButtonLabel;
    return isPending;
  };

  const removeBoilerplateText = (regex, message) => {
    const matches = message.matchAll(regex);
    let scrubbedMessage = message;

    for (const match of matches) {
      scrubbedMessage = scrubbedMessage.replace(match[0], '');
    }

    return scrubbedMessage;
  };

  const getAdditionalMessage = (tourInfo, isInitialSetting) => {
    let message = community?.referralNote?.text ?? '';

    if (!isInitialSetting) {
      message = removeBoilerplateText(
        /((The In-Person|The Virtual|The CT App).*\n)|(The tour has been rescheduled.*\n.*\n.*\n)/g,
        message,
      );
    }

    if (
      tourInfo.originalTourButtonLabel &&
      tourInfo.originalTourButtonLabel !== tourInfo.tourButtonLabel
    ) {
      if (tourInfo.tourButtonLabel === '') {
        const parts = tourInfo.originalTourButtonLabel.split(' - ');
        message =
          labels.SCHEDULED_TOUR_HAS_BEEN_CANCELED.replace(
            '{tourType}',
            parts[1],
          ).replace('{datetime}', parts[0]) +
          '\n' +
          message;
      } else {
        message =
          labels.SCHEDULED_TOUR_HAS_BEEN_RESCHEDULED.replace(
            '{prevDate}',
            tourInfo.originalTourButtonLabel,
          ).replace('{newDate}', tourInfo.tourButtonLabel) +
          '\n' +
          message;
      }
    }

    return message;
  };

  const getTourSchedulerConfiguration = () => {
    if (
      tourDetail &&
      (tourDetail.currentStatus === TOUR_STATUS.SCHEDULED ||
        tourDetail.currentStatus === TOUR_STATUS.RESCHEDULED)
    ) {
      return forReferralTourUpdateSchedulerConfiguration;
    }
    if (tourDraft) {
      return forReferralSchedulerConfiguration;
    }
    return forReferralSchedulerConfiguration;
  };

  const isScheduleTourButtonVisible = (community) => {
    return !community?.careTypes.homeCare;
  };

  const onMessageChangeDebounced = useCallback(
    debounce((message, community) => onMessageChange(message, community), 500),
    [],
  );

  //#endregion Functions

  //#region Event Handlers

  const handleAdditionalMessageChange = (message) => {
    setAdditionalMessage(message);
    onMessageChangeDebounced(message, community);
  };

  const handleIncludeTourInformationChange = (event) => {
    const isChecked = event.target.checked;
    setIncludeTourInformation(isChecked);
  };

  const handleTourSchedulerChange = (tourInfo) => {
    setIsIncludeTourInformationVisible(true);

    const message = getAdditionalMessage(tourInfo, false);
    setAdditionalMessage(message);
    onMessageChange(message, community);

    if (tourInfo.originalTourButtonLabel !== tourInfo.tourButtonLabel) {
      const isChecked = tourInfo.tourButtonLabel === '' ? false : true;
      setIncludeTourInformation(isChecked);
    }

    setTourInfo(tourInfo);

    onTourChange(tourInfo);
  };

  //#endregion Event Handlers

  //#region Render Fragments

  const ReferralType = ({ community }) => {
    if (
      community.referralStatus.isReferralStarted &&
      community.referralStatus.referralDate
    ) {
      return (
        <Typography
          className={classes.referral_type}
          color="cosmicCobalt3"
          level="small"
          bold
        >
          {labels.REFERRAL_UPDATE}
        </Typography>
      );
    }
    return (
      <Typography
        className={classes.referral_type}
        color="wintergreenDream2"
        level="small"
        bold
      >
        {labels.NEW_REFERRAL}
      </Typography>
    );
  };

  const TourMessage = ({
    isPendingChange,
    originalTourButtonLabel,
    tourButtonLabel,
  }) => {
    const message = useMemo(() => {
      if (!isPendingChange) {
        return;
      }
      if (!originalTourButtonLabel && tourButtonLabel) {
        return `${labels.PENDING_SCHEDULE} ${tourButtonLabel}`;
      }
      if (originalTourButtonLabel && !tourButtonLabel) {
        return `${labels.PENDING_CANCELATION} ${originalTourButtonLabel}`;
      }
      if (originalTourButtonLabel !== tourButtonLabel) {
        return `${labels.PENDING_RESCHEDULE} ${originalTourButtonLabel}`;
      }
      return;
    }, [isPendingChange, originalTourButtonLabel, tourButtonLabel]);

    return (
      <>
        {message && (
          <Toast type="informative" className={classes.tour_change_message}>
            {message}
          </Toast>
        )}
      </>
    );
  };

  TourMessage.propTypes = {
    isPendingChange: PropTypes.bool,
    originalTourButtonLabel: PropTypes.string,
    tourButtonLabel: PropTypes.string,
  };

  //#endregion Render Fragments

  //#region Render

  return (
    <div
      className={clsx(classes.community_for_referral_container, className)}
      data-id={community.id}
    >
      <div className={classes.header}>
        <div className={classes.header_container}>
          <Typography bold>{community.name}</Typography>
          {isCtAppEnabled(community.id) ? (
            <Tag
              key="1"
              text={labels.CT_APP_ENABLED}
              color="cosmicCobalt3"
              icon={<Cross />}
              className={classes.tag_margin}
            />
          ) : (
            <div />
          )}
        </div>
      </div>

      {tourInfo && (
        <TourMessage
          isPendingChange={tourDraft ? true : false}
          originalTourButtonLabel={tourInfo.originalTourButtonLabel}
          tourButtonLabel={tourInfo.tourButtonLabel}
        />
      )}

      <div className={classes.additional_container}>
        <div className={'notes'}>
          <ReferralType community={community} />
          <TextInput
            id={`additional-message-${community.id}`}
            multiline
            maxLength={2500}
            value={additionalMessage}
            rows={3}
            onChange={handleAdditionalMessageChange}
            label={labels.ADDITIONAL_MESSAGE_SPECIFIC_TO_THIS_COMMUNITY}
            className={classes.additional_message}
          />
        </div>

        {isScheduleTourButtonVisible() && (
          <div className={'tour'}>
            {isIncludeTourInformationVisible && (
              <Checkbox
                checked={includeTourInformation}
                className={'include_tour'}
                disabled={!isIncludeTourInformationEnabled}
                label={labels.INCLUDE_TOUR_INFORMATION}
                onChange={handleIncludeTourInformationChange}
              />
            )}

            <TourScheduler
              community={community}
              configuration={getTourSchedulerConfiguration()}
              dataForAnalytics={{
                ...dataForAnalytics,
                familyFileId,
                familyFileUserId,
              }}
              familyFileId={familyFileId}
              isCtAppEnabled={isCtAppEnabled(community.id)}
              isRemoveTourAllowed={false}
              onChange={handleTourSchedulerChange}
              tourDetail={tourDetail}
              tourDraft={tourDraft}
            />
          </div>
        )}
      </div>
    </div>
  );

  //#endregion Render
};

CommunityForReferralV2.propTypes = {
  className: PropTypes.string,
  community: PropTypes.object,
  dataForAnalytics: PropTypes.shape({
    originatingPage: PropTypes.string,
  }),
  familyFileId: PropTypes.number,
  familyFileUserId: PropTypes.number,
  labels: PropTypes.object,
  onMessageChange: PropTypes.func,
  onIncludeTourInformationChange: PropTypes.func,
  onTourChange: PropTypes.func,
  tourDetail: PropTypes.object,
  tourDraft: PropTypes.shape({
    familyCommunityTourDraftId: PropTypes.number.isRequired,
    communityId: PropTypes.number.isRequired,
    activityDate: PropTypes.number.isRequired,
    tourType: PropTypes.string.isRequired,
  }),
};

CommunityForReferralV2.defaults = {
  onIncludeTourInformationChange: () => {},
  onMessageChange: () => {},
  onTourChange: () => {},
};

export default CommunityForReferralV2;
