import {
  cancel,
  put,
  select,
  spawn,
  take,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { logInquiryErrorViewed } from 'services/callCenter/amplitudeEventsService';
import graphqlClient from 'stateManagement/graphqlClient';
import { resetTelephonyState } from 'stateManagement/telephony/actions/telephonyActions';
import {
  getFormChicletsRequest,
  resetFormChicletsState,
} from '../../formChiclets/actions/formChicletsActions';
import { resetWarmTransferState } from '../../warmTransfer/actions/warmTransferActions';
import {
  CANCEL_PENDING_INQUIRIES,
  createInquiryError,
  createInquirySuccess,
  CREATE_INQUIRY_REQUEST,
  findInquiriesByParamsError,
  findInquiriesByParamsSuccess,
  FIND_INQUIRIES_BY_PARAMS_REQUEST,
  getInquiryDetailsError,
  getInquiryDetailsSuccess,
  getInquiryError,
  getInquirySuccess,
  GET_INQUIRY_DETAILS_REQUEST,
  GET_INQUIRY_REQUEST,
  saveIsCallQueuedRequest,
  saveIsDncRequest,
  saveLastContactedDatesRequest,
  updateLatestInquiryId,
  updateInquiry,
} from '../actions/inquiryActions';
import createInquiryMutation from '../graphql/createInquiryMutation';
import findInquiriesByParamsQuery from '../graphql/findInquiriesByParamsQuery';
import findInquiryDetailsInfoQuery from '../graphql/findInquiryDetailsInfoQuery';
import findInquiryForScreeningQuery from '../graphql/findInquiryForScreeningQuery';
import findIsCallQueuedQuery from '../graphql/findIsCallQueuedQuery';
import findNumbersInDncQuery from '../graphql/findNumbersInDncQuery';
import getCallAttemptsQuery from '../graphql/getCallAttemptsQuery';
import { selectLatestInquiryId } from '../reducers/inquiryReducer';

const findInquiry = (inquiryId) => {
  return graphqlClient
    .makeGraphqlRequest({
      query: findInquiryForScreeningQuery,
      operationName: 'findInquiryForScreening',
      variables: {
        inquiryId: parseInt(inquiryId, 10),
      },
    })
    .then((response) => response.data.data.findInquiryForScreening);
};

const findCallDataByInquiry = (inquiryId) => {
  return graphqlClient
    .makeGraphqlRequest({
      query: getCallAttemptsQuery,
      operationName: 'GetCallAttempt',
      variables: {
        input: {
          provider: 'five9',
          inquiryId: parseInt(inquiryId, 10),
        },
      },
    })
    .then((response) => response.data);
};

const findNumbersInDNC = (phoneNumber) => {
  return graphqlClient
    .makeGraphqlRequest({
      query: findNumbersInDncQuery,
      operationName: 'FindNumbersInDnc',
      variables: {
        findNumbersInDncInput: {
          provider: 'five9',
          phoneNumbers: phoneNumber,
        },
      },
    })
    .then((response) => response.data);
};

const findIsQueued = (inquiryId, phoneNumber) => {
  return graphqlClient
    .makeGraphqlRequest({
      query: findIsCallQueuedQuery,
      operationName: 'IsCallQueuedQuery',
      variables: {
        isCallQueuedInput: {
          inquiryId: parseInt(inquiryId, 10),
          phoneNumber,
        },
      },
    })
    .then((response) => response.data);
};

const findInquiryDetails = (inquiryId) => {
  return graphqlClient
    .makeGraphqlRequest({
      query: findInquiryDetailsInfoQuery,
      operationName: 'findInquiryDetailsInfo',
      variables: {
        inquiryId: parseInt(inquiryId, 10),
      },
    })
    .then((response) => response.data.data);
};

const createInquiry = (primaryPhone, dnis) => {
  return graphqlClient
    .makeGraphqlRequest({
      query: createInquiryMutation,
      operationName: 'createInquiry',
      variables: {
        primaryPhone: primaryPhone,
        inquiryMethod: `INBOUND_CALL`,
        dnis: dnis,
      },
    })
    .then((response) => response.data.data.createInquiry);
};

const findInquiriesByParams = (searchCriteria) => {
  let inquiryDateFrom = searchCriteria.inquiryDateRangeFrom;
  let inquiryDateTo = searchCriteria.inquiryDateRangeTo;
  return graphqlClient
    .makeGraphqlRequest({
      query: findInquiriesByParamsQuery,
      operationName: 'findInquiriesByParams',
      variables: {
        inquirySearch: {
          filters: {
            inquiryId:
              searchCriteria.inquiryId !== ''
                ? Number(searchCriteria.inquiryId)
                : null,
            firstName:
              searchCriteria.firstName !== '' ? searchCriteria.firstName : null,
            lastName:
              searchCriteria.lastName !== '' ? searchCriteria.lastName : null,
            familyFileId:
              searchCriteria.familyFileId !== ''
                ? Number(searchCriteria.familyFileId)
                : null,
            phone:
              searchCriteria.phoneNumber && searchCriteria.phoneNumber !== ''
                ? `+1${searchCriteria.phoneNumber}`
                : null,
            email: searchCriteria.email !== '' ? searchCriteria.email : null,
            oneId: searchCriteria.oneId !== '' ? searchCriteria.oneId : null,
            audienceKey:
              searchCriteria.audienceKey !== ''
                ? searchCriteria.audienceKey
                : null,
            status: searchCriteria.status !== '' ? searchCriteria.status : null,
            dateFrom: new Date(
              Date.UTC(
                inquiryDateFrom.getFullYear(),
                inquiryDateFrom.getMonth(),
                inquiryDateFrom.getDate(),
                0,
                0,
                0,
              ),
            ),
            dateTo: new Date(
              Date.UTC(
                inquiryDateTo.getFullYear(),
                inquiryDateTo.getMonth(),
                inquiryDateTo.getDate(),
                23,
                59,
                59,
                999,
              ),
            ),
          },
          params: {
            size: searchCriteria.size,
            page: searchCriteria.page,
          },
        },
      },
    })
    .then((response) => response.data);
};

function* findLastContactedDates(inquiryId) {
  try {
    const lastContactedDates = yield findCallDataByInquiry(inquiryId);
    if (lastContactedDates?.data?.getCallAttempt?.length) {
      yield put(
        saveLastContactedDatesRequest(
          inquiryId,
          lastContactedDates.data.getCallAttempt,
        ),
      );
    }
  } catch (e) {
    // silent error
  }
}

function* findIsDnc(inquiryId, phoneNumber) {
  try {
    const number = phoneNumber.replace('+1', '');
    const isDnc = yield findNumbersInDNC(number);
    yield put(
      saveIsDncRequest(inquiryId, !!isDnc?.data?.findNumbersInDnc?.length),
    );
  } catch (e) {
    // silent error
  }
}

function* findIsCallQueued(inquiryId, phoneNumber) {
  try {
    const number = phoneNumber.replace('+1', '');
    const response = yield findIsQueued(inquiryId, number);
    const isQueued = response?.data?.isCallQueued?.length > 0 ? true : false;
    const listName = response?.data?.isCallQueued[0]?.listName;
    yield put(saveIsCallQueuedRequest(inquiryId, isQueued, listName));
  } catch (e) {
    // silent error
  }
}

function* findInquiriesByParamsSaga(action) {
  try {
    const data = yield findInquiriesByParams(action.payload.searchCriteria);
    const inquiriesData = data?.data?.findInquiriesByParams;

    if (inquiriesData) {
      yield put(findInquiriesByParamsSuccess(inquiriesData));

      const spawnedTasks = [];
      for (let inquiry of inquiriesData?.inquiries) {
        if (!inquiry.lastContactedDates) {
          spawnedTasks.push(
            yield spawn(findLastContactedDates, inquiry.inquiryId),
          );
        }
        if (!inquiry.isQueued) {
          spawnedTasks.push(
            yield spawn(findIsCallQueued, inquiry.inquiryId, inquiry.phone),
          );
        }
        if (!inquiry.isDnc) {
          spawnedTasks.push(
            yield spawn(findIsDnc, inquiry.inquiryId, inquiry.phone),
          );
        }
      }
      yield take(CANCEL_PENDING_INQUIRIES);
      for (let task of spawnedTasks) {
        yield cancel(task);
      }
    }

    if (data.errors) {
      yield put(
        findInquiriesByParamsError({
          message: data.errors[0].message,
        }),
      );
    }
  } catch (error) {
    console.log('owl', error);
    yield put(findInquiriesByParamsError(error));
  }
}

function* getInquirySaga(action) {
  try {
    const latestInquiryId = yield select(selectLatestInquiryId);
    if (
      parseInt(latestInquiryId, 10) !== parseInt(action.payload.inquiryId, 10)
    ) {
      yield put(updateLatestInquiryId(parseInt(action.payload.inquiryId, 10)));

      try {
        const inquiry = yield findInquiry(
          latestInquiryId || action.payload.inquiryId,
        );

        if (inquiry) {
          inquiry.familyFileId = inquiry.InquiryFamilyFile?.[0]?.familyFileId;
          yield put(getInquirySuccess(inquiry));
          if (inquiry.status === 'open') {
            yield put(
              getFormChicletsRequest(action.payload.allowDisabledChiclets),
            );
          }
        } else {
          logInquiryErrorViewed(inquiry?.inquiryId, 'Inquiry not found');
          yield put(getInquiryError({ message: 'Inquiry not found' }));
        }
      } catch (err) {
        logInquiryErrorViewed(latestInquiryId, err.message);
        yield put(getInquiryError(err));
      }
    } else {
      logInquiryErrorViewed(latestInquiryId, 'Inquiry not found');
      yield put(getInquiryError({ message: 'Inquiry not found' }));
    }
  } catch (error) {
    console.error('inquirySaga/getInquirySaga error: ', error);
  }
}

function* createInquirySaga(action) {
  try {
    if (action.payload.primaryPhone) {
      const inquiry = yield createInquiry(
        action.payload.primaryPhone,
        action.payload.dnis,
      );

      if (inquiry) {
        yield put(resetFormChicletsState());
        yield put(resetWarmTransferState());
        yield put(resetTelephonyState());

        yield put(updateInquiry(inquiry));
        yield put(createInquirySuccess(inquiry));
        yield put(updateLatestInquiryId(parseInt(inquiry.inquiryId, 10)));
        yield put(getFormChicletsRequest());
      } else {
        yield put(createInquiryError({ message: 'Inquiry has not created' }));
      }
    }
  } catch (err) {
    yield put(createInquiryError(err));
    console.error('inquirySaga/createInquirySaga error: ', err);
  }
}

function* getInquiryDetailsSaga(action) {
  try {
    const latestInquiryId = yield select(selectLatestInquiryId);
    if (
      parseInt(latestInquiryId, 10) !== parseInt(action.payload.inquiryId, 10)
    ) {
      yield put(updateLatestInquiryId(parseInt(action.payload.inquiryId, 10)));
      try {
        const data = yield findInquiryDetails(
          latestInquiryId || action.payload.inquiryId,
        );
        if (data) {
          yield put(getInquiryDetailsSuccess(data));
        } else {
          yield put(
            getInquiryDetailsError({ message: 'Inquiry details not found' }),
          );
        }
      } catch (err) {
        yield put(getInquiryDetailsError(err));
      }
    } else {
      yield put(getInquiryError({ message: 'Inquiry details not found' }));
    }
  } catch (err) {
    console.error('inquirySaga/getInquiryDetailsSaga error: ', err);
  }
}

function* watchInquiry() {
  yield takeLeading(GET_INQUIRY_REQUEST, getInquirySaga);
  yield takeLeading(CREATE_INQUIRY_REQUEST, createInquirySaga);
  yield takeLatest(FIND_INQUIRIES_BY_PARAMS_REQUEST, findInquiriesByParamsSaga);
  yield takeLeading(GET_INQUIRY_DETAILS_REQUEST, getInquiryDetailsSaga);
}

export {
  watchInquiry,
  getInquirySaga,
  createInquirySaga,
  getInquiryDetailsSaga,
};
