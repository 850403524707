import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './SpecialFeatures.style';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import Typography from 'common/Typography/Typography';
import DropdownPopover from 'common/Dropdown/DropdownPopover/DropdownPopover';

const SpecialFeatures = ({ features }) => {
  const classes = useStyles();
  let preparedFeatures = [];
  const iconGroups = {};

  features.forEach((feature) => {
    if (!feature.groupIcons) preparedFeatures.push(feature);
    if (feature.groupIcons) {
      if (!iconGroups[feature.type]) {
        iconGroups[feature.type] = {};
        iconGroups[feature.type].label = feature.type;
        iconGroups[feature.type].icon = feature.icon;
        iconGroups[feature.type].isGroup = true;
        iconGroups[feature.type].is = feature.is;
        iconGroups[feature.type].isGroup = true;

        iconGroups[feature.type].attributeNames = [];
      }
      iconGroups[feature.type].attributeNames.push(feature.label);
    }
  });

  preparedFeatures = [
    ...preparedFeatures,
    ...Object.keys(iconGroups).map((key) => iconGroups[key]),
  ].map((feature) => {
    return {
      ...feature,
      action: () => {},
      tooltip: feature.isGroup ? (
        feature.attributeNames.map((attributeName, key) => (
          <Typography level="tiny" color="platinum5" key={key}>
            {attributeName}
          </Typography>
        ))
      ) : (
        <Typography level="tiny" color="platinum5">
          {feature.label}
        </Typography>
      ),
    };
  });

  const filtersToShow = preparedFeatures.slice(0, 5);
  const filtersForDropdown = preparedFeatures.slice(5);

  const getCollapsedLabel = () => `+${filtersForDropdown.length} more`;

  return (
    <div className={classes.filter_content}>
      {filtersToShow.map((feature, key) => (
        <div key={key}>
          <SmartTooltip title={feature.tooltip} placement="bottom">
            <div className={classes.amenity_icon}>{feature.icon}</div>
          </SmartTooltip>
        </div>
      ))}
      {filtersForDropdown.length > 0 && (
        <div className={classes.more_container}>
          <DropdownPopover
            items={filtersForDropdown}
            clickableElement={
              <Typography level="small">{getCollapsedLabel()}</Typography>
            }
          />
        </div>
      )}
    </div>
  );
};

SpecialFeatures.propTypes = {
  features: PropTypes.array,
};

export default SpecialFeatures;
