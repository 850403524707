import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideTelephonyIframe } from 'stateManagement/telephony/actions/telephonyActions';
import { selectTelephonyIframeVisibility } from 'stateManagement/telephony/reducers/telephonyReducer';
import IframeDialog from './IframeDialog';

const IframeDialogContainer = ({ children }) => {
  const dispatch = useDispatch();
  const isIframeOpen = useSelector(selectTelephonyIframeVisibility);

  return (
    <Fragment>
      <IframeDialog
        isOpen={isIframeOpen}
        onClose={() => dispatch(hideTelephonyIframe())}
      >
        {children}
      </IframeDialog>
    </Fragment>
  );
};

IframeDialogContainer.propTypes = {
  children: PropTypes.node,
};

IframeDialogContainer.defaultProps = {};

export default IframeDialogContainer;
