import gql from 'graphql-tag';

export default gql`
  query {
    findUser {
      userId
      role
      firstName
      lastName
      email
      username
      workPhone
      thumbnailImageId
    }
  }
`;
