import { makeStyles } from '../../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '19px',
      verticalAlign: 'middle',
      marginTop: '16px',
      '& svg': {
        verticalAlign: 'middle',
        fontSize: '18px',
      },
    },
    prev: {
      backgroundColor: 'transparent',
      border: 'none',
      color: theme.palette.dark.eerieBlack1,
      display: 'inline',
      '& $label': {
        marginLeft: '12px',
      },
      '&:hover': {
        outline: 'none',
        color: theme.palette.neutral.cosmicCobalt3,
        cursor: 'pointer',
        '& label': {
          cursor: 'pointer',
        },
      },
      '&:focus': {
        outline: 'none',
      },
    },
    next: {
      backgroundColor: 'transparent',
      border: 'none',
      color: theme.palette.dark.eerieBlack1,
      display: 'inline',
      '& $label': {
        marginRight: '12px',
      },
      '&:hover': {
        outline: 'none',
        color: theme.palette.neutral.cosmicCobalt3,
        cursor: 'pointer',
        '& label': {
          cursor: 'pointer',
        },
      },
      '&:focus': {
        outline: 'none',
      },
    },
    label: {
      display: 'inline',
      fontFamily: 'Poppins',
      fontSize: '13px',
    },
    pagesContainer: {
      display: 'inline',
    },
    page: {
      backgroundColor: 'transparent',
      width: 'min-content',
      display: 'inline',
      padding: '4px 8px',
      border: `1px solid transparent`,
      fontSize: '13px',
      '&:hover': {
        outline: 'none',
        border: `1px solid ${theme.palette.neutral.cosmicCobalt3}`,
        color: theme.palette.neutral.cosmicCobalt3,
        cursor: 'pointer',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    selected: {
      backgroundColor: theme.palette.neutral.cosmicCobalt3,
      color: theme.palette.light.platinum5,
      margin: '0 4px',
      '&:hover': {
        backgroundColor: theme.palette.neutral.cosmicCobalt3,
        color: theme.palette.light.platinum5,
        cursor: 'default',
      },
    },
    other_pages_container: {
      display: 'inline',
      '& svg': {
        fontSize: '20px',
        color: theme.palette.dark.eerieBlack1,
        fontFamily: 'Poppins',
      },
    },
    other_pages: {
      display: 'inline',
    },
    go_to_page: {
      display: 'inline',
      marginLeft: '44px',
      '& input': {
        height: '19px',
        width: '19px',
        fontSize: '13px',
        lineHeight: '25px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        textAlign: 'center',
        marginLeft: '8px',
        border: `1px solid ${theme.palette.light.platinum1}`,
        '&:focus': {
          outline: 'none',
          border: `1px solid ${theme.palette.neutral.cosmicCobalt3}`,
        },
      },
    },
    go_to_page_button: {
      backgroundColor: 'transparent',
      border: 'none',
      display: 'inline',
      marginLeft: '8px',
      '&:hover': {
        outline: 'none',
        color: theme.palette.neutral.cosmicCobalt3,
        cursor: 'pointer',
        '& label': {
          cursor: 'pointer',
        },
      },
      '&:focus': {
        outline: 'none',
      },
    },
    disabled: {
      color: theme.palette.dark.disabled,
      '&:hover': {
        color: theme.palette.dark.disabled,
        cursor: 'default',
        '& label': {
          cursor: 'default',
        },
      },
    },
  };
});

export { useStyles };
