import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Media from 'common/Media/Media';
import { useStyles } from './ContactsPopup.style';
import ContactsPopupSm from './ContactsPopupSm';
import ContactsPopupXs from './ContactsPopupXs';
import Duplicate from 'common/Icons/basic/Duplicate';
import Modal from 'common/Modal/Modal';
import { selectManualInquiryState } from 'stateManagement/callcenter/formChiclets/reducers/formChicletsReducer';
import useGraphQLMutation from 'hooks/useGraphQLMutation';
import createManualInquiryContactMutation from '../ContactInfo/createManualInquiryContactMutation';
import { updateInquiry as updateInquiryAction } from 'stateManagement/callcenter/inquiry/actions/inquiryActions';

const ContactsPopup = ({
  currentContact,
  inquiry,
  onClose,
  labels,
  contacts,
  action,
  isOpen,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [createManualInquiryContact] = useGraphQLMutation(
    createManualInquiryContactMutation,
  );
  const [matchedContacts, setMatchedContacts] = useState(contacts);
  const [activedButton, setActivedButton] = useState('disabled');
  const manualInquiryState = useSelector(selectManualInquiryState);
  const orderingContactsInquiries = () => {
    let newInquiries = [];
    if (contacts && contacts.length > 0) {
      contacts.map((contact) => {
        newInquiries = contact.inquiries.sort(function (a, b) {
          return b.updatedAt - a.updatedAt;
        });
        contact.lastInquiry = newInquiries[0];
        return contact;
      });
    }
  };

  const changeContactSelection = (e, contact) => {
    contact.checked = e.target.checked;
    setMatchedContacts([...matchedContacts]);
    const CheckedContact = contacts.find((oneContact) => {
      return oneContact.checked === true;
    });
    if (CheckedContact) {
      setActivedButton('secondary');
    } else {
      setActivedButton('disabled');
    }
  };

  const doMerging = async (merge) => {
    let contactsToMerge = [];
    if (merge) {
      contacts.forEach((oneContact) => {
        if (oneContact.checked === true) {
          contactsToMerge.push(oneContact.oneId);
        }
      });
    }
    if (currentContact.oneId || (!currentContact.oneId && merge)) {
      const updatingWithMerging = {
        variables: {
          oneId: currentContact.oneId,
          firstName: currentContact.firstName,
          lastName: currentContact.lastName,
          phone: currentContact.phone,
          email: currentContact.email,
          findMatchedContacts: false,
          contactsToMerge,
          isManualInquiry: manualInquiryState.isManualInquiry,
        },
      };

      const response = await action.action(updatingWithMerging);
      await action.reload(
        {
          firstName: currentContact.firstName,
          lastName: currentContact.lastName,
          phone: currentContact.phone,
          email: currentContact.email,
        },
        {},
        response,
      );
    } else if (!currentContact.oneId && !merge) {
      const manualContact = {
        variables: {
          firstName: currentContact.firstName,
          lastName: currentContact.lastName,
          phone: currentContact.phone,
          email: currentContact.email,
        },
      };
      const response = await createManualInquiryContact(manualContact);
      const newInquiry = cloneDeep(inquiry);
      newInquiry.inquiryContact = {
        ...currentContact,
        oneId: response.data.createManualInquiryContact.oneId,
        inquiryContactId:
          response.data.createManualInquiryContact.inquiryContactId,
      };
      dispatch(updateInquiryAction(newInquiry));
      currentContact.updatedAt =
        response.data.createManualInquiryContact.updatedAt;
    }
    onClose();
  };

  const renderModal = (content, size) => {
    const className = classes[`modal_${size}`];
    return (
      <Modal
        title={labels.CONTACT_DUPLICATE_CHECK}
        isOpen={isOpen}
        color="cosmicCobalt2"
        noBodyScroll={false}
        className={className}
        icon={<Duplicate />}
        showCloseIcon={false}
      >
        {content}
      </Modal>
    );
  };

  orderingContactsInquiries();

  return (
    <Media
      sm={renderModal(
        <ContactsPopupSm
          currentContact={currentContact}
          inquiry={inquiry}
          labels={labels}
          contacts={contacts}
          changeContactSelection={changeContactSelection}
          doMerging={doMerging}
          activedButton={activedButton}
        />,
        'sm',
      )}
      xs={renderModal(
        <ContactsPopupXs
          currentContact={currentContact}
          inquiry={inquiry}
          labels={labels}
          contacts={contacts}
          changeContactSelection={changeContactSelection}
          doMerging={doMerging}
          activedButton={activedButton}
        />,
        'xs',
      )}
    />
  );
};

ContactsPopup.propTypes = {
  currentContact: PropTypes.object,
  inquiry: PropTypes.object,
  onClose: PropTypes.func,
  contacts: PropTypes.array,
  labels: PropTypes.object,
  action: PropTypes.any,
  isOpen: PropTypes.bool,
};
export default ContactsPopup;
