import allCallStates, {
  activeConferenceStates,
} from 'config/callStates.config';
import { cloneDeep } from 'lodash';
import { put, select, takeLeading } from 'redux-saga/effects';
import { telephonyEventReasons } from 'services/callCenter/enums';
import { stepTypes } from 'services/callCenter/fieldsMapper';
import formService from 'services/callCenter/formService';
import warmTransferService, {
  newCompleteWarmTransferStates,
  warmTransferStates,
  warmTransferTypes,
} from 'services/callCenter/warmTransferService';
import graphqlClient from 'stateManagement/graphqlClient';
import {
  TELEPHONY_WARM_TRANSFER_UPDATE,
  updateRedialed,
} from 'stateManagement/telephony/actions/telephonyActions';
import {
  logWarmTransferInitiated,
  logSLANotFound,
  logWarmTransferAddSlaToConferenceRetried,
  logWarmTransferErrorViewed,
  logPingSLA,
  logWarmTransferPingStatusUptate,
} from 'services/callCenter/amplitudeEventsService';
import {
  selectCallState,
  selectHasRedialed,
  selectTelephonyWarmTransfer,
} from 'stateManagement/telephony/reducers/telephonyReducer';
import { updateFormSteps } from '../../formChiclets/actions/formChicletsActions';
import { selectFormChiclets } from '../../formChiclets/reducers/formChicletsReducer';
import { selectInquiry } from '../../inquiry/reducers/inquiryReducer';
import {
  CANCEL_WARM_TRANSFER,
  COMPLETE_WARM_TRANSFER,
  COMPLETE_WARM_TRANSFER_FAMILY_LEFT,
  setCancelWarmTransferError,
  setSlaConnected,
  setSlaDisconnected,
  setWarmTransferCompleted,
  setWarmTransferType,
  SET_CHANGE_SLA_STATE,
  SET_FAMILY_CONNECTED,
  SET_FAMILY_DISCONNECTED,
  SET_SLA_CONNECTED,
  SET_SLA_DISCONNECTED,
  START_WARM_TRANSFER,
  updateSendFamilyFileEvent,
  updateSlaContactAttemptsCount,
  updateWarmTransferAttemptsCount,
  updateWarmTransferState,
} from '../actions/warmTransferActions';
import reAssignFamilyFileMutation from '../graphql/ReAssignFamilyFileMutation';
import {
  selectFindBestSlaAttemptsCount,
  selectShouldSendFamilyFileEvent,
  selectSlaContactAttemptsCount,
  selectWarmTransferAttempt,
  selectWarmTransferState,
  selectWarmTransferType,
} from '../reducers/warmTransferReducer';
import warmTransferFormService from 'services/callCenter/warmTransferFormService';
import formServiceHelper from 'services/callCenter/formServiceHelper';

export const MAX_RETRY_WT_ATTEMPTS = 2;
export const MAX_SLA_ATTEMPTS = 2;

const startedSearchingSla = (warmTransfer, warmTransferState) =>
  warmTransfer &&
  warmTransferState === warmTransferStates.STARTED &&
  warmTransfer.isWarmTransferInitiated &&
  !warmTransfer.isWarmTransferCompleted;

const errorSearchingSla = (warmTransfer, warmTransferState) =>
  warmTransferState === warmTransferStates.SEARCHING && !warmTransfer;

const searchSlaCompleted = (warmTransfer, warmTransferState) =>
  warmTransferState === warmTransferStates.SEARCHING &&
  warmTransfer.isWarmTransferCompleted;

const slaExists = (warmTransferState) =>
  warmTransferState === warmTransferStates.SUCCESS ||
  warmTransferState === warmTransferStates.WAITING_SLA ||
  warmTransferState === warmTransferStates.RETRYING_SLA;

const isSearchingSla = (warmTransferState) =>
  warmTransferState === warmTransferStates.STARTED ||
  warmTransferState === warmTransferStates.SEARCHING;

const getWinnerSlaAgent = (warmTransfer, availableAdvisors) => {
  let winnerSla = {};

  const winningSlaAgentId = warmTransfer?.winningSlaAgentId;
  if (winningSlaAgentId) {
    winnerSla = availableAdvisors.find((a) => a.agentId === winningSlaAgentId);
  }
  return winnerSla;
};

const reAssignFamilyFile = (variables) => {
  return graphqlClient
    .makeGraphqlRequest({
      query: reAssignFamilyFileMutation,
      operationName: 'reAssignFamilyFile',
      variables,
    })
    .then((response) => response.data.data.reAssignFamilyFile);
};

const reassignFF = async (
  warmTransfer,
  availableAdvisors,
  reAssignFamilyFile,
  familyFileId,
  inquiry,
  forceDefault = false,
  isSendFamilyFileEvent,
) => {
  let winnerSlaAgent = {};
  let winningSlaAgentId = warmTransfer?.winningSlaAgentId;
  if (forceDefault) {
    winnerSlaAgent = availableAdvisors.find((a) => a.isAutoAssigned);
    await reAssignFamilyFile({
      familyFileId,
      assignedTo: winnerSlaAgent.userId,
      sendCreatedEvent: !isSendFamilyFileEvent,
      sendReassignedEvent: isSendFamilyFileEvent,
      inquiryId: inquiry.inquiryId,
    });
  } else if (winningSlaAgentId) {
    winnerSlaAgent = availableAdvisors.find(
      (a) => a.agentId === winningSlaAgentId,
    );
    await reAssignFamilyFile({
      familyFileId,
      assignedTo: winnerSlaAgent.userId,
      sendCreatedEvent: !isSendFamilyFileEvent,
      sendReassignedEvent: isSendFamilyFileEvent,
      inquiryId: inquiry.inquiryId,
    });
  } else {
    console.error('no winner sla agent found', {
      winningSlaAgentId,
      availableAdvisors,
    });
  }
};

const handleSuccess = ({
  warmTransfer,
  availableAdvisors,
  formSteps,
  reAssignFamilyFile,
  inquiry,
  familyFileId,
  shouldSendFamilyFileEvent,
  findBestSlaAttempts,
  warmTransferType,
}) => {
  const winner = getWinnerSlaAgent(warmTransfer, availableAdvisors);

  let newFormSteps = warmTransferFormService.updateStepsSlaFound(
    formSteps,
    warmTransferType,
  );
  if (winner.isAutoAssigned) {
    newFormSteps = warmTransferFormService.updateStepsSlaNotFound(formSteps);
    logSLANotFound({
      inquiryId: inquiry.inquiryId,
      familyFileId: inquiry.familyFileId,
      slaEmail: winner?.email,
      leadId: warmTransfer.leadId,
      warmTransferId: warmTransfer.warmTransferId,
      warmTransferAttempt: findBestSlaAttempts,
    });
  }
  reassignFF(
    warmTransfer,
    availableAdvisors,
    reAssignFamilyFile,
    familyFileId,
    inquiry,
    false,
    shouldSendFamilyFileEvent,
  );
  return newFormSteps;
};

function* setSlaConnectedSaga() {
  try {
    const { formSteps } = yield select(selectFormChiclets);
    let newFormSteps = warmTransferFormService.showWarmTransferIntroduction(
      formSteps,
    );
    newFormSteps = warmTransferFormService.disableWarmTransferChiclets(
      newFormSteps,
    );
    yield put(updateWarmTransferState(warmTransferStates.IN_PROGRESS_WITH_SLA));
    yield put(updateFormSteps(newFormSteps));
  } catch (err) {
    console.error('warmTransferSaga/setSlaConnectedSaga error: ', err);
  }
}

function* transferToSla(warmTransfer, availableAdvisors, transferLead) {
  yield put(updateWarmTransferState(warmTransferStates.RETRYING_SLA));

  const winnerSlaAgent = getWinnerSlaAgent(warmTransfer, availableAdvisors);
  warmTransferService.onTransferToSLA(winnerSlaAgent, transferLead);
  yield put(updateWarmTransferAttemptsCount());
}

function* autoAssign(
  formSteps,
  warmTransfer,
  availableAdvisors,
  inquiry,
  shouldSendFamilyFileEvent,
  contextAuth,
  agentInfo,
  completeWarmTransfer,
) {
  let newFormSteps = warmTransferFormService.updateStepsSlaNotFound(formSteps);
  newFormSteps = warmTransferFormService.disableWarmTransferChiclets(
    newFormSteps,
  );
  yield put(updateFormSteps(newFormSteps));
  yield put(updateWarmTransferState(warmTransferStates.SUCCESS_NOT_FOUND));
  reassignFF(
    warmTransfer,
    availableAdvisors,
    reAssignFamilyFile,
    inquiry?.familyFileId,
    inquiry,
    true,
    shouldSendFamilyFileEvent,
  );

  const transferType = yield select(selectWarmTransferType);
  const defaultUser = availableAdvisors.find((a) => a.isAutoAssigned);
  const warmTransferCompleteData = {
    auth: contextAuth,
    userId: parseInt(agentInfo?.id),
    warmTransferProcessId: defaultUser.warmTransferProcessId,
  };
  warmTransferService.onCompleteWarmTransfer(
    newCompleteWarmTransferStates.ERROR_ADVISOR_CONNECTION,
    warmTransferCompleteData,
    completeWarmTransfer,
    transferType,
  );
}

function* setSlaDisconnectedSaga(action) {
  try {
    const { formSteps } = yield select(selectFormChiclets);
    const { inquiry } = yield select(selectInquiry);
    const retryWT = yield select(selectWarmTransferAttempt);
    const slaCount = yield select(selectSlaContactAttemptsCount);
    const warmTransfer = yield select(selectTelephonyWarmTransfer);
    const shouldSendFamilyFileEvent = yield select(
      selectShouldSendFamilyFileEvent,
    );
    const {
      availableAdvisors,
      transferLead,
      contextAuth,
      agentInfo,
      completeWarmTransfer,
    } = action.payload;
    if (retryWT < MAX_RETRY_WT_ATTEMPTS) {
      if (slaCount < MAX_SLA_ATTEMPTS) {
        yield transferToSla(warmTransfer, availableAdvisors, transferLead);
        const findBestSlaAttempts = yield select(
          selectFindBestSlaAttemptsCount,
        );
        const winner = getWinnerSlaAgent(warmTransfer, availableAdvisors);
        logWarmTransferAddSlaToConferenceRetried({
          inquiryId: inquiry.inquiryId,
          familyFileId: inquiry.familyFileId,
          slaEmail: winner?.email,
          leadId: warmTransfer.leadId,
          warmTransferId: warmTransfer.warmTransferId,
          warmTransferAttempt: findBestSlaAttempts,
        });
      }
    } else {
      if (slaCount === MAX_SLA_ATTEMPTS) {
        yield autoAssign(
          formSteps,
          warmTransfer,
          availableAdvisors,
          inquiry,
          shouldSendFamilyFileEvent,
          contextAuth,
          agentInfo,
          completeWarmTransfer,
        );
      } else {
        let newFormSteps = warmTransferFormService.prepareChicletsForRetry(
          formSteps,
        );
        yield put(updateFormSteps(newFormSteps));

        yield put(setWarmTransferType(warmTransferTypes.ANY_SLA));
        yield put(updateWarmTransferState(warmTransferStates.ERROR_SLA));
        const findBestSlaAttempts = yield select(
          selectFindBestSlaAttemptsCount,
        );
        logWarmTransferErrorViewed({
          leadId: warmTransfer.leadId,
          slaEmail: getWinnerSlaAgent(warmTransfer, availableAdvisors)?.email,
          inquiryId: inquiry.inquiryId,
          warmTransferId: warmTransfer.warmTransferId,
          warmTransferAttempt: findBestSlaAttempts,
        });
        yield put(updateSlaContactAttemptsCount());
      }
    }
  } catch (err) {
    console.error('warmTransferSaga/setSlaDisconnectedSaga error: ', err);
  }
}

function* setFamilyConnectedSaga() {
  try {
    const hasRedialed = yield select(selectHasRedialed);
    const { formSteps } = yield select(selectFormChiclets);
    if (hasRedialed && formSteps) {
      let newFormSteps = formService.hideFamilyDisconnectedChiclet(formSteps);
      let disabledTransferToSLAChiclet = formSteps.find(
        (step) =>
          step.disabled === true && step.type === stepTypes.transferSla.key,
      );
      if (disabledTransferToSLAChiclet) {
        newFormSteps = formServiceHelper.enableChicletByStepType(
          newFormSteps,
          stepTypes.transferSla,
        );
        newFormSteps = formServiceHelper.enableChicletByStepType(
          newFormSteps,
          stepTypes.completeWarmTransfer,
        );
        newFormSteps = formServiceHelper.enableChicletByStepType(
          newFormSteps,
          stepTypes.slaMatch,
        );
        yield put(updateFormSteps(newFormSteps));
      }
      yield put(updateRedialed(false));
    }
  } catch (err) {
    console.error('warmTransferSaga/setFamilyConnectedSaga error: ', err);
  }
}

function* setFamilyDisconnectedWarmTransferBeforeSla(actionPayload) {
  try {
    const { inquiry } = yield select(selectInquiry);
    const warmTransfer = yield select(selectTelephonyWarmTransfer);

    const shouldSendFamilyFileEvent = yield select(
      selectShouldSendFamilyFileEvent,
    );

    const {
      availableAdvisors,
      cancelWarmTransfer,
      contextAuth,
      agentInfo,
      completeWarmTransfer,
    } = actionPayload;

    reassignFF(
      warmTransfer,
      availableAdvisors,
      reAssignFamilyFile,
      inquiry?.familyFileId,
      inquiry,
      true,
      shouldSendFamilyFileEvent,
    );
    yield put(updateWarmTransferState(warmTransferStates.CANCEL_SUCCESS));
    cancelWarmTransfer();
    const transferType = yield select(selectWarmTransferType);

    const defaultUser = availableAdvisors.find((a) => a.isAutoAssigned);
    const warmTransferCompleteData = {
      auth: contextAuth,
      userId: parseInt(agentInfo?.id),
      warmTransferProcessId: defaultUser.warmTransferProcessId,
    };
    warmTransferService.onCompleteWarmTransfer(
      newCompleteWarmTransferStates.CANCELLED_FAMILY_ABANDONED_BEFORE_SLA,
      warmTransferCompleteData,
      completeWarmTransfer,
      transferType,
    );
  } catch (err) {
    console.error(
      'warmTransferSaga/setFamilyDisconnectedWarmTransfer error: ',
      err,
    );
  }
}

function* setFamilyDisconnectedSaga(action) {
  try {
    const warmTransferState = yield select(selectWarmTransferState);
    const { formSteps } = yield select(selectFormChiclets);
    const warmTransfer = yield select(selectTelephonyWarmTransfer);
    const {
      availableAdvisors,
      activeConference,
      activeContact,
      cancelWarmTransfer,
      contextAuth,
      agentInfo,
      completeWarmTransfer,
    } = action.payload;

    const winningSlaAgentId = warmTransfer?.winningSlaAgentId;

    if (
      !slaExists(warmTransferState) &&
      isSearchingSla(warmTransferState) &&
      warmTransfer?.warmTransferId
    ) {
      yield setFamilyDisconnectedWarmTransferBeforeSla({
        availableAdvisors,
        activeConference,
        cancelWarmTransfer,
        contextAuth,
        agentInfo,
        completeWarmTransfer,
      });
    } else {
      if (formSteps && winningSlaAgentId) {
        if (
          JSON.stringify(activeConference) === '{}' ||
          activeConference.state !==
            activeConferenceStates.CONFERENCE_PARTICIPANT_TALKING.key
        ) {
          const winner = getWinnerSlaAgent(warmTransfer, availableAdvisors);
          if (!winner.isAutoAssigned) {
            let newFormSteps = warmTransferFormService.disableWarmTransferChiclets(
              formSteps,
              [
                stepTypes.transferSla.key,
                stepTypes.slaMatch.key,
                stepTypes.completeWarmTransfer.key,
              ],
            );

            newFormSteps = warmTransferFormService.showCompleteWTChiclet(
              newFormSteps,
            );
            yield put(updateFormSteps(newFormSteps));
          }
        }
      } else {
        let newFormSteps = warmTransferFormService.disableWarmTransferChiclets(
          formSteps,
          [
            stepTypes.transferSla.key,
            stepTypes.slaMatch.key,
            stepTypes.completeWarmTransfer.key,
          ],
        );

        if (
          activeContact.eventReason ===
          telephonyEventReasons.DISCONNECTED_BY_CALLER
        ) {
          newFormSteps = formService.showFamilyDisconnectedChiclet(
            newFormSteps,
          );
        }
        yield put(updateFormSteps(newFormSteps));
      }
    }
  } catch (err) {
    console.error('warmTransferSaga/setFamilyDisconnectedSaga error: ', err);
  }
}

function* setTelephonyWarmTransferSaga(action) {
  try {
    const {
      agentInfo,
      availableAdvisors,
      completeWarmTransfer,
      contextAuth,
      warmTransfer,
    } = action.payload;

    const { formSteps } = yield select(selectFormChiclets);
    const warmTransferState = yield select(selectWarmTransferState);
    const { inquiry } = yield select(selectInquiry);
    const shouldSendFamilyFileEvent = yield select(
      selectShouldSendFamilyFileEvent,
    );
    const warmTransferType = yield select(selectWarmTransferType);
    if (startedSearchingSla(warmTransfer, warmTransferState)) {
      yield put(updateWarmTransferState(warmTransferStates.SEARCHING));
    } else if (errorSearchingSla(warmTransfer, warmTransferState)) {
      yield put(updateWarmTransferState(warmTransferStates.ERROR));
    } else if (searchSlaCompleted(warmTransfer, warmTransferState)) {
      const findBestSlaAttempts = yield select(selectFindBestSlaAttemptsCount);
      if (warmTransfer.winningSlaAgentId) {
        const winner = getWinnerSlaAgent(warmTransfer, availableAdvisors);
        logWarmTransferInitiated({
          inquiryId: inquiry.inquiryId,
          familyFileId: inquiry.familyFileId,
          slaEmail: winner?.email,
          leadId: warmTransfer.leadId,
          warmTransferId: warmTransfer.warmTransferId,
          warmTransferAttempt: findBestSlaAttempts,
        });

        let newFormSteps = cloneDeep(formSteps);
        if (winner.isAutoAssigned) {
          const defaultUser = availableAdvisors.find((a) => a.isAutoAssigned);
          const warmTransferCompleteData = {
            auth: contextAuth,
            userId: parseInt(agentInfo?.id),
            warmTransferProcessId: defaultUser.warmTransferProcessId,
          };
          const transferType = yield select(selectWarmTransferType);
          warmTransferService.onCompleteWarmTransfer(
            warmTransferService.findAutoAssignedCompleteWarmTransferStatesByResult(
              warmTransfer.warmTransferResult,
            ),
            warmTransferCompleteData,
            completeWarmTransfer,
            transferType,
          );
          newFormSteps = warmTransferFormService.disableWarmTransferChiclets(
            formSteps,
          );
        }

        newFormSteps = handleSuccess({
          warmTransfer,
          availableAdvisors,
          formSteps: newFormSteps,
          reAssignFamilyFile,
          inquiry,
          familyFileId: inquiry?.familyFileId,
          shouldSendFamilyFileEvent,
          findBestSlaAttempts,
          warmTransferType,
        });
        yield put(updateSendFamilyFileEvent());
        yield put(updateFormSteps(newFormSteps));

        if (warmTransferType === warmTransferTypes.REINQUIRY_OR_DUPLICATE) {
          yield put(
            updateWarmTransferState(warmTransferStates.SUCCESS_EXISTING),
          );
        } else {
          yield put(updateWarmTransferState(warmTransferStates.SUCCESS));
        }
      } else {
        let wtState = warmTransferStates.ERROR;
        if (warmTransferType === warmTransferTypes.REINQUIRY_OR_DUPLICATE) {
          wtState = warmTransferStates.EXISTING_NOT_ACCEPTED;
          const steps = warmTransferFormService.showPreviousSlaNotAcceptedStep(
            formSteps,
          );
          yield put(updateFormSteps(steps));
          yield put(setWarmTransferType(warmTransferTypes.ANY_SLA));
        }

        yield put(updateWarmTransferState(wtState));
      }
    }

    if (
      warmTransfer &&
      warmTransfer?.isWarmTransferInitiated &&
      !warmTransfer?.isWarmTransferCompleted
    ) {
      logPingSLA({
        leadId: warmTransfer?.leadId,
        pingRound: yield select(selectSlaContactAttemptsCount),
        slaEmail: warmTransfer?.slaEmail,
        warmTransferAttempt: yield select(selectSlaContactAttemptsCount),
        warmTransferId: warmTransfer?.warmTransferId,
      });

      logWarmTransferPingStatusUptate({
        inquiryId: inquiry?.inquiryId,
        leadId: warmTransfer?.leadId,
        slaEmail: warmTransfer?.lastPingSlaEmail,
        pingRound: yield select(selectSlaContactAttemptsCount),
        pingStatus: warmTransfer?.lastPingSlaStatus,
        warmTransferAttempt: yield select(selectSlaContactAttemptsCount),
        warmTransferId: warmTransfer?.warmTransferId,
      });
    }
  } catch (err) {
    console.error('warmTransferSaga/setTelephonyWarmTransferSaga error: ', err);
  }
}

function* cancelTheWarmTransfer(actionPayload) {
  const {
    agentInfo,
    availableAdvisors,
    cancelWarmTransfer,
    completeWarmTransfer,
    contextAuth,
  } = actionPayload;

  const { inquiry } = yield select(selectInquiry);
  const warmTransfer = yield select(selectTelephonyWarmTransfer);
  const callState = yield select(selectCallState);

  let callStateWT = newCompleteWarmTransferStates.SRC_CANCELLED;
  if (callState === allCallStates.WRAP_UP.key) {
    callStateWT =
      newCompleteWarmTransferStates.CANCELLED_FAMILY_ABANDONED_BEFORE_SLA;
    reassignFF(
      warmTransfer,
      availableAdvisors,
      reAssignFamilyFile,
      inquiry.familyFileId,
      inquiry,
      true,
    );
  }
  yield put(updateWarmTransferState(warmTransferStates.CANCEL_SUCCESS));
  cancelWarmTransfer();
  const transferType = yield select(selectWarmTransferType);
  const defaultUser = availableAdvisors.find((a) => a.isAutoAssigned);
  const warmTransferCompleteData = {
    auth: contextAuth,
    userId: parseInt(agentInfo?.id),
    warmTransferProcessId: defaultUser.warmTransferProcessId,
  };
  warmTransferService.onCompleteWarmTransfer(
    callStateWT,
    warmTransferCompleteData,
    completeWarmTransfer,
    transferType,
  );
}

function* setWarmTransferCancelSaga(action) {
  try {
    const warmTransfer = yield select(selectTelephonyWarmTransfer);

    if (warmTransfer.winningSlaAgentId) {
      yield put(setCancelWarmTransferError(true));
      return;
    }
    yield cancelTheWarmTransfer(action.payload);
  } catch (err) {
    console.error('warmTransferSaga/setWarmTransferCancelSaga error: ', err);
  }
}

function* setCompleteWarmTransferFamilyLeftSaga(action) {
  try {
    const warmTransfer = yield select(selectTelephonyWarmTransfer);
    const {
      availableAdvisors,
      contextAuth,
      agentInfo,
      completeWarmTransfer,
      completeWarmConference,
    } = action.payload;
    completeWarmConference();
    const winnerSlaAgent = getWinnerSlaAgent(warmTransfer, availableAdvisors);
    const transferType = yield select(selectWarmTransferType);
    const warmTransferCompleteData = {
      auth: contextAuth,
      userId: parseInt(agentInfo?.id),
      warmTransferProcessId: winnerSlaAgent.warmTransferProcessId,
    };
    warmTransferService.onCompleteWarmTransfer(
      newCompleteWarmTransferStates.CUSTOMER_ABANDON_DURING_CONFERENCE,
      warmTransferCompleteData,
      completeWarmTransfer,
      transferType,
    );
  } catch (err) {
    console.error(
      'warmTransferSaga/setCompleteWarmTransferFamilyLeftSaga error: ',
      err,
    );
  }
}

function* setCompleteWarmTransferSaga(action) {
  try {
    const warmTransfer = yield select(selectTelephonyWarmTransfer);
    const callState = yield select(selectCallState);
    const {
      activeConference,
      availableAdvisors,
      contextAuth,
      agentInfo,
      completeWarmTransfer,
      completeWarmConference,
    } = action.payload;
    completeWarmConference();
    const winnerSlaAgent = getWinnerSlaAgent(warmTransfer, availableAdvisors);
    const warmTransferCompleteData = {
      auth: contextAuth,
      userId: parseInt(agentInfo?.id),
      warmTransferProcessId: winnerSlaAgent.warmTransferProcessId,
    };
    let completeWarmTransferState = newCompleteWarmTransferStates.SUCCESS;
    if (
      activeConference.eventReason ===
      telephonyEventReasons.DISCONNECTED_BY_CALLER
    ) {
      completeWarmTransferState =
        newCompleteWarmTransferStates.ADVISOR_ABANDON_DURING_CONFERENCE;
    } else if (callState === allCallStates.WRAP_UP.key) {
      completeWarmTransferState =
        newCompleteWarmTransferStates.CUSTOMER_ABANDON_DURING_CONFERENCE;
    }
    const transferType = yield select(selectWarmTransferType);
    warmTransferService.onCompleteWarmTransfer(
      completeWarmTransferState,
      warmTransferCompleteData,
      completeWarmTransfer,
      transferType,
    );
    yield put(setWarmTransferCompleted());
  } catch (err) {
    console.error('warmTransferSaga/setCompleteWarmTransferSaga error: ', err);
  }
}

function* setChangeSlaStateSaga(action) {
  try {
    const {
      activeConference,
      availableAdvisors,
      transferLead,
      contextAuth,
      agentInfo,
      completeWarmTransfer,
    } = action.payload;
    const warmTransferState = yield select(selectWarmTransferState);
    if (slaExists(warmTransferState)) {
      if (
        activeConference.state ===
        activeConferenceStates.CONFERENCE_PARTICIPANT_TALKING.key
      ) {
        yield put(setSlaConnected());
      } else if (
        activeConference.state === activeConferenceStates.FINISHED.key
      ) {
        yield put(
          setSlaDisconnected({
            availableAdvisors,
            transferLead,
            contextAuth,
            agentInfo,
            completeWarmTransfer,
          }),
        );
      }
    }
  } catch (err) {
    console.error('warmTransferSaga/setChangeSlaStateSaga error: ', err);
  }
}

function* startWarmTransferSaga() {
  const { formSteps } = yield select(selectFormChiclets);
  const warmTransferType = yield select(selectWarmTransferType);

  const newFormSteps = warmTransferFormService.showStartingWarmTransferStep(
    formSteps,
    warmTransferType,
  );

  yield put(updateFormSteps(newFormSteps));
}

function* watchWarmTransfer() {
  yield takeLeading(SET_SLA_CONNECTED, setSlaConnectedSaga);
  yield takeLeading(SET_SLA_DISCONNECTED, setSlaDisconnectedSaga);
  yield takeLeading(SET_FAMILY_DISCONNECTED, setFamilyDisconnectedSaga);
  yield takeLeading(SET_FAMILY_CONNECTED, setFamilyConnectedSaga);
  yield takeLeading(
    TELEPHONY_WARM_TRANSFER_UPDATE,
    setTelephonyWarmTransferSaga,
  );
  yield takeLeading(CANCEL_WARM_TRANSFER, setWarmTransferCancelSaga);
  yield takeLeading(
    COMPLETE_WARM_TRANSFER_FAMILY_LEFT,
    setCompleteWarmTransferFamilyLeftSaga,
  );
  yield takeLeading(COMPLETE_WARM_TRANSFER, setCompleteWarmTransferSaga);
  yield takeLeading(SET_CHANGE_SLA_STATE, setChangeSlaStateSaga);

  yield takeLeading(START_WARM_TRANSFER, startWarmTransferSaga);
}

export {
  watchWarmTransfer,
  setSlaConnectedSaga,
  setFamilyConnectedSaga,
  setFamilyDisconnectedSaga,
  setSlaDisconnectedSaga,
  setTelephonyWarmTransferSaga,
  setWarmTransferCancelSaga,
  setCompleteWarmTransferFamilyLeftSaga,
  setCompleteWarmTransferSaga,
  setChangeSlaStateSaga,
  startWarmTransferSaga,
};
