import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { useStyles } from './SmartTooltip.style';
import clsx from 'clsx';

const SmartTooltip = ({
  children,
  title,
  placement,
  isInteractive,
  cursor,
  className,
}) => {
  const classes = useStyles(cursor);

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      title={title}
      placement={placement}
      arrow
      interactive={isInteractive}
      enterTouchDelay={50}
      leaveTouchDelay={2500}
    >
      <div
        className={clsx(
          classes.tooltip_container,
          className,
          'custom_tooltip_container',
        )}
      >
        {children}
      </div>
    </Tooltip>
  );
};

SmartTooltip.propTypes = {
  title: PropTypes.node,
  placement: PropTypes.oneOf(['bottom', 'top', 'left', 'right']),
  isInteractive: PropTypes.bool,
  children: PropTypes.node.isRequired,
  cursor: PropTypes.string,
  className: PropTypes.string,
};

SmartTooltip.defaultProps = {
  title: '',
  className: '',
  cursor: 'pointer',
};

export default SmartTooltip;
