import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => ({
  date_time_picker_modal_container: {
    marginRight: '0px',
  },
  modal_body: {
    padding: '18px',
  },
  timezone_container: {
    padding: '0 34px',
  },
  modal_footer: {
    borderTop: `1px solid ${theme.palette.light.platinum1}`,
    padding: '16px 24px',
    display: 'flex',
    justifyContent: 'flex-end',
    '& :not(:first-child)': {
      marginLeft: '24px',
    },
  },
  date_picker_container: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiTypography-body1, & .MuiTypography-body2': {
      fontFamily: 'Poppins',
    },
    '& .MuiPickersCalendarHeader-switchHeader': {
      marginBottom: theme.spacing(2),
    },
    '& .MuiPickersCalendarHeader-dayLabel': {
      color: theme.palette.dark.eerieBlack1,
      fontSize: 12,
      margin: '0 4px',
    },
    '& .MuiPickersBasePicker-pickerView': {
      padding: theme.spacing(1),
    },
    '& .MuiPickersCalendarHeader-transitionContainer': {
      '& p': {
        color: theme.palette.dark.eerieBlack1,
        fontWeight: 'bold',
        fontSize: 15,
      },
    },
    '& .MuiPickersDay-current': {
      borderBottom: `2px ${theme.palette.neutral.cosmicCobalt3} solid`,
      borderRadius: 'unset',
      '&:hover': {
        borderBottom: 'unset',
        borderRadius: '50%',
      },
    },
    '& .MuiPickersDay-day.MuiPickersDay-current': {
      color: theme.palette.neutral.cosmicCobalt3,
      '& > span > p': {
        fontWeight: 'bold',
      },
    },
    '& .MuiPickersDay-day.MuiPickersDay-current.MuiPickersDay-daySelected': {
      '& > span > p': {
        fontWeight: 'normal',
      },
    },
    '& .MuiPickersDay-day.MuiPickersDay-daySelected': {
      borderRadius: '50%',
      backgroundColor: theme.palette.neutral.cosmicCobalt3,
      color: theme.palette.light.platinum5,
      '&:hover': {
        backgroundColor: theme.palette.neutral.cosmicCobalt3,
        color: theme.palette.light.platinum5,
      },
    },
    '& .MuiPickersDay-day': {
      color: theme.palette.dark.eerieBlack1,
      margin: '0 4px',
      '&:hover': {
        backgroundColor: theme.palette.neutral.cosmicCobalt5,
        color: theme.palette.dark.eerieBlack1,
      },
    },

    '& .Mui-disabled .arrow': {
      color: theme.palette.dark.disabled,
    },
    '& .MuiPickersDay-dayDisabled': {
      color: theme.palette.dark.disabled,
    },
    '& .MuiPickersDay-hidden': {
      opacity: 1,
      color: theme.palette.dark.disabled,
      '&:hover': {
        cursor: 'not-allowed',
      },
    },
  },
  selected_date: {
    textAlign: 'center',
  },
  times_section: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '24px',
  },
  times_container: {
    display: 'flex',
    maxWidth: '680px',
    flexWrap: 'wrap',
    '& > *:not(:last-child)': {
      marginRight: '16px',
    },
  },
  time_btn: {
    width: '110px',
    marginTop: '8px',
    marginBottom: '8px',
    '&.active': {
      backgroundColor: theme.palette.neutral.cosmicCobalt3,
      color: theme.palette.light.platinum5,
    },
  },
}));

export { useStyles };
