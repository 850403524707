import React from 'react';
import PropTypes from 'prop-types';

const AlertOctagon = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M23 16.5563V7.44365L16.5563 1H7.44365L1 7.44365V16.5563L7.44365 23H16.5563L23 16.5563ZM8.27208 3H15.7279L21 8.27208V15.7279L15.7279 21H8.27208L3 15.7279V8.27208L8.27208 3ZM12.0003 17.9983C12.5528 17.9983 13.0007 17.5506 13.0007 16.9983C13.0007 16.4461 12.5528 15.9983 12.0003 15.9983C11.4479 15.9983 11 16.4461 11 16.9983C11 17.5506 11.4479 17.9983 12.0003 17.9983ZM13.0036 5.99835H11.003V13.9983H13.0036V5.99835Z"
      />
    </svg>
  );
};

AlertOctagon.propTypes = {
  className: PropTypes.string,
};

export default AlertOctagon;
