import React from 'react';
import PropTypes from 'prop-types';
import Tag from '../../common/Tag/Tag';
import { useStyles } from './AudienceTag.style';
import { map } from './mapper';
import clsx from 'clsx';

const AudienceTag = ({ audience, labels, className }) => {
  const classes = useStyles();
  const element = map(audience, labels);
  if (!element) {
    return <></>;
  }
  return (
    <Tag
      className={clsx(classes.self_tag, className)}
      color={element.color}
      text={element.label}
      icon={element.icon}
    />
  );
};

AudienceTag.propTypes = {
  audience: PropTypes.string,
  className: PropTypes.string,
  labels: PropTypes.object,
};

AudienceTag.defaultProps = {
  className: '',
};

export default AudienceTag;
