import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { withLabels } from '../../context/LabelContext';
import Card from '../../common/Card/Card';
import ArrowRight from '../../common/Icons/arrow/ArrowRight';
import Loading from '../../components/Loading/Loading';
import { useStyles } from './Home.style';
import { isInYGL } from '../../utils/yglHelper';

const BucketCard = ({ bucketId, name, count, labels }) => {
  const classes = useStyles();
  const history = useHistory();

  let iconClass = 'green_circle';
  switch (name) {
    case labels.NEW_AND_PRE_REFERRED:
      iconClass = 'yellow_circle';
      break;
    case labels.MOVE_INS:
      iconClass = 'amber_circle';
      break;
    case labels.CLOSED:
      iconClass = 'red_circle';
      break;
    default:
      break;
  }

  const handleClick = () => {
    if (isInYGL()) {
      window.open(
        `${window.location.origin}/family-file-list?bucket=${bucketId}`,
        'Beacon',
      );
    } else {
      history.push(`/family-file-list?bucket=${bucketId}`);
    }
  };

  return (
    <Card className={classes.card} title={''}>
      <div onClick={handleClick}>
        <div className={classes.line_title}>
          <div className={classes[iconClass]} />
          <div>{name}</div>
        </div>
        <div className={classes.line_count}>
          {count !== undefined && <div className={classes.number}>{count}</div>}
          {count === undefined && <Loading height={'42'} />}
          <ArrowRight className={classes.arrow} />
        </div>
      </div>
    </Card>
  );
};

BucketCard.propTypes = {
  bucketId: PropTypes.number.isRequired,
  count: PropTypes.string,
  labels: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

export default withLabels(BucketCard);
