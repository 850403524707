import { makeStyles } from '../../Theme/Theme';

const useStyles = makeStyles(() => ({
  table_row_card_container: {
    display: 'flex',
    padding: '16px',
    marginTop: '4px',
  },
}));

export { useStyles };
