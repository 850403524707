import gql from 'graphql-tag';

export default gql`
  mutation($actionId: Int!, $familyFileId: Int, $oneId: String) {
    completeAction(
      actionId: $actionId
      familyFileId: $familyFileId
      oneId: $oneId
    ) {
      actionId
    }
  }
`;
