export default {
  INQUIRY_SEARCH: 'Inquiry Search',
  DESELECT_INQUIRY_IDS: 'Deselect Inquiry ID(s)',
  WE_COULDNT_FIND_WHAT_YOURE_LOOKING_FOR:
    'We couldn´t find what you´re looking for',
  PLEASE_TRY_ADJUSTING_YOUR_SEARCH: 'Please try adjusting your search.',

  INTRODUCTION: 'Introduction',
  DISCLAIMER: 'Disclaimer',
  CONTACT_INFO: 'Contact Info',
  CONFIRM_INTEREST: 'Confirm Interest',
  FAMILY_INFO: 'Family Info',
  MEDICAL_INFO: 'Medical Info',
  LOCATION: 'Location',
  TRANSFER_TO_SLA: 'Transfer to SLA',
  SLA_MATCH: 'SLA Match',
  SLA_INTRODUCTION: 'SLA Introduction',
  CALL_COMPLETED: 'Call Completed',
  NOT_CONFIRMED: 'Not confirmed',
  SRC_HAS_NOT_STARTED: 'Screening process has not started',
  DIAL_OUTCOME: 'Dial Outcome',

  CALLED_CONVERSATION: 'Called Family - Conversation',
  CALLED_NO_CONTACT: 'Called Family - No Contact',
  CALLED_LEFT_VM: 'Called Family - Left VM',
  CALLED_NOT_SEARCHING: 'Called Family - Not Searching',

  ADD_PHONE_TO_DNC: 'Add to Do Not Call List',
  REMOVE_PHONE_FROM_DNC: 'Remove from Do Not Call List',

  ADD_PHONE_CONFIRM:
    'Are you sure you want to add {PHONE} to the Do Not Call list? This means no phone calls will be allowed for this number.',
  REMOVE_PHONE_CONFIRM:
    'Are you sure you want to remove {PHONE} from the Do Not Call list? This means phone calls will be allowed for this number.',

  CLOSE_INQUIRY_REMOVE_FROM_CALL_QUEUE:
    'Remove from Call Queue & Close Inquiry',
  CLOSE_INQUIRY: 'Close Inquiry',
  CLOSE_INQUIRY_REASON: 'Choose a Reason to Close Inquiry',
  REMOVE_CALL_QUEUE_AND_CLOSE_CONFIRM:
    'Are you sure you want to close {NAME} inquiry {INQUIRYID} and remove records from the OB call lists?',
  CLOSE_INQUIRY_CONFIRM:
    'Are you sure you want to close {NAME} inquiry {INQUIRYID}?',
  INQUIRY_CONTACTED_MODAL:
    'Sorry, we can’t close this inquiry at the moment because it is currently being processed. Please try again later.',
};
