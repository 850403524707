import PetFriendly from '../../Icons/amenities/PetFriendly';
import SwimmingPool from '../../Icons/amenities/SwimmingPool';
import WashingMachine from '../../Icons/amenities/WashingMachine';
import Church from '../../Icons/amenities/Church';
import React from 'react';

const getSpecialFeatures = (labels) => {
  return [
    {
      label: labels.PET_FRIENDLY,
      value: 'Accepts Pets',
      group: true,
      icon: <PetFriendly />,
      selected: false,
    },
    {
      label: labels.SWIMMING_POOL,
      group: false,
      value: 'Swimming Pool / Hot Tub',
      icon: <SwimmingPool />,
      selected: false,
    },
    {
      label: labels.WASHER_AND_DRYER,
      group: false,
      value: 'Laundry service / drycleaning',
      icon: <WashingMachine />,
      selected: false,
    },
    {
      label: labels.CHAPEL_CHURCH,
      group: false,
      value: 'Churches',
      icon: <Church />,
      selected: false,
    },
  ];
};
export default getSpecialFeatures;
