export const GET_FORM_CHICLETS_REQUEST = 'formChiclets/get/request';
export const GET_FORM_CHICLETS_SUCCESS = 'formChiclets/get/success';
export const GET_FORM_CHICLETS_ERROR = 'formChiclets/get/error';

export const UPDATE_FORM_STEPS = 'formChiclets/updateFormSteps';
export const ADD_END_CALL_STEPS = 'formChiclets/addEndCallSteps';

export const SAVE_INTERNAL_TRANSFER_CALL =
  'formChiclets/saveInternalTransferCall';
export const COMPLETE_INTERNAL_TRANSFER_CALL =
  'formChiclets/completeInternalTransferCall';

export const UPDATE_DISPOSITION = 'formChiclets/updateDisposition';
export const UPDATE_CLOSE_REASON = 'formChiclets/updateCloseReason';

export const SAVE_FORM_CHICLETS_REQUEST = 'formChiclets/save/request';
export const SAVE_FORM_CHICLETS_SUCCESS = 'formChiclets/save/success';
export const SAVE_FORM_CHICLETS_ERROR = 'formChiclets/save/error';

export const GET_MANUAL_INQUIRY_STATE = 'formChiclets/get/manualInquiryState';

export const SAVE_FORM_CHICLETS_REQUEST_2 = 'formChiclets/save/request2';
export const GET_BASIC_INFO_SCRIPT_REQUEST = 'basicInfoScript/get/request';
export const GET_BASIC_INFO_SCRIPT_SUCCESS = 'basicInfoScript/get/success';
export const GET_BASIC_INFO_SCRIPT_ERROR = 'basicInfoScript/get/error';

export const UPDATE_WARM_TRANSFER = 'formChiclets/updateWarmTransfer';

export const COMPLETE_WARM_TRANSFER_REQUEST = 'formChiclets/completeWT/request';
export const COMPLETE_WARM_TRANSFER_SUCCESS = 'formChiclets/completeWT/success';
export const COMPLETE_WARM_TRANSFER_ERROR = 'formChiclets/completeWT/error';

export const UPDATE_WARM_TRANSFER_POOL_TYPE =
  'formChiclets/updateWarmTransferPoolType';

export const UPDATE_COLLAPSED_STATE = 'formChiclets/updateCollapsedState';

export const RESET_FORM_CHICLETS_STATE = 'formChiclets/resetFormChicletsState';

export const ENTERED_WAITING_SCREEN = 'formChiclets/waitingScreen/entered';

export const getFormChicletsRequest = (allowDisabledChiclets = false) => ({
  type: GET_FORM_CHICLETS_REQUEST,
  payload: { allowDisabledChiclets },
});

export const getFormChicletsSuccess = (response) => {
  return { type: GET_FORM_CHICLETS_SUCCESS, payload: { response } };
};

export const getFormChicletsError = (error) => {
  return { type: GET_FORM_CHICLETS_ERROR, payload: { error } };
};

export const updateFormSteps = (formSteps) => {
  return { type: UPDATE_FORM_STEPS, payload: { formSteps } };
};

export const startTransferCall = (input) => {
  return {
    type: SAVE_INTERNAL_TRANSFER_CALL,
    payload: {
      name: input.name,
      phoneNumber: input.phoneNumber,
      isTransferPending: true,
      isTransferComplete: false,
    },
  };
};

export const completeTransferCall = () => {
  return { type: COMPLETE_INTERNAL_TRANSFER_CALL };
};

export const addEndCallSteps = (endCallSteps) => {
  return { type: ADD_END_CALL_STEPS, payload: { endCallSteps } };
};

export const updateCloseReason = (answer) => {
  return {
    type: UPDATE_CLOSE_REASON,
    payload: {
      closeInquiry: answer.closeInquiry,
      closeReason: answer.closeReason,
      isNoAnswerSelected: answer.isNoAnswerSelected,
      isScheduleCallSelected: answer.isScheduleCallSelected,
      removeFromCampaign: answer.removeFromCampaign,
    },
  };
};

export const updateDisposition = (disposition) => {
  return {
    type: UPDATE_DISPOSITION,
    payload: {
      disposition,
    },
  };
};

export const saveFormChicletsRequest2 = (status, closeReason) => ({
  type: SAVE_FORM_CHICLETS_REQUEST_2,
  payload: { status, closeReason },
});

export const saveFormChicletsRequest = ({
  formToSave,
  status,
  closeReason,
}) => ({
  type: SAVE_FORM_CHICLETS_REQUEST,
  payload: { formToSave, status, closeReason },
});

export const saveFormChicletsSuccess = (response) => {
  return { type: SAVE_FORM_CHICLETS_SUCCESS, payload: { response } };
};

export const saveFormChicletsError = (error) => {
  return { type: SAVE_FORM_CHICLETS_ERROR, payload: { error } };
};

export const getManualInquiryState = (manualInquiryState) => {
  return {
    type: GET_MANUAL_INQUIRY_STATE,
    payload: {
      isManualInquiry: manualInquiryState.isManualInquiry,
      isCreatedManualInquiry: manualInquiryState.isCreatedManualInquiry,
      hasErrors: manualInquiryState.hasErrors,
    },
  };
};

export const getBasicInfoScriptRequest = () => ({
  type: GET_BASIC_INFO_SCRIPT_REQUEST,
});

export const getBasicInfoScriptSuccess = (response) => {
  return { type: GET_BASIC_INFO_SCRIPT_SUCCESS, payload: { response } };
};

export const getBasicInfoScriptError = (error) => {
  return { type: GET_BASIC_INFO_SCRIPT_ERROR, payload: { error } };
};

export const updateWarmTransferPoolType = (poolType) => {
  return {
    type: UPDATE_WARM_TRANSFER_POOL_TYPE,
    payload: { poolType },
  };
};
export const updateCollapsedState = (collapsedState) => {
  return {
    type: UPDATE_COLLAPSED_STATE,
    payload: { collapsedState },
  };
};

export const resetFormChicletsState = () => {
  return { type: RESET_FORM_CHICLETS_STATE };
};

export const enteredWaitingScreen = () => {
  return { type: ENTERED_WAITING_SCREEN };
};
