import { all, put, select, takeLeading } from 'redux-saga/effects';
import formInitializerService from 'services/callCenter/formInitializerService';
import formService from 'services/callCenter/formService';
import graphqlClient from 'stateManagement/graphqlClient';
import {
  resetInquiryState,
  setRedirectToWaitingScreen,
  updateInquiry,
} from '../../inquiry/actions/inquiryActions';
import { selectInquiry } from '../../inquiry/reducers/inquiryReducer';
import {
  ENTERED_WAITING_SCREEN,
  getFormChicletsError,
  getFormChicletsSuccess,
  GET_FORM_CHICLETS_REQUEST,
  resetFormChicletsState,
  saveFormChicletsError,
  saveFormChicletsSuccess,
  SAVE_FORM_CHICLETS_REQUEST,
  SAVE_FORM_CHICLETS_REQUEST_2,
  updateFormSteps,
} from '../actions/formChicletsActions';
import createInquiryFormDataMutation from '../graphql/createInquiryFormDataMutation';
import findScriptByTargetQuery from '../graphql/findScriptByTargetQuery';
import {
  selectFormChiclets,
  selectManualInquiryState,
} from '../reducers/formChicletsReducer';
import { inquiryManualObject } from './inquiryObject';
import { logInquiryErrorViewed } from 'services/callCenter/amplitudeEventsService';
import { resetWarmTransferState } from '../../warmTransfer/actions/warmTransferActions';
import { resetTelephonyState } from 'stateManagement/telephony/actions/telephonyActions';
import warmTransferFormService from 'services/callCenter/warmTransferFormService';

const findScriptByTarget = (target) => {
  return graphqlClient
    .makeGraphqlRequest({
      query: findScriptByTargetQuery,
      operationName: 'findScriptByTarget',
      variables: { target: target || '' },
    })
    .then((response) => response.data.data.findScriptByTarget);
};

const saveFormChiclets = (variables) => {
  return graphqlClient
    .makeGraphqlRequest({
      query: createInquiryFormDataMutation,
      operationName: 'createInquiryFormData',
      variables,
    })
    .then((response) => response);
};

function* getFormChicletsSaga(action) {
  try {
    const manualInquiryState = yield select(selectManualInquiryState);
    let formSteps;
    let formChiclets;

    const { inquiry } = yield select(selectInquiry);

    if (inquiry.familyFileId) {
      formSteps = warmTransferFormService.disableChicletsAfterCreatingFF(
        formSteps,
      );
    }

    let allowDisabledChiclets = false;

    if (manualInquiryState.isManualInquiry) {
      const emptyInquiryContact = {
        email: null,
        firstName: null,
        lastName: null,
        mergedOneId: null,
        oneId: null,
        phone: null,
      };
      yield put(
        updateInquiry({
          ...inquiryManualObject,
          inquiryContact: emptyInquiryContact,
        }),
      );

      formChiclets = yield findScriptByTarget(
        inquiry.inquiryMethod?.toLowerCase(),
      );
    } else {
      formChiclets = yield findScriptByTarget(
        inquiry.audienceKey || inquiry.inquiryMethod?.toLowerCase(),
      );
      allowDisabledChiclets = action.payload.allowDisabledChiclets;
    }

    formSteps = formInitializerService.initializeForm(
      formChiclets,
      inquiry,
      allowDisabledChiclets,
    );

    if (formChiclets) {
      yield all([
        put(getFormChicletsSuccess(formChiclets)),
        put(updateFormSteps(formSteps)),
      ]);
    } else {
      logInquiryErrorViewed(null, 'There is no script to show.');
      console.error('There is no script to show.');
      yield put(
        getFormChicletsError({ message: 'There is no script to show.' }),
      );
    }
  } catch (err) {
    logInquiryErrorViewed(null, err.message);
    console.error('error loading the chiclets', err);
    yield put(getFormChicletsError(err));
  }
}

function* saveFormChicletsSaga(action) {
  try {
    const { formChiclets } = yield select(selectFormChiclets);
    const { inquiry } = yield select(selectInquiry);

    const formSummary = formService.createFormSummary(
      action.payload.formToSave,
    );

    const variables = {
      inquiryId: inquiry.inquiryId,
      slugId: formChiclets.slug,
      versionId: formChiclets.version,
      status: action.payload.status,
      formData: formSummary,
      closeReason: action.payload.closeReason,
    };
    const response = yield saveFormChiclets(variables);
    if (response.data.data) {
      yield put(saveFormChicletsSuccess());
    } else {
      logInquiryErrorViewed(inquiry.inquiryId, response.data.errors[0].message);
      console.error(
        'response error when trying to save chiclets: ',
        response.data.errors,
      );
      yield put(saveFormChicletsError(response.data.errors));
    }
  } catch (err) {
    logInquiryErrorViewed(null, err.message);
    console.error('error when trying to save chiclets: ', err);
    yield put(saveFormChicletsError(err));
  }
}

function* saveFormChicletsSaga2(action) {
  try {
    const { formChiclets, formSteps } = yield select(selectFormChiclets);
    const { inquiry } = yield select(selectInquiry);

    const formSummary = formService.createFormSummary(formSteps);

    const variables = {
      inquiryId: inquiry.inquiryId,
      slugId: formChiclets.slug,
      versionId: formChiclets.version,
      status: action.payload.status,
      formData: formSummary,
      closeReason: action.payload.closeReason,
    };
    const response = yield saveFormChiclets(variables);
    if (response.data.data) {
      yield put(saveFormChicletsSuccess());
    } else {
      logInquiryErrorViewed(inquiry.inquiryId, response.data.errors[0].message);
      console.error(
        'response error when trying to save chiclets: ',
        response.data.errors,
      );
      yield put(saveFormChicletsError(response.data.errors));
    }
  } catch (err) {
    logInquiryErrorViewed(null, err.message);
    console.error('error when trying to save chiclets: ', err);
    yield put(saveFormChicletsError(err));
  }
}

function* enteredWaitingScreenSaga() {
  yield all([
    put(setRedirectToWaitingScreen(false)),
    put(resetInquiryState()),
    put(resetFormChicletsState()),
    put(resetWarmTransferState()),
    put(resetTelephonyState()),
  ]);
}

function* watchFormChiclets() {
  yield takeLeading(GET_FORM_CHICLETS_REQUEST, getFormChicletsSaga);
  yield takeLeading(SAVE_FORM_CHICLETS_REQUEST, saveFormChicletsSaga);
  yield takeLeading(SAVE_FORM_CHICLETS_REQUEST_2, saveFormChicletsSaga2);
  yield takeLeading(ENTERED_WAITING_SCREEN, enteredWaitingScreenSaga);
}

export { watchFormChiclets, getFormChicletsSaga, enteredWaitingScreenSaga };
