import React from 'react';
import PropTypes from 'prop-types';

const OldMan = ({ className }) => {
  return (
    <svg
      className={className}
      width="172"
      height="172"
      viewBox="0 0 172 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip1)">
        <path
          d="M146.232 153.486L133.61 141.989H38.3897L25.7683 153.486C21.4379 157.431 18.1463 162.382 16.1838 167.901C15.4732 169.899 16.9551 171.999 19.0761 171.999H152.924C155.045 171.999 156.527 169.899 155.816 167.901C153.854 162.382 150.562 157.431 146.232 153.486Z"
          fill="url(#om_paint0_linear)"
        />
        <path
          d="M152.926 172H67.5022L59.2037 163.697C58.5535 162.789 57.5074 161.367 56.186 159.659C56.0733 159.592 38.3884 141.99 38.3884 141.99H133.611L146.232 153.486C148.395 155.457 150.304 157.683 151.913 160.105C153.521 162.526 154.834 165.144 155.814 167.903C156.527 169.899 155.044 172 152.926 172Z"
          fill="url(#om_paint1_linear)"
        />
        <path
          d="M146.232 153.486L133.61 141.989H86V171.998H152.924C155.045 171.998 156.527 169.899 155.816 167.901C153.854 162.382 150.562 157.431 146.232 153.486Z"
          fill="url(#om_paint2_linear)"
        />
        <path
          d="M152.926 172H114.63L91.3684 148.934L102.735 141.99H133.611L146.232 153.485C148.395 155.457 150.304 157.683 151.913 160.104C152.034 160.288 152.155 160.475 152.272 160.663C152.297 160.696 152.322 160.734 152.338 160.771C152.388 160.838 152.43 160.905 152.472 160.976C153.83 163.151 154.951 165.473 155.814 167.903C156.527 169.899 155.043 172 152.926 172Z"
          fill="url(#om_paint3_linear)"
        />
        <path
          d="M75.7019 167.428L66.4477 158.174L71.8832 152.738C79.6799 144.942 92.3208 144.942 100.118 152.738L105.553 158.174L96.2988 167.428C90.6106 173.116 81.3894 173.116 75.7019 167.428Z"
          fill="url(#om_paint4_linear)"
        />
        <path
          d="M80.6329 148.935L61.0206 163.963C60.4462 164.404 59.6236 164.284 59.2017 163.696C56.4852 159.909 46.9702 147.164 38.3897 141.989C38.3897 141.989 46.3162 134.764 57.4308 134.764L80.6329 148.935Z"
          fill="url(#om_paint5_linear)"
        />
        <path
          d="M91.3671 148.935L110.979 163.963C111.554 164.404 112.376 164.284 112.798 163.696C115.515 159.909 125.03 147.164 133.61 141.989C133.61 141.989 125.684 134.764 114.569 134.764L91.3671 148.935Z"
          fill="url(#om_paint6_linear)"
        />
        <path
          d="M46.2575 90.859C53.7389 90.859 59.8037 84.7942 59.8037 77.3128C59.8037 69.8315 53.7389 63.7666 46.2575 63.7666C38.7762 63.7666 32.7114 69.8315 32.7114 77.3128C32.7114 84.7942 38.7762 90.859 46.2575 90.859Z"
          fill="url(#om_paint7_linear)"
        />
        <path
          d="M43.7568 89.9559C49.2431 89.9559 53.6907 85.5083 53.6907 80.022C53.6907 74.5357 49.2431 70.0882 43.7568 70.0882C38.2705 70.0882 33.823 74.5357 33.823 80.022C33.823 85.5083 38.2705 89.9559 43.7568 89.9559Z"
          fill="url(#om_paint8_linear)"
        />
        <path
          d="M125.742 90.859C133.224 90.859 139.289 84.7942 139.289 77.3128C139.289 69.8315 133.224 63.7666 125.742 63.7666C118.261 63.7666 112.196 69.8315 112.196 77.3128C112.196 84.7942 118.261 90.859 125.742 90.859Z"
          fill="url(#om_paint9_linear)"
        />
        <path
          d="M128.243 89.9559C133.73 89.9559 138.177 85.5083 138.177 80.022C138.177 74.5357 133.73 70.0882 128.243 70.0882C122.757 70.0882 118.309 74.5357 118.309 80.022C118.309 85.5083 122.757 89.9559 128.243 89.9559Z"
          fill="url(#om_paint10_linear)"
        />
        <path
          d="M112.196 77.3128C112.196 84.7941 118.261 90.859 125.742 90.859C133.224 90.859 139.289 84.7941 139.289 77.3128L125.742 63.7666C118.261 63.767 112.196 69.8315 112.196 77.3128Z"
          fill="url(#om_paint11_linear)"
        />
        <path
          d="M86.6863 133.874H85.3137C62.0876 133.874 43.9433 113.813 46.268 90.7036L49.3111 60.4516C51.1518 42.1511 66.5549 28.2198 84.9474 28.2198H87.0522C105.445 28.2198 120.848 42.1511 122.689 60.4516L125.732 90.7036C128.057 113.813 109.912 133.874 86.6863 133.874Z"
          fill="url(#om_paint12_linear)"
        />
        <path
          d="M65.33 82.6619C68.0155 82.6619 70.1925 79.8472 70.1925 76.3751C70.1925 72.9031 68.0155 70.0884 65.33 70.0884C62.6445 70.0884 60.4674 72.9031 60.4674 76.3751C60.4674 79.8472 62.6445 82.6619 65.33 82.6619Z"
          fill="url(#om_paint13_linear)"
        />
        <path
          d="M106.67 82.6619C109.356 82.6619 111.533 79.8472 111.533 76.3751C111.533 72.9031 109.356 70.0884 106.67 70.0884C103.984 70.0884 101.807 72.9031 101.807 76.3751C101.807 79.8472 103.984 82.6619 106.67 82.6619Z"
          fill="url(#om_paint14_linear)"
        />
        <path
          d="M99.13 98.0149C99.068 98.0527 99.0056 98.0901 98.9428 98.1276C90.9944 102.854 81.0059 102.854 73.0578 98.1276C72.9951 98.0901 72.9327 98.0527 72.8707 98.0149C70.9659 96.8595 68.6722 96.5189 66.5316 97.1371C61.7456 98.5191 53.6323 101.879 48.564 109.527C46.6204 112.46 46.3004 116.177 47.647 119.428C51.6917 129.19 63.1945 150.712 86.0007 150.712C108.807 150.712 120.31 129.19 124.354 119.428C125.701 116.177 125.381 112.46 123.437 109.527C118.369 101.879 110.256 98.5195 105.47 97.1371C103.329 96.5185 101.035 96.8592 99.13 98.0149Z"
          fill="url(#om_paint15_linear)"
        />
        <path
          d="M115.876 101.967C112.116 99.3991 108.236 97.9361 105.469 97.1368C103.328 96.5186 101.035 96.8592 99.13 98.0147C99.068 98.0524 99.0056 98.0899 98.9428 98.127C90.9944 102.854 81.0059 102.854 73.0578 98.127C72.9951 98.0895 72.9327 98.0521 72.8707 98.0147C70.9659 96.8592 68.6722 96.5186 66.5316 97.1368C63.7641 97.9361 59.8847 99.3988 56.1243 101.967C61.8654 110.634 69.8883 114.479 73.7681 115.913C74.4335 116.159 75.1205 116.345 75.826 116.42C77.747 116.622 79.7018 116.172 81.3597 115.104C84.1859 113.284 87.8154 113.284 90.642 115.104C92.2996 116.172 94.2546 116.622 96.1753 116.42C96.8805 116.345 97.5678 116.159 98.2332 115.913C102.112 114.479 110.135 110.634 115.876 101.967Z"
          fill="url(#om_paint16_linear)"
        />
        <path
          d="M99.7549 116.504C99.7573 116.478 99.7583 116.463 99.7583 116.463L99.7526 116.464C99.7094 116.001 99.2639 115.578 98.7014 115.78L98.1779 115.968C95.6684 116.869 92.8827 116.55 90.641 115.107C87.8148 113.286 84.1852 113.286 81.3587 115.107C79.117 116.55 76.3312 116.869 73.8217 115.968L73.2983 115.78C72.6154 115.535 72.1051 116.209 72.2788 116.761C72.5213 118.744 74.4116 130.042 86 130.042H86.0057C97.6488 130.042 99.5017 118.637 99.73 116.733C99.7506 116.658 99.758 116.581 99.7549 116.504Z"
          fill="url(#om_paint17_linear)"
        />
        <path
          d="M86 119.051C81.5722 119.051 77.5319 120.703 74.4551 123.42C76.3265 126.758 79.7523 130.043 86 130.043H86.0057C92.2513 130.043 95.6775 126.761 97.5496 123.424C94.4718 120.704 90.4298 119.051 86 119.051Z"
          fill="url(#om_paint18_linear)"
        />
        <path
          d="M98.1327 93.1762L95.0307 70.0884H76.9693L73.8672 93.1762C73.4295 96.4337 75.3744 99.5786 78.5156 100.546C83.3839 102.045 88.6161 102.045 93.4843 100.546C96.6255 99.579 98.5705 96.4337 98.1327 93.1762Z"
          fill="url(#om_paint19_linear)"
        />
        <path
          d="M135.116 40.9716C131.944 44.0268 128.243 45.1478 128.243 45.1478V53.7964C128.243 55.9845 127.906 58.1561 127.243 60.24L123.767 71.1559C123.767 57.4347 116.981 56.4847 116.981 56.4847C108.925 53.0919 110.592 41.8134 110.592 41.8134C97.8083 60.1526 67.7984 50.7078 67.7984 50.7078C67.7984 44.3141 74.1921 41.8134 74.1921 41.8134C59.8665 41.8134 59.8041 50.2829 59.8041 50.4286C59.8041 55.9555 57.6743 61.2737 53.8522 65.2708L48.2339 71.1559L45.3247 62.5532C43.9035 58.3642 43.7659 53.817 45.1079 49.6033C45.3578 48.8239 45.6498 48.0654 45.9955 47.3734C41.6149 48.3905 35.8296 46.5775 33.0412 45.5313C32.0409 45.1603 31.449 44.1351 31.62 43.0805C32.5535 37.2702 37.2636 19.306 59.8041 19.306C56.5155 17.0845 54.5149 14.1251 53.4644 12.1788C52.8765 11.0952 53.3312 9.74483 54.4522 9.2403C65.3017 4.38889 118.144 -16.8514 121.149 28.2213C126.38 24.5409 134.07 30.4762 135.887 38.3663C136.104 39.3086 135.812 40.3005 135.116 40.9716Z"
          fill="url(#om_paint20_linear)"
        />
        <path
          d="M110.591 41.8139L114.759 31.8814L114.319 32.1688C101.115 40.792 86.4081 46.8589 70.9638 50.0534L67.7983 50.7083C67.7983 50.7083 97.8079 60.1532 110.591 41.8139Z"
          fill="url(#om_paint21_linear)"
        />
        <path
          d="M59.8037 35.5782L55.0282 35.0362C47.8706 34.2238 40.7137 36.5643 35.4192 41.4484L31.9512 44.648C32.2041 45.0409 32.5758 45.3589 33.0412 45.5316C35.8296 46.5777 41.6149 48.3908 45.9955 47.3737C48.2976 43.1195 51.834 39.6617 56.1391 37.456L59.8037 35.5782Z"
          fill="url(#om_paint22_linear)"
        />
        <path
          d="M135.116 40.9717C135.789 40.3231 136.079 39.3754 135.903 38.4625C130.62 36.3047 124.763 36.0494 124.763 36.0494C128.618 40.3218 128.243 45.1482 128.243 45.1482C128.243 45.1482 131.944 44.0269 135.116 40.9717Z"
          fill="url(#om_paint23_linear)"
        />
        <path
          d="M65.33 56.4727C61.7588 56.4727 58.4523 57.6029 55.7472 59.5249C53.8292 60.8878 53.5918 63.6462 55.2555 65.3098L55.4902 65.5446C56.814 66.8683 58.8759 66.9884 60.4155 65.9233C61.8111 64.9577 63.5044 64.3922 65.33 64.3922C67.1555 64.3922 68.8489 64.9577 70.2445 65.9233C71.7837 66.9884 73.846 66.8683 75.1697 65.5446L75.4045 65.3098C77.0681 63.6462 76.8307 60.8878 74.9127 59.5249C72.2076 57.6029 68.9008 56.4727 65.33 56.4727Z"
          fill="url(#om_paint24_linear)"
        />
        <path
          d="M106.67 56.4727C103.099 56.4727 99.7924 57.6029 97.0873 59.5249C95.1693 60.8878 94.9319 63.6462 96.5956 65.3098L96.8303 65.5446C98.154 66.8683 100.216 66.9884 101.756 65.9233C103.151 64.9577 104.844 64.3922 106.67 64.3922C108.496 64.3922 110.189 64.9577 111.585 65.9233C113.124 66.9884 115.186 66.8683 116.51 65.5446L116.745 65.3098C118.408 63.6462 118.171 60.8878 116.253 59.5249C113.548 57.6029 110.241 56.4727 106.67 56.4727Z"
          fill="url(#om_paint25_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="om_paint0_linear"
          x1="69.0465"
          y1="114.101"
          x2="112.95"
          y2="256.092"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4F8B6" />
          <stop offset="1" stopColor="#3B8EAC" />
        </linearGradient>
        <linearGradient
          id="om_paint1_linear"
          x1="101.531"
          y1="158.947"
          x2="27.1314"
          y2="65.5835"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B8EAC" stopOpacity="0" />
          <stop offset="1" stopColor="#343168" />
        </linearGradient>
        <linearGradient
          id="om_paint2_linear"
          x1="120.998"
          y1="153.382"
          x2="120.998"
          y2="218.424"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B8EAC" stopOpacity="0" />
          <stop offset="1" stopColor="#343168" />
        </linearGradient>
        <linearGradient
          id="om_paint3_linear"
          x1="129.361"
          y1="157.106"
          x2="70.036"
          y2="155.855"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B8EAC" stopOpacity="0" />
          <stop offset="1" stopColor="#343168" />
        </linearGradient>
        <linearGradient
          id="om_paint4_linear"
          x1="84.1721"
          y1="155.432"
          x2="100.011"
          y2="179.19"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A34A9E" />
          <stop offset="1" stopColor="#343168" />
        </linearGradient>
        <linearGradient
          id="om_paint5_linear"
          x1="51.5029"
          y1="137.913"
          x2="81.9273"
          y2="168.338"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4F8B6" />
          <stop offset="1" stopColor="#3B8EAC" />
        </linearGradient>
        <linearGradient
          id="om_paint6_linear"
          x1="120.497"
          y1="137.913"
          x2="90.0723"
          y2="168.338"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4F8B6" />
          <stop offset="1" stopColor="#3B8EAC" />
        </linearGradient>
        <linearGradient
          id="om_paint7_linear"
          x1="39.9188"
          y1="70.1266"
          x2="61.2152"
          y2="94.27"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCCECE" />
          <stop offset="1" stopColor="#FBA5C2" />
        </linearGradient>
        <linearGradient
          id="om_paint8_linear"
          x1="40.8622"
          y1="82.9605"
          x2="59.2017"
          y2="64.3434"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCCECE" stopOpacity="0" />
          <stop offset="1" stopColor="#BB4966" />
        </linearGradient>
        <linearGradient
          id="om_paint9_linear"
          x1="132.081"
          y1="70.1266"
          x2="110.785"
          y2="94.27"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCCECE" />
          <stop offset="1" stopColor="#FBA5C2" />
        </linearGradient>
        <linearGradient
          id="om_paint10_linear"
          x1="131.138"
          y1="82.9605"
          x2="112.798"
          y2="64.3434"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCCECE" stopOpacity="0" />
          <stop offset="1" stopColor="#BB4966" />
        </linearGradient>
        <linearGradient
          id="om_paint11_linear"
          x1="141.003"
          y1="88.9137"
          x2="96.4046"
          y2="59.0427"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCCECE" stopOpacity="0" />
          <stop offset="1" stopColor="#BB4966" />
        </linearGradient>
        <linearGradient
          id="om_paint12_linear"
          x1="62.6728"
          y1="55.27"
          x2="140.615"
          y2="143.632"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCCECE" />
          <stop offset="1" stopColor="#FBA5C2" />
        </linearGradient>
        <linearGradient
          id="om_paint13_linear"
          x1="58.3387"
          y1="67.5886"
          x2="68.6196"
          y2="80.5096"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A34A9E" />
          <stop offset="1" stopColor="#343168" />
        </linearGradient>
        <linearGradient
          id="om_paint14_linear"
          x1="99.6787"
          y1="67.5886"
          x2="109.96"
          y2="80.5096"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A34A9E" />
          <stop offset="1" stopColor="#343168" />
        </linearGradient>
        <linearGradient
          id="om_paint15_linear"
          x1="83.7809"
          y1="112.646"
          x2="106.47"
          y2="148.647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4F7FF" />
          <stop offset="1" stopColor="#A2E3E9" />
        </linearGradient>
        <linearGradient
          id="om_paint16_linear"
          x1="86"
          y1="107.674"
          x2="86"
          y2="129.967"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A2E3E9" stopOpacity="0" />
          <stop offset="1" stopColor="#518CD2" />
        </linearGradient>
        <linearGradient
          id="om_paint17_linear"
          x1="86.0017"
          y1="100.67"
          x2="86.0017"
          y2="126.068"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDA4D2" />
          <stop offset="1" stopColor="#343168" />
        </linearGradient>
        <linearGradient
          id="om_paint18_linear"
          x1="86.0023"
          y1="120.737"
          x2="86.0023"
          y2="128.727"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCCECE" />
          <stop offset="1" stopColor="#FBA5C2" />
        </linearGradient>
        <linearGradient
          id="om_paint19_linear"
          x1="86"
          y1="72.9932"
          x2="86"
          y2="120.414"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCCECE" stopOpacity="0" />
          <stop offset="1" stopColor="#BB4966" />
        </linearGradient>
        <linearGradient
          id="om_paint20_linear"
          x1="70.9241"
          y1="18.327"
          x2="90.3749"
          y2="51.9489"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4F7FF" />
          <stop offset="1" stopColor="#A2E3E9" />
        </linearGradient>
        <linearGradient
          id="om_paint21_linear"
          x1="92.8078"
          y1="27.2701"
          x2="86.7642"
          y2="82.7047"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A2E3E9" stopOpacity="0" />
          <stop offset="1" stopColor="#518CD2" />
        </linearGradient>
        <linearGradient
          id="om_paint22_linear"
          x1="46.667"
          y1="32.8714"
          x2="43.2533"
          y2="64.1814"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A2E3E9" stopOpacity="0" />
          <stop offset="1" stopColor="#518CD2" />
        </linearGradient>
        <linearGradient
          id="om_paint23_linear"
          x1="130.751"
          y1="37.0366"
          x2="126.166"
          y2="46.4146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A2E3E9" stopOpacity="0" />
          <stop offset="1" stopColor="#518CD2" />
        </linearGradient>
        <linearGradient
          id="om_paint24_linear"
          x1="65.33"
          y1="57.5149"
          x2="65.33"
          y2="70.1053"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4F7FF" />
          <stop offset="1" stopColor="#A2E3E9" />
        </linearGradient>
        <linearGradient
          id="om_paint25_linear"
          x1="106.67"
          y1="57.5149"
          x2="106.67"
          y2="70.1053"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4F7FF" />
          <stop offset="1" stopColor="#A2E3E9" />
        </linearGradient>
        <clipPath id="clip1">
          <rect width="172" height="172" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

OldMan.propTypes = {
  className: PropTypes.string,
};

export default OldMan;
