import { useEffect } from 'react';
import socket from 'socket.io-client';
import withConfig from '../utils/withConfig';

const useSocket = (topic, callback = () => {}) => {
  const serverUrl = withConfig('WEBSOCKET_URL');

  useEffect(() => {
    const client = socket.connect(serverUrl, { transports: ['websocket'] });
    const socketCallback = (params) => callback(params);
    client.on(topic, socketCallback);
    return () => client.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverUrl, topic]);
};
export default useSocket;
