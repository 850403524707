import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './HealthcareServices.style';
import Checkbox from 'common/Checkbox/Checkbox';
import Radio from 'common/Radio/Radio';
import AmbulatoryAssistance from 'common/Icons/lineIcons/healthcareServices/AmbulatoryAssistance';
import DiabeticCare from 'common/Icons/lineIcons/healthcareServices/DiabeticCare';
import IncontinenceCare from 'common/Icons/lineIcons/healthcareServices/IncontinenceCare';
import MemoryCare from 'common/Icons/lineIcons/healthcareServices/MemoryCare';
import HighAcuityCare from 'common/Icons/lineIcons/healthcareServices/HighAcuityCare';
import MedicationManagement from 'common/Icons/lineIcons/healthcareServices/MedicationManagement';
import SkilledServices from 'common/Icons/lineIcons/healthcareServices/SkilledServices';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import Typography from 'common/Typography/Typography';

const HealthcareServices = ({
  //#region Props
  labels,
  filters,
  onChange,
  shouldClear,
  onClear,
  filterToRemove,
  //#endregion Props
}) => {
  //#region Hooks
  const classes = useStyles();
  const [healthcareServices, setHealthcareServices] = useState(filters);
  const [
    allHealthcareServicesSelected,
    setAllHealthcareServicesSelected,
  ] = useState(false);

  useEffect(() => {
    setHealthcareServices(filters);
  }, [filters]);

  useEffect(() => {
    if (filterToRemove) {
      const updatedFilters = healthcareServices.map((type) => {
        if (type.label === filterToRemove) {
          type.selected = false;
        }
        return type;
      });
      setHealthcareServices(updatedFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterToRemove]);

  useEffect(() => {
    shouldClear && clearFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldClear]);

  useEffect(() => {
    const isAmbulatorySelected = !!getFiltersByType(
      labels.AMBULATORY_CARE,
    ).find((service) => service.selected);

    const allCheckboxesSelected = !healthcareServices.find(
      (service) => service.type !== labels.AMBULATORY_CARE && !service.selected,
    );

    setAllHealthcareServicesSelected(
      allCheckboxesSelected && isAmbulatorySelected,
    );
    // eslint-disable-next-line
  }, [healthcareServices]);
  //#endregion Hooks

  //#region utility functions
  const clearFilters = () => {
    const clearServices = healthcareServices.map((service) => ({
      ...service,
      ...{ selected: false },
    }));
    setHealthcareServices([...clearServices]);
    onClear();
  };

  const getFiltersByType = (label) => {
    return healthcareServices.filter((service) => service.type === label);
  };

  const NONE_ITEM = {
    label: 'None',
    value: 'None',
  };

  const getRadioItems = (type, showSelectNone = false) => {
    let items = getFiltersByType(type);
    if (showSelectNone) {
      items.push(NONE_ITEM);
    }
    return items;
  };

  const getSelectedRadioItem = (type, showSelectNone = false) => {
    const selectedItem = getFiltersByType(type).find(
      (service) => service.selected,
    );
    const isItemSelected = !!selectedItem;
    if (showSelectNone && !isItemSelected) {
      return NONE_ITEM;
    }
    return selectedItem;
  };

  //#endregion utility functions

  //#region Handlers
  const handleToggleAll = () => {
    setAllHealthcareServicesSelected(!allHealthcareServicesSelected);
    const newServices = healthcareServices.map((service) => {
      if (service.type === labels.AMBULATORY_CARE) {
        return {
          ...service,
          ...{
            selected:
              service.label === labels.STANDBY_ASSISTANCE
                ? !allHealthcareServicesSelected
                : false,
          },
        };
      }
      return {
        ...service,
        ...{ selected: !allHealthcareServicesSelected },
      };
    });
    setHealthcareServices([...newServices]);
    onChange(newServices);
  };

  const handleSelectedItem = (service) => {
    service.selected = !service.selected;
    setHealthcareServices([...healthcareServices]);
    onChange(healthcareServices);
  };

  const handleSelectedRadioItem = (selectedItem, type) => {
    const updatedFilters = healthcareServices.map((service) => {
      if (service.type === type) {
        service.selected = service.label === selectedItem.label ? true : false;
      }
      return service;
    });
    setHealthcareServices([...updatedFilters]);
    onChange(updatedFilters);
  };

  //#endregion Handlers

  //#region Render
  return (
    <div className={classes.filter_container}>
      <Checkbox
        checked={allHealthcareServicesSelected}
        indeterminate={allHealthcareServicesSelected}
        className={classes.checkbox}
        label={
          allHealthcareServicesSelected
            ? labels.DESELECT_ALL
            : labels.SELECT_ALL
        }
        onChange={handleToggleAll}
      />
      <div>
        <SmartTooltip
          title={
            <div>
              Select the <u>lowest</u> level of ambulatory care needed
            </div>
          }
          placement="bottom"
        >
          <div className={classes.row_title}>
            <AmbulatoryAssistance />
            <Typography>{labels.AMBULATORY_CARE}</Typography>
          </div>
        </SmartTooltip>
      </div>
      <div className={classes.filter_subgroup}>
        <div className={classes.radio_filter}>
          <Radio
            items={getRadioItems(labels.AMBULATORY_CARE, true)}
            onChange={(item) => {
              handleSelectedRadioItem(item, labels.AMBULATORY_CARE);
            }}
            selected={getSelectedRadioItem(labels.AMBULATORY_CARE, true)}
            isRow={false}
          />
        </div>
      </div>
      <div className={classes.row_title}>
        <DiabeticCare />
        <Typography>{labels.DIABETIC_CARE}</Typography>
      </div>
      <div className={classes.filter_subgroup}>
        {getFiltersByType(labels.DIABETIC_CARE).map((filter, key) => (
          <div key={key} className={classes.filter}>
            <Checkbox
              key={key}
              label={filter.label}
              className={classes.checkbox}
              checked={filter.selected}
              onChange={() => handleSelectedItem(filter)}
            />
          </div>
        ))}
      </div>
      <div className={classes.row_title}>
        <HighAcuityCare />
        <Typography>{labels.HIGH_ACUITY_CARE}</Typography>
      </div>
      <div className={classes.filter_subgroup}>
        {getFiltersByType(labels.HIGH_ACUITY_CARE).map((filter, key) => (
          <div key={key} className={classes.filter}>
            <Checkbox
              key={key}
              label={filter.label}
              className={classes.checkbox}
              checked={filter.selected}
              onChange={() => handleSelectedItem(filter)}
            />
          </div>
        ))}
      </div>
      <div className={classes.row_title}>
        <IncontinenceCare />
        <Typography>{labels.INCONTINENCE_CARE}</Typography>
      </div>
      <div className={classes.filter_subgroup}>
        {getFiltersByType(labels.INCONTINENCE_CARE).map((filter, key) => (
          <div key={key} className={classes.filter}>
            <Checkbox
              key={key}
              label={filter.label}
              className={classes.checkbox}
              checked={filter.selected}
              onChange={() => handleSelectedItem(filter)}
            />
          </div>
        ))}
      </div>
      <div className={classes.row_title}>
        <MemoryCare />
        <Typography>{labels.MEMORY_CARE}</Typography>
      </div>
      <div className={classes.filter_subgroup}>
        {getFiltersByType(labels.MEMORY_CARE).map((filter, key) => (
          <div key={key} className={classes.filter}>
            <Checkbox
              key={key}
              label={filter.label}
              className={classes.checkbox}
              checked={filter.selected}
              onChange={() => handleSelectedItem(filter)}
            />
          </div>
        ))}
      </div>
      <div className={classes.row_title}>
        <MedicationManagement />
        <Typography>{labels.MEDICATION_MANAGEMENT}</Typography>
      </div>
      <div className={classes.filter_subgroup}>
        {getFiltersByType(labels.MEDICATION_MANAGEMENT).map((filter, key) => (
          <div key={key} className={classes.filter}>
            <Checkbox
              key={key}
              label={filter.label}
              className={classes.checkbox}
              checked={filter.selected}
              onChange={() => handleSelectedItem(filter)}
            />
          </div>
        ))}
      </div>
      <div className={classes.row_title}>
        <SkilledServices />
        <Typography>{labels.SKILLED_SERVICES}</Typography>
      </div>
      <div className={classes.filter_subgroup}>
        {getFiltersByType(labels.SKILLED_SERVICES).map((filter, key) => (
          <div key={key} className={classes.filter}>
            <Checkbox
              key={key}
              label={filter.label}
              className={classes.checkbox}
              checked={filter.selected}
              onChange={() => handleSelectedItem(filter)}
            />
          </div>
        ))}
      </div>
    </div>
  );
  //#endregion Render
};

//#region PropTypes and Default
HealthcareServices.propTypes = {
  labels: PropTypes.object,
  filters: PropTypes.array,
  filterToRemove: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  shouldClear: PropTypes.bool,
};
//#endregion PropTypes and Defaults

export default HealthcareServices;
