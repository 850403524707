import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  overlay_container: (type) => {
    const styles = {
      backgroundColor: theme.palette.dark.eerieBlack1,
      zIndex: '-1',
      position: 'fixed',
      right: 0,
      bottom: 0,
      top: 0,
      left: 0,
      opacity: 0.3,
    };
    if (type === 'dark') {
      styles.opacity = 0.6;
    }
    return styles;
  },
}));

export { useStyles };
