import { stepTypes } from './fieldsMapper';

const createAnswersForContactInfo = (labels) => {
  return [
    {
      stepId: -1,
      value: 'save',
      label: labels.SAVE,
      hides: [],
      shows: [],
      nextStep: -1,
      order: 1,
      action: 'updateInquiryContact',
      selectedRule: [],
      closeInquiry: false,
      addToDoNotCall: false,
    },
  ];
};

const createAnswersForFamilyInfo = (labels) => {
  return [
    {
      stepId: -1,
      value: 'save',
      label: labels.SAVE,
      hides: [],
      shows: [],
      nextStep: -1,
      order: 1,
      action: 'fakeSaveMethod',
      selectedRule: [],
      closeInquiry: false,
      addToDoNotCall: false,
    },
  ];
};

const createAnswersForLocation = (labels) => {
  const saveAnswer = {
    stepId: -1,
    value: 'save',
    label: labels.SAVE,
    hides: [],
    shows: [],
    nextStep: -1,
    order: 1,
    action: 'updatePreferredLocation',
    selectedRule: [],
    closeInquiry: false,
    addToDoNotCall: false,
  };
  const closeAnswer = {
    stepId: -1,
    value: 'noCloseInquiry',
    label: labels.NO_CLOSE_INQUIRY,
    hides: [],
    shows: [],
    nextStep: -1,
    order: 2,
    action: '',
    selectedRule: [],
    closeInquiry: true,
    closeReason: labels.UNQUALIFIED_LOCATION_REASON,
    addToDoNotCall: false,
  };
  return [saveAnswer, closeAnswer];
};

const createAnswersForTransferSla = (labels, number, buttonLabel) => {
  if (!buttonLabel) buttonLabel = labels.FIND_BEST_SLA;
  return [
    {
      stepId: -1,
      value: 'findBestSla',
      label: buttonLabel,
      hides: [],
      shows: [],
      nextStep: number,
      order: 1,
      action: 'findBestSLAAvailable',
      selectedRule: [],
      closeInquiry: false,
      addToDoNotCall: false,
    },
  ];
};

const createAnswersForStep = (step, labels) => {
  if (step.answers) return step.answers;
  switch (step.type) {
    case 'contactInfo':
      return createAnswersForContactInfo(labels);
    case 'familyInfo':
      return createAnswersForFamilyInfo(labels);
    case 'locationInfo':
      return createAnswersForLocation(labels);
    case 'transferSla':
      return createAnswersForTransferSla(labels, step.number);
    default:
      return step.answers;
  }
};

const createSlaMatchStep = (number, scriptId, labels) => {
  return {
    scriptId,
    number,
    order: number,
    name: labels.SLA_MATCH_FOUND,
    size: 1,
    text: labels.SLA_MATCH_FOUND_TEXT,
    type: stepTypes.slaMatch.key,
    property: stepTypes.slaMatch.key,
    isHidden: true,
    isLegal: false,
    isOptional: false,
    isPipeda: false,
    alternateText: null,
    answers: [
      {
        stepId: -1,
        order: 1,
        label: labels.TRANSFER_TO_SLA,
        value: 'transferToSla',
        nextStep: number + 1,
        closeInquiry: false,
        closeReason: null,
        addToDoNotCall: false,
        shows: [number + 1],
        hides: [],
        selectedRule: [],
        action: 'transferToSLA',
      },
    ],
  };
};

const createCompleteWarmTransferStep = (number, scriptId, labels) => {
  return {
    scriptId,
    number,
    order: number,
    name: labels.COMPLETE_WARM_TRANSFER,
    size: 1,
    text: labels.COMPLETE_WARM_TRANSFER_TEXT,
    type: stepTypes.completeWarmTransfer.key,
    property: stepTypes.completeWarmTransfer.key,
    isHidden: true,
    isLegal: false,
    isOptional: false,
    isPipeda: false,
    alternateText: null,
    answers: [
      {
        stepId: -1,
        order: 1,
        label: labels.COMPLETE_WARM_TRANSFER_BUTTON,
        value: 'completeWarmTransfer',
        nextStep: number,
        closeInquiry: true,
        closeReason: 'warm transfer completed',
        addToDoNotCall: false,
        shows: [],
        hides: [],
        selectedRule: [],
        action: 'completeWarmTransfer',
      },
    ],
  };
};

const createSlaNotFoundStep = (number, scriptId, labels) => {
  return {
    scriptId,
    number,
    order: number,
    name: labels.SLA_MATCH_NOT_FOUND,
    size: 1,
    text: labels.SLA_MATCH_NOT_FOUND,
    type: stepTypes.slaNotFound.key,
    property: stepTypes.slaNotFound.key,
    isHidden: true,
    isLegal: false,
    isOptional: false,
    isPipeda: false,
    alternateText: null,
    answers: [
      {
        stepId: -1,
        order: 1,
        label: labels.OK,
        value: 'ok',
        nextStep: -1,
        closeInquiry: false,
        closeReason: null,
        addToDoNotCall: false,
        shows: [],
        hides: [],
        selectedRule: [],
        action: null,
      },
    ],
  };
};

const createFamilyDisconnectedCompleteWTStep = (number, scriptId, labels) => {
  return {
    scriptId,
    number,
    order: number,
    name: labels.FAMILY_DISCONNECTED_COMPLETE_WT_TITLE,
    size: 1,
    text: labels.FAMILY_DISCONNECTED_COMPLETE_WT_TEXT,
    type: stepTypes.completeWarmTransferFamilyDisconnected.key,
    property: stepTypes.completeWarmTransferFamilyDisconnected.key,
    isHidden: true,
    isLegal: false,
    isOptional: false,
    isPipeda: false,
    alternateText: null,
    answers: [
      {
        stepId: -1,
        order: 1,
        label: labels.OK,
        value: 'ok',
        nextStep: -1,
        closeInquiry: false,
        closeReason: null,
        addToDoNotCall: false,
        shows: [],
        hides: [],
        selectedRule: [],
        action: 'completeWarmTransferFamilyDisconnected',
      },
    ],
  };
};

const createTryPreviousSlaStep = (number, scriptId, labels) => {
  return {
    scriptId,
    number,
    order: number,
    name: labels.TRY_PREVIOUS_SLA_TITLE,
    size: 1,
    text: labels.TRY_PREVIOUS_SLA_TEXT,
    type: stepTypes.tryPreviousSla.key,
    property: stepTypes.tryPreviousSla.key,
    isHidden: true,
    isLegal: false,
    isOptional: false,
    isPipeda: false,
    alternateText: null,
    answers: createAnswersForTransferSla(
      labels,
      number,
      labels.TRY_PREVIOUS_SLA_BUTTON,
    ),
  };
};

const createPreviousSlaAcceptedStep = (number, scriptId, labels) => {
  return {
    scriptId,
    number,
    order: number,
    name: labels.PREVIOUS_SLA_ACCEPTED_TITLE,
    size: 1,
    text: labels.PREVIOUS_SLA_ACCEPTED_TEXT,
    type: stepTypes.previousSlaAccepted.key,
    property: stepTypes.previousSlaAccepted.key,
    isHidden: true,
    isLegal: false,
    isOptional: false,
    isPipeda: false,
    alternateText: null,
    answers: [
      {
        stepId: -1,
        order: 1,
        label: labels.TRANSFER_TO_SLA,
        value: 'transferToSla',
        nextStep: number + 1,
        closeInquiry: false,
        closeReason: null,
        addToDoNotCall: false,
        shows: [number + 1],
        hides: [],
        selectedRule: [],
        action: 'transferToSLA',
      },
    ],
  };
};

const createPreviousSlaNotAcceptedStep = (number, scriptId, labels) => {
  return {
    scriptId,
    number,
    order: number,
    name: labels.PREVIOUS_SLA_NOT_ACCEPTED_TITLE,
    size: 1,
    text: labels.PREVIOUS_SLA_NOT_ACCEPTED_TEXT,
    type: stepTypes.previousSlaNotAccepted.key,
    property: stepTypes.previousSlaNotAccepted.key,
    isHidden: true,
    isLegal: false,
    isOptional: false,
    isPipeda: false,
    alternateText: null,
    answers: [
      {
        stepId: -1,
        order: 1,
        label: labels.FIND_BEST_SLA,
        value: 'findBestSla',
        nextStep: number,
        closeInquiry: false,
        closeReason: null,
        addToDoNotCall: false,
        shows: [],
        hides: [],
        selectedRule: [],
        action: 'findBestSLAAvailable',
      },
    ],
  };
};

const createWarmTransferSteps = (currentScript, labels) => {
  let maxNumber = 0;
  if (currentScript?.steps?.length > 0) {
    const allNumbers = currentScript.steps.map((s) => s.number);
    maxNumber = Math.max(...allNumbers);
  }
  const transferSlaNumber = maxNumber + 1;
  const slaMatchStep = createSlaMatchStep(
    transferSlaNumber + 1,
    currentScript.scriptId,
    labels,
  );
  const completeWarmTransferStep = createCompleteWarmTransferStep(
    transferSlaNumber + 2,
    currentScript.scriptId,
    labels,
  );
  const slaNotFoundStep = createSlaNotFoundStep(
    transferSlaNumber + 3,
    currentScript.scriptId,
    labels,
  );
  const familyDisconnectedCompleteWTStep = createFamilyDisconnectedCompleteWTStep(
    transferSlaNumber + 4,
    currentScript.scriptId,
    labels,
  );

  const tryPreviousSlaStep = createTryPreviousSlaStep(
    transferSlaNumber + 5,
    currentScript.scriptId,
    labels,
  );

  const previousSlaAcceptedStep = createPreviousSlaAcceptedStep(
    transferSlaNumber + 6,
    currentScript.scriptId,
    labels,
  );

  const previousSlaNotAcceptedStep = createPreviousSlaNotAcceptedStep(
    transferSlaNumber + 7,
    currentScript.scriptId,
    labels,
  );

  return [
    slaMatchStep,
    completeWarmTransferStep,
    slaNotFoundStep,
    familyDisconnectedCompleteWTStep,
    tryPreviousSlaStep,
    previousSlaAcceptedStep,
    previousSlaNotAcceptedStep,
  ];
};

const createAdditionalSteps = (step, currentScript, labels) => {
  if (step.type === 'transferSla' && !step.stepId) {
    return createWarmTransferSteps(currentScript, labels);
  }
  return [];
};

const createEndCallSteps = (endCallState) => {
  const endCallReasonStep = {
    answer: {
      value: endCallState.endCallReason,
      extraFields: { closeInquiry: endCallState.closeInquiry },
    },
    number: 1000,
    name: 'END CALL',
    state: 'ANSWERED',
    property: 'endCallReason',
  };
  const endCallStep = {
    answer: {
      value: 'true',
      extraFields: { closeInquiry: endCallState.closeInquiry },
    },
    number: 1001,
    name: 'END CALL',
    state: 'ANSWERED',
    property: 'isCompleteCall',
  };
  return [endCallReasonStep, endCallStep];
};

const getEndCallSteps = (endCallState) => {
  return createEndCallSteps(endCallState);
};

const stepService = {
  createAnswersForStep,
  createAdditionalSteps,
  getEndCallSteps,
};

export default stepService;
