import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  tours_scheduled_container: {
    height: theme.spacing(18),
    boxShadow: `0px 0px 8px ${theme.palette.grey['400']}`,
    padding: theme.spacing(3),
    borderRadius: '4px',
    backgroundColor: theme.palette.common.white,
  },

  tours_scheduled_container_bellow_small_size: {
    height: 'auto',
  },
}));

export { useStyles };
