import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  container: {
    '& p': {
      margin: 0,
    },
    margin: '24px 0',
  },
  row_preferences_resources: {
    display: 'flex',
    flexFlow: 'column nowrap',
    marginBottom: '24px',
  },
  resource: {
    alignItems: 'center',
    color: theme.palette.dark.eerieBlack1,
    display: 'flex',
    marginBottom: '5px',
    marginTop: '5px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  label: {
    paddingRight: '8px',
    marginBottom: '8px',
  },
  icon_check: {
    fontSize: '20px',
    marginRight: '6px',
  },
  show_message: {
    marginBottom: '16px',
  },
  change_background: {
    '&:hover': {
      backgroundColor: theme.palette.light.platinum4,
      color: theme.palette.light.platinum4,
    },
  },
  edit_container: {
    position: 'relative',
  },
}));

export { useStyles };
