import React from 'react';
import PropTypes from 'prop-types';

const ArrowBoldForvardAll = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M10 3.58578L18.4142 12L10 20.4142V15.8338C7.95556 15.6089 5.87631 16.5379 3.70711 18.7071L2 20.4142V18C2 11.8507 4.73892 8.41289 10 8.03493V3.58578ZM12 8.41421V10H11C6.97795 10 4.75046 11.81 4.1604 15.6825C6.47384 14.0018 8.84725 13.431 11.2425 14.0299L12 14.2192V15.5858L15.5858 12L12 8.41421ZM14 3.58578V6.58578L19.4142 12L14 17.4142V20.4142L22.4142 12L14 3.58578Z"
      />
    </svg>
  );
};

ArrowBoldForvardAll.propTypes = {
  className: PropTypes.string,
};

export default ArrowBoldForvardAll;
