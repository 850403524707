import React from 'react';
import PropTypes from 'prop-types';

const CircleFilledChecked = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12ZM15.293 8.29289L10.0001 13.5858L7.70718 11.2929L6.29297 12.7071L10.0001 16.4142L16.7072 9.70711L15.293 8.29289Z"
        fill="currentColor"
      />
    </svg>
  );
};

CircleFilledChecked.propTypes = {
  className: PropTypes.string,
};

export default CircleFilledChecked;
