import { combineReducers } from 'redux';
import catalogsReducer from './catalogs/reducers/catalogsReducer';
import formChicletsReducer from './formChiclets/reducers/formChicletsReducer';
import inquiryReducer from './inquiry/reducers/inquiryReducer';
import warmTransferReducer from './warmTransfer/reducers/warmTransferReducer';

const rootReducer = combineReducers({
  catalogs: catalogsReducer,
  inquiry: inquiryReducer,
  formChiclets: formChicletsReducer,
  warmTransfer: warmTransferReducer,
});

export default rootReducer;
