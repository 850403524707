import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './Typography.style';
import { useTextColorStyles } from '../style/textColor';
import { colorReference } from '../Theme/themes/default';
import clsx from 'clsx';

const Typography = ({
  //#region
  level,
  children,
  color,
  className,
  bold,
  underlined,
  //#endregion
}) => {
  //#region
  const classes = useStyles();
  const textColorClasses = useTextColorStyles();
  //#endregion

  const getChildren = () => {
    return bold ? <strong>{children}</strong> : children;
  };

  const elements = {
    h1: (
      <h1
        className={clsx(
          classes.h1,
          classes.common,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
        )}
      >
        {getChildren()}
      </h1>
    ),
    h2: (
      <h2
        className={clsx(
          classes.h2,
          classes.common,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
        )}
      >
        {getChildren()}
      </h2>
    ),
    h3: (
      <h3
        className={clsx(
          classes.h3,
          classes.common,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
        )}
      >
        {getChildren()}
      </h3>
    ),
    h4: (
      <h4
        className={clsx(
          classes.h4,
          classes.common,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
        )}
      >
        {getChildren()}
      </h4>
    ),
    body: (
      <div
        className={clsx(
          classes.body,
          classes.common,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
        )}
      >
        {getChildren()}
      </div>
    ),
    large: (
      <div
        className={clsx(
          classes.large,
          classes.common,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
        )}
      >
        {getChildren()}
      </div>
    ),
    small: (
      <div
        className={clsx(
          classes.small,
          classes.common,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
        )}
      >
        {getChildren()}
      </div>
    ),
    tiny: (
      <div
        className={clsx(
          classes.tiny,
          classes.common,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
        )}
      >
        {getChildren()}
      </div>
    ),
  };
  return children ? elements[level] : null;
};

//#region
Typography.propTypes = {
  level: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'body',
    'large',
    'small',
    'tiny',
  ]),
  color: PropTypes.oneOf([...colorReference, 'inherit']),
  children: PropTypes.node,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  underlined: PropTypes.bool,
  bold: PropTypes.bool,
};

Typography.defaultProps = {
  level: 'body',
  color: 'eerieBlack1',
  bold: false,
  underlined: false,
  children: null,
};
//#endregion

export default Typography;
