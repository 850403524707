import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  layout_container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: theme.palette.light.platinum1,
  },
  main_container: {
    backgroundColor: theme.palette.light.platinum3,
    padding: '32px',
    overflow: 'auto',
    flexGrow: 1,
  },
}));

export { useStyles };
