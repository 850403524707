import React from 'react';
import { useStyles } from './Divider.style';
import PropTypes from 'prop-types';
import { useBackgroundColorStyles } from '../style/backgroundColor';
import { colorReference } from '../Theme/themes/default';
import clsx from 'clsx';

const Divider = ({ color, spacing, className }) => {
  const classes = useStyles(spacing);
  const colorClasses = useBackgroundColorStyles();
  return (
    <div className={clsx(colorClasses[color], classes.divider, className)} />
  );
};

Divider.propTypes = {
  color: PropTypes.oneOf(colorReference),
  spacing: PropTypes.number,
  className: PropTypes.string,
};

Divider.defaultProps = {
  color: 'disabled',
  spacing: 2,
  className: '',
};

export default Divider;
