import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => ({
  modal_container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'fit-content',
    height: '100vh',
    minWidth: '480px',
    margin: '0 auto',
    '&:focus': {
      outline: 'none',
    },
  },
  title_container: {
    height: '43px',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '13px 16px',
    borderRadius: '4px 4px 0 0',
    borderBottom: `1px solid ${theme.palette.light.platinum1}`,
  },
  body_container: {
    display: 'block',
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.light.platinum5,
    padding: '24px',
    borderRadius: ' 0 0 4px 4px',
    maxHeight: '85vh',
    overflowY: 'scroll',
  },
  no_scroll: {
    overflow: 'hidden',
  },
  no_body_padding: {
    padding: 0,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '8px',
    },
  },
  close_icon: {
    alignSelf: 'center',
    '& svg': {
      cursor: 'pointer',
      fontSize: '16px',
      verticalAlign: 'middle',
    },
  },
}));

export { useStyles };
