import React from 'react';
import PropTypes from 'prop-types';

const Smiled = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM16.9106 15.4484L15.2747 14.2978C14.5293 15.3576 13.3195 16 12 16C10.6805 16 9.4707 15.3576 8.72535 14.2978L7.08944 15.4484C8.20545 17.0352 10.0224 18 12 18C13.9776 18 15.7945 17.0352 16.9106 15.4484ZM14 11V9H16V11H14ZM8 9V11H10V9H8Z"
      />
    </svg>
  );
};

Smiled.propTypes = {
  className: PropTypes.string,
};

export default Smiled;
