import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  left_panel_container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  workinglist_link_container: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-start',
    '& > a': {
      color: theme.palette.neutral.cosmicCobalt3,
      display: 'flex',
      alignItems: 'center',
      '&:active': {
        color: theme.palette.neutral.cosmicCobalt3,
      },
      '&:visited': {
        color: theme.palette.neutral.cosmicCobalt3,
      },
    },
  },
}));

export { useStyles };
