import PropTypes from 'prop-types';
import React from 'react';
import Media from 'common/Media/Media';
import { useStyles } from './DuplicateFamilyFilePopup.style';
import Duplicate from 'common/Icons/basic/Duplicate';
import Modal from 'common/Modal/Modal';
import { changeState } from 'services/callCenter/duplicateFamilyFilePopupService';
import DuplicateFamilyFilePopupSm from './DuplicateFamilyFilePopupSm';
import DuplicateFamilyFilePopupXs from './DuplicateFamilyFilePopupXs';

const FamilyFilePopup = ({
  isOpen,
  currentFamilyFile,
  foundFamilyFile,
  onClose,
  labels,
}) => {
  const classes = useStyles();

  let orderedFamilyFile;
  const orderingFoundedFamilyFile = (foundFamilyFile) => {
    const sortedFamilyFile = foundFamilyFile.sort((a, b) => {
      return b.count - a.count;
    });
    return sortedFamilyFile;
  };
  const newFoundResident = changeState(foundFamilyFile, currentFamilyFile);

  orderedFamilyFile = orderingFoundedFamilyFile(newFoundResident);

  const renderModal = (content, size) => {
    const className = classes[`modal_${size}`];
    return (
      <Modal
        title={labels.FAMILY_FILE_DUPLICATE_CHECK}
        isOpen={isOpen}
        onClose={onClose}
        color="cosmicCobalt2"
        noBodyScroll={false}
        className={className}
        showCloseIcon={false}
        icon={<Duplicate />}
      >
        {content}
      </Modal>
    );
  };

  return (
    <Media
      sm={renderModal(
        <DuplicateFamilyFilePopupSm
          foundFamilyFile={orderedFamilyFile}
          labels={labels}
          currentFamilyFile={currentFamilyFile}
          type="familyFile"
          onClose={onClose}
        />,
        'sm',
      )}
      xs={renderModal(
        <DuplicateFamilyFilePopupXs
          foundFamilyFile={orderedFamilyFile}
          labels={labels}
          currentFamilyFile={currentFamilyFile}
          type="familyFile"
          onClose={onClose}
        />,
        'xs',
      )}
    />
  );
};

FamilyFilePopup.propTypes = {
  isOpen: PropTypes.bool,
  currentFamilyFile: PropTypes.object.isRequired,
  foundFamilyFile: PropTypes.array,
  onClose: PropTypes.func,
  labels: PropTypes.object,
};
export default FamilyFilePopup;
