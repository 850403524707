import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => {
  return {
    summary_row_card: {
      marginBottom: '4px',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      order: 2,
    },
    row_container: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
    },
    content: {
      paddingTop: '8px',
    },
  };
});

export { useStyles };
