import React from 'react';
import PropTypes from 'prop-types';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';

const ConditionalTooltip = ({ withTooltip, labels, children }) => {
  const tooltip = labels.CLICK_TO_EDIT_OR_UPDATE_FINANCIAL_CRITERIA;
  if (withTooltip) {
    return (
      <SmartTooltip title={tooltip} placement="bottom">
        {children}
      </SmartTooltip>
    );
  }
  return children;
};
ConditionalTooltip.propTypes = {
  withTooltip: PropTypes.bool.isRequired,
  labels: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};
export default ConditionalTooltip;
