import { format, formatTourActivityDate } from '../common/utils/dateFormat';
import {
  getDate,
  getDateInUtc,
  getDateWithTimezoneOffset,
  getHourAndMinutes12HourFormat,
} from '../utils/dateFormat';
import labels from '../config/labels';
import { logEvent } from './analyticsService';
import careTypeService from '../services/careTypeService';

export const availableTourTypes = {
  IP: labels.IN_PERSON_TOUR,
  VT: labels.CT_APP,
  VTX: labels.VIRTUAL_TOURS_OTHER,
};

export const ACTION_TYPE = {
  RECORD_SCHEDULED: 'recordScheduled',
  RECORD_COMPLETED: 'recordCompleted',
  DRAFT_SCHEDULED: 'draftScheduled',
  DRAFT_COMPLETED: 'draftCompleted',
  DRAFT_CANCEL: 'draftCancel',
  CANCEL: 'cancel',
  COMPLETE: 'complete',
  RESCHEDULE: 'reschedule',
};

export const ACTION_BUTTON_TYPE = {
  SAVE_AND_CLOSE: 'saveclose',
  SAVE_AND_REFER: 'saverefer',
};

export const TOUR_STATUS_TEXT = {
  1: 'Scheduled Tour',
  2: 'Completed',
  3: 'Canceled',
  4: 'Rescheduled',
};

export const TOUR_STATUS_CODE = {
  SCHEDULED: 1,
  COMPLETED: 2,
  CANCELED: 3,
  RESCHEDULED: 4,
};

export const TOUR_STATUS = {
  SCHEDULED: 'Scheduled Tour',
  COMPLETED: 'Completed',
  CANCELED: 'Canceled',
  RESCHEDULED: 'Rescheduled',
};

export const ACTIVITY_TYPE_CODE = {
  SCHEDULED: 'Scheduled',
  TOURED: 'Toured',
  CANCELED: 'Canceled',
  RESCHEDULED: 'Rescheduled',
};

const tourTypesCountTemplate = {
  IP: 0,
  VT: 0,
  VTX: 0,
};

export const STATUS_QUESTION_OPTION = {
  SCHEDULED: 'scheduled',
  COMPLETED: 'completed',
};

export const UPDATE_TYPE_QUESTION_OPTION = {
  COMPLETE: 'complete',
  CANCEL: 'cancel',
  RESCHEDULE: 'reschedule',
};

export const IN_PERSON = 'IP';
export const CT_APP = 'VT';
export const VIRTUAL_TOUR = 'VTX';
const SAME_DAY = 'SAME';
const DIFFERENT_DAY = 'DIFFERENT';

/**
 *  @deprecated Use activityTypeCode/tourStatus enum instead.
 */
const CANCELED = 'Canceled';
/**
 *  @deprecated Use activityTypeCode/tourStatus enum instead.
 */
const COMPLETED = 'Completed';

/**
 * Builds activity date in local time. Service will convert to UTC.
 */
export const buildActivityDate = (selectedDate, selectedTime) => {
  const activityDate = new Date(
    `${selectedDate.getFullYear()}-${
      selectedDate.getMonth() + 1
    }-${selectedDate.getDate()} ${selectedTime
      .toLowerCase()
      .replace('pm', ' pm')
      .replace('am', ' am')}`,
  ).getTime();
  return activityDate;
};

const createTourButtonLabel = (selectedDate, selectedTime, selectedType) => {
  if (selectedDate) {
    const label = `${selectedDate} @ ${timeToDisplayFormat(selectedTime)} - ${
      availableTourTypes[selectedType]
    }`;
    return label;
  }
  return '';
};

const createOriginalTourButtonlabel = (tourDetail) => {
  if (
    !tourDetail ||
    tourDetail.currentStatus === TOUR_STATUS.CANCELED ||
    tourDetail.currentStatus === TOUR_STATUS.COMPLETED
  ) {
    return null;
  }

  const activityDate = formatTourActivityDate(
    tourDetail.scheduledTourDate,
    'MMM DD, YYYY (DDD)',
  );
  const activityTime = getHourAndMinutes12HourFormat(
    tourDetail.scheduledTourDate,
    true,
  )
    .toUpperCase()
    .replace(' PM', 'PM')
    .replace(' AM', 'AM')
    .padStart(7, '0');
  const originalTourButtonLabel = createTourButtonLabel(
    activityDate,
    activityTime,
    tourDetail.tourType,
  );
  return originalTourButtonLabel;
};

/**
 * Create tour information (based on tour date/time/etc from TourActivity) for use by TourScheduler.
 */
export const tourToTourInfo = ({
  communityId,
  familyCommunityTourDraftId,
  familyCommunityTourId,
  selectedDate,
  selectedTime,
  selectedType,
  tourDetail,
}) => {
  const tourInfo = {
    activityDate:
      selectedDate === ''
        ? 0
        : buildActivityDate(new Date(selectedDate), selectedTime),
    communityId: communityId,
    familyCommunityTourDraftId: familyCommunityTourDraftId,
    familyCommunityTourId: familyCommunityTourId,
    selectedDate: format(selectedDate, 'MMM DD, YYYY (DDD)'),
    selectedTime,
    selectedType,
  };
  tourInfo.tourButtonLabel = createTourButtonLabel(
    tourInfo.selectedDate,
    tourInfo.selectedTime,
    tourInfo.selectedType,
  );
  tourInfo.originalTourButtonLabel = createOriginalTourButtonlabel(tourDetail);

  return tourInfo;
};

/**
 * Create tour information (based on tourDetail and tourDraft) for use by TourScheduler.
 */
export const tourDataToTourInfo = (communityId, tourDetail, tourDraft) => {
  if (tourDraft) {
    const tourInfo = {
      activityDate: tourDraft.activityDate,
      communityId,
      familyCommunityTourDraftId: tourDraft.familyCommunityTourDraftId,
      familyCommunityTourId: tourDetail?.familyCommunityTourId,
      selectedDate:
        tourDraft.activityDate === 0
          ? null
          : formatTourActivityDate(
              tourDraft.activityDate,
              'MMM DD, YYYY (DDD)',
            ),
      selectedTime: getHourAndMinutes12HourFormat(tourDraft.activityDate, true)
        .toUpperCase()
        .replace(' PM', 'PM')
        .replace(' AM', 'AM')
        .padStart(7, '0'),
      selectedType: tourDraft.tourType,
    };
    tourInfo.tourButtonLabel = createTourButtonLabel(
      tourInfo.selectedDate,
      tourInfo.selectedTime,
      tourInfo.selectedType,
    );
    tourInfo.originalTourButtonLabel = createOriginalTourButtonlabel(
      tourDetail,
    );

    return tourInfo;
  }

  if (tourDetail) {
    const tourInfo = {
      activityDate: tourDetail.scheduledTourDate,
      communityId,
      familyCommunityTourDraftId: null,
      familyCommunityTourId: tourDetail.familyCommunityTourId,
      selectedDate: formatTourActivityDate(
        tourDetail.scheduledTourDate,
        'MMM DD, YYYY (DDD)',
      ),
      selectedTime: tourDetail.scheduledTourDate
        ? getHourAndMinutes12HourFormat(tourDetail.scheduledTourDate, true)
            .toUpperCase()
            .replace(' PM', 'PM')
            .replace(' AM', 'AM')
            .padStart(7, '0')
        : null,
      selectedType: tourDetail.tourType,
    };
    tourInfo.tourButtonLabel = createTourButtonLabel(
      tourInfo.selectedDate,
      tourInfo.selectedTime,
      tourInfo.selectedType,
    );
    tourInfo.originalTourButtonLabel = createOriginalTourButtonlabel(
      tourDetail,
    );

    if (
      tourDetail.currentStatus === TOUR_STATUS.COMPLETED ||
      tourDetail.currentStatus === TOUR_STATUS.CANCELED
    ) {
      tourInfo.activityDate = null;
      tourInfo.selectedDate = null;
      tourInfo.selectedTime = null;
      tourInfo.selectedType = null;
      tourInfo.tourButtonLabel = '';
    }

    if (tourDetail.currentStatus === TOUR_STATUS.COMPLETED) {
      const completedTourActivity = tourDetail?.tourActivities
        ?.filter((activity) => {
          return TOUR_STATUS_TEXT[activity.status] === TOUR_STATUS.COMPLETED;
        })
        .pop();

      tourInfo.completedDate = format(
        getDateInUtc(new Date(completedTourActivity.activityDate)).getTime(),
        'MMM DD, YYYY (DDD)',
      );
      tourInfo.completedTime = getHourAndMinutes12HourFormat(
        completedTourActivity.activityDate,
        true,
      );
    }

    return tourInfo;
  }

  const emptyTourInfo = {
    communityId: null,
    familyCommunityTourDraftId: null,
    familyCommunityTourId: null,
    selectedDate: null,
    selectedTime: null,
    selectedType: null,
    tourButtonLabel: '',
    originalTourButtonLabel: '',
  };
  return emptyTourInfo;
};

const toTourInfo = (tourDetails) => {
  return {
    communityId: tourDetails.communityId,
    familyCommunityTourId: tourDetails.familyCommunityTourId,
    selectedDate: format(tourDetails.scheduledTourDate, 'MMM DD, YYYY (DDD)'),
    selectedTime: getHourAndMinutes12HourFormat(
      tourDetails.scheduledTourDate,
      true,
    ),
    selectedType: tourDetails.tourType,
  };
};

/**
 *  This is only used by Tours v1.
 */
const toCompletedTourInfo = (tourDetails) => {
  const completedTourActivity = tourDetails?.tourActivities
    ?.filter((activity) => {
      return TOUR_STATUS_TEXT[activity.status] === COMPLETED;
    })
    .pop();

  return {
    completedDate: format(
      getDateInUtc(new Date(completedTourActivity.activityDate)).getTime(),
      'MMM DD, YYYY (DDD)',
    ),
    completedTime: getHourAndMinutes12HourFormat(
      completedTourActivity.activityDate,
      true,
    ),
    selectedType: tourDetails.tourType,
    communityId: tourDetails.communityId,
    familyCommunityTourId: tourDetails.familyCommunityTourId,
  };
};

const toCalendarDetails = (tourDetails) => {
  if (tourDetails) {
    return {
      communityId: tourDetails.communityId,
      familyCommunityTourId: tourDetails.familyCommunityTourId,
      scheduledTourDate: getDateWithTimezoneOffset(
        tourDetails.scheduledTourDate,
      ),
      tourType: tourDetails.tourType,
    };
  }
};

const formatTourScheduleLabel = (tourInfo) => {
  return `${format(tourInfo.selectedDate, 'MMM DD, YYYY (DDD)')} @ ${
    tourInfo.selectedTime
  } - ${availableTourTypes[tourInfo.selectedType]}`;
};

export const timeToDisplayFormat = (time) => {
  if (!time) return '';

  const displayTime = time
    .replace('AM', ' am')
    .replace('PM', ' pm')
    .replace(/^0/, '');
  return displayTime;
};

const buildDate = (selectedDate, selectedTime) => {
  const shortDate = format(
    getDateWithTimezoneOffset(selectedDate),
    'YYYY-MM-DD',
  );
  const hoursAndMinutes = selectedTime
    .replace('PM', ' PM')
    .replace('AM', ' AM');
  const regularDate = new Date(`${shortDate} ${hoursAndMinutes}`);

  return `${shortDate} ${regularDate.getHours()}:${regularDate.getMinutes()}`;
};

const getTourTypeLabel = (tourType) => {
  return availableTourTypes[tourType];
};

const getTourTypes = () => {
  return [
    { label: labels.CT_APP, value: CT_APP },
    { label: labels.VIRTUAL_TOURS_OTHER, value: VIRTUAL_TOUR },
    { label: labels.IN_PERSON_TOUR, value: IN_PERSON },
  ];
};

const getScheduledTourDate = (tourDetails) => {
  return tourDetails ? tourDetails.scheduledTourDate : null;
};

const getSameDayLabel = (scheduledTourDate) => {
  const scheduledTourDateFormat = format(scheduledTourDate, '(MMM X)');
  return `${labels.SAME_DAY} ${scheduledTourDateFormat}`;
};

const getWhenCompletedLabel = (whenCompleted, scheduledTourDate) => {
  if (whenCompleted === SAME_DAY) {
    return getSameDayLabel(scheduledTourDate);
  }
  if (whenCompleted === DIFFERENT_DAY) {
    return labels.DIFFERENT_DAY;
  }
  return '';
};

const getMarkTourCompletedRadioItems = (scheduledTourDate) => {
  return [
    { label: getSameDayLabel(scheduledTourDate), value: SAME_DAY },
    { label: labels.DIFFERENT_DAY, value: DIFFERENT_DAY },
  ];
};

const determineWhetherReferralUpdate = (referralStatus) => {
  if (referralStatus.isReferralStarted && referralStatus.referralDate) {
    return true;
  }
  return false;
};

const isCurrentStatusAllowedToReschecule = (tourStatus) => {
  return tourStatus !== CANCELED;
};

const isReschedulingAllowed = (
  referralStatus,
  scheduledTourDate,
  selectedTourTime,
  tourStatus,
) => {
  if (isCurrentStatusAllowedToReschecule(tourStatus)) {
    const isReferralUpdate = determineWhetherReferralUpdate(referralStatus);

    if (isReferralUpdate && scheduledTourDate && !selectedTourTime) {
      return true;
    } else {
      return false;
    }
  }

  return false;
};

const getReferralManagerScheduledTourDateTime = (tourInfo) => {
  const properties = ['{date}', '{time}', '{tourType}'];

  const regex = new RegExp(
    `${properties[0]}|${properties[1]}|${properties[2]}`,
    'gi',
  );

  var label = labels.TOUR_SCHEDULED.replace(regex, (searchValue) => {
    let value = '';

    switch (searchValue) {
      case properties[0]:
        value = tourInfo.selectedDate;
        break;
      case properties[1]:
        value = tourInfo.selectedTime;
        break;
      case properties[2]:
        value = availableTourTypes[tourInfo.selectedType];
        break;
      default:
        value = '';
    }

    return value;
  });

  return label;
};

const getTourAnalyticsDetails = (communities = []) => {
  const communityIds = communities.map((community) => community.id);

  let tourDateTimes = [];
  let tourTypes = [];
  communities.forEach((community) => {
    if (community.scheduledTourDate) {
      const selectedTime = getHourAndMinutes12HourFormat(
        community.scheduledTourDate,
        true,
      );
      const tourDateTime = `${getDate(
        community.scheduledTourDate,
      )} ${selectedTime}`;
      tourDateTimes.push({
        communityId: community.id,
        selectedTourTime: tourDateTime,
      });
    }
    if (community.tourType) {
      tourTypes.push({
        communityId: community.id,
        tourType: getTourTypeLabel(community.tourType),
      });
    }
  });

  return [communityIds, tourDateTimes, tourTypes];
};

/**
 *  Tour Scheduling v1.
 */
export const logTourSchedulingOpened = (
  communityId,
  familyFileId,
  originatingPage,
  userId,
) => {
  logEvent('Tour Scheduling Opened', {
    channel: 'community',
    communityId: Number(communityId),
    crmUserId: userId,
    eventPath: 'referral alert start',
    eventType: 'action',
    familyFileId: familyFileId,
    screenName: originatingPage,
    section: 'referral',
  });
};

/**
 *  Tour Scheduling v2.
 */
export const logTourSchedulingOpenedV2 = (
  communityId,
  familyFileId,
  originatingPage,
  userId,
) => {
  logEvent('Tour Scheduling Opened', {
    channel: 'community',
    communityId: communityId,
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    screenName: originatingPage,
    section: 'community',
  });
};

/**
 *  Tour Scheduling v1 (remove when v1 deprecated)
 */
export const logTourSaved = (
  careTypes,
  communityId,
  familyFileId,
  originatingPage,
  tourDateTime,
  tourType,
  userId,
) => {
  const displayCareTypes = careTypeService.toDisplayFormatArray(careTypes);

  logEvent('Tour Time Selected', {
    careTypes: displayCareTypes,
    channel: 'community',
    communityId: Number(communityId),
    crmUserId: userId,
    eventPath: 'referral alert start',
    eventType: 'action',
    familyFileId: familyFileId,
    screenName: originatingPage,
    section: 'referral',
    selectedTourTime: tourDateTime,
    tourType: tourType,
  });
};

/**
 * Tour Scheduling v1
 */
export const logRemoveTourTimeSelected = (
  communityId,
  familyFileId,
  originatingPage,
  tourDateTime,
  tourType,
  userId,
) => {
  logEvent('Remove Tour Time Selected', {
    channel: 'community',
    communityId: Number(communityId),
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    screenName: originatingPage,
    section: 'referral',
    selectedTourTime: tourDateTime,
    tourType: tourType,
  });
};

/**
 * Tour Scheduling v2
 */
export const logRemoveTourTimeSelectedV2 = (
  communityId,
  familyFileId,
  originatingPage,
  tourDateTime,
  tourType,
  userId,
) => {
  logEvent('Remove Tour Time Selected', {
    channel: 'community',
    communityId: Number(communityId),
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    screenName: originatingPage,
    section: 'community',
    selectedTourTime: tourDateTime,
    tourType: tourType,
  });
};

/**
 *  Tour Scheduling v1.
 */
export const logTourMarkedCompleted = (
  communityId,
  familyFileId,
  tourDateTime,
  tourType,
  updatedDayOption,
  updatedTourTime,
  updatedTourType,
  userId,
) => {
  logEvent('Tour Marked Completed', {
    channel: 'community',
    communityId: Number(communityId),
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    screenName: 'communities tab',
    section: 'community',
    selectedTourTime: tourDateTime,
    tourType: tourType,
    updatedDayOption: updatedDayOption,
    updatedTourTime: updatedTourTime,
    updatedTourType: updatedTourType,
  });
};

/**
 *  Tour Scheduling v2.
 */
export const logTourScheduled = ({
  communityId,
  familyFileId,
  isRecorded,
  originatingPage,
  tourDateTime,
  tourType,
  userId,
}) => {
  logEvent('Tour Scheduled', {
    channel: 'community',
    communityId: communityId,
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    recorded: isRecorded ? 'yes' : 'no',
    screenName: originatingPage,
    section: 'community',
    selectedTourTime: tourDateTime,
    tourType: tourType,
  });
};

/**
 *  Tour Scheduling v2.
 */
export const logTourMarkedCompletedV2 = (
  communityId,
  familyFileId,
  selectedTourDate,
  tourType,
  userId,
  wasPreviouslyScheduled,
) => {
  logEvent('Tour Marked Completed', {
    channel: 'community',
    communityId: communityId,
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    previouslyScheduled: wasPreviouslyScheduled ? 'yes' : 'no',
    screenName: 'communities tab',
    section: 'community',
    selectedTourDate: selectedTourDate,
    tourType: tourType,
  });
};

/**
 *  Tour Scheduling v1.
 */
export const logTourRescheduled = (communities, familyFileId, userId) => {
  const [communityIds, tourDateTimes, tourTypes] = getTourAnalyticsDetails(
    communities,
  );
  logEvent('Tour Rescheduled', {
    channel: 'community',
    communityIdList: communityIds,
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    screenName: 'referral alert',
    section: 'referral',
    selectedTourTimes: tourDateTimes,
    tourTypes: tourTypes,
  });
};

/**
 *  Tour Scheduling v2.
 */
export const logTourRescheduledV2 = ({
  communityId,
  familyFileId,
  isRecorded,
  originatingPage,
  tourDateTime,
  tourType,
  userId,
}) => {
  logEvent('Tour Rescheduled', {
    channel: 'community',
    communityId: communityId,
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    recorded: isRecorded ? 'yes' : 'no',
    screenName: originatingPage,
    section: 'community',
    selectedTourTime: tourDateTime,
    tourType: tourType,
  });
};

/**
 *  Tour Scheduling v1.
 */
export const logTourCancelled = (communities, familyFileId, userId) => {
  const [communityIds, tourDateTimes, tourTypes] = getTourAnalyticsDetails(
    communities,
  );
  logEvent('Tour Cancelled', {
    channel: 'community',
    communityIdList: communityIds,
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    screenName: 'referral alert',
    section: 'referral',
    selectedTourTimes: tourDateTimes,
    tourTypes: tourTypes,
  });
};

/**
 *  Tour Scheduling v2.
 */
export const logTourCancelledV2 = ({
  communityId,
  familyFileId,
  isRecorded,
  originatingPage,
  tourDateTime,
  tourType,
  userId,
}) => {
  logEvent('Tour Cancelled', {
    channel: 'community',
    communityId: communityId,
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    recorded: isRecorded ? 'yes' : 'no',
    screenName: originatingPage,
    section: 'community',
    selectedTourTime: tourDateTime,
    tourType: tourType,
  });
};

export const logTourScheduleSaved = (
  communityId,
  familyFileId,
  tourDateTime,
  tourType,
  userId,
) => {
  logEvent('Tour Schedule Saved', {
    channel: 'community',
    communityId: communityId,
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    screenName: 'communities tab',
    section: 'community',
    selectedTourTime: tourDateTime,
    tourType: tourType,
  });
};

export const logTourDraftSaved = (
  communityId,
  familyFileId,
  originatingPage,
  tourDateTime,
  tourType,
  userId,
) => {
  logEvent('Tour Draft Saved', {
    channel: 'community',
    communityId: communityId,
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    screenName: originatingPage,
    section: 'community',
    selectedTourTime: tourDateTime,
    tourType: tourType,
  });
};

export const logTourErrorViewed = (
  communityId,
  errorMessage,
  familyFileId,
  originatingPage,
  userId,
) => {
  logEvent('Tour Error Viewed', {
    channel: 'community',
    communityId: communityId,
    crmUserId: userId,
    errorMessage: errorMessage,
    eventType: 'view',
    familyFileId: familyFileId,
    screenName: originatingPage,
    section: 'community',
  });
};

export default {
  availableTourTypes,
  buildDate,
  formatTourScheduleLabel,
  getMarkTourCompletedRadioItems,
  getReferralManagerScheduledTourDateTime,
  getScheduledTourDate,
  getTourTypeLabel,
  getTourTypes,
  getWhenCompletedLabel,
  isReschedulingAllowed,
  toCalendarDetails,
  toTourInfo,
  tourTypesCountTemplate,
  tourDataToTourInfo,
  toCompletedTourInfo,
};
