const capitalize = (s) => {
  if (typeof s !== 'string') {
    return s;
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 * Truncate string to length. (optional) add ellipsis
 *
 * @param {String} str
 * @param {Number} length
 * @param {Boolean} ellipsis
 * @returns String
 */
const truncateString = (str, length, ellipsis = false) => {
  if (
    !str ||
    !length ||
    typeof str !== 'string' ||
    typeof length !== 'number'
  ) {
    throw new Error(
      'truncateString function requires a string and length parameter',
    );
  }
  if (str.length <= length) {
    return str;
  }
  return ellipsis
    ? str.substring(0, length - 1) + '…'
    : str.substring(0, length);
};

export { capitalize, truncateString };
