import React from 'react';
import PropTypes from 'prop-types';
import Media from '../../common/Media/Media';

const versionNumber =
  (window.ENV.REACT_APP_VERSION
    ? `${window.ENV.REACT_APP_VERSION}`
    : `${process.env.REACT_APP_VERSION}`) || '0.0.0.0';

const versionStyle = {
  position: 'fixed',
  bottom: '0',
  right: '0',
  fontWeight: 'bold',
  padding: '5px 10px',
  color: '#ddd',
};
const bbText = 'Beacon Build';

const Version = (props) => {
  const version = versionNumber === 'undefined' ? '0.0.0.0' : versionNumber;

  const Small = () => (
    <div className={props.class}>
      <div>
        <span>{bbText}</span>
      </div>
      <div>
        <span>{version}</span>
      </div>
    </div>
  );

  const Large = () => (
    <div className={props.class}>
      <span>
        {bbText} {version}
      </span>
    </div>
  );

  return props.class ? (
    <Media xs={<Small />} sm={<Small />} md={<Large />}></Media>
  ) : (
    <span style={versionStyle}>{`Build ${version}`}</span>
  );
};

Version.propTypes = {
  class: PropTypes.string,
};

export default Version;
