import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useStyles } from './NavigationSidePanel.style';
import SidePanel from 'common/SidePanel/SidePanel';
import Typography from 'common/Typography/Typography';
import Home from 'common/Icons/basic/Home';
import FileCreated from 'common/Icons/file/FileCreate';
import { getManualInquiryState } from 'stateManagement/callcenter/formChiclets/actions/formChicletsActions';
import Search from 'common/Icons/basic/Search';
import { colorReference } from 'common/Theme/themes/default';
import { inquiryManualObject } from 'stateManagement/callcenter/formChiclets/sagas/inquiryObject';
import { updateInquiry } from 'stateManagement/callcenter/inquiry/actions/inquiryActions';
import Alert from 'common/Alert/Alert';

const NavigationSidePanel = ({
  labels,
  isOpen,
  onClose,
  backgroundColor,
  width,
  xColor,
  agentState,
  flags,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const onClickDashboard = (event) => {
    event.stopPropagation();
    onClose();
    history.push(`/callcenter/home`);
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState(
    labels.UPDATE_STATUS_TO_CREATE_MANUAL_INQUIRY,
  );
  const [dialogContent, setDialogContent] = useState(
    labels.UPDATE_STATUS_FOR_MANUAL_INQUIRY,
  );

  const isOnReadyState = agentState?.name === 'Ready';

  const onClickCreateManualInquiry = (event) => {
    if (isOnReadyState) {
      setDialogTitle(labels.UPDATE_STATUS_TO_CREATE_MANUAL_INQUIRY);
      setDialogContent(labels.UPDATE_STATUS_FOR_MANUAL_INQUIRY);
      setIsDialogOpen(true);
    } else {
      dispatch(
        getManualInquiryState({
          isManualInquiry: true,
          isCreatedManualInquiry: false,
        }),
      );
      dispatch(updateInquiry(inquiryManualObject));
      history.push(`/callcenter/manual-inquiry`);
      onClose();
      event.stopPropagation();
    }
  };

  const onClickSearchInquiry = (event) => {
    if (isOnReadyState) {
      setDialogTitle(labels.UPDATE_STATUS_TO_OPEN_INQUIRY);
      setDialogContent(labels.UPDATE_STATUS_FOR_INQUIRY_SEARCH);
      setIsDialogOpen(true);
    } else {
      history.push(`/callcenter/inquiry-search`);
      onClose();
      event.stopPropagation();
    }
  };

  const isMenuItemActive = (sourceElement) => {
    let currentLocationCode = '';
    switch (history.location.pathname) {
      case '/dashboard':
        currentLocationCode = 'dash';
        break;
      default:
        break;
    }
    if (sourceElement === currentLocationCode) {
      return true;
    }
    return false;
  };

  return (
    <Fragment>
      <Alert
        title={dialogTitle}
        description={dialogContent}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        type="informative"
        confirmText={labels.OK}
        onConfirm={() => setIsDialogOpen(false)}
      />

      <SidePanel
        position="left"
        width={width}
        xColor={xColor}
        isOpen={isOpen}
        onClose={onClose}
        backgroundColor={backgroundColor}
      >
        <div className={classes.navigation_side_panel_container}>
          <div
            className={clsx(classes.list_item, {
              [classes.item_active]: isMenuItemActive(`dash`),
            })}
            onClick={(event) => onClickDashboard(event)}
          >
            <Home />
            <Typography color="platinum5">{labels.DASHBOARD}</Typography>
          </div>
          {flags.inquirySearchIcon && (
            <div
              className={clsx(classes.list_item)}
              onClick={(event) => onClickSearchInquiry(event)}
            >
              <Search />
              <Typography color="platinum5">
                {labels.SEARCH_INQUIRES}
              </Typography>
            </div>
          )}
          <div
            className={clsx(classes.list_item)}
            onClick={(event) => onClickCreateManualInquiry(event)}
          >
            <FileCreated />
            <Typography color="platinum5">{labels.MANUAL_INQUIRY}</Typography>
          </div>
        </div>
      </SidePanel>
    </Fragment>
  );
};

NavigationSidePanel.propTypes = {
  labels: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  width: PropTypes.string,
  xColor: PropTypes.oneOf(colorReference),
  backgroundColor: PropTypes.oneOf(colorReference),
  agentState: PropTypes.object,
  flags: PropTypes.shape({
    inquirySearchIcon: PropTypes.bool,
  }),
};

NavigationSidePanel.defaultProps = {
  agentState: {},
};

export default withLDConsumer()(NavigationSidePanel);
