import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  address_button: {
    boxShadow: 'none',
    width: '50%',
    padding: '0px 8px 8px 0px',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: theme.palette.light.white,
      color: theme.palette.light.white,
    },
  },
  plus: {
    '& svg': {
      color: theme.palette.neutral.cosmicCobalt1,
      fill: theme.palette.neutral.cosmicCobalt1,
    },
  },
  x: {
    '& svg': {
      color: theme.palette.correction.smokyTopaz2,
      fill: theme.palette.correction.smokyTopaz2,
    },
  },
  relationship_to_resident: {
    width: theme.spacing(28),
    height: theme.spacing(8),
    '& > .star': {
      marginTop: 0,
      marginLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  standard_dropdown: {
    width: theme.spacing(24),
    height: theme.spacing(8),
    '& > .star': {
      marginTop: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '48%',
      marginTop: theme.spacing(1),
    },
  },
  location_dropdown: {
    width: theme.spacing(28),
    height: theme.spacing(8),
    marginRight: theme.spacing(3),
    '& label': {
      color: theme.palette.dark.eerieBlack1,
    },
    '& > .star': {
      marginTop: 0,
      marginLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  country_dropdown: {
    width: theme.spacing(22),
    height: theme.spacing(8),
    marginRight: theme.spacing(3),
    '& label': {
      color: theme.palette.dark.eerieBlack1,
    },
    '& > .star': {
      marginTop: 0,
      marginLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  city_input: {
    marginRight: theme.spacing(1),
    width: theme.spacing(22),
    height: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      width: '48%',
      marginTop: theme.spacing(1),
      marginRight: 0,
    },
  },
  address_input_container: {
    display: 'block',
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginRight: theme.spacing(14),
    marginLeft: theme.spacing(3),
    width: theme.spacing(69),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 0,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      '& > div': {
        flexGrow: 1,
        marginTop: theme.spacing(1),
      },
    },
  },
  contact_input_container: {
    display: 'block',
    marginTop: theme.spacing(2),
    padding: '8px 24px 0 0',
    width: theme.spacing(69),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 0,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      '& > div': {
        flexGrow: 1,
        marginTop: theme.spacing(1),
      },
    },
  },
  address_input: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '48%',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  additional_information: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  phone_input: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  email_input: {
    width: theme.spacing(32),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: '48%',
      marginTop: theme.spacing(1),
    },
  },
  title: {
    width: theme.spacing(32),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '48%',
      marginTop: theme.spacing(1),
    },
  },
  contact_preference: {
    marginTop: theme.spacing(3),
  },
  radio_container: {
    marginTop: theme.spacing(2),
    '& > label': {
      flexGrow: 1,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  container_with_error: {
    paddingBottom: theme.spacing(4),
  },
  column_address: {
    flexGrow: 2,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  column_contact: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  default_input: {
    width: theme.spacing(23),
    marginRight: theme.spacing(3),
    height: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      width: '48%',
      marginTop: theme.spacing(1),
      marginRight: 0,
    },
  },
  names_container: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      width: '100%',
      '& > div': {
        width: '100%',
        marginTop: theme.spacing(1),
      },
      '& > div.error': {
        marginBottom: theme.spacing(1),
      },
    },
  },
  phones_container: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      width: '100%',
      '& > div': {
        width: '100%',
        marginTop: theme.spacing(2),
      },
      '& > div.error': {
        marginBottom: theme.spacing(1),
      },
    },
  },
  default_name_input: {
    width: theme.spacing(23),
    marginRight: theme.spacing(3),
    height: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  default_postal_code_input: {
    width: theme.spacing(13),
    marginRight: theme.spacing(3),
    height: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  error_message: {
    padding: '0px 24px 16px 24px',
  },
  divider: {
    marginBottom: 0,
  },

  city_state_container: {
    display: 'flex',
    marginTop: theme.spacing(2),
    padding: '8px 8px 0 0',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      marginTop: 0,
    },
  },
  address2: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  email_container: {
    marginTop: 0,
    paddingTop: 0,
  },
  checkbox_address: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  contact_container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 24px 16px 24px',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      width: '100%',
      '& > div': {
        width: '100%',
        marginTop: theme.spacing(1),
      },
      '& > div.error': {
        marginBottom: theme.spacing(3),
      },
    },
  },
  column: {
    width: '100%',
    paddingRigth: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export { useStyles };
