import PropTypes from 'prop-types';
import React from 'react';
import Eye from 'common/Icons/basic/Eye';
import EyeNo from 'common/Icons/basic/EyeNo';
import Menu from 'common/Icons/basic/Menu';
import Typography from 'common/Typography/Typography';
import { useStyles } from './SortStepsDialog.style';
import { stepTypes } from 'services/callCenter/fieldsMapper';

const Item = ({ step, labels }) => {
  const classes = useStyles();
  return (
    <div key={step.stepId} className={classes.sortable_item}>
      <div className={classes.sortable_item_title}>
        <Menu className={classes.sortable_item_icon} />
        {step.isHidden ? (
          <EyeNo className={classes.sortable_item_icon} />
        ) : (
          <Eye className={classes.sortable_item_icon} />
        )}
        <Typography level="small" bold>
          {labels.NEW_ORDER.replace('{ORDER}', step.newOrder)}
        </Typography>
        <Typography level="small">
          {labels.OLD_ORDER.replace('{ORDER}', step.order)}
        </Typography>
        <Typography bold className={classes.sortable_item_name}>
          {step.name}
        </Typography>
      </div>
      <Typography level="tiny">{stepTypes[step.type]?.label}</Typography>
    </div>
  );
};

Item.propTypes = {
  step: PropTypes.object,
  labels: PropTypes.object,
};

export default Item;
