import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  dropdown_keypad_container: {
    position: 'relative',
  },
  container: {
    width: '368px',
    height: '440px',
    position: 'absolute',
    zIndex: 99,
    backgroundColor: theme.palette.light.platinum5,
    border: `1px solid ${theme.palette.neutral.cosmicCobalt5}`,
    boxShadow: '0px 0px 8px rgba(13, 19, 33, 0.2)',
    borderRadius: '2px',
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container_with_no_button: {
    height: '380px',
  },
  error_container: {
    width: '321px',
    marginTop: '1px',
  },
  icon_with_error: {
    '& path': {
      fill: theme.palette.correction.smokyTopaz2,
    },
  },
  dialer_input: {
    width: '321px',
    height: '40px',
    marginBottom: '4px',
    '& svg': {
      fontSize: '24px',
      color: theme.palette.neutral.cosmicCobalt3,
      fill: theme.palette.neutral.cosmicCobalt3,
    },
  },
  delete_icon: {
    cursor: 'pointer',
    '& svg': {
      verticalAlign: 'middle',
    },
  },
  button_numbers_container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '24px',
    marginBottom: '-24px',
  },
  button_number: {
    marginBottom: '24px',
    width: '93px',
    height: '46px',
  },
  phone_icon: {
    height: '20px',
    width: '20px',
  },
  animated_button_number: {
    animationName: '$changeColor',
    animationDuration: '1s',
  },
  '@keyframes changeColor': {
    '0%': { backgroundColor: theme.palette.light.platinum5 },
    '50%': {
      backgroundColor: theme.palette.neutral.cosmicCobalt3,
      color: theme.palette.light.platinum5,
    },
    '100%': { backgroundColor: theme.palette.light.platinum5 },
  },
}));

export { useStyles };
