import caStates from './caStates.json';
import usStates from './usStates.json';
import states from './states.json';

const US = 'US';
const CANADA = 'CA';

const getPhoneNumberErrorKey = ({
  type,
  isPrimaryContact,
  isAdditionalContact,
  labels,
}) => {
  const PRIMARY = 'primary';
  const SECONDARY = 'secondary';
  const ADDITIONAL = 'additional';

  const phoneErrorsMap = {
    [labels.HOME_PHONE_CODE]: 'HomePhoneError',
    [labels.WORK_PHONE_CODE]: 'WorkPhoneError',
    [labels.CELL_PHONE_CODE]: 'CellPhoneError',
  };

  if (isPrimaryContact) {
    return `${PRIMARY}${phoneErrorsMap[type]}`;
  }
  if (!isPrimaryContact && !isAdditionalContact) {
    return `${SECONDARY}${phoneErrorsMap[type]}`;
  }
  if (isAdditionalContact) {
    return `${ADDITIONAL}${phoneErrorsMap[type]}`;
  }
};

const getEmailErrorKey = (isPrimaryContact, isAdditionalContact) => {
  if (isPrimaryContact) {
    return 'primaryEmailError';
  }
  if (!isPrimaryContact && !isAdditionalContact) {
    return 'secondaryEmailError';
  }
  if (isAdditionalContact) {
    return 'additionalEmailError';
  }
};

const getAllowContactOptions = (labels) => [
  { label: labels.YES, value: 'Yes' },
  { label: labels.NO, value: 'No' },
];

const getHasEmailOptions = (labels) => [
  { label: labels.HAS_EMAIL, value: 'Yes' },
  { label: labels.NO_EMAIL_USE_ADDRESS, value: 'No' },
];

const isValidPhoneNumber = (number) => {
  if (!number) return true;
  const isNumberOnly = /^\d+$/.test(number);
  return number.length === 10 && isNumberOnly;
};

const isValidEmail = (email) => {
  if (!email) return true;
  return /^((?!_)\w)+([.-]?\w+)*@\w+([.-]?\w+)*\.\w+$/.test(email);
};

const isValidPostalCode = (postalCode, country) => {
  if (!postalCode) return true;
  if (!country) {
    return true;
  } else if (country === US) {
    return /^[0-9]*$/.test(postalCode) && postalCode.length === 5;
  } else if (country === CANADA) {
    return /^[A-Z]+.*[0-9]+$/.test(postalCode) && postalCode.length === 6;
  }
};

const getStatesByCountry = (country) => {
  let statesToShow;
  if (!country) {
    statesToShow = states;
  } else if (country === US) {
    statesToShow = usStates;
  } else if (country === CANADA) {
    statesToShow = caStates;
  }
  return statesToShow;
};

export {
  getEmailErrorKey,
  getPhoneNumberErrorKey,
  getAllowContactOptions,
  getHasEmailOptions,
  getStatesByCountry,
  isValidPhoneNumber,
  isValidEmail,
  isValidPostalCode,
};
