import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  toolbar_button: {
    cursor: 'pointer',
    '& svg': {
      verticalAlign: 'middle',
      paddingLeft: '10px',
      color: theme.palette.neutral.cosmicCobalt3,
    },
  },
  toolbar_container: {
    display: 'flex',
    justifyContent: 'center',
  },
  toolbar_container_mobile: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  home_table_header: {
    '& $icon svg': {
      fontSize: '15px',
    },
    height: '20px',
    alignItems: 'center',
    '& $filter_row': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: theme.palette.light.platinum5,
    },
  },
  filter_row: {
    borderBottom: 'none',
  },
  page_size_container: {
    float: 'right',
  },
  page_size: {
    marginTop: '14px',
    width: theme.spacing(10),
    '& > div:first-child': {
      marginLeft: '0',
    },
  },
  item: {
    alignItems: 'center',
  },
  icon: {
    marginRight: '32px',
    width: '25px',
    alignItems: 'center',
    textAlign: 'center',
    '& svg': {
      verticalAlign: 'middle',
    },
  },
  resident: {
    fontSize: '15px',
    flexDirection: 'column',
  },
  sales_phase: {
    display: 'table-cell',
    justifyContent: 'flex-start',
    width: '25%',
  },
  sales_phase_mobile: {
    justifyContent: 'flex-start',
    width: '25%',
  },
  contact: {
    display: 'flex',
    marginTop: '4px',
    alignItems: 'center',
    '& svg': {
      marginRight: '4px',
      color: theme.palette.neutral.cosmicCobalt3,
    },
  },
  last_call: {
    flexDirection: 'column',
    width: '25%',
    verticalAlign: 'middle',
  },
  last_call_mobile: {
    flexDirection: 'column',
    width: '25%',
  },
  last_call_sort: {
    paddingBottom: '3px',
  },
  last_call_date: {
    paddingLeft: '24px',
  },
  red_icon: {
    lineHeight: '15px',
    display: 'flex',
    '& svg': {
      color: theme.palette.correction.smokyTopaz2,
      fill: theme.palette.correction.smokyTopaz2,
    },
  },
  green_icon: {
    lineHeight: '15px',
    display: 'flex',
    '& svg': {
      color: theme.palette.positive.wintergreenDream3,
      fill: theme.palette.positive.wintergreenDream3,
    },
  },
  call_info: {
    display: 'flex',
    alignItems: 'center',
    '& div': {
      display: 'flex',
    },
    '& svg': {
      marginRight: '8px',
      fontSize: '16px',
    },
  },
  actions: {
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'center',
  },
  home_table_row: {
    cursor: 'pointer',
    '& $icon svg': {
      fontSize: '24px',
    },
  },
  tour_date: {
    marginRight: '2%',
  },
  tool_tip: {
    width: '50%',
    display: 'inline-flex',
    flexDirection: 'row',
  },
  tour_link: {
    fontSize: '15px',
  },
  tour_details_container: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
  },
  primary_contact_container: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    width: '100%',
  },
  pagination_row: {
    height: '76px',
    '&:hover': {
      backgroundColor: `${theme.palette.light.platinum5} !important`,
    },
  },
  pager: {
    marginTop: [['0'], '!important'],
    textAlign: 'center',
  },

  empty_row: {
    height: '76px',
    '&:hover': {
      backgroundColor: `${theme.palette.light.platinum5} !important`,
    },
  },
  empty_table_container: {
    backgroundColor: theme.palette.light.white,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '140px 0 145px 0',
    width: '100%',
  },
  no_content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    '& svg': {
      height: '80px',
      width: '80px',
    },
  },
  empty_header: {
    fontWeight: 'bold',
    marginTop: '16px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  empty_message: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '16px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  scroll_container: {
    overflowX: 'auto',
  },
  toast_container: {
    marginBottom: '8px',
  },

  empty_details: {
    marginLeft: '76px',
  },
  filters_container: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '16px',
    '& > div, > p': {
      marginRight: '8px',
    },
  },
  row: {
    display: 'flex',
  },
  audience_tag: {
    marginLeft: '8px',
  },
}));

export { useStyles };
