import React from 'react';
import PropTypes from 'prop-types';
import Media from 'common/Media/Media';
import SearchResultsTableLG from './SearchResultsTableLG';
import SearchResultsTableMD from './SearchResultsTableMD';
import SearchResultsTableSM from './SearchResultsTableSM';
import SearchResultsTableXS from './SearchResultsTableXS';

const SearchResultsTable = ({
  data,
  labels,
  pageNumber,
  pageCount,
  onPageChange,
  loading,
}) => {
  return (
    <Media
      lg={
        <SearchResultsTableLG
          data={data}
          labels={labels}
          pageCount={pageCount}
          currentPage={pageNumber}
          onPageChange={onPageChange}
          loading={loading}
        />
      }
      md={
        <SearchResultsTableMD
          data={data}
          labels={labels}
          pageCount={pageCount}
          currentPage={pageNumber}
          onPageChange={onPageChange}
          loading={loading}
        />
      }
      sm={
        <SearchResultsTableSM
          data={data}
          labels={labels}
          pageCount={pageCount}
          currentPage={pageNumber}
          onPageChange={onPageChange}
          loading={loading}
        />
      }
      xs={
        <SearchResultsTableXS
          data={data}
          labels={labels}
          pageCount={pageCount}
          currentPage={pageNumber}
          onPageChange={onPageChange}
          loading={loading}
        />
      }
    />
  );
};

SearchResultsTable.propTypes = {
  data: PropTypes.array,
  labels: PropTypes.object,
  pageNumber: PropTypes.number,
  pageCount: PropTypes.number,
  onPageChange: PropTypes.func,
  loading: PropTypes.bool,
};

SearchResultsTable.defaultProps = {
  data: [],
  currentPage: 1,
  onPageChange: () => {},
};

export default SearchResultsTable;
