import React from 'react';
import PropTypes from 'prop-types';

const ArrowTop = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M13.0001 5.41421V22H11.0001V5.41421L4.70718 11.7071L3.29297 10.2929L12.0001 1.58578L20.7072 10.2929L19.293 11.7071L13.0001 5.41421Z"
      />
    </svg>
  );
};

ArrowTop.propTypes = {
  className: PropTypes.string,
};

export default ArrowTop;
