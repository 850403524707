import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './TableRow.style';
import clsx from 'clsx';

const TableRow = ({ className, size, children, onClick, withHover }) => {
  const classes = useStyles();
  const containerClasses = clsx(
    classes.tr,
    classes[size],
    className,
    withHover && classes.with_hover,
  );
  return (
    <tr className={containerClasses} onClick={onClick}>
      {children}
    </tr>
  );
};

TableRow.propTypes = {
  className: PropTypes.string,
  withHover: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'extended']),
};

TableRow.defaultProps = {
  size: 'small',
  onClick: () => {},
  withHover: true,
};

export default TableRow;
