import React from 'react';

const FinancialCriteria = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.0645 9.93558V0.90332H18.9678V44.2582H55.0968V9.93558H46.0645Z"
        fill="#F1F1F1"
      />
      <path
        d="M22.5811 4.51611H42.452V9.93547H22.5811V4.51611Z"
        fill="#E9CE73"
      />
      <path
        d="M46.0645 24.3871H51.4838V40.6452H46.0645V24.3871Z"
        fill="#F1F1F1"
      />
      <path
        d="M46.0645 20.7742H51.4838V24.3871H46.0645V20.7742Z"
        fill="#D6621B"
      />
      <path
        d="M40.6455 24.3871H46.0649V40.6452H40.6455V24.3871Z"
        fill="#F1F1F1"
      />
      <path
        d="M35.2256 24.3871H40.6449V40.6452H35.2256V24.3871Z"
        fill="#F1F1F1"
      />
      <path
        d="M29.8066 24.3871H35.226V40.6452H29.8066V24.3871Z"
        fill="#F1F1F1"
      />
      <path
        d="M46.0647 20.7742V24.3871H29.8066V20.7742H46.0647Z"
        fill="#D6621B"
      />
      <path
        d="M22.5811 24.3871H29.8069V40.6452H22.5811V24.3871Z"
        fill="#F1F1F1"
      />
      <path
        d="M22.5811 20.7742H29.8069V24.3871H22.5811V20.7742Z"
        fill="#D6621B"
      />
      <path
        d="M55.0967 9.93558H46.0645V0.90332L55.0967 9.93558Z"
        fill="#82B7A8"
      />
      <path
        d="M18.9678 44.2581V11.7419H0.90332V55.0968H37.0324V44.2581H18.9678Z"
        fill="#F3E0D4"
      />
      <path
        d="M56 9.56149L46.4385 0H18.0645V10.8387H0V56H37.9355V45.1613H56V9.56149ZM46.9677 3.08367L52.9163 9.03226H46.9677V3.08367ZM36.129 54.1935H1.80645V12.6452H18.0645V45.1613H36.129V54.1935ZM19.871 43.3548V1.80645H45.1613V10.8387H54.1935V43.3548H19.871Z"
        fill="#0D1321"
      />
      <path
        d="M9.93507 34.3226C11.4288 34.3226 12.6448 35.5385 12.6448 37.0323H14.4512C14.4512 34.8519 12.8966 33.0273 10.8383 32.6075V30.7097H9.03185V32.5162H5.41895V37.0323C5.41895 39.5223 7.44503 41.5484 9.93507 41.5484C11.4288 41.5484 12.6448 42.7643 12.6448 44.2581V46.9678H9.93507C8.44131 46.9678 7.2254 45.7519 7.2254 44.2581H5.41895C5.41895 46.4385 6.97357 48.2631 9.03185 48.6829V50.5807H10.8383V48.7742H14.4512V44.2581C14.4512 41.7681 12.4251 39.742 9.93507 39.742C8.44131 39.742 7.2254 38.5261 7.2254 37.0323V34.3226H9.93507Z"
        fill="#0D1321"
      />
      <path d="M25.29 50.5807H34.3223V52.3871H25.29V50.5807Z" fill="#0D1321" />
      <path
        d="M21.6777 46.9678H34.3229V48.7742H21.6777V46.9678Z"
        fill="#0D1321"
      />
      <path
        d="M3.61328 14.4517H16.2584V16.2581H3.61328V14.4517Z"
        fill="#0D1321"
      />
      <path
        d="M3.61328 18.0645H16.2584V19.8709H3.61328V18.0645Z"
        fill="#0D1321"
      />
      <path
        d="M14.4512 21.6774H16.2576V23.4838H14.4512V21.6774Z"
        fill="#0D1321"
      />
      <path
        d="M3.61328 21.6774H12.6455V23.4838H3.61328V21.6774Z"
        fill="#0D1321"
      />
      <path
        d="M3.61328 25.2903H16.2584V27.0967H3.61328V25.2903Z"
        fill="#0D1321"
      />
      <path
        d="M43.3552 3.61292H21.6777V10.8387H43.3552V3.61292ZM41.5487 9.03227H23.4842V5.41937H41.5487V9.03227Z"
        fill="#0D1321"
      />
      <path
        d="M21.6777 41.5484H52.3874V19.871H21.6777V41.5484ZM23.4842 25.2903H28.9035V39.7419H23.4842V25.2903ZM39.7423 25.2903V39.7419H36.1293V25.2903H39.7423ZM41.5487 25.2903H45.1616V39.7419H41.5487V25.2903ZM30.71 23.4839V21.6774H45.1616V23.4839H30.71ZM34.3229 25.2903V39.7419H30.71V25.2903H34.3229ZM46.9681 39.7419V25.2903H50.581V39.7419H46.9681ZM50.581 23.4839H46.9681V21.6774H50.581V23.4839ZM28.9035 21.6774V23.4839H23.4842V21.6774H28.9035Z"
        fill="#0D1321"
      />
      <path
        d="M21.6777 12.6451H23.4842V14.4516H21.6777V12.6451Z"
        fill="#0D1321"
      />
      <path d="M25.29 12.6451H52.3868V14.4516H25.29V12.6451Z" fill="#0D1321" />
      <path
        d="M21.6777 16.2581H52.3874V18.0645H21.6777V16.2581Z"
        fill="#0D1321"
      />
    </svg>
  );
};

export default FinancialCriteria;
