import { inquiryManualObject } from '../../stateManagement/callcenter/formChiclets/sagas/inquiryObject';
import { budgetMinMaxRanges } from './fieldsMapper';
import formService from './formService';
import { cloneDeep } from 'lodash';

const buildManualInquiry = (formSteps, formChiclets) => {
  let newInquiryManualObject = cloneDeep(inquiryManualObject);

  const newFormSteps = cloneDeep(formSteps);
  newFormSteps.forEach((chiclet) => {
    if (chiclet.property === 'contactInfo') {
      newInquiryManualObject.inquiryContact = chiclet.answer.userInputValues;
      newInquiryManualObject.primaryPhone =
        chiclet.answer.userInputValues.phone;
      newInquiryManualObject.email = chiclet.answer.userInputValues.email;
      newInquiryManualObject.firstName =
        chiclet.answer.userInputValues.firstName;
      newInquiryManualObject.lastName = chiclet.answer.userInputValues.lastName;
    }
    if (chiclet.property === 'familyInfo') {
      newInquiryManualObject.inquiryFormData.relationToResident =
        chiclet.answer.userInputValues?.relationship;
      newInquiryManualObject.inquiryFormData.resident1FirstName =
        chiclet.answer.userInputValues?.resident1FirstName;
      newInquiryManualObject.inquiryFormData.resident1LastName =
        chiclet.answer.userInputValues?.resident1LastName;
      newInquiryManualObject.relationToResident =
        chiclet.answer.userInputValues?.relationship;
      newInquiryManualObject.resident1FirstName =
        chiclet.answer.userInputValues?.resident1FirstName;
      newInquiryManualObject.resident1LastName =
        chiclet.answer.userInputValues?.resident1LastName;
    }
    if (chiclet.property === 'locationInfo') {
      newInquiryManualObject.desiredCity =
        chiclet.answer.userInputValues?.location.city;
      newInquiryManualObject.desiredState =
        chiclet.answer.userInputValues?.location.state;
      newInquiryManualObject.desiredCountry =
        chiclet.answer.userInputValues?.location.country;
      newInquiryManualObject.desiredCountryCode =
        chiclet.answer.userInputValues?.location.country;
      newInquiryManualObject.desiredPostalCode =
        chiclet.answer.userInputValues?.location.zip;
      newInquiryManualObject.locationRange = Number(
        chiclet.answer.userInputValues?.distance,
      );

      newInquiryManualObject.inquiryFormData.desiredCity =
        chiclet.answer.userInputValues?.location.city;
      newInquiryManualObject.inquiryFormData.desiredState =
        chiclet.answer.userInputValues?.location.state;
      newInquiryManualObject.inquiryFormData.desiredCountry =
        chiclet.answer.userInputValues?.location.country;
      newInquiryManualObject.inquiryFormData.desiredPostalCode =
        chiclet.answer.userInputValues?.location.zip;
      newInquiryManualObject.inquiryFormData.desiredDistance =
        chiclet.answer.userInputValues?.distance;
    }
    if (chiclet.property === 'careTypeCode') {
      newInquiryManualObject.inquiryFormData.careTypeCode =
        chiclet.answer.userInputValues?.selectedOptions;
      newInquiryManualObject.careTypeCode =
        chiclet.answer.userInputValues?.selectedOptions;
    }
    if (chiclet.property === 'budgetRange') {
      if (chiclet.answer.value) {
        newInquiryManualObject.budgetMin =
          budgetMinMaxRanges[Number(chiclet.answer.value)].budgetMin;
        newInquiryManualObject.budgetMax =
          budgetMinMaxRanges[Number(chiclet.answer.value)].budgetMax;
        newInquiryManualObject.inquiryFormData.budget = chiclet.answer.value;
      }
    }
    if (chiclet.property === 'referralSource') {
      newInquiryManualObject.sourceId = Number(
        chiclet.answer.userInputValues?.source,
      );
      newInquiryManualObject.subSourceId = Number(
        chiclet.answer.userInputValues?.subSource,
      );
    }
    if (chiclet.property === 'confirmInterest') {
      newInquiryManualObject.inquiryFormData.confirmInterest =
        chiclet.answer.value;
    }
    if (chiclet.property === 'medicalInfo') {
      newInquiryManualObject.lookingReason = chiclet.answer.value;
      newInquiryManualObject.inquiryFormData.medicalInfo = chiclet.answer.value;
    }
    if (chiclet.property === 'unqualified') {
      newInquiryManualObject.inquiryFormData.confirmInterest =
        chiclet.answer.value;
    }
    if (chiclet.property === 'moveDateRange') {
      newInquiryManualObject.moveDateRange = chiclet.answer.value;
      newInquiryManualObject.inquiryFormData.moveDateRange =
        chiclet.answer.value;
    }
    if (chiclet.property === 'veteran') {
      let veteran = null;
      if (chiclet.answer.value && chiclet.answer.value === 'true') {
        veteran = true;
      } else if (chiclet.answer.value && chiclet.answer.value === 'false') {
        veteran = false;
      }
      newInquiryManualObject.veteran = veteran;
      newInquiryManualObject.inquiryFormData.veteran = veteran;
    }
  });

  newFormSteps.forEach((chiclet) => {
    if (
      chiclet.property === 'confirmInterest' &&
      chiclet.answer.value === 'self'
    ) {
      newInquiryManualObject.inquiryFormData.relationToResident = 'SELF';
      newInquiryManualObject.relationToResident = 'SELF';
    }
  });

  const formSummary = formService.createFormSummary(formSteps);
  newInquiryManualObject.formData = {
    slugId: formChiclets.slug,
    versionId: formChiclets.version,
    formData: formSummary,
    status: 'open',
    closeReason: '',
  };
  return newInquiryManualObject;
};

export default {
  buildManualInquiry,
};
