import React, { useEffect, useState } from 'react';
import Tabs from '../../common/Tabs/Tabs';
import CaseBasicInformation from '../CaseBasicInformation/CaseBasicInformation';
import PropTypes from 'prop-types';
import basicInformationQuery from '../CaseProfileSidePanel/basicInformationQuery';
import Activity from '../Activity/Activity';
import toBasicInformation from '../CaseProfileSidePanel/basicInformationService';
import useGraphQLQuery from '../../hooks/useGraphQLQuery';
import { useStyles } from './CaseProfileMobile.style';
import FamilyFileMobile from '../FamilyFile/FamilyFileMobile';
import Loading from '../Loading/Loading';
import CommunityXS from '../Community/CommunityXS';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const CaseProfileMobile = ({
  familyFileId,
  labels,
  onChange,
  activeTab,
  showCommunitiesTab,
  flags,
  size,
  isInSidePanel,
}) => {
  const classes = useStyles();
  const [activeTabIndex, setActiveTabIndex] = useState(activeTab);
  const { loading, error, data, refetch } = useGraphQLQuery(
    basicInformationQuery,
    {
      variables: { familyFileId },
    },
  );

  const tabs = [];
  useEffect(() => {
    setActiveTabIndex(activeTab);
    const activeTabInfo = tabs[activeTabIndex];
    if (activeTabInfo) {
      sessionStorage.setItem(
        'CASE_PROFILE_ACTIVE_TAB',
        `${activeTabInfo.label} tab`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, tabs]);

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  const basicInformation = toBasicInformation(data.findFamilyFileById);

  // flags.onlyShowCommunitiesTabFf will 'hide' Activity tab; see LD description
  if (!flags.onlyShowCommunitiesTabFf) {
    tabs.push(
      {
        label: labels.FAMILY_FILE,
        component: (
          <FamilyFileMobile labels={labels} familyFileId={familyFileId} />
        ),
      },
      {
        label: labels.ACTIVITY,
        component: (
          <Activity
            labels={labels}
            familyFileId={familyFileId}
            showInSidePanel={true}
          />
        ),
      },
    );
  }

  if (flags.communitiesOwl && showCommunitiesTab) {
    tabs.push({
      label: labels.COMMUNITIES,
      component: (
        <CommunityXS
          labels={labels}
          familyFileId={familyFileId}
          isInSidePanel={isInSidePanel}
        />
      ),
    });
  }

  const refetchHandler = () => {
    refetch({ familyFileId });
  };

  return (
    <div className={classes.case_profile_container}>
      <CaseBasicInformation
        labels={labels}
        basicInformation={basicInformation}
        size={size}
        shouldRefetch={refetchHandler}
        flags={flags}
      />
      {isInSidePanel && (
        <Tabs
          activeTab={activeTab}
          onChange={onChange}
          className={classes.tabs}
          tabs={tabs}
        />
      )}
    </div>
  );
};

CaseProfileMobile.propTypes = {
  familyFileId: PropTypes.number,
  flags: PropTypes.object,
  activeTab: PropTypes.number,
  labels: PropTypes.object,
  onChange: PropTypes.func,
  hideCommunitiesTab: PropTypes.bool,
  size: PropTypes.string,
  showCommunitiesTab: PropTypes.bool,
  isInSidePanel: PropTypes.bool,
};

CaseProfileMobile.defaultProps = {
  onChange: () => {},
  showCommunitiesTab: false,
  isInSidePanel: false,
};

export default withLDConsumer()(CaseProfileMobile);
