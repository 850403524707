import React, { useEffect, useState } from 'react';
import { useStyles } from './EmailContainerFilter.style';
import clsx from 'clsx';
import Checkbox from '../../Checkbox/Checkbox';
import PropTypes from 'prop-types';
import Typography from '../../Typography/Typography';

const validateAllSelected = (elementsAdditional, elementsPrimary) => {
  let secondaryIsChecked = false;
  if (elementsPrimary) {
    const checkSecondaryEmail = elementsPrimary.find(
      (element) => !element.isPrimary,
    );
    if (checkSecondaryEmail) {
      secondaryIsChecked = checkSecondaryEmail.checked;
    }
  }
  let allAdditionalSelected = true; //empty additional contacts = true
  if (elementsAdditional.length > 0) {
    const selectedAditionalContactsCounter = elementsAdditional.filter(
      (contact) => contact.checked,
    ).length;
    allAdditionalSelected =
      elementsAdditional.length === selectedAditionalContactsCounter;
  }
  return secondaryIsChecked && allAdditionalSelected;
};

const EmailContainerFilter = ({
  labels,
  elementsPrimary,
  elementsAdditional,
  onContactsChange,
}) => {
  const classes = useStyles();
  const [isAllSelected, setIsAllSelected] = useState(false);

  useEffect(() => {
    setIsAllSelected(validateAllSelected(elementsAdditional, elementsPrimary));
  }, [elementsAdditional, elementsPrimary]);

  const handleCheckboxChange = (e, item) => {
    item.checked = e.target.checked;
    onContactsChange([...elementsAdditional], [...elementsPrimary]);
    setIsAllSelected(validateAllSelected(elementsAdditional, elementsPrimary));
  };

  const getSelectAllText = () => {
    return isAllSelected ? labels.DESELECT_ALL : labels.SELECT_ALL;
  };

  const handleSelectAllOnChange = (e) => {
    const value = e.target.checked;
    if (isAllSelected === false) {
      setIsAllSelected(value);
    } else {
      setIsAllSelected(false);
    }
    elementsPrimary.map((element) => {
      //prevent default email from changing
      if (!element.isPrimary) element.checked = value;
      return element;
    });
    elementsAdditional.map((element) => {
      element.checked = value;
      return element;
    });
    onContactsChange([...elementsAdditional], [...elementsPrimary]);
  };

  const showDefaultEmailAddress = (email) => {
    return (
      <div className={classes.primary_email}>
        <Typography color="eerieBlack5" level="small">
          {`${email} - `}
        </Typography>
        <Typography color="cosmicCobalt3" level="small">
          {labels.DEFAULT_EMAIL}
        </Typography>
      </div>
    );
  };

  return (
    <div className={classes.menu}>
      <div className={classes.items_container}>
        <Checkbox
          className={classes.checkbox}
          indeterminate={isAllSelected}
          checked={isAllSelected}
          onChange={handleSelectAllOnChange}
          label={getSelectAllText()}
        />
        <div className={classes.label_check_title}>
          {labels.PRIMARY_CONTACT}
        </div>
        {elementsPrimary.map((item, key) => (
          <>
            <div key={key}>
              <Checkbox
                className={clsx(
                  classes.checkbox,
                  item.checked && classes.selected,
                )}
                checked={item.checked}
                disabled={item.disabled}
                label={
                  item.isPrimary
                    ? `${item.name} (${item.relationToResident})`
                    : item.emailAddress
                }
                onChange={
                  item.isPrimary ? null : (e) => handleCheckboxChange(e, item)
                }
              />
            </div>
            {item.isPrimary && (
              <div className={classes.label_check}>
                {showDefaultEmailAddress(item.emailAddress)}
              </div>
            )}
          </>
        ))}
        <div className={classes.check}></div>
        <div className={classes.label_check_title}>
          {elementsAdditional.length > 0 && (
            <>
              <br />
              <span className={classes.mandatory}>
                {labels.ADDITIONAL_CONTACTS}
              </span>
            </>
          )}
        </div>
        {elementsAdditional.map((item, key) => (
          <>
            <div className={classes.group_item} key={key}>
              <Checkbox
                className={clsx(
                  classes.checkbox,
                  item.checked && classes.selected,
                )}
                checked={item.checked}
                label={`${item.name} (${
                  item.relationToResident
                    ? item.relationToResident
                    : labels.UNKNOWN_RELATION
                })`}
                onChange={(e) => handleCheckboxChange(e, item)}
              />
            </div>
            <div className={classes.label_check}>{item.emailAddress}</div>
          </>
        ))}
      </div>
    </div>
  );
};

EmailContainerFilter.propTypes = {
  labels: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  selectedText: PropTypes.string,
  familyFileId: PropTypes.number,
  elementsPrimary: PropTypes.array,
  elementsAdditional: PropTypes.array,
  onContactsChange: PropTypes.func,
  isDisabled: PropTypes.bool,
};

EmailContainerFilter.defaultProps = {
  disabled: false,
  className: '',
  onChange: () => {},
};

export default EmailContainerFilter;
