import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  layout_container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  main_container: {
    backgroundColor: theme.palette.light.platinum1,
    overflow: 'auto',
    flexGrow: 1,
  },
  top_bar: {
    height: '72px',
    backgroundColor: theme.palette.neutral.cosmicCobalt1,
    width: '100%',
    boxShadow: '0px 0px 8px rgba(13, 19, 33, 0.5)',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '52px',
    height: '52px',
  },
}));

export { useStyles };
