import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import React, { useState } from 'react';
import { ApolloProvider } from 'react-apollo';
import { Provider as ReduxProvider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import CallcenterRoutesWithRedux from './CallcenterRoutesWithRedux';
import HomeRoute from './components/HomeRoute/HomeRoute';
import Loading from './components/Loading/Loading';
import PrivateRouteWithLayout from './components/PrivateRouteWithLayout/PrivateRouteWithLayout';
import labels from './config/labels';
import roles, { getRoles } from './config/roles';
import { useAuth0 } from './context/Authentication';
import LabelContext from './context/LabelContext';
import ActionList from './pages/ActionList/ActionList';
import Communities from './pages/Communities/Communities';
import EmptyLayout from './pages/EmptyLayout/EmptyLayout';
import AuthError from './pages/Error/AuthError';
import FamilyFile from './pages/FamilyFile/FamilyFile';
import InitialConsultation from './pages/InitialConsultation/InitialConsultation';
import FamilyFileList from './pages/FamilyFileList/FamilyFileList';
import HealthCheck from './pages/HealthCheck/HealthCheck';
import Home from './pages/Home/Home';
import Layout from './pages/Layout/Layout';
import Login from './pages/Login/Login';
import SearchResults from './pages/SearchResults/SearchResults';
import SendFamilyFileLetter from './pages/SendFamilyFileLetter/SendFamilyFileLetter';
import SendReferral from './pages/SendReferral/SendReferral';
import CurrentUser from './services/CurrentUser';
import store from './stateManagement/store';
import client from './utils/graphQLClient';
import history from './utils/history';
import withConfig from './utils/withConfig';
import AdminRoutesWithRedux from './AdminRoutesWithRedux';
import { PhoneProvider } from '@aplaceformom/owl-connect-client';
import IframeDialogContainer from './pages/callCenter/Inquiry/telephony/IframeDialogContainer';

const App = () => {
  const { loading } = useAuth0();

  const slaScreensAllowedRoles = [
    roles.SLA,
    roles.MANAGER,
    roles.PARTNERDEV,
    roles.TRAINER,
  ];
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem('CURRENT_USER')),
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <LabelContext.Provider value={labels}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ApolloProvider client={client}>
          <CurrentUser
            onUserChanged={(currentUser) => setCurrentUser(currentUser)}
          >
            <ReduxProvider store={store}>
              <PhoneProvider
                apiEnv={withConfig('OWL_CONNECT_ENVIRONMENT')}
                email={currentUser?.email}
                iframeContainer={IframeDialogContainer}
                iframeStyle={{
                  height: 460,
                  position: 'relative',
                  border: 'none',
                }}
                provider="five9"
                userId={currentUser?.userId}
              >
                <div className="App">
                  <Router history={history}>
                    <Route path="/login" exact component={Login} />
                    <Route path="/healthcheck" component={HealthCheck} />
                    <Route path="/error" exact component={AuthError} />
                    <Switch>
                      <PrivateRouteWithLayout
                        path="/home"
                        component={HomeRoute}
                        layout={Layout}
                        allowedRoles={getRoles()}
                      />
                      <PrivateRouteWithLayout
                        path="/dashboard"
                        component={Home}
                        layout={Layout}
                        allowedRoles={slaScreensAllowedRoles}
                      />
                      <PrivateRouteWithLayout
                        path="/action-list"
                        component={ActionList}
                        layout={Layout}
                        allowedRoles={slaScreensAllowedRoles}
                      />
                      <PrivateRouteWithLayout
                        path="/family-file"
                        component={FamilyFile}
                        layout={Layout}
                        allowedRoles={slaScreensAllowedRoles}
                      />
                      <PrivateRouteWithLayout
                        path="/family-file-list"
                        component={FamilyFileList}
                        layout={Layout}
                        allowedRoles={slaScreensAllowedRoles}
                      />
                      <PrivateRouteWithLayout
                        path="/initial-consultation"
                        component={InitialConsultation}
                        layout={EmptyLayout}
                        allowedRoles={slaScreensAllowedRoles}
                      />
                      <PrivateRouteWithLayout
                        path="/communities"
                        component={Communities}
                        layout={EmptyLayout}
                        allowedRoles={slaScreensAllowedRoles}
                      />
                      <PrivateRouteWithLayout
                        path="/send-referral"
                        component={SendReferral}
                        layout={EmptyLayout}
                        allowedRoles={slaScreensAllowedRoles}
                      />
                      <PrivateRouteWithLayout
                        path="/send-family-file-letter"
                        component={SendFamilyFileLetter}
                        layout={EmptyLayout}
                        allowedRoles={slaScreensAllowedRoles}
                      />
                      <PrivateRouteWithLayout
                        path="/search"
                        component={SearchResults}
                        layout={Layout}
                        allowedRoles={slaScreensAllowedRoles}
                      />
                    </Switch>
                    <Route path={'/admin'} component={AdminRoutesWithRedux} />
                    <Route
                      path={'/callcenter'}
                      component={CallcenterRoutesWithRedux}
                    />
                  </Router>
                </div>
              </PhoneProvider>
            </ReduxProvider>
          </CurrentUser>
        </ApolloProvider>
      </MuiPickersUtilsProvider>
    </LabelContext.Provider>
  );
};

export default withLDProvider({
  clientSideID: withConfig('LD_CLIENT_SIDE_ID'),
})(App);
