import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useStyles } from './HomeTable.style';
import Typography from '../../common/Typography/Typography';
import ArrowRight from '../../common/Icons/arrow/ArrowRight';
import NotFound from '../../common/Graphics/familyFile/NotFound';
import CaseProfileSidePanel from '../CaseProfileSidePanel/CaseProfileSidePanel';
import { registerEvent } from '../../services/Analytics';
import EmptyTable from './EmptyTable';
import clsx from 'clsx';
import User from '../../common/Icons/basic/User';
import Tag from '../../common/Tag/Tag';
import Pagination from '../../common/Pagination/Pagination';
import RichDropdown from '../../common/RichDropdown/RichDropdown';
import Media from '../../common/Media/Media';
import MedicallyUrgentFilter from '../../common/Filters/MedicallyUrgentFilter/MedicallyUrgentFilter';
import Table from '../../common/Table/Table/Table';
import TableHead from '../../common/Table/TableHead/TableHead';
import TableRow from '../../common/Table/TableRow/TableRow';
import TableBody from '../../common/Table/TableBody/TableBody';
import TableCell from '../../common/Table/TableCell/TableCell';
import HomeToursScheduledCell from './HomeToursScheduledCell';
import HomeTableLastCallCell from './HomeTableLastCallCell';
import salesPhaseService from '../../services/HomeTable/salesPhaseService';
import Dropdown from '../../common/Dropdown/Dropdown';
import AudienceTag from '../AudienceTag/AudienceTag';

const PAGE_SIZE_ITEMS = [
  { label: '10', value: '10' },
  { label: '25', value: '25' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
];
const DEFAULT_PAGE_SIZE_ITEM = PAGE_SIZE_ITEMS[0];
const DEFAULT_PAGE_SIZE = parseInt(PAGE_SIZE_ITEMS[0].value, 10);

let pageSizeItem = DEFAULT_PAGE_SIZE_ITEM;

const HomeTableWithRowsMobile = ({
  bucketId,
  columns,
  isSalesPhaseFixed,
  labels,
  medicallyUrgentFilterItems,
  onMedicallyUrgentFilterChange,
  onPageNumberChange,
  onPageSizeChange,
  onSalesPhaseFilterChange,
  pageCount,
  pageNumber,
  rows,
  salesPhaseFilterItems,
  showPaging,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [isCaseProfileOpen, setIsCaseProfileOpen] = useState(false);
  const [familyFileId, setFamilyFileId] = useState(0);
  const [salesPhaseOptions, setSalesPhaseOptions] = useState([]);

  const [showContactColumn, setShowContactColumn] = useState(false);
  const [showSalesPhaseColumn, setShowSalesPhaseColumn] = useState(false);
  const [showLastContactedColumn, setShowLastContactedColumn] = useState(false);
  const [showTourDetailsColumn, setShowTourDetailsColumn] = useState(false);

  const emptyLabels = {
    EMPTY_HEADER: labels.FAMILY_FILE_NOT_FOUND_HEADER,
    EMPTY_DESCRIPTION_LINE1: labels.FAMILY_FILE_NOT_FOUND_DESCRIPTION_LINE1,
    EMPTY_DESCRIPTION_LINE2: labels.FAMILY_FILE_NOT_FOUND_DESCRIPTION_LINE2,
  };

  const salesPhaseColor = salesPhaseService.getLabelColor(bucketId);

  useEffect(() => {
    const salesPhaseFilterOptions = [
      {
        title: 'Filter by',
        type: 'checkbox',
        items: salesPhaseFilterItems,
      },
    ];

    setSalesPhaseOptions(salesPhaseFilterOptions);
  }, [salesPhaseFilterItems]);

  useEffect(() => {
    setShowContactColumn(columns.includes('contact'));
    setShowSalesPhaseColumn(columns.includes('salesPhase'));
    setShowLastContactedColumn(columns.includes('lastContacted'));
    setShowTourDetailsColumn(columns.includes('tourDetails'));
  }, [columns]);

  const handleRowClick = (row) => {
    setFamilyFileId(row.familyFileId);
    setIsCaseProfileOpen(true);
    registerEvent('FamilyFiles', 'Opened side panel');
  };

  const handleDetailsClick = (row, event) => {
    event.stopPropagation();
    history.push(`/family-file/${row.familyFileId}`);
  };

  const registerEventForMedicallyUrgentStatusChange = (
    medicallyUrgentLabels,
  ) => {
    const selectedFilters =
      medicallyUrgentLabels.length > 0
        ? medicallyUrgentLabels.join(',')
        : labels.NO_FILTER_SELECTED;
    registerEvent('FamilyFiles', 'Use of urgent filter', selectedFilters);
  };

  const handleMedicallyUrgentFilterChange = (medicallyUrgentItems) => {
    const medicallyUrgentStatusLabels = medicallyUrgentItems.map(
      (item) => item.label,
    );
    registerEventForMedicallyUrgentStatusChange(medicallyUrgentStatusLabels);

    onMedicallyUrgentFilterChange(medicallyUrgentItems);
  };

  const handleSalesPhaseFilterChange = (groups) => {
    let items = [];
    if (groups.length > 0) {
      items = groups[0].items;
    }
    onSalesPhaseFilterChange(items);
  };

  const getPrimaryContactDisplay = (row) => {
    return (
      <Fragment>
        <div>
          {row.primaryContact.includes('Unknown') ? (
            <Typography level="body">{row.primaryContact}</Typography>
          ) : (
            <div className={classes.row}>
              <Typography level="large" bold>
                {row.primaryContact}
              </Typography>
              <AudienceTag
                className={classes.audience_tag}
                audience={row.audienceKey}
                labels={labels}
              />
            </div>
          )}
          {row.relationship !== 'Self' &&
          row.relationship !== '' &&
          !row.primaryContact.includes('Unknown') ? (
            <Typography level="small" color="eerieBlack5">
              ({row.relationship})
            </Typography>
          ) : (
            <span></span>
          )}
        </div>
        <div className={classes.contact}>
          <User />
          <Typography level="tiny" color="eerieBlack5">
            {row.residents}
          </Typography>
        </div>
      </Fragment>
    );
  };

  const handlePageSizeChange = (newPageSizeItem) => {
    pageSizeItem = newPageSizeItem;
    onPageSizeChange(parseInt(pageSizeItem.value, 10));
  };

  const handlePageNumberChange = (pageNumber) => {
    onPageNumberChange(pageNumber);
  };

  return (
    <div>
      {isCaseProfileOpen && (
        <CaseProfileSidePanel
          labels={labels}
          showCommunitiesTab={true}
          familyFileId={familyFileId}
          isOpen={isCaseProfileOpen}
          onClose={() => setIsCaseProfileOpen(false)}
        />
      )}

      <div className={classes.scroll_container}>
        <Table>
          <TableHead className={clsx(classes.home_table_header)}>
            <TableRow className={clsx(classes.filter_row)}>
              <TableCell colSpan={3}>
                <Media
                  xs={
                    <MedicallyUrgentFilter
                      onChange={handleMedicallyUrgentFilterChange}
                      labels={labels}
                      filters={medicallyUrgentFilterItems}
                      title={labels.FILTER_BY}
                      size={'xs'}
                    />
                  }
                />
              </TableCell>
              {showPaging && (
                <TableCell colSpan={1} className={classes.page_size_container}>
                  <Media
                    xs={
                      <Dropdown
                        className={classes.page_size}
                        items={PAGE_SIZE_ITEMS}
                        label={labels.SHOW_LIST}
                        onChange={(item) => handlePageSizeChange(item)}
                        selectedItem={pageSizeItem}
                      />
                    }
                  />
                </TableCell>
              )}
              {!showPaging && <TableCell colSpan={1}></TableCell>}
            </TableRow>
            <TableRow>
              {showContactColumn && (
                <TableCell className={clsx(classes.item, classes.resident)}>
                  {labels.PRIMARY_CONTACT}
                </TableCell>
              )}
              {showSalesPhaseColumn && (
                <TableCell className={clsx(classes.item, classes.sales_phase)}>
                  {isSalesPhaseFixed ? (
                    labels.SALES_PHASE
                  ) : (
                    <RichDropdown
                      emptyLabel={labels.SALES_PHASE}
                      groups={salesPhaseOptions}
                      onChange={handleSalesPhaseFilterChange}
                    />
                  )}
                </TableCell>
              )}
              {showLastContactedColumn && (
                <TableCell
                  className={clsx(classes.item, classes.last_call_mobile)}
                >
                  {labels.LAST_CONTACTED}
                </TableCell>
              )}
              {showTourDetailsColumn && (
                <TableCell
                  className={clsx(classes.item, classes.last_call_mobile)}
                >
                  {labels.TOUR_DETAILS}
                </TableCell>
              )}
              <TableCell className={clsx(classes.item, classes.actions)}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          {rows.length > 0 && (
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index} onClick={() => handleRowClick(row)}>
                  {showContactColumn && (
                    <TableCell className={clsx(classes.item, classes.resident)}>
                      {row.isProfileDataAvailable ? (
                        getPrimaryContactDisplay(row)
                      ) : (
                        <Fragment>
                          <div>
                            <Typography level="large" color="smokyTopaz2">
                              {row.profileDataErrorMessage || ''}
                            </Typography>
                          </div>
                        </Fragment>
                      )}
                    </TableCell>
                  )}
                  {showSalesPhaseColumn && (
                    <TableCell
                      className={clsx(classes.item, classes.sales_phase_mobile)}
                    >
                      <Tag text={row.salesPhase} color={salesPhaseColor} />
                    </TableCell>
                  )}
                  {showLastContactedColumn && (
                    <TableCell
                      className={clsx(classes.item, classes.last_call_mobile)}
                    >
                      <HomeTableLastCallCell row={row} labels={labels} />
                    </TableCell>
                  )}
                  {showTourDetailsColumn && (
                    <TableCell
                      className={clsx(classes.item, classes.last_call_mobile)}
                    >
                      <HomeToursScheduledCell
                        row={row}
                        labels={{ UNKNOWN: labels.UNKNOWN }}
                      />
                    </TableCell>
                  )}
                  <TableCell className={clsx(classes.item, classes.actions)}>
                    <div className={classes.toolbar_container_mobile}>
                      <div className={classes.toolbar_button}>
                        {row.isProfileDataAvailable ? (
                          <div
                            onClick={(event) => handleDetailsClick(row, event)}
                          >
                            <span>{labels.DETAILS}</span>
                            <ArrowRight />
                          </div>
                        ) : (
                          <div className={classes.empty_details}></div>
                        )}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {showPaging && (
                <TableRow size="extended" className={classes.pagination_row}>
                  <TableCell colSpan={4}>
                    <Pagination
                      className={classes.pager}
                      currentPage={pageNumber}
                      onPageChange={(pageNumber) =>
                        handlePageNumberChange(pageNumber)
                      }
                      pageCount={pageCount}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
          {rows.length === 0 && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={4}>
                  <EmptyTable labels={emptyLabels}>
                    <NotFound />
                  </EmptyTable>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </div>
    </div>
  );
};

HomeTableWithRowsMobile.propTypes = {
  bucketId: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  labels: PropTypes.object.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      familyFileId: PropTypes.number.isRequired,
      primaryContact: PropTypes.string.isRequired,
      residents: PropTypes.string.isRequired,
      relationship: PropTypes.string.isRequired,
      createdAt: PropTypes.number,
      salesPhase: PropTypes.string.isRequired,
      lastcall: PropTypes.shape({
        type: PropTypes.string,
        completedAt: PropTypes.number,
        result: PropTypes.string,
      }),
    }),
  ).isRequired,
  salesPhaseFilterItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      checked: PropTypes.bool,
    }),
  ).isRequired,
  onSalesPhaseFilterChange: PropTypes.func,
  medicallyUrgentFilterItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      checked: PropTypes.bool,
    }),
  ),
  pageCount: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  onMedicallyUrgentFilterChange: PropTypes.func,
  onPageSizeChange: PropTypes.func.isRequired,
  onPageNumberChange: PropTypes.func.isRequired,
  showPaging: PropTypes.bool,
  isSalesPhaseFixed: PropTypes.bool,
};

HomeTableWithRowsMobile.defaultProps = {
  onMedicallyUrgentFilterChange: () => {},
  onSalesPhaseFilterChange: () => {},
  onPageSizeChange: () => {},
  onPageNumberChange: () => {},
  pageCount: 999999,
  pageNumber: 1,
  showPaging: false,
  isSalesPhaseFixed: false,
};

export default HomeTableWithRowsMobile;
export { DEFAULT_PAGE_SIZE };
