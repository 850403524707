const findScriptBySlugQuery = `
  query findScriptBySlug($slug: String!) {
    findScriptBySlug(slug: $slug) {
      scriptId
      slug
      name
      description
      target
      type
      version
      versionName
      updatedAt
      steps {
        stepId
        number
        order
        name
        size
        text
        type
        isHidden
        isLegal
        isOptional
        isPipeda
        alternateText
        property
        answers {
          answerId
          order
          label
          value
          nextStep
          closeInquiry
          addToDoNotCall
          shows
          hides
          selectedRule
          action
          closeReason
          disposition
          urlLink
          poolType
        }
      }
    }
  }
`;

export default findScriptBySlugQuery;
