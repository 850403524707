import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  community_info_container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    width: '385px',
    marginLeft: '24px',
  },
  community_info_container_xs: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    marginLeft: '24px',
    width: '300px',
    marginTop: '24px',
  },
  community_info_small_map_container_xs: {
    width: '230px',
  },
  company_name: {
    marginTop: '4px',
  },
  contact_container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '8px',
  },
  contact: {
    display: 'flex',
    '& svg': {
      marginRight: '6px',
      color: theme.palette.neutral.cosmicCobalt1,
      fill: theme.palette.neutral.cosmicCobalt1,
      fontSize: '15px',
      marginTop: '1px',
    },
  },
  tags: {
    display: 'flex',
    marginTop: '12px',
    alignItems: 'center',
    '& div:last-child': {
      marginLeft: '5px',
    },
  },
  care_type: {
    alignSelf: 'flex-start',
    height: '28px',
    width: '28px',
    boxSizing: 'border-box',
    borderRadius: '50%',
    textAlign: 'center',
    color: theme.palette.dark.eerieBlack1,
    backgroundColor: theme.palette.care.regalia5,
    marginRight: '4px',
  },
  miles: {
    color: theme.palette.dark.eerieBlack5,
  },
  distance: {
    paddingLeft: '4px',
    minWidth: '55px',
  },
  rating_container: {
    display: 'flex',
    marginTop: '4px',
    alignItems: 'center',
  },
  rating_star_container: {
    display: 'flex',
    marginLeft: '4px',
  },
  no_rating_container: {
    display: 'flex',
    alignItems: 'center',
  },
  rating_star: {
    '& path': {
      fill: theme.palette.miscellaneous2.fieldDrab4,
    },
  },
  review_count: {
    marginLeft: '4px',
    '& > a': {
      fontSize: '14px',
      color: theme.palette.dark.eerieBlack4,
      '&:active': {
        color: theme.palette.dark.eerieBlack4,
      },
      '&:visited': {
        color: theme.palette.dark.eerieBlack4,
      },
    },
  },
  no_rating: {
    marginLeft: '4px',
    '& path': {
      fill: theme.palette.dark.disabled,
    },
  },
}));

export { useStyles };
