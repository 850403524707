import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './HeaderActivityCard.style';
import CallPhone from 'common/Icons/call/CallPhone';
import Mail from 'common/Icons/basic/Mail';
import Typography from 'common/Typography/Typography';
import { getTodayOrPastDate } from 'utils/dateFormat';
import { colorReference } from 'common/Theme/themes/default';

const HeaderActivityCard = ({ mode, type, date, color, dueLabel }) => {
  const classes = useStyles();

  const getIcon = () => {
    if (type.indexOf('Call') >= 0) {
      return <CallPhone />;
    }
    return <Mail />;
  };

  const getDate = () => {
    if (mode !== 'history') {
      const prefix = `${dueLabel}: `;
      return (
        <Fragment>
          <Typography level="tiny" color="eerieBlack5">
            {prefix}
          </Typography>
          <Typography level="tiny" color={color}>
            <b>{getTodayOrPastDate(date)}</b>
          </Typography>
        </Fragment>
      );
    }
    return '';
  };

  return (
    <Fragment>
      <div className={classes.row}>
        <div className={classes.inline}>
          {getIcon()}
          <Typography>
            <b>{type}</b>
          </Typography>
          <div>{getDate()}</div>
        </div>
      </div>
    </Fragment>
  );
};

HeaderActivityCard.propTypes = {
  dueLabel: PropTypes.string,
  mode: PropTypes.oneOf(['todo', 'upcoming', 'history']),
  type: PropTypes.string.isRequired,
  date: PropTypes.number.isRequired,
  color: PropTypes.oneOf([...colorReference, 'transparent']),
};

HeaderActivityCard.defaultProps = {
  color: 'transparent',
  dueLabel: 'Due',
  mode: 'history',
};

export default HeaderActivityCard;
