import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './DatePicker.style';
import { DatePicker as MaterialDatePicker } from '@material-ui/pickers';
import ArrowLeft from '../Icons/arrow/ArrowLeft';
import ArrowRight from '../Icons/arrow/ArrowRight';
import TextInput from '../TextInput/TextInput';
import CalendarDates from '../Icons/time/CalendarDates';
import { Popover } from '@material-ui/core';
import { format } from 'date-fns';
import SmartTooltip from '../../common/SmartTooltip/SmartTooltip';
import { generateId } from '../utils/random';
import Typography from '../../common/Typography/Typography';
import clsx from 'clsx';

const DatePicker = ({
  dateSelected,
  onChange,
  label,
  disableToolbar,
  disablePast,
  disableFuture,
  disableDatesTooltipTitle,
  id,
  defaultLabel,
  error,
  textHint,
  minDate,
  maxDate,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dateSelected || new Date());
  const INPUT_DATE_FORMAT = 'MMM d, y (EEE)';

  useEffect(() => {
    document.activeElement.blur();
  }, [selectedDate, isOpen]);

  const handleOnChange = (date) => {
    setSelectedDate(date);
    onChange(date);
    setIsOpen(false);
  };

  const showCalendar = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const renderDay = (day, selectedDate, dayInCurrentMonth, dayComponent) => {
    if (
      (minDate || maxDate || disablePast || disableFuture) &&
      dayComponent.props.disabled
    ) {
      return (
        <SmartTooltip
          title={disableDatesTooltipTitle}
          placement="top"
          cursor="not-allowed"
        >
          {dayComponent}
        </SmartTooltip>
      );
    }

    return dayComponent;
  };

  const inputClass = clsx(
    classes.date_picker_text_input,
    isOpen && classes.date_picker_text_input_with_shadow,
  );

  const getTextInputValue = () => {
    return defaultLabel && !dateSelected
      ? defaultLabel
      : format(selectedDate, INPUT_DATE_FORMAT);
  };

  return (
    <>
      <TextInput
        className={inputClass}
        label={label}
        onClick={showCalendar}
        startIcon={<CalendarDates />}
        value={getTextInputValue()}
        id={id}
        error={error}
      />
      {error && (
        <Typography level="small" className={classes.error_text}>
          {textHint}
        </Typography>
      )}

      {isOpen && (
        <div>
          <Popover
            open={isOpen}
            className={classes.date_picker_container}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            anchorEl={anchorEl}
            onClose={() => setIsOpen(false)}
            onClick={(e) => e.stopPropagation()}
          >
            <MaterialDatePicker
              value={selectedDate}
              onChange={handleOnChange}
              disableToolbar={disableToolbar}
              variant={'static'}
              label={label}
              format={INPUT_DATE_FORMAT}
              className={classes.date_picker_container}
              leftArrowIcon={<ArrowLeft className={classes.arrow} />}
              rightArrowIcon={<ArrowRight className={classes.arrow} />}
              disablePast={disablePast}
              renderDay={renderDay}
              open={isOpen}
              disableFuture={disableFuture}
              minDate={minDate}
              maxDate={maxDate}
            />
          </Popover>
        </div>
      )}
    </>
  );
};

DatePicker.propTypes = {
  dateSelected: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disableToolbar: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  label: PropTypes.string.isRequired,
  disableDatesTooltipTitle: PropTypes.string,
  id: PropTypes.string,
  defaultLabel: PropTypes.string,
  error: PropTypes.bool,
  textHint: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

DatePicker.defaultProps = {
  disableToolbar: true,
  disablePast: false,
  disableFuture: false,
  id: generateId(),
  defaultLabel: null,
  error: false,
  textHint: '',
};

export default DatePicker;
