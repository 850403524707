import React from 'react';
import PropTypes from 'prop-types';

const Plus = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="currentColor" d="M13 11H22V13H13V22H11V13H2V11H11V2H13V11Z" />
    </svg>
  );
};

Plus.propTypes = {
  className: PropTypes.string,
};

export default Plus;
