import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => {
  return {
    modal_sm: {
      width: '80%',
      height: '100%',
      '& div > p > b ': {
        fontSize: '13px',
      },
    },
    modal_xs: {
      width: '80%',
      height: '100%',
      '& div > p > b ': {
        fontSize: '13px',
      },
    },
  };
});

export { useStyles };
