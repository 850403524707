import cloneDeep from 'lodash.clonedeep';
import { CHICLET_CARD_STATES } from '../../pages/callCenter/Inquiry/FormChiclets/ChicletCard/ChicletCard';

const updateSteps = (step, formSteps) => {
  const position = _getPosition(step);
  const rowSteps = formSteps.filter((st) => _getPosition(st) === position);
  const shouldCollapse = rowSteps.every(
    (st) => st.state === CHICLET_CARD_STATES.ANSWERED.value,
  );
  if (shouldCollapse) {
    return formSteps.map((st) => {
      if (rowSteps.some((rs) => rs.number === st.number)) {
        return { ...st, ...{ collapsed: true } };
      }
      return st;
    });
  }
  return formSteps;
};

const updateOnResize = (formSteps) => {
  const newFormSteps = cloneDeep(formSteps);
  const groups = _groupSteps(newFormSteps);
  newFormSteps.forEach((st) => (st.collapsed = false));
  const keys = Object.keys(groups);
  for (const key in keys) {
    const groupSteps = groups[keys[key]];
    const shouldCollapse = groupSteps.every(
      (st) => st.state === CHICLET_CARD_STATES.ANSWERED.value,
    );
    if (shouldCollapse) {
      newFormSteps.forEach((st) => {
        if (groupSteps.some((gs) => gs.number === st.number)) {
          st.collapsed = true;
        }
      });
    }
  }
  return newFormSteps;
};

const _getPosition = (step) => {
  const id = `${step.number}. ${step.name}`;
  const element = document.getElementById(id);
  if (element) {
    return element.getBoundingClientRect().y;
  }
  return 0;
};

const _groupSteps = (formSteps) => {
  return formSteps.reduce((acc, curr) => {
    const position = _getPosition(curr);
    if (!acc[position]) {
      acc[position] = [];
    }
    acc[position].push(curr);
    return acc;
  }, {});
};

export default { updateSteps, updateOnResize };
