import gql from 'graphql-tag';

export default gql`
  mutation(
    $inquiryId: Int!
    $assignedTo: Int!
    $isFamilyFileClosedOrMoveIn: Boolean
  ) {
    createFamilyFileForWarmTransfer(
      input: {
        inquiryId: $inquiryId
        assignedTo: $assignedTo
        isFamilyFileClosedOrMoveIn: $isFamilyFileClosedOrMoveIn
      }
    ) {
      familyFileId
      externalId
    }
  }
`;
