export const GET_SCRIPT_BY_SLUG_REQUEST = 'scriptPreview/getBySlug/request';
export const GET_SCRIPT_BY_SLUG_SUCCESS = 'scriptPreview/getBySlug/success';
export const GET_SCRIPT_BY_SLUG_ERROR = 'scriptPreview/getBySlug/error';

export const UPDATE_PREVIEW_FORM_STEPS = 'scriptPreview/updateFormSteps';

export const getScriptBySlugRequest = (slug) => ({
  type: GET_SCRIPT_BY_SLUG_REQUEST,
  payload: { slug },
});

export const updatePreviewFormSteps = (formSteps) => {
  return { type: UPDATE_PREVIEW_FORM_STEPS, payload: { formSteps } };
};

export const getScriptBySlugSuccess = (response) => {
  return { type: GET_SCRIPT_BY_SLUG_SUCCESS, payload: { response } };
};

export const getScriptBySlugError = (error) => {
  return { type: GET_SCRIPT_BY_SLUG_ERROR, payload: { error } };
};
