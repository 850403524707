import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ChevronRight from '../../Icons/arrow/ChevronRight';
import ChevronLeft from '../../Icons/arrow/ChevronLeft';
import { useStyles } from './MobilePagination.style';

const MobilePagination = ({
  className,
  currentPage,
  pageCount,
  goToPageLabel,
  onPageChange,
}) => {
  const classes = useStyles();

  const [goToPage, setGoToPage] = useState(0);

  const handlePrevPageChange = () => {
    onPageChange(currentPage - 1);
  };

  const handleNextPageChange = () => {
    onPageChange(currentPage + 1);
  };

  const shouldEnableGoToPageButton = () => {
    return goToPage > 0 && goToPage <= pageCount;
  };

  const handleGoToPage = (page) => {
    onPageChange(page);
  };

  return (
    <div className={clsx(classes.container, className)}>
      <button
        data-testid="prevBtn"
        disabled={currentPage === 1}
        className={clsx(classes.prev, currentPage === 1 && classes.disabled)}
        onClick={handlePrevPageChange}
      >
        <ChevronLeft />
      </button>

      <div className={classes.page_info}>
        Page {currentPage} of {pageCount}
      </div>

      <button
        data-testid="nextBtn"
        disabled={currentPage === pageCount}
        className={clsx(
          classes.next,
          currentPage === pageCount && classes.disabled,
        )}
        onClick={handleNextPageChange}
      >
        <ChevronRight />
      </button>
      <div className={classes.go_to_page}>
        <label>{goToPageLabel}</label>
        <input
          data-testid="goToPageInput"
          onChange={(e) => setGoToPage(parseInt(e.target.value))}
        />
        <button
          data-testid="goToPageBtn"
          disabled={!shouldEnableGoToPageButton()}
          className={clsx(
            classes.go_to_page_button,
            !shouldEnableGoToPageButton() && classes.disabled,
          )}
          onClick={() => handleGoToPage(goToPage)}
        >
          <ChevronRight />
        </button>
      </div>
    </div>
  );
};

MobilePagination.propTypes = {
  className: PropTypes.string,
  currentPage: PropTypes.number,
  pageCount: PropTypes.number,
  goToPageLabel: PropTypes.string,
  onPageChange: PropTypes.func,
};

MobilePagination.defaultProps = {
  currentPage: 1,
  onPageChange: () => {},
  goToPageLabel: 'Go to page',
};

export default MobilePagination;
