import gql from 'graphql-tag';

export default gql`
  mutation(
    $familyFileId: Int!
    $communityId: Int!
    $familyCommunityTourId: Int!
    $completedActivityDate: Date!
    $completedTourType: String!
    $activityTypeCode: String!
    $timeOffset: Int!
  ) {
    completeTour(
      input: {
        familyFileId: $familyFileId
        communityId: $communityId
        familyCommunityTourId: $familyCommunityTourId
        completedActivityDate: $completedActivityDate
        completedTourType: $completedTourType
        activityTypeCode: $activityTypeCode
        timeOffset: $timeOffset
      }
    ) {
      activityDate
      tourType
    }
  }
`;
