import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useStyles } from './Communities.style';
import Button from '../../common/Button/Button';
import Typography from '../../common/Typography/Typography';
import User from '../../common/Icons/basic/User';
import CaseProfileSidePanel from '../../components/CaseProfileSidePanel/CaseProfileSidePanel';
import { withLabels } from '../../context/LabelContext';
import useGraphQLQuery from '../../hooks/useGraphQLQuery';
import communitiesQuery from './communitiesQuery';
import Loading from '../../components/Loading/Loading';
import LocationFilter from '../../common/Filters/LocationFilter/LocationFilter';
import DistanceFilter from '../../common/Filters/DistanceFilter/DistanceFilter';
import BudgetFilter from '../../common/Filters/BudgetFilter/BudgetFilter';
import CareTypeFilter from '../../common/Filters/CareTypeFilter/CareTypeFilter';
import CommunitiesTable from './CommunitiesTable/CommunitiesTable';
import CommunitiesDropDown from './CommunitiesDropDown/CommunitiesDropDown';
import { registerEvent, registerPage } from '../../services/Analytics';
import {
  careTypeLookup,
  getInitialBudget,
  getInitialCareTypeCodes,
  getInitialCareTypeFilter,
  getInitialPreferredLocations,
  getAppError,
} from './CommunitiesService';
import referredCommunityService from '../../services/referredCommunitiesService';
import familyFileCommunitiesQuery from '../../components/Community/familyFileCommunitiesQuery';
import clsx from 'clsx';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import DocumentHead from '../../common/DocumentHead/DocumentHead';
import useSocket from '../../hooks/useSocket';
import { getFamilyFileIdFromUrl, getKeyFromValue } from '../../utils/utils';
import Options from '../../common/Icons/basic/Options';
import MoreFiltersSidePanel from '../../components/MoreFiltersSidePanel/MoreFiltersSidePanel';

const Communities = ({ labels, flags }) => {
  const location = useLocation();

  const [initialCareTypeCodes, setInitialCareTypeCodes] = useState([]);
  const [initialBudget, setInitialBudget] = useState({ min: 0, max: 0 });
  const [distanceFilter, setDistanceFilter] = useState('20');
  const [budgetFilter, setBudgetFilter] = useState({});
  const [locationFilter, setLocationFilter] = useState({});
  const [careTypeFilter, setCareTypeFilter] = useState([]);
  const [savedCommunities, setSavedCommunities] = useState([]);
  const [referredCommunities, setReferredCommunities] = useState([]);
  const familyFileIdFromUrl = getFamilyFileIdFromUrl(location);
  const [attributeNamesFilter, setAttributeNamesFilter] = useState([]);
  const [groupNamesFilter, setGroupNamesFilter] = useState([]);
  const [trackCareTypeFilter, setTrackCareTypeFilter] = useState(false);
  const [careTypeFilterWithLabels, setCareTypeFilterWithLabels] = useState([]);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [isMoreFiltersSidePanelOpen, setIsMoreFiltersSidePanelOpen] = useState(
    false,
  );
  const [appError, setAppError] = useState(null);
  const [familyFileId, setFamilyFileId] = useState();
  const [familyFileSalesPhase, setFamilyFileSalesPhase] = useState('');
  const [shouldClearMoreFilters, setShouldClearMoreFilters] = useState(false);
  const [filterToRemove, setFilterToRemove] = useState('');

  const classes = useStyles();
  let familyFileUserId = useRef(0);

  const {
    loading: communitiesLoading,
    error: communitiesError,
    data: communitiesData,
  } = useGraphQLQuery(communitiesQuery, {
    variables: { familyFileId: familyFileIdFromUrl },
  });
  const {
    loading: dropdownLoading,
    error: dropdownError,
    data: dropdownData,
  } = useGraphQLQuery(familyFileCommunitiesQuery, {
    variables: { familyFileId: familyFileIdFromUrl },
  });

  const onDistanceFilterChange = (distance) => {
    if (distance !== distanceFilter) {
      registerEvent(
        'CommunitySearchFilters',
        'Used Distance Filter',
        distance,
        true,
      );
      setDistanceFilter(distance);
    }
  };

  const checkHomeCareDistanceFilter = (careTypes) => {
    if (careTypes.includes('homeCare')) {
      setDistanceFilter('0');
    }
  };

  useEffect(() => {
    registerPage('/communities');
  }, []);

  useEffect(() => {
    registerPage(location.pathname);
  }, [location]);

  useEffect(() => {
    const careTypesCodes = careTypeFilter.map((ct) => {
      return getKeyFromValue(careTypeLookup, ct);
    });

    setInitialCareTypeCodes([...careTypesCodes]);
  }, [careTypeFilter]);

  useEffect(() => {
    if (
      dropdownData &&
      dropdownData.findFamilyFileById &&
      dropdownData.findFamilyFileById.communities
    ) {
      const nonReferredSavedCommunities = referredCommunityService.buildSavedCommunities(
        dropdownData.findFamilyFileById.referralStatus,
        dropdownData.findFamilyFileById.communities,
      );
      setSavedCommunities(nonReferredSavedCommunities);
      const referredCommunities = referredCommunityService.filteredReferredCommunities(
        dropdownData.findFamilyFileById.referralStatus,
      );
      setReferredCommunities(referredCommunities);
      setFamilyFileId(dropdownData.findFamilyFileById.familyFileId);
    }
  }, [dropdownData]);

  useEffect(() => {
    if (trackCareTypeFilter) {
      let label = labels.NO_FILTER_SELECTED;
      if (careTypeFilterWithLabels.length > 0) {
        label = careTypeFilterWithLabels.join(', ');
      }
      registerEvent(
        'CommunitySearchFilters',
        'Used Care Type Filter',
        label,
        true,
      );
    }
  }, [
    trackCareTypeFilter,
    careTypeFilterWithLabels,
    labels.NO_FILTER_SELECTED,
  ]);

  useEffect(() => {
    let careTypeCodes = [];
    if (communitiesData?.findFamilyFileById?.residents) {
      careTypeCodes = communitiesData.findFamilyFileById.residents[0].careTypes.map(
        (careType) => {
          return careType.code;
        },
      );
    }
    const initialCareTypeCodes = getInitialCareTypeCodes(
      careTypeCodes,
      location,
    );
    setInitialCareTypeCodes([...initialCareTypeCodes]);

    const initialCareTypeFilter = getInitialCareTypeFilter(
      initialCareTypeCodes,
    );
    setCareTypeFilter(initialCareTypeFilter);

    checkHomeCareDistanceFilter(initialCareTypeFilter);

    const initialBudget = getInitialBudget(
      communitiesData?.findFamilyFileById?.leadData?.budgetDescription,
      location,
    );
    setInitialBudget(initialBudget);

    setLocationFilter({});

    if (communitiesData) {
      familyFileUserId.current = communitiesData.findFamilyFileById.userId;

      let preferredLocations = communitiesData.findFamilyFileById.familyDesiredLocations.map(
        (location) => {
          return {
            label: `${location.city}, ${location.state} ${location.zip}`,
            value: location.zip,
          };
        },
      );
      preferredLocations = getInitialPreferredLocations(
        preferredLocations,
        location,
      );
      setLocationFilter({
        zip: preferredLocations[0].value,
        address: preferredLocations[0].label,
        state: preferredLocations[0].label.replace(/.*,\s([A-Z]{2})\s.*/, '$1'),
      });
      setFamilyFileSalesPhase(communitiesData.findFamilyFileById?.salesPhase);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communitiesData]);

  const loadAppError = (error) => {
    console.error(error);
    return getAppError(error, classes.error_icon);
  };

  const reloadComponent = (data) => {
    if (data === 'reload') {
      window.location.reload();
    }
  };

  useSocket(familyFileId, reloadComponent);

  if (appError) return loadAppError(appError);
  if (communitiesLoading) return <Loading />;
  if (communitiesError) return loadAppError(communitiesError);
  if (dropdownLoading) return <Loading />;
  if (dropdownError) return loadAppError(dropdownError);

  const primaryContactName =
    communitiesData.findFamilyFileById?.contacts?.length > 0
      ? `${communitiesData.findFamilyFileById.contacts[0].profileData.firstName} ${communitiesData.findFamilyFileById.contacts[0].profileData.lastName}`
      : labels.UNKNOWN;

  const getDocumentTitle = () =>
    labels.PRIMARY_CONTACT_NAME_COMMUNITY_SEARCH_TITLE.replace(
      '{primaryContactName}',
      primaryContactName,
    );

  let preferredLocations = communitiesData.findFamilyFileById.familyDesiredLocations.map(
    (location) => {
      return {
        label: `${location.city}, ${location.state} ${location.zip}`,
        value: location.zip,
      };
    },
  );
  preferredLocations = getInitialPreferredLocations(
    preferredLocations,
    location,
  );

  const dataForSegment = {
    familyFileId: familyFileId,
    oneId: communitiesData.findFamilyFileById.oneId,
  };

  const handleSaveCommunity = (community) => {
    const customDimension = [
      {
        name: 'COMMUNITY_ID',
        value: community.id,
      },
    ];
    registerEvent(
      'CommunitySearchPage',
      'Added Community',
      community.rank,
      false,
      customDimension,
    );
    if (
      !savedCommunities.find(
        (savedCommunity) => savedCommunity.id === community.id,
      )
    ) {
      savedCommunities.push(community);
    }
    setSavedCommunities([...savedCommunities]);
  };

  const handleRemoveCommunity = (community) => {
    registerEvent('CommunitySearchPage', 'Removed Community', undefined, true);
    const communityId = community.id;
    const newCommunities = savedCommunities.filter(
      (community) => community.id !== communityId,
    );
    setSavedCommunities([...newCommunities]);
  };

  const onRemoveOtherFilters = () => {
    setAttributeNamesFilter([]);
    setGroupNamesFilter([]);
    setShouldClearMoreFilters(true);
  };

  const onRemoveSingleFilter = (tag) => {
    const newCareTypes = careTypeFilter.filter((c) => c !== tag);
    const newAttributeNames = attributeNamesFilter.filter(
      (c) => c.value !== tag?.value,
    );
    const newGroupNames = groupNamesFilter.filter(
      (c) => c.value !== tag?.value,
    );

    setFilterToRemove(tag.label);

    setAttributeNamesFilter([...newAttributeNames]);
    setCareTypeFilter([...newCareTypes]);
    setGroupNamesFilter([...newGroupNames]);
  };

  const handleOnMapChange = ({ city, state, zip }) => {
    setLocationFilter({
      address: `${city}, ${state} ${zip}`,
      zip,
      state,
    });
  };

  const handleCommunitiesDropdownClose = () => {
    window.opener = null;
    window.open('', '_self');
    window.close();
  };

  const handleCareTypeFilterChange = (careTypes) => {
    if (
      JSON.stringify(careTypeFilter) ===
      JSON.stringify(careTypes.map((item) => item.value))
    ) {
      setTrackCareTypeFilter(false);
    } else {
      setTrackCareTypeFilter(true);
    }
    setCareTypeFilterWithLabels(careTypes.map((item) => item.label));
    const careTypeValues = careTypes.map((item) => item.value);
    setCareTypeFilter(careTypeValues);
    setInitialCareTypeCodes(careTypes.map((ct) => ct.code));
    checkHomeCareDistanceFilter(careTypeValues);
  };

  const familyButtonClasses = clsx([
    !flags.communitySearchUiFromYgl && classes.family_file_button,
    flags.communitySearchUiFromYgl && classes.family_file_button_disabled,
  ]);

  const handleMoreFiltersChange = (moreFilters) => {
    const selectedFilters = moreFilters.filter((filter) => filter.selected);
    setAttributeNamesFilter(selectedFilters);

    if (shouldClearMoreFilters) {
      setShouldClearMoreFilters(false);
    }

    setIsMoreFiltersSidePanelOpen(false);
  };

  const onBudgetFilterChange = (budget) => {
    if (
      JSON.stringify(budgetFilter) !== '{}' &&
      JSON.stringify(budgetFilter) !== JSON.stringify(budget)
    ) {
      registerEvent(
        'CommunitySearchFilters',
        'Used Budget Filter',
        undefined,
        true,
      );
    }
    setBudgetFilter(budget);
  };

  return (
    <div className={classes.communities_container}>
      <DocumentHead title={getDocumentTitle()} />
      <CaseProfileSidePanel
        familyFileId={familyFileId}
        labels={labels}
        isOpen={isSidePanelOpen}
        onClose={() => setIsSidePanelOpen(false)}
      />
      <div className={classes.top_bar}>
        <Button
          className={familyButtonClasses}
          startIcon={<User />}
          onClick={() => setIsSidePanelOpen(true)}
        >
          <Typography color="platinum5">
            {`${primaryContactName} (${labels.ID}: ${familyFileId})`}
          </Typography>
        </Button>
        <CommunitiesDropDown
          familyFileId={familyFileId}
          familyFileUserId={familyFileUserId.current}
          flags={flags}
          labels={labels}
          onClose={handleCommunitiesDropdownClose}
          onRemove={(newCommunities) => {
            registerEvent(
              'CommunitySearchPage',
              'Removed Community',
              undefined,
              true,
            );
            setSavedCommunities(newCommunities);
          }}
          savedCommunities={savedCommunities}
          dataForSegment={dataForSegment}
          communitySearchUiFromYgl={flags.communitySearchUiFromYgl}
        />
      </div>
      <div className={classes.filters_container}>
        <LocationFilter
          preferredLocations={preferredLocations}
          onChange={(location) => {
            if (
              location.address &&
              locationFilter.address !== location.address
            ) {
              registerEvent(
                'CommunitySearchFilters',
                'Used Location Filter',
                location.address,
                true,
              );
            }
            setLocationFilter(location);
          }}
          className={classes.filter}
          selectedValue={locationFilter.address}
          labels={labels}
        />
        <DistanceFilter
          onChange={onDistanceFilterChange}
          className={classes.filter}
          labels={labels}
          selectedValue={distanceFilter}
          disabled={
            careTypeFilter.includes('homeCare') && careTypeFilter.length === 1
          }
        />
        <BudgetFilter
          onChange={onBudgetFilterChange}
          className={classes.filter}
          labels={labels}
          minInitialValue={initialBudget.min}
          maxInitialValue={initialBudget.max}
        />
        <CareTypeFilter
          careTypeCodes={initialCareTypeCodes}
          onChange={handleCareTypeFilterChange}
          className={classes.filter}
          labels={labels}
        />
        <Button
          className={classes.more_filters_button}
          onClick={() => setIsMoreFiltersSidePanelOpen(true)}
          startIcon={<Options />}
          type="outlined"
          size="small"
        >
          {labels.MORE_FILTERS}
        </Button>
      </div>
      <div className={classes.body}>
        <CommunitiesTable
          onSave={handleSaveCommunity}
          onRemove={handleRemoveCommunity}
          careTypeFilter={careTypeFilter}
          careTypeFilterWithLabels={careTypeFilterWithLabels}
          familyFileId={familyFileId}
          familyFileUserId={familyFileUserId.current}
          familyFileSalesPhase={familyFileSalesPhase}
          labels={labels}
          onMapChange={handleOnMapChange}
          savedCommunities={savedCommunities}
          referredCommunities={referredCommunities}
          distanceFilter={distanceFilter}
          locationFilter={locationFilter}
          budgetFilter={budgetFilter}
          attributeNamesFilter={attributeNamesFilter}
          groupNamesFilter={groupNamesFilter}
          otherFilters={[...attributeNamesFilter, ...groupNamesFilter]}
          onRemoveFilters={onRemoveOtherFilters}
          onRemoveSingleFilter={onRemoveSingleFilter}
          dataForSegment={dataForSegment}
          communitySearchUiFromYgl={flags.communitySearchUiFromYgl}
          onAppError={setAppError}
        />
      </div>
      {isMoreFiltersSidePanelOpen && (
        <MoreFiltersSidePanel
          labels={labels}
          selectedMoreFilters={attributeNamesFilter}
          shouldClearFilters={shouldClearMoreFilters}
          filterToRemove={filterToRemove}
          onChange={handleMoreFiltersChange}
          isOpen={isMoreFiltersSidePanelOpen}
          onClose={() => setIsMoreFiltersSidePanelOpen(false)}
        />
      )}
    </div>
  );
};
Communities.propTypes = {
  labels: PropTypes.object,
  flags: PropTypes.shape({
    communitySearchUiFromYgl: PropTypes.bool,
  }),
};
Communities.defaultProps = {};
export default withLDConsumer()(withLabels(Communities));
