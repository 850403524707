const reAssignFamilyFileMutation = `
  mutation reAssignFamilyFile(
    $familyFileId: Int
    $assignedTo: Int
    $sendCreatedEvent: Boolean
    $sendReassignedEvent: Boolean
    $inquiryId: Int
  ) {
    reAssignFamilyFile(
      input: {
        familyFileId: $familyFileId
        assignedTo: $assignedTo
        sendCreatedEvent: $sendCreatedEvent
        sendReassignedEvent: $sendReassignedEvent
        inquiryId: $inquiryId
      }
    ) {
      familyFileId
      userId
    }
  }
`;
export default reAssignFamilyFileMutation;
