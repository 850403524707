export default {
  CONTEXT_EMPTY: 'Phone Context empty, please reload',
  DESCRIPTION_UNABLE_TO_CANCEL_WT:
    'SLA already notified, unable to cancel warm transfer.',
  DESCRIPTION_WARM_TRANSFER_CANCELLED:
    'The Warm Transfer has been successfully cancelled',
  ERROR_CALL: 'Please start a call to initiate warm transfer',
  ERROR_CONTEXT: 'Telephony context not loaded, please refresh the page',
  ERROR_RELATIONSHIP: 'Please select a relationship to start warm transfer',
  ERROR_SLA:
    'SLA failed to connect to conference, please restart the pinging process',
  ERROR_ZIP: 'Please select a postal code to start warm transfer',
  FAILED_TO_CONNECT_SLA:
    'SLA failed to connect to conference, trying to connect again',
  FINDING_BEST_SLA: 'Finding Best SLA',
  RETRYING_SLA: 'Retrying SLA',
  SEARCHING_EXISTING: 'Trying to Contact Existing SLA',
  SEARCHING_EXISTING_TEXT: '',
  THERE_WAS_AN_ERROR_DURING_WARM_TRANSFER:
    'There was an error during the warm transfer please try again',
  TRYING_TO_CONTACT_SLA: 'Trying to contact SLA',
  UNABLE_TO_CANCEL_WT: 'Unable to Cancel Warm Transfer',
  WAITING_SLA: 'Waiting SLA',
  WARM_TRANSFER_CANCELLED: 'Warm Transfer Cancelled',
  WARM_TRANSFER_ERROR: 'Warm Transfer Error',
  WINNER_SLA_EMAIL: 'Winner SLA Email',
  WINNER_SLA_NAME: 'Winner SLA Name',
  WINNER_SLA_PHONE: 'Winner SLA Phone',
  WINNER_SLA_STATUS: 'Winner SLA status',

  WINNER_SLA_USERNAME: 'Winner SLA Username',
};
