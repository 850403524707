import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useStyles } from './HomeTable.style';
import Typography from '../../common/Typography/Typography';
import ArrowRight from '../../common/Icons/arrow/ArrowRight';
import CaseProfileSidePanel from '../CaseProfileSidePanel/CaseProfileSidePanel';
import { registerEvent } from '../../services/Analytics';
import EmptyTable from './EmptyTable';
import clsx from 'clsx';
import User from '../../common/Icons/basic/User';
import Media from '../../common/Media/Media';
import Tag from '../../common/Tag/Tag';
import Pagination from '../../common/Pagination/Pagination';
import NotFound from '../../common/Graphics/familyFile/NotFound';
import RichDropdown from '../../common/RichDropdown/RichDropdown';
import SortButton from '../../common/SortButton/SortButton';
import Table from '../../common/Table/Table/Table';
import TableHead from '../../common/Table/TableHead/TableHead';
import TableRow from '../../common/Table/TableRow/TableRow';
import TableCell from '../../common/Table/TableCell/TableCell';
import TableBody from '../../common/Table/TableBody/TableBody';
import HomeToursScheduledCell from './HomeToursScheduledCell';
import HomeTableLastCallCell from './HomeTableLastCallCell';
import MedicallyUrgentFilter from '../../common/Filters/MedicallyUrgentFilter/MedicallyUrgentFilter';
import Toast from '../../common/Toast/Toast';
import salesPhaseService from '../../services/HomeTable/salesPhaseService';
import Dropdown from '../../common/Dropdown/Dropdown';
import AudienceTag from '../AudienceTag/AudienceTag';

const PAGE_SIZE_ITEMS = [
  { label: '10', value: '10' },
  { label: '25', value: '25' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
];
const DEFAULT_PAGE_SIZE_ITEM = PAGE_SIZE_ITEMS[0];
const DEFAULT_PAGE_SIZE = parseInt(PAGE_SIZE_ITEMS[0].value, 10);

let pageSizeItem = DEFAULT_PAGE_SIZE_ITEM;

const HomeTableWithRows = ({
  bucketId,
  columns,
  handleSort,
  isSalesPhaseFixed,
  labels,
  medicallyUrgentFilterItems,
  onMedicallyUrgentFilterChange,
  onPageNumberChange,
  onPageSizeChange,
  onSalesPhaseFilterChange,
  order,
  pageCount,
  pageNumber,
  rows,
  salesPhaseFilterItems,
  showPaging,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [isCaseProfileOpen, setIsCaseProfileOpen] = useState(false);
  const [familyFileId, setFamilyFileId] = useState(0);
  const [salesPhaseOptions, setSalesPhaseOptions] = useState([]);
  const [salesPhaseFilter, setSalesPhaseFilter] = useState([]);
  const [showProfileError, setShowProfileError] = useState(false);
  const [profileError, setProfileError] = useState('');
  const [medicallyUrgentFilterTags, setMedicallyUrgentFilterTags] = useState(
    [],
  );

  const [showContactColumn, setShowContactColumn] = useState(false);
  const [showSalesPhaseColumn, setShowSalesPhaseColumn] = useState(false);
  const [showLastContactedColumn, setShowLastContactedColumn] = useState(false);
  const [showTourDetailsColumn, setShowTourDetailsColumn] = useState(false);

  const emptyLabels = {
    EMPTY_HEADER: labels.FAMILY_FILE_NOT_FOUND_HEADER,
    EMPTY_DESCRIPTION_LINE1: labels.FAMILY_FILE_NOT_FOUND_DESCRIPTION_LINE1,
    EMPTY_DESCRIPTION_LINE2: labels.FAMILY_FILE_NOT_FOUND_DESCRIPTION_LINE2,
  };

  const salesPhaseColor = salesPhaseService.getLabelColor(bucketId);

  useEffect(() => {
    const filters = salesPhaseFilterItems.filter((filter) => filter.checked);
    setSalesPhaseFilter(filters);
    const salesPhaseFilterOptions = [
      {
        title: 'Filter by',
        type: 'checkbox',
        items: salesPhaseFilterItems,
      },
    ];
    setSalesPhaseOptions(salesPhaseFilterOptions);

    const medicallyUrgentFilters = medicallyUrgentFilterItems.filter(
      (filter) => filter.checked,
    );
    setMedicallyUrgentFilterTags(medicallyUrgentFilters);
  }, [salesPhaseFilterItems, medicallyUrgentFilterItems]);

  useEffect(() => {
    setShowContactColumn(columns.includes('contact'));
    setShowSalesPhaseColumn(columns.includes('salesPhase'));
    setShowLastContactedColumn(columns.includes('lastContacted'));
    setShowTourDetailsColumn(columns.includes('tourDetails'));
  }, [columns]);

  const handleRowClick = (row) => {
    if (row.isProfileDataAvailable) {
      setShowProfileError(false);
      setFamilyFileId(row.familyFileId);
      setIsCaseProfileOpen(true);
      registerEvent('FamilyFiles', 'Opened side panel');
    } else {
      setProfileError(
        `Sorry, there’s an error retrieving the information for the family file with the ID: ${row.familyFileId}. Please try again later.`,
      );
      setShowProfileError(true);
    }
  };

  const handleDetailsClick = (row, event) => {
    event.stopPropagation();
    history.push(`/family-file/${row.familyFileId}`);
  };

  /*
    Sales Phase functionality
  */
  const registerEventForSalesPhaseChange = (salesPhaseLabels) => {
    const selectedFilters =
      salesPhaseLabels.length > 0
        ? salesPhaseLabels.join(',')
        : labels.NO_FILTER_SELECTED;
    registerEvent('FamilyFiles', 'Filter by Sales Phase', selectedFilters);
  };

  const handleSalesPhaseFilterChange = (groups) => {
    let items = [];
    if (groups.length > 0) {
      items = groups[0].items;
    }
    const salesPhaseLabels = items.map((item) => item.label);
    registerEventForSalesPhaseChange(salesPhaseLabels);

    onSalesPhaseFilterChange(items);
  };

  const handleRemoveSalesPhaseFilter = (item) => {
    const activeFilters = salesPhaseFilterItems.filter(
      (filter) => filter.value !== item.value,
    );

    const activeLabels = activeFilters
      .filter((filter) => filter.checked)
      .map((filter) => filter.label);
    registerEventForSalesPhaseChange(activeLabels);

    onSalesPhaseFilterChange(activeFilters);
  };

  /*
    Medically Urgent Status functionality
  */
  const registerEventForMedicallyUrgentStatusChange = (
    medicallyUrgentLabels,
  ) => {
    const selectedFilters =
      medicallyUrgentLabels.length > 0
        ? medicallyUrgentLabels.join(',')
        : labels.NO_FILTER_SELECTED;
    registerEvent('FamilyFiles', 'Use of urgent filter', selectedFilters);
  };

  const handleMedicallyUrgentFilterChange = (medicallyUrgentItems) => {
    const medicallyUrgentStatusLabels = medicallyUrgentItems.map(
      (item) => item.label,
    );
    registerEventForMedicallyUrgentStatusChange(medicallyUrgentStatusLabels);

    onMedicallyUrgentFilterChange(medicallyUrgentItems);
  };

  const handleRemoveMedicallyUrgentFilter = (item) => {
    const activeFilters = medicallyUrgentFilterItems.filter(
      (filter) => filter.value !== item.value,
    );

    const activeLabels = activeFilters
      .filter((filter) => filter.checked)
      .map((filter) => filter.label);
    registerEventForMedicallyUrgentStatusChange(activeLabels);

    onMedicallyUrgentFilterChange(activeFilters);
  };

  /*

  */
  const getPrimaryContactDisplay = (row) => {
    return (
      <Fragment>
        <div className={classes.primary_contact_container}>
          {row.primaryContact.includes('Unknown') ? (
            <Typography level="body">{row.primaryContact}</Typography>
          ) : (
            <div className={classes.row}>
              <Typography level="large" bold>
                {row.primaryContact}
              </Typography>
              <AudienceTag
                className={classes.audience_tag}
                audience={row.audienceKey}
                labels={labels}
              />
            </div>
          )}
          &nbsp;
          {row.relationship !== 'Self' &&
          row.relationship !== '' &&
          !row.primaryContact.includes('Unknown') ? (
            <Typography level="small" color="eerieBlack5">
              ({row.relationship})
            </Typography>
          ) : (
            <span></span>
          )}
        </div>
        {row.relationship !== 'Self' && (
          <div className={classes.contact}>
            <User />
            <Typography level="tiny" color="eerieBlack5">
              {row.residents}
            </Typography>
          </div>
        )}
      </Fragment>
    );
  };

  const handlePageSizeChange = (newPageSizeItem) => {
    registerEvent('FamilyFiles', 'Page size changed', pageSizeItem.value);
    pageSizeItem = newPageSizeItem;
    onPageSizeChange(parseInt(pageSizeItem.value, 10));
  };

  const handlePageNumberChange = (pageNumber) => {
    registerEvent('FamilyFiles', 'Page number changed');
    onPageNumberChange(pageNumber);
  };

  return (
    <Fragment>
      {showProfileError && (
        <div className={classes.toast_container}>
          <Toast type="error">
            <div>
              <Typography>{profileError}</Typography>
            </div>
          </Toast>
        </div>
      )}
      {isCaseProfileOpen && (
        <CaseProfileSidePanel
          labels={labels}
          showCommunitiesTab={true}
          familyFileId={familyFileId}
          isOpen={isCaseProfileOpen}
          onClose={() => setIsCaseProfileOpen(false)}
        />
      )}
      <Table>
        <TableHead className={clsx(classes.home_table_header)}>
          <TableRow className={clsx(classes.filter_row)}>
            <TableCell colSpan={3}>
              <Media
                xs={
                  <MedicallyUrgentFilter
                    onChange={handleMedicallyUrgentFilterChange}
                    labels={labels}
                    filters={medicallyUrgentFilterItems}
                    title={labels.FILTER_BY}
                    size={'xs'}
                    className={clsx(classes.item, classes.resident)}
                  />
                }
                md={
                  <MedicallyUrgentFilter
                    onChange={handleMedicallyUrgentFilterChange}
                    labels={labels}
                    filters={medicallyUrgentFilterItems}
                    title={labels.SHOW_BY_MEDICAL_URGENCY}
                    size={'md'}
                  />
                }
              />
              {((!isSalesPhaseFixed && salesPhaseFilter.length > 0) ||
                medicallyUrgentFilterTags.length > 0) && (
                <div className={classes.filters_container}>
                  <Typography>{`${labels.RESULTS_SHOWING_BY}:`}</Typography>
                  {!isSalesPhaseFixed && (
                    <>
                      {salesPhaseFilter.map((filter, key) => (
                        <Tag
                          onClose={() => handleRemoveSalesPhaseFilter(filter)}
                          color="cosmicCobalt5"
                          key={key}
                          text={filter.label}
                        />
                      ))}
                    </>
                  )}
                  {medicallyUrgentFilterTags.map((filter, key) => (
                    <Tag
                      onClose={() => handleRemoveMedicallyUrgentFilter(filter)}
                      color="cosmicCobalt5"
                      key={key}
                      text={filter.label}
                    />
                  ))}
                </div>
              )}
            </TableCell>
            {showPaging && (
              <TableCell colSpan={1} className={classes.page_size_container}>
                <Media
                  xs={
                    <Dropdown
                      className={classes.page_size}
                      items={PAGE_SIZE_ITEMS}
                      label={labels.SHOW_LIST}
                      onChange={(item) => handlePageSizeChange(item)}
                      selectedItem={pageSizeItem}
                    />
                  }
                />
              </TableCell>
            )}
            {!showPaging && <TableCell colSpan={1}></TableCell>}
          </TableRow>
          <TableRow>
            {showContactColumn && (
              <TableCell className={clsx(classes.item, classes.resident)}>
                <div>{labels.PRIMARY_CONTACT}</div>
              </TableCell>
            )}
            {showSalesPhaseColumn && (
              <TableCell className={clsx(classes.item, classes.sales_phase)}>
                {isSalesPhaseFixed ? (
                  labels.SALES_PHASE
                ) : (
                  <RichDropdown
                    emptyLabel={labels.SALES_PHASE}
                    groups={salesPhaseOptions}
                    onChange={handleSalesPhaseFilterChange}
                  />
                )}
              </TableCell>
            )}
            {showLastContactedColumn && (
              <TableCell className={clsx(classes.item, classes.last_call)}>
                <SortButton
                  onClick={handleSort}
                  className={clsx(classes.last_call_sort)}
                  emptyLabel={labels.LAST_CONTACTED}
                  filter={order}
                />
              </TableCell>
            )}
            {showTourDetailsColumn && (
              <TableCell className={clsx(classes.item, classes.last_call)}>
                <SortButton
                  onClick={handleSort}
                  className={clsx(classes.last_call_sort)}
                  emptyLabel={labels.TOUR_DETAILS}
                  filter={order}
                />
              </TableCell>
            )}
            <TableCell colSpan={1}></TableCell>
          </TableRow>
        </TableHead>
        {rows.length > 0 && (
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                className={classes.home_table_row}
                onClick={() => handleRowClick(row)}
                size="extended"
              >
                {showContactColumn && (
                  <TableCell className={clsx(classes.item, classes.resident)}>
                    {row.isProfileDataAvailable ? (
                      getPrimaryContactDisplay(row)
                    ) : (
                      <div className={classes.primary_contact_container}>
                        <Typography level="body" color="smokyTopaz2">
                          {row.profileDataErrorMessage || ''}
                        </Typography>
                      </div>
                    )}
                  </TableCell>
                )}
                {showSalesPhaseColumn && (
                  <TableCell
                    className={clsx(classes.item, classes.sales_phase)}
                  >
                    <Tag text={row.salesPhase} color={salesPhaseColor} />
                  </TableCell>
                )}
                {showLastContactedColumn && (
                  <TableCell className={clsx(classes.item, classes.last_call)}>
                    <HomeTableLastCallCell row={row} labels={labels} />
                  </TableCell>
                )}
                {showTourDetailsColumn && (
                  <TableCell className={clsx(classes.item, classes.last_call)}>
                    <HomeToursScheduledCell
                      row={row}
                      labels={{ UNKNOWN: labels.UNKNOWN }}
                    />
                  </TableCell>
                )}
                <TableCell className={clsx(classes.item, classes.actions)}>
                  <div className={classes.toolbar_container}>
                    <div className={classes.toolbar_button}>
                      {row.isProfileDataAvailable ? (
                        <div
                          onClick={(event) => handleDetailsClick(row, event)}
                        >
                          <span>{labels.DETAILS}</span>
                          <ArrowRight />
                        </div>
                      ) : (
                        <div className={classes.empty_details}></div>
                      )}
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))}
            {showPaging && (
              <TableRow size="extended" className={classes.pagination_row}>
                <TableCell colSpan={4}>
                  <Pagination
                    className={classes.pager}
                    currentPage={pageNumber}
                    onPageChange={(pageNumber) =>
                      handlePageNumberChange(pageNumber)
                    }
                    pageCount={pageCount}
                  />
                </TableCell>
              </TableRow>
            )}
            {!showPaging && (
              <TableRow size="extended" className={classes.empty_row}>
                <TableCell colSpan={4}></TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
      {rows.length === 0 && (
        <EmptyTable labels={emptyLabels}>
          <NotFound />
        </EmptyTable>
      )}
    </Fragment>
  );
};

HomeTableWithRows.propTypes = {
  bucketId: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  labels: PropTypes.object.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      familyFileId: PropTypes.number.isRequired,
      primaryContact: PropTypes.string.isRequired,
      residents: PropTypes.string.isRequired,
      relationship: PropTypes.string.isRequired,
      createdAt: PropTypes.number,
      salesPhase: PropTypes.string.isRequired,
      lastcall: PropTypes.shape({
        type: PropTypes.string,
        completedAt: PropTypes.number,
        result: PropTypes.string,
      }),
    }),
  ).isRequired,
  salesPhaseFilterItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      checked: PropTypes.bool,
    }),
  ).isRequired,
  onSalesPhaseFilterChange: PropTypes.func,
  medicallyUrgentFilterItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      checked: PropTypes.bool,
    }),
  ),
  handleSort: PropTypes.func,
  order: PropTypes.string,
  pageCount: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  onMedicallyUrgentFilterChange: PropTypes.func,
  onPageSizeChange: PropTypes.func.isRequired,
  onPageNumberChange: PropTypes.func.isRequired,
  showPaging: PropTypes.bool,
  isSalesPhaseFixed: PropTypes.bool,
};
HomeTableWithRows.defaultProps = {
  onMedicallyUrgentFilterChange: () => {},
  onSalesPhaseFilterChange: () => {},
  onPageSizeChange: () => {},
  onPageNumberChange: () => {},
  pageCount: 999999,
  pageNumber: 1,
  showPaging: false,
  isSalesPhaseFixed: false,
};

export default HomeTableWithRows;
export { DEFAULT_PAGE_SIZE };
