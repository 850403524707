import React from 'react';
import PropTypes from 'prop-types';

const TextMessage = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5104 3.99984V1.95557C11.5104 1.24478 10.9321 0.666504 10.2214 0.666504H3.28906C2.57827 0.666504 2 1.24478 2 1.95557V14.0441C2 14.7549 2.57827 15.3332 3.28906 15.3332H10.2214C10.9321 15.3332 11.5104 14.7549 11.5104 14.0441V11.644V10.1769V10.1332H12.9C13.3234 10.1332 13.6667 9.78992 13.6667 9.3665V4.7665C13.6667 4.34309 13.3234 3.99984 12.9 3.99984H11.5104ZM10.2214 3.99984V3.24463H3.33333V12.9998H10.2214V10.1769V10.1332H9.86661L7.53333 11.5915V10.1332H6.76667C6.34325 10.1332 6 9.78992 6 9.3665V4.7665C6 4.34309 6.34325 3.99984 6.76667 3.99984H10.2214ZM11.5104 9.13237V9.129L11.502 9.13237H9.62021L8.35765 9.90318V9.13237H6.92015V4.91985H12.6701V9.13237H11.5104ZM7.91667 7.44984V8.2165H10.6V7.44984H7.91667ZM7.91667 6.68317V5.9165H11.3667V6.68317H7.91667Z"
        fill="currentColor"
      />
    </svg>
  );
};

TextMessage.propTypes = {
  className: PropTypes.string,
};

export default TextMessage;
