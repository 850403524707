import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      width: '100%',
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      userSelect: 'none',
      justifyContent: 'space-between',
      padding: '16px 16px 16px 8px',
      borderBottom: `1px ${theme.palette.light.platinum1} solid`,
      '& p': {
        display: 'inline',
      },
    },
    left_title: {
      width: '84%',
    },
    icon: {
      paddingLeft: '19px',
      '& svg': {
        verticalAlign: 'middle',
        color: theme.palette.neutral.cosmicCobalt3,
      },
    },
    view_more: {
      display: 'flex',
      '& svg': {
        paddingLeft: 10,
      },
    },
    body: {
      marginTop: 20,
    },
    title_expanded: {
      backgroundColor: theme.palette.neutral.dropdown,
    },
  };
});

export { useStyles };
