import React from 'react';
import PropTypes from 'prop-types';

const Construction = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6203 8.82296L20.2571 6.45972C20.1179 5.99218 19.8639 5.55147 19.4946 5.18226L17.8637 3.55136C17.1668 2.85446 16.2153 2.56791 15.3085 2.69064C15.0123 2.57131 14.7254 2.47062 14.4428 2.3871C12.9453 1.93753 10.1936 1.48243 8.58891 3.48593C8.45915 3.64781 8.45544 3.877 8.57969 4.04318C8.59286 4.06084 8.60718 4.07735 8.62242 4.09253C8.75061 4.22072 8.94532 4.26211 9.11685 4.1917C10.2335 3.73381 11.3141 4.04907 12.4203 5.15531C12.5116 5.24659 12.6028 5.34377 12.6941 5.44568C12.6374 6.13148 12.8131 6.83245 13.2209 7.42156L12.1899 8.45254C11.3407 8.33151 10.4463 8.59687 9.79439 9.24887L1.82481 17.2185C0.725064 18.3182 0.725064 20.1076 1.82481 21.2074C2.92455 22.3071 4.71398 22.3071 5.81367 21.2074L13.7833 13.2378C14.429 12.5921 14.695 11.7089 14.5826 10.8668L15.6244 9.82505C15.8908 10.0094 16.1798 10.1472 16.4803 10.2366L18.8435 12.5998C19.3498 13.106 20.1706 13.106 20.6769 12.5997L22.6204 10.6563C23.1266 10.1501 23.1266 9.32923 22.6203 8.82296ZM12.4577 11.9121L4.48807 19.8817C4.11929 20.2505 3.51925 20.2504 3.15046 19.8817C2.78168 19.5129 2.78168 18.9129 3.15046 18.5441L11.12 10.5745C11.4889 10.2057 12.0889 10.2057 12.4577 10.5745C12.8264 10.9433 12.8264 11.5433 12.4577 11.9121Z"
        fill="#0D1321"
      />
    </svg>
  );
};

Construction.propTypes = {
  className: PropTypes.string,
};

export default Construction;
