import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  last_contacted_container: {
    display: 'flex',
    '& > p,svg': {
      marginRight: '8px',
    },
  },
  green_icon: {
    color: theme.palette.positive.wintergreenDream3,
    fill: theme.palette.positive.wintergreenDream3,
  },
  red_icon: {
    color: theme.palette.correction.smokyTopaz2,
    fill: theme.palette.correction.smokyTopaz2,
  },
}));

export { useStyles };
