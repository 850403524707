import React from 'react';
import PropTypes from 'prop-types';

const ArrowForwardAll = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M9.29289 5.70711L13.5858 10H13C5.13106 10 2 13.2563 2 21H4C4 14.3437 6.25356 12 13 12H13.5858L9.29289 16.2929L10.7071 17.7071L17.4142 11L10.7071 4.29289L9.29289 5.70711ZM14.2929 5.70711L19.5858 11L14.2929 16.2929L15.7071 17.7071L22.4142 11L15.7071 4.29289L14.2929 5.70711Z"
      />
    </svg>
  );
};

ArrowForwardAll.propTypes = {
  className: PropTypes.string,
};

export default ArrowForwardAll;
