import React from 'react';
import PropTypes from 'prop-types';

const PinCross = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.43849 15.1689L8 15.5518L7.56151 15.1689C3.87421 11.9492 2 9.13792 2 6.66675C2 3.16719 4.73137 0.666748 8 0.666748C11.2686 0.666748 14 3.16719 14 6.66675C14 9.13792 12.1258 11.9492 8.43849 15.1689ZM3.33333 6.66675C3.33333 8.56624 4.87107 10.9558 8 13.7757C11.1289 10.9558 12.6667 8.56624 12.6667 6.66675C12.6667 3.93115 10.5573 2.00008 8 2.00008C5.44274 2.00008 3.33333 3.93115 3.33333 6.66675ZM5.64298 5.25253L7.05719 6.66675L5.64298 8.08096L6.58579 9.02377L8 7.60956L9.41421 9.02377L10.357 8.08096L8.94281 6.66675L10.357 5.25253L9.41421 4.30973L8 5.72394L6.58579 4.30973L5.64298 5.25253Z"
      />
    </svg>
  );
};

PinCross.propTypes = {
  className: PropTypes.string,
};

export default PinCross;
