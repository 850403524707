import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withLabels } from '../../context/LabelContext';
import { useStyles } from './Home.style';

import BucketCard from './BucketCard';
import Container from '../../common/Container/Container';
import ContainerItem from '../../common/ContainerItem/ContainerItem';
import Media from '../../common/Media/Media';
import HeaderTitle from '../../components/HeaderTitle/HeaderTitle';
import { HomeHeader } from '../../components/HomeHeader/HomeHeader';
import { withAuthenticationContext } from '../../context/Authentication';
import { registerPage } from '../../services/Analytics';
import familyFileListQuery from '../FamilyFileList/FamilyFileListQuery';
import familyFileListToursQuery from '../FamilyFileList/FamilyFileListToursQuery';
import familyFileListClosedQuery from '../FamilyFileList/FamilyFileListClosedQuery';
import useGraphQLQuery from '../../hooks/useGraphQLQuery';
import { SalesPhaseBucket } from '../../components/NavigationSidePanel/NavigationSidePanel';
import { getYearsAgoInDays } from '../../utils/dateUtils';

const TOUR_PRIOR_DAYS = 7;
const CLOSED_PRIOR_DAYS = getYearsAgoInDays(5);

const lastCallSortOrder = 'desc';
const dateSortOrder = 'desc';
const medicallyUrgentStatuses = [];
const pageNumber = 1;
const pageSize = 1;
const referralStatusType = '';

const Home = ({ auth, labels }) => {
  useEffect(() => {
    registerPage('/home');
  }, []);

  const classes = useStyles();

  /*
    New and Pre-Referred 
  */
  const {
    error: newPreReferredError,
    data: newPreReferredData,
  } = useGraphQLQuery(familyFileListQuery, {
    variables: {
      lastCallSortOrder,
      medicallyUrgentStatuses,
      pageNumber,
      pageSize,
      referralStatusType,
      salesPhases: ['New Lead', 'Pre Referral Cnslt.'],
    },
  });
  if (newPreReferredError) {
    console.log(
      `Error retrieving newPreReferredData. Error: ${JSON.stringify(
        newPreReferredError,
      )}`,
    );
  }
  const [newPreReferredCount, setNewPreReferredCount] = useState();
  useEffect(() => {
    setNewPreReferredCount(
      newPreReferredData?.findFamilyFilesByParams?.filteredCount,
    );
  }, [newPreReferredData]);

  /*
    Recently Referred 
  */
  const {
    error: recentlyReferredError,
    data: recentlyReferredData,
  } = useGraphQLQuery(familyFileListQuery, {
    variables: {
      daysFrom: 26,
      lastCallSortOrder,
      medicallyUrgentStatuses,
      pageNumber,
      pageSize,
      referralStatusType: 'RecentlyReferred',
      salesPhases: [
        'Referral Sent',
        'Scheduled Tour',
        'Post-Tour',
        'Deposit/Community Fee Paid',
      ],
    },
  });
  if (recentlyReferredError) {
    console.log(
      `Error retrieving recentlyReferredData. Error: ${JSON.stringify(
        recentlyReferredError,
      )}`,
    );
  }
  const [recentlyReferredCount, setRecentlyReferredCount] = useState();
  useEffect(() => {
    setRecentlyReferredCount(
      recentlyReferredData?.findFamilyFilesByParams?.filteredCount,
    );
  }, [recentlyReferredData]);

  /*
    Older Referrals (> 26 Days)
  */
  const { error: postReferralError, data: postReferralData } = useGraphQLQuery(
    familyFileListQuery,
    {
      variables: {
        lastCallSortOrder,
        medicallyUrgentStatuses,
        pageNumber,
        pageSize,
        referralStatusType: 'PostReferral',
        salesPhases: [
          'Referral Sent',
          'Scheduled Tour',
          'Post-Tour',
          'Deposit/Community Fee Paid',
        ],
      },
    },
  );
  if (postReferralError) {
    console.log(
      `Error retrieving postReferralData. Error: ${JSON.stringify(
        postReferralError,
      )}`,
    );
  }
  const [postReferralCount, setPostReferralCount] = useState();
  useEffect(() => {
    setPostReferralCount(
      postReferralData?.findFamilyFilesByParams?.filteredCount,
    );
  }, [postReferralData]);

  /*
    Move-ins
  */
  const { error: moveinError, data: moveinData } = useGraphQLQuery(
    familyFileListQuery,
    {
      variables: {
        lastCallSortOrder,
        medicallyUrgentStatuses,
        pageNumber,
        pageSize,
        referralStatusType,
        salesPhases: ['Wait List', 'Move-In Process', 'Awaiting Decision'],
      },
    },
  );
  if (moveinError) {
    console.log(
      `Error retrieving moveinData. Error: ${JSON.stringify(moveinError)}`,
    );
  }
  const [moveinCount, setMoveinCount] = useState();
  useEffect(() => {
    setMoveinCount(moveinData?.findFamilyFilesByParams?.filteredCount);
  }, [moveinData]);

  /*
    Touring
  */
  const { error: touringError, data: touringData } = useGraphQLQuery(
    familyFileListToursQuery,
    {
      variables: {
        dateSortOrder,
        daysFrom: TOUR_PRIOR_DAYS,
        medicallyUrgentStatuses,
        pageNumber,
        pageSize,
      },
    },
  );
  if (touringError) {
    console.log(
      `Error retrieving touringData. Error: ${JSON.stringify(touringError)}`,
    );
  }
  const [touringCount, setTouringCount] = useState();
  useEffect(() => {
    setTouringCount(touringData?.findFamilyFilesWithTours?.filteredCount);
  }, [touringData]);

  /*
    Closed 
  */
  const { error: closedError, data: closedData } = useGraphQLQuery(
    familyFileListClosedQuery,
    {
      variables: {
        daysFrom: CLOSED_PRIOR_DAYS,
        lastCallSortOrder,
        medicallyUrgentStatuses,
        pageNumber,
        pageSize,
      },
    },
  );
  if (closedError) {
    console.log(
      `Error retrieving closedData. Error: ${JSON.stringify(closedError)}`,
    );
  }
  const [closedCount, setClosedCount] = useState();
  useEffect(() => {
    setClosedCount(closedData?.findFamilyFilesClosed?.filteredCount);
  }, [closedData]);

  /*

  */
  const BucketCardNewPreReferred = () => {
    return (
      <BucketCard
        bucketId={SalesPhaseBucket.NEW_PREFERRED}
        name={labels.NEW_AND_PRE_REFERRED}
        count={newPreReferredCount?.toLocaleString()}
      ></BucketCard>
    );
  };

  const BucketCardRecentReferrals = () => {
    return (
      <BucketCard
        bucketId={SalesPhaseBucket.RECENT_REFERRALS}
        name={labels.RECENTLY_REFERRED}
        count={recentlyReferredCount?.toLocaleString()}
      ></BucketCard>
    );
  };

  const BucketCardTouring = () => {
    return (
      <BucketCard
        bucketId={SalesPhaseBucket.TOURING}
        name={labels.TOURING}
        count={touringCount?.toLocaleString()}
      ></BucketCard>
    );
  };

  const BucketCardPostReferrals = () => {
    return (
      <BucketCard
        bucketId={SalesPhaseBucket.OLDER_REFERRALS}
        name={labels.OLDER_REFERRALS}
        count={postReferralCount?.toLocaleString()}
      ></BucketCard>
    );
  };

  const BucketCardMoveins = () => {
    return (
      <BucketCard
        bucketId={SalesPhaseBucket.MOVEINS}
        name={labels.MOVE_INS}
        count={moveinCount?.toLocaleString()}
      ></BucketCard>
    );
  };

  const BucketCardClosed = () => {
    return (
      <BucketCard
        bucketId={SalesPhaseBucket.CLOSED}
        name={labels.CLOSED}
        count={closedCount?.toLocaleString()}
      ></BucketCard>
    );
  };

  return (
    <>
      <HeaderTitle
        title={labels.MANAGE_FAMILY_FILES}
        subtitle={labels.MANAGE_YOUR_NON_REFERRED_FAMILIES}
      />
      <div className={classes.manage_container}>
        <Media
          xs={
            <>
              <Container container spacing={2}>
                <ContainerItem className={classes.itemXS}>
                  <BucketCardNewPreReferred />
                </ContainerItem>
                <ContainerItem className={classes.itemXS}>
                  <BucketCardRecentReferrals />
                </ContainerItem>
              </Container>

              <Container container spacing={2} className={classes.containerXS}>
                <ContainerItem className={classes.itemXS}>
                  <BucketCardTouring />
                </ContainerItem>
                <ContainerItem className={classes.itemXS}>
                  <BucketCardPostReferrals />
                </ContainerItem>
              </Container>

              <Container container spacing={2} className={classes.containerXS}>
                <ContainerItem className={classes.itemXS}>
                  <BucketCardMoveins />
                </ContainerItem>
                <ContainerItem className={classes.itemXS}>
                  <BucketCardClosed />
                </ContainerItem>
              </Container>
            </>
          }
          sm={
            <>
              <Container container spacing={4}>
                <ContainerItem className={classes.itemSM}>
                  <BucketCardNewPreReferred />
                </ContainerItem>
                <ContainerItem className={classes.itemSM}>
                  <BucketCardRecentReferrals />
                </ContainerItem>
                <ContainerItem className={classes.itemSM}>
                  <BucketCardTouring />
                </ContainerItem>
              </Container>

              <Container container spacing={4} className={classes.containerSM}>
                <ContainerItem className={classes.itemSM}>
                  <BucketCardPostReferrals />
                </ContainerItem>
                <ContainerItem className={classes.itemSM}>
                  <BucketCardMoveins />
                </ContainerItem>
                <ContainerItem className={classes.itemSM}>
                  <BucketCardClosed />
                </ContainerItem>
              </Container>
            </>
          }
          lg={
            <>
              <Container container spacing={4}>
                <ContainerItem className={classes.itemLG}>
                  <BucketCardNewPreReferred />
                </ContainerItem>
                <ContainerItem className={classes.itemLG}>
                  <BucketCardRecentReferrals />
                </ContainerItem>
                <ContainerItem className={classes.itemLG}>
                  <BucketCardTouring />
                </ContainerItem>
                <ContainerItem className={classes.itemLG}>
                  <BucketCardPostReferrals />
                </ContainerItem>
                <ContainerItem className={classes.itemLG}>
                  <BucketCardMoveins />
                </ContainerItem>
                <ContainerItem className={classes.itemLG}>
                  <BucketCardClosed />
                </ContainerItem>
              </Container>
            </>
          }
        />
      </div>

      <HomeHeader auth={auth} labels={labels} className={classes.home_header} />
    </>
  );
};

Home.propTypes = {
  labels: PropTypes.object.isRequired,
  auth: PropTypes.shape({
    user: PropTypes.shape({
      given_name: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default withLabels(withAuthenticationContext(Home));
export { Home };
