import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './RowCard.style';
import clsx from 'clsx';
import { colorReference } from '../Theme/themes/default';

const RowCard = ({ className, color, children, onClick }) => {
  const classes = useStyles(color);
  const containerClasses = clsx(classes.container, className);

  return (
    <div className={containerClasses} onClick={onClick}>
      {children}
    </div>
  );
};

RowCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  color: PropTypes.oneOf([...colorReference, 'transparent']),
};

RowCard.defaultProps = {
  color: 'transparent',
  className: '',
  onClick: () => {},
};

export default RowCard;
