import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles(() => ({
  loading_container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: (height) => height,
  },
}));

export { useStyles };
