import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from '../Marker.style';
import StartingPoint from 'common/Icons/maps/StartingPoint';

const HoveredMarker = ({ $hover }) => {
  const classes = useStyles($hover);

  return (
    <>
      <StartingPoint className={classes.starting_point} />
      <div className={classes.starting_point_shadow} />
    </>
  );
};

HoveredMarker.propTypes = {
  $hover: PropTypes.bool,
};

export default HoveredMarker;
