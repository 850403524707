import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Button from '../../common/Button/Button';
import Card from '../../common/Card/Card';
import ArrowForwardAll from '../../common/Icons/arrow/ArrowForwardAll';
import Search from '../../common/Icons/basic/Search';
import PinAdd from '../../common/Icons/maps/PinAdd';
import Typography from '../../common/Typography/Typography';
import { useExternalId } from '../../hooks/useExternalId';
import useGraphQLQuery from '../../hooks/useGraphQLQuery';
import { registerEvent } from '../../services/Analytics';
import EmptyCommunitiesXS from '../EmptyCommunities/EmptyCommunitiesXS';
import Loading from '../Loading/Loading';
import YglLink from '../YglExternalId/YglLink';
import { useStyles } from './Community.style';
import familyFileCommunitiesQuery from './familyFileCommunitiesQuery';
import IncompleteFamilyFile from './IncompleteFamilyFile/IncompleteFamilyFile';
import FamilyFileSearchBanner from './FamilyFileSearchBanner/FamilyFileSearchBanner';

const CommunityXS = ({ labels, familyFileId, isInSidePanel }) => {
  const classes = useStyles();
  const { loading, error, data } = useGraphQLQuery(familyFileCommunitiesQuery, {
    variables: { familyFileId },
  });

  const { externalId, fetchError } = useExternalId(familyFileId);
  const [savedCommunitiesCount, setSavedCommunitiesCount] = useState(0);
  const [referredCommunitiesCount, setReferredCommunitiesCount] = useState(0);

  useEffect(() => {
    let unReferredCount = 0;
    let referredCount = 0;
    if (data?.findFamilyFileById?.referralStatus) {
      data.findFamilyFileById.referralStatus.forEach((referralStatus) => {
        if (referralStatus.isReferralStarted === true) {
          referredCount++;
        } else {
          unReferredCount++;
        }
      });
    }
    setSavedCommunitiesCount(unReferredCount);
    setReferredCommunitiesCount(referredCount);
  }, [data]);

  if (loading) return <Loading height="calc(100vh - 197px)" />;
  if (error) return `Error! ${error.message}`;

  const preferredLocations =
    data.findFamilyFileById.familyDesiredLocations || [];

  const showIncompleteFamilyFile = preferredLocations.length === 0;

  const showEmptyReferredCommunities =
    !showIncompleteFamilyFile && referredCommunitiesCount === 0;
  const showReferredCommunities =
    !showIncompleteFamilyFile && referredCommunitiesCount > 0;

  const showEmptySavedCommunities =
    !showIncompleteFamilyFile && savedCommunitiesCount === 0;
  const showSavedCommunities =
    !showIncompleteFamilyFile && savedCommunitiesCount > 0;

  return (
    <div className={classes.community_container_xs}>
      <div className={classes.workinglist_link_container}>
        <YglLink
          url="YGL_WORKING_LIST_PAGE_URL"
          externalId={externalId ?? familyFileId}
          hasError={fetchError}
          type="button"
          target={'self'}
        >
          {labels.GO_TO_WORKING_LIST}
        </YglLink>
      </div>

      {(!showEmptySavedCommunities || !showEmptyReferredCommunities) && (
        <FamilyFileSearchBanner
          familyFileId={familyFileId}
          labels={labels}
          sidePanelView={isInSidePanel}
        />
      )}

      {showIncompleteFamilyFile && (
        <IncompleteFamilyFile labels={labels} familyFileId={familyFileId} />
      )}

      {showReferredCommunities && (
        <>
          <Card
            className={classes.referred_communities_container_xs}
            title={labels.REFERRED_COMMUNITIES}
            icon={<ArrowForwardAll />}
          >
            <div className={classes.text_container}>
              <Typography>{labels.YOU_HAVE}</Typography>
              <Typography bold>{`(${referredCommunitiesCount})`}</Typography>
              <Typography>
                {labels.REFERRED_COMMUNITIES.toLowerCase()}
              </Typography>
            </div>
            <div className={classes.more_details}>
              <Button
                openInNewTab
                to={
                  isInSidePanel
                    ? `/family-file/${familyFileId}?tab=2`
                    : `/communities/${familyFileId}`
                }
              >
                <Typography color="platinum5">
                  {labels.SHOW_MORE_DETAILS}
                </Typography>
              </Button>
            </div>
          </Card>
        </>
      )}

      {showEmptySavedCommunities && showEmptyReferredCommunities && (
        <div className={classes.empty_communities_container_xs}>
          <EmptyCommunitiesXS labels={labels} />
          <div
            className={classes.empty_communities_container_button}
            onClick={() => {
              registerEvent(
                'FamilyFiles',
                'Go to search communities',
                'Empty Communities',
              );
            }}
          >
            <Button
              openInNewTab
              to={`/communities/${familyFileId}`}
              className={classes.search_community}
              startIcon={<Search />}
            >
              <Typography color="platinum5">
                {labels.SEARCH_FOR_COMMUNITIES}
              </Typography>
            </Button>
          </div>
        </div>
      )}

      {showSavedCommunities && (
        <>
          <Card
            className={classes.saved_communities_container_xs}
            title={labels.SAVED_COMMUNITIES}
            icon={<PinAdd />}
          >
            <div className={classes.text_container}>
              <Typography>{labels.YOU_HAVE}</Typography>
              <Typography bold>{`(${savedCommunitiesCount})`}</Typography>
              <Typography>{labels.SAVED_COMMUNITIES.toLowerCase()}</Typography>
            </div>
            <div className={classes.more_details}>
              <Button openInNewTab to={`/communities/${familyFileId}`}>
                <Typography color="platinum5">
                  {labels.SHOW_MORE_DETAILS}
                </Typography>
              </Button>
            </div>
          </Card>
        </>
      )}
    </div>
  );
};

CommunityXS.propTypes = {
  labels: PropTypes.object.isRequired,
  familyFileId: PropTypes.number.isRequired,
  isInSidePanel: PropTypes.bool,
};

CommunityXS.defaultProps = {
  isInSidePanel: false,
};

export default CommunityXS;
