import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  edit_contacts_container: {
    height: theme.spacing(60),
    overflowY: 'auto',
    overflowX: 'hidden',
    width: theme.spacing(100),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  edit_contacts_container_xl: {
    height: 'auto',
  },
  edit_contacts_container_expanded: {
    height: '72vh',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  inner_row: {
    display: 'flex',
  },
  show_all_container: {
    cursor: 'pointer',
  },
  show_all: {
    marginLeft: 10,
    color: theme.palette.neutral.cosmicCobalt3,
  },
  divider: {
    width: '100%',
    marginTop: 24,
  },
  actions_container: {
    padding: '0px 24px 16px 0',
  },
  actions: {
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(3),
    },
  },
  with_padding: {
    padding: '16px 24px 16px 24px',
  },
  no_padding_top: {
    paddingTop: 0,
  },
  no_padding_bottom: {
    paddingBottom: 0,
  },
  error_message: {
    marginLeft: '24px',
    marginRight: '24px',
  },
  edit_contacts_modal_container: {
    [theme.breakpoints.down('sm')]: {
      '&.modal_container': {
        width: '80%',
        minWidth: '80%',
      },
    },
  },
}));

export { useStyles };
