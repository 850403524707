const validate = (data, labels) => {
  const budget = data.findFamilyFileById.leadData.budgetDescription;
  const preferredLocation = data.findFamilyFileById.familyDesiredLocations;
  const contacts = data.findFamilyFileById.contacts;
  const residents = data.findFamilyFileById.residents;
  const livingSituation = data.findFamilyFileById.questionsData.currentLiving;
  const likelihood = data.findFamilyFileById.leadData.likelihoodToMovePct;
  const expectedMove = data.findFamilyFileById.leadData.expectedMoveDate;
  const audienceKey = data.findFamilyFileById.audienceKey;

  const selfLeadsAudicences = [
    'self_not_urgent_veteran_financially_unqualified',
    'self_urgent_financially_unqualified',
    'self_not_urgent_financially_qualified',
  ];
  const isSelfLead = selfLeadsAudicences.some((selfAudience) =>
    audienceKey?.includes(selfAudience),
  );

  const commonValidations = [
    {
      valid: _isValidBudget(budget),
      label: labels.FINANCIAL_RANGE_REFERRAL_VALIDATION,
    },
    {
      valid: _isValidPreferredLocation(preferredLocation),
      label: labels.PREFERRED_LOCATIONS,
    },
    {
      valid: _isValidContactFirstName(contacts),
      label: labels.CONTACT_FIRST_NAME,
    },
    {
      valid: _isValidContactLastName(contacts),
      label: labels.CONTACT_LAST_NAME,
    },
    {
      valid: _isValidContactRelation(contacts),
      label: labels.CONTACT_RELATION_TO_RESIDENT,
    },
    {
      valid: _isValidResidentFirstName(residents),
      label: labels.RESIDENT_FIRST_NAME,
    },
    {
      valid: _isValidResidentLastName(residents),
      label: labels.RESIDENT_LAST_NAME,
    },
  ];

  const notSelfLeadValidations = [
    {
      valid: _isValidResidentLivingSituation(livingSituation),
      label: labels.RESIDENT_LIVING_SITUATION,
    },
    { valid: _isValidLikelihood(likelihood), label: labels.LIKELIHOOD_MOVE },
    {
      valid: _isValidExpectedMove(expectedMove),
      label: labels.EXPECTED_MOVE_IN_DATE,
    },
  ];

  let result = [...commonValidations];
  if (!isSelfLead) {
    result = [...result, ...notSelfLeadValidations];
  }
  return result;
};

const _isValidBudget = (budget) => budget !== 'Family Undecided';

const _isValidPreferredLocation = (preferredLocation) => {
  return preferredLocation && preferredLocation.length > 0;
};

const _isValidContactFirstName = (contacts) => {
  if (contacts && contacts.length > 0) {
    const primaryContact = contacts.find(
      (contact) => contact.profileData && contact.profileData.isPrimary,
    );

    const firstName = primaryContact?.profileData?.firstName;

    if (firstName) {
      return firstName.toLowerCase() !== 'unknown';
    }
  }
  return false;
};

const _isValidContactLastName = (contacts) => {
  if (contacts && contacts.length > 0) {
    const primaryContact = contacts.find(
      (contact) => contact.profileData && contact.profileData.isPrimary,
    );

    const lastName = primaryContact?.profileData?.lastName;

    if (lastName) {
      return lastName.toLowerCase() !== 'unknown';
    }
  }
  return false;
};

const _isValidContactRelation = (contacts) => {
  if (contacts && contacts.length > 0) {
    const primaryContact = contacts.find(
      (contact) => contact.profileData && contact.profileData.isPrimary,
    );
    return (
      primaryContact &&
      primaryContact.profileData &&
      !!primaryContact.profileData.relationToResident
    );
  }
  return false;
};

const _isValidResidentFirstName = (residents) => {
  if (residents && residents.length > 0) {
    const firstResident = residents[0];

    const firstName = firstResident?.profileData?.firstName;

    if (firstName) {
      return firstName.toLowerCase() !== 'unknown';
    }
  }
  return false;
};

const _isValidResidentLastName = (residents) => {
  if (residents && residents.length > 0) {
    const firstResident = residents[0];

    const lastName = firstResident?.profileData?.lastName;

    if (lastName) {
      return lastName.toLowerCase() !== 'unknown';
    }
  }
  return false;
};

const _isValidResidentLivingSituation = (livingSituation) => !!livingSituation;

const _isValidLikelihood = (likelihood) => !!likelihood;

const _isValidExpectedMove = (expectedMove) => !!expectedMove;

export default { validate };
