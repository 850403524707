import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    input: {
      border: `1px solid ${theme.palette.light.platinum1}`,
      backgroundColor: theme.palette.light.platinum5,
      width: '100%',
      '& .MuiOutlinedInput-multiline': {
        padding: 0,
      },
      borderRadius: '2px',
      '& input, textarea': {
        padding: '8px 16px',
        fontSize: '15px',
        fontFamily: 'Poppins',
        lineHeight: '22px',
        height: 'auto',
        color: theme.palette.dark.eerieBlack5,
      },
      '&:hover': {
        border: `1px solid ${theme.palette.neutral.cosmicCobalt3}`,
        '& svg': {
          color: theme.palette.neutral.cosmicCobalt3,
          fill: theme.palette.neutral.cosmicCobalt3,
        },
      },
      '&:focus-within': {
        border: `1px solid ${theme.palette.neutral.cosmicCobalt2}`,
        '& span svg': {
          color: theme.palette.neutral.cosmicCobalt2,
          fill: theme.palette.neutral.cosmicCobalt2,
        },
        '& $label': {
          color: theme.palette.neutral.cosmicCobalt3,
        },
      },
      '& fieldset': {
        border: 'none',
        '&:hover': {
          border: 'none',
        },
      },
    },
    filled: {
      '& $input': {
        border: `1px solid ${theme.palette.neutral.cosmicCobalt2}`,
      },
      '& label': {
        color: theme.palette.dark.eerieBlack1,
      },
      '& label.label_disabled': {
        color: theme.palette.dark.eerieBlack5,
      },
    },
    label: {
      fontSize: '13px',
      lineHeight: '19px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: theme.palette.dark.eerieBlack5,
      marginBottom: '4px',
      textAlign: 'left',
    },
    text_hint: {
      fontSize: '12px',
      lineHeight: '8px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: theme.palette.dark.eerieBlack5,
      marginTop: '4px',
      textAlign: 'left',
    },
    error: {
      '& $input': {
        border: `1px solid ${theme.palette.correction.smokyTopaz2}`,
      },
      '& $label, $text_hint': {
        color: theme.palette.correction.smokyTopaz2,
      },
      '& $startIcon svg': {
        color: theme.palette.dark.disabled,
        fill: theme.palette.dark.disabled,
      },
      '& $endIcon svg': {
        color: theme.palette.dark.disabled,
        fill: theme.palette.dark.disabled,
      },
      '& $input:hover': {
        '& $startIcon svg': {
          color: theme.palette.dark.disabled,
          fill: theme.palette.dark.disabled,
        },
        '& $endIcon svg': {
          color: theme.palette.dark.disabled,
          fill: theme.palette.dark.disabled,
        },
      },
    },
    disabled: {
      '& $input': {
        backgroundColor: theme.palette.light.platinum4,
        border: `1px solid ${theme.palette.light.platinum1}`,
        '&:hover': {
          border: `1px solid ${theme.palette.light.platinum1}`,
        },
        '& $startIcon svg': {
          color: theme.palette.light.platinum1,
          fill: theme.palette.light.platinum1,
        },
        '& $endIcon svg': {
          color: theme.palette.light.platinum1,
          fill: theme.palette.light.platinum1,
        },
      },
    },
    startIcon: {
      marginRight: 0,
    },
    endIcon: {
      marginLeft: 0,
    },
    mandatory: {
      color: theme.palette.correction.smokyTopaz2,
      marginRight: 4,
    },
  };
});

export { useStyles };
