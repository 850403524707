import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { StylesProvider } from '@material-ui/styles';
import defaultTheme from './themes/default';
import './themes/default.css';
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/core/styles';

const ThemeContext = createContext(defaultTheme);

const ThemeProvider = ({ theme, children }) => {
  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={theme}>
        <MaterialThemeProvider theme={theme}>{children}</MaterialThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

const makeStyles = (callback) => createUseStyles(callback(defaultTheme));

ThemeProvider.propTypes = {
  theme: PropTypes.object,
  children: PropTypes.node.isRequired,
};

ThemeProvider.defaultProps = {
  theme: defaultTheme,
};

export { ThemeProvider, makeStyles };
