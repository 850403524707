import React from 'react';
import { useStyles } from './FamilyFile.style';
import Container from '../../common/Container/Container';
import PropTypes from 'prop-types';
import ContainerItem from '../../common/ContainerItem/ContainerItem';
import CenterPanel from './CenterPanel/CenterPanel';

const FamilyFileMobile = ({ labels, familyFileId, activeTab, size }) => {
  const classes = useStyles();

  return (
    <Container className={classes.family_file_container} spacing={4}>
      <ContainerItem xs={12}>
        <CenterPanel
          activeTab={activeTab}
          labels={labels}
          familyFileId={familyFileId}
          size={size}
        />
      </ContainerItem>
    </Container>
  );
};

FamilyFileMobile.propTypes = {
  labels: PropTypes.object,
  familyFileId: PropTypes.number,
  activeTab: PropTypes.number,
  size: PropTypes.string,
};

export default FamilyFileMobile;
