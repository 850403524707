import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useAuth0 } from '../../context/Authentication';
import { withUser } from '../../context/UserContext';
import { getRoles } from '../../config/roles';
import { sentryExceptionWithData } from '../../utils/sentryExceptionWithData';

const PrivateRouteWithLayout = ({
  component: Component,
  layout: Layout,
  path,
  currentUser,
  allowedRoles,
  ...rest
}) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path },
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const render = (props) => (
    <Layout>
      <Component {...props} />
    </Layout>
  );

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (!allowedRoles.includes(currentUser?.role)) {
    const error = new Error(
      'Error 401: Unauthorized Role - Current user does not have an allowed role',
    );
    const additionalData = { allowedRoles, currentUser };
    sentryExceptionWithData(error, additionalData);

    return <Redirect to="/error" />;
  }

  return <Route path={path} render={render} {...rest} />;
};

PrivateRouteWithLayout.propTypes = {
  component: PropTypes.elementType.isRequired,
  layout: PropTypes.elementType.isRequired,
  path: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.string,
    __typename: PropTypes.string,
  }),
  allowedRoles: PropTypes.arrayOf(PropTypes.oneOf(getRoles())).isRequired,
};

export default withUser(PrivateRouteWithLayout);
