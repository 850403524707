export const GET_ADMIN_CATALOGS_REQUEST = 'adminCatalogs/get/request';
export const GET_ADMIN_CATALOGS_SUCCESS = 'adminCatalogs/get/success';
export const GET_ADMIN_CATALOGS_ERROR = 'adminCatalogs/get/error';

export const getAdminCatalogsRequest = () => ({
  type: GET_ADMIN_CATALOGS_REQUEST,
});

export const getAdminCatalogsSuccess = (response) => {
  return { type: GET_ADMIN_CATALOGS_SUCCESS, payload: { response } };
};

export const getAdminCatalogsError = (error) => {
  return { type: GET_ADMIN_CATALOGS_ERROR, payload: { error } };
};
