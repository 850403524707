import React from 'react';
import PropTypes from 'prop-types';

const CallRedial = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.08739 6.07059C7.50177 5.51208 7.65036 4.92346 7.18171 4.40388C6.13 2.93205 5.4227 2.02187 4.97905 1.58355C4.1356 0.75025 2.74813 0.862967 2.01765 1.58284C1.6253 1.96949 1.49247 2.10227 1.08961 2.51152C-1.15436 4.75701 0.214131 9.50392 3.85208 13.146C7.4892 16.7872 12.235 18.1566 14.4837 15.9063C14.8554 15.5479 15.1702 15.233 15.4184 14.9705C16.1342 14.2138 16.2429 12.8873 15.4137 12.0256C14.9885 11.5838 14.1207 10.9105 12.5872 9.81344C12.1175 9.39339 11.5606 9.48443 11.05 9.81944C10.8044 9.98059 10.6257 10.1436 10.2882 10.4815L9.67539 11.0947C9.59467 11.1755 8.49862 10.6265 7.43466 9.56138C6.37013 8.49565 5.8216 7.39938 5.90194 7.31903L6.51521 6.70534C6.62229 6.59815 6.67348 6.54625 6.73912 6.47724C6.8759 6.33347 6.98914 6.203 7.08739 6.07059ZM10.8059 12.226L11.4188 11.6126C11.6046 11.4267 11.7249 11.3123 11.8229 11.2335C13.1663 12.1984 13.9443 12.8051 14.2621 13.1353C14.4529 13.3336 14.4232 13.6954 14.2574 13.8707C14.0278 14.1134 13.7296 14.4118 13.3636 14.7648C11.9096 16.2197 8.07865 15.1143 4.98279 12.0149C1.88596 8.91463 0.781222 5.0826 2.22432 3.6385C2.62555 3.23094 2.75268 3.10386 3.13942 2.72273C3.28443 2.57983 3.67942 2.54774 3.85582 2.72202C4.1974 3.05948 4.831 3.87088 5.76307 5.16942C5.71481 5.22934 5.65455 5.29704 5.58121 5.37413C5.52738 5.43071 5.48253 5.47618 5.38449 5.57433L4.77183 6.18742C3.72956 7.22975 4.61719 9.00374 6.30395 10.6924C7.98954 12.3799 9.76394 13.2686 10.8059 12.226Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.50189 3.32134H13.6493C14.9475 3.32134 16 4.41352 16 5.7608C16 7.10807 14.9475 8.20025 13.6493 8.20025H12.6698V7.3871H13.6493C14.5148 7.3871 15.2164 6.65898 15.2164 5.7608C15.2164 4.86261 14.5148 4.13449 13.6493 4.13449H9.50189L11.1838 5.87988L10.6297 6.45486L8.00195 3.72792L10.6297 1.00098L11.1838 1.57596L9.50189 3.32134Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </svg>
  );
};

CallRedial.propTypes = {
  className: PropTypes.string,
};

export default CallRedial;
