import React, { useState, useEffect } from 'react';
import { useStyles } from './FamilyFile.style';
import LeftPanel from './LeftPanel/LeftPanel';
import Container from '../../common/Container/Container';
import PropTypes from 'prop-types';
import ContainerItem from '../../common/ContainerItem/ContainerItem';
import CenterPanel from './CenterPanel/CenterPanel';
import Sidebar from '../SendReferral/Sidebar/Sidebar';
import NotesSidePanel from '../../components/NotesSidePanel/NotesSidePanel';

const FamilyFileDesktop = ({ labels, familyFileId, activeTab, flags }) => {
  const classes = useStyles();
  const [isNotesPanelOpen, setIsNotesPanelOpen] = useState(true);
  const [onlyShowCommunitiesTabFf, setOnlyShowCommunitiesTabFf] = useState(
    false,
  );

  useEffect(() => {
    if (flags?.onlyShowCommunitiesTabFf) {
      setIsNotesPanelOpen(false);
      setOnlyShowCommunitiesTabFf(true);
    }
  }, [flags]);

  return (
    <Container className={classes.family_file_container} spacing={4}>
      <ContainerItem xs={3}>
        <LeftPanel labels={labels} familyFileId={familyFileId} />
      </ContainerItem>
      <ContainerItem xs={9}>
        <CenterPanel
          activeTab={activeTab}
          labels={labels}
          familyFileId={familyFileId}
        />
        {onlyShowCommunitiesTabFf && (
          <>
            <Sidebar
              labels={labels}
              onClickShowNotes={() => {
                setIsNotesPanelOpen(true);
              }}
              onClickShowFamilyFile={() => false}
              hideFamilyFileIcon={true}
            />

            <NotesSidePanel
              familyFileId={familyFileId}
              labels={labels}
              isOpen={isNotesPanelOpen}
              onClose={() => setIsNotesPanelOpen(false)}
              screenName="family file"
            />
          </>
        )}
      </ContainerItem>
    </Container>
  );
};

FamilyFileDesktop.propTypes = {
  labels: PropTypes.object,
  familyFileId: PropTypes.number,
  activeTab: PropTypes.number,
  flags: PropTypes.shape({
    onlyShowCommunitiesTabFf: PropTypes.bool,
  }),
};

export default FamilyFileDesktop;
