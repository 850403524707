import React from 'react';

const Wheelchair = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8801 34.6C26.4796 34.6 32.6401 28.4394 32.6401 20.84C32.6401 13.2405 26.4796 7.07996 18.8801 7.07996C11.2807 7.07996 5.12012 13.2405 5.12012 20.84C5.12012 28.4394 11.2807 34.6 18.8801 34.6Z"
        fill="#F3F5FC"
      />
      <path d="M19.2 11.16H23.04V17.96H19.2V11.16Z" fill="#F3E0D4" />
      <path
        d="M23.0401 18.6H19.2001C18.8466 18.6 18.5601 18.3135 18.5601 17.96V11.16C18.5601 10.8066 18.8466 10.52 19.2001 10.52H23.0401C23.3935 10.52 23.6801 10.8066 23.6801 11.16V17.96C23.6801 18.3135 23.3935 18.6 23.0401 18.6ZM19.8401 17.32H22.4001V11.8H19.8401V17.32Z"
        fill="#0D1321"
      />
      <path
        d="M20.7128 12.52H19.4443C18.6026 12.52 17.9202 11.8376 17.9202 10.9959V9.00004C17.9202 6.87924 19.6394 5.16003 21.7602 5.16003C23.881 5.16003 25.6002 6.87924 25.6002 9.00004V14.76H24.3334C23.3514 14.76 22.4134 14.3511 21.7451 13.6316L20.7128 12.52Z"
        fill="#F3E0D4"
      />
      <path
        d="M25.6 15.4H24.3333C23.177 15.4 22.0629 14.9141 21.2762 14.0671L20.4336 13.16H19.4442C18.2509 13.16 17.28 12.1891 17.28 10.9959V9.00002C17.28 6.52978 19.2898 4.52002 21.76 4.52002C24.2303 4.52002 26.24 6.52978 26.24 9.00002V14.76C26.24 15.1135 25.9535 15.4 25.6 15.4ZM21.76 5.80002C19.9955 5.80002 18.56 7.23554 18.56 9.00002V10.9959C18.56 11.4834 18.9567 11.88 19.4442 11.88H20.7127C20.8906 11.88 21.0605 11.9541 21.1816 12.0845L22.2139 13.196C22.7594 13.7832 23.5317 14.12 24.3333 14.12H24.96V9.00002C24.96 7.23554 23.5245 5.80002 21.76 5.80002Z"
        fill="#0D1321"
      />
      <path
        d="M37.44 40.4298H26.3968C26.0434 40.4298 25.7568 40.1432 25.7568 39.7898C25.7568 39.4363 26.0434 39.1498 26.3968 39.1498H37.44C37.7935 39.1498 38.08 39.4363 38.08 39.7898C38.08 40.1432 37.7935 40.4298 37.44 40.4298Z"
        fill="#0D1321"
      />
      <path
        d="M17.28 22.8766V17.8882C17.28 16.9559 18.0555 16.2 19.012 16.2H19.8067C23.3597 16.2 26.24 19.0074 26.24 22.4704V26.28L17.28 22.8766Z"
        fill="#D6621B"
      />
      <path
        d="M26.2399 26.92C26.1632 26.92 26.0863 26.9062 26.0127 26.8782L17.0527 23.4749C16.8041 23.3805 16.6399 23.1424 16.6399 22.8766V17.8882C16.6399 16.6045 17.7039 15.56 19.0119 15.56H19.8066C23.7068 15.56 26.8799 18.66 26.8799 22.4704V26.28C26.8799 26.4904 26.7765 26.6874 26.6032 26.8067C26.4949 26.8816 26.3679 26.92 26.2399 26.92ZM17.9199 22.4352L25.5999 25.3523V22.4704C25.5999 19.3658 23.001 16.84 19.8066 16.84H19.0119C18.4096 16.84 17.9199 17.3102 17.9199 17.8882V22.4352Z"
        fill="#0D1321"
      />
      <path
        d="M19.4404 27.6401C19.2057 27.6401 18.9796 27.5104 18.8674 27.2862L16.7074 22.9662C16.6631 22.8773 16.6399 22.7793 16.6399 22.68V19.24C16.6399 18.8865 16.9265 18.6 17.2799 18.6C17.6333 18.6 17.9199 18.8865 17.9199 19.24V22.5289L19.7261 26.1414L21.3917 25.3086L20.5837 24.0697C20.5159 23.9657 20.4799 23.8443 20.4799 23.7201V19.2401C20.4799 18.8867 20.7665 18.6001 21.1199 18.6001C21.4733 18.6001 21.7599 18.8867 21.7599 19.2401V23.5299L22.8561 25.2105C22.9557 25.3633 22.9855 25.5515 22.9377 25.7275C22.89 25.9036 22.7693 26.0508 22.6063 26.1324L19.7263 27.5724C19.6343 27.6184 19.5365 27.6401 19.4404 27.6401Z"
        fill="#0D1321"
      />
      <path
        d="M31.8108 35.2316L30.676 31.3652C30.4108 30.462 29.6388 29.7996 28.7059 29.6745L25.3123 29.2197L25.2385 25.1623L31.9395 26.7247C32.6667 26.8943 33.2424 27.449 33.4388 28.1693L35.28 34.9201L31.8108 35.2316Z"
        fill="#D5ECE8"
      />
      <path
        d="M31.8103 35.8715C31.5289 35.8715 31.2772 35.6862 31.1967 35.4117L30.0617 31.5452C29.8679 30.8854 29.3023 30.4 28.6207 30.3086L25.2271 29.8539C24.9137 29.812 24.6779 29.5473 24.6723 29.2312L24.5985 25.1737C24.595 24.9764 24.6825 24.7884 24.8359 24.6644C24.9894 24.5403 25.1913 24.4939 25.3836 24.5388L32.0846 26.1014C33.042 26.3246 33.7975 27.0524 34.0561 28.0011L35.8972 34.7515C35.9471 34.9344 35.9134 35.1297 35.8052 35.2853C35.6971 35.4408 35.5257 35.5405 35.3369 35.5574L31.8678 35.8689C31.8489 35.8705 31.8297 35.8715 31.8103 35.8715ZM25.9422 28.6584L28.791 29.0401C29.9732 29.1985 30.9542 30.0403 31.29 31.1848L32.2771 34.547L34.4614 34.3508L32.8214 28.3377C32.6867 27.8435 32.2931 27.4643 31.7942 27.3478L25.8934 25.9718L25.9422 28.6584Z"
        fill="#0D1321"
      />
      <path
        d="M35.2802 34.92L37.7062 36.9658H32.3202L31.811 35.2316L35.2802 34.92Z"
        fill="#82B7A8"
      />
      <path
        d="M37.706 37.6058H32.3201C32.0361 37.6058 31.786 37.4186 31.706 37.1459L31.1969 35.4117C31.1428 35.2279 31.174 35.0296 31.282 34.8712C31.3899 34.7128 31.563 34.6112 31.7537 34.5941L35.2228 34.2826C35.3921 34.2669 35.562 34.3207 35.6927 34.4307L38.1187 36.4765C38.3241 36.6498 38.3995 36.933 38.3071 37.1853C38.215 37.4379 37.9748 37.6058 37.706 37.6058ZM32.7993 36.3258H35.9543L35.0715 35.5813L32.6446 35.7992L32.7993 36.3258Z"
        fill="#0D1321"
      />
      <path
        d="M37.44 37.7533H28.2135C27.86 37.7533 27.5735 37.4667 27.5735 37.1133C27.5735 36.7598 27.86 36.4733 28.2135 36.4733H37.44C37.7935 36.4733 38.08 36.7598 38.08 37.1133C38.08 37.4667 37.7935 37.7533 37.44 37.7533Z"
        fill="#0D1321"
      />
      <path
        d="M9.91996 15.4H12.6539C13.7948 15.4 14.72 16.325 14.72 17.4661V24.12L12.8 25.56V18.1555C12.8 17.6941 12.4259 17.32 11.9644 17.32H9.91996C9.38972 17.32 8.95996 16.8903 8.95996 16.36C8.95996 15.8298 9.38972 15.4 9.91996 15.4Z"
        fill="#F7EED0"
      />
      <path
        d="M12.8001 26.2C12.7025 26.2 12.6044 26.1778 12.5138 26.1325C12.297 26.024 12.1601 25.8024 12.1601 25.56V18.1555C12.1601 18.0477 12.0724 17.96 11.9645 17.96H9.92007C9.03783 17.96 8.32007 17.2423 8.32007 16.36C8.32007 15.4778 9.03783 14.76 9.92007 14.76H12.654C14.1461 14.76 15.3601 15.9739 15.3601 17.4661V24.12C15.3601 24.3215 15.2652 24.5112 15.1041 24.632L13.1841 26.072C13.0711 26.1567 12.9361 26.2 12.8001 26.2ZM9.92007 16.04C9.74359 16.04 9.60007 16.1835 9.60007 16.36C9.60007 16.5365 9.74359 16.68 9.92007 16.68H11.9645C12.7781 16.68 13.4401 17.3419 13.4401 18.1555V24.28L14.0801 23.8V17.4661C14.0801 16.6797 13.4404 16.04 12.654 16.04H9.92007ZM14.7201 24.12H14.7217H14.7201Z"
        fill="#0D1321"
      />
      <path
        d="M18.7201 42.84C24.2872 42.84 28.8001 38.327 28.8001 32.76C28.8001 27.193 24.2872 22.68 18.7201 22.68C13.1531 22.68 8.64014 27.193 8.64014 32.76C8.64014 38.327 13.1531 42.84 18.7201 42.84Z"
        fill="#DDE3F8"
      />
      <path
        d="M18.72 43.48C12.809 43.48 8 38.6711 8 32.76C8 26.849 12.809 22.04 18.72 22.04C24.631 22.04 29.44 26.849 29.44 32.76C29.44 38.6711 24.631 43.48 18.72 43.48ZM18.72 23.32C13.5149 23.32 9.28 27.5548 9.28 32.76C9.28 37.9653 13.5149 42.2 18.72 42.2C23.9251 42.2 28.16 37.9653 28.16 32.76C28.16 27.5548 23.9251 23.32 18.72 23.32Z"
        fill="#0D1321"
      />
      <path
        d="M18.7201 40.1178C22.7837 40.1178 26.0778 36.8236 26.0778 32.76C26.0778 28.6964 22.7837 25.4022 18.7201 25.4022C14.6565 25.4022 11.3623 28.6964 11.3623 32.76C11.3623 36.8236 14.6565 40.1178 18.7201 40.1178Z"
        fill="#A37D00"
      />
      <path
        d="M18.72 40.7577C14.3101 40.7577 10.7224 37.1701 10.7224 32.76C10.7224 28.3499 14.3101 24.7622 18.72 24.7622C23.1299 24.7622 26.7176 28.3499 26.7176 32.76C26.7176 37.1701 23.1299 40.7577 18.72 40.7577ZM18.72 26.0422C15.016 26.0422 12.0024 29.0558 12.0024 32.76C12.0024 36.4641 15.016 39.4777 18.72 39.4777C22.424 39.4777 25.4376 36.4641 25.4376 32.76C25.4376 29.0558 22.424 26.0422 18.72 26.0422Z"
        fill="#0D1321"
      />
      <path
        d="M19.08 35.2928C20.28 35.2928 21.2528 34.32 21.2528 33.12C21.2528 31.92 20.28 30.9472 19.08 30.9472C17.88 30.9472 16.9072 31.92 16.9072 33.12C16.9072 34.32 17.88 35.2928 19.08 35.2928Z"
        fill="#F7EED0"
      />
      <path
        d="M19.0799 35.9328C17.529 35.9328 16.2671 34.671 16.2671 33.12C16.2671 31.5689 17.529 30.3072 19.0799 30.3072C20.6308 30.3072 21.8927 31.5689 21.8927 33.12C21.8927 34.671 20.6308 35.9328 19.0799 35.9328ZM19.0799 31.5872C18.2348 31.5872 17.5471 32.2747 17.5471 33.12C17.5471 33.9653 18.2348 34.6528 19.0799 34.6528C19.925 34.6528 20.6127 33.9653 20.6127 33.12C20.6127 32.2747 19.925 31.5872 19.0799 31.5872Z"
        fill="#0D1321"
      />
      <path
        d="M38.16 42.84C39.7506 42.84 41.04 41.5505 41.04 39.96C41.04 38.3694 39.7506 37.08 38.16 37.08C36.5694 37.08 35.28 38.3694 35.28 39.96C35.28 41.5505 36.5694 42.84 38.16 42.84Z"
        fill="#DDE3F8"
      />
      <path
        d="M38.1601 43.48C36.2192 43.48 34.6401 41.901 34.6401 39.96C34.6401 38.019 36.2192 36.44 38.1601 36.44C40.1011 36.44 41.6802 38.019 41.6802 39.96C41.6802 41.901 40.1011 43.48 38.1601 43.48ZM38.1601 37.72C36.9249 37.72 35.9201 38.7248 35.9201 39.96C35.9201 41.1952 36.9249 42.2 38.1601 42.2C39.3953 42.2 40.4001 41.1952 40.4001 39.96C40.4001 38.7248 39.3953 37.72 38.1601 37.72Z"
        fill="#0D1321"
      />
      <path
        d="M38.1601 41.1162C38.7986 41.1162 39.3162 40.5986 39.3162 39.9601C39.3162 39.3215 38.7986 38.8039 38.1601 38.8039C37.5215 38.8039 37.0039 39.3215 37.0039 39.9601C37.0039 40.5986 37.5215 41.1162 38.1601 41.1162Z"
        fill="#0D1321"
      />
      <path
        d="M38.4 8.36004C38.0466 8.36004 37.76 8.07348 37.76 7.72004V5.80003C37.76 5.44659 38.0466 5.16003 38.4 5.16003C38.7535 5.16003 39.04 5.44659 39.04 5.80003V7.72004C39.04 8.07348 38.7535 8.36004 38.4 8.36004Z"
        fill="#0D1321"
      />
      <path
        d="M38.4 12.84C38.0466 12.84 37.76 12.5535 37.76 12.2V10.28C37.76 9.92657 38.0466 9.64001 38.4 9.64001C38.7535 9.64001 39.04 9.92657 39.04 10.28V12.2C39.04 12.5535 38.7535 12.84 38.4 12.84Z"
        fill="#0D1321"
      />
      <path
        d="M37.1201 9.63998H35.2001C34.8466 9.63998 34.5601 9.35342 34.5601 8.99998C34.5601 8.64655 34.8466 8.35999 35.2001 8.35999H37.1201C37.4735 8.35999 37.7601 8.64655 37.7601 8.99998C37.7601 9.35342 37.4735 9.63998 37.1201 9.63998Z"
        fill="#0D1321"
      />
      <path
        d="M41.6 9.63998H39.68C39.3266 9.63998 39.04 9.35342 39.04 8.99998C39.04 8.64655 39.3266 8.35999 39.68 8.35999H41.6C41.9535 8.35999 42.24 8.64655 42.24 8.99998C42.24 9.35342 41.9535 9.63998 41.6 9.63998Z"
        fill="#0D1321"
      />
      <path
        d="M42.2399 17.9601C42.5933 17.9601 42.8799 17.6735 42.8799 17.3201C42.8799 16.9666 42.5933 16.6801 42.2399 16.6801C41.8864 16.6801 41.5999 16.9666 41.5999 17.3201C41.5999 17.6735 41.8864 17.9601 42.2399 17.9601Z"
        fill="#0D1321"
      />
      <path
        d="M30.0799 16.04C30.4334 16.04 30.72 15.7535 30.72 15.4C30.72 15.0465 30.4334 14.76 30.0799 14.76C29.7265 14.76 29.4399 15.0465 29.4399 15.4C29.4399 15.7535 29.7265 16.04 30.0799 16.04Z"
        fill="#0D1321"
      />
      <path
        d="M7.68004 24.36C8.0335 24.36 8.32004 24.0735 8.32004 23.72C8.32004 23.3666 8.0335 23.08 7.68004 23.08C7.32658 23.08 7.04004 23.3666 7.04004 23.72C7.04004 24.0735 7.32658 24.36 7.68004 24.36Z"
        fill="#0D1321"
      />
      <path
        d="M37.4402 26.28C36.205 26.28 35.2002 25.2752 35.2002 24.04C35.2002 22.8048 36.205 21.8 37.4402 21.8C38.6754 21.8 39.6802 22.8048 39.6802 24.04C39.6802 25.2752 38.6754 26.28 37.4402 26.28ZM37.4402 23.08C36.9108 23.08 36.4802 23.5106 36.4802 24.04C36.4802 24.5695 36.9108 25 37.4402 25C37.9696 25 38.4002 24.5695 38.4002 24.04C38.4002 23.5106 37.9696 23.08 37.4402 23.08Z"
        fill="#0D1321"
      />
      <path
        d="M9.91993 10.9201C8.68473 10.9201 7.67993 9.91526 7.67993 8.68006C7.67993 7.44486 8.68473 6.44006 9.91993 6.44006C11.1551 6.44006 12.1599 7.44486 12.1599 8.68006C12.1599 9.91526 11.1551 10.9201 9.91993 10.9201ZM9.91993 7.72006C9.39049 7.72006 8.95993 8.15062 8.95993 8.68006C8.95993 9.2095 9.39049 9.64006 9.91993 9.64006C10.4494 9.64006 10.8799 9.2095 10.8799 8.68006C10.8799 8.15062 10.4494 7.72006 9.91993 7.72006Z"
        fill="#0D1321"
      />
    </svg>
  );
};

export default Wheelchair;
