import React, { useState, useEffect } from 'react';
import { useStyles } from './FamilyFileSidePanel.style';
import PropTypes from 'prop-types';
import SidePanel from '../../common/SidePanel/SidePanel';
import CaseProfileMobile from '../CaseProfile/CaseProfileMobile';
import DropdownPopover from '../../common/Dropdown/DropdownPopover/DropdownPopover';
import PencilEdit from '../../common/Icons/basic/PencilEdit';
import ResidentUpdate from '../../services/Resident/ResidentUpdate';
import useGraphQLQuery from '../../hooks/useGraphQLQuery';
import toBasicInformation from '../CaseProfileSidePanel/basicInformationService';
import Loading from '../Loading/Loading';
import { toContacts } from '../../pages/FamilyFile/LeftPanel/contactsService';
import ContactsUpdate from '../../services/Contacts/ContactsUpdate';
import toFamilyFileInformation from '../FamilyFile/familyFileService';
import Divider from '../../common/Divider/Divider';
import ContactInformation from '../FamilyFile/ContactInformation/ContactInformation';
import FinancialCriteria from '../FamilyFile/FinancialCriteria/FinancialCriteria';
import MedicalBackgroundDesktop from '../FamilyFile/MedicalBackground/MedicalBackgroundDesktop';
import familyFileSidePanelQuery from './FamilyFileSidePanelQuery';
import CloseSidePanelButton from './CloseSidePanelButton';
import Media from '../../common/Media/Media';

const FamilyFileSidePanel = ({
  onClose,
  isOpen,
  familyFileId,
  labels,
  onSave,
}) => {
  const classes = useStyles();
  const sidePanelWidth = '488';

  const [
    isEditResidentInformationOpen,
    setIsEditResidentInformationOpen,
  ] = useState(false);
  const [
    isEditContactInformationOpen,
    setIsEditContactInformationOpen,
  ] = useState(false);
  const [basicInformation, setBasicInformation] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [familyFileInformation, setFamilyFileInformation] = useState(null);

  const [isSelfLead, setIsSelfLead] = useState(false);

  const [financialCriteria, setFinancialCriteria] = useState(null);

  const { loading, error, data } = useGraphQLQuery(familyFileSidePanelQuery, {
    variables: { familyFileId },
  });

  useEffect(() => {
    if (data) {
      setBasicInformation(toBasicInformation(data.findFamilyFileById));

      setFamilyFileInformation(
        toFamilyFileInformation(data.findFamilyFileById),
      );
      setContacts(
        toContacts(
          data.findFamilyFileById.contacts,
          data.findFamilyFileById.residents,
          data.findFamilyFileById.leadData,
          familyFileId,
        ),
      );
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (familyFileInformation) {
      setIsSelfLead(familyFileInformation.isSelfLead);
      setFinancialCriteria(familyFileInformation.financialCriteria);
    }
  }, [familyFileInformation]);

  const refetchHandler = () => {
    setIsEditResidentInformationOpen(false);
    onSave();
  };

  const handleRefetch = () => {
    onSave();
  };

  const closeEditContactInformationHandler = () => {
    setIsEditContactInformationOpen(false);
  };

  const items = [
    {
      label: <>{labels.EDIT_CONTACT_INFORMATION}</>,
      action: () => setIsEditContactInformationOpen(true),
    },
    {
      label: <>{labels.EDIT_RESIDENT_INFORMATION}</>,
      action: () => setIsEditResidentInformationOpen(true),
    },
  ];

  const additionalToolbar = (
    <div className={classes.additional_toolbar}>
      <DropdownPopover
        className={classes.additional_toolbar}
        clickableElement={<PencilEdit className={classes.edit_icon} />}
        items={items}
        placement={'right'}
      />
    </div>
  );

  const getSidePanel = (variant) => {
    return (
      <SidePanel
        onClose={onClose}
        isOpen={isOpen}
        additionalToolbar={additionalToolbar}
        variant={variant}
        displayCloseIcon={false}
        width={sidePanelWidth}
      >
        <>
          {loading && <Loading />}
          {error && `Error! ${error.message}`}

          {!loading && (
            <div className={classes.side_panel_container}>
              <CloseSidePanelButton
                toolTipText={labels.HIDE_FAMILY_FILE}
                onClick={() => onClose()}
              />
              <CaseProfileMobile familyFileId={familyFileId} labels={labels} />
              <Divider spacing={0} color="platinum1" />
              <div className={classes.family_file_container}>
                <div className={classes.mobile_container}>
                  {contacts && (
                    <ContactInformation
                      labels={labels}
                      contactInformationData={contacts}
                      handleRefetch={handleRefetch}
                      allowEdit={true}
                    />
                  )}
                  {familyFileInformation && (
                    <MedicalBackgroundDesktop
                      labels={labels}
                      medicalBackgroundData={
                        familyFileInformation?.medicalBackground
                      }
                      isSelfLead={isSelfLead}
                      enableReferralAlertValidation={false}
                      onCancelEdit={handleRefetch}
                      onSave={handleRefetch}
                      allowEdit={true}
                    />
                  )}
                  {financialCriteria && (
                    <FinancialCriteria
                      labels={labels}
                      financialCriteriaData={financialCriteria}
                      onCancelEdit={handleRefetch}
                      onSave={handleRefetch}
                      allowEdit={true}
                    />
                  )}
                </div>
              </div>
              {basicInformation && (
                <ResidentUpdate
                  labels={labels}
                  basicInformation={basicInformation}
                  handleRefetchState={refetchHandler}
                  editResidentInformationOpen={isEditResidentInformationOpen}
                  enableReferralAlertValidation={false}
                />
              )}
              {contacts && (
                <ContactsUpdate
                  labels={labels}
                  contact={contacts}
                  handleRefetchState={handleRefetch}
                  familyFileId={familyFileId}
                  editContactInformationOpen={isEditContactInformationOpen}
                  handleClosingEdit={closeEditContactInformationHandler}
                  enableReferralAlertValidation={false}
                  isSelfLead={isSelfLead}
                />
              )}
            </div>
          )}
        </>
      </SidePanel>
    );
  };

  return (
    <Media xs={getSidePanel('persistent')} lg={getSidePanel('persistent')} />
  );
};

FamilyFileSidePanel.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  familyFileId: PropTypes.number,
  onSave: PropTypes.func,
  labels: PropTypes.object,
};

export default FamilyFileSidePanel;
