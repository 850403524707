import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './CommunitiesTable.style';
import ScreenNotSupported from './ScreenNotSupported/ScreenNotSupported';

const CommunitiesTableXS = ({ labels }) => {
  const classes = useStyles();

  return (
    <div className={classes.communities_table_lg_container}>
      <div className={classes.table_container_xs}>
        <ScreenNotSupported labels={labels} />
      </div>
    </div>
  );
};

CommunitiesTableXS.propTypes = {
  labels: PropTypes.object.isRequired,
};

CommunitiesTableXS.defaultProps = {
  onAppError: () => {},
};

export default CommunitiesTableXS;
