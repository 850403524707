import gql from 'graphql-tag';

export default gql`
  query($familyFileId: Int) {
    findFamilyFileById(id: $familyFileId) {
      oneId

      actions {
        actionId
        dueDate
        description
        type
        status
        completedAt
      }
    }
  }
`;
