import React from 'react';
import MaterialCheckbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import { FormControlLabel } from '@material-ui/core';
import { useStyles } from './Checkbox.style';
import clsx from 'clsx';

const Checkbox = ({
  checked,
  label,
  disabled,
  indeterminate,
  onChange,
  className,
}) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      label={label}
      className={clsx(
        classes.checkbox_container,
        className,
        disabled && classes.disabled_label_container,
      )}
      disabled={disabled}
      control={
        <MaterialCheckbox
          className={disabled ? classes.checkbox_disabled : classes.checkbox}
          checked={checked}
          indeterminate={indeterminate}
          onChange={onChange}
          color="primary"
        />
      }
    />
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  checked: false,
  label: '',
  className: '',
  disabled: false,
  indeterminate: false,
  onChange: () => {},
};

export default Checkbox;
