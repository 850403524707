import React from 'react';
import { inquiryPropType } from '../inquiryPropTypes';
import NotesDropdown from '../CallBar/ConferenceCallButtons/NotesDropdown/';
import { useStyles } from './FilterBar.style';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const FilterBar = ({ inquiry, className }) => {
  const classes = useStyles();
  return (
    <div className={classes.filter_bar}>
      <div></div>
      <div></div>
      <div className={clsx(className, classes.pull_right)}>
        <NotesDropdown className="callbarNotes" inquiry={inquiry} />
      </div>
    </div>
  );
};

FilterBar.propTypes = {
  inquiry: inquiryPropType,
  className: PropTypes.string,
};

FilterBar.defaultProps = {
  className: '',
};

export default FilterBar;
