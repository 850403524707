import React from 'react';
import Tag from 'common/Tag/Tag';
import PropTypes from 'prop-types';
import { useStyles } from './StatusTag.style';
import labels from 'config/labels';
import { capitalize } from 'utils/stringUtils';

const StatusTag = ({ centered, status }) => {
  const classes = useStyles();
  const getColor = () => {
    if (status.toLowerCase() === labels.OPEN.toLowerCase()) {
      return 'wintergreenDream5';
    }
    if (status.toLowerCase() === labels.CLOSED.toLowerCase()) {
      return 'smokyTopaz5';
    }
    return 'transparent';
  };

  return (
    <div className={centered ? classes.centered : ''}>
      {status !== '-' && <Tag text={capitalize(status)} color={getColor()} />}
      {status === '-' && '-'}
    </div>
  );
};

StatusTag.propTypes = {
  centered: PropTypes.bool,
  status: PropTypes.string,
};

StatusTag.defaultProps = {
  centered: false,
  status: '-',
};

export default StatusTag;
