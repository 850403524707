import React from 'react';
import PropTypes from 'prop-types';

const SwimmingPool = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.85317 32.304L11.6377 31.2576C12.4316 30.792 13.4149 30.7974 14.2034 31.2717L15.8731 32.2754C16.6798 32.7605 17.5869 33.0032 18.4946 33.0032C19.3831 33.0032 20.2724 32.7703 21.0676 32.304L22.8522 31.2576C23.6461 30.792 24.6293 30.7974 25.4182 31.2717L27.0876 32.2754C28.6849 33.2355 30.6752 33.2463 32.2825 32.304L34.067 31.2576C34.8609 30.792 35.8438 30.7974 36.6327 31.2717L38.3021 32.2754C39.8993 33.2355 41.8897 33.2463 43.497 32.304L45.2832 31.2566L43.9793 29.0327L42.1928 30.08C41.4002 30.5449 40.4183 30.5396 39.6308 30.0659L37.961 29.0622C36.6129 28.2515 34.9838 28.1169 33.5413 28.6617V8.9334C33.5413 6.53185 35.495 4.57812 37.8966 4.57812H40.0823V2H37.8963C34.0734 2 30.9632 5.11019 30.9632 8.9334V11.0409H20.1502V8.9334C20.1502 6.53185 22.1039 4.57812 24.5055 4.57812H26.6912V2H24.5055C20.6823 2 17.5721 5.11019 17.5721 8.9334V30.2479C17.4452 30.1975 17.321 30.1378 17.2015 30.0659L15.5321 29.0622C13.9342 28.1014 11.9422 28.0904 10.3339 29.0337L8.549 30.08C7.75643 30.5449 6.77453 30.5396 5.98699 30.0659L4.32834 29.0689L3 31.2784L4.65866 32.2754C6.25555 33.2355 8.24587 33.2463 9.85317 32.304V32.304ZM28.4163 30.0659L26.7466 29.0622C25.1487 28.1014 23.1567 28.0904 21.5484 29.0337L20.1502 29.8534V22.8442H30.9632V30.0884C30.173 30.5446 29.1988 30.5366 28.4163 30.0659V30.0659ZM30.9629 13.619V20.266H20.1502V13.619H30.9629Z"
        fill="currentColor"
      />
      <path
        d="M9.85317 38.8023L11.6377 37.756C12.4316 37.2903 13.4149 37.2957 14.2034 37.7701L15.8731 38.7738C17.47 39.7339 19.4604 39.7446 21.0676 38.8023L22.8522 37.756C23.6461 37.2903 24.6293 37.2957 25.4182 37.7701L27.0876 38.7738C28.6845 39.7339 30.6748 39.7449 32.2825 38.8023L34.067 37.756C34.8609 37.2903 35.8438 37.2957 36.6327 37.7701L38.3021 38.7738C39.1091 39.2589 40.0162 39.5016 40.9235 39.5016C41.8124 39.5016 42.7017 39.2686 43.497 38.8023L45.2832 37.7549L43.979 35.531L42.1928 36.5783C41.3999 37.0433 40.4183 37.0379 39.6308 36.5642L37.961 35.5605C36.3631 34.5994 34.3715 34.5887 32.7628 35.532L30.9783 36.5783C30.1857 37.0433 29.2038 37.0379 28.4163 36.5642L26.7469 35.5605C25.1487 34.5994 23.1567 34.5887 21.5484 35.532L19.7638 36.5783C18.9712 37.0433 17.9893 37.0379 17.2018 36.5642L15.5324 35.5605C13.9342 34.5994 11.9422 34.5887 10.3339 35.532L8.54933 36.5783C7.75643 37.0433 6.77486 37.0379 5.98733 36.5642L4.32867 35.5672L3 37.7764L4.65866 38.7738C6.25555 39.7339 8.24587 39.7449 9.85317 38.8023V38.8023Z"
        fill="currentColor"
      />
      <path
        d="M42.1928 43.0768C41.3999 43.5417 40.4183 43.5364 39.6308 43.0627L37.961 42.059C36.3631 41.0979 34.3711 41.0868 32.7628 42.0301L30.9783 43.0768C30.1854 43.5417 29.2038 43.5364 28.4163 43.0627L26.7466 42.059C25.1487 41.0979 23.1567 41.0871 21.5484 42.0301L19.7638 43.0768C18.9712 43.5414 17.9893 43.5364 17.2018 43.0627L15.5321 42.059C13.9342 41.0979 11.9422 41.0871 10.3339 42.0301L8.54933 43.0768C7.75643 43.5414 6.77486 43.5364 5.98733 43.0627L4.32867 42.0654L3 44.2749L4.65866 45.2722C6.25521 46.232 8.24587 46.2431 9.85317 45.3008L11.6377 44.2541C12.4316 43.7885 13.4149 43.7942 14.2034 44.2682L15.8731 45.2722C17.47 46.2323 19.4604 46.2431 21.0676 45.3008L22.8522 44.2541C23.6461 43.7885 24.6293 43.7942 25.4182 44.2682L27.0876 45.2722C28.6845 46.232 30.6748 46.2431 32.2825 45.3008L34.067 44.2541C34.8609 43.7885 35.8438 43.7942 36.6327 44.2682L38.3021 45.2722C39.1091 45.7573 40.0162 46 40.9235 46C41.8124 46 42.7017 45.767 43.497 45.3008L45.2832 44.2531L43.979 42.0291L42.1928 43.0768Z"
        fill="currentColor"
      />
    </svg>
  );
};

SwimmingPool.propTypes = {
  className: PropTypes.string,
};

export default SwimmingPool;
