import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => ({
  month_and_year_container: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginLeft: '24px',
    '& svg': {
      verticalAlign: 'middle',
    },
  },
  select: {
    minWidth: '160px',
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.light.platinum1}`,
    backgroundColor: theme.palette.light.white,
    borderRadius: '2px',
    padding: '8px 16px',
    cursor: 'pointer',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'left',
    color: theme.palette.dark.eerieBlack5,
    fontSize: '15px',
    display: 'flex',
    lineHeight: '18px',
    '&:focus': {
      border: `1px solid ${theme.palette.neutral.cosmicCobalt3}`,
      outline: 'none',
    },
    '&:hover': {
      border: `1px solid ${theme.palette.neutral.cosmicCobalt3}`,
      outline: 'none',
    },
  },
  filled: {
    '& $select': {
      border: `1px solid ${theme.palette.neutral.cosmicCobalt2}`,
    },
    '& $label': {
      color: theme.palette.dark.eerieBlack1,
    },
  },
  error: {
    '& $select': {
      border: `1px solid ${theme.palette.correction.smokyTopaz2}`,
    },
    '& $label': {
      color: theme.palette.correction.smokyTopaz2,
    },
  },
  label: {
    fontSize: '13px',
    lineHeight: '19px',
    marginBottom: '4px',
    color: theme.palette.dark.eerieBlack5,
    textAlign: 'left',
  },
  menu: {
    position: 'relative',
    width: '100%',
  },
  items_container: {
    position: 'absolute',
    width: '350px',
    top: 0,
    left: 0,
    padding: '10px 0 24px 0',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.neutral.cosmicCobalt5}`,
    backgroundColor: theme.palette.light.white,
    textAlign: 'left',
  },
  selected: {
    backgroundColor: theme.palette.neutral.dropdown,
  },
  group_name: {
    paddingLeft: '16px',
    marginBottom: '13px',
  },
  year_and_month_container: {
    display: 'flex',
    alignItems: 'center',
    '& fieldset': {
      width: 'fit-content',
    },
  },
  other_distance_input: {
    width: '140px',
    position: 'absolute',
    right: '34px',
    '& input': {
      textAlign: 'right',
    },
  },
  radio_container: {
    width: '100%',
    '& label': {
      margin: 0,
      paddingLeft: '7px',
      width: '100%',
      '&:hover': {
        backgroundColor: theme.palette.neutral.dropdown,
        color: theme.palette.dark.eerieBlack1,
      },
    },
    '& label.selected': {
      backgroundColor: theme.palette.neutral.dropdown,
      color: theme.palette.dark.eerieBlack1,
    },
  },
  dropdowns_container: {
    display: 'flex',
  },
  select_option: {
    width: 120,
    marginRight: 24,
  },
}));

export { useStyles };
