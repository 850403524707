import React from 'react';
import PropTypes from 'prop-types';

const FileCreate = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M13 3H5V21H15V23H5C3.89543 23 3 22.1046 3 21V3C3 1.89543 3.89543 1 5 1H15.4142L21 6.58579V14H19V9H15C13.8954 9 13 8.10457 13 7V3ZM19 19V17H21V19H23V21H21V23H19V21H17V19H19ZM15 3.41421L18.5858 7H15V3.41421Z"
      />
    </svg>
  );
};

FileCreate.propTypes = {
  className: PropTypes.string,
};

export default FileCreate;
