import { cloneDeep } from 'lodash';
import { CHICLET_CARD_STATES } from '../../pages/callCenter/Inquiry/FormChiclets/ChicletCard/ChicletCard';
import { stepTypes } from './fieldsMapper';
import { warmTransferTypes } from './warmTransferService';
import formServiceHelper from './formServiceHelper';

const warmTransferChiclets = [
  stepTypes.slaMatch.key,
  stepTypes.slaNotFound.key,
  stepTypes.completeWarmTransfer.key,
  stepTypes.previousSlaNotAccepted.key,
  stepTypes.previousSlaAccepted.key,
];

const resetWarmTransferChiclets = (formSteps, typeToShow) => {
  const typesToHide = warmTransferChiclets.filter((w) => w !== typeToShow);
  let newFormSteps = cloneDeep(formSteps);
  newFormSteps = newFormSteps.map((step) => {
    if (step.type === typeToShow) {
      step.state = CHICLET_CARD_STATES.CURRENT.value;
      step.hidden = false;
      step.extraFields.hidden = false;
      return step;
    } else if (typesToHide.includes(step.type)) {
      step.extraFields.hidden = true;
      step.hidden = true;
      return step;
    }
    return step;
  });
  return newFormSteps;
};

const hideWarmTransferResults = (formSteps) => {
  let newSteps = cloneDeep(formSteps);
  newSteps = newSteps.map((step) => {
    if (warmTransferChiclets.includes(step.type)) {
      step.extraFields.hidden = true;
      step.hidden = true;
      return step;
    }
    return step;
  });
  return newSteps;
};

const prepareChicletsForRetry = (formSteps) => {
  let newFormSteps = hideWarmTransferResults(formSteps);
  newFormSteps = updateCollapsedFindSla(newFormSteps);
  newFormSteps = formServiceHelper.hideChicletByStepType(
    newFormSteps,
    stepTypes.tryPreviousSla,
  );
  newFormSteps = formServiceHelper.showChicletByStepType(
    newFormSteps,
    stepTypes.transferSla,
  );
  return newFormSteps;
};

const updateStepsSlaNotFound = (formSteps) => {
  let newSteps = resetWarmTransferChiclets(
    formSteps,
    stepTypes.slaNotFound.key,
  );
  newSteps = newSteps.map((step) => {
    const newStep = cloneDeep(step);
    if (newStep.type === stepTypes.transferSla.key) {
      newStep.extraFields.hidden = true;
      newStep.hidden = true;
      return newStep;
    }
    return newStep;
  });
  return newSteps;
};

const findTransferToSLA = (formSteps) => {
  const newSteps = cloneDeep(formSteps);
  return newSteps?.find((step) => step.type === stepTypes.transferSla.key);
};

const updateCollapsedFindSla = (formSteps) => {
  let newSteps = cloneDeep(formSteps);
  newSteps = newSteps.map((step) => {
    const newStep = cloneDeep(step);
    if (newStep.type === stepTypes.transferSla.key) {
      newStep.collapsed = false;
      return newStep;
    }
    return newStep;
  });
  return newSteps;
};

const updateStepsSlaFound = (formSteps, warmTransferType) => {
  let stepType = stepTypes.slaMatch.key;

  if (warmTransferType === warmTransferTypes.REINQUIRY_OR_DUPLICATE) {
    stepType = stepTypes.previousSlaAccepted.key;
  }

  return resetWarmTransferChiclets(formSteps, stepType);
};

const showWarmTransferErrors = (formSteps, error) => {
  const newSteps = cloneDeep(formSteps);
  const newFormSteps = newSteps.map((step) => {
    const newStep = cloneDeep(step);
    if (newStep.type === stepTypes.transferSla.key) {
      newStep.state = CHICLET_CARD_STATES.CURRENT_WITH_ERROR.value;
      newStep.errors = [error];
      return newStep;
    }
    return newStep;
  });
  return newFormSteps;
};

const showWarmTransferIntroduction = (formSteps) => {
  const newSteps = cloneDeep(formSteps);
  const newFormSteps = newSteps.map((step) => {
    if (step.type === stepTypes.completeWarmTransfer.key) {
      step.extraFields.hidden = false;
      step.hidden = false;
      return step;
    }
    return step;
  });
  return newFormSteps;
};

const disableWarmTransferChiclets = (
  formSteps,
  stepsToHide = [stepTypes.transferSla.key, stepTypes.slaMatch.key],
) => {
  const newSteps = cloneDeep(formSteps);
  const newFormSteps = newSteps?.map((step) => {
    if (stepsToHide.includes(step.type)) {
      step.disabled = true;
    }
    return step;
  });
  return newFormSteps;
};

const showCompleteWTChiclet = (formSteps) => {
  const newSteps = cloneDeep(formSteps);
  const newFormSteps = newSteps.map((step) => {
    if (step.type === stepTypes.completeWarmTransferFamilyDisconnected.key) {
      step.hidden = false;
      step.extraFields.hidden = false;
      return step;
    }
    return step;
  });
  return newFormSteps;
};

const disableChicletsAfterCreatingFF = (formSteps) => {
  const typesToKeepEnabled = Object.values(stepTypes)
    .filter((type) => type.allowAfterFamilyFileCreated)
    .map((stepType) => stepType.key);

  let newFormSteps = cloneDeep(formSteps);

  newFormSteps = newFormSteps?.map((step) => {
    let disabled = step.disabled;
    if (!typesToKeepEnabled.includes(step.type)) {
      disabled = true;
    }
    return {
      ...step,
      disabled,
    };
  });

  return newFormSteps;
};

const showStartingWarmTransferStep = (formSteps, warmTransferType) => {
  let showType = stepTypes.transferSla;
  let hideType = stepTypes.tryPreviousSla;
  if (warmTransferType === warmTransferTypes.REINQUIRY_OR_DUPLICATE) {
    showType = stepTypes.tryPreviousSla;
    hideType = stepTypes.transferSla;
  }
  let newFormSteps = formServiceHelper.showChicletByStepType(
    formSteps,
    showType,
  );
  newFormSteps = formServiceHelper.hideChicletByStepType(
    newFormSteps,
    hideType,
  );

  return newFormSteps;
};

const showPreviousSlaNotAcceptedStep = (formSteps) => {
  let newFormSteps = formServiceHelper.showChicletByStepType(
    formSteps,
    stepTypes.previousSlaNotAccepted,
  );
  newFormSteps = formServiceHelper.hideChicletByStepType(
    newFormSteps,
    stepTypes.transferSla,
  );
  newFormSteps = formServiceHelper.hideChicletByStepType(
    newFormSteps,
    stepTypes.tryPreviousSla,
  );

  return newFormSteps;
};

const warmTransferFormService = {
  disableChicletsAfterCreatingFF,
  disableWarmTransferChiclets,
  findTransferToSLA,
  hideWarmTransferResults,
  showCompleteWTChiclet,
  showPreviousSlaNotAcceptedStep,
  showStartingWarmTransferStep,
  showWarmTransferErrors,
  showWarmTransferIntroduction,
  updateCollapsedFindSla,
  updateStepsSlaFound,
  updateStepsSlaNotFound,
  prepareChicletsForRetry,
};

export default warmTransferFormService;
