import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => {
  return {
    form_container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(330px, 1fr))',
      // gridAutoRows: '376px',
      gridGap: '24px',
    },
  };
});

export { useStyles };
