import React from 'react';
import PropTypes from 'prop-types';

const Dashboard = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.54409 1H8.59653C9.99933 1 11.1406 2.14129 11.1406 3.54409V8.59653C11.1406 9.99933 9.99933 11.1406 8.59653 11.1406H3.54409C2.14129 11.1406 1 9.99933 1 8.59653V3.54409C1 2.14129 2.14129 1 3.54409 1ZM8.59653 9.42188C9.05161 9.42188 9.42188 9.05161 9.42188 8.59653V3.54409C9.42188 3.08901 9.05161 2.71875 8.59653 2.71875H3.54409C3.08901 2.71875 2.71875 3.08901 2.71875 3.54409V8.59653C2.71875 9.05161 3.08901 9.42188 3.54409 9.42188H8.59653ZM15.4375 1H20.4219C21.8435 1 23 2.15655 23 3.57812V8.5625C23 9.98408 21.8435 11.1406 20.4219 11.1406H15.4375C14.0159 11.1406 12.8594 9.98408 12.8594 8.5625V3.57812C12.8594 2.15655 14.0159 1 15.4375 1ZM20.4219 9.42188C20.8957 9.42188 21.2812 9.03636 21.2812 8.5625V3.57812C21.2812 3.10427 20.8957 2.71875 20.4219 2.71875H15.4375C14.9636 2.71875 14.5781 3.10427 14.5781 3.57812V8.5625C14.5781 9.03636 14.9636 9.42188 15.4375 9.42188H20.4219ZM8.59653 12.8594H3.54409C2.14129 12.8594 1 14.0007 1 15.4035V20.4559C1 21.8587 2.14129 23 3.54409 23H8.59653C9.99933 23 11.1406 21.8587 11.1406 20.4559V15.4035C11.1406 14.0007 9.99933 12.8594 8.59653 12.8594ZM9.42188 20.4559C9.42188 20.911 9.05161 21.2812 8.59653 21.2812H3.54409C3.08901 21.2812 2.71875 20.911 2.71875 20.4559V15.4035C2.71875 14.9484 3.08901 14.5781 3.54409 14.5781H8.59653C9.05161 14.5781 9.42188 14.9484 9.42188 15.4035V20.4559ZM15.4375 12.8594H20.4219C21.8435 12.8594 23 14.0159 23 15.4375V20.4219C23 21.8435 21.8435 23 20.4219 23H15.4375C14.0159 23 12.8594 21.8435 12.8594 20.4219V15.4375C12.8594 14.0159 14.0159 12.8594 15.4375 12.8594ZM20.4219 21.2812C20.8957 21.2812 21.2812 20.8957 21.2812 20.4219V15.4375C21.2812 14.9636 20.8957 14.5781 20.4219 14.5781H15.4375C14.9636 14.5781 14.5781 14.9636 14.5781 15.4375V20.4219C14.5781 20.8957 14.9636 21.2812 15.4375 21.2812H20.4219Z"
        fill="#FEFEFE"
      />
    </svg>
  );
};

Dashboard.propTypes = {
  className: PropTypes.string,
};

export default Dashboard;
