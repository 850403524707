import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './Layout.style';
import TopBar from '../../components/TopBar/TopBar';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

const Layout = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const mainContainerRef = useRef(null);

  useEffect(() => {
    if (mainContainerRef && mainContainerRef.current) {
      mainContainerRef.current.scrollTop = 0;
    }
  }, [location.pathname]);

  return (
    <div className={classes.layout_container}>
      <TopBar />
      <div
        ref={mainContainerRef}
        className={clsx(classes.main_container, 'main_container')}
        id="main_container"
      >
        {children}
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
