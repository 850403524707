import React from 'react';
import PropTypes from 'prop-types';
import Media from '../../common/Media/Media';
import CaseProfileMobile from './CaseProfileMobile';
import CaseProfileDesktop from './CaseProfileDesktop';

const CaseProfile = ({
  familyFileId,
  labels,
  activeTab,
  size,
  showCommunitiesTab,
}) => {
  return (
    <Media
      xs={
        <CaseProfileMobile
          activeTab={activeTab}
          labels={labels}
          familyFileId={familyFileId}
          size={size}
          showCommunitiesTab={showCommunitiesTab}
        />
      }
      md={
        <CaseProfileDesktop
          activeTab={activeTab}
          labels={labels}
          familyFileId={familyFileId}
          showCommunitiesTab={showCommunitiesTab}
        />
      }
    />
  );
};

CaseProfile.propTypes = {
  familyFileId: PropTypes.number,
  activeTab: PropTypes.number,
  labels: PropTypes.object,
  size: PropTypes.string,
  showCommunitiesTab: PropTypes.bool,
};

export default CaseProfile;
