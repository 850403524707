import React from 'react';
import PropTypes from 'prop-types';

const Route = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="currentColor"
        d="M6 22C7.30622 22 8.41746 21.1652 8.82929 20H17.5C19.9853 20 22 17.9853 22 15.5C22 13.0147 19.9853 11 17.5 11H6.5C5.11929 11 4 9.88073 4 8.50001C4 7.1193 5.11929 6.00001 6.5 6.00001H9.58579L8.29289 7.29291L9.70711 8.70712L13.4142 5.00001L9.70711 1.29291L8.29289 2.70712L9.58579 4.00001H6.5C4.01472 4.00001 2 6.01473 2 8.50001C2 10.9853 4.01472 13 6.5 13H17.5C18.8807 13 20 14.1193 20 15.5C20 16.8807 18.8807 18 17.5 18H8.82929C8.41746 16.8348 7.30622 16 6 16C4.34315 16 3 17.3432 3 19C3 20.6569 4.34315 22 6 22ZM5 19C5 18.4477 5.44772 18 6 18C6.55228 18 7 18.4477 7 19C7 19.5523 6.55228 20 6 20C5.44772 20 5 19.5523 5 19ZM18 2.00001C19.6569 2.00001 21 3.34316 21 5.00001C21 6.65687 19.6569 8.00001 18 8.00001C16.3431 8.00001 15 6.65687 15 5.00001C15 3.34316 16.3431 2.00001 18 2.00001ZM17 5.00001C17 4.44773 17.4477 4.00001 18 4.00001C18.5523 4.00001 19 4.44773 19 5.00001C19 5.5523 18.5523 6.00001 18 6.00001C17.4477 6.00001 17 5.5523 17 5.00001Z"
      />
    </svg>
  );
};

Route.propTypes = {
  className: PropTypes.string,
};

export default Route;
