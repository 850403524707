import React from 'react';
import PropTypes from 'prop-types';

const SkilledServices = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M25 8.68H17.32V1H8.68V8.68H1V17.32H8.68V25H17.32V17.32H25V8.68Z" 
        stroke="currentColor" stroke-miterlimit="10" 
        stroke-linecap="round" 
        stroke-linejoin="round"
      />
   </svg>
  );
};

SkilledServices.propTypes = {
  className: PropTypes.string,
};

export default SkilledServices;
