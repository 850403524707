import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles(() => ({
  self_tag: {
    height: 'fit-content',
    '& svg': {
      fontSize: '16px',
    },
  },
}));

export { useStyles };
