import React from 'react';
import PropTypes from 'prop-types';

const ServerError = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M68.75 96.875H31.25H18.75V92.3437C18.75 89.2031 19.8125 86.1719 21.7812 83.7188C23.8594 81.125 26.7969 79.3594 30.0781 78.7656L39.4375 77.0625L42.1875 89.0625L50 84.375L57.8125 89.0625L60.5625 77.0781L69.9063 78.7656C73.1563 79.3594 76.0937 81.1094 78.1719 83.6875L78.1875 83.7031C80.1719 86.1562 81.25 89.2187 81.25 92.375V96.875H68.75Z"
        fill="#A15EB5"
      />
      <path
        d="M57.8125 76.5625L60.5625 77.0781L57.8125 89.0625L50 84.375L57.8125 76.5625Z"
        fill="#C592C7"
      />
      <path
        d="M50 84.375L42.1875 89.0625L39.4375 77.0625L42.1875 76.5625L50 84.375Z"
        fill="#C592C7"
      />
      <path
        d="M57.8125 76.5625L50 84.375L42.1875 76.5625L43.75 71.875H56.2031L57.7656 76.5625H57.8125Z"
        fill="#F3E0D4"
      />
      <path
        d="M3.125 21.875H96.875V68.75C96.875 70.4688 95.4688 71.875 93.75 71.875H56.2031H43.75H6.25C4.53125 71.875 3.125 70.4688 3.125 68.75V21.875Z"
        fill="#FEFEFE"
      />
      <path
        d="M96.875 6.25V21.875H3.125V6.25C3.125 4.53125 4.53125 3.125 6.25 3.125H93.75C95.4688 3.125 96.875 4.53125 96.875 6.25Z"
        fill="#82B7A8"
      />
      <path
        d="M26.5625 15.625H10.9375C10.075 15.625 9.375 14.925 9.375 14.0625V10.9375C9.375 10.075 10.075 9.375 10.9375 9.375H26.5625C27.425 9.375 28.125 10.075 28.125 10.9375V14.0625C28.125 14.925 27.425 15.625 26.5625 15.625Z"
        fill="#FEFEFE"
      />
      <path
        d="M56.25 53.125H43.75V56.25H42.1875C39.5938 56.25 37.5 54.1562 37.5 51.5625C37.5 50.2656 38.0312 49.0937 38.875 48.25C39.7187 47.4062 40.8906 46.875 42.1875 46.875H57.8125C60.4062 46.875 62.5 48.9688 62.5 51.5625C62.5 52.8594 61.9687 54.0312 61.125 54.875C60.2812 55.7187 59.1094 56.25 57.8125 56.25H56.25V53.125Z"
        fill="#D6621B"
      />
      <path
        d="M50 65.625C48.2812 65.625 46.7188 64.9219 45.5781 63.7969C44.4531 62.6563 43.75 61.0938 43.75 59.375V56.25V53.125H56.25V56.25V59.375C56.25 62.8281 53.4531 65.625 50 65.625Z"
        fill="#E1737D"
      />
      <path d="M59.375 9.375H90.625V15.625H59.375V9.375Z" fill="#E9CE73" />
      <path
        d="M93.75 1.5625H6.25C3.66562 1.5625 1.5625 3.66562 1.5625 6.25V68.75C1.5625 71.3344 3.66562 73.4375 6.25 73.4375H41.5828L40.9984 75.1906L29.8031 77.2266C26.1547 77.8906 22.8687 79.85 20.5547 82.7453C18.3844 85.4578 17.1875 88.8656 17.1875 92.3406V98.4375H82.8125V92.3703C82.8125 88.875 81.6047 85.45 79.3906 82.7047C77.0797 79.8359 73.8078 77.8906 70.1797 77.2313L58.9531 75.1906L58.3687 73.4375H93.75C96.3344 73.4375 98.4375 71.3344 98.4375 68.75V6.25C98.4375 3.66562 96.3344 1.5625 93.75 1.5625ZM6.25 4.6875H93.75C94.6125 4.6875 95.3125 5.38906 95.3125 6.25V20.3125H4.6875V6.25C4.6875 5.38906 5.3875 4.6875 6.25 4.6875ZM43.2297 86.6156L41.325 78.3078L41.6609 78.2469L47.4813 84.0656L43.2297 86.6156ZM76.9781 84.6891C78.725 86.8578 79.6875 89.5844 79.6875 92.3703V95.3125H70.3125V85.9375H67.1875V95.3125H32.8125V85.9375H29.6875V95.3125H20.3125V92.3406C20.3125 89.5734 21.2641 86.8578 22.9953 84.6953C24.8391 82.3891 27.4547 80.8281 30.3609 80.3L38.2484 78.8656L41.1453 91.5094L50 86.1969L58.8547 91.5094L61.75 78.875L69.6219 80.3063C72.5094 80.8313 75.1141 82.3797 76.9781 84.6891ZM58.3313 78.2531L58.6734 78.3156L56.7719 86.6141L52.5203 84.0641L58.3313 78.2531ZM55.9875 76.1781L50 82.1656L43.975 76.1406L44.8766 73.4375H55.0766L55.9875 76.1781ZM93.75 70.3125H6.25C5.3875 70.3125 4.6875 69.6109 4.6875 68.75V23.4375H95.3125V68.75C95.3125 69.6109 94.6125 70.3125 93.75 70.3125Z"
        fill="#0D1321"
      />
      <path
        d="M10.9375 17.1875H26.5625C28.2859 17.1875 29.6875 15.7859 29.6875 14.0625V10.9375C29.6875 9.21406 28.2859 7.8125 26.5625 7.8125H10.9375C9.21406 7.8125 7.8125 9.21406 7.8125 10.9375V14.0625C7.8125 15.7859 9.21406 17.1875 10.9375 17.1875ZM17.1875 14.0625V10.9375H20.3125V14.0625H17.1875ZM26.5641 14.0625H23.4375V10.9375H26.5625L26.5641 14.0625ZM10.9375 10.9375H14.0625V14.0625H10.9375V10.9375Z"
        fill="#0D1321"
      />
      <path
        d="M92.1875 7.8125H57.8125V17.1875H92.1875V7.8125ZM89.0625 14.0625H60.9375V10.9375H89.0625V14.0625Z"
        fill="#0D1321"
      />
      <path
        d="M57.8125 45.3125H42.1875C38.7406 45.3125 35.9375 48.1156 35.9375 51.5625C35.9375 55.0094 38.7406 57.8125 42.1875 57.8125V59.375C42.1875 63.6828 45.6922 67.1875 50 67.1875C54.3078 67.1875 57.8125 63.6828 57.8125 59.375V57.8125C61.2594 57.8125 64.0625 55.0094 64.0625 51.5625C64.0625 48.1156 61.2594 45.3125 57.8125 45.3125ZM54.6875 59.375C54.6875 61.4094 53.3766 63.1281 51.5625 63.775V60.9375H48.4375V63.775C46.6234 63.1281 45.3125 61.4094 45.3125 59.375V54.6875H54.6875V59.375ZM57.8125 54.6875V51.5625H42.1875V54.6875C40.4641 54.6875 39.0625 53.2859 39.0625 51.5625C39.0625 49.8391 40.4641 48.4375 42.1875 48.4375H57.8125C59.5359 48.4375 60.9375 49.8391 60.9375 51.5625C60.9375 53.2859 59.5359 54.6875 57.8125 54.6875Z"
        fill="#0D1321"
      />
      <path
        d="M41.083 40.1671L43.2924 37.9578L38.1471 32.8125L43.2924 27.6671L41.083 25.4578L35.9377 30.6031L30.7924 25.4578L28.583 27.6671L33.7283 32.8125L28.583 37.9578L30.7924 40.1671L35.9377 35.0218L41.083 40.1671Z"
        fill="#0D1321"
      />
      <path
        d="M69.208 25.4578L64.0627 30.6031L58.9174 25.4578L56.708 27.6671L61.8533 32.8125L56.708 37.9578L58.9174 40.1671L64.0627 35.0218L69.208 40.1671L71.4174 37.9578L66.2721 32.8125L71.4174 27.6671L69.208 25.4578Z"
        fill="#0D1321"
      />
    </svg>
  );
};

ServerError.propTypes = {
  className: PropTypes.string,
};

export default ServerError;
