import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './UserMenu.style';
import Typography from '../../common/Typography/Typography';
import ChevronTop from '../../common/Icons/arrow/ChevronTop';
import ChevronBottom from '../../common/Icons/arrow/ChevronBottom';
import { withLabels } from '../../context/LabelContext';
import { withAuthenticationContext } from '../../context/Authentication';
import { cloneDeep } from 'lodash';
import clsx from 'clsx';
import SmartTooltip from '../../common/SmartTooltip/SmartTooltip';
import Version from '../../components/Version';
import allCallStates from '../../config/callStates.config';

const TALKING = allCallStates.TALKING.key;
const WRAP_UP = allCallStates.WRAP_UP.key;
const RINGING_ON_OTHER_SIDE = allCallStates.RINGING_ON_OTHER_SIDE.key;

const UserMenu = ({ skills, selectedSkills, auth, labels, callState }) => {
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const getDisplayName = () => {
    const familyName = auth.user.family_name || '';
    const name = auth.user.given_name || auth.user.name;
    return `${name} ${familyName.substring(0, 1).toUpperCase()}.`;
  };

  let firstSkill, otherSkills;
  if (selectedSkills?.length > 0) {
    const otherSelectedSkillsIds = cloneDeep(selectedSkills);
    otherSelectedSkillsIds.shift();

    if (skills) {
      otherSkills = otherSelectedSkillsIds.map((selectedSkillId) =>
        skills.find((skill) => skill.id.toString() === selectedSkillId),
      );

      const firstSkillId = selectedSkills[0];
      firstSkill = skills.find((s) => s.id.toString() === firstSkillId);
    }
  }

  const getSkillLabel = () => {
    if (selectedSkills.length === 0) return null;
    return (
      <Typography level="tiny" color="platinum5">
        {firstSkill?.name}
        <strong
          className={clsx({
            [classes.hellip]: true,
            visible: selectedSkills?.length > 1 && !isOpen,
          })}
        >
          &hellip;
        </strong>
      </Typography>
    );
  };

  const getSkillList = () => {
    if (selectedSkills.length <= 1) return null;
    return (
      <Fragment>
        <Typography color="platinum5" level="small" bold>
          Other skills
        </Typography>
        {otherSkills?.map((skill) => (
          <Typography
            key={skill.id}
            color="platinum5"
            level="tiny"
            className={classes.skill}
          >
            {skill.name}
          </Typography>
        ))}
      </Fragment>
    );
  };
  const isDisabledLogout =
    callState === TALKING ||
    callState === WRAP_UP ||
    callState === RINGING_ON_OTHER_SIDE;

  const getLogoutDropdownOption = () => {
    if (isDisabledLogout) {
      return (
        <div
          data-testid="logout-button"
          className={classes.user_menu_item}
          onClick={() => {}}
        >
          <SmartTooltip
            title={labels.DISABLED_BY_CALL_ACTIVE}
            placement="bottom"
          >
            <Typography color="disabled">{labels.LOGOUT}</Typography>
          </SmartTooltip>
        </div>
      );
    } else {
      return (
        <>
          <div
            data-testid="logout-button"
            className={classes.user_menu_item}
            onClick={() => auth.logout()}
          >
            <Typography color="platinum5">{labels.LOGOUT}</Typography>
          </div>
        </>
      );
    }
  };

  return (
    <div
      className={classes.user}
      data-testid="user-menu"
      onClick={() => setIsOpen(!isOpen)}
      ref={wrapperRef}
    >
      <img src={auth.user.picture} alt="user" />
      <div className={classes.user_name_skill}>
        <Typography bold color="platinum5">
          {getDisplayName()}
        </Typography>
        {getSkillLabel()}
      </div>
      {isOpen ? <ChevronTop /> : <ChevronBottom />}
      {isOpen && (
        <div className={classes.user_menu}>
          <div className={classes.skill_list}>{getSkillList()}</div>
          {getLogoutDropdownOption()}
          <Version class={clsx(classes.user_menu_item, classes.version_info)} />
        </div>
      )}
    </div>
  );
};

UserMenu.propTypes = {
  labels: PropTypes.object.isRequired,
  skills: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  ),
  selectedSkills: PropTypes.arrayOf(PropTypes.string),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      given_name: PropTypes.string,
      family_name: PropTypes.string,
      picture: PropTypes.string.isRequired,
      name: PropTypes.string,
    }).isRequired,
    logout: PropTypes.func.isRequired,
  }).isRequired,
  callState: PropTypes.string,
};
UserMenu.defaultProps = {
  callState: '',
};

export default withAuthenticationContext(withLabels(UserMenu));
export { UserMenu };
