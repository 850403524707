import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import debounce from 'lodash.debounce';
import { useStyles } from './SendReferral.style';
import CaseProfileSidePanel from '../../components/CaseProfileSidePanel/CaseProfileSidePanel';
import Button from '../../common/Button/Button';
import User from '../../common/Icons/basic/User';
import Typography from '../../common/Typography/Typography';
import CustomLink from '../../common/Link/Link';
import useGraphQLQuery from '../../hooks/useGraphQLQuery';
import sendReferralQuery from './sendReferralQuery';
import Loading from '../../components/Loading/Loading';
import ArrowLeft from '../../common/Icons/arrow/ArrowLeft';
import TextInput from '../../common/TextInput/TextInput';
import Divider from '../../common/Divider/Divider';
import Toast from '../../common/Toast/Toast';
import Send from '../../common/Icons/basic/Send';
import validateFamilyFileService from './validateFamilyFileService';
import useGraphQLMutation from '../../hooks/useGraphQLMutation';
import sendReferralMutation from './sendReferralMutation';
import upsertReferralNotesMutation from './upsertReferralNotesMutation';
import Alert from '../../common/Alert/Alert';
import Refer from '../../common/Graphics/communities/Refer';
import CommunityForReferralV2 from './CommunityForReferralV2';
import {
  getDateTime,
  getHourAndMinutes12HourFormat,
  getTimeOffsetFromSelectedDate,
} from '../../utils/dateFormat';
import NotesSidePanel from '../../components/NotesSidePanel/NotesSidePanel';
import Sidebar from './Sidebar/Sidebar';
import FamilyFileSidePanel from '../../components/FamilyFileSidePanel/FamilyFileSidePanel';
import DocumentHead from '../../common/DocumentHead/DocumentHead';
import tourScheduleService, {
  TOUR_STATUS,
  TOUR_STATUS_CODE,
} from '../../services/tourScheduleService';
import {
  logReferralAlertErrorViewed,
  logReferralAlertManagerViewed,
  logReferralAlertSendAttempted,
  logReferralAlertSendSucceeded,
  logReferralAlertSettingsCleared,
  logReferralAlertSettingsSaved,
  logReferralAlertSuccessButtonClicked,
  logReferralAlertValidationViewed,
} from '../../services/referredCommunitiesService';
import {
  logTourScheduled,
  logTourRescheduledV2,
  logTourCancelledV2,
} from 'services/tourScheduleService';
import createTourMutation from '../../components/Community/TourScheduler/Mutations/createTourMutation';
import updateTourMutation from '../../components/Community/TourScheduler/Mutations/updateTourMutation';
import deleteTourDraftMutation from '../../components/Community/TourScheduler/Mutations/deleteTourDraftMutation';
import { ApolloError } from 'apollo-client';

const SELF_LEAD_AUDIENCES = [
  'self_not_urgent_veteran_financially_unqualified',
  'self_urgent_financially_unqualified',
  'self_not_urgent_financially_qualified',
];

const TOUR_SCHEDULE = 'Schedule';
const TOUR_CANCEL = 'Cancel';
const TOUR_RESCHEDULE = 'Reschedule';

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

const SendReferralLGV2 = ({ labels, currentUser }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [isNotesPanelOpen, setIsNotesPanelOpen] = useState(true);
  const [isFamilyFileSidePanelOpen, setIsFamilyFileSidePanelOpen] = useState(
    false,
  );
  const [communities, setCommunities] = useState([]);
  const [generalText, setGeneralText] = useState('');
  const [generalNoteId, setGeneralNoteId] = useState(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isMessageAlertOpen, setIsMessageAlertOpen] = useState(false);
  const [tourDetails, setTourDetails] = useState([]);
  const [tourDrafts, setTourDrafts] = useState([]);
  const [familyFileId, setFamilyFileId] = useState();
  const [shouldSaveDraft, setShouldSaveDraft] = useState(false);
  const [
    showScheduledTourInPastToast,
    setShowScheduledTourInPastToast,
  ] = useState(false);
  let familyFileUserId = useRef();
  let [isSendNowButtonEnabled, setIsSendNowButtonEnabled] = useState(true);
  let isInitialSearch = useRef(true);

  const [showClosedMessage, setShowClosedMessage] = useState(false);
  const [showRejectedMessage, setShowRejectedMessage] = useState(false);

  const getFamilyFileIdFromUrl = () => {
    const pathname = location.pathname;
    const paths = pathname.split('/');
    return Number(paths[paths.length - 1]);
  };

  const getSelectedCommunityIds = () => {
    const params = new URLSearchParams(location.search);
    return params.get('communities').split(',');
  };

  const familyFileIdFromUrl = getFamilyFileIdFromUrl();

  //#region Hooks & Supporting Functions

  const onGeneralMessageChangeDebounced = useCallback(
    debounce((message) => setGeneralText(message), 500),
    [],
  );

  const { loading, error, data } = useGraphQLQuery(sendReferralQuery, {
    variables: { familyFileId: familyFileIdFromUrl },
  });

  const [sendReferral] = useGraphQLMutation(sendReferralMutation);
  const [upsertReferralNotes] = useGraphQLMutation(upsertReferralNotesMutation);
  const [createTourQL] = useGraphQLMutation(createTourMutation);
  const [updateTourQL] = useGraphQLMutation(updateTourMutation);
  const [deleteTourDraft] = useGraphQLMutation(deleteTourDraftMutation);

  const getReferralNote = (communityId, referralNotes) => {
    let referralNote = {
      id: null,
      text: '',
    };

    if (referralNotes.length > 0) {
      const foundNote = referralNotes.find(
        (note) => note.communityId === communityId,
      );

      if (foundNote) {
        referralNote.id = foundNote.referralNoteId;
        referralNote.text = foundNote.note;
      }
    }
    return referralNote;
  };

  const getReferralStatus = (communityId, referralStatuses) => {
    const foundstatus = referralStatuses.find(
      (status) => status.communityId === communityId,
    );
    return foundstatus;
  };

  let invalidProperties;

  useEffect(() => {
    if (data?.findFamilyFileById?.referralNotes?.summaryNote) {
      const { summaryNote } = data.findFamilyFileById.referralNotes;
      setGeneralNoteId(summaryNote.referralNoteId);
      setGeneralText(summaryNote.note);
    }

    let referralNotes = [];
    if (data?.findFamilyFileById?.referralNotes?.propertyNotes) {
      referralNotes = data.findFamilyFileById.referralNotes.propertyNotes;
    }

    let referralStatus = [];
    if (data?.findFamilyFileById?.referralStatus) {
      referralStatus = data.findFamilyFileById.referralStatus;
    }

    let filteredCommunities = [];
    let filteredCommunityIds = [];
    if (data?.findFamilyFileById?.communities) {
      familyFileUserId.current = data.findFamilyFileById.userId;

      const filteredNotClosedCommunities = data.findFamilyFileById.communities.filter(
        (community) => {
          const closedCommunityIds = referralStatus
            .filter((status) => status.communityStatus === labels.CLOSED)
            .map((status) => status.communityId);
          if (
            getSelectedCommunityIds().indexOf(community.id) >= 0 &&
            closedCommunityIds.includes(Number(community.id))
          ) {
            setShowClosedMessage(true);
          }
          const closedAndRejectedCommunityIds = referralStatus
            .filter(
              (status) =>
                status.communityStatus === labels.CLOSED && status.isRejected,
            )
            .map((status) => status.communityId);
          if (
            getSelectedCommunityIds().indexOf(community.id) >= 0 &&
            closedAndRejectedCommunityIds.includes(Number(community.id))
          ) {
            setShowClosedMessage(true);
            setShowRejectedMessage(true);
          }

          return (
            getSelectedCommunityIds().indexOf(community.id) >= 0 &&
            !closedCommunityIds.includes(Number(community.id))
          );
        },
      );

      filteredCommunities = filteredNotClosedCommunities
        .filter((community) => {
          const rejectedCommunityIds = referralStatus
            .filter((status) => status.isRejected === true)
            .map((status) => status.communityId);
          if (
            getSelectedCommunityIds().indexOf(community.id) >= 0 &&
            rejectedCommunityIds.includes(Number(community.id))
          ) {
            setShowRejectedMessage(true);
          }
          return (
            getSelectedCommunityIds().indexOf(community.id) >= 0 &&
            !rejectedCommunityIds.includes(Number(community.id))
          );
        })
        .map((community) => ({
          ...community,
          id: Number(community.id),
          ...{
            referralNote: getReferralNote(Number(community.id), referralNotes),
            referralStatus: getReferralStatus(
              Number(community.id),
              referralStatus,
            ),
          },
        }));
      setCommunities(filteredCommunities);

      filteredCommunityIds = filteredCommunities.map((community) => {
        return community.id;
      });

      if (isInitialSearch.current) {
        isInitialSearch.current = false;
        const communityIds = filteredCommunities.map((community) =>
          Number(community.id),
        );
        logReferralAlertManagerViewed(
          communityIds,
          data.findFamilyFileById.familyFileId,
          familyFileUserId.current,
        );
      }

      if (invalidProperties?.length > 0) {
        const errorMessages = invalidProperties.map(
          (property) => property.label,
        );
        createValidationErrorAnalyticsEvents(
          errorMessages,
          data?.findFamilyFileById?.familyFileId,
        );
      }
    }

    if (data?.findFamilyFileById?.tourDetailsExtended) {
      const filteredTourDetails = data.findFamilyFileById.tourDetailsExtended.filter(
        (tourDetail) => {
          return filteredCommunityIds.includes(tourDetail.communityId);
        },
      );
      setTourDetails(filteredTourDetails);
    }

    if (data?.findFamilyFileById?.tourDrafts) {
      const filteredTourDrafts = data.findFamilyFileById.tourDrafts.filter(
        (tourDraft) => {
          return filteredCommunityIds.includes(tourDraft.communityId);
        },
      );
      setTourDrafts(filteredTourDrafts);
    }

    setFamilyFileId(data?.findFamilyFileById?.familyFileId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //#endregion Hooks

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  const validations = validateFamilyFileService.validate(data, labels);
  invalidProperties = validations.filter((prop) => !prop.valid);

  const oneId = data.findFamilyFileById.oneId;

  const isSelfLead = SELF_LEAD_AUDIENCES.some((selfAudience) =>
    data.findFamilyFileById.audienceKey?.includes(selfAudience),
  );

  //#region Functions

  const checkForToursScheduledInPast = () => {
    const today = new Date(Date.now()).setHours(0, 0, 0, 0);
    const isScheduledTourInPast = communities.some((community) => {
      if (!community.referralStatus.includeTourInformation) {
        return false;
      }

      const tourDraft = tourDrafts.find(
        (draft) => draft.communityId === community.id,
      );
      if (tourDraft) {
        return tourDraft.activityDate < today;
      }

      const tourDetail = tourDetails.find(
        (tour) => tour.communityId === community.id,
      );
      if (tourDetail) {
        return (
          tourDetail.scheduledTourDate < today &&
          !community.isForCancel &&
          tourDetail.currentStatus !== TOUR_STATUS.COMPLETED &&
          tourDetail.currentStatus !== TOUR_STATUS.CANCELED
        );
      }

      return false;
    });

    return isScheduledTourInPast;
  };

  const isTourDraftForCancel = (tourDraft) => {
    return tourDraft.activityDate === 0;
  };

  const createTour = async (tourDraft) => {
    // Add timezone offset because it's removed in the service.
    const timeOffset = getTimeOffsetFromSelectedDate(tourDraft.activityDate);
    const variables = {
      familyFileId,
      communityId: tourDraft.communityId,
      activityDate: tourDraft.activityDate + timeOffset * 60000,
      timeOffset,
      status: TOUR_SCHEDULE,
      tourType: tourDraft.tourType,
    };

    try {
      const response = await createTourQL({ variables });
      const tour = response.data.createTour;
      return tour;
    } catch (exception) {
      console.error(exception);
      throw exception;
    }
  };

  const updateTour = async (tourDetail, tourDraft) => {
    let variables;
    if (isTourDraftForCancel(tourDraft)) {
      variables = {
        familyCommunityTourId: tourDetail.familyCommunityTourId,
        status: TOUR_CANCEL,
        userName: currentUser.username,
        userRole: currentUser.role,
      };
    } else {
      // Add timezone offset because it's removed in the service.
      const timeOffset = getTimeOffsetFromSelectedDate(tourDraft.activityDate);
      let status;
      if (tourDetail.currentStatus === TOUR_STATUS.SCHEDULED) {
        status = TOUR_RESCHEDULE;
      } else if (tourDetail.currentStatus === TOUR_STATUS.RESCHEDULED) {
        status = TOUR_RESCHEDULE;
      } else {
        status = TOUR_SCHEDULE;
      }
      variables = {
        familyCommunityTourId: tourDetail.familyCommunityTourId,
        activityDate: tourDraft.activityDate + timeOffset * 60000,
        timeOffset,
        status,
        tourType: tourDraft.tourType,
        userName: currentUser.username,
        userRole: currentUser.role,
      };
    }

    try {
      const response = await updateTourQL({ variables });
      const tour = response.data.createTour;
      return tour;
    } catch (exception) {
      console.error(exception);
      throw exception;
    }
  };

  const deleteTourDraftRecord = async (familyCommunityTourDraftId) => {
    const variables = {
      familyCommunityTourDraftId,
    };

    try {
      await deleteTourDraft({ variables });
      return;
    } catch (exception) {
      console.error(exception);
      throw exception;
    }
  };

  const tourDraftIsCancellation = (tourDraft) => {
    return tourDraft?.activityDate === 0;
  };

  /**
   * Convert drafts to tours if marked as to be included in referral alert or
   * draft is a cancellation.
   */
  const convertTourDraftsToTours = async () => {
    await asyncForEach(tourDrafts, async (tourDraft) => {
      const community = communities.find((community) => {
        return community.id === tourDraft.communityId;
      });
      let promises = [];

      if (
        community.referralStatus.includeTourInformation ||
        tourDraftIsCancellation(tourDraft)
      ) {
        const existingTourDetail = tourDetails.find((tourDetail) => {
          return tourDetail.communityId === tourDraft.communityId;
        });

        if (existingTourDetail) {
          promises.push(updateTour(existingTourDetail, tourDraft));
        } else {
          promises.push(createTour(tourDraft));
        }
        promises.push(
          deleteTourDraftRecord(tourDraft.familyCommunityTourDraftId),
        );
      }
      await Promise.all(promises);
    });
  };

  const getDataForReferrals = (communityId) => {
    const tourDraft = tourDrafts.find(
      (tour) => tour.communityId === communityId,
    );
    if (tourDraft) {
      return {
        tourType: tourDraft.tourType,
        tourDateTime: tourDraft.activityDate,
        tourStatus: TOUR_STATUS_CODE.SCHEDULED,
      };
    }

    const tourDetail = tourDetails.find(
      (tour) => tour.communityId === communityId,
    );

    return {
      tourType: tourDetail?.tourType,
      tourDateTime: tourDetail?.scheduledTourDate,
      tourStatus: tourDetail?.currentStatus,
    };
  };

  /**
   * Build referrals for all communities.
   */
  const buildReferrals = () => {
    const referrals = communities.map((community) => {
      const { tourType, tourDateTime, tourStatus } = getDataForReferrals(
        community.id,
      );

      const referral = {
        communityId: community.id,
        communityName: community.name,
        notes: community.referralNote.text,
        desiredPostalCode:
          data.findFamilyFileById.familyDesiredLocations[0].zip,
      };

      if (tourType && !community.isForCancel) {
        referral.tourType = tourType;
        referral.tourDateTime = getDateTime(tourDateTime);
      }

      if (tourStatus?.currentStatus === TOUR_STATUS.COMPLETED) {
        const lastScheduledTour = getLastScheduledTour(community.id);
        if (lastScheduledTour) {
          referral.tourType = lastScheduledTour.tourType
            ? lastScheduledTour.tourType.trim()
            : '';
          referral.tourDateTime = getDateTime(lastScheduledTour.activityDate);
        } else {
          referral.isTourScheduled = false;
          referral.tourDateTime = null;
          referral.tourType = null;
        }
      } else if (tourStatus?.currentStatus === TOUR_STATUS.CANCELED) {
        referral.isTourScheduled = false;
        referral.tourDateTime = null;
        referral.tourType = null;
      }

      referral.doNotSendTourInformation =
        !community.referralStatus.includeTourInformation ?? true;

      if (community.isForCancel) {
        referral.isTourScheduled = false;
      }

      return referral;
    });

    return referrals;
  };

  const sendReferrals = async () => {
    const referrals = buildReferrals();

    const sendReferralBody = {
      familyFileId,
      userName: currentUser.username,
      userRole: currentUser.role,
      generalNote: generalText,
      referrals,
      oneId,
    };
    createSendAttemptedAnalyticsEvents();

    try {
      const { data: response } = await sendReferral({
        variables: { input: sendReferralBody },
      });
      if (response.sendCommunityReferral?.result === 'emails sent') {
        setIsAlertOpen(true);
        createSuccessAlertAnalyticsEvents();
        createTourAnalyticsEvents();
      } else {
        console.error(
          `sendReferral failed. response: ${response.sendCommunityReferral?.result}`,
        );
        throw new ApolloError(response.sendCommunityReferral?.result);
      }
    } catch (exception) {
      createFailureAlertAnalyticsEvents(
        exception.message ? exception.message : exception,
      );
      throw exception;
    }
  };

  const getLastScheduledTour = (communityId) => {
    const activities = tourDetails.find(
      (tour) => communityId === tour.communityId,
    ).tourActivities;

    const lastScheduledTour = activities
      .reverse()
      .find(
        (activity) =>
          activity.status === TOUR_STATUS_CODE.SCHEDULED ||
          activity.status === TOUR_STATUS_CODE.RESCHEDULED,
      );

    return lastScheduledTour;
  };

  const getTourDetail = (tourDetails, communityId) => {
    return tourDetails.find(
      (tour) => parseInt(tour.communityId) === parseInt(communityId),
    );
  };

  const getTourDraft = (tourDrafts, communityId) => {
    const tourDraft = tourDrafts.find(
      (draft) => draft.communityId === parseInt(communityId),
    );
    return tourDraft;
  };

  const saveReferralNotes = () => {
    const referralNotes = communities.map((community) => {
      return {
        communityId: community.id,
        note: community.referralNote.text,
        referralNoteId: community.referralNote.id,
      };
    });

    const referralNotesBody = {
      familyFileId,
      summaryNote: {
        referralNoteId: generalNoteId,
        note: generalText,
      },
      propertyNotes: referralNotes,
    };

    return upsertReferralNotes({
      variables: { input: referralNotesBody },
    });
  };

  const isAnyCommunityAlreadyReferred = () => {
    const referredCommunities = communities.filter((community) => {
      return (
        community.referralStatus.isReferralStarted &&
        community.referralStatus.referralDate
      );
    });
    if (referredCommunities.length > 0) {
      return true;
    }
    return false;
  };

  //#endregion Functions

  //#region Event Handlers

  const handleSendReferralsQueryRefetch = async () => {
    shouldSaveDraft && (await handleSaveSettings(false));
    window.location.reload();
  };

  const handleGeneralMessageChange = (message) => {
    onGeneralMessageChangeDebounced(message);
    setShouldSaveDraft(true);
  };

  const handleCommunityMessageChange = (
    message,
    community,
    updateCommunities = true,
  ) => {
    if (community.referralNote.text !== message) {
      setShouldSaveDraft(true);
    }
    community.referralNote.text = message;
    if (updateCommunities) {
      setCommunities([...communities]);
    }
  };

  const handleIncludeTourInformationChange = (
    includeTourInformation,
    community,
  ) => {
    community.referralStatus.includeTourInformation = includeTourInformation;
    setCommunities([...communities]);
  };

  const handleTourChange = (tourInfo) => {
    if (tourInfo.familyCommunityTourDraftId) {
      const tourDraft = getTourDraft(tourDrafts, tourInfo.communityId);
      if (tourDraft) {
        tourDraft.activityDate = tourInfo.activityDate;

        tourDraft.familyCommunityTourDraftId =
          tourInfo.familyCommunityTourDraftId;
        tourDraft.tourType = tourInfo.selectedType;
      } else {
        const newTourDraft = {
          activityDate: tourInfo.activityDate,
          communityId: tourInfo.communityId,
          familyCommunityTourDraftId: tourInfo.familyCommunityTourDraftId,
          tourType: tourInfo.selectedType,
        };
        tourDrafts.push(newTourDraft);
        setTourDrafts([...tourDrafts]);
      }
    } else if (tourInfo.familyCommunityTourId) {
      const tour = getTourDetail(tourDetails, tourInfo.communityId);
      if (tour) {
        tour.familyCommunityTourId = tourInfo.familyCommunityTourId;
        tour.tourType = tourInfo.selectedType;
        tour.scheduledTourDate = tourInfo.activityDate;
        setTourDetails([...tourDetails]);
      } else {
        const newTour = {
          communityId: tourInfo.communityId,
          currentStatus: null,
          familyCommunityTourId: tourInfo.familyCommunityTourId,
          scheduledTourDate: tourInfo.activityDate,
          tourType: tourInfo.selectedType,
        };
        tourDetails.push(newTour);
        setTourDetails([...tourDetails]);
      }
    }
  };

  const handleMessagesClear = () => {
    setGeneralText('');
    const clearCommunities = communities.map((community) => ({
      ...community,
      ...{
        referralNote: {
          ...community.referralNote,
          ...{ text: '' },
        },
      },
    }));
    setCommunities(clearCommunities);
    createSettingsClearedAnalyticsEvents();
  };

  const handleSaveSettings = async (shouldShowModal = true) => {
    const { data: response } = await saveReferralNotes();

    if (response.upsertReferralNotes) {
      createSettingsSavedAnalyticsEvents();
      shouldShowModal && setIsMessageAlertOpen(true);
    }
  };

  const handleGoToCommunitiesPageClick = () => {
    history.push(`/family-file/${familyFileId}`);
  };

  const handleSendReferrals = async () => {
    setIsSendNowButtonEnabled(false);

    const { data: response } = await saveReferralNotes();

    if (response.upsertReferralNotes) {
      const areToursInFuture = !checkForToursScheduledInPast();

      if (areToursInFuture) {
        await convertTourDraftsToTours();
        await sendReferrals();
      } else {
        setShowScheduledTourInPastToast(true);
        setIsSendNowButtonEnabled(true);
      }
    }
  };

  const handleMessageSuccessClose = () => {
    setIsMessageAlertOpen(false);
  };

  const handleReferralSuccessFamilyLetterClick = () => {
    createSuccessButtonAnalyticsEvents(labels.CONTINUE_TO_FAMILY_LETTER);

    const communityIdsString = communities
      .map((community) => Number(community.id))
      .join(',');

    history.push(
      `/send-family-file-letter/${familyFileId}?communities=${communityIdsString}`,
    );
  };

  const handleOpenFamilyFileSidePanel = () => {
    setIsNotesPanelOpen(false);
    setIsFamilyFileSidePanelOpen(true);
  };

  //#endregion Event Handlers

  //#region Analytics Events

  const getTourCountsForReferralEvent = (
    community,
    toursCount,
    toursScheduled,
  ) => {
    const tourDraft = tourDrafts.find(
      (tourDraft) => tourDraft.communityId === community.id,
    );
    if (tourDraft) {
      toursCount++;
      toursScheduled[tourDraft.tourType]++;
    } else {
      const tourDetail = tourDetails.find(
        (tour) => tour.communityId === community.id,
      );
      if (tourDetail?.scheduledTourDate && tourDetail?.tourType) {
        toursCount++;
        toursScheduled[tourDetail.tourType]++;
      }
    }
    return [toursCount, toursScheduled];
  };

  const getDetailsForReferralEvent = () => {
    let communitiesForEvent = [];
    let toursCount = 0;
    let toursScheduled = { ...tourScheduleService.tourTypesCountTemplate };

    communities.forEach((community) => {
      const isReferralUpdate =
        community.referralStatus.isReferralStarted &&
        community.referralStatus.referralDate;
      const referralType = isReferralUpdate
        ? labels.REFERRAL_UPDATE
        : labels.NEW_REFERRAL;

      communitiesForEvent.push({
        id: community.id,
        careTypes: community.careTypes,
        referralType,
      });

      [toursCount, toursScheduled] = getTourCountsForReferralEvent(
        community,
        toursCount,
        toursScheduled,
      );
    });
    return [communitiesForEvent, toursCount, toursScheduled];
  };

  const getTourDetails = (communityId) => {
    const tourDraft = tourDrafts.find(
      (tour) => tour.communityId === communityId,
    );
    const tourDetail = tourDetails.find(
      (tour) => tour.communityId === communityId,
    );

    if (tourDraft) {
      let tourStatus = TOUR_STATUS.SCHEDULED;
      let tourDateTime = tourDraft.activityDate;
      let tourType = tourDraft.tourType;

      if (isTourDraftForCancel(tourDraft)) {
        tourDateTime = tourDetail?.scheduledTourDate;
        tourStatus = TOUR_STATUS.CANCELED;
        tourType = tourDetail?.tourType;
      } else {
        if (
          tourDetail?.currentStatus === TOUR_STATUS.SCHEDULED ||
          tourDetail?.currentStatus === TOUR_STATUS.RESCHEDULED
        ) {
          tourStatus = TOUR_STATUS.RESCHEDULED;
        }
      }

      return {
        tourDateTime,
        tourStatus,
        tourType,
      };
    }

    return {
      tourDateTime: tourDetail?.scheduledTourDate,
      tourStatus: tourDetail?.currentStatus,
      tourType: tourDetail?.tourType,
    };
  };

  const getTourAnalyticsDetails = (communityId) => {
    const { tourDateTime, tourStatus, tourType } = getTourDetails(communityId);

    const eventTourType = tourScheduleService.getTourTypeLabel(tourType);
    const eventTourDateTime = `${new Date(tourDateTime)
      .toISOString()
      .substr(0, 10)} ${getHourAndMinutes12HourFormat(tourDateTime, true)}`;

    return {
      tourDateTime: eventTourDateTime,
      tourStatus,
      tourTypeText: eventTourType,
    };
  };

  const createSettingsSavedAnalyticsEvents = () => {
    const [
      communities,
      toursCount,
      toursScheduled,
    ] = getDetailsForReferralEvent();

    logReferralAlertSettingsSaved(
      communities,
      familyFileId,
      toursCount,
      toursScheduled,
      familyFileUserId.current,
    );
  };

  const createSettingsClearedAnalyticsEvents = () => {
    const [
      communities,
      toursCount,
      toursScheduled,
    ] = getDetailsForReferralEvent();

    logReferralAlertSettingsCleared(
      communities,
      familyFileId,
      toursCount,
      toursScheduled,
      familyFileUserId.current,
    );
  };

  const createSendAttemptedAnalyticsEvents = () => {
    const [
      communities,
      toursCount,
      toursScheduled,
    ] = getDetailsForReferralEvent();

    logReferralAlertSendAttempted(
      communities,
      familyFileId,
      toursCount,
      toursScheduled,
      familyFileUserId.current,
    );
  };

  const createSuccessButtonAnalyticsEvents = (buttonText) => {
    logReferralAlertSuccessButtonClicked(
      buttonText,
      familyFileId,
      familyFileUserId.current,
    );
  };

  const createValidationErrorAnalyticsEvents = (
    errorMessages,
    familyFileId,
  ) => {
    logReferralAlertValidationViewed(
      errorMessages,
      familyFileId,
      familyFileUserId.current,
    );
  };

  const createSuccessAlertAnalyticsEvents = () => {
    const [
      communities,
      toursCount,
      toursScheduled,
    ] = getDetailsForReferralEvent();

    logReferralAlertSendSucceeded(
      communities,
      familyFileId,
      toursCount,
      toursScheduled,
      familyFileUserId.current,
    );
  };

  /**
   * Send Tour analytics events for each community having tour marked to be included in referral alert or
   * draft is a cancellation.
   */
  const createTourAnalyticsEvents = () => {
    communities.forEach((community) => {
      const tourDraft = tourDrafts.find(
        (tourDraft) => tourDraft.communityId === community.id,
      );
      if (
        community.referralStatus.includeTourInformation ||
        tourDraftIsCancellation(tourDraft)
      ) {
        const {
          tourDateTime,
          tourStatus,
          tourTypeText,
        } = getTourAnalyticsDetails(community.id);

        const analyticsData = {
          communityId: community.id,
          familyFileId: familyFileId,
          isRecorded: false,
          originatingPage: 'referral alert',
          tourDateTime: tourDateTime,
          tourType: tourTypeText,
          userId: familyFileUserId.current,
        };

        if (tourStatus === TOUR_STATUS.SCHEDULED) {
          logTourScheduled(analyticsData);
        } else if (tourStatus === TOUR_STATUS.CANCELED) {
          logTourCancelledV2(analyticsData);
        } else if (tourStatus === TOUR_STATUS.RESCHEDULED) {
          logTourRescheduledV2(analyticsData);
        }
      }
    });
  };

  const createFailureAlertAnalyticsEvents = (errorMessage) => {
    const [communities] = getDetailsForReferralEvent();

    logReferralAlertErrorViewed(
      communities,
      errorMessage,
      familyFileId,
      familyFileUserId.current,
    );
  };

  //#endregion

  //#region Render Fragments

  const renderPrimaryContact = () => {
    const primaryContact = data.findFamilyFileById.contacts.find(
      (contact) => !!contact.profileData.isPrimary,
    );
    return `${primaryContact.profileData.firstName} ${primaryContact.profileData.lastName}`;
  };

  const renderResidentName = () => {
    return data.findFamilyFileById.residents.length > 0
      ? `${data.findFamilyFileById.residents[0].profileData.firstName} ${data.findFamilyFileById.residents[0].profileData.lastName}`
      : labels.UNKNOWN;
  };

  const renderValidationLink = () => {
    return (
      <CustomLink
        className={classes.link}
        onClick={handleOpenFamilyFileSidePanel}
      >
        <Typography>{labels.OPEN_FAMILY_FILE_PANEL}</Typography>
      </CustomLink>
    );
  };

  const renderDocumentTitle = () => {
    return `${renderPrimaryContact()}: ${labels.REFERRAL_ALERT} `;
  };

  const renderSuccessModalBody = () => {
    return (
      <>
        <Typography color="eerieBlack5">
          {labels.SUCCESSFULLY_SENT_REFERRAL_LIST}
        </Typography>
        <ul className={classes.community_list}>
          {communities.map((community, key) => (
            <li key={key}>{community.name}</li>
          ))}
        </ul>
        {isAnyCommunityAlreadyReferred() ? (
          <div className={classes.buttons_container}>
            <Button
              className={classes.family_letter_button}
              onClick={handleReferralSuccessFamilyLetterClick}
              type={'light'}
            >
              {labels.CONTINUE_TO_FAMILY_LETTER}
            </Button>
            {renderGoToCommunitiesPageButton()}
          </div>
        ) : (
          <div className={classes.buttons_container}>
            {renderGoToCommunitiesPageButton('light')}
            <Button
              className={classes.family_letter_button}
              onClick={handleReferralSuccessFamilyLetterClick}
            >
              {labels.CONTINUE_TO_FAMILY_LETTER}
            </Button>
          </div>
        )}
      </>
    );
  };

  const renderGoToCommunitiesPageButton = (type) => (
    <Button size="small" type={type} onClick={handleGoToCommunitiesPageClick}>
      {labels.GO_TO_COMMUNITIES_PAGE}
    </Button>
  );

  //#endregion Render Fragments

  //#region Render

  return (
    <div className={classes.send_referral_container}>
      <DocumentHead title={renderDocumentTitle()} />
      <Alert
        type="success"
        title={labels.NOTES_SAVED}
        description={labels.SUCCESSFULLY_SAVED_NOTES}
        confirmText={labels.OK}
        onConfirm={handleMessageSuccessClose}
        onClose={handleMessageSuccessClose}
        isOpen={isMessageAlertOpen}
      />
      <Alert
        isOpen={isAlertOpen}
        modalBody={renderSuccessModalBody()}
        title={labels.SUCCESS}
        showCloseButton={false}
        type="success"
      />
      <CaseProfileSidePanel
        familyFileId={familyFileId}
        labels={labels}
        isOpen={isSidePanelOpen}
        onClose={() => setIsSidePanelOpen(false)}
      />
      <div className={classes.top_bar}>
        <div className={classes.back_container}>
          <Link
            className={classes.back_link}
            onClick={() => history.goBack()}
            to={'#'}
          >
            <ArrowLeft />
            <Typography bold color="platinum5">
              {labels.BACK}
            </Typography>
          </Link>
        </div>
        <Button
          className={classes.disabled_button}
          startIcon={<User />}
          onClick={() => setIsSidePanelOpen(true)}
        >
          <Typography color="platinum5">
            {`${renderPrimaryContact()} (${labels.ID}: ${familyFileId})`}
          </Typography>
        </Button>
      </div>
      {communities.length > 0 && (
        <>
          <div className={classes.lg_main_container}>
            <div
              className={clsx(classes.lg_body, {
                [classes.lg_body_shift]:
                  isNotesPanelOpen || isFamilyFileSidePanelOpen,
              })}
            >
              <div className={classes.lg_referral_body}>
                <div className={classes.lg_referral_body_content}>
                  <Typography bold level="large">
                    {labels.REFERRAL_ALERT_MANAGER}
                  </Typography>
                  {invalidProperties.length > 0 && (
                    <Toast
                      type="error"
                      className={classes.error_toast}
                      onClose={() => {}}
                    >
                      <div>
                        <Typography>
                          {labels.YOU_ARE_MISSING_INFORMATION}
                        </Typography>
                        {invalidProperties.map((prop, key) => (
                          <Typography key={key}>{`- ${prop.label}`}</Typography>
                        ))}
                        {renderValidationLink()}
                      </div>
                    </Toast>
                  )}
                  <div className={classes.note}>
                    <Typography level="small">
                      <b>{labels.PLEASE_NOTE}: </b>
                      {labels.THOUGH_NOT_MANDATORY}
                    </Typography>
                  </div>
                  {showScheduledTourInPastToast && (
                    <Toast
                      type="error"
                      className={classes.toast}
                      onClose={() => setShowScheduledTourInPastToast(false)}
                    >
                      {labels.SCHEDULING_TOUR_IN_PAST}
                    </Toast>
                  )}
                  <Typography
                    className={classes.about_the_family}
                    bold
                    level="large"
                  >
                    {labels.ABOUT_THE_FAMILY}
                  </Typography>
                  {!isSelfLead && (
                    <Toast className={classes.toast} type="warning">
                      <div className={classes.referral_toast_message}>
                        <Typography>
                          {labels.REFERRAL_TOAST_MESSAGE}{' '}
                          <b>{renderResidentName()}</b>
                        </Typography>
                        <Typography>
                          {labels.PRIMARY_CONTACT}:{' '}
                          <b>{renderPrimaryContact()}</b>
                        </Typography>
                      </div>
                    </Toast>
                  )}
                  <TextInput
                    className={classes.general_text_input}
                    multiline
                    maxLength={2500}
                    value={generalText}
                    onChange={handleGeneralMessageChange}
                    rows={3}
                    label={
                      isSelfLead
                        ? labels.ADDITIONAL_NOTES
                        : labels.MESSAGE_TO_ALL_COMMUNITIES_SELECTED
                    }
                  />

                  {communities.map((community, key) => (
                    <div key={key}>
                      <CommunityForReferralV2
                        className={classes.community_container}
                        community={{ ...community, id: Number(community.id) }}
                        dataForAnalytics={{
                          originatingPage: 'referral alert',
                        }}
                        familyFileId={familyFileId}
                        familyFileUserId={familyFileUserId.current}
                        labels={labels}
                        onIncludeTourInformationChange={
                          handleIncludeTourInformationChange
                        }
                        onMessageChange={handleCommunityMessageChange}
                        onTourChange={handleTourChange}
                        tourDetail={getTourDetail(tourDetails, community.id)}
                        tourDraft={getTourDraft(tourDrafts, community.id)}
                      />
                      {key !== communities.length - 1 && (
                        <Divider color="disabled" className={classes.divider} />
                      )}
                    </div>
                  ))}
                </div>
                <div className={classes.lg_referral_body_footer}>
                  <div className={classes.clear} onClick={handleMessagesClear}>
                    <Typography level="small">{labels.CLEAR} </Typography>
                  </div>
                  <Button type="light" onClick={handleSaveSettings}>
                    <Typography level="small">
                      {labels.SAVE_SETTINGS}
                    </Typography>
                  </Button>
                  <Button
                    type={
                      invalidProperties.length > 0 || !isSendNowButtonEnabled
                        ? 'disabled'
                        : 'secondary'
                    }
                    startIcon={<Send />}
                    onClick={handleSendReferrals}
                  >
                    <Typography level="small" color="platinum5">
                      {labels.SEND_NOW}
                    </Typography>
                  </Button>
                </div>
              </div>
            </div>
            {!(isNotesPanelOpen || isFamilyFileSidePanelOpen) && (
              <Sidebar
                labels={labels}
                onClickShowNotes={() => {
                  setIsNotesPanelOpen(true);
                }}
                onClickShowFamilyFile={() => {
                  setIsFamilyFileSidePanelOpen(true);
                }}
                hideFamilyFileIcon={false}
              />
            )}
          </div>
          <NotesSidePanel
            familyFileId={getFamilyFileIdFromUrl()}
            labels={labels}
            isOpen={isNotesPanelOpen}
            onClose={() => setIsNotesPanelOpen(false)}
            screenName="referral alert"
          />
          <FamilyFileSidePanel
            familyFileId={getFamilyFileIdFromUrl()}
            labels={labels}
            isOpen={isFamilyFileSidePanelOpen}
            onClose={() => setIsFamilyFileSidePanelOpen(false)}
            onSave={handleSendReferralsQueryRefetch}
          />
        </>
      )}
      {communities.length === 0 && (
        <div className={classes.empty_body}>
          <div className={classes.no_referrals_container}>
            <Refer className={classes.referral_icon} />
            <Typography level={'large'} bold>
              {labels.NO_REFERRED_COMMUNITIES}
            </Typography>
            {showClosedMessage && (
              <Typography className={classes.no_referrals_text}>
                {labels.CLOSED_COMMUNITY_TOOLTIP_TEXT}
              </Typography>
            )}
            {showRejectedMessage && (
              <Typography className={classes.no_referrals_text}>
                {labels.COMMUNITY_REJECTED_REFERALL}
              </Typography>
            )}
            <div className={classes.buttons_container_referral}>
              {renderGoToCommunitiesPageButton()}
            </div>
          </div>
        </div>
      )}
    </div>
  );

  //#endregion Render
};

//#region PropTypes and Defaults

SendReferralLGV2.propTypes = {
  currentUser: PropTypes.object,
  labels: PropTypes.object,
};

SendReferralLGV2.defaults = {};

//#endregion PropTypes and Defaults

export default SendReferralLGV2;
