import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  saved_communities_container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  title: {
    display: 'flex',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      width: '24px',
      height: '24px',
      fontSize: '13px',
      backgroundColor: theme.palette.correction.smokyTopaz2,
      marginLeft: '8px',
    },
  },
  items_container: {
    display: 'flex',
    flexDirection: 'column',
  },
  communities_container: {
    height: 'auto',
    boxSizing: 'border-box',
  },
  empty_text: {
    marginBottom: '13px',
  },
  community_container: {
    display: 'flex',
    padding: '24px 0',
    borderBottom: `1px solid ${theme.palette.light.platinum1}`,
    marginRight: '15px',
    '& > div': {
      alignSelf: 'flex-start',
    },
  },
  remove_icon: {
    marginLeft: '26px',
    textAlign: 'right',
    alignSelf: 'flex-start',
    cursor: 'pointer',
    '& svg': {
      color: theme.palette.correction.smokyTopaz2,
    },
  },
  checkbox: {
    marginRight: 0,
    alignSelf: 'flex-start',
    '& span:first-child': {
      marginTop: '-5px',
    },
  },
  community_image: {
    width: '10%',
  },
  community_info: {
    width: '60%',
  },
  communities_header: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
  communities_footer: {
    height: '57px',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: '16px',
    },
  },
  header_row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginBottom: '16px',
  },
  price_range_container: {
    width: '15%',
  },
  disclosure_message: {
    width: '100%',
  },
  saving_container: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.light.white,
    opacity: '0.7',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 9999,
  },
  button_spacing: {
    marginLeft: theme.spacing(2),
  },
  button_text: {
    fontSize: '14px',
  },
}));

export { useStyles };
