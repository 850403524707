import React from 'react';
import PropTypes from 'prop-types';

const Cross = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.1849 5.83301H10.6663V3.31449C10.6663 2.49629 10.1237 1.83301 9.45422 1.83301H6.54513C5.87569 1.83301 5.33301 2.49629 5.33301 3.31449V5.83301H2.81449C1.99629 5.83301 1.33301 6.37569 1.33301 7.04513V9.95422C1.33301 10.6237 1.99629 11.1663 2.81449 11.1663H5.33301V13.6849C5.33301 14.5031 5.87569 15.1663 6.54513 15.1663H9.45422C10.1237 15.1663 10.6663 14.5031 10.6663 13.6849V11.1663H13.1849C14.0031 11.1663 14.6663 10.6237 14.6663 9.95422V7.04513C14.6663 6.37569 14.0031 5.83301 13.1849 5.83301ZM13.333 7.16634H9.33301V3.16634H6.66634V7.16634H2.66634V9.83301H6.66634V13.833H9.33301V9.83301H13.333V7.16634Z"
        fill="#FEFEFE"
      />
    </svg>
  );
};
Cross.propTypes = {
  className: PropTypes.string,
};
export default Cross;
