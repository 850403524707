import gql from 'graphql-tag';

export default gql`
  query($familyFileId: Int!, $communityId: Int!) {
    findFamilyLettersByFamilyFileId(
      input: { familyFileId: $familyFileId, communityId: $communityId }
    ) {
      sentAt
    }
  }
`;
