import { uniqWith } from 'lodash';

const buildSkillNotification = ({ skills, skillId, added, labels }) => {
  const skill = skills.find((s) => s.id.toString() === skillId);
  if (!skill) return null;
  const date = new Date();
  const type = added ? 'SKILL_ADDED' : 'SKILL_REMOVED';
  const id = `${type}_${skill.id}_${date.getTime()}`;
  const contentLabel = labels[type];
  return {
    id,
    title: labels.SKILL_UPDATE,
    content: contentLabel.replace('{SKILL}', skill.name),
    read: false,
    date,
  };
};

const buildSkillNotifications = ({
  skills,
  labels,
  skillsNotifications,
  addedSkills,
  removedSkills,
}) => {
  const notificationsAdded = addedSkills?.map((skillId) =>
    buildSkillNotification({ skills, skillId, added: true, labels }),
  );
  const notificationsRemoved = removedSkills?.map((skillId) =>
    buildSkillNotification({ skills, skillId, added: false, labels }),
  );
  const newNotifications = [
    ...notificationsAdded,
    ...notificationsRemoved,
  ].filter((n) => n !== null);
  const allNotifications = uniqWith(
    [...skillsNotifications, ...newNotifications],
    (a, b) => a.id === b.id,
  );
  return allNotifications;
};

const notificationsService = {
  buildSkillNotifications,
};
export default notificationsService;
