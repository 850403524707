import React from 'react';
import PropTypes from 'prop-types';
import { SortableElement } from 'react-sortable-hoc';
import { useStyles } from './SortStepsDialog.style';
import Item from './Item';

const SortableItem = SortableElement(Item);

const List = ({ items, labels }) => {
  const classes = useStyles();
  return (
    <div className={classes.sortable_list_container}>
      {items.map((step, i) => {
        return (
          <SortableItem
            step={step}
            index={i}
            key={step.stepId}
            labels={labels}
          />
        );
      })}
    </div>
  );
};

List.propTypes = {
  items: PropTypes.array,
  labels: PropTypes.object,
};

export default List;
