import React from 'react';
import PropTypes from 'prop-types';

const Flag = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="currentColor"
        d="M7 23H5H4V21H5V12V4V1H7V2H21.1247L18.126 7.99991L21.126 14H7V21H8V23H7ZM7 12V4H17.8893L15.89 8.00009L17.89 12H7Z"
      />
    </svg>
  );
};

Flag.propTypes = {
  className: PropTypes.string,
};

export default Flag;
