import React from 'react';
import PropTypes from 'prop-types';
import { withLabels } from '../../context/LabelContext';
import { withUser } from '../../context/UserContext';
import SendReferralLG from './SendReferralLG';
import SendReferralLGV2 from './SendReferralLGV2';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const SendReferral = ({ labels, currentUser, flags }) => {
  return (
    <>
      {flags.isTourWorkflowV2 ? (
        <SendReferralLGV2
          labels={labels}
          currentUser={currentUser}
          flags={flags}
        />
      ) : (
        <SendReferralLG
          labels={labels}
          currentUser={currentUser}
          flags={flags}
        />
      )}
    </>
  );
};

SendReferral.propTypes = {
  labels: PropTypes.object,
  currentUser: PropTypes.object,
  flags: PropTypes.shape({
    isTourWorkflowV2: PropTypes.bool.isRequired,
  }),
};

export default withLDConsumer()(withUser(withLabels(SendReferral)));
