import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  call_button_container: {
    display: 'flex',
    '& svg': {
      fontSize: '24px',
      color: theme.palette.light.platinum5,
      fill: theme.palette.light.platinum5,
    },
  },
  active: {
    '& svg': {
      color: theme.palette.neutral.cosmicCobalt3,
      fill: theme.palette.neutral.cosmicCobalt3,
    },
  },
  disabled: {
    '& svg': {
      color: theme.palette.dark.disabled,
      fill: theme.palette.dark.disabled,
    },
  },
  phone_dialpad_keypad: {
    marginTop: '10px',
  },
}));

export { useStyles };
