import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontSize: '12px',
    textDecorationLine: 'underline',
    color: theme.palette.dark.eerieBlack1,

    '& svg': {
      marginRight: '5px',
    },
  },
}));

export { useStyles };
