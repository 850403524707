import { useEffect, useState } from 'react';
import { useInterval } from './useInterval';
import useGraphQLQuery from './useGraphQLQuery';
import familyFileExternalIdQuery from './familyFileExternalIdQuery';

const MAX_ATTEMPTS = 7;
const DEFAULT_DELAY = 1000 * 10;

export const useExternalId = (familyFileId) => {
  const [externalId, setExternalId] = useState(null);
  const [fetchedFamilyId, setFetchedFamilyId] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [fetchCount, setFetchCount] = useState(1);
  const [fetchError, setFetchError] = useState(false);
  const { error, data, refetch } = useGraphQLQuery(familyFileExternalIdQuery, {
    variables: { familyFileId },
  });

  useEffect(() => {
    if (error) {
      setFetching(false);
      setFetchError(true);
    }
    if (data && data.findFamilyFileById && data.findFamilyFileById) {
      if (data.findFamilyFileById.externalId) {
        setExternalId(data.findFamilyFileById.externalId);
        setFetchedFamilyId(data.findFamilyFileById.familyFileId);
        setFetching(false);
      } else {
        if (!fetchError) setFetching(true);
      }
    }
  }, [data, error, fetchError]);

  useInterval(
    () => {
      refetch();
      setFetchCount(fetchCount + 1);
      if (fetching && fetchCount >= MAX_ATTEMPTS) {
        setFetching(false);
        setFetchError(true);
      }
    },
    fetching ? DEFAULT_DELAY : null,
  );

  return { externalId, fetchedFamilyId, fetchError, fetchCount, fetching };
};
