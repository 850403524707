module.exports = {
  CROSS_DOMAIN_STORAGE_URL:
    'https://sharedlogin.prod.aplaceformom.com/index.html',

  AUTH0_CLIENT_ID: 'I623AjOe0iuuiVacsWNtaSiFC3KOCqhb',
  AUTH0_DOMAIN: 'aplaceformom.auth0.com',

  LOGOUT_URL: 'https://owl-main-ui.internal.prod.aplaceformom.com/home',

  IMAGE_URL: 'https://www.aplaceformom.com/images',

  CASEMANAGEMENT_API_GET_CASES_URL:
    'https://owl-familyfile-service.internal.prod.aplaceformom.com/api/cases/?salesPhase={salesPhases}',
  CASEMANAGEMENT_API_GET_ACTIONS_BY_CASEID_URL:
    'https://owl-familyfile-service.internal.prod.aplaceformom.com/api/cases/{caseId}/actions',

  YGL_TASK_PAGE_URL:
    'https://ygl.aplaceformom.com/home.asp?tab=3&pg=te&LID={yglLeadID}',
  YGL_CASE_PAGE_URL:
    'https://ygl.aplaceformom.com/home.asp?tab=2&pg=ld&LID={yglLeadID}',
  YGL_WORKING_LIST_PAGE_URL:
    'https://ygl.aplaceformom.com/home.asp?tab=2&pg=wl&LID={yglLeadID}',
  YGL_PROFESSIONAL_SOURCE_URL:
    'https://ygl.aplaceformom.com/Leads/ProfessionalSrcTracking.aspx?LID={yglLeadID}',
  YGL_PROPERTY_PROFILE_URL:
    'https://ygl.aplaceformom.com/home.asp?tab=5&pg=pp&PID={yglPropertyID}',
  YGL_DISCLOSURE_URL:
    'https://salescrm-leads.internal.prod.aplaceformom.com/Lead/{yglLeadId}/Disclosure/Status',

  BEACON_FAMILY_FILE:
    'https://owl-main-ui.internal.prod.aplaceformom.com/family-file/{familyFileId}',

  POINTS_URL:
    'https://owl-legacyproxy-service.internal.prod.aplaceformom.com/metrics/points',
  QUOTA_URL:
    'https://owl-legacyproxy-service.internal.prod.aplaceformom.com/metrics/quotas',

  TOURS_SCHEDULED_METRICS_DURING_IC_URL:
    'https://owl-legacyproxy-service.internal.prod.aplaceformom.com/metrics/percentage-of-tours-scheduled-on-initial-consult',
  SECOND_ROUND_REFERRALS_METRICS_URL:
    'https://owl-legacyproxy-service.internal.prod.aplaceformom.com/metrics/percentage-of-tours-scheduled-on-second-round-referral',

  GRAPHQL_URL: 'https://owl-graph.internal.prod.aplaceformom.com/graphql',

  YGL_COMMUNICATION_SERVICE_TEXT_URL:
    'https://salescrm-communicationsupport.internal.prod.aplaceformom.com/Communication/acquireClickToTextUrl',

  APFM_LIVE_SPLASH_URL:
    'https://www.aplaceformom.com/community/{yglPropertyUrl}',

  TOUR_SCHEDULED_GOAL: 30,
  TOUR_SCHEDULED_LOW_LIMIT: 20,
  TOUR_SCHEDULED_HIGH_LIMIT: 29,
  TOUR_SCHEDULED_LOWEST_LIMIT: 0,

  SECOND_ROUND_GOAL: 35,
  SECOND_ROUND_LOW_LIMIT: 25,
  SECOND_ROUND_HIGH_LIMIT: 34,
  SECOND_ROUND_LOWEST_LIMIT: 0,
  TRACKING_ID: 'UA-6176737-73',

  AMPLITUDE_API_KEY: '92646f0703730d9e7c6a224a5a4dc75c',
  LD_CLIENT_SIDE_ID: '5e602dc2a311810981d54d8b',

  FAMILY_FILE_LETTER_EMAIL_TEMPLATE: 'beacon-send-family-letter-template',
  SELF_LEAD_FAMILY_FILE_LETTER_EMAIL_TEMPLATE:
    'beacon-send-family-letter-self-leads',
  MY_SEARCH_LINK: 'https://ygl.aplaceformom.com/Leads/LeadUrlGenerator.ashx',

  MANDRILL_API_KEY: 'vAisONvR1PoriTmUFqTAag',

  GOOGLE_GEOCODE_API_KEY: 'AIzaSyByPSCPyqVZswrHlBnxSxUpwOhqlj_FFRM',
  GOOGLE_MAPS_API_KEY: 'AIzaSyByPSCPyqVZswrHlBnxSxUpwOhqlj_FFRM',
  GOOGLE_PLACES_API_KEY: 'AIzaSyByPSCPyqVZswrHlBnxSxUpwOhqlj_FFRM',

  WEBSOCKET_URL: 'wss://owl-push-notifications.internal.prod.aplaceformom.com',

  SENTRY_APP_ENVIRONMENT: 'prod',
  OWL_CONNECT_ENVIRONMENT: 'prod',

  DEFAULT_CALLCENTER_USER_ID: '35830',
  CALL_BACK_CAMPAIGN_NAME: 'OS OB Beacon Scheduled Callbacks',
  CALL_BACK_LIST_NAME: 'OS Beacon Callbacks',

  CALL_BACK_CAMPAIGN_NAME_TEST: 'TEST QA OB BEACON SRCAuto Scheduled Callbacks',
  CALL_BACK_LIST_NAME_TEST: 'TEST Beacon Callbacks',

  CORPORATE_PHONE_NUMBER: '2062854666',
  CUSTOMER_EXPERIENCE_TEAM_PHONE_NUMBER: '5995595605',
  SENIOR_ADVISOR_TEAM_PHONE_NUMBER: '5995595846',
  RESOURCE_LINE_PHONE_NUMBER: '5995550520',
  SPANISH_LINE_PHONE_NUMBER: '5995550464',
};
