import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import CustomToolbar from './CustomToolbar';
import Typography from 'common/Typography/Typography';
import 'react-quill/dist/quill.snow.css';
import { useStyles } from './Quill.style';
import './quill.css';

const RQuill = ReactQuill.Quill;
var SizeStyle = RQuill.import('attributors/style/size');
RQuill.register(SizeStyle, true);

function inquiryVar(selectedValue) {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, selectedValue + ' ');
  this.quill.setSelection(cursorPosition + selectedValue.length + 1);
}
function globalVar(selectedValue) {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, selectedValue + ' ');
  this.quill.setSelection(cursorPosition + selectedValue.length + 1);
}
function assignSlaVar(selectedValue) {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, selectedValue + ' ');
  this.quill.setSelection(cursorPosition + selectedValue.length + 1);
}

const Quill = ({ label, value, onChange }) => {
  const classes = useStyles();

  const modules = {
    toolbar: {
      container: '#toolbar',
      handlers: { inquiryVar, globalVar, assignSlaVar },
    },
  };

  return (
    <div>
      <Typography className={classes.label} level="small">
        {label}
      </Typography>
      <CustomToolbar />
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        modules={modules}
      />
    </div>
  );
};

Quill.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
export default Quill;
