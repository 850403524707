import React from 'react';
import PropTypes from 'prop-types';

const MemoryCare = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M10.3494 24.7417C10.3494 24.7417 10.3494 20.9322 10.3494 19.7417C10.3494 18.5513 9.87319 18.0751 9.03985 17.3608C5.58747 13.9084 5.34937 11.4084 6.06366 7.95602C6.77794 4.50364 11.7779 0.575065 17.1351 2.47983C22.4922 4.38459 22.3732 9.97983 22.3732 9.97983C22.3732 9.97983 23.5637 12.5989 24.1589 13.6703C24.7541 14.7417 24.1589 15.0989 23.5637 15.0989C22.9684 15.0989 21.897 15.0989 21.897 15.0989C21.897 15.0989 21.897 16.5274 21.897 17.837C21.897 19.1465 20.9446 19.6227 20.7065 19.6227C20.4684 19.6227 20.1113 19.6227 19.5161 19.6227C18.5637 19.6227 18.6827 20.2179 18.6827 21.0513C18.6827 21.8846 18.6827 24.7417 18.6827 24.7417"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.931 5.2176H9.64528V0.931885H4.88337V5.2176H0.597656V9.9795H4.88337V14.2652H9.64528V9.9795H13.931V5.2176Z"
          fill="white"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0 0.33667)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

MemoryCare.propTypes = {
  className: PropTypes.string,
};

export default MemoryCare;
