import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import useGraphQLQuery from 'hooks/useGraphQLQuery';
import { useExternalId } from 'hooks/useExternalId';

import Typography from 'common/Typography/Typography';
import { useStyles } from './ReferredCommunities.style';
import NoReferredCommunities from '../../NoReferredCommunities/NoReferredCommunities';
import Checkbox from 'common/Checkbox/Checkbox';
import Send from 'common/Icons/basic/Send';
import ReferralCommunitiesRow from '../ReferredCommunitiesRow/ReferralCommunitiesRow';
import DropdownPopover from 'common/Dropdown/DropdownPopover/DropdownPopover';
import Note from 'common/Icons/file/Note';
import Modal from 'common/Modal/Modal';
import PinRound from 'common/Icons/maps/PinRound';
import Button from 'common/Button/Button';
import tourScheduleService, {
  logTourScheduleSaved,
} from 'services/tourScheduleService';
import { getDate, getHourAndMinutes12HourFormat } from 'utils/dateFormat';
import { dialerService } from 'services/communication/telephony/DialerService';

const ReferredCommunitiesList = ({
  labels,
  familyFileId,
  familyFileUserId,
  referredCommunities,
  setReferredCommunities,
  shouldRefetch,
}) => {
  const classes = useStyles();
  const [toggleAll, setToggleAll] = useState(false);
  const [afterScheduledTourModal, setAfterScheduledTourModal] = useState(false);
  const [scheduledTourCommunity, setScheduledTourCommunity] = useState();
  const [ffExternalId, setFFexternalId] = useState();

  let familyFilePrimaryContact = useRef();

  const familyFilePrimaryContactQuery = gql`
    query($familyFileId: Int) {
      findFamilyFileById(id: $familyFileId) {
        contacts {
          profileData {
            firstName
            lastName
            relationToResident
            emailAddress
            isPrimary
          }
        }
      }
    }
  `;

  const { error, data } = useGraphQLQuery(familyFilePrimaryContactQuery, {
    variables: { familyFileId },
  });

  const { externalId } = useExternalId(familyFileId);

  useEffect(() => {
    if (data?.findFamilyFileById?.contacts) {
      familyFilePrimaryContact.current = data.findFamilyFileById.contacts;
    }
    if (externalId) {
      setFFexternalId(externalId);
    }
  }, [data, externalId, setFFexternalId]);

  useEffect(() => {
    const shouldToggleAll = referredCommunities
      .filter((comm) => !comm.disabled)
      .every((comm) => comm.isChecked);

    setToggleAll(shouldToggleAll);
  }, [referredCommunities]);

  const getTourAnalyticsDetails = () => {
    const tourDetails = scheduledTourCommunity.tourDetails;

    const selectedTourTypeText = tourScheduleService.getTourTypeLabel(
      tourDetails.tourType,
    );
    const selectedTime = getHourAndMinutes12HourFormat(
      tourDetails.scheduledTourDate,
      true,
    );
    const tourDateTime = `${getDate(
      tourDetails.scheduledTourDate,
    )} ${selectedTime}`;

    return [selectedTourTypeText, tourDateTime];
  };

  const createTourScheduleSavedAnalyticsEvents = () => {
    const [selectedTourTypeText, tourDateTime] = getTourAnalyticsDetails();

    logTourScheduleSaved(
      scheduledTourCommunity.id,
      familyFileId,
      tourDateTime,
      selectedTourTypeText,
      familyFileUserId,
    );
  };

  const handleCommunityChange = (e, community) => {
    community.isChecked = e.target.checked;
    setReferredCommunities([...referredCommunities]);
  };

  const handleToggleAll = () => {
    const newReferredCommunity = referredCommunities.map((comm) => ({
      ...comm,
      ...{ isChecked: comm.disabled ? false : !toggleAll },
    }));
    setReferredCommunities(newReferredCommunity);
  };

  const handleTourSavedDialogClose = () => {
    setAfterScheduledTourModal(false);
    createTourScheduleSavedAnalyticsEvents();
    shouldRefetch();
  };

  const getSelectedCommunities = () => {
    return referredCommunities
      .filter((community) => community.isChecked)
      .map((community) => community.id);
  };

  const generateSendFamilyLetterLink = () => {
    return `/send-family-file-letter/${familyFileId}?communities=${getSelectedCommunities().join(
      ',',
    )}`;
  };

  const anySelected = () => {
    return !!referredCommunities.find((community) => community.isChecked);
  };

  if (referredCommunities.length === 0) {
    return (
      <NoReferredCommunities familyFileId={familyFileId} labels={labels} />
    );
  }

  const generateSendReferralLink = () => {
    return `/send-referral/${familyFileId}?communities=${getSelectedCommunities().join(
      ',',
    )}`;
  };

  const getBulkActions = () => {
    return [
      {
        action: () => {
          window.open(generateSendReferralLink(), '_self');
        },
        label: (
          <>
            <Send className={classes.icon} />
            {labels.SEND_REFERRAL_UPDATES}
          </>
        ),

        value: labels.SEND_REFERRAL_UPDATES,
      },
      {
        action: () => {
          window.open(generateSendFamilyLetterLink(), '_self');
        },
        label: (
          <>
            <Note className={classes.icon} />
            {labels.SEND_FAMILY_LETTER}
          </>
        ),
        value: labels.SEND_FAMILY_LETTER,
      },
    ];
  };

  const openAfterScheduledTourModal = (updatedCommunity) => {
    setScheduledTourCommunity(updatedCommunity);
    referredCommunities.forEach((community) => {
      if (community.id === updatedCommunity.id) {
        community = updatedCommunity;
      }
    });
    setReferredCommunities([...referredCommunities]);
    setAfterScheduledTourModal(true);
  };

  if (error) return `Error! ${error.message}`;
  return (
    <div className={classes.referred_communities}>
      <div className={classes.header_row}>
        <div className={classes.text_container}>
          <Typography>{labels.YOU_HAVE}&nbsp;</Typography>
          <Typography bold>{`(${referredCommunities.length})`}</Typography>
          <Typography>
            &nbsp;{labels.REFERRED_COMMUNITIES.toLowerCase()}
          </Typography>
        </div>
      </div>

      <div className={classes.header_row_labels}>
        <div className={classes.controls}>
          <Checkbox
            className={classes.checkbox_all}
            checked={toggleAll}
            indeterminate={toggleAll}
            label={toggleAll ? labels.DESELECT_ALL : labels.SELECT_ALL}
            onChange={handleToggleAll}
          />
          <div className={classes.bulk_actions_container}>
            <DropdownPopover
              displayButton={true}
              items={getBulkActions()}
              buttonLabel={labels.SELECT_ACTION}
              buttonType={anySelected() ? 'primary' : 'disabled'}
              placement={'left'}
            />
          </div>
        </div>
        <div className={classes.referral_sent_title}>
          <Typography>{labels.STATUS}</Typography>
        </div>
        <div className={classes.family_letter_sent}>
          <Typography>{labels.LAST_UPDATES}</Typography>
        </div>
        <div className={classes.actions}>
          <Typography>{labels.ACTIONS}</Typography>
        </div>
      </div>
      {referredCommunities.map((community, key) => (
        <ReferralCommunitiesRow
          community={{ ...community, id: Number(community.id) }}
          dataForAnalytics={{
            originatingPage: 'communities tab',
          }}
          familyFileId={familyFileId}
          familyFileUserId={familyFileUserId}
          handleAfterScheduledTourModal={openAfterScheduledTourModal}
          key={key}
          labels={labels}
          onChange={(e) => handleCommunityChange(e, community)}
          onTourCompletion={shouldRefetch}
          dialerService={dialerService}
          familyFilePrimaryContact={familyFilePrimaryContact}
          externalId={ffExternalId}
        />
      ))}
      <Modal
        isOpen={afterScheduledTourModal}
        title={labels.TOUR_SAVED_TITLE}
        icon={<PinRound />}
        onClose={handleTourSavedDialogClose}
        className={classes.modal_container}
      >
        <>
          <Typography color="eerieBlack5">{labels.SAVED_TOUR_AT}: </Typography>
          <Typography color="eerieBlack5" className={classes.modal_text}>
            {`- ${scheduledTourCommunity?.name}`}
          </Typography>
          <Typography color="eerieBlack5" className={classes.modal_text}>
            {labels.SCHEDULED_TOUR_WITHOUT_RA}
          </Typography>
          <Typography color="eerieBlack5" className={classes.modal_text}>
            {labels.UPDATED_REFERRAL_RECOMMENDED}{' '}
          </Typography>
          <div className={classes.modal_button_container}>
            <Button
              type="light"
              size="small"
              onClick={handleTourSavedDialogClose}
              className={classes.modal_button}
            >
              {labels.CLOSE}
            </Button>
            <Button
              size="small"
              onClick={() => {
                window.open(
                  `/send-referral/${familyFileId}?selectedTourTime=true&communities=${scheduledTourCommunity.id}`,
                  '_self',
                );
              }}
              className={classes.modal_button}
            >
              {labels.SEND_REFERRAL_UPDATE}
            </Button>
          </div>
        </>
      </Modal>
    </div>
  );
};

ReferredCommunitiesList.propTypes = {
  labels: PropTypes.object.isRequired,
  familyFileId: PropTypes.number.isRequired,
  familyFileUserId: PropTypes.number,
  referredCommunities: PropTypes.array,
  setReferredCommunities: PropTypes.func,
  shouldRefetch: PropTypes.func,
};

export default ReferredCommunitiesList;
