import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from 'common/Typography/Typography';
import { useStyles } from './ContactInformationEdit.style';
import clsx from 'clsx';
import TextInput from 'common/TextInput/TextInput';
import Dropdown from 'common/Dropdown/Dropdown';
import Checkbox from 'common/Checkbox/Checkbox';
import relationshipToResident from './relationshipToResident.json';
import countries from './countries.json';
import { formatPhone } from 'utils/phoneFormat';
import { handleOnChange } from 'services/formService';
import Radio from 'common/Radio/Radio';
import Button from 'common/Button/Button';
import Plus from 'common/Icons/basic/Plus';
import X from 'common/Icons/basic/X';
import Container from 'common/Container/Container';
import { _parseToYesOrNo, _parseToBoolean } from 'utils/utils';
import {
  getEmailErrorKey,
  getAllowContactOptions,
  getHasEmailOptions,
  getStatesByCountry,
  isValidPhoneNumber,
  isValidEmail,
  isValidPostalCode,
} from './ContactInformationHelper';

const INITIAL_PHONE_ERRORS_STATE = {
  primaryHomePhoneError: false,
  primaryWorkPhoneError: false,
  primaryCellPhoneError: false,
  secondaryHomePhoneError: false,
  secondaryWorkPhoneError: false,
  secondaryCellPhoneError: false,
  additionalHomePhoneError: false,
  additionalWorkPhoneError: false,
  additionalCellPhoneError: false,
};
const INITIAL_EMAIL_ERRORS_STATE = {
  primaryEmailError: false,
  secondaryEmailError: false,
  additionalEmailError: false,
};

const ContactInformationEdit = ({
  contactInfo,
  labels,
  firstNameError,
  lastNameError,
  relationshipToResidentError,
  isPrimaryContact,
  isAdditionalContact,
  setFirstNameError,
  setLastNameError,
  setRelationshipToResidentError,
}) => {
  const classes = useStyles();

  const [emailErrors, setEmailErrors] = useState(INITIAL_EMAIL_ERRORS_STATE);
  const [phoneErrors, setPhoneErrors] = useState(INITIAL_PHONE_ERRORS_STATE);

  const allowContactOptions = getAllowContactOptions(labels);
  const hasEmailOptions = getHasEmailOptions(labels);

  const [contact, setContact] = useState(contactInfo);
  const [noEmail, setNoEmail] = useState(true);
  const [primaryContactExpanded, setPrimaryContactExpanded] = useState(false);
  const [secondaryContactExpanded, setSecondaryContactExpanded] = useState(
    false,
  );

  const [selectedAllowContactItem, setSelectedAllowContactItem] = useState(
    _parseToYesOrNo(contact.allowContact) || 'Select from list',
  );
  const [selectedHasEmailItem, setSelectedHasEmailItem] = useState(
    _parseToYesOrNo(noEmail) || 'Select from list',
  );

  let selectedAllowContact = allowContactOptions.find(
    (item) => item.value === selectedAllowContactItem,
  );

  let selectedHasEmail = hasEmailOptions.find(
    (item) => item.value === selectedHasEmailItem,
  );

  const getEmail = ({ emailAddresses, isPrimary = false }) => {
    if (emailAddresses?.length > 0) {
      const emails = isPrimary
        ? emailAddresses.filter((email) => email.isPrimary)
        : emailAddresses.filter((email) => !email.isPrimary);
      if (emails.length > 0) {
        return emails[0].emailAddress;
      }
    }
  };

  const getEmailAddress = ({ contactDetail, emailAddresses }) => {
    const emailAddress = getEmail(emailAddresses, true);
    if (emailAddress) {
      return emailAddress;
    } else {
      if (contactDetail.email) {
        return contactDetail.email;
      }
    }
  };

  const getSecondEmailAddress = ({ emailAddresses }) => {
    const emailAddress = getEmail(emailAddresses);
    if (emailAddress) {
      return emailAddress;
    }
  };

  const [email, setEmail] = useState(getEmailAddress(contact));

  const [secondEmail, setSecondEmail] = useState(
    getSecondEmailAddress(contact),
  );

  const getPhoneByCode = (code, phones) => {
    const phoneToUse = phones.find((phone) => phone.subCategoryCode === code);
    if (phoneToUse === undefined) return '';
    return `${phoneToUse.areaCode}${phoneToUse.localNumber}`;
  };

  const [homePhone, setHomePhone] = useState(
    getPhoneByCode(labels.HOME_PHONE_CODE, contact.phones),
  );

  const [cellPhone, setCellPhone] = useState(
    getPhoneByCode(labels.CELL_PHONE_CODE, contact.phones),
  );
  const [workPhone, setWorkPhone] = useState(
    getPhoneByCode(labels.WORK_PHONE_CODE, contact.phones),
  );

  const setContactProperty = (name, value) => {
    setContact({
      ...contact,
      [name]: typeof value === 'string' ? value.trim() : value,
    });
  };

  const statesToShow = getStatesByCountry(contact.country);

  const getSelectedRelationshipItem = (relationToResidentId) => {
    if (!relationToResidentId) return { value: '', label: '--select--' };

    return relationshipToResident.find(
      (rel) => rel.value.toString() === relationToResidentId.toString(),
    );
  };

  const getSelectedStateItem = (stateSelected) => {
    return statesToShow.find((state) => state.value === stateSelected);
  };

  const getSelectedCountryItem = (countrySelected) => {
    return countries.find((country) => country.value === countrySelected);
  };

  const handleRelationshipToResidentChange = (item) => {
    setContactProperty('relationship', item.label);
    setContactProperty('relationToResidentId', item.value);
    if (item.value) {
      setRelationshipToResidentError(null);
    }
  };

  const handleCountryChange = (item) => {
    setContactProperty('country', item.value);
  };

  const handleEmailAddressChange = (enteredEmailAddress, isPrimary = false) => {
    contact.emailAddresses = [
      { emailAddress: enteredEmailAddress, isPrimary: isPrimary },
    ];
    isPrimary
      ? setEmail(enteredEmailAddress)
      : setSecondEmail(enteredEmailAddress);
    setContact(contact);
    const isValid = isValidEmail(enteredEmailAddress);
    const emailErrorKey = getEmailErrorKey(
      isPrimaryContact,
      isAdditionalContact,
    );
    handleEmailError(emailErrorKey, !isValid);
    return enteredEmailAddress;
  };
  const handlePrimaryEmailAddress = (enteredEmailAddress) => {
    return handleEmailAddressChange(enteredEmailAddress, true);
  };
  const handleSecondEmailAddress = (enteredEmailAddress) => {
    return handleEmailAddressChange(enteredEmailAddress);
  };
  const getPhoneNumberErrorKey = ({ type }) => {
    const PRIMARY = 'primary';
    const SECONDARY = 'secondary';
    const ADDITIONAL = 'additional';

    const phoneErrorsMap = {
      [labels.HOME_PHONE_CODE]: 'HomePhoneError',
      [labels.WORK_PHONE_CODE]: 'WorkPhoneError',
      [labels.CELL_PHONE_CODE]: 'CellPhoneError',
    };

    if (isPrimaryContact) {
      return `${PRIMARY}${phoneErrorsMap[type]}`;
    }
    if (!isPrimaryContact && !isAdditionalContact) {
      return `${SECONDARY}${phoneErrorsMap[type]}`;
    }
    if (isAdditionalContact) {
      return `${ADDITIONAL}${phoneErrorsMap[type]}`;
    }
  };

  const handlePhoneChange = (phone, type) => {
    const clearPhone = phone.replace(/[-\s]/g, '');

    if (type === labels.HOME_PHONE_CODE) setHomePhone(clearPhone);
    if (type === labels.CELL_PHONE_CODE) setCellPhone(clearPhone);
    if (type === labels.WORK_PHONE_CODE) setWorkPhone(clearPhone);

    contact.phones = contact.phones.map((phone) => {
      if (type === phone.subCategoryCode) {
        return {
          ...phone,
          localNumber: clearPhone.substring(3),
          areaCode: clearPhone.substring(0, 3),
        };
      }
      return phone;
    });

    const alreadyEnteredPhone = contact.phones.find(
      (phone) => phone.subCategoryCode === type,
    );

    if (alreadyEnteredPhone === undefined) {
      contact.phones.push({
        localNumber: clearPhone.substring(3),
        areaCode: clearPhone.substring(0, 3),
        subCategoryCode: type,
        isPrimaryForCategory: type === labels.CELL_PHONE_CODE,
      });
    }

    const cellPhoneNumbers = contact.phones.filter(
      (phone) =>
        phone.subCategoryCode === labels.CELL_PHONE_CODE &&
        phone.localNumber !== '',
    );

    const homePhoneNumbers = contact.phones.filter(
      (phone) =>
        phone.subCategoryCode === labels.HOME_PHONE_CODE &&
        phone.localNumber !== '',
    );

    const workPhoneNumbers = contact.phones.filter(
      (phone) =>
        phone.subCategoryCode === labels.WORK_PHONE_CODE &&
        phone.localNumber !== '',
    );

    if (cellPhoneNumbers.length > 0) {
      contact.phones.map((phone) => {
        if (phone.subCategoryCode !== labels.CELL_PHONE_CODE) {
          phone.isPrimaryForCategory = false;
        } else {
          phone.isPrimaryForCategory = true;
        }
        return '';
      });
    } else {
      if (homePhoneNumbers.length > 0) {
        contact.phones.map((phone) => {
          if (phone.subCategoryCode !== labels.HOME_PHONE_CODE) {
            phone.isPrimaryForCategory = false;
          } else {
            phone.isPrimaryForCategory = true;
          }
          return '';
        });
      } else {
        if (workPhoneNumbers.length > 0) {
          contact.phones.map((phone) => {
            if (phone.subCategoryCode !== labels.WORK_PHONE_CODE) {
              phone.isPrimaryForCategory = false;
            } else {
              phone.isPrimaryForCategory = true;
            }
            return '';
          });
        }
      }
    }

    const errorKey = getPhoneNumberErrorKey(type);
    const isValid = isValidPhoneNumber(clearPhone);
    handlePhoneNumberErrors(errorKey, !isValid);

    setContact(contact);
  };

  const handleBooleanOption = (checked, option) => {
    if (option === 'noSend') {
      console.dir(checked);
    } else {
      setContactProperty(option, checked);
    }
  };

  const onChangeAllowContactItem = (selectedItem) => {
    setSelectedAllowContactItem(selectedItem.value);
    setContactProperty('allowContact', selectedAllowContactItem);
  };
  const onChangeHasEmailItem = (selectedItem) => {
    setNoEmail(_parseToBoolean(selectedItem.value));
    setSelectedHasEmailItem(selectedItem.value);
  };

  const getTextInputId = (type) => {
    let contact = isPrimaryContact ? 'primary-contact' : 'secondary';
    contact = isAdditionalContact ? 'additional-contact' : contact;

    return `${contact}-${type}`;
  };

  const isEmptyContact = (contactInfo) => {
    return (
      contactInfo === undefined ||
      (!contactInfo.firstName && !contactInfo.lastName)
    );
  };

  const isContactsFormChanged = (original, edited) => {
    if (isEmptyContact(edited?.secondaryContact)) {
      delete original?.secondaryContact;
      delete edited?.secondaryContact;
    }
    if (isEmptyContact(edited?.additionalContact)) {
      delete original?.additionalContact;
      delete edited?.additionalContact;
    }

    return JSON.stringify(original) !== JSON.stringify(edited);
  };

  const checkForChangedFormData = (originalData) => (
    editedData,
    certainProperty = undefined,
  ) => {
    if (certainProperty) {
      return isContactsFormChanged(
        originalData?.[certainProperty],
        editedData?.[certainProperty],
      );
    }
    return isContactsFormChanged(originalData, editedData);
  };

  const handlePhoneNumberErrors = (errorField, isError) => {
    if (Object.keys(phoneErrors).includes(errorField)) {
      phoneErrors[errorField] = isError;
    }

    setPhoneErrors({ ...phoneErrors });
  };

  const handleEmailError = (errorField, isError) => {
    if (Object.keys(emailErrors).includes(errorField)) {
      emailErrors[errorField] = isError;
    }
    setEmailErrors({ ...emailErrors });
  };

  const hasErrors = (inputErrors) => {
    return Object.values(inputErrors).reduce((acc, curr) => acc || curr, false);
  };

  useEffect(() => {
    console.dir(!(hasErrors(emailErrors) || hasErrors(phoneErrors)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneErrors, emailErrors]);

  useEffect(() => {
    setPrimaryContactExpanded(
      checkForChangedFormData(contactInfo, 'primaryContact'),
    );
    setSecondaryContactExpanded(
      checkForChangedFormData(contactInfo, 'secondaryContact'),
    );
    setNoEmail(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactInfo]);

  return (
    <Fragment>
      {(contact.isPrimary || !secondaryContactExpanded) && (
        <div className={classes.contact_container}>
          <div className={classes.column}>
            <Container
              spacing={6}
              className={clsx(classes.row, classes.contact_input_container)}
            >
              <div className={clsx(classes.title)}>
                <Typography bold>{labels.PRIMARY_CONTACT_DETAILS}</Typography>
              </div>
              <div className={classes.names_container}>
                <TextInput
                  label={labels.FIRST_NAME}
                  value={contact.firstName}
                  className={clsx(
                    classes.default_name_input,
                    'name-input',
                    firstNameError ? 'error' : '',
                  )}
                  onChange={handleOnChange(
                    'firstName',
                    setFirstNameError,
                    setContactProperty,
                  )}
                  error={!!firstNameError}
                  textHint={firstNameError}
                  id={getTextInputId('first-name')}
                  isMandatory={isPrimaryContact}
                  maxLength={45}
                  autoComplete="off"
                />
                <TextInput
                  label={labels.LAST_NAME}
                  value={contact.lastName}
                  className={clsx(
                    classes.default_name_input,
                    'name-input',
                    lastNameError ? 'error' : '',
                  )}
                  onChange={handleOnChange(
                    'lastName',
                    setLastNameError,
                    setContactProperty,
                  )}
                  error={!!lastNameError}
                  textHint={lastNameError}
                  id={`${
                    isPrimaryContact ? 'primary-contact' : 'secondary'
                  }-last-name`}
                  isMandatory={isPrimaryContact}
                  maxLength={45}
                  autoComplete="off"
                />
                <Dropdown
                  emptyLabel="--select--"
                  error={!!relationshipToResidentError}
                  textHint={relationshipToResidentError}
                  items={relationshipToResident}
                  className={classes.relationship_to_resident}
                  label={labels.RELATION_TO_RESIDENT}
                  onChange={handleRelationshipToResidentChange}
                  selectedItem={getSelectedRelationshipItem(
                    contact.relationToResidentId,
                  )}
                  withScroll
                />
              </div>
              <div className={(clsx(classes.row), classes.column_contact)}>
                {noEmail && (
                  <>
                    <TextInput
                      label={labels.EMAIL}
                      value={email}
                      className={classes.email_input}
                      onChange={handlePrimaryEmailAddress}
                      error={!isValidEmail(email)}
                      textHint={isValidEmail(email) ? '' : labels.INVALID_EMAIL}
                      id="primary-email"
                      maxLength={45}
                      autoComplete="off"
                      isMandatory={isPrimaryContact}
                      // disabled={!flags.editAdditionalContact}
                    />
                    <TextInput
                      label={labels.SECONDARY_EMAIL}
                      value={secondEmail}
                      className={classes.email_input}
                      onChange={handleSecondEmailAddress}
                      error={!isValidEmail(secondEmail)}
                      textHint={
                        isValidEmail(secondEmail) ? '' : labels.INVALID_EMAIL
                      }
                      id="secondary-email"
                      maxLength={45}
                      autoComplete="off"
                      // disabled={!flags.editAdditionalContact}
                    />
                  </>
                )}
                <div className={clsx(classes.row, classes.radio_container)}>
                  <Radio
                    selected={selectedHasEmail}
                    name="complete-options"
                    items={hasEmailOptions}
                    onChange={(e) => onChangeHasEmailItem(e)}
                  />
                </div>
              </div>
              <div className={classes.phones_container}>
                <TextInput
                  label={labels.HOME_PHONE}
                  value={formatPhone(homePhone)}
                  className={clsx(classes.default_input, classes.phone_input)}
                  error={!isValidPhoneNumber(homePhone)}
                  textHint={
                    isValidPhoneNumber(homePhone)
                      ? ''
                      : labels.INVALID_PHONE_NUMBER
                  }
                  onChange={(value) =>
                    handlePhoneChange(value, labels.HOME_PHONE_CODE)
                  }
                  id={`${
                    isPrimaryContact ? 'primary' : 'secondary'
                  }-home-phone`}
                  maxLength={45}
                  autoComplete="off"
                  // disabled={!flags.editAdditionalContact}
                />
                <TextInput
                  label={labels.WORK_PHONE}
                  value={formatPhone(workPhone)}
                  className={clsx(classes.default_input, classes.phone_input)}
                  onChange={(value) =>
                    handlePhoneChange(value, labels.WORK_PHONE_CODE)
                  }
                  error={!isValidPhoneNumber(workPhone)}
                  textHint={
                    isValidPhoneNumber(workPhone)
                      ? ''
                      : labels.INVALID_PHONE_NUMBER
                  }
                  id={`${
                    isPrimaryContact ? 'primary' : 'secondary'
                  }-work-phone`}
                  maxLength={45}
                  autoComplete="off"
                  // disabled={!flags.editAdditionalContact}
                />
                <TextInput
                  label={labels.CELL_PHONE}
                  value={formatPhone(cellPhone)}
                  className={clsx(classes.default_input, classes.phone_input)}
                  onChange={(value) =>
                    handlePhoneChange(value, labels.CELL_PHONE_CODE)
                  }
                  error={!isValidPhoneNumber(cellPhone)}
                  textHint={
                    isValidPhoneNumber(cellPhone)
                      ? ''
                      : labels.INVALID_PHONE_NUMBER
                  }
                  id={`${
                    isPrimaryContact ? 'primary' : 'secondary'
                  }-cell-phone`}
                  maxLength={45}
                  autoComplete="off"
                  // disabled={!flags.editAdditionalContact}
                />
              </div>
              <div className={clsx(classes.row, classes.radio_container)}>
                <Radio
                  selected={selectedAllowContact}
                  label={labels.ALLOW_CONTACT}
                  name="complete-options"
                  items={allowContactOptions}
                  onChange={(e) => onChangeAllowContactItem(e)}
                />
              </div>
              {primaryContactExpanded && noEmail && (
                <Button
                  type="light"
                  className={clsx(classes.address_button, classes.plus)}
                  endIcon={<Plus />}
                  onClick={() =>
                    setPrimaryContactExpanded(!primaryContactExpanded)
                  }
                >
                  <Typography bold>{labels.PRIMARY_ADDRESS_TITLE}</Typography>
                </Button>
              )}
            </Container>
          </div>
          <>
            {(!primaryContactExpanded || !noEmail) && (
              <div className={classes.column}>
                <div
                  className={clsx(classes.row, classes.address_input_container)}
                >
                  <Button
                    type="light"
                    className={clsx(classes.address_button, classes.x)}
                    endIcon={<X />}
                    onClick={() =>
                      setPrimaryContactExpanded(!primaryContactExpanded)
                    }
                  >
                    <Typography bold>{labels.PRIMARY_ADDRESS}</Typography>
                  </Button>
                  <TextInput
                    label={labels.ADDRESS_1}
                    value={contact.address1 || ''}
                    className={classes.address_input}
                    onChange={(value) => setContactProperty('address1', value)}
                    id={`${
                      isPrimaryContact ? 'primary' : 'secondary'
                    }-address1`}
                    maxLength={45}
                    autoComplete="off"
                  />
                  <TextInput
                    label={labels.ADDRESS_2}
                    value={contact.address2 || ''}
                    className={clsx(classes.address_input, classes.address2)}
                    onChange={(value) => setContactProperty('address2', value)}
                    id={`${
                      isPrimaryContact ? 'primary' : 'secondary'
                    }-address2`}
                    maxLength={45}
                    autoComplete="off"
                  />
                  <div
                    className={clsx(classes.row, classes.city_state_container)}
                  >
                    <Dropdown
                      emptyLabel={'--select--'}
                      items={countries}
                      className={classes.country_dropdown}
                      label={labels.COUNTRY}
                      onChange={(item) => handleCountryChange(item)}
                      selectedItem={getSelectedCountryItem(contact.country)}
                    />
                    <Dropdown
                      emptyLabel={'--select--'}
                      items={statesToShow}
                      value={contact.state || ''}
                      className={classes.location_dropdown}
                      label={labels.STATE_PROV}
                      onChange={(item) =>
                        setContactProperty('state', item.value)
                      }
                      selectedItem={getSelectedStateItem(contact.state)}
                      withScroll
                    />
                    <TextInput
                      label={labels.CITY}
                      value={contact.city || ''}
                      className={classes.city_input}
                      onChange={(value) => setContactProperty('city', value)}
                      id={`${isPrimaryContact ? 'primary' : 'secondary'}-city`}
                      maxLength={45}
                      autoComplete="off"
                    />
                  </div>
                  <div
                    className={clsx(classes.row, classes.city_state_container)}
                  >
                    <TextInput
                      label={labels.POSTAL_CODE}
                      value={contact.postalCode || ''}
                      error={
                        !isValidPostalCode(contact.postalCode, contact.country)
                      }
                      className={classes.default_postal_code_input}
                      onChange={(value) =>
                        setContactProperty('postalCode', value)
                      }
                      id={`${
                        isPrimaryContact ? 'primary' : 'secondary'
                      }-postal-code`}
                      maxLength={45}
                      autoComplete="off"
                      disabled={!contact.country}
                    />
                    <Checkbox
                      label={labels.NO_ADDRESS_IN_REFERRAL_ALERT}
                      className={classes.checkbox_address}
                      onChange={(item) =>
                        handleBooleanOption(item.target.checked, 'noSend')
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      )}
      {!contact.isPrimary && (
        <div className={classes.contact_container}>
          <Button
            type="light"
            className={clsx(classes.address_button, classes.plus)}
            endIcon={<Plus />}
            onClick={() =>
              setSecondaryContactExpanded(!secondaryContactExpanded)
            }
          >
            <Typography bold>{labels.ADDITIONAL_CONTACT}</Typography>
          </Button>
        </div>
      )}
    </Fragment>
  );
};

ContactInformationEdit.propTypes = {
  labels: PropTypes.object.isRequired,
  contactInfo: PropTypes.shape({
    name: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    relationship: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    allowContact: PropTypes.bool,
    isInquirer: PropTypes.bool,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    contactDetail: PropTypes.shape({
      phonePrimary: PropTypes.string,
      phoneSecondary: PropTypes.string,
      email: PropTypes.string,
      primaryPhoneType: PropTypes.string,
      secondaryPhoneType: PropTypes.string,
    }),
    phones: PropTypes.any,
  }),
  firstNameError: PropTypes.string,
  lastNameError: PropTypes.string,
  relationshipToResidentError: PropTypes.string,
  isPrimaryContact: PropTypes.bool,
  title: PropTypes.string,
  isAdditionalContact: PropTypes.bool,
  flags: PropTypes.shape({
    editAdditionalContact: PropTypes.bool,
  }),
  enableReferralAlertValidation: PropTypes.bool,
  setFirstNameError: PropTypes.func,
  setLastNameError: PropTypes.func,
  setRelationshipToResidentError: PropTypes.func,
};

ContactInformationEdit.defaultProps = {
  isPrimaryContact: false,
  firstNameError: '',
  lastNameError: '',
  relationshipToResidentError: '',
  isAdditionalContact: false,
  enableReferralAlertValidation: false,
  setFirstNameError: () => {},
  setLastNameError: () => {},
  setRelationshipToResidentError: () => {},
};

export default ContactInformationEdit;
