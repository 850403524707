import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useStyles } from './Sidebar.style';
import NoteText from 'common/Icons/file/NoteText';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';

const ShowNotesButton = ({ labels, onClick, onlyShowCommunitiesTabFf }) => {
  const classes = useStyles();

  return (
    <div
      className={
        !onlyShowCommunitiesTabFf
          ? classes.sidebar_button
          : clsx(classes.sidebar_button, classes.sidebar_button_btmMargin)
      }
      onClick={onClick}
    >
      <SmartTooltip placement={'left'} title={labels.SHOW_NOTES}>
        <NoteText />
      </SmartTooltip>
    </div>
  );
};

ShowNotesButton.propTypes = {
  labels: PropTypes.object,
  onClick: PropTypes.func,
  onlyShowCommunitiesTabFf: PropTypes.bool,
};

ShowNotesButton.default = {
  onClick: () => {},
};

export default ShowNotesButton;
