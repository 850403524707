import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles(() => ({
  filters: {
    width: '25%',
    marginRight: '0.5%',
  },
  filters_input: {
    width: '73.5%',
    marginRight: '1%',
  },
  filters_container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& label': {
      display: 'none',
    },
  },
  filters_mobile: {
    flexGrow: 1,
    '& .star': {
      display: 'none',
    },
  },
  filters_input_mobile: {
    flexGrow: 1,
    '& > div, & > div:hover': {
      borderLeft: 0,
      borderRight: 0,
    },
  },
  filters_container_mobile: {
    padding: '16px',
    alignItems: 'center',
    flexDirection: 'row',
    width: '%',
    '& label': {
      display: 'none',
    },
  },
}));

export { useStyles };
