import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  user: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '16px 24px',
    cursor: 'pointer',
    borderLeft: `1px solid ${theme.palette.neutral.cosmicCobalt2}`,
    '& img': {
      marginRight: '16px',
      borderRadius: '50%',
      height: '40px',
      width: '40px',
    },
    '& svg': {
      color: theme.palette.light.platinum5,
      marginLeft: '12px',
      fontSize: '16px',
    },
  },
  user_menu: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '72px',
    left: 0,
    right: 0,
    backgroundColor: theme.palette.neutral.cosmicCobalt1,
    zIndex: 3,
  },
  user_menu_item: {
    cursor: 'pointer',
    padding: '16px 24px',
    marginTop: '16px',
    '&:hover': {
      backgroundColor: theme.palette.neutral.cosmicCobalt3,
    },
  },
  user_name_skill: {
    display: 'flex',
    flexDirection: 'column',
  },
  skill_list: {
    marginLeft: 80,
  },
  skill: {
    marginBottom: 4,
  },
  hellip: {
    color: theme.palette.neutral.cosmicCobalt1,
    '&.visible': {
      color: theme.palette.light.platinum5,
    },
  },
  version_info: {
    '& span': {
      color: theme.palette.neutral.cosmicCobalt4,
      fontSize: '11px',
    },
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
}));

export { useStyles };
