import React from 'react';

const MedicalBackground = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.6453 9.03224V13.8915L37.1136 15.5264L37.0324 15.3548V8.12902H29.8066V4.51611H40.6453V9.03224Z"
        fill="#E9CE73"
      />
      <path
        d="M40.6453 41.6657V55.0968H0.90332V4.51611H11.742V8.12902H4.51622V51.4839H37.0324V38.8387L37.1135 38.7483C38.1614 39.8504 39.3447 40.8348 40.6453 41.6657Z"
        fill="#E9CE73"
      />
      <path
        d="M31.6124 25.0282C31.6124 30.2037 33.6266 35.0991 37.1129 38.7482L37.0318 38.8386V51.4837H4.51562V8.12891H37.0318V15.3547L37.1129 15.5263L31.6124 18.0644V25.0282Z"
        fill="#EDEDED"
      />
      <path
        d="M12.6454 0.90332H28.9035C29.4001 0.90332 29.8067 1.30995 29.8067 1.80655V8.12913H11.7422V1.80655C11.7422 1.30995 12.1488 0.90332 12.6454 0.90332Z"
        fill="#A37D00"
      />
      <path
        d="M31.613 36.1292V37.0325H9.93555V36.1292C9.93555 30.141 14.786 25.2905 20.7743 25.2905C23.7728 25.2905 26.4825 26.5007 28.4429 28.4606C30.4028 30.421 31.613 33.1307 31.613 36.1292Z"
        fill="#E1737D"
      />
      <path
        d="M25.2901 18.0645V20.7741C25.2901 23.2668 23.2666 25.2903 20.7739 25.2903C18.2813 25.2903 16.2578 23.2668 16.2578 20.7741V18.0645C16.2578 15.5718 18.2813 13.5483 20.7739 13.5483C22.0203 13.5483 23.1493 14.0542 23.9714 14.867C24.7842 15.6891 25.2901 16.8181 25.2901 18.0645Z"
        fill="#F3E0D4"
      />
      <path
        d="M40.6455 41.6656C39.3449 40.8347 38.1617 39.8503 37.1138 38.7482C33.6275 35.0991 31.6133 30.2037 31.6133 25.0282V18.0644L37.1138 15.5263L40.6455 13.8914L43.3552 12.645L55.0972 18.0644V25.0282C55.0972 31.929 51.5204 38.3327 45.6402 41.9456L43.3552 43.3547L41.0702 41.9456C40.9256 41.8552 40.7902 41.7652 40.6455 41.6656Z"
        fill="#82B7A8"
      />
      <path
        d="M47.8711 24.3869V27.9998H45.1614V30.7095H41.5485V27.9998H38.8389V24.3869H41.5485V21.6772H45.1614V24.3869H47.8711Z"
        fill="#EDEDED"
      />
      <path
        d="M16.2578 3.61279H18.0643V5.41924H16.2578V3.61279Z"
        fill="#0D1321"
      />
      <path
        d="M23.4844 3.61279H25.2908V5.41924H23.4844V3.61279Z"
        fill="#0D1321"
      />
      <path
        d="M24.2623 24.9153C25.4411 23.9208 26.1935 22.4341 26.1935 20.7741V18.0644C26.1935 15.0755 23.763 12.645 20.7742 12.645C17.7853 12.645 15.3548 15.0755 15.3548 18.0644V20.7741C15.3548 22.4341 16.1072 23.9208 17.2861 24.9153C12.5097 26.4047 9.03223 30.8683 9.03223 36.1289V37.9353H32.5161V36.1289C32.5161 30.8683 29.0386 26.4047 24.2623 24.9153ZM17.1613 20.7741V18.0644C17.1613 16.0718 18.7816 14.4515 20.7742 14.4515C22.7667 14.4515 24.3871 16.0718 24.3871 18.0644V20.7741C24.3871 22.7666 22.7667 24.387 20.7742 24.387C18.7816 24.387 17.1613 22.7666 17.1613 20.7741ZM10.8387 36.1289C10.8387 30.6509 15.2961 26.1934 20.7742 26.1934C26.2522 26.1934 30.7096 30.6509 30.7096 36.1289H10.8387Z"
        fill="#0D1321"
      />
      <path
        d="M9.03223 39.7422H32.5161V41.5486H9.03223V39.7422Z"
        fill="#0D1321"
      />
      <path d="M30.71 46.9678H32.5164V48.7742H30.71V46.9678Z" fill="#0D1321" />
      <path
        d="M9.03223 46.9678H28.9032V48.7742H9.03223V46.9678Z"
        fill="#0D1321"
      />
      <path
        d="M9.03223 43.355H19.8709V45.1614H9.03223V43.355Z"
        fill="#0D1321"
      />
      <path
        d="M21.6777 43.355H32.5164V45.1614H21.6777V43.355Z"
        fill="#0D1321"
      />
      <path
        d="M43.2725 38.1036L44.2198 39.6419C49.2572 36.5411 52.3867 30.94 52.3867 25.0249V24.3872H50.5803V25.0249C50.5803 30.3177 47.7793 35.3287 43.2725 38.1036Z"
        fill="#0D1321"
      />
      <path
        d="M50.5811 20.7744H52.3875V22.5809H50.5811V20.7744Z"
        fill="#0D1321"
      />
      <path
        d="M40.6452 20.7744V23.4841H37.9355V28.9034H40.6452V31.6131H46.0646V28.9034H48.7743V23.4841H46.0646V20.7744H40.6452ZM46.9678 25.2905V27.097H44.2581V29.8067H42.4517V27.097H39.742V25.2905H42.4517V22.5809H44.2581V25.2905H46.9678Z"
        fill="#0D1321"
      />
      <path
        d="M43.3685 11.6568L41.5484 12.4366V3.6129H30.7097V1.80645C30.7097 0.810169 29.8995 0 28.9032 0H12.6452C11.6489 0 10.8387 0.810169 10.8387 1.80645V3.6129H0V56H41.5484V43.3032L43.3548 44.4151L46.1143 42.7171C52.212 38.9653 56 32.1854 56 25.0257V17.4863L43.3685 11.6568ZM39.7419 5.41935V13.2106L37.9355 13.9846V7.22581H30.7097V5.41935H39.7419ZM1.80645 54.1935V5.41935H10.8387V7.22581H3.6129V52.3871H37.9355V40.7528C38.5101 41.2503 39.1104 41.7182 39.7419 42.1526V54.1935H1.80645ZM54.1935 25.0257C54.1935 31.5631 50.735 37.7529 45.1666 41.1797L43.3548 42.2946L41.5431 41.1797C35.9747 37.752 32.5161 31.5622 32.5161 25.0257V18.6427L34.701 17.6336L33.9442 15.9943L30.7097 17.4863V25.0248C30.7097 30.2166 32.7093 35.2015 36.129 38.9851V50.5806H5.41935V9.03226H27.0968V7.22581H12.6452V1.80645H28.9032V9.03226H36.129V16.7251L43.3412 13.6344L54.1935 18.6427V25.0257Z"
        fill="#0D1321"
      />
    </svg>
  );
};

export default MedicalBackground;
