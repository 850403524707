import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  referred_communities: {
    backgroundColor: theme.palette.light.white,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '8px',
  },
  text_container: {
    display: 'flex',
    marginBottom: '26px',
    '& b': {
      margin: '0 4px',
    },
  },
  header_row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginBottom: '16px',
  },
  header_row_labels: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
  },
  checkbox_all: {
    width: '50%',
  },
  referral_sent_title: {
    width: '25%',
    textAlign: 'center',
  },
  family_letter_sent: {
    width: '25%',
    textAlign: 'center',
  },
  actions: {
    width: '25%',
    textAlign: 'center',
  },
  bulk_actions_container: {
    marginLeft: theme.spacing(2),
  },
  toast_error_container: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    fontSize: '20px',
    marginRight: '6px',
  },
  modal_container: {
    width: '650px',
    '& svg': {
      color: theme.palette.positive.wintergreenDream2,
      fill: theme.palette.positive.wintergreenDream2,
    },
  },
  modal_button_container: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
  modal_button: {
    marginLeft: theme.spacing(1),
  },
  modal_text: {
    marginBottom: theme.spacing(2),
  },
}));

export { useStyles };
