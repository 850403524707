import React, { useState } from 'react';
import { useStyles } from './DropdownPopover.style';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import SmartTooltip from '../../SmartTooltip/SmartTooltip';
import ChevronTop from '../../Icons/arrow/ChevronTop';
import ChevronBottom from '../../Icons/arrow/ChevronBottom';
import Button from '../../Button/Button';
import clsx from 'clsx';

const DropdownPopover = ({
  items,
  toolTipContent,
  buttonType,
  displayButton,
  clickableElement,
  buttonLabel,
  placement,
  onOpenChange,
  toolTipPlacement,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    onOpenChange(true);
    setIsOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onOpenChange(false);
    setIsOpen(false);
  };

  const selectItem = (item) => {
    item.action();
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      {clickableElement && (
        <SmartTooltip title={toolTipContent} placement={toolTipPlacement}>
          <div onClick={handleClick}>{clickableElement}</div>
        </SmartTooltip>
      )}

      {displayButton && (
        <Button
          onClick={handleClick}
          endIcon={isOpen ? <ChevronTop /> : <ChevronBottom />}
          type={buttonType}
          size={'small'}
        >
          {buttonLabel}
        </Button>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: placement,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: placement,
        }}
        classes={{
          root: classes.root,
          paper: classes.root,
        }}
      >
        <div className={classes.items_container}>
          {items.map((item, index) => (
            <div key={index}>
              <SmartTooltip title={item.tooltip}>
                {item.icon && (
                  <div className={classes.start_icon}>{item.icon}</div>
                )}
                <div
                  key={index}
                  onClick={() => selectItem(item)}
                  className={clsx(
                    item.disabled && classes.disabled_label_container,
                    classes.label_container,
                  )}
                >
                  {item.label}
                </div>
              </SmartTooltip>
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
};

DropdownPopover.propTypes = {
  children: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.element.isRequired,
      action: PropTypes.func.isRequired,
      tooltip: PropTypes.string,
      icon: PropTypes.element,
    }),
  ),
  className: PropTypes.string,
  buttonType: PropTypes.oneOf([
    'primary',
    'secondary',
    'light',
    'disabled',
    'outlined',
    'warning',
    'outlinedDisabled',
    'misc',
  ]),
  displayButton: PropTypes.bool,
  onOpenChange: PropTypes.func,
  clickableElement: PropTypes.element,
  toolTipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  buttonLabel: PropTypes.string,
  placement: PropTypes.oneOf(['left', 'right', 'center']),
  toolTipPlacement: PropTypes.oneOf(['bottom', 'top', 'left', 'right']),
};

DropdownPopover.defaultProps = {
  placement: 'center',
  displayButton: false,
  onOpenChange: () => {},
};

export default DropdownPopover;
