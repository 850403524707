import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '24px 0',
    position: 'relative',
  },
  row: {
    display: 'flex',
    flexFlow: 'row nowrap',
    flexGrow: 1,
    marginBottom: '24px',
    '& $row': {
      marginBottom: 0,
    },
    '& svg': {
      fontSize: '48px',
    },
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  row_last_child: {
    display: 'flex',
    flexFlow: 'row nowrap',
    flexGrow: 1,
    margingBottom: '0px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  label: {
    paddingRight: '8px',
    marginBottom: '4px',
  },
  icon_text: {
    padding: '8px 16px',
  },
  half_column: {
    display: 'flex',
    flexGrow: 1,
    flexBasis: '50%',
  },
  flex_grow: {
    flexGrow: 1,
  },

  change_background: {
    '&:hover': {
      backgroundColor: theme.palette.light.platinum4,
      color: theme.palette.light.platinum4,
    },
  },
  show_message: {
    paddingBottom: '16px',
  },
  flex: {
    display: 'flex',
  },
}));

export { useStyles };
