import React from 'react';
import PropTypes from 'prop-types';

const CallVoicemail = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="currentColor"
        d="M10.0004 15H13.9996C13.3719 14.1643 13 13.1256 13 12C13 9.23858 15.2386 7 18 7C20.7614 7 23 9.23858 23 12C23 14.7614 20.7614 17 18 17H6C3.23858 17 1 14.7614 1 12C1 9.23858 3.23858 7 6 7C8.76142 7 11 9.23858 11 12C11 13.1256 10.6281 14.1643 10.0004 15ZM6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15ZM21 12C21 13.6569 19.6569 15 18 15C16.3431 15 15 13.6569 15 12C15 10.3431 16.3431 9 18 9C19.6569 9 21 10.3431 21 12Z"
      />
    </svg>
  );
};

CallVoicemail.propTypes = {
  className: PropTypes.string,
};

export default CallVoicemail;
