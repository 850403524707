import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  overview_text_input_container: {
    width: '100%',
  },
  overview_description_button: {
    marginTop: '24px',
    textAlign: 'right',
  },
  overview_button: {
    marginRight: theme.spacing(3),
  },
  success_message: {
    margin: theme.spacing(1),
  },
  error_message: {
    margin: theme.spacing(1),
  },
  container: {
    '& > div:last-child': {
      padding: 0,
    },
  },
  overview_card_container: {
    '& > div:last-child': {
      padding: 0,
    },
  },
  success_error_message: {
    padding: '16px',
  },
  key_note_container: {
    padding: '16px 19px',
  },
  edit_key_note_container: {
    padding: '24px',
  },
}));

export { useStyles };
