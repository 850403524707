import { isToday } from '../../utils/dateUtils';
import { getDateWithTimezoneOffset } from '../../utils/dateFormat';

const _sortByDueDate = (tasks) => tasks.sort((a, b) => a.dueDate - b.dueDate);
const _sortByCompletedDateDescending = (tasks) =>
  tasks.sort((a, b) => b.completedAt - a.completedAt);

const _getToDoTasks = (tasks) => {
  const today = new Date(Date.now());
  const todayTimestamp = getTimestamp(today);
  today.setDate(today.getDate() + 1);
  const tomorrowTimestamp = getTimestamp(today);
  const todayTasks = tasks.filter((task) => isToday(task.dueDate)).slice(0, 5);

  const pastTasks = tasks.filter((task) => todayTimestamp > task.dueDate);
  _sortByDueDate(pastTasks);
  const orderedPastTasks = pastTasks.slice(
    pastTasks.length - 2,
    pastTasks.length,
  );

  const futureTasks = tasks.filter((task) => tomorrowTimestamp <= task.dueDate);
  _sortByDueDate(futureTasks);
  const orderedFutureTasks = futureTasks.slice(
    futureTasks.length - 2,
    futureTasks.length,
  );

  const availablePastTasksSlots = Math.min(2, 5 - todayTasks.length);
  const availablePastTasks = orderedPastTasks.slice(0, availablePastTasksSlots);
  const availableFutureTasksSlots = Math.min(
    2,
    5 - todayTasks.length - availablePastTasks.length,
  );

  const toDoTasks = [
    ...todayTasks,
    ...availablePastTasks,
    ...orderedFutureTasks.slice(0, availableFutureTasksSlots),
  ];

  _sortByDueDate(toDoTasks);

  return _toActivityData(toDoTasks);
};

const _getMoreTasks = (incompleteTasks, toDoTasks) => {
  const moreTasks = incompleteTasks.filter(
    (incompleteTask) =>
      !toDoTasks.find(
        (toDoTask) =>
          toDoTask.dueDate === incompleteTask.dueDate &&
          toDoTask.description === incompleteTask.description,
      ),
  );

  _sortByDueDate(moreTasks);

  return _toActivityData(moreTasks);
};

const _groupActionsByCompletedDate = (actions) => {
  const items = [];
  for (let i = 0; i < actions.length; i++) {
    const action = actions[i];
    const timestamp = action.completedAt
      ? getTimestamp(action.completedAt)
      : null;
    const current = items.find((item) => item.date === timestamp);
    if (!current) {
      items.push({ date: timestamp, actions: [action] });
    } else {
      current.actions.push(action);
    }
  }
  return items;
};

const _toActivityData = (actions) => {
  const types = {
    1: 'Call - Family',
    2: 'Mail',
    3: 'Fax',
    4: 'E-Mail - Family',
    5: 'Call - Property',
    6: 'E-Mail - Property',
    13: 'Home Visit',
    1505: 'Outreach',
    1507: 'Advisor Review',
    1508: 'Text-Family',
    1509: 'Text-Partner',
  };

  return actions.map((action) => {
    return {
      dueDate: action.dueDate,
      completedAt: action.completedAt,
      description: action.description,
      type: types[action.type],
      actionId: action.actionId,
    };
  });
};

const fixDueDateUTCTimezoneForActions = (actions) => {
  actions.map(
    (action) =>
      (action.dueDate = getDateWithTimezoneOffset(action.dueDate).getTime()),
  );
};

const fixDueDateUTCTimezoneForActionsWhenCompleted = (actions) => {
  actions.map((action) =>
    action.completedAt
      ? (action.completedAt = getDateWithTimezoneOffset(
          action.completedAt,
        ).getTime())
      : null,
  );
};

const getPastTasks = (actions) => {
  fixDueDateUTCTimezoneForActionsWhenCompleted(actions);
  const activityData = _toActivityData(
    actions.filter((action) => action.status === 1),
  );
  _sortByCompletedDateDescending(activityData);
  return _groupActionsByCompletedDate(activityData);
};

const getUpcomingTasks = (actions) => {
  fixDueDateUTCTimezoneForActions(actions);
  const incompleteTasks = actions.filter((action) => action.status === 0);
  const toDoTasks = _getToDoTasks(incompleteTasks);
  const moreTasks = _getMoreTasks(incompleteTasks, toDoTasks);
  return [toDoTasks, moreTasks];
};

const getTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  date.setHours(0, 0, 0, 0);
  return date.getTime();
};

export { getPastTasks, getUpcomingTasks };
