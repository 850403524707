import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './CommunityAmenities.style';
import Checkbox from 'common/Checkbox/Checkbox';

const CommunityAmenities = ({
  labels,
  filters,
  onChange,
  shouldClear,
  onClear,
  filterToRemove,
}) => {
  const classes = useStyles();
  const [communityAmenities, setCommunityAmenities] = useState(filters);
  const [
    allCommunityAmenitiesSelected,
    setAllCommunityAmenitiesSelected,
  ] = useState(false);

  useEffect(() => {
    setCommunityAmenities(filters);
  }, [filters]);

  useEffect(() => {
    if (filterToRemove) {
      const updatedFilters = communityAmenities.map((type) => {
        if (type.label === filterToRemove) {
          type.selected = false;
        }
        return type;
      });
      setCommunityAmenities(updatedFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterToRemove]);

  useEffect(() => {
    shouldClear && clearFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldClear]);

  useEffect(() => {
    const allSelected = !communityAmenities.find(
      (amenity) => !amenity.selected,
    );
    setAllCommunityAmenitiesSelected(allSelected);
  }, [communityAmenities]);

  const handleToggleAll = () => {
    setAllCommunityAmenitiesSelected(!allCommunityAmenitiesSelected);
    const newAmenities = communityAmenities.map((amenity) => ({
      ...amenity,
      ...{ selected: !allCommunityAmenitiesSelected },
    }));
    setCommunityAmenities([...newAmenities]);
    onChange(newAmenities);
  };

  const handleSelectedItem = (amenity) => {
    amenity.selected = !amenity.selected;
    setCommunityAmenities([...communityAmenities]);
    onChange(communityAmenities);
  };

  const clearFilters = () => {
    const clearRoomAmenities = communityAmenities.map((amenity) => ({
      ...amenity,
      ...{ selected: false },
    }));
    setCommunityAmenities([...clearRoomAmenities]);
    onClear();
  };

  return (
    <div className={classes.filter_container}>
      <Checkbox
        checked={allCommunityAmenitiesSelected}
        indeterminate={allCommunityAmenitiesSelected}
        className={classes.checkbox}
        label={
          allCommunityAmenitiesSelected
            ? labels.DESELECT_ALL
            : labels.SELECT_ALL
        }
        onChange={handleToggleAll}
      />
      {communityAmenities.map((amenity, key) => (
        <div key={key} className={classes.amenity}>
          <Checkbox
            key={key}
            className={classes.checkbox}
            checked={amenity.selected}
            onChange={() => handleSelectedItem(amenity)}
          />
          <div className={classes.checkbox_label}>
            {amenity.icon} {amenity.label}
          </div>
        </div>
      ))}
    </div>
  );
};

CommunityAmenities.propTypes = {
  labels: PropTypes.object,
  filters: PropTypes.array,
  filterToRemove: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  shouldClear: PropTypes.bool,
};

export default CommunityAmenities;
