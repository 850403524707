import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => ({
  date_picker_container: {
    '& .MuiTypography-body1, & .MuiTypography-body2': {
      fontFamily: 'Poppins',
    },
    '& .MuiPickersCalendarHeader-switchHeader': {
      marginBottom: theme.spacing(2),
    },
    '& .MuiPickersCalendarHeader-dayLabel': {
      color: theme.palette.dark.eerieBlack1,
      fontSize: 12,
      margin: '0 4px',
    },
    '& .MuiPickersBasePicker-pickerView': {
      padding: theme.spacing(1),
    },
    '& .MuiPickersCalendarHeader-transitionContainer': {
      '& p': {
        color: theme.palette.dark.eerieBlack1,
        fontWeight: 'bold',
        fontSize: 15,
      },
    },
    '& .MuiPickersDay-current': {
      borderBottom: `2px ${theme.palette.neutral.cosmicCobalt3} solid`,
      borderRadius: 'unset',
      '&:hover': {
        borderRadius: '50%',
        borderBottom: `2px transparent solid`,
      },
    },
    '& .MuiPickersDay-day.MuiPickersDay-current': {
      color: theme.palette.neutral.cosmicCobalt3,
      '& > span > p': {
        fontWeight: 'bold',
      },
    },
    '& .MuiPickersDay-day.MuiPickersDay-current.MuiPickersDay-daySelected': {
      '& > span > p': {
        fontWeight: 'bold',
      },
    },
    '& .MuiPickersDay-day.MuiPickersDay-daySelected': {
      borderRadius: '50%',
      backgroundColor: theme.palette.neutral.cosmicCobalt3,
      color: theme.palette.light.platinum5,
      '&:hover': {
        backgroundColor: theme.palette.neutral.cosmicCobalt3,
        color: theme.palette.light.platinum5,
      },
    },
    '& .MuiPickersDay-day': {
      color: theme.palette.dark.eerieBlack1,
      margin: '0 4px',
      '&:hover': {
        backgroundColor: theme.palette.neutral.cosmicCobalt5,
        color: theme.palette.dark.eerieBlack1,
      },
    },

    '& .Mui-disabled $arrow': {
      color: theme.palette.dark.disabled,
    },
    '& .MuiPickersDay-dayDisabled': {
      color: theme.palette.dark.disabled,
    },
    '& .MuiPickersDay-hidden': {
      opacity: 1,
      color: theme.palette.dark.disabled,
      '&:hover': {
        cursor: 'not-allowed',
      },
    },
  },
  date_picker_text_input: {
    width: '240px',
    '& svg': {
      fontSize: '24px',
      fill: theme.palette.neutral.cosmicCobalt3,
    },
    '& input': {
      color: theme.palette.dark.eerieBlack1,
    },
    '& > div': {
      border: `1px solid ${theme.palette.neutral.cosmicCobalt3}`,
    },
  },
  date_picker_text_input_with_shadow: {
    '& > div': {
      boxShadow: '0px 2px 4px rgba(13, 19, 33, 0.3)',
    },
  },
  arrow: {
    color: theme.palette.dark.eerieBlack1,
  },
  current_day_disabled: {},
}));

export { useStyles };
