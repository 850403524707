import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import CallNumbers from 'common/Icons/call/CallNumbers';
import PhoneDialpad from 'components/Call/PhoneDialpad/PhoneDialpad';
import CallButton from './CallButton';
import { useStyles } from './CallButton.style';
import { PhoneContext } from '@aplaceformom/owl-connect-client';

const KeyPadCallButton = ({ disabled, labels }) => {
  const classes = useStyles();
  const [isActive, setIsActive] = useState(false);
  const [isOpenDialpad, setIsOpenDialpad] = useState(false);
  const wrapperRef = useRef(null);
  const { sendDTMF } = useContext(PhoneContext);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpenDialpad(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const onMakeInternalCall = (phoneNumberValue) => {
    sendDTMF(phoneNumberValue);
  };

  const onClick = () => {
    setIsOpenDialpad(!isOpenDialpad);
    setIsActive(!isActive);
  };

  return (
    <div ref={wrapperRef}>
      <CallButton
        icon={<CallNumbers />}
        title={labels.KEYPAD}
        active={isActive}
        disabled={disabled}
        onClick={onClick}
      />
      {isOpenDialpad && (
        <PhoneDialpad
          className={classes.phone_dialpad_keypad}
          onMakeCall={onMakeInternalCall}
          directDial
        />
      )}
    </div>
  );
};

KeyPadCallButton.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  labels: PropTypes.object,
};

KeyPadCallButton.defaultProps = {
  disabled: false,
};

export default KeyPadCallButton;
