import { isCallAction, isEmailAction, isTextAction } from './actionTypeService';

const _getLastCallText = (lastCall) => {
  if (lastCall.type === 'Call - Property') {
    return 'Called - Property';
  }
  return `${lastCall.type.replace('Call -', 'Called')} - ${lastCall.result}`;
};

const _getLastEmailText = (action) => {
  return `${action.type.replace('E-Mail', 'Emailed')}`;
};

const _getLastText = (action) => {
  return `${action.type.replace('Text', 'Texted')}`;
};

const getText = (action) => {
  if (isCallAction(action.type)) {
    return _getLastCallText(action);
  }
  if (isEmailAction(action.type)) {
    return _getLastEmailText(action);
  }
  if (isTextAction(action.type)) {
    return _getLastText(action);
  }
  return `${action.type}`;
};

export { getText };
