import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  manage_container: {
    marginTop: theme.spacing(2),
  },

  containerXS: {
    marginTop: theme.spacing(1),
  },

  containerSM: {
    marginTop: theme.spacing(2),
  },

  itemXS: {
    flexGrow: '1',
    maxWidth: '50%',
    minWidth: '50%',

    '& > div': {
      padding: '16px 16px 8px 16px',
    },
    '& > div > div': {
      padding: '0',
    },
  },
  itemSM: {
    flexGrow: '1',
    maxWidth: '33%',
    minWidth: '33%',
    '& > div': {
      padding: '24px 24px 16px 24px',
    },
    '& > div > div': {
      padding: '0',
    },
  },
  itemLG: {
    flexGrow: '1',
    minWidth: '16%',
    '& > div': {
      padding: '24px 24px 16px 24px',
    },
    '& > div > div': {
      padding: '0',
    },
  },

  card: {
    flexGrow: '1',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0px 0px 8px rgba(13, 19, 33, 0.5)',
    },
  },

  line_title: {
    display: 'flex',
    alignItems: 'end',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '22px',
    height: '44px',
  },
  line_count: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
  },
  number: {
    fontWeight: 'bold',
    fontSize: '38px',
    lineHeight: '42px',
  },
  arrow: {
    color: theme.palette.neutral.cosmicCobalt3,
  },

  home_header: {
    marginTop: theme.spacing(3),
  },

  yellow_circle: {
    minHeight: '16px',
    minWidth: '16px',
    backgroundColor: theme.palette.miscellaneous2.fieldDrab4,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  green_circle: {
    minHeight: '16px',
    minWidth: '16px',
    backgroundColor: theme.palette.positive.wintergreenDream3,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  amber_circle: {
    minHeight: '16px',
    minWidth: '16px',
    backgroundColor: theme.palette.miscellaneous1.hotCinnamon4,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  red_circle: {
    minHeight: '16px',
    minWidth: '16px',
    backgroundColor: theme.palette.correction.smokyTopaz3,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
}));

export { useStyles };
