import axios from 'axios';
import cache from '../utils/cache';

axios.defaults.timeout = 20000;

const getToken = () => {
  return cache.get('ID_TOKEN');
};

const get = async (url) => {
  return await axios.get(url, {
    headers: _getHeaders(),
  });
};

const post = async (url, data) => {
  return await axios.post(url, data, {
    headers: _getHeaders(),
  });
};

const graphql = async (url, data) => {
  return await axios.post(url, data, {
    headers: _getHeadersGraphql(),
  });
};

const postWithoutToken = async (url, data) => {
  return await axios.post(url, data, {
    headers: {},
  });
};

const _getHeaders = () => {
  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const additionalEmail = localStorage.getItem('additional_email');
  if (additionalEmail) {
    headers['additional_email'] = additionalEmail;
  }

  const email = localStorage.getItem('email');
  if (email) {
    headers['email'] = email;
  }

  return headers;
};
const _getHeadersGraphql = () => {
  const token = getToken();
  const headers = {
    Authorization: token,
  };
  const additionalEmail = localStorage.getItem('additional_email');
  if (additionalEmail) {
    headers['additional_email'] = additionalEmail;
  }

  const email = localStorage.getItem('email');
  if (email) {
    headers['email'] = email;
  }

  return headers;
};

export default { get, post, postWithoutToken, graphql };
