import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  search_results_container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.light.platinum3,
    height: '100vh',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
  },
  results_title: {
    display: 'flex',
    marginTop: '8px',
  },
  lowercase: {
    textTransform: 'lowercase',
  },
}));

export { useStyles };
