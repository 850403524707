import React from 'react';
import PropTypes from 'prop-types';

const Sad = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 16.2978C13.3195 16.2978 14.5293 16.9402 15.2747 18L16.9106 16.8494C15.7945 15.2627 13.9776 14.2978 12 14.2978C10.0224 14.2978 8.20545 15.2627 7.08944 16.8494L8.72535 18C9.4707 16.9402 10.6805 16.2978 12 16.2978ZM14 12V10H16V12H14ZM8 10V12H10V10H8Z"
      />
    </svg>
  );
};

Sad.propTypes = {
  className: PropTypes.string,
};

export default Sad;
