import { useState, useRef, useEffect, useCallback } from 'react';
import { cloneDeep } from 'lodash';
import useGraphQLQuery from '../../hooks/useGraphQLQuery';
import useGraphQLMutation from '../../hooks/useGraphQLMutation';
import familyFileQuery from '../../components/FamilyFile/familyFileQuery';
import updateFinancialCriteriaMutation from '../../components/FamilyFile/FinancialCriteria/UpdateFinancialCriteriaMutation';
import upsertCommunityPreferenceMutation from '../../components/FamilyFile/CommunityPreferences/UpsertCommunityPreference';
import updateCareTypesMutation from '../../services/Resident/Mutations/updateCareTypesMutation';
import InitialConsultationFormService from './InitialConsultationFormService';
import toFamilyFileInformation from '../../components/FamilyFile/familyFileService';
import UpdateMedicalBackgroundMutation from '../../components/FamilyFile/MedicalBackground/UpdateMedicalBackgroundMutation';
import FamilyFileAnalyticsService from '../../components/FamilyFile/FamilyFileAnalyticsService';

/**
 * Hook that provides utilities for getting initial data, saving draft changes, and submitting the Initial Consultation
 * form.
 *
 * @param {Number} familyFileId - The identifier of the family file.
 * @param {String} section - The section that this hook is being rendered in (i.e. "family file", "dashboard", etc)
 * @param {String} screenName - The screen that this hook is being rendered in (i.e. "initial consultation", "family
 *    file", etc).
 * @returns {Object} - Object containing properties for interacting with the Initial Consultation form.
 */
const useInitialConsultationForm = ({
  familyFileId,
  section,
  screenName,
} = {}) => {
  // The form data that provides the "initial" values to each sub-form
  const [initialFormData, setInitialFormData] = useState({
    financialCriteria: undefined,
    medicalBackground: undefined,
  });

  // A copy of the initial form data. This is NOT passed to the component to prevent consumers from mutating this state.
  // This can be compared with the draftFormData in order to find what changes have actually occurred.
  const originalFormDataForComparison = useRef({});

  // A "draft" of all the changes being applied to the form. Saved as a ref rather than state to prevent unnecessary
  // renders
  const draftFormData = useRef({});

  // Internal state for determining if the form is loading. This should include gathering initial data as well as any
  // additional data transformations required by the components prior to rendering.
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const setFinancialCriteriaFormState = useCallback((financialCriteria) => {
    draftFormData.current = { ...draftFormData.current, financialCriteria };
  }, []);

  const setCommunityPreferenceState = useCallback((communityPreferences) => {
    draftFormData.current = { ...draftFormData.current, communityPreferences };
  }, []);

  const setMedicalBackgroundFormState = useCallback((medicalBackground) => {
    draftFormData.current = { ...draftFormData.current, medicalBackground };
  }, []);

  const [updateFinancialCriteria] = useGraphQLMutation(
    updateFinancialCriteriaMutation,
  );
  const [upsertCommunityPreference] = useGraphQLMutation(
    upsertCommunityPreferenceMutation,
  );
  const [updateMedicalBackground] = useGraphQLMutation(
    UpdateMedicalBackgroundMutation,
  );
  const [updateCareTypes] = useGraphQLMutation(updateCareTypesMutation);
  const initialConsultationFormService = new InitialConsultationFormService({
    updateFinancialCriteriaMutation: updateFinancialCriteria,
    upsertCommunityPreferenceMutation: upsertCommunityPreference,
    updateCareTypesMutation: updateCareTypes,
    updateMedicalBackgroundMutation: updateMedicalBackground,
  });

  const submitInitialConsultationForm = useCallback(
    ({ submitButtonIdentifier }) => {
      setIsFormSubmitting(true);

      const familyFileAnalyticsService = new FamilyFileAnalyticsService();

      Promise.all([
        familyFileAnalyticsService.submitFamilyFileUpdateAnalytics({
          familyFileId,
          section,
          screenName,
          submitButtonIdentifier,
          initialMedicalBackgroundFormModel:
            originalFormDataForComparison.current.medicalBackground,
          updatedMedicalBackgroundFormModel:
            draftFormData.current.medicalBackground,

          initialFinancialCriteriaFormModel:
            originalFormDataForComparison.current.financialCriteria,
          updatedFinancialCriteriaFormModel:
            draftFormData.current.financialCriteria,

          initialCommunityPreferencesFormModel:
            originalFormDataForComparison.current.communityPreferences,
          updatedCommunityPreferencesFormModel:
            draftFormData.current.communityPreferences,
        }),
        initialConsultationFormService.updateFinancialCriteria(
          draftFormData.current.financialCriteria,
        ),
        initialConsultationFormService.upsertCommunityPreference(
          draftFormData.current.communityPreferences,
        ),
        initialConsultationFormService.updateMedicalBackground(
          draftFormData.current.medicalBackground,
        ),
      ])
        .then((results) => {
          console.log(results);

          // Update original form to latest submission so later analytics calls are calculated based on newer changes
          // after the original submission
          originalFormDataForComparison.current = cloneDeep(
            draftFormData.current,
          );
        })
        .catch((errors) => {
          console.error(errors);
        })
        .finally(() => {
          setIsFormSubmitting(false);
        });
    },
    [
      draftFormData,
      initialConsultationFormService,
      familyFileId,
      section,
      screenName,
    ],
  );

  // Retrieve initial family file
  const { loading, error, data, refetch } = useGraphQLQuery(familyFileQuery, {
    variables: { familyFileId },
  });

  // Ensures form loading state is always in sync with the initial GraphQL query loading state, especially if a refetch
  // is performed.
  useEffect(() => {
    if (loading) {
      setIsFormLoading(true);
    }
  }, [loading]);

  // When data comes back from family file, set the initial form data
  useEffect(() => {
    if (data?.findFamilyFileById) {
      const {
        contactInformation,
        financialCriteria,
        communityPreferences,
        contacts,
        medicalBackground,
      } = toFamilyFileInformation(data.findFamilyFileById);

      const initialData = {
        contactInformation,
        financialCriteria,
        communityPreferences,
        contacts,
        medicalBackground,
      };
      setInitialFormData(initialData);
      originalFormDataForComparison.current = cloneDeep(initialData);
      draftFormData.current = cloneDeep(initialData);
      setIsFormLoading(false);
    }
  }, [data, setFinancialCriteriaFormState]);

  return {
    setFinancialCriteriaFormState,
    setCommunityPreferenceState,
    setMedicalBackgroundFormState,
    submitInitialConsultationForm,
    formData: initialFormData,
    familyFileData: data,
    isLoading: isFormLoading,
    isSubmitting: isFormSubmitting,
    error,
    refetch,
  };
};

export default useInitialConsultationForm;
