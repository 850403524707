import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import RouteIcon from '../../common/Icons/maps/Route';
import Typography from '../../common/Typography/Typography';
import { useStyles } from './HomeTable.style';
import Link from '../../common/Link/Link';
import SmartTooltip from '../../common/SmartTooltip/SmartTooltip';
import toursService from '../../services/HomeTable/toursService';

const HomeToursScheduledCell = ({ row, labels }) => {
  const classes = useStyles();
  if (row.mainTour) {
    return (
      <Fragment>
        <div className={classes.call_info}>
          {
            <div>
              <RouteIcon />
            </div>
          }
          <Typography bold>{`${row.mainTour.communityName}`}</Typography>
        </div>
        <div className={classes.tour_details_container}>
          <Typography color="eerieBlack5" className={classes.tour_date}>
            {toursService.getPrimaryTourFormattedDate(row) || labels.UNKNOWN}
          </Typography>
          {row.nextTours.length > 0 ? (
            <SmartTooltip
              className={classes.tool_tip}
              title={toursService.getNextToursInformation(row)}
              placement="bottom"
            >
              <Link className={classes.tour_link} openInNewTab={false}>
                +{row.nextTours.length} this day
              </Link>
            </SmartTooltip>
          ) : (
            <div></div>
          )}
        </div>
      </Fragment>
    );
  }
  return <Typography color="eerieBlack5">No scheduled tour</Typography>;
};

HomeToursScheduledCell.propTypes = {
  row: PropTypes.object.isRequired,
  labels: PropTypes.shape({
    UNKNOWN: PropTypes.string.isRequired,
  }).isRequired,
};

export default HomeToursScheduledCell;
