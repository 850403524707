import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './Sidebar.style';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';

const SidebarIcon = ({ tooltipText, icon, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.sidebar_button} onClick={onClick}>
      <SmartTooltip placement={'left'} title={tooltipText}>
        {icon}
      </SmartTooltip>
    </div>
  );
};

SidebarIcon.propTypes = {
  tooltipText: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func,
};

SidebarIcon.default = {
  onClick: () => {},
};

export default SidebarIcon;
