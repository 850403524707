import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import Button from 'common/Button/Button';
import CircleChecked from 'common/Icons/basic/CircleChecked';
import { useStyles } from './CommunityPreferencesEdit.style';
import TextInput from 'common/TextInput/TextInput';
import Typography from 'common/Typography/Typography';
import Checkbox from 'common/Checkbox/Checkbox';
import Container from '../../../common/Container/Container';
import ContainerItem from '../../../common/ContainerItem/ContainerItem';
import CommunityPreferenceFormModel from './CommunityPreferenceFormModel';
import useMediaQuery from '../../../hooks/useMediaQuery';

const getInitialCareTypeOptions = (labels, initialCareTypePreferenceList) => [
  {
    label: labels.CARE_TYPE_LABELS_WITH_CODE_MEMORY_CARE,
    value: 'memoryCare',
    checked: initialCareTypePreferenceList?.some((entry) => entry.code === 'Z'),
    code: 'Z',
    name: 'Memory Care',
    careTypeId: 13,
  },
  {
    label: labels.CARE_TYPE_LABELS_WITH_CODE_HOME_CARE,
    value: 'homeCare',
    checked: initialCareTypePreferenceList?.some((entry) => entry.code === 'H'),
    code: 'H',
    name: 'Home Care',
    careTypeId: 5,
  },
  {
    label: labels.CARE_TYPE_LABELS_WITH_CODE_ASSISTED_LIVING,
    value: 'assistedLiving',
    checked: initialCareTypePreferenceList?.some((entry) => entry.code === 'A'),
    code: 'A',
    name: 'Assisted Living',
    careTypeId: 1,
  },
  {
    label: labels.CARE_TYPE_LABELS_WITH_CODE_RESIDENTIAL_CARE_HOME,
    value: 'residentialCareHome',
    checked: initialCareTypePreferenceList?.some((entry) => entry.code === 'G'),
    code: 'G',
    name: 'Residential Care Home',
    careTypeId: 4,
  },
  {
    label: labels.CARE_TYPE_LABELS_WITH_CODE_RETIREMENT_COMMUNITY,
    value: 'independentLiving',
    checked: initialCareTypePreferenceList?.some((entry) => entry.code === 'R'),
    code: 'R',
    name: 'Retirement Community',
    careTypeId: 10,
  },
  {
    label: labels.CARE_TYPE_LABELS_WITH_CODE_NURSING_HOME,
    value: 'nursingHome',
    checked: initialCareTypePreferenceList?.some((entry) => entry.code === 'N'),
    code: 'N',
    name: 'Nursing Home',
    careTypeId: 7,
  },
  {
    label: labels.CARE_TYPE_LABELS_WITH_CODE_SENIOR_APARTMENTS,
    value: 'seniorApartments',
    checked: initialCareTypePreferenceList?.some((entry) => entry.code === 'P'),
    code: 'P',
    name: 'Senior Apartments',
    careTypeId: 9,
  },
  {
    label: labels.CARE_TYPE_LABELS_WITH_CODE_ADULT_DAY_SERVICES,
    value: 'adultDayServices',
    checked: initialCareTypePreferenceList?.some((entry) => entry.code === 'D'),
    code: 'D',
    name: 'Adult Day Services',
    careTypeId: 3,
  },
];

const getInitialRoomPreferenceOptions = (labels, initialRoomPreferences) => [
  {
    label: 'Studio',
    value: 'STUDIO',
    checked: initialRoomPreferences?.some((e) => e === 'STUDIO'),
  },
  {
    label: 'One bedroom',
    value: 'PRIVATE_ONE_BEDROOM',
    checked: initialRoomPreferences?.some((e) => e === 'PRIVATE_ONE_BEDROOM'),
  },
  {
    label: 'Two bedrooms',
    value: 'PRIVATE_TWO_BEDROOM',
    checked: initialRoomPreferences?.some((e) => e === 'PRIVATE_TWO_BEDROOM'),
  },
  {
    label: 'Private',
    value: 'PRIVATE',
    checked: initialRoomPreferences?.some((e) => e === 'PRIVATE'),
  },
  {
    label: 'Shared (Semi-Private)',
    value: 'SHARED',
    checked: initialRoomPreferences?.some((e) => e === 'SHARED'),
  },
];

const CommunityPreferencesEdit = ({
  communityPreferences,
  labels,
  onSave,
  onCancel,
  onChange,
  shouldShowActionControls,
  flags,
}) => {
  const classes = useStyles();

  const [careTypePreferences, setCareTypePreferences] = useState(
    getInitialCareTypeOptions(labels, communityPreferences.careTypePreference),
  );
  const [roomPreferences, setRoomPreferences] = useState(
    getInitialRoomPreferenceOptions(
      labels,
      communityPreferences.roomPreference,
    ),
  );

  const [locationRange, setLocationRange] = useState(
    communityPreferences.locationRange,
  );

  const getSelectAllText = (elements) => {
    return elements.some((item) => item.checked)
      ? labels.DESELECT_ALL
      : labels.SELECT_ALL;
  };

  const handleCareTypeChange = (event, selectedItem) => {
    const identifier = selectedItem.value;

    const updatedItems = careTypePreferences.map((preference) => ({
      ...preference,
      checked:
        preference.value === identifier
          ? !selectedItem.checked
          : preference.checked,
    }));

    setCareTypePreferences(updatedItems);
  };

  const handleCareTypeSelectAllChange = () => {
    const newValues = !careTypePreferences.some(
      (preference) => preference.checked,
    );

    const updatedPreferences = careTypePreferences.map((element) => ({
      ...element,
      checked: newValues,
    }));

    setCareTypePreferences(updatedPreferences);
  };

  const handleRoomPreferenceChange = (e, selectedItem) => {
    const updatedRoomPreferences = roomPreferences.map((entry) => {
      if (entry.value !== selectedItem.value) {
        return entry;
      }

      return {
        ...entry,
        checked: e.target.checked,
      };
    });
    setRoomPreferences(updatedRoomPreferences);
  };

  const handleRoomPreferenceSelectAllChange = (event) => {
    const value = event.target.checked;

    const newRoomPreferences = roomPreferences.map((element) => {
      element.checked = value;
      return element;
    });

    setRoomPreferences(newRoomPreferences);
    communityPreferences.roomPreference = roomPreferences
      .filter((e) => e.checked === true)
      .map((e) => e.value);
  };

  const constructFormModel = useCallback(() => {
    return new CommunityPreferenceFormModel({
      oneId: communityPreferences.oneId,
      familyFileId: communityPreferences.familyFileId,
      residentId: communityPreferences.residentId,
      careTypePreference: careTypePreferences
        .filter((entry) => entry.checked)
        .map(({ careTypeId, code, name }) => ({
          careTypeId,
          code,
          name,
        })),
      roomPreference: roomPreferences
        .filter((entry) => entry.checked)
        .map((entry) => entry.value),
      locationRange: locationRange,
    });
  }, [
    communityPreferences,
    careTypePreferences,
    roomPreferences,
    locationRange,
  ]);

  // Used to ensure the onChange does not trigger just because we have received props for the first time.
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const formModel = constructFormModel();
    onChange(formModel);
  }, [
    communityPreferences.oneId,
    communityPreferences.familyFileId,
    communityPreferences.residentId,
    locationRange,
    careTypePreferences,
    roomPreferences,
    constructFormModel,
    onChange,
  ]);

  const handleSaveClick = () => {
    const formModel = constructFormModel();
    onSave(formModel);
  };

  const actionControls = (
    <ContainerItem xs={12}>
      <div className={classes.column_button}>
        <div className={classes.button_cancel}>
          <Button type="light" onClick={onCancel}>
            {labels.CANCEL}
          </Button>
        </div>
        <div>
          <Button
            type="secondary"
            onClick={handleSaveClick}
            startIcon={<CircleChecked />}
          >
            {labels.SAVE}
          </Button>
        </div>
      </div>
    </ContainerItem>
  );
  const isSMDown = useMediaQuery((theme) => theme?.breakpoints?.down('sm'));

  // Developer note: With the IC workflow, several fields have changed appearance. When the IC workflow is fully
  // launched, this if statement can be removed and the other render path can be removed.
  if (flags.isInitialConsultationEnabled) {
    return (
      <>
        {shouldShowActionControls && actionControls}
        <Container
          className={classes.community_preferences_container}
          spacing={6}
        >
          <ContainerItem
            sm={12}
            md={6}
            className={classes.community_preferences_form_container}
          >
            <Typography
              className={classes.form_section_header}
              color="eerieBlack1"
              bold
            >
              {labels.TYPE_OF_CARE}
            </Typography>
            <Typography level="small" color="eerieBlack5">
              {labels.SELECT_PREFERRED_CARE_TYPE}
            </Typography>
            <div
              className={classes.checkbox_grid}
              data-testid="care-type-container"
            >
              <div
                className={classes.checkbox_grid_select_all_toggle_container}
              >
                <Checkbox
                  className={classes.checkbox}
                  indeterminate={careTypePreferences.some(
                    (item) => item.checked,
                  )}
                  checked={careTypePreferences.some((item) => item.checked)}
                  onChange={handleCareTypeSelectAllChange}
                  label={getSelectAllText(careTypePreferences)}
                />
              </div>
              {careTypePreferences.map((item, key) => (
                <div key={key} className={classes.checkbox_grid_entry}>
                  <Checkbox
                    checked={item.checked}
                    label={item.label}
                    onChange={(event) => handleCareTypeChange(event, item)}
                  />
                </div>
              ))}
            </div>
          </ContainerItem>
          <ContainerItem sm={12} md={6}>
            <Typography
              className={classes.form_section_header}
              color="eerieBlack1"
              bold
            >
              {labels.ROOM_TYPE}
            </Typography>
            <Typography level="small" color="eerieBlack5">
              {labels.SELECT_PREFERRED_RECOMMENDED_ROOM_TYPE}
            </Typography>
            <div
              className={classes.checkbox_grid}
              data-testid="room-preference-container"
            >
              <div
                className={classes.checkbox_grid_select_all_toggle_container}
              >
                <Checkbox
                  className={classes.checkbox}
                  indeterminate={roomPreferences.some((item) => item.checked)}
                  checked={roomPreferences.some((item) => item.checked)}
                  onChange={handleRoomPreferenceSelectAllChange}
                  label={getSelectAllText(roomPreferences)}
                />
              </div>
              {roomPreferences.map((item, key) => (
                <div key={key} className={classes.checkbox_grid_entry}>
                  <Checkbox
                    checked={item.checked}
                    label={item.label}
                    onChange={(e) => handleRoomPreferenceChange(e, item)}
                  />
                </div>
              ))}
            </div>
          </ContainerItem>
        </Container>
        {shouldShowActionControls && isSMDown && actionControls}
      </>
    );
  }

  return (
    <div className={classes.row}>
      {shouldShowActionControls && actionControls}
      <div className={classes.inputs_community_preferences}>
        <div className={classes.row_header_preference}>
          <div className={classes.column}>
            <Typography level="small">{labels.ROOM_PREFERENCE}</Typography>
          </div>
        </div>
        <div className={classes.row_preference}>
          <div className={classes.preferences_group_item}>
            <Checkbox
              className={classes.checkbox}
              indeterminate={roomPreferences.some((item) => item.checked)}
              checked={roomPreferences.some((item) => item.checked)}
              onChange={handleRoomPreferenceSelectAllChange}
              label={getSelectAllText(roomPreferences)}
            />
          </div>
          <div className={classes.preference_items}>
            {roomPreferences.map((item, key) => (
              <Checkbox
                key={key}
                className={classes.preference_items_label}
                checked={item.checked}
                label={item.label}
                onChange={(e) => handleRoomPreferenceChange(e, item)}
              />
            ))}
          </div>
        </div>
        <div className={classes.distance_input}>
          <div className={classes.column}>
            <TextInput
              label="Distance requested"
              value={locationRange.toString()}
              type="number"
              endIcon={
                <Typography color="eerieBlack5">
                  {labels.MILES_PREFERRED_LOCATION}
                </Typography>
              }
              className={classes.default_input}
              id="locationRange"
              maxLength={3}
              onChange={(value) => {
                setLocationRange(parseInt(value, 10));
              }}
            />
          </div>
        </div>
      </div>

      {shouldShowActionControls && isSMDown && actionControls}
    </div>
  );
};

CommunityPreferencesEdit.propTypes = {
  communityPreferences: PropTypes.instanceOf(CommunityPreferenceFormModel)
    .isRequired,
  labels: PropTypes.object.isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,

  /**
   * Callback function invoked when any of the preferences are modified. When invoked, an instance of
   * {@link CommunityPreferenceFormModel} with the current state of the component is provided as an argument.
   */
  onChange: PropTypes.func,

  /**
   * Boolean to determine whether or not to show the Cancel and Save buttons. Defaults to false.
   */
  shouldShowActionControls: PropTypes.bool,

  /**
   * Object containing feature flags.
   */
  flags: PropTypes.shape({
    isInitialConsultationEnabled: PropTypes.bool,
  }).isRequired,
};

CommunityPreferencesEdit.defaultProps = {
  onSave: () => {},
  onCancel: () => {},
  onChange: () => {},
  shouldShowActionControls: false,
};

export default withLDConsumer()(CommunityPreferencesEdit);
