import restClient from 'utils/restClient';

const getTextingServiceUrl = async (url, contact) => {
  try {
    const textInitiationResponse = await restClient.post(url, contact);
    return textInitiationResponse.data;
  } catch (error) {
    console.error(`Error fetching texting url ${error}`);
  }
};
export default { getTextingServiceUrl };
