import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    places_input: {
      alignItems: 'center',
      display: 'flex',
      '& > div': {
        flex: '1',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },

    add_location: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(3),
      '&:hover': {
        cursor: 'pointer',
      },
      '& svg': {
        fontSize: 16,
        color: theme.palette.neutral.cosmicCobalt3,
        verticalAlign: 'middle',
        marginLeft: 9,
      },
    },

    places_entry: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),

      border: `1px solid ${theme.palette.light.platinum1}`,
      backgroundColor: theme.palette.light.platinum5,
      '& .MuiOutlinedInput-multiline': {
        padding: 0,
      },
      borderRadius: '2px',
      '& input, textarea': {
        width: '100%',
        border: 'none',
        padding: '8px 14px',
        fontSize: '15px',
        fontFamily: 'Poppins',
        lineHeight: '22px',
        height: 'auto',
        color: theme.palette.dark.eerieBlack5,
        '&:focus': {
          outline: 'none',
        },
      },
      '&:hover': {
        border: `1px solid ${theme.palette.neutral.cosmicCobalt3}`,
        '& svg': {
          color: theme.palette.neutral.cosmicCobalt3,
          fill: theme.palette.neutral.cosmicCobalt3,
        },
      },
      '&:focus-within': {
        border: `1px solid ${theme.palette.neutral.cosmicCobalt2}`,
        '& span svg': {
          color: theme.palette.neutral.cosmicCobalt2,
          fill: theme.palette.neutral.cosmicCobalt2,
        },
        '& $label': {
          color: theme.palette.neutral.cosmicCobalt3,
        },
      },
      '& fieldset': {
        border: 'none',
        '&:hover': {
          border: 'none',
        },
      },
      '&:disabled': {
        backgroundColor: 'red', //theme.palette.light.platinum4,
      },
    },

    filled: {
      '& $places_entry': {
        border: `1px solid ${theme.palette.neutral.cosmicCobalt2}`,
      },
      '& label': {
        color: theme.palette.dark.eerieBlack1,
      },
      '& label.label_disabled': {
        color: theme.palette.dark.eerieBlack5,
      },
    },

    label: {
      fontSize: '13px',
      lineHeight: '19px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: theme.palette.dark.eerieBlack5,
      marginBottom: '4px',
      textAlign: 'left',
    },

    error: {
      '& $places_entry': {
        border: `1px solid ${theme.palette.correction.smokyTopaz2}`,
      },
      '& $startIcon svg': {
        color: theme.palette.dark.disabled,
        fill: theme.palette.dark.disabled,
      },
      '& $endIcon svg': {
        color: theme.palette.dark.disabled,
        fill: theme.palette.dark.disabled,
      },
      '& $places_entry:hover': {
        '& $startIcon svg': {
          color: theme.palette.dark.disabled,
          fill: theme.palette.dark.disabled,
        },
        '& $endIcon svg': {
          color: theme.palette.dark.disabled,
          fill: theme.palette.dark.disabled,
        },
      },
    },

    disabled: {
      '& $places_entry': {
        backgroundColor: theme.palette.light.platinum4,
        border: `1px solid ${theme.palette.light.platinum1}`,
        '&:hover': {
          border: `1px solid ${theme.palette.light.platinum1}`,
        },
        '& > input': {
          backgroundColor: theme.palette.light.platinum4,
        },
        '& $startIcon svg': {
          color: theme.palette.light.platinum1,
          fill: theme.palette.light.platinum1,
          cursor: 'auto',
        },
        '& $endIcon svg': {
          color: theme.palette.light.platinum1,
          fill: theme.palette.light.platinum1,
          cursor: 'auto',
        },
      },
    },

    startIcon: {
      marginRight: 0,
      '& + div': {
        display: 'flex',
        flex: '1',
      },
      '&.MuiInputAdornment-root': {
        paddingLeft: theme.spacing(1),
      },
    },
    endIcon: {
      marginLeft: 0,
      cursor: 'pointer',
      '&.MuiInputAdornment-root': {
        paddingRight: theme.spacing(1),
      },
    },

    menu: {
      position: 'relative',
      width: '100%',
      top: -theme.spacing(1),
    },
    menu_items_container: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.neutral.cosmicCobalt5}`,
      backgroundColor: theme.palette.light.white,
      textAlign: 'left',
      zIndex: '10000',
    },
    menu_group_item: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '10px 16px',
      '&:hover': {
        backgroundColor: theme.palette.neutral.dropdown,
      },
      '& svg': {
        fontSize: '24px',
        color: theme.palette.dark.eerieBlack1,
        marginRight: '12px',
      },
    },
    menu_group_name: {
      padding: '10px 16px',
    },
  };
});

export { useStyles };
