import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.light.platinum5,
    borderColor: theme.palette.neutral.cosmicCobalt3,
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: 'thin',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  banner_item_button: {
    margin: 'auto',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  banner_item_logo: {
    display: 'block',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  banner_item_text: {
    margin: 'auto',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  banner_search_pin_logo: {
    fontSize: '75px',
  },
  //use for caseprofileview
  side_panel_banner_item_button: {
    textAlign: 'center',
  },
  side_panel_banner_item_logo: {
    textAlign: 'center',
  },
  side_panel_banner_item_text: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  side_panel_banner_search_pin_logo: {
    fontSize: '75px',
  },
}));

export { useStyles };
