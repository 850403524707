const allRoles = {
  SLA: {
    role: 'SLA',
    home: '/dashboard',
    analytics: true,
  },
  CALLCENTER: {
    role: 'CALLCENTER',
    home: '/callcenter/home',
    analytics: true,
  },
  MANAGER: {
    role: 'MANAGER',
    home: '/dashboard',
    analytics: false,
  },
  PARTNERDEV: {
    role: 'PARTNERDEV',
    home: '/dashboard',
    analytics: false,
  },
  TRAINER: {
    role: 'TRAINER',
    home: '/dashboard',
    analytics: false,
  },
};

const roles = Object.entries(allRoles).reduce((acc, entry) => {
  acc[entry[0]] = entry[1].role;
  return acc;
}, {});

const getHomePage = (role) => allRoles[role].home;
const getRoles = () => Object.values(allRoles).map((role) => role.role);
const getAnalyticsRoles = () =>
  Object.values(allRoles)
    .filter((role) => role.analytics)
    .map((role) => role.role);

export default roles;
export { getRoles, getHomePage, getAnalyticsRoles };
