import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles(() => ({
  root: {
    padding: 32,
  },
  slug_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: 16,
  },
}));

export { useStyles };
