import React, { Fragment, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { useStyles } from './CommunityInfo.style';
import Typography from 'common/Typography/Typography';
import Pin from 'common/Icons/maps/Pin';
import CallPhone from 'common/Icons/call/CallPhone';
import Tag from 'common/Tag/Tag';
import CalendarDates from 'common/Icons/time/CalendarDates';
import ArrowForwardAll from 'common/Icons/arrow/ArrowForwardAll';
import PinCross from 'common/Icons/maps/PinCross';
import Button from 'common/Button/Button';
import ScheduledTourInfo from 'components/SavedCommunities/ScheduledTourInfo';
import labels from 'config/labels';
import { formatTourActivityDate } from 'common/utils/dateFormat';
import useGraphQLMutation from 'hooks/useGraphQLMutation';
import scheduleTourMutation from '../CommunityInfo/Mutations/scheduleTourMutation';
import updateScheduleTourMutation from '../CommunityInfo/Mutations/updateScheduleTourMutation';
import deleteTourMutation from '../CommunityInfo/Mutations/deleteTourMutation';
import {
  getDate,
  getDateTime,
  getHourAndMinutes12HourFormat,
  getTimeOffsetFromSelectedDate,
} from 'utils/dateFormat';
import { isCtAppEnabled } from 'config/ctAppCommunities';
import tourScheduleService, {
  ACTIVITY_TYPE_CODE,
  logRemoveTourTimeSelected,
} from 'services/tourScheduleService';
import TourScheduler, {
  preReferredSchedulerConfiguration,
} from 'components/Community/TourScheduler/TourScheduler';

const CommunityInfoLG = ({
  address,
  city,
  className,
  communityId,
  companyName,
  dataForAnalytics,
  disableScheduleTourForHomeCare,
  distance,
  familyFileId,
  familyFileUserId,
  flags,
  isCalendarPastDisabled,
  moveIns,
  name,
  phone,
  referred,
  state,
  tourDetail,
  tourDraft,
  zip,
}) => {
  const classes = useStyles();

  const community = useRef(null);
  const [scheduledTourOpen, setScheduledTourOpen] = useState(false);
  const [selectedTourLabel, setSelectedTourLabel] = useState('');
  const [tourInfo, setTourInfo] = useState();

  const emptyTourDetail = {
    communityId: parseInt(communityId),
    familyCommunityTourId: null,
    scheduledTourDate: null,
    tourType: '',
  };

  const [extendedTourDetail, setExtendedTourDetail] = useState(
    tourDetail || emptyTourDetail,
  );
  const [familyCommunityTourId, setFamilyCommunityTourId] = useState();

  const availableTypes = {
    IP: [labels.IN_PERSON_TOUR],
    VT: [labels.CT_APP],
    VTX: [labels.VIRTUAL_TOURS_OTHER],
  };

  const [scheduleTour] = useGraphQLMutation(scheduleTourMutation);
  const [updateScheduleTour] = useGraphQLMutation(updateScheduleTourMutation);
  const [deleteScheduledTour] = useGraphQLMutation(deleteTourMutation);

  const handleClose = () => {
    setScheduledTourOpen(false);
  };

  useEffect(() => {
    community.current = {
      address,
      city,
      id: Number(communityId),
      name,
      phone,
      state,
      postalCode: zip,
    };
  }, [address, city, communityId, name, phone, state, zip]);

  useEffect(() => {
    if (tourInfo) {
      setSelectedTourLabel(
        `${formatTourActivityDate(
          tourInfo.selectedDate,
          'MMM DD, YYYY (DDD)',
        )} @ ${tourInfo.selectedTime} - ${
          availableTypes[tourInfo.selectedType]
        }`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourInfo]);

  useEffect(() => {
    if (tourDetail) {
      setTourInfo(toTourInfo(tourDetail));
      setFamilyCommunityTourId(tourDetail.familyCommunityTourId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourDetail]);

  /**
   *
   * Tours v1 functionality.
   */
  const handleSave = ({ activityDate, selectedType }) => {
    let scheduleTourPromise;
    const timeOffset = getTimeOffsetFromSelectedDate(activityDate);

    const currentUser = JSON.parse(localStorage.getItem('CURRENT_USER'));
    const referral = {
      communityId: parseInt(communityId),
      communityName: name,
      desiredPostalCode: zip,
      tourType: selectedType,
      tourDateTime: getDateTime(activityDate),
      isTourScheduled: true,
    };

    if (familyCommunityTourId) {
      scheduleTourPromise = updateScheduleTour({
        variables: {
          familyCommunityTourId,
          activityDate,
          tourType: selectedType,
          activityTypeCode: ACTIVITY_TYPE_CODE.SCHEDULED,
          timeOffset,
          referral,
          userName: currentUser.username,
          userRole: currentUser.role,
        },
      });
    } else {
      scheduleTourPromise = scheduleTour({
        variables: {
          familyFileId,
          communityId: parseInt(communityId),
          activityDate,
          activityTypeCode: ACTIVITY_TYPE_CODE.SCHEDULED,
          tourType: selectedType,
          timeOffset,
          referral,
          userName: currentUser.username,
          userRole: currentUser.role,
        },
      });
    }

    scheduleTourPromise
      .then((response) => {
        let tourScheduled;
        if (response.data.scheduleTour) {
          tourScheduled = response.data.scheduleTour;
        } else {
          tourScheduled = response.data.updateScheduledTour;
        }

        extendedTourDetail.scheduledTourDate = activityDate;
        extendedTourDetail.tourType = tourScheduled.tourType;
        extendedTourDetail.familyCommunityTourId =
          tourScheduled.familyCommunityTourId;
        extendedTourDetail.communityId = tourScheduled.communityId;

        setExtendedTourDetail(extendedTourDetail);

        const selectedDate = formatTourActivityDate(
          tourScheduled.activityDate,
          'MMM DD, YYYY (DDD)',
        );
        const selectedTime = getHourAndMinutes12HourFormat(
          tourScheduled.activityDate,
          true,
        );
        const selectedType = tourScheduled.tourType;

        setFamilyCommunityTourId(tourScheduled.familyCommunityTourId);
        setTourInfo({
          selectedDate,
          selectedTime,
          selectedType,
        });
      })
      .catch((error) => {
        console.error(
          `There was an error scheduling tour for familyFileId ${familyFileId}`,
          error,
        );
      });

    setScheduledTourOpen(false);
  };

  const removeScheduledTour = () => {
    createRemoveTourTimeSelectedAnalyticsEvents();

    const deleteScheduledTourPromise = deleteScheduledTour({
      variables: {
        familyCommunityTourId: parseInt(familyCommunityTourId),
      },
    });

    deleteScheduledTourPromise
      .then(() => {
        setSelectedTourLabel();
        setFamilyCommunityTourId();
        setExtendedTourDetail(emptyTourDetail);
      })
      .catch((error) => {
        console.error(
          `There was an error removing scheduled tour for familyFileId ${familyFileId}`,
          error,
        );
      });
  };

  const toTourInfo = () => {
    return {
      selectedDate: formatTourActivityDate(
        tourDetail.scheduledTourDate,
        'MMM DD, YYYY (DDD)',
      ),
      selectedTime: getHourAndMinutes12HourFormat(
        tourDetail.scheduledTourDate,
        true,
      ),
      selectedType: tourDetail.tourType,
      communityId: tourDetail.communityId,
      familyCommunityTourId: tourDetail.familyCommunityTourId,
    };
  };

  const handleTourScheduleClick = () => {
    setScheduledTourOpen(true);
  };

  const getTourSchedulerConfiguration = () => {
    return preReferredSchedulerConfiguration;
  };

  const getTourAnalyticsDetails = () => {
    const selectedTourTypeText = tourScheduleService.getTourTypeLabel(
      extendedTourDetail.tourType,
    );
    const selectedTime = getHourAndMinutes12HourFormat(
      extendedTourDetail.scheduledTourDate,
      true,
    );
    const tourDateTime = `${getDate(
      extendedTourDetail.scheduledTourDate,
    )} ${selectedTime}`;

    return [selectedTourTypeText, tourDateTime];
  };

  const createRemoveTourTimeSelectedAnalyticsEvents = () => {
    const [selectedTourTypeText, tourDateTime] = getTourAnalyticsDetails();

    logRemoveTourTimeSelected(
      communityId,
      familyFileId,
      dataForAnalytics.originatingPage,
      tourDateTime,
      selectedTourTypeText,
      familyFileUserId,
    );
  };

  return (
    <>
      <div
        data-id={communityId}
        className={clsx(classes.community_info_container, className)}
      >
        <Typography bold>{name}</Typography>
        <Typography className={classes.company_name}>{companyName}</Typography>
        <div className={classes.contact_container}>
          <div className={classes.contact}>
            <Pin />
            <Typography level="small" color="eerieBlack4">
              <Fragment>
                {`${address} ${city}, ${state} ${zip}`}
                {distance && (
                  <b className={classes.miles}>{` (${distance} mi)`}</b>
                )}
              </Fragment>
            </Typography>
          </div>
          <div className={classes.contact}>
            <CallPhone />
            <Typography level="small" color="eerieBlack4">
              {phone}
            </Typography>
          </div>
          <div className={classes.tags}>
            {!isNaN(referred) && (
              <Tag
                text={'Not yet referred'}
                icon={<ArrowForwardAll />}
                color="platinum3"
              />
            )}
            {!isNaN(moveIns) && (
              <Tag text={'No move-ins'} icon={<PinCross />} color="platinum3" />
            )}
          </div>

          {!disableScheduleTourForHomeCare && (
            <>
              {flags.isTourWorkflowV2 ? (
                <TourScheduler
                  community={community.current}
                  configuration={getTourSchedulerConfiguration()}
                  dataForAnalytics={{
                    ...dataForAnalytics,
                    familyFileId,
                    familyFileUserId,
                  }}
                  familyFileId={familyFileId}
                  isCtAppEnabled={isCtAppEnabled(communityId)}
                  tourDetail={extendedTourDetail}
                  tourDraft={tourDraft}
                />
              ) : (
                <div className={classes.scheduled_tour_button_container}>
                  {selectedTourLabel && (
                    <Typography
                      level="small"
                      className={classes.selected_tour_time}
                    >
                      {labels.SELECTED_TOUR_TIME}
                    </Typography>
                  )}
                  <div className={classes.button_container}>
                    <Button
                      type="outlined"
                      onClick={handleTourScheduleClick}
                      startIcon={<CalendarDates />}
                      size="small"
                      className={clsx(
                        selectedTourLabel && classes.selected_tour_button,
                      )}
                    >
                      {selectedTourLabel
                        ? selectedTourLabel
                        : labels.SCHEDULE_TOUR}
                    </Button>
                    {selectedTourLabel && (
                      <div
                        onClick={removeScheduledTour}
                        className={classes.remove_tour_info_container}
                      >
                        <Typography
                          underlined
                          className={classes.remove_tour_time}
                          color="smokyTopaz2"
                          level="small"
                        >
                          {labels.REMOVE_TOUR_TIME}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {scheduledTourOpen && (
        <ScheduledTourInfo
          address={address}
          city={city}
          dataForAnalytics={dataForAnalytics}
          familyFileId={familyFileId}
          familyFileUserId={familyFileUserId}
          isCtAppEnabled={isCtAppEnabled(communityId)}
          isOpen={scheduledTourOpen}
          labels={labels}
          isPastDisabled={isCalendarPastDisabled}
          name={name}
          onClose={handleClose}
          onSave={handleSave}
          phone={phone}
          state={state}
          tourDetails={extendedTourDetail}
          zip={zip}
        />
      )}
    </>
  );
};

CommunityInfoLG.propTypes = {
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  className: PropTypes.string,
  communitiesFeature: PropTypes.array,
  communityId: PropTypes.number,
  companyName: PropTypes.string.isRequired,
  dataForAnalytics: PropTypes.shape({
    careTypes: PropTypes.object,
    originatingPage: PropTypes.string,
  }),
  disableScheduleTourForHomeCare: PropTypes.bool,
  distance: PropTypes.number,
  familyFileId: PropTypes.number,
  familyFileUserId: PropTypes.number,
  flags: PropTypes.shape({
    isTourWorkflowV2: PropTypes.bool,
  }),
  isCalendarPastDisabled: PropTypes.bool,
  moveIns: PropTypes.number,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  referred: PropTypes.number,
  state: PropTypes.string.isRequired,
  tourDetail: PropTypes.shape({
    communityId: PropTypes.number,
    familyCommunityTourDraftId: PropTypes.number,
    familyCommunityTourId: PropTypes.number,
    scheduledTourDate: PropTypes.number,
    tourType: PropTypes.string,
  }),
  tourDraft: PropTypes.shape({
    familyCommunityTourDraftId: PropTypes.number.isRequired,
    communityId: PropTypes.number.isRequired,
    activityDate: PropTypes.number.isRequired,
    tourType: PropTypes.string.isRequired,
  }),
  zip: PropTypes.string.isRequired,
};

CommunityInfoLG.defaultProps = {
  className: '',
  isCalendarPastDisabled: false,
  moveIns: NaN,
  referred: NaN,
};

export default withLDConsumer()(CommunityInfoLG);
