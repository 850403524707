import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import Button from 'common/Button/Button';
import List from 'common/Icons/basic/List';
import PinRound from 'common/Icons/maps/PinRound';
import Link from 'common/Link/Link';
import TableRowCard from 'common/TableCard/TableRowCard/TableRowCard';
import Tag from 'common/Tag/Tag';
import Toast from 'common/Toast/Toast';
import Typography from 'common/Typography/Typography';
import Loading from 'components/Loading/Loading';
import Map from 'components/Map/Map';
import YglLink from 'components/YglExternalId/YglLink';
import { useExternalId } from 'hooks/useExternalId';
import { useGraphQLLazyQuery } from 'hooks/useGraphQLQuery';
import { registerEvent } from 'services/Analytics';
import disclosureService from 'services/disclosureService';
import withConfig from 'utils/withConfig';
import { getZipCodeError, isZipCodeError } from '../CommunitiesService';
import CommunityImage from '../CommunityImage/CommunityImage';
import CommunityInfoXS from '../CommunityInfo/CommunityInfoXS';
import CommunityPriceRange from '../CommunityPriceRange/CommunityPriceRange';
import findCommunitiesQuery from '../findCommunitiesQuery';
import NoResults from '../NoResults/NoResults';
import SaveCommunity from '../SaveCommunity/SaveCommunity';
import { useStyles } from './CommunitiesTable.style';
import {
  getAmenities,
  getDisplayImageId,
  getPriceRanges,
  getQueryVariables,
  getRoomCharges,
  getRoomFees,
  IMAGE_SIZE,
  logCommunitySearchCommunitySaved,
  logCommunitySearchCommunityAttributeClicked,
  logCommunitySearchFiltered,
  logCommunitySearchProfileViewed,
  logCommunitySearchResultsViewed,
  logCommunitySearchSavedCommunityRemoved,
  logCommunitySearchViewed,
  logCommunitySearchViewErrorEncountered,
} from './communityService';
import CommunityAdvisory from '../CommunityAdvisory/CommunityAdvisory';
import CommunityLinks from '../CommunityLinks/CommunityLinks';
import {
  getActiveAdvisoryTypeLabels,
  getCovidVaccineInformation,
} from '../CommunityAdvisory/CommunityAdvisoryService';

const imageUrl = withConfig('IMAGE_URL');

let isInitialSearch = true;

const CommunitiesTableSM = ({
  labels,
  locationFilter,
  distanceFilter,
  familyFileId,
  familyFileUserId,
  familyFileSalesPhase,
  onSave,
  onRemove,
  onMapChange,
  savedCommunities,
  dataForSegment,
  otherFilters,
  attributeNamesFilter,
  groupNamesFilter,
  onRemoveFilters,
  onRemoveSingleFilter,
  careTypeFilter,
  budgetFilter,
  onAppError,
}) => {
  const classes = useStyles();
  const [communities, setCommunities] = useState([]);
  const [isListView, setIsListView] = useState(true);

  const [selectedCommunity, setSelectedCommunity] = useState({});
  const [communitiesFeature, setCommunitiesFeatures] = useState();
  const [openCardMapCommunity, setOpenCardMapCommunity] = useState({});
  const [isClickedInsideTheMap, setIsClickedInsideTheMap] = useState(false);
  const [saveCommunityErrorMessage, setSaveCommunityErrorMessage] = useState();
  const [showDisclosure, setShowDisclosure] = useState(false);
  const { externalId, fetchError } = useExternalId(familyFileId);

  const priorLocationFilter = useRef(locationFilter);
  const priorDistanceFilter = useRef(distanceFilter);
  const priorBudgetFilter = useRef(budgetFilter);
  const priorCareTypeFilter = useRef(careTypeFilter);
  const priorOtherFilters = useRef(otherFilters);

  const [getCommunities, { loading, error, data }] = useGraphQLLazyQuery(
    findCommunitiesQuery,
  );

  const processCommunitiesData = (data) => {
    const communitiesInState = data.findOwlCommunities.filter(
      (communities) => communities.state === locationFilter.state,
    );

    if (communitiesInState.length === 0) {
      setCommunities([]);
      return [];
    } else {
      const allCommunities = data.findOwlCommunities.map((community, key) => {
        const isSaved = !!savedCommunities.find(
          (savedCommunity) => community.id === savedCommunity.id,
        );
        return { ...community, ...{ isSaved }, rank: key + 1 };
      });
      setCommunities([...allCommunities]);
      return allCommunities;
    }
  };

  useEffect(() => {
    if (data?.findOwlCommunities) {
      const allCommunities = processCommunitiesData(data);

      const communityIds = allCommunities.map((community) => {
        return community.id;
      });

      if (isInitialSearch) {
        isInitialSearch = false;
        logCommunitySearchViewed(
          familyFileId,
          externalId,
          familyFileUserId,
          allCommunities.length,
          showDisclosure ? 'disclosure' : '',
        );
      }

      if (!isEqual(priorLocationFilter.current, locationFilter)) {
        priorLocationFilter.current = locationFilter;
        createSearchFilteredLog(allCommunities.length);
      }
      if (!isEqual(priorDistanceFilter.current, distanceFilter)) {
        priorDistanceFilter.current = distanceFilter;
        createSearchFilteredLog(allCommunities.length);
      }
      if (!isEqual(priorBudgetFilter.current, budgetFilter)) {
        priorBudgetFilter.current = budgetFilter;
        createSearchFilteredLog(allCommunities.length);
      }
      if (priorCareTypeFilter.current !== careTypeFilter) {
        priorCareTypeFilter.current = careTypeFilter;
        createSearchFilteredLog(allCommunities.length);
      }
      if (!isEqual(priorOtherFilters.current, otherFilters)) {
        priorOtherFilters.current = otherFilters;
        createSearchFilteredLog(allCommunities.length);
      }

      logCommunitySearchResultsViewed(
        familyFileId,
        familyFileUserId,
        communityIds,
        {
          location: locationFilter,
          distance: distanceFilter,
          careTypes: careTypeFilter,
          budget: budgetFilter,
          other: otherFilters,
          healthcareServices: attributeNamesFilter,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data?.findOwlCommunities) {
      processCommunitiesData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedCommunities]);

  useEffect(() => {
    const selectedFeatures = otherFilters.filter((filter) => filter.selected);
    const featuresPerCommunity = communities.map((community) => {
      const featureAvailableForCommunity = [];

      selectedFeatures.forEach((feature) => {
        if (feature.group) {
          const groupMatchingFeature = community.attributes.filter(
            (group) => group.groupName === feature.value,
          );
          if (groupMatchingFeature && groupMatchingFeature.length > 0) {
            if (
              groupMatchingFeature[0].groupAttributes.some(
                (attributes) => attributes.isAvailable,
              )
            ) {
              featureAvailableForCommunity.push(feature.label);
            }
          }
        } else {
          community.attributes.forEach((group) => {
            const groupAttributeMatchingFeature = group.groupAttributes.filter(
              (attribute) => attribute.name === feature.value,
            );
            if (
              groupAttributeMatchingFeature &&
              groupAttributeMatchingFeature.length > 0
            ) {
              if (groupAttributeMatchingFeature[0].isAvailable) {
                featureAvailableForCommunity.push(feature.label);
              }
            }
          });
        }
      });
      let communityFeaturesPair = {
        communityId: community.id,
        features: featureAvailableForCommunity,
      };
      return communityFeaturesPair;
    });
    const mapOfCommunitiesToFeatures = featuresPerCommunity.reduce(
      (accumulator, current) => {
        if (!accumulator[current.communityId]) {
          accumulator[current.communityId] = [];
        }
        accumulator[current.communityId].push(current.features);
        return accumulator;
      },
      {},
    );

    setCommunitiesFeatures(mapOfCommunitiesToFeatures);
  }, [savedCommunities, communities, otherFilters]);

  useEffect(() => {
    (async () => {
      if (disclosureService.isDisclosureState(locationFilter.state)) {
        const stateCodesWithCompletedDisclosures = await disclosureService.stateCodesWithCompletedDisclosures(
          familyFileId,
        );
        if (
          !stateCodesWithCompletedDisclosures.includes(locationFilter.state)
        ) {
          setShowDisclosure(true);
        } else {
          setShowDisclosure(false);
        }
      } else {
        setShowDisclosure(false);
      }
    })();
  }, [familyFileId, locationFilter]);

  const createSearchFilteredLog = (resultsCount) => {
    logCommunitySearchFiltered(familyFileId, familyFileUserId, resultsCount, {
      location: locationFilter,
      distance: distanceFilter,
      careTypes: careTypeFilter,
      budget: budgetFilter,
      other: otherFilters,
      healthcareServices: attributeNamesFilter,
    });
  };

  const getCorrectAttributeNames = (attributeNames) => {
    return attributeNames.flatMap((filter) => {
      return filter.isGroup ? filter.attributeNames : filter.value;
    });
  };

  useEffect(() => {
    const variables = getQueryVariables(
      locationFilter,
      distanceFilter,
      careTypeFilter,
      budgetFilter,
      getCorrectAttributeNames(attributeNamesFilter),
      groupNamesFilter.map((e) => e.value),
    );
    getCommunities({ variables });
    // eslint-disable-next-line
  }, [
    locationFilter,
    distanceFilter,
    careTypeFilter,
    budgetFilter,
    attributeNamesFilter,
    groupNamesFilter,
  ]);

  const isThereFilters = () => {
    return (
      attributeNamesFilter.map((e) => e.value).length > 0 ||
      groupNamesFilter.map((e) => e.value).length > 0
    );
  };

  const onClick = (community) => {
    setOpenCardMapCommunity(community);
    setSelectedCommunity(community);
  };

  useEffect(() => {
    if (error && !isZipCodeError(error)) {
      logCommunitySearchViewErrorEncountered(
        familyFileId,
        familyFileUserId,
        error?.message,
      );
      onAppError(error);
    }
    // eslint-disable-next-line
  }, [error]);

  const getCommunitySearchRank = (communityId) => {
    const communitySearchRank =
      communities
        .filter((community) => !community.isReferred)
        .findIndex((community) => {
          return community.id === communityId;
        }) + 1;
    return communitySearchRank;
  };

  const onSaveCommunityUpdate = (error, community) => {
    let message = null;

    if (error) {
      message = community.isSaved
        ? labels.UNABLE_TO_REMOVE_DUE_TO_ERROR
        : labels.UNABLE_TO_SAVE_DUE_TO_ERROR;
    }

    setSaveCommunityErrorMessage(message);
  };

  const onSaveCommunity = (community) => {
    const communityIds = communities.map((community) => {
      return community.id;
    });

    // Ordinal of where this community is in community list
    const communitySearchRank = getCommunitySearchRank(community.id);

    // Ordinal of community in list of saved communities
    const orderCommunitySaved = savedCommunities.length + 1;

    logCommunitySearchCommunitySaved(
      familyFileId,
      familyFileUserId,
      community.id,
      communitySearchRank,
      orderCommunitySaved,
      communityIds,
      {
        location: locationFilter,
        distance: distanceFilter,
        careTypes: careTypeFilter,
        budget: budgetFilter,
        other: otherFilters,
        healthcareServices: attributeNamesFilter,
      },
    );

    onSave(community);
  };

  const onRemoveCommunity = (community) => {
    logCommunitySearchSavedCommunityRemoved(familyFileId, familyFileUserId, [
      community.id,
    ]);

    onRemove(community);
  };

  const onClickCommunityProfile = (community) => {
    // Ordinal of where this community is in community list
    const communitySearchRank = getCommunitySearchRank(community.id);

    logCommunitySearchProfileViewed(
      familyFileId,
      familyFileUserId,
      community.id,
      communitySearchRank,
    );
  };

  const onClickAttribute = (community, attribute) => {
    // Ordinal of where this community is in community list
    const communitySearchRank = getCommunitySearchRank(community.id);

    logCommunitySearchCommunityAttributeClicked(
      familyFileId,
      familyFileUserId,
      community.id,
      communitySearchRank,
      familyFileSalesPhase,
      attribute,
    );
  };

  const loadZipCodeError = () => {
    return getZipCodeError(locationFilter?.zip, classes.error_icon);
  };

  if (loading) return <Loading />;
  if (isZipCodeError(error)) return loadZipCodeError();

  const getCommunityListView = () => {
    if (communities.length > 0) {
      return (
        <Fragment>
          {saveCommunityErrorMessage && (
            <Toast
              className={classes.save_community_error_sm_list}
              type="error"
            >
              <Typography>{saveCommunityErrorMessage}</Typography>
            </Toast>
          )}
          <div className={classes.table_container_sm_bottom}>
            {getFiltersComponent()}
            {communities.length > 0 &&
              communities.map((community, key) => (
                <TableRowCard
                  key={key}
                  className={clsx(
                    classes.communities_table_row_xs_container,
                    selectedCommunity.id === community.id &&
                      classes.selected_community,
                  )}
                  onClick={() => onClick(community)}
                >
                  <Fragment>
                    <div
                      data-id={community.id}
                      className={classes.left_container}
                    >
                      <SaveCommunity
                        labels={labels}
                        onSave={(community) => {
                          onSaveCommunity(community);
                        }}
                        onRemove={(community) => {
                          onRemoveCommunity(community);
                        }}
                        familyFileId={familyFileId}
                        community={community}
                        dataForSegment={dataForSegment}
                        locationFilter={locationFilter}
                        onUpdate={onSaveCommunityUpdate}
                      />
                      <CommunityImage
                        imageId={getDisplayImageId(
                          community.images,
                          IMAGE_SIZE.SMALL,
                        )}
                        imageUrl={imageUrl}
                        size="xs"
                        tagText={
                          community.careTypes.homeCare ? 'HOME CARE' : ''
                        }
                      />
                      <CommunityInfoXS
                        labels={labels}
                        communityId={community.id}
                        address={community.address}
                        phone={community.phoneNumber}
                        companyName={community.organizationName}
                        name={community.name}
                        city={community.city}
                        state={community.state}
                        distance={community.distance}
                        zip={community.zip}
                        referred={community.countReferred}
                        moveIns={community.countMovedIn}
                        careTypes={community.careTypes}
                        ratingAverage={community.ratingAverage}
                        numberOfReviews={community.numberOfReviews}
                        communitiesFeature={getAmenities(
                          communitiesFeature[community.id],
                          labels,
                        )}
                        vaccineInformation={getCovidVaccineInformation(
                          community.advisories,
                        )}
                        logVaccineClick={() => {
                          onClickAttribute(community, 'Covid Vaccine');
                        }}
                      />
                    </div>
                    <div>
                      <CommunityPriceRange
                        careTypeStyle="tag"
                        labels={labels}
                        prices={getPriceRanges(
                          community.careTypes,
                          community.roomCharges,
                        )}
                        roomFees={getRoomFees(community.roomFees)}
                      />
                    </div>
                    {community.advisories && community.advisories.length > 0 && (
                      <>
                        <div className={classes.break}></div>
                        <CommunityAdvisory
                          labels={labels}
                          communityName={community.name}
                          communityId={community.id}
                          openAdvisoryTypes={getActiveAdvisoryTypeLabels(
                            community.advisories,
                          )}
                          logAdvisoryClick={() => {
                            onClickAttribute(community, 'Advisory');
                          }}
                        />
                      </>
                    )}
                    <div className={classes.break}></div>
                    <div className={classes.last_container}>
                      <CommunityLinks
                        communityId={community.id}
                        careTypes={community.careTypes}
                        careTypeStyle="tag"
                        communityName={community.name}
                        onProfileClick={() => {
                          onClickCommunityProfile(community);
                        }}
                        onRatesClick={() => {
                          onClickAttribute(community, 'Detailed Rates');
                        }}
                        roomCharges={getRoomCharges(community.roomCharges)}
                        roomFees={getRoomFees(community.roomFees)}
                        labels={labels}
                      />
                    </div>
                  </Fragment>
                </TableRowCard>
              ))}
          </div>
        </Fragment>
      );
    }
    if (communities.length === 0) {
      return (
        <Fragment>
          <div className={classes.table_container_sm_empty}>
            {getFiltersComponent()}
            <NoResults labels={labels} />
          </div>
        </Fragment>
      );
    }
  };

  const getFiltersComponent = () => {
    if (isThereFilters()) {
      return (
        <div className={classes.filters_container}>
          <Typography>
            {labels.RESULTS_FILTERED_BY}
            {':'}
          </Typography>
          <Link onClick={() => onRemoveFilters()} className={classes.clear_all}>
            {labels.CLEAR_ALL}
          </Link>
          {attributeNamesFilter.map((t, k) => (
            <Tag
              onClose={() => onRemoveSingleFilter(t)}
              className={classes.tag}
              color="cosmicCobalt5"
              key={k}
              text={t.label}
            />
          ))}
          {groupNamesFilter.map((t, k) => (
            <Tag
              onClose={() => onRemoveSingleFilter(t)}
              className={classes.tag}
              color="cosmicCobalt5"
              key={k}
              text={t.label}
            />
          ))}
        </div>
      );
    }
  };

  const getCommunityDisclosureMessage = () => {
    const text = labels.COMMUNITY_DISCLOSURE_MESSAGE_1.replace(
      '{state}',
      disclosureService.getDisclosureStateName(locationFilter.state),
    );
    return (
      <>
        {text}
        <YglLink
          url="YGL_WORKING_LIST_PAGE_URL"
          externalId={externalId}
          hasError={fetchError}
          type="link"
        >
          {labels.COMMUNITY_DISCLOSURE_MESSAGE_2}
        </YglLink>
        {labels.COMMUNITY_DISCLOSURE_MESSAGE_3}
      </>
    );
  };

  return (
    <div className={classes.communities_table_sm_container}>
      <div className={classes.table_container_sm_top}>
        <div className={classes.left_view_option_container}>
          <Typography>
            Showing <b>({communities.length})</b> communities
          </Typography>
        </div>
        <div className={classes.right_view_option_container}>
          {isListView && (
            <div>
              <Button size="small" startIcon={<List />}>
                {labels.LIST_VIEW}
              </Button>
              <Button
                type="outlined"
                size="small"
                startIcon={<PinRound />}
                onClick={() => {
                  registerEvent(
                    'CommunitySearchPage',
                    'Selected Map View',
                    null,
                    true,
                  );
                  setIsListView(false);
                }}
              >
                {labels.MAP_VIEW}
              </Button>
            </div>
          )}
          {!isListView && (
            <div>
              <Button
                type="outlined"
                size="small"
                startIcon={<List />}
                onClick={() => {
                  registerEvent(
                    'CommunitySearchPage',
                    'Selected List View',
                    null,
                    true,
                  );
                  setIsListView(true);
                }}
              >
                {labels.LIST_VIEW}
              </Button>
              <Button startIcon={<PinRound />} size="small">
                {labels.MAP_VIEW}
              </Button>
            </div>
          )}
        </div>
      </div>

      {showDisclosure && (
        <div className={classes.table_container_sm_message}>
          <Toast className={classes.disclosure_message} type="error">
            <Typography>{getCommunityDisclosureMessage()}</Typography>{' '}
          </Toast>
        </div>
      )}

      {isListView && getCommunityListView()}
      {!isListView && (
        <div className={classes.map_container_sm}>
          {saveCommunityErrorMessage && (
            <Toast className={classes.save_community_error} type="error">
              <Typography>{saveCommunityErrorMessage}</Typography>
            </Toast>
          )}
          {getFiltersComponent()}
          <ClickAwayListener
            onClickAway={() => {
              setIsClickedInsideTheMap(false);
            }}
          >
            <>
              <Map
                address={locationFilter.address}
                communities={communities}
                labels={labels}
                onCommunitySelected={(community) => {
                  setSelectedCommunity(community);
                }}
                onClickInside={() => {
                  setIsClickedInsideTheMap(true);
                }}
                mapSelectedCommunity={selectedCommunity}
                mapOpenCardCommunity={openCardMapCommunity}
                onSave={(community) => {
                  onSaveCommunity(community);
                }}
                onRemove={(community) => {
                  onRemoveCommunity(community);
                }}
                onProfileClick={(community) => {
                  onClickCommunityProfile(community);
                }}
                onRatesClick={(community) => {
                  onClickAttribute(community, 'Detailed Rates');
                }}
                onMapChange={onMapChange}
                familyFileId={familyFileId}
                isClickedInsideTheMap={isClickedInsideTheMap}
                dataForSegment={dataForSegment}
                locationFilter={locationFilter}
                onSaveCommunityUpdate={onSaveCommunityUpdate}
                communitiesFeature={communitiesFeature}
                isSmallMap={false}
              />
            </>
          </ClickAwayListener>
        </div>
      )}
    </div>
  );
};

CommunitiesTableSM.propTypes = {
  locationFilter: PropTypes.object,
  distanceFilter: PropTypes.string,
  labels: PropTypes.object.isRequired,
  familyFileId: PropTypes.number,
  familyFileUserId: PropTypes.number,
  familyFileSalesPhase: PropTypes.string,
  savedCommunities: PropTypes.array,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
  onMapChange: PropTypes.func,
  dataForSegment: PropTypes.shape({
    familyFileId: PropTypes.number,
    oneId: PropTypes.string,
  }),
  otherFilters: PropTypes.array,
  attributeNamesFilter: PropTypes.array,
  groupNamesFilter: PropTypes.array,
  onRemoveFilters: PropTypes.func,
  onRemoveSingleFilter: PropTypes.func,
  careTypeFilter: PropTypes.array,
  budgetFilter: PropTypes.object,
  onAppError: PropTypes.func,
};

CommunitiesTableSM.defaultProps = {
  onAppError: () => {},
};

export default withLDConsumer()(CommunitiesTableSM);
