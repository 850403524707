import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  relationship_to_resident: {
    width: theme.spacing(28),
    height: 63,
    '& > .star': {
      marginTop: 0,
      marginLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  standard_dropdown: {
    width: theme.spacing(24),
    height: 63,
    '& > .star': {
      marginTop: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '48%',
      marginTop: 8,
    },
  },
  address_input_container: {
    display: 'block',
    marginTop: '16px',
    padding: '8px 24px 0 0',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 0,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      '& > div': {
        flexGrow: 1,
        marginTop: 8,
      },
    },
  },
  address_input: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '48%',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  additional_information: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  phone_input: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  email_input: {
    width: theme.spacing(32),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: '48%',
      marginTop: theme.spacing(1),
    },
  },
  contact_preference: {
    marginTop: 24,
  },
  checkboxes_container: {
    marginTop: 16,
    '& > label': {
      flexGrow: 1,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  contact_information_container: {
    padding: '8px 24px 24px 0',
    justifyContent: 'space-between',
  },
  container_with_error: {
    paddingBottom: '32px',
  },
  column_address: {
    flexGrow: 2,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  column_contact: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  default_input: {
    width: theme.spacing(23),
    marginRight: theme.spacing(3),
    height: 63,
    [theme.breakpoints.down('sm')]: {
      width: '48%',
      marginTop: theme.spacing(1),
      marginRight: 0,
    },
  },
  names_container: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      width: '100%',
      '& > div': {
        width: '100%',
        marginTop: theme.spacing(1),
      },
      '& > div.error': {
        marginBottom: theme.spacing(3),
      },
    },
  },
  default_name_input: {
    width: theme.spacing(23),
    marginRight: theme.spacing(3),
    height: 63,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  error_message: {
    padding: '0px 24px 16px 24px',
  },
  divider: {
    marginBottom: 0,
  },

  city_state_container: {
    display: 'flex',
    marginTop: '16px',
    padding: '8px 24px 0 0',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      marginTop: 0,
    },
  },
  address2: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  phones_container: {
    display: 'block',
    marginTop: '16px',
    padding: '8px 24px 0 0',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 0,
    },
  },
  email_container: {
    marginTop: 0,
    paddingTop: 0,
  },
}));

export { useStyles };
