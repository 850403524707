import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './CallButton.style';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import clsx from 'clsx';

const CallButton = ({ icon, title, active, disabled, onClick }) => {
  const classes = useStyles();

  const getContent = () => {
    if (disabled) return icon;
    return (
      <SmartTooltip title={title} placement="bottom">
        {icon}
      </SmartTooltip>
    );
  };

  return (
    <div
      className={clsx(
        classes.call_button_container,
        active && classes.active,
        disabled && classes.disabled,
      )}
      data-testid="call-button-icon"
      onClick={() => !disabled && onClick()}
    >
      {getContent()}
    </div>
  );
};

CallButton.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

CallButton.defaultProps = {
  disabled: false,
  active: false,
};

export default CallButton;
