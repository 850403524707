import React from 'react';
import PropTypes from 'prop-types';

const Smoking = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M4.16608 11.5072C2.85655 10.9119 1.30894 11.5072 0.713698 12.8167C0.11846 14.1262 0.713698 15.6738 2.02322 16.2691L22.0232 25.0786L24.1661 20.3167L4.16608 11.5072Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M6.90467 12.6978L4.88086 17.4597"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M21.9037 19.3643L19.8799 24.1262"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M24.4048 6.03101C24.4048 7.22148 22.5 7.69767 22.5 8.53101C22.5 9.36434 24.4048 10.0786 24.4048 11.1501C24.4048 12.2215 22.5 12.6977 22.5 13.6501C22.5 14.6024 24.4048 14.9596 24.4048 16.3881"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.9526 1.26904C20.9526 2.45952 19.0479 2.93571 19.0479 3.76904C19.0479 4.60238 20.9526 5.31666 20.9526 6.38809C20.9526 7.45952 19.0479 8.05476 19.0479 9.00714C19.0479 9.95952 20.9526 10.3167 20.9526 11.7452"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0 0.673828)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

Smoking.propTypes = {
  className: PropTypes.string,
};

export default Smoking;
