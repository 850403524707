import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => ({
  name: {
    color: '#000',
    fontWeight: 'bold',
  },
  value: {
    color: '#707070',
    marginLeft: 4,
  },
  double: {
    gridColumnStart: 'span 2',
  },
}));

export { useStyles };
