const getKeyFromValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

const getBooleanFromUrl = (location, name) => {
  const params = new URLSearchParams(location.search);
  const paramValue = params.get(name);
  let value = null;

  try {
    value = JSON.parse(paramValue);
  } catch (error) {
    value = false;
    console.error(
      `utils. JSON.parse failed. Error: ${error}. Data: ${paramValue}.`,
    );
  }
  return value;
};

const getCurrentUserFromSessionStorage = () => {
  try {
    const currentUser = JSON.parse(localStorage.getItem('CURRENT_USER'));
    return currentUser;
  } catch (error) {
    console.error(
      `Analytics. JSON.parse failed. Error: ${error}. Data: ${localStorage.getItem(
        'CURRENT_USER',
      )}`,
    );
    return {};
  }
};

const getFamilyFileIdFromUrl = (location) => {
  const pathname = location.pathname;
  const paths = pathname.split('/');
  return Number(paths[paths.length - 1]);
};

const _parseToYesOrNo = (bool) => {
  if (bool === true) {
    return 'Yes';
  }
  if (bool === false) {
    return 'No';
  }
};

const _parseToBoolean = (value) => {
  if (value === 'Yes') {
    return true;
  }
  if (value === 'No') {
    return false;
  }
  if (value === 'Select from list') {
    return null;
  }
};

export {
  getKeyFromValue,
  getBooleanFromUrl,
  getCurrentUserFromSessionStorage,
  getFamilyFileIdFromUrl,
  _parseToYesOrNo,
  _parseToBoolean,
};
