import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    tooltip: {
      backgroundColor: `${theme.palette.dark.eerieBlack1} 80%`,
      color: theme.palette.light.platinum5,
      borderRadius: '4px',
      fontSize: '12px',
      padding: '8px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
    },
    arrow: {
      color: `${theme.palette.dark.eerieBlack1} 80%`,
    },
    tooltip_container: {
      display: 'flex',
      marginRight: '0px',
      alignSelf: 'center',
      cursor: (cursor) => {
        if (cursor === 'not-allowed') {
          return `url(/images/cursors/${cursor}.png), auto`;
        }
        return cursor;
      },
    },
  };
});

export { useStyles };
