import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  top_bar: {
    height: '72px',
    backgroundColor: theme.palette.neutral.cosmicCobalt1,
    width: '100%',
    boxShadow: '0px 0px 8px rgba(13, 19, 33, 0.5)',
    zIndex: 1400,
    boxSizing: 'border-box',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: '1fr auto 1fr',
  },
  family_file_button: {
    gridColumnStart: 2,
  },
  content: {
    display: 'flex',
    '& .MuiGrid-container': {
      margin: 0,
    },
  },
  expandable: {
    marginBottom: theme.spacing(3),
  },
  boxes: {
    flexGrow: 1,
  },
  expandables: {
    display: 'flex',
    height: 'fit-content',
    flexDirection: 'column',
    '&.MuiGrid-grid-xs-12': {
      padding: theme.spacing(4.5),
    },
    '&.MuiGrid-grid-xs-9': {
      padding: theme.spacing(3),
    },
  },
  financial_criteria_container: {
    padding: theme.spacing(3),
  },
  notes_panel: {
    height: 'calc(100vh - 72px)',
    overflow: 'auto',
    boxShadow: '0px 0px 8px rgb(13 19 33 / 50%)',
    '&.MuiGrid-grid-xs-3': {
      padding: 0,
    },
  },
  user: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: theme.spacing(2, 3),
    cursor: 'pointer',
    borderLeft: `1px solid ${theme.palette.neutral.cosmicCobalt2}`,
    '& img': {
      marginRight: theme.spacing(2),
      borderRadius: '50%',
      height: theme.spacing(5),
      width: theme.spacing(5),
    },
    '& svg': {
      color: theme.palette.light.platinum5,
      marginLeft: theme.spacing(1.5),
      fontSize: theme.spacing(2),
    },
  },
  user_menu: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: theme.spacing(9),
    left: 0,
    right: 0,
    backgroundColor: theme.palette.neutral.cosmicCobalt1,
    zIndex: '1300',
  },
  user_menu_item: {
    cursor: 'pointer',
    padding: theme.spacing(2, 3),
    marginTop: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.neutral.cosmicCobalt3,
    },
  },
  version_info: {
    '& span': {
      color: theme.palette.neutral.cosmicCobalt4,
      fontSize: '11px',
    },
    textAlign: 'center',
  },
  formControlsBar: {
    position: 'sticky',
    top: '5rem',
    display: 'flex',
    marginBottom: '24px',
    zIndex: 1000,
  },
  formControls: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
  },
  subform_container: {
    width: '100%',
  },
  care_and_preferences_container: {
    padding: theme.spacing(2),
  },
}));

export { useStyles };
