import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    circle_minus: {
      cursor: 'pointer',
    },
    chiclet_container: {
      position: 'relative',
      minWidth: '330px',
      '&.disabled': {
        overflow: 'hidden',
      },
    },
    chiclet_container_shadow: {
      boxShadow: '0px 0px 8px rgba(186, 186, 184, 0.3)',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'Poppins',
      backgroundColor: theme.palette.light.platinum5,
      borderRadius: '4px',
      height: 'fit-content',
      width: '100%',
    },
    optional_head: {
      fontWeight: 'bold',
      fontSize: '13px',
      lineHeight: '19px',
      backgroundColor: theme.palette.neutral.cosmicCobalt5,
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '4px 4px 0 0',
      '& div': {
        paddingLeft: '8px',
      },
      '& svg': {
        fontSize: '22px',
      },
    },
    head: {
      fontWeight: 'bold',
      fontSize: '13px',
      lineHeight: '19px',
      borderBottom: `1px solid ${theme.palette.light.platinum1}`,
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '4px 4px 0 0',
      '& div': {
        paddingLeft: '8px',
      },
      '& svg': {
        fontSize: '22px',
      },
    },
    shown: {
      display: 'flex',
      opacity: 1,
    },
    hidden: {
      display: 'none',
      opacity: 0,
    },
    current: {
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.4)',
      border: `1px solid ${theme.palette.neutral.cosmicCobalt2}`,
      '& $head': {
        borderBottom: `2px solid ${theme.palette.neutral.cosmicCobalt3}`,
        '& svg': {
          color: theme.palette.neutral.cosmicCobalt3,
        },
      },
    },
    error: {
      border: `1px solid ${theme.palette.correction.smokyTopaz2}`,
      '& $head': {
        '& svg': {
          color: theme.palette.correction.smokyTopaz2,
        },
      },
    },
    current_with_error: {
      boxShadow: '0px 0px 8px rgba(13, 19, 33, 0.5);',
      border: `1px solid ${theme.palette.correction.smokyTopaz2}`,
      '& $head': {
        borderBottom: `2px solid ${theme.palette.correction.smokyTopaz2}`,
        '& svg': {
          color: theme.palette.correction.smokyTopaz2,
        },
      },
    },
    inactive: {
      '& $head': {
        '& svg': {
          color: theme.palette.dark.eerieBlack5,
        },
      },
      '& $body': {
        color: theme.palette.dark.disabled,
      },
    },
    answered: {
      '& $head': {
        '& svg': {
          color: theme.palette.neutral.cosmicCobalt3,
        },
      },
    },
    not_current: {
      '& $head': {
        '& svg': {
          color: theme.palette.neutral.cosmicCobalt3,
        },
      },
    },
    body: {
      padding: '24px',
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      boxSizing: 'border-box',
      height: '332px',
      '& p:first-child': {
        marginTop: 0,
      },
    },
    chiclet_body: {
      width: '100%',
      height: '100%',
      '& > div > p': {
        marginTop: 'auto',
        marginBottom: '0.75rem',
      },
    },

    disabled: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: '#ffffffaa',
      zIndex: 2,
      borderRadius: '4px',
    },
  };
});
export { useStyles };
