import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './TabPanel.style';

const TabPanel = ({ index, value, children }) => {
  const classes = useStyles();
  return (
    <div>
      {index === value && (
        <div className={classes.tab_panel_container}>{children}</div>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default TabPanel;
