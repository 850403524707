const isSameDay = (date1, date2) => {
  return (
    isSameYear(date1, date2) &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

const isYesterday = (date1, date2) => {
  const dayDifference = Math.abs(
    _calculateDayOfTheYear(date1) - _calculateDayOfTheYear(date2),
  );
  return isSameYear(date1, date2) && dayDifference === 1;
};

const isSameYear = (date1, date2) => {
  return date1.getYear() === date2.getYear();
};

const isToday = (timestamp) => {
  const now = new Date(Date.now());
  const startDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    0,
    0,
    0,
  ).getTime();
  const endDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    23,
    59,
    59,
  ).getTime();

  return startDate <= timestamp && timestamp <= endDate;
};

const getRemainingDaysForCurrentMonth = () => {
  const today = new Date(Date.now());
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const remainingDays = lastDayOfMonth.getDate() - today.getDate();

  if (remainingDays === 1) {
    return remainingDays + ' day';
  }

  return remainingDays + ' days';
};

const getYearsAgoInDays = (yearsAgo) => {
  const today = new Date(Date.now());
  const priorDate = new Date(
    today.getFullYear() - yearsAgo,
    today.getMonth(),
    today.getDate(),
  );
  const timeDifference = today.getTime() - priorDate.getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  const daysDifference = Math.floor(timeDifference / oneDay);
  return daysDifference;
};

const _calculateDayOfTheYear = (date) => {
  const start = new Date(date.getFullYear(), 0, 0);
  const diff =
    date -
    start +
    (start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000;
  const oneDay = 1000 * 60 * 60 * 24;
  return Math.floor(diff / oneDay);
};

export {
  getRemainingDaysForCurrentMonth,
  getYearsAgoInDays,
  isSameDay,
  isSameYear,
  isToday,
  isYesterday,
};
