import gql from 'graphql-tag';

export default gql`
  mutation($scriptId: Int!) {
    deleteScript(input: { scriptId: $scriptId }) {
      scriptId
      status
    }
  }
`;
