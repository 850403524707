import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  screening_notes_container: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    backgroundColor: theme.palette.neutral.dropdown,
    padding: '13px 19px',
  },
  notes_container: {
    padding: '16px 24px',
  },
}));

export { useStyles };
