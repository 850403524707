import React from 'react';
import PropTypes from 'prop-types';

const Laptop = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M19 4H5C3.89543 4 3 4.89543 3 6V15H1V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V15H21V6C21 4.89543 20.1046 4 19 4ZM19 15H5V6H19V15ZM3 18V17H21V18H3Z"
      />
    </svg>
  );
};

Laptop.propTypes = {
  className: PropTypes.string,
};

export default Laptop;
