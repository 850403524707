import React from 'react';
import { useStyles } from './ScreenNotSupported.style';
import Question from 'common/Graphics/other/Question';
import Typography from 'common/Typography/Typography';
import PropTypes from 'prop-types';

const ScreenNotSupported = ({ labels }) => {
  const classes = useStyles();
  return (
    <div className={classes.not_supported_msg_container}>
      <Question />
      <Typography className={classes.title} level="large" bold>
        {labels.SCREEN_NOT_SUPPORTED}
      </Typography>
      <Typography className={classes.text}>
        {labels.PLEASE_ADJUST_SCREEN_SIZE}
      </Typography>
    </div>
  );
};

ScreenNotSupported.propTypes = {
  labels: PropTypes.object.isRequired,
};

export default ScreenNotSupported;
