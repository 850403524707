import * as Sentry from '@sentry/react';

/**
 * Custom Sentry logging method that captures an error and an object which will appear under the "Additional Data" field
 * @param {Error} err - An Error object usually containing a message.
 * @param {Object} additionalData - A generic object that can be insightful to log.
 */
export function sentryExceptionWithData(err, additionalData) {
  Sentry.withScope((scope) => {
    scope.setExtras(additionalData);
    Sentry.captureException(err);
  });
}
