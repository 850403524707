import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useStyles } from './financialCriteriaEdit.style';
import Button from 'common/Button/Button';
import Typography from 'common/Typography/Typography';
import Dropdown from 'common/Dropdown/Dropdown';
import CircleChecked from 'common/Icons/basic/CircleChecked';
import Radio from 'common/Radio/Radio';
import Checkbox from 'common/Checkbox/Checkbox';
import Link from 'common/Link/Link';
import FinancialCriteriaErrorMessage from './FinancialCriteriaErrorMessage';
import FinancialCriteriaFormModel from './FinancialCriteriaFormModel';
import Toast from '../../../common/Toast/Toast';
import { _parseToYesOrNo, _parseToBoolean } from 'utils/utils';
import { inSendReferralPage } from 'services/familyFileService';
import { useLocation } from 'react-router-dom';

const SELECT_FROM_LIST_VALUE = 'Select from list';
const convertSelectFromListToNull = (value) =>
  value === SELECT_FROM_LIST_VALUE ? null : value;

const veteranStatusOptions = {
  NO: 'No',
  YES: 'Yes',
};

const medicaidOptions = {
  CURRENTLY_APPLYING: 'Currently Applying',
  NO: 'No',
  YES: 'Yes',
};

// Developer Note: Ordering of these items is crucial, as the old, non-IC-related UI relies on indexes to pull these
// values. Once the full IC workflow is released, this developer note can be removed, as the new path just accepts the
// list without accessing an individual index.
const getFinancialRangeItems = (labels) => [
  {
    label: labels.BUDGET_RANGE,
    value: 'Specific Financial Range',
  },
  {
    label: labels.UNDECIDED_BUDGET,
    value: 'Family Undecided',
  },
];

const getSpecificBudgetRangeItems = (labels) => [
  { label: labels.BUDGET_RANGE_DESCRIPTOR_1, value: '$10000 and over' },
  { label: labels.BUDGET_RANGE_DESCRIPTOR_2, value: '$9000-$9999' },
  { label: labels.BUDGET_RANGE_DESCRIPTOR_3, value: '$8000-$8999' },
  { label: labels.BUDGET_RANGE_DESCRIPTOR_4, value: '$7000-$7999' },
  { label: labels.BUDGET_RANGE_DESCRIPTOR_5, value: '$6000-$6999' },
  { label: labels.BUDGET_RANGE_DESCRIPTOR_6, value: '$5000-$5999' },
  { label: labels.BUDGET_RANGE_DESCRIPTOR_7, value: '$4000-$4999' },
  { label: labels.BUDGET_RANGE_DESCRIPTOR_8, value: '$3000-$3999' },
  { label: labels.BUDGET_RANGE_DESCRIPTOR_9, value: '$2500-$2999' },
  { label: labels.BUDGET_RANGE_DESCRIPTOR_10, value: '$2000-$2499' },
  { label: labels.BUDGET_RANGE_DESCRIPTOR_11, value: '$1500-$1999' },
  { label: labels.BUDGET_RANGE_DESCRIPTOR_12, value: '$1000-$1499' },
  { label: labels.BUDGET_RANGE_DESCRIPTOR_13, value: '$500-$999' },
  { label: labels.BUDGET_RANGE_DESCRIPTOR_14, value: 'Under $500' },
];

const getVeteranStatusItems = (labels) => [
  { label: labels.YES, value: 'Yes' },
  { label: labels.NO, value: 'No' },
];

const getAidOrAttendanceItems = (labels) => [
  { label: labels.SELECT_FROM_LIST, value: SELECT_FROM_LIST_VALUE },
  { label: labels.NOT_A_CANDIDATE, value: 'Not a candidate' },
  { label: labels.POTENTIAL_CANDIDATE, value: 'Potential candidate' },
  { label: labels.CURRENT_RECIPIENT, value: 'Current recipient' },
];

const getInsurancePolicyItems = (labels) => [
  { label: labels.SELECT_FROM_LIST, value: SELECT_FROM_LIST_VALUE },
  { label: labels.NO_POLICY, value: 'No policy' },
  { label: labels.HAS_POLICY, value: 'Has policy' },
];

const getFinancialResourcesItems = (labels, financialCriteriaData) => [
  {
    label: labels.FINANCIAL_RESOURCE_401K,
    value: '401K',
    checked: financialCriteriaData.financialResources.some((e) => e === '401K'),
  },
  {
    label: labels.FINANCIAL_RESOURCE_MONTHLY_INCOME,
    value: 'Monthly income',
    checked: financialCriteriaData.financialResources.some(
      (e) => e === 'Monthly income',
    ),
  },

  {
    label: labels.FINANCIAL_RESOURCE_COMPANION_ROOM_INTEREST,
    value: 'Companion room interest',
    checked: financialCriteriaData.financialResources.some(
      (e) => e === 'Companion room interest',
    ),
  },
  {
    label: labels.FINANCIAL_RESOURCE_OTHER_ASSETS,
    value: 'Other assets',
    checked: financialCriteriaData.financialResources.some(
      (e) => e === 'Other assets',
    ),
  },
  {
    label: labels.FINANCIAL_RESOURCE_FAMILY_WILLING_TO_CONTRIBUTE,
    value: 'Family willing to contribute',
    checked: financialCriteriaData.financialResources.some(
      (e) => e === 'Family willing to contribute',
    ),
  },
  {
    label: labels.FINANCIAL_RESOURCE_PRIVATE_INSURANCE,
    value: 'Private insurance (BC, BS, Aetna, etc.)',
    checked: financialCriteriaData.financialResources.some(
      (e) => e === 'Private insurance (BC, BS, Aetna, etc.)',
    ),
  },
  {
    label: labels.FINANCIAL_RESOURCE_HOME_TO_SELL,
    value: 'Home to sell',
    checked: financialCriteriaData.financialResources.some(
      (e) => e === 'Home to sell',
    ),
  },
  {
    label: labels.FINANCIAL_RESOURCE_SAVINGS,
    value: 'Savings',
    checked: financialCriteriaData.financialResources.some(
      (e) => e === 'Savings',
    ),
  },
  {
    label: labels.FINANCIAL_RESOURCE_STOCKS_BONDS_OR_CDS,
    value: `Stocks/Bonds/or CD’s`,
    checked: financialCriteriaData.financialResources.some(
      (e) => e === `Stocks/Bonds/or CD’s`,
    ),
  },
];

const getMedicaidItems = (labels) => [
  { label: labels.YES, value: 'YES' },
  { label: labels.NO, value: 'NO' },
  { label: labels.CURRENTLY_APPLYING, value: 'CURRENTLY_APPLYING' },
];

const FinancialCriteriaEdit = ({
  labels,
  financialCriteriaData,
  onSave,
  onCancel,
  enableReferralAlertValidation,
  shouldShowActionControls,
  onChange,
  flags,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const [selectedFinancialRange, setSelectedFinancialRange] = useState({
    value:
      financialCriteriaData.estimatedBudgetRange === 'Family Undecided'
        ? financialCriteriaData.estimatedBudgetRange
        : 'Specific Financial Range',
  });

  const [showBudgetRange, setShowBudgetRange] = useState(
    selectedFinancialRange.value !== '' &&
      selectedFinancialRange.value !== 'Family Undecided',
  );
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showSuccessOrErrorField, setShowSuccessOrFailureField] = useState(
    false,
  );
  const [
    hasVaAidDescriptionBeenClosed,
    setHasVaAidDescriptionBeenClosed,
  ] = useState(false);

  const [selectedVaAidStatus, setSelectedVaAidStatus] = useState(
    financialCriteriaData.vaAid,
  );

  const [
    selectedInsurancePolicyItem,
    setSelectedInsurancePolicyItem,
  ] = useState(financialCriteriaData.hasLongTermCareInsurancePolicy || '');

  const financialRangeItems = getFinancialRangeItems(labels);
  const specificBudgetRangeItems = getSpecificBudgetRangeItems(labels);
  const veteranStatusItems = getVeteranStatusItems(labels);
  const aidOrAttendanceItems = getAidOrAttendanceItems(labels);
  const financialResourcesItems = getFinancialResourcesItems(
    labels,
    financialCriteriaData,
  );
  let insurancePolicyItems = getInsurancePolicyItems(labels);
  const medicaidItems = getMedicaidItems(labels);

  // Developer note: Can remove this when the IC workflow is fully launched; With the new IC workflow, the medicaid and
  // insurance policy inputs are sets of radio buttons instead of a dropdown
  if (!flags.isInitialConsultationEnabled) {
    const selectFromListOption = {
      label: labels.SELECT_FROM_LIST,
      value: SELECT_FROM_LIST_VALUE,
    };
    veteranStatusItems.unshift(selectFromListOption);
    medicaidItems.unshift(selectFromListOption);
    insurancePolicyItems = insurancePolicyItems.filter(
      (entry) => entry.value !== SELECT_FROM_LIST_VALUE,
    );
  }

  const [financialResources, setFinancialResources] = useState(
    financialResourcesItems,
  );

  const [selectedVeteranItem, setSelectedVeteranItem] = useState(
    _parseToYesOrNo(financialCriteriaData.isVeteran) || SELECT_FROM_LIST_VALUE,
  );

  // Resets the VA Aid toast to show again
  useEffect(() => {
    if (selectedVeteranItem === veteranStatusOptions.YES) {
      setHasVaAidDescriptionBeenClosed(false);
    }
  }, [selectedVeteranItem]);

  const [selectedMedicaidItem, setSelectedMedicaidItem] = useState(
    financialCriteriaData.medicaid || SELECT_FROM_LIST_VALUE,
  );

  const selectedAidOrAttendanceItem =
    financialCriteriaData.vaAid || SELECT_FROM_LIST_VALUE;

  let selectedVeteran = veteranStatusItems.find(
    (item) => item.value === selectedVeteranItem,
  );

  // Developer note: Before the IC workflow changes, veteran status was a dropdown with "Select from list" available.
  // Once the IC workflow is fully launched, this fallback of "Select from list" can be removed. The "No" value may need
  // to remain based on the backend still sending atypical values for the frontend.
  if (!selectedVeteran) {
    selectedVeteran = flags.isInitialConsultationEnabled
      ? { value: 'No' }
      : { value: SELECT_FROM_LIST_VALUE };
  }

  const selectedMedicaid = medicaidItems.find(
    (item) => item.value === selectedMedicaidItem,
  );

  const selectedAidOrAttendance = aidOrAttendanceItems.find(
    (item) => item.value === selectedAidOrAttendanceItem,
  );

  const selectedInsurancePolicy = insurancePolicyItems.find(
    (item) => item.value === selectedInsurancePolicyItem,
  );

  const [
    selectedSpecificBudgetRangeItem,
    setSelectedSpecificBudgetRangeItem,
  ] = useState(
    financialCriteriaData.estimatedBudgetRange || SELECT_FROM_LIST_VALUE,
  );
  const selectedSpecificBudgetRange = specificBudgetRangeItems.find(
    (item) => item.value === selectedSpecificBudgetRangeItem,
  );

  const onChangeBudgetRangeItem = (selectedItem) => {
    setSelectedFinancialRange(selectedItem);

    if (selectedItem.value === 'Family Undecided') {
      setShowBudgetRange(false);
      financialCriteriaData.estimatedBudgetRange = selectedItem.value;
    } else {
      setSelectedSpecificBudgetRangeItem(SELECT_FROM_LIST_VALUE);
      setShowBudgetRange(true);
    }
  };

  const changeInSpecificBudgetRange = (selectedItem) => {
    setSelectedSpecificBudgetRangeItem(selectedItem.value);
    financialCriteriaData.estimatedBudgetRange = selectedItem.value;
  };

  const changeInFinancialResources = (e, selectedItem) => {
    selectedItem.checked = e.target.checked;
    setFinancialResources([...financialResources]);
    financialCriteriaData.financialResources = financialResources
      .filter((e) => e.checked === true)
      .map((e) => e.value);
  };
  const getSelectAllText = () => {
    return financialResources.some((item) => item.checked)
      ? labels.DESELECT_ALL
      : labels.SELECT_ALL;
  };
  const validateAndSave = () => {
    let isError = false;
    if (
      selectedFinancialRange.value === 'Specific Financial Range' &&
      selectedSpecificBudgetRangeItem === SELECT_FROM_LIST_VALUE
    ) {
      isError = true;
    }
    if (selectedFinancialRange.value === '') {
      isError = true;
    }
    if (isError) {
      setShowSuccessMessage(true);
      setShowSuccessOrFailureField(true);
    } else {
      onSave(
        new FinancialCriteriaFormModel({
          ...financialCriteriaData,
          medicaid: convertSelectFromListToNull(selectedMedicaidItem),
        }),
      );
    }
  };

  const handleSelectAllOnChange = (e) => {
    const value = e.target.checked;

    const newFinancialResources = financialResources.map((element) => {
      element.checked = value;
      return element;
    });

    setFinancialResources(newFinancialResources);
    financialCriteriaData.financialResources = financialResources
      .filter((e) => e.checked === true)
      .map((e) => e.value);
  };

  const changeInVeteran = (selectedItem) => {
    setSelectedVeteranItem(selectedItem.value);
    financialCriteriaData.isVeteran = _parseToBoolean(selectedItem.value);
  };

  const changeInMedicaid = (selectedItem) => {
    setSelectedMedicaidItem(selectedItem.value);
    financialCriteriaData.medicaid = selectedItem.value;
  };
  const changeInAidOrAttendance = (selectedItem) => {
    if (selectedItem.value !== SELECT_FROM_LIST_VALUE) {
      setSelectedVaAidStatus(selectedItem.value);
      financialCriteriaData.vaAid = selectedItem.value;
    } else {
      setSelectedVaAidStatus(null);
      financialCriteriaData.vaAid = null;
    }
  };

  const changeInInsurancePolicy = (selectedItem) => {
    if (selectedItem.value !== SELECT_FROM_LIST_VALUE) {
      setSelectedInsurancePolicyItem(selectedItem.value);
      financialCriteriaData.hasLongTermCareInsurancePolicy = selectedItem.value;
    } else {
      setSelectedInsurancePolicyItem(null);
      financialCriteriaData.hasLongTermCareInsurancePolicy = null;
    }
  };

  const showVaAidDropdown = () => {
    return selectedVeteran.value === veteranStatusOptions.YES;
  };

  const showVeteransAidDescriptionToast = () => {
    return (
      selectedVeteran.value === veteranStatusOptions.YES &&
      !hasVaAidDescriptionBeenClosed
    );
  };

  const Actions = ({ shouldShow }) => {
    return (
      <div
        className={clsx(
          enableReferralAlertValidation
            ? classes.column_button_send_referral
            : classes.column_button,
          shouldShow ? '' : classes.actions_for_desktop,
        )}
      >
        <div>
          <Button type="light" onClick={onCancel}>
            {labels.CANCEL}
          </Button>
        </div>
        <div>
          <Button
            type="secondary"
            onClick={validateAndSave}
            startIcon={<CircleChecked />}
          >
            {labels.SAVE}
          </Button>
        </div>
      </div>
    );
  };

  Actions.propTypes = {
    shouldShow: PropTypes.bool.isRequired,
  };

  const VeteransAidDescription = () => {
    return (
      <div className={classes.row_veteran_description}>
        <Typography className={classes.veteran_description_label}>
          {`"${labels.THE} `}
          <Link to={`https://www.va.gov/`} openInNewTab>
            {labels.VETERANS_ADMINISTRATION}
          </Link>
          {` ${labels.VETERANS_ADMINISTRATION_DESCRIPTION}"`}
        </Typography>
      </div>
    );
  };

  // Used to ensure the onChange does not trigger just because we have received props for the first time.
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const formModel = new FinancialCriteriaFormModel({
      formDataId: financialCriteriaData.formDataId,
      oneId: financialCriteriaData.oneId,
      familyFileId: financialCriteriaData.familyFileId,
      estimatedBudgetRange: showBudgetRange
        ? selectedSpecificBudgetRangeItem
        : selectedFinancialRange.value,
      financialResources: financialResources
        .filter((resource) => resource.checked)
        .map((resource) => resource.value),
      hasLongTermCareInsurancePolicy: selectedInsurancePolicyItem,
      isVeteran: selectedVeteranItem === veteranStatusOptions.YES,
      vaAid: selectedVaAidStatus,
      medicaid: convertSelectFromListToNull(selectedMedicaidItem),
    });

    onChange(formModel);
  }, [
    financialCriteriaData.formDataId,
    financialCriteriaData.oneId,
    financialCriteriaData.familyFileId,
    showBudgetRange,
    selectedFinancialRange,
    selectedSpecificBudgetRangeItem,
    financialResources,
    selectedVeteranItem,
    selectedVaAidStatus,
    selectedInsurancePolicyItem,
    selectedMedicaidItem,
    onChange,
  ]);

  // Developer note: Path for rendering new IC-related UI changes. Once the entire workflow is launched, the flag can be
  // deprecated and the other return path and this if statement can be deleted.
  if (flags.isInitialConsultationEnabled && !inSendReferralPage(location)) {
    return (
      <Fragment>
        {showSuccessOrErrorField && (
          <FinancialCriteriaErrorMessage
            showSuccessMessage={showSuccessMessage}
            handleClose={() => {
              setShowSuccessOrFailureField(false);
              setShowSuccessMessage(false);
            }}
            labels={labels}
          />
        )}
        {/* First set of Actions visible on Mobile viewports */}
        {shouldShowActionControls && (
          <div className={classes.actions_container}>
            <Actions shouldShow />
          </div>
        )}
        <div className={classes.form_container}>
          <div>
            <div className={clsx(classes.row, classes.first_row_header)}>
              <Typography
                color="eerieBlack1"
                bold
                className={classes.required_field_star_at_start}
              >
                {labels.FAMILY_BUDGET}
              </Typography>
            </div>
            <Typography level="small" color="eerieBlack5">
              {labels.SELECTED_ESTIMATED_BUDGET_RANGE}
            </Typography>
            <div className={classes.family_budget_controls_container}>
              <div
                className={classes.budget_range_dropdown_container}
                data-testid="budgetrange-dropdown"
              >
                <Dropdown
                  disabled={!showBudgetRange}
                  className={classes.dropdown_budget_range}
                  error={
                    selectedSpecificBudgetRangeItem === SELECT_FROM_LIST_VALUE
                  }
                  textHint={
                    showBudgetRange &&
                    selectedSpecificBudgetRangeItem === SELECT_FROM_LIST_VALUE
                      ? 'Required'
                      : ''
                  }
                  items={specificBudgetRangeItems}
                  selectedItem={selectedSpecificBudgetRange}
                  onChange={(e) => changeInSpecificBudgetRange(e)}
                />
              </div>
              <div>
                <Radio
                  selected={selectedFinancialRange}
                  items={financialRangeItems}
                  name="budget-range"
                  onChange={(e) => onChangeBudgetRangeItem(e)}
                />
              </div>
            </div>
            <Typography
              color="eerieBlack1"
              bold
              className={classes.section_heading_label}
            >
              {labels.VETERAN_STATUS}
            </Typography>
            <Typography
              level="small"
              color="eerieBlack5"
              className={classes.us_residents_only_label}
            >
              {labels.US_RESIDENTS_ONLY}
            </Typography>
            <div
              className={clsx(classes.row, classes.residents_option_container)}
            >
              <div
                className={clsx(
                  classes.column,
                  classes.veteran_status_dropdown_container,
                )}
                data-testid="veteran-status-dropdown"
              >
                {/* Developer Note: Using a label rather than the label prop on Radio because of a weird issue where
                    toggling the Budget Range radio buttons rapidly would cause the label within the Veteran Status
                    radio to occasionally disappear. Using why-did-you-render and the React dev tools did occasionally
                    show this Radio re-rendering, but it wasn't clear why it only happened occasionally. A recording
                    is attached to a comment Kim Villalobos made on BUB-2209.
                */}
                <label className={classes.radio_button_label}>
                  {labels.ARE_THEY_A_VETERAN_OR_WAS_THEIR_SPOUSE_A_VETERAN}
                </label>
                <Radio
                  selected={selectedVeteran}
                  items={veteranStatusItems}
                  name="veteran-status"
                  onChange={(e) => changeInVeteran(e)}
                />
              </div>
              {showVaAidDropdown() && (
                <div
                  className={clsx(
                    classes.column,
                    classes.va_aid_dropdown_container,
                  )}
                  data-testid="va-aid-dropdown"
                >
                  <Dropdown
                    className={classes.standard_dropdown_width}
                    items={aidOrAttendanceItems}
                    label={labels.VA_AID_AND_ATTENDANCE_BENEFITS}
                    selectedItem={selectedAidOrAttendance}
                    onChange={(e) => changeInAidOrAttendance(e)}
                  />
                </div>
              )}
            </div>
            {showVeteransAidDescriptionToast() && (
              <Toast
                type="informative"
                onClose={() => setHasVaAidDescriptionBeenClosed(true)}
              >
                <div className={classes.va_aid_description_container}>
                  <VeteransAidDescription />
                </div>
              </Toast>
            )}
            <div className={classes.row_header}>
              <div className={classes.column}>
                <Typography color="eerieBlack1" bold>
                  {labels.INSURANCE_POLICY}
                </Typography>
              </div>
            </div>
            <div
              className={(classes.row, classes.div_insurance_radio)}
              data-testid="insurance-policy-dropdown"
            >
              <Dropdown
                className={classes.standard_dropdown_width}
                items={insurancePolicyItems}
                label={labels.LONG_TERM_CARE_INSURANCE_POLICY}
                selectedItem={selectedInsurancePolicy}
                onChange={changeInInsurancePolicy}
              />
            </div>
          </div>
          <div>
            {!enableReferralAlertValidation && (
              <>
                <div className={classes.column}>
                  <Typography
                    color="eerieBlack1"
                    bold
                    className={classes.section_heading_label}
                  >
                    {labels.FINANCIAL_RESOURCES}
                  </Typography>
                  <Typography level="small" color="eerieBlack5">
                    {labels.FAMILY_FINANCIAL_MEANS_AND_OTHER_RESOURCES}
                  </Typography>
                </div>
                <div
                  className={classes.financial_resources_grid}
                  data-testid="financial-resource-checkboxes"
                >
                  <div
                    className={clsx(
                      classes.group_item,
                      classes.financial_resources_select_all_toggle_container,
                    )}
                  >
                    <Checkbox
                      className={classes.checkbox}
                      indeterminate={financialResources.some(
                        (item) => item.checked,
                      )}
                      checked={financialResources.some((item) => item.checked)}
                      onChange={handleSelectAllOnChange}
                      label={getSelectAllText()}
                    />
                  </div>
                  {financialResources.map((item, key) => (
                    <Checkbox
                      key={key}
                      checked={item.checked}
                      label={item.label}
                      onChange={(e) => changeInFinancialResources(e, item)}
                    />
                  ))}
                </div>
                <div className={classes.column}>
                  <Typography
                    color="eerieBlack1"
                    bold
                    className={classes.section_heading_label}
                  >
                    {labels.MEDICAID}
                  </Typography>
                  <Typography
                    level="small"
                    color="eerieBlack5"
                    className={classes.us_residents_only_label}
                  >
                    {labels.US_RESIDENTS_ONLY}
                  </Typography>
                </div>
                <label className={classes.radio_button_label}>
                  {labels.ARE_YOU_CURRENTLY_ENROLLED_IN_MEDICAID}
                </label>
                <div
                  className={clsx(
                    classes.row,
                    classes.residents_option_container,
                  )}
                  data-testid="medicaid-radio-group"
                >
                  <Radio
                    selected={selectedMedicaid}
                    items={medicaidItems}
                    name="medicaid-status"
                    onChange={(e) => changeInMedicaid(e)}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {/* Second set of Actions visible on Desktop viewports */}
        {shouldShowActionControls && <Actions shouldShow={false} />}
      </Fragment>
    );
  }

  return (
    <Fragment>
      {showSuccessOrErrorField && (
        <FinancialCriteriaErrorMessage
          showSuccessMessage={showSuccessMessage}
          handleClose={() => {
            setShowSuccessOrFailureField(false);
            setShowSuccessMessage(false);
          }}
          labels={labels}
        />
      )}
      {/* First set of Actions visible on Mobile viewports */}
      {shouldShowActionControls && <Actions shouldShow />}
      <div className={clsx(classes.row, classes.first_row_header)}>
        <Typography
          color="eerieBlack1"
          bold
          className={classes.family_budget_star}
        >
          {labels.FAMILY_FINANCIAL_RANGE}
        </Typography>
      </div>
      <div className={classes.row}>
        <Typography level="small">
          {labels.SELECTED_ESTIMATED_FINANCIAL_RANGE}
        </Typography>
      </div>

      <div className={classes.budget_range_item_row1}>
        <Radio
          selected={selectedFinancialRange}
          items={[financialRangeItems[0]]}
          name="budget-range"
          onChange={(e) => onChangeBudgetRangeItem(e)}
        />
      </div>
      {showBudgetRange && (
        <div className={classes.budget_range_dropdown_row}>
          <Dropdown
            className={classes.dropdown_budget_range}
            error={selectedSpecificBudgetRangeItem === SELECT_FROM_LIST_VALUE}
            textHint={
              selectedSpecificBudgetRangeItem === SELECT_FROM_LIST_VALUE
                ? 'Required'
                : ''
            }
            items={specificBudgetRangeItems}
            selectedItem={selectedSpecificBudgetRange}
            onChange={(e) => changeInSpecificBudgetRange(e)}
          />
        </div>
      )}
      <div className={classes.budget_range_item_row2}>
        <Radio
          selected={selectedFinancialRange}
          items={[financialRangeItems[1]]}
          name="budget-range"
          onChange={(e) => onChangeBudgetRangeItem(e)}
        />
      </div>

      {!enableReferralAlertValidation && (
        <>
          <div className={classes.row_header_financial_resources}>
            <div className={classes.column}>
              <Typography
                color="eerieBlack1"
                bold
                className={classes.financial_resources_label}
              >
                {labels.FINANCIAL_RESOURCES}
              </Typography>
              <Typography level="small">
                {labels.FAMILY_FINANCIAL_MEANS_AND_OTHER_RESOURCES}
              </Typography>
            </div>
          </div>
          <div className={classes.row_financial_resources}>
            <div className={classes.group_item}>
              <Checkbox
                className={classes.checkbox}
                indeterminate={financialResources.some((item) => item.checked)}
                checked={financialResources.some((item) => item.checked)}
                onChange={handleSelectAllOnChange}
                label={getSelectAllText()}
              />
            </div>
            <div className={classes.getting_around_items}>
              {financialResources.map((item, key) => (
                <Checkbox
                  key={key}
                  className={classes.other_issue}
                  checked={item.checked}
                  label={item.label}
                  onChange={(e) => changeInFinancialResources(e, item)}
                />
              ))}
            </div>
          </div>

          <div className={classes.row_header}>
            <Typography color="eerieBlack1" bold>
              {labels.US_RESIDENTS_ONLY}
            </Typography>
          </div>
          <div
            className={clsx(classes.row, classes.residents_option_container)}
          >
            <div className={classes.column}>
              <Dropdown
                className={classes.dropdown_residents_only}
                items={veteranStatusItems}
                label={labels.ARE_THEY_A_VETERAN_OR_WAS_THEIR_SPOUSE_A_VETERAN}
                selectedItem={selectedVeteran}
                onChange={(e) => changeInVeteran(e)}
              />
            </div>
            <div className={classes.column}>
              <Dropdown
                className={classes.dropdown_residents_only}
                items={aidOrAttendanceItems}
                label={labels.VA_AID_AND_ATTENDANCE_BENEFITS}
                selectedItem={selectedAidOrAttendance}
                onChange={(e) => changeInAidOrAttendance(e)}
              />
            </div>
          </div>
          {selectedVeteran.value === veteranStatusOptions.YES && (
            <VeteransAidDescription />
          )}
          <div className={classes.row_header}>
            <div className={classes.column}>
              <Typography color="eerieBlack1" bold>
                {labels.INSURANCE_POLICY}
              </Typography>
            </div>
          </div>
          <div className={(classes.row, classes.div_insurance_radio)}>
            <Radio
              selected={selectedInsurancePolicy}
              label={labels.LONG_TERM_CARE_INSURANCE_POLICY}
              name="complete-options"
              items={insurancePolicyItems}
              onChange={changeInInsurancePolicy}
            />
          </div>
          <div
            className={clsx(classes.row, classes.residents_option_container)}
            data-testid="medicaid-dropdown"
          >
            <Dropdown
              className={classes.dropdown_residents_only}
              items={medicaidItems}
              label={labels.MEDICAID}
              selectedItem={selectedMedicaid}
              onChange={(e) => changeInMedicaid(e)}
            />
          </div>
        </>
      )}
      {/* Second set of Actions visible on Desktop viewports */}
      {shouldShowActionControls && <Actions shouldShow={false} />}
    </Fragment>
  );
};

FinancialCriteriaEdit.propTypes = {
  labels: PropTypes.object.isRequired,
  financialCriteriaData: PropTypes.shape({
    formDataId: PropTypes.number,
    oneId: PropTypes.string,
    familyFileId: PropTypes.number,
    estimatedBudgetRange: PropTypes.string,
    financialResources: PropTypes.arrayOf(PropTypes.string),
    hasLongTermCareInsurancePolicy: PropTypes.string,
    isVeteran: PropTypes.bool,
    vaAid: PropTypes.string,
    medicaid: PropTypes.string,
  }).isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  enableReferralAlertValidation: PropTypes.bool,

  /**
   * Boolean to determine whether or not to show the Cancel and Save buttons. Defaults to false.
   */
  shouldShowActionControls: PropTypes.bool,

  /**
   * Callback invoked when any input within the form is changed. When invoked, an instance of
   * {@link FinancialCriteriaFormModel} containing the current values of the form is provided as an argument.
   */
  onChange: PropTypes.func,

  /**
   * Object containing feature flags.
   */
  flags: PropTypes.shape({
    isInitialConsultationEnabled: PropTypes.bool,
  }),
};

FinancialCriteriaEdit.defaultProps = {
  enableReferralAlertValidation: false,
  shouldShowActionControls: false,
  onChange: () => {},
  onSave: () => {},
  flags: {},
};

export default withLDConsumer()(FinancialCriteriaEdit);

export { medicaidOptions };
