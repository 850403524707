import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './Notification.style';
import Typography from '../../common/Typography/Typography';
import clsx from 'clsx';

export const NotificationPropType = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  read: PropTypes.bool,
  date: PropTypes.instanceOf(Date),
});

const Notification = ({ notification, onClick }) => {
  const classes = useStyles();
  if (!notification) return null;
  return (
    <div
      test-id={notification.id}
      onClick={() => onClick(notification)}
      className={clsx(classes.notification, !notification.read && 'unread')}
    >
      <Typography level="small" bold={!notification.read}>
        {notification.title}
      </Typography>
      <Typography level="small">{notification.content}</Typography>
    </div>
  );
};

Notification.propTypes = {
  notification: NotificationPropType,
  onClick: PropTypes.func,
};

export default Notification;
