import React from 'react';
import PropTypes from 'prop-types';
import { originalFormStepAnswerPropType } from '../../inquiryPropTypes';
import { useStyles } from './ButtonGroup.style';
import Button from 'common/Button/Button';

const handleOnClick = (option, onClick) => {
  if (option.urlLink !== null) {
    window.open(option.urlLink, '_blank', 'noopener noreferrer');
  }
  onClick(option);
};

const ButtonGroup = ({ options, disabled, onClick, selected, testId }) => {
  const classes = useStyles();
  const sortedOptions = options.sort((a, b) => a.order - b.order);
  const buttonSize = sortedOptions.length > 5 ? 'tiny' : 'small';
  return (
    <div className={classes.buttons_container}>
      {sortedOptions.map((option, key) => {
        let type = 'outlined';
        if (disabled) type = 'outlinedDisabled';
        if (
          selected &&
          selected.value === option.value &&
          option.label !== 'Save'
        ) {
          type = 'primary';
        }
        return (
          <Button
            type={type}
            key={key}
            size={buttonSize}
            className={classes.button}
            onClick={() => handleOnClick(option, onClick)}
            testId={`${testId}_actions_${option.value}`}
          >
            {option.label}
          </Button>
        );
      })}
    </div>
  );
};

ButtonGroup.defaultProps = {
  disabled: false,
};

ButtonGroup.propTypes = {
  options: PropTypes.arrayOf(originalFormStepAnswerPropType).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  selected: originalFormStepAnswerPropType,
  testId: PropTypes.string.isRequired,
};

export default ButtonGroup;
