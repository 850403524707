import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types';

export const AnswerPropType = shape({
  order: oneOfType([number, string]),
  label: string,
  value: string,
  shows: arrayOf(oneOfType([number, string])),
  hides: arrayOf(oneOfType([number, string])),
});

export const TypePropType = shape({
  id: string,
  label: string,
  value: string,
  description: string,
});

export const StepPropType = shape({
  id: string,
  number: oneOfType([number, string]),
  name: string,
  type: string,
  hidden: bool,
  answers: arrayOf(AnswerPropType),
});

export const ScriptPropType = shape({
  id: string,
  name: string,
  slug: string,
  description: string,
  target: string,
});
