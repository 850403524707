import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    modal_sm: {
      width: '80%',
      '& div > p > b ': {
        fontSize: '13px',
      },
    },
    modal_xs: {
      width: '80%',
      height: '100%',
      '& div > p > b ': {
        fontSize: '13px',
      },
    },

    bold_blue: {
      color: theme.palette.neutral.cosmicCobalt3,
      fontWeight: 'bold',
    },

    table_cell_current_call: {
      backgroundColor: theme.palette.neutral.dropdown,
      borderRight: `1px solid ${theme.palette.light.platinum1}`,
      padding: '0px 16px !important',
      height: '45px !important',
      justifyItems: 'center',
      whiteSpace: 'nowrap',

      [theme.breakpoints.down('xs')]: {
        borderRight: `2px solid ${theme.palette.dark.disabled}`,
        backgroundColor: theme.palette.neutral.dropdown,
        padding: '0px 16px !important',
        height: '45px !important',
        justifyItems: 'center',
      },
    },
    table_cell_matched_contact: {
      backgroundColor: theme.palette.neutral.dropdown,
      borderRight: `1px solid ${theme.palette.light.platinum1}`,
      padding: '0px 16px !important',
      height: '45px !important',
      justifyItems: 'center',
    },
    table_cell_default: {
      borderRight: `1px solid ${theme.palette.light.platinum1}`,
      padding: '0px 16px !important',
      height: '45px !important',
      justifyItems: 'center',
    },
    table_cell_current_call_title: {
      borderRight: `1px solid ${theme.palette.light.platinum1}`,
      [theme.breakpoints.down('xs')]: {
        borderRight: `2px solid ${theme.palette.dark.disabled}`,
      },
    },

    table_cell_title: {
      borderRight: `2px solid ${theme.palette.dark.disabled}`,
      minWidth: '110px',
    },
    table_cell_check_box: {
      textAlign: 'center',
      borderRight: `1px solid ${theme.palette.light.platinum1}`,
      height: '28px',
    },
    check_box: {
      width: '20px',
      height: '10px',
    },

    column_button: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.down('xl')]: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& div': {
          paddingLeft: theme.spacing(3),
          paddingTop: theme.spacing(4),
        },
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& div': {
          paddingLeft: theme.spacing(3),
          paddingTop: theme.spacing(4),
        },
      },

      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        '& div': {
          paddingTop: theme.spacing(4),
          justifyItems: 'center',
        },
      },
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
        display: 'block',
        '& div': {
          paddingTop: theme.spacing(1),
          paddingLeft: '127px',
          justifyItems: 'center',
        },
      },
    },

    toast: {
      [theme.breakpoints.down('xl')]: {
        paddingBottom: theme.spacing(2),
      },
      [theme.breakpoints.down('md')]: {
        paddingBottom: theme.spacing(2),
      },
      [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(2),
      },
      [theme.breakpoints.down('xs')]: {
        paddingBottom: '10px',
      },
    },
    table_container: {
      display: 'flex',
    },

    scrollable_table: {
      overflowX: 'scroll',
    },
    header: {
      '& tr': {
        borderBottom: 'none',
      },
    },
    button_merge: {
      marginLeft: '20px',
      marginTop: theme.spacing(1),
    },

    title_label: {
      paddingTop: '1.5px',
      paddingBottom: '1.5px',
    },
    table_label: {
      overflowWrap: 'break-word',
    },
  };
});

export { useStyles };
