import React, { Fragment, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import CallPhone from 'common/Icons/call/CallPhone';
import Plus from 'common/Icons/basic/Plus';
import Minus from 'common/Icons/basic/Minus';
import Typography from 'common/Typography/Typography';
import { useStyles } from './EditContactsInformation.style';
import Modal from 'common/Modal/Modal';
import ExpandableRow from 'common/ExpandableRow/ExpandableRow';
import isSameSize from 'utils/sizesHelper';
import clsx from 'clsx';
import Button from 'common/Button/Button';
import Divider from 'common/Divider/Divider';
import CircleChecked from 'common/Icons/basic/CircleChecked';
import Toast from 'common/Toast/Toast';
import ContactInfo from './ContactInfo';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { isEmptyContact } from 'services/formService';

const EditContactsInformation = ({
  labels,
  contactInfo,
  onClose,
  isOpen,
  onSave,
  size,
  showErrorMessage,
  flags,
  enableReferralAlertValidation,
  checkForChanges,
}) => {
  const primaryContactErrorRef = useRef(null);
  const secondaryContactErrorRef = useRef(null);
  const additionalContactErrorRef = useRef(null);

  const executeScroll = (ref) =>
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const INITIAL_PHONE_ERRORS_STATE = {
    primaryHomePhoneError: false,
    primaryWorkPhoneError: false,
    primaryCellPhoneError: false,
    secondaryHomePhoneError: false,
    secondaryWorkPhoneError: false,
    secondaryCellPhoneError: false,
    additionalHomePhoneError: false,
    additionalWorkPhoneError: false,
    additionalCellPhoneError: false,
  };
  const INITIAL_EMAIL_ERRORS_STATE = {
    primaryEmailError: false,
    secondaryEmailError: false,
    additionaEmailError: false,
  };
  const classes = useStyles();
  const [contactsInformation, setContactsInformation] = useState({
    ...contactInfo,
  });
  const [primaryContact, setPrimaryContact] = useState({
    ...contactsInformation.primaryContact,
  });
  const [secondaryContact, setSecondaryContact] = useState({
    ...contactsInformation.secondaryContact,
  });
  const [additionalContact, setAdditionalContact] = useState({
    ...contactsInformation.additionalContact,
  });

  const [showToastError, setShowToastError] = useState(showErrorMessage);

  const getEmptyContact = () => {
    return {
      contactId: null,
      profileFormDataId: null,
      name: '',
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
      allowContact: false,
      isInquirer: false,
      allowMailing: false,
      relationship: '',
      contactDetail: {},
      phones: [],
      emailAddresses: [],
    };
  };

  if (secondaryContact === undefined) {
    setSecondaryContact(getEmptyContact());
  }

  if (additionalContact === undefined) {
    setAdditionalContact(getEmptyContact());
  }

  const [primaryContactExpanded, setPrimaryContactExpanded] = useState(false);

  const [secondaryContactExpanded, setSecondaryContactExpanded] = useState(
    false,
  );

  const [additionalContactExpanded, setAdditionalContactExpanded] = useState(
    false,
  );

  const [showAllIcon, setShowAllIcon] = useState(
    <Plus className={classes.show_all} />,
  );

  const [showAllText, setShowAllText] = useState(labels.SHOW_ALL);

  const [firstNameError, setFirstNameError] = useState();
  const [lastNameError, setLastNameError] = useState();
  const [emailErrors, setEmailErrors] = useState(INITIAL_EMAIL_ERRORS_STATE);
  const [phoneErrors, setPhoneErrors] = useState(INITIAL_PHONE_ERRORS_STATE);
  const [
    relationshipToResidentError,
    setRelationshipToResidentError,
  ] = useState();
  const [
    relationshipToSecondaryResidentError,
    setRelationshipToSecondaryResidentError,
  ] = useState(null);
  const [
    relationshipToAdditionalResidentError,
    setRelationshipToAdditionalResidentError,
  ] = useState(null);

  const [classEditContactsContainer, setClassEditContactsContainer] = useState(
    clsx(
      classes.edit_contacts_container,
      isSameSize(size, 'xl') ? classes.edit_contacts_container_xl : '',
    ),
  );

  const [enableSave, setEnableSave] = useState(true);

  useEffect(() => {
    setEnableSave(!(hasErrors(emailErrors) || hasErrors(phoneErrors)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneErrors, emailErrors]);

  useEffect(() => {
    setPrimaryContact(contactInfo.primaryContact);
    setSecondaryContact(contactInfo.secondaryContact);
    setAdditionalContact(contactInfo.additionalContact);
    setPrimaryContactExpanded(checkForChanges(contactInfo, 'primaryContact'));
    setSecondaryContactExpanded(
      checkForChanges(contactInfo, 'secondaryContact'),
    );
    setAdditionalContactExpanded(
      checkForChanges(contactInfo, 'additionalContact'),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactInfo]);

  useEffect(() => {
    setClassEditContactsContainer(
      clsx(
        classes.edit_contacts_container,
        isSameSize(size, 'xl') ? classes.edit_contacts_container_xl : '',
        primaryContactExpanded ||
          secondaryContactExpanded ||
          additionalContactExpanded
          ? classes.edit_contacts_container_expanded
          : '',
      ),
    );
    if (
      primaryContactExpanded ||
      secondaryContactExpanded ||
      additionalContactExpanded
    ) {
      setShowAllText(labels.HIDE_ALL);
      setShowAllIcon(<Minus className={classes.show_all} />);
    }
    if (
      !primaryContactExpanded &&
      !secondaryContactExpanded &&
      !additionalContactExpanded
    ) {
      setShowAllText(labels.SHOW_ALL);
      setShowAllIcon(<Plus className={classes.show_all} />);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    primaryContactExpanded,
    secondaryContactExpanded,
    additionalContactExpanded,
  ]);

  useEffect(() => {
    setShowToastError(showErrorMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showErrorMessage]);

  const isContactValid = (contact) =>
    !!contact.firstName && !!contact.lastName && !!contact.relationship;

  const isOptionalContactValid = (contact) =>
    ((!!contact.firstName || !!contact.lastName) && !!contact.relationship) ||
    ((!contact.firstName || !contact.lastName) && !!contact.relationship) ||
    (!contact.firstName && !contact.lastName && !contact.relationship);

  const handlePhoneNumberErrors = (errorField, isError) => {
    if (Object.keys(phoneErrors).includes(errorField)) {
      phoneErrors[errorField] = isError;
    }

    setPhoneErrors({ ...phoneErrors });
  };

  const handleEmailError = (errorField, isError) => {
    if (Object.keys(emailErrors).includes(errorField)) {
      emailErrors[errorField] = isError;
    }
    setEmailErrors({ ...emailErrors });
  };

  const hasErrors = (inputErrors) => {
    return Object.values(inputErrors).reduce((acc, curr) => acc || curr, false);
  };

  const handleCancel = () => {
    onClose(contactsInformation);
    setFirstNameError();
    setLastNameError();
    setRelationshipToResidentError();
    setRelationshipToSecondaryResidentError();
    setRelationshipToAdditionalResidentError();
  };

  const handleSave = () => {
    if (!isContactValid(primaryContact)) {
      if (!primaryContact.firstName) {
        setFirstNameError(labels.REQUIRED);
      }
      if (!primaryContact.lastName) {
        setLastNameError(labels.REQUIRED);
      }
      if (!primaryContact.relationship) {
        setRelationshipToResidentError(labels.REQUIRED);
      }
      setPrimaryContactExpanded(true);
      executeScroll(primaryContactErrorRef);
    }
    if (secondaryContact.firstName || secondaryContact.lastName) {
      if (!secondaryContact.relationship) {
        setSecondaryContactExpanded(true);
        setRelationshipToSecondaryResidentError(labels.REQUIRED);
      }
      if (isContactValid(primaryContact)) {
        executeScroll(secondaryContactErrorRef);
      }
    }
    if (additionalContact.firstName || additionalContact.lastName) {
      if (!additionalContact.relationship) {
        setAdditionalContactExpanded(true);
        setRelationshipToAdditionalResidentError(labels.REQUIRED);
      }
      if (
        isContactValid(primaryContact) &&
        isOptionalContactValid(secondaryContact)
      ) {
        executeScroll(additionalContactErrorRef);
      }
    }

    if (primaryContact.lastName) setLastNameError();
    if (primaryContact.firstName) setFirstNameError();
    if (primaryContact.relationship) setRelationshipToResidentError();

    //if all 3 forms are valid, save
    if (
      isContactValid(primaryContact) &&
      isOptionalContactValid(secondaryContact) &&
      isOptionalContactValid(additionalContact)
    ) {
      onSave(contactsInformation);
    }
  };

  const handleContactInfo = (contactInfo) => {
    setPrimaryContact(contactInfo);
    contactsInformation.primaryContact = contactInfo;
    setContactsInformation(contactsInformation);
  };

  const handleSecondaryContactInfo = (contactInfo) => {
    setSecondaryContact(contactInfo);
    contactsInformation.secondaryContact = contactInfo;
    setContactsInformation(contactsInformation);
  };

  const handleAdditionalContactInfo = (contactInfo) => {
    setAdditionalContact(contactInfo);
    contactsInformation.additionalContact = contactInfo;
    setContactsInformation(contactsInformation);
  };

  const handleShowAllClick = () => {
    setPrimaryContactExpanded(showAllText === labels.SHOW_ALL);
    setSecondaryContactExpanded(showAllText === labels.SHOW_ALL);
    setAdditionalContactExpanded(showAllText === labels.SHOW_ALL);
  };

  const canEditAddtionalContact = () => {
    return flags.canEditAdditionalContacts;
  };

  const getTitle = (typeOfContact, contactInformation) => {
    if (isEmptyContact(contactInformation)) {
      return (
        <Fragment>
          <Typography color="eerieBlack5" level="body">
            {typeOfContact}:{' '}
          </Typography>
          <Typography level="body" color="eerieBlack1" bold>
            {labels.NO_CONTACT}
          </Typography>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Typography color="eerieBlack5" level="body">
          {typeOfContact}:{' '}
        </Typography>
        <Typography level="body" color="eerieBlack1" bold>
          {contactInformation.name}{' '}
          {contactInformation.relationship
            ? `(${contactInformation.relationship})`
            : ''}
        </Typography>
      </Fragment>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCancel}
      closeOnClickOutside={false}
      title={labels.CONTACTS}
      icon={<CallPhone />}
      color="cosmicCobalt2"
      noBodyScroll={true}
      noBodyPadding={true}
      className={classes.edit_contacts_modal_container}
    >
      <div className={classEditContactsContainer}>
        <div
          className={clsx(
            classes.row,
            classes.show_all_container,
            classes.with_padding,
          )}
          onClick={handleShowAllClick}
        >
          <Typography>{showAllText}</Typography>
          {showAllIcon}
        </div>
        {showToastError && (
          <Toast
            type="error"
            className={classes.error_message}
            onClose={() => ''}
          >
            <Typography>{labels.UNABLE_TO_SAVE_DUE_TO_ERROR}</Typography>
          </Toast>
        )}
        <div
          ref={primaryContactErrorRef}
          className={clsx(
            classes.row,
            classes.with_padding,
            classes.no_padding_bottom,
          )}
        >
          <ExpandableRow
            title={getTitle(labels.PRIMARY_CONTACT, primaryContact)}
            titleBold={false}
            defaultState={primaryContactExpanded}
            expandText={labels.VIEW_MORE}
            collapseText={labels.VIEW_LESS}
            onClickHandler={(value) => setPrimaryContactExpanded(value)}
          >
            <ContactInfo
              contactInfo={primaryContact}
              labels={labels}
              size={size}
              handleContactInfo={handleContactInfo}
              firstNameError={firstNameError}
              lastNameError={lastNameError}
              handlePhoneErrors={handlePhoneNumberErrors}
              handleEmailError={handleEmailError}
              relationshipToResidentError={relationshipToResidentError}
              isPrimaryContact={true}
              title={labels.PRIMARY_CONTACT_DETAILS}
              flags={flags}
              enableReferralAlertValidation={enableReferralAlertValidation}
              setFirstNameError={setFirstNameError}
              setLastNameError={setLastNameError}
              setRelationshipToResidentError={setRelationshipToResidentError}
            />
          </ExpandableRow>
        </div>
        {canEditAddtionalContact() && (
          <div
            ref={secondaryContactErrorRef}
            className={clsx(
              classes.row,
              classes.with_padding,
              classes.no_padding_top,
            )}
          >
            <ExpandableRow
              title={getTitle(labels.SECONDARY_CONTACT, secondaryContact)}
              titleBold={false}
              defaultState={secondaryContactExpanded}
              expandText={labels.VIEW_MORE}
              collapseText={labels.VIEW_LESS}
              onClickHandler={(value) => setSecondaryContactExpanded(value)}
            >
              <ContactInfo
                contactInfo={secondaryContact}
                labels={labels}
                size={size}
                handleContactInfo={handleSecondaryContactInfo}
                firstNameError={''}
                lastNameError={''}
                handlePhoneErrors={handlePhoneNumberErrors}
                handleEmailError={handleEmailError}
                relationshipToResidentError={
                  relationshipToSecondaryResidentError
                }
                isPrimaryContact={false}
                title={labels.SECONDARY_CONTACT_DETAILS}
                flags={flags}
                setRelationshipToResidentError={
                  setRelationshipToSecondaryResidentError
                }
              />
            </ExpandableRow>
          </div>
        )}
        {canEditAddtionalContact() && (
          <div
            ref={additionalContactErrorRef}
            className={clsx(
              classes.row,
              classes.with_padding,
              classes.no_padding_top,
            )}
          >
            <ExpandableRow
              title={getTitle(labels.ADDITIONAL_CONTACT, additionalContact)}
              titleBold={false}
              defaultState={additionalContactExpanded}
              expandText={labels.VIEW_MORE}
              collapseText={labels.VIEW_LESS}
              onClickHandler={(value) => setAdditionalContactExpanded(value)}
            >
              <ContactInfo
                contactInfo={additionalContact}
                labels={labels}
                size={size}
                handleContactInfo={handleAdditionalContactInfo}
                firstNameError={''}
                lastNameError={''}
                handlePhoneErrors={handlePhoneNumberErrors}
                handleEmailError={handleEmailError}
                relationshipToResidentError={
                  relationshipToAdditionalResidentError
                }
                isAdditionalContact={true}
                title={labels.ADDITIONAL_CONTACT_DETAILS}
                flags={flags}
                setRelationshipToResidentError={
                  setRelationshipToAdditionalResidentError
                }
              />
            </ExpandableRow>
          </div>
        )}
      </div>
      <Divider className={classes.divider} color="platinum1" />
      <div className={classes.actions_container}>
        <div className={clsx(classes.actions)}>
          <Button size="small" type="light" onClick={handleCancel}>
            {labels.CANCEL}
          </Button>
          <Button
            size="small"
            type={enableSave ? 'secondary' : 'disabled'}
            onClick={handleSave}
            startIcon={<CircleChecked />}
          >
            {labels.SAVE}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

EditContactsInformation.propTypes = {
  labels: PropTypes.object.isRequired,
  contactInfo: PropTypes.shape({
    primaryContact: PropTypes.shape({
      name: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      relationship: PropTypes.string,
      address1: PropTypes.string,
      address2: PropTypes.string,
      allowContact: PropTypes.bool,
      isInquirer: PropTypes.bool,
      city: PropTypes.string,
      postalCode: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      contactDetail: PropTypes.shape({
        phonePrimary: PropTypes.string,
        phoneSecondary: PropTypes.string,
        email: PropTypes.string,
        primaryPhoneType: PropTypes.string,
        secondaryPhoneType: PropTypes.string,
      }),
      phones: PropTypes.any,
    }),
    secondaryContact: PropTypes.shape({
      name: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      relationship: PropTypes.string,
      address1: PropTypes.string,
      address2: PropTypes.string,
      allowContact: PropTypes.bool,
      isInquirer: PropTypes.bool,
      city: PropTypes.string,
      postalCode: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      contactDetail: PropTypes.shape({
        phonePrimary: PropTypes.string,
        phoneSecondary: PropTypes.string,
        email: PropTypes.string,
        primaryPhoneType: PropTypes.string,
        secondaryPhoneType: PropTypes.string,
      }),
    }),
    additionalContact: PropTypes.shape({
      name: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      relationship: PropTypes.string,
      address1: PropTypes.string,
      address2: PropTypes.string,
      allowContact: PropTypes.bool,
      isInquirer: PropTypes.bool,
      city: PropTypes.string,
      postalCode: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      contactDetail: PropTypes.shape({
        phonePrimary: PropTypes.string,
        phoneSecondary: PropTypes.string,
        email: PropTypes.string,
        primaryPhoneType: PropTypes.string,
        secondaryPhoneType: PropTypes.string,
      }),
    }),
  }),
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  size: PropTypes.string,
  showErrorMessage: PropTypes.bool,
  flags: PropTypes.shape({
    dialOverrideNumber: PropTypes.string,
    editAdditionalContact: PropTypes.bool,
    canEditAdditionalContacts: PropTypes.bool,
  }),
  enableReferralAlertValidation: PropTypes.bool,
  isSelfLead: PropTypes.bool,
  checkForChanges: PropTypes.func,
};

EditContactsInformation.defaultProps = {
  enableReferralAlertValidation: false,
};

export default withLDConsumer()(EditContactsInformation);
