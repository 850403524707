import gql from 'graphql-tag';

export default gql`
  mutation(
    $formDataId: Int!
    $firstName: String
    $lastName: String
    $oneId: String
    $familyFileId: Int
  ) {
    updateNames(
      input: {
        formDataId: $formDataId
        firstName: $firstName
        lastName: $lastName
        oneId: $oneId
        familyFileId: $familyFileId
      }
    ) {
      formDataId
    }
  }
`;
