import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SmartTooltip from '../../common/SmartTooltip/SmartTooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Copy from '../Icons/basic/Copy';
import { useStyles } from './CopyClipboard.style';

const CopyClipboard = ({ label, textToCopy }) => {
  const classes = useStyles();
  const [copySuccess, setCopySuccess] = useState(false);

  const changeTitle = (label) => {
    if (!copySuccess) {
      return `Click to copy ${label}`;
    }
    return `${label} copied!`;
  };

  return (
    <SmartTooltip title={changeTitle(label)} placement="bottom">
      <CopyToClipboard text={textToCopy} onCopy={() => setCopySuccess(true)}>
        <span onMouseOutCapture={() => setCopySuccess(false)}>
          <Copy className={classes.icon} />
        </span>
      </CopyToClipboard>
    </SmartTooltip>
  );
};

CopyClipboard.propTypes = {
  label: PropTypes.string,
  textToCopy: PropTypes.string.isRequired,
};

CopyClipboard.defaultProps = {
  label: '',
};

export default CopyClipboard;
