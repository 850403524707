import clsx from 'clsx';
import { parsePhoneNumber } from 'libphonenumber-js';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Button from 'common/Button/Button';
import Checkbox from 'common/Checkbox/Checkbox';
import Duplicate from 'common/Icons/basic/Duplicate';
import Table from 'common/Table/Table/Table';
import TableBody from 'common/Table/TableBody/TableBody';
import TableCell from 'common/Table/TableCell/TableCell';
import TableHead from 'common/Table/TableHead/TableHead';
import TableRow from 'common/Table/TableRow/TableRow';
import Toast from 'common/Toast/Toast';
import Typography from 'common/Typography/Typography';
import { getShortMonthDayYearFormat } from 'utils/dateFormat';
import { formatPhoneWithParenthesis } from 'utils/phoneFormat';
import { useStyles } from './ContactsPopup.style';
import {
  logMergeClicked,
  logDoNotMergeClicked,
} from 'services/callCenter/amplitudeEventsService';

const ContactsPopupXs = ({
  currentContact,
  inquiry,
  labels,
  contacts,
  changeContactSelection,
  doMerging,
  activedButton,
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.toast}>
        <Toast type="warning">
          <Typography>
            {`${contacts.length} ${labels.POSSIBLE_CONTACTS_DUPLICATES_FOUND}`}
          </Typography>
        </Toast>
      </div>
      <div className={classes.table_container}>
        <div className={classes.sticky_column_xs}>
          <Table>
            <TableHead className={classes.header}>
              <TableRow size="small">
                <TableCell className={classes.table_cell_current_call_title}>
                  <Typography color="cosmicCobalt3" bold>
                    {labels.CURRENT_CALL}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow size="medium">
                <TableCell className={classes.table_cell_current_call}>
                  <Typography
                    className={classes.table_label}
                  >{`${currentContact.firstName} ${currentContact.lastName}`}</Typography>
                </TableCell>
              </TableRow>
              <TableRow size="medium">
                <TableCell className={classes.table_cell_current_call}>
                  <Typography className={classes.table_label}>
                    {formatPhoneWithParenthesis(
                      parsePhoneNumber(
                        currentContact.phone,
                        'US',
                      ).formatNational(),
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow size="medium">
                <TableCell className={classes.table_cell_current_call}>
                  <Typography className={classes.table_label}>
                    {currentContact.email || labels.DASH_MESSAGE}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow size="medium">
                <TableCell className={classes.table_cell_current_call}>
                  <Typography className={classes.table_label}>
                    {currentContact.oneId || labels.DASH_MESSAGE}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow size="medium">
                <TableCell className={classes.table_cell_current_call}>
                  <Typography className={classes.table_label}>
                    {getShortMonthDayYearFormat(currentContact.updatedAt) ||
                      labels.DASH_MESSAGE}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow size="medium">
                <TableCell className={classes.table_cell_current_call}>
                  <Typography className={classes.table_label}>
                    {inquiry.inquiryId || labels.DASH_MESSAGE}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow size="medium">
                <TableCell className={classes.table_cell_current_call}>
                  <Typography className={classes.table_label}>
                    {inquiry.status || labels.DASH_MESSAGE}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <div className={classes.scrollable_table}>
          <Table>
            <TableHead className={classes.header}>
              <TableRow size="small">
                {contacts.map((contact, index) => (
                  <TableCell
                    key={index}
                    className={classes.table_cell_check_box}
                  >
                    <Typography>
                      <Checkbox
                        className={classes.check_box}
                        key={index}
                        checked={contact.checked}
                        onChange={(e) => changeContactSelection(e, contact)}
                      />
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow size="medium">
                {contacts.map((contact, index) => (
                  <TableCell
                    key={index}
                    className={clsx(
                      contact.nameMatch
                        ? classes.table_cell_matched_contact
                        : classes.table_cell_default,
                    )}
                  >
                    <Typography>{`${contact.firstName} ${contact.lastName}`}</Typography>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow size="medium">
                {contacts.map((contact, index) => (
                  <TableCell
                    key={index}
                    className={clsx(
                      contact.phoneMatch
                        ? classes.table_cell_matched_contact
                        : classes.table_cell_default,
                    )}
                  >
                    <Typography>
                      {formatPhoneWithParenthesis(
                        parsePhoneNumber(contact.phone, 'US').formatNational(),
                      )}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow size="medium">
                {contacts.map((contact, index) => (
                  <TableCell
                    key={index}
                    className={clsx(
                      contact.emailMatch
                        ? classes.table_cell_matched_contact
                        : classes.table_cell_default,
                    )}
                  >
                    <Typography>{contact.email}</Typography>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow size="medium">
                {contacts.map((contact, index) => (
                  <TableCell
                    key={index}
                    className={clsx(
                      contact.oneIdMatch
                        ? classes.table_cell_matched_contact
                        : classes.table_cell_default,
                    )}
                  >
                    <Typography>
                      {contact.oneId || labels.DASH_MESSAGE}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow size="medium">
                {contacts.map((contact, index) => (
                  <TableCell key={index} className={classes.table_cell_default}>
                    <Typography>
                      {getShortMonthDayYearFormat(contact.updatedAt) ||
                        labels.DASH_MESSAGE}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow size="medium">
                {contacts.map((contact, index) => (
                  <TableCell key={index} className={classes.table_cell_default}>
                    <Typography>{contact.lastInquiry?.inquiryId}</Typography>
                  </TableCell>
                ))}
              </TableRow>

              <TableRow size="medium">
                {contacts.map((contact, index) => (
                  <TableCell key={index} className={classes.table_cell_default}>
                    <Typography>{contact.lastInquiry?.status}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>

      <div className={classes.column_button}>
        <div>
          <Button
            type="light"
            onClick={() => {
              doMerging(false);
              logDoNotMergeClicked(inquiry?.inquiryId, contacts.length);
            }}
          >
            {labels.DO_NOT_MERGE}
          </Button>
        </div>
        <div className={classes.button_merge}>
          <Button
            type={activedButton}
            onClick={() => {
              doMerging(true);
              logMergeClicked(inquiry?.inquiryId, contacts.length);
            }}
            startIcon={<Duplicate />}
          >
            {labels.MERGE}
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

ContactsPopupXs.propTypes = {
  currentContact: PropTypes.object,
  inquiry: PropTypes.object,
  contacts: PropTypes.array,
  labels: PropTypes.object,
  changeContactSelection: PropTypes.func,
  doMerging: PropTypes.func,
  activedButton: PropTypes.string,
};
export default ContactsPopupXs;
