import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  scheduled_tour_toast: {
    maxWidth: '789px',
    marginBottom: '16px',
  },
  toast_message: {
    marginTop: theme.spacing(2),
  },
}));

export { useStyles };
