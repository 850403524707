import React from 'react';
import { useStyles } from './Card.style';
import { useBackgroundColorStyles } from '../style/backgroundColor';
import { colorReference } from '../Theme/themes/default';
import Typography from '../Typography/Typography';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Card = ({ title, icon, children, className, headerColor, width }) => {
  const classes = useStyles({
    width,
  });
  const backgroundClasses = useBackgroundColorStyles();
  return (
    <div className={clsx(classes.container, className)}>
      {title && (
        <div className={clsx(classes.head, backgroundClasses[headerColor])}>
          {icon}
          <Typography color="inherit" level="small" bold>
            {title}
          </Typography>
        </div>
      )}
      <div className={clsx(classes.body)}>{children}</div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
  width: PropTypes.oneOf(['extended', 'medium']),
  headerColor: PropTypes.oneOf(colorReference),
};

Card.defaultProps = {
  icon: <div />,
  className: '',
  headerColor: 'platinum4',
  width: 'medium',
};

export default Card;
