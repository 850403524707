import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './RightPanel.style';
import useGraphQLQuery from 'hooks/useGraphQLQuery';
import activityQuery from 'components/Activity/activityQuery';
import { getPastTasks } from 'components/Activity/activityService';
import FamilyFileHistory from 'components/Activity/FamilyFileHistory/FamilyFileHistory';
import Loading from 'components/Loading/Loading';

const RightPanel = ({ labels, familyFileId }) => {
  const classes = useStyles();
  const { loading, error, data } = useGraphQLQuery(activityQuery, {
    variables: { familyFileId },
  });

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;
  const pastActions = getPastTasks(data.findFamilyFileById.actions);

  return (
    <div className={classes.right_panel_container}>
      <FamilyFileHistory titleLevel="h4" labels={labels} items={pastActions} />
    </div>
  );
};

RightPanel.propTypes = {
  familyFileId: PropTypes.number,
  labels: PropTypes.object,
};

RightPanel.defaultProps = {};

export default RightPanel;
