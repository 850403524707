import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './LocationInfo.styles';
import Typography from 'common/Typography/Typography';
import Toast from 'common/Toast/Toast';
import { cloneDeep } from 'lodash';
import LocationFilter from 'common/Filters/LocationFilter/LocationFilter';
import DistanceFilter from 'common/Filters/DistanceFilter/DistanceFilter';
import findCommunitiesService from 'services/callCenter/findCommunitiesService';

const LocationInfo = ({
  preferredLocation,
  labels,
  answer,
  onChange,
  loadLastSearch,
}) => {
  const classes = useStyles();

  const newPreferredLocation = answer?.userInputValues?.location
    ? answer.userInputValues.location
    : preferredLocation.locationValues;
  const newDistance = answer?.userInputValues?.distance
    ? answer.userInputValues.distance
    : preferredLocation.distance;
  const newHasCommunities = answer?.userInputValues
    ? answer.userInputValues.hasCommunities
    : true;

  const [locationState, setLocationState] = useState(newPreferredLocation);
  const [distanceState, setDistanceState] = useState(newDistance);
  const [hasCommunitiesState, setHasCommunitiesState] = useState(
    newHasCommunities,
  );

  const findOwlCommunities = async (location, distance) => {
    if (distance === distanceState && location === locationState) return;

    const distanceParsed = parseFloat(cloneDeep(distance));
    const distanceValue = distanceParsed > 0 ? distanceParsed : 300;

    const variables = {
      zip: location.zip,
      distance: distanceValue,
    };
    var response = await findCommunitiesService.findOwlCommunities(variables);

    const hasCommunities = response.error
      ? false
      : response.data?.data?.findOwlCommunities?.length > 0;

    const userInputValues = {
      location,
      distance: distanceValue.toString(),
      hasCommunities,
    };

    onChange({ userInputValues });
    setLocationState(location);
    setDistanceState(distance);
    setHasCommunitiesState(hasCommunities);
  };

  const normalFlow = (
    <Typography
      level="small"
      color="eerieBlack1"
      className={classes.where_looking_text}
    >
      {labels.WHERE_LOOKING}
    </Typography>
  );

  const notFoundCommunities = (
    <Fragment>
      <Toast type="error">
        <Typography>{labels.TOAST_NO_PARTNER_COMMUNITIES}</Typography>
      </Toast>
      <Typography className={classes.no_partner_script}>
        {labels.SCRIPT_NO_PARTNER_COMMUNITIES}
        <strong>{locationState.address}</strong>
      </Typography>
      <Typography>{labels.SCRIPT_WOULD_LIKE_ANOTHER_LOCATION}</Typography>
    </Fragment>
  );

  const preferredLocations = () => {
    const locations = [];
    if (
      preferredLocation.locationValues.zip !== '' &&
      preferredLocation.locationValues.zip !== undefined
    ) {
      locations.push({
        label: preferredLocation.locationValues.address,
        value: preferredLocation.locationValues.zip,
        country: preferredLocation.locationValues.country,
      });
    }
    return locations;
  };

  return (
    <div className={classes.location_main_container}>
      {hasCommunitiesState ? normalFlow : notFoundCommunities}
      <div className={classes.location_child_container}>
        <div className={classes.location_place_container}>
          <LocationFilter
            preferredLocations={preferredLocations()}
            onChange={(location) => findOwlCommunities(location, distanceState)}
            className={'search_box'}
            selectedValue={locationState.zip ? locationState.address : ''}
            labels={labels}
            loadLastSearch={loadLastSearch}
          />
        </div>
        <div className={classes.distance_container}>
          <DistanceFilter
            className={classes.distance_box}
            labels={labels}
            placeholder={labels.DISTANCE_RANGE_WITHIN}
            onChange={(distance) => findOwlCommunities(locationState, distance)}
            selectedValue={distanceState}
          />
        </div>
      </div>
    </div>
  );
};
LocationInfo.propTypes = {
  preferredLocation: PropTypes.object,
  labels: PropTypes.object,
  answer: PropTypes.object,
  onChange: PropTypes.func,
  step: PropTypes.object,
  loadLastSearch: PropTypes.bool,
};

LocationInfo.defaultProps = {
  loadLastSearch: false,
};

export default LocationInfo;
