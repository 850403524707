import PropTypes from 'prop-types';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { PhoneContext } from '@aplaceformom/owl-connect-client';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { selectCatalogs } from 'stateManagement/callcenter/catalogs/reducers/catalogsReducer';
import inquiryService from 'services/callCenter/inquiryService';
import inquirySearchService from 'services/callCenter/inquirySearchService';
import { useStyles } from './InquirySearch.style';
import Table from 'common/Table/Table/Table';
import TableBody from 'common/Table/TableBody/TableBody';
import TableHead from 'common/Table/TableHead/TableHead';
import TableRow from 'common/Table/TableRow/TableRow';
import TableCell from 'common/Table/TableCell/TableCell';
import Home from 'common/Icons/basic/Home';
import Settings from 'common/Icons/basic/Settings';
import ClipboardText from 'common/Icons/file/ClipboardText';
import ClipboardDelete from 'common/Icons/file/ClipboardDelete';
import Typography from 'common/Typography/Typography';
import HeaderTitle from 'components/HeaderTitle/HeaderTitle';
import TextInput from 'common/TextInput/TextInput';
import DatePicker from 'common/DatePicker/DatePicker';
import Search from 'common/Icons/basic/Search';
import CallNo from 'common/Icons/call/CallNo';
import CallPhone from 'common/Icons/call/CallPhone';
import Button from 'common/Button/Button';
import Tag from 'common/Tag/Tag';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import { parsePhoneNumber } from 'libphonenumber-js';
import {
  formatPhoneWithoutPlusOne,
  formatPhoneWithParenthesis,
} from 'utils/phoneFormat';
import {
  cancelPendingInquiries,
  findInquiriesByParamsRequest,
} from 'stateManagement/callcenter/inquiry/actions/inquiryActions';
import { selectFindInquiriesByParams } from 'stateManagement/callcenter/inquiry/reducers/inquiryReducer';
import Pagination from 'common/Pagination/Pagination';
import Dropdown from 'common/Dropdown/Dropdown';
import ChevronBottom from 'common/Icons/arrow/ChevronBottom';
import DropdownPopover from 'common/Dropdown/DropdownPopover/DropdownPopover';
import ChevronTop from 'common/Icons/arrow/ChevronTop';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import Loading from 'components/Loading/Loading';
import UhOhThereWasAProblem from 'common/Graphics/UhOhThereWasAProblem';
import NoInquiriesFound from 'common/Graphics/NoInquiriesFound';
import Link from 'common/Link/Link';
import { mapAudience } from 'services/callCenter/audienceService';
import { format } from 'date-fns';
import Toast from 'common/Toast/Toast';
import { cloneDeep } from 'lodash';
import Alert from 'common/Alert/Alert';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { withAuthenticationContext } from 'context/Authentication';
import { withLabels } from 'context/LabelContext';

import useGraphQLMutation from 'hooks/useGraphQLMutation';
import upsertDoNotCallMutation from 'services/callCenter/Mutations/upsertDoNotCallMutation';
import closeInquiryMutation from 'services/callCenter/Mutations/closeInquiryMutation';
import { sentryExceptionWithData } from 'utils/sentryExceptionWithData';

const InquirySearch = ({ labels, flags }) => {
  //#region Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    addNumberToDnc,
    removeNumbersFromDnc,
    deleteCallsFromLists,
  } = useContext(PhoneContext);
  const [upsertDoNotCallQL] = useGraphQLMutation(upsertDoNotCallMutation);
  const [closeInquiry] = useGraphQLMutation(closeInquiryMutation);

  const itemsMenu = [
    {
      icon: <Home />,
      label: labels.DASHBOARD,
      to: '/callcenter/home',
    },
    {
      label: labels.INQUIRY_SEARCH,
      to: '#',
    },
  ];

  const INPUT_DATE_FORMAT = 'EEE, MMM d @ h:mm a';
  let today = new Date();
  const lastYearDate = new Date(
    today.getFullYear() - 1,
    today.getMonth(),
    today.getDate(),
    0,
    0,
    0,
  );
  lastYearDate.setDate(lastYearDate.getDate() + 1);

  const getMinDate = (fromDate) => {
    const oneYearAgoDate = new Date(
      fromDate.getFullYear() - 1,
      fromDate.getMonth(),
      fromDate.getDate(),
      0,
      0,
      0,
    );
    oneYearAgoDate.setDate(oneYearAgoDate.getDate() + 1);
    return oneYearAgoDate;
  };
  const getMaxDate = (fromDate) => {
    const oneYearForward = new Date(
      fromDate.getFullYear() + 1,
      fromDate.getMonth(),
      fromDate.getDate(),
      0,
      0,
      0,
    );
    oneYearForward.setDate(oneYearForward.getDate() - 1);
    return oneYearForward;
  };

  const PAGE_SIZE_ITEMS = [
    { label: '10', value: '10' },
    { label: '25', value: '25' },
    { label: '50', value: '50' },
    { label: '100', value: '100' },
  ];
  const DEFAULT_PAGE_SIZE_ITEM = PAGE_SIZE_ITEMS[0];

  const { catalogs } = useSelector(selectCatalogs);
  const {
    inquiriesByParams,
    inquiriesByParamsError,
    inquiriesByParamsLoading,
  } = useSelector(selectFindInquiriesByParams);

  const [pageSizeItem, setPageSizeItem] = useState(DEFAULT_PAGE_SIZE_ITEM);
  const inquiriesResult = inquiriesByParams?.inquiries;
  const countResult = inquiriesByParams?.inquirySearchParam?.count || 0;
  const [inquiryResultToShow, setInquiryResultToShow] = useState(
    inquiriesResult,
  );

  const [isOpen, setIsOpen] = useState(true);
  const [searchButtonType, setSearchButtonType] = useState('disabled');
  const [confirmButton, setConfirmButton] = useState('');
  const defaultSearchCriteria = {
    firstName: '',
    phoneNumber: '',
    inquiryId: '',
    lastName: '',
    email: '',
    oneId: '',
    familyFileId: '',
    inquiryDateRangeFrom: lastYearDate,
    inquiryDateRangeTo: today,
    size: 10,
    page: 1,
  };
  const [searchCriteria, setSearchCriteria] = useState(defaultSearchCriteria);
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [currentCloseReason, setCurrentCloseReason] = useState('');
  const [showAddRemovePhoneAlert, setShowAddRemovePhoneAlert] = useState({
    showAlert: false,
    row: null,
  });
  const [showRemoveInquiryAlert, setShowRemoveInquiryAlert] = useState({
    showAlert: false,
    row: null,
  });

  useEffect(() => {
    if (inquiriesResult) {
      setInquiryResultToShow(inquiriesResult);
    }
  }, [inquiriesResult]);
  //#endregion Hooks

  if (!flags.inquirySearchIcon) {
    const errorMessage = new Error(
      'Error 401: Unauthorized Role - Inquiry search icon feature flag is TURNED OFF',
    );
    sentryExceptionWithData(errorMessage, {
      inquirySearchIconFlag: flags.inquirySearchIcon,
    });

    return <Redirect to="/error" />;
  }

  const isSearchButtonEnabled = (newSearchCriteria) => {
    //#region non-date
    if (
      newSearchCriteria.firstName !== null &&
      newSearchCriteria.firstName !== '' &&
      newSearchCriteria.firstName.length > 1
    ) {
      setSearchButtonType('primary');
      return;
    }
    if (
      newSearchCriteria.phoneNumber !== null &&
      newSearchCriteria.phoneNumber !== ''
    ) {
      setSearchButtonType('primary');
      return;
    }
    if (
      newSearchCriteria.inquiryId !== null &&
      newSearchCriteria.inquiryId !== '' &&
      !isNaN(newSearchCriteria.inquiryId)
    ) {
      setSearchButtonType('primary');
      return;
    }
    if (
      newSearchCriteria.lastName !== null &&
      newSearchCriteria.lastName !== '' &&
      newSearchCriteria.lastName.length > 1
    ) {
      setSearchButtonType('primary');
      return;
    }

    if (newSearchCriteria.email !== null && newSearchCriteria.email !== '') {
      setSearchButtonType('primary');
      return;
    }

    if (newSearchCriteria.oneId !== null && newSearchCriteria.oneId !== '') {
      setSearchButtonType('primary');
      return;
    }
    if (
      newSearchCriteria.familyFileId !== null &&
      newSearchCriteria.familyFileId !== '' &&
      newSearchCriteria.familyFileId.match(/\d+$/) &&
      !isNaN(newSearchCriteria.inquiryId)
    ) {
      setSearchButtonType('primary');
      return;
    }
    //#endregion

    setSearchButtonType('disabled');
  };

  //#region Event Handlers
  const handleChange = (id, value) => {
    if (value === 0) {
      value = '';
    }
    const newSearchCriteria = { ...searchCriteria, [id]: value };

    setSearchCriteria(newSearchCriteria);
    isSearchButtonEnabled(newSearchCriteria);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && searchButtonType !== 'disabled') {
      doSearching();
    }
  };

  const handlePageNumberChange = (pageNumber) => {
    setInquiryResultToShow([]);
    const newSearchCriteria = { ...searchCriteria, page: pageNumber };
    setSearchCriteria(newSearchCriteria);
    dispatch(cancelPendingInquiries());
    dispatch(findInquiriesByParamsRequest(newSearchCriteria));
  };

  const handlePageSizeChange = (newPageSizeItem) => {
    setPageSizeItem(newPageSizeItem);
    const newSearchCriteria = {
      ...searchCriteria,
      page: 1,
      size: parseInt(newPageSizeItem.value, 10),
    };
    setSearchCriteria(newSearchCriteria);
    dispatch(cancelPendingInquiries());
    dispatch(findInquiriesByParamsRequest(newSearchCriteria));
  };
  //#endregion Event Handlers

  //#region Utility Functions
  const getPrimaryContact = (inquiryResult) => {
    return (
      <Fragment>
        <div className={classes.contact_cell}>
          <Typography
            bold
          >{`${inquiryResult.firstName} ${inquiryResult.lastName}`}</Typography>
          {catalogs && (
            <Typography>{`(${inquiryService.getInfo(
              inquiryResult,
              'relation',
              labels,
              catalogs?.familyRelationships,
            )})`}</Typography>
          )}
        </div>
        <div className={classes.contact_info}>
          <Typography color="eerieBlack5">{`${labels.HOME}:`}</Typography>
          &nbsp;
          <Typography>
            {formatPhoneWithParenthesis(
              parsePhoneNumber(inquiryResult.phone, 'US').formatNational(),
            )}
          </Typography>
        </div>
        {inquiryResult.email && (
          <div className={classes.contact_info}>
            <Typography color="eerieBlack5">{`${labels.EMAIL}:`}</Typography>
            &nbsp;
            <Typography>{inquiryResult.email}</Typography>
          </div>
        )}
        <div className={classes.contact_info}>
          <Typography color="eerieBlack5">{`${labels.ONEID}:`}</Typography>
          &nbsp;
          <Typography>{inquiryResult.oneId}</Typography>
        </div>
        {inquiryResult.familyFileId && (
          <div className={classes.contact_info}>
            <Typography color="eerieBlack5">{`${labels.FAMILY_FILE_ID}:`}</Typography>
            &nbsp;
            <Typography> {inquiryResult.familyFileId}</Typography>
          </div>
        )}
      </Fragment>
    );
  };

  const getLastContacted = (row) => {
    const data = row?.lastContactedDates ? row.lastContactedDates : false;
    if (data) {
      const dataRow = inquirySearchService.buildCallAttemptSearchDetails(data);

      return (
        <Fragment>
          <div className={classes.last_contacted}>
            <div className="contact_action">
              <Typography bold>{dataRow.dispositionText}</Typography>
            </div>
            <div>{dataRow.lastContactedDate}</div>
            <div>
              Call Attempts:
              <strong>{dataRow.attemptNumber}</strong>
            </div>
            <div>
              Call ID: <strong>{dataRow.callId}</strong>
            </div>
            <div></div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className={classes.last_contacted}>Not contacted yet.</div>
        </Fragment>
      );
    }
  };

  const doSearching = () => {
    setInquiryResultToShow([]);
    dispatch(cancelPendingInquiries());
    dispatch(findInquiriesByParamsRequest(searchCriteria));
    setShowToastMessage(false);
    setToastMessage('');
  };

  const doClearing = () => {
    setSearchCriteria(defaultSearchCriteria);
    isSearchButtonEnabled(defaultSearchCriteria);
  };

  const getTag = (row) => {
    const audienceTag = mapAudience(row?.audienceKey) && (
      <Tag
        className={classes.audience_tag}
        text={labels[mapAudience(row?.audienceKey).label]}
        color={
          mapAudience(row?.audienceKey).urgent ? 'smokyTopaz5' : 'cosmicCobalt5'
        }
        icon={mapAudience(row?.audienceKey).icon}
      />
    );
    return audienceTag;
  };
  const updateInquiryResultToShow = (phone, isDnc) => {
    const inquiries = cloneDeep(inquiryResultToShow);
    inquiries.forEach((inquiry) => {
      if (inquiry.phone === phone) {
        inquiry.isDnc = !isDnc;
      }
    });
    setInquiryResultToShow(inquiries);
  };
  const addOrRemoveToDNCList = async () => {
    const { phone, isDnc, inquiryId } = showAddRemovePhoneAlert.row;
    const formattedPhone = parsePhoneNumber(phone, 'US').formatNational();
    let message = `There was an issue ${
      isDnc ? 'removing' : 'adding'
    } ${formattedPhone} to the Do Not Call list. Please try again.`;
    const variables = {
      inquiryId: inquiryId,
      activeStatus: !isDnc,
      phoneNumber: phone,
    };

    try {
      if (isDnc) {
        const responseRemove = await removeNumbersFromDnc([
          formatPhoneWithoutPlusOne(phone),
        ]);
        if (responseRemove?.data > 0) {
          message = `${formattedPhone} was successfully 
              removed from the Do Not Call list`;
          updateInquiryResultToShow(phone, isDnc);
        }
      } else {
        const responseAdd = await addNumberToDnc(formattedPhone);
        if (responseAdd?.data) {
          message = `${formattedPhone} was successfully 
              added to the Do Not Call list`;
          updateInquiryResultToShow(phone, isDnc);
        }
      }

      await upsertDoNotCallQL({ variables });
      setShowToastMessage(true);
      setToastMessage(message);
    } catch (e) {
      setShowToastMessage(true);
      setToastMessage(message);
    }
    setShowAddRemovePhoneAlert({ showAlert: false, row: null });
  };

  const getToastMessage = () => {
    const type = toastMessage.toLowerCase().includes('successfully')
      ? 'success'
      : 'error';
    return (
      <Toast type={type} className={classes.toast_message} onClose={() => {}}>
        <Typography>{toastMessage}</Typography>
      </Toast>
    );
  };

  const getDescription = (description) => {
    return (
      <div className={classes.alert_description}>
        <span dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    );
  };

  const showAlertModal = () => {
    const phone = showAddRemovePhoneAlert?.row?.phone;
    const isDnc = showAddRemovePhoneAlert?.row?.isDnc;
    const formattedPhone = phone
      ? parsePhoneNumber(phone, 'US').formatNational()
      : '';

    const title = isDnc
      ? labels.REMOVE_PHONE_FROM_DNC
      : labels.ADD_PHONE_TO_DNC;
    const descriptionTemplate = isDnc
      ? labels.REMOVE_PHONE_CONFIRM
      : labels.ADD_PHONE_CONFIRM;
    const descriptionWithPhone = descriptionTemplate.replace(
      '{PHONE}',
      `<strong>${formattedPhone}</strong>`,
    );
    const finalDescription = getDescription(descriptionWithPhone);

    return (
      <Alert
        onClose={() =>
          setShowAddRemovePhoneAlert({ showAlert: false, row: null })
        }
        onConfirm={addOrRemoveToDNCList}
        title={title}
        type="delete"
        isOpen={showAddRemovePhoneAlert.showAlert}
        cancelText={labels.CANCEL}
        confirmText={labels.CONFIRM}
        descriptionObj={finalDescription}
      />
    );
  };

  const closeAndRemoveInquiry = async () => {
    const { phone, isQueued, status } = showRemoveInquiryAlert.row;
    let message = `There was an issue closing / removing from call queue inquiry #${showRemoveInquiryAlert?.row?.inquiryId}. Please try again.`;
    const variablesInquiryCloseInput = {
      variables: {
        inquiryId: showRemoveInquiryAlert?.row?.inquiryId,
        status: 'closed',
        closedReason: currentCloseReason,
      },
    };

    try {
      if (status === 'open' && isQueued) {
        await deleteCallsFromLists(formatPhoneWithoutPlusOne(phone));
        const response = await closeInquiry(variablesInquiryCloseInput);
        if (response.data) {
          showRemoveInquiryAlert.row.status = 'closed';
          showRemoveInquiryAlert.row.isQueued = false;
          message = `Inquiry #${showRemoveInquiryAlert?.row?.inquiryId} was successfully removed from call queue and closed.`;
        }
      } else if (status === 'open') {
        const response = await closeInquiry(variablesInquiryCloseInput);
        if (response.data) {
          showRemoveInquiryAlert.row.status = 'closed';
          message = `Successfully closed inquiry #${showRemoveInquiryAlert?.row?.inquiryId}.`;
        } else {
          message = response.data.closeInquiry.error;
        }
      } else {
        message = `There was an issue closing / removing from call queue for inquiry #${showRemoveInquiryAlert?.row?.inquiryId}. Please try again.`;
      }
      const inquiries = cloneDeep(inquiryResultToShow);

      setInquiryResultToShow(inquiries);
      setShowToastMessage(true);
      setToastMessage(message);
    } catch (e) {
      setShowToastMessage(true);
      setToastMessage(message);
    }
    setShowRemoveInquiryAlert({ showAlert: false, row: null });
  };

  const CLOSE_REASONS = catalogs?.closeReasons
    .map((c) => ({
      value: c.formEnumFieldValueKey,
      label: c.formEnumFieldValueLabel,
    }))
    .sort((a, b) => (a.last_nom > b.label ? 1 : b.label > a.label ? -1 : 0));

  const changeValue = (id, value) => {
    setCurrentCloseReason(value);
    if (value) {
      setConfirmButton(`${labels.CONFIRM}`);
    } else {
      setConfirmButton('');
    }
  };

  const showCloseInquiryOptions = () => {
    const inquiryName = `${showRemoveInquiryAlert?.row?.firstName} ${showRemoveInquiryAlert?.row?.lastName}`;
    const inquiryId = `${showRemoveInquiryAlert?.row?.inquiryId}`;
    const descriptionTemplate = showRemoveInquiryAlert.row?.isQueued
      ? labels.REMOVE_CALL_QUEUE_AND_CLOSE_CONFIRM
      : labels.CLOSE_INQUIRY_CONFIRM;
    const descriptionWithName = descriptionTemplate
      .replace('{NAME}', `<strong>${inquiryName}'s</strong>`)
      .replace('{INQUIRYID}', `<strong>${inquiryId}</strong>`);
    const finalDescription = getDescription(descriptionWithName);
    return (
      <Fragment>
        <Typography color="eerieBlack5">{finalDescription}</Typography>
        <Dropdown
          className={classes.close_reason}
          dropdownClass={classes.close_reason_dropdown}
          items={CLOSE_REASONS}
          label={labels.CLOSE_INQUIRY_REASON}
          selectedItem={currentCloseReason}
          onChange={(item) => changeValue('closeReason', item.value)}
          useSetState={false}
          isMandatory
        />
      </Fragment>
    );
  };

  const showAlertModalCloseAndRemoveInquiry = () => {
    let title = showRemoveInquiryAlert.row?.isQueued
      ? labels.CLOSE_INQUIRY_REMOVE_FROM_CALL_QUEUE
      : labels.CLOSE_INQUIRY;
    if (showRemoveInquiryAlert.row?.lastContactedDates) {
      return (
        <Alert
          onClose={() =>
            setShowRemoveInquiryAlert({ showAlert: false, row: null })
          }
          title={title}
          description={labels.INQUIRY_CONTACTED_MODAL}
          type="delete"
          isOpen={showRemoveInquiryAlert.showAlert}
        />
      );
    } else {
      return (
        <Alert
          onClose={() =>
            setShowRemoveInquiryAlert({ showAlert: false, row: null })
          }
          onConfirm={closeAndRemoveInquiry}
          title={title}
          modalBody={showCloseInquiryOptions()}
          type="delete"
          isOpen={showRemoveInquiryAlert.showAlert}
          cancelText={labels.CANCEL}
          confirmText={confirmButton}
        />
      );
    }
  };

  const showDropDownPopOver = (row) => {
    return (
      <DropdownPopover
        popoverClassName={classes.dropDown_pop_over}
        itemsClassName={classes.items_pop_over}
        items={[
          {
            action: () => {
              setShowToastMessage(false);
              setShowAddRemovePhoneAlert({
                showAlert: true,
                row,
              });
            },

            label: (
              <>
                {row?.isDnc ? <CallNo /> : <CallPhone />}
                {row?.isDnc
                  ? labels.REMOVE_PHONE_FROM_DNC
                  : labels.ADD_PHONE_TO_DNC}
              </>
            ),
          },
          {
            action: () => {
              setShowToastMessage(false);
              setConfirmButton('');
              setCurrentCloseReason('');
              setShowRemoveInquiryAlert({
                showAlert: true,
                row,
              });
            },

            label: (
              <>
                {row.status === 'closed' && !row.isQueued ? (
                  ''
                ) : (
                  <ClipboardDelete />
                )}
                {row.status === 'open' && row.isQueued
                  ? labels.CLOSE_INQUIRY_REMOVE_FROM_CALL_QUEUE
                  : !row.isQueued && row.status === 'closed'
                  ? ''
                  : labels.CLOSE_INQUIRY}
              </>
            ),
          },
        ]}
        toolTipContent={'Action'}
        clickableElement={<Settings className={classes.settings} />}
        placement={'right'}
      />
    );
  };

  const statusDetails = (row) => {
    if (
      !row.statusDetail ||
      (row.status === 'closed' && row.statusDetail === 'CLOSED')
    ) {
      return null;
    }
    return <Tag text={row.statusDetail} color="wintergreenDream5" />;
  };

  //#region Render
  return (
    <Fragment>
      <div className={classes.main_container}>
        {showAddRemovePhoneAlert && showAlertModal()}
        {showRemoveInquiryAlert && showAlertModalCloseAndRemoveInquiry()}
        <div className={classes.dashboard_container}>
          <Breadcrumb separator="/" items={itemsMenu} />
        </div>
        <div>
          <Typography className={classes.header_title} bold>
            {labels.INQUIRY_SEARCH}
            <div
              onClick={() => setIsOpen(!isOpen)}
              className={classes.header_title_icon}
            >
              {isOpen ? (
                <ChevronTop className={classes.chevron} />
              ) : (
                <ChevronBottom className={classes.chevron} />
              )}
            </div>
          </Typography>
        </div>
        {isOpen && (
          <div className={classes.filter_container}>
            <div className={classes.filter_row}>
              <div className={classes.names_container} id="inputNames">
                <Typography className={classes.filter_label} bold>
                  {labels.FIRST_NAME}
                </Typography>
                <TextInput
                  className={classes.filter_input}
                  id="firstName"
                  value={searchCriteria.firstName}
                  onChange={(value) => handleChange('firstName', value)}
                  autoComplete="off"
                  onKeyPress={(value) => handleKeyPress(value)}
                />
              </div>
              <div className={classes.names_container} id="inputNames">
                <Typography className={classes.filter_label} bold>
                  {labels.PHONE_NUMBER}
                </Typography>
                <TextInput
                  type="phoneword"
                  className={classes.filter_input}
                  id="phoneNumber"
                  value={searchCriteria.phoneNumber}
                  onChange={(value) => handleChange('phoneNumber', value)}
                  autoComplete="off"
                  onKeyPress={(value) => handleKeyPress(value)}
                />
              </div>
              <div className={classes.names_container} id="inputNames">
                <Typography className={classes.filter_label} bold>
                  {labels.INQUIRY_ID}
                </Typography>
                <TextInput
                  type="number"
                  className={classes.filter_input}
                  id="inquiryId"
                  value={searchCriteria.inquiryId}
                  onChange={(value) => handleChange('inquiryId', value)}
                  autoComplete="off"
                  onKeyPress={(value) => handleKeyPress(value)}
                />
              </div>
            </div>
            <div className={classes.filter_row}>
              <div className={classes.names_container} id="inputNames">
                <Typography className={classes.filter_label} bold>
                  {labels.LAST_NAME}
                </Typography>
                <TextInput
                  className={classes.filter_input}
                  id="lastName"
                  value={searchCriteria.lastName}
                  onChange={(value) => handleChange('lastName', value)}
                  autoComplete="off"
                  onKeyPress={(value) => handleKeyPress(value)}
                />
              </div>
              <div className={classes.names_container} id="inputNames">
                <Typography className={classes.filter_label} bold>
                  {labels.EMAIL}
                </Typography>
                <TextInput
                  className={classes.filter_input}
                  id="email"
                  value={searchCriteria.email}
                  onChange={(value) => handleChange('email', value)}
                  autoComplete="off"
                  onKeyPress={(value) => handleKeyPress(value)}
                />
              </div>
              <div className={classes.names_container} id="inputNames">
                <Typography className={classes.filter_label} bold>
                  {labels.ONE_ID}
                </Typography>
                <TextInput
                  type="number"
                  className={classes.filter_input}
                  id="oneId"
                  value={searchCriteria.oneId}
                  onChange={(value) => handleChange('oneId', value)}
                  autoComplete="off"
                  onKeyPress={(value) => handleKeyPress(value)}
                />
              </div>
            </div>
            <div className={classes.filter_row}>
              <div className={classes.filter_row_date_range}>
                <Typography className={classes.filter_label} bold>
                  {labels.INQUIRY_DATE_RANGE}
                </Typography>
                <DatePicker
                  id={'dateFrom'}
                  dateSelected={searchCriteria?.inquiryDateRangeFrom}
                  onChange={(value) =>
                    handleChange('inquiryDateRangeFrom', value)
                  }
                  minDate={getMinDate(searchCriteria?.inquiryDateRangeTo)}
                  maxDate={searchCriteria?.inquiryDateRangeTo}
                  disableDatesTooltipTitle={labels.DATE_RANGE_LIMITATION_1_YR}
                  label=""
                />
                <Typography
                  className={classes.filter_label}
                  color="cosmicCobalt3"
                >
                  -
                </Typography>
                <DatePicker
                  id={'dateTo'}
                  dateSelected={searchCriteria.inquiryDateRangeTo}
                  onChange={(value) =>
                    handleChange('inquiryDateRangeTo', value)
                  }
                  minDate={searchCriteria?.inquiryDateRangeFrom}
                  maxDate={getMaxDate(searchCriteria?.inquiryDateRangeFrom)}
                  disableDatesTooltipTitle={labels.DATE_RANGE_LIMITATION_1_YR}
                  label=""
                />
              </div>
              <div className={classes.names_container} id="inputNames">
                <Typography className={classes.filter_label} bold>
                  {labels.FAMILY_FILE_ID}
                </Typography>
                <TextInput
                  type="number"
                  className={classes.filter_input}
                  id="familyFileId"
                  value={searchCriteria.familyFileId}
                  onChange={(value) => handleChange('familyFileId', value)}
                  autoComplete="off"
                  onKeyPress={(value) => handleKeyPress(value)}
                />
              </div>
            </div>

            <div className={classes.filter_button}>
              <Fragment>
                <div className={classes.button_clear}>
                  <Button type="light" onClick={() => doClearing()}>
                    {labels.CLEAR}
                  </Button>
                </div>
                <div>
                  <Button
                    type={searchButtonType}
                    startIcon={<Search />}
                    onClick={() => doSearching()}
                  >
                    {labels.SEARCH}
                  </Button>
                </div>
              </Fragment>
            </div>
          </div>
        )}
        {(inquiryResultToShow !== undefined || inquiriesByParamsLoading) && (
          <Fragment>
            <div>
              <HeaderTitle
                title={labels.SEARCH_RESULTS}
                titleLevel="h4"
                className={classes.header_result_title}
              />
              {!inquiriesByParamsLoading && (
                <div className={classes.header_result_sub_title}>
                  <Typography bold>
                    {`${countResult} ${labels.INQUIRY_RESULT}`}
                  </Typography>
                  &nbsp;
                  <Typography color="eerieBlack5">
                    {labels.THAT_MEET_YOUR_SEARCH_CRITERIA}
                  </Typography>
                </div>
              )}
              <div>{showToastMessage && getToastMessage()}</div>
              {inquiryResultToShow?.length > 0 && (
                <div className={classes.page_size_container}>
                  <div>
                    <Typography
                      className={classes.page_size_label}
                      color="eerieBlack5"
                      level="small"
                    >
                      {labels.RESULTS_PER_PAGE}
                    </Typography>
                    <Dropdown
                      className={classes.page_size}
                      items={PAGE_SIZE_ITEMS}
                      onChange={(item) => handlePageSizeChange(item)}
                      selectedItem={pageSizeItem}
                    />
                  </div>
                </div>
              )}
            </div>

            <Table className={classes.home_table}>
              <TableHead className={classes.home_table_header}>
                <TableRow className={classes.home_table_header_row}>
                  <TableCell>{labels.INQUIRY_ID}</TableCell>
                  <TableCell>{labels.PRIMARY_CONTACT}</TableCell>
                  <TableCell>{labels.LAST_CONTACTED}</TableCell>
                  <TableCell>{labels.TAGS}</TableCell>
                  <TableCell>{labels.STATUS_DETAILS}</TableCell>
                  <TableCell>{labels.ACTIONS}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inquiryResultToShow?.map((row, index) => (
                  <TableRow key={index} className={classes.home_table_row}>
                    <TableCell>
                      {row.inquiryId}
                      <div>
                        <Typography color="eerieBlack5">Created:</Typography>
                        <Typography>
                          {format(row.createdAt, INPUT_DATE_FORMAT)}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>{getPrimaryContact(row)}</TableCell>
                    <TableCell>{getLastContacted(row)}</TableCell>
                    <TableCell>
                      {getTag(row)}
                      {row?.isDnc === true && (
                        <Tag
                          className="dnc"
                          text="DNC"
                          color="smokyTopaz2"
                          icon={<CallNo />}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {row.status === 'open' ? (
                        <Tag text="Open" color="wintergreenDream2" />
                      ) : (
                        <Tag text="Closed" color="smokyTopaz2" />
                      )}
                      {row.isQueued === true ? (
                        <SmartTooltip title={row.listName} placement="bottom">
                          <Tag text="Queued" color="wintergreenDream5" />
                        </SmartTooltip>
                      ) : (
                        <Tag text="Not Queued" color="hotCinnamon3" />
                      )}
                      {statusDetails(row)}
                      {row.status === 'closed' &&
                      row.closedReason &&
                      row.closedReason.length < 15 ? (
                        <Tag text={row.closedReason} color="smokyTopaz5" />
                      ) : (
                        ''
                      )}
                      {row.status === 'closed' &&
                      row.closedReason &&
                      row.closedReason.length >= 15 ? (
                        <SmartTooltip
                          title={row.closedReason}
                          placement="bottom"
                        >
                          <Tag
                            text={row.closedReason?.substr(0, 14)}
                            color="smokyTopaz5"
                          />
                        </SmartTooltip>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell>
                      <div className={classes.action}>
                        {flags.hasInquiryAdminAccess &&
                          showDropDownPopOver(row)}

                        <SmartTooltip
                          title={labels.INQUIRY_DETAILS}
                          placement="bottom"
                        >
                          <Link
                            to={`/callcenter/inquiry-details/${row.inquiryId}`}
                            openInNewTab={true}
                          >
                            <ClipboardText className={classes.clipboard_text} />
                          </Link>
                        </SmartTooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
                {inquiryResultToShow?.length > 0 &&
                  !inquiriesByParamsLoading &&
                  !inquiriesByParamsError && (
                    <TableRow
                      size="extended"
                      className={classes.pagination_row}
                    >
                      <TableCell colSpan={6}>
                        <Pagination
                          className={classes.pager}
                          currentPage={searchCriteria.page}
                          onPageChange={(pageNumber) =>
                            handlePageNumberChange(pageNumber)
                          }
                          pageCount={Math.ceil(
                            countResult / Number(pageSizeItem.value),
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                {inquiryResultToShow?.length === 0 &&
                  !inquiriesByParamsLoading &&
                  !inquiriesByParamsError && (
                    <TableRow className={classes.pagination_row_without_result}>
                      <TableCell colSpan={6}>
                        <NoInquiriesFound />
                        <Typography
                          level="large"
                          bold
                          className={classes.empty_page_text}
                        >
                          {labels.NO_INQUIRIES_FOUND}
                        </Typography>
                        <Typography level="body">
                          {labels.WE_COULDNT_FIND_WHAT_YOURE_LOOKING_FOR}
                        </Typography>
                        <Typography level="body">
                          {labels.PLEASE_TRY_ADJUSTING_YOUR_SEARCH}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}

                {inquiriesByParamsLoading === true && (
                  <TableRow className={classes.pagination_row}>
                    <TableCell colSpan={6}>
                      <Loading height="calc(100vh - 400px)" />
                    </TableCell>
                  </TableRow>
                )}
                {inquiriesByParamsError && !inquiriesByParamsLoading && (
                  <TableRow className={classes.pagination_row_without_result}>
                    <TableCell colSpan={6}>
                      <UhOhThereWasAProblem />
                      <Typography
                        level="large"
                        bold
                        className={classes.empty_page_text}
                      >
                        {labels.UH_OH_THERE_WAS_A_PROBLEM}
                      </Typography>
                      <Typography level="body">
                        {labels.PLEASE_TRY_REFRESHING}
                      </Typography>
                      <div className={classes.error_message}>
                        <Typography level="body">
                          {labels.IF_THE_PROBLEM_PERSISTS}
                        </Typography>
                        &nbsp;
                        <Link to={'mailto:techsupport@aplaceformom.com'}>
                          <SmartTooltip
                            title={'techsupport@aplaceformom.com'}
                            placement="bottom"
                          >
                            <Typography className={classes.link}>
                              {labels.TECH_SUPPORT}
                            </Typography>
                          </SmartTooltip>
                        </Link>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
  //#endregion Render
};

//#region PropTypes & Defaults

InquirySearch.propTypes = {
  labels: PropTypes.object,
  flags: PropTypes.shape({
    inquirySearchIcon: PropTypes.bool,
    hasInquiryAdminAccess: PropTypes.bool,
  }),
};

//#endregion PropTypes & Defaults

export default withLDConsumer()(
  withAuthenticationContext(withLabels(InquirySearch)),
);
