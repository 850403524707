import React from 'react';

const Bedridden = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.2401 35.84C34.193 35.84 40.6401 29.3929 40.6401 21.44C40.6401 13.4871 34.193 7.03998 26.2401 7.03998C18.2872 7.03998 11.8401 13.4871 11.8401 21.44C11.8401 29.3929 18.2872 35.84 26.2401 35.84Z"
        fill="#F3F5FC"
      />
      <path
        d="M8.64 32.6399C8.28656 32.6399 8 32.3533 8 31.9999V30.7199C8 30.3665 8.28656 30.0799 8.64 30.0799C8.99344 30.0799 9.28 30.3665 9.28 30.7199V31.9999C9.28 32.3533 8.99344 32.6399 8.64 32.6399Z"
        fill="#0D1321"
      />
      <path
        d="M8.64 36.4799C8.28656 36.4799 8 36.1934 8 35.8399V34.5599C8 34.2065 8.28656 33.9199 8.64 33.9199C8.99344 33.9199 9.28 34.2065 9.28 34.5599V35.8399C9.28 36.1934 8.99344 36.4799 8.64 36.4799Z"
        fill="#0D1321"
      />
      <path
        d="M7.35994 33.92H6.07994C5.7265 33.92 5.43994 33.6334 5.43994 33.28C5.43994 32.9265 5.7265 32.64 6.07994 32.64H7.35994C7.71338 32.64 7.99994 32.9265 7.99994 33.28C7.99994 33.6334 7.71338 33.92 7.35994 33.92Z"
        fill="#0D1321"
      />
      <path
        d="M11.2 33.92H9.92003C9.56659 33.92 9.28003 33.6334 9.28003 33.28C9.28003 32.9265 9.56659 32.64 9.92003 32.64H11.2C11.5535 32.64 11.84 32.9265 11.84 33.28C11.84 33.6334 11.5535 33.92 11.2 33.92Z"
        fill="#0D1321"
      />
      <path
        d="M9.7535 12.8357C10.1314 12.8357 10.4377 12.5294 10.4377 12.1516C10.4377 11.7737 10.1314 11.4674 9.7535 11.4674C9.37565 11.4674 9.06934 11.7737 9.06934 12.1516C9.06934 12.5294 9.37565 12.8357 9.7535 12.8357Z"
        fill="#0D1321"
      />
      <path
        d="M20.2551 11.9494C19.4079 11.9494 18.6229 11.3993 18.3648 10.5478C18.212 10.0431 18.2645 9.50904 18.5133 9.04408C18.7621 8.57912 19.177 8.2388 19.6818 8.086C20.7234 7.76968 21.8277 8.36104 22.1434 9.40264C22.4591 10.4442 21.8683 11.5486 20.8267 11.8646C20.6367 11.9218 20.444 11.9494 20.2551 11.9494ZM20.2535 9.28088C20.1869 9.28088 20.1194 9.29064 20.0525 9.31096C19.8751 9.36472 19.7293 9.4844 19.6419 9.64776C19.5546 9.81112 19.536 9.99896 19.5898 10.1766C19.701 10.5428 20.0904 10.7513 20.4554 10.6394C20.8216 10.5286 21.0291 10.1401 20.9183 9.77384C20.8272 9.47432 20.5512 9.28088 20.2535 9.28088Z"
        fill="#0D1321"
      />
      <path
        d="M40.6668 25.5999H6.69306C6.0009 25.5999 5.43994 25.0388 5.43994 24.3468V23.2927C5.43994 21.7393 6.6993 20.4799 8.25274 20.4799H39.1071C40.6606 20.4799 41.9199 21.7393 41.9199 23.2927V24.3468C41.9199 25.0388 41.3588 25.5999 40.6668 25.5999Z"
        fill="#F7EED0"
      />
      <path
        d="M40.6669 26.2399H6.69317C5.64949 26.2399 4.80005 25.3905 4.80005 24.3468V23.2927C4.80005 21.389 6.34917 19.8399 8.25285 19.8399H39.1073C41.0109 19.8399 42.5601 21.389 42.5601 23.2927V24.3468C42.5601 25.3905 41.7106 26.2399 40.6669 26.2399ZM8.25285 21.1199C7.05477 21.1199 6.08005 22.0946 6.08005 23.2927V24.3468C6.08005 24.6849 6.35509 24.9599 6.69317 24.9599H40.6669C41.005 24.9599 41.2801 24.6849 41.2801 24.3468V23.2927C41.2801 22.0946 40.3053 21.1199 39.1073 21.1199H8.25285Z"
        fill="#0D1321"
      />
      <path
        d="M33.6 28.16H13.76C12.346 28.16 11.2 27.0139 11.2 25.6H36.16C36.16 27.0139 35.0139 28.16 33.6 28.16Z"
        fill="#C35A19"
      />
      <path
        d="M33.6001 28.8H13.7601C11.9957 28.8 10.5601 27.3643 10.5601 25.6C10.5601 25.2465 10.8466 24.96 11.2001 24.96H36.1601C36.5135 24.96 36.8001 25.2465 36.8001 25.6C36.8001 27.3643 35.3644 28.8 33.6001 28.8ZM11.9498 26.24C12.2138 26.9849 12.9258 27.52 13.7601 27.52H33.6001C34.4345 27.52 35.1463 26.9849 35.4103 26.24H11.9498Z"
        fill="#0D1321"
      />
      <path
        d="M15.0408 40.3199C14.8461 40.3199 14.6539 40.2315 14.5283 40.0639C14.3162 39.7811 14.3733 39.3799 14.6561 39.1679L30.0162 27.6479C30.3002 27.4355 30.7005 27.4935 30.9121 27.7758C31.1243 28.0587 31.0672 28.4598 30.7843 28.6718L15.4243 40.1918C15.309 40.2783 15.1742 40.3199 15.0408 40.3199Z"
        fill="#0D1321"
      />
      <path
        d="M13.12 42.88C14.1803 42.88 15.04 42.0204 15.04 40.96C15.04 39.8996 14.1803 39.04 13.12 39.04C12.0596 39.04 11.2 39.8996 11.2 40.96C11.2 42.0204 12.0596 42.88 13.12 42.88Z"
        fill="#A37D00"
      />
      <path
        d="M13.1201 43.52C11.7085 43.52 10.5601 42.3715 10.5601 40.96C10.5601 39.5484 11.7085 38.4 13.1201 38.4C14.5316 38.4 15.6801 39.5484 15.6801 40.96C15.6801 42.3715 14.5316 43.52 13.1201 43.52ZM13.1201 39.68C12.4141 39.68 11.8401 40.254 11.8401 40.96C11.8401 41.6659 12.4141 42.24 13.1201 42.24C13.826 42.24 14.4001 41.6659 14.4001 40.96C14.4001 40.254 13.826 39.68 13.1201 39.68Z"
        fill="#0D1321"
      />
      <path
        d="M31.6795 40.3199C31.5461 40.3199 31.4114 40.2783 31.2962 40.1918L15.9362 28.6718C15.6533 28.4599 15.5962 28.0587 15.8083 27.7758C16.0205 27.4935 16.4208 27.4355 16.7043 27.6479L32.0643 39.1679C32.3472 39.3798 32.4043 39.7811 32.1922 40.0639C32.0664 40.2315 31.8743 40.3199 31.6795 40.3199Z"
        fill="#0D1321"
      />
      <path
        d="M33.6002 42.88C34.6606 42.88 35.5202 42.0204 35.5202 40.96C35.5202 39.8996 34.6606 39.04 33.6002 39.04C32.5398 39.04 31.6802 39.8996 31.6802 40.96C31.6802 42.0204 32.5398 42.88 33.6002 42.88Z"
        fill="#A37D00"
      />
      <path
        d="M33.6 43.52C32.1885 43.52 31.04 42.3715 31.04 40.96C31.04 39.5484 32.1885 38.4 33.6 38.4C35.0116 38.4 36.16 39.5484 36.16 40.96C36.16 42.3715 35.0116 43.52 33.6 43.52ZM33.6 39.68C32.8941 39.68 32.32 40.254 32.32 40.96C32.32 41.6659 32.8941 42.24 33.6 42.24C34.306 42.24 34.88 41.6659 34.88 40.96C34.88 40.254 34.306 39.68 33.6 39.68Z"
        fill="#0D1321"
      />
      <path
        d="M18.88 20.48H9.28003V18.56C9.28003 17.4996 10.1397 16.64 11.2 16.64H18.88C19.9403 16.64 20.8 17.4996 20.8 18.56C20.8 19.6203 19.9403 20.48 18.88 20.48Z"
        fill="#F7EED0"
      />
      <path
        d="M18.8801 21.1199H9.28014C8.9267 21.1199 8.64014 20.8334 8.64014 20.4799V18.5599C8.64014 17.1484 9.78862 15.9999 11.2001 15.9999H18.8801C20.2917 15.9999 21.4401 17.1484 21.4401 18.5599C21.4401 19.9715 20.2917 21.1199 18.8801 21.1199ZM9.92014 19.8399H18.8801C19.5861 19.8399 20.1601 19.2659 20.1601 18.5599C20.1601 17.854 19.5861 17.2799 18.8801 17.2799H11.2001C10.4942 17.2799 9.92014 17.854 9.92014 18.5599V19.8399Z"
        fill="#0D1321"
      />
    </svg>
  );
};

export default Bedridden;
