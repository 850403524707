import gql from 'graphql-tag';

export default gql`
  mutation(
    $formDataId: Int!
    $firstName: String!
    $lastName: String!
    $relationToResidentId: Int
    $address1: String
    $address2: String
    $isInquirer: Boolean
    $allowMailing: Boolean
    $allowContact: Boolean
    $postalCode: String
    $city: String
    $state: String
    $country: String
    $primaryPhone: String
    $primaryPhoneType: String
    $phones: [PhoneInput]
    $emailAddress: String
    $emailAddresses: [EmailInput]
    $oneId: String
    $familyFileId: Int
    $isPrimary: Boolean
    $contactId: Int!
  ) {
    updateContact(
      input: {
        formDataId: $formDataId
        firstName: $firstName
        lastName: $lastName
        relationToResidentId: $relationToResidentId
        address1: $address1
        address2: $address2
        isInquirer: $isInquirer
        allowMailing: $allowMailing
        allowContact: $allowContact
        postalCode: $postalCode
        city: $city
        state: $state
        country: $country
        primaryPhone: $primaryPhone
        primaryPhoneType: $primaryPhoneType
        phones: $phones
        emailAddress: $emailAddress
        emailAddresses: $emailAddresses
        oneId: $oneId
        familyFileId: $familyFileId
        isPrimary: $isPrimary
        contactId: $contactId
      }
    ) {
      formDataId
    }
  }
`;
