import React from 'react';
import PropTypes from 'prop-types';

const CalendarDelete = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="currentColor"
        d="M8 6H6V5H4V8H20V5H18V6H16V5H8V6ZM4 20V10H20V20H4ZM16 3H8V2H6V3H4C2.89543 3 2 3.89543 2 5V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V5C22 3.89543 21.1046 3 20 3H18V2H16V3ZM14.2929 11.2929L12 13.5858L9.70711 11.2929L8.29289 12.7071L10.5858 15L8.29289 17.2929L9.70711 18.7071L12 16.4142L14.2929 18.7071L15.7071 17.2929L13.4142 15L15.7071 12.7071L14.2929 11.2929Z"
      />
    </svg>
  );
};

CalendarDelete.propTypes = {
  className: PropTypes.string,
};

export default CalendarDelete;
