import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './TourStatusMessage.style';
import Typography from '../../common/Typography/Typography';
import Toast from '../../common/Toast/Toast';

const TourStatusMessage = ({
  status,
  selectedDate,
  selectedTime,
  labels,
  tourType,
}) => {
  const classes = useStyles();
  const CANCELED = 'Canceled';
  const WARNING = 'warning';
  const INFORMATIVE = 'informative';
  const COMPLETED = 'Completed';
  const SUCCESS = 'success';
  const UNKNOWN = 'Unknown';

  const getToastType = () => {
    if (status === CANCELED) {
      return WARNING;
    }

    if (status === COMPLETED) {
      return SUCCESS;
    }

    return INFORMATIVE;
  };

  const printTourType = () => {
    return tourType ? tourType : UNKNOWN;
  };

  const getToastMessage = () => {
    if (status === COMPLETED) {
      return (
        <Typography>
          {labels.LAST_TOUR_COMPLETED_ON} <b>{selectedDate}.</b> {labels.TYPE}:{' '}
          <b>{printTourType()}</b>
        </Typography>
      );
    }
    return (
      <Typography>
        {labels.SCHEDULED_TOUR_ON} <b>{selectedDate}</b> {labels.AT}{' '}
        <b>{selectedTime}.</b> {labels.TYPE}: <b>{printTourType()}</b>
        {status === CANCELED && <>{labels.HAS_BEEN_CANCELED}</>}
      </Typography>
    );
  };

  return (
    <>
      <div className={classes.scheduled_tour_toast}>
        <Toast type={getToastType()} className={classes.toast_message}>
          {getToastMessage()}
        </Toast>
      </div>
    </>
  );
};

TourStatusMessage.propTypes = {
  labels: PropTypes.object,
  selectedTime: PropTypes.string,
  selectedDate: PropTypes.string,
  tourType: PropTypes.string,
  status: PropTypes.string,
};

TourStatusMessage.defaults = {};

export default TourStatusMessage;
