import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: '0',
  },
  item: {
    padding: theme.spacing(2),
  },
}));

export { useStyles };
