export const GET_CATALOGS_REQUEST = 'catalogs/get/request';
export const GET_CATALOGS_SUCCESS = 'catalogs/get/success';
export const GET_CATALOGS_ERROR = 'catalogs/get/error';

export const getCatalogsRequest = () => ({ type: GET_CATALOGS_REQUEST });

export const getCatalogsSuccess = (response) => {
  return { type: GET_CATALOGS_SUCCESS, payload: { response } };
};

export const getCatalogsError = (error) => {
  return { type: GET_CATALOGS_ERROR, payload: { error } };
};
