import gql from 'graphql-tag';

export default gql`
  mutation($familyCommunityTourDraftId: Int!) {
    deleteTourDraft(
      input: { familyCommunityTourDraftId: $familyCommunityTourDraftId }
    ) {
      familyCommunityTourDraftId
    }
  }
`;
