import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Media from '../../common/Media/Media';
import FamilyFileDesktop from './FamilyFileDesktop';
import FamilyFileXL from './FamilyFileXL';
import { withLabels } from '../../context/LabelContext';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import FamilyFileMobile from './FamilyFileMobile';
import { logFamilyFilePageViewed } from 'services/familyFileService';
import { useExternalId } from 'hooks/useExternalId';

const FamilyFile = ({ labels, flags }) => {
  const location = useLocation();

  const getFamilyFileId = () => {
    const pathname = location.pathname;
    const paths = pathname.split('/');
    return Number(paths[paths.length - 1]);
  };

  const getActiveTab = () => {
    const search = location.search;
    const urlParams = new URLSearchParams(search);
    return Number(urlParams.get('tab')) || 0;
  };
  const familyFileId = getFamilyFileId();
  const activeTab = getActiveTab();
  const { externalId } = useExternalId(familyFileId);

  const activeTabLabel = sessionStorage.getItem('CASE_PROFILE_ACTIVE_TAB');
  if (externalId) {
    logFamilyFilePageViewed(
      familyFileId,
      externalId,
      activeTabLabel ?? '',
      'none',
    );
  }

  return (
    <Media
      xs={
        <FamilyFileMobile
          activeTab={activeTab}
          labels={labels}
          familyFileId={familyFileId}
          size="xs"
        />
      }
      md={
        <FamilyFileDesktop
          activeTab={activeTab}
          labels={labels}
          familyFileId={familyFileId}
          flags={flags}
        />
      }
      xl={
        <FamilyFileXL
          activeTab={activeTab}
          labels={labels}
          familyFileId={familyFileId}
          flags={flags}
        />
      }
    />
  );
};

FamilyFile.propTypes = {
  labels: PropTypes.object,
  flags: PropTypes.object,
};

export default withLDConsumer()(withLabels(FamilyFile));
