import gql from 'graphql-tag';

export default gql`
  mutation(
    $familyCommunityTourId: Int!
    $activityDate: Date
    $timeOffset: Int
    $status: TourStatus
    $tourType: String
    $userName: String
    $userRole: String
    $isRecorded: Boolean
    $isActivityHidden: Boolean
  ) {
    updateTour(
      input: {
        familyCommunityTourId: $familyCommunityTourId
        activityDate: $activityDate
        timeOffset: $timeOffset
        status: $status
        tourType: $tourType
        userName: $userName
        userRole: $userRole
        isRecorded: $isRecorded
        isActivityHidden: $isActivityHidden
      }
    ) {
      familyFileId
      familyCommunityTourId
      communityId
      activityDate
      tourType
      currentStatus
      latestActivity {
        familyCommunityTourActivityId
        familyCommunityTourId
        status
        activityDate
        tourType
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
