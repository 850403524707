import ErrorLayout from '../ErrorLayout/ErrorLayout';
import { useStyles } from '../Error/AuthError.style';
import Button from '../../common/Button/Button';
import TextInput from '../../common/TextInput/TextInput';
import labels from '../../config/labels';
import React, { useState } from 'react';

const EmailFormInput = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');

  const handleLoginClick = () => {
    global.localStorage.setItem('email', email);
    window.location.reload();
  };

  const handleEmailChange = (value) => {
    setEmail(value);
  };

  return (
    <ErrorLayout showTopBar={true}>
      <div className={classes.error_container}>
        <TextInput
          id="email"
          maxLength={100}
          isMandatory
          label={labels.EMAIL_ADDRESS_PROMPT}
          className={classes.min_input_width}
          value={email}
          onChange={handleEmailChange}
        />
        <Button className={classes.button_padding} onClick={handleLoginClick}>
          {labels.LOGIN}
        </Button>
      </div>
    </ErrorLayout>
  );
};

export default EmailFormInput;
