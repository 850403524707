import { logEvent } from '../services/analyticsService';
import careTypeService from '../services/careTypeService';

const getCommunityLogDetails = (communities) => {
  const communityIds = communities.map((community) => community.id);

  const communityDetails = {};
  communities.forEach((community) => {
    const displayCareTypes = careTypeService.toDisplayFormatArray(
      community.careTypes,
    );
    communityDetails[community.id] = { careTypes: displayCareTypes };
  });

  return [communityIds, communityDetails];
};

const getReferralLogDetails = (communities = []) => {
  const referralDetails = [];
  communities.forEach((community) => {
    referralDetails.push({
      communityId: community.id,
      referralType: community.referralType,
    });
  });
  return referralDetails;
};

const buildCommunities = (
  referralStatus,
  communities,
  tourDetailsExtended,
  tourDrafts,
) => {
  if (!referralStatus) return communities;

  const builtCommunities = communities.map((community) => {
    community.tourDetails = tourDetailsExtended.find(
      (tourDetails) =>
        parseInt(tourDetails.communityId) === parseInt(community.id),
    );

    community.tourDraft = tourDrafts.find((tourDrafts) => {
      return tourDrafts.communityId === Number(community.id);
    });

    const referredCommunity = referralStatus.find(
      (status) =>
        community.id.toString() === status.communityId.toString() &&
        status.isReferralStarted,
    );
    if (referredCommunity) {
      const modifiedReferralCommunity = {
        ...community,
        ...{
          isReferralStarted: referredCommunity.isReferralStarted,
          referralDate: referredCommunity.referralDate,
          communityStatus: referredCommunity.communityStatus,
          isRejected: referredCommunity.isRejected,
        },
      };
      return modifiedReferralCommunity;
    }
    return community;
  });
  const referredCommunities = builtCommunities.filter(
    (community) => community.isReferralStarted,
  );
  return referredCommunities.reduce((acc, curr) => {
    const duplicate = acc.find((a) => a.id === curr.id);
    if (!duplicate) {
      acc.push(curr);
    }
    return acc;
  }, []);
};

const buildSavedCommunities = (referralStatus, communities) => {
  if (!referralStatus) return communities;

  const builtCommunities = communities.map((community) => {
    const referredCommunity = referralStatus.find(
      (status) => community.id.toString() === status.communityId.toString(),
    );

    return {
      ...community,
      ...{
        communityStatus: referredCommunity?.communityStatus,
        isRejected: referredCommunity?.isRejected,
      },
    };
  });

  return builtCommunities.filter((community) => {
    const referredCommunity = referralStatus.find(
      (status) =>
        community.id.toString() === status.communityId.toString() &&
        status.isReferralStarted,
    );
    return !referredCommunity;
  });
};

const filteredReferredCommunities = (referralStatus) => {
  const referredComunities = referralStatus.filter(
    (status) => status.isReferralStarted && status.referralDate,
  );

  return referredComunities.map((community) => community.communityId);
};

export const logReferralAlertManagerViewed = (
  communityIds,
  familyFileId,
  userId,
) => {
  logEvent('Referral Alert Manager Viewed', {
    channel: 'community',
    communityIdList: communityIds,
    crmUserId: userId,
    eventPath: 'referral alert start',
    eventType: 'view',
    familyFileId: familyFileId,
    screenName: 'referral alert',
    section: 'referral',
  });
};

// Only applies to Tour Scheduling v1 (remove when v1 deprecated)
export const logReferralAlertTourTimeSelected = (
  careTypes,
  communityId,
  familyFileId,
  tourDateTime,
  tourType,
  userId,
) => {
  const displayCareTypes = careTypeService.toDisplayFormatArray(careTypes);

  logEvent('Referral Alert Tour Time Selected', {
    channel: 'community',
    careTypes: displayCareTypes,
    communityId: communityId,
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    screenName: 'referral alert',
    section: 'referral',
    selectedTourTime: tourDateTime,
    tourType: tourType,
  });
};

export const logReferralAlertSendAttempted = (
  communities,
  familyFileId,
  toursCount,
  toursScheduled,
  userId,
) => {
  const [communityIds, communityDetails] = getCommunityLogDetails(communities);
  const referralDetails = getReferralLogDetails(communities);

  logEvent('Referral Alert Send Attempted', {
    channel: 'community',
    communityDetails: communityDetails,
    communityIdList: communityIds,
    crmUserId: userId,
    eventPath: 'referral alert send',
    eventType: 'action',
    familyFileId: familyFileId,
    numCommunitiesReferred: communityIds.length,
    numToursScheduled: toursCount,
    referralType: referralDetails,
    screenName: 'referral alert',
    section: 'referral',
    toursScheduled: toursScheduled,
  });
};

export const logReferralAlertSuccessButtonClicked = (
  buttonName,
  familyFileId,
  userId,
) => {
  logEvent('Referral Alert Success Button Clicked', {
    buttonText: buttonName,
    channel: 'community',
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    screenName: 'referral alert success',
    section: 'referral',
  });
};

export const logReferralAlertSettingsSaved = (
  communities,
  familyFileId,
  toursCount,
  toursScheduled,
  userId,
) => {
  const [communityIds, communityDetails] = getCommunityLogDetails(communities);
  const referralDetails = getReferralLogDetails(communities);

  logEvent('Referral Alert Settings Saved', {
    channel: 'community',
    communityDetails: communityDetails,
    communityIdList: communityIds,
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    numCommunitiesReferred: communityIds.length,
    numToursScheduled: toursCount,
    referralType: referralDetails,
    screenName: 'referral alert',
    section: 'referral',
    toursScheduled: toursScheduled,
  });
};

export const logReferralAlertSettingsCleared = (
  communities,
  familyFileId,
  toursCount,
  toursScheduled,
  userId,
) => {
  const [communityIds, communityDetails] = getCommunityLogDetails(communities);
  const referralDetails = getReferralLogDetails(communities);

  logEvent('Referral Alert Settings Cleared', {
    channel: 'community',
    communityDetails: communityDetails,
    communityIdList: communityIds,
    crmUserId: userId,
    eventType: 'action',
    familyFileId: familyFileId,
    numCommunitiesReferred: communityIds.length,
    numToursScheduled: toursCount,
    referralType: referralDetails,
    screenName: 'referral alert',
    section: 'referral',
    toursScheduled: toursScheduled,
  });
};

export const logReferralAlertValidationViewed = (
  errorMessages,
  familyFileId,
  userId,
) => {
  logEvent('Referral Alert Validation Viewed', {
    alertNotification: errorMessages,
    channel: 'community',
    crmUserId: userId,
    eventPath: 'referral alert validation',
    eventType: 'view',
    familyFileId: familyFileId,
    screenName: 'referral alert validation',
    section: 'referral',
  });
};

export const logReferralAlertSendSucceeded = (
  communities,
  familyFileId,
  toursCount,
  toursScheduled,
  userId,
) => {
  const [communityIds, communityDetails] = getCommunityLogDetails(communities);
  const referralDetails = getReferralLogDetails(communities);

  logEvent('Referral Alert Send Succeeded', {
    channel: 'community',
    communityDetails: communityDetails,
    communityIdList: communityIds,
    crmUserId: userId,
    eventPath: 'referral alert success',
    eventType: 'view',
    familyFileId: familyFileId,
    numCommunitiesReferred: communityIds.length,
    numToursScheduled: toursCount,
    referralType: referralDetails,
    screenName: 'referral alert',
    section: 'referral',
    toursScheduled: toursScheduled,
  });
};

export const logReferralAlertErrorViewed = (
  communities,
  errorMessage,
  familyFileId,
  userId,
) => {
  const [communityIds] = getCommunityLogDetails(communities);

  logEvent('Referral Alert Error Viewed', {
    channel: 'community',
    communityIdList: communityIds,
    crmUserId: userId,
    errorMessage: errorMessage,
    eventPath: 'referral alert success',
    eventType: 'view',
    familyFileId: familyFileId,
    numCommunitiesReferred: communityIds.length,
    screenName: 'referral alert',
    section: 'referral',
  });
};

export const getTourStatusLabel = (key, labels) => {
  const tourStatus = {
    'Scheduled Tour': labels.TOUR_STATUS_SCHEDULED,
    Completed: labels.TOUR_STATUS_COMPLETED,
    Canceled: labels.TOUR_STATUS_CANCELED,
    Rescheduled: labels.TOUR_STATUS_RESCHEDULED,
  };

  return tourStatus[key];
};

export default {
  buildCommunities,
  buildSavedCommunities,
  filteredReferredCommunities,
};
