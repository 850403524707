export const hasNonHomeCareType = (careTypes) => {
  let hasNonHomeCareType = false;
  for (const careType in careTypes) {
    if (careType === 'homeCare') {
      continue;
    }
    if (careTypes[careType] === true) {
      hasNonHomeCareType = true;
      break;
    }
  }
  return hasNonHomeCareType;
};

export const hasHomeCareType = (careTypes) => {
  let hasHomeCareType = false;
  for (const careType in careTypes) {
    if (careType === 'homeCare' && careTypes[careType] === true) {
      hasHomeCareType = true;
      break;
    }
  }
  return hasHomeCareType;
};
