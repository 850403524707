import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => ({
  slider_container: {
    padding: '0 6px',
    '& .MuiSlider-root': {
      color: theme.palette.neutral.cosmicCobalt3,
    },
    '& .MuiSlider-rail': {
      color: theme.palette.light.platinum1,
    },
  },
  label: {
    fontSize: '13px',
    lineHeight: '19px',
    color: theme.palette.dark.eerieBlack5,
    margin: '4px',
    textAlign: 'left',
  },
}));

export { useStyles };
