import React from 'react';
import PropTypes from 'prop-types';

const Note = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M4 22C2.89543 22 2 21.1046 2 20V4C2 2.89543 2.89543 2 4 2H20C21.1046 2 22 2.89543 22 4V17.4142L17.4142 22H4ZM20 4H4V20H15V17C15 15.8954 15.8954 15 17 15H20V4ZM19.5858 17H17V19.5858L19.5858 17Z"
      />
    </svg>
  );
};

Note.propTypes = {
  className: PropTypes.string,
};

export default Note;
