import React, { Fragment } from 'react';
import { useStyles } from './PreferredLocation.style';
import Typography from '../../common/Typography/Typography';
import PropTypes from 'prop-types';
import labels from '../../config/labels';
import SmartTooltip from '../../common/SmartTooltip/SmartTooltip';

const PreferredLocation = ({ preferredLocations }) => {
  const classes = useStyles();

  const inBetweenPreferredLocations = (index) => {
    return index !== preferredLocations.length - 1;
  };

  const moreThanTwoLocations = () => {
    return preferredLocations.length > 2;
  };

  const printLocationsForSmartTooltip = () => {
    let locations = preferredLocations
      .slice(1, preferredLocations.length)
      .map((location, index) => (
        <div key={index} className={classes.more_than_two_locations_tooltip}>
          {preferredLocationLabel(location)}
        </div>
      ));
    return locations;
  };

  return (
    <div className={classes.locations_container}>
      {moreThanTwoLocations() && (
        <Fragment>
          <Typography level="small" bold={true}>
            {preferredLocationLabel(preferredLocations[0])}
          </Typography>
          <Typography level="small" className={classes.pipe}>
            {labels.PIPE}
          </Typography>
          <div className={classes.more_than_two_locations_label}>
            <SmartTooltip
              title={printLocationsForSmartTooltip()}
              placement="bottom"
              isInteractive={true}
            >
              <Typography level="small" bold={true}>
                {preferredLocations.length - 1} {labels.MORE_LOCATIONS}
              </Typography>
            </SmartTooltip>
          </div>
        </Fragment>
      )}

      {!moreThanTwoLocations() &&
        preferredLocations.map((preferredLocation, index) => {
          return (
            <Fragment key={index}>
              <Typography level="small" bold={true}>
                {preferredLocationLabel(preferredLocation)}
              </Typography>
              {inBetweenPreferredLocations(index) && (
                <Typography level="small" className={classes.pipe}>
                  {labels.PIPE}
                </Typography>
              )}
            </Fragment>
          );
        })}
    </div>
  );
};

const preferredLocationLabel = (prefferedLocation) => {
  return `${prefferedLocation.city}, ${prefferedLocation.state}  ${prefferedLocation.zip}`;
};

PreferredLocation.propTypes = {
  preferredLocations: PropTypes.arrayOf(PropTypes.object),
};

export default PreferredLocation;
