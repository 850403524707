import { getDateInUtc } from '../../utils/dateFormat';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const _getFullYear = (date) => {
  return date.getFullYear();
};

const _getShortYear = (timestamp) => {
  return _getFullYear(timestamp).toString().substring(2);
};

const _getMonthNumber = (date) => {
  const number = date.getMonth() + 1;
  return number.toString().padStart(2, '0');
};

const _getFullMonthName = (date) => {
  return months[date.getMonth()];
};

const _getShortMonthName = (date) => {
  return _getFullMonthName(date).toString().substring(0, 3);
};

const _getFullDayOfWeek = (date) => {
  return weekDays[date.getDay()];
};

const _getShortDayOfWeek = (date) => {
  return weekDays[date.getDay()].substring(0, 3);
};

const _getDayNumber = (date) => {
  const number = date.getDate();
  return number.toString().padStart(2, '0');
};

const _getExactDayNumber = (date) => {
  return date.getDate();
};

const _get24HourNotation = (date) => {
  return date.getHours().toString().padStart(2, '0');
};

const _get12HourNotation = (date) => {
  const hour = date.getHours() % 12 || 12;
  return hour.toString().padStart(2, '0');
};

const _getMinutes = (date) => {
  const minutes = date.getMinutes();
  return minutes.toString().padStart(2, '0');
};

const _getSeconds = (date) => {
  const seconds = date.getSeconds();
  return seconds.toString().padStart(2, '0');
};

const _getAmPm = (date) => {
  const hour = date.getHours();
  return hour < 12 ? 'am' : 'pm';
};

const _getAmPmUpperCase = (date) => {
  return _getAmPm(date).toUpperCase();
};

const mapper = {
  YYYY: _getFullYear,
  YY: _getShortYear,
  MMMM: _getFullMonthName,
  MMM: _getShortMonthName,
  MM: _getMonthNumber,
  DDDD: _getFullDayOfWeek,
  DDD: _getShortDayOfWeek,
  DD: _getDayNumber,
  X: _getExactDayNumber,
  hh: _get24HourNotation,
  HH: _get12HourNotation,
  mm: _getMinutes,
  ss: _getSeconds,
  '%a': _getAmPm,
  '%A': _getAmPmUpperCase,
};

const _prepare = (pattern) => {
  return pattern.replace(/(a|A)/g, '%$1');
};

const format = (timestamp, pattern) => {
  if (!timestamp) {
    return timestamp;
  }
  pattern = _prepare(pattern);
  const date = new Date(timestamp);
  const patterns = Object.keys(mapper);
  for (const p in patterns) {
    const current = patterns[p];
    if (pattern.match(current)) {
      const func = mapper[current];
      const value = func(date);
      pattern = pattern.replace(new RegExp(`${current}`, 'g'), value);
    }
  }
  return pattern;
};

const formatTourActivityDate = (timestamp, pattern) => {
  if (!timestamp) {
    return timestamp;
  }
  pattern = _prepare(pattern);
  const initialDate = new Date(timestamp);
  const date = getDateInUtc(initialDate);
  const patterns = Object.keys(mapper);
  for (const p in patterns) {
    const current = patterns[p];
    if (pattern.match(current)) {
      const func = mapper[current];
      const value = func(date);
      pattern = pattern.replace(new RegExp(`${current}`, 'g'), value);
    }
  }
  return pattern;
};

export { format, formatTourActivityDate };
