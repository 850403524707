import PropTypes from 'prop-types';
import React from 'react';
import NewInquiryTopBar from '../callCenter/Inquiry/NewInquiryTopBar/NewInquiryTopBar';

const CallcenterLayout = ({ children }) => {
  return (
    <div>
      <NewInquiryTopBar />
      {children}
    </div>
  );
};

CallcenterLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CallcenterLayout;
