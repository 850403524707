import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useStyles } from './TableDetails.style';
import ChevronTop from 'common/Icons/arrow/ChevronTop';
import ChevronBottom from 'common/Icons/arrow/ChevronBottom';
import RowDetail from './RowDetail';

const TableDetails = ({ details, children, headerClass, detailColor }) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={headerClass}>
      <div className={classes.top_panel} onClick={() => setIsOpen(!isOpen)}>
        {children}
        <div className={classes.top_panel_right}>
          {isOpen ? <ChevronTop /> : <ChevronBottom />}
        </div>
      </div>
      {isOpen && (
        <div className={classes.main_panel_details}>
          {details.map((detail) => (
            <RowDetail
              key={detail.order}
              rowDetail={detail}
              detailColor={detailColor}
            />
          ))}
        </div>
      )}
    </div>
  );
};

TableDetails.propTypes = {
  details: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  headerClass: PropTypes.string,
  detailColor: PropTypes.string.isRequired,
};

export default TableDetails;
