const CARE_TYPE_CODE_HOMECARE = 'H';

const careTypeLookup = {
  adultDayServices: 'D',
  assistedLiving: 'A',
  homeCare: 'H',
  independentLiving: 'R', // Independent Living is the new term for Retirement Community
  memoryCare: 'Z',
  notSure: 'O',
  nursingHome: 'N',
  residentialCareHome: 'G',
  respiteCare: 'M',
  seniorApartments: 'P',
};

export const getNonHomeCareTypeDetails = (careTypes, roomCharges, roomFees) => {
  const nonHomeCareTypeValues = roomCharges.map(
    (roomCharge) => roomCharge.careType,
  );

  for (const careType in careTypes) {
    if (careTypes[careType] === true) {
      const careTypeCode = careTypeLookup[careType];
      if (!nonHomeCareTypeValues.includes(careTypeCode)) {
        nonHomeCareTypeValues.push(careTypeCode);
      }
    }
  }

  const nonHomeCareTypeCodes = Array.from(new Set(nonHomeCareTypeValues));

  const nonHomeCareApartmentTypeValues = roomCharges.map(
    (roomCharge) => roomCharge.apartmentType,
  );
  const nonHomeCareApartmentTypes = Array.from(
    new Set(nonHomeCareApartmentTypeValues),
  );

  const nonHomeCareFees = roomFees.filter((fee) => {
    return fee.careType !== CARE_TYPE_CODE_HOMECARE;
  });

  const nonHomeCareFeesValues = nonHomeCareFees.map((fee) => fee.feeType);
  const nonHomeCareFeeTypes = Array.from(new Set(nonHomeCareFeesValues));

  return [
    nonHomeCareFees,
    nonHomeCareTypeCodes,
    nonHomeCareApartmentTypes,
    nonHomeCareFeeTypes,
  ];
};

/*
  Note: HomeCare doesn't have any room charges 
*/
export const getHomeCareTypeDetails = (fees) => {
  const homeCareFees = fees
    .filter((fee) => {
      return fee.careType === CARE_TYPE_CODE_HOMECARE;
    })
    .map((fee) => {
      let updatedFee = { ...fee, suffix: '' };
      if (fee.feeType === 'Care Rate') {
        updatedFee.feeType = 'Hourly';
        updatedFee.suffix = 'hourly';
      }
      if (fee.feeType === 'Live-In Care Rate') {
        updatedFee.feeType = 'Live-in';
        updatedFee.suffix = 'monthly';
      }
      return updatedFee;
    });

  const homeCareFeesValues = homeCareFees.map((fee) => fee.feeType);
  const homeCareFeeTypes = Array.from(new Set(homeCareFeesValues));

  return [homeCareFees, homeCareFeeTypes];
};
