import React from 'react';
import PropTypes from 'prop-types';

const CallMissed = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M7 8V5.41421L12 10.4142L18.7071 3.70711L17.2929 2.29289L12 7.58579L8.41421 4H11V2H5V8H7ZM14.4267 17.324C13.8091 17.1184 12.9237 17.0002 11.9968 17C11.0704 16.9998 10.185 17.118 9.56735 17.3236C9.29782 17.4134 9.10254 17.5125 8.99819 17.5994V18.4998C8.99831 19.0561 8.98526 19.3378 8.91482 19.6767C8.7653 20.3959 8.36504 20.953 7.59794 20.9972C5.57993 21.3324 4.23197 21.5 3.49955 21.5C2.04222 21.5 1 20.1968 1 19V17.5C1 13.7761 6.02665 10.9987 11.9972 11C17.9691 11.0013 22.994 13.7769 22.9936 17.4728C22.9981 17.6391 23 17.8114 23 18.0055C23 18.1468 22.9992 18.2587 22.9969 18.5091C22.9947 18.756 22.994 18.8651 22.994 19C22.994 20.1896 21.9503 21.5 20.4944 21.5C19.7627 21.5 18.4166 21.3327 16.4018 20.9981C15.3496 20.9554 15.0604 20.1844 15.0053 19.0441C14.9974 18.8811 14.9958 18.7545 14.9958 18.5011L14.9958 17.5995C14.8914 17.5127 14.6962 17.4137 14.4267 17.324ZM6.99819 18.5V17.5C6.99819 15.7341 9.20465 14.9994 11.9972 15C14.7914 15.0006 16.9958 15.7343 16.9958 17.5V18.4998C16.9958 18.7225 16.9971 18.8269 17.003 18.9476C17.0051 18.9921 17.0079 19.0335 17.0111 19.0715C18.7615 19.3572 19.9381 19.5 20.4944 19.5C20.7329 19.5 20.994 19.1722 20.994 19C20.994 18.8571 20.9947 18.744 20.997 18.4909C20.9992 18.2465 21 18.1386 21 18.0051C21 17.828 20.9983 17.6734 20.994 17.5C20.994 15.2011 17.0111 13.0011 11.9968 13C6.98401 12.9989 3 15.2002 3 17.5V19C3 19.1782 3.25738 19.5 3.49955 19.5C4.05591 19.5 5.23279 19.3571 6.98362 19.0714C6.99451 18.9375 6.99825 18.7607 6.99819 18.5Z"
      />
    </svg>
  );
};

CallMissed.propTypes = {
  className: PropTypes.string,
};

export default CallMissed;
