import clsx from 'clsx';
import Button from 'common/Button/Button';
import Duplicate from 'common/Icons/basic/Duplicate';
import Link from 'common/Link/Link';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import TableCell from 'common/Table/TableCell/TableCell';
import Tag from 'common/Tag/Tag';
import Typography from 'common/Typography/Typography';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import {
  createsValueForDynamicHeightInFamilyFile,
  getSalePhase,
} from 'services/callCenter/duplicateFamilyFilePopupService';
import { userRoleEnum } from 'utils/userRoleEnum';
import withConfig from 'utils/withConfig';
import { useStyles } from './DuplicateFamilyFilePopup.style';

export const POPUP_TYPES = {
  RESIDENT: 'resident',
  FAMILY_FILE: 'familyFile',
};

const getTagSalesPhase = (familyFile, labels, classes) => {
  if (!familyFile.salesPhase) {
    return labels.DASH_MESSAGE;
  }
  const salesPhase =
    familyFile.familyFileStatus + ' - ' + familyFile.salesPhase;
  if (salesPhase.length > 30) {
    return (
      <SmartTooltip title={salesPhase} placement="top">
        <Tag
          className={classes.longTag}
          text={salesPhase}
          color={getSalePhase(familyFile.salesPhase)}
        />
      </SmartTooltip>
    );
  }
  return <Tag text={salesPhase} color={getSalePhase(familyFile.salesPhase)} />;
};

const getReferredStatusForCurrent = (currentFamilyFile, type, labels) => {
  if (type === POPUP_TYPES.RESIDENT) {
    return labels.DASH_MESSAGE;
  }
  return currentFamilyFile.referredActivityDate ? labels.YES : labels.NO;
};

const getLocationForCurrent = (
  currentFFlocationLength,
  currentFamilyFile,
  classes,
) => {
  return (
    <Fragment>
      {currentFamilyFile.location.map((location, index) => (
        <div
          key={index}
          className={classes.table_cell_matched_contact_location}
        >
          <Typography
            className={classes.typography}
            level={currentFFlocationLength === 1 ? 'body' : 'small'}
          >
            {location}
          </Typography>
        </div>
      ))}
    </Fragment>
  );
};

const getLocationForDuplicates = (foundFamilyFile, currentFamilyFile) => {
  return (
    <Fragment>
      {foundFamilyFile.map((familyFile, index) => (
        <Fragment key={index}>
          {familyFile.location.length === 1 && (
            <OneLocationForFamilyFile
              index={index}
              foundFamilyFile={foundFamilyFile}
              ffLocation={familyFile.location}
              currentFamilyFile={currentFamilyFile}
            />
          )}
          {familyFile.location.length > 1 && (
            <MoreThanOneLocationForFamilyFile
              index={index}
              foundFamilyFile={foundFamilyFile}
              ffLocation={familyFile.location}
              currentFamilyFile={currentFamilyFile}
            />
          )}
        </Fragment>
      ))}
    </Fragment>
  );
};

const getSlaStatusForDuplicates = (
  familyFile,
  currentFamilyFile,
  classes,
  labels,
) => {
  if (!familyFile.isActive) {
    return (
      <SmartTooltip title={labels.INACTIVE_USER} placement="top">
        <Typography color="disabled">
          {familyFile.sla || labels.DASH_MESSAGE}
        </Typography>
      </SmartTooltip>
    );
  } else if (familyFile.userRoleId !== userRoleEnum.SLA) {
    return (
      <SmartTooltip title={labels.NO_LONGER_AN_SLA} placement="top">
        <Typography color="smokyTopaz2">
          {familyFile.sla || labels.DASH_MESSAGE}
        </Typography>
      </SmartTooltip>
    );
  } else {
    return (
      <Typography
        color="eerieBlack1"
        className={clsx(
          familyFile.sla === currentFamilyFile.sla
            ? classes.status_active_matched
            : classes.status_active,
        )}
      >
        {familyFile.sla || labels.DASH_MESSAGE}
      </Typography>
    );
  }
};

const getFamilyFileIdForDuplicates = (familyFile, type, classes) => {
  if (type === POPUP_TYPES.RESIDENT) {
    return (
      <Typography className={classes.custom_label}>
        {familyFile.familyFileId}
      </Typography>
    );
  }
  if (type === POPUP_TYPES.FAMILY_FILE) {
    return (
      <Typography color="cosmicCobalt3" underlined bold>
        <Link
          className={classes.link_beacon}
          to={withConfig('BEACON_FAMILY_FILE', {
            familyFileId: familyFile.familyFileId,
          })}
          openInNewTab={true}
        >
          {familyFile.familyFileId}
        </Link>
      </Typography>
    );
  }
};

const getButtonType = (type, doMerging, onClose, labels, classes) => {
  if (type === POPUP_TYPES.RESIDENT) {
    return (
      <Fragment>
        <div className={classes.button_not_merge}>
          <Button type="light" onClick={() => doMerging(false)}>
            {labels.NO_DUPLICATES_FOUND}
          </Button>
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => doMerging(true)}
            startIcon={<Duplicate />}
          >
            {labels.SAME_RESIDENT}
          </Button>
        </div>
      </Fragment>
    );
  }
  if (type === POPUP_TYPES.FAMILY_FILE) {
    return (
      <div>
        <Button type="light" onClick={() => onClose()}>
          {labels.CLOSE}
        </Button>
      </div>
    );
  }
};

const OneLocationForFamilyFile = ({
  index,
  foundFamilyFile,
  ffLocation,
  currentFamilyFile,
}) => {
  let valueForCalcHeight = 0;
  const foundFFLongestLocation = foundFamilyFile.longestLocation;
  const currentFFlocationLength = currentFamilyFile.location.length;
  valueForCalcHeight = createsValueForDynamicHeightInFamilyFile(
    foundFFLongestLocation,
    currentFFlocationLength,
  );

  const classes = useStyles(valueForCalcHeight);
  return (
    <TableCell
      key={index}
      className={clsx(
        currentFamilyFile.location.find((loc) => loc === ffLocation[0])
          ? classes.table_cell_resident_matched_location_family_file
          : classes.table_cell_family_file_location,
      )}
    >
      {ffLocation.map((location, index) => (
        <div
          key={index}
          className={classes.table_cell_default_location_family_file}
        >
          <Typography className={classes.typography}>{location}</Typography>
        </div>
      ))}
    </TableCell>
  );
};

const MoreThanOneLocationForFamilyFile = ({
  index,
  foundFamilyFile,
  ffLocation,
  currentFamilyFile,
}) => {
  let valueForCalcHeight = 0;
  const foundFFLongestLocation = foundFamilyFile.longestLocation;
  const currentFFlocationLength = currentFamilyFile.location.length;
  valueForCalcHeight = createsValueForDynamicHeightInFamilyFile(
    foundFFLongestLocation,
    currentFFlocationLength,
  );

  const classes = useStyles(valueForCalcHeight);
  return (
    <TableCell key={index} className={classes.table_cell_family_file_location}>
      {ffLocation.map((location, index) => (
        <div
          key={index}
          className={clsx(
            currentFamilyFile.location.find((loc) => loc === location)
              ? classes.table_cell_matched_contact_location_family_file
              : classes.table_cell_default_location_family_file,
          )}
        >
          <Typography className={classes.typography} level="small">
            {location}
          </Typography>
        </div>
      ))}
    </TableCell>
  );
};

MoreThanOneLocationForFamilyFile.propTypes = {
  foundFamilyFile: PropTypes.array,
  ffLocation: PropTypes.array,
  currentFamilyFile: PropTypes.object,
  index: PropTypes.number,
};

OneLocationForFamilyFile.propTypes = {
  foundFamilyFile: PropTypes.array,
  ffLocation: PropTypes.array,
  currentFamilyFile: PropTypes.object,
  index: PropTypes.number,
};
export {
  getTagSalesPhase,
  getReferredStatusForCurrent,
  getLocationForCurrent,
  getLocationForDuplicates,
  getSlaStatusForDuplicates,
  getFamilyFileIdForDuplicates,
  getButtonType,
  OneLocationForFamilyFile,
  MoreThanOneLocationForFamilyFile,
};
