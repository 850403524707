import React from 'react';
import { useStyles } from './Tag.style';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useBackgroundColorStyles } from '../style/backgroundColor';
import { colorReference } from '../Theme/themes/default';
import X from '../Icons/basic/X';

const Tag = ({ color, text, type, icon, onClose, className }) => {
  const classes = useStyles();
  const backgroundClasses = useBackgroundColorStyles();
  const containerClasses = clsx(
    className,
    classes.tag_container,
    backgroundClasses[color],
  );

  const getText = () => (type === 'bold' ? <b>{text}</b> : text);

  return (
    <div className={containerClasses}>
      {icon && <div className={classes.icon}>{icon}</div>}
      {getText()}
      {onClose && (
        <div className={classes.close_icon} onClick={onClose}>
          <X />
        </div>
      )}
    </div>
  );
};

Tag.propTypes = {
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  icon: PropTypes.node,
  type: PropTypes.oneOf(['bold', 'regular']),
  color: PropTypes.oneOf(colorReference),
  className: PropTypes.string,
};

Tag.defaultProps = {
  color: 'cosmicCobalt3',
  type: 'regular',
};

export default Tag;
