import { makeStyles } from 'common/Theme/Theme';

const getWidth = (size) => {
  switch (size) {
    case 'xs':
      return '120px';
    case 's':
      return '166px';
    case 'lg':
      return '274px';
    case 'map':
      return '420px';
    case 'referred':
      return '170px';
    default:
      return '394px';
  }
};

const getHeight = (size) => {
  switch (size) {
    case 'xs':
      return '120px';
    case 's':
      return '166px';
    case 'lg':
      return '274px';
    case 'map':
      return '140px';
    case 'referred':
      return '120px';
    default:
      return '394px';
  }
};

const useStyles = makeStyles((theme) => ({
  community_image_container: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '4px',
    flexGrow: 0,
    height: getHeight,
    minHeight: getHeight,
    minWidth: getWidth,
    objectFit: 'none',
    position: 'relative',
    width: getWidth,
  },
  image_tag: {
    display: 'flex',
    padding: '4px 8px',
    borderRadius: '16px',
    backgroundColor: theme.palette.care.regalia2,
    position: 'absolute',
    top: '4px',
    left: '4px',
  },
  tags_container: {
    padding: '4px',
    '& > div:not(:first-child)': {
      marginTop: '4px',
    },
  },
}));

export { useStyles };
