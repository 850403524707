import React from 'react';
import { useStyles } from './NotesSidePanel.style';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import HideNotesButton from './HideNotesButton';
import Notes from './Notes';
import Drawer from '@material-ui/core/Drawer';

const NotesSidePanel = ({
  familyFileId,
  isOpen,
  onClose,
  labels,
  flags,
  screenName,
  isUnderneathTopBar,
}) => {
  const classes = useStyles({ isOpen, isUnderneathTopBar });

  return (
    <Drawer
      className={classes.note_side_panel_container}
      anchor={'right'}
      open={isOpen}
      onClose={onClose}
      classes={{
        paper: classes.drawerPaper,
      }}
      variant={'persistent'}
    >
      <HideNotesButton
        labels={labels}
        onClick={onClose}
        isUnderneathTopBar={isUnderneathTopBar}
      />
      <Notes
        familyFileId={familyFileId}
        isOpen={isOpen}
        labels={labels}
        flags={flags}
        screenName={screenName}
      />
    </Drawer>
  );
};

NotesSidePanel.propTypes = {
  isOpen: PropTypes.bool,
  isUnderneathTopBar: PropTypes.bool,
  familyFileId: PropTypes.number,
  onClose: PropTypes.func,
  labels: PropTypes.object,
  incomingNotes: PropTypes.array,
  flags: PropTypes.shape({
    addNoteEnabled: PropTypes.bool,
    editKeyNote: PropTypes.bool,
  }),
  screenName: PropTypes.string,
};

NotesSidePanel.defaultProps = {
  incomingNotes: [],
  isOpen: false,
  isUnderneathTopBar: false,
  screenName: 'family file',
};

export default withLDConsumer()(NotesSidePanel);
