import * as Sentry from '@sentry/react';
import { cloneDeep } from 'lodash';
import allCallStates from '../../config/callStates.config';
import { CHICLET_CARD_STATES } from '../../pages/callCenter/Inquiry/FormChiclets/ChicletCard/ChicletCard';
import { updateDisposition } from '../../stateManagement/callcenter/formChiclets/actions/formChicletsActions';
import getErrorMessage from '../../utils/errorMessageFromExceptionUtil';
import { stepTypes } from './fieldsMapper';

const CANADA = 'CA';

const updateStepStates = (formSteps, currentQuestion, currentState) => {
  const steps = cloneDeep(formSteps);
  if (CHICLET_CARD_STATES.CURRENT_WITH_ERROR.value !== currentState) {
    const stepsMap = steps.map((step) => {
      const newStep = cloneDeep(step);
      const isCurrent = newStep.number === currentQuestion;
      if (isCurrent) {
        newStep.state = CHICLET_CARD_STATES.CURRENT.value;
      }
      return newStep;
    });
    return stepsMap;
  }
  return steps;
};

const updateSteps = (formSteps, selectedStep, selectedOption) => {
  const newFormSteps = cloneDeep(formSteps);
  const steps = newFormSteps.map((step) => {
    const newStep = cloneDeep(step);
    let stepNumber = newStep.number;
    const isOptional = newStep.extraFields?.isOptional;
    if (stepNumber === selectedStep.number) {
      if (selectedStep.errors.length !== 0) {
        newStep.errors = selectedStep.errors;
      }
      if (
        selectedStep.type === 'contactInfo' ||
        selectedStep.type === 'referralSource' ||
        selectedStep.type === 'slaMatch'
      ) {
        newStep.answer = {
          ...selectedOption,
          userInputValues: newStep.answer.userInputValues,
        };
      } else {
        newStep.answer = {
          ...selectedOption,
          userInputValues: selectedStep.answer.userInputValues,
        };
      }

      newStep.answers = selectedStep.answers;
      newStep.collapsed = selectedStep.collapsed;
      if (selectedStep.errors.length === 0) {
        newStep.state = CHICLET_CARD_STATES.ANSWERED.value;
        newStep.answered = true;
      } else {
        newStep.state = CHICLET_CARD_STATES.CURRENT_WITH_ERROR.value;
      }
    }
    if (selectedOption.extraFields?.hides.includes(stepNumber) && !isOptional) {
      newStep.extraFields.hidden = true;
      newStep.hidden = true;
      newStep.state = CHICLET_CARD_STATES.NOT_CURRENT.value;
    }
    if (selectedOption.extraFields?.shows.includes(stepNumber) && !isOptional) {
      newStep.extraFields.hidden = false;
      newStep.hidden = false;
      if (newStep.state !== CHICLET_CARD_STATES.ANSWERED.value) {
        newStep.state = CHICLET_CARD_STATES.NOT_CURRENT.value;
      }
    }
    return newStep;
  });
  return steps;
};

const updateRelationShipBasedOnInterest = (formSteps) => {
  const steps = cloneDeep(formSteps);
  const confirmInterestChiclet = steps.find(
    (o) => o.property === 'confirmInterest',
  );
  if (confirmInterestChiclet?.answer?.value === 'self') {
    const familyInfoChiclet = steps.find((o) => o.property === 'familyInfo');
    if (familyInfoChiclet) {
      Object.assign(familyInfoChiclet.answer, {
        userInputValues: { relationship: 'SELF' },
      });
    }
  }
  return steps;
};

const updateStepAnswer = (formSteps, selectedStep, selectedAnswer) => {
  const newFormSteps = cloneDeep(formSteps);
  const steps = newFormSteps.map((newStep) => {
    if (newStep.number === selectedStep.number) {
      newStep.answer.label = selectedAnswer.label;
      newStep.answer.userInputValues = { ...selectedAnswer.userInputValues };
      newStep.answers.forEach((answer) => {
        if (answer.value === selectedAnswer.value) {
          answer.label = selectedAnswer.label;
        }
      });
      newStep.answer.value = '';
    }
    return newStep;
  });
  return steps;
};

const updateManyChoiceStepAnswer = (
  formSteps,
  selectedStep,
  selectedOptions,
) => {
  const newFormSteps = cloneDeep(formSteps);
  newFormSteps.forEach((step) => {
    if (step.number === selectedStep.number) {
      step.answer.label = 'Saved';
      step.answer.value = 'selectedOptions';
      step.answer.userInputValues = { selectedOptions };
    }
  });
  return newFormSteps;
};

const updateTextInputGroupAnswer = (formSteps, selectedStep, textAnswer) => {
  const newFormSteps = cloneDeep(formSteps);

  newFormSteps.forEach((step) => {
    if (step.number === selectedStep.number) {
      step.answer.userInputValues = { textAnswer };
    }
  });
  return showFamilyInfoStep(newFormSteps);
};

const showFamilyInfoStep = (formSteps = []) => {
  const familyInfoStep = formSteps.find(
    (s) => s.type === stepTypes.familyInfo.key,
  );
  if (familyInfoStep) {
    const newFormSteps = cloneDeep(formSteps);
    const finalSteps = newFormSteps.map((step) => {
      if (step.state === CHICLET_CARD_STATES.CURRENT.value) {
        step.state = CHICLET_CARD_STATES.NOT_CURRENT.value;
      }
      if (step.type === stepTypes.familyInfo.key) {
        step.hidden = false;
        step.extraFields.hidden = false;
        step.state = CHICLET_CARD_STATES.CURRENT.value;
      }
      return step;
    });
    return finalSteps;
  }
  return formSteps;
};

const createFormSummary = (newFormSteps) => {
  const formSummary = newFormSteps.map((currentValue) => {
    const {
      number,
      name,
      answer: { value, userInputValues, extraFields },
      state,
      property,
      type,
    } = currentValue;
    const answer = {};
    if (value) {
      answer.value = value;
    }
    if (userInputValues) {
      answer.userInputValues = userInputValues;
    }
    if (extraFields && extraFields.closeInquiry) {
      answer.closeInquiry = extraFields.closeInquiry;
    }
    if (extraFields && extraFields.closeReason) {
      answer.closeReason = extraFields.closeReason;
    }
    if (extraFields && extraFields.addToDoNotCall) {
      answer.addToDoNotCall = extraFields.addToDoNotCall;
    }
    return {
      number,
      name,
      state,
      property,
      answer,
      type,
    };
  });
  return formSummary;
};

const isFormEditable = (newFormSteps) => {
  let isEditable = true;
  newFormSteps.forEach((currentValue) => {
    const {
      answer: { extraFields },
    } = currentValue;
    if (extraFields && extraFields.closeInquiry) {
      isEditable = false;
    }
  });
  return isEditable;
};

const setEraseContactInfo = (formSteps, shouldErase) => {
  const newFormSteps = cloneDeep(formSteps);
  const steps = newFormSteps.map((step) => {
    const newStep = cloneDeep(step);
    if (
      newStep.type === stepTypes.contactInfo.key ||
      newStep.type === stepTypes.multipleChoice.key
    ) {
      newStep.shouldEraseInfo = shouldErase;
      newStep.errors = [];
    }
    return newStep;
  });
  return steps;
};

const showPipedaChiclet = (formSteps, userInputValues) => {
  const isCanadaLocation =
    userInputValues?.variables?.location?.country === CANADA;
  const newFormSteps = cloneDeep(formSteps);
  newFormSteps.forEach((step) => {
    if (step.extraFields.isPipeda) {
      step.hidden = !isCanadaLocation;
      step.extraFields.hidden = !isCanadaLocation;
    }
  });
  return newFormSteps;
};

const showInternalTransferChiclet = (formSteps) => {
  const newFormSteps = cloneDeep(formSteps);
  newFormSteps.forEach((step) => {
    step.collapsed = true;
    if (step.property === 'transferSuccessful') {
      step.collapsed = false;
      step.hidden = true;
      step.extraFields.hidden = true;
    }
    if (step.property === 'transfer') {
      step.collapsed = false;
      step.hidden = false;
      step.extraFields.hidden = false;
      step.state = CHICLET_CARD_STATES.CURRENT.value;
    }
  });
  return newFormSteps;
};

const handleActionButton = async (
  selectedStep,
  selectedOption,
  newActions,
  state,
  onUserInputChange,
  customAction,
) => {
  selectedStep.errors = [];
  let userInputValues = {};
  let response = null;
  try {
    userInputValues =
      selectedStep.answer.userInputValues || selectedOption.userInputValues;
    response = await newActions[customAction].action({
      variables: userInputValues,
    });
    await newActions[customAction].reload(
      userInputValues,
      selectedOption,
      response,
    );

    state = CHICLET_CARD_STATES.ANSWERED.value;
  } catch (errors) {
    const message = getErrorMessage(errors);
    Sentry.captureException(
      new Error(`Request failed with the action: ${customAction} | ${message}`),
    );
    selectedOption.userInputValues = userInputValues;
    selectedStep.errors = message.split(',');
    state = CHICLET_CARD_STATES.CURRENT_WITH_ERROR.value;
    onUserInputChange(selectedStep, selectedOption);
  }
  return { state, [customAction]: response, selectedStep };
};

const showRescheduleCallStep = (formSteps = []) => {
  const newFormSteps = cloneDeep(formSteps);
  const rescheduleCallStep = newFormSteps.find(
    (s) => s.type === stepTypes.completeReschedule.key,
  );
  if (rescheduleCallStep) {
    const finalSteps = newFormSteps.map((step) => {
      if (step.state === CHICLET_CARD_STATES.CURRENT.value) {
        step.state = CHICLET_CARD_STATES.NOT_CURRENT.value;
      }
      step.collapsed = true;
      if (step.type === stepTypes.completeReschedule.key) {
        step.collapsed = false;
        step.hidden = false;
        step.extraFields.hidden = false;
        step.state = CHICLET_CARD_STATES.CURRENT.value;
      }
      return step;
    });
    return finalSteps;
  }
  return newFormSteps;
};

const updateDisabledState = (
  formSteps,
  disabledState,
  allowDisabledChiclets,
) => {
  let newSteps = cloneDeep(formSteps);
  if (!allowDisabledChiclets && newSteps) {
    newSteps = newSteps.map((step) => {
      const newStep = cloneDeep(step);
      newStep.disabled = false;
      return newStep;
    });
    return newSteps;
  }
  const disclaimerStep = newSteps.find(
    (step) => step.type === stepTypes.recordingDisclaimer.key,
  );
  switch (disabledState) {
    case allCallStates.RINGING_ON_OTHER_SIDE.key:
    case allCallStates.RINGING_ON_OUR_SIDE.key:
      newSteps = newSteps.map((step) => {
        const newStep = cloneDeep(step);
        if (newStep.order < disclaimerStep?.order) {
          newStep.disabled = false;
        } else {
          newStep.disabled = true;
        }
        return newStep;
      });
      break;
    case allCallStates.TALKING.key:
      newSteps = newSteps.map((step) => {
        const newStep = cloneDeep(step);
        newStep.disabled = false;
        return newStep;
      });
      break;
    default:
      newSteps = newSteps.map((step) => {
        const newStep = cloneDeep(step);
        newStep.disabled = true;
        return newStep;
      });
      break;
  }
  return newSteps;
};

const showCompleteCallChiclet = (formSteps, dispatch) => {
  const newSteps = cloneDeep(formSteps);

  const foundStep = newSteps.find(
    (step) => step.type === stepTypes.slaNotFound.key,
  );
  const answer = foundStep.answers.find((answer) => answer.closeInquiry);

  const answerNextStep = answer?.nextStep;
  const answerDispositon = answer?.disposition;
  if (answerDispositon) {
    dispatch(updateDisposition(answerDispositon));
  }
  const newFormSteps = newSteps.map((step) => {
    const newStep = cloneDeep(step);
    if (newStep.number === answerNextStep) {
      newStep.hidden = false;
      newStep.extraFields.hidden = false;
      return newStep;
    }
    return newStep;
  });
  return newFormSteps;
};

const showFamilyDisconnectedChiclet = (formSteps) => {
  const newSteps = cloneDeep(formSteps);
  const newFormSteps = newSteps?.map((step) => {
    if (step.property === 'familyDisconnected') {
      step.extraFields.hidden = false;
      step.hidden = false;
      step.state = CHICLET_CARD_STATES.CURRENT.value;
    }
    return step;
  });
  return newFormSteps;
};

const hideFamilyDisconnectedChiclet = (formSteps) => {
  const newSteps = cloneDeep(formSteps);
  const newFormSteps = newSteps.map((step) => {
    if (step.property === 'familyDisconnected') {
      step.extraFields.hidden = true;
      step.hidden = true;
    }
    return step;
  });
  return newFormSteps;
};

export default {
  createFormSummary,
  handleActionButton,
  hideFamilyDisconnectedChiclet,
  isFormEditable,
  setEraseContactInfo,
  showCompleteCallChiclet,
  showFamilyDisconnectedChiclet,
  showFamilyInfoStep,
  showInternalTransferChiclet,
  showPipedaChiclet,
  showRescheduleCallStep,
  updateDisabledState,
  updateManyChoiceStepAnswer,
  updateRelationShipBasedOnInterest,
  updateStepAnswer,
  updateSteps,
  updateStepStates,
  updateTextInputGroupAnswer,
};
