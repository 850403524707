import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  communities_container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  top_bar: {
    height: '72px',
    backgroundColor: theme.palette.neutral.cosmicCobalt1,
    width: '100%',
    boxShadow: '0px 0px 8px rgba(13, 19, 33, 0.5)',
    zIndex: 2,
    padding: '14px 0',
    display: 'flex',
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'center',
  },
  family_file_button: {
    zIndex: -1,
  },
  family_file_button_disabled: {
    pointerEvents: 'none',
  },
  filters_container: {
    height: '93px',
    display: 'flex',
    padding: '15px 32px',
    zIndex: 1,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.light.platinum5,
    width: '100%',
    boxShadow: '0px 0px 8px rgba(13, 19, 33, 0.5)',
    borderRadius: '4px',
    [theme.breakpoints.down(1024)]: {
      flexWrap: 'wrap',
      height: 'auto',
      alignItems: 'center',
      zIndex: 1,
    },
  },
  body: {
    backgroundColor: theme.palette.light.platinum3,
    flexGrow: 1,
  },
  filter: {
    marginRight: '24px',
    [theme.breakpoints.down(1024)]: {
      paddingBottom: '10px',
    },
  },
  error_icon: {
    width: '97px',
    height: '97px',
  },
  more_filters_button: {
    display: 'flex',
    alignSelf: 'flex-end',
    height: '40px',
  },
}));

export { useStyles };
