import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useStyles } from './SendReferral.style';
import CaseProfileSidePanel from '../../components/CaseProfileSidePanel/CaseProfileSidePanel';
import Button from '../../common/Button/Button';
import User from '../../common/Icons/basic/User';
import Typography from '../../common/Typography/Typography';
import CustomLink from '../../common/Link/Link';
import useGraphQLQuery from '../../hooks/useGraphQLQuery';
import sendReferralQuery from './sendReferralQuery';
import Loading from '../../components/Loading/Loading';
import ArrowLeft from '../../common/Icons/arrow/ArrowLeft';
import TextInput from '../../common/TextInput/TextInput';
import Divider from '../../common/Divider/Divider';
import Toast from '../../common/Toast/Toast';
import Send from '../../common/Icons/basic/Send';
import validateFamilyFileService from './validateFamilyFileService';
import useGraphQLMutation from '../../hooks/useGraphQLMutation';
import sendReferralMutation from './sendReferralMutation';
import upsertReferralNotesMutation from './upsertReferralNotesMutation';
import updateTourStatusMutation from './updateTourStatusMutation';
import Alert from '../../common/Alert/Alert';
import Refer from '../../common/Graphics/communities/Refer';
import { registerEvent } from '../../services/Analytics';
import CommunityForReferral from './CommunityForReferral';
import {
  getDateTime,
  getTimeOffsetFromSelectedDate,
} from '../../utils/dateFormat';
import NotesSidePanel from '../../components/NotesSidePanel/NotesSidePanel';
import Sidebar from './Sidebar/Sidebar';
import FamilyFileSidePanel from '../../components/FamilyFileSidePanel/FamilyFileSidePanel';
import DocumentHead from '../../common/DocumentHead/DocumentHead';
import tourScheduleService from '../../services/tourScheduleService';
import useQueryParams from '../../hooks/useQueryParams';
import {
  logReferralAlertErrorViewed,
  logReferralAlertManagerViewed,
  logReferralAlertSendAttempted,
  logReferralAlertSendSucceeded,
  logReferralAlertSettingsCleared,
  logReferralAlertSettingsSaved,
  logReferralAlertSuccessButtonClicked,
  logReferralAlertValidationViewed,
} from '../../services/referredCommunitiesService';
import {
  logTourCancelled,
  logTourRescheduled,
} from '../../services/tourScheduleService';
import { getBooleanFromUrl, getFamilyFileIdFromUrl } from '../../utils/utils';

const SendReferralLG = ({ labels, currentUser }) => {
  const CANCEL_TOUR = 'cancelTour';
  const RESCHEDULE_TOUR = 'rescheduleTour';
  const SCHEDULED = 1;
  const RESCHEDULED = 4;

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [isNotesPanelOpen, setIsNotesPanelOpen] = useState(true);
  const [isFamilyFileSidePanelOpen, setIsFamilyFileSidePanelOpen] = useState(
    false,
  );
  const [communities, setCommunities] = useState([]);
  const [generalText, setGeneralText] = useState('');
  const [generalNoteId, setGeneralNoteId] = useState(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isNoteAlertOpen, setIsNoteAlertOpen] = useState(false);
  const [tourScheduleDetails, setTourScheduleDetails] = useState([]);
  const [familyFileId, setFamilyFileId] = useState();
  const [communitiesStatus, setCommunitiesStatus] = useState();
  const [shouldSaveDraft, setShouldSaveDraft] = useState(false);
  const [
    showScheduledTourInPastToast,
    setShowScheduledTourInPastToast,
  ] = useState(false);
  let familyFileUserId = useRef();
  let isInitialSearch = useRef(true);
  let [isSendNowButtonEnabled, setIsSendNowButtonEnabled] = useState(true);
  const [showClosedMessage, setShowClosedMessage] = useState(false);
  const [showRejectedMessage, setShowRejectedMessage] = useState(false);

  const getSelectedCommunitiesId = () => {
    const params = new URLSearchParams(location.search);
    return params.get('communities').split(',');
  };

  const getRescheduleTourFromUrl = () => {
    const params = new URLSearchParams(location.search);
    const paramValue = params.get('rescheduleTour');
    let rescheduleTour = null;

    try {
      rescheduleTour = JSON.parse(paramValue);
    } catch {
      rescheduleTour = false;
      console.error(
        `SendReferralLG. JSON.parse failed. Error: ${error}. Data: ${paramValue}`,
      );
    }

    return rescheduleTour;
  };

  const familyFileIdFromUrl = getFamilyFileIdFromUrl(location);
  const rescheduleTour = getRescheduleTourFromUrl();
  const selectedTourTime = getBooleanFromUrl(location, 'selectedTourTime');
  const communitiesForCancel = useQueryParams('forCancel');

  const { loading, error, data } = useGraphQLQuery(sendReferralQuery, {
    variables: { familyFileId: familyFileIdFromUrl },
  });

  const handleSendReferralQueryRefetch = async () => {
    shouldSaveDraft && (await handleSaveDraft(false));
    window.location.reload();
  };

  const [updateTourStatus] = useGraphQLMutation(updateTourStatusMutation);

  const [sendReferral] = useGraphQLMutation(sendReferralMutation);
  const [saveReferralNotes] = useGraphQLMutation(upsertReferralNotesMutation);

  const getReferralNote = (communityId, referralNotes) => {
    communityId = Number(communityId);
    let referralNote = {
      id: null,
      text: '',
    };

    if (referralNotes.length > 0) {
      const foundNote = referralNotes.find(
        (status) => status.communityId === communityId,
      );

      if (foundNote) {
        referralNote.id = foundNote.referralNoteId;
        referralNote.text = foundNote.note;
      }
    }
    return referralNote;
  };

  const getReferralStatus = (communityId, referralStatuses) => {
    communityId = Number(communityId);
    let foundstatus = null;
    if (referralStatuses.length > 0) {
      foundstatus = referralStatuses.find((n) => n.communityId === communityId);
      return foundstatus;
    }
  };

  let invalidProperties;

  useEffect(() => {
    if (data?.findFamilyFileById?.referralNotes?.summaryNote) {
      const { summaryNote } = data.findFamilyFileById.referralNotes;
      setGeneralNoteId(summaryNote.referralNoteId);
      setGeneralText(summaryNote.note);
    }

    let referralNotes = [];
    if (data?.findFamilyFileById?.referralNotes?.propertyNotes) {
      referralNotes = data.findFamilyFileById.referralNotes.propertyNotes;
    }

    let referralStatus = [];
    if (data?.findFamilyFileById?.referralStatus) {
      referralStatus = data.findFamilyFileById.referralStatus;
    }

    if (data?.findFamilyFileById?.communities) {
      familyFileUserId.current = data.findFamilyFileById.userId;

      const filteredCommunities = data.findFamilyFileById.communities.filter(
        (community) => {
          const closedCommunityIds = referralStatus
            .filter((status) => status.communityStatus === labels.CLOSED)
            .map((status) => status.communityId);
          if (
            getSelectedCommunitiesId().indexOf(community.id) >= 0 &&
            closedCommunityIds.includes(Number(community.id))
          ) {
            setShowClosedMessage(true);
          }

          const closedAndRejectedCommunityIds = referralStatus
            .filter(
              (status) =>
                status.communityStatus === labels.CLOSED && status.isRejected,
            )
            .map((status) => status.communityId);
          if (
            getSelectedCommunitiesId().indexOf(community.id) >= 0 &&
            closedAndRejectedCommunityIds.includes(Number(community.id))
          ) {
            setShowClosedMessage(true);
            setShowRejectedMessage(true);
          }

          return (
            getSelectedCommunitiesId().indexOf(community.id) >= 0 &&
            !closedCommunityIds.includes(Number(community.id))
          );
        },
      );

      const finalNotRejectedCommunities = filteredCommunities.filter(
        (community) => {
          const rejectedCommunityIds = referralStatus
            .filter((status) => status.isRejected)
            .map((status) => status.communityId);
          if (
            getSelectedCommunitiesId().indexOf(community.id) >= 0 &&
            rejectedCommunityIds.includes(Number(community.id))
          ) {
            setShowRejectedMessage(true);
          }
          return (
            getSelectedCommunitiesId().indexOf(community.id) >= 0 &&
            !rejectedCommunityIds.includes(Number(community.id))
          );
        },
      );

      const finalFilteredCommunites = finalNotRejectedCommunities.map(
        (community) => ({
          ...community,
          id: Number(community.id),
          ...{
            referralNote: getReferralNote(community.id, referralNotes),
            referralStatus: getReferralStatus(community.id, referralStatus),
          },
        }),
      );

      setCommunities(finalFilteredCommunites);

      if (isInitialSearch.current) {
        isInitialSearch.current = false;
        const communityIds = filteredCommunities.map((community) =>
          Number(community.id),
        );
        logReferralAlertManagerViewed(
          communityIds,
          data.findFamilyFileById.familyFileId,
          familyFileUserId.current,
        );
      }

      if (invalidProperties?.length > 0) {
        const errorMessages = invalidProperties.map(
          (property) => property.label,
        );
        createValidationErrorAnalyticsEvents(
          errorMessages,
          data?.findFamilyFileById?.familyFileId,
        );
      }
    }

    if (data?.findFamilyFileById?.tourDetailsExtended) {
      setTourScheduleDetails(data.findFamilyFileById.tourDetailsExtended);

      setCommunitiesStatus(
        data?.findFamilyFileById?.tourDetailsExtended.map((tourDetail) => {
          return {
            communityId: tourDetail.communityId,
            currentStatus: tourDetail.currentStatus,
          };
        }),
      );
    }
    setFamilyFileId(data?.findFamilyFileById?.familyFileId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  const validations = validateFamilyFileService.validate(data, labels);
  invalidProperties = validations.filter((prop) => !prop.valid);

  const tourDetails = data.findFamilyFileById.tourDetailsExtended;

  const residentName =
    data.findFamilyFileById.residents.length > 0
      ? `${data.findFamilyFileById.residents[0].profileData.firstName} ${data.findFamilyFileById.residents[0].profileData.lastName}`
      : labels.UNKNOWN;

  const getPrimaryContact = () => {
    const primaryContact = data.findFamilyFileById.contacts.find(
      (contact) => !!contact.profileData.isPrimary,
    );
    return `${primaryContact.profileData.firstName} ${primaryContact.profileData.lastName}`;
  };

  const handleCommunityTextChange = (
    value,
    community,
    updateCommunities = true,
  ) => {
    if (community.referralNote.text !== value) {
      setShouldSaveDraft(true);
    }
    community.referralNote.text = value;
    if (updateCommunities) {
      setCommunities([...communities]);
    }
  };

  const handleClear = () => {
    setGeneralText('');
    const clearCommunities = communities.map((community) => ({
      ...community,
      ...{
        referralNote: {
          ...community.referralNote,
          ...{ text: '' },
        },
      },
    }));
    setCommunities(clearCommunities);
    createSettingsClearedAnalyticsEvents();
  };

  const saveReferralNote = () => {
    const referralNotes = communities.map((community) => {
      const communityId = Number(community.id);
      return {
        communityId,
        note: community.referralNote.text,
        referralNoteId: community.referralNote.id,
      };
    });

    const referralNotesBody = {
      familyFileId,
      summaryNote: {
        referralNoteId: generalNoteId,
        note: generalText,
      },
      propertyNotes: referralNotes,
    };
    return saveReferralNotes({
      variables: { input: referralNotesBody },
    });
  };

  const handleSaveDraft = async (shouldShowModal = true) => {
    const { data: response } = await saveReferralNote();
    if (response.upsertReferralNotes) {
      createSettingsSavedAnalyticsEvents();
      shouldShowModal && setIsNoteAlertOpen(true);
    }
  };

  const oneId = data.findFamilyFileById.oneId;
  const selfLeadsAudicences = [
    'self_not_urgent_veteran_financially_unqualified',
    'self_urgent_financially_unqualified',
    'self_not_urgent_financially_qualified',
  ];
  const audienceKey = data.findFamilyFileById.audienceKey;

  const isSelfLead = selfLeadsAudicences.some((selfAudience) =>
    audienceKey?.includes(selfAudience),
  );

  const isAnyCommunityAlreadyReferred = () => {
    const referredCommunities = communities.filter((community) => {
      return (
        community.referralStatus.isReferralStarted &&
        community.referralStatus.referralDate
      );
    });
    if (referredCommunities.length > 0) {
      return true;
    }
    return false;
  };

  const getDetailsForReferralEvent = () => {
    let communitiesForEvent = [];
    let toursCount = 0;
    let toursScheduled = { ...tourScheduleService.tourTypesCountTemplate };

    communities.forEach((community) => {
      const isReferralUpdate =
        community.referralStatus.isReferralStarted &&
        community.referralStatus.referralDate;
      const referralType = isReferralUpdate
        ? labels.REFERRAL_UPDATE
        : labels.NEW_REFERRAL;

      communitiesForEvent.push({
        id: Number(community.id),
        careTypes: community.careTypes,
        referralType,
      });
      const tourDetails = tourScheduleDetails.find(
        (tour) => tour.communityId === Number(community.id),
      );
      if (tourDetails?.scheduledTourDate && tourDetails?.tourType) {
        toursCount++;
        toursScheduled[tourDetails.tourType]++;
      }
    });
    return [communitiesForEvent, toursCount, toursScheduled];
  };

  const getDetailsForTourEvent = (requiredTourAction) => {
    let communitiesForEvent = [];
    communities
      .filter((community) => {
        return (
          (requiredTourAction === RESCHEDULE_TOUR &&
            community.isForReschedule) ||
          (requiredTourAction === CANCEL_TOUR && community.isForCancel)
        );
      })
      .forEach((community) => {
        const tourDetails = tourScheduleDetails.find(
          (tour) => tour.communityId === Number(community.id),
        );
        communitiesForEvent.push({
          id: Number(community.id),
          scheduledTourDate: tourDetails?.scheduledTourDate,
          tourType: tourDetails?.tourType,
        });
      });
    return [communitiesForEvent];
  };

  const createSettingsSavedAnalyticsEvents = () => {
    const [
      communities,
      toursCount,
      toursScheduled,
    ] = getDetailsForReferralEvent();

    logReferralAlertSettingsSaved(
      communities,
      familyFileId,
      toursCount,
      toursScheduled,
      familyFileUserId.current,
    );
  };

  const createSettingsClearedAnalyticsEvents = () => {
    const [
      communities,
      toursCount,
      toursScheduled,
    ] = getDetailsForReferralEvent();

    logReferralAlertSettingsCleared(
      communities,
      familyFileId,
      toursCount,
      toursScheduled,
      familyFileUserId.current,
    );
  };

  const createSendAttemptedAnalyticsEvents = () => {
    registerEvent('FamilyFiles', 'Send Referral Letter', new Date().toString());

    const [
      communities,
      toursCount,
      toursScheduled,
    ] = getDetailsForReferralEvent();

    logReferralAlertSendAttempted(
      communities,
      familyFileId,
      toursCount,
      toursScheduled,
      familyFileUserId.current,
    );
  };

  const createRescheduledTourAnalyticsEvents = () => {
    const [rescheduledCommunities] = getDetailsForTourEvent(RESCHEDULE_TOUR);

    if (rescheduledCommunities.length > 0) {
      logTourRescheduled(
        rescheduledCommunities,
        familyFileId,
        familyFileUserId.current,
      );
    }
  };

  const createCancelledTourAnalyticsEvents = () => {
    const [cancelledCommunities] = getDetailsForTourEvent(CANCEL_TOUR);

    if (cancelledCommunities.length > 0) {
      logTourCancelled(
        cancelledCommunities,
        familyFileId,
        familyFileUserId.current,
      );
    }
  };

  const createSuccessButtonAnalyticsEvents = (buttonText) => {
    logReferralAlertSuccessButtonClicked(
      buttonText,
      familyFileId,
      familyFileUserId.current,
    );
  };

  const createValidationErrorAnalyticsEvents = (
    errorMessages,
    familyFileId,
  ) => {
    logReferralAlertValidationViewed(
      errorMessages,
      familyFileId,
      familyFileUserId.current,
    );
  };

  const createValidationLinkAnalyticsEvents = () => {
    // Being implemented in upcoming story (JSB, 11/19/2020)
  };

  const createSuccessAlertAnalyticsEvents = () => {
    const [
      communities,
      toursCount,
      toursScheduled,
    ] = getDetailsForReferralEvent();

    logReferralAlertSendSucceeded(
      communities,
      familyFileId,
      toursCount,
      toursScheduled,
      familyFileUserId.current,
    );
  };

  const createFailureAlertAnalyticsEvents = (errorMessage) => {
    const [communities] = getDetailsForReferralEvent();

    logReferralAlertErrorViewed(
      communities,
      errorMessage,
      familyFileId,
      familyFileUserId.current,
    );
  };

  const handleTourActionChange = (action, community) => {
    community.isForCancel = false;
    community.isForReschedule = false;

    if (action === CANCEL_TOUR) {
      community.isForCancel = true;
    } else {
      if (action === RESCHEDULE_TOUR) {
        community.isForReschedule = true;
      }
    }

    setCommunities([...communities]);
  };

  const cancelTour = async (communityIdsForCancelation) => {
    const communityTourIdsForCancelation = tourDetails
      .filter(
        (detail) =>
          detail.communityId === Number(communityIdsForCancelation[0]),
      )
      .map((detail) => {
        return {
          familyCommunityTourId: detail.familyCommunityTourId,
          scheduledTourDate: detail.scheduledTourDate,
          tourType: detail.tourType,
        };
      });

    const cancelationDate = new Date(
      communityTourIdsForCancelation[0].scheduledTourDate,
    );
    const timeOffset = getTimeOffsetFromSelectedDate(cancelationDate);

    const updateTourStatusBody = {
      familyCommunityTourId:
        communityTourIdsForCancelation[0].familyCommunityTourId,
      status: 'Cancel',
      activityDate: cancelationDate,
      tourType: communityTourIdsForCancelation[0].tourType,
      timeOffset: timeOffset,
    };

    await updateTourStatus({ variables: { input: updateTourStatusBody } });
  };

  const isAnyTourScheduledInPast = () => {
    const communitiesToIncludeSelectedTime = communities.filter((community) => {
      return community.isSelectedTourTime;
    });

    if (communitiesToIncludeSelectedTime.length > 0) {
      const isScheduledTourInPast = communitiesToIncludeSelectedTime.some(
        (community) => {
          const communityId = Number(community.id);
          const tourDetails = tourScheduleDetails.find(
            (tour) => tour.communityId === communityId,
          );

          const communityWithStatus = communitiesStatus.find(
            (communityStatus) => communityStatus.communityId === communityId,
          );

          const today = new Date(Date.now()).setHours(0, 0, 0, 0);

          return (
            tourDetails?.scheduledTourDate < today &&
            !community.isForCancel &&
            communityWithStatus?.currentStatus !== 'Completed' &&
            communityWithStatus?.currentStatus !== 'Canceled'
          );
        },
      );

      return isScheduledTourInPast;
    }
    return false;
  };

  const handleTourCancellations = async () => {
    const communityIdsForCancelation = communities
      .filter((community) => {
        return community.isForCancel;
      })
      .map((community) => community.id);

    if (communityIdsForCancelation.length > 0) {
      await cancelTour(communityIdsForCancelation);
    }
  };

  const buildReferrals = () => {
    const referrals = communities.map((community) => {
      const communityId = Number(community.id);
      const tourDetails = tourScheduleDetails.find(
        (tour) => tour.communityId === communityId,
      );

      const communityWithStatus = communitiesStatus.find(
        (communityStatus) => communityStatus.communityId === communityId,
      );

      const referral = {
        communityId,
        communityName: community.name,
        notes: community.referralNote.text,
        desiredPostalCode:
          data.findFamilyFileById.familyDesiredLocations[0].zip,
      };

      if (tourDetails?.tourType && !community.isForCancel) {
        referral.tourType = tourDetails.tourType;

        referral.tourDateTime = getDateTime(tourDetails.scheduledTourDate);
      }

      if (communityWithStatus?.currentStatus === 'Completed') {
        const lastScheduledTour = getLastScheduledTour(communityId);
        if (lastScheduledTour) {
          referral.tourType = lastScheduledTour?.tourType
            ? lastScheduledTour?.tourType.trim()
            : '';

          referral.tourDateTime = getDateTime(lastScheduledTour.activityDate);
        } else {
          referral.isTourScheduled = false;
          referral.tourDateTime = null;
          referral.tourType = null;
        }
      }

      if (communityWithStatus?.currentStatus === 'Canceled') {
        referral.isTourScheduled = false;
        referral.tourDateTime = null;
        referral.tourType = null;
      }

      referral.doNotSendTourInformation = getDoNotIncludeTourTimeValue(
        communityWithStatus?.currentStatus,
        community,
        selectedTourTime,
        tourDetails?.scheduledTourDate,
      );

      if (community.isForCancel) {
        referral.isTourScheduled = false;
      }

      return referral;
    });
    return referrals;
  };

  const sendReferrals = async () => {
    const referrals = buildReferrals();

    const sendReferralBody = {
      familyFileId,
      userName: currentUser.username,
      userRole: currentUser.role,
      generalNote: generalText,
      referrals,
      oneId,
    };

    createSendAttemptedAnalyticsEvents();

    try {
      const { data: response } = await sendReferral({
        variables: { input: sendReferralBody },
      });
      if (response.sendCommunityReferral?.result === 'emails sent') {
        setIsAlertOpen(true);
        createSuccessAlertAnalyticsEvents();
        createRescheduledTourAnalyticsEvents();
        createCancelledTourAnalyticsEvents();
      } else {
        createFailureAlertAnalyticsEvents(
          response.sendCommunityReferral?.result,
        );
      }
    } catch (exception) {
      createFailureAlertAnalyticsEvents(
        exception.message ? exception.message : exception,
      );
      throw exception;
    }
  };

  const checkScheduleTourInFuture = () => {
    let isScheduleTourInFuture = true;

    if (selectedTourTime) {
      const isTourScheduledInPast = isAnyTourScheduledInPast();
      if (isTourScheduledInPast) {
        isScheduleTourInFuture = false;
      }
    }

    return isScheduleTourInFuture;
  };

  const handleSendReferral = async () => {
    setIsSendNowButtonEnabled(false);

    await handleTourCancellations();

    const { data: response } = await saveReferralNote();

    if (response.upsertReferralNotes) {
      const isScheduleTourInFuture = checkScheduleTourInFuture();

      if (isScheduleTourInFuture) {
        await sendReferrals();
      } else {
        setShowScheduledTourInPastToast(true);
        setIsSendNowButtonEnabled(true);
      }
    }
  };

  const getLastScheduledTour = (communityId) => {
    const activities = tourScheduleDetails.find(
      (tourScheduled) =>
        parseInt(communityId) === parseInt(tourScheduled.communityId),
    ).tourActivities;

    const lastScheduledTour = activities
      .reverse()
      .find(
        (activity) =>
          activity.status === SCHEDULED || activity.status === RESCHEDULED,
      );

    return lastScheduledTour;
  };

  const getDoNotIncludeTourTimeValue = (
    currentStatus,
    community,
    selectedTourTimeInUrl,
    scheduledTourTime,
  ) => {
    if (
      currentStatus &&
      (currentStatus === 'Completed' || currentStatus === 'Canceled')
    ) {
      return true;
    }
    if (selectedTourTimeInUrl) {
      return !community.isSelectedTourTime;
    }

    const today = new Date(Date.now()).getTime();
    const todayInUTC = new Date(
      today - new Date().getTimezoneOffset() * 60 * 1000,
    ).getTime();

    return scheduledTourTime < todayInUTC;
  };

  const handleSelectedTourTimeChange = (isSelectedTourTime, communityId) => {
    const community = communities.find(
      (community) => community.id === communityId,
    );
    if (community) {
      community.isSelectedTourTime = isSelectedTourTime;
      if (!isSelectedTourTime) {
        setShowScheduledTourInPastToast(false);
      }
    }
  };

  const getTourDetails = (tours, communityId) => {
    return tours.find(
      (tour) => parseInt(tour.communityId) === parseInt(communityId),
    );
  };

  const isCommunityForCancel = (id) => {
    if (!communitiesForCancel) return false;
    return communitiesForCancel.split(',').includes(id.toString());
  };

  const handleOnTourSave = (
    activityDate,
    selectedType,
    communityId,
    checked,
    familyCommunityTourId = null,
  ) => {
    setShowScheduledTourInPastToast(false);
    const tour = getTourDetails(tourScheduleDetails, communityId);
    if (tour) {
      if (checked) {
        tour.scheduledTourDate = activityDate;
        tour.tourType = selectedType;
        tour.familyCommunityTourId = familyCommunityTourId;
        setTourScheduleDetails([...tourScheduleDetails]);
      } else {
        const uncheckedCommunities = tourScheduleDetails.filter(
          (tour) => parseInt(tour.communityId) !== parseInt(communityId),
        );
        setTourScheduleDetails(uncheckedCommunities);
      }
    } else {
      if (checked) {
        const newTour = {
          communityId: parseInt(communityId),
          scheduledTourDate: activityDate,
          tourType: selectedType,
          familyCommunityTourId,
        };
        tourScheduleDetails.push(newTour);
        setTourScheduleDetails([...tourScheduleDetails]);
      }
    }
  };

  const openFamilyFileSidePanel = () => {
    createValidationLinkAnalyticsEvents();
    setIsNotesPanelOpen(false);
    setIsFamilyFileSidePanelOpen(true);
  };

  const renderValidationLink = () => {
    return (
      <CustomLink
        className={classes.link}
        onClick={() => openFamilyFileSidePanel()}
      >
        <Typography>{labels.OPEN_FAMILY_FILE_PANEL}</Typography>
      </CustomLink>
    );
  };

  const getDocumentTitle = () => {
    return `${getPrimaryContact()}: ${labels.REFERRAL_ALERT} `;
  };

  const handleNoteSuccessClose = () => {
    setIsNoteAlertOpen(false);
  };

  const handleSuccessFamilyLetterClick = () => {
    createSuccessButtonAnalyticsEvents(labels.CONTINUE_TO_FAMILY_LETTER);

    const communityIdsString = communities
      .map((community) => Number(community.id))
      .join(',');

    history.push(
      `/send-family-file-letter/${familyFileId}?communities=${communityIdsString}`,
    );
  };

  const goToCommunitiesPage = () => {
    history.push(`/family-file/${familyFileId}`);
  };

  const goToCommunitiesPageButton = (type) => (
    <Button size="small" type={type} onClick={goToCommunitiesPage}>
      {labels.GO_TO_COMMUNITIES_PAGE}
    </Button>
  );

  const renderSuccessModalBody = () => {
    return (
      <>
        <Typography color="eerieBlack5">
          {labels.SUCCESSFULLY_SENT_REFERRAL_LIST}
        </Typography>
        <ul className={classes.community_list}>
          {communities.map((community, key) => (
            <li key={key}>{community.name}</li>
          ))}
        </ul>
        {isAnyCommunityAlreadyReferred() && (
          <div className={classes.buttons_container}>
            <Button
              className={classes.family_letter_button}
              onClick={handleSuccessFamilyLetterClick}
              type={'light'}
            >
              {labels.CONTINUE_TO_FAMILY_LETTER}
            </Button>
            {goToCommunitiesPageButton()}
          </div>
        )}
        {!isAnyCommunityAlreadyReferred() && (
          <div className={classes.buttons_container}>
            {goToCommunitiesPageButton('light')}
            <Button
              className={classes.family_letter_button}
              onClick={handleSuccessFamilyLetterClick}
            >
              {labels.CONTINUE_TO_FAMILY_LETTER}
            </Button>
          </div>
        )}
      </>
    );
  };

  const disableScheduleTourForHomeCare = (community) => {
    return !!community.careTypes?.homeCare;
  };

  const handleTourStatusChange = (newStatus, communityId) => {
    const newStatuses = communitiesStatus.map((communityStatus) => {
      const status =
        communityStatus.communityId === communityId
          ? newStatus
          : communityStatus.currentStatus;
      return {
        communityId: communityStatus.communityId,
        currentStatus: status,
      };
    });

    setCommunitiesStatus(newStatuses);
  };

  const handleGeneralTextChange = (value) => {
    setGeneralText(value);
    setShouldSaveDraft(true);
  };

  return (
    <div className={classes.send_referral_container}>
      <DocumentHead title={getDocumentTitle()} />
      <Alert
        type="success"
        title={labels.NOTES_SAVED}
        description={labels.SUCCESSFULLY_SAVED_NOTES}
        confirmText={labels.OK}
        onConfirm={handleNoteSuccessClose}
        onClose={handleNoteSuccessClose}
        isOpen={isNoteAlertOpen}
      />
      <Alert
        isOpen={isAlertOpen}
        modalBody={renderSuccessModalBody()}
        title={labels.SUCCESS}
        showCloseButton={false}
        type="success"
      />
      <CaseProfileSidePanel
        familyFileId={familyFileId}
        labels={labels}
        isOpen={isSidePanelOpen}
        onClose={() => setIsSidePanelOpen(false)}
      />
      <div className={classes.top_bar}>
        <div className={classes.back_container}>
          <Link
            className={classes.back_link}
            onClick={() => history.goBack()}
            to={'#'}
          >
            <ArrowLeft />
            <Typography bold color="platinum5">
              {labels.BACK}
            </Typography>
          </Link>
        </div>
        <Button
          className={classes.disabled_button}
          startIcon={<User />}
          onClick={() => setIsSidePanelOpen(true)}
        >
          <Typography color="platinum5">
            {`${getPrimaryContact()} (${labels.ID}: ${familyFileId})`}
          </Typography>
        </Button>
      </div>
      {communities.length > 0 && (
        <>
          <div className={classes.lg_main_container}>
            <div
              className={clsx(classes.lg_body, {
                [classes.lg_body_shift]:
                  isNotesPanelOpen || isFamilyFileSidePanelOpen,
              })}
            >
              <div className={classes.lg_referral_body}>
                <div className={classes.lg_referral_body_content}>
                  <Typography bold level="large">
                    {labels.REFERRAL_ALERT_MANAGER}
                  </Typography>
                  {invalidProperties.length > 0 && (
                    <Toast
                      type="error"
                      className={classes.error_toast}
                      onClose={() => {}}
                    >
                      <div>
                        <Typography>
                          {labels.YOU_ARE_MISSING_INFORMATION}
                        </Typography>
                        {invalidProperties.map((prop, key) => (
                          <Typography key={key}>{`- ${prop.label}`}</Typography>
                        ))}
                        {renderValidationLink()}
                      </div>
                    </Toast>
                  )}
                  <div className={classes.note}>
                    <Typography level="small">
                      <b>{labels.PLEASE_NOTE}: </b>
                      {labels.THOUGH_NOT_MANDATORY}
                    </Typography>
                  </div>
                  {showScheduledTourInPastToast && (
                    <Toast
                      type="error"
                      className={classes.toast}
                      onClose={() => setShowScheduledTourInPastToast(false)}
                    >
                      {labels.SCHEDULING_TOUR_IN_PAST}
                    </Toast>
                  )}
                  <Typography
                    className={classes.about_the_family}
                    bold
                    level="large"
                  >
                    {labels.ABOUT_THE_FAMILY}
                  </Typography>
                  {!isSelfLead && (
                    <Toast className={classes.toast} type="warning">
                      <div className={classes.referral_toast_message}>
                        <Typography>
                          {labels.REFERRAL_TOAST_MESSAGE} <b>{residentName}</b>
                        </Typography>
                        <Typography>
                          {labels.PRIMARY_CONTACT}: <b>{getPrimaryContact()}</b>
                        </Typography>
                      </div>
                    </Toast>
                  )}
                  <TextInput
                    className={classes.general_text_input}
                    multiline
                    maxLength={2500}
                    value={generalText}
                    onChange={handleGeneralTextChange}
                    rows={3}
                    label={
                      isSelfLead
                        ? labels.ADDITIONAL_NOTES
                        : labels.MESSAGE_TO_ALL_COMMUNITIES_SELECTED
                    }
                  />

                  {communities.map((community, key) => (
                    <div key={key}>
                      <CommunityForReferral
                        className={classes.community_container}
                        community={community}
                        currentUser={currentUser}
                        dataForAnalytics={{
                          originatingPage: 'referral alert',
                        }}
                        disableScheduleTourForHomeCare={disableScheduleTourForHomeCare(
                          community,
                        )}
                        familyFileId={familyFileId}
                        familyFileUserId={familyFileUserId.current}
                        isForCancelFromUrl={isCommunityForCancel(community.id)}
                        isForRescheduleTour={rescheduleTour}
                        labels={labels}
                        onChange={handleCommunityTextChange}
                        onSelectedTourTimeChange={handleSelectedTourTimeChange}
                        onTourActionChange={(action, community) =>
                          handleTourActionChange(action, community)
                        }
                        onTourScheduleChange={handleOnTourSave}
                        onTourStatusChange={handleTourStatusChange}
                        selectedTourTime={selectedTourTime}
                        tourDetails={getTourDetails(tourDetails, community.id)}
                      />
                      {key !== communities.length - 1 && (
                        <Divider color="disabled" className={classes.divider} />
                      )}
                    </div>
                  ))}
                </div>
                <div className={classes.lg_referral_body_footer}>
                  <div className={classes.clear} onClick={handleClear}>
                    <Typography level="small">{labels.CLEAR}</Typography>
                  </div>
                  <Button type="light" onClick={handleSaveDraft}>
                    <Typography level="small">
                      {labels.SAVE_SETTINGS}
                    </Typography>
                  </Button>
                  <Button
                    type={
                      invalidProperties.length > 0 || !isSendNowButtonEnabled
                        ? 'disabled'
                        : 'secondary'
                    }
                    startIcon={<Send />}
                    onClick={handleSendReferral}
                  >
                    <Typography level="small" color="platinum5">
                      {labels.SEND_NOW}
                    </Typography>
                  </Button>
                </div>
              </div>
            </div>
            {!(isNotesPanelOpen || isFamilyFileSidePanelOpen) && (
              <Sidebar
                labels={labels}
                onClickShowNotes={() => {
                  setIsNotesPanelOpen(true);
                }}
                onClickShowFamilyFile={() => {
                  setIsFamilyFileSidePanelOpen(true);
                }}
                hideFamilyFileIcon={false}
              />
            )}
          </div>
          <NotesSidePanel
            familyFileId={familyFileIdFromUrl}
            labels={labels}
            isOpen={isNotesPanelOpen}
            onClose={() => setIsNotesPanelOpen(false)}
            screenName="referral alert"
          />
          <FamilyFileSidePanel
            familyFileId={familyFileIdFromUrl}
            labels={labels}
            isOpen={isFamilyFileSidePanelOpen}
            onClose={() => setIsFamilyFileSidePanelOpen(false)}
            onSave={handleSendReferralQueryRefetch}
          />
        </>
      )}
      {communities.length === 0 && (
        <div className={classes.empty_body}>
          <div className={classes.no_referrals_container}>
            <Refer className={classes.referral_icon} />
            <Typography level={'large'} bold>
              {labels.NO_REFERRED_COMMUNITIES}
            </Typography>
            {showClosedMessage && (
              <Typography className={classes.no_referrals_text}>
                {labels.CLOSED_COMMUNITY_TOOLTIP_TEXT}
              </Typography>
            )}
            {showRejectedMessage && (
              <Typography className={classes.no_referrals_text}>
                {labels.COMMUNITY_REJECTED_REFERALL}
              </Typography>
            )}
            <div className={classes.buttons_container_referral}>
              {goToCommunitiesPageButton()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

SendReferralLG.propTypes = {
  currentUser: PropTypes.object,
  labels: PropTypes.object,
};

export default SendReferralLG;
