import React from 'react';
import PropTypes from 'prop-types';

const UhOhThereWasAProblem = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55 77.4984H25H15V73.8734C15 71.3609 15.85 68.9359 17.425 66.9734C19.0875 64.8984 21.4375 63.4859 24.0625 63.0109L31.55 61.6484L33.75 71.2484L40 67.4984L46.25 71.2484L48.45 61.6609L55.925 63.0109C58.525 63.4859 60.875 64.8859 62.5375 66.9484L62.55 66.9609C64.1375 68.9234 65 71.3734 65 73.8984V77.4984H55Z"
        fill="#A15EB5"
      />
      <path
        d="M46.25 61.25L48.45 61.6625L46.25 71.25L40 67.5L46.25 61.25Z"
        fill="#C592C7"
      />
      <path
        d="M39.9998 67.5L33.7498 71.25L31.5498 61.65L33.7498 61.25L39.9998 67.5Z"
        fill="#C592C7"
      />
      <path
        d="M46.25 61.25L40 67.5L33.75 61.25L35 57.5H44.9625L46.2125 61.25H46.25Z"
        fill="#F3E0D4"
      />
      <path
        d="M2.5 17.5H77.5V55C77.5 56.375 76.375 57.5 75 57.5H44.9625H35H5C3.625 57.5 2.5 56.375 2.5 55V17.5Z"
        fill="#FEFEFE"
      />
      <path
        d="M77.5 5V17.5H2.5V5C2.5 3.625 3.625 2.5 5 2.5H75C76.375 2.5 77.5 3.625 77.5 5Z"
        fill="#82B7A8"
      />
      <path
        d="M21.25 12.5H8.75C8.06 12.5 7.5 11.94 7.5 11.25V8.75C7.5 8.06 8.06 7.5 8.75 7.5H21.25C21.94 7.5 22.5 8.06 22.5 8.75V11.25C22.5 11.94 21.94 12.5 21.25 12.5Z"
        fill="#FEFEFE"
      />
      <path
        d="M45 42.5H35V45H33.75C31.675 45 30 43.325 30 41.25C30 40.2125 30.425 39.275 31.1 38.6C31.775 37.925 32.7125 37.5 33.75 37.5H46.25C48.325 37.5 50 39.175 50 41.25C50 42.2875 49.575 43.225 48.9 43.9C48.225 44.575 47.2875 45 46.25 45H45V42.5Z"
        fill="#D6621B"
      />
      <path
        d="M40 52.5C38.625 52.5 37.375 51.9375 36.4625 51.0375C35.5625 50.125 35 48.875 35 47.5V45V42.5H45V45V47.5C45 50.2625 42.7625 52.5 40 52.5Z"
        fill="#E1737D"
      />
      <path d="M47.5 7.5H72.5V12.5H47.5V7.5Z" fill="#E9CE73" />
      <path
        d="M75 1.25H5C2.9325 1.25 1.25 2.9325 1.25 5V55C1.25 57.0675 2.9325 58.75 5 58.75H33.2663L32.7987 60.1525L23.8425 61.7812C20.9237 62.3125 18.295 63.88 16.4437 66.1963C14.7075 68.3662 13.75 71.0925 13.75 73.8725V78.75H66.25V73.8962C66.25 71.1 65.2838 68.36 63.5125 66.1637C61.6638 63.8688 59.0462 62.3125 56.1438 61.785L47.1625 60.1525L46.695 58.75H75C77.0675 58.75 78.75 57.0675 78.75 55V5C78.75 2.9325 77.0675 1.25 75 1.25ZM5 3.75H75C75.69 3.75 76.25 4.31125 76.25 5V16.25H3.75V5C3.75 4.31125 4.31 3.75 5 3.75ZM34.5837 69.2925L33.06 62.6463L33.3288 62.5975L37.985 67.2525L34.5837 69.2925ZM61.5825 67.7513C62.98 69.4863 63.75 71.6675 63.75 73.8962V76.25H56.25V68.75H53.75V76.25H26.25V68.75H23.75V76.25H16.25V73.8725C16.25 71.6588 17.0112 69.4863 18.3962 67.7563C19.8713 65.9113 21.9637 64.6625 24.2887 64.24L30.5988 63.0925L32.9163 73.2075L40 68.9575L47.0837 73.2075L49.4 63.1L55.6975 64.245C58.0075 64.665 60.0912 65.9037 61.5825 67.7513ZM46.665 62.6025L46.9387 62.6525L45.4175 69.2912L42.0163 67.2513L46.665 62.6025ZM44.79 60.9425L40 65.7325L35.18 60.9125L35.9013 58.75H44.0612L44.79 60.9425ZM75 56.25H5C4.31 56.25 3.75 55.6888 3.75 55V18.75H76.25V55C76.25 55.6888 75.69 56.25 75 56.25Z"
        fill="#0D1321"
      />
      <path
        d="M8.75 13.75H21.25C22.6287 13.75 23.75 12.6287 23.75 11.25V8.75C23.75 7.37125 22.6287 6.25 21.25 6.25H8.75C7.37125 6.25 6.25 7.37125 6.25 8.75V11.25C6.25 12.6287 7.37125 13.75 8.75 13.75ZM13.75 11.25V8.75H16.25V11.25H13.75ZM21.2512 11.25H18.75V8.75H21.25L21.2512 11.25ZM8.75 8.75H11.25V11.25H8.75V8.75Z"
        fill="#0D1321"
      />
      <path
        d="M73.75 6.25H46.25V13.75H73.75V6.25ZM71.25 11.25H48.75V8.75H71.25V11.25Z"
        fill="#0D1321"
      />
      <path
        d="M46.25 36.25H33.75C30.9925 36.25 28.75 38.4925 28.75 41.25C28.75 44.0075 30.9925 46.25 33.75 46.25V47.5C33.75 50.9462 36.5538 53.75 40 53.75C43.4462 53.75 46.25 50.9462 46.25 47.5V46.25C49.0075 46.25 51.25 44.0075 51.25 41.25C51.25 38.4925 49.0075 36.25 46.25 36.25ZM43.75 47.5C43.75 49.1275 42.7013 50.5025 41.25 51.02V48.75H38.75V51.02C37.2987 50.5025 36.25 49.1275 36.25 47.5V43.75H43.75V47.5ZM46.25 43.75V41.25H33.75V43.75C32.3712 43.75 31.25 42.6287 31.25 41.25C31.25 39.8712 32.3712 38.75 33.75 38.75H46.25C47.6287 38.75 48.75 39.8712 48.75 41.25C48.75 42.6287 47.6287 43.75 46.25 43.75Z"
        fill="#0D1321"
      />
      <path
        d="M32.8662 32.1347L34.6337 30.3672L30.5175 26.2509L34.6337 22.1347L32.8662 20.3672L28.75 24.4834L24.6337 20.3672L22.8662 22.1347L26.9825 26.2509L22.8662 30.3672L24.6337 32.1347L28.75 28.0184L32.8662 32.1347Z"
        fill="#0D1321"
      />
      <path
        d="M55.3662 20.3672L51.25 24.4834L47.1337 20.3672L45.3662 22.1347L49.4825 26.2509L45.3662 30.3672L47.1337 32.1347L51.25 28.0184L55.3662 32.1347L57.1337 30.3672L53.0175 26.2509L57.1337 22.1347L55.3662 20.3672Z"
        fill="#0D1321"
      />
    </svg>
  );
};

UhOhThereWasAProblem.propTypes = {
  className: PropTypes.string,
};

export default UhOhThereWasAProblem;
