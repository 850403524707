import React, { useEffect, useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { useStyles } from './TextInput.style';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import { generateId } from '../utils/random';

const TextInput = ({
  label,
  id,
  value,
  error,
  disabled,
  textHint,
  maxLength,
  multiline,
  rows,
  className,
  placeholder,
  rowsMax,
  onChange,
  onFocus,
  ref,
  type,
  min,
  max,
  onBlur,
  startIcon: StartIcon,
  endIcon: EndIcon,
  isMandatory,
  autoComplete,
  onClick,
  onKeyPress,
}) => {
  const classes = useStyles();

  const [currentValue, setCurrentValue] = useState('');

  const containerClasses = clsx(
    className,
    error && classes.error,
    disabled && classes.disabled,
    currentValue && classes.filled,
  );

  const labelClass = clsx(classes.label, disabled && 'label_disabled');

  const handleResizeObserverError = (event) => {
    if (event.message === 'ResizeObserver loop limit exceeded') {
      console.log(
        'Ignore: ResizeObserver loop limit exceeded triggered by Grammarly extension',
      );
      return true;
    }
  };

  useEffect(() => {
    window.addEventListener('error', handleResizeObserverError);

    return () => {
      window.removeEventListener('error', handleResizeObserverError);
    };
  }, []);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const getStartIcon = () => {
    if (StartIcon) {
      return (
        <InputAdornment className={classes.startIcon} position="start">
          {StartIcon}
        </InputAdornment>
      );
    }
  };
  const getEndIcon = () => {
    if (EndIcon) {
      return (
        <InputAdornment className={classes.endIcon} position="end">
          {EndIcon}
        </InputAdornment>
      );
    }
  };

  const handleOnChange = (event) => {
    let value = event.target.value;
    if (type === 'text') {
      onChange(event.target.value);
      setCurrentValue(event.target.value);
    }
    if (type === 'number') {
      value = value.replace(/,/g, '');
      if (value === '') {
        value = 0;
      }
      const number = Number(value);
      if (!isNaN(number)) {
        if (number >= min && number <= max) {
          onChange(value);
          setCurrentValue(value);
        }
      }
    }
    if (type === 'phoneword') {
      const hasPhoneCharacter = value.includes('#') || value.includes('*');
      let number = Number(value.replace(/[^0-9]/g, ''));
      if (!isNaN(number) || hasPhoneCharacter) {
        if (number >= min && number <= max) {
          const phoneNumber = value.replace(/[^0-9|\\*|\\#]/g, '');
          onChange(phoneNumber);
          setCurrentValue(phoneNumber);
        }
      }
    }
  };

  const handleOnBlur = () => {
    onBlur(currentValue);
  };

  return (
    <div className={containerClasses} onClick={onClick}>
      <InputLabel className={labelClass} htmlFor={id}>
        {isMandatory && <span className={classes.mandatory}>*</span>}
        {label}
      </InputLabel>
      <TextField
        onKeyPress={onKeyPress}
        inputRef={ref}
        onFocus={onFocus}
        placeholder={placeholder}
        value={currentValue}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        disabled={disabled}
        id={id}
        className={classes.input}
        variant="outlined"
        inputProps={{
          maxLength: maxLength,
          autoComplete: autoComplete,
        }}
        InputProps={{
          startAdornment: getStartIcon(),
          endAdornment: getEndIcon(),
        }}
      />
      {textHint && <div className={classes.text_hint}>{textHint}</div>}
    </div>
  );
};

TextInput.propTypes = {
  error: PropTypes.bool,
  ref: PropTypes.object,
  type: PropTypes.oneOf(['text', 'number', 'phoneword']),
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  value: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  multiline: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  textHint: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  isMandatory: PropTypes.bool,
  autoComplete: PropTypes.string,
  onKeyPress: PropTypes.func,
};

TextInput.defaultProps = {
  value: '',
  type: 'text',
  placeholder: '',
  className: '',
  error: false,
  rows: 1,
  rowsMax: 1000,
  multiline: false,
  disabled: false,
  min: 0,
  max: Infinity,
  id: generateId(),
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onClick: () => {},
  isMandatory: false,
  autoComplete: 'on',
};

export default TextInput;
