import React from 'react';
import PropTypes from 'prop-types';

const CallDecline = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M11.9968 12C12.9237 12.0002 13.8091 12.1184 14.4267 12.324C14.6962 12.4137 14.8914 12.5127 14.9958 12.5995L14.9958 13.5011C14.9958 13.7545 14.9974 13.8811 15.0053 14.0441C15.0604 15.1844 15.3496 15.9554 16.4018 15.9981C18.4166 16.3327 19.7627 16.5 20.4944 16.5C21.9503 16.5 22.994 15.1896 22.994 14C22.994 13.8651 22.9947 13.756 22.9969 13.5091C22.9992 13.2587 23 13.1468 23 13.0055C23 12.8114 22.9981 12.6391 22.9936 12.4728C22.994 8.77688 17.9691 6.00129 11.9972 6C6.02665 5.99871 1 8.77611 1 12.5V14C1 15.1968 2.04222 16.5 3.49955 16.5C4.23197 16.5 5.57993 16.3324 7.59794 15.9972C8.36504 15.953 8.7653 15.3959 8.91482 14.6767C8.98526 14.3378 8.99831 14.0561 8.99819 13.4998V12.5994C9.10254 12.5125 9.29782 12.4134 9.56735 12.3236C10.185 12.118 11.0704 11.9998 11.9968 12ZM6.99819 12.5V13.5C6.99825 13.7607 6.99451 13.9375 6.98362 14.0714C5.23279 14.3571 4.05591 14.5 3.49955 14.5C3.25738 14.5 3 14.1782 3 14V12.5C3 10.2002 6.98401 7.99892 11.9968 8C17.0111 8.00108 20.994 10.2011 20.994 12.5C20.9983 12.6734 21 12.828 21 13.0051C21 13.1386 20.9992 13.2465 20.997 13.4909C20.9947 13.744 20.994 13.8571 20.994 14C20.994 14.1722 20.7329 14.5 20.4944 14.5C19.9381 14.5 18.7615 14.3571 17.0111 14.0715C17.0079 14.0335 17.0051 13.9921 17.003 13.9476C16.9971 13.8269 16.9958 13.7225 16.9958 13.4998V12.5C16.9958 10.7343 14.7914 10.0006 11.9972 10C9.20465 9.9994 6.99819 10.7341 6.99819 12.5Z"
      />
    </svg>
  );
};

CallDecline.propTypes = {
  className: PropTypes.string,
};

export default CallDecline;
