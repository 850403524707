import React from 'react';
import PropTypes from 'prop-types';

const MedicationManagement = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M3.07519 22.9248C0.30827 20.1579 0.30827 15.5865 3.07519 12.8195L12.8196 3.07519C15.5865 0.308271 20.1579 0.308271 22.9248 3.07519C25.6917 5.84211 25.6917 10.4135 22.9248 13.1805L13.1805 22.9248C10.4135 25.6917 5.84211 25.6917 3.07519 22.9248Z" 
        stroke="currentColor" 
        stroke-miterlimit="10" 
        stroke-linecap="round" 
        stroke-linejoin="round"
      />
      <path 
        d="M7.8877 8.00745L17.993 18.1127" 
        stroke="currentColor" 
        stroke-miterlimit="10" 
        stroke-linecap="round" 
        stroke-linejoin="round"
      />
      <path 
        d="M11.1357 8.12776L15.1057 4.15784" 
        stroke="currentColor" 
        stroke-miterlimit="10" 
        stroke-linecap="round" 
        stroke-linejoin="round"
      />
      <path 
        d="M4.03857 15.2254L8.0085 11.2555" 
        stroke="currentColor" 
        stroke-miterlimit="10" 
        stroke-linecap="round" 
        stroke-linejoin="round"
      />
    </svg>
  );
};

MedicationManagement.propTypes = {
  className: PropTypes.string,
};

export default MedicationManagement;
