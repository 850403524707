import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  row: {
    '& p': {
      display: 'inline',
      margin: 0,
    },
  },
  inline: {
    display: 'inline',
    '& div': {
      float: 'right',
    },
    '& svg, p:first-child': {
      marginRight: '4px',
      verticalAlign: 'middle',
    },
    '& p': {
      marginRight: theme.spacing(1),
    },
  },
  actions: {
    marginTop: theme.spacing(3),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(2),
    },
  },
  main_container: {},
  complete_actions_container: {},
  complete_action_options: {
    '& label': {
      marginRight: 8,
    },
  },
}));

export { useStyles };
