import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles(() => ({
  case_profile_container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  tabs: {
    flexGrow: 1,
    '& .tabs_container': {
      boxShadow: 'none',
      border: 'none',
      backgroundColor: 'transparent',
      '& div:first-child': {
        border: 'none',
      },
    },
  },
}));

export { useStyles };
