const five9ErrorCodes = {
  '-1': 'INTERNAL_ERROR',
  0: 'SERVER_UNAVAILABLE',
  1: 'NOT_AUTHORIZED',
  10: 'SKILL_VOICEMAIL_HAS_NO_CONTACT',
  10000: 'INVALID_ACCOUNT',
  10001: 'PASSWORD_EXPIRED',
  10002: 'ACCOUNT_LOCKED',
  10003: 'DUPLICATE_LOGIN',
  10005: 'CALL_NOT_IN_TIME',
  10006: 'NUMBER_IN_DNC_LIST',
  1001: 'Q_NUMBER_IN_DNC_LIST_FORCE',
  10011: 'ALREADY_ON_FARM',
  10012: 'USER_HAS_ACTIVE_TASK',
  1002: 'Q_MULTIPLE_CONTACTS_SELECT',
  11: 'UNSUPPORTED',
  11001: 'FAILED_TO_REMOVE_CONTACT',
  11002: 'SIMILAR_CONTACT',
  11003: 'INCORRECT_CONTACT_FIELD_VALUE',
  11004: 'CONTACT_DOES_NOT_EXIST',
  11005: 'CONTACT_OBSOLETE',
  11006: 'FIELD_DOES_NOT_EXIST',
  11007: 'UNMODIFIABLE_CONTACT_FIELD',
  11008: 'CRM_SERVICE_FAILURE',
  11009: 'CRM_SERVICE_UNAVAILABLE',
  11010: 'NO_CAMPAIGN_SCRIPT',
  11011: 'SOCIAL_SERVICES_UNAVAILABLE',
  11012: 'DUPLICATE_CHAT_ID',
  12: 'RETRY_LATER',
  12001: 'RESTART_STATION_FAILED',
  12002: 'STATION_BUSY',
  12003: 'STATION_RESTARTING',
  12004: 'CALLER_DISCONNECTED',
  12007: 'PASSWORD_MATCH_PREVIOUS',
  12008: 'PASSWORD_MIN_CAPITAL_LETTERS',
  12009: 'PASSWORD_MIN_DIGITS',
  12010: 'PASSWORD_MIN_LENGTH',
  12011: 'PASSWORD_MIN_SPECIAL_CHARACTERS',
  12012: 'CANNOT_LOG_OUT_DURING_ACTIVE_CALL',
  12013: 'CANNOT_LOG_OUT_WHEN_PARKED_CALLS',
  12014: 'CANNOT_LOG_OUT_AFTER_ACCEPTING_VOICEMAIL',
  12015: 'CANNOT_DO_FOR_TEST_CALL',
  12016: 'INVALID_WAV_FILE_FORMAT',
  12017: 'VOICEMAIL_MAX_COMMENT_LENGTH_EXCEEDED',
  12018: 'WRONG_PASSWORD',
  12019: 'CONSULTANT_DISCONNECTED',
  12020: 'MUST_BE_NOT_READY_TO_MAKE_ECHO_CALL',
  12021: 'INVALID_PHONE_NUMBER',
  12022: 'NO_RECIPIENTS_AVAILABLE',
  12023: 'NOT_A_NUMBER_FIELD',
  12024: 'NUMBER_IS_RESTRICTED_FOR_CALL',
  12025: 'INCORRECT_CONTACT_RECORD_NO_NUMBER_FIELDS',
  12026: 'INVALID_FILE_FORMAT',
  12027: 'PSTN_BILL_RATE_NOT_FOUND',
  12028: 'INVALID_STATION_ID',
  12029: 'HAS_ACTIVE_TASK',
  12030: 'AGENT_NOT_TRANFERABLE',
  12031: 'PREVIEW_HAS_NO_NUMBERS_TO_CALL',
  12032: 'INVALID_CONTACT_RECORD',
  12033: 'EMAIL_ATTACHMENT_ID_NOT_FOUND',
  12034: 'EMAIL_ATTACHMENT_FILE_NOT_FOUND',
  12035: 'NOT_ALLOWED_TO_CHANGE_LANGUAGE',
  12036: 'UNSUPPORTED_LANGUAGE',
  12037: 'INVALID_WORKSHEET_ANSWERS',
  12038: 'PREVIEW_HAS_ACTIVE_CALL',
  12039: 'CANNOT_LOG_OUT_WHEN_QUEUED_CALLS',
  12040: 'PASSWORD_NOT_ALLOWED_SPECIAL_CHARACTERS',
  12041: 'PASSWORD_CANNOT_CONTAIN_USERNAME',
  12042: 'AGENT_IS_NOT_LOGGED_IN',
  12043: 'INVALID_AGENT_RECORDING_STATE',
  12044: 'AGENT_DOES_NOT_HAVE_PERMISSION',
  12045: 'AGENT_IS_NOT_IN_CORRECT_STATE',
  12046: 'JAVA_AGENT_CANNOT_BE_READY_FOR_NON_VOICE_CHANNELS',
  12047: 'PASSWORD_IN_BLACK_LIST',
  13001: 'MAX_RUNNING_CAMPAIGNS',
  13002: 'PROHIBITED_CAMPAIGN_DIALING_MODE',
  13003: 'AUTODIAL_CAMPAIGN_SUPPRESSED',
  13004: 'FORCE_LOGOUT_ERROR',
  13005: 'CANNOT_FORCE_LOGOUT_SELF',
  13006: 'MONITOR_AGENT_FAILED',
  13007: 'AGENT_IS_NOT_READY_FOR_CALLS',
  13008: 'ALERT_NOT_FOUND_OR_NOT_ACCESSIBLE_TO_USER',
  13009: 'ALERT_UPDATED_BY_OTHER_SESSION',
  13010: 'USER_TRIES_TO_MODIFY_ALERT_CREATED_BY_OTHER_USER',
  13011: 'USER_TRIES_TO_MODIFY_ALERT_INFO_FOR_OTHER_USER',
  13012: 'USER_DOES_NOT_HAVE_RIGHTS_TO_DELETE_ALERT',
  13013: 'OBJECT_LOCKED_BY_ANOTHER_USER',
  13014: 'BATCH_OPERATION_ERROR',
  13019: 'AGENT_HAS_PROFILE',
  13020: 'CATEGORY_NOT_FOUND',
  13021: 'CATEGORY_UPDATED_BY_OTHER_SESSION',
  13022: 'CATEGORY_KEY_NOT_FOUND',
  13023: 'CATEGORY_ITEM_NOT_FOUND',
  13024: 'CANNOT_SEND_EMAIL',
  13025: 'NO_EMAIL_CONFIGURED',
  13026: 'WORKSHEET_NOT_FOUND',
  13027: 'WORKSHEET_UPDATED_BY_OTHER_SESSION',
  13031: 'AGENT_CHANNEL_NOT_PERMITTED',
  13032: 'DIALING_LIST_EMPTY',
  13033: 'NUMBER_NOT_IN_SMS_OPTIN_LIST',
  13034: 'SMS_CHAT_WITH_CUSTOMER_EXISTS',
  13035: 'SMS_MESSAGE_DELIVERY_FAILED',
  13036: 'EMAIL_ATTACHMENT_ACCESS_FORBIDDEN',
  13037: 'DRAFT_DOES_NOT_EXIST',
  13038: 'CAMPAIGN_IS_NOT_CONFIGURED_FOR_SMS',
  13039: 'WORKSHEET_ALREADY_EXISTS',
  13040: 'SMS_OPEN_DISPOSITION_NOT_ALLOWED',
  2: 'OBJECT_NOT_FOUND',
  2007: 'NO_ROLES_ASSIGNED',
  2008: 'NO_PERMISSIONS_ASSIGNED',
  2009: 'EXISTING_SESSION_USE_FORCE_LOGIN',
  2010: 'NO_VALID_APPLICATION_ROLE_ASSIGNED',
  2011: 'USER_ACCOUNT_NOT_ACTIVE',
  2013: 'USER_PWD_CHANGE_NEEDED',
  2014: 'USER_SECURITY_QUESTION_CHANGE_NEEDED',
  2015: 'EXISTING_SESSION_ON_ACTIVE_CALL',
  2016: 'CAN_NOT_RUN_WEB_CLIENT',
  2017: 'CAN_NOT_RUN_WEB_CLIENT_RUN_JAVA_AGENT',
  2018: 'FREEDOM_NOT_ENABLED',
  2019: 'SFAGENT_APP_CONCURRENCY_EXCEEDED',
  2020: 'SAML_AUTH_FAILED',
  2021: 'CONTINUOUS_LOGIN_FAILED',
  2022: 'WARN_INVALID_REQUEST_ADDRESS',
  2023: 'CANT_GET_DB_CONNECTION',
  3: 'WRONG_STATE',
  4: 'INVALID_PARAMETER_VALUE',
  5: 'LIMIT_REACHED',
  5001: 'SERVICE_MIGRATED_RETRY_REQUEST',
  5002: 'SERVICE_UNAVAILABLE',
  5003: 'SERVICE_ERROR',
  5999: 'UNABLE_TO_ACQUIRE_DISTRIBUTED_LOCK',
  6: 'INVALID_REQUEST',
  7: 'OUT_OF_RANGE',
  8: 'CANNOT_FORCE_LOGOUT_WHEN_TALKING',
  9: 'CALL_HAS_NO_CONTACT',
};
const getFive9ErrorName = (code) => {
  return five9ErrorCodes[code] || null;
};

const getTelephonyError = (error, labels) => {
  const code = error.extensions?.telephonyErrorCode;
  if (code) {
    return labels[getFive9ErrorName(code)] || `Unknown five9 code: ${code}`;
  }
  return error.message;
};

export default getTelephonyError;
