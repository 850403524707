import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import React from 'react';
import X from 'common/Icons/basic/X';
import Typography from 'common/Typography/Typography';
import { useStyles } from './IframeDialog.style';

const IframeDialog = ({ isOpen, onClose, children }) => {
  const classes = useStyles();
  return (
    <Dialog keepMounted onClose={onClose} open={isOpen}>
      <div className={classes.title_container}>
        <Typography level="large">Log in to Five9</Typography>
        {onClose ? (
          <div className={classes.close_icon} onClick={onClose}>
            <X />
          </div>
        ) : null}
      </div>
      <div className={classes.body_container}>{children}</div>
    </Dialog>
  );
};

IframeDialog.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default IframeDialog;
