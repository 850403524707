import React from 'react';
import PropTypes from 'prop-types';

const X = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M8.00008 9.41417L3.70718 13.7071L2.29297 12.2928L6.58586 7.99995L2.29297 3.70706L3.70718 2.29285L8.00008 6.58574L12.293 2.29285L13.7072 3.70706L9.41429 7.99995L13.7072 12.2928L12.293 13.7071L8.00008 9.41417Z"
      />
    </svg>
  );
};

X.propTypes = {
  className: PropTypes.string,
};

export default X;
