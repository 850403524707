import React from 'react';
import { useStyles } from './Loading.style';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const Loading = ({ height }) => {
  const classes = useStyles(height);
  return (
    <div className={classes.loading_container}>
      <CircularProgress />
    </div>
  );
};

Loading.propTypes = {
  height: PropTypes.string,
};

Loading.defaultProps = {
  height: '100vh',
};

export default Loading;
