import { logEvent } from '../../services/analyticsService';

const logOnNoteEvent = (event) => {
  const keyNoteEvent = getNoteLogEvent(event);
  logEvent(event.type, keyNoteEvent);
};

const getNoteLogEvent = ({
  familyFileId,
  userId,
  id,
  screenName,
  error,
  noteType,
}) => {
  let eventData = {
    familyFileId: familyFileId,
    crmUserId: userId,
    platform: 'beacon',
    channel: 'beacon',
    section: 'family file',
    screenName: screenName,
    eventType: 'action',
    eventPath: 'note',
    noteType: noteType,
    modification: id ? 'save' : 'add',
  };

  if (error) {
    eventData.errorMessage = error;
    eventData.eventType = 'view';
  }

  return eventData;
};

const NoteLogService = { logOnNoteEvent };

export default NoteLogService;
