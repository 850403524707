import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './Chiclets.style';
import Chiclet from './Chiclet';

const Chiclets = ({
  steps,
  inquiry,
  currentUser,
  labels,
  warmTransfer,
  handleAnswer,
  closeOptionalStep,
  onUserInputChange,
  onManyChoiceChange,
  onTextInputGroupSave,
}) => {
  const classes = useStyles();

  let stepCount = 0;
  return (
    <div className={classes.form_container}>
      {steps.map((step) => {
        if (!step.extraFields?.hidden) stepCount += 1;
        return (
          <Chiclet
            key={step.number}
            step={step}
            index={stepCount}
            currentUser={currentUser}
            inquiry={inquiry}
            onClick={(selectedOption) => handleAnswer(step, selectedOption)}
            onClose={() => closeOptionalStep(step)}
            labels={labels}
            warmTransfer={warmTransfer}
            onUserInputChange={(selectedAnswer) =>
              onUserInputChange(step, selectedAnswer)
            }
            onManyChoiceChange={(selectedOptions) =>
              onManyChoiceChange(step, selectedOptions)
            }
            onTextInputGroupSave={(textAnswer) =>
              onTextInputGroupSave(step, textAnswer)
            }
          />
        );
      })}
    </div>
  );
};

Chiclets.propTypes = {
  steps: PropTypes.array,
  inquiry: PropTypes.object,
  currentUser: PropTypes.object,
  labels: PropTypes.object,
  warmTransfer: PropTypes.object,
  handleAnswer: PropTypes.func,
  closeOptionalStep: PropTypes.func,
  onUserInputChange: PropTypes.func,
  onManyChoiceChange: PropTypes.func,
  onTextInputGroupSave: PropTypes.func,
};

export default Chiclets;
