import labels from 'config/labels';
import {
  setDuplicateFamilyFile,
  updateFindBestSlaAttemptsCount,
  updateSendFamilyFileEvent,
} from 'stateManagement/callcenter/warmTransfer/actions/warmTransferActions';
import allCallStates from '../../config/callStates.config';
import { updateFormSteps } from '../../stateManagement/callcenter/formChiclets/actions/formChicletsActions';
import { updateInquiryFamilyFileId } from '../../stateManagement/callcenter/inquiry/actions/inquiryActions';
import { familyFileStatus, STATUS_DETAILS } from './enums';
import warmTransferFormService from './warmTransferFormService';

export const warmTransferTypes = {
  ANY_SLA: { typeId: 1, value: 'WarmTransfer' },
  REINQUIRY_OR_DUPLICATE: { typeId: 3, value: 'ReInquiry' },
  SPECIFIC_SLA: { typeId: 2, value: 'RequestedAdvisor' },
};

export const warmTransferStates = {
  CANCEL_SUCCESS: 'CANCEL_SUCCESS',
  ERROR_CALL: 'ERROR_CALL',
  ERROR_CONTEXT: 'ERROR_CONTEXT',
  ERROR_DATA: 'ERROR_DATA',
  ERROR_RELATIONSHIP: 'ERROR_RELATIONSHIP',
  ERROR_SLA: 'ERROR_SLA',
  ERROR_UNKNOWN: 'ERROR_UNKNOWN',
  ERROR_ZIP: 'ERROR_ZIP',
  ERROR: 'ERROR',
  EXISTING_NOT_ACCEPTED: 'EXISTING_NOT_ACCEPTED',
  IN_PROGRESS_WITH_SLA: 'IN_PROGRESS_WITH_SLA',
  NOT_STARTED: 'NOT_STARTED',
  REQUESTED: 'REQUESTED',
  RETRYING_SLA: 'RETRYING_SLA',
  SEARCHING_EXISTING: 'SEARCHING_EXISTING',
  SEARCHING: 'SEARCHING',
  STARTED: 'STARTED',
  SUCCESS_EXISTING: 'SUCCESS_EXISTING',
  SUCCESS_NOT_FOUND: 'SUCCESS_NOT_FOUND',
  SUCCESS: 'SUCCESS',
  WAITING_SLA: 'WAITING_SLA',
};
export const warmTransferResults = {
  TRANSFER_COMPLETED: 1,
  TRANSFER_FAILED: 2,

  NO_ADVISORS_LOGGED_IN: 3,
  NO_ADVISORS_AVAILABLE: 4,
  NO_ADVISORS_ACCEPTED: 5,
  NO_ADVISORS_IN_READY_STATE: 6,

  TRANSFER_CANCELLED_LEAD_CLOSED: 7,
  TRANSFER_CANCELLED_LEAD_ASSIGNED: 8,

  ADVISOR_ABANDON_DURING_CONFERENCE: 9,
  CUSTOMER_ABANDON_DURING_CONFERENCE: 10,

  ERROR_ADVISOR_CONNECTION: 11,
  ERROR: 12,
};

const transferCancellationReasons = {
  WARM_TRANSFER: 3, //Conference Family Succeeded
  AUTO_ASSIGN: 4,

  NO_ADVISORS_TO_PING: 2, // (autoassigned)No Advisor Available and Pinging of Advisors Was Not Attempted
  NO_ADVISOR_ACCEPTED: 7, //(autoassigned)No Advisor Available and Pinging of Advisors Was Attempted
  ADVISOR_NO_ANSWER: 8, //(autoassigned)Advisor Was Called for WT But Did Not Answer, SRC clicks "No Answer" button
  ADVISOR_ABANDON: 9, //(not used)Conference Failed and Family is Connected

  CUSTOMER_ABANDON: 1, //Family disconnected after the WT has started, at any point(after--successful)
  CUSTOMER_ABANDON_BEFORE_HOLD: 5, //(not used)Customer Abandoned and Family Not On Hold
  CUSTOMER_ABANDON_DURING_HOLD: 6, //(not used)Customer Abandoned and Family On Hold

  UNKNOWN: 0, //All Other Cases

  LEAD_CLOSED: 10, //"Close Lead" Clicked or Contact Closed don't use as we are not closing leads
};

export const transferDetailCodes = {
  CONFERENCE_SUCCESSFUL: 1, //successful warm transfer

  TRANSFER_FAILED: 2, //not successful warm transfer (auto-assign scenarios, family disconnected, WT cancelled)

  TRANSFER_CANCELLED_LEAD_ASSIGNED: 8, //SRC cancelled the WT and the lead was already assigned (used on cancel scenarios)

  ADVISOR_ABANDON_DURING_CONFERENCE: 9, //SLA disconnected during conference
  CUSTOMER_ABANDON_DURING_CONFERENCE: 10, //family disconnected during conference

  ERROR_ADVISOR_CONNECTION: 11, // SLA couldn’t be connected to the conference call
  ERROR: 12, //any other system errors

  TRANSFER_CANCELLED_LEAD_CLOSED: 7, // don't use as we are not closing leads
};

export const newCompleteWarmTransferStates = {
  AUTO_ASSIGNED_NO_ADVISORS_WORKING: {
    transferDetailCode: transferDetailCodes.TRANSFER_CANCELLED_LEAD_ASSIGNED,
    transferResult: warmTransferResults.NO_ADVISORS_LOGGED_IN,
    description: 'No appropriate Advisors were logged into Telephony (Five9)',
  },
  AUTO_ASSIGNED_NO_ADVISORS_AVAILABLE: {
    transferDetailCode: transferDetailCodes.TRANSFER_CANCELLED_LEAD_ASSIGNED,
    transferResult: warmTransferResults.NO_ADVISORS_AVAILABLE,
    description:
      'Appropriate Advisors were logged in, but not logged into the WT Skill',
  },
  AUTO_ASSIGNED_NO_ADVISORS_ACCEPTED: {
    transferDetailCode: transferDetailCodes.TRANSFER_CANCELLED_LEAD_ASSIGNED,
    transferResult: warmTransferResults.NO_ADVISORS_ACCEPTED,
    description: 'Advisors were pinged, but no one accepted',
  },
  AUTO_ASSIGNED_NO_ADVISORS_IN_READY_STATE: {
    transferDetailCode: transferDetailCodes.TRANSFER_CANCELLED_LEAD_ASSIGNED,
    transferResult: warmTransferResults.NO_ADVISORS_IN_READY_STATE,
    description:
      'Advisors were logged in to the WT Skill but not available (in a not ready state, on a call state, after call work) to be pinged',
  },
  TRANSFER_CANCELLED_LEAD_CLOSED: {
    transferDetailCode: transferDetailCodes.TRANSFER_CANCELLED_LEAD_CLOSED,
    transferResult: warmTransferResults.TRANSFER_CANCELLED_LEAD_CLOSED,
    transferCancellationReason: transferCancellationReasons.UNKNOWN,
    description:
      'The transfer search was canceled and the inquiry and family file were closed, not sent to and Advisor. | Not yet supported as of 5/17',
  },
  TRANSFER_CANCELLED_LEAD_ASSIGNED: {
    transferDetailCode: transferDetailCodes.TRANSFER_CANCELLED_LEAD_ASSIGNED,
    transferResult: warmTransferResults.NO_ADVISORS_IN_READY_STATE,
    description:
      'The transfer search was canceled and the family file was sent to and Advisor for follow up.',
  },
  ADVISOR_ABANDON_DURING_CONFERENCE: {
    transferDetailCode: transferDetailCodes.ADVISOR_ABANDON_DURING_CONFERENCE,
    transferResult: warmTransferResults.ADVISOR_ABANDON_DURING_CONFERENCE,
    description:
      'The Advisor disconnected from the conference (they clicked the button to hang up)',
  },
  CUSTOMER_ABANDON_DURING_CONFERENCE: {
    transferDetailCode: transferDetailCodes.CUSTOMER_ABANDON_DURING_CONFERENCE,
    transferResult: warmTransferResults.CUSTOMER_ABANDON_DURING_CONFERENCE,
    description:
      'The family disconnected from the conference after the SLA is brought on',
  },
  ERROR_ADVISOR_CONNECTION: {
    transferDetailCode: transferDetailCodes.ERROR_ADVISOR_CONNECTION,
    transferResult: warmTransferResults.ERROR_ADVISOR_CONNECTION,
    description: 'There was an error adding the Advisor to the call',
  },
  SUCCESS: {
    transferDetailCode: transferDetailCodes.CONFERENCE_SUCCESSFUL,
    transferResult: warmTransferResults.TRANSFER_COMPLETED,
    description: 'The family was successfully conferenced with the Advisor',
  },
  SRC_CANCELLED: {
    transferDetailCode: transferDetailCodes.TRANSFER_CANCELLED_LEAD_ASSIGNED,
    transferResult: warmTransferResults.TRANSFER_CANCELLED_LEAD_ASSIGNED,
    transferCancellationReasonCode: transferCancellationReasons.UNKNOWN,
    description:
      'The src cancelled the warm transfer before there was a winner sla. temp until we support closing leads',
  },
  CANCELLED_FAMILY_ABANDONED_BEFORE_SLA: {
    transferDetailCode: transferDetailCodes.TRANSFER_CANCELLED_LEAD_ASSIGNED,
    transferResult: warmTransferResults.TRANSFER_CANCELLED_LEAD_ASSIGNED,
    transferCancellationReasonCode:
      transferCancellationReasons.CUSTOMER_ABANDON,
    description:
      'The family abandoned before there was a winner sla. temp until we support closing leads',
  },
};

const findAutoAssignedCompleteWarmTransferStatesByResult = (result) => {
  const allowedResults = [
    warmTransferResults.NO_ADVISORS_LOGGED_IN,
    warmTransferResults.NO_ADVISORS_AVAILABLE,
    warmTransferResults.NO_ADVISORS_ACCEPTED,
    warmTransferResults.NO_ADVISORS_IN_READY_STATE,
  ];
  if (!allowedResults.includes(result)) {
    console.error(`result ${result} not support`);
    throw new Error(`result ${result} not support`);
  }

  return Object.values(newCompleteWarmTransferStates).find(
    (state) => state.transferResult === result,
  );
};

const callFamilyFileForWarmTransferMutation = async (
  createFamilyFileForWarmTransfer,
  variables,
) => {
  try {
    const response = await createFamilyFileForWarmTransfer({ variables });
    const {
      data: {
        createFamilyFileForWarmTransfer: { familyFileId, externalId },
      },
    } = response;
    return { familyFileId, externalId };
  } catch (error) {
    console.error(error);
    return { error: error.message };
  }
};

const callUpdateInquiry = async (updateInquiry, variables) => {
  try {
    const response = await updateInquiry({ variables });
    const {
      data: {
        updateInquiry: { inquiryId, duplicateStatus, inquiryFamilyFile },
      },
    } = response;
    return { inquiryId, duplicateStatus, inquiryFamilyFile };
  } catch (error) {
    console.error(error);
    return { error: error.message };
  }
};
const callPrepareWarmTransfer = (
  inquiry,
  setFamilyFileId,
  familyFileId,
  initiateWarmTransfer,
  warmTransferPoolType,
  onUpdateStatusDetail,
  dispatch,
  newFormSteps,
  postalCode,
  leadId,
  state,
  city,
  primaryContactName,
  primaryContactRelationship,
  residentName,
  phoneProviderUsername,
) => {
  const formSteps = warmTransferFormService.disableChicletsAfterCreatingFF(
    newFormSteps,
  );
  dispatch(updateFormSteps(formSteps));
  dispatch(updateInquiryFamilyFileId(familyFileId));
  onUpdateStatusDetail(STATUS_DETAILS.TRANSFERRED);
  setFamilyFileId(familyFileId);
  const defaultPoolType = 1;
  const warmTransferInput = {
    familyFileId,
    postalCode,
    leadId,
    oneId: inquiry.inquiryContact.oneId,
    contactId: inquiry.inquiryContact.inquiryContactId.toString(),
    beaconInquiryId: inquiry.inquiryId,
    poolType: warmTransferPoolType || defaultPoolType,
    state,
    city,
    primaryContactName,
    primaryContactRelationship,
    residentName,
    slaEmailId: phoneProviderUsername,
  };
  initiateWarmTransfer(warmTransferInput);

  dispatch(updateFindBestSlaAttemptsCount());
};

const onInitiateWarmTransfer = async (
  formSteps,
  setWarmTransferState,
  dispatch,
  inquiry,
  assignedToId,
  setFamilyFileId,
  initiateWarmTransfer,
  callData,
  createFamilyFileForWarmTransfer,
  warmTransferPoolType,
  onUpdateStatusDetail,
  callState,
  updatingInquiryVariables,
  currentFamilyFileStatus,
  hasDuplicate,
  updateInquiry,
  isInquiryUpdated,
  warmTransferType,
) => {
  if (!callData.number || callState !== allCallStates.TALKING.key) {
    setWarmTransferState(warmTransferStates.ERROR_CALL);
    return;
  }
  const familyInfo = formSteps.find((s) => s.property === 'familyInfo');
  const relationship = familyInfo?.answer?.userInputValues?.relationship;

  const locationInfo = formSteps.find((s) => s.property === 'locationInfo');
  const postalCode = locationInfo?.answer?.userInputValues?.location?.zip;

  const city = locationInfo?.answer?.userInputValues?.location?.city;
  const state = locationInfo?.answer?.userInputValues?.location?.state;

  const primaryContactName = inquiry.inquiryContact.firstName.concat(
    labels.BLANK_SPACE,
    inquiry.inquiryContact.lastName,
  );

  const primaryContactRelationship = inquiry?.relationToResident;

  const residentFirstName = inquiry?.resident1FirstName || '';
  const residentLastName = inquiry?.resident1LastName || '';

  const residentName =
    residentFirstName.concat(labels.BLANK_SPACE, residentLastName).trim() ||
    labels.UNKNOWN;

  if (!relationship) {
    setWarmTransferState(warmTransferStates.ERROR_RELATIONSHIP);
    return;
  }

  if (!postalCode) {
    setWarmTransferState(warmTransferStates.ERROR_ZIP);
    return;
  }

  setWarmTransferState(warmTransferStates.STARTED);
  let newFormSteps = warmTransferFormService.hideWarmTransferResults(formSteps);
  dispatch(updateFormSteps(newFormSteps));

  const isFamilyFileClosedOrMoveIn =
    currentFamilyFileStatus === familyFileStatus.CLOSED ||
    currentFamilyFileStatus === familyFileStatus.MOVE_IN;

  const variables = {
    inquiryId: inquiry.inquiryId,
    assignedTo: assignedToId,
    isFamilyFileClosedOrMoveIn,
  };

  if (currentFamilyFileStatus === familyFileStatus.OPEN) {
    if (!isInquiryUpdated.current) {
      const newUpdatingInquiryVariables = {
        ...updatingInquiryVariables,
        newFamilyFileId: updatingInquiryVariables.duplicateFamilyFileId,
        duplicateFamilyFileId: null,
        isSameResident: hasDuplicate,
      };
      const updateInquiryResponse = await callUpdateInquiry(
        updateInquiry,
        newUpdatingInquiryVariables,
      );
      if (updateInquiryResponse.duplicateStatus) {
        isInquiryUpdated.current = true;
      }
    }

    let phoneProviderUsername;

    if (warmTransferType === warmTransferTypes.REINQUIRY_OR_DUPLICATE) {
      phoneProviderUsername = updatingInquiryVariables.phoneProviderUsername;
    }

    callPrepareWarmTransfer(
      inquiry,
      setFamilyFileId,
      updatingInquiryVariables.duplicateFamilyFileId,
      initiateWarmTransfer,
      warmTransferPoolType,
      onUpdateStatusDetail,
      dispatch,
      newFormSteps,
      postalCode,
      updatingInquiryVariables.externalId,
      state,
      city,
      primaryContactName,
      primaryContactRelationship,
      residentName,
      phoneProviderUsername,
    );
    dispatch(updateSendFamilyFileEvent());
  } else {
    const response = await callFamilyFileForWarmTransferMutation(
      createFamilyFileForWarmTransfer,
      variables,
    );
    const { familyFileId, externalId: leadId } = response;
    if (familyFileId) {
      dispatch(setDuplicateFamilyFile({ familyFileStatus: null }));
      if (!isInquiryUpdated.current) {
        const newUpdatingInquiryVariables = {
          ...updatingInquiryVariables,
          newFamilyFileId: familyFileId,
          isSameResident: hasDuplicate,
        };
        const updateInquiryResponse = await callUpdateInquiry(
          updateInquiry,
          newUpdatingInquiryVariables,
        );
        if (updateInquiryResponse.duplicateStatus) {
          isInquiryUpdated.current = true;
        }
      }

      callPrepareWarmTransfer(
        inquiry,
        setFamilyFileId,
        familyFileId,
        initiateWarmTransfer,
        warmTransferPoolType,
        onUpdateStatusDetail,
        dispatch,
        newFormSteps,
        postalCode,
        leadId,
        state,
        city,
        primaryContactName,
        primaryContactRelationship,
        residentName,
      );
    } else {
      const { error } = response;
      console.error(`There was an error with ff creation:${error}`);
      setWarmTransferState(warmTransferStates.ERROR_DATA);
      newFormSteps = warmTransferFormService.showWarmTransferErrors(
        newFormSteps,
        error,
      );
      dispatch(updateFormSteps(newFormSteps));
    }
  }
};

const onTransferToSLA = async (winnerSlaAgent, transferLead) => {
  let isError = false;
  if (winnerSlaAgent) {
    const response = await transferLead(winnerSlaAgent.warmTransferProcessId);
    if (response?.error !== undefined) {
      isError = true;
    }
  } else {
    isError = true;
    console.error(
      'something bad happened when trying to transfer to sla: winnerSlaAgent not found',
    );
  }
  return isError;
};

const onCompleteWarmTransfer = async (
  warmTransferState,
  warmTransferCompleteData,
  completeWarmTransfer,
  transferType,
) => {
  const variables = {
    transferCancellationReasonCode:
      warmTransferState.transferCancellationReasonCode,
    transferDetailCode: warmTransferState.transferDetailCode,
    warmTransferResult: warmTransferState.transferResult,
    warmTransferProcessId: warmTransferCompleteData.warmTransferProcessId,
    transferType: transferType.value,
  };
  await completeWarmTransfer(variables);
};

const warmTransferService = {
  onInitiateWarmTransfer,
  onTransferToSLA,
  onCompleteWarmTransfer,
  findAutoAssignedCompleteWarmTransferStatesByResult,
};

export default warmTransferService;
