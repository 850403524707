import React from 'react';
import PropTypes from 'prop-types';
//common
import Typography from '../../common/Typography/Typography';

const SendSuccessMessage = ({ labels, contacts }) => {
  return (
    <>
      <Typography color="eerieBlack5">
        {labels.FAMILY_LETTER_SUCCESSFULLY_SENT_TO_CONTACTS}
      </Typography>
      {contacts?.map((contact, key) => (
        <div key={key} style={{ margin: '20px 0 28px 0' }}>
          <Typography color="eerieBlack5">
            {contact.name} (
            {contact.relationToResident
              ? contact.relationToResident
              : labels.UNKNOWN_RELATION}
            ) {`: ${contact.email}`}
          </Typography>
        </div>
      ))}
    </>
  );
};

SendSuccessMessage.propTypes = {
  labels: PropTypes.shape({
    FAMILY_LETTER_SUCCESSFULLY_SENT_TO_CONTACTS: PropTypes.string.isRequired,
    UNKNOWN_RELATION: PropTypes.string.isRequired,
  }).isRequired,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      relationToResident: PropTypes.string,
      email: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default SendSuccessMessage;
