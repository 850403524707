import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../common/Modal/Modal';
import { useStyles } from './ScheduledTourInfo.style';
import Typography from '../../common/Typography/Typography';
import CalendarDates from '../../common/Icons/time/CalendarDates';
import Button from '../../common/Button/Button';
import Divider from '../../common/Divider/Divider';
import CircleChecked from '../../common/Icons/basic/CircleChecked';
import Pin from '../../common/Icons/maps/Pin';
import clsx from 'clsx';
import CallPhone from '../../common/Icons/call/CallPhone';
import Toast from '../../common/Toast/Toast';
import StaticDatePicker from '../../common/DatePicker/StaticDatePicker';
import { format } from '../../common/utils/dateFormat';
import { getDate, getHourAndMinutes12HourFormat } from '../../utils/dateFormat';
import times from './times';
import Tag from '../../common/Tag/Tag';
import Cross from '../../common/Icons/various/Cross';
import tourScheduleService from '../../services/tourScheduleService';
import { logReferralAlertTourTimeSelected } from '../../services/referredCommunitiesService';
import {
  logTourSchedulingOpened,
  logTourSaved,
} from '../../services/tourScheduleService';
import SmartTooltip from '../../common/SmartTooltip/SmartTooltip';

const ScheduledTourInfo = ({
  address,
  city,
  dataForAnalytics,
  familyFileId,
  familyFileUserId,
  isCtAppEnabled,
  isOpen,
  labels,
  name,
  onClose,
  onSave,
  phone,
  state,
  tourDetails,
  zip,
  isPastDisabled,
}) => {
  const classes = useStyles();
  const IN_PERSON = 'IP';
  const CT_APP = 'VT';
  const VIRTUAL_TOUR = 'VTX';

  const dateSelected = tourDetails?.scheduledTourDate
    ? new Date(tourDetails.scheduledTourDate)
    : null;

  const [selectedDate, setSelectedDate] = useState(dateSelected || new Date());

  const formatSelectedTime = (time) => {
    return getHourAndMinutes12HourFormat(time, true)
      .replace(' pm', 'PM')
      .replace(' am', 'AM')
      .padStart(7, '0');
  };
  const [selectedTime, setSelectedTime] = useState(
    dateSelected ? formatSelectedTime(dateSelected) : null,
  );

  const [selectedType, setSelectedType] = useState(
    tourDetails.tourType || null,
  );

  useEffect(() => {
    logTourSchedulingOpened(
      tourDetails.communityId,
      familyFileId,
      dataForAnalytics.originatingPage,
      familyFileUserId,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldBeEnabled = () => {
    return selectedDate && selectedTime && selectedType;
  };

  const getTourAnalyticsDetails = (time) => {
    const selectedTourTypeText = tourScheduleService.getTourTypeLabel(
      selectedType,
    );
    const tourDateTime = `${getDate(selectedDate)} ${time
      .replace('AM', ' am')
      .replace('PM', ' pm')}`;
    return [selectedTourTypeText, tourDateTime];
  };

  const handleSave = () => {
    const rawSelectedDate = new Date(selectedDate);
    const year = rawSelectedDate.getFullYear();
    const month = rawSelectedDate.getMonth() + 1;
    const day = rawSelectedDate.getDate();
    const activityDate = new Date(
      `${year}-${month}-${day} ${selectedTime
        .toLowerCase()
        .replace('pm', ' pm')
        .replace('am', ' am')}`,
    ).getTime();

    const [selectedTourTypeText, tourDateTime] = getTourAnalyticsDetails(
      selectedTime,
    );
    logTourSaved(
      dataForAnalytics.careTypes,
      tourDetails.communityId,
      familyFileId,
      dataForAnalytics.originatingPage,
      tourDateTime,
      selectedTourTypeText,
      familyFileUserId,
    );

    onSave({
      activityDate,
      selectedType,
    });
  };

  const scheduledTourInformationMessage = () => {
    return (
      <Typography>
        {labels.SCHEDULED_TOUR_INFORMATION_MESSAGE_PART_1}
        <b>{labels.SCHEDULED_TOUR_INFORMATION_MESSAGE_PART_2}</b>
        {labels.SCHEDULED_TOUR_INFORMATION_MESSAGE_PART_3}
      </Typography>
    );
  };

  const getButtonType = () => {
    if (!isCtAppEnabled) {
      return 'disabled';
    }
    if (CT_APP === selectedType) {
      return 'primary';
    }
    return 'outlined';
  };

  const handleTimeSelected = (time) => {
    setSelectedTime(time);

    if (dataForAnalytics.originatingPage === 'referral alert') {
      const [selectedTourTypeText, tourDateTime] = getTourAnalyticsDetails(
        time,
      );
      logReferralAlertTourTimeSelected(
        dataForAnalytics.careTypes,
        tourDetails.communityId,
        familyFileId,
        tourDateTime,
        selectedTourTypeText,
        familyFileUserId,
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={labels.REQUEST_A_TOUR_WITH_COMMUNITY}
      icon={<CalendarDates />}
      color="cosmicCobalt2"
      noBodyScroll={true}
      noBodyPadding={true}
      className={classes.modal_container}
    >
      <div className={classes.scheduled_tour_container}>
        <div className={classes.row}>
          <Typography bold level="large">
            {name}
          </Typography>
          {isCtAppEnabled ? (
            <Tag
              key="1"
              text={labels.CT_APP_ENABLED}
              color="cosmicCobalt3"
              icon={<Cross />}
              className={classes.tag_margin}
            />
          ) : (
            <div />
          )}
        </div>
        <div className={clsx(classes.row, classes.address_container)}>
          {address && (
            <div className={classes.column}>
              <Pin className={classes.icon} />
              <Typography>{`${address} ${city}, ${state} ${zip}`}</Typography>
            </div>
          )}
          {phone && (
            <div className={classes.column}>
              <CallPhone className={classes.icon} />
              <Typography>{phone}</Typography>
            </div>
          )}
        </div>

        <Divider className={classes.inner_divider} color="platinum1" />
        <div className={classes.row}>
          <Toast type="warning">{scheduledTourInformationMessage()}</Toast>
        </div>
        <div className={clsx(classes.row, classes.tour_types)}>
          <Button
            className={clsx(
              classes.type_btn,
              selectedType === IN_PERSON && classes.active,
            )}
            type={selectedType === IN_PERSON ? 'primary' : 'outlined'}
            size="small"
            onClick={() => setSelectedType(IN_PERSON)}
          >
            {labels.IN_PERSON_TOUR}
          </Button>
          {getButtonType() === 'disabled' ? (
            <SmartTooltip
              title={labels.COMMUNITY_DOES_NOT_USE_CT_APP}
              placement="top"
            >
              <Button
                className={clsx(
                  classes.type_btn,
                  selectedType === CT_APP && classes.active,
                )}
                type={getButtonType()}
                size="small"
                onClick={() => setSelectedType(CT_APP)}
              >
                {labels.CT_APP}
              </Button>
            </SmartTooltip>
          ) : (
            <Button
              className={clsx(
                classes.type_btn,
                selectedType === CT_APP && classes.active,
              )}
              type={getButtonType()}
              size="small"
              onClick={() => setSelectedType(CT_APP)}
            >
              {labels.CT_APP}
            </Button>
          )}
          <Button
            className={clsx(
              classes.type_btn,
              selectedType === VIRTUAL_TOUR && classes.active,
            )}
            type={selectedType === VIRTUAL_TOUR ? 'primary' : 'outlined'}
            size="small"
            onClick={() => setSelectedType(VIRTUAL_TOUR)}
          >
            {labels.VIRTUAL_TOURS_OTHER}
          </Button>
        </div>
        <div className={classes.row}>
          <div className={classes.selected_date}>
            <Typography>
              {!selectedDate && labels.PLEASE_SELECT_A_DATE}
              {selectedDate && (
                <>
                  {labels.YOU_HAVE_SELECTED}
                  <b>{format(selectedDate, 'MMMM DD, YYYY (DDD)')}</b>
                </>
              )}
            </Typography>
          </div>
        </div>
        <div className={clsx(classes.row, classes.date_time_container)}>
          <StaticDatePicker
            onChange={(value) => setSelectedDate(value)}
            value={selectedDate}
            disablePast={isPastDisabled}
            pastDatesTooltipTitle={labels.CANNOT_SCHEDULE_IN_THE_PAST}
          />
          <div className={classes.timezone_container}>
            <div className={classes.times_section}>
              <Typography
                color="eerieBlack5"
                level="small"
                className={classes.time_title}
              >
                {labels.MORNING_TIMES}
              </Typography>
              <div className={classes.times_container}>
                {times
                  .filter((time) => time.isMorning)
                  .map((time, key) => (
                    <Button
                      className={clsx(
                        classes.time_btn,
                        time.value === selectedTime && classes.active,
                      )}
                      type={
                        time.value === selectedTime ? 'primary' : 'outlined'
                      }
                      size="small"
                      key={key}
                      onClick={() => handleTimeSelected(time.value)}
                    >
                      {time.label}
                    </Button>
                  ))}
              </div>
            </div>
            <div className={classes.times_section}>
              <Typography
                color="eerieBlack5"
                level="small"
                className={classes.time_title}
              >
                {labels.AFTERNOON_TIMES}
              </Typography>
              <div className={classes.times_container}>
                {times
                  .filter((time) => !time.isMorning)
                  .map((time, key) => (
                    <Button
                      className={clsx(
                        classes.time_btn,
                        time.value === selectedTime && classes.active,
                      )}
                      type={
                        time.value === selectedTime ? 'primary' : 'outlined'
                      }
                      size="small"
                      key={key}
                      onClick={() => handleTimeSelected(time.value)}
                    >
                      {time.label}
                    </Button>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider className={classes.divider} color="platinum1" />
      <div className={classes.actions_container}>
        <div className={classes.actions}>
          <Button size="small" type="light" onClick={onClose}>
            {labels.CANCEL}
          </Button>
          <Button
            type={shouldBeEnabled() ? 'secondary' : 'disabled'}
            size="small"
            onClick={handleSave}
            startIcon={<CircleChecked />}
          >
            {labels.SAVE}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ScheduledTourInfo.propTypes = {
  address: PropTypes.string,
  city: PropTypes.string,
  dataForAnalytics: PropTypes.shape({
    careTypes: PropTypes.object,
    originatingPage: PropTypes.string,
  }),
  familyFileId: PropTypes.number,
  familyFileUserId: PropTypes.number,
  isCtAppEnabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  labels: PropTypes.object.isRequired,
  message: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  phone: PropTypes.string,
  state: PropTypes.string,
  tourDetails: PropTypes.shape({
    communityId: PropTypes.number,
    familyCommunityTourId: PropTypes.number,
    scheduledTourDate: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]),
    tourType: PropTypes.string,
  }),
  zip: PropTypes.string,
  currentStatus: PropTypes.string,
  isPastDisabled: PropTypes.bool,
};

ScheduledTourInfo.defaultProps = {
  isOpen: false,
  message: '',
  tourDetails: {
    scheduledTourDate: null,
    tourType: null,
  },
  isCtAppEnabled: false,
  isPastDisabled: false,
};

export default ScheduledTourInfo;
