import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import Button from 'common/Button/Button';
import Typography from 'common/Typography/Typography';
import CalendarDates from 'common/Icons/time/CalendarDates';
import labels from 'config/labels';
import { useStyles } from './TourScheduler.style';
import TourActivity from './TourActivity/TourActivity';
import tourScheduleService, {
  ACTION_BUTTON_TYPE,
  ACTION_TYPE,
  buildActivityDate,
  logRemoveTourTimeSelectedV2,
  logTourCancelledV2,
  logTourDraftSaved,
  logTourErrorViewed,
  logTourMarkedCompletedV2,
  logTourRescheduledV2,
  logTourScheduled,
  logTourSchedulingOpenedV2,
  STATUS_QUESTION_OPTION,
  timeToDisplayFormat,
  tourDataToTourInfo,
  tourToTourInfo,
} from 'services/tourScheduleService';
import {
  getDateLessTimezoneOffset,
  getTimeOffsetFromSelectedDate,
} from 'utils/dateFormat';

import useGraphQLMutation from 'hooks/useGraphQLMutation';
import createTourMutation from './Mutations/createTourMutation';
import updateTourMutation from './Mutations/updateTourMutation';
import upsertTourDraftMutation from './Mutations/upsertTourDraftMutation';
import deleteTourDraftMutation from './Mutations/deleteTourDraftMutation';

const MIDNIGHT = '12:00AM';

//#region Configuration Settings

export const preReferredSchedulerConfiguration = {
  schedulingQuestion: {
    value: 'yes',
    show: true,
    isYesEnabled: true,
    isNoEnabled: false,
    yesTooltip: '',
    noTooltip: labels.TOUR_QUESTION_SCHEDULING_TOOLTIP_NO,
  },
  statusQuestion: {
    value: STATUS_QUESTION_OPTION.SCHEDULED,
    show: true,
    isYesEnabled: true,
    isNoEnabled: false,
    yesTooltip: '',
    noTooltip: labels.TOUR_QUESTION_STATUS_TOOLTIP_COMPLETED,
  },
  updateTypeQuestion: {
    value: '',
    show: false,
  },
  updateQuestion: {
    show: false,
  },
  action: ACTION_BUTTON_TYPE.SAVE_AND_CLOSE,
  allowReferralAlertMessage: true,
};

export const referredSchedulerConfiguration = {
  schedulingQuestion: {
    value: 'yes',
    show: true,
    isYesEnabled: true,
    isNoEnabled: true,
  },
  statusQuestion: {
    value: STATUS_QUESTION_OPTION.SCHEDULED,
    show: true,
    isYesEnabled: true,
    isNoEnabled: false,
  },
  updateTypeQuestion: {
    value: '',
    show: false,
  },
  updateQuestion: {
    show: false,
  },
  action: ACTION_BUTTON_TYPE.SAVE_AND_REFER,
  allowReferralAlertMessage: true,
};

export const referredUpdateSchedulerConfiguration = {
  schedulingQuestion: {
    show: false,
  },
  statusQuestion: {
    show: false,
  },
  updateTypeQuestion: {
    value: '',
    show: true,
    isCompleteEnabled: true,
  },
  updateQuestion: {
    isAllowed: true,
    value: 'yes',
    show: false,
  },
  action: ACTION_BUTTON_TYPE.SAVE_AND_REFER,
  allowReferralAlertMessage: true,
};

export const forReferralSchedulerConfiguration = {
  schedulingQuestion: {
    value: 'yes',
    show: true,
    isYesEnabled: true,
    isNoEnabled: false,
    yesTooltip: '',
    noTooltip: '',
  },
  statusQuestion: {
    value: STATUS_QUESTION_OPTION.SCHEDULED,
    show: true,
    isYesEnabled: true,
    isNoEnabled: false,
    yesTooltip: '',
    noTooltip: '',
  },
  updateTypeQuestion: {
    value: '',
    show: false,
  },
  updateQuestion: {
    show: false,
  },
  action: ACTION_BUTTON_TYPE.SAVE_AND_CLOSE,
  allowReferralAlertMessage: false,
};

export const forReferralTourUpdateSchedulerConfiguration = {
  schedulingQuestion: {
    value: 'yes',
    show: false,
  },
  statusQuestion: {
    value: STATUS_QUESTION_OPTION.SCHEDULED,
    show: false,
  },
  updateTypeQuestion: {
    value: '',
    show: true,
    isCompleteEnabled: false,
  },
  updateQuestion: {
    isAllowed: false,
  },
  action: ACTION_BUTTON_TYPE.SAVE_AND_CLOSE,
  allowReferralAlertMessage: false,
};

//#endregion Configuration Settings

const TOUR_SCHEDULE = 'Schedule';
const TOUR_COMPLETE = 'Complete';
const TOUR_CANCEL = 'Cancel';
const TOUR_RESCHEDULE = 'Reschedule';

const currentUser = JSON.parse(localStorage.getItem('CURRENT_USER'));

const TourScheduler = ({
  community,
  configuration,
  dataForAnalytics,
  familyFileId,
  isCtAppEnabled,
  isRemoveTourAllowed,
  onChange,
  tourDetail,
  tourDraft,
}) => {
  const classes = useStyles();

  const EMPTY_TOUR_DETAILS = {
    communityId: community?.id,
    currentStatus: null,
    familyCommunityTourDraftId: null,
    familyCommunityTourId: null,
    scheduledTourDate: null,
    tourActivities: [],
    tourType: '',
  };

  let familyFileIdRef = useRef(familyFileId);

  const [createTourQL] = useGraphQLMutation(createTourMutation);
  const [updateTourQL] = useGraphQLMutation(updateTourMutation);
  const [upsertTourDraftQL] = useGraphQLMutation(upsertTourDraftMutation);
  const [deleteTourDraftQL] = useGraphQLMutation(deleteTourDraftMutation);

  const [tourInfo, setTourInfo] = useState(null);
  const [isTourActivityModalOpen, setIsTourActivityModalOpen] = useState(false);

  useEffect(() => {
    if (!community) return;

    const tourInfo = tourDataToTourInfo(community.id, tourDetail, tourDraft);
    setTourInfo(tourInfo);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community, tourDetail, tourDraft]);

  //#region Analytics functions

  const getTourAnalyticsDetails = ({
    selectedDate,
    selectedTime,
    selectedType,
  }) => {
    const tourTypeText = tourScheduleService.getTourTypeLabel(selectedType);
    const tourDateTime = `${new Date(selectedDate)
      .toISOString()
      .substr(0, 10)} ${timeToDisplayFormat(selectedTime)}`;

    return {
      tourTypeText,
      tourDateTime,
    };
  };

  const createTourScheduledAnalyticsEvent = ({
    isRecorded,
    selectedDate,
    selectedTime,
    selectedType,
  }) => {
    const { tourTypeText, tourDateTime } = getTourAnalyticsDetails({
      selectedDate,
      selectedTime,
      selectedType,
    });

    logTourScheduled({
      communityId: community.id,
      familyFileId: dataForAnalytics.familyFileId,
      isRecorded,
      originatingPage: dataForAnalytics.originatingPage,
      tourDateTime,
      tourType: tourTypeText,
      userId: dataForAnalytics.familyFileUserId,
    });
  };

  const createTourRescheduledAnalyticsEvent = ({
    isRecorded,
    selectedDate,
    selectedTime,
    selectedType,
  }) => {
    const { tourTypeText, tourDateTime } = getTourAnalyticsDetails({
      selectedDate,
      selectedTime,
      selectedType,
    });

    logTourRescheduledV2({
      communityId: community.id,
      familyFileId: dataForAnalytics.familyFileId,
      isRecorded,
      originatingPage: dataForAnalytics.originatingPage,
      tourDateTime,
      tourType: tourTypeText,
      userId: dataForAnalytics.familyFileUserId,
    });
  };

  const createTourCancelledAnalyticsEvent = ({
    isRecorded,
    originalDate,
    originalTime,
    originalType,
  }) => {
    const { tourTypeText, tourDateTime } = getTourAnalyticsDetails({
      selectedDate: originalDate,
      selectedTime: originalTime,
      selectedType: originalType,
    });

    logTourCancelledV2({
      communityId: community.id,
      familyFileId: dataForAnalytics.familyFileId,
      isRecorded,
      originatingPage: dataForAnalytics.originatingPage,
      tourDateTime,
      tourType: tourTypeText,
      userId: dataForAnalytics.familyFileUserId,
    });
  };

  const createRemoveTourTimeSelectedAnalyticsEvent = ({
    selectedDate,
    selectedTime,
    selectedType,
  }) => {
    const { tourTypeText, tourDateTime } = getTourAnalyticsDetails({
      selectedDate,
      selectedTime,
      selectedType,
    });

    logRemoveTourTimeSelectedV2(
      community.id,
      dataForAnalytics.familyFileId,
      dataForAnalytics.originatingPage,
      tourDateTime,
      tourTypeText,
      dataForAnalytics.familyFileUserId,
    );
  };

  const createTourSchedulingOpenedAnalyticsEvent = () => {
    logTourSchedulingOpenedV2(
      community.id,
      dataForAnalytics.familyFileId,
      dataForAnalytics.originatingPage,
      dataForAnalytics.familyFileUserId,
    );
  };

  const createTourMarkedCompletedAnalyticsEvent = ({
    selectedDate,
    selectedTime,
    selectedType,
    wasPreviouslyScheduled,
  }) => {
    const { tourTypeText, tourDateTime } = getTourAnalyticsDetails({
      selectedDate,
      selectedTime,
      selectedType,
    });

    logTourMarkedCompletedV2(
      community.id,
      dataForAnalytics.familyFileId,
      tourDateTime,
      tourTypeText,
      dataForAnalytics.familyFileUserId,
      wasPreviouslyScheduled,
    );
  };

  const createTourDraftSavedAnalyticsEvent = ({
    selectedDate,
    selectedTime,
    selectedType,
  }) => {
    const { tourTypeText, tourDateTime } = getTourAnalyticsDetails({
      selectedDate,
      selectedTime,
      selectedType,
    });

    logTourDraftSaved(
      community.id,
      dataForAnalytics.familyFileId,
      dataForAnalytics.originatingPage,
      tourDateTime,
      tourTypeText,
      dataForAnalytics.familyFileUserId,
    );
  };

  const createTourErrorViewedAnalyticsEvent = (errorMessage) => {
    logTourErrorViewed(
      community.id,
      errorMessage,
      dataForAnalytics.familyFileId,
      'tour scheduling modal',
      dataForAnalytics.familyFileUserId,
    );
  };

  //#endregion Analytics functions

  const isTourInfoAvailable = () => {
    return tourInfo?.tourButtonLabel ? true : false;
  };

  const createTour = async ({
    status,
    selectedDate,
    selectedTime,
    selectedType,
    isRecorded,
    isActivityHidden,
  }) => {
    const activityDate = buildActivityDate(selectedDate, selectedTime);
    const timeOffset = getTimeOffsetFromSelectedDate(selectedDate);

    const variables = {
      familyFileId: familyFileIdRef.current,
      communityId: community.id,
      activityDate: activityDate,
      timeOffset,
      status,
      tourType: selectedType,
      isRecorded,
      isActivityHidden,
    };

    try {
      const response = await createTourQL({ variables });
      const tour = response.data.createTour;
      return tour;
    } catch (exception) {
      createTourErrorViewedAnalyticsEvent(
        exception.message ? exception.message : exception,
      );
      console.error(exception);
      throw exception;
    }
  };

  const updateTour = async ({
    familyCommunityTourId,
    status,
    selectedDate,
    selectedTime,
    selectedType,
    isRecorded,
    isActivityHidden,
  }) => {
    let variables;
    if (status === TOUR_CANCEL) {
      variables = {
        familyCommunityTourId,
        status,
        userName: currentUser.username,
        userRole: currentUser.role,
        isRecorded,
        isActivityHidden,
      };
    } else {
      const activityDate = buildActivityDate(selectedDate, selectedTime);

      const timeOffset = getTimeOffsetFromSelectedDate(selectedDate);

      variables = {
        familyCommunityTourId,
        activityDate,
        timeOffset,
        status,
        tourType: selectedType,
        userName: currentUser.username,
        userRole: currentUser.role,
        isRecorded,
        isActivityHidden,
      };
    }

    try {
      const response = await updateTourQL({ variables });

      const tour = response.data.updateTour;
      return tour;
    } catch (exception) {
      createTourErrorViewedAnalyticsEvent(
        exception.message ? exception.message : exception,
      );
      console.error(exception);
      throw exception;
    }
  };

  const upsertTour = async ({
    status,
    selectedDate,
    selectedTime,
    selectedType,
    isRecorded,
    isActivityHidden,
  }) => {
    let tour;

    if (tourInfo.familyCommunityTourId) {
      tour = await updateTour({
        familyCommunityTourId: tourInfo.familyCommunityTourId,
        status: status,
        selectedDate,
        selectedTime,
        selectedType,
        isRecorded,
        isActivityHidden,
      });
    } else {
      tour = await createTour({
        status,
        selectedDate,
        selectedTime,
        selectedType,
        isRecorded,
        isActivityHidden,
      });
    }

    return tour;
  };

  const saveTourDraft = async ({
    isCancel,
    selectedDate,
    selectedTime,
    selectedType,
  }) => {
    let variables;

    if (isCancel) {
      variables = {
        familyCommunityTourDraftId:
          tourInfo?.familyCommunityTourDraftId || null,
        familyFileId: familyFileIdRef.current,
        communityId: community.id,
        activityDate: 0,
        timeOffset: 0,
        tourType: '',
      };
    } else {
      const activityDate = buildActivityDate(selectedDate, selectedTime);
      const timeOffset = getTimeOffsetFromSelectedDate(selectedDate);

      variables = {
        familyCommunityTourDraftId:
          tourInfo?.familyCommunityTourDraftId || null,
        familyFileId: familyFileIdRef.current,
        communityId: community.id,
        activityDate: activityDate,
        timeOffset: timeOffset,
        tourType: selectedType,
      };
    }

    try {
      const response = await upsertTourDraftQL({ variables });

      const tourDraft = response.data.upsertTourDraft;

      return tourDraft;
    } catch (exception) {
      createTourErrorViewedAnalyticsEvent(
        exception.message ? exception.message : exception,
      );
      console.error(exception);
      throw exception;
    }
  };

  const processRecordScheduledTour = async (
    selectedDate,
    selectedTime,
    selectedType,
  ) => {
    const tour = await upsertTour({
      status: TOUR_SCHEDULE,
      selectedDate,
      selectedTime,
      selectedType,
      isRecorded: true,
    });

    const newTourInfo = tourToTourInfo({
      communityId: community.id,
      familyCommunityTourDraftId: null,
      familyCommunityTourId: tour.familyCommunityTourId,
      selectedDate,
      selectedTime,
      selectedType,
      tourDetail: null,
    });

    createTourScheduledAnalyticsEvent({
      isRecorded: true,
      selectedDate,
      selectedTime,
      selectedType,
    });

    const hasTourChanged = !isEqual(tourInfo, newTourInfo);
    hasTourChanged && onChange(newTourInfo);

    setTourInfo(newTourInfo);
    setIsTourActivityModalOpen(false);
  };

  const processRecordCompletedTour = async (
    selectedDate,
    selectedTime,
    selectedType,
  ) => {
    const tourSchedule = await upsertTour({
      status: TOUR_SCHEDULE,
      selectedDate,
      selectedTime: selectedTime || '',
      selectedType,
      isRecorded: true,
      isActivityHidden: true,
    });

    const tourComplete = await updateTour({
      familyCommunityTourId: tourSchedule.familyCommunityTourId,
      status: TOUR_COMPLETE,
      selectedDate,
      selectedTime: selectedTime || '',
      selectedType,
      isRecorded: true,
      isActivityHidden: false,
    });

    createTourMarkedCompletedAnalyticsEvent({
      selectedDate,
      selectedTime,
      selectedType,
      wasPreviouslyScheduled: false,
    });

    const newTourInfo = tourDataToTourInfo(
      community.id,
      {
        ...EMPTY_TOUR_DETAILS,
        familyCommunityTourId: tourComplete.familyCommunityTourId,
      },
      tourDraft,
    );

    onChange(newTourInfo);

    setTourInfo(newTourInfo);
    setIsTourActivityModalOpen(false);
  };

  const processDraftScheduledTour = async ({
    selectedDate,
    selectedTime,
    selectedType,
    actionButtonType,
  }) => {
    const savedTourDraft = await saveTourDraft({
      isCancel: false,
      selectedDate,
      selectedTime,
      selectedType,
    });

    const newTourInfo = tourToTourInfo({
      communityId: community.id,
      familyCommunityTourDraftId: savedTourDraft.familyCommunityTourDraftId,
      familyCommunityTourId: tourDetail?.familyCommunityTourId,
      selectedDate,
      selectedTime,
      selectedType,
      tourDetail,
    });

    createTourDraftSavedAnalyticsEvent({
      selectedDate,
      selectedTime,
      selectedType,
    });

    if (actionButtonType === ACTION_BUTTON_TYPE.SAVE_AND_REFER) {
      window.open(
        `/send-referral/${familyFileId}?communities=${community.id}`,
        '_self',
      );
    } else if (actionButtonType === ACTION_BUTTON_TYPE.SAVE_AND_CLOSE) {
      const hasTourChanged = !isEqual(tourInfo, newTourInfo);
      // 'Publish' activity date in UTC time
      hasTourChanged &&
        onChange({
          ...newTourInfo,
          activityDate: getDateLessTimezoneOffset(
            buildActivityDate(selectedDate, selectedTime),
          ),
        });

      setTourInfo(newTourInfo);

      setIsTourActivityModalOpen(false);
    }
  };

  const processDraftCancelTour = async () => {
    const tourDraft = await saveTourDraft({ isCancel: true });

    const newTourInfo = tourToTourInfo({
      communityId: community.id,
      familyCommunityTourDraftId: tourDraft.familyCommunityTourDraftId,
      familyCommunityTourId: tourDetail.familyCommunityTourId,
      selectedDate: '',
      selectedTime: '',
      selectedType: '',
      tourDetail,
    });

    onChange(newTourInfo);

    setTourInfo(newTourInfo);
    setIsTourActivityModalOpen(false);
  };

  const processCompleteTour = async ({
    selectedDate,
    selectedType,
    actionButtonType,
  }) => {
    if (actionButtonType === ACTION_BUTTON_TYPE.SAVE_AND_CLOSE) {
      if (tourInfo.familyCommunityTourDraftId) {
        await deleteTourDraftQL({
          variables: {
            familyCommunityTourDraftId: tourInfo.familyCommunityTourDraftId,
          },
        });
      }

      await updateTour({
        familyCommunityTourId: tourInfo.familyCommunityTourId,
        status: TOUR_COMPLETE,
        selectedDate,
        selectedTime: MIDNIGHT,
        selectedType,
      });

      const newTourInfo = tourToTourInfo({
        communityId: community.id,
        familyCommunityTourDraftId: null,
        familyCommunityTourId: tourInfo.familyCommunityTourId,
        selectedDate,
        selectedTime: MIDNIGHT,
        selectedType,
        tourDetail: null,
      });

      createTourMarkedCompletedAnalyticsEvent({
        selectedDate: newTourInfo.selectedDate,
        selectedTime: newTourInfo.selectedTime,
        selectedType: newTourInfo.selectedType,
        wasPreviouslyScheduled: true,
      });

      onChange(newTourInfo);
      setTourInfo(newTourInfo);
      setIsTourActivityModalOpen(false);
    }
  };

  const processCancelTour = async (actionButtonType) => {
    if (actionButtonType === ACTION_BUTTON_TYPE.SAVE_AND_REFER) {
      await saveTourDraft({ isCancel: true });

      window.open(
        `/send-referral/${familyFileId}?communities=${community.id}`,
        '_self',
      );
    } else if (actionButtonType === ACTION_BUTTON_TYPE.SAVE_AND_CLOSE) {
      if (tourInfo.familyCommunityTourDraftId) {
        await deleteTourDraftQL({
          variables: {
            familyCommunityTourDraftId: tourInfo.familyCommunityTourDraftId,
          },
        });
      }

      await updateTour({
        familyCommunityTourId: tourInfo.familyCommunityTourId,
        status: TOUR_CANCEL,
        selectedDate: '',
        selectedTime: '',
        selectedType: '',
      });

      const newTourInfo = tourToTourInfo({
        communityId: community.id,
        familyCommunityTourDraftId: null,
        familyCommunityTourId: tourInfo.familyCommunityTourId,
        selectedDate: '',
        selectedTime: '',
        selectedType: '',
        tourDetail: null,
      });

      createTourCancelledAnalyticsEvent({
        isRecorded: true,
        originalDate: tourInfo.selectedDate,
        originalTime: tourInfo.selectedTime,
        originalType: tourInfo.selectedType,
      });

      onChange(newTourInfo);
      setTourInfo(newTourInfo);
      setIsTourActivityModalOpen(false);
    }
  };

  const processRescheduleTour = async (
    selectedDate,
    selectedTime,
    selectedType,
    actionButtonType,
  ) => {
    if (actionButtonType === ACTION_BUTTON_TYPE.SAVE_AND_REFER) {
      await saveTourDraft({
        isCancel: false,
        selectedDate,
        selectedTime,
        selectedType,
      });

      createTourDraftSavedAnalyticsEvent({
        selectedDate,
        selectedTime,
        selectedType,
      });

      window.open(
        `/send-referral/${familyFileId}?communities=${community.id}`,
        '_self',
      );
    } else if (actionButtonType === ACTION_BUTTON_TYPE.SAVE_AND_CLOSE) {
      if (tourInfo.familyCommunityTourDraftId) {
        await deleteTourDraftQL({
          variables: {
            familyCommunityTourDraftId: tourInfo.familyCommunityTourDraftId,
          },
        });
      }

      await updateTour({
        familyCommunityTourId: tourInfo.familyCommunityTourId,
        status: TOUR_RESCHEDULE,
        selectedDate,
        selectedTime,
        selectedType,
      });

      const newTourInfo = tourToTourInfo({
        communityId: community.id,
        familyCommunityTourDraftId: null,
        familyCommunityTourId: tourInfo.familyCommunityTourId,
        selectedDate,
        selectedTime,
        selectedType,
        tourDetail,
      });

      createTourRescheduledAnalyticsEvent({
        isRecorded: true,
        selectedDate,
        selectedTime,
        selectedType,
      });

      onChange(newTourInfo);
      setTourInfo(newTourInfo);
      setIsTourActivityModalOpen(false);
    }
  };

  const renderTourButtonLabel = () => {
    if (tourDraft?.activityDate === 0) {
      return tourInfo?.originalTourButtonLabel || labels.SCHEDULE_TOUR;
    }
    return tourInfo?.tourButtonLabel || labels.SCHEDULE_TOUR;
  };

  //#region Event Handlers

  const handleTourButtonClick = () => {
    createTourSchedulingOpenedAnalyticsEvent();
    setIsTourActivityModalOpen(true);
  };

  const handleRemoveTourClick = async () => {
    if (tourInfo.familyCommunityTourDraftId) {
      await deleteTourDraftQL({
        variables: {
          familyCommunityTourDraftId: tourInfo.familyCommunityTourDraftId,
        },
      });

      createRemoveTourTimeSelectedAnalyticsEvent({
        selectedDate: tourInfo.selectedDate,
        selectedTime: tourInfo.selectedTime,
        selectedType: tourInfo.selectedType,
      });
      setTourInfo(null);
    }
  };

  const handleTourDialogClose = () => {
    setIsTourActivityModalOpen(false);
  };

  const handleTourDialogSave = async (
    selectedDate,
    selectedTime,
    selectedType,
    action,
    actionButtonType,
  ) => {
    switch (action) {
      case ACTION_TYPE.RECORD_SCHEDULED:
        await processRecordScheduledTour(
          selectedDate,
          selectedTime,
          selectedType,
        );
        break;

      case ACTION_TYPE.RECORD_COMPLETED:
        await processRecordCompletedTour(
          selectedDate,
          selectedTime,
          selectedType,
        );
        break;

      case ACTION_TYPE.DRAFT_SCHEDULED:
        await processDraftScheduledTour({
          selectedDate,
          selectedTime,
          selectedType,
          actionButtonType,
        });
        break;

      case ACTION_TYPE.DRAFT_CANCEL:
        await processDraftCancelTour();
        break;

      case ACTION_TYPE.COMPLETE:
        await processCompleteTour({
          selectedDate,
          selectedType,
          actionButtonType,
        });
        break;

      case ACTION_TYPE.CANCEL:
        await processCancelTour(actionButtonType);
        break;

      case ACTION_TYPE.RESCHEDULE:
        await processRescheduleTour(
          selectedDate,
          selectedTime,
          selectedType,
          actionButtonType,
        );
        break;

      default:
        break;
    }
  };

  //#endregion Handlers

  return (
    <>
      {isTourInfoAvailable() && (
        <Typography level="small" className={classes.selected_tour_time}>
          {labels.SELECTED_TOUR_TIME}
        </Typography>
      )}

      <div className={classes.tour_button_container}>
        <Button
          type="outlined"
          onClick={handleTourButtonClick}
          startIcon={<CalendarDates />}
          size="small"
          className={clsx(tourInfo && classes.selected_tour_button)}
        >
          {renderTourButtonLabel()}
        </Button>
      </div>

      {isRemoveTourAllowed && isTourInfoAvailable() && (
        <div
          onClick={handleRemoveTourClick}
          className={classes.remove_tour_info_container}
        >
          <Typography
            underlined
            className={classes.remove_tour_time}
            color="smokyTopaz2"
            level="small"
          >
            {labels.REMOVE_TOUR_TIME}
          </Typography>
        </div>
      )}

      {community && (
        <TourActivity
          community={community}
          configuration={configuration}
          isCtAppEnabled={isCtAppEnabled}
          isOpen={isTourActivityModalOpen}
          labels={labels}
          onClose={handleTourDialogClose}
          onSave={handleTourDialogSave}
          tourInfo={tourInfo}
        />
      )}
    </>
  );
};

TourScheduler.propTypes = {
  community: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    phone: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    referralNote: PropTypes.shape({
      text: PropTypes.string,
    }),
    zip: PropTypes.string,
  }),
  configuration: PropTypes.shape({
    schedulingQuestion: PropTypes.shape({
      show: PropTypes.bool,
      isYesEnabled: PropTypes.bool,
      isNoEnabled: PropTypes.bool,
    }),
    statusQuestion: PropTypes.shape({
      show: PropTypes.bool,
      isYesEnabled: PropTypes.bool,
      isNoEnabled: PropTypes.bool,
    }),
    arePastDatesEnabled: PropTypes.bool,
    action: PropTypes.string,
  }),
  dataForAnalytics: PropTypes.shape({
    familyFileId: PropTypes.number,
    familyFileUserId: PropTypes.number,
    originatingPage: PropTypes.string,
  }),
  familyFileId: PropTypes.number,
  isCtAppEnabled: PropTypes.bool,
  isRemoveTourAllowed: PropTypes.bool,
  onChange: PropTypes.func,
  tourDetail: PropTypes.shape({
    currentStatus: PropTypes.string,
    familyCommunityTourId: PropTypes.number,
    scheduledTourDate: PropTypes.string | PropTypes.number,
    tourType: PropTypes.string,
  }),
  tourDraft: PropTypes.shape({
    familyCommunityTourDraftId: PropTypes.number.isRequired,
    communityId: PropTypes.number.isRequired,
    activityDate: PropTypes.number.isRequired,
    tourType: PropTypes.string.isRequired,
  }),
};

TourScheduler.defaultProps = {
  isRemoveTourAllowed: true,
  onChange: () => {},
};

export default TourScheduler;
