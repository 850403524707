import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '8px',
    marginBottom: '4px',
  },
  description: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '16px',
    paddingBottom: '8px',
  },
  show_more: {
    color: theme.palette.neutral.cosmicCobalt3,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  card: {
    marginBottom: '4px',
  },
  success_message: {
    marginTop: theme.spacing(3),
  },
  open_tasks_link: {
    color: theme.palette.neutral.cosmicCobalt3,
    display: 'flex',
    alignItems: 'center',
    '&:active': {
      color: theme.palette.neutral.cosmicCobalt3,
    },
    '&:visited': {
      color: theme.palette.neutral.cosmicCobalt3,
    },
    '& > svg': {
      marginLeft: theme.spacing(1),
    },
  },
}));

export { useStyles };
