import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './CommunityAdvisory.style';
import CommunityAdvisoryModal from './CommunityAdvisoryModal';
import Toast from 'common/Toast/Toast';

const CommunityAdvisory = ({
  labels,
  communityName,
  communityId,
  openAdvisoryTypes,
  logAdvisoryClick,
}) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getAdvisoryText = () => {
    if (openAdvisoryTypes.length === 2) {
      return `${openAdvisoryTypes.join(' and')} advisory`;
    } else if (openAdvisoryTypes.length === 3) {
      return `${openAdvisoryTypes[0]}, ${openAdvisoryTypes[1]}, and ${openAdvisoryTypes[2]} advisory`;
    }
    return `${openAdvisoryTypes[0]} advisory`;
  };

  return (
    <div className={classes.advisory_container}>
      <CommunityAdvisoryModal
        isModalOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        labels={labels}
        communityName={communityName}
        communityId={communityId}
      />
      <Toast
        type="error"
        className={classes.advisory_message}
        onMore={() => {
          logAdvisoryClick();
          setIsModalOpen(true);
        }}
      >
        {getAdvisoryText()}
      </Toast>
    </div>
  );
};

CommunityAdvisory.propTypes = {
  labels: PropTypes.object.isRequired,
  communityName: PropTypes.string,
  communityId: PropTypes.string.isRequired,
  openAdvisoryTypes: PropTypes.array.isRequired,
  logAdvisoryClick: PropTypes.func,
};

CommunityAdvisory.defaultProps = {
  communityName: '',
};

export default CommunityAdvisory;
