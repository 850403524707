import React from 'react';
import PropTypes from 'prop-types';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { useStyles } from './CommunityPreferences.style';
import Field from './Field';
import Typography from 'common/Typography/Typography';
import CheckMark from 'common/Icons/basic/CheckMark';
import ContainerItem from 'common/ContainerItem/ContainerItem';
import Container from 'common/Container/Container';

export const ROOM_PREFERENCE_ENUM = {
  ALL: 'All room types',
  STUDIO: 'Studio',
  PRIVATE_ONE_BEDROOM: 'One bedroom',
  PRIVATE_TWO_BEDROOM: 'Two bedrooms',
  PRIVATE: 'Private',
  SHARED: 'Shared (Semi-Private)',
};

const SummaryView = ({ label, list }) => {
  const classes = useStyles();

  if (!list || list.length === 0) {
    return null;
  }

  return (
    <div className={classes.row_preferences_resources}>
      <Typography className={classes.label} color="eerieBlack5" level="small">
        <>{label}</>
      </Typography>
      {list.map((entry) => {
        return (
          <div key={entry} className={classes.resource}>
            <CheckMark className={classes.icon_check} />
            <Typography level="body">{entry}</Typography>
          </div>
        );
      })}
    </div>
  );
};

SummaryView.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
};

SummaryView.defaultProps = {
  label: '',
  list: [],
};

const CommunityPreferencesContent = ({
  communityPreferences,
  isEditable,
  labels,
  flags,
}) => {
  const CARE_TYPE_ENUM = {
    Z: labels.CARE_TYPE_LABELS_WITH_CODE_MEMORY_CARE,
    H: labels.CARE_TYPE_LABELS_WITH_CODE_HOME_CARE,
    A: labels.CARE_TYPE_LABELS_WITH_CODE_ASSISTED_LIVING,
    G: labels.CARE_TYPE_LABELS_WITH_CODE_RESIDENTIAL_CARE_HOME,
    R: labels.CARE_TYPE_LABELS_WITH_CODE_RETIREMENT_COMMUNITY,
    N: labels.CARE_TYPE_LABELS_WITH_CODE_NURSING_HOME,
    P: labels.CARE_TYPE_LABELS_WITH_CODE_SENIOR_APARTMENTS,
    D: labels.CARE_TYPE_LABELS_WITH_CODE_ADULT_DAY_SERVICES,
  };

  const convertToRoomPreferenceLabel = (key) => ROOM_PREFERENCE_ENUM[key];
  const convertToCareTypeLabel = (careTypeObject) =>
    CARE_TYPE_ENUM[careTypeObject.code];

  const tooltip = labels.CLICK_TO_EDIT_OR_UPDATE_COMMUNITY_PREFERENCES_CRITERIA;

  return (
    <Container>
      {flags.isInitialConsultationEnabled && (
        <ContainerItem xs={12} sm={6}>
          <SummaryView
            label={labels.TYPE_OF_CARE}
            list={communityPreferences.careTypePreference?.map(
              convertToCareTypeLabel,
            )}
          />
        </ContainerItem>
      )}
      <ContainerItem xs={12} sm={6}>
        <SummaryView
          label={labels.ROOM_PREFERENCE}
          list={communityPreferences.roomPreference?.map(
            convertToRoomPreferenceLabel,
          )}
        />
      </ContainerItem>
      {/* Developer note: With the IC workflow, the Distance Request field moved to the Resident Information sub-form.
            When the IC workflow is fully launched, this section can be removed.
         */}
      {!flags.isInitialConsultationEnabled && (
        <Field
          withTooltip={isEditable}
          tooltip={tooltip}
          label={labels.DISTANCE_REQUESTED}
          value={`${communityPreferences.locationRange} ${labels.MILES_PREFERRED_LOCATION}`}
        />
      )}
    </Container>
  );
};

CommunityPreferencesContent.propTypes = {
  communityPreferences: PropTypes.shape({
    locationRange: PropTypes.number,
    roomPreference: PropTypes.arrayOf(PropTypes.string),
    careTypePreference: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  isEditable: PropTypes.bool,
  labels: PropTypes.object.isRequired,

  /**
   * Object containing feature flags.
   */
  flags: PropTypes.shape({
    isInitialConsultationEnabled: PropTypes.bool,
  }).isRequired,
};

CommunityPreferencesContent.defaultProps = {
  isEditable: false,
};

export default withLDConsumer()(CommunityPreferencesContent);
