import React from 'react';
import PropTypes from 'prop-types';
import RowCard from '../../RowCard/RowCard';
import { useStyles } from './TableRowCard.style';
import clsx from 'clsx';

const TableRowCard = ({ className, children, onClick }) => {
  const classes = useStyles();
  const tableRowCardClasses = clsx(classes.table_row_card_container, className);

  return (
    <RowCard className={tableRowCardClasses} onClick={onClick}>
      {children}
    </RowCard>
  );
};

TableRowCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

TableRowCard.defaultProps = {
  className: '',
  onClick: () => {},
};

export default TableRowCard;
