const findInquiryForScreeningQuery = `
  query findInquiryForScreening($inquiryId: Int!) {
    findInquiryForScreening(inquiryId: $inquiryId) {
      inquiryId
      firstName
      lastName
      email
      primaryPhone
      desiredPostalCode
      desiredCity
      desiredState
      desiredCountryCode
      relationToResident
      careTypeCode
      lookingReason
      moveDateRange
      veteran
      resident1FirstName
      resident1LastName
      resident1Age
      resident1Gender
      resident2FirstName
      resident2LastName
      resident2Age
      resident2Gender
      budgetMin
      budgetMax
      financialResources
      medicaid
      locationRange
      roomPreference
      getAround
      diabetic
      memoryLoss
      behavioral
      toileting
      bathing
      medicaid
      referrerUrl
      utmCampaign
      utmMedium
      utmSource
      utmTerm
      utmContent
      status
      audienceKey
      sourceId
      subSourceId
      inquiryMethod
      submissionUrl
      InquiryFamilyFile {
        familyFileId
      }
      inquiryContact {
        inquiryContactId
        oneId
        firstName
        lastName
        phone
        email
        lastContactedAt
        updatedAt
      }
      inquiryFormData {
        inquiryFormDataId
        inquiryId
        createdAt
        createdBy
        updatedAt
        updatedBy
        slugId
        versionId
        contactConfirmation
        disclaimer
        firstName
        lastName
        email
        primaryPhone
        noAnswerCall
        confirmInterest
        relationToResident
        resident1FirstName
        resident1LastName
        budget
        medicalInfo
        desiredCity
        desiredState
        desiredPostalCode
        desiredCountry
        desiredDistance
        notInterestedReason
        transferSla
        unqualified
        pipedaDisclosure
        isCompleteCall
        moveDateRange
        careTypeCode
        veteran
      }
      createdBy
      createdAt
      updatedBy
      updatedAt
      notes {
        inquiryNoteId
        note
        createdBy {
          userId
          firstName
          lastName
        }
        createdAt
        updatedBy {
          userId
          firstName
          lastName
        }
        updatedAt
      }
    }
  }`;

export default findInquiryForScreeningQuery;
