import PropTypes from 'prop-types';
import React from 'react';
import Alert from 'common/Alert/Alert';
import { withLabels } from 'context/LabelContext';

const OtherAlerts = ({ alerts, labels }) => {
  const alertsConfig = {
    CALL_DISCONNECTED_BY_CALLER: {
      title: labels.CALL_DISCONNECTED,
      description: labels.CALL_DISCONNECTED_BY_CALLER,
      confirmText: labels.CONTINUE,
      type: 'informative',
    },
    REDIAL_NOT_ALLOWED: {
      title: labels.REDIAL_NOT_ALLOWED_TITLE,
      description: labels.REDIAL_NOT_ALLOWED_DESCRIPTION,
      confirmText: labels.OK,
      type: 'delete',
    },
  };
  const alert = alerts.find((alert) => alert.isOpen);
  if (!alert) return null;
  const otherConfig = alertsConfig[alert.alertType];
  return (
    <Alert
      title={otherConfig.title}
      description={otherConfig.description}
      confirmText={otherConfig.confirmText}
      type={otherConfig.type}
      isOpen={alert.isOpen}
      onClose={alert.onClose}
      onConfirm={alert.onClose}
    />
  );
};

OtherAlerts.propTypes = {
  alerts: PropTypes.array,
  labels: PropTypes.object,
};

export default withLabels(OtherAlerts);
