import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ActivityMobile from './ActivityMobile';
import Media from '../../common/Media/Media';
import ActivityXL from './ActivityXL';
import ActivityDesktop from './ActivityDesktop';
import useGraphQLQuery from '../../hooks/useGraphQLQuery';
import { getPastTasks, getUpcomingTasks } from './activityService';
import activityQuery from './activityQuery';
import Loading from '../Loading/Loading';

const Activity = ({ labels, familyFileId, showInSidePanel }) => {
  const [shouldRefetch, setShouldRefetch] = useState({
    completed: false,
    type: '',
  });
  const [completedActionMessage, setCompletedActionMessage] = useState(
    undefined,
  );

  useEffect(() => {
    if (shouldRefetch.completed === true) {
      refetch({ familyFileId });
      setCompletedActionMessage(shouldRefetch.type);
    }
    // eslint-disable-next-line
  }, [shouldRefetch]);

  const { loading, error, data, refetch } = useGraphQLQuery(activityQuery, {
    variables: { familyFileId },
  });

  if (loading) return <Loading height="calc(100vh - 197px)" />;
  if (error) return `Error! ${error.message}`;

  const pastActions = getPastTasks(data.findFamilyFileById.actions);
  const [toDoTasks, moreTasks] = getUpcomingTasks(
    data.findFamilyFileById.actions,
  );

  const dataForSegment = {
    familyFileId: familyFileId,
    oneId: data.findFamilyFileById.oneId,
  };

  const handleRefetchState = (needToRefetch) => {
    setShouldRefetch(needToRefetch);
  };

  if (showInSidePanel) {
    return (
      <ActivityMobile
        labels={labels}
        familyFileId={familyFileId}
        toDoTasks={toDoTasks}
        pastActions={pastActions}
        handleRefetchState={handleRefetchState}
        completedActionMessage={completedActionMessage}
        moreTasks={moreTasks}
        dataForSegment={dataForSegment}
      />
    );
  }

  return (
    <Media
      xs={
        <ActivityMobile
          labels={labels}
          familyFileId={familyFileId}
          toDoTasks={toDoTasks}
          pastActions={pastActions}
          handleRefetchState={handleRefetchState}
          completedActionMessage={completedActionMessage}
          moreTasks={moreTasks}
          dataForSegment={dataForSegment}
        />
      }
      sm={
        <ActivityDesktop
          labels={labels}
          familyFileId={familyFileId}
          toDoTasks={toDoTasks}
          pastActions={pastActions}
          handleRefetchState={handleRefetchState}
          completedActionMessage={completedActionMessage}
          moreTasks={moreTasks}
          dataForSegment={dataForSegment}
        />
      }
      xl={
        <ActivityXL
          labels={labels}
          familyFileId={familyFileId}
          toDoTasks={toDoTasks}
          pastActions={pastActions}
          handleRefetchState={handleRefetchState}
          completedActionMessage={completedActionMessage}
          moreTasks={moreTasks}
          dataForSegment={dataForSegment}
        />
      }
    />
  );
};

Activity.propTypes = {
  labels: PropTypes.object.isRequired,
  familyFileId: PropTypes.number.isRequired,
  showInSidePanel: PropTypes.bool,
};

export default Activity;
