import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import NoteText from 'common/Icons/file/NoteText';
import TextInput from 'common/TextInput/TextInput';
import Dropdown from 'common/Dropdown/Dropdown';
import Typography from 'common/Typography/Typography';
import Toast from 'common/Toast/Toast';
import ScriptBuilderModal from '../common/ScriptBuilderModal/ScriptBuilderModal';
import { ScriptPropType } from '../scriptBuilderPropTypes';
import cloneObject from '../util/cloneObject';
import Switch from 'common/Switch/Switch';
import { useStyles } from './ScriptDialog.style';

const ScriptDialog = ({
  onClose,
  currentScript: initialScript,
  scripts,
  onSaveScript,
  open,
  labels,
}) => {
  const classes = useStyles();
  const [script, setScript] = useState({});
  const [errors, setErrors] = useState([]);
  const [scriptsWithSameTarget, setScriptsWithSameTarget] = useState([]);

  const statusMap = {
    ENABLED: true,
    DISABLED: false,
  };
  const targetTitles = [
    {
      label: 'Default',
      value: 'default',
    },
    {
      label: 'Test Screening Process',
      value: 'test_screening_process',
    },
    {
      label: 'Self Urgent Financially Qualified',
      value: 'self_urgent_financially_qualified',
    },
    {
      label: 'Health Care Test',
      value: 'health_care',
    },
    {
      label: 'Inbound Call',
      value: 'inbound_call',
    },
    {
      label: 'Manual',
      value: 'manual',
    },
  ];
  const selectedTargetItem = script.target ? script.target : 'Select from list';

  const selectedTarget = targetTitles.find(
    (item) => item.value === selectedTargetItem,
  );
  useEffect(() => {
    let scriptCopy = cloneObject(initialScript);
    if (!scriptCopy) scriptCopy = {};
    setScript(scriptCopy);
    setErrors([]);
    setScriptsWithSameTarget([]);
  }, [initialScript]);

  if (!open) return null;

  const getScriptWithSameTarget = () => {
    let scriptNames;
    setScriptsWithSameTarget([]);
    if (script.status === 'ENABLED') {
      scriptNames = scripts.map((existedScript) => {
        if (
          script.target &&
          script.target === existedScript.target &&
          script.slug !== existedScript.slug &&
          existedScript.status === 'ENABLED'
        ) {
          return existedScript.name;
        }
        return '';
      });
      setScriptsWithSameTarget(scriptNames.filter(Boolean).join(', '));
    }
  };

  const changeValue = (id, value) => {
    script[id] = value;
    setScript({ ...script });

    if (id === 'target') {
      getScriptWithSameTarget();
    }
  };

  const blurName = (value) => {
    if (!script.slug || script.slug.trim() === '') {
      let slug = '';
      const name = value;
      let caps = false;
      for (let i = 0; i < name.length; i++) {
        const char = name[i];
        if (char !== ' ') {
          if (caps) {
            slug += char.toUpperCase();
            caps = false;
          } else {
            slug += char.toLowerCase();
          }
        } else {
          caps = true;
        }
      }
      setScript({ ...script, slug });
    }
  };

  const complete = () => {
    if (!script.name) return false;
    if (!script.slug) return false;
    if (!script.description) return false;
    return true;
  };

  const duplicated = () => {
    const foundScriptsBySlug = scripts.filter((f) => f.slug === script.slug)
      .length;
    if (foundScriptsBySlug === 0) return false;
    if (Object.keys(initialScript).length === 0 && foundScriptsBySlug === 0) {
      return false;
    }
    if (Object.keys(initialScript).length === 0 && foundScriptsBySlug > 0) {
      return true;
    }
    if (Object.keys(initialScript).length !== 0 && foundScriptsBySlug === 0) {
      return false;
    }
    if (Object.keys(initialScript).length !== 0 && foundScriptsBySlug === 1) {
      return false;
    }
    return true;
  };

  const addScript = async () => {
    let hasErrors = false;
    let newErrors = [...errors];
    if (duplicated()) {
      if (!newErrors.find((e) => e.key === 'duplicated')) {
        newErrors.push({
          key: 'duplicated',
          text: labels.SCRIPT_EXISTING_SLUG,
        });
      }
      hasErrors = true;
    } else {
      newErrors = newErrors.filter((e) => e.key !== 'duplicated');
    }
    if (!complete()) {
      if (!newErrors.find((e) => e.key === 'incomplete')) {
        newErrors.push({
          key: 'incomplete',
          text: labels.SCRIPT_INCOMPLETE,
        });
      }
      hasErrors = true;
    } else {
      newErrors = newErrors.filter((e) => e.key !== 'incomplete');
    }
    setErrors(newErrors);
    if (!hasErrors) {
      onSaveScript(script);
    }
  };

  const changeStatusToggle = (e) => {
    script.status = e.target.checked ? 'ENABLED' : 'DISABLED';
    setScript({ ...script });

    getScriptWithSameTarget();
  };

  const getErrors = () => {
    if (errors.length === 0) return null;
    return (
      <div className={classes.errors}>
        {errors.map((e) => (
          <div key={e.key}>{e.text}</div>
        ))}
      </div>
    );
  };

  const title = initialScript.scriptId ? labels.EDIT_SCRIPT : labels.NEW_SCRIPT;
  const bodyComponent = (
    <Fragment>
      {scriptsWithSameTarget.length > 0 && (
        <Toast type="warning" className={classes.target_script_warning}>
          <Typography>
            {labels.SETTING_THIS_SCRIPT_AS_ACTIVE.replace(
              '{SCRIPT_NAME}',
              scriptsWithSameTarget,
            )}
          </Typography>
        </Toast>
      )}
      {getErrors()}
      <div className={classes.column}>
        <div className={classes.status_script_switch}>
          <Switch
            label={labels.SET_SCRIPT_AS_ACTIVE}
            checked={statusMap[script?.status] || false}
            onChange={(e) => changeStatusToggle(e)}
          />
        </div>
        <Dropdown
          className={classes.input_drop_down}
          items={targetTitles}
          label={labels.TARGET}
          selectedItem={selectedTarget}
          onChange={(e) => changeValue('target', e.value)}
        />
        <TextInput
          className={classes.input}
          id="name"
          label={labels.NAME}
          variant="outlined"
          color="primary"
          name="name"
          value={script.name || ''}
          onChange={(value) => changeValue('name', value)}
          onBlur={(value) => blurName(value)}
        />
        <TextInput
          className={classes.input}
          disabled={script.scriptId ? true : false}
          id="slug"
          label={labels.SLUG}
          variant="outlined"
          color="primary"
          name="slug"
          value={script.slug || ''}
          onChange={(value) => changeValue('slug', value)}
        />
      </div>
      <div className={classes.column}>
        <TextInput
          label={labels.DESCRIPTION}
          id="description"
          className={classes.input}
          multiline
          rows={6}
          name="description"
          variant="outlined"
          color="primary"
          value={script.description || ''}
          onChange={(value) => changeValue('description', value)}
        />
      </div>
    </Fragment>
  );

  return (
    <ScriptBuilderModal
      title={title}
      iconComponent={<NoteText />}
      bodyComponent={bodyComponent}
      onSave={addScript}
      onClose={onClose}
      open={open}
      labels={labels}
    />
  );
};

ScriptDialog.propTypes = {
  onClose: PropTypes.func,
  currentScript: ScriptPropType,
  scripts: PropTypes.arrayOf(ScriptPropType),
  onSaveScript: PropTypes.func,
  open: PropTypes.bool,
  labels: PropTypes.object,
};

export default ScriptDialog;
