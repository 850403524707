import PropTypes from 'prop-types';
import { originalFormStepPropType, inquiryPropType } from '../inquiryPropTypes';
import ButtonGroup from './ButtonGroup/ButtonGroup';
import CheckboxGroup from './CheckboxGroup/CheckboxGroup';
import React, { useState } from 'react';
import EndCall from 'common/Graphics/other/EndCall';
import {
  assignSlaFields,
  globalFields,
  inquiryFields,
  operations,
  stepTypes,
} from 'services/callCenter/fieldsMapper';
import inquiryService from 'services/callCenter/inquiryService';
import { useStyles } from './Chiclet.style';
import ChicletCard, { CHICLET_CARD_STATES } from './ChicletCard/ChicletCard';
import ContactInfo from './ContactInfo/ContactInfo';
import FamilyInfo from './FamilyInfo/FamilyInfo';
import LocationInfo from './LocationInfo/LocationInfo';
import formChicletsService from 'services/callCenter/formChicletsService';
import { cloneDeep } from 'lodash';
import ButtonLocation from './ButtonGroup/ButtonLocation';
import ReferralSource from './ReferralSource/ReferralSource';
import Typography from 'common/Typography/Typography';
import TextInputGroup from './TextInputGroup/TextInputGroup';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { selectManualInquiryState } from '../../../../stateManagement/callcenter/formChiclets/reducers/formChicletsReducer';

const renderHTML = (rawHTML) =>
  React.createElement('div', { dangerouslySetInnerHTML: { __html: rawHTML } });

const UNKNOWN = 'Unknown';
const dummyLabels = { UNKNOWN };

const MAX_ALLOWED_SIZE = 45; //max allowed size in OppGen

const replacePlaceholders = (
  stringWithPlaceholders,
  currentUser,
  inquiry,
  warmTransfer,
  shouldEraseInfo,
) => {
  let newString = stringWithPlaceholders;

  Object.entries(inquiryFields).forEach(([placeholder, obj]) => {
    const regex = new RegExp(`{{${placeholder}}}`, 'g');
    newString = newString.replace(
      regex,
      obj.value(inquiry, dummyLabels, null, shouldEraseInfo),
    );
  });
  Object.entries(globalFields).forEach(([placeholder, obj]) => {
    const regex = new RegExp(`{{${placeholder}}}`, 'g');
    newString = newString.replace(regex, obj.value(currentUser, dummyLabels));
  });
  Object.entries(assignSlaFields).forEach(([placeholder, obj]) => {
    const regex = new RegExp(`{{${placeholder}}}`, 'g');
    newString = newString.replace(regex, obj.value(warmTransfer));
  });

  return newString;
};

const setSelectedAnswer = (step, inquiry) => {
  if (Object.keys(step.answer).length === 0) {
    step.answers.forEach((answer) => {
      if (
        answer.extraFields.selectedRule &&
        answer.extraFields.selectedRule.length > 0
      ) {
        const [inquiryKey, operation, value] = answer.extraFields.selectedRule;
        const [value1, value2] = value.split('-');
        const resultOperation = operations[operation].operation(
          inquiry[inquiryKey],
          value1,
          value2,
        );
        if (resultOperation) {
          step.answer.value = answer.value;
        }
      }
    });
  }
  return step;
};

const renderTextBoxes = (step, currentUser, inquiry, warmTransfer) => {
  return (
    <React.Fragment key={step.number}>
      {renderHTML(
        replacePlaceholders(
          step.question,
          currentUser,
          inquiry,
          warmTransfer,
          step.shouldEraseInfo,
        ),
      )}
    </React.Fragment>
  );
};

const renderMultipleChoice = (step, currentUser, inquiry, warmTransfer) => {
  return (
    <React.Fragment key={step.number}>
      {renderHTML(
        replacePlaceholders(
          step.question,
          currentUser,
          inquiry,
          warmTransfer,
          step.shouldEraseInfo,
        ),
      )}
    </React.Fragment>
  );
};

const renderLocationInfo = (
  step,
  labels,
  inquiry,
  onUserInputChange,
  loadLastLocationSearch,
) => {
  const preferredLocation = inquiryService.getInfo(
    inquiry,
    'preferredLocationObj',
    labels,
  );
  return (
    <React.Fragment key={step.number}>
      {
        <LocationInfo
          preferredLocation={preferredLocation}
          labels={labels}
          answer={step.answer}
          onChange={onUserInputChange}
          loadLastSearch={loadLastLocationSearch}
        />
      }
    </React.Fragment>
  );
};

const renderContactInfo = (step, inquiry, labels, onUserInputChange) => {
  const firstName = formChicletsService.getValidInputValue(
    step.shouldEraseInfo,
    inquiry.inquiryContact.firstName,
    step.answer,
    'firstName',
  );
  const lastName = formChicletsService.getValidInputValue(
    step.shouldEraseInfo,
    inquiry.inquiryContact.lastName,
    step.answer,
    'lastName',
  );
  const email = formChicletsService.getValidInputValue(
    false,
    inquiry.inquiryContact.email,
    step.answer,
    'email',
  );
  const phone = formChicletsService.getValidInputValue(
    false,
    inquiry.inquiryContact.phone,
    step.answer,
    'phone',
  );
  return (
    <React.Fragment key={step.number}>
      {
        <ContactInfo
          oneId={inquiry.inquiryContact.oneId}
          firstName={firstName}
          lastName={lastName}
          email={email}
          phone={phone}
          answer={step.answer}
          errors={step.errors}
          labels={labels}
          onChange={onUserInputChange}
        />
      }
    </React.Fragment>
  );
};

const renderReferralSource = (step, inquiry, labels, onUserInputChange) => {
  return (
    <ReferralSource
      key={step.number}
      answer={step.answer}
      labels={labels}
      onChange={onUserInputChange}
      inquiry={inquiry}
      errors={step.errors}
    />
  );
};

const renderEndCall = (step, currentUser, inquiry, classes) => {
  return (
    <div key={step.number} className={classes.body_call_end}>
      {renderMultipleChoice(step, currentUser, inquiry, {})}
      <div className={classes.container_call_end}>
        <div className={classes.call_end}>
          <EndCall />
        </div>
      </div>
    </div>
  );
};

const renderFamilyInfo = (step, inquiry, labels, onUserInputChange) => {
  return (
    <FamilyInfo
      key={step.number}
      inquiry={inquiry}
      answer={step.answer}
      labels={labels}
      onChange={onUserInputChange}
    />
  );
};

const renderContent = (
  step,
  currentUser,
  inquiry,
  labels,
  warmTransfer,
  onUserInputChange,
  classes,
  loadLastLocationSearch,
) => {
  switch (step.type) {
    case 'multipleChoice':
    case 'recordingDisclaimer':
    case 'transferSla':
    case 'slaMatch':
    case 'slaNotFound':
    case 'completeWarmTransfer':
    case 'completeWarmTransferFamilyDisconnected':
    case 'tryPreviousSla':
    case 'previousSlaAccepted':
    case 'previousSlaNotAccepted':
      return renderMultipleChoice(step, currentUser, inquiry, warmTransfer);
    case 'contactInfo':
      return renderContactInfo(step, inquiry, labels, onUserInputChange);
    case 'locationInfo':
      return renderLocationInfo(
        step,
        labels,
        inquiry,
        onUserInputChange,
        loadLastLocationSearch,
      );
    case 'endCall':
    case 'completeReschedule':
      return renderEndCall(step, currentUser, inquiry, classes);
    case 'familyInfo':
      return renderFamilyInfo(step, inquiry, labels, onUserInputChange);
    case 'referralSource':
      return renderReferralSource(step, inquiry, labels, onUserInputChange);
    case 'manyChoice':
      return renderMultipleChoice(step, currentUser, inquiry, {});
    case 'textBoxGroup':
      return renderTextBoxes(step, currentUser, inquiry);
    default:
      return (
        <div
          key={step.number}
        >{`rendering for ${step.type} not implemented`}</div>
      );
  }
};

const getLocationButtons = (step, onClick, testId) => {
  const orderedStepAnswers = cloneDeep(step.answers).sort((a, b) =>
    a.order > b.order ? 1 : -1,
  );
  const hasCommunities =
    step.answer.userInputValues && step.answer.userInputValues.hasCommunities;
  return (
    <ButtonLocation
      options={orderedStepAnswers}
      onClick={onClick}
      selected={step.answer}
      testId={testId}
      hasCommunities={hasCommunities}
    />
  );
};

const getDisabledFamilyInfoButton = (step, inquiry, labels) => {
  const defaultValues = {
    relationship: UNKNOWN,
    resident1FirstName: '',
    resident1LastName: '',
  };
  const familyRelationship = inquiryService.getInfo(
    inquiry,
    'relation',
    labels,
  );
  const existFamilyRelationship =
    familyRelationship && familyRelationship !== UNKNOWN;
  let userInputValues = step.answer.userInputValues;

  if (!userInputValues || JSON.stringify(userInputValues) === '{}') {
    userInputValues = defaultValues;
  }
  const {
    relationship,
    resident1FirstName: firstName,
    resident1LastName: lastName,
  } = userInputValues;

  const isValidRelationship = relationship !== UNKNOWN;
  const isValidFirstName = firstName
    ? firstName.length <= MAX_ALLOWED_SIZE
    : true;
  const isValidLastName = lastName ? lastName.length <= MAX_ALLOWED_SIZE : true;

  return !(
    existFamilyRelationship ||
    (isValidRelationship && isValidFirstName && isValidLastName)
  );
};

const Chiclet = ({
  step,
  index,
  currentUser,
  inquiry,
  onClick,
  labels,
  warmTransfer,
  onUserInputChange,
  onClose,
  onManyChoiceChange,
  onTextInputGroupSave,
  flags,
}) => {
  const classes = useStyles();
  const testId = `${index}. ${step.name}`;

  const manualInquiryState = useSelector(selectManualInquiryState);

  const [loadLastLocationSearch, setLoadLastLocationSearch] = useState(
    !manualInquiryState?.isManualInquiry,
  );

  const onLocationInfoClick = (option) => {
    onClick(option);
    setLoadLastLocationSearch(true);
  };

  const getActions = (step, onClick, testId, inquiry, labels) => {
    step = setSelectedAnswer(step, inquiry);
    switch (step.type) {
      case 'contactInfo':
        if (step.shouldEraseInfo) {
          step.answer.value = '';
          step.answers.forEach((answer) => {
            if (answer.label === labels.SAVED) {
              answer.label = labels.SAVE;
            }
          });
        }
        return (
          <ButtonGroup
            options={step.answers}
            disabled={step.state === CHICLET_CARD_STATES.INACTIVE.value}
            onClick={onClick}
            selected={step.answer}
            testId={testId}
          />
        );
      case 'multipleChoice':
      case 'recordingDisclaimer':
      case 'endCall':
      case 'transferSla':
      case 'slaMatch':
      case 'slaNotFound':
      case 'completeWarmTransfer':
      case 'completeReschedule':
      case 'completeWarmTransferFamilyDisconnected':
      case 'tryPreviousSla':
      case 'previousSlaAccepted':
      case 'previousSlaNotAccepted':
        return (
          <ButtonGroup
            options={step.answers}
            disabled={step.state === CHICLET_CARD_STATES.INACTIVE.value}
            onClick={onClick}
            selected={step.answer}
            testId={testId}
          />
        );
      case 'manyChoice':
        return (
          <CheckboxGroup
            options={step.answers}
            selected={step.answer}
            onManyChoiceChange={onManyChoiceChange}
          />
        );
      case 'familyInfo': {
        const isDisabledButton = getDisabledFamilyInfoButton(
          step,
          inquiry,
          labels,
        );
        return (
          <ButtonGroup
            options={step.answers}
            disabled={isDisabledButton}
            onClick={onClick}
            selected={step.answer}
            testId={testId}
          />
        );
      }
      case 'referralSource': {
        return (
          <ButtonGroup
            options={step.answers}
            onClick={onClick}
            selected={step.answer}
            testId={testId}
          />
        );
      }
      case 'locationInfo': {
        return getLocationButtons(step, onLocationInfoClick, testId);
      }
      case 'textBoxGroup': {
        return (
          <TextInputGroup
            options={step.answers}
            testId={testId}
            labels={labels}
            onTextInputGroupSave={onTextInputGroupSave}
          />
        );
      }
      default:
        return <div>{`actions for ${step.type} not implemented`}</div>;
    }
  };

  const renderErrors = () => {
    if (
      step.errors?.length > 0 &&
      step.type !== 'contactInfo' &&
      step.type !== 'referralSource'
    ) {
      return (
        <Typography color="hotCinnamon3" className={classes.error_step}>
          {step.errors.join('; ')}
        </Typography>
      );
    }
  };

  let isChicletDisabled = step.disabled;
  if (
    flags.allowDisabledChiclets &&
    inquiry.familyFileId &&
    !stepTypes[step.type]?.allowAfterFamilyFileCreated &&
    !step.disabled
  ) {
    isChicletDisabled = true;
  }

  return (
    <ChicletCard
      state={step.state}
      title={`${step.extraFields?.isOptional ? `` : `${index}.`} ${step.name}`}
      actions={getActions(step, onClick, testId, inquiry, labels)}
      optional={step.extraFields?.isOptional}
      onClose={() => onClose(step)}
      id={`${step.number}. ${step.name}`}
      span={step.extraFields?.span}
      hidden={step.hidden}
      testId={testId}
      collapsed={step.collapsed}
      labels={labels}
      disabled={isChicletDisabled}
    >
      {renderContent(
        step,
        currentUser,
        inquiry,
        labels,
        warmTransfer,
        onUserInputChange,
        classes,
        loadLastLocationSearch,
      )}
      {renderErrors()}
    </ChicletCard>
  );
};

Chiclet.defaultProps = {
  span: 1,
};

Chiclet.propTypes = {
  step: originalFormStepPropType.isRequired,
  index: PropTypes.number.isRequired,
  inquiry: inquiryPropType,
  onClick: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    __typename: PropTypes.string,
  }),
  labels: PropTypes.object,
  warmTransfer: PropTypes.object,
  onUserInputChange: PropTypes.func,
  onClose: PropTypes.func,
  onManyChoiceChange: PropTypes.func,
  onTextInputGroupSave: PropTypes.func,
  flags: PropTypes.object,
};

export default withLDConsumer()(Chiclet);
