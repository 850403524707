import React from 'react';
import PropTypes from 'prop-types';
import { getRemainingDaysForCurrentMonth } from '../../utils/dateUtils';
import Typography from '../../common/Typography/Typography';

const QuotaFooter = ({ points, quota, labels }) => {
  const remainingDays = getRemainingDaysForCurrentMonth();

  if (points >= quota) {
    return <Typography level="small">{labels.REACHED_QUOTA}</Typography>;
  } else {
    return (
      <Typography level="small">
        <b>{remainingDays}</b> {labels.REMAINING_QUOTA_DAYS}
      </Typography>
    );
  }
};

QuotaFooter.propTypes = {
  quota: PropTypes.number.isRequired,
  points: PropTypes.number.isRequired,
  labels: PropTypes.object.isRequired,
};

export default QuotaFooter;
