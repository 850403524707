import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './NotesDropdown.styles';
import clsx from 'clsx';
import Button from 'common/Button/Button';
import SquareButton from '../SquareButton/SquareButton';
import NoteText from 'common/Icons/file/NoteText';
import CircleChecked from 'common/Icons/basic/CircleChecked';
import Typography from 'common/Typography/Typography';
import { withLabels } from 'context/LabelContext';
import SidePanel from 'common/SidePanel/SidePanel';
import Divider from 'common/Divider/Divider';
import Alert from 'common/Alert/Alert';
import useGraphQLMutation from 'hooks/useGraphQLMutation';
import deleteNoteMutation from './deleteNoteMutation';
import createNoteMutation from './createNoteMutation';
import updateNoteMutation from './updateNoteMutation';
import {
  getMonthDayYearFormat,
  getHourAndMinutes12HourFormat,
} from 'utils/dateFormat';
import TrashEmpty from 'common/Icons/basic/TrashEmpty';

import {
  DialogActions,
  TextareaAutosize,
  InputLabel,
  IconButton,
} from '@material-ui/core';
import Container from 'common/Container/Container';
import ContainerItem from 'common/ContainerItem/ContainerItem';
import { logInquiryNoteModified } from 'services/callCenter/amplitudeEventsService';

const sortNotes = (inquiryNotes) => {
  const sortedNotes =
    inquiryNotes?.sort((a, b) => {
      return a.createdAt - b.createdAt;
    }) ?? [];

  return sortedNotes;
};
const NotesDropdown = ({ labels, className, inquiry }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const [isValid, setFormValid] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState({ isOpen: false, id: null });
  const [errorMessage, setErrorMessage] = useState('');
  const [inquiryNotes, setInquiryNotes] = useState(sortNotes(inquiry.notes)); // all notes
  const [currentNote, setCurrentNote] = useState({}); // the note being edited/added
  const [originalNote, setOriginalNote] = useState({});
  const noteRef = useRef(null);
  const [deleteNote] = useGraphQLMutation(deleteNoteMutation);
  const [createNote] = useGraphQLMutation(createNoteMutation);
  const [updateNote] = useGraphQLMutation(updateNoteMutation);

  const handleClose = () => {
    setIsOpen(false);
    resetNotesForm();
  };
  const resetNotesForm = () => {
    setCharCount(0);
    setCurrentNote({});
    if (noteRef.current) noteRef.current.value = '';
    setFormValid(false);
  };

  const handleNotesChange = (e) => {
    setCurrentNote({
      ...currentNote,
      ...{ note: e.currentTarget.value },
    });
    const countChars = e.target.value.length;
    setCharCount(countChars);
    if (countChars >= 140) {
      e.target.focus();
      return false;
    } else if (countChars > 0) {
      setFormValid(true);
    }
  };
  const handleEditNote = (noteObj) => {
    setOriginalNote(noteObj); // hold original note to add back to array on cancel
    setCurrentNote(noteObj);
    // update note arr for re-render
    let arr = inquiryNotes.filter(function (ele) {
      return ele.inquiryNoteId !== noteObj.inquiryNoteId;
    });
    setInquiryNotes(sortNotes(arr));
  };
  const cancelEditNote = () => {
    inquiryNotes.push(originalNote);
    setInquiryNotes(sortNotes([...inquiryNotes]));
    resetNotesForm();
  };
  const handleUpdate = async () => {
    try {
      let updatedNote = await updateNote({
        variables: {
          inquiryNoteId: Number(currentNote.inquiryNoteId),
          note: currentNote.note,
        },
      });
      if (updatedNote.error) {
        throw new Error('Error');
      } else {
        resetNotesForm();
        inquiryNotes.push(currentNote);
        setInquiryNotes(sortNotes([...inquiryNotes]));
      }
    } catch (err) {
      setErrorMessage('There was a problem updating the note.');
    }
  };
  const handleSave = async () => {
    if (currentNote.inquiryNoteId) {
      handleUpdate();
      logInquiryNoteModified(
        inquiry?.inquiryId,
        inquiry?.familyFileId,
        'edit note',
      );
      return;
    }
    try {
      let createdNote = await createNote({
        variables: {
          inquiryId: Number(inquiry.inquiryId),
          note: currentNote.note,
        },
      });
      // if !error set current note to empty
      resetNotesForm();
      inquiryNotes.push(createdNote.data.createInquiryNote);
      setInquiryNotes([...inquiryNotes]); //refreshes ui
      logInquiryNoteModified(inquiry?.inquiryId, inquiry?.familyFileId, 'save');
    } catch (err) {
      setErrorMessage('There was an error saving the note.');
    }
  };

  const handleDelete = async (noteId) => {
    logInquiryNoteModified(inquiry?.inquiryId, inquiry?.familyFileId, 'delete');

    try {
      await deleteNote({
        variables: {
          inquiryNoteId: Number(noteId),
        },
      });
      let arr = inquiryNotes.filter(function (ele) {
        return ele.inquiryNoteId !== noteId;
      });
      setInquiryNotes(arr);
      setIsDeleteOpen({ isOpen: false });
    } catch (err) {
      setIsDeleteOpen({ isOpen: false });
      setErrorMessage('Could not delete note.');
    }
  };

  const renderNote = (noteObj) => {
    return (
      <div className={clsx(classes.note)} key={noteObj.inquiryNoteId}>
        <Typography level="small" bold={true} color="eerieBlack1">
          {noteObj.createdBy.firstName} -{' '}
          {getMonthDayYearFormat(noteObj.createdAt)}
        </Typography>
        <Container className="note_text">
          <ContainerItem xs={10}>{noteObj.note}</ContainerItem>
          <ContainerItem className="right" xs={2}>
            <div className="time">
              {getHourAndMinutes12HourFormat(noteObj.createdAt)}
            </div>
            <IconButton
              onClick={() =>
                setIsDeleteOpen({ isOpen: true, id: noteObj.inquiryNoteId })
              }
              className="icon"
              size="small"
            >
              <TrashEmpty className={clsx(classes.delete)} />
            </IconButton>
          </ContainerItem>
        </Container>

        <DialogActions>
          <Button onClick={() => handleEditNote(noteObj)} type="outlined">
            {labels.EDIT_NOTE}
          </Button>
        </DialogActions>
        <Alert
          title={labels.DELETE}
          description={labels.CONFIRM_DELETE_NOTE}
          isOpen={isDeleteOpen.isOpen}
          onClose={() => setIsDeleteOpen({ isOpen: false })}
          type="delete"
          cancelText={labels.CANCEL}
          confirmText={labels.DELETE}
          onConfirm={() => handleDelete(isDeleteOpen.id)}
        />
      </div>
    );
  };
  //@note: for future ticket when note history is available
  const getAllNotes = () => {
    return inquiryNotes && inquiryNotes.map((noteObj) => renderNote(noteObj));
  };

  return (
    <>
      <SquareButton
        icon={<NoteText />}
        title={labels.SHOW_NOTES}
        onClick={() => setIsOpen(!isOpen)}
        isActive={isOpen}
        disabled={!isNaN(inquiry.familyFileId)}
      />
      <SidePanel
        isOpen={isOpen}
        position="right"
        onClose={() => handleClose()}
        backgroundColor="platinum4"
        belowHeader
        className={classes.side_panel}
      >
        <div className={classes.notes_panel}>
          <div className={classes.panel_header}>
            <Typography color="eerieBlack3" level="h4">
              {labels.SHOW_NOTES}
            </Typography>
          </div>

          <div className={classes.note_form}>
            <TextareaAutosize
              name="currentNote"
              onChange={handleNotesChange}
              className={clsx(className, classes.input)}
              rowsMin={6}
              maxLength="140"
              placeholder={'Write a note here ...'}
              value={currentNote.note}
              ref={noteRef}
              required
            />
            <InputLabel
              className={clsx(
                classes.label,
                charCount !== 0
                  ? charCount >= 140
                    ? 'danger'
                    : 'info'
                  : 'secondary',
              )}
            >
              {charCount}/140
              {charCount >= 140 ? ' - ' + labels.REACHED_MAX_CHARS : ''}
            </InputLabel>

            <DialogActions>
              {currentNote.inquiryNoteId && (
                <Button
                  type="light"
                  size="small"
                  onClick={() => cancelEditNote()}
                >
                  {labels.CANCEL}
                </Button>
              )}
              <Button
                startIcon={<CircleChecked />}
                type={isValid ? 'secondary' : 'disabled'}
                size="small"
                onClick={() => handleSave(currentNote)}
              >
                {labels.SAVE}
              </Button>
            </DialogActions>
            {errorMessage && (
              <div>
                <Typography color="smokyTopaz2" level="small" bold={true}>
                  {errorMessage}
                </Typography>
              </div>
            )}
          </div>
          <Divider />
          <div className="notes_history">{getAllNotes()}</div>
        </div>
      </SidePanel>
    </>
  );
};

NotesDropdown.propTypes = {
  isOpen: PropTypes.bool,
  labels: PropTypes.object,
  className: PropTypes.string,
  inquiry: PropTypes.object,
};

NotesDropdown.defaultProps = {
  isOpen: false,
  labels: { title: 'Show Notes' },
  inquiry: { notes: [] },
};

export default withLabels(NotesDropdown);
