import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withLabels } from '../../context/LabelContext';
import { useStyles } from './FlipEffect.style';
import { registerEvent } from '../../services/Analytics';

const FlipEffect = ({ frontComponent, backComponent }) => {
  const classes = useStyles();
  const [flipClass, setFlipClass] = useState(classes.components_container);

  const flipComponent = () => {
    const selectFlipClass = clsx([
      classes.components_container,
      flipClass.includes('flipped') ? '' : classes.flipped,
    ]);
    setFlipClass(selectFlipClass);
    registerEvent('Quota', 'Flipped Quota Card');
  };

  const withOnClick = (Component) => {
    const ComponentWithOnClick = React.cloneElement(Component, {
      onClick: () => flipComponent(),
    });
    return ComponentWithOnClick;
  };

  return (
    <Fragment>
      <div className={classes.flip_main_container}>
        <div className={flipClass}>
          <div className={`${classes.component} ${classes.front}`}>
            {withOnClick(frontComponent)}
          </div>
          <div className={`${classes.component} ${classes.back}`}>
            {withOnClick(backComponent)}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

FlipEffect.propTypes = {
  frontComponent: PropTypes.element.isRequired,
  backComponent: PropTypes.element.isRequired,
};

export default withLabels(FlipEffect);
export { FlipEffect };
