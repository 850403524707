import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import { useStyles } from './SidePanel.style';
import X from '../Icons/basic/X';
import { colorReference } from '../Theme/themes/default';

const SidePanel = ({
  position,
  isOpen,
  onClose,
  children,
  additionalToolbar,
  backgroundColor,
  width,
  xColor,
  belowHeader,
  variant,
  displayCloseIcon,
}) => {
  const dynamicStyles = {
    backgroundColor,
    width,
    xColor,
    belowHeader,
  };
  const classes = useStyles(dynamicStyles);

  return (
    <Drawer
      className={classes.side_panel_container}
      anchor={position}
      open={isOpen}
      onClose={onClose}
      variant={variant}
    >
      <div className={classes.toolbar}>
        {additionalToolbar}
        {displayCloseIcon && (
          <div onClick={onClose}>
            <X />
          </div>
        )}
      </div>
      {children}
    </Drawer>
  );
};

SidePanel.propTypes = {
  position: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.object,
  additionalToolbar: PropTypes.object,
  width: PropTypes.string,
  belowHeader: PropTypes.bool,
  xColor: PropTypes.oneOf(colorReference),
  backgroundColor: PropTypes.oneOf(colorReference),
  variant: PropTypes.oneOf(['permanent', 'persistent', 'temporary']),
  displayCloseIcon: PropTypes.bool,
};

SidePanel.defaultProps = {
  position: 'right',
  isOpen: false,
  width: '480',
  xColor: 'eerieBlack1',
  backgroundColor: 'platinum5',
  onClose: () => {},
  belowHeader: false,
  variant: 'temporary',
  displayCloseIcon: true,
};

export default SidePanel;
