import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import Dropdown from 'common/Dropdown/Dropdown';
import Switch from 'common/Switch/Switch';
import TextInput from 'common/TextInput/TextInput';
import Typography from 'common/Typography/Typography';
import stepService from 'services/callCenter/stepService';
import Quill from '../common/Quill/Quill';
import ScriptBuilderModal from '../common/ScriptBuilderModal/ScriptBuilderModal';
import { StepPropType, TypePropType } from '../scriptBuilderPropTypes';
import cloneObject from '../util/cloneObject';
import { useStyles } from './StepDialog.style';
const SIZES = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
];

const StepDialog = ({
  onClose,
  currentStep: initialStep,
  types,
  steps,
  onSaveStep,
  open,
  labels,
  properties,
}) => {
  const classes = useStyles();
  const [step, setStep] = useState({});
  const [stepType, setStepType] = useState('multipleChoice');
  const [errors, setErrors] = useState([]);
  const [stepProperty, setStepProperty] = useState('contactConfirmation');

  useEffect(() => {
    const foundType =
      types && initialStep
        ? types.find((t) => t.value === initialStep.type)
        : null;
    const foundProperty =
      properties && initialStep
        ? properties.find((p) => p.value === initialStep.property)
        : null;

    if (foundType !== 'multipleChoice') setStepType(foundType);
    if (foundProperty !== 'contactConfirmation') setStepProperty(foundProperty);

    let stepCopy = cloneObject(initialStep);

    if (!stepCopy) stepCopy = {};

    setStep(stepCopy);
    setErrors([]);
  }, [initialStep, types, properties]);

  if (!open) return null;

  const typesToShow = types.filter((t) => !t.hidden);

  const defaultNumber = steps ? steps.length + 1 : 1;

  const changeValue = (id, value) => {
    step[id] = value;
    setStep({ ...step });
    if (id === 'type') {
      setStepType(types.find((t) => t.value.toString() === value.toString()));
    } else if (id === 'property') {
      setStepProperty(
        properties.find((p) => p.value.toString() === value.toString()),
      );
    }
  };

  const onCloseStepDialog = () => {
    setStep({ ...initialStep });
    onClose();
  };

  const changeVisibility = () => {
    step.isHidden = !step.isHidden;
    setStep({ ...step });
  };

  const changeOptional = () => {
    step.isOptional = !step.isOptional;
    setStep({ ...step });
  };

  const changePipeda = () => {
    step.isPipeda = !step.isPipeda;
    setStep({ ...step });
  };

  const complete = () => {
    if (!step.name) return false;
    if ((step.type === 'multipleChoice' || !step.type) && !step.text) {
      return false;
    }
    if (
      (step.property === 'contactConfirmation' || !step.property) &&
      !step.text
    ) {
      return false;
    }
    return true;
  };

  const addStep = async () => {
    let hasErrors = false;
    let newErrors = [...errors];

    if (!complete()) {
      if (!newErrors.find((e) => e.key === 'incomplete')) {
        newErrors.push({
          key: 'incomplete',
          text: labels.STEP_INCOMPLETE,
        });
      }
      hasErrors = true;
    } else {
      newErrors = newErrors.filter((e) => e.key !== 'incomplete');
    }
    setErrors(newErrors);
    if (!hasErrors) {
      if (!step.order) {
        step.order = defaultNumber;
      } else {
        step.order = parseInt(step.order, 10);
      }
      if (!step.type) step.type = 'multipleChoice';
      if (!step.size) {
        step.size = 1;
      } else {
        step.size = parseInt(step.size, 10);
      }
      if (!step.text) step.text = '';
      if (step.isHidden === undefined) step.isHidden = false;
      if (step.isLegal === undefined) step.isLegal = false;
      if (step.isOptional === undefined) step.isOptional = false;
      if (step.isPipeda === undefined) step.isPipeda = false;
      if (!step.number) step.number = step.order;
      step.answers = stepService.createAnswersForStep(step, labels);
      onSaveStep(step);
    }
  };

  const getErrors = () => {
    if (errors.length === 0) return null;
    return (
      <div className={classes.errors}>
        {errors.map((e) => (
          <div key={e.key}>{e.text}</div>
        ))}
      </div>
    );
  };

  const getSelectedType = () => {
    if (!stepType) return null;
    const type = stepType.value || 'multipleChoice';
    return types.find((s) => s.value === type);
  };

  const getSelectedProperty = () => {
    if (!stepProperty) return null;
    const property = stepProperty.value || 'contactConfirmation';
    return properties.find((s) => s.value === property);
  };

  const getSelectedSize = () => {
    const size = step.size || '1';
    return SIZES.find((s) => s.value.toString() === size.toString());
  };

  const title = initialStep.stepId ? labels.EDIT_STEP : labels.NEW_STEP;
  const bodyComponent = (
    <Fragment>
      {getErrors()}
      <div className={classes.column}>
        <TextInput
          className={classes.input}
          id="order"
          label={labels.ORDER}
          variant="outlined"
          color="primary"
          name="order"
          value={step.order ? step.order.toString() : defaultNumber.toString()}
          onChange={(value) => changeValue('order', value)}
        />
        <TextInput
          className={classes.input}
          id="name"
          label={labels.NAME}
          variant="outlined"
          color="primary"
          name="name"
          value={step.name || ''}
          onChange={(value) => changeValue('name', value)}
        />
        <Dropdown
          items={typesToShow}
          label={labels.STEP_TYPE}
          selectedItem={getSelectedType()}
          onChange={(value) => changeValue('type', value.value)}
          disabled={getSelectedType()?.hidden}
        />
        <Typography>{stepType ? stepType.description : ''}</Typography>
        <Dropdown
          className={classes.size_dropdown}
          items={SIZES}
          label={labels.SIZE}
          selectedItem={getSelectedSize()}
          onChange={(value) => changeValue('size', value.value)}
        />
        <div>
          <Switch
            className={classes.hidden_switch}
            label={labels.STEP_HIDDEN}
            checked={step.isHidden || false}
            onChange={changeVisibility}
          />
          <Switch
            className={classes.hidden_switch}
            label={labels.STEP_OPTIONAL}
            checked={step.isOptional || false}
            onChange={changeOptional}
          />
          <Switch
            className={classes.hidden_switch}
            label={labels.STEP_PIPEDA}
            checked={step.isPipeda || false}
            onChange={changePipeda}
          />
        </div>
      </div>
      <div className={classes.column}>
        <Dropdown
          items={properties}
          label={labels.PROPERTY}
          selectedItem={getSelectedProperty()}
          onChange={(value) => changeValue('property', value.value)}
        />

        <Quill
          label={labels.TEXT}
          value={step.text || ''}
          onChange={(value) => changeValue('text', value)}
        />
      </div>
    </Fragment>
  );

  return (
    <ScriptBuilderModal
      title={title}
      iconComponent={null}
      bodyComponent={bodyComponent}
      onSave={addStep}
      onClose={onCloseStepDialog}
      open={open}
      labels={labels}
    />
  );
};

StepDialog.propTypes = {
  onClose: PropTypes.func,
  currentStep: StepPropType,
  types: PropTypes.arrayOf(TypePropType),
  steps: PropTypes.arrayOf(StepPropType),
  onSaveStep: PropTypes.func,
  open: PropTypes.bool,
  labels: PropTypes.object,
  properties: PropTypes.arrayOf(TypePropType),
};

export default StepDialog;
