import React from 'react';

const Walker = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5601 37.6472C30.1595 37.6472 36.3201 31.4866 36.3201 23.8872C36.3201 16.2878 30.1595 10.1272 22.5601 10.1272C14.9606 10.1272 8.80005 16.2878 8.80005 23.8872C8.80005 31.4866 14.9606 37.6472 22.5601 37.6472Z"
        fill="#F3F5FC"
      />
      <path
        d="M8.79991 10.1273C8.44647 10.1273 8.15991 9.84069 8.15991 9.48725V7.56725C8.15991 7.21381 8.44647 6.92725 8.79991 6.92725C9.15335 6.92725 9.43991 7.21381 9.43991 7.56725V9.48725C9.43991 9.84069 9.15335 10.1273 8.79991 10.1273Z"
        fill="#0D1321"
      />
      <path
        d="M8.79991 14.6072C8.44647 14.6072 8.15991 14.3207 8.15991 13.9672V12.0472C8.15991 11.6938 8.44647 11.4072 8.79991 11.4072C9.15335 11.4072 9.43991 11.6938 9.43991 12.0472V13.9672C9.43991 14.3207 9.15335 14.6072 8.79991 14.6072Z"
        fill="#0D1321"
      />
      <path
        d="M7.51996 11.4073H5.59996C5.24652 11.4073 4.95996 11.1208 4.95996 10.7673C4.95996 10.4139 5.24652 10.1273 5.59996 10.1273H7.51996C7.8734 10.1273 8.15997 10.4139 8.15997 10.7673C8.15997 11.1208 7.8734 11.4073 7.51996 11.4073Z"
        fill="#0D1321"
      />
      <path
        d="M11.9999 11.4073H10.0799C9.7265 11.4073 9.43994 11.1208 9.43994 10.7673C9.43994 10.4139 9.7265 10.1273 10.0799 10.1273H11.9999C12.3534 10.1273 12.6399 10.4139 12.6399 10.7673C12.6399 11.1208 12.3534 11.4073 11.9999 11.4073Z"
        fill="#0D1321"
      />
      <path
        d="M10.7201 22.2873C11.0735 22.2873 11.3601 22.0008 11.3601 21.6473C11.3601 21.2939 11.0735 21.0073 10.7201 21.0073C10.3666 21.0073 10.0801 21.2939 10.0801 21.6473C10.0801 22.0008 10.3666 22.2873 10.7201 22.2873Z"
        fill="#0D1321"
      />
      <path
        d="M40.16 19.0872C40.5135 19.0872 40.8 18.8007 40.8 18.4473C40.8 18.0938 40.5135 17.8073 40.16 17.8073C39.8066 17.8073 39.52 18.0938 39.52 18.4473C39.52 18.8007 39.8066 19.0872 40.16 19.0872Z"
        fill="#0D1321"
      />
      <path
        d="M36 13.3273C34.7648 13.3273 33.76 12.3225 33.76 11.0873C33.76 9.85209 34.7648 8.84729 36 8.84729C37.2352 8.84729 38.24 9.85209 38.24 11.0873C38.24 12.3225 37.2352 13.3273 36 13.3273ZM36 10.1273C35.4706 10.1273 35.04 10.5579 35.04 11.0873C35.04 11.6167 35.4706 12.0473 36 12.0473C36.5294 12.0473 36.96 11.6167 36.96 11.0873C36.96 10.5579 36.5294 10.1273 36 10.1273Z"
        fill="#0D1321"
      />
      <path
        d="M9.11988 34.4473C7.88468 34.4473 6.87988 33.4425 6.87988 32.2073C6.87988 30.9721 7.88468 29.9673 9.11988 29.9673C10.3551 29.9673 11.3599 30.9721 11.3599 32.2073C11.3599 33.4425 10.3551 34.4473 9.11988 34.4473ZM9.11988 31.2473C8.59044 31.2473 8.15988 31.6778 8.15988 32.2073C8.15988 32.7367 8.59044 33.1673 9.11988 33.1673C9.64932 33.1673 10.0799 32.7367 10.0799 32.2073C10.0799 31.6778 9.64932 31.2473 9.11988 31.2473Z"
        fill="#0D1321"
      />
      <path
        d="M18.3977 15.1746H20.2425C22.4062 15.1746 24.1601 16.9554 24.1601 19.152V26.1926H15.8401V17.7712C15.8401 16.3371 16.9852 15.1746 18.3977 15.1746Z"
        fill="#B5C3F1"
      />
      <path
        d="M24.16 26.8328H15.84C15.4865 26.8328 15.2 26.5462 15.2 26.1928V17.7712C15.2 15.9864 16.6345 14.5345 18.3976 14.5345H20.2424C22.7553 14.5345 24.7998 16.6059 24.7998 19.152V26.1926C24.8 26.5462 24.5134 26.8328 24.16 26.8328ZM16.48 25.5528H23.52V19.152C23.52 17.3117 22.0497 15.8145 20.2425 15.8145H18.3977C17.3404 15.8145 16.4801 16.6923 16.4801 17.7712V25.5528H16.48Z"
        fill="#0D1321"
      />
      <path
        d="M20.6033 20.1624L22.4407 22.3768C23.1452 23.2259 24.1582 23.7603 25.2567 23.8625L31.0241 24.3991C31.4563 24.4393 31.8091 24.059 31.7366 23.6311C31.6963 23.3932 31.5295 23.1963 31.3015 23.1172L25.4454 21.0876L23.2099 18.1059C22.5491 17.2246 21.2292 17.219 20.5611 18.0947C20.092 18.7096 20.1094 19.567 20.6033 20.1624Z"
        fill="#F3E0D4"
      />
      <path
        d="M31.0882 25.0422C31.0473 25.0422 31.0062 25.0402 30.9649 25.0364L25.1977 24.4998C23.921 24.381 22.767 23.7722 21.9482 22.7855L20.111 20.5713C19.4225 19.7415 19.3986 18.5636 20.0526 17.7066C20.4916 17.1311 21.1577 16.8015 21.8812 16.8015H21.8913C22.6186 16.8046 23.286 17.1401 23.722 17.7222L25.8401 20.5471L31.5111 22.5127C31.9601 22.6682 32.2881 23.0559 32.3676 23.5244C32.4358 23.926 32.3159 24.3276 32.0388 24.6262C31.79 24.8942 31.4489 25.0422 31.0882 25.0422ZM21.0959 19.7538L22.9332 21.9681C23.5337 22.6916 24.3798 23.1382 25.3161 23.2252L31.0834 23.7618C31.0884 23.7623 31.0935 23.7628 31.1004 23.7553C31.1073 23.7479 31.1065 23.7426 31.1057 23.738C31.1042 23.7305 31.0991 23.7244 31.0921 23.7218L25.2359 21.6921C25.1153 21.6503 25.01 21.5734 24.9334 21.4713L22.6977 18.4895C22.5041 18.2313 22.2081 18.0825 21.8855 18.0812C21.8839 18.0812 21.8825 18.0812 21.881 18.0812C21.5601 18.0812 21.2646 18.2274 21.0698 18.4826C20.7799 18.8633 20.7905 19.3858 21.0959 19.7538Z"
        fill="#0D1321"
      />
      <path
        d="M17.1447 26.327L17.6554 33.0669C17.739 34.1691 17.6111 35.272 17.2796 36.3265C16.4969 38.8166 15.0327 42.7672 15.0327 42.7672H20.9599L18.3999 40.2072C19.6799 38.2872 20.909 36.0387 20.909 34.3822V30.5184L17.1447 26.327Z"
        fill="#F7EED0"
      />
      <path
        d="M20.96 43.4071H15.0326C14.823 43.4071 14.627 43.3046 14.5073 43.1327C14.3877 42.9609 14.3597 42.7412 14.4325 42.5447C14.4472 42.5054 15.9001 38.5804 16.6689 36.1347C16.9776 35.1534 17.0947 34.1375 17.0174 33.115L16.5067 26.3751C16.4859 26.1019 16.6414 25.8459 16.8934 25.7383C17.1454 25.6308 17.4379 25.6953 17.6209 25.8991L21.3853 30.0905C21.4909 30.2079 21.5491 30.3603 21.5491 30.5182V34.382C21.5491 35.8142 20.7865 37.6955 19.2208 40.1225L21.4125 42.3143C21.5957 42.4974 21.6502 42.7727 21.5512 43.0118C21.4521 43.2508 21.2189 43.4071 20.96 43.4071ZM15.9489 42.1271H19.4149L17.9475 40.6598C17.7317 40.4439 17.6982 40.106 17.8675 39.8523C19.8523 36.8748 20.2691 35.2343 20.2691 34.3823V30.7636L17.9251 28.1535L18.2937 33.0185C18.3837 34.2039 18.2478 35.3815 17.8902 36.5185C17.3334 38.2895 16.4261 40.8166 15.9489 42.1271Z"
        fill="#0D1321"
      />
      <path
        d="M30.5599 43.4072C30.2065 43.4072 29.9199 43.1207 29.9199 42.7672V27.7455C29.9199 24.7357 32.3684 22.2872 35.378 22.2872C38.3876 22.2872 40.8362 24.7357 40.8362 27.7455V38.2469C40.8362 38.6003 40.5497 38.8869 40.1962 38.8869C39.8428 38.8869 39.5562 38.6003 39.5562 38.2469V27.7455C39.5562 25.4416 37.6818 23.5672 35.378 23.5672C33.0743 23.5672 31.1999 25.4416 31.1999 27.7455V42.7672C31.1999 43.1207 30.9134 43.4072 30.5599 43.4072Z"
        fill="#0D1321"
      />
      <path
        d="M40.1964 42.9928C41.4134 42.9928 42.4 42.0062 42.4 40.7891C42.4 39.5721 41.4134 38.5854 40.1964 38.5854C38.9793 38.5854 37.9927 39.5721 37.9927 40.7891C37.9927 42.0062 38.9793 42.9928 40.1964 42.9928Z"
        fill="#DDE3F8"
      />
      <path
        d="M40.1965 43.6329C38.6283 43.6329 37.3528 42.3572 37.3528 40.7892C37.3528 39.2212 38.6285 37.9457 40.1965 37.9457C41.7645 37.9457 43.04 39.2214 43.04 40.7892C43.04 42.357 41.7643 43.6329 40.1965 43.6329ZM40.1965 39.2255C39.3342 39.2255 38.6328 39.927 38.6328 40.789C38.6328 41.6511 39.3344 42.3527 40.1965 42.3527C41.0585 42.3527 41.76 41.6513 41.76 40.7892C41.76 39.9271 41.0585 39.2255 40.1965 39.2255Z"
        fill="#0D1321"
      />
      <path
        d="M40.1962 41.4619C40.5678 41.4619 40.869 41.1607 40.869 40.7891C40.869 40.4176 40.5678 40.1163 40.1962 40.1163C39.8247 40.1163 39.5234 40.4176 39.5234 40.7891C39.5234 41.1607 39.8247 41.4619 40.1962 41.4619Z"
        fill="#0D1321"
      />
      <path
        d="M40.1599 31.8872H30.5599C30.2065 31.8872 29.9199 31.6006 29.9199 31.2472C29.9199 30.8937 30.2065 30.6072 30.5599 30.6072H40.1599C40.5134 30.6072 40.7999 30.8937 40.7999 31.2472C40.7999 31.6006 40.5134 31.8872 40.1599 31.8872Z"
        fill="#0D1321"
      />
      <path
        d="M40.1599 35.7273H30.5599C30.2065 35.7273 29.9199 35.4407 29.9199 35.0873C29.9199 34.7338 30.2065 34.4473 30.5599 34.4473H40.1599C40.5134 34.4473 40.7999 34.7338 40.7999 35.0873C40.7999 35.4407 40.5134 35.7273 40.1599 35.7273Z"
        fill="#0D1321"
      />
      <path
        d="M17.9707 10.1272H20.9598V15.1744H17.9707V10.1272Z"
        fill="#F3E0D4"
      />
      <path
        d="M20.9599 15.8145H17.9708C17.6174 15.8145 17.3308 15.528 17.3308 15.1745V10.1272C17.3308 9.77374 17.6174 9.48718 17.9708 9.48718H20.9599C21.3134 9.48718 21.5999 9.77374 21.5999 10.1272V15.1744C21.5999 15.528 21.3134 15.8145 20.9599 15.8145ZM18.6108 14.5345H20.3199V10.7672H18.6108V14.5345Z"
        fill="#0D1321"
      />
      <path
        d="M19.9128 12.3672H18.6443C17.8025 12.3672 17.1201 11.6848 17.1201 10.843V8.8472C17.1201 6.7264 18.8393 5.0072 20.9601 5.0072C23.0809 5.0072 24.8001 6.7264 24.8001 8.8472V14.6072H23.5334C22.5513 14.6072 21.6134 14.1982 20.9451 13.4787L19.9128 12.3672Z"
        fill="#F3E0D4"
      />
      <path
        d="M24.8 15.2472H23.5333C22.3769 15.2472 21.2629 14.7613 20.4761 13.9142L19.6336 13.0072H18.6441C17.4509 13.0072 16.48 12.0363 16.48 10.843V8.84718C16.48 6.37695 18.4897 4.36719 20.96 4.36719C23.4302 4.36719 25.44 6.37695 25.44 8.84718V14.6072C25.44 14.9606 25.1534 15.2472 24.8 15.2472ZM20.96 5.64719C19.1955 5.64719 17.76 7.08271 17.76 8.84718V10.843C17.76 11.3305 18.1566 11.7272 18.6441 11.7272H19.9126C20.0905 11.7272 20.2605 11.8013 20.3816 11.9317L21.4139 13.0432C21.9593 13.6304 22.7317 13.9672 23.5333 13.9672H24.16V8.84718C24.16 7.08287 22.7245 5.64719 20.96 5.64719Z"
        fill="#0D1321"
      />
      <path
        d="M15.8401 26.1927C15.8401 28.4298 17.059 30.5048 19.0551 31.6658L20.4634 32.4848C21.4393 33.0523 22.0351 34.0667 22.0351 35.1603V42.7671H28.3492L25.1327 39.8845L25.7329 34.2992C25.8482 33.2261 25.779 32.1418 25.5281 31.0909L24.3583 26.1925H15.8401V26.1927Z"
        fill="#F7EED0"
      />
      <path
        d="M28.3491 43.4071H22.035C21.6815 43.4071 21.395 43.1206 21.395 42.7671V35.1604C21.395 34.3009 20.9147 33.4878 20.1415 33.038L18.7334 32.219C16.5539 30.9515 15.2 28.6423 15.2 26.1927C15.2 25.8393 15.4865 25.5527 15.84 25.5527H24.3582C24.6545 25.5527 24.9119 25.7561 24.9807 26.0441L26.1505 30.9425C26.419 32.067 26.4926 33.2193 26.3692 34.3678L25.8041 39.6271L28.7763 42.2907C28.9737 42.4676 29.0415 42.7483 28.9468 42.9959C28.8519 43.2436 28.6143 43.4071 28.3491 43.4071ZM22.675 42.1271H26.6759L24.7055 40.3612C24.5523 40.2238 24.4745 40.0209 24.4963 39.8163L25.0964 34.2308C25.2043 33.2279 25.1399 32.2215 24.9055 31.2396L23.8532 26.8326H16.5182C16.7271 28.5843 17.7825 30.1852 19.3769 31.1126L20.7851 31.9316C21.9508 32.6095 22.675 33.8468 22.675 35.1604V42.1271Z"
        fill="#0D1321"
      />
    </svg>
  );
};

export default Walker;
