import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  contact_info_container: {
    'text-align': 'center',
    color: theme.palette.text,
  },
  delete_icon: {
    cursor: 'pointer',
    '& svg': {
      verticalAlign: 'middle',
    },
  },
  default_input: {
    width: '100%',
    marginBottom: '10px',
  },
  last_item: {
    width: '100%',
    marginBottom: '-10px',
  },
  names_container: {
    display: 'flex',
    justifyContent: 'space-between',
    '& div:first-child ': {
      marginRight: '6px',
    },
  },
  mandatory: {
    color: theme.palette.correction.smokyTopaz2,
    marginRight: 4,
  },
  label: {
    fontSize: '13px',
    lineHeight: '19px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: theme.palette.dark.eerieBlack5,
    marginBottom: '4px',
    textAlign: 'left',
  },
  error: {
    '& $label': {
      color: theme.palette.correction.smokyTopaz2,
    },
  },
  name: {
    color: theme.palette.dark.eerieBlack1,
  },
}));

export { useStyles };
