import React from 'react';
import { useStyles } from './MetricBody.style';
import Neutral from 'common/Icons/reactions/Neutral';
import Typography from 'common/Typography/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isSameSize from 'utils/sizesHelper';

const AlmostAtGoal = ({
  percentageAccomplished,
  toReachTheGoal,
  size,
  labels,
}) => {
  const classes = useStyles();
  let useSMDownClass = isSameSize(size, 'xs') || isSameSize(size, 'sm');

  const selectClasses = clsx([
    classes.goal_container,
    useSMDownClass ? classes.goal_container_bellow_small_size : '',
  ]);

  return (
    <div className={selectClasses}>
      <div className={classes.percentage_row_wrapper}>
        <Typography color="fieldDrab2" level="h4">
          <Neutral />
        </Typography>
        <Typography level="h2">
          {percentageAccomplished}
          {labels.PERCENTAGE}
        </Typography>
        <Typography color="fieldDrab2">
          <label className={classes.current_message}>
            (+{toReachTheGoal}%) {labels.ALMOST_THERE}
          </label>
        </Typography>
      </div>
    </div>
  );
};

AlmostAtGoal.propTypes = {
  percentageAccomplished: PropTypes.number,
  toReachTheGoal: PropTypes.number,
  size: PropTypes.string,
  labels: PropTypes.object,
};

export default AlmostAtGoal;
