import React from 'react';
import Card from 'common/Card/Card';
import Case from 'common/Icons/basic/Case';
import Keynote from 'common/Graphics/Keynote';
import Button from 'common/Button/Button';
import { useStyles } from './KeyNoteDefaultPage.style';
import Typography from 'common/Typography/Typography';
import PropTypes from 'prop-types';

const KeyNoteDefaultPage = ({ labels, toggleKeyNoteDefaultPage }) => {
  const classes = useStyles();

  return (
    <Card title={labels.OVERVIEW} icon={<Case />}>
      <div className={classes.icon}>
        <Keynote />
        <Typography level="h4">{labels.EMPTY_KEYNOTE_HEADER}</Typography>
        <div className={classes.empty_keynote}>
          <Typography>{labels.OVERVIEW_EMPTY_KEYNOTE_DESCRIPTION}</Typography>
        </div>
        <Button
          className={classes.overview_key_note_button}
          onClick={() => toggleKeyNoteDefaultPage(false)}
        >
          Add a Keynote
        </Button>
      </div>
    </Card>
  );
};

KeyNoteDefaultPage.propTypes = {
  labels: PropTypes.object.isRequired,
  toggleKeyNoteDefaultPage: PropTypes.func.isRequired,
};

export default KeyNoteDefaultPage;
