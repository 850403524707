import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../common/Typography/Typography';
import Container from '../../common/Container/Container';
import ContainerItem from '../../common/ContainerItem/ContainerItem';
import Flag from '../../common/Icons/basic/Flag';
import QuotaFooter from '../Quota/QuotaFooter';

import { useStyles } from './SurpassingQuota.style';

const SurpassingQuota = ({ quota, points, onClick, labels }) => {
  const rightLimit = quota * 2;
  let displayPointsInBar;

  if (points - quota <= 20) {
    displayPointsInBar = quota + 20;
  } else {
    displayPointsInBar = points;
  }

  const progress = Math.min((quota / rightLimit) * 100.0, 100.0) || 0;
  const mtd_bar_progress =
    Math.min((displayPointsInBar / rightLimit) * 100.0, 100.0) || 0;

  const classes = useStyles(progress, mtd_bar_progress);

  return (
    <div className={classes.surpassing_quota_container}>
      <Container>
        <ContainerItem className={classes.month_to_date} xs={4}>
          <Typography level="small">
            {`${labels.MONTHS[new Date(Date.now()).getMonth()]} ${new Date(
              Date.now(),
            ).getFullYear()}`}
          </Typography>
        </ContainerItem>
        <ContainerItem className={classes.quota_header_title} xs={8}>
          <Typography>
            <b>{labels.POINTS_THIS_MONTH}</b>
          </Typography>
        </ContainerItem>
      </Container>
      <div className={classes.clickable_container} onClick={onClick}>
        <div className={classes.rate}>
          <Typography level="h2" color="cosmicCobalt1">
            {points}
          </Typography>
        </div>
        <div className={classes.bar_container}>
          <div className={classes.bar_empty} />
          <div className={classes.bar_filled} />
          <div className={classes.line} />
          <div className={classes.mtd_bar_filled} />
          <div className={classes.mtd_label}>
            <Typography level="tiny" color="cosmicCobalt1">
              {labels.MTD}
            </Typography>{' '}
          </div>
          <div className={classes.mtd_line} />
        </div>
        <div className={classes.label_container}>
          <div className={classes.start_label}>
            <Typography level="small" color="eerieBlack1">
              0
            </Typography>
          </div>
          <div className={classes.quota_container}>
            <Typography level="small" color={'eerieBlack1'}>
              {quota}
            </Typography>
          </div>
          <div className={classes.quota_flag_container}>
            <Typography>
              <Flag />
            </Typography>
          </div>
          <div className={classes.end_label}>
            <Typography level="small" color="eerieBlack1">
              {rightLimit}
            </Typography>
          </div>
        </div>
        <Container container className={classes.quotas_footer_container}>
          <ContainerItem className={classes.quotas_footer}>
            <QuotaFooter points={points} quota={quota} labels={labels} />
          </ContainerItem>
        </Container>
      </div>
    </div>
  );
};

SurpassingQuota.propTypes = {
  quota: PropTypes.number.isRequired,
  points: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  labels: PropTypes.shape({
    MONTHS: PropTypes.array.isRequired,
    POINTS_THIS_MONTH: PropTypes.string.isRequired,
    MTD: PropTypes.string.isRequired,
  }).isRequired,
};

export default SurpassingQuota;
