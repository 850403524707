import gql from 'graphql-tag';

export default gql`
  query(
    $filterType: FilterType!
    $filterValue: String!
    $pageNumber: Int
    $pageSize: Int
  ) {
    search(
      params: {
        pageSize: $pageSize
        pageNumber: $pageNumber
        filterType: $filterType
        filterValue: $filterValue
      }
    ) {
      pagination {
        pageSize
        pageCount
        elementsCount
      }
      results {
        id
        resident {
          name
        }
        preferredLocations {
          city
          state
        }
        primaryContact {
          name
          email
          relation
          cellPhone
          homePhone
        }
        audienceKey
        tags
        lastContacted {
          date
          type
          result
        }
      }
    }
  }
`;
