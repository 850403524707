import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Alert from 'common/Alert/Alert';
import Button from 'common/Button/Button';
import Home from 'common/Icons/basic/Home';
import Typography from 'common/Typography/Typography';
import { withLabels } from 'context/LabelContext';
import useGraphQLMutation from 'hooks/useGraphQLMutation';
import manualInquiryService from 'services/callCenter/manualInquiryService';
import { selectCatalogs } from 'stateManagement/callcenter/catalogs/reducers/catalogsReducer';
import {
  getFormChicletsRequest,
  getManualInquiryState,
} from 'stateManagement/callcenter/formChiclets/actions/formChicletsActions';
import { selectFormChiclets } from 'stateManagement/callcenter/formChiclets/reducers/formChicletsReducer';
import { updateLatestInquiryId } from 'stateManagement/callcenter/inquiry/actions/inquiryActions';
import createInquiryMutation from 'stateManagement/callcenter/inquiry/graphql/createInquiryManualMutation';
import { selectInquiry } from 'stateManagement/callcenter/inquiry/reducers/inquiryReducer';
import FormChiclets from '../FormChiclets/FormChiclets';
import deleteInquiryContactMutation from './deleteInquiryContactMutation';
import { useStyles } from './ManualInquiry.style';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';

const ManualInquiry = ({ labels }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const { catalogs } = useSelector(selectCatalogs);
  const { formChiclets, formSteps } = useSelector(selectFormChiclets);
  const { inquiry } = useSelector(selectInquiry);
  const [showCancelAlert, setShowCancelAlert] = useState(false);

  const [createInquiry] = useGraphQLMutation(createInquiryMutation);
  const [deleteInquiryContact] = useGraphQLMutation(
    deleteInquiryContactMutation,
  );

  useEffect(() => {
    if (catalogs) {
      dispatch(getFormChicletsRequest());
    }
    dispatch(
      getManualInquiryState({
        isManualInquiry: true,
        isCreatedManualInquiry: false,
      }),
    );
  }, [dispatch, catalogs]);

  const showChiclets = () => {
    if (formSteps?.length > 0) {
      return <FormChiclets />;
    }
  };

  const createNewInquiry = async () => {
    try {
      const allChiclets = cloneDeep(formSteps);
      const newInquiryManualObject = manualInquiryService.buildManualInquiry(
        allChiclets,
        formChiclets,
      );

      const response = await createInquiry({
        variables: newInquiryManualObject,
      });
      if (response) {
        dispatch(
          getManualInquiryState({
            isManualInquiry: false,
            isCreatedManualInquiry: true,
          }),
        );
        dispatch(updateLatestInquiryId(response.data.createInquiry.inquiryId));
      }
    } catch (ex) {
      //todo: the result of this error really should be surfaced somehow.
      console.error(ex.message);
    }
    history.push(`/callcenter/home`);
  };

  const onConfirmDelete = async () => {
    if (
      inquiry?.inquiryContact?.oneId &&
      inquiry?.inquiryContact?.inquiryContactId
    ) {
      await deleteInquiryContact({
        variables: {
          oneId: inquiry.inquiryContact.oneId,
          inquiyContactId: inquiry.inquiryContact.inquiryContactId,
        },
      });
    }
    history.push(`/callcenter/home`);
  };

  const itemsMenu = [
    {
      icon: <Home />,
      label: labels.DASHBOARD,
      to: '/callcenter/home',
    },
    {
      label: labels.MANUAL_INQUIRY,
      to: '#',
    },
  ];

  const getSaveButtonType = () => {
    const requiredSteps =
      formSteps?.filter(
        (s) =>
          s.extraFields?.isOptional === false &&
          s.extraFields?.hidden === false,
      ) || [];
    let type = !formChiclets ? 'disabled' : 'primary';

    requiredSteps.forEach((requiredStep) => {
      if (!requiredStep.answered || requiredStep.errors?.length > 0) {
        type = 'disabled';
      }
    });
    return type;
  };

  return (
    <div className={classes.body}>
      {showCancelAlert && (
        <Alert
          onClose={() => setShowCancelAlert(false)}
          onConfirm={onConfirmDelete}
          title={labels.CANCEL_MANUAL_INQUIRY}
          description={labels.CANCEL_MANUAL_INQUIRY_DESCRIPTION}
          type="delete"
          isOpen={showCancelAlert}
          cancelText={labels.GO_BACK}
          confirmText={labels.YES_CANCEL_INQUIRY}
        />
      )}
      <div className={classes.dashboard_container}>
        <Breadcrumb separator="/" items={itemsMenu} />
      </div>
      <Typography className={classes.title} bold>
        {labels.MANUAL_INQUIRY}
      </Typography>
      <Typography className={classes.subtitle} color="eerieBlack5">
        {labels.SELECT_AND_FILL_RELEVENT_FIELDS}
      </Typography>
      <div className={classes.center_container}>{showChiclets()}</div>
      <div className={classes.button_group}>
        <Button
          type="outlined"
          onClick={() => setShowCancelAlert(true)}
          className={classes.outllined_button}
        >
          {labels.CANCEL}
        </Button>
        <Button type={getSaveButtonType()} onClick={() => createNewInquiry()}>
          {labels.CREATE_NEW_INQUIRY}
        </Button>
      </div>
    </div>
  );
};
ManualInquiry.propTypes = {
  labels: PropTypes.object,
};
export default withLabels(ManualInquiry);
