import gql from 'graphql-tag';

export default gql`
  mutation(
    $scriptId: Int
    $slug: String!
    $name: String!
    $description: String
    $target: String
    $type: String
    $versionName: String
    $status: ScriptStatusEnum!
  ) {
    saveScript(
      input: {
        scriptId: $scriptId
        slug: $slug
        name: $name
        description: $description
        target: $target
        type: $type
        versionName: $versionName
        status: $status
      }
    ) {
      scriptId
      slug
      version
    }
  }
`;
