import { makeStyles } from '../../Theme/Theme';

const useStyles = makeStyles((theme) => ({
  budget_filter_container: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginLeft: '24px',
    '& svg': {
      verticalAlign: 'middle',
    },
  },
  select: {
    minWidth: '200px',
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.light.platinum1}`,
    backgroundColor: theme.palette.light.white,
    borderRadius: '2px',
    padding: '8px 16px',
    cursor: 'pointer',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'left',
    color: theme.palette.dark.eerieBlack5,
    fontSize: '15px',
    display: 'flex',
    lineHeight: '22px',
    '&:focus': {
      border: `1px solid ${theme.palette.neutral.cosmicCobalt3}`,
      outline: 'none',
    },
    '&:hover': {
      border: `1px solid ${theme.palette.neutral.cosmicCobalt3}`,
      outline: 'none',
    },
  },
  filled: {
    '& $select': {
      border: `1px solid ${theme.palette.neutral.cosmicCobalt2}`,
    },
    '& $label': {
      color: theme.palette.dark.eerieBlack1,
    },
  },
  label: {
    fontSize: '13px',
    lineHeight: '19px',
    marginBottom: '4px',
    color: theme.palette.dark.eerieBlack5,
    textAlign: 'left',
  },
  price_over: {
    display: 'flex',
    marginTop: theme.spacing(1.5),
  },
  price_over_radio: {
    '& > div > label': {
      marginRight: '0',
    },
  },
  price_over_label: {
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    whiteSpace: 'pre-wrap',
    '& p::after': {
      content: `' '`,
    },
  },
  menu: {
    position: 'relative',
    width: '100%',
  },
  items_container: {
    position: 'absolute',
    top: 0,
    padding: '10px 16px',
    left: 0,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.neutral.cosmicCobalt5}`,
    backgroundColor: theme.palette.light.white,
    textAlign: 'left',
    width: '390px',
    [theme.breakpoints.down(1024)]: {
      width: 'max-content',
    },
  },
  slider: {
    marginLeft: '32px',
    padding: '0',
  },
  inputs_container: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '32px',
  },
  input: {
    width: '120px',
    '& input': {
      textAlign: 'right',
      color: theme.palette.dark.eerieBlack1,
    },
  },
  divider: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  actions_container: {
    padding: '0 8px 8px 0',
  },
  actions: {
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export { useStyles };
