import gql from 'graphql-tag';

export default gql`
  mutation($familyCommunityTourId: Int!) {
    deleteScheduledTour(
      input: { familyCommunityTourId: $familyCommunityTourId }
    ) {
      familyFileId
      familyCommunityTourId
      communityId
    }
  }
`;
