import { inquiryFields } from './fieldsMapper';

const getInfo = (inquiry, key, labels, catalog) => {
  const unknownText = labels?.UNKNOWN;
  if (!inquiry) return unknownText;
  if (!inquiryFields[key]) return unknownText;
  return inquiryFields[key].value(inquiry, labels, catalog);
};

export default {
  getInfo,
};
