import React from 'react';
import PropTypes from 'prop-types';
import CaseProfile from 'components/CaseProfile/CaseProfile';

const CenterPanel = ({ labels, familyFileId, activeTab, size }) => {
  return (
    <CaseProfile
      activeTab={activeTab}
      familyFileId={familyFileId}
      labels={labels}
      size={size}
      showCommunitiesTab={true}
    />
  );
};

CenterPanel.propTypes = {
  labels: PropTypes.object,
  familyFileId: PropTypes.number.isRequired,
  activeTab: PropTypes.number,
  size: PropTypes.string,
};

CenterPanel.defaultProps = {
  activeTab: 0,
};

export default CenterPanel;
