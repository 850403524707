import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from './Alert.style';
import MaterialModal from '@material-ui/core/Modal';
import Overlay from '../Overlay/Overlay';
import X from '../Icons/basic/X';
import AlertTriangle from '../Icons/notifications/AlertTriangle';
import Info from '../Icons/notifications/Info';
import CircleChecked from '../Icons/basic/CircleChecked';
import CircleX from '../Icons/basic/CircleX';
import Typography from '../Typography/Typography';
import Button from '../Button/Button';

const Alert = ({
  cancelText,
  className,
  confirmText,
  description,
  isOpen,
  modalBody,
  onClose,
  onConfirm,
  showCloseButton,
  title,
  type,
  descriptionObj,
}) => {
  const classes = useStyles();

  const getIcon = () => {
    switch (type) {
      case 'delete':
        return <AlertTriangle />;
      case 'success':
        return <CircleChecked />;
      case 'error':
        return <CircleX />;
      case 'informative':
        return <Info />;
      default:
        return '';
    }
  };

  const buttonsContainerClass = clsx(
    classes.buttons_container,
    cancelText ? '' : classes.single_button_container,
  );
  const confirmButtonClass = clsx(cancelText ? '' : classes.confirm_button);

  return (
    <MaterialModal open={isOpen} onClose={onClose} BackdropComponent={Overlay}>
      <div className={clsx(classes.alert_container, className)}>
        <div
          className={clsx(
            classes.body,
            cancelText || confirmText ? '' : classes.body_no_buttons,
          )}
        >
          <div className={classes.title_container}>
            <div className={clsx(classes.title, classes[type])}>
              {getIcon()}
              <Typography level="large" bold>
                {title}
              </Typography>
            </div>
            {showCloseButton && (
              <div className={classes.close_icon} onClick={onClose}>
                <X />
              </div>
            )}
          </div>

          <div className={classes.description}>
            {modalBody && modalBody}
            {description && !descriptionObj && (
              <Typography color="eerieBlack5">{description}</Typography>
            )}
            {descriptionObj && !description && <div>{descriptionObj}</div>}
          </div>
          <div className={buttonsContainerClass}>
            {cancelText && (
              <Button type="light" onClick={onClose}>
                {cancelText}
              </Button>
            )}
            {confirmText && (
              <Button
                type={type === 'delete' ? 'warning' : 'primary'}
                onClick={onConfirm}
                className={confirmButtonClass}
              >
                {confirmText}
              </Button>
            )}
          </div>
        </div>
      </div>
    </MaterialModal>
  );
};

Alert.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  type: PropTypes.oneOf([
    'confirm',
    'delete',
    'success',
    'error',
    'informative',
  ]),
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  modalBody: PropTypes.element,
  showCloseButton: PropTypes.bool,
  descriptionObj: PropTypes.node,
};

Alert.defaultProps = {
  className: '',
  description: '',
  onClose: () => {},
  onConfirm: () => {},
  showCloseButton: true,
  type: 'success',
  descriptionObj: undefined,
};

export default Alert;
