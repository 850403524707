import {
  GET_ADMIN_CATALOGS_ERROR,
  GET_ADMIN_CATALOGS_REQUEST,
  GET_ADMIN_CATALOGS_SUCCESS,
} from '../actions/adminCatalogsActions';

const adminCatalogsReducer = (
  state = { loading: false, error: null, data: null },
  action,
) => {
  switch (action.type) {
    case GET_ADMIN_CATALOGS_REQUEST:
      return { loading: true, error: null, data: null };
    case GET_ADMIN_CATALOGS_SUCCESS:
      return {
        loading: false,
        error: null,
        data: action.payload.response,
      };
    case GET_ADMIN_CATALOGS_ERROR:
      return {
        loading: false,
        error: action.payload.error,
        data: null,
      };
    default:
      return state;
  }
};

export const selectAdminCatalogs = (state) => ({
  catalogsLoading: state?.admin?.catalogs?.loading,
  catalogsError: state?.admin?.catalogs?.error,
  catalogs: state?.admin?.catalogs?.data,
});

export default adminCatalogsReducer;
