import React from 'react';
import PropTypes from 'prop-types';

const Kitchenette = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M14.0481 2.19179V22.9061C14.0481 23.5013 13.5719 23.9775 12.9767 23.9775H1.66713C1.07189 23.9775 0.595703 23.5013 0.595703 22.9061V2.19179C0.595703 1.59655 1.07189 1.12036 1.66713 1.12036H13.0957C13.5719 1.12036 14.0481 1.59655 14.0481 2.19179Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M0.595703 8.38232H14.0481"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M2.61914 23.8584V24.9298"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M12.0234 23.8584V24.9298"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M3.69094 8.38228C3.69094 10.0489 2.38142 11.4775 0.595703 12.1918V4.57275C2.38142 5.16799 3.69094 6.71561 3.69094 8.38228Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M23.9289 23.8586H14.6432C14.4051 23.8586 14.167 23.6205 14.167 23.3824V13.2633C14.167 12.6681 14.6432 12.1919 15.2384 12.1919H23.4527C24.0479 12.1919 24.5241 12.6681 24.5241 13.2633V23.3824C24.4051 23.6205 24.167 23.8586 23.9289 23.8586Z"
          fill="white"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M15.7139 23.8584V24.9298"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M22.7373 23.8584V24.9298"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M14.0479 15.406H24.405"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M19.2852 15.406V23.8584"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M17.3809 17.668V18.9775"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M21.1904 17.668V18.9775"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0 0.525146)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

Kitchenette.propTypes = {
  className: PropTypes.string,
};

export default Kitchenette;
