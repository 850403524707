import React, { Fragment, useState, useRef } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { useLocation } from 'react-router-dom';
import Card from 'common/Card/Card';
import EditCard from 'common/Card/EditCard/EditCard';
import CheckMark from 'common/Icons/basic/CheckMark';
import Dollar from 'common/Icons/finance/Dollar';
import Toast from 'common/Toast/Toast';
import Typography from 'common/Typography/Typography';
import useGraphQLMutation from 'hooks/useGraphQLMutation';
import { registerEvent } from 'services/Analytics';
import {
  inFamilyFilePage,
  inSendReferralPage,
} from 'services/familyFileService';
import ConditionalTooltip from './ConditionalTooltip';
import Field from './Field';
import { useStyles } from './FinancialCriteria.style';
import FinancialCriteriaDefaultPage from './FinancialCriteriaDefaultPage';
import FinancialCriteriaEdit, {
  medicaidOptions,
} from './FinancialCriteriaEdit';
import updateFinancialCriteriaMutation from './UpdateFinancialCriteriaMutation';
import FamilyFileAnalyticsService from '../FamilyFileAnalyticsService';

const FinancialCriteria = ({
  labels,
  financialCriteriaData,
  flags,
  onCancelEdit,
  onSave,
  allowEdit,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const [financialCriteriaState, setFinancialCriteriaState] = useState(
    financialCriteriaData,
  );
  const [editFinancialCriteria, setEditFinancialCriteria] = useState(false);

  const [showSuccessOrErrorField, setShowSuccessOrFailureField] = useState(
    false,
  );
  const [showSuccessMessage, setShowSuccessMessage] = useState(true);

  const [updateFinancialCriteria] = useGraphQLMutation(
    updateFinancialCriteriaMutation,
  );

  const originalFormState = useRef(cloneDeep(financialCriteriaState));

  const [
    showFinancialCriteriaDefaultPage,
    setShowFinancialCriteriaDefaultPage,
  ] = useState(
    (financialCriteriaData.estimatedBudgetRange === null ||
      financialCriteriaData.estimatedBudgetRange === '') &&
      flags.updateContactsOwl,
  );
  const displayBudgetMap = {
    '$10000 and over': '$10,000 and over',
    '$9000-$9999': '$9,000-$9,999',
    '$8000-$8999': '$8,000-$8,999',
    '$7000-$7999': '$7,000-$7,999',
    '$6000-$6999': '$6,000-$6,999',
    '$5000-$5999': '$5,000-$5,999',
    '$4000-$4999': '$4,000-$4,999',
    '$500-$999': '$500-$999',
    'Under $500': 'Under $500',
    '$3000-$3999': '$3,000-$3,999',
    '$2500-$2999': '$2,500-$2,999',
    '$2000-$2499': '$2,000-$2,499',
    '$1500-$1999': '$1,500-$1,999',
    '$1000-$1499': '$1,000-$1,499',
    'Family Undecided': 'Family Undecided',
  };

  const ShowSuccessOrErrorMessage = () => {
    if (showSuccessOrErrorField) {
      if (showSuccessMessage) {
        return (
          <div className={classes.show_message}>
            <Toast
              onClose={() => {
                setShowSuccessOrFailureField(false);
                setShowSuccessMessage(false);
              }}
            >
              <Typography>
                {labels.FINANCIAL_CRITERIA_SUCCESS_MESSAGE}
              </Typography>
            </Toast>
          </div>
        );
      } else {
        return (
          <div className={classes.show_message}>
            <Toast
              type="error"
              onClose={() => {
                setShowSuccessOrFailureField(false);
              }}
            >
              <Typography>{labels.OVERVIEW_ERROR_MESSAGE}</Typography>
            </Toast>
          </div>
        );
      }
    }
    return <div />;
  };

  const saveFinancialCriteria = (financialCriteriaForSaving) => {
    registerEvent('FamilyFiles', 'Edit financial info is saved');

    const familyFileAnalyticsService = new FamilyFileAnalyticsService();

    familyFileAnalyticsService.submitFamilyFileUpdateAnalytics({
      familyFileId: financialCriteriaForSaving.familyFileId,
      section: 'family file',
      screenName: 'family file',
      submitButtonIdentifier: 'Save',
      initialFinancialCriteriaFormModel: originalFormState.current,
      updatedFinancialCriteriaFormModel: financialCriteriaForSaving,
    });

    const financialCriteria = {
      variables: {
        formDataId: financialCriteriaForSaving.formDataId,
        budgetDescription: financialCriteriaForSaving.estimatedBudgetRange,
        financialResources: financialCriteriaForSaving.financialResources,
        longTermCare: financialCriteriaForSaving.hasLongTermCareInsurancePolicy,
        veteran: financialCriteriaForSaving.isVeteran,
        vaAid: financialCriteriaForSaving.vaAid,
        oneId: financialCriteriaData.oneId,
        familyFileId: financialCriteriaData.familyFileId,
        medicaid: financialCriteriaForSaving.medicaid,
      },
    };
    updateFinancialCriteria(financialCriteria)
      .then(() => {
        originalFormState.current = cloneDeep(financialCriteriaForSaving);
        setFinancialCriteriaState(financialCriteriaForSaving);
        setShowSuccessOrFailureField(true);
        setShowSuccessMessage(true);
        setEditFinancialCriteria(false);
        onSave();
      })
      .catch(() => {
        setShowSuccessOrFailureField(true);
        setShowSuccessMessage(false);
      });
  };

  const cancelFinancialCriteriaEdit = () => {
    setEditFinancialCriteria(false);
    onCancelEdit();
  };

  const enableEditForReferralAlertValidation = inSendReferralPage(location);

  const shouldBeEditable = () => {
    return (
      allowEdit ||
      (flags.updateContactsOwl && inFamilyFilePage(location)) ||
      enableEditForReferralAlertValidation
    );
  };

  const startEdition = () => {
    registerEvent('FamilyFiles', 'Edit financial info is opened');
    setEditFinancialCriteria(true);
    setShowSuccessOrFailureField(false);
  };

  if (showFinancialCriteriaDefaultPage) {
    return (
      <FinancialCriteriaDefaultPage
        labels={labels}
        toggleFinancialCriteriaDefaultPage={setShowFinancialCriteriaDefaultPage}
        toggleFinancialCriteriaEditPage={setEditFinancialCriteria}
      />
    );
  }

  const renderAllFields = (isEditable) => {
    return (
      <Fragment>
        <Field
          label={labels.ESTIMATED_FINANCIAL_RANGE}
          value={
            displayBudgetMap[financialCriteriaState.estimatedBudgetRange] ||
            labels.UNKNOWN
          }
          labels={labels}
          isEditable={isEditable}
          isBold
        />
        <ConditionalTooltip withTooltip={isEditable} labels={labels}>
          <FinancialResources
            labels={labels}
            financialResources={financialCriteriaState.financialResources}
          />
        </ConditionalTooltip>
        <div className={classes.two_cols}>
          <Field
            label={labels.VETERAN_OR_SPOUSE_VETERAN}
            value={financialCriteriaState.isVeteran ? labels.YES : labels.NO}
            labels={labels}
            isEditable={isEditable}
            isVisible={
              financialCriteriaState.isVeteran === true ||
              financialCriteriaState.isVeteran === false
            }
          />
          <Field
            label={labels.VA_AID_ATTENDANCE_BENEFITS}
            value={financialCriteriaState.vaAid}
            labels={labels}
            isEditable={isEditable}
            isVisible={
              financialCriteriaState.isVeteran === true &&
              financialCriteriaState.vaAid !== null
            }
          />
          <Field
            label={labels.LONG_TERM_CARE_INSURANCE_POLICY}
            value={financialCriteriaState.hasLongTermCareInsurancePolicy}
            labels={labels}
            isEditable={isEditable}
            isVisible={
              financialCriteriaState.hasLongTermCareInsurancePolicy
                ? true
                : false
            }
          />
          <Field
            label={labels.MEDICAID}
            value={medicaidOptions[financialCriteriaState.medicaid]}
            labels={labels}
            isEditable={isEditable}
            isVisible={financialCriteriaState.medicaid ? true : false}
          />
        </div>
      </Fragment>
    );
  };

  const renderNotEditingInfo = () => {
    return (
      <div className={classes.change_background} onClick={() => startEdition()}>
        {renderAllFields(true)}
      </div>
    );
  };

  const renderEditingInfo = () => {
    return (
      <FinancialCriteriaEdit
        financialCriteriaData={financialCriteriaState}
        labels={labels}
        onSave={saveFinancialCriteria}
        onCancel={cancelFinancialCriteriaEdit}
        enableReferralAlertValidation={enableEditForReferralAlertValidation}
        shouldShowActionControls
      />
    );
  };

  const renderEditableInfo = () => {
    return (
      <div>
        <ShowSuccessOrErrorMessage />
        {editFinancialCriteria ? renderEditingInfo() : renderNotEditingInfo()}
      </div>
    );
  };

  if (shouldBeEditable()) {
    return (
      <EditCard
        headerColor="platinum4"
        type="light"
        title={labels.FINANCIAL_CRITERIA}
        icon={<Dollar />}
        className={classes.container}
        clickCallback={() => startEdition()}
        flags={flags}
        allowEdit={shouldBeEditable()}
      >
        {renderEditableInfo()}
      </EditCard>
    );
  }
  return (
    <Card
      className={classes.container}
      title={labels.FINANCIAL_CRITERIA}
      icon={<Dollar />}
    >
      {renderAllFields(false)}
    </Card>
  );
};

const FinancialResources = ({ labels, financialResources }) => {
  const classes = useStyles();
  if (financialResources.length === 0) return null;

  return (
    <Fragment>
      <div className={classes.row_financial_resources}>
        <Typography className={classes.label} color="eerieBlack5" level="small">
          {labels.FINANCIAL_RESOURCES}
        </Typography>
        {financialResources.map((financialResource, index) => {
          return (
            <div key={index} className={classes.resource}>
              <CheckMark className={classes.icon_check} />
              <Typography level="body">{financialResource}</Typography>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

FinancialResources.propTypes = {
  labels: PropTypes.object.isRequired,
  financialResources: PropTypes.arrayOf(PropTypes.string),
};

FinancialCriteria.propTypes = {
  labels: PropTypes.object.isRequired,
  financialCriteriaData: PropTypes.shape({
    formDataId: PropTypes.number,
    estimatedBudgetRange: PropTypes.string,
    financialResources: PropTypes.arrayOf(PropTypes.string),
    hasLongTermCareInsurancePolicy: PropTypes.string,
    isVeteran: PropTypes.bool,
    vaAid: PropTypes.string,
    familyFileId: PropTypes.number,
    oneId: PropTypes.string,
  }).isRequired,
  flags: PropTypes.shape({
    updateContactsOwl: PropTypes.bool,
  }),
  onCancelEdit: PropTypes.func,
  onSave: PropTypes.func,
  allowEdit: PropTypes.bool,
};

FinancialCriteria.defaultProps = {
  onSave: () => {},
  onCancelEdit: () => {},
  allowEdit: false,
};

export default withLDConsumer()(FinancialCriteria);
