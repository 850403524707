import gql from 'graphql-tag';

export default gql`
  query($userEmail: String!) {
    findUsersByParams(email: $userEmail) {
      firstName
      lastName
      thumbnailImageId
    }
  }
`;
