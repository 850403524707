import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  topBarContainer: {
    display: 'flex',
    height: 72,
    backgroundColor: theme.palette.neutral.cosmicCobalt1,
    justifyContent: 'space-between',
    boxShadow: '0px 0px 8px rgba(13, 19, 33, 0.5)',
    zIndex: 14,
    position: 'fixed',
    top: 0,
    width: '100%',
  },
  left: {
    display: 'flex',
    '& :hover': {
      cursor: 'pointer',
    },
    '& svg': {
      alignSelf: 'center',
      marginLeft: '24px',
      fontSize: '24px',
      color: theme.palette.light.platinum5,
    },
  },
  right: {
    display: 'flex',
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    borderLeft: `1px solid ${theme.palette.neutral.cosmicCobalt2}`,
    boxSizing: 'border-box',
    '& svg': {
      fontSize: '24px',
      color: theme.palette.light.platinum5,
    },
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    borderLeft: `1px solid ${theme.palette.neutral.cosmicCobalt2}`,
    boxSizing: 'border-box',
    padding: '12px 16px',
  },
}));
export { useStyles };
