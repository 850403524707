import React from 'react';
import PropTypes from 'prop-types';

const ChevronTop = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M12.0001 9.41421L4.70718 16.7071L3.29297 15.2929L12.0001 6.58578L20.7072 15.2929L19.293 16.7071L12.0001 9.41421Z"
      />
    </svg>
  );
};

ChevronTop.propTypes = {
  className: PropTypes.string,
};

export default ChevronTop;
