import React from 'react';
import { useStyles } from './NotesSidePanel.style';
import ChevronsRight from '../../common/Icons/arrow/ChevronsRight';
import SmartTooltip from '../../common/SmartTooltip/SmartTooltip';
import PropTypes from 'prop-types';

const HideNotesButton = ({ onClick, labels, isUnderneathTopBar }) => {
  const classes = useStyles({ isUnderneathTopBar });

  return (
    <div
      className={classes.close_button}
      onClick={() => {
        onClick();
      }}
    >
      <SmartTooltip
        className={classes.close_button_tooltip}
        title={labels.HIDE_NOTES}
        placement="left"
      >
        <ChevronsRight />
      </SmartTooltip>
    </div>
  );
};

HideNotesButton.propTypes = {
  onClick: PropTypes.func,
  isUnderneathTopBar: PropTypes.bool,
  labels: PropTypes.object,
};

HideNotesButton.defaultProps = {
  onClick: () => {},
  isUnderneathTopBar: false,
};

export default HideNotesButton;
