import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import Toast from 'common/Toast/Toast';
import Typography from 'common/Typography/Typography';
import { useExternalId } from 'hooks/useExternalId';
import { registerEvent } from 'services/Analytics';
import { getDateWithTimezoneOffset } from 'utils/dateFormat';
import YglLink from '../../YglExternalId/YglLink';
import ActivityCard from '../ActivityCard/ActivityCard';
import { useStyles } from './ToDoTasks.style';

const ToDoTasks = ({
  labels,
  toDoTasks,
  moreTasks,
  familyFileId,
  shouldRefetchCallback,
  completedActionMessage,
  dataForSegment,
  yglLinkType,
}) => {
  const classes = useStyles();
  const [isMoreTasksOpen, setIsMoreTasksOpen] = useState(false);
  const [actionBeingEdited, setActionBeingEdited] = useState();
  const [showSuccessMessage, setShowSuccessMessage] = useState(
    completedActionMessage,
  );
  const { externalId, fetchError } = useExternalId(familyFileId);

  const getColor = (dueDate) => {
    const today = new Date(Date.now());
    today.setHours(0, 0, 0, 0);
    return dueDate >= today.getTime() ? 'hotCinnamon4' : 'smokyTopaz3';
  };

  const onEditCardMode = (actionId) => {
    setActionBeingEdited(actionId);
  };

  const refetchCallback = (needToRefetch) => {
    registerEvent('FamilyFiles', 'Mark task complete');
    shouldRefetchCallback(needToRefetch);
  };

  return (
    <Fragment>
      <div className={classes.title}>
        <Typography bold>{labels.TO_DO_LIST}</Typography>
        <YglLink
          url="YGL_TASK_PAGE_URL"
          externalId={externalId}
          hasError={fetchError}
          className={classes.open_tasks_link}
          type={yglLinkType}
        >
          {labels.OPEN_TASKS_IN_YGL}
        </YglLink>
      </div>

      {showSuccessMessage && (
        <div className={classes.success_message}>
          <Toast
            onClose={() => {
              setShowSuccessMessage(false);
            }}
          >
            <Typography level="body">
              {labels.SUCCESS} {completedActionMessage}{' '}
              {labels.TASK_MARKED_COMPLETE}
            </Typography>
          </Toast>
        </div>
      )}

      <div className={classes.description}>
        <Typography bold level="small">
          {`${labels.OPEN_TASK} (${toDoTasks.length})`}
        </Typography>
      </div>
      <div>
        {toDoTasks.map((action, key) => (
          <ActivityCard
            key={key}
            className={classes.card}
            type={action.type}
            color={getColor(action.dueDate)}
            date={getDateWithTimezoneOffset(action.dueDate).getTime()}
            description={action.description}
            mode="todo"
            actionId={action.actionId}
            onEditCardMode={onEditCardMode}
            isCardBeingEdited={action.actionId === actionBeingEdited}
            shouldRefetchCallback={refetchCallback}
            dataForSegment={dataForSegment}
          />
        ))}
      </div>
      {moreTasks.length > 0 && (
        <div className={classes.description}>
          <Typography
            bold
            level="small"
          >{`${labels.MORE_OPEN_TASKS} (${moreTasks.length})`}</Typography>
          <div onClick={() => setIsMoreTasksOpen(!isMoreTasksOpen)}>
            <Typography className={classes.show_more} level="small">
              {isMoreTasksOpen
                ? labels.HIDE.toLowerCase()
                : labels.SHOW_MORE.toLowerCase()}
            </Typography>
          </div>
        </div>
      )}
      <div>
        {isMoreTasksOpen &&
          moreTasks.map((action, key) => (
            <ActivityCard
              key={key}
              className={classes.card}
              type={action.type}
              color={getColor(action.dueDate)}
              date={getDateWithTimezoneOffset(action.dueDate).getTime()}
              description={action.description}
              mode="todo"
              actionId={action.actionId}
              onEditCardMode={onEditCardMode}
              isCardBeingEdited={action.actionId === actionBeingEdited}
              shouldRefetchCallback={refetchCallback}
            />
          ))}
      </div>
    </Fragment>
  );
};

ToDoTasks.propTypes = {
  labels: PropTypes.object.isRequired,
  toDoTasks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      dueDate: PropTypes.number.isRequired,
    }),
  ).isRequired,
  moreTasks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      dueDate: PropTypes.number.isRequired,
    }),
  ).isRequired,
  familyFileId: PropTypes.number.isRequired,
  shouldRefetchCallback: PropTypes.func,
  completedActionMessage: PropTypes.string,
  dataForSegment: PropTypes.shape({
    familyFileId: PropTypes.number,
    oneId: PropTypes.string,
  }),
  yglLinkType: PropTypes.string.isRequired,
};

export default ToDoTasks;
