import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import React from 'react';
import PinnedMap from '../../common/Graphics/communities/PinnedMap';
import PinAdd from '../../common/Icons/maps/PinAdd';
import Typography from '../../common/Typography/Typography';
import { useExternalId } from '../../hooks/useExternalId';
import YglLink from '../YglExternalId/YglLink';
import { useStyles } from './EmptyCommunities.style';

const EmptyCommunities = ({
  flags,
  labels,
  familyFileId,
  isInCommunityPage,
}) => {
  const classes = useStyles();
  const { externalId, fetchError } = useExternalId(familyFileId);

  return (
    <div className={classes.empty_container}>
      <div className={classes.header_row}>
        {familyFileId && flags.communitySearchUiFromYgl && (
          <>
            <Typography bold>{labels.WORKING_LIST_FOR_REFERRAL}</Typography>
            <YglLink
              url="YGL_WORKING_LIST_PAGE_URL"
              externalId={externalId}
              hasError={fetchError}
              type="button"
              color={isInCommunityPage ? 'light' : 'secondary'}
              target={'self'}
              textColor={isInCommunityPage ? 'eerieBlack1' : 'platinum5'}
              eventParams={[
                'CommunitySearchPage',
                'Opened Working List in YGL',
                `0 saved communities`,
                true,
              ]}
            >
              {isInCommunityPage
                ? labels.OPEN_WORKING_LIST_IN_YGL
                : labels.GO_TO_WORKING_LIST}
            </YglLink>
          </>
        )}
      </div>
      <div className={classes.no_content}>
        <PinnedMap className={classes.no_content_graphic} />
        <Typography className={classes.empty_text} bold level="large">
          {labels.ITS_EMPTY_HERE}
        </Typography>
        {!isInCommunityPage && (
          <Typography>{labels.EMPTY_COMMUNITY_TIP}</Typography>
        )}
        <Typography>{labels.SEARCH_FOR_COMMUNITIES_NOW}</Typography>
        <Typography>{labels.YOU_CAN_SAVE_COMMUNITIES}</Typography>
        <Typography className={classes.save_community_button}>
          <PinAdd />
          {`- ${labels.SAVE_COMMUNITY_BUTTON}`}
        </Typography>
      </div>
    </div>
  );
};

EmptyCommunities.propTypes = {
  labels: PropTypes.object,
  flags: PropTypes.shape({
    communitySearchUiFromYgl: PropTypes.bool,
  }),
  familyFileId: PropTypes.number,
  isInCommunityPage: PropTypes.bool,
};

export default withLDConsumer()(EmptyCommunities);
