import gql from 'graphql-tag';

export default gql`
  mutation(
    $familyCommunityTourDraftId: Int
    $familyFileId: Int!
    $communityId: Int!
    $activityDate: Date!
    $timeOffset: Int!
    $tourType: String!
  ) {
    upsertTourDraft(
      input: {
        familyCommunityTourDraftId: $familyCommunityTourDraftId
        familyFileId: $familyFileId
        communityId: $communityId
        activityDate: $activityDate
        timeOffset: $timeOffset
        tourType: $tourType
      }
    ) {
      familyCommunityTourDraftId
      familyFileId
      communityId
      activityDate
      tourType
      createdAt
      updatedAt
    }
  }
`;
