import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  medical_background_form_container: {
    width: '100%',
  },
  container: {
    margin: '24px 0',
  },
  actions_container: {
    position: 'relative',
    height: '64px',
  },
  row: {
    display: 'flex',
    flexFlow: 'row nowrap',
    flexGrow: 1,
    marginBottom: '20px',
  },
  row_flex_wrap: {
    flexWrap: 'wrap',
  },
  row_getting_around: {
    display: 'flex',
    flexFlow: 'row wrap',
    flexGrow: 1,
    marginBottom: '3px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  header_and_buttons: {
    display: 'flex',
    height: '37px',
  },
  first_row_header: {
    display: 'flex',
    flexFlow: 'row nowrap',
    flexGrow: 1,
    marginBottom: '16px',
  },
  row_header: {
    display: 'flex',
    flexFlow: 'row nowrap',
    flexGrow: 1,
    marginTop: '36px',
    marginBottom: '12px',
  },
  icon_text: {
    padding: '12px 16px',
  },
  extended_width: {
    width: '350px',
  },
  column_currently_living: {
    zIndex: 900,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&.send_referral_column_currently_living': {
      width: '100%',
    },
    '&.send_referral_column_currently_living .custom_select': {
      width: '100%',
    },
  },
  column_rehab: {
    zIndex: 800,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  column_medication: {
    width: '170px',
    zIndex: 700,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      '& > div': {
        width: '100%',
      },
    },
  },
  text_input_container: {
    width: '100%',
  },
  column_text_input: {
    display: 'flex',
    flexDirection: 'column',
    width: '650px',
  },
  text_input_margin_bottom: {
    marginBottom: theme.spacing(3),
  },
  column_memory_condition: {
    display: 'flex',
    flexDirection: 'column',
    width: '450px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      '&.custom_select': {
        width: '100%',
        '& > div': {
          width: '100%',
        },
      },
    },
  },
  column_other_issue: {
    display: 'flex',
    flexDirection: 'column',
    width: '450px',
    paddingTop: '5px',
  },
  mandatory_dropdown: {
    zIndex: 999,
    '&::before': {
      content: `'*'`,
      position: 'absolute',
      color: 'red',
      left: '-2px',
    },
    '& .star': {
      marginLeft: '10px',
    },
    '&.custom_select': {
      width: '250px',
    },
    [theme.breakpoints.down('sm')]: {
      '&.custom_select': {
        width: '100%',
        '& > div': {
          width: '100%',
        },
      },
    },
  },
  dropdown: {
    zIndex: 999,
    '&.custom_select': {
      width: '250px',
    },
    [theme.breakpoints.down('sm')]: {
      '&.custom_select': {
        width: '100%',
        '& > div': {
          width: '100%',
        },
      },
    },
  },
  custom_select: {
    '&.custom_select': {
      width: '420px',
      zIndex: 333,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        '& > div': {
          width: '100%',
        },
      },
    },
  },
  custom_select_panel: {
    '&.custom_select': {
      width: '350px',
      zIndex: 333,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        '& > div': {
          width: '100%',
        },
      },
    },
  },
  column_button: {
    display: 'flex',
    position: 'absolute',
    right: '24px',
    '& div': {
      paddingLeft: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      position: 'unset',
      marginBottom: theme.spacing(3),
      '& div': {
        paddingLeft: 0,
        paddingRight: theme.spacing(3),
      },
    },
  },
  column_button_send_referral: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    '& div': {
      paddingLeft: 0,
      paddingRight: theme.spacing(3),
    },
  },
  max_width: {
    maxWidth: '100%',
  },
  text_input_location: {
    marginTop: '4px',
    width: '320px',
    position: 'relative',

    '&::before': {
      content: `'*'`,
      position: 'absolute',
      color: 'red',
      left: '-2px',
    },
    '& label': {
      marginLeft: '10px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&.send_referral_currently_living': {
      width: '100%',
    },
  },
  group_item: {
    display: 'flex',
  },
  getting_around_items: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingRight: '12px',
    height: '70px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexWrap: 'unset',
      '&:last-child': {
        paddingLeft: 0,
      },
    },
  },
  getting_around_item: {
    display: 'flex',
    alginItems: 'center',
    flexGrow: 1,
    height: '70px',
    '& svg': {
      fontSize: '48px',
    },
    cursor: 'pointer',
  },
  other_issue: {
    color: theme.palette.dark.eerieBlack1,
  },
  small_block_container: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    '&.send_referral_small_block_container': {
      display: 'block',
    },
  },
  actions_for_desktop: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      marginTop: theme.spacing(2),
    },
  },
}));

export { useStyles };
