import React from 'react';
import PropTypes from 'prop-types';
import { getHomePage } from '../../config/roles';
import { Redirect } from 'react-router-dom';
import { withUser } from '../../context/UserContext';

const HomeRoute = ({ currentUser }) => {
  return <Redirect to={getHomePage(currentUser.role)} />;
};

HomeRoute.propTypes = {
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.string,
    __typename: PropTypes.string,
  }).isRequired,
};

export default withUser(HomeRoute);
