import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './TableHead.style';
import clsx from 'clsx';

const TableHead = ({ className, children }) => {
  const classes = useStyles();
  const containerClasses = clsx(className, classes.t_head);
  return <thead className={containerClasses}>{children}</thead>;
};

TableHead.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default TableHead;
