import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './IncompleteFamilyFile.style';
import Assessment from 'common/Graphics/familyFile/Assessment';
import Typography from 'common/Typography/Typography';
import Button from 'common/Button/Button';
import PencilEdit from 'common/Icons/basic/PencilEdit';
import Search from 'common/Icons/basic/Search';
import { registerEvent } from 'services/Analytics';

const IncompleteFamilyFile = ({ labels, familyFileId }) => {
  const classes = useStyles();
  return (
    <div className={classes.incomplete_ff_container}>
      <Assessment className={classes.icon} />
      <Typography level="large" bold className={classes.title}>
        {labels.INCOMPLETE_FAMILY_FILE}
      </Typography>
      <Typography className={classes.description}>
        {labels.PLEASE_COMPLETE_INITIAL_CONSULTATION}
      </Typography>
      <Typography>
        {labels.PLEASE_COMPLETE_INITIAL_CONSULTATION_EXAMPLE}
      </Typography>
      <Button className={classes.edit_ff_button} startIcon={<PencilEdit />}>
        <Typography color="platinum5">{labels.EDIT_FAMILY_FILE}</Typography>
      </Button>
      <div
        onClick={() => {
          registerEvent(
            'FamilyFiles',
            'Go to search communities',
            'Incomplete Family File',
          );
        }}
      >
        <Button
          type="light"
          openInNewTab
          to={`/communities/${familyFileId}`}
          className={classes.edit_ff_button}
          startIcon={<Search />}
        >
          <Typography color="eerieBlack1">
            {labels.SEARCH_FOR_COMMUNITIES}
          </Typography>
        </Button>
      </div>
    </div>
  );
};

IncompleteFamilyFile.propTypes = {
  labels: PropTypes.object,
  familyFileId: PropTypes.number.isRequired,
};

IncompleteFamilyFile.defaultProps = {};

export default IncompleteFamilyFile;
