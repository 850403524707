import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Button from 'common/Button/Button';
import Delete from 'common/Icons/basic/Delete';
import Dialpad from 'common/Icons/call/CallNumbers';
import CallPhone from 'common/Icons/call/CallPhone';
import TextInput from 'common/TextInput/TextInput';
import Typography from 'common/Typography/Typography';
import { withLabels } from 'context/LabelContext';
import getTelephonyError from 'services/callCenter/five9Error';
import { useStyles } from './PhoneDialpad.style';

const PhoneDialpad = ({
  onMakeCall,
  startCallButton,
  directDial,
  labels,
  className,
  error,
}) => {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [lastNumber, setLastNumber] = useState('');
  const [isFromTextInput, setIsFromTextInput] = useState(false);
  const phoneWords = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '*',
    '0',
    '#',
  ];

  useEffect(() => {
    const lastNum =
      phoneNumber.length > 0
        ? phoneNumber.slice(phoneNumber.length - 1)
        : phoneNumber;

    if (phoneNumber && directDial) {
      onMakeCall(lastNum);
    }
    // eslint-disable-next-line
  }, [phoneNumber, directDial]);

  const getEndIcon = () => {
    if (phoneNumber && phoneNumber.length > 0) {
      return (
        <div onClick={() => setPhoneNumber('')} className={classes.delete_icon}>
          <Delete />
        </div>
      );
    }
  };

  const handleFromButtons = (value) => {
    setPhoneNumber(value);
    setIsFromTextInput(false);
  };

  const handleFromTextInput = (value) => {
    setPhoneNumber(value);
    setIsFromTextInput(true);
    setLastNumber(value.length > 0 ? value.slice(value.length - 1) : value);
  };

  const renderDialPadButtons = () => {
    return phoneWords.map((value, key) => (
      <Button
        key={key}
        type="outlined"
        className={clsx(
          classes.button_number,
          isFromTextInput && lastNumber === value
            ? classes.animated_button_number
            : '',
        )}
        onClick={() => handleFromButtons(phoneNumber + value)}
      >
        {value}
      </Button>
    ));
  };

  const containerClass = clsx(
    className,
    classes.container,
    startCallButton ? '' : classes.container_with_no_button,
  );

  const hasError = !!error;
  let errorMessage = null;
  if (hasError) {
    errorMessage = getTelephonyError(error, labels);
  }

  return (
    <div className={containerClass} data-testid="phone_dialpad">
      <TextInput
        id="phoneNumber"
        type="phoneword"
        startIcon={
          <Dialpad className={clsx(hasError && classes.icon_with_error)} />
        }
        endIcon={getEndIcon()}
        placeholder={labels.PRESS_OR_TYPE_NUMBER_TO_DIAL}
        className={classes.dialer_input}
        value={phoneNumber === 0 ? '' : phoneNumber.toString()}
        onChange={(value) => handleFromTextInput(value)}
        error={hasError}
      />
      {error && (
        <Typography color="smokyTopaz2" className={classes.error_container}>
          {errorMessage}
        </Typography>
      )}
      <div className={classes.button_numbers_container}>
        {renderDialPadButtons()}
      </div>
      {startCallButton && (
        <Button
          startIcon={<CallPhone className={classes.phone_icon} />}
          type={phoneNumber && phoneNumber.length > 0 ? 'primary' : 'disabled'}
          onClick={() => {
            onMakeCall(phoneNumber);
          }}
        >
          {labels.START_CALL}
        </Button>
      )}
    </div>
  );
};

PhoneDialpad.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  startCallButton: PropTypes.bool,
  directDial: PropTypes.bool,
  labels: PropTypes.object,
  error: PropTypes.object,
  onMakeCall: PropTypes.func,
  className: PropTypes.string,
};

PhoneDialpad.defaultProps = {
  isOpen: false,
  startCallButton: false,
  directDial: false,
  className: '',
  onMakeCall: () => {},
  error: null,
};

export default withLabels(PhoneDialpad);
