import React from 'react';
import PropTypes from 'prop-types';

const Church = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.4531 40.8438C34.165 40.8438 34.7422 40.2666 34.7422 39.5547V36.1172C34.7422 35.4053 34.165 34.8281 33.4531 34.8281C32.7412 34.8281 32.1641 35.4053 32.1641 36.1172V39.5547C32.1641 40.2666 32.7412 40.8438 33.4531 40.8438Z"
        fill="currentColor"
      />
      <path
        d="M14.5469 34.8281C13.835 34.8281 13.2578 35.4053 13.2578 36.1172V39.5547C13.2578 40.2666 13.835 40.8438 14.5469 40.8438C15.2588 40.8438 15.8359 40.2666 15.8359 39.5547V36.1172C15.8359 35.4053 15.2588 34.8281 14.5469 34.8281Z"
        fill="currentColor"
      />
      <path
        d="M24 26.2344C26.1324 26.2344 27.8672 24.4996 27.8672 22.3672C27.8672 20.2348 26.1324 18.5 24 18.5C21.8676 18.5 20.1328 20.2348 20.1328 22.3672C20.1328 24.4996 21.8676 26.2344 24 26.2344ZM24 21.0781C24.7108 21.0781 25.2891 21.6564 25.2891 22.3672C25.2891 23.078 24.7108 23.6562 24 23.6562C23.2892 23.6562 22.7109 23.078 22.7109 22.3672C22.7109 21.6564 23.2892 21.0781 24 21.0781Z"
        fill="currentColor"
      />
      <path
        d="M45.4219 29.0262L35.6016 22.5335V18.0703C35.6016 17.6118 35.3581 17.1878 34.962 16.9568L25.2891 11.3143V7.32812H26.5781C27.29 7.32812 27.8672 6.75097 27.8672 6.03906C27.8672 5.32716 27.29 4.75 26.5781 4.75H25.2891V3.28906C25.2891 2.57716 24.7119 2 24 2C23.2881 2 22.7109 2.57716 22.7109 3.28906V4.75H21.4219C20.71 4.75 20.1328 5.32716 20.1328 6.03906C20.1328 6.75097 20.71 7.32812 21.4219 7.32812H22.7109V11.3143L13.038 16.9569C12.6419 17.1878 12.3984 17.6118 12.3984 18.0703V22.5335L2.5781 29.0262C2.21716 29.265 2 29.6688 2 30.1016V44.7109C2 45.4228 2.57716 46 3.28906 46H44.7109C45.4228 46 46 45.4228 46 44.7109V30.1016C46 29.6688 45.7828 29.265 45.4219 29.0262ZM4.57812 30.7946L12.3984 25.6241V30.2311L8.72745 32.4336C8.33918 32.6667 8.10156 33.0863 8.10156 33.5391V43.4219H4.57812V30.7946ZM10.6797 34.2689L14.3507 32.0664C14.7389 31.8333 14.9766 31.4137 14.9766 30.9609V18.8107L24 13.5471L33.0234 18.8107V30.9609C33.0234 31.4137 33.2611 31.8333 33.6493 32.0663L37.3203 34.2689V43.4219H29.5859V36.9766C29.5859 33.8965 27.0801 31.3906 24 31.3906C20.9199 31.3906 18.4141 33.8965 18.4141 36.9766V43.4219H10.6797V34.2689ZM20.9922 43.4219V36.9766C20.9922 35.3181 22.3415 33.9688 24 33.9688C25.6585 33.9688 27.0078 35.3181 27.0078 36.9766V43.4219H20.9922ZM43.4219 43.4219H39.8984V33.5391C39.8984 33.0863 39.6608 32.6667 39.2726 32.4337L35.6016 30.2311V25.6242L43.4219 30.7946V43.4219Z"
        fill="currentColor"
      />
    </svg>
  );
};

Church.propTypes = {
  className: PropTypes.string,
};

export default Church;
