import gql from 'graphql-tag';

export default gql`
  mutation(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
  ) {
    createManualInquiryContact(
      input: {
        email: $email
        phone: $phone
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      inquiryContactId
      oneId
      firstName
      lastName
      email
      phone
      updatedAt
    }
  }
`;
