import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Media from 'common/Media/Media';
import ContactsMobile from './ContactsMobile';
import ContactsXL from './ContactsXL';
import { registerEvent } from 'services/Analytics';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { dialerService } from 'services/communication/telephony/DialerService';
import ContactsUpdate from 'services/Contacts/ContactsUpdate';

const Contacts = ({
  labels,
  contact,
  handleRefetchState,
  familyFileId,
  flags,
}) => {
  useEffect(() => {
    dialerService.setTestNumber(flags.dialOverrideNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [contactsInformation] = useState({
    ...contact,
  });

  const [editContactInformationOpen, setEditContactInformationOpen] = useState(
    false,
  );

  const handleOnEditMode = (open) => {
    registerEvent('FamilyFiles', 'Edit contact info is opened');
    setEditContactInformationOpen(open);
  };

  const closeEditHandler = () => {
    setEditContactInformationOpen(false);
  };

  return (
    <Fragment>
      <ContactsUpdate
        labels={labels}
        contact={contactsInformation}
        handleRefetchState={handleRefetchState}
        familyFileId={familyFileId}
        editContactInformationOpen={editContactInformationOpen}
        handleClosingEdit={closeEditHandler}
      >
        <Media
          xs={
            <Fragment>
              <ContactsMobile
                labels={labels}
                familyFileId={familyFileId}
                contact={contactsInformation}
                onEditMode={handleOnEditMode}
                dialerService={dialerService}
              />
            </Fragment>
          }
          xl={
            <Fragment>
              <ContactsXL
                labels={labels}
                familyFileId={familyFileId}
                contact={contactsInformation}
                onEditMode={handleOnEditMode}
                dialerService={dialerService}
              />
            </Fragment>
          }
        />
      </ContactsUpdate>
    </Fragment>
  );
};

Contacts.propTypes = {
  labels: PropTypes.object.isRequired,
  familyFileId: PropTypes.number.isRequired,
  contact: PropTypes.shape({
    primaryContact: PropTypes.shape({
      name: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      relationship: PropTypes.string,
      address1: PropTypes.string,
      address2: PropTypes.string,
      allowContact: PropTypes.bool,
      isInquirer: PropTypes.bool,
      city: PropTypes.string,
      postalCode: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      contactDetail: PropTypes.shape({
        phonePrimary: PropTypes.string,
        phoneSecondary: PropTypes.string,
        email: PropTypes.string,
        primaryPhoneType: PropTypes.string,
        secondaryPhoneType: PropTypes.string,
      }),
      phones: PropTypes.arrayOf(
        PropTypes.shape({
          subCategoryCode: PropTypes.string,
          phone: PropTypes.string,
        }),
      ),
      emailAddresses: PropTypes.arrayOf(
        PropTypes.shape({
          emailAddress: PropTypes.string,
        }),
      ),
    }),
    secondaryContact: PropTypes.shape({
      name: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      relationship: PropTypes.string,
      address1: PropTypes.string,
      address2: PropTypes.string,
      allowContact: PropTypes.bool,
      isInquirer: PropTypes.bool,
      city: PropTypes.string,
      postalCode: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      contactDetail: PropTypes.shape({
        phonePrimary: PropTypes.string,
        phoneSecondary: PropTypes.string,
        email: PropTypes.string,
        primaryPhoneType: PropTypes.string,
        secondaryPhoneType: PropTypes.string,
      }),
    }),
    additionalContact: PropTypes.shape({
      name: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      relationship: PropTypes.string,
      address1: PropTypes.string,
      address2: PropTypes.string,
      allowContact: PropTypes.bool,
      isInquirer: PropTypes.bool,
      city: PropTypes.string,
      postalCode: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      contactDetail: PropTypes.shape({
        phonePrimary: PropTypes.string,
        phoneSecondary: PropTypes.string,
        email: PropTypes.string,
        primaryPhoneType: PropTypes.string,
        secondaryPhoneType: PropTypes.string,
      }),
    }),
  }),
  handleRefetchState: PropTypes.func,
  flags: PropTypes.shape({
    dialOverrideNumber: PropTypes.string,
  }),
};

export default withLDConsumer()(Contacts);
