import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './CommunityPrimaryContact.style';
import Typography from 'common/Typography/Typography';
import Mail from 'common/Icons/basic/Mail';
import User from 'common/Icons/basic/User';
import Printer from 'common/Icons/basic/Printer';
import CallPhone from 'common/Icons/call/CallPhone';
import TextMessage from 'common/Icons/call/TextMessage';
import Pin from 'common/Icons/maps/Pin';
import Toast from 'common/Toast/Toast.js';
import { getCommunityAddress } from '../ReferredCommunities/ReferredCommunitiesRow/referredCommunitiesService';
import clsx from 'clsx';
import ActionableTooltip from 'common/ActionableTooltip/ActionableTooltip.js';

import {
  getActionableTitleEmail,
  getActionablePhone,
  getActionableFax,
} from 'services/TelephonyActions';

const CommunityPrimaryContact = ({
  labels,
  contact,
  community,
  flags,
  familyFileId,
  familyFileUserId,
  dialerService,
  currentUser,
  careTypes,
  familyFilePrimaryContact,
  externalId,
}) => {
  const classes = useStyles();
  const [telephonyError, setTelephonyError] = useState(null);
  const telephonyData = {
    labels,
    contact,
    community,
    flags,
    familyFileId,
    familyFileUserId,
    dialerService,
    currentUser,
    careTypeCodes: careTypes.toString(),
    classes,
    setTelephonyError,
    externalId,
  };

  const primaryProfileName = familyFilePrimaryContact.current.find(
    (contact) => contact.profileData.isPrimary,
  );
  const primaryProfileFullname = `${primaryProfileName.profileData.firstName} ${primaryProfileName.profileData.lastName}`;

  const getContactFullName = () => {
    if (contact.firstName && contact.lastName) {
      return (
        <Typography bold>
          {`${contact.firstName} ${contact.lastName}`}
        </Typography>
      );
    }
    return (
      <Typography color="disabled">
        {labels.POINT_OF_CONTACT_NOT_AVAILABLE}
      </Typography>
    );
  };

  return (
    <div className={classes.contact_info_container}>
      <div className={[classes.row]}>
        {telephonyError && (
          <Toast
            className={classes.toast_error}
            type="error"
            onClose={() => {
              setTelephonyError(null);
            }}
          >
            {telephonyError}
          </Toast>
        )}
      </div>
      <Typography level="small" color="eerieBlack5">
        {labels.COMMUNITY_PRIMARY_CONTACT}
      </Typography>
      <div className={classes.contact_info_row}>
        <User
          className={clsx([
            classes.icon,
            !(contact.firstName && contact.lastName) && classes.icon_disabled,
          ])}
        />
        {getContactFullName()}
      </div>
      <div className={classes.contact_info_row}>
        {contact.email ? (
          <ActionableTooltip
            title={getActionableTitleEmail(
              telephonyData,
              `APFM Lead - ${
                primaryProfileFullname || labels.POINT_OF_CONTACT_NOT_AVAILABLE
              }  #${externalId}#`,
            )}
            placement="bottom-start"
          >
            <Mail className={classes.icon} />
            <Typography
              className={classes.contact_info_text}
              level={'small'}
              color={'eerieBlack1'}
            >
              {contact.email}
            </Typography>
          </ActionableTooltip>
        ) : (
          <>
            <Mail className={clsx([classes.icon, classes.icon_disabled])} />{' '}
            <Typography
              className={classes.contact_info_text}
              level={'small'}
              color={'disabled'}
            >
              {labels.EMAIL_NOT_AVAILABLE}
            </Typography>{' '}
          </>
        )}
      </div>
      <div className={classes.contact_info_row}>
        <div className={classes.contact_info_column}>
          {contact.workPhone ? (
            <ActionableTooltip
              title={getActionablePhone(
                contact.workPhone,
                'work phone',
                telephonyData,
              )}
              placement="bottom-start"
            >
              <CallPhone className={classes.icon} />
              <Typography color={'eerieBlack1'} level={'small'}>
                {contact.workPhone}
              </Typography>
            </ActionableTooltip>
          ) : (
            <>
              <CallPhone
                className={clsx([classes.icon, classes.icon_disabled])}
              />
              <Typography color={'disabled'} level={'small'}>
                {labels.PHONE_NOT_AVAILABLE}
              </Typography>{' '}
            </>
          )}
        </div>
        <div className={classes.contact_info_column}>
          {contact.cellPhone ? (
            <ActionableTooltip
              title={getActionablePhone(
                contact.cellPhone,
                'mobile',
                telephonyData,
              )}
              placement="bottom-start"
            >
              <TextMessage className={classes.icon} />
              <Typography color={'eerieBlack1'} level={'small'}>
                {contact.cellPhone}
              </Typography>
            </ActionableTooltip>
          ) : (
            <>
              <TextMessage
                className={clsx([classes.icon, classes.icon_disabled])}
              />
              <Typography color={'disabled'} level={'small'}>
                {labels.MOBILE_NOT_AVAILABLE}
              </Typography>
            </>
          )}
        </div>
        <div className={classes.contact_info_column}>
          {contact.fax ? (
            <ActionableTooltip
              title={getActionableFax(contact.fax, telephonyData)}
              placement="bottom-start"
            >
              <Printer className={classes.icon} />
              <Typography color={'eerieBlack1'} level={'small'}>
                {contact.fax}
              </Typography>
            </ActionableTooltip>
          ) : (
            <>
              <Printer
                className={clsx([classes.icon, classes.icon_disabled])}
              />
              <Typography color={'disabled'} level={'small'}>
                {labels.FAX_NOT_AVAILABLE}
              </Typography>
            </>
          )}
        </div>
      </div>
      <div className={classes.contact_info_row}>
        <Pin
          className={clsx([
            classes.icon,
            !community.address && classes.icon_disabled,
          ])}
        />
        {getCommunityAddress(community)}
      </div>
    </div>
  );
};

CommunityPrimaryContact.propTypes = {
  labels: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
  community: PropTypes.object.isRequired,
  flags: PropTypes.shape({
    enableOneClickDial: PropTypes.bool,
    textOverrideNumber: PropTypes.string,
  }),
  familyFileId: PropTypes.number,
  familyFileUserId: PropTypes.number,
  externalId: PropTypes.number,
  dialerService: PropTypes.object,
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    username: PropTypes.string,
  }),
  careTypes: PropTypes.array,
  familyFilePrimaryContact: PropTypes.object,
};

export default CommunityPrimaryContact;
