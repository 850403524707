import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import React from 'react';
import { getCurrentFamilyFile } from 'services/callCenter/duplicateFamilyFilePopupService';
import Toast from '../../common/Toast/Toast';
import Typography from '../../common/Typography/Typography';
import { useExternalId } from '../../hooks/useExternalId';
import useGraphQLQuery from '../../hooks/useGraphQLQuery';
import Loading from '../Loading/Loading';
import YglLink from '../YglExternalId/YglLink';
import CommunityPreferences from './CommunityPreferences/CommunityPreferences';
import { useStyles } from './FamilyFile.style';
import DuplicateFamilyFile from './FamilyFilePopup/DuplicateFamilyFile';
import familyFileQuery from './familyFileQuery';
import toFamilyFileInformation from './familyFileService';
import FinancialCriteria from './FinancialCriteria/FinancialCriteria';
import MedicalBackgroundDesktop from './MedicalBackground/MedicalBackgroundDesktop';
import Overview from './Overview/Overview';

const FamilyFileDesktop = ({ labels, familyFileId, flags }) => {
  const classes = useStyles();
  const { loading, error, data, refetch } = useGraphQLQuery(familyFileQuery, {
    variables: { familyFileId },
  });
  const { externalId, fetchError } = useExternalId(familyFileId);

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;
  const {
    overview,
    medicalBackground,
    contactInformation,
    financialCriteria,
    tourDetails,
    notes,
    communityPreferences,
    isSelfLead,
  } = toFamilyFileInformation(data.findFamilyFileById);
  let includeTourDetails = tourDetails.length > 0 ? true : false;
  const currentFamilyFile = getCurrentFamilyFile(
    contactInformation,
    communityPreferences,
  );

  return (
    <div className={classes.desktop_container}>
      <div className={classes.lead_link_container}>
        <YglLink
          url="YGL_CASE_PAGE_URL"
          externalId={externalId}
          hasError={fetchError}
          type="button"
        >
          {labels.OPEN_LEAD_SUMMARY_IN_YGL}
        </YglLink>
      </div>
      {includeTourDetails ? (
        <Toast
          className={classes.tours_info_container}
          type="informative"
          onClose={() => {}}
        >
          <div>
            <Typography>Tours Scheduled:</Typography>
            {tourDetails.map((tour, index) => (
              <Typography key={index} bold>
                {tour.scheduledTourDate}, {tour.communityName}
              </Typography>
            ))}
            <YglLink
              className={classes.working_list_link}
              url="YGL_WORKING_LIST_PAGE_URL"
              externalId={externalId}
              hasError={fetchError}
              type="link"
            >
              {labels.GO_TO_WORKING_LIST}
            </YglLink>
          </div>
        </Toast>
      ) : (
        <div></div>
      )}
      {flags.showDuplicatesFamilyFiles && (
        <DuplicateFamilyFile
          labels={labels}
          params={currentFamilyFile}
          familyFileId={familyFileId}
          externalId={externalId}
        />
      )}
      <Overview labels={labels} overviewData={overview} notes={notes} />
      <MedicalBackgroundDesktop
        labels={labels}
        medicalBackgroundData={medicalBackground}
        onCancelEdit={() => refetch()}
        isSelfLead={isSelfLead}
      />
      <FinancialCriteria
        labels={labels}
        financialCriteriaData={financialCriteria}
        onCancelEdit={() => refetch()}
      />
      <CommunityPreferences
        labels={labels}
        communityPreferencesData={communityPreferences}
      />
    </div>
  );
};

FamilyFileDesktop.propTypes = {
  labels: PropTypes.object.isRequired,
  familyFileId: PropTypes.number.isRequired,
  flags: PropTypes.shape({
    showDuplicatesFamilyFiles: PropTypes.bool,
  }),
};

export default withLDConsumer()(FamilyFileDesktop);
