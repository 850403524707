import { makeStyles } from '../../Theme/Theme';

const useStyles = makeStyles(() => {
  return {
    t_body: {
      display: 'table-row-group',
    },
  };
});

export { useStyles };
