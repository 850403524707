import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => {
  return {
    textbox_container: {
      width: '100%',
      height: '95%',
      display: 'block',
      marginTop: '-15px',
    },
    textbox: {
      boxShadow: 'none',
      padding: '.2rem',
      fontSize: '14px',
      display: 'block',
      width: '250px',
      minWidth: '100%',
      '& label': {
        width: 'max-content',
        display: 'block',
      },
    },
    textbox_saveBtn: {
      padding: '8px 16px',
      fontSize: '14px',
      margin: '11px 0 11px 0',
    },
  };
});
export { useStyles };
