import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import App from './App';
import Auth0Provider from './context/Authentication';
import history from './utils/history';
import withConfig from './utils/withConfig';
import { ThemeProvider } from './common/Theme/Theme';

// A function that routes the user to the right place after login
const onRedirectCallback = (appState) => {
  appState.targetUrl = localStorage.getItem('PAGE_TO_REDIRECT');
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );
  localStorage.removeItem('PAGE_TO_REDIRECT');
};

const useSentry = withConfig('USE_SENTRY');
if (useSentry) {
  const env = withConfig('SENTRY_APP_ENVIRONMENT');
  const sentryDsn = withConfig('SENTRY_DSN');
  const traceRate = withConfig('SENTRY_TRACE_SAMPLE_RATE');
  const release = window.ENV.REACT_APP_VERSION;

  Sentry.init({
    dsn: sentryDsn,
    environment: env,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: traceRate,
    release: release,
  });
  // set current user's email to sentry logs
  const currentUser = JSON.parse(localStorage.getItem('CURRENT_USER'));

  Sentry.setUser({ email: currentUser?.email });

  console.log(`Sentry initialized on dsn: ${sentryDsn}`);
}

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: withConfig('LD_CLIENT_SIDE_ID'),
  });

  ReactDOM.render(
    <LDProvider>
      <ThemeProvider>
        <Auth0Provider
          domain={withConfig('AUTH0_DOMAIN')}
          client_id={withConfig('AUTH0_CLIENT_ID')}
          redirect_uri={window.location.origin}
          data={window.location}
          onRedirectCallback={onRedirectCallback}
        >
          <App />
        </Auth0Provider>
      </ThemeProvider>
    </LDProvider>,
    document.getElementById('root'),
  );
})();
