import gql from 'graphql-tag';

export default gql`
  mutation(
    $stepId: Int!
    $answerId: Int
    $order: Int!
    $label: String!
    $value: String!
    $nextStep: Int!
    $closeInquiry: Boolean
    $closeReason: String
    $disposition: String
    $addToDoNotCall: Boolean
    $shows: [Int]
    $hides: [Int]
    $selectedRule: [String]
    $action: String
    $urlLink: String
    $poolType: Int
  ) {
    saveAnswer(
      input: {
        stepId: $stepId
        answerId: $answerId
        order: $order
        label: $label
        value: $value
        nextStep: $nextStep
        closeInquiry: $closeInquiry
        closeReason: $closeReason
        disposition: $disposition
        addToDoNotCall: $addToDoNotCall
        shows: $shows
        hides: $hides
        selectedRule: $selectedRule
        action: $action
        urlLink: $urlLink
        poolType: $poolType
      }
    ) {
      scriptId
      answerId
    }
  }
`;
