import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './SavedCommunities.style';
import Checkbox from '../../common/Checkbox/Checkbox';
import CommunityImage from '../../pages/Communities/CommunityImage/CommunityImage';
import CommunityInfoLG from '../../pages/Communities/CommunityInfo/CommunityInfoLG';
import {
  getDisplayImageId,
  getPriceRanges,
  getRoomCharges,
  getRoomFees,
  IMAGE_SIZE,
} from '../../pages/Communities/CommunitiesTable/communityService';
import CommunityPriceRange from '../../pages/Communities/CommunityPriceRange/CommunityPriceRange';
import X from '../../common/Icons/basic/X';
import disclosureService from '../../services/disclosureService';
import SmartTooltip from '../../common/SmartTooltip/SmartTooltip';
import { getTags } from '../../services/communityTagsService';
const Community = ({
  community,
  dataForAnalytics,
  familyFileId,
  familyFileUserId,
  imageUrl,
  labels,
  onChange,
  onRemove,
  stateCodesWithCompletedDisclosures,
  tourDetails,
  tourDraft,
}) => {
  const classes = useStyles();

  const RegularCheckbox = () => {
    return (
      <Checkbox
        checked={community.isChecked}
        className={classes.checkbox}
        onChange={onChange}
      />
    );
  };

  const disableScheduleTourForHomeCare = (community) => {
    return (
      community.careTypes.homeCare ||
      community.communityStatus === 'Closed' ||
      community.isRejected
    );
  };

  const validateTitleForCheckBox = () => {
    let title = '';
    if (
      disclosureService.isDisclosureStateWithoutDisclosure(
        community.state,
        stateCodesWithCompletedDisclosures,
      )
    ) {
      title =
        labels.COMMUNITY_DISCLOSURE_MESSAGE_1.replace(
          '{state}',
          disclosureService.getDisclosureStateName(community.state),
        ) +
        labels.COMMUNITY_DISCLOSURE_MESSAGE_2 +
        labels.COMMUNITY_DISCLOSURE_MESSAGE_3;
    } else if (community.communityStatus === 'Closed') {
      title = labels.CLOSED_COMMUNITY_TOOLTIP_TEXT;
    } else if (community.isRejected) {
      title = labels.COMMUNITY_REJECTED_REFERALL;
    }
    return title;
  };

  const getCheckbox = () => {
    const titleText = validateTitleForCheckBox();

    if (titleText !== '') {
      return (
        <SmartTooltip title={titleText} placement="bottom">
          <Checkbox disabled className={classes.checkbox} />
        </SmartTooltip>
      );
    } else {
      return <RegularCheckbox />;
    }
  };

  return (
    <div className={classes.community_container}>
      {getCheckbox()}
      <CommunityImage
        className={classes.community_image}
        imageId={getDisplayImageId(community.images, IMAGE_SIZE.SMALL)}
        imageUrl={imageUrl}
        size="xs"
        tags={getTags(community)}
      />
      <CommunityInfoLG
        address={community.address}
        city={community.city}
        className={classes.community_info}
        communityId={community.id}
        companyName={community.organizationName}
        dataForAnalytics={dataForAnalytics}
        disableScheduleTourForHomeCare={
          community.isRejected
            ? community.isRejected
            : disableScheduleTourForHomeCare(community)
        }
        distance={community.distance}
        familyFileId={familyFileId}
        familyFileUserId={familyFileUserId}
        isCalendarPastDisabled={true}
        name={community.name}
        phone={community.phoneNumber}
        state={community.state}
        tourDetail={tourDetails}
        tourDraft={tourDraft}
        zip={community.zip}
      />
      <CommunityPriceRange
        careTypes={community.careTypes}
        careTypeStyle="tag"
        communityId={community.id}
        className={classes.price_range_container}
        communityName={community.name}
        labels={labels}
        prices={getPriceRanges(community.careTypes, community.roomCharges)}
        roomCharges={getRoomCharges(community.roomCharges)}
        roomFees={getRoomFees(community.roomFees)}
      />
      <div
        className={classes.remove_icon}
        onClick={() => {
          onRemove(community);
        }}
      >
        <X />
      </div>
    </div>
  );
};

Community.propTypes = {
  community: PropTypes.object,
  dataForAnalytics: PropTypes.shape({
    careTypes: PropTypes.object,
    originatingPage: PropTypes.string,
  }),
  disableScheduleTourForHomeCare: PropTypes.func,
  familyFileId: PropTypes.number,
  familyFileUserId: PropTypes.number,
  imageUrl: PropTypes.string.isRequired,
  labels: PropTypes.object,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  stateCodesWithCompletedDisclosures: PropTypes.arrayOf(PropTypes.string),
  tourDetails: PropTypes.shape({
    communityId: PropTypes.number,
    familyCommunityTourId: PropTypes.number,
    scheduledTourDate: PropTypes.number,
    tourType: PropTypes.string,
  }),
  tourDraft: PropTypes.object,
};

export default Community;
