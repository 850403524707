import gql from 'graphql-tag';

export default gql`
  mutation createFamilyFileNote($input: FamilyFileAnyNoteInput!) {
    createFamilyFileNote(input: $input) {
      familyFileId
      familyNoteId
      noteFamilyFileId
    }
  }
`;
