import gql from 'graphql-tag';

export default gql`
  mutation upsertFamilyLetterNotes($input: UpsertFamilyLetterNotesInput!) {
    upsertFamilyLetterNotes(input: $input) {
      familyLetterNotes {
        familyLetterNoteId
        communityId
        createdAt
        createdBy
        updatedAt
        updatedBy
        subject
        introduction
        communityIntroduction
        communityNote
        closingParagraph
        template
        links
        sendTo
        additionalNotes
      }
    }
  }
`;
