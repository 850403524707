import React from 'react';
import PropTypes from 'prop-types';

const Bread = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 20V9.46487C22.1956 8.77325 23 7.48056 23 6C23 3.79086 21.2091 2 19 2H5C2.79086 2 1 3.79086 1 6C1 7.48056 1.8044 8.77325 3 9.46487V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20ZM19 20H5V8.31129L4.00145 7.73366C3.38553 7.37737 3 6.72334 3 6C3 4.89543 3.89543 4 5 4H19C20.1046 4 21 4.89543 21 6C21 6.72334 20.6145 7.37737 19.9985 7.73366L19 8.31129V20Z"
        fill="currentColor"
      />
    </svg>
  );
};

Bread.propTypes = {
  className: PropTypes.string,
};

export default Bread;
