import timezones from './NorthAmerican-timezones';

const timezoneType = ['Standard', 'Daylight'];
const timezoneItems = timezones
  .filter((timezone) => !timezone.hidden)
  .map((timezone) => ({
    label: timezone.text,
    value: timezone.utc[0],
    isdst: timezone.isdst,
    abbr: timezone.abbr,
    offset: timezone.offset.toString(),
  }));

/* Arizona uses MST all year */
const getMSTzone = timezones
  .filter((timezone) => timezone.abbr === 'MST')
  .map((timezone) => ({
    label: timezone.text,
    value: timezone.utc[0],
    isdst: timezone.isdst,
    abbr: timezone.abbr,
    offset: timezone.offset.toString(),
  }))[0];

const getMDTzone = timezones
  .filter((timezone) => timezone.abbr === 'MDT')
  .map((timezone) => ({
    label: timezone.text,
    value: timezone.utc[0],
    isdst: timezone.isdst,
    abbr: timezone.abbr,
    offset: timezone.offset.toString(),
  }))[0];

export { timezoneItems, timezoneType, getMSTzone, getMDTzone };
