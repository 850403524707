import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CallRecieved from '../../common/Icons/call/CallRecieved';
import CallDecline from '../../common/Icons/call/CallDecline';
import CallVoicemail from '../../common/Icons/call/CallVoicemail';
import Typography from '../../common/Typography/Typography';
import { getTodayOrYesterdayOrPastDate } from '../../utils/dateFormat';
import { useStyles } from './HomeTable.style';
import Mail from '../../common/Icons/basic/Mail';
import CallPhone from '../../common/Icons/call/CallPhone';
import CommentChecked from '../../common/Icons/chatting/CommentChecked';
import Headphone from '../../common/Icons/music/Headphone';

const HomeTableLastCallCell = ({ row, labels }) => {
  const classes = useStyles();

  const lastCallIcon = {
    'No Contact': <CallDecline />,
    'Left VM': <CallVoicemail />,
    Conversation: <CallRecieved />,
  };

  const lastContactIconClass = {
    'No Contact': classes.red_icon,
    'Left VM': classes.red_icon,
    Conversation: classes.green_icon,
  };

  const isCallAction = (action) => {
    return action.type.match(new RegExp('Call', 'gi'));
  };

  const isEmailAction = (action) => {
    return action.type.match(new RegExp('Mail', 'gi'));
  };

  const isTextAction = (action) => {
    return action.type.match(new RegExp('Text', 'gi'));
  };

  const isOutReachAction = (action) => {
    return action.type.match(new RegExp('Outreach', 'gi'));
  };

  const getLastCallText = (lastCall) => {
    if (lastCall.type === 'Call - Property') {
      return 'Called - Property';
    }
    return `${lastCall.type.replace('Call -', 'Called')} - ${lastCall.result}`;
  };

  const getLastEmailText = (action) => {
    return `${action.type.replace('E-Mail', 'Emailed')}`;
  };

  const getLastText = (action) => {
    return `${action.type.replace('Text', 'Texted')}`;
  };

  const getText = (action) => {
    if (isCallAction(action)) {
      return getLastCallText(action);
    }
    if (isEmailAction(action)) {
      return getLastEmailText(action);
    }
    if (isTextAction(action)) {
      return getLastText(action);
    }
    return `${action.type}`;
  };

  const getLastCallIcon = (lastCall) => {
    if (lastCall.type === 'Call - Property') {
      return lastCallIcon.Conversation;
    }
    return lastCallIcon[lastCall.result];
  };

  const getIcon = (action) => {
    if (isCallAction(action)) {
      return getLastCallIcon(action);
    }
    if (isEmailAction(action)) {
      return <Mail />;
    }
    if (isTextAction(action)) {
      return <CommentChecked />;
    }
    if (isOutReachAction(action)) {
      return <Headphone />;
    }
    return <CallPhone />;
  };

  const getLastContactIconClass = (action) => {
    if (isCallAction(action)) {
      if (action.type === 'Call - Property') {
        return lastContactIconClass.Conversation;
      }
      return lastContactIconClass[action.result];
    }
    if (isEmailAction(action)) {
      return classes.green_icon;
    }
    return classes.green_icon;
  };

  if (row.lastCall) {
    return (
      <Fragment>
        <div className={classes.call_info}>
          {
            <div className={getLastContactIconClass(row.lastCall)}>
              {getIcon(row.lastCall)}
            </div>
          }
          <Typography bold>{getText(row.lastCall)}</Typography>
        </div>
        <Typography color="eerieBlack5" className={classes.last_call_date}>
          {getTodayOrYesterdayOrPastDate(row.lastCall.completedAt) ||
            labels.UNKNOWN}
        </Typography>
      </Fragment>
    );
  }
  return (
    <Typography color="eerieBlack5">{labels.NOT_CONTACTED_YET}</Typography>
  );
};

HomeTableLastCallCell.propTypes = {
  row: PropTypes.object.isRequired,
  labels: PropTypes.shape({
    UNKNOWN: PropTypes.string.isRequired,
    NOT_CONTACTED_YET: PropTypes.string,
  }).isRequired,
};

export default HomeTableLastCallCell;
