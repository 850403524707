const customChiclets = [
  'contactInfo',
  'familyInfo',
  'locationInfo',
  'transferSla',
  'slaMatch',
  'slaNotFound',
];

const isCustomChiclet = (type) => customChiclets.includes(type);

const customChicletsService = {
  isCustomChiclet,
};

export default customChicletsService;
