import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './Table.style';
import clsx from 'clsx';

const Table = ({ className, children }) => {
  const classes = useStyles();
  const containerClasses = clsx(className, classes.table);
  return <table className={containerClasses}>{children}</table>;
};

Table.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Table;
