import { makeStyles } from '../../Theme/Theme';

const useStyles = makeStyles(() => {
  return {
    table: {
      width: '100%',
      display: 'table',
      borderSpacing: 0,
      borderCollapse: 'collapse',
    },
  };
});

export { useStyles };
