import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ArrowBoldForvardAll from 'common/Icons/arrow/ArrowBoldForvardAll';
import ChevronBottom from 'common/Icons/arrow/ChevronBottom';
import ChevronTop from 'common/Icons/arrow/ChevronTop';
import Typography from 'common/Typography/Typography';
import { withLabels } from 'context/LabelContext';
import { getItems } from './items';
import { useStyles } from './TransferDropdown.style';

const TransferDropdown = ({ labels, className, onChange, disabled }) => {
  const classes = useStyles();
  const elements = getItems(labels);
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleMenuItemClick = (element) => {
    onChange(element);
    setIsOpen(false);
  };

  const onClickDropDown = () => {
    if (disabled) return {};
    return setIsOpen(!isOpen);
  };

  return (
    <div
      ref={wrapperRef}
      className={clsx(className, classes.transfer_dropdown_container)}
    >
      <div
        className={clsx(
          classes.transfer_button,
          isOpen && classes.active,
          disabled && classes.disabled,
        )}
        onClick={onClickDropDown}
      >
        <ArrowBoldForvardAll className={classes.icon} />
        <Typography color={disabled ? 'disabled' : 'eerieBlack1'}>
          {labels.TRANSFER}
        </Typography>
        {isOpen ? (
          <ChevronTop className={classes.arrow} />
        ) : (
          <ChevronBottom className={classes.arrow} />
        )}
      </div>
      {isOpen && (
        <div className={classes.menu}>
          <Typography
            className={classes.group_name}
            color="eerieBlack3"
            level="small"
          >
            {labels.TRANSFER_OPTIONS}
          </Typography>
          {elements.map((e, k) => (
            <div
              onClick={() => handleMenuItemClick(e)}
              key={k}
              data-testid={`status-drop-${k}`}
              className={classes.menu_item}
            >
              <Typography>{e.label}</Typography>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

TransferDropdown.propTypes = {
  labels: PropTypes.object,
  className: PropTypes.string,
  onChange: PropTypes.func,
  setInternalTransferCall: PropTypes.func,
  disabled: PropTypes.bool,
};

TransferDropdown.defaultProps = {
  onChange: () => {},
  disabled: false,
};

export default withLabels(TransferDropdown);
