import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    radio_container: {
      '& legend, span': {
        fontFamily: 'Poppins',
        fontSize: '15px',
      },
    },
    radio: {
      color: theme.palette.dark.eerieBlack1,
      '&.MuiRadio-colorSecondary.Mui-checked': {
        color: theme.palette.neutral.cosmicCobalt3,
      },
    },
    label_container: {
      marginRight: '12px',
      color: theme.palette.dark.eerieBlack1,
    },
    tooltip_container: {
      alignSelf: 'start',
    },
  };
});

export { useStyles };
