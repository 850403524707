import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  modal_container: {
    maxWidth: '580px',
  },
  title_container: {
    background: theme.palette.neutral.cosmicCobalt2,
    color: theme.palette.light.platinum2,
  },
  modal_body: {
    padding: theme.spacing(3),
    fontFamily: 'Poppins',
  },
  search_animation: {
    textAlign: 'center',
  },
  modal_footer: {
    borderTop: `1px solid ${theme.palette.light.platinum1}`,
    display: 'flex',
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(3),
    },
  },
  subtitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '22px',
    margin: '12px',
  },
}));

export { useStyles };
