import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './CallWrapper.style';
import CallBar from '../Inquiry/CallBar/CallBar';
import { useSelector } from 'react-redux';
import Container from 'common/Container/Container';
import ContainerItem from 'common/ContainerItem/ContainerItem';
import SideBar from '../Inquiry/SideBar';
import { selectInquiry } from 'stateManagement/callcenter/inquiry/reducers/inquiryReducer';
import { selectFormChiclets } from 'stateManagement/callcenter/formChiclets/reducers/formChicletsReducer';
import { CallBarScreenType } from '../Inquiry/CallBar/callBarScreenEnum';

const CallWrapper = ({
  children,
  onEndCall,
  onTransfer,
  onReschedule,
  onRedial,
  isWaitingCallDisplayed,
  onUpdateStatusDetail,
  screen,
}) => {
  const classes = useStyles();

  const { inquiry } = useSelector(selectInquiry);
  const { formSteps, formChiclets } = useSelector(selectFormChiclets);
  const rescheduleDisposition =
    formSteps?.find((formStep) => formStep.type === 'completeReschedule')
      ?.answers[0]?.disposition || 'CS Customer Callback';

  return (
    <div className={classes.new_inquiry_container}>
      <CallBar
        onEndCall={(firstValue, secondValue) =>
          onEndCall(firstValue, 'closed', true, secondValue)
        }
        onRedial={onRedial}
        onTransfer={onTransfer}
        onReschedule={onReschedule}
        rescheduleDisposition={rescheduleDisposition}
        onUpdateStatusDetail={onUpdateStatusDetail}
        screen={screen}
      />
      <div className={classes.body}>
        <Container container spacing={6} className={classes.main_container}>
          <ContainerItem item xs={8} sm={3} xl={2} className={classes.side_bar}>
            {formSteps?.length > 0 && (
              <SideBar
                inquiry={inquiry}
                formSteps={formSteps}
                formChiclets={formChiclets}
                displayOptionalChiclets={!isWaitingCallDisplayed}
              />
            )}
          </ContainerItem>
          <ContainerItem
            item
            xs={12}
            sm={9}
            xl={10}
            className={classes.center_container}
          >
            {children}
          </ContainerItem>
        </Container>
      </div>
    </div>
  );
};

CallWrapper.defaultProps = {
  onReschedule: () => {},
  onUpdateStatusDetail: () => {},
};

CallWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onEndCall: PropTypes.func,
  onTransfer: PropTypes.func,
  onReschedule: PropTypes.func,
  onRedial: PropTypes.func,
  onUpdateStatusDetail: PropTypes.func,
  isWaitingCallDisplayed: PropTypes.bool,
  screen: CallBarScreenType.isRequired,
};

export default CallWrapper;
