import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withConfig from 'utils/withConfig';
import { useStyles } from './CommunitiesTable.style';
import TableRowCard from 'common/TableCard/TableRowCard/TableRowCard';
import SaveCommunity from '../SaveCommunity/SaveCommunity';
import CommunityImage from '../CommunityImage/CommunityImage';
import CommunityPriceRange from '../CommunityPriceRange/CommunityPriceRange';
import CommunityInfoXS from '../CommunityInfo/CommunityInfoXS';
import CommunityLinks from '../CommunityLinks/CommunityLinks';
import { getTags } from 'services/communityTagsService';
import {
  getDisplayImageId,
  getPriceRanges,
  getRoomCharges,
  getRoomFees,
  IMAGE_SIZE,
} from './communityService';
import CommunityAdvisory from '../CommunityAdvisory/CommunityAdvisory';
import {
  getActiveAdvisoryTypeLabels,
  getCovidVaccineInformation,
} from '../CommunityAdvisory/CommunityAdvisoryService';
import SpecialFeatures from './SpecialFeatures/SpecialFeatures';

const CommunitiesTableRow = ({
  labels,
  onClick,
  onHover,
  onSave,
  onRemove,
  onUpdate,
  onProfileClick,
  onAttributeClick,
  familyFileId,
  community,
  dataForSegment,
  locationFilter,
  isSavable,
  isSelected,
  size,
  communitiesFeature,
  otherFilters,
}) => {
  const classes = useStyles();
  const imageUrl = withConfig('IMAGE_URL');

  const getSpecialFeaturesForCommunity = (community, filters) => {
    const availableFiltersInCommunity = community.attributes
      .flatMap((attribute) => {
        return attribute.groupAttributes.filter((ga) => ga.isAvailable);
      })
      .map((attribute) => attribute.name);

    const validFilters = filters.filter((filter) => {
      return !!availableFiltersInCommunity.find(
        (f) => f === filter.value || filter?.attributeNames?.includes(f),
      );
    });
    return validFilters.map((valid) => {
      if (valid.isGroup) {
        return {
          ...valid,
          attributeNames: valid.attributeNames.filter((name) => {
            return availableFiltersInCommunity.includes(name);
          }),
        };
      }
      return valid;
    });
  };

  const activeAdvisoryTypes = getActiveAdvisoryTypeLabels(community.advisories);

  return (
    <div
      data-id={community.id}
      onClick={() => onClick(community)}
      onMouseEnter={() => onHover(community)}
    >
      <TableRowCard
        className={clsx(
          size === 'lg'
            ? classes.communities_table_row_lg_container
            : classes.communities_table_row_xs_container,
          isSelected && classes.selected_community,
        )}
      >
        <>
          <div className={classes.left_container}>
            {isSavable && (
              <SaveCommunity
                labels={labels}
                onSave={onSave}
                onRemove={onRemove}
                familyFileId={familyFileId}
                community={community}
                dataForSegment={dataForSegment}
                locationFilter={locationFilter}
                onUpdate={onUpdate}
              />
            )}
            <CommunityImage
              imageId={getDisplayImageId(community.images, IMAGE_SIZE.SMALL)}
              imageUrl={imageUrl}
              size="xs"
              tags={getTags(community)}
            />
            <CommunityInfoXS
              labels={labels}
              communityId={community.id}
              address={community.address}
              phone={community.phoneNumber}
              companyName={community.organizationName}
              name={community.name}
              city={community.city}
              state={community.state}
              distance={community.distance}
              zip={community.zip}
              referred={community.countReferred}
              moveIns={community.countMovedIn}
              careTypes={community.careTypes}
              ratingAverage={community.ratingAverage}
              numberOfReviews={community.numberOfReviews}
              communitiesFeature={communitiesFeature}
              vaccineInformation={getCovidVaccineInformation(
                community.advisories,
              )}
              logVaccineClick={() => {
                onAttributeClick(community, 'Covid Vaccine');
              }}
            />
          </div>
          <div>
            <CommunityPriceRange
              careTypeStyle="tag"
              labels={labels}
              prices={getPriceRanges(
                community.careTypes,
                community.roomCharges,
              )}
              roomFees={getRoomFees(community.roomFees)}
            />
          </div>
          {activeAdvisoryTypes.length > 0 && (
            <>
              <div className={classes.break}></div>
              <CommunityAdvisory
                labels={labels}
                communityId={community.id}
                communityName={community.name}
                openAdvisoryTypes={activeAdvisoryTypes}
                logAdvisoryClick={() => {
                  onAttributeClick(community, 'Advisory');
                }}
              />
            </>
          )}
          <div className={classes.break}></div>
          <div className={classes.last_container}>
            <SpecialFeatures
              features={getSpecialFeaturesForCommunity(community, otherFilters)}
            />
            <CommunityLinks
              careTypes={community.careTypes}
              careTypeStyle="tag"
              communityId={community.id}
              communityName={community.name}
              labels={labels}
              onProfileClick={onProfileClick}
              onRatesClick={() => {
                onAttributeClick(community, 'Detailed Rates');
              }}
              roomCharges={getRoomCharges(community.roomCharges)}
              roomFees={getRoomFees(community.roomFees)}
            />
          </div>
        </>
      </TableRowCard>
    </div>
  );
};

CommunitiesTableRow.propTypes = {
  labels: PropTypes.object,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  onSave: PropTypes.func,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  familyFileId: PropTypes.number,
  community: PropTypes.object,
  locationFilter: PropTypes.object,
  isSavable: PropTypes.bool,
  isSelected: PropTypes.bool,
  dataForSegment: PropTypes.shape({
    familyFileId: PropTypes.number,
    oneId: PropTypes.string,
  }),
  size: PropTypes.oneOf(['xs', 'lg']),
  communitiesFeature: PropTypes.array,
  onProfileClick: PropTypes.func,
  onAttributeClick: PropTypes.func,
  otherFilters: PropTypes.array,
};

CommunitiesTableRow.defaultProps = {
  isSavable: false,
  isSelected: false,
  size: 'xs',
  onClick: () => {},
  onUpdate: () => {},
  onHover: () => {},
  onProfileClick: () => {},
  onAttributeClick: () => {},
};

export default CommunitiesTableRow;
