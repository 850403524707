import React from 'react';
import { useStyles } from './ViewCard.style';
import { useBackgroundColorStyles } from '../../style/backgroundColor';
import { colorReference } from '../../Theme/themes/default';
import Typography from '../../Typography/Typography';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ExternalLink from '../../Icons/basic/ExternalLink';

const ViewCard = ({
  title,
  buttonTitle,
  icon,
  children,
  className,
  headerColor,
  viewButtonColor,
  clickCallback,
  buttonDisabled,
}) => {
  const classes = useStyles();
  const backgroundClasses = useBackgroundColorStyles();

  const handleOnClick = () => {
    if (!buttonDisabled) {
      clickCallback();
    }
  };
  const buttonBg = buttonDisabled ? 'disabled' : viewButtonColor;

  return (
    <div className={clsx(classes.container, className)}>
      <div className={classes.head_container}>
        <div className={clsx(classes.head, backgroundClasses[headerColor])}>
          {icon}
          <Typography color="inherit" level="small">
            <b>{title}</b>
          </Typography>
        </div>
        <div
          className={clsx(
            classes.view_container,
            backgroundClasses[buttonBg],
            buttonDisabled && classes.button_disabled,
          )}
          onClick={handleOnClick}
        >
          <ExternalLink />
          <Typography color="inherit" level="small">
            {buttonTitle}
          </Typography>
        </div>
      </div>
      <div className={clsx(classes.body, className)}>{children}</div>
    </div>
  );
};

ViewCard.propTypes = {
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
  viewButtonColor: PropTypes.string,
  clickCallback: PropTypes.func.isRequired,
  buttonDisabled: PropTypes.bool,
  headerColor: PropTypes.oneOf(colorReference),
};

ViewCard.defaultProps = {
  icon: <div />,
  className: '',
  headerColor: 'platinum4',
  buttonTitle: 'View',
  viewButtonColor: 'platinum4',
  buttonDisabled: false,
};

export default ViewCard;
