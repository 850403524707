import gql from 'graphql-tag';

export default gql`
  mutation($inquiryId: Int!, $value: String!) {
    createInquiryDisclaimerLog(
      input: { inquiryId: $inquiryId, value: $value }
    ) {
      disclaimerLogId
    }
  }
`;
