import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './CommunityLinks.style';
import withConfig from 'utils/withConfig';
import { hasNonHomeCareType } from './../CommunityPriceRange/CommunityPriceRangeService';
import RoomChargesModal from './../CommunityPriceRange/RoomChargesModal';
import Typography from 'common/Typography/Typography';
import Link from 'common/Link/Link';
import Divider from 'common/Divider/Divider';
import { registerEvent } from 'services/Analytics';

const CommunityLinks = ({
  careTypes,
  careTypeStyle,
  communityId,
  communityName,
  onProfileClick,
  onRatesClick,
  labels,
  roomCharges,
  roomFees,
}) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const careTypeName = {
    A: labels.ASSISTED_LIVING,
    Z: labels.MEMORY_CARE,
    N: labels.NURSING_HOME,
    H: labels.HOME_CARE,
    I: labels.INDEPENDENT_LIVING,
    M: labels.RESPITE_CARE,
    D: labels.ADULT_DAY_SERVICES,
    O: labels.NOT_SURE,
    G: labels.RESIDENTIAL_CARE_HOME,
    P: labels.SENIOR_APARTMENTS,
    R: labels.RETIREMENT_COMMUNITY,
  };

  const handleRateDetailsClick = () => {
    onRatesClick();
    registerEvent('FamilyFiles', 'Click to view Room Charges', undefined, true);
    setIsModalOpen(true);
  };

  const handleViewInfoClick = (event) => {
    event.preventDefault();
    onProfileClick();
    window.open(
      withConfig('YGL_PROPERTY_PROFILE_URL', { yglPropertyID: communityId }),
      '_blank',
    );
  };

  return (
    <>
      <RoomChargesModal
        careTypeNames={careTypeName}
        careTypes={careTypes}
        careTypeStyle={careTypeStyle}
        communityName={communityName}
        isModalOpen={isModalOpen}
        labels={labels}
        onClose={() => setIsModalOpen(false)}
        roomCharges={roomCharges}
        roomFees={roomFees}
      />
      <div className={classes.link_container}>
        <div className={classes.links}>
          {hasNonHomeCareType(careTypes) && (
            <>
              {roomCharges.length >= 0 && (
                <Link onClick={(event) => handleRateDetailsClick(event)}>
                  <Typography color="cosmicCobalt3" level="small">
                    {labels.DETAILED_RATES.toLowerCase()}
                  </Typography>
                </Link>
              )}
              {roomCharges.length === 0 && (
                <Typography color="disabled" level="small">
                  {labels.RATES_UNAVAILABLE}
                </Typography>
              )}
              <Divider
                className={classes.link_divider}
                color="disabled"
              ></Divider>
            </>
          )}
          <Link onClick={(event) => handleViewInfoClick(event)}>
            <Typography color="cosmicCobalt3" level="small">
              {labels.VIEW_PROFILE.toLowerCase()}
            </Typography>
          </Link>
        </div>
      </div>
    </>
  );
};

CommunityLinks.propTypes = {
  careTypes: PropTypes.object,
  careTypeStyle: PropTypes.oneOf(['tag', 'map']),
  communityId: PropTypes.string.isRequired,
  communityName: PropTypes.string,
  labels: PropTypes.object,
  onProfileClick: PropTypes.func,
  onRatesClick: PropTypes.func,
  roomCharges: PropTypes.array,
  roomFees: PropTypes.array,
};

CommunityLinks.defaultProps = {
  careTypeStyle: 'tag',
  onProfileClick: () => {},
  onRatesClick: () => {},
};

export default CommunityLinks;
