import React from 'react';
import labels from 'config/labels';
import AmbulatoryAssistance from 'common/Icons/lineIcons/healthcareServices/AmbulatoryAssistance';
import DiabeticCare from 'common/Icons/lineIcons/healthcareServices/DiabeticCare';
import IncontinenceCare from 'common/Icons/lineIcons/healthcareServices/IncontinenceCare';
import MemoryCare from 'common/Icons/lineIcons/healthcareServices/MemoryCare';
import HighAcuityCare from 'common/Icons/lineIcons/healthcareServices/HighAcuityCare';
import MedicationManagement from 'common/Icons/lineIcons/healthcareServices/MedicationManagement';
import SkilledServices from 'common/Icons/lineIcons/healthcareServices/SkilledServices';

const ambulatoryCare = [
  {
    label: labels.STANDBY_ASSISTANCE,
    value: 'Standby Assistance',
    isGroup: true,
    attributeNames: [
      'Standby Assistance',
      '1 Person Assisted Transfer',
      '2 Person Assisted Transfer',
      'Total Transfer / Hoyer Lift',
    ],
    selected: false,
    icon: <AmbulatoryAssistance />,
    groupIcons: false,
    type: labels.AMBULATORY_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.ONE_PERSON_ASSISTED_TRANSFER,
    value: '1 Person Assisted Transfer',
    isGroup: true,
    attributeNames: [
      '1 Person Assisted Transfer',
      '2 Person Assisted Transfer',
      'Total Transfer / Hoyer Lift',
    ],
    selected: false,
    icon: <AmbulatoryAssistance />,
    groupIcons: false,
    type: labels.AMBULATORY_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.TWO_PERSON_ASSISTED_TRANSFER,
    value: '2 Person Assisted Transfer',
    isGroup: true,
    attributeNames: [
      '2 Person Assisted Transfer',
      'Total Transfer / Hoyer Lift',
    ],
    selected: false,
    icon: <AmbulatoryAssistance />,
    groupIcons: false,
    type: labels.AMBULATORY_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.TOTAL_TRANSFER_HOYER_LIFT,
    value: 'Total Transfer / Hoyer Lift',
    selected: false,
    icon: <AmbulatoryAssistance />,
    groupIcons: false,
    type: labels.AMBULATORY_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
];

const diabeticCare = [
  {
    label: labels.RESIDENTS_MONITOR_BLOOD_SUGAR,
    value: 'Self Management',
    selected: false,
    icon: <DiabeticCare />,
    groupIcons: true,
    type: labels.DIABETIC_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.STAFF_MONITOR_BLOOD_SUGAR,
    value: 'Property Can Monitor Blood Sugar for Pill Dosage',
    selected: false,
    icon: <DiabeticCare />,
    groupIcons: true,
    type: labels.DIABETIC_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.ADMINISTER_INSULIN_INJECTIONS,
    value: 'Property Can Administer Insulin Injections',
    selected: false,
    icon: <DiabeticCare />,
    groupIcons: true,
    type: labels.DIABETIC_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.ADMINISTER_SLIDING_SCALE,
    value: 'Property Can Administer Sliding Scale',
    selected: false,
    icon: <DiabeticCare />,
    groupIcons: true,
    type: labels.DIABETIC_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
];

const highAcuityCare = [
  {
    label: labels.CATHETER,
    value: 'Catheter Care',
    selected: false,
    icon: <HighAcuityCare />,
    groupIcons: true,
    type: labels.HIGH_ACUITY_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.COLOSTOMY,
    value: 'Colostomy Care',
    selected: false,
    icon: <HighAcuityCare />,
    groupIcons: true,
    type: labels.HIGH_ACUITY_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.FEEDING_TUBE,
    value: 'Feeding Tube',
    selected: false,
    icon: <HighAcuityCare />,
    groupIcons: true,
    type: labels.HIGH_ACUITY_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.OXYGEN,
    value: 'Oxygen',
    selected: false,
    icon: <HighAcuityCare />,
    groupIcons: true,
    type: labels.HIGH_ACUITY_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.WOUND_CARE,
    value: 'Wound Care',
    selected: false,
    icon: <HighAcuityCare />,
    groupIcons: true,
    type: labels.HIGH_ACUITY_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.IV_CARE,
    value: 'IVs',
    selected: false,
    icon: <HighAcuityCare />,
    groupIcons: true,
    type: labels.HIGH_ACUITY_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.HISTORY_OF_PSYCHIATRIC_CARE,
    value: 'Psychiatric Care',
    selected: false,
    icon: <HighAcuityCare />,
    groupIcons: true,
    type: labels.HIGH_ACUITY_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.VENTILATOR_CARE,
    value: 'Ventilators',
    selected: false,
    icon: <HighAcuityCare />,
    groupIcons: true,
    type: labels.HIGH_ACUITY_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.HISTORY_OF_SUICIDAL_IDEATION,
    value: 'Danger to themselves or others/Suicidal ideation',
    selected: false,
    icon: <HighAcuityCare />,
    groupIcons: true,
    type: labels.HIGH_ACUITY_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
];

const incontinenceCare = [
  {
    label: labels.SELF_MANAGEMENT,
    value: 'Self Management',
    selected: false,
    icon: <IncontinenceCare />,
    groupIcons: true,
    type: labels.INCONTINENCE_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.REMINDERS,
    value: 'Reminders',
    selected: false,
    icon: <IncontinenceCare />,
    groupIcons: true,
    type: labels.INCONTINENCE_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.URINARY,
    value: 'Incontinence Care - Bladder',
    selected: false,
    icon: <IncontinenceCare />,
    groupIcons: true,
    type: labels.INCONTINENCE_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.BOWEL,
    value: 'Incontinence Care - Bowel',
    selected: false,
    icon: <IncontinenceCare />,
    groupIcons: true,
    type: labels.INCONTINENCE_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
];

const medicationManagement = [
  {
    label: labels.MEDICATION_MANAGEMENT_SELF,
    value: 'No medication services (resident self-medicates)',
    selected: false,
    icon: <MedicationManagement />,
    groupIcons: true,
    type: labels.MEDICATION_MANAGEMENT,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.MEDICATION_MANAGEMENT_REMINDERS,
    value: 'Reminders and monitoring',
    selected: false,
    icon: <MedicationManagement />,
    groupIcons: true,
    type: labels.MEDICATION_MANAGEMENT,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.MEDICATION_MANAGEMENT_FULL,
    value: 'Community administers medications',
    selected: false,
    icon: <MedicationManagement />,
    groupIcons: true,
    type: labels.MEDICATION_MANAGEMENT,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.MEDICATION_MANAGEMENT_MED_AID,
    value: 'Community administers injections(medication aides)',
    selected: false,
    icon: <MedicationManagement />,
    groupIcons: true,
    type: labels.MEDICATION_MANAGEMENT,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.MEDICATION_MANAGEMENT_LPN,
    value: 'Community administers injections (licensed nurses)',
    selected: false,
    icon: <MedicationManagement />,
    groupIcons: true,
    type: labels.MEDICATION_MANAGEMENT,
    is: labels.HEALTHCARE_SERVICES,
  },
];

const memoryCare = [
  {
    label: labels.RESTLESSNESS_PACING_WANDERING,
    value: 'Wandering',
    selected: false,
    icon: <MemoryCare />,
    groupIcons: true,
    type: labels.MEMORY_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.HALLUCINATIONS_AND_DELUSIONS,
    value: 'Behavioral Issues',
    selected: false,
    icon: <MemoryCare />,
    groupIcons: true,
    type: labels.MEMORY_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.REDIRECTION_CUING_DISORIENTATION,
    value: 'Disorientation/Lack of Awareness',
    selected: false,
    icon: <MemoryCare />,
    groupIcons: true,
    type: labels.MEMORY_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.EXIT_SEEKING_BEHAVIORS,
    value: 'Exit-seeking behaviors',
    selected: false,
    icon: <MemoryCare />,
    groupIcons: true,
    type: labels.MEMORY_CARE,
    is: labels.HEALTHCARE_SERVICES,
  },
];

const skilledServices = [
  {
    label: labels.SKILLED_SERVICES_NURSES,
    value: 'Nurses on Staff',
    selected: false,
    icon: <SkilledServices />,
    groupIcons: true,
    type: labels.SKILLED_SERVICES,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.SKILLED_SERVICES_24_HOUR,
    value: '24-Hour Awake Staff',
    selected: false,
    icon: <SkilledServices />,
    groupIcons: true,
    type: labels.SKILLED_SERVICES,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.SKILLED_SERVICES_AGENCY,
    value: 'Homecare Onsite',
    selected: false,
    icon: <SkilledServices />,
    groupIcons: true,
    type: labels.SKILLED_SERVICES,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.SKILLED_SERVICES_HOSPICE,
    value: 'Hospice Available',
    selected: false,
    icon: <SkilledServices />,
    groupIcons: true,
    type: labels.SKILLED_SERVICES,
    is: labels.HEALTHCARE_SERVICES,
  },
  {
    label: labels.SKILLED_SERVICES_NURSING,
    value: 'Skilled nursing',
    selected: false,
    icon: <SkilledServices />,
    groupIcons: true,
    type: labels.SKILLED_SERVICES,
    is: labels.HEALTHCARE_SERVICES,
  },
];

const healthcareServices = [
  ...ambulatoryCare,
  ...diabeticCare,
  ...highAcuityCare,
  ...incontinenceCare,
  ...medicationManagement,
  ...memoryCare,
  ...skilledServices,
];

export default healthcareServices;
