import withConfig from '../utils/withConfig';

const MAIN_IFRAME_ID = 'shared-login';
const TIMEOUT = 2;
let queue = [];
let isRemoteStorageReady;
let responseChecker = [];

const getIframe = () => {
  let iframe = window.document.getElementById(MAIN_IFRAME_ID);
  if (!iframe) {
    window.addEventListener('message', (event) => handleMessage(event), true);
    iframe = document.createElement('iframe');
    iframe.style.cssText =
      'position:absolute;width:1px;height:1px;left:-9999px;';
    iframe.sandbox.add('allow-scripts');
    iframe.sandbox.add('allow-same-origin');
    iframe.id = MAIN_IFRAME_ID;
    iframe.src = withConfig('CROSS_DOMAIN_STORAGE_URL');
    document.body.appendChild(iframe);
  }
  return iframe;
};

const tryJsonParse = (data) => {
  // Ignore timer related messages
  if (data?.substr(0, 12) === 'setImmediate') {
    return [false];
  }
  try {
    const parsedData = JSON.parse(data);
    return [true, parsedData];
  } catch (error) {
    console.error(
      `crossDomainStorage. JSON.parse failed. Error: ${error}. Data: ${data}.`,
    );
    return [false];
  }
};

const getToken = async (key) => {
  getIframe();
  const request = {
    id: Date.now().toString(),
    key: key,
    type: 'get',
  };
  const data = {
    request: request,
  };
  if (isRemoteStorageReady) {
    sendRequest(data);
  } else {
    queue.push(data);
  }
  for (
    let count = 0;
    count < TIMEOUT && !responseChecker[request.id];
    count++
  ) {
    await sleep(1000);
  }
  const messageData = responseChecker[request.id];
  delete responseChecker[request.id];
  return Promise.resolve(messageData);
};

const getEmail = async () => {
  getIframe();
  const request = {
    id: Date.now().toString(),
    key: 'userEmail',
    type: 'get',
  };
  const data = {
    request: request,
  };
  if (isRemoteStorageReady) {
    sendRequest(data);
  } else {
    queue.push(data);
  }

  for (
    let count = 0;
    count < TIMEOUT && !responseChecker[request.id];
    count++
  ) {
    await sleep(1000);
  }

  const messageData = responseChecker[request.id];
  delete responseChecker[request.id];
  return Promise.resolve(messageData);
};

const handleMessage = (event) => {
  if (event.data === 'ready') {
    remoteStorageIsReady();
  } else if (event.data && event.data.substr) {
    const [isParsed, messageData] = tryJsonParse(event.data);
    if (isParsed) {
      responseChecker[messageData.id] = messageData.value || 'NULL';
    }
  }
};

const remoteStorageIsReady = () => {
  isRemoteStorageReady = true;
  if (queue.length) {
    const queueLength = queue.length;
    for (let index = 0; index < queueLength; index++) {
      sendRequest(queue[index]);
    }
    queue = [];
  }
};

const sendRequest = (data) => {
  const iframe = getIframe();
  iframe.contentWindow.postMessage(JSON.stringify(data.request), '*');
};

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export default { getToken, getEmail };
