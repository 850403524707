import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Typography from 'common/Typography/Typography';
import { useStyles } from '../Contacts.style';

const Address = ({
  labels,
  address1,
  address2,
  city,
  postalCode,
  state,
  size,
}) => {
  const classes = useStyles();

  const printFieldIfPresent = (address) => {
    if (address) return address;
    return '';
  };
  const isComma = () => {
    if (
      printFieldIfPresent(city) &&
      (printFieldIfPresent(state) || printFieldIfPresent(postalCode))
    ) {
      return true;
    }
    return false;
  };

  if (!address1 && !address2 && !city & !state) {
    return '';
  }
  return (
    <div className={classes.row}>
      <div className={classes.column}>
        <Typography level="tiny" color="eerieBlack5">
          {`${labels.ADDRESS}`}
        </Typography>

        {size !== 'XL' && (
          <Fragment>
            <Typography level="small" className={classes.column}>
              {printFieldIfPresent(address1)} {printFieldIfPresent(address2)}
            </Typography>
            <Typography level="small" className={classes.column}>
              {printFieldIfPresent(city)}
              {isComma() && labels.COMMA_QUOTA_MESSAGE}
              {` ${printFieldIfPresent(state)} ${printFieldIfPresent(
                postalCode,
              )}`}
            </Typography>
          </Fragment>
        )}
        {size === 'XL' && (
          <Typography level="small" className={classes.column}>
            {`${printFieldIfPresent(address1)} ${printFieldIfPresent(
              address2,
            )} `}
            {printFieldIfPresent(city)}
            {isComma() && labels.COMMA_QUOTA_MESSAGE}
            {` ${printFieldIfPresent(state)} ${printFieldIfPresent(
              postalCode,
            )}`}
          </Typography>
        )}
      </div>
    </div>
  );
};

Address.propTypes = {
  labels: PropTypes.shape({
    ADDRESS: PropTypes.string,
    COMMA_QUOTA_MESSAGE: PropTypes.string,
  }).isRequired,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  postalCode: PropTypes.string,
  state: PropTypes.string,
  size: PropTypes.string,
};

export default Address;
