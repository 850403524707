import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => ({
  referred_leads: {
    height: '20px',
    '& p': {
      margin: 0,
    },
  },
}));

export { useStyles };
