import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Copy from '../common/Icons/basic/Copy';
import Send from '../common/Icons/basic/Send';
import Link from '../common/Link/Link';
import CallPhone from '../common/Icons/call/CallPhone';
import CommentText from '../common/Icons/chatting/CommentText';
import withConfig from '../utils/withConfig';
import chatboxClient from './communication/text/Chatbox';
import Typography from '../common/Typography/Typography';
import labels from '../config/labels';
import { logEvent } from '../services/analyticsService';

const logCommunityContacted = (
  contactAction,
  contactType,
  familyFileId,
  familyFileUserId,
) => {
  logEvent('Community Contacted', {
    channel: 'community',
    contactAction: contactAction,
    contactType: contactType,
    crmUserId: familyFileUserId,
    eventType: 'action',
    familyFileId: familyFileId,
    screenName: 'communities tab',
    section: 'family file',
  });
};

const getActionableTitleEmail = (
  { contact, classes, familyFileId, familyFileUserId },
  subject,
) => {
  return (
    <>
      <div
        className={classes.actionItem}
        onClick={() => {
          logCommunityContacted(
            'copy',
            'email',
            familyFileId,
            familyFileUserId,
          );
        }}
      >
        <CopyToClipboard text={contact.email}>
          <span className={classes.pointer_show}>
            <Typography level="small" className={classes.contact}>
              <Copy className={classes.actionicon} />
              {labels.COPY}
            </Typography>
          </span>
        </CopyToClipboard>
      </div>
      <div
        className={classes.actionItem}
        onClick={() => {
          logCommunityContacted(
            'email',
            'email',
            familyFileId,
            familyFileUserId,
          );
        }}
      >
        <Typography level="small">
          <Link
            className={classes.contact}
            to={`mailto:${contact.email}?subject=${subject}`}
          >
            <Send className={classes.icon} />
            {labels.SEND_EMAIL}
          </Link>
        </Typography>
      </div>
    </>
  );
};

const clickToDial = async (
  phoneNumber,
  dialerService,
  currentUser,
  setTelephonyError,
) => {
  try {
    setTelephonyError(null);
    const response = await dialerService.dialCall(
      phoneNumber,
      currentUser.username,
    );
    if (!response.success) {
      setTelephonyError(response.error);
    }
  } catch (err) {
    setTelephonyError(err.message);
  }
};

const getTextUrlResponse = (
  phoneNumber,
  contact,
  careTypeCodes,
  familyFileId,
  currentUser,
  flags,
) => {
  const textOverrideNumber = textOverriddenNumber(flags);
  const textToPhoneNumber = textOverrideNumber.trim()
    ? textOverrideNumber
    : phoneNumber;

  //Care Type is mandatory to be sent to get the chat url. So if not care type is present send a dummy value of X
  var careTypeCodesList = careTypeCodes ? careTypeCodes : 'X';

  const contactDetails = {
    fullName: `${contact.firstName} ${contact.lastName}`,
    leadId: familyFileId,
    phoneNumber: textToPhoneNumber,
    slaEmail: currentUser.email,
    careTypeList: careTypeCodesList,
  };

  let response = chatboxClient.getTextingServiceUrl(
    withConfig('YGL_COMMUNICATION_SERVICE_TEXT_URL'),
    contactDetails,
  );
  return response;
};

const sendTextClickHandler = async (
  phoneNumber,
  contact,
  careTypeCodes,
  familyFileId,
  currentUser,
  flags,
) => {
  await getTextUrlResponse(
    phoneNumber,
    contact,
    careTypeCodes,
    familyFileId,
    currentUser,
    flags,
  ).then((response) => {
    window.open(response, '_blank');
  });
};

const enableOneClickDial = (flags) => {
  return flags.enableOneClickDial;
};

const textOverriddenNumber = (flags) => {
  return flags.textOverrideNumber;
};

const getActionablePhone = (
  phoneNumber,
  phoneNumberType,
  {
    contact,
    careTypeCodes,
    dialerService,
    currentUser,
    classes,
    setTelephonyError,
    flags,
    familyFileId,
    familyFileUserId,
  },
) => {
  return (
    <div>
      <div
        className={classes.actionItem}
        onClick={() => {
          logCommunityContacted(
            'copy',
            phoneNumberType,
            familyFileId,
            familyFileUserId,
          );
        }}
      >
        <CopyToClipboard text={phoneNumber}>
          <span className={classes.pointer_show}>
            <Typography level="small" className={classes.contact}>
              <Copy className={classes.actionicon} />
              {labels.COPY}
            </Typography>
          </span>
        </CopyToClipboard>
      </div>
      {enableOneClickDial(flags) && (
        <div className={classes.actionItem}>
          <Typography level="small">
            <Link
              onClick={(event) => {
                event.preventDefault();
                logCommunityContacted(
                  'call',
                  phoneNumberType,
                  familyFileId,
                  familyFileUserId,
                );
                clickToDial(
                  phoneNumber,
                  dialerService,
                  currentUser,
                  setTelephonyError,
                );
              }}
              className={classes.contact}
            >
              <CallPhone className={classes.icon} />
              {labels.CALL} {contact.firstName}
            </Link>
          </Typography>
        </div>
      )}
      <div
        className={classes.actionItem}
        onClick={() => {
          logCommunityContacted(
            'text',
            phoneNumberType,
            familyFileId,
            familyFileUserId,
          );
          sendTextClickHandler(
            phoneNumber,
            contact,
            careTypeCodes,
            familyFileId,
            currentUser,
            flags,
          );
        }}
      >
        <Typography level="small" className={classes.contact}>
          <CommentText className={classes.actionicon} />
          {labels.SEND_TEXT}
        </Typography>
      </div>
    </div>
  );
};

const getActionableFax = (
  phoneNumber,
  { classes, familyFileId, familyFileUserId },
) => {
  return (
    <div
      className={classes.actionItem}
      onClick={() => {
        logCommunityContacted('copy', 'fax', familyFileId, familyFileUserId);
      }}
    >
      <CopyToClipboard text={phoneNumber}>
        <span className={classes.pointer_show}>
          <Typography level="small" className={classes.contact}>
            <Copy className={classes.actionicon} />
            {labels.COPY}
          </Typography>
        </span>
      </CopyToClipboard>
    </div>
  );
};

getActionableTitleEmail.propTypes = {
  contact: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  familyFileId: PropTypes.number.isRequired,
  familyFileUserId: PropTypes.number.isRequired,
  subject: PropTypes.string,
};

getActionableFax.propTypes = {
  phoneNumber: PropTypes.string,
  classes: PropTypes.object,
};

export { getActionableTitleEmail, getActionablePhone, getActionableFax };
