import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { callTypes } from '../config/callStates.config';
import { getInquiryRequest } from '../stateManagement/callcenter/inquiry/actions/inquiryActions';

export const useInquiryIdFromActiveContact = (
  inquiry,
  callPreview,
  latestInquiryId,
  callType,
  allowDisabledChiclets,
) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const inquiryId = latestInquiryId
      ? latestInquiryId
      : callPreview?.contact?.inquiryId;
    if (
      inquiry?.inquiryId !== inquiryId &&
      inquiryId &&
      parseInt(latestInquiryId, 10) !== parseInt(inquiryId, 10) &&
      callType !== callTypes.INBOUND
    ) {
      dispatch(getInquiryRequest(inquiryId, allowDisabledChiclets));
    }
  }, [
    callPreview,
    inquiry,
    latestInquiryId,
    dispatch,
    callType,
    allowDisabledChiclets,
  ]);
};
