export default {
  /* Medication Management */
  MEDICATION_MANAGEMENT: 'Medication Management',
  MEDICATION_MANAGEMENT_SELF: 'Residents Must Self Administer Medications',
  MEDICATION_MANAGEMENT_REMINDERS: 'Reminders and Monitoring Available',
  MEDICATION_MANAGEMENT_FULL: 'Full Medication Management Available',
  MEDICATION_MANAGEMENT_MED_AID:
    "Medication Aides May Administer Injections with Doctor's Order",
  MEDICATION_MANAGEMENT_LPN:
    "Licensed Nurses May Administer Injections with Doctor's Order",
  /* Skilled Services */
  SKILLED_SERVICES: 'Staffing & Ancillary Services',
  SKILLED_SERVICES_NURSES: 'On-Site Nursing Services (limited hours)',
  SKILLED_SERVICES_24_HOUR: '24-Hour Awake Staff',
  SKILLED_SERVICES_AGENCY: 'Home Care Agency On-Site',
  SKILLED_SERVICES_HOSPICE: 'Hospice Available On-Site',
  SKILLED_SERVICES_NURSING: 'Skilled Nursing Facility Located On-Site',
  /* Ambulatory Care  */
  AMBULATORY_CARE: 'Ambulatory care',
  STANDBY_ASSISTANCE: 'Standby Assistance',
  ONE_PERSON_ASSISTED_TRANSFER: '1 Person Assisted Transfer',
  TWO_PERSON_ASSISTED_TRANSFER: '2 Person Assisted Transfer',
  TOTAL_TRANSFER_HOYER_LIFT: 'Total Transfer/ Hoyer Lift',
  /* Diabetic Care */
  DIABETIC_CARE: 'Diabetic Care',
  RESIDENTS_MONITOR_BLOOD_SUGAR:
    'Residents May Self Monitor Blood Sugar & Medications',
  STAFF_MONITOR_BLOOD_SUGAR: 'Staff May Monitor Blood Sugar for Pill Dosage',
  ADMINISTER_INSULIN_INJECTIONS: 'Administer Insulin Injections',
  ADMINISTER_SLIDING_SCALE: 'Administer Sliding Scale',
  /* High Acuity Care */
  HIGH_ACUITY_CARE: 'High Acuity Care',
  CATHETER: 'Catheter',
  COLOSTOMY: 'Colostomy',
  FEEDING_TUBE: 'Feeding Tube',
  OXYGEN: 'Oxygen',
  WOUND_CARE: 'Wound Care',
  IV_CARE: 'IV Care',
  HISTORY_OF_PSYCHIATRIC_CARE: 'History of Psychiatric Care',
  VENTILATOR_CARE: 'Ventilator Care',
  HISTORY_OF_SUICIDAL_IDEATION: 'History of Suicidal Ideation',
  /* Incontinence Care */
  INCONTINENCE_CARE: 'Incontinence care',
  SELF_MANAGEMENT: 'Self Management',
  REMINDERS: 'Reminders',
  URINARY: 'Urinary',
  BOWEL: 'Bowel',
  /* Memory Care */
  MEMORY_CARE: 'Memory Care',
  RESTLESSNESS_PACING_WANDERING: 'Restlessness, Pacing, or Wandering',
  HALLUCINATIONS_AND_DELUSIONS: 'Hallucinations and Delusions',
  REDIRECTION_CUING_DISORIENTATION: 'Redirection, Cuing, Disorientation',
  EXIT_SEEKING_BEHAVIORS: 'Exit-Seeking Behaviors',
};
