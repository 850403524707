const findSnapshotsByScripIdQuery = `
  query findSnapshotsByScripId($scriptId: Int!) {
    findSnapshotsByScripId(scriptId: $scriptId) {
      snapshotScriptId
      createdAt
      createdBy {
        firstName
        lastName
        userId
      }
      slug
      version
      reason
      snapshot
    }
  }
`;

export default findSnapshotsByScripIdQuery;
