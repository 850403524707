import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  };
});

export { useStyles };
