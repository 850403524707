import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../common/Typography/Typography';
import { useStyles } from './MessagePoint.style';
import Win from '../../common/Graphics/Win';
import Media from '../../common/Media/Media';

const MessagePoint = ({ userGivenName, labels }) => {
  const classes = useStyles();

  let congratulationsHeader = `${labels.CONGRATULATIONS_QUOTA_MESSAGE}${labels.COMMA_QUOTA_MESSAGE}${userGivenName}${labels.BANG_QUOTA_MESSAGE}`;

  if (userGivenName === '' || userGivenName === undefined) {
    congratulationsHeader = `${labels.CONGRATULATIONS_QUOTA_MESSAGE}${labels.BANG_QUOTA_MESSAGE}`;
  }

  return (
    <div className={classes.message_container}>
      <Media
        xs={
          <div>
            <Typography
              color="platinum5"
              level="h4"
              className={classes.header_message_xs}
            >
              {congratulationsHeader}
            </Typography>
            <div
              className={`${classes.body_container} ${classes.body_container_xs}`}
            >
              <Win />
              <Typography
                color="platinum5"
                level="small"
                className={classes.body_text}
              >
                {labels.SURPASSING_QUOTA_MESSAGE}
              </Typography>
            </div>
          </div>
        }
        sm={
          <div className={classes.body_container}>
            <Win />
            <div>
              <Typography
                color="platinum5"
                level="h4"
                className={classes.header_message_sm}
              >
                {congratulationsHeader}
              </Typography>
              <Typography
                color="platinum5"
                level="small"
                className={classes.body_text_sm}
              >
                {labels.SURPASSING_QUOTA_MESSAGE}
              </Typography>
            </div>
          </div>
        }
        md={
          <div className={classes.body_container}>
            <Win />
            <div>
              <Typography
                color="platinum5"
                level="h4"
                className={classes.header_message_sm}
              >
                {congratulationsHeader}
              </Typography>
              <Typography
                color="platinum5"
                level="small"
                className={classes.body_text_sm}
              >
                {labels.SURPASSING_QUOTA_MESSAGE}
              </Typography>
            </div>
          </div>
        }
        lg={
          <div>
            <Typography
              color="platinum5"
              level="h4"
              className={classes.header_message}
            >
              {congratulationsHeader}
            </Typography>
            <div
              className={`${classes.body_container} ${classes.body_container_lg}`}
            >
              <Win />
              <Typography
                color="platinum5"
                level="small"
                className={classes.body_text}
              >
                {labels.SURPASSING_QUOTA_MESSAGE}
              </Typography>
            </div>
          </div>
        }
        xl={
          <div className={classes.body_container}>
            <Win />
            <div>
              <Typography
                color="platinum5"
                level="h4"
                className={classes.header_message_xl}
              >
                {congratulationsHeader}
              </Typography>
              <Typography
                color="platinum5"
                level="small"
                className={classes.body_text_xl}
              >
                {labels.SURPASSING_QUOTA_MESSAGE}
              </Typography>
            </div>
          </div>
        }
      />
    </div>
  );
};

MessagePoint.propTypes = {
  labels: PropTypes.object.isRequired,
  userGivenName: PropTypes.string,
};
export default MessagePoint;
export { MessagePoint };
