import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  contacts: {
    '& > div:first-child': {
      borderRadius: 0,
    },
    '& > div:last-child': {
      padding: '0 0 16px 0',
    },
  },
  row: {
    display: 'flex',
    marginTop: '16px',
    padding: '0 24px',
  },
  column: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    '& > p, div, svg': {
      marginTop: '3px',
    },
    '& p:first-child': {
      marginTop: '0',
    },
  },
  contact_name: {
    display: 'flex',
    flexWrap: 'wrap',
    '& p:first-child': {
      marginRight: '4px',
    },
  },
  contact: {
    display: 'flex',
    alignItems: 'center',
  },
  contact_level: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: '8px',
    },
  },
  secondary_contact: {
    cursor: 'pointer',
  },
  icon: {
    color: theme.palette.neutral.cosmicCobalt3,
    fontSize: '20px',
    marginRight: '6px',
  },
  icon_x: {
    color: theme.palette.correction.smokyTopaz2,
    fontSize: '16px',
    marginRight: '6px',
  },
  contact_name_container: {
    wordBreak: 'break-all',
  },
  actionItem: {
    display: 'flex',
    textAlign: 'left',
    cursor: 'pointer',
    color: theme.palette.dark.eerieBlack1,
    '& div': {
      padding: '9px 16px',
    },
    '& p': {
      padding: '9px 16px',
    },
    '&:hover': {
      backgroundColor: theme.palette.neutral.dropdown,
    },
    '& a, .link': {
      'text-decoration': 'none',
      color: theme.palette.dark.eerieBlack1,
    },
    '& svg': {
      fontSize: '24px',
      color: theme.palette.dark.eerieBlack1,
      marginRight: '12px',
    },
    padding: 0,
  },
  toast_error: {
    width: '100%',
    fontSize: '13px',
    alignItems: 'center',
    '& div:nth-child(2)': {
      marginLeft: '12px',
    },
  },
}));

export { useStyles };
