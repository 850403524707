import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => ({
  centered_icon: {
    display: 'flex',
    alignItems: 'center',
  },
  info_row: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 8,
  },
  name_row: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  right_spaced: {
    marginRight: 4,
  },
  info_right_margin: (props) => ({
    marginRight: props.infoRightMargin,
  }),
}));

export { useStyles };
