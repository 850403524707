/* eslint-disable no-loop-func */
import TreeView from '@material-ui/lab/TreeView';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../../common/Alert/Alert';
import Button from '../../common/Button/Button';
import ChevronBottom from '../../common/Icons/arrow/ChevronBottom';
import ChevronRight from '../../common/Icons/arrow/ChevronRight';
import Eye from '../../common/Icons/basic/Eye';
import EyeNo from '../../common/Icons/basic/EyeNo';
import NoteText from '../../common/Icons/file/NoteText';
import QuestionCircle from '../../common/Icons/notifications/QuestionCircle';
import { withLabels } from '../../context/LabelContext';
import useGraphQLMutation from '../../hooks/useGraphQLMutation';
import {
  inquiryProperties,
  stepTypes,
} from '../../services/callCenter/fieldsMapper';
import stepService from '../../services/callCenter/stepService';
import { selectAdminCatalogs } from '../../stateManagement/admin/catalogs/reducers/adminCatalogsReducer';
import {
  cancelPendingSnapshotRequests,
  getScriptsRequest,
  getSnapshotsRequest,
} from '../../stateManagement/admin/scriptBuilder/actions/scriptBuilderActions';
import { selectScripts } from '../../stateManagement/admin/scriptBuilder/reducers/scriptBuilderReducer';
import AnswerDialog from './Answer/AnswerDialog';
import AnswerView from './Answer/AnswerView';
import StyledTreeItem from './common/CustomTree/StyledTreeItem';
import deleteAnswerMutation from './graphql/deleteAnswerMutation';
import deleteScriptMutation from './graphql/deleteScriptMutation';
import deleteStepMutation from './graphql/deleteStepMutation';
import importStepsMutation from './graphql/importStepsMutation';
import saveAnswerMutation from './graphql/saveAnswerMutation';
import saveScriptMutation from './graphql/saveScriptMutation';
import saveStepMutation from './graphql/saveStepMutation';
import ExportDialog from './ImportExport/ExportDialog';
import ImportDialog from './ImportExport/ImportDialog';
import ScriptDialog from './Script/ScriptDialog';
import ScriptView from './Script/ScriptView';
import { useStyles } from './ScriptBuilder.style';
import StepDialog from './Step/StepDialog';
import StepView from './Step/StepView';
import cloneObject from './util/cloneObject';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Redirect } from 'react-router-dom';
import SortStepsDialog from './Script/SortSteps/SortStepsDialog';
import ScriptSnapshotsDialog from './Script/ScriptSnapshotsDialog';
import { sentryExceptionWithData } from 'utils/sentryExceptionWithData';

const types = Object.entries(stepTypes).map(([key, value]) => ({
  ...value,
  value: key,
}));

const properties = Object.entries(inquiryProperties).map(([key, value]) => ({
  ...value,
  value: key,
}));

const getScriptNodeId = (script) => `${script.scriptId}`;
const getStepNodeId = (script, step) => `${script.scriptId}||${step.stepId}`;
const getAnswerNodeId = (script, step, answer) =>
  `${script.scriptId}||${step.stepId}||${answer.answerId}`;

const ScriptBuilder = ({ labels, flags }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedLevel, setSelectedLevel] = useState(null);
  const [isScriptDialogOpen, setScriptDialogOpen] = React.useState(false);
  const [isStepDialogOpen, setStepDialogOpen] = React.useState(false);
  const [isAnswerDialogOpen, setAnswerDialogOpen] = React.useState(false);
  const [currentScript, setCurrentScript] = React.useState(null);
  const [currentStep, setCurrentStep] = React.useState(null);
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [sortingSteps, setSortingSteps] = useState(false);

  const { scriptsLoading, scriptsError, scripts } = useSelector(selectScripts);
  const { catalogs } = useSelector(selectAdminCatalogs);

  const [exporting, setExporting] = useState(false);
  const [importing, setImporting] = useState(false);
  const [viewHistory, setViewHistory] = useState(false);

  const [saveScript] = useGraphQLMutation(saveScriptMutation);
  const [saveStep] = useGraphQLMutation(saveStepMutation);
  const [saveAnswer] = useGraphQLMutation(saveAnswerMutation);
  const [deleteScript] = useGraphQLMutation(deleteScriptMutation);
  const [deleteStep] = useGraphQLMutation(deleteStepMutation);
  const [deleteAnswer] = useGraphQLMutation(deleteAnswerMutation);
  const [importSteps] = useGraphQLMutation(importStepsMutation);

  useEffect(() => {
    dispatch(getScriptsRequest());
  }, [dispatch]);

  if (!flags.scriptBuilderAccess) {
    const errorMessage = new Error(
      'Error 401: Unauthorized Role - Script Builder Access feature flag is TURNED OFF',
    );
    sentryExceptionWithData(errorMessage, {
      scriptBuilderAccessFlag: flags.scriptBuilderAccess,
    });

    return <Redirect to="/error" />;
  }
  if (scripts === null) return <div>Loading...</div>;

  const onExportScript = (content) => {
    setExporting(JSON.stringify(content, null, 2));
  };

  const onExportStep = () => {
    setExporting(`{ "steps" : [${JSON.stringify(currentStep, null, 2)}] }`);
  };

  const onSortSteps = async (newSteps) => {
    const sortedSteps = newSteps.map((step) => {
      const { newOrder, answers, ...oldStep } = step;
      const newAnswers = answers.map((answer) => {
        delete answer.updatedBy;
        delete answer.createdBy;
        delete answer.updatedAt;
        delete answer.createdAt;
        return {
          ...answer,
          stepId: step.stepId,
        };
      });
      delete oldStep.updatedBy;
      delete oldStep.createdBy;
      delete oldStep.updatedAt;
      delete oldStep.createdAt;
      return {
        ...oldStep,
        order: newOrder,
        scriptId: currentScript.scriptId,
        answers: newAnswers,
      };
    });

    try {
      await importSteps({
        variables: {
          script: {
            scriptId: currentScript.scriptId,
            steps: sortedSteps,
          },
          overwrite: true,
        },
      });
      setSortingSteps(false);
      dispatch(getScriptsRequest());
    } catch (e) {
      console.log('Error calling the mutation: ', e);
    }
  };

  const onSortAnswers = () => {
    console.log('Sorting answers: ', currentStep);
  };

  const onImportScript = async (jsonString, overwrite) => {
    let newSteps;
    try {
      const json = JSON.parse(jsonString);
      newSteps = json.steps.map((step) => {
        const newStep = cloneDeep(step);
        newStep.stepId = -1;
        newStep.scriptId = currentScript.scriptId;
        delete newStep.__typename;
        delete newStep.updatedBy;
        delete newStep.createdBy;
        delete newStep.updatedAt;
        delete newStep.createdAt;
        delete newStep.version;
        newStep.answers.forEach((answer) => {
          delete answer.__typename;
          delete answer.updatedBy;
          delete answer.createdBy;
          delete answer.updatedAt;
          delete answer.createdAt;
          delete answer.version;
          answer.answerId = -1;
          answer.stepId = -1;
        });
        return newStep;
      });
    } catch (e) {
      console.log('Error parsing Json', e);
    }
    try {
      await importSteps({
        variables: {
          script: {
            scriptId: currentScript.scriptId,
            steps: newSteps,
          },
          overwrite,
        },
      });
      setImporting(false);
      dispatch(getScriptsRequest());
    } catch (e) {
      console.log('Error calling the mutation: ', e);
    }
  };

  const onSaveScript = async (scriptToSave) => {
    const newScript = cloneObject(scriptToSave);
    setScriptDialogOpen(false);
    if (!newScript.status) newScript.status = 'ENABLED';

    delete newScript.__typename;

    try {
      if (!scriptsLoading) {
        const response = await saveScript({
          variables: newScript,
        });
        const {
          data: {
            saveScript: { scriptId },
          },
        } = response;
        newScript.scriptId = scriptId;
        dispatch(getScriptsRequest());
        setCurrentScript(newScript);
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  const onDelete = () => {
    setConfirmDelete(true);
  };

  const onDeleteScript = async () => {
    try {
      await deleteScript({
        variables: { scriptId: currentScript.scriptId },
      });
      dispatch(getScriptsRequest());
      setCurrentScript(null);
    } catch (err) {
      console.log('error', err);
    }
  };

  const onDeleteStep = async () => {
    try {
      await deleteStep({
        variables: {
          stepId: currentStep.stepId,
          scriptId: currentScript.scriptId,
        },
      });
      dispatch(getScriptsRequest());
      const newCurrentScript = cloneObject(currentScript);
      const steps = newCurrentScript.steps.filter(
        (s) => s.number !== currentStep.number,
      );
      newCurrentScript.steps = steps;
      setCurrentScript(newCurrentScript);
      setCurrentStep(null);
    } catch (err) {
      console.log('error', err);
    }
  };

  const onDeleteAnswer = async () => {
    try {
      await deleteAnswer({
        variables: { answerId: currentAnswer.answerId },
      });
      dispatch(getScriptsRequest());
      const newCurrentStep = cloneObject(currentStep);
      const answers = newCurrentStep.answers.filter(
        (a) => a.value !== currentAnswer.value,
      );
      newCurrentStep.answers = answers;
      setCurrentStep(newCurrentStep);
      setCurrentAnswer(null);
    } catch (err) {
      console.log('error', err);
    }
  };

  const onDuplicateScript = async (scriptToDuplicate) => {
    const newScript = cloneObject(scriptToDuplicate);
    delete newScript.scriptId;

    let slug = scriptToDuplicate.slug + '_copy';
    let count = 0;
    while (scripts.find((f) => f.slug === slug)) {
      count += 1;
      slug = scriptToDuplicate.slug + `_copy${count}`;
    }
    newScript.slug = slug;
    newScript.name = scriptToDuplicate.name + `_copy${count}`;

    await onSaveScript(newScript);
  };

  const openScriptModal = (script) => {
    setCurrentScript(script);
    setScriptDialogOpen(true);
  };

  const onSaveStep = async (stepToSave) => {
    const newStep = cloneObject(stepToSave);
    newStep.scriptId = currentScript.scriptId;
    setStepDialogOpen(false);
    delete newStep.__typename;
    delete newStep.updatedBy;
    delete newStep.createdBy;
    delete newStep.updatedAt;
    delete newStep.createdAt;
    if (!newStep.answers) newStep.answers = [];
    newStep.answers.forEach((answer) => {
      delete answer.__typename;
      delete answer.updatedBy;
      delete answer.createdBy;
      delete answer.updatedAt;
      delete answer.createdAt;
      answer.stepId = -1;
      if (stepToSave.stepId) {
        answer.stepId = stepToSave.stepId;
      }
    });
    try {
      if (!scriptsLoading) {
        await saveStep({ variables: newStep });
        const additionalSteps = stepService.createAdditionalSteps(
          newStep,
          currentScript,
          labels,
        );
        if (additionalSteps.length > 0) {
          const saveStepsPromises = additionalSteps.map((variables) =>
            saveStep({ variables }),
          );
          await Promise.all(saveStepsPromises);
        }

        dispatch(getScriptsRequest());
        const newScript = cloneObject(currentScript);
        newScript.steps.push(newScript);
        setCurrentScript(newScript);
        setCurrentStep(newStep);
      }
    } catch (err) {
      console.log('error', err);
    }
  };
  const onDuplicateStep = async (stepToDuplicate) => {
    const newStep = cloneObject(stepToDuplicate);
    newStep.scriptId = currentScript.scriptId;
  };
  const openStepModal = (script, step) => {
    setCurrentScript(script);
    setCurrentStep(step);
    setStepDialogOpen(true);
  };
  const addStep = (script) => openStepModal(script, {});

  const openAnswerModal = (step, answer) => {
    setCurrentStep(step);
    setCurrentAnswer(answer);
    setAnswerDialogOpen(true);
  };

  const addAnswer = (step) => openAnswerModal(step, {});

  const onSaveAnswer = async (answerToSave) => {
    const newAnswer = cloneObject(answerToSave);
    if (!newAnswer.stepId) {
      newAnswer.stepId = currentStep.stepId;
    }
    if (newAnswer.value) {
      newAnswer.value = newAnswer.value.trim();
    }
    setAnswerDialogOpen(false);
    delete newAnswer.__typename;
    try {
      if (!scriptsLoading) {
        const response = await saveAnswer({
          variables: newAnswer,
        });
        const {
          data: {
            saveAnswer: { answerId },
          },
        } = response;
        newAnswer.answerId = answerId;
        dispatch(getScriptsRequest());
        const newStep = cloneObject(currentStep);
        if (newStep.answers.find((e) => e.answerId === answerId)) {
          newStep.answers = newStep.answers.filter(
            (x) => x.answerId !== answerId,
          );
        }
        newStep.answers.push(newAnswer);
        setCurrentStep(newStep);
        setCurrentAnswer(newAnswer);
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  const onDuplicateAnswer = (answerToDuplicate) => {
    const newAnswer = cloneObject(answerToDuplicate);

    let order = answerToDuplicate.order;
    while (currentStep.answers.find((a) => a.order === order)) {
      order += 1;
    }
    newAnswer.value = `${answerToDuplicate.value}_${order}`;
    newAnswer.label = `${answerToDuplicate.label}_${order}`;
    newAnswer.order = order;
    onSaveAnswer(newAnswer);
  };

  const onCallHistory = (currentScript) => {
    dispatch(cancelPendingSnapshotRequests());
    dispatch(getSnapshotsRequest(currentScript.scriptId));
    setViewHistory(true);
  };

  const handleNodeLabelClick = (event) => {
    event.preventDefault();
  };

  const handleNodeToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleNodeSelect = (event, nodeIds) => {
    setSelected(nodeIds);
    const valueParts = nodeIds.split('||');
    const selectedScript = scripts.find(
      (f) => f.scriptId.toString() === valueParts[0].toString(),
    );
    if (valueParts.length === 1) {
      setCurrentScript(selectedScript);
      setSelectedLevel('script');
      return;
    }
    if (valueParts.length === 2) {
      const selectedStep = selectedScript.steps.find(
        (s) => s.stepId.toString() === valueParts[1].toString(),
      );
      setCurrentScript(selectedScript);
      setCurrentStep(selectedStep);
      setSelectedLevel('step');
    }
    if (valueParts.length === 3) {
      const selectedStep = selectedScript.steps.find(
        (s) => s.stepId.toString() === valueParts[1].toString(),
      );
      const selectedAnswer = selectedStep.answers.find(
        (a) => a.answerId.toString() === valueParts[2].toString(),
      );
      setCurrentScript(selectedScript);
      setCurrentStep(selectedStep);
      setCurrentAnswer(selectedAnswer);
      setSelectedLevel('answer');
    }
  };

  const onConfirmDelete = async () => {
    switch (selectedLevel) {
      case 'script':
        await onDeleteScript();
        break;
      case 'step':
        await onDeleteStep();
        break;
      case 'answer':
        await onDeleteAnswer();
        break;
      default:
        break;
    }
    setConfirmDelete(false);
  };

  if (scriptsError) return `Error! ${scriptsError.message}`;

  return (
    <div className={classes.container}>
      <React.Fragment>
        <SortStepsDialog
          onClose={() => setSortingSteps(false)}
          script={currentScript}
          onSave={(newSteps) => onSortSteps(newSteps)}
          open={sortingSteps}
          labels={labels}
        />
        <ExportDialog
          onClose={() => setExporting(false)}
          content={exporting ? exporting : ''}
          isOpen={exporting ? true : false}
          labels={labels}
        />
        <ImportDialog
          onClose={() => setImporting(false)}
          isOpen={importing}
          onImport={onImportScript}
          labels={labels}
        />
        <ScriptSnapshotsDialog
          open={viewHistory}
          onClose={() => setViewHistory(false)}
          labels={labels}
          onViewSnapshotHistory={onExportScript}
        />
        <Alert
          onClose={() => setConfirmDelete(false)}
          onConfirm={onConfirmDelete}
          title={labels.CONFIRM_DELETE_TITLE}
          description={labels.CONFIRM_DELETE.replace('{TYPE}', selectedLevel)}
          type="delete"
          isOpen={confirmDelete}
          cancelText={labels.NO}
          confirmText={labels.YES}
        />
        <ScriptDialog
          onClose={() => setScriptDialogOpen(false)}
          currentScript={currentScript}
          onSaveScript={(scriptToSave) => onSaveScript(scriptToSave)}
          open={isScriptDialogOpen}
          scripts={scripts}
          labels={labels}
        />

        <StepDialog
          onClose={() => setStepDialogOpen(false)}
          currentStep={currentStep}
          types={types}
          steps={
            currentScript && currentScript.steps ? currentScript.steps : []
          }
          onSaveStep={(stepToSave) => onSaveStep(stepToSave)}
          open={isStepDialogOpen}
          labels={labels}
          properties={properties}
        />
        {currentAnswer && (
          <AnswerDialog
            onClose={() => setAnswerDialogOpen(false)}
            step={currentStep}
            answer={currentAnswer}
            steps={
              currentScript && currentScript.steps ? currentScript.steps : []
            }
            onSaveAnswer={(answerToSave) => onSaveAnswer(answerToSave)}
            open={isAnswerDialogOpen}
            labels={labels}
            properties={properties}
            catalogs={catalogs}
          />
        )}
      </React.Fragment>
      <div className={classes.top}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => openScriptModal({})}
        >
          {labels.NEW_SCRIPT}
        </Button>
      </div>
      <div className={classes.main}>
        <div className={classes.tree_bar}>
          <TreeView
            className={classes.root}
            defaultCollapseIcon={<ChevronBottom />}
            defaultExpandIcon={<ChevronRight />}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleNodeToggle}
            onNodeSelect={handleNodeSelect}
          >
            {scripts
              .sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
              )
              .map((script) => {
                let steps = script.steps ? script.steps : [];
                steps = steps.sort((a, b) => a.order - b.order);
                return (
                  <StyledTreeItem
                    key={`script_${script.slug}`}
                    nodeId={getScriptNodeId(script)}
                    labelText={script.name}
                    labelIcon={NoteText}
                    onLabelClick={handleNodeLabelClick}
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                  >
                    {steps.map((step) => {
                      let answers = step.answers ? step.answers : [];
                      answers = answers.sort((a, b) => a.order - b.order);
                      return (
                        <StyledTreeItem
                          key={`step_${step.number}`}
                          nodeId={getStepNodeId(script, step)}
                          labelText={`${step.order}. ${step.name}`}
                          labelIcon={step.isHidden ? EyeNo : Eye}
                          onLabelClick={handleNodeLabelClick}
                          labelInfo={
                            types
                              ? types.find((t) => t.value === step.type)?.label
                              : step.type
                          }
                          color="#e3742f"
                          bgColor="#fcefe3"
                        >
                          {answers.map((answer) => {
                            return (
                              <StyledTreeItem
                                key={`answer_${answer.value}`}
                                nodeId={getAnswerNodeId(script, step, answer)}
                                labelText={answer.label}
                                labelIcon={QuestionCircle}
                                onLabelClick={handleNodeLabelClick}
                                color="#a250f5"
                                bgColor="#f3e8fd"
                              />
                            );
                          })}
                        </StyledTreeItem>
                      );
                    })}
                  </StyledTreeItem>
                );
              })}
          </TreeView>
        </div>
        <div className={classes.content}>
          {currentScript && selectedLevel === 'script' ? (
            <ScriptView
              script={currentScript}
              onAddStep={(script) => addStep(script)}
              onEdit={() => setScriptDialogOpen(true)}
              onDuplicate={(script) => onDuplicateScript(script)}
              onDelete={() => onDelete()}
              onExport={() => onExportScript(currentScript)}
              onImport={() => setImporting(true)}
              onSort={() => setSortingSteps(true)}
              labels={labels}
              onViewHistory={() => onCallHistory(currentScript)}
            />
          ) : null}
          {currentStep && selectedLevel === 'step' ? (
            <StepView
              step={currentStep}
              type={types.find((t) => t.value === currentStep.type)?.label}
              onAddAnswer={(step) => addAnswer(step)}
              onEdit={() => setStepDialogOpen(true)}
              onDelete={() => onDelete()}
              onDuplicate={(step) => onDuplicateStep(step)}
              onExport={() => onExportStep()}
              onSort={() => onSortAnswers()}
              labels={labels}
              properties={properties}
            />
          ) : null}
          {currentAnswer && selectedLevel === 'answer' ? (
            <AnswerView
              step={currentStep}
              answer={currentAnswer}
              steps={
                currentScript && currentScript.steps ? currentScript.steps : []
              }
              onEdit={() => setAnswerDialogOpen(true)}
              onDelete={() => onDelete()}
              onDuplicate={(answerToDuplicate) =>
                onDuplicateAnswer(answerToDuplicate)
              }
              catalogs={catalogs}
              labels={labels}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

ScriptBuilder.propTypes = {
  labels: PropTypes.object,
  flags: PropTypes.shape({
    scriptBuilderAccess: PropTypes.bool,
  }),
};
export default withLDConsumer()(withLabels(ScriptBuilder));
