import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => ({
  dialog_title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '10px',
    padding: '10px 16px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px',
  },
  input: {
    marginTop: '10px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    '&:not(:first-child)': {
      marginLeft: 10,
    },
  },
  errors: {
    gridColumnStart: 'span 2',
    padding: '10px 16px',
    border: 'solid 1px #b20303',
    color: '#b20303',
    margin: '16px',
    borderRadius: '8px',
  },
  size_dropdown: {
    marginBottom: '20px',
  },
}));

export { useStyles };
