import React from 'react';
import PropTypes from 'prop-types';
import ErrorLayout from 'pages/ErrorLayout/ErrorLayout';
import Typography from 'common/Typography/Typography';
import { useStyles } from './AppError.style';
import clsx from 'clsx';
import Link from '../../common/Link/Link';

const AppError = ({
  errorIcon,
  messages,
  errorContainerClass,
  email,
  showTopBar,
}) => {
  const classes = useStyles();
  const errorClass = clsx([
    errorContainerClass && errorContainerClass,
    !errorContainerClass && classes.error_container,
  ]);

  const emailLink = `mailto:${email}?subject=Feedback`;
  return (
    <ErrorLayout showTopBar={showTopBar}>
      <div className={errorClass}>
        {errorIcon}
        <Typography level="large" bold className={classes.title}>
          {messages.title}
        </Typography>
        <Typography>{messages.message1}</Typography>
        <Typography>{messages.message2}</Typography>
        <div className={classes.bottom}>
          {email && (
            <>
              <Typography>
                {messages.message3}{' '}
                <Link to={emailLink}>{messages.message4}</Link>{' '}
                {messages.message5}
              </Typography>
              <Typography>{messages.message6}</Typography>
            </>
          )}
          {!email && <Typography>{messages.message3}</Typography>}
        </div>
      </div>
    </ErrorLayout>
  );
};

AppError.propTypes = {
  errorIcon: PropTypes.element,
  errorContainerClass: PropTypes.element,
  messages: PropTypes.object,
  email: PropTypes.string,
  showTopBar: PropTypes.bool,
};

AppError.defaultProps = {
  showTopBar: true,
};

export default AppError;
