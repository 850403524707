import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './InfoRow.style';
import Typography from 'common/Typography/Typography';

const InfoRow = ({ title, info, bold, icon, testId, infoRightMargin }) => {
  const classes = useStyles({ infoRightMargin });

  const getIcon = () => {
    if (!icon) return null;
    return (
      <Typography
        level="large"
        color="cosmicCobalt3"
        className={[classes.centered_icon, classes.right_spaced]}
      >
        {icon}
      </Typography>
    );
  };

  return (
    <div
      className={[classes.info_row, classes.info_right_margin]}
      data-testid={testId}
    >
      <Typography level="tiny" color="eerieBlack5">
        {title}
      </Typography>
      <div className={classes.name_row}>
        {getIcon()}
        <Typography
          level="small"
          color={info === 'Unknown' ? 'disabled' : 'eerieBlack2'}
          bold={bold}
        >
          {info}
        </Typography>
      </div>
    </div>
  );
};

InfoRow.defaultProps = {
  icon: null,
  bold: false,
  infoRightMargin: 0,
};

InfoRow.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  infoRightMargin: PropTypes.number,
  testId: PropTypes.string.isRequired,
  bold: PropTypes.bool,
  icon: PropTypes.node,
};

export default InfoRow;
