import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles(() => ({
  activity_mobile_container: {
    padding: '24px',
    height: 'calc(100vh - 392px)',
  },
  activity_xl_container: {
    marginTop: '32px',
  },
  activity_desktop_container: {
    marginTop: '32px',
  },
}));

export { useStyles };
