import PropTypes from 'prop-types';
import React from 'react';
import Divider from 'common/Divider/Divider';
import ButtonBar from '../common/ButtonBar/ButtonBar';
import ViewRow from '../common/ViewRow/ViewRow';
import { StepPropType } from '../scriptBuilderPropTypes';
import { useStyles } from './StepView.style';

const StepView = ({
  step,
  onAddAnswer,
  onEdit,
  onDelete,
  onDuplicate,
  onExport,
  type,
  labels,
  properties,
}) => {
  const classes = useStyles();

  const isCustomizedChiclet =
    step &&
    step.type &&
    (step.type === 'contactInfo' ||
      step.type === 'familyInfo' ||
      step.type === 'locationInfo');

  const calculateAddAnswer = () => {
    const actualAnswers = step.answers.length;
    const foundProperty = properties.filter(
      (p) => p.value === step.property && p.type === 'enum',
    );
    return foundProperty[0] && foundProperty[0].values
      ? foundProperty[0].values.length !== actualAnswers
      : true;
  };

  const showAddAnswer = !isCustomizedChiclet && calculateAddAnswer();

  return (
    <div className={classes.container}>
      <ButtonBar
        onDelete={
          step.answers && step.answers.length === 0
            ? () => onDelete(step)
            : null
        }
        onAdd={showAddAnswer ? () => onAddAnswer(step) : null}
        onDuplicate={() => onDuplicate(step)}
        onEdit={() => onEdit(step)}
        onExport={() => onExport()}
        addLabel={labels.ADD_ANSWER}
        duplicateLabel={labels.DUPLICATE_STEP}
        labels={labels}
      />
      <div className={classes.body}>
        <ViewRow label={labels.NUMBER} value={step.number} />
        <ViewRow label={labels.ORDER} value={step.order} />
        <ViewRow label={labels.NAME} value={step.name} />
        <ViewRow label={labels.SIZE} value={step.size} />
        <ViewRow label={labels.STEP_TYPE} value={type} />
        <ViewRow label={labels.PROPERTY} value={step.property} />
        <ViewRow label={labels.STEP_HIDDEN} value={step.isHidden} />
        <ViewRow label={labels.STEP_LEGAL} value={step.isLegal} />
        <ViewRow label={labels.STEP_OPTIONAL} value={step.isOptional} />
        <ViewRow label={labels.TEXT} value={step.text} html />
        <ViewRow label={labels.STEP_PIPEDA} value={step.isPipeda} />
        <ViewRow
          label={labels.ALTERNATE_TEXT}
          value={step.alternateText}
          html
        />
      </div>
      <Divider />
      <div className={classes.body}>
        <ViewRow
          label={labels.CREATED_BY}
          value={
            step.createdBy?.firstName && step.createdBy?.lastName
              ? `${step.createdBy?.firstName} ${step.createdBy?.lastName}`
              : undefined
          }
        />
        <ViewRow label={labels.CREATED_AT} value={step.createdAt} date />
        <ViewRow
          label={labels.UPDATED_BY}
          value={
            step.updatedBy?.firstName && step.updatedBy?.lastName
              ? `${step.updatedBy?.firstName} ${step.updatedBy?.lastName}`
              : undefined
          }
        />
        <ViewRow label={labels.UPDATED_AT} value={step.updatedAt} date />
      </div>
    </div>
  );
};

StepView.propTypes = {
  step: StepPropType,
  onAddAnswer: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onDuplicate: PropTypes.func,
  onExport: PropTypes.func,
  type: PropTypes.string,
  labels: PropTypes.object,
  properties: PropTypes.array,
};

export default StepView;
