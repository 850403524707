import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  edit_resident_container: {
    height: '72vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
  },
  edit_resident_container_xl: {
    height: 'auto',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginRight: 18,
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
      },
    },
  },
  family_file_header: {
    display: 'flex',
    alignItems: 'center',
  },
  family_file_id: {
    paddingLeft: '5px',
  },
  about_resident_title: {
    paddingLeft: 24,
  },
  basic_information_container: {
    padding: '8px 24px 24px 24px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  move_in_date_container: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  types_of_care_container: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  select_type_of_care: {
    marginTop: 9,
  },
  move_in_date_picker: {
    width: theme.spacing(30),
    zIndex: 9999,
    [theme.breakpoints.down('sm')]: {
      '& .items_container': {
        zIndex: 1,
        width: 'fit-content',
      },
      '& .select_option': {
        marginRight: theme.spacing(1),
      },
    },
  },
  divider: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  actions_container: {
    padding: '0px 24px 16px 0',
  },
  actions: {
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(2),
    },
  },
  likelihood_to_move_slider: {
    width: '100%',
    marginLeft: 65,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginLeft: 0,
      '& > div': {
        marginLeft: 0,
      },
      '&.slider_container': {
        paddingLeft: 0,
      },
    },
  },
  resident_likelihood_to_move_container: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  likelihood_to_move_input: {
    width: 100,
  },
  default_input: {
    width: theme.spacing(23),
    height: 63,
    [theme.breakpoints.down('sm')]: {
      '& > div > div > input': {
        paddingRight: 14,
        paddingLeft: 14,
      },
    },
  },
  year_calculation: {
    width: 81,
    height: 63,
  },
  preferred_location_container: {
    padding: '8px 24px 0 24px',
    marginTop: 0,
  },
  with_padding: {
    padding: '24px 24px 16px 24px',
  },
  error_message: {
    width: '87%',
    marginLeft: '24px',
  },

  preferred_location_message: {
    flex: '1',
    marginBottom: '8px',
    '& span': {
      color: theme.palette.neutral.cosmicCobalt3,
      cursor: 'pointer',
      textDecorationLine: 'underline',
    },
  },
  edit_resident_modal_container: {
    [theme.breakpoints.down('sm')]: {
      '&.modal_container': {
        width: '80%',
        minWidth: '80%',
      },
    },
  },
  names_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  default_input_name: {
    marginRight: 18,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginTop: theme.spacing(1),
      width: '48%',
    },
  },
  age_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > div': {
      marginRight: 18,
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        marginTop: theme.spacing(1),
        width: '48%',
      },
    },
  },
  type_of_cares_container: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

export { useStyles };
