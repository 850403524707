import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './BedroomTypes.style';
import Button from 'common/Button/Button';

const BedroomTypes = ({
  labels,
  filters,
  onChange,
  shouldClear,
  onClear,
  filterToRemove,
}) => {
  const classes = useStyles();
  const [bedroomTypes, setBedroomTypes] = useState(filters);
  const [allRoomTypesSelected, setAllRoomTypesSelected] = useState(false);

  useEffect(() => {
    setBedroomTypes(filters);
  }, [filters]);

  useEffect(() => {
    if (filterToRemove) {
      const updatedFilters = bedroomTypes.map((type) => {
        if (type.label === filterToRemove) {
          type.selected = false;
        }
        return type;
      });
      setBedroomTypes(updatedFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterToRemove]);

  useEffect(() => {
    shouldClear && clearFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldClear]);

  useEffect(() => {
    const allSelected = !bedroomTypes.find(
      (bedroomType) => !bedroomType.selected,
    );
    setAllRoomTypesSelected(allSelected);
  }, [bedroomTypes]);

  const handleToggleAll = () => {
    setAllRoomTypesSelected(!allRoomTypesSelected);
    const newBedRoomTypes = bedroomTypes.map((room) => ({
      ...room,
      ...{ selected: !allRoomTypesSelected },
    }));
    setBedroomTypes([...newBedRoomTypes]);
    onChange(newBedRoomTypes);
  };

  const handleSelectedItem = (bedroom) => {
    bedroom.selected = !bedroom.selected;
    setBedroomTypes([...bedroomTypes]);
    onChange(bedroomTypes);
  };

  const clearFilters = () => {
    const clearBedroomTypes = bedroomTypes.map((bedroom) => ({
      ...bedroom,
      ...{ selected: false },
    }));
    setBedroomTypes([...clearBedroomTypes]);
    onClear();
  };

  return (
    <div className={classes.filter_container}>
      <Button
        size="small"
        className={classes.single_button}
        onClick={() => handleToggleAll()}
        type={allRoomTypesSelected ? 'primary' : 'outlined'}
      >
        {labels.ALL_ROOM_TYPES}
      </Button>
      {bedroomTypes.map((bedroom, key) => (
        <Button
          className={classes.single_button}
          size="small"
          startIcon={bedroom.icon}
          key={key}
          onClick={() => handleSelectedItem(bedroom)}
          type={bedroom.selected ? 'primary' : 'outlined'}
        >
          {bedroom.label}
        </Button>
      ))}
    </div>
  );
};

BedroomTypes.propTypes = {
  labels: PropTypes.object,
  filters: PropTypes.array,
  filterToRemove: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  shouldClear: PropTypes.bool,
};

export default BedroomTypes;
