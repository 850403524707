import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => ({
  buttons_block: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
    marginBottom: '30px',
    '& :not(:first-child)': {
      marginLeft: 4,
    },
  },
  answer: {
    marginRight: '8px',
    marginBottom: '8px',
    boxSizing: 'border-box',
  },
  double: {
    gridColumnStart: 'span 2',
  },
  with_border: {
    border: 'solid 1px #eee',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',

    borderRadius: '8px',
  },
  answer_body: {
    display: 'grid',
    gridTemplateColumns: '350px 1fr',
    gridGap: '4px',
    padding: '8px',
  },
  name: {
    color: '#000',
    fontWeight: 'bold',
  },
  value: {
    color: '#707070',
    marginLeft: 4,
  },
}));

export { useStyles };
