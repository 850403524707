import gql from 'graphql-tag';

export default gql`
  mutation(
    $auth: String!
    $inquiryId: Int!
    $campaignName: String!
    $listName: String!
    $disposition: String
    $callTime: SafeInt!
    $provider: Provider!
    $phoneNumber: String!
    $oneId: String!
  ) {
    addCallToList(
      input: {
        auth: $auth
        inquiryId: $inquiryId
        campaignName: $campaignName
        listName: $listName
        disposition: $disposition
        callTime: $callTime
        provider: $provider
        phoneNumber: $phoneNumber
        oneId: $oneId
      }
    ) {
      uploadErrorsCount
      crmRecordsInserted
      crmRecordsUpdated
      listRecordsDeleted
      listRecordsInserted
    }
  }
`;
