import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useStyles } from './ConferenceCallButton.style';

const ConferenceCallButton = ({ icon, color, title, disabled, onClick }) => {
  const classes = useStyles();
  if (disabled) {
    onClick = () => {};
  }
  return (
    <div
      className={clsx(
        classes.conference_call_button,
        disabled && 'is_disabled',
        color,
      )}
      onClick={onClick}
    >
      {icon}
      {title}
    </div>
  );
};

ConferenceCallButton.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  color: PropTypes.oneOf(['cosmicCobalt', 'smokyTopaz']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

ConferenceCallButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  color: 'cosmicCobalt',
};

export default ConferenceCallButton;
