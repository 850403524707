import React from 'react';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialRadio from '@material-ui/core/Radio';
import PropTypes from 'prop-types';

import { generateId } from '../utils/random';
import SmartTooltip from '../SmartTooltip/SmartTooltip';
import { useStyles } from './Radio.style';

const Radio = ({
  name,
  items,
  label,
  onChange,
  onClick,
  selected,
  isRow,
  className,
  itemClass,
}) => {
  const classes = useStyles();

  const handleOnChange = (event) => {
    const value = event.target.value;
    const selectedItem = items.filter((item) => item.value === value)[0];
    onChange(selectedItem);
  };

  const handleOnClick = (event) => {
    onClick(event);
  };

  return (
    <FormControl
      className={clsx(className, classes.radio_container)}
      component="fieldset"
    >
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        name={name}
        value={selected ? selected.value : ''}
        onChange={handleOnChange}
        row={isRow}
      >
        {items.map((item) => (
          <SmartTooltip
            className={clsx(className, classes.tooltip_container)}
            key={item.value}
            title={item.tooltip}
            placement="top"
          >
            <FormControlLabel
              className={clsx(
                selected && item.value === selected.value && 'selected',
                classes.label_container,
                itemClass,
              )}
              value={item.value}
              disabled={item.disabled || false}
              control={<MaterialRadio className={classes.radio} />}
              label={item.label}
              onClick={() => handleOnClick(item.value)}
            />
          </SmartTooltip>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

Radio.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  selected: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  isRow: PropTypes.bool,
  itemClass: PropTypes.string,
};

Radio.defaultProps = {
  name: generateId(),
  className: '',
  label: '',
  onClick: () => {},
  onChange: () => {},
  isRow: true,
  itemClass: '',
};

export default Radio;
