import clsx from 'clsx';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'common/Button/Button';
import Modal from 'common/Modal/Modal';
import { useStyles } from './ScriptBuilderModal.style';

const ScriptBuilderModal = ({
  title,
  iconComponent,
  bodyComponent,
  onSave,
  onClose,
  open,
  labels,
  bodyClass,
}) => {
  const classes = useStyles();

  if (!open) return null;
  const deboucedSave = debounce(() => onSave(), 300, {
    leading: true,
    trailing: false,
  });
  return (
    <Modal
      className={classes.modal_container}
      icon={iconComponent}
      title={title}
      isOpen
      noBodyScroll={true}
      noBodyPadding={true}
      onClose={onClose}
    >
      <div className={clsx(classes.modal_body, bodyClass)}>{bodyComponent}</div>
      <div className={classes.modal_footer}>
        <Button onClick={deboucedSave}>{labels.SAVE}</Button>
        <Button onClick={onClose} style={{ marginLeft: 10 }}>
          {labels.CANCEL}
        </Button>
      </div>
    </Modal>
  );
};

ScriptBuilderModal.defaultProps = {
  bodyClass: '',
};

ScriptBuilderModal.propTypes = {
  title: PropTypes.string,
  bodyClass: PropTypes.string,
  iconComponent: PropTypes.node,
  bodyComponent: PropTypes.node,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  labels: PropTypes.object,
};

export default ScriptBuilderModal;
