import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    alert_container: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      width: 'fit-content',
      height: '100vh',
      minWidth: '520px',
      maxHeight: '800px',
      margin: '0 auto',
      '&:focus': {
        outline: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        width: '80%',
        minWidth: '80%',
      },
    },
    body: {
      width: 560,
      backgroundColor: theme.palette.light.platinum5,
      borderRadius: 4,
      paddingBottom: 24,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        minWidth: '100%',
      },
    },
    body_no_buttons: {
      paddingBottom: 0,
    },
    title_container: {
      height: '43px',
      width: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '24px 16px',
      borderRadius: '4px 4px 0 0',
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 11,
      '& svg': {
        marginRight: '8px',
        verticalAlign: 'middle',
        fontSize: 24,
      },
    },
    success: {
      '& svg': {
        color: theme.palette.positive.wintergreenDream3,
      },
    },
    error: {
      '& svg': {
        color: theme.palette.correction.smokyTopaz2,
      },
    },
    delete: {
      '& svg': {
        color: theme.palette.correction.smokyTopaz2,
      },
    },
    informative: {
      '& svg': {
        color: theme.palette.neutral.cosmicCobalt3,
      },
    },
    description: {
      padding: '8px 16px',
    },
    close_icon: {
      alignSelf: 'center',
      '& svg': {
        cursor: 'pointer',
        fontSize: '16px',
        verticalAlign: 'middle',
      },
    },
    buttons_container: {
      display: 'flex',
      marginTop: theme.spacing(2),
      justifyContent: 'flex-end',
      '& > *': {
        marginRight: 24,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        margin: 24,
        justifyContent: 'space-between',
        '& > *': {
          width: '100%',
          marginRight: 0,
          marginTop: theme.spacing(1),
        },
      },
    },
    confirm_button: {
      [theme.breakpoints.down('xs')]: {
        width: 100,
      },
    },
    single_button_container: {
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        margin: 'auto',
        width: 'fit-content',
      },
    },
  };
});

export { useStyles };
