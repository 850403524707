import gql from 'graphql-tag';

export default gql`
  mutation($inquiryId: Int!, $note: String!) {
    createInquiryNote(input: { inquiryId: $inquiryId, note: $note }) {
      inquiryNoteId
      note
      createdBy {
        firstName
        lastName
        userId
      }
      createdAt
      updatedBy {
        firstName
        lastName
        userId
      }
      updatedAt
    }
  }
`;
