import {
  getTodayOrYesterdayOrPastDate,
  getHourAndMinutes12HourFormat,
} from '../../utils/dateFormat';
import MedicalBackgroundFormModel from './MedicalBackground/MedicalBackgroundFormModel';
import FinancialCriteriaFormModel from './FinancialCriteria/FinancialCriteriaFormModel';
import ContactsFormModel from './ContactInformation/ContactsFormModel';
import CommunityPreferenceFormModel from './CommunityPreferences/CommunityPreferenceFormModel';

const toFamilyFileInformation = (familyFile) => {
  const contactInformation = _buildContactInformation(familyFile);

  return {
    overview: _buildOverview(familyFile),
    contactInformation,
    medicalBackground: _buildMedicalBackgroundInformation(familyFile),
    financialCriteria: _buildFinancialCriteria(familyFile),
    tourDetails: _buildTourDetails(familyFile),
    notes: _buildNotes(familyFile),
    communityPreferences: _buildCommunityPreferences(
      familyFile,
      contactInformation,
    ),
    isSelfLead: _buildIsSelfLeadBasedOnAudienceKey(familyFile),
    contacts: _buildContacts(contactInformation),
  };
};

const selfLeadsAudiences = [
  'self_not_urgent_veteran_financially_unqualified',
  'self_urgent_financially_unqualified',
  'self_not_urgent_financially_qualified',
];

const _buildIsSelfLeadBasedOnAudienceKey = (familyFile) => {
  return selfLeadsAudiences.some((selfAudience) =>
    familyFile.audienceKey?.includes(selfAudience),
  );
};

const _buildOverview = (familyFile) => {
  const noteText = familyFile.keyNote ? familyFile.keyNote.noteText : '';
  return {
    description: noteText,
    familyFileId: familyFile.familyFileId,
    keyNoteId: familyFile.keyNote ? familyFile.keyNote.keyNoteId : null,
    typeOfCare: null,
  };
};

const _buildContactInformation = (familyFile) => {
  const primaryContact = familyFile.contacts.find(
    (contact) => contact.profileData.isPrimary,
  );
  const secondaryContact = familyFile.contacts.find(
    (contact) => !contact.profileData.isPrimary,
  );
  let contact = {};
  if (primaryContact) {
    contact = {
      familyFileId: familyFile.familyFileId,
      residentId:
        familyFile.residents.length > 0
          ? familyFile.residents[0].residentId
          : undefined,
      careTypes:
        familyFile.residents.length > 0
          ? familyFile.residents[0].careTypes
          : [],
      doNotCall: !!familyFile.leadData.doNotCall,
      primaryContact: {
        firstName: `${primaryContact.profileData.firstName}`,
        lastName: `${primaryContact.profileData.lastName}`,
        name: `${primaryContact.profileData.firstName} ${primaryContact.profileData.lastName}`,
        relationship: primaryContact.profileData.relationToResident,
        relationToResidentId: primaryContact.profileData.relationToResidentId,
        address1: primaryContact.profileData.address1,
        address2: primaryContact.profileData.address2,
        allowContact: primaryContact.profileData.allowContact,
        city: primaryContact.profileData.city,
        state: primaryContact.profileData.state,
        country: primaryContact.profileData.country,
        postalCode: primaryContact.profileData.postalCode,
        isPrimary: primaryContact.profileData.isPrimary,
        phones: primaryContact.profileData.phones,
        emailAddresses: primaryContact.profileData.emailAddresses,
        contactDetail: _buildContactDetail(primaryContact.profileData),
      },
    };
  }

  if (secondaryContact) {
    contact['secondaryContact'] = {
      firstName: `${secondaryContact.profileData.firstName}`,
      lastName: `${secondaryContact.profileData.lastName}`,
      name: `${secondaryContact.profileData.firstName} ${secondaryContact.profileData.lastName}`,
      relationship: secondaryContact.profileData.relationToResident,
      relationToResidentId: secondaryContact.profileData.relationToResidentId,
      address1: secondaryContact.profileData.address1,
      address2: secondaryContact.profileData.address2,
      allowContact: secondaryContact.profileData.allowContact,
      city: secondaryContact.profileData.city,
      state: secondaryContact.profileData.state,
      country: secondaryContact.profileData.country,
      postalCode: secondaryContact.profileData.postalCode,
      isPrimary: secondaryContact.profileData.isPrimary,
      phones: secondaryContact.profileData.phones,
      emailAddresses: secondaryContact.profileData.emailAddresses,
      contactDetail: _buildContactDetail(secondaryContact.profileData),
    };
  }

  return contact;
};

const _buildContactDetail = (profileData) => {
  if (profileData.emailAddress !== '' && profileData.primaryPhone === '') {
    const email = profileData.emailAddress;
    return { email };
  }
  if (profileData.emailAddress === '' && profileData.primaryPhone !== '') {
    const phonePrimary = profileData.primaryPhone;
    const primaryPhoneType = profileData.primaryPhoneType;
    let phoneSecondary;
    let secondaryPhoneType;
    if (profileData.secondaryPhone !== '') {
      phoneSecondary = profileData.secondaryPhone;
      secondaryPhoneType = profileData.secondaryPhoneType;
    }
    return {
      phonePrimary,
      phoneSecondary,
      primaryPhoneType,
      secondaryPhoneType,
    };
  }

  if (profileData.emailAddress !== '' && profileData.primaryPhone !== '') {
    const email = profileData.emailAddress;
    const phonePrimary = profileData.primaryPhone;
    const primaryPhoneType = profileData.primaryPhoneType;
    let phoneSecondary;
    let secondaryPhoneType;
    if (profileData.secondaryPhone !== '') {
      phoneSecondary = profileData.secondaryPhone;
      secondaryPhoneType = profileData.secondaryPhoneType;
    }
    return {
      phonePrimary,
      phoneSecondary,
      email,
      primaryPhoneType,
      secondaryPhoneType,
    };
  }
  return {
    phonePrimary: '',
    phoneSecondary: '',
    email: '',
    primaryPhoneType: '',
    secondaryPhoneType: '',
  };
};

const _parseToBoolean = (text) => text && !(text.toLowerCase() === 'no');

const _buildMedicalBackgroundInformation = (familyFile) => {
  return new MedicalBackgroundFormModel({
    formDataId: familyFile.questionsData.formDataId,
    rehabSkilledNursingRequired: familyFile.questionsData.rehab,
    memoryLoss: familyFile.questionsData.memoryLoss,
    memoryDiagnosis: familyFile.questionsData.memoryDiagnosis,
    currentSituation: familyFile.questionsData.currentLiving,
    locationName: familyFile.questionsData.locationName,
    currentMedication: familyFile.questionsData.medications,
    otherIssues: familyFile.questionsData.wandering,
    moreHelp: null,
    helpInShower: _parseToBoolean(familyFile.questionsData.bathing),
    helpInToileting: _parseToBoolean(familyFile.questionsData.toileting),
    diabeticCare: familyFile.questionsData.diabetic,
    gettingAroundDescription: familyFile.questionsData.getAround,
    healthIssues: familyFile.questionsData.healthIssues,
    anythingElse: familyFile.questionsData.anythingElse,
    oneId: familyFile.oneId,
    familyFileId: familyFile.familyFileId,
  });
};

const _buildFinancialCriteria = (familyFile) => {
  return new FinancialCriteriaFormModel({
    formDataId: familyFile.leadData.formDataId,
    estimatedBudgetRange: familyFile.leadData.budgetDescription,
    financialResources: familyFile.leadData.financialResources,
    hasLongTermCareInsurancePolicy: familyFile.leadData.longTermCare,
    isVeteran: familyFile.leadData.veteran,
    vaAid: familyFile.leadData.vaAid,
    oneId: familyFile.oneId,
    familyFileId: familyFile.familyFileId,
    medicaid: familyFile.medicaid,
  });
};

const _buildNotes = (familyFile) => {
  return (
    (familyFile.notes || [])
      .filter((f) => f.createdBy && f.noteType)
      .map((f) => ({
        note: f.note,
        createdBy: `${f.createdBy.firstName} ${f.createdBy.lastName}`,
        code: f.noteType.noteTypeCode,
        createdAt: f.createdAt,
      })) || []
  );
};

const _getCommunityName = (communityId, familyFile) => {
  if (
    familyFile.tourCommunitiesExtended &&
    familyFile.tourCommunitiesExtended.communities &&
    familyFile.tourCommunitiesExtended.communities.length > 0
  ) {
    const { communities } = familyFile.tourCommunitiesExtended;
    let foundCommunity = communities.find((community) => {
      return community.id === communityId.toString();
    });
    return foundCommunity && foundCommunity.name && foundCommunity.name !== ''
      ? foundCommunity.name
      : 'Community Name Unknown';
  } else {
    return 'Community Name Unknown';
  }
};

const _getTourFormattedDate = (tourDate) => {
  const date = getTodayOrYesterdayOrPastDate(tourDate);
  const time = getHourAndMinutes12HourFormat(tourDate);
  return date + ', ' + time;
};

const _buildTourDetails = (familyFile) => {
  if (
    familyFile.tourDetailsExtended &&
    familyFile.tourDetailsExtended.length > 0
  ) {
    return familyFile.tourDetailsExtended.map((tour) => {
      return {
        communityName: _getCommunityName(tour.communityId, familyFile),
        scheduledTourDate: _getTourFormattedDate(tour.scheduledTourDate),
      };
    });
  } else {
    return [];
  }
};

const _buildCommunityPreferences = (familyFile, contactInformation) => {
  const locationRange =
    familyFile.familyFileCommunityPreferences &&
    familyFile.familyFileCommunityPreferences.locationRange
      ? familyFile.familyFileCommunityPreferences.locationRange
      : 0;
  const roomPreference =
    familyFile.familyFileCommunityPreferences &&
    familyFile.familyFileCommunityPreferences.roomPreference
      ? familyFile.familyFileCommunityPreferences.roomPreference
      : null;

  return new CommunityPreferenceFormModel({
    familyFileId: familyFile.familyFileId,
    oneId: familyFile.oneId ? familyFile.oneId : null,
    locationRange,
    roomPreference,
    residentId: contactInformation.residentId,
    careTypePreference: contactInformation.careTypes,
  });
};

const _buildContacts = (contactInformation) => {
  const contactList = [];

  if (contactInformation.primaryContact) {
    const primaryContact = new ContactsFormModel(
      contactInformation.primaryContact,
    );
    contactList.push(primaryContact);
  }
  if (contactInformation.secondaryContact) {
    const secondaryContact = new ContactsFormModel(
      contactInformation.secondaryContact,
    );
    contactList.push(secondaryContact);
  }

  return contactList;
};

export default toFamilyFileInformation;
