import gql from 'graphql-tag';

export default gql`
  query($familyFileId: Int!) {
    findFamilyFileById(id: $familyFileId) {
      keyNote {
        keyNoteId
        noteText
        updatedAt
        updatedBy {
          firstName
          lastName
        }
      }
      notes {
        familyNoteId
        note
        noteType {
          noteTypeCode
        }
        createdAt
        createdBy {
          role
          roleId
          firstName
          lastName
        }
      }
      user {
        username
        isActive
      }
      contacts {
        profileData {
          formDataId
          isPrimary
          personId
        }
      }
    }
  }
`;
