import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './CommunityInfoPinPoint.style';
import Typography from 'common/Typography/Typography';
import Pin from 'common/Icons/maps/Pin';
import CallPhone from 'common/Icons/call/CallPhone';
import StarNo from 'common/Icons/basic/StarNo';
import StarFilled from 'common/Icons/basic/StarFilled';
import StarFilledHalf from 'common/Icons/basic/StarFilledHalf';
import Link from 'common/Link/Link';
import communityRatingService from 'services/communityRatingsService';
import clsx from 'clsx';

const CommunityInfoPinPointXS = ({
  labels,
  communityId,
  name,
  companyName,
  address,
  phone,
  city,
  state,
  zip,
  distance,
  // referred,
  // moveIns,
  ratingAverage,
  numberOfReviews,
  isSmallMap,
}) => {
  const classes = useStyles();

  const getAddress = () => {
    const fullAddress = `${address} ${city}, ${state} ${zip}`;
    let distanceLength = 0;
    if (distance) {
      distanceLength = distance.length;
    }
    const isAddressTooLong = fullAddress.length + distanceLength >= 30;

    if (isAddressTooLong) {
      return (
        <div>
          <Typography level="small" color="eerieBlack4">
            {`${address}`}
            {distance && (
              <b className={classes.distance}>{`(${distance} mi)`}</b>
            )}
          </Typography>
          <Typography level="small" color="eerieBlack4">
            {`${city}, ${state} ${zip}`}
          </Typography>
        </div>
      );
    }
    return (
      <Typography level="small" color="eerieBlack4">
        {`${address} ${city}, ${state} ${zip}`}
        {distance && <b className={classes.distance}>{`(${distance} mi)`}</b>}
      </Typography>
    );
  };

  const getRatingAverage = () => {
    if (ratingAverage === 0) {
      return (
        <div className={classes.no_rating_container}>
          <Typography color="disabled">{labels.NO_RATINGS}</Typography>
          <StarNo className={classes.no_rating} />
        </div>
      );
    }

    const roundedRating = ratingAverage.toFixed(1);
    const { wholeStars, halfStar } = communityRatingService.getStarInfo(
      ratingAverage,
      roundedRating,
    );
    const stars = Array.from(Array(wholeStars).keys());

    return (
      <div className={classes.rating_container}>
        <Typography bold color="fieldDrab3">
          {roundedRating}
        </Typography>
        <div className={classes.rating_star_container}>
          {stars.map((key) => (
            <div key={key} className={classes.rating_star}>
              <StarFilled />
            </div>
          ))}
          {halfStar && (
            <div className={classes.rating_star}>
              <StarFilledHalf />
            </div>
          )}
        </div>
        <div className={classes.review_count}>
          <Link
            onClick={(event) =>
              communityRatingService.handleViewReviewsClick(
                event,
                communityId,
                name,
              )
            }
          >
            ({numberOfReviews}{' '}
            {numberOfReviews > 1 ? `${labels.REVIEW}s` : labels.REVIEW})
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div
      className={
        isSmallMap
          ? clsx(
              classes.community_info_container_xs,
              classes.community_info_small_map_container_xs,
            )
          : classes.community_info_container_xs
      }
    >
      <Typography bold>{name}</Typography>
      <Typography className={classes.company_name}>{companyName}</Typography>
      {!isSmallMap && (
        <div>
          {getRatingAverage()}
          <div className={classes.contact_container}>
            <div className={classes.contact}>
              <Pin />
              {getAddress()}
            </div>
            <div className={classes.contact}>
              <CallPhone />
              <Typography level="small" color="eerieBlack4">
                {phone}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

CommunityInfoPinPointXS.propTypes = {
  labels: PropTypes.object.isRequired,
  communityId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  distance: PropTypes.number,
  address: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  // referred: PropTypes.number.isRequired,
  // moveIns: PropTypes.number.isRequired,
  ratingAverage: PropTypes.number.isRequired,
  numberOfReviews: PropTypes.number.isRequired,
  isSmallMap: PropTypes.bool,
};

export default CommunityInfoPinPointXS;
