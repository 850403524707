import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './SquareButton.style';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import clsx from 'clsx';

const SquareButton = ({ icon, title, isActive, onClick, disabled }) => {
  const classes = useStyles();
  if (disabled) {
    onClick = () => {};
  }
  return (
    <div
      className={clsx(
        classes.square_button_container,
        isActive && classes.active,
        disabled && classes.disabled,
      )}
      data-testid="square-button-icon"
      onClick={onClick}
    >
      <SmartTooltip title={title} placement="bottom">
        {icon}
      </SmartTooltip>
    </div>
  );
};

SquareButton.propTypes = {
  icon: PropTypes.node,
  isActive: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

SquareButton.defaultProps = {
  isActive: false,
  onClick: () => {},
  disabled: false,
};

export default SquareButton;
