import NounMe from '../../common/Icons/basic/NounMe';
import React from 'react';

const mapper = {
  self_urgent_financially_qualified: {
    label: 'SELF',
    icon: <NounMe />,
    color: 'cosmicCobalt1',
  },
  self_not_urgent_not_veteran_financially_unqualified: {
    label: 'SELF',
    color: 'cosmicCobalt1',
    icon: <NounMe />,
  },
  self_not_urgent_veteran_financially_unqualified: {
    label: 'SELF',
    color: 'cosmicCobalt1',
    icon: <NounMe />,
  },
  self_urgent_financially_unqualified: {
    label: 'SELF',
    color: 'cosmicCobalt1',
    icon: <NounMe />,
  },
  self_not_urgent_financially_qualified: {
    label: 'SELF',
    color: 'cosmicCobalt1',
    icon: <NounMe />,
  },
};

const map = (audience, labels) => {
  const keys = Object.keys(mapper);
  const element = keys.find(
    (key) => audience && audience.match(new RegExp(key, 'ig')),
  );
  if (element) {
    return {
      icon: mapper[element].icon,
      label: labels[mapper[element].label],
      color: mapper[element].color,
    };
  }
  return null;
};

export { map };
