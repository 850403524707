import { useEffect } from 'react';
import { throttle } from 'lodash';

const useOnWindowResize = (handler) => {
  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      handler(window.innerWidth);
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, [handler]);
};

export default useOnWindowResize;
