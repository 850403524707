import { ReactComponent as SearchIcon } from './images/searching.svg';
import styled, { keyframes } from 'styled-components';

const leftToRight = keyframes`
0%{
  transform: translateX(-1.8rem)
}
100% {
  transform: translateX(2rem);
}
`;

const Searching = styled(SearchIcon)`
  height: 10rem;
  width: 10rem;
  display: inline-block;
  .magnifying_glass {
    animation: ${leftToRight} infinite 1.25s ease-in-out alternate;
    &:hover {
      animation-play-state: paused;
      cursor: pointer;
    }
  }
  .magnifying_glass_lens {
    opacity: 85%
  }
`;

export default Searching;
