import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  no_search_result_container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.light.platinum5,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '80px 0',
    '& svg': {
      fontSize: '80px',
      marginBottom: '24px',
    },
  },
  title: {
    marginBottom: '16px',
  },
}));

export { useStyles };
