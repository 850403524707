export const RESET_WARM_TRANSFER_STATE = 'warmTransfer/reset';
export const UPDATE_WARM_TRANSFER_STATE = 'warmTransfer/state/update';
export const SET_FAMILY_DISCONNECTED = 'warmTransfer/family/disconnected';
export const SET_FAMILY_CONNECTED = 'warmTransfer/family/connected';
export const SET_FAMILY_DISCONNECTED_WT = 'warmTransfer/family/disconnectedWT';
export const SET_SLA_CONNECTED = 'warmTransfer/sla/connected';
export const SET_SLA_DISCONNECTED = 'warmTransfer/sla/disconnected';
export const UPDATE_SLA_CONTACT_ATTEMPTS_COUNT =
  'warmTransfer/sla/attemptsCount';
export const UPDATE_WARM_TRANSFER_ATTEMPTS_COUNT = 'warmTransfer/attemptsCount';
export const SEND_FAMILY_FILE_EVENT = 'warmTransfer/shouldSendFamilyFileEvent';
export const CANCEL_WARM_TRANSFER = 'warmTransfer/cancel/success';
export const SET_CANCEL_WARM_TRANSFER_ERROR = 'warmTransfer/cancel/error';
export const COMPLETE_WARM_TRANSFER_FAMILY_LEFT =
  'warmTransfer/complete/familyLeft';
export const COMPLETE_WARM_TRANSFER = 'warmTransfer/complete/success';
export const SET_WARM_TRANSFER_COMPLETED = 'warmTransfer/completed/isCompleted';
export const SET_CHANGE_SLA_STATE = 'warmTransfer/sla/state';
export const SET_DUPLICATE_FAMILY_FILE =
  'warmTransfer/family/duplicateFamilyFile';
export const UPDATE_FIND_BEST_SLA_ATTEMPTS_COUNT =
  'warmTransfer/findBestSlaAttemptsCount';

export const SET_WARM_TRANSFER_TYPE = 'warmTransfer/type/set';
export const START_WARM_TRANSFER = 'warmTransfer/start';
export const RESTART_WARM_TRANSFER = 'warmTransfer/restart';

export const resetWarmTransferState = () => ({
  type: RESET_WARM_TRANSFER_STATE,
});

export const restartWarmTransfer = () => ({
  type: RESTART_WARM_TRANSFER,
});

export const startWarmTransfer = () => ({
  type: START_WARM_TRANSFER,
});

export const updateWarmTransferState = (warmTransferState) => ({
  type: UPDATE_WARM_TRANSFER_STATE,
  payload: { warmTransferState },
});

export const setWarmTransferType = (warmTransferType) => ({
  type: SET_WARM_TRANSFER_TYPE,
  payload: { warmTransferType },
});

export const setFamilyConnected = () => ({ type: SET_FAMILY_CONNECTED });

export const setFamilyDisconnected = ({
  availableAdvisors,
  activeConference,
  activeContact,
  cancelWarmTransfer,
  contextAuth,
  agentInfo,
  completeWarmTransfer,
}) => ({
  type: SET_FAMILY_DISCONNECTED,
  payload: {
    availableAdvisors,
    activeConference,
    activeContact,
    cancelWarmTransfer,
    contextAuth,
    agentInfo,
    completeWarmTransfer,
  },
});

export const setFamilyDisconnectedWarmTransfer = ({
  availableAdvisors,
  cancelWarmTransfer,
  contextAuth,
  agentInfo,
  completeWarmTransfer,
}) => ({
  type: SET_FAMILY_DISCONNECTED_WT,
  payload: {
    availableAdvisors,
    cancelWarmTransfer,
    contextAuth,
    agentInfo,
    completeWarmTransfer,
  },
});

export const setCancelWarmTransfer = ({
  availableAdvisors,
  cancelWarmTransfer,
  contextAuth,
  agentInfo,
  completeWarmTransfer,
}) => ({
  type: CANCEL_WARM_TRANSFER,
  payload: {
    availableAdvisors,
    cancelWarmTransfer,
    contextAuth,
    agentInfo,
    completeWarmTransfer,
  },
});

export const setSlaConnected = () => ({ type: SET_SLA_CONNECTED });

export const setSlaDisconnected = ({
  availableAdvisors,
  transferLead,
  contextAuth,
  agentInfo,
  completeWarmTransfer,
}) => ({
  type: SET_SLA_DISCONNECTED,
  payload: {
    availableAdvisors,
    transferLead,
    contextAuth,
    agentInfo,
    completeWarmTransfer,
  },
});

export const updateSlaContactAttemptsCount = () => ({
  type: UPDATE_SLA_CONTACT_ATTEMPTS_COUNT,
});

export const updateWarmTransferAttemptsCount = () => ({
  type: UPDATE_WARM_TRANSFER_ATTEMPTS_COUNT,
});

export const updateSendFamilyFileEvent = () => ({
  type: SEND_FAMILY_FILE_EVENT,
});

export const setCancelWarmTransferError = (hasError) => ({
  type: SET_CANCEL_WARM_TRANSFER_ERROR,
  payload: { hasError },
});

export const setCompleteWarmTransferFamilyLeft = ({
  activeContact,
  agentInfo,
  availableAdvisors,
  completeWarmConference,
  completeWarmTransfer,
  contextAuth,
}) => ({
  type: COMPLETE_WARM_TRANSFER_FAMILY_LEFT,
  payload: {
    activeContact,
    agentInfo,
    availableAdvisors,
    completeWarmConference,
    completeWarmTransfer,
    contextAuth,
  },
});

export const setCompleteWarmTransfer = ({
  activeConference,
  agentInfo,
  availableAdvisors,
  completeWarmConference,
  completeWarmTransfer,
  contextAuth,
  deleteCallsFromLists,
  dispositions,
  isLastCallAttempt,
  leaveConference,
  number,
  setDisposition,
}) => ({
  type: COMPLETE_WARM_TRANSFER,
  payload: {
    activeConference,
    agentInfo,
    availableAdvisors,
    completeWarmConference,
    completeWarmTransfer,
    contextAuth,
    deleteCallsFromLists,
    dispositions,
    isLastCallAttempt,
    leaveConference,
    number,
    setDisposition,
  },
});

export const setWarmTransferCompleted = () => ({
  type: SET_WARM_TRANSFER_COMPLETED,
});

export const setChangeSlaState = ({
  activeConference,
  agentInfo,
  availableAdvisors,
  completeWarmTransfer,
  contextAuth,
  transferLead,
}) => ({
  type: SET_CHANGE_SLA_STATE,
  payload: {
    activeConference,
    agentInfo,
    availableAdvisors,
    completeWarmTransfer,
    contextAuth,
    transferLead,
  },
});

export const setDuplicateFamilyFile = (duplicateFamilyFile) => ({
  type: SET_DUPLICATE_FAMILY_FILE,
  payload: {
    familyFileStatus: duplicateFamilyFile.familyFileStatus,
    hasDuplicate: duplicateFamilyFile.hasDuplicate,
    phoneProviderUsername: duplicateFamilyFile.phoneProviderUsername,
  },
});
export const updateFindBestSlaAttemptsCount = () => ({
  type: UPDATE_FIND_BEST_SLA_ATTEMPTS_COUNT,
});
