import React from 'react';
import PropTypes from 'prop-types';

const SwimmingPool = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.92658 16.8258L5.81885 16.3026C6.21581 16.0698 6.70743 16.0725 7.1017 16.3097L7.93657 16.8116C8.33991 17.0541 8.79343 17.1754 9.24728 17.1754C9.69157 17.1754 10.1362 17.059 10.5338 16.8258L11.4261 16.3026C11.8231 16.0698 12.3147 16.0725 12.7091 16.3097L13.5438 16.8116C14.3424 17.2916 15.3376 17.297 16.1412 16.8258L17.0335 16.3026C17.4305 16.0698 17.9219 16.0725 18.3164 16.3097L19.1511 16.8116C19.9497 17.2916 20.9448 17.297 21.7485 16.8258L22.6416 16.3021L21.9897 15.1902L21.0964 15.7138C20.7001 15.9463 20.2092 15.9436 19.8154 15.7068L18.9805 15.2049C18.3065 14.7996 17.4919 14.7323 16.7707 15.0047V5.14053C16.7707 3.93975 17.7475 2.96289 18.9483 2.96289H20.0411V1.67383H18.9481C17.0367 1.67383 15.4816 3.22892 15.4816 5.14053V6.19426H10.0751V5.14053C10.0751 3.93975 11.052 2.96289 12.2527 2.96289H13.3456V1.67383H12.2527C10.3411 1.67383 8.78604 3.22892 8.78604 5.14053V15.7978C8.72259 15.7726 8.66049 15.7427 8.60074 15.7068L7.76604 15.2049C6.96709 14.7245 5.97109 14.719 5.16694 15.1907L4.2745 15.7138C3.87821 15.9463 3.38726 15.9436 2.9935 15.7068L2.16417 15.2083L1.5 16.313L2.32933 16.8116C3.12777 17.2916 4.12293 17.297 4.92658 16.8258ZM14.2081 15.7068L13.3733 15.2049C12.5743 14.7245 11.5783 14.719 10.7742 15.1907L10.0751 15.6005V12.0959H15.4816V15.718C15.0865 15.9461 14.5994 15.9421 14.2081 15.7068ZM15.4814 7.48332V10.8068H10.0751V7.48332H15.4814Z"
        fill="currentColor"
      />
      <path
        d="M4.92658 20.075L5.81885 19.5518C6.21581 19.319 6.70743 19.3217 7.1017 19.5589L7.93657 20.0607C8.73502 20.5408 9.73018 20.5461 10.5338 20.075L11.4261 19.5518C11.8231 19.319 12.3147 19.3217 12.7091 19.5589L13.5438 20.0607C14.3423 20.5408 15.3374 20.5463 16.1412 20.075L17.0335 19.5518C17.4305 19.319 17.9219 19.3217 18.3164 19.5589L19.1511 20.0607C19.5546 20.3033 20.0081 20.4246 20.4618 20.4246C20.9062 20.4246 21.3508 20.3081 21.7485 20.075L22.6416 19.5513L21.9895 18.4393L21.0964 18.963C20.6999 19.1955 20.2092 19.1928 19.8154 18.9559L18.9805 18.4541C18.1816 17.9735 17.1857 17.9682 16.3814 18.4398L15.4892 18.963C15.0929 19.1955 14.6019 19.1928 14.2081 18.9559L13.3734 18.4541C12.5743 17.9735 11.5783 17.9682 10.7742 18.4398L9.88191 18.963C9.48562 19.1955 8.99467 19.1928 8.60091 18.9559L7.76621 18.4541C6.96709 17.9735 5.97109 17.9682 5.16694 18.4398L4.27467 18.963C3.87821 19.1955 3.38743 19.1928 2.99366 18.9559L2.16434 18.4574L1.5 19.562L2.32933 20.0607C3.12777 20.5408 4.12293 20.5463 4.92658 20.075Z"
        fill="currentColor"
      />
      <path
        d="M21.0964 22.2121C20.6999 22.4446 20.2092 22.4419 19.8154 22.2051L18.9805 21.7032C18.1816 21.2227 17.1856 21.2171 16.3814 21.6888L15.4892 22.2121C15.0927 22.4446 14.6019 22.4419 14.2081 22.2051L13.3733 21.7032C12.5743 21.2227 11.5783 21.2173 10.7742 21.6888L9.88191 22.2121C9.48562 22.4444 8.99467 22.4419 8.60091 22.2051L7.76604 21.7032C6.96709 21.2227 5.97109 21.2173 5.16694 21.6888L4.27467 22.2121C3.87821 22.4444 3.38743 22.4419 2.99366 22.2051L2.16434 21.7064L1.5 22.8111L2.32933 23.3098C3.12761 23.7897 4.12293 23.7952 4.92658 23.3241L5.81885 22.8007C6.21581 22.5679 6.70743 22.5708 7.1017 22.8078L7.93657 23.3098C8.73502 23.7899 9.73018 23.7952 10.5338 23.3241L11.4261 22.8007C11.8231 22.5679 12.3147 22.5708 12.7091 22.8078L13.5438 23.3098C14.3423 23.7897 15.3374 23.7952 16.1412 23.3241L17.0335 22.8007C17.4305 22.5679 17.9219 22.5708 18.3164 22.8078L19.1511 23.3098C19.5546 23.5524 20.0081 23.6737 20.4618 23.6737C20.9062 23.6737 21.3508 23.5572 21.7485 23.3241L22.6416 22.8002L21.9895 21.6883L21.0964 22.2121Z"
        fill="currentColor"
      />
    </svg>
  );
};

SwimmingPool.propTypes = {
  className: PropTypes.string,
};

export default SwimmingPool;
