import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

const ContainerItem = ({ children, className, xs, sm, md, lg, xl }) => {
  return (
    <Grid item className={className} xs={xs} md={md} sm={sm} lg={lg} xl={xl}>
      {children}
    </Grid>
  );
};

ContainerItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
};

export default ContainerItem;
