import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  pinpoint_button: {
    height: '30px',
    width: '30px',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    '&:focus': {
      outline: '0px',
    },
  },
  common: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    userSelect: 'none',
    transform: 'translate(-50%, -50%)',
    '&:hover': {
      zIndex: 1,
    },
  },
  default: (hover) => ({
    fontSize: '48px',
    '& .insidePath': {
      color: hover
        ? theme.palette.neutral.cosmicCobalt1
        : theme.palette.neutral.cosmicCobalt3,
      fill: hover
        ? theme.palette.neutral.cosmicCobalt1
        : theme.palette.neutral.cosmicCobalt3,
    },
  }),
  '@keyframes hover': {
    '0%': {
      marginTop: '0px',
    },
    '50%': {
      marginTop: '-10px',
    },
  },
  default_highlight: {
    fontSize: '48px',
    zIndex: 2,
    animation: '$hover 1.0s infinite ease-in',
  },
  starting_point: {
    fontSize: '56px',
    transform: 'translate(-50%, -50%)',
  },
  shadow: {
    background: 'rgba(0,0,0,0.2)',
    borderRadius: '50%',
    height: '14px',
    width: '14px',
    position: 'absolute',
    left: '5px',
    top: '50%',
    margin: '11px 0px 0px -12px',
    transform: 'rotateX(55deg)',
    zIndex: '-2',
  },
  starting_point_shadow: {
    background: 'rgba(0,0,0,0.2)',
    borderRadius: '50%',
    height: '14px',
    width: '14px',
    position: 'absolute',
    left: '12px',
    top: '6px',
    margin: '11px 0px 0px -12px',
    transform: 'rotateX(55deg) translate(-50%, -50%)',
    zIndex: '-2',
  },
}));

export { useStyles };
