import { logEvent } from 'services/analyticsService';
import { getCurrentUserFromSessionStorage } from 'utils/utils';
import convertArrayToSortedPipeDelimitedString from '../../utils/convertArrayToSortedPipeDelimitedString';

const MEDICAL_BACKGROUND_FIELD_TO_ANALYTICS_KEY_MAP = {
  currentSituation: 'Current Living Situation',
  locationName: 'Location',
  rehabSkilledNursingRequired: 'Looking for a Rehab/Skilled Nursing Facility',
  currentMedication: 'Current Medication Needs',
  memoryLoss: 'Experiences Memory Loss',
  memoryDiagnosis: 'Diagnosed with Memory Condition',
  otherIssues: 'Combative or Wandering Issues',
  gettingAroundDescription: 'Getting Around',
  diabeticCare: 'Diabetic Care',
  helpInShower: 'Help in Bathing or Showering',
  helpInToileting: 'Help in Toileting',
  healthIssues: 'Health Issues/Nutritional Needs',
  anythingElse: 'Anything Else',
};
const convertMedicalBackgroundDiffToAnalyticsKey = (diff) =>
  MEDICAL_BACKGROUND_FIELD_TO_ANALYTICS_KEY_MAP[diff.key];

const FINANCIAL_CRITERIA_FIELD_TO_ANALYTICS_KEY_MAP = {
  estimatedBudgetRange: 'Budget Range',
  financialResources: 'Financial Resources',
  hasLongTermCareInsurancePolicy: 'Long-Term Care Insurance Policy',
  isVeteran: 'Is Veteran',
  vaAid: 'VA Aid and Attendance Benefits',
  medicaid: 'Medicaid Benefits',
};
const convertFinancialCriteriaDiffToAnalyticsKey = (diff) =>
  FINANCIAL_CRITERIA_FIELD_TO_ANALYTICS_KEY_MAP[diff.key];

const COMMUNITY_PREFERENCES_FIELD_TO_ANALYTICS_KEY_MAP = {
  careTypePreference: 'Type of Care',
  roomPreference: 'Room Type',
  locationRange: 'Location Range',
};
const convertCommunityPreferencesDiffToAnalyticsKey = (diff) =>
  COMMUNITY_PREFERENCES_FIELD_TO_ANALYTICS_KEY_MAP[diff.key];

class FamilyFileAnalyticsService {
  submitFamilyFileUpdateAnalytics({
    familyFileId,
    section,
    screenName,
    submitButtonIdentifier,
    initialMedicalBackgroundFormModel,
    updatedMedicalBackgroundFormModel,
    initialFinancialCriteriaFormModel,
    updatedFinancialCriteriaFormModel,
    initialCommunityPreferencesFormModel,
    updatedCommunityPreferencesFormModel,
  } = {}) {
    // Errors in analytics should not prevent the main operation from proceeding
    try {
      let crmUserId = getCurrentUserFromSessionStorage()?.userId ?? '';
      let crmUserRole = getCurrentUserFromSessionStorage()?.role ?? '';

      const medicalBackgroundDiffs =
        initialMedicalBackgroundFormModel?.calculateDiff(
          updatedMedicalBackgroundFormModel,
        ) || [];
      const financialCriteriaDiffs =
        initialFinancialCriteriaFormModel?.calculateDiff(
          updatedFinancialCriteriaFormModel,
        ) || [];
      const communityPreferencesDiffs =
        initialCommunityPreferencesFormModel?.calculateDiff(
          updatedCommunityPreferencesFormModel,
        ) || [];

      const eventData = {
        familyFileId,
        crmUserId,
        crmUserRole,
        platform: 'beacon',
        channel: 'beacon',
        section,
        screenName,
        eventType: 'action',
        updatedCategory: convertArrayToSortedPipeDelimitedString(
          getUpdatedCategories(
            medicalBackgroundDiffs,
            financialCriteriaDiffs,
            communityPreferencesDiffs,
          ),
        ),
        updatedMedicalBackground: convertArrayToSortedPipeDelimitedString(
          medicalBackgroundDiffs
            .map(convertMedicalBackgroundDiffToAnalyticsKey)
            .filter((entry) => entry),
        ),
        updatedFinancialBackground: convertArrayToSortedPipeDelimitedString(
          financialCriteriaDiffs
            .map(convertFinancialCriteriaDiffToAnalyticsKey)
            .filter((entry) => entry),
        ),
        updatedCommunityPreferences: convertArrayToSortedPipeDelimitedString(
          communityPreferencesDiffs
            .map(convertCommunityPreferencesDiffToAnalyticsKey)
            .filter((entry) => entry),
        ),
        buttonCta: submitButtonIdentifier,
      };

      logEvent('Family File Updates Saved', eventData);
    } catch (err) {
      console.error(
        'Error when submitting family file update analytics: ',
        err,
      );
    }
  }
}

function getUpdatedCategories(
  medicalBackgroundDiffs,
  financialCriteriaDiffs,
  communityPreferencesDiffs,
) {
  const updatedCategories = [];

  if (medicalBackgroundDiffs.length) {
    updatedCategories.push('Medical Background');
  }

  if (financialCriteriaDiffs.length) {
    updatedCategories.push('Financial Criteria');
  }

  if (communityPreferencesDiffs.length) {
    updatedCategories.push('Care & Preferences');
  }

  return updatedCategories;
}

export default FamilyFileAnalyticsService;
