import PropTypes from 'prop-types';
import Typography from 'common/Typography/Typography';
import RowCard from 'common/RowCard/RowCard';
import React from 'react';
import { useStyles } from './RowDetail.style';

const RowDetail = ({ rowDetail, detailColor }) => {
  const classes = useStyles();

  return (
    <RowCard
      key={rowDetail.order}
      className={classes.summary_row_card}
      color={detailColor}
    >
      <div className={classes.row}>
        <div key={rowDetail.order} className={classes.row_container}>
          <Typography color="eerieBlack1" bold className={classes.label}>
            {rowDetail.title}
          </Typography>
          <Typography color="eerieBlack5" className={classes.content}>
            {rowDetail.content}
          </Typography>
        </div>
      </div>
    </RowCard>
  );
};

RowDetail.propTypes = {
  rowDetail: PropTypes.object.isRequired,
  detailColor: PropTypes.string.isRequired,
};

export default RowDetail;
