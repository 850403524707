import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  advisory_modal_container: {
    marginRight: '100px',
  },
  advisory_container: {
    flexBasis: '100%',
    justifyContent: 'flex-end',
    marginLeft: '180px',
    marginTop: theme.spacing(2),
  },
  advisory_message: {
    fontSize: '14px',
  },
  advisory_header: {
    display: 'flex',
    '& svg': {
      fontSize: '24px',
      marginRight: '8px',
      marginBottom: '8px',
    },
  },
  important_icon: {
    color: theme.palette.correction.smokyTopaz2,
  },
  warning_icon: {
    color: theme.palette.miscellaneous1.hotCinnamon4,
  },
  information_icon: {
    color: theme.palette.neutral.cosmicCobalt3,
  },
  advisory_restrictions_container: {
    marginLeft: '32px',
    '& svg': {
      fontSize: '24px',
      marginRight: '16px',
    },
  },
  restriction_detail_container: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: theme.spacing(1),
  },
  restriction_details: {
    display: 'flex',
    flexFlow: 'column',
  },
  advisory_detail_container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  close_button: {
    backgroundColor: theme.palette.light.platinum4,
    color: theme.palette.dark.eerieBlack1,
    '&:hover': {
      backgroundColor: theme.palette.light.platinum4,
    },
  },
  btn_container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export { useStyles };
