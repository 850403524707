import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'common/Typography/Typography';
import Expandable from 'common/Expandable/Expandable';
import Timeline from 'common/Timeline/Timeline';
import ActivityCard from '../ActivityCard/ActivityCard';
import { useStyles } from './FamilyFileHistory.style';
import { getReadableDate, getDateWithTimezoneOffset } from 'utils/dateFormat';

const FamilyFileHistory = ({ labels, items, titleLevel }) => {
  const classes = useStyles();

  const getItems = (item) => {
    return item.actions.map((action) => {
      return {
        color: 'wintergreenDream2',
        component: (
          <ActivityCard
            type={action.type}
            description={action.description}
            actionId={action.actionId}
            isCompleted={true}
            date={getDateWithTimezoneOffset(action.completedAt).getTime()}
          />
        ),
      };
    });
  };

  const getTitle = (item) =>
    item.date !== null
      ? `${getReadableDate(item.date)} (${item.actions.length})`
      : `${labels.UNKNOWN} (${item.actions.length})`;

  if (items.length === 0) {
    return '';
  }

  return (
    <div className={classes.family_file_history_container}>
      <Typography level={titleLevel} className={classes.title}>
        <b>{labels.FAMILY_FILE_HISTORY}</b>
      </Typography>
      {items.map((item, key) => (
        <Expandable
          key={key}
          className={classes.expandable}
          title={getTitle(item)}
        >
          <Timeline items={getItems(item)} />
        </Expandable>
      ))}
    </div>
  );
};

FamilyFileHistory.propTypes = {
  labels: PropTypes.object.isRequired,
  titleLevel: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'body',
    'small',
    'tiny',
  ]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.number,
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          dueDate: PropTypes.number.isRequired,
        }),
      ),
    }),
  ),
};

FamilyFileHistory.defaultProps = {
  titleLevel: 'body',
};

export default FamilyFileHistory;
