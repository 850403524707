import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './Link.style';
import clsx from 'clsx';

const Link = ({ to, openInNewTab, children, onClick, className, disabled }) => {
  const classes = useStyles();

  const getProps = () => openInNewTab && { target: '_blank' };

  return (
    <a
      onClick={onClick}
      className={clsx(classes.link, className, disabled && classes.disabled)}
      href={to}
      {...getProps()}
    >
      {children}
    </a>
  );
};

Link.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  openInNewTab: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

Link.defaultProps = {
  openInNewTab: false,
  to: '#',
  className: '',
  disabled: false,
};

export default Link;
