import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  community_preferences_container: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  community_preferences_form_container: {
    width: '100%',
  },
  form_section_header: {
    marginBottom: theme.spacing(1),
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gridGap: theme.spacing(1),
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  inputs_community_preferences: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  distance_input: {
    width: '300px',
    marginTop: '12px !important',
    marginBottom: theme.spacing(2),
  },
  checkbox_grid: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: theme.spacing(2),
  },
  checkbox_grid_select_all_toggle_container: {
    width: '100%',
  },
  checkbox_grid_entry: {
    width: '50%',
    minWidth: '250px',
    maxWidth: '325px',
  },
  room_preference_input: {
    flexGrow: 1,
    marginTop: `${theme.spacing(1)}px !important`,
    [theme.breakpoints.down('sm')]: {
      flexGrow: 0,
      marginRight: theme.spacing(1),
    },
  },
  dropdown_room_preferences: {
    zIndex: 333,
    '&.custom_select': {
      width: '250px',
    },
  },
  row_preference: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '5px',
    [theme.breakpoints.down('xl')]: {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  row_header_preference: {
    display: 'flex',
    flexFlow: 'row',
    marginBottom: '8px',
    width: '100%',
  },
  preferences_group_item: {
    display: 'flex',
  },
  preference_items: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingRight: '12px',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      '& > label': {
        width: '100%',
      },
    },
  },
  preference_items_label: {
    color: theme.palette.dark.eerieBlack1,
    [theme.breakpoints.down('xl')]: {
      width: '349px',
    },
    [theme.breakpoints.down('md')]: {
      width: '267px',
    },
  },

  button_cancel: {
    paddingLeft: '0',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  column_button: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    '& div:not(:first-child)': {
      paddingLeft: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
    marginBottom: theme.spacing(3),
  },
}));

export { useStyles };
