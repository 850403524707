import React from 'react';
import PropTypes from 'prop-types';

const Disaster = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9054 21.743C24.0311 21.526 24.0316 21.2583 23.9069 21.0407L12.61 1.35321C12.4846 1.13477 12.2519 1 12.0001 1C11.7481 1 11.5156 1.13477 11.3902 1.35321C11.0948 1.8681 0.127878 20.9799 0.093271 21.0407C-0.0316071 21.2583 -0.0310578 21.526 0.0947358 21.743C0.220529 21.9601 0.452341 22.0938 0.703196 22.0938H23.297C23.5478 22.0938 23.7796 21.9601 23.9054 21.743ZM20.4689 17.875H14.1095C13.0707 17.875 12.1623 17.3085 11.6753 16.4688H19.6621L20.4689 17.875ZM12.0001 3.11579L18.8552 15.0625H11.297C11.297 13.5116 12.5586 12.25 14.1095 12.25H14.8126C15.201 12.25 15.5157 11.9353 15.5157 11.5469C15.5157 9.60835 13.9386 8.03126 12.0001 8.03126C10.8625 8.03126 9.76858 8.27754 8.77907 8.72944L12.0001 3.11579ZM1.91737 20.6875C6.15902 13.3556 5.61061 14.251 6.86855 12.0589C8.04061 10.4323 9.93136 9.43751 12.0001 9.43751C12.9174 9.43751 13.6999 10.026 13.9895 10.8454C11.7185 10.9091 9.89071 12.7763 9.89071 15.0625C9.89071 17.3849 11.7794 19.2813 14.1095 19.2813H21.2759L22.0828 20.6875H1.91737Z"
        fill="#0D1321"
      />
    </svg>
  );
};

Disaster.propTypes = {
  className: PropTypes.string,
};

export default Disaster;
