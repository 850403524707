import { parsePhoneNumber } from 'libphonenumber-js';
import { format } from 'date-fns';
import labels from '../../config/labels.js';

const STRING = 'string';
const OPEN = 'open';
const ENUM = 'enum';

const getText = (value, defaultValue) => {
  if (!value) return defaultValue;
  return value;
};

const getPreferredLocationText = (inquiry, defaultValue) => {
  if (
    !inquiry.desiredCity &&
    !inquiry.inquiryFormData?.desiredCity &&
    !inquiry.desiredState &&
    !inquiry.inquiryFormData?.desiredState
  ) {
    return defaultValue;
  }
  const locationValues = [
    getText(inquiry.inquiryFormData?.desiredCity || inquiry.desiredCity, ''),
    getText(inquiry.inquiryFormData?.desiredState || inquiry.desiredState, ''),
  ];
  return `${locationValues.filter(Boolean).join(', ')}`;
};

const getPreferredLocationObject = (inquiry) => {
  const zip = getText(
    inquiry.inquiryFormData?.desiredPostalCode || inquiry.desiredPostalCode,
    '',
  );
  const city = getText(
    inquiry.inquiryFormData?.desiredCity || inquiry.desiredCity,
    '',
  );
  const state = getText(
    inquiry.inquiryFormData?.desiredState || inquiry.desiredState,
    '',
  );
  const country = getText(
    inquiry.inquiryFormData?.desiredCountry || inquiry.desiredCountryCode,
    '',
  );
  const distance = getText(
    inquiry.inquiryFormData?.desiredDistance || inquiry.locationRange,
    '15',
  ).toString();
  const cityState = [city, state].filter(Boolean).join(', ');
  const formatAddress =
    zip !== '' || city !== '' || state ? `${cityState} ${zip}` : '';
  const locationValues = {
    zip,
    city,
    state,
    country,
    address: formatAddress,
  };
  return { locationValues, distance };
};

const getElementFromCatalogByKey = (catalog, key) => {
  if (!catalog) return null;
  const keyInfo = catalog.filter((relation) => {
    return relation.enumValue === key;
  });
  return keyInfo[0];
};

const getLabelFromCatalog = (key, labels, catalog) => {
  if (!key || !catalog) {
    return labels.UNKNOWN;
  }
  return getElementFromCatalogByKey(catalog, key).enumLabel;
};

const getFullName = (firstName, lastName, defaultValue) => {
  if (!firstName && !lastName) {
    return defaultValue;
  }
  firstName = getText(firstName, '');
  lastName = getText(lastName, '');
  return `${firstName} ${lastName}`;
};

const getExpectedMoveIn = (moveDateValue, labels, catalog) => {
  if (!catalog) return null;
  const ZERO_DAYS = '0';
  const MORE_THAN_DAYS = 'MORE';
  if (!moveDateValue) {
    return labels.UNKNOWN;
  }
  const range = catalog.filter((move) => {
    return move.enumKey === moveDateValue;
  })[0];
  if (range.enumValue === ZERO_DAYS) {
    return labels.IMMEDIATELY;
  }

  const today = new Date(Date.now());
  today.setDate(today.getDate() + parseInt(range.enumValue));
  const month = labels.MONTHS[today.getMonth()];

  const isMoreThan = range.enumKey.includes(MORE_THAN_DAYS)
    ? labels.OR_LATER
    : '';
  return `${month} ${today.getFullYear()} ${isMoreThan}`.trim();
};

export const globalFields = {
  srcName: {
    description: labels.USER_NAME,
    type: STRING,
    defaultValue: (labels) => labels.UNKNOWN,
    value: function (currentUser, labels) {
      if (!currentUser) return this.defaultValue(labels);
      return getText(currentUser.firstName, this.defaultValue(labels));
    },
  },
  date: {
    description: labels.CURRENT_DATE,
    type: STRING,
    defaultValue: (labels) => labels.UNKNOWN,
    value: function () {
      return format(new Date(), labels.CURRENT_DATE_DEFAULT_FORMAT);
    },
  },
};

export const assignSlaFields = {
  existingSlaName: {
    description: labels.WINNER_SLA_NAME,
    type: STRING,
    defaultValue: (labels) => labels.UNKNOWN,
    value: function (warmTransfer) {
      if (warmTransfer?.previousSla) {
        return warmTransfer.previousSla.slaName;
      }
      return '';
    },
  },
  winnerSlaName: {
    description: labels.WINNER_SLA_NAME,
    type: STRING,
    defaultValue: (labels) => labels.UNKNOWN,
    value: function (warmTransfer) {
      if (warmTransfer?.winnerSlaAgent) {
        return warmTransfer.winnerSlaAgent.displayName;
      }
      return '';
    },
  },
  winnerSlaUsername: {
    description: labels.WINNER_SLA_USERNAME,
    type: STRING,
    defaultValue: (labels) => labels.UNKNOWN,
    value: function (warmTransfer) {
      if (warmTransfer?.winnerSlaAgent) {
        return warmTransfer.winnerSlaAgent.userName;
      }
      return '';
    },
  },
  winnerSlaPhone: {
    description: labels.WINNER_SLA_PHONE,
    type: STRING,
    defaultValue: (labels) => labels.UNKNOWN,
    value: function (warmTransfer) {
      if (warmTransfer?.winnerSlaAgent) {
        return warmTransfer.winnerSlaAgent.displayPhoneNumber;
      }
      return '';
    },
  },
  winnerSlaEmail: {
    description: labels.WINNER_SLA_EMAIL,
    type: STRING,
    defaultValue: (labels) => labels.UNKNOWN,
    value: function (warmTransfer) {
      if (warmTransfer?.winnerSlaAgent) {
        return warmTransfer.winnerSlaAgent.email;
      }
      return '';
    },
  },
  winnerSlaStatus: {
    description: labels.WINNER_SLA_STATUS,
    type: STRING,
    defaultValue: (labels) => labels.UNKNOWN,
    value: function (warmTransfer) {
      if (warmTransfer?.winnerSlaAgent) {
        return warmTransfer.winnerSlaAgent.statusForDisplay;
      }
      return '';
    },
  },
};

export const inquiryFields = {
  contactFullName: {
    description: labels.CONTACT_FULL_NAME,
    type: STRING,
    defaultValue: (labels) => labels.UNKNOWN,
    value: function (inquiry, labels) {
      if (!inquiry.inquiryContact) return this.defaultValue(labels);
      return getFullName(
        inquiry.inquiryContact.firstName,
        inquiry.inquiryContact.lastName,
        this.defaultValue(labels),
      );
    },
  },
  contactFirstName: {
    description: labels.CONTACT_FIRST_NAME,
    type: STRING,
    defaultValue: (labels) => labels.UNKNOWN,
    value: function (inquiry, labels, catalog, shouldEraseInfo) {
      if (shouldEraseInfo) return '';
      if (!inquiry.inquiryContact) return this.defaultValue(labels);
      return getText(
        inquiry.inquiryContact.firstName,
        this.defaultValue(labels),
      );
    },
  },
  relation: {
    description: labels.RELATION,
    type: STRING,
    defaultValue: (labels) => labels.UNKNOWN,
    value: function (inquiry, labels, catalog) {
      if (catalog) {
        return getLabelFromCatalog(
          inquiry.inquiryFormData?.relationToResident ||
            inquiry.relationToResident,
          labels,
          catalog,
        );
      }
      return getText(
        inquiry.inquiryFormData?.relationToResident ||
          inquiry.relationToResident ||
          this.defaultValue(labels),
      );
    },
  },
  relationText: {
    description: labels.RELATION_TEXT,
    type: STRING,
    defaultValue: () => labels.IS_YOUR_LOVED_ONE,
    value: function (inquiry) {
      return inquiry.relationToResident === 'SELF'
        ? labels.ARE_YOU
        : labels.IS_YOUR_LOVED_ONE;
    },
  },
  source: {
    description: labels.SOURCE_NAME,
    type: STRING,
    defaultValue: () => labels.APFM,
    value: function (inquiry) {
      const inquiryMethodMapping = {
        WEB: labels.WEB,
        INBOUND_CALL: labels.INBOUND_CALL,
        MANUAL: labels.MANUAL,
      };
      const source =
        (inquiry.inquiryMethod &&
          inquiryMethodMapping[inquiry.inquiryMethod]) ||
        this.defaultValue();
      return source;
    },
  },
  subSourceId: {
    description: labels.SUBSOURCE_NAME,
    type: STRING,
    defaultValue: () => '',
    value: function (inquiry) {
      const subSourceId =
        (inquiry.subSourceId &&
          labels.SUBSOURCE_ID.replace('{SUBSOURCE_ID}', inquiry.subSourceId)) ||
        this.defaultValue();
      return subSourceId;
    },
  },
  submissionUrl: {
    description: labels.SUBMISSION_URL,
    type: STRING,
    defaultValue: () => '',
    value: function (inquiry) {
      const url = inquiry?.submissionUrl;
      const regexExpression =
        '^(?:https?://)?(?:[^@/\n]+@)?(?:www.)?([^:/?\n]+)?(?:/)?([^:/?\n]+)?(?:/)';
      const result = new RegExp(regexExpression, 'g');
      const splittedUrl = url?.match(result);
      return splittedUrl ? `(${splittedUrl})` : this.defaultValue();
    },
  },
  sourcePhone: {
    description: labels.SOURCE_PHONE,
    type: STRING,
    defaultValue: () => labels.DEFAULT_SOURCE_PHONE,
    value: function (inquiry, labels) {
      return this.defaultValue(labels);
    },
  },
  referralSource: {
    description: labels.REFERRAL_SOURCE,
    type: STRING,
    defaultValue: () => '',
    value: function (inquiry, labels) {
      return this.defaultValue(labels);
    },
  },
  referralSubSource: {
    description: labels.REFERRAL_SUBSOURCE,
    type: STRING,
    defaultValue: () => '',
    value: function (inquiry, labels) {
      return this.defaultValue(labels);
    },
  },
  phone: {
    description: labels.PHONE_NUMBER,
    type: 'phone',
    defaultValue: (labels) => labels.UNKNOWN,
    value: function (inquiry, labels) {
      if (!inquiry.inquiryContact || !inquiry.inquiryContact.phone) {
        return this.defaultValue(labels);
      }
      return parsePhoneNumber(
        inquiry.inquiryContact.phone,
        'US',
      ).formatNational();
    },
  },
  email: {
    description: labels.EMAIL,
    type: 'email',
    defaultValue: (labels) => labels.UNKNOWN,
    value: function (inquiry, labels) {
      if (!inquiry.inquiryContact) return this.defaultValue(labels);
      return getText(inquiry.inquiryContact.email, this.defaultValue(labels));
    },
  },
  oneId: {
    description: labels.ONE_ID,
    type: STRING,
    defaultValue: (labels) => labels.UNKNOWN,
    value: function (inquiry, labels) {
      if (!inquiry.inquiryContact) return this.defaultValue(labels);
      return getText(inquiry.inquiryContact.oneId, this.defaultValue(labels));
    },
  },
  residentName: {
    description: labels.RESIDENT_NAME,
    type: STRING,
    defaultValue: (labels) => labels.UNKNOWN,
    value: function (inquiry, labels) {
      return this.defaultValue(labels);
    },
  },
  familyFileId: {
    description: labels.FAMILY_FILE_ID,
    type: STRING,
    defaultValue: (labels) => labels.UNKNOWN,
    value: function (inquiry, labels) {
      return getText(inquiry.familyFileId, this.defaultValue(labels));
    },
  },
  inquiryId: {
    description: labels.INQUIRY_ID,
    type: STRING,
    defaultValue: (labels) => labels.UNKNOWN,
    value: function (inquiry, labels) {
      return getText(inquiry.inquiryId, this.defaultValue(labels));
    },
  },
  preferredLocation: {
    description: labels.PREFERRED_LOCATION,
    type: STRING,
    defaultValue: (labels) => labels.UNKNOWN,
    value: function (inquiry, labels) {
      return getPreferredLocationText(inquiry, this.defaultValue(labels));
    },
  },
  preferredLocationObj: {
    description: labels.PREFERRED_LOCATION_OBJECT,
    type: 'object',
    value: function (inquiry) {
      return getPreferredLocationObject(inquiry);
    },
  },
  expectedMoveIn: {
    description: labels.EXPECTED_MOVE_IN,
    type: STRING,
    defaultValue: (labels) => labels.UNKNOWN,
    value: function (inquiry, labels, catalog) {
      if (!catalog) return this.defaultValue(labels);
      let hasFormData =
        inquiry.inquiryFormData &&
        inquiry.inquiryFormData.moveDateRange !== null &&
        inquiry.inquiryFormData.moveDateRange !== undefined;

      let moveDateRange = hasFormData
        ? inquiry.inquiryFormData.moveDateRange
        : inquiry.moveDateRange;

      return getExpectedMoveIn(moveDateRange, labels, catalog);
    },
  },
  likelihood: {
    description: labels.LIKELIHOOD,
    type: STRING,
    defaultValue: (labels) => labels.UNKNOWN,
    value: function (inquiry, labels) {
      return this.defaultValue(labels);
    },
  },
  resident1FirstName: {
    description: labels.RESIDENT_FIRST_NAME,
    type: STRING,
    defaultValue: () => '',
    value: function (inquiry, labels) {
      return getText(
        inquiry.inquiryFormData?.resident1FirstName ||
          inquiry.resident1FirstName,
        this.defaultValue(labels),
      );
    },
  },
  resident1LastName: {
    description: labels.RESIDENT_LAST_NAME,
    type: STRING,
    defaultValue: () => '',
    value: function (inquiry, labels) {
      return getText(
        inquiry.inquiryFormData?.resident1LastName || inquiry.resident1LastName,
        this.defaultValue(labels),
      );
    },
  },
  resident1FullName: {
    description: labels.RESIDENT_FULL_NAME,
    type: STRING,
    defaultValue: (labels) => labels.UNKNOWN,
    value: function (inquiry, labels) {
      return getFullName(
        inquiry.inquiryFormData?.resident1FirstName ||
          inquiry.resident1FirstName,
        inquiry.inquiryFormData?.resident1LastName || inquiry.resident1LastName,
        this.defaultValue(labels),
      );
    },
  },
  budgetMax: {
    description: labels.BUDGET_MAX,
    type: STRING,
    defaultValue: () => -1,
    value: (inquiry) => getText(inquiry.budgetMax, -1),
  },
};

export const stepTypes = {
  multipleChoice: {
    key: 'multipleChoice',
    label: labels.MULTIPLE_CHOICE,
    description: labels.MULTIPLE_CHOICE_DESCRIPTION,
    hidden: false,
    allowAfterFamilyFileCreated: false,
  },
  manyChoice: {
    key: 'manyChoice',
    label: labels.MANY_CHOICES,
    description: labels.MANY_CHOICES_DESCRIPTION,
    hidden: false,
    allowAfterFamilyFileCreated: false,
  },
  textBoxGroup: {
    key: 'textBoxGroup',
    label: labels.TEXT_BOX_GROUP,
    description: labels.TEXT_BOX_GROUP_DESCRIPTION,
    hidden: false,
    allowAfterFamilyFileCreated: false,
  },
  endCall: {
    key: 'endCall',
    label: labels.END_CALL,
    description: labels.END_CALL_DESCRIPTION,
    hidden: false,
    allowAfterFamilyFileCreated: true,
  },
  contactInfo: {
    key: 'contactInfo',
    label: labels.CONTACT_INFO,
    description: labels.CONTACT_INFO_DESCRIPTION,
    hidden: false,
    allowAfterFamilyFileCreated: false,
  },
  familyInfo: {
    key: 'familyInfo',
    label: labels.FAMILY_INFO,
    description: labels.FAMILY_INFO_DESCRIPTION,
    hidden: false,
    allowAfterFamilyFileCreated: false,
  },
  locationInfo: {
    key: 'locationInfo',
    label: labels.LOCATION,
    description: labels.LOCATION_DESCRIPTION,
    hidden: false,
    allowAfterFamilyFileCreated: false,
  },
  //warm transfer
  transferSla: {
    key: 'transferSla',
    label: labels.TRANSFER_TO_SLA,
    description: labels.TRANSFER_TO_SLA_DESCRIPTION,
    hidden: false,
    allowAfterFamilyFileCreated: true,
  },
  slaMatch: {
    key: 'slaMatch',
    label: labels.SLA_MATCH,
    description: labels.SLA_MATCH_DESCRIPTION,
    hidden: true,
    allowAfterFamilyFileCreated: true,
  },
  slaNotFound: {
    key: 'slaNotFound',
    label: labels.SLA_IS_NOT_FOUND,
    description: labels.SLA_IS_NOT_FOUND_DESCRIPTION,
    hidden: true,
    allowAfterFamilyFileCreated: true,
  },
  completeWarmTransfer: {
    key: 'completeWarmTransfer',
    label: labels.IS_COMPLETE_WARM_TRANSFER,
    description: labels.IS_COMPLETE_WARM_TRANSFER_DESCRIPTION,
    hidden: true,
    allowAfterFamilyFileCreated: true,
  },
  completeWarmTransferFamilyDisconnected: {
    key: 'completeWarmTransferFamilyDisconnected',
    label: labels.COMPLETE_WT_FAMILY_DISCONNECTED,
    description: labels.COMPLETE_WT_FAMILY_DISCONNECTED_DESCRIPTION,
    hidden: true,
    allowAfterFamilyFileCreated: true,
  },
  tryPreviousSla: {
    key: 'tryPreviousSla',
    label: labels.TRY_PREVIOUS_SLA,
    description: labels.TRY_PREVIOUS_SLA,
    hidden: true,
    allowAfterFamilyFileCreated: true,
  },
  previousSlaAccepted: {
    key: 'previousSlaAccepted',
    label: labels.PREVIOUS_SLA_ACCEPTED_TITLE,
    description: labels.PREVIOUS_SLA_ACCEPTED_TITLE,
    hidden: true,
    allowAfterFamilyFileCreated: true,
  },
  previousSlaNotAccepted: {
    key: 'previousSlaNotAccepted',
    label: labels.PREVIOUS_SLA_NOT_ACCEPTED,
    description: labels.PREVIOUS_SLA_NOT_ACCEPTED,
    hidden: true,
    allowAfterFamilyFileCreated: true,
  },
  //end warm transfer
  referralSource: {
    key: 'referralSource',
    label: labels.REFERRAL_SOURCE,
    description: labels.REFERRAL_SOURCE,
    hidden: false,
    allowAfterFamilyFileCreated: false,
  },
  completeReschedule: {
    key: 'completeReschedule',
    label: labels.COMPLETE_RESCHEDULE,
    description: labels.COMPLETE_RESCHEDULE,
    hidden: false,
    allowAfterFamilyFileCreated: false,
  },
  recordingDisclaimer: {
    key: 'recordingDisclaimer',
    label: labels.RECORDING_DISCLAIMER,
    description: labels.RECORDING_DISCLAIMER,
    hidden: false,
    allowAfterFamilyFileCreated: false,
  },
};

export const operations = {
  equals: {
    label: labels.EQUALS,
    operation: (inquiryValue, val) => {
      return inquiryValue === val;
    },
  },
  different: {
    label: labels.NOT_EQUAL,
    operation: (inquiryValue, val) => {
      return inquiryValue !== val;
    },
  },
  less: {
    label: labels.LESS,
    operation: (inquiryValue, val) => {
      return inquiryValue < val;
    },
  },
  lessEqual: {
    label: labels.LESS_THAN,
    operation: (inquiryValue, val) => {
      return inquiryValue <= val;
    },
  },
  greater: {
    label: labels.GREATER,
    operation: (inquiryValue, val) => {
      return inquiryValue > val;
    },
  },
  greaterEqual: {
    label: labels.GREATER_THAN,
    operation: (inquiryValue, val) => {
      return inquiryValue >= val;
    },
  },
  between: {
    label: labels.BETWEEN,
    operation: (inquiryValue, min, max) => {
      if (!inquiryValue) return false;
      return (
        parseFloat(inquiryValue) >= parseFloat(min) &&
        parseFloat(inquiryValue) <= parseFloat(max)
      );
    },
  },
};

export const actions = {
  eraseContactInfoFields: {
    label: labels.ERASE_CONTACT_INFO_FIELDS_ACTION,
  },
  keepContactInfoFields: {
    label: labels.KEEP_CONTACT_INFO_FIELDS_ACTION,
  },
  updatePreferredLocation: {
    label: labels.UPDATE_PREFERRED_LOCATION_ACTION,
  },
  readPipeda: {
    label: labels.READ_PIPEDA_ACTION,
  },
  sendPipedaEmail: {
    label: labels.SEND_PIPEDA_EMAIL_ACTION,
  },
  completeCallAction: {
    label: labels.COMPLETE_CALL_ACTION,
  },
  findBestSLAAvailable: {
    label: labels.FIND_BEST_SLA_ACTION,
  },
  transferToSLA: {
    label: labels.TRANSFER_TO_SLA_ACTION,
  },
  referralResource: {
    label: labels.KEEP_REFERRAL_SOURCE_FIELDS_ACTION,
  },
  addToNotes: {
    label: labels.INFO_ADDED_TO_NOTES_ACTION,
  },
};

export const inquiryProperties = {
  contactConfirmation: {
    label: labels.CONTACT_CONFIRMATION_PROP,
    type: OPEN,
  },
  disclaimer: {
    label: labels.DISCLAIMER_PROP,
    type: OPEN,
  },
  contactInfo: {
    label: labels.CONTACT_INFO_PROP,
    type: OPEN,
  },
  noAnswerCall: {
    label: labels.NOT_ANSWER_CALL_PROP,
    type: OPEN,
  },
  confirmInterest: {
    label: labels.CONFIRM_INTEREST_PROP,
    type: OPEN,
  },
  familyInfo: {
    label: labels.FAMILY_INFO_PROP,
    type: OPEN,
  },
  budget: {
    label: labels.BUDGET_PROP,
    type: ENUM,
    values: [
      { label: labels.TWO_THOUSAND_OR_GREATER, value: '2000OrGreater' },
      { label: labels.ONE_THOUSAND_AND_LESS_TWO_THOUSAND, value: '1000-1999' },
      { label: labels.LESS_ONE_THOUSAND, value: 'unqualified' },
    ],
  },
  medicalInfo: {
    label: labels.MEDICAL_INFO_PROP,
    type: ENUM,
    values: [
      {
        label: labels.DISCHARGE_HOSPITAL_REHAB,
        value: 'DISCHARGE_HOSPITAL_REHAB',
      },
      {
        label: labels.ALZHEIMER_DEMENTIA_DIAGNOSIS,
        value: 'ALZHEIMER_DEMENTIA_DIAGNOSIS',
      },
      {
        label: labels.AROUND_CLOCK_MEDICAL_CARE_NEEDED,
        value: 'CLOCK_MEDICAL_CARE',
      },
      {
        label: labels.CAREGIVER_NO_LONGER_PROVIDING_SUPPORT,
        value: 'CAREGIVER_NO_SUPPORT',
      },
      { label: labels.CHANGE_IN_LIFESTYLE, value: 'CHANGE_IN_LIFESTYLE' },
      { label: labels.CURRENTLY_NURSING_HOME, value: 'NURSING_HOME' },
      { label: labels.OTHER_PROP, value: 'OTHER' },
    ],
  },
  locationInfo: {
    label: labels.LOCATION_INFO_PROP,
    type: OPEN,
  },
  pipedaDisclosure: {
    label: labels.PIPEDA_DISCLOSURE_PROP,
    type: OPEN,
    key: 'pipedaDisclosure',
  },
  notInterestedReason: {
    label: labels.NOT_INTERESTED_REASON_PROP,
    type: OPEN,
  },
  transferSla: {
    label: labels.TRANSFER_SLA_PROP,
    type: OPEN,
  },
  unqualified: {
    label: labels.UNQUALIFIED_PROP,
    type: OPEN,
  },
  moveDateRange: {
    label: labels.URGENCY_PROP,
    type: ENUM,
    values: [
      { label: labels.URGENCY_IMMEDIATELY, value: '_0_DAYS' },
      { label: labels.URGENCY_LESS_30_DAYS, value: '_30_DAYS' },
      { label: labels.URGENCY_LESS_60_DAYS, value: '_60_DAYS' },
      { label: labels.URGENCY_LESS_90_DAYS, value: '_90_DAYS' },
      { label: labels.URGENCY_MORE_90_DAYS, value: 'MORE_THAN_90' },
    ],
  },
  careTypeCode: {
    label: labels.CARE_TYPE_CODE_PROP,
    type: ENUM,
    values: [
      { label: labels.CARE_TYPE_RETIREMENT_COMUNITY, value: 'R' },
      { label: labels.CARE_TYPE_HOME, value: 'H' },
      { label: labels.CARE_TYPE_RESIDENTIAL, value: 'G' },
      { label: labels.CARE_TYPE_SENIOR_APARTMENTS, value: 'P' },
      { label: labels.CARE_TYPE_NURSING_HOME, value: 'N' },
      { label: labels.CARE_TYPE_ADULT_DAY_SERVICES, value: 'D' },
      { label: labels.CARE_TYPE_MEMORY, value: 'Z' },
      { label: labels.CARE_TYPE_ASSISTED_LIVING, value: 'A' },
    ],
  },
  veteran: {
    label: labels.VETERAN_PROP,
    type: ENUM,
    values: [
      { label: labels.VETERAN_YES, value: 'true' },
      { label: labels.VETERAN_NO, value: 'false' },
    ],
  },
  financialResources: {
    label: labels.FINANCIAL_RESOURCES_PROP,
    type: ENUM,
    values: [
      {
        label: labels.FINANCIAL_MONTHLY_ADDITIONAL_INCOME,
        value: 'MONTHLY_INCOME_ADDITIONAL',
      },
      { label: labels.FINANCIAL_HOME_TO_SELL, value: 'HOME_TO_SELL' },
      { label: labels.FINANCIAL_SAVINGS, value: 'SAVINGS' },
      {
        label: labels.FINANCIAL_STOCKS,
        value: 'STOCKS_BONDS_401KS_CDS_IRAS',
      },
      {
        label: labels.FINANCIAL_FAMILY_SUPPORT,
        value: 'FAMILY_FINANCIAL_SUPPORT',
      },
      { label: labels.FINANCIAL_LONG_TERM, value: 'LONG_TERM_CARE_INSURANCE' },
      { label: labels.FINANCIAL_VA_BENEFITS, value: 'VA_BENEFITS' },
      { label: labels.FINANCIAL_NONE, value: 'NONE' },
    ],
  },
  medicaid: {
    label: labels.MEDICAID_PROP,
    type: ENUM,
    values: [
      { label: labels.MEDICAID_YES, value: 'YES' },
      { label: labels.MEDICAID_NO, value: 'NO' },
      {
        label: labels.MEDICAID_CURRENTLY_APPLYING,
        value: 'CURRENTLY_APPLYING',
      },
    ],
  },
  roomPreference: {
    label: labels.ROOM_PREFERENCE_PROP,
    type: ENUM,
    values: [
      { label: labels.ROOM_PREFERENCE_SHARED, value: 'SHARED' },
      {
        label: labels.ROOM_PREFERENCE_PRIVATE_1_BEDROOM,
        value: 'PRIVATE_ONE_BEDROOM',
      },
      {
        label: labels.ROOM_PREFERENCE_PRIVATE_2_BEDROOM,
        value: 'PRIVATE_TWO_BEDROOM',
      },
      { label: labels.ROOM_PREFERENCE_STUDIO, value: 'STUDIO' },
      { label: labels.ROOM_PREFERENCE_PRIVATE, value: 'PRIVATE' },
    ],
  },
  getAround: {
    label: labels.GET_AROUND_PROP,
    type: ENUM,
    values: [
      { label: labels.GET_AROUND_INDEPENDENT, value: 'INDEPENDENT' },
      { label: labels.GET_AROUND_WALKER, value: 'WALKER' },
      { label: labels.GET_AROUND_CANE, value: 'CANE' },
      { label: labels.GET_AROUND_WHEELCHAIR, value: 'WHEELCHAIR' },
      {
        label: labels.GET_AROUND_TEMPORARILY,
        value: 'TEMPORARILY_IMMOBILE_INJURY',
      },
      {
        label: labels.GET_AROUND_BEDRIDDEN,
        value: 'BEDRIDDEN_UNABLE_WEIGHT',
      },
    ],
  },
  diabetic: {
    label: labels.DIABETIC_PROP,
    type: ENUM,
    values: [
      { label: labels.DIABETIC_NOT, value: 'NOT_DIABETIC' },
      { label: labels.DIABETIC_SELF, value: 'SELF_MANAGEMENT' },
      {
        label: labels.DIABETIC_BLOOD_SUGAR_MONITORED,
        value: 'BLOOD_SUGAR_MONITORED',
      },
      {
        label: labels.DIABETIC_INSULIN_INJECTIONS,
        value: 'INSULIN_INJECTIONS',
      },
      { label: labels.DIABETIC_SLIDING_SCALE, value: 'SLIDING_SCALE' },
    ],
  },
  memoryLoss: {
    label: labels.MEMORY_LOSS_PROP,
    type: ENUM,
    values: [
      { label: labels.MEMORY_LOSS_NO, value: 'NO' },
      { label: labels.MEMORY_LOSS_DEMENTIA, value: 'DEMENTIA' },
      { label: labels.MEMORY_LOSS_ALZHEIMER, value: 'ALZHEIMER' },
      { label: labels.MEMORY_LOSS_OTHER, value: 'OTHER' },
      {
        label: labels.MEMORY_LOSS_SPECIAL_MEMORY,
        value: 'SPECIAL_MEMORY_CARE',
      },
    ],
  },
  behavioral: {
    label: labels.BEHAVIORAL_PROP,
    type: ENUM,
    values: [
      { label: labels.BEHAVIORAL_COMBATIVE, value: 'COMBATIVE_ISSUES' },
      { label: labels.BEHAVIORAL_WANDERING, value: 'WANDERING_ISSUES' },
      { label: labels.BEHAVIORAL_NO, value: 'NO' },
    ],
  },
  bathing: {
    label: labels.BATHING_PROP,
    type: ENUM,
    values: [
      { label: labels.BATHING_YES, value: 'true' },
      { label: labels.BATHING_NO, value: 'false' },
    ],
  },
  toileting: {
    label: labels.TOILETING_PROP,
    type: ENUM,
    values: [
      { label: labels.TOILETING_YES, value: 'true' },
      { label: labels.TOILETING_NO, value: 'false' },
    ],
  },
  budgetRange: {
    label: labels.BUDGET_RANGE_PROP,
    type: ENUM,
    values: [
      { label: labels.BUDGET_UNDER_500, value: '9' },
      { label: labels.BUDGET_BETWEEN_500_999, value: '10' },
      { label: labels.BUDGET_BETWEEN_1000_1499, value: '3' },
      { label: labels.BUDGET_BETWEEN_1500_1999, value: '4' },
      { label: labels.BUDGET_BETWEEN_2000_2499, value: '5' },
      { label: labels.BUDGET_BETWEEN_2500_2999, value: '6' },
      { label: labels.BUDGET_BETWEEN_3000_3999, value: '7' },
      { label: labels.BUDGET_BETWEEN_4000_4999, value: '11' },
      { label: labels.BUDGET_BETWEEN_5000_5999, value: '12' },
      { label: labels.BUDGET_BETWEEN_6000_6999, value: '14' },
      { label: labels.BUDGET_BETWEEN_7000_7999, value: '15' },
      { label: labels.BUDGET_BETWEEN_8000_8999, value: '16' },
      { label: labels.BUDGET_BETWEEN_9000_9999, value: '17' },
      { label: labels.BUDGET_OVER_10000, value: '18' },
    ],
  },
  transfer: {
    label: labels.TRANSFER,
    type: OPEN,
    values: [
      { label: labels.RESOURCE_LINE, value: 'resourceLine' },
      { label: labels.CORPORATE, value: 'corporate' },
      { label: labels.CUSTOMER_EXPERIENCE, value: 'customerExperience' },
      { label: labels.SENIOR_ADVISOR_REVIEW, value: 'seniorAdvisorReview' },
      { label: labels.SPANISH_LINE, value: 'spanishLine' },
    ],
  },
  transferSuccessful: {
    label: labels.TRANSFER_SUCCESSFUL_PROP,
    type: OPEN,
  },
  referralSource: {
    label: labels.REFERRAL_SOURCE_PROP,
    type: OPEN,
  },
  professionalReferral: {
    label: labels.PROFESSIONAL_REFERRAL_PROP,
    type: OPEN,
  },
  notes: {
    label: labels.NOTES_PROP,
    type: OPEN,
  },
  familyDisconnected: {
    label: labels.FAMILY_DISCONNECTED_PROP,
    type: OPEN,
  },
};

export const budgetMinMaxRanges = {
  9: { budgetMin: 0, budgetMax: 500 },
  10: { budgetMin: 500, budgetMax: 999 },
  3: { budgetMin: 1000, budgetMax: 1499 },
  4: { budgetMin: 1500, budgetMax: 1999 },
  5: { budgetMin: 2000, budgetMax: 2499 },
  6: { budgetMin: 2500, budgetMax: 2999 },
  7: { budgetMin: 3000, budgetMax: 3999 },
  11: { budgetMin: 4000, budgetMax: 4999 },
  12: { budgetMin: 5000, budgetMax: 5999 },
  14: { budgetMin: 6000, budgetMax: 6999 },
  15: { budgetMin: 7000, budgetMax: 7999 },
  16: { budgetMin: 8000, budgetMax: 8999 },
  17: { budgetMin: 9000, budgetMax: 9999 },
  18: { budgetMin: 10000, budgetMax: 1000000 },
};

export const poolTypes = [
  {
    label: labels.DEFAULT_POOL_PROP,
    value: '1',
  },
  {
    label: labels.HEALTH_CARE_PROP,
    value: '2',
  },
];
