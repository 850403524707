export default {
  BUDGET_MAX: 'Budget Max',
  CONTACT_FULL_NAME: 'Contact full name',
  CURRENT_DATE: 'Current date',
  DEFAULT_SOURCE_PHONE: '(888) 857-0796',
  PREFERRED_LOCATION_OBJECT: 'Preferred Location Object',
  REFERRAL_SOURCE: 'Referral source',
  REFERRAL_SUBSOURCE: 'Referral subSource',
  RELATION: 'Relation',
  RELATION_TEXT: "Relation Text: 'Are you' or 'Is your loved one'",
  RESIDENT_FULL_NAME: 'Resident Full Name',
  SOURCE_NAME: 'Source name',
  SOURCE_PHONE: 'Source phone',
  SUBMISSION_URL: 'Submission url',
  SUBSOURCE_ID: 'SubsourceId: {SUBSOURCE_ID}',
  SUBSOURCE_NAME: 'SubSource name',
  USER_NAME: 'User name',
};
