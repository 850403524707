import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '../../common/Typography/Typography';
import Container from '../../common/Container/Container';
import ContainerItem from '../../common/ContainerItem/ContainerItem';
import Flag from '../../common/Icons/basic/Flag';
import QuotaFooter from './QuotaFooter';

import { useStyles } from './Quota.style';

const PointsInProgressiveBar = ({ className, points, quota }) => {
  if (quota - points >= 5 && points !== 0) {
    return (
      <div className={className}>
        <Typography level="small" color="eerieBlack1">
          {points}
        </Typography>
      </div>
    );
  }
  return '';
};

const PrintMTDLabel = ({ className, points, labels }) => {
  if (points === 0) {
    return '';
  }
  return (
    <div className={className}>
      <Typography color="platinum5">{labels.MTD}</Typography>
    </div>
  );
};

const Quota = ({ quota, points, onClick, labels }) => {
  let displayPointsInBar;

  if (points <= 9 && points > 0) {
    displayPointsInBar = 10;
  } else {
    displayPointsInBar = points;
  }

  const progress = Math.min((displayPointsInBar / quota) * 100.0, 100.0) || 0;
  const classes = useStyles(progress, points);

  const mtdLabelClasses = clsx([
    classes.mtd_label,
    classes.mtd_almost_at_limit_label,
  ]);

  const clickableClass = clsx([
    onClick !== undefined ? classes.clickable_container : '',
  ]);

  return (
    <div className={classes.quota_container}>
      <Container className={classes.metrics_header_container}>
        <ContainerItem className={classes.month_to_date} xs={4}>
          <Typography level="small">
            {`${labels.MONTHS[new Date(Date.now()).getMonth()]} ${new Date(
              Date.now(),
            ).getFullYear()}`}
          </Typography>
        </ContainerItem>
        <ContainerItem className={classes.metric_header_title} xs={8}>
          <Typography>
            <b>{labels.POINTS_THIS_MONTH}</b>
          </Typography>
        </ContainerItem>
      </Container>
      <div className={clickableClass} onClick={onClick}>
        <div className={classes.rate}>
          <Typography level="h2" color="cosmicCobalt1">
            {points}
          </Typography>
        </div>
        <div className={classes.bar_container}>
          <div className={classes.bar_empty} />
          <div className={classes.bar_filled} />
          <div className={classes.line} />
          <PrintMTDLabel
            className={mtdLabelClasses}
            points={points}
            labels={labels}
          />
        </div>
        <div className={classes.label_container}>
          <div className={classes.start_label}>
            <Typography level="small" color="eerieBlack1">
              0
            </Typography>
          </div>
          <div className={classes.quota_flag_container}>
            <Typography>
              <Flag />
            </Typography>
          </div>
          <PointsInProgressiveBar
            className={classes.points_in_progressive_bar}
            points={points}
            quota={quota}
          />
          <div className={classes.end_label}>
            <Typography level="small" color="eerieBlack1">
              {quota}
            </Typography>
          </div>
        </div>
        <Container container className={classes.quotas_footer_container}>
          <ContainerItem className={classes.quotas_footer}>
            <QuotaFooter points={points} quota={quota} labels={labels} />
          </ContainerItem>
        </Container>
      </div>
    </div>
  );
};

PointsInProgressiveBar.propTypes = {
  className: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  quota: PropTypes.number.isRequired,
};

PrintMTDLabel.propTypes = {
  className: PropTypes.string,
  points: PropTypes.number.isRequired,
  labels: PropTypes.shape({
    MTD: PropTypes.string.isRequired,
  }).isRequired,
};

Quota.propTypes = {
  quota: PropTypes.number.isRequired,
  points: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  labels: PropTypes.object.isRequired,
};

export default Quota;

export { PointsInProgressiveBar, PrintMTDLabel, QuotaFooter };
