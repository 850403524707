import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Button from 'common/Button/Button';
import ChevronBottom from 'common/Icons/arrow/ChevronBottom';
import ChevronTop from 'common/Icons/arrow/ChevronTop';
import Typography from 'common/Typography/Typography';
import { withLabels } from 'context/LabelContext';
import { getItems, getLogoutOption } from './items';
import { useStyles } from './StatusDropDown.style';
import Radio from 'common/Radio/Radio';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import allCallStates from 'config/callStates.config';

const TALKING = allCallStates.TALKING.key;
const WRAP_UP = allCallStates.WRAP_UP.key;
const RINGING_ON_OTHER_SIDE = allCallStates.RINGING_ON_OTHER_SIDE.key;

const PENDING_STATE = {
  name: 'Pending Status',
  color: 'hotCinnamon3',
};

const getButtonType = (selected) => {
  switch (selected.color) {
    case 'wintergreenDream2':
      return 'secondary';
    case 'smokyTopaz2':
      return 'warning';
    case 'hotCinnamon3':
      return 'misc';
    default:
      return 'primary';
  }
};

const StatusDropDown = ({
  className,
  onChange,
  labels,
  agentState,
  agentStates,
  isLoading,
  logoutReasons,
  onClickLogout,
  callState,
}) => {
  const classes = useStyles();
  const elements = getItems(agentStates);
  const wrapperRef = useRef(null);
  const [pendingStatusChange, setPendingStatusChange] = useState(null);
  const [showStatesDropDown, setShowStatesDropDown] = useState(false);
  const [showLogoutDropDown, setShowLogoutDropDown] = useState(false);
  const [logoutReasonSelected, setLogoutReasonSelected] = useState({
    value: '',
  });
  const [logoutButtonType, setLogoutButtonType] = useState('disabled');

  const logoutState = getLogoutOption();

  useEffect(() => {
    if (callState !== TALKING && pendingStatusChange) {
      onChange(pendingStatusChange);
      setPendingStatusChange(null);
    }
  }, [callState, onChange, pendingStatusChange]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowStatesDropDown(false);
        setShowLogoutDropDown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const isDisabledLogout = (e) => {
    return (
      (callState === TALKING ||
        callState === WRAP_UP ||
        callState === RINGING_ON_OTHER_SIDE) &&
      e.id === logoutState.id
    );
  };

  const getIcon = (element, state = '') => {
    const color =
      state === 'disabled' ? `${element.color}_disabled` : element.color;
    return (
      <span className={clsx(classes[color], classes.icon)}>{element.icon}</span>
    );
  };

  const handleMenuItemClick = (element) => {
    if (element.isSelectable && element.id === logoutState.id) {
      setShowStatesDropDown(false);
      setShowLogoutDropDown(true);
    } else if (element.isSelectable && element.id !== logoutState.id) {
      if (callState === TALKING) {
        if (element.id === selected.id) {
          setPendingStatusChange(null);
        } else {
          setPendingStatusChange(element);
        }
      } else {
        onChange(element);
      }
      setShowStatesDropDown(false);
      setShowLogoutDropDown(false);
    }
  };

  const selected = elements.find((item) => item.id === agentState.id) || {};

  const getEndIcon = () => {
    if (isLoading) return <CircularProgress size={20} />;
    if (showStatesDropDown) return <ChevronTop />;
    return <ChevronBottom />;
  };

  const handleLogoutReasonChange = (value) => {
    if (value) {
      setLogoutReasonSelected(value);
      setLogoutButtonType('primary');
    }
  };

  const handleConfirmLogout = async () => {
    await onClickLogout(logoutReasonSelected);
  };

  const handleCancelLogout = () => {
    setLogoutReasonSelected({ value: '' });
    setLogoutButtonType('disabled');
    setShowLogoutDropDown(false);
  };

  const getDropDownOption = (e, k) => {
    if (isDisabledLogout(e)) {
      return (
        <div
          key={k}
          data-testid={`status-drop-${k}`}
          className={clsx(classes.menu_item, classes.selectable)}
        >
          {getIcon(e, 'disabled')}
          <SmartTooltip
            title={labels.DISABLED_BY_CALL_ACTIVE}
            placement="bottom"
          >
            <Typography color="disabled">{e.name}</Typography>
          </SmartTooltip>
        </div>
      );
    } else {
      return (
        <div
          onClick={() => handleMenuItemClick(e)}
          key={k}
          data-testid={`status-drop-${k}`}
          className={clsx(
            classes.menu_item,
            e.isSelectable && classes.selectable,
          )}
        >
          {getIcon(e)}
          <Typography color={e.isSelectable ? 'eerieBlack1' : 'disabled'}>
            {e.name}
          </Typography>
        </div>
      );
    }
  };

  return (
    <div
      ref={wrapperRef}
      className={clsx(className, classes.status_dropdown_container)}
    >
      {pendingStatusChange && (
        <SmartTooltip
          title={labels.STATUS_WILL_UPDATE_AFTER_CALL_ENDS}
          placement="bottom"
        >
          <Button
            size="medium"
            type={getButtonType(PENDING_STATE)}
            onClick={() => setShowStatesDropDown(!showStatesDropDown)}
            endIcon={getEndIcon()}
          >
            {`${PENDING_STATE.name}: ${pendingStatusChange.name}`}
          </Button>
        </SmartTooltip>
      )}
      {!pendingStatusChange && (
        <Button
          size="medium"
          type={getButtonType(selected)}
          onClick={() => {
            setShowStatesDropDown(!showStatesDropDown);
            setShowLogoutDropDown(false);
          }}
          startIcon={selected.icon}
          endIcon={getEndIcon()}
        >
          {selected.name || ''}
        </Button>
      )}
      {showStatesDropDown && (
        <div className={classes.menu}>
          <Typography
            className={classes.group_name}
            color="eerieBlack3"
            level="small"
          >
            {labels.SET_STATUS_AS}
          </Typography>
          {elements
            .filter((e) => pendingStatusChange || e.id !== selected.id)
            .filter((e) => e.isSelectable)
            .sort((a, b) => {
              let id1 = a.id > 0 ? a.id : a.id * -1 * 1000000;
              let id2 = b.id > 0 ? b.id : b.id * -1 * 1000000;
              return id1 - id2;
            })
            .map((e, k) => getDropDownOption(e, k))}
        </div>
      )}
      {showLogoutDropDown && (
        <div className={classes.menu}>
          <Typography
            className={classes.group_name}
            color="eerieBlack3"
            level="small"
          >
            {labels.SELECT_LOGOUT_REASON}
          </Typography>
          <Radio
            items={logoutReasons}
            selected={logoutReasonSelected}
            isRow={false}
            itemClass={clsx(classes.selectable, classes.radio_item)}
            onChange={handleLogoutReasonChange}
            className={classes.radio_container}
          />
          <div className={classes.actions_container}>
            <div className={classes.actions}>
              <Button size="small" type="outlined" onClick={handleCancelLogout}>
                {labels.CANCEL}
              </Button>
              <Button
                size="small"
                type={logoutButtonType}
                onClick={handleConfirmLogout}
              >
                {labels.LOG_OUT}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
StatusDropDown.propTypes = {
  labels: PropTypes.object,
  className: PropTypes.string,
  agentState: PropTypes.object,
  agentStates: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  logoutReasons: PropTypes.arrayOf(PropTypes.object),
  onClickLogout: PropTypes.func,
  callState: PropTypes.string,
};

StatusDropDown.defaultProps = {
  className: '',
  callState: '',
};

export default withLabels(StatusDropDown);
