const ctAppCommunities = [
  1365640,
  51585,
  159666,
  92876,
  92875,
  97492,
  51085,
  96085,
  93155,
  1346600,
  1363023,
  1338080,
  1338081,
  1338079,
  50408,
  1369901,
  1366846,
  148710,
  1366845,
  1386026,
  1375048,
  1445081,
  1440287,
  1338758,
  1415068,
  95956,
  1441098,
  139217,
  139069,
  155471,
  55630,
  137888,
  57387,
  58153,
  55826,
  58785,
  146374,
  68668,
  78861,
  87886,
  54182,
  139504,
  159901,
  72068,
  1356670,
  1364964,
  59670,
  1356654,
  1356436,
  1366667,
  1351862,
  1356054,
  54408,
  76380,
  139477,
  159963,
  1351960,
  1362768,
  145034,
  137928,
  1402470,
  66176,
  89912,
  153211,
  68666,
  66730,
  1333479,
  1402839,
  1356749,
  1360261,
  1353913,
  56871,
  1352078,
  1364508,
  68586,
  1349856,
  85818,
  59120,
  1359371,
  1353570,
  1353776,
  76042,
  65588,
  62136,
  1360341,
  97249,
  1398770,
  73061,
  57349,
  57555,
  1366527,
  1364837,
  1351110,
  72792,
  1378242,
  49468,
  1354789,
  1356253,
  149357,
  92304,
  1359083,
  141251,
  1360799,
  1365893,
  53997,
  1357538,
  1440522,
  160132,
  97193,
  1408181,
  1446375,
  143978,
  1333855,
  1392153,
  1373908,
  65042,
  1389523,
  1374635,
  1340451,
  1400253,
  1441236,
  143364,
  70971,
  95224,
  140936,
  1346577,
  149395,
  1412706,
  80022,
  1404603,
  1364375,
  1445622,
  142077,
  1436508,
  1436514,
  1353674,
  1353676,
  1436516,
  1408213,
  1412045,
  1436509,
  1389213,
  1436521,
  1395806,
  1401354,
  1345568,
  1353672,
  1436517,
  1436510,
  1407876,
  1436511,
  1353673,
  1401739,
  1388137,
  1356422,
  1412162,
  1365982,
  1353675,
  1446086,
  147265,
  1445000,
  154306,
  71317,
  1444997,
  140302,
  1374311,
  144813,
  1445002,
  1405465,
  1445001,
  140223,
  1444999,
  155396,
  1360574,
  1389662,
  1371675,
  1355547,
  1367035,
  139041,
  1402527,
  1399629,
  140334,
  150051,
  153947,
  1441401,
  49965,
  152854,
  1403081,
  1402321,
  152855,
  100049,
  100050,
  100051,
  152419,
  100052,
  78877,
  1401587,
  1445196,
  137837,
  1337781,
  1445207,
  48419,
  1365590,
  1446019,
  1449807,
  56307,
  57225,
  59081,
  1334787,
  1361620,
  1334795,
  1414730,
  1446501,
  1444505,
  1437036,
  1369028,
  1387098,
  1396788,
  86461,
  1381192,
  86510,
  66681,
  159156,
  156569,
  142797,
  146677,
  85918,
  86487,
  1359723,
  66735,
  1409702,
  1401239,
  102199,
  102200,
  1380169,
  86051,
  97862,
  142810,
  86587,
  85881,
  66732,
  1363483,
  97996,
  1394063,
  66752,
  1401240,
  85952,
  86516,
  66691,
  66641,
  86463,
  147209,
  66583,
  85936,
  1381618,
  1356510,
  86040,
  98853,
  1358622,
  85940,
  1375935,
  1368709,
  159352,
  85832,
  1438276,
  97997,
  143466,
  141447,
  85198,
  141487,
  1383110,
  85319,
  85759,
  66286,
  1337395,
  66329,
  1383363,
  85472,
  85287,
  1383364,
  141467,
  1343073,
  95077,
  69354,
  1449905,
  1445984,
  1381884,
  153541,
  1420961,
  1365974,
  1404925,
  1388161,
  1358247,
  1370223,
  1398626,
  1388162,
  1370222,
  1411912,
  1370224,
  1360223,
  1386329,
  1342838,
  1409416,
  1404888,
  1394884,
  1441999,
  1421899,
  1397603,
  1400798,
  1441998,
  1391146,
  1388825,
  1437424,
  1415219,
  1405228,
  1415095,
  1399356,
  1390555,
  1408276,
  1441996,
  1368695,
  1415220,
  1381246,
  1397160,
  1382054,
  1422010,
  1441997,
  1360462,
  139237,
  1415316,
  1396054,
  1445892,
  49377,
  1404866,
  90114,
  1404867,
  1404864,
  1404861,
  102322,
  102323,
  76311,
  75077,
  61820,
  61810,
  75098,
  1370220,
  75055,
  48974,
  1399734,
  1437792,
  98635,
  1441463,
  143527,
  97760,
  94785,
  97759,
  97243,
  53501,
  1404360,
  1444948,
  1388192,
  1441964,
  1438691,
  1332360,
  1387233,
  1437794,
  1438359,
  1381626,
  1438818,
  1399109,
  1395502,
  1444476,
  1406008,
  1409228,
  1409185,
  1413973,
  1393154,
  1378599,
  1406648,
  1404361,
  1440908,
  1397518,
  1420463,
  89647,
  89626,
  1405718,
  69587,
  91473,
  1396530,
  68600,
  91868,
  69191,
  82706,
  1405719,
  1361070,
  92975,
  91874,
  89828,
  82691,
  1422030,
  89726,
  89806,
  68775,
  1379282,
  1394868,
  83175,
  67488,
  1420754,
  138904,
  1412160,
  80849,
  101062,
  139521,
  73368,
  1392436,
  85883,
  1364336,
  70707,
  1364364,
  153549,
  1413939,
  1368649,
  1361043,
  1361044,
  75001,
  1406206,
  1364503,
  58620,
  155073,
  50251,
  81298,
  81702,
  82147,
  1399446,
  101866,
  1439081,
  1385954,
  141878,
  1375241,
  64539,
  1384712,
  1412668,
  1414056,
  1385956,
  82402,
  64978,
  140484,
  1413003,
  59080,
  140483,
  53230,
  77238,
  138998,
  56028,
  53318,
  54783,
  72811,
  79085,
  73622,
  56962,
  58169,
  140480,
  55569,
  72953,
  72878,
  1401381,
  64595,
  1403926,
  139238,
  56979,
  1359938,
  1363648,
  150964,
  61323,
  50786,
  72874,
  65250,
  72884,
  72888,
  56350,
  72807,
  50974,
  54917,
  1363717,
  57110,
  72857,
  55573,
  72892,
  50169,
  56015,
  72887,
  72896,
  91339,
  54924,
  66686,
  61780,
  72894,
  140482,
  51513,
  57335,
  142636,
  72885,
  72468,
  58456,
  57895,
  57927,
  72856,
  96269,
  141563,
  55100,
  150845,
  140497,
  62142,
  59108,
  56087,
  72875,
  65445,
  1441751,
  72883,
  99472,
  75219,
  72886,
  139635,
  58522,
  95654,
  72809,
  99267,
  81963,
  55460,
  63403,
  1436794,
  137859,
  72893,
  60924,
  1394068,
  72891,
  1414089,
  152428,
  1438476,
  55910,
  72876,
  77324,
  72858,
  1441719,
  77663,
  72906,
  50798,
  55597,
  150528,
  72890,
  47826,
  55914,
  1363715,
  72889,
  50121,
  51633,
  72877,
  1408919,
  1438477,
  50361,
  72810,
  95495,
  1445139,
  1351144,
  72898,
  72882,
  72808,
  71131,
  99445,
  55027,
  85857,
  1437656,
  99609,
  59082,
  72872,
  1383928,
  1421222,
  76863,
  75753,
  77180,
  1441720,
  152466,
  1421492,
  51560,
  50168,
  72471,
  51584,
  152366,
  142459,
  140485,
  61311,
  72453,
  95333,
  55240,
  139047,
  89487,
  72859,
  99446,
  140481,
  1335440,
  101171,
  67996,
  88706,
  68384,
  1439183,
  88332,
  1400657,
  93801,
  100164,
  88311,
  1383520,
  1440913,
  1441388,
  1446571,
  61526,
  1373939,
  1446635,
  82377,
  1444965,
  152096,
  152095,
  1337727,
  152091,
  152094,
  1402337,
  152092,
  152093,
  152097,
  102501,
  1363315,
  1413535,
  1370139,
  151343,
  102506,
  102502,
  102504,
  102525,
  142254,
  102503,
  102505,
  102507,
  1388455,
  1439214,
  101289,
  51074,
  1386055,
  101290,
  1363151,
  93048,
  1371471,
  1392214,
  1392218,
  1392213,
  1392215,
  1393015,
  150777,
  1407088,
  1396487,
  1396488,
  1441646,
  1401744,
  1420567,
  1388029,
  1371210,
  71329,
  1361379,
  1374237,
  1446185,
  1358380,
  141277,
  141278,
  1401680,
  67518,
  141275,
  98497,
  1438239,
  87798,
  1383173,
  141276,
  139222,
  1368385,
  142061,
  1353596,
  62348,
  96650,
  93830,
  1414191,
  1414644,
  1405041,
  1442934,
  1438754,
  1445220,
  1438165,
  93223,
  72828,
  1333983,
  1385952,
  1406901,
  1446753,
  53140,
  1437969,
  1366886,
  82553,
  1408200,
  96571,
  96572,
  1411759,
  51396,
  1361084,
  1358040,
  1370377,
  1414750,
  1414749,
  81439,
  96045,
  1411530,
  144643,
  96570,
  65135,
  94494,
  96411,
  83492,
  98262,
  96229,
  1348387,
  83509,
  1337106,
  144642,
  96569,
  95402,
  96568,
  1414751,
  89541,
  1402683,
  72981,
  1438115,
  1367400,
  100466,
  1445877,
  52891,
  144575,
  1438561,
  1378222,
  1449798,
  1450009,
  1340786,
  1444306,
  50925,
  1385428,
  1332151,
  1398434,
  1390584,
  1383281,
  1414647,
  1365047,
  1445113,
  1445115,
  1445114,
  1345211,
  1389960,
  143317,
  139571,
  1409315,
  144144,
  144145,
  1376057,
  85297,
  85500,
  64430,
  1362042,
  1367595,
  94980,
  85309,
  139140,
  149712,
  151064,
  104540,
  150535,
  1442447,
  1444943,
  1398489,
  1439169,
  1445626,
  1406718,
  1438344,
  68874,
  1383821,
  1382351,
  1382111,
  78674,
  147745,
  63538,
  1367261,
  1376368,
  1363520,
  1399230,
  1332448,
  1332117,
  1387900,
  1385128,
  97556,
  1345157,
  82491,
  1345000,
  64654,
  1369689,
  139854,
  80356,
  99162,
  81622,
  1398050,
  1421264,
  1356506,
  140463,
  150434,
  144183,
  151102,
  101853,
  1363339,
  1443635,
  1421767,
  90919,
  90680,
  57029,
  72237,
  71967,
  72221,
  76694,
  97919,
  72583,
  52253,
  86080,
  72584,
  80078,
  1353090,
  72555,
  50707,
  80387,
  48093,
  63924,
  49989,
  63847,
  97823,
  80017,
  49784,
  80016,
  60233,
  71853,
  142066,
  80093,
  95068,
  85596,
  143518,
  94901,
  51852,
  66153,
  50484,
  142352,
  95081,
  80280,
  143517,
  142511,
  57705,
  65998,
  72659,
  72658,
  72534,
  1352719,
  51394,
  52471,
  63900,
  80205,
  50148,
  56567,
  142367,
  62927,
  138841,
  72648,
  96627,
  52269,
  72715,
  142059,
  58633,
  80252,
  142362,
  72513,
  72506,
  70414,
  142062,
  64410,
  96086,
  95023,
  96948,
  64945,
  72548,
  51936,
  80124,
  60768,
  95721,
  72756,
  71530,
  97848,
  72624,
  50381,
  52165,
  95006,
  96631,
  142499,
  137793,
  93614,
  72495,
  68910,
  55845,
  1380935,
  83325,
  50082,
  80391,
  72535,
  72529,
  94917,
  141058,
  72657,
  94911,
  138034,
  52032,
  96639,
  65220,
  69692,
  50731,
  60013,
  72749,
  95009,
  49771,
  78636,
  72291,
  53732,
  95022,
  96629,
  63907,
  79993,
  97820,
  95720,
  94155,
  63938,
  47741,
  59669,
  64252,
  142368,
  50088,
  99759,
  93135,
  47996,
  54773,
  49040,
  96623,
  80491,
  80492,
  47846,
  59154,
  72607,
  95078,
  82152,
  138834,
  80091,
  79708,
  100345,
  72590,
  59057,
  50991,
  80590,
  142067,
  72668,
  102302,
  72622,
  72599,
  140857,
  66147,
  95099,
  92852,
  58801,
  55069,
  100885,
  141656,
  53703,
  49899,
  49776,
  63699,
  63427,
  70248,
  64167,
  59784,
  53060,
  142064,
  50212,
  52902,
  72585,
  95048,
  47918,
  52397,
  52650,
  72669,
  72412,
  80044,
  64005,
  49216,
  101904,
  50417,
  138912,
  97481,
  52061,
  72301,
  63472,
  142349,
  139887,
  56271,
  67947,
  49031,
  71666,
  96622,
  101862,
  98075,
  89786,
  95026,
  56234,
  53182,
  79701,
  94912,
  61148,
  94944,
  138838,
  95029,
  72602,
  98614,
  59754,
  83703,
  72647,
  64100,
  50123,
  69317,
  94810,
  95027,
  97917,
  55470,
  51297,
  140595,
  89408,
  51712,
  53058,
  80248,
  72530,
  1329880,
  95084,
  72686,
  141872,
  72545,
  72551,
  53685,
  48032,
  72234,
  76363,
  93315,
  94910,
  56311,
  93185,
  64029,
  66392,
  56139,
  61635,
  1391399,
  54656,
  63149,
  97957,
  64171,
  72563,
  83286,
  139250,
  72387,
  72305,
  65802,
  101905,
  95030,
  54774,
  142490,
  72595,
  72400,
  67551,
  65953,
  146617,
  99728,
  142359,
  61482,
  95106,
  63897,
  72693,
  49763,
  72654,
  65903,
  52535,
  64335,
  72618,
  95019,
  70673,
  50523,
  72789,
  65123,
  95112,
  72744,
  80253,
  140409,
  99248,
  58417,
  72375,
  138837,
  66156,
  142350,
  142364,
  152164,
  96619,
  71789,
  52472,
  52563,
  47803,
  72331,
  66409,
  67388,
  72660,
  61080,
  72514,
  81251,
  95028,
  139866,
  81289,
  48328,
  1371214,
  1367410,
  93440,
  52221,
  71749,
  81628,
  79789,
  80453,
  142348,
  80075,
  93555,
  144609,
  72672,
  47733,
  95008,
  72536,
  97718,
  77153,
  139865,
  53711,
  52015,
  68758,
  88463,
  72666,
  72528,
  72539,
  72550,
  75640,
  142507,
  95050,
  141877,
  137922,
  93551,
  142494,
  69335,
  66629,
  72540,
  138800,
  52624,
  142509,
  52264,
  51137,
  100354,
  1363316,
  95082,
  80843,
  57798,
  142496,
  58998,
  95040,
  50070,
  72617,
  66762,
  80273,
  66626,
  51632,
  67764,
  68117,
  142357,
  65144,
  72511,
  62098,
  93387,
  80490,
  72561,
  52254,
  95052,
  55502,
  66591,
  140659,
  96625,
  142369,
  72651,
  72652,
  99727,
  85008,
  93397,
  75769,
  51767,
  50467,
  80470,
  98240,
  97767,
  95053,
  95042,
  95723,
  85020,
  89610,
  52728,
  80402,
  142358,
  61165,
  68538,
  95722,
  47697,
  48384,
  72526,
  52862,
  72517,
  51506,
  72774,
  72775,
  98450,
  138836,
  142510,
  94124,
  51634,
  98757,
  53249,
  141059,
  71339,
  51896,
  97824,
  95086,
  47876,
  50326,
  50428,
  72655,
  84897,
  72670,
  142063,
  99368,
  72558,
  54261,
  60027,
  72612,
  72556,
  71629,
  95059,
  142363,
  138839,
  49717,
  95039,
  72582,
  72650,
  79841,
  61967,
  72598,
  72748,
  54309,
  80605,
  72503,
  102355,
  72620,
  72494,
  72332,
  93798,
  138835,
  138038,
  96633,
  72614,
  83740,
  65070,
  49946,
  71302,
  97380,
  50982,
  51362,
  52250,
  50759,
  95005,
  94411,
  1353506,
  72398,
  72399,
  95083,
  70638,
  89485,
  96624,
  48186,
  54991,
  140407,
  52220,
  49853,
  80345,
  72755,
  72823,
  50389,
  71541,
  71788,
  51206,
  72758,
  81340,
  63826,
  60446,
  93290,
  61597,
  72562,
  48191,
  78842,
  62933,
  1357385,
  1349296,
  63855,
  50346,
  62689,
  65914,
  72702,
  72741,
  64222,
  138842,
  47852,
  142497,
  72864,
  89650,
  52036,
  1380955,
  59698,
  72662,
  60029,
  60028,
  53330,
  94150,
  74524,
  141166,
  49978,
  50785,
  96620,
  142065,
  82099,
  72619,
  72078,
  64641,
  72757,
  65081,
  72600,
  94465,
  95061,
  96636,
  68620,
  49834,
  72721,
  64094,
  50938,
  70059,
  140408,
  57470,
  72310,
  65788,
  101668,
  145100,
  48963,
  51481,
  93433,
  95085,
  72544,
  93786,
  72779,
  72784,
  63888,
  97138,
  52083,
  49612,
  53751,
  53215,
  63513,
  71865,
  63547,
  63916,
  52466,
  72695,
  72696,
  55884,
  93361,
  66931,
  96635,
  79526,
  74511,
  98678,
  49632,
  62208,
  75503,
  99604,
  51464,
  84925,
  62146,
  60201,
  50841,
  80030,
  64424,
  48553,
  72625,
  84166,
  72597,
  97958,
  97369,
  84571,
  68500,
  77769,
  59768,
  142371,
  74131,
  142491,
  52613,
  64007,
  61481,
  142500,
  70403,
  60378,
  65935,
  142365,
  100742,
  1363380,
  71753,
  52869,
  65593,
  96630,
  72520,
  95024,
  99730,
  95045,
  47855,
  99733,
  99438,
  72865,
  47975,
  62192,
  51132,
  53234,
  100972,
  48382,
  72490,
  72489,
  62785,
  78016,
  72496,
  72720,
  49084,
  49083,
  68612,
  69720,
  51295,
  83393,
  161324,
  49761,
  84491,
  142812,
  96039,
  85358,
  96621,
  57042,
  72745,
  72549,
  71564,
  80276,
  70466,
  92950,
  95087,
  82213,
  142060,
  50102,
  51860,
  71214,
  52235,
  72531,
  72537,
  49878,
  63971,
  72397,
  72667,
  98074,
  47709,
  53392,
  93810,
  93245,
  47628,
  96475,
  95025,
  80974,
  96634,
  97821,
  95007,
  48979,
  72616,
  85331,
  80500,
  50445,
  72484,
  51078,
  72593,
  51095,
  139864,
  71537,
  93220,
  73747,
  72032,
  1356215,
  94916,
  143741,
  96626,
  72541,
  63223,
  1358139,
  1361254,
  1413365,
  1394192,
  1413825,
  1438234,
  1333287,
  84700,
  1399961,
  1400548,
  1438821,
  99154,
  1363017,
  1380352,
  1364054,
  1413298,
  51497,
  1375597,
  94918,
  93635,
  1398865,
  1398863,
  82565,
  94871,
  56832,
  1414131,
  1398864,
  1420536,
  1401904,
  1438418,
  140766,
  1398866,
  1420527,
  1413666,
  160231,
  1398862,
  81324,
  81111,
  1420531,
  1392473,
  1392474,
  1398458,
  1398457,
  59762,
  54380,
  1369563,
  96899,
  57098,
  64570,
  1438153,
  1399381,
  1389420,
  142450,
  98926,
  81367,
  75836,
  1401196,
  153846,
  141140,
  1412199,
  1421495,
  152385,
  152415,
  66326,
  95373,
  96661,
  153993,
  80976,
  135491,
  69814,
  95372,
  91604,
  95374,
  1376266,
  137870,
  53814,
  140149,
  79479,
  86236,
  93202,
  85259,
  156489,
  66048,
  95368,
  1354786,
  56404,
  63919,
  156654,
  66058,
  47949,
  51797,
  99924,
  51972,
  93280,
  62103,
  80966,
  151827,
  79659,
  50376,
  61271,
  95371,
  65855,
  69779,
  83205,
  146046,
  84400,
  50512,
  95370,
  1338449,
  51113,
  64028,
  96286,
  142843,
  1369858,
  143582,
  98267,
  101185,
  152906,
  146594,
  153092,
  152416,
  99479,
  85409,
  92335,
  146436,
  85221,
  66430,
  1356121,
  51567,
  85524,
  51161,
  69770,
  159335,
  1354122,
  85213,
  80938,
  69776,
  150990,
  66269,
  85476,
  1362794,
  141396,
  51674,
  84689,
  64260,
  146392,
  1394179,
  72416,
  1370170,
  81527,
  50921,
  139301,
  1414742,
  144059,
  1396386,
  1388848,
  1408348,
  1376514,
  1408339,
  1408349,
  1396455,
  1439268,
  1383151,
  1369762,
  1366039,
  1366619,
  1390335,
  93060,
  82360,
  1368584,
  1436946,
  1436945,
  1436947,
  1436948,
  150228,
  1353801,
  1377564,
  66519,
  142934,
  1396967,
  1446225,
  94176,
  155033,
  143541,
  60427,
  75382,
  154486,
  146207,
  105016,
  100415,
  158558,
  75953,
  1411072,
  95791,
  149369,
  100299,
  1444205,
  1384001,
  92611,
  1380399,
  139215,
  1368334,
  1444895,
  1393460,
  1411632,
  1441715,
  1441823,
  139145,
  72683,
  144569,
  60116,
  71575,
  71280,
  60214,
  55848,
  71910,
  140868,
  60132,
  71283,
  97904,
  96945,
  71678,
  59919,
  101498,
  139511,
  1376064,
  95886,
  53614,
  1414556,
  79165,
  87837,
  91023,
  153103,
  150045,
  1413456,
  1357754,
  1365540,
  1443599,
  1413452,
  1337030,
  1356421,
  1367738,
  68949,
  1441578,
  1365064,
  1335706,
  1369451,
  1357753,
  1369450,
  1413453,
  1384570,
  1413455,
  1346460,
  1371511,
  1440540,
  81625,
  1381075,
  1409117,
  1413457,
  1356134,
  1404635,
  1365063,
  1331164,
  81823,
  1345949,
  1345948,
  152088,
  1377354,
  1381077,
  1413454,
  1369452,
  1414479,
  80825,
  91866,
  157149,
  1370975,
  155735,
  65333,
  1376005,
  92072,
  93578,
  94451,
  85299,
  142762,
  94348,
  94044,
  76977,
  93710,
  101285,
  1444390,
  157137,
  149729,
  59769,
  84255,
  144894,
  1380774,
  93638,
  99686,
  70664,
  70692,
  154144,
  52620,
  71635,
  1382578,
  153699,
  50092,
  1438824,
  1441458,
  1438822,
  1449613,
  93137,
  1399526,
  1383822,
  153434,
  1412486,
  1366636,
  1369029,
  102298,
  146123,
  1340104,
  1384638,
  1414263,
  1376976,
  1420455,
  65494,
  140896,
  151795,
  1392487,
  146352,
  1381362,
  89522,
  95105,
  140325,
  1414793,
  1414795,
  90557,
  139524,
  1441066,
  140374,
  154959,
  101989,
  77334,
  1414188,
  138423,
  75441,
  75469,
  151829,
  1438000,
  1445261,
  1445262,
  72833,
  1443652,
  100077,
  72457,
  61351,
  72459,
  140809,
  1445263,
  72460,
  72814,
  100079,
  1387969,
  72462,
  1445579,
  74671,
  59559,
  1352301,
  1352171,
  48905,
  1365662,
  1359312,
  1400543,
  152110,
  50979,
  142897,
  1344050,
  1401203,
  143934,
  89077,
  102489,
  145057,
  51039,
  1401336,
  1382932,
  1421244,
  1401334,
  1329333,
  67234,
  1358011,
  1437060,
  1386473,
  94953,
  59384,
  67113,
  150644,
  97947,
  1409124,
  1405817,
  1370765,
  1359618,
  139131,
  52316,
  100007,
  1441740,
  1446692,
  98646,
  59635,
  99857,
  59201,
  97909,
  60118,
  102304,
  59596,
  102303,
  67685,
  142487,
  1406721,
  60066,
  146560,
  71000,
  72362,
  73372,
  1371534,
  71012,
  1445396,
  1438264,
  1437584,
  96407,
  1414515,
  140398,
  1414773,
  1400505,
  138442,
  101257,
  1421482,
  1441934,
  1421481,
  1413778,
  1370878,
  1336965,
  1407570,
  65236,
  140383,
  140384,
  1407568,
  79237,
  78757,
  63629,
  1445130,
  96221,
  161341,
  1445165,
  1445166,
  52414,
  52402,
  66128,
  84652,
  147764,
  93836,
  70835,
  55060,
  1387563,
  137784,
  1443154,
  97265,
  1367404,
  100717,
  1413951,
  1414252,
  1438514,
  1421542,
  1407749,
  1399298,
  1385969,
  1375599,
  1375116,
  1375135,
  1374233,
  1401047,
  51630,
  59664,
  151004,
  73190,
  62789,
  97154,
  72381,
  49160,
  1334285,
  1421493,
  1446666,
  74478,
  1392970,
  1363306,
  1401768,
  77147,
  66871,
  1412888,
  64322,
  1392503,
  1356459,
  1361049,
  1449871,
  53113,
  78509,
  100775,
  68893,
  155892,
  151189,
  1403539,
  69533,
  153463,
  1440952,
  102464,
  159606,
  1392435,
  139400,
  157716,
  57954,
  1365353,
  96637,
  1358095,
  1414225,
  161151,
  147167,
  144316,
  147409,
  1414226,
  1414244,
  1356551,
  1363697,
  85330,
  156538,
  1441132,
  147536,
  82715,
  49831,
  141821,
  50569,
  1441688,
  78512,
  1393702,
  70438,
  143580,
  1354754,
  63404,
  101048,
  1394400,
  1414243,
  63178,
  155619,
  1380453,
  154498,
  49021,
  1340883,
  66480,
  1445364,
  1414227,
  154663,
  1446613,
  1439155,
  1352340,
  1367945,
  1414543,
  1385820,
  1436496,
  1438447,
  1441913,
  65390,
  1421219,
  1382873,
  1395886,
  1400064,
  1385821,
  1390321,
  55865,
  150335,
  50066,
  50058,
  1388256,
  1374453,
  54174,
  1440948,
  1356848,
  63252,
  96047,
  96046,
  51305,
  1441504,
  1368386,
  1397162,
  95884,
  63551,
  51477,
  94658,
  47844,
  95394,
  55122,
  92376,
  95727,
  70173,
  138358,
  51550,
  65085,
  50939,
  80256,
  95171,
  97616,
  70231,
  51815,
  53737,
  78618,
  55167,
  96062,
  78155,
  95170,
  98623,
  138361,
  78073,
  50931,
  80255,
  51189,
  141511,
  146992,
  72710,
  101865,
  82866,
  55110,
  63759,
  49519,
  77831,
  138362,
  77738,
  51142,
  80508,
  102261,
  96064,
  97916,
  95885,
  62263,
  102520,
  95073,
  98021,
  80310,
  54524,
  83499,
  47872,
  95155,
  1329943,
  138357,
  77683,
  95758,
  82858,
  1331529,
  82474,
  63833,
  95755,
  62225,
  72374,
  96450,
  51400,
  66230,
  82578,
  83078,
  99628,
  50026,
  140290,
  138360,
  51202,
  62892,
  77998,
  138359,
  98018,
  102521,
  96159,
  63754,
  51637,
  93279,
  82322,
  53331,
  1380952,
  100279,
  64004,
  51717,
  95072,
  62229,
  62682,
  94172,
  77750,
  49081,
  51696,
  65197,
  161104,
  83177,
  96061,
  98019,
  51484,
  77969,
  1415186,
  1397880,
  1335984,
  53735,
  1359730,
  1374707,
  1398299,
  62127,
  74310,
  62183,
  74368,
  74349,
  74738,
  101953,
  74352,
  74364,
  1370986,
  62117,
  74284,
  74687,
  1421052,
  161376,
  1334089,
  1376429,
  101516,
  72717,
  142295,
  63378,
  1398297,
  141767,
  1358897,
  99126,
  98592,
  147453,
  60815,
  1441960,
  101514,
  99183,
  70233,
  1408799,
  95743,
  92410,
  1438848,
  70873,
  94341,
  62278,
  1331051,
  1352996,
  160137,
  1350118,
  47861,
  1399857,
  155775,
  94038,
  1333068,
  160456,
  101428,
  1398417,
  60728,
  88698,
  95881,
  146145,
  1358003,
  87211,
  149490,
  1370264,
  1370265,
  1444518,
  1414073,
  1355024,
  153253,
  1368431,
  1368430,
  1408308,
  77494,
  49949,
  77873,
  1361603,
  50235,
  50236,
  1414514,
  84651,
  1388925,
  50597,
  1446277,
  52735,
  141568,
  53291,
  100024,
  1370268,
  72380,
  72093,
  70353,
  86945,
  85200,
  142353,
  69826,
  52426,
  142498,
  142508,
  139491,
  74211,
  74217,
  86891,
  142356,
  79818,
  91682,
  85129,
  53467,
  81081,
  66449,
  75684,
  48141,
  53519,
  79861,
  91969,
  84734,
  52333,
  48028,
  95044,
  1370263,
  85752,
  50957,
  1370274,
  79793,
  64416,
  85662,
  62501,
  60112,
  86009,
  1370277,
  81428,
  52648,
  64521,
  83825,
  85478,
  143465,
  87646,
  92235,
  72661,
  72627,
  67369,
  53792,
  70340,
  71773,
  86337,
  85141,
  80893,
  1367174,
  1367175,
  95067,
  72243,
  52400,
  86367,
  53211,
  72664,
  69798,
  50985,
  94892,
  72232,
  66330,
  85460,
  73746,
  73840,
  53452,
  80712,
  84164,
  86935,
  72626,
  61537,
  72504,
  71922,
  1370253,
  85808,
  84343,
  84186,
  142501,
  52671,
  72079,
  1370267,
  80875,
  96422,
  66015,
  85781,
  85688,
  73784,
  82806,
  65679,
  96088,
  75348,
  81423,
  87323,
  1364025,
  51743,
  72483,
  75674,
  61496,
  145101,
  67348,
  64720,
  66360,
  85509,
  77517,
  52353,
  79838,
  81563,
  1370262,
  85645,
  1370275,
  51551,
  53843,
  66036,
  94893,
  72663,
  81097,
  62995,
  77488,
  86968,
  50743,
  80232,
  71929,
  66438,
  66472,
  52470,
  60271,
  77950,
  150993,
  72316,
  86310,
  85777,
  1370256,
  85747,
  84063,
  63154,
  63771,
  1370269,
  80776,
  49769,
  87955,
  66503,
  147739,
  71758,
  65967,
  126662,
  66069,
  85475,
  96051,
  71995,
  62389,
  63651,
  67845,
  67367,
  51110,
  51758,
  51737,
  80841,
  92237,
  65724,
  86001,
  60285,
  84056,
  71599,
  87528,
  85366,
  73973,
  63882,
  1370266,
  50369,
  52499,
  91593,
  72491,
  72492,
  1370272,
  86638,
  72378,
  72901,
  52539,
  78677,
  66401,
  92216,
  65244,
  52792,
  67267,
  53847,
  78139,
  100896,
  146372,
  59717,
  81291,
  72705,
  78360,
  52361,
  85461,
  86938,
  59779,
  81022,
  64481,
  91586,
  52884,
  50890,
  85240,
  52551,
  71730,
  89907,
  100436,
  78578,
  65578,
  92064,
  144961,
  1375895,
  1405067,
  97029,
  1411166,
  1378595,
  1329228,
  1348462,
  1411167,
  1386851,
  1375896,
  1357323,
  1386850,
  71221,
  71370,
  71498,
  71458,
  71393,
  139873,
  140970,
  96715,
  1386332,
  60492,
  1444923,
  1438583,
  78992,
  1445061,
  1361644,
  1408433,
  1408432,
  1445367,
  1443205,
  1386144,
  1446680,
  1444956,
  99537,
  1361128,
  143469,
  1399633,
  66622,
  86075,
  53038,
  147303,
  56566,
  49064,
  59049,
  1368882,
  87524,
  75250,
  51591,
  101521,
  78529,
  52201,
  152468,
  1361323,
  63208,
  51392,
  78963,
  50313,
  81424,
  80948,
  50598,
  63578,
  1360306,
  79553,
  53456,
  79994,
  1357783,
  85443,
  1333371,
  66437,
  85307,
  81483,
  70637,
  80845,
  87617,
  141248,
  100611,
  102477,
  140871,
  98583,
  50571,
  78778,
  49042,
  140872,
  81568,
  63487,
  156381,
  77280,
  63514,
  49041,
  98177,
  61412,
  72914,
  64187,
  62352,
  64892,
  148271,
  53264,
  62926,
  99511,
  1338693,
  82240,
  141398,
  64861,
  1343588,
  55696,
  142611,
  97642,
  78525,
  153652,
  64504,
  81486,
  144854,
  80579,
  72912,
  79409,
  61502,
  82304,
  64543,
  144323,
  59976,
  80695,
  142651,
  78649,
  140842,
  141172,
  57911,
  69543,
  87614,
  141169,
  59193,
  48570,
  49846,
  80088,
  81085,
  80656,
  155990,
  64340,
  90334,
  100711,
  82532,
  75623,
  66446,
  64495,
  98698,
  144322,
  66292,
  138595,
  72351,
  96380,
  50204,
  91214,
  144980,
  82315,
  63509,
  66562,
  85647,
  139919,
  95110,
  64770,
  79272,
  66571,
  81736,
  81734,
  89778,
  139596,
  137940,
  85496,
  142058,
  65488,
  97182,
  84134,
  80925,
  49727,
  98582,
  56824,
  63255,
  64564,
  63238,
  1343592,
  72521,
  95113,
  49016,
  48476,
  66424,
  50200,
  83775,
  52159,
  64306,
  1333844,
  143838,
  97643,
  150283,
  139920,
  81108,
  91220,
  74163,
  79107,
  64845,
  150751,
  52593,
  89894,
  81711,
  65617,
  82600,
  49721,
  82660,
  63877,
  64394,
  139921,
  80867,
  82967,
  48893,
  142609,
  1352607,
  98427,
  58611,
  85289,
  82985,
  72945,
  78673,
  63608,
  72415,
  82493,
  72419,
  64368,
  98179,
  142610,
  64441,
  97641,
  138857,
  75421,
  48914,
  82281,
  53648,
  153997,
  139915,
  48548,
  53763,
  81591,
  65533,
  152267,
  141352,
  72524,
  83039,
  72424,
  138594,
  152467,
  142403,
  153507,
  65078,
  75693,
  98581,
  82936,
  139918,
  81087,
  81050,
  142613,
  81533,
  139922,
  99510,
  143196,
  150394,
  64209,
  67986,
  98180,
  142608,
  81041,
  85238,
  85679,
  65496,
  98178,
  66345,
  100937,
  100595,
  1443605,
  1445351,
  49025,
  48919,
  49233,
  1390968,
  51516,
  96358,
  96356,
  90194,
  1421003,
  89583,
  89440,
  1446018,
  1403227,
  1401231,
  156142,
  1362741,
  1363782,
  1396972,
  1382606,
  1400602,
  1333573,
  1410294,
  78536,
  1364251,
  65641,
  160237,
  1342619,
  1362331,
  143749,
  1343357,
  143755,
  1342616,
  101089,
  159470,
  151648,
  157252,
  1342615,
  1343351,
  60180,
  143872,
  1384791,
  53135,
  60287,
  1443649,
  1414640,
  137979,
  1381413,
  147162,
  1396372,
  140362,
  1331617,
  141204,
  143688,
  96845,
  1371580,
  1388639,
  94848,
  144178,
  1421380,
  1393265,
  95935,
  140326,
  60158,
  1366413,
  1413533,
  1399204,
  60019,
  1354531,
  97914,
  123165,
  60194,
  1374959,
  68511,
  93965,
  143311,
  71563,
  95216,
  1357563,
  140363,
  1357555,
  147230,
  1363766,
  1397602,
  1389408,
  1368372,
  95183,
  53098,
  59774,
  1368697,
  153166,
  59727,
  59731,
  1413798,
  60193,
  1380778,
  140364,
  60293,
  1349273,
  1374144,
  1397159,
  1381368,
  1393132,
  1414071,
  52774,
  1414617,
  1366411,
  95184,
  151550,
  95232,
  96052,
  147165,
  101611,
  59688,
  1365749,
  60359,
  60265,
  1437283,
  1398128,
  1403829,
  73370,
  1410447,
  1421379,
  95708,
  1379195,
  95280,
  1371582,
  95181,
  1398127,
  151551,
  1361586,
  59828,
  142564,
  1336978,
  1384381,
  1366646,
  58987,
  1386952,
  1414624,
  1359666,
  147166,
  72578,
  94862,
  71524,
  72771,
  95981,
  149530,
  1438100,
  1382306,
  1364672,
  1382367,
  1382307,
  1441223,
  146633,
  55772,
  140411,
  145061,
  143142,
  1407498,
  79198,
  89943,
  153996,
  1405960,
  1330751,
  89975,
  146771,
  1405957,
  100589,
  100590,
  1387927,
  146770,
  1403824,
  1405959,
  142256,
  1338612,
  100585,
  1354388,
  152084,
  1405967,
  1438243,
  1405958,
  100587,
  1407497,
  101426,
  146769,
  152083,
  151586,
  1363628,
  1342870,
  1380568,
  100583,
  1338607,
  1361390,
  1338609,
  143144,
  1405965,
  1405966,
  143143,
  1391394,
  1405164,
  100584,
  1411160,
  143141,
  100588,
  1397442,
  1334844,
  143145,
  71863,
  99967,
  67852,
  146964,
  69780,
  1436818,
  146526,
  93435,
  70633,
  78616,
  1378309,
  63105,
  82173,
  78935,
  76167,
  82293,
  63118,
  78224,
  1441948,
  1354745,
  1445861,
  97878,
  1445605,
  146582,
  157091,
  1368746,
  151063,
  1443188,
  1380787,
  1449633,
  1351763,
  1398341,
  1441537,
  1400854,
  54168,
  1446441,
  148879,
  1402478,
  146027,
  1390917,
  1421253,
  1347119,
  1359099,
  1385330,
  1421854,
  1359100,
  1421855,
  1421853,
  1390919,
  1421856,
  1344591,
  1394018,
  141293,
  58539,
  97908,
  96894,
  92670,
  91633,
  91223,
  81930,
  91640,
  142222,
  63532,
  53716,
  96763,
  1404855,
  91418,
  141291,
  1357554,
  66738,
  72467,
  91328,
  1330160,
  91322,
  76477,
  51766,
  1414374,
  69576,
  1356067,
  91308,
  53726,
  91264,
  102530,
  144062,
  91476,
  91465,
  72903,
  91311,
  1329608,
  146800,
  1386545,
  81413,
  152149,
  140152,
  76781,
  90601,
  140709,
  1400927,
  69553,
  69549,
  139525,
  143968,
  82544,
  158063,
  71085,
  1392438,
  146425,
  97653,
  97654,
  1420429,
  1444970,
  1366726,
  1445456,
  1415157,
  141079,
  1338610,
  138332,
  1445954,
  59248,
  1420696,
  67971,
  141822,
  82312,
  101007,
  82287,
  143354,
  1445583,
  1445770,
  1403131,
  71523,
  1444977,
  142489,
  1437323,
  72645,
  82543,
  48108,
  67894,
  48198,
  49111,
  96628,
  1396741,
  1397166,
  67421,
  48347,
  140796,
  99732,
  50011,
  142351,
  50277,
  150487,
  48139,
  146533,
  78586,
  146532,
  146534,
  1420484,
  79404,
  59723,
  1412537,
  142355,
  1445628,
  96025,
  53173,
  53219,
  1342788,
  1444898,
  94988,
  102063,
  100203,
  139262,
  1358978,
  155395,
  76936,
  1366466,
  100201,
  100275,
  1444331,
  1446713,
  154590,
  1444959,
  1414393,
  1360456,
  1446572,
  1446681,
  1342472,
  1413620,
  1403533,
  1386547,
  48026,
  1385058,
  1393301,
  1413621,
  1441941,
  96083,
  1375098,
  1436528,
  1399756,
  80749,
  50029,
  1406554,
  1438356,
  50199,
  1330201,
  81049,
  81064,
  1413622,
  1354927,
  139318,
  1420375,
  1389314,
  1412003,
  49832,
  47829,
  1412002,
  1436527,
  48889,
  48558,
  96084,
  1381955,
  1402142,
  1407020,
  1440442,
  1357436,
  1387621,
  1387622,
  1387624,
  1387623,
  1420069,
  1381162,
  1414239,
  1385967,
  1421913,
  1400006,
  1438296,
  1347208,
  1437177,
  1399906,
  1414241,
  1414237,
  1374830,
  1414236,
  1404853,
  1410425,
  1421890,
  1402168,
  79644,
  1374831,
  1399907,
  1414238,
  1400007,
  1387618,
  1440357,
  139680,
  139681,
  139682,
  139685,
  139683,
  139684,
  1442030,
  93073,
  1445565,
  78554,
  84981,
  79217,
  75656,
  95476,
  93276,
  78650,
  89484,
  61464,
  95471,
  1380892,
  95467,
  95625,
  78519,
  49265,
  95474,
  1387832,
  49048,
  95470,
  77754,
  73866,
  78871,
  68591,
  48957,
  73978,
  75917,
  1366499,
  79001,
  95473,
  95472,
  89781,
  84481,
  62985,
  61147,
  50198,
  153317,
  49927,
  61344,
  86122,
  95468,
  49512,
  49847,
  95624,
  65875,
  101243,
  84930,
  68660,
  68655,
  142840,
  76305,
  68554,
  95475,
  95477,
  78839,
  86047,
  49560,
  54816,
  158151,
  152181,
  1367518,
  74685,
  61208,
  140657,
  1390419,
  101090,
  147946,
  74549,
  139142,
  1339007,
  157397,
  61974,
  79091,
  63277,
  78946,
  95384,
  1401570,
  102050,
  1386877,
  1390778,
  146272,
  97192,
  1443242,
  60555,
  1421488,
  1412672,
  1362073,
  1352017,
  1446638,
  1444766,
  90078,
  151909,
  70789,
  1377573,
  1387308,
  101249,
  143509,
  143508,
  98619,
  1420161,
  1355302,
  148555,
  1367678,
  1404698,
  1406787,
  1370125,
  98620,
  151749,
  1393147,
  101356,
  152493,
  144656,
  142603,
  63089,
  78608,
  100104,
  1366891,
  1378087,
  1389124,
  78300,
  1377287,
  99505,
  148182,
  101166,
  148284,
  1389123,
  78123,
  1438791,
  79390,
  1422023,
  1444652,
  1355264,
  63638,
  64944,
  1358568,
  82302,
  83135,
  83027,
  82226,
  148118,
  101872,
  82283,
  82291,
  83136,
  1396737,
  83146,
  1396720,
  82231,
  1392840,
  1368418,
  1437470,
  55570,
  149640,
  1414501,
  54782,
  99823,
  1409705,
  95142,
  95899,
  66435,
  55375,
  98379,
  50929,
  85938,
  139604,
  68446,
  95904,
  147151,
  47850,
  86519,
  57728,
  94149,
  67112,
  49442,
  74237,
  147159,
  90077,
  95133,
  147158,
  98367,
  91713,
  90454,
  147185,
  55064,
  95900,
  71852,
  80344,
  71147,
  139605,
  76002,
  52846,
  83203,
  101844,
  50733,
  90944,
  70604,
  95219,
  147176,
  95141,
  147161,
  98370,
  143435,
  64840,
  80418,
  95902,
  95147,
  53436,
  101839,
  60424,
  74758,
  139606,
  140879,
  80157,
  101842,
  82370,
  82857,
  95687,
  50430,
  63926,
  56815,
  98376,
  64243,
  80386,
  50930,
  49605,
  60196,
  90902,
  95134,
  95135,
  148383,
  91462,
  70690,
  98371,
  59775,
  89318,
  93960,
  83609,
  72180,
  73263,
  80967,
  52097,
  60000,
  148495,
  147152,
  59595,
  89031,
  89271,
  86748,
  81094,
  86070,
  82983,
  98372,
  92418,
  56540,
  79646,
  95137,
  95905,
  73307,
  71978,
  1347425,
  147157,
  48126,
  51301,
  93428,
  56574,
  154511,
  140875,
  95117,
  98381,
  98373,
  52560,
  95122,
  93814,
  47740,
  52028,
  53012,
  60001,
  67161,
  77804,
  147160,
  71514,
  98369,
  98377,
  66827,
  48391,
  83178,
  147179,
  77521,
  55692,
  98368,
  95114,
  94364,
  69055,
  84941,
  62591,
  91903,
  98383,
  147184,
  72067,
  139643,
  83534,
  67326,
  140878,
  72315,
  59943,
  85992,
  1353514,
  93474,
  139608,
  95143,
  147186,
  95903,
  95129,
  139609,
  95220,
  52532,
  83555,
  98384,
  93616,
  60386,
  93448,
  148384,
  85640,
  57696,
  50179,
  56450,
  52297,
  50442,
  81735,
  59803,
  98302,
  60261,
  146991,
  50802,
  64450,
  147115,
  60113,
  56463,
  95144,
  139611,
  81485,
  92443,
  55276,
  58724,
  147155,
  86187,
  60301,
  60050,
  147153,
  77667,
  92186,
  70732,
  92793,
  48025,
  72680,
  50606,
  69531,
  55833,
  143537,
  147113,
  52391,
  63160,
  71227,
  92514,
  97621,
  58696,
  139325,
  147175,
  95136,
  76331,
  82531,
  142469,
  86237,
  59540,
  54351,
  95146,
  70846,
  57701,
  95901,
  63553,
  58281,
  76805,
  80424,
  98380,
  69611,
  147182,
  65071,
  82464,
  47977,
  95116,
  50034,
  52459,
  139001,
  61149,
  59987,
  94063,
  53491,
  87452,
  56504,
  78592,
  81592,
  86168,
  1409944,
  80952,
  92130,
  147187,
  139612,
  50616,
  71752,
  147178,
  85716,
  82573,
  82634,
  140877,
  49858,
  140448,
  51453,
  138398,
  1437324,
  78411,
  156787,
  1353707,
  1446477,
  1392650,
  1368616,
  1437422,
  1446409,
  75827,
  75822,
  1444851,
  1444849,
  154830,
  1336266,
  55311,
  55256,
  86055,
  85902,
  66592,
  102479,
  66651,
  55333,
  1379960,
  1340557,
  1363895,
  75482,
  1446467,
  64444,
  101757,
  138596,
  1441421,
  72704,
  1408677,
  139861,
  72699,
  77730,
  139859,
  73965,
  97949,
  147114,
  1415043,
  52260,
  59060,
  89994,
  95132,
  71804,
  52610,
  75977,
  71342,
  76026,
  101843,
  98382,
  71570,
  1409696,
  58856,
  71482,
  139613,
  1436952,
  98378,
  50372,
  58637,
  101837,
  95138,
  56564,
  1394450,
  98374,
  1413624,
  1421096,
  1415113,
  1393248,
  1420251,
  1394499,
  1388357,
  1379755,
  1436591,
  1333589,
  1393705,
  1365524,
  1336180,
  1369830,
  1379360,
  92674,
  1414018,
  150844,
  67904,
  1400340,
  1411966,
  50563,
  1361200,
  1354532,
  158766,
  1445548,
  160624,
  149734,
  1369897,
  1385722,
  141429,
  97077,
  149990,
  1364733,
  1355658,
  67273,
  88319,
  144075,
  144074,
  1390780,
  1359897,
  86680,
  148071,
  1385668,
  1421194,
  149997,
  1411596,
  1333770,
  68818,
  1384535,
  143147,
  72559,
  143157,
  72565,
  67830,
  87778,
  87783,
  1438209,
  149999,
  68793,
  154971,
  1395405,
  147242,
  1362429,
  1406726,
  1357371,
  87062,
  67296,
  154801,
  144193,
  67394,
  155046,
  87149,
  87673,
  1397760,
  1421195,
  1382477,
  1389052,
  158475,
  1357636,
  1376815,
  1399697,
  147243,
  147241,
  1421192,
  1336445,
  1357399,
  1440330,
  1399191,
  1420867,
  99995,
  158767,
  143609,
  144290,
  96058,
  156143,
  53145,
  57686,
  1350877,
  69315,
  68014,
  1421758,
  1445457,
  1445708,
  84771,
  93650,
  144073,
  98009,
  98008,
  1365450,
  99538,
  70534,
  62746,
  78321,
  98010,
  98011,
  1381577,
  1381578,
  1332469,
  1391945,
  146959,
  1348618,
  54313,
  97394,
  1381305,
  61515,
  1398294,
  1413985,
  140157,
  144102,
  1438434,
  1403492,
  1363523,
  95002,
  157725,
  155305,
  1392031,
  75615,
  1413984,
  1403491,
  77009,
  1399447,
  1438380,
  1382768,
  65954,
  144647,
  1446103,
  64602,
  58410,
  1399943,
  146083,
  1446145,
  95889,
  56137,
  57886,
  63570,
  55265,
  1381079,
  48314,
  56983,
  144608,
  72404,
  63872,
  72403,
  152458,
  72905,
  72407,
  56282,
  143199,
  72408,
  1338650,
  1414704,
  1414703,
  1414702,
  1367089,
  71994,
  1340657,
  1363419,
  146875,
  71297,
  1395461,
  1395439,
  71990,
  1395437,
  1395436,
  1381497,
  71941,
  1340655,
  55722,
  1395434,
  1379316,
  72679,
  1420793,
  1420794,
  71938,
  153843,
  1395438,
  1395435,
  1353029,
  1334583,
  1379315,
  1389759,
  1353030,
  55721,
  1374277,
  1395460,
  71948,
  1353031,
  1336678,
  1359091,
  154060,
  1399594,
  1345894,
  142858,
  1350108,
  95799,
  1438920,
  92137,
  69212,
  1404380,
  1446440,
  153776,
  1409760,
  1381402,
  77188,
  1410116,
  1440532,
  1441655,
  105351,
  1415046,
  1394959,
  74250,
  1413034,
  1446055,
  47984,
  85023,
  80102,
  1370398,
  1359561,
  1388975,
  1407847,
  1392481,
  140486,
  1415414,
  99783,
  60522,
  1415413,
  60523,
  1383513,
  1406853,
  76342,
  1403346,
  1407823,
  1407824,
  1370756,
  140487,
  1381104,
  1393748,
  154324,
  1382246,
  101263,
  1436905,
  72822,
  1370755,
  54845,
  1437452,
  1380779,
  157236,
  1411634,
  70908,
  70830,
  1396389,
  97535,
  151880,
  1410689,
  1414310,
  70863,
  101305,
  1411633,
  1389670,
  1396388,
  1380807,
  79775,
  69709,
  1384274,
  1395596,
  144167,
  1376273,
  1359493,
  1355363,
  1393428,
  158067,
  1414065,
  1414066,
  144086,
  1411679,
  158966,
  153175,
  1384275,
  1367974,
  1359492,
  91463,
  1359071,
  1359169,
  1441184,
  1393427,
  157518,
  1359494,
  1367976,
  153174,
  1367975,
  158967,
  144085,
  1406449,
  1395595,
  1384273,
  1359495,
  1359491,
  1384271,
  1384272,
  69685,
  142997,
  1414763,
  1370134,
  95109,
  140072,
  71298,
  1403276,
  1394913,
  1438819,
  1411000,
  72738,
  61236,
  77345,
  1441864,
  140034,
  1444726,
  99245,
  71353,
  56827,
  1413949,
  1365008,
  1444972,
  1441076,
  140073,
  1444934,
  137842,
  72735,
  1401107,
  97146,
  94632,
  53609,
  76107,
  1437244,
  77307,
  70973,
  72736,
  138445,
  97145,
  93788,
  1378501,
  58191,
  1408749,
  1405716,
  59897,
  71968,
  97078,
  153248,
  1438058,
  1438059,
  147931,
  1438060,
  1437316,
  1438061,
  143248,
  144140,
  1438062,
  1438063,
  153251,
  1438064,
  142120,
  1436813,
  153250,
  1438065,
  144158,
  1438066,
  153249,
  144159,
  1446617,
  95718,
  1438067,
  147930,
  1438068,
  144161,
  1334766,
  1408480,
  1402279,
  87059,
  108785,
  49897,
  1443141,
  49472,
  80807,
  68604,
  1358801,
  63414,
  1381423,
  1438382,
  50601,
  1330206,
  90151,
  92790,
  1404561,
  1383406,
  67411,
  1382370,
  1395660,
  1379891,
  1402023,
  1401269,
  47718,
  1404332,
  1437097,
  85323,
  1363834,
  150496,
  151765,
  87371,
  61564,
  75982,
  65555,
  80969,
  68608,
  1368644,
  1390993,
  64900,
  139500,
  85223,
  85724,
  50945,
  85868,
  69295,
  83607,
  1437187,
  49542,
  1407031,
  47828,
  1339099,
  100806,
  139207,
  50562,
  152498,
  1407062,
  1401271,
  66840,
  63117,
  86324,
  151410,
  50620,
  1354253,
  1375564,
  63063,
  1421822,
  139698,
  1408533,
  1344253,
  1394050,
  152648,
  58839,
  66746,
  1406479,
  1437284,
  66960,
  1413611,
  49545,
  1420490,
  75468,
  1390437,
  1401830,
  85518,
  49161,
  1445200,
  1384145,
  1377231,
  140388,
  84294,
  73989,
  48956,
  69570,
  1362301,
  68675,
  1402080,
  1366566,
  1409181,
  1402079,
  1366567,
  1366568,
  1403712,
  1374792,
  1409183,
  1402293,
  1402291,
  1402292,
  1442454,
  75196,
  1379534,
  1446194,
  1446192,
  1446193,
  1352005,
  137827,
  1373427,
  98584,
  1407019,
  1407016,
  1445683,
  156307,
  139487,
  142906,
  1450034,
  73546,
  63391,
  78913,
  153538,
  1364731,
  139674,
  54338,
  59417,
  1446234,
  149831,
  146301,
  1409749,
  1362717,
  143286,
  85339,
  1335951,
  89776,
  89945,
  65624,
  138231,
  102374,
  53115,
  144001,
  98063,
  1399351,
  99680,
  1441947,
  1399205,
  157171,
  81944,
  1344066,
  81858,
  81695,
  1344429,
  1361266,
  81807,
  64762,
  99424,
  1445894,
  1412875,
  66362,
  58194,
  81114,
  98095,
  98096,
  54700,
  60442,
  78057,
  1421876,
  1405926,
  62824,
  1415396,
  1383717,
  140272,
  61294,
  1387788,
  1420386,
  139819,
  96056,
  146737,
  1383719,
  139143,
  1401573,
  1374917,
  75752,
  1353188,
  1415264,
  75708,
  73151,
  101919,
  102112,
  68570,
  79597,
  141463,
  58185,
  137847,
  1378887,
  100028,
  141061,
  152891,
  1375882,
  148306,
  71310,
  146963,
  55790,
  1378833,
  138933,
  99921,
  55979,
  98554,
  101416,
  54776,
  101653,
  98430,
  71316,
  1353247,
  71862,
  98673,
  149279,
  1343670,
  71441,
  93835,
  1371030,
  58907,
  60277,
  1345117,
  157425,
  157995,
  157997,
  1444811,
  95939,
  1382091,
  86219,
  1366643,
  1446640,
  1399373,
  1392170,
  66259,
  66245,
  85125,
  1335317,
  1441149,
  160918,
  61093,
  61094,
  54258,
  1414741,
  72719,
  149068,
  151571,
  1346801,
  147696,
  1383276,
  61689,
  139746,
  140412,
  1358395,
  154703,
  146125,
  150365,
  140951,
  1362318,
  57499,
  1369864,
  59376,
  1366262,
  49601,
  1365975,
  161265,
  98015,
  1354419,
  85351,
  72472,
  1361578,
  1339948,
  1330503,
  1444913,
  49595,
  49706,
  60524,
  1403757,
  1388081,
  1374903,
  137790,
  143326,
  78808,
  79135,
  72718,
  139820,
  100577,
  50117,
  49891,
  104757,
  1403729,
  66396,
  48183,
  1382643,
  152774,
  1366406,
  79612,
  54256,
  66920,
  85804,
  76205,
  1369870,
  99532,
  1436759,
  1381112,
  99918,
  49871,
  50027,
  49790,
  49773,
  80463,
  1398125,
  149746,
  1366349,
  1354040,
  160485,
  1414684,
  1364064,
  1385905,
  1392176,
  71358,
  56629,
  102113,
  140800,
  1400546,
  1369947,
  1444937,
  1385049,
  1404633,
  1441807,
  1362884,
  60853,
  1358932,
  1408065,
  95382,
  82890,
  1394393,
  1394395,
  1394392,
  1394396,
  1407711,
  52856,
  1413907,
  93293,
  141842,
  1346570,
  59751,
  98130,
  95381,
  1442418,
  71636,
  1442041,
  72153,
  98056,
  139677,
  71552,
  58796,
  58800,
  1445847,
  149833,
  1446474,
  1441675,
  147735,
  1445147,
  72037,
  92181,
  1374929,
  140614,
  1364640,
  1371090,
  1401710,
  1379283,
  1356662,
  1421351,
  1420361,
  1361214,
  1413482,
  1379696,
  141022,
  1421355,
  1421353,
  1361215,
  1415308,
  1380308,
  1380307,
  1385845,
  102228,
  1444916,
  1374468,
  81107,
  1437261,
  1384254,
  1375859,
  1378971,
  1381589,
  1396927,
  1406184,
  82546,
  62135,
  85145,
  1375572,
  90769,
  1350620,
  66221,
  1361296,
  1414570,
  85053,
  91316,
  53322,
  1335165,
  66611,
  1350651,
  141455,
  1357375,
  84073,
  1414569,
  1369041,
  154255,
  83876,
  83977,
  152802,
  157138,
  1421047,
  76160,
  1407477,
  82649,
  1407369,
  1445140,
  1420815,
  1361915,
  143855,
  140592,
  82814,
  101644,
  98697,
  97714,
  80953,
  64364,
  97715,
  64147,
  79274,
  63929,
  1354143,
  133377,
  71226,
  1402451,
  99691,
  146471,
  1390383,
  1404731,
  1330479,
  1404733,
  1413969,
  1365269,
  1358669,
  1404732,
  1414655,
  51092,
  1393696,
  1381928,
  1354398,
  1347332,
  1354056,
  153468,
  1441801,
  1445844,
  1445845,
  148096,
  73972,
  1357642,
  74496,
  53235,
  1436837,
  1387051,
  141245,
  101886,
  101524,
  1440434,
  96359,
  97381,
  1420391,
  1394869,
  1441565,
  102169,
  1371074,
  1437119,
  1376996,
  54621,
  56160,
  1362275,
  99723,
  1369051,
  65704,
  154059,
  56613,
  84691,
  57455,
  102475,
  142789,
  1338122,
  97840,
  1380977,
  1402178,
  137613,
  72405,
  1362274,
  1357916,
  146691,
  142795,
  72480,
  151112,
  88607,
  1357368,
  1412969,
  140291,
  141314,
  151977,
  1441115,
  1384562,
  1398699,
  1420592,
  1377444,
  1441305,
  1437998,
  1407631,
  1369697,
  141289,
  138943,
  1360541,
  1369589,
  1441309,
  1331759,
  72470,
  1358370,
  1369588,
  1436957,
  1346379,
  1441221,
  1384020,
  1370191,
  102138,
  140761,
  1392199,
  1400682,
  1421326,
  1401472,
  1420593,
  1368456,
  58610,
  1437870,
  1368002,
  144148,
  1441250,
  1377441,
  1441304,
  1421324,
  1408758,
  1375940,
  1392198,
  1420240,
  1378759,
  1398990,
  78416,
  70021,
  75419,
  84826,
  83862,
  1386168,
  1445582,
  1444742,
  1445052,
  1403653,
  147812,
  1441572,
  1441566,
  139876,
  98750,
  1441569,
  1414709,
  1441568,
  1413496,
  148806,
  1383865,
  1445169,
  1438235,
  1394341,
  1412404,
  143605,
  66243,
  65673,
  1449919,
  151692,
  1373903,
  1358140,
  1386596,
  1398387,
  72493,
  1441128,
  1396712,
  1375257,
  1365785,
  72665,
  72656,
  1361083,
  101748,
  55338,
  71304,
  71658,
  55280,
  53497,
  54900,
  97561,
  54672,
  101599,
  56928,
  55821,
  1358194,
  53461,
  95222,
  55381,
  56575,
  54901,
  55369,
  149053,
  59496,
  1357439,
  143579,
  56198,
  55614,
  57776,
  49070,
  47849,
  141844,
  55577,
  1368284,
  59885,
  60278,
  49446,
  152171,
  59954,
  47999,
  98658,
  55999,
  96941,
  55840,
  139345,
  55720,
  1399350,
  56402,
  140664,
  49080,
  48908,
  59949,
  1358193,
  147825,
  1382565,
  141846,
  64161,
  1342082,
  53053,
  49168,
  94051,
  1367255,
  50103,
  57972,
  139000,
  80769,
  102533,
  1389848,
  138345,
  1400709,
  155227,
  59263,
  57304,
  54222,
  1386421,
  72703,
  1333898,
  77823,
  99731,
  73976,
  72687,
  1441880,
  96821,
  72685,
  1353458,
  72688,
  156624,
  77906,
  1365867,
  97995,
  1386609,
  1393082,
  1404632,
  68828,
  1421452,
  67864,
  139496,
  1446694,
  1445361,
  65960,
  1400743,
  1331636,
  139446,
  89698,
  1364906,
  1350509,
  1364905,
  99247,
  1446273,
  138991,
  138992,
  144622,
  48309,
  1390782,
  62121,
  139485,
  95863,
  91904,
  83204,
  141451,
  139481,
  139480,
  90864,
  91658,
  1421266,
  91239,
  100619,
  96035,
  96036,
  96263,
  139752,
  1401198,
  96037,
  63351,
  1436783,
  1361518,
  1358125,
  1446132,
  1358124,
  1371559,
  1446129,
  1446131,
  1446130,
  144061,
  86704,
  101897,
  150426,
  1390879,
  1369544,
  66810,
  1410999,
  1387699,
  1439266,
  64321,
  1375755,
  81098,
  1414193,
  1363546,
  1406719,
  1390403,
  1400385,
  1351819,
  1414663,
  1414662,
  1415077,
  1415076,
  1438975,
  1399869,
  100526,
  1385231,
  81173,
  1389906,
  1414661,
  1396409,
  1391898,
  64608,
  72866,
  1409975,
  1393029,
  1403149,
  1350609,
  80780,
  81415,
  1437923,
  96810,
  94407,
  94527,
  71048,
  137867,
  55185,
  1436797,
  144227,
  54317,
  55016,
  56303,
  71325,
  1438185,
  1445705,
  1449642,
  1357076,
  1332735,
  1332897,
  1353947,
  1353948,
  1359622,
  1445765,
  98647,
  1401831,
  100545,
  1364785,
  87574,
  91728,
  1377035,
  1421759,
  156819,
  1401454,
  1346194,
  1440939,
  1359779,
  69602,
  1408325,
  59946,
  71267,
  96662,
  59430,
  98106,
  52796,
  59759,
  71029,
  95011,
  89766,
  98105,
  52697,
  53553,
  95013,
  97178,
  97217,
  1420714,
  98107,
  97218,
  59675,
  71695,
  56497,
  94255,
  96843,
  95868,
  96842,
  97216,
  52749,
  149839,
  59510,
  138389,
  96649,
  144832,
  144833,
  58395,
  58221,
  52016,
  1362334,
  1446089,
  102191,
  87181,
  96826,
  72603,
  61707,
  1415079,
  75290,
  148131,
  1449747,
  1369212,
  1361552,
  1383548,
  1383549,
  158643,
  1360352,
  1360353,
  1332967,
  78170,
  74401,
  142915,
  62110,
  62193,
  62064,
  1377236,
  140870,
  1400146,
  148781,
  141681,
  87309,
  99616,
  1445322,
  148953,
  1440950,
  1369043,
  1373224,
  94413,
  1387345,
  66821,
  1335622,
  59665,
  1361387,
  60104,
  59703,
  138767,
  146623,
  60024,
  1438057,
  60217,
  71532,
  60234,
  59758,
  59770,
  147677,
  147676,
  1440945,
  1442453,
  97903,
  97902,
  150853,
  150961,
  66744,
  86052,
  1438201,
  1446123,
  96964,
  95092,
  75945,
  75909,
  95094,
  61408,
  75549,
  79163,
  97944,
  79303,
  79772,
  96963,
  96965,
  95093,
  61340,
  96966,
  95095,
  1337932,
  72592,
  1446475,
  1364884,
  1364947,
  57024,
  1403088,
  146194,
  60316,
  60320,
  138815,
  101450,
  144896,
  102034,
  71956,
  60304,
  101192,
  101451,
  101193,
  142918,
  60044,
  72300,
  60008,
  1329929,
  152690,
  79351,
  139375,
  52205,
  101709,
  1403853,
  1404314,
  1414496,
  1400323,
  1413353,
  1413352,
  1413356,
  1414168,
  1411937,
  1405481,
  1406949,
  1413355,
  1411938,
  1390502,
  1437793,
  77484,
  1445541,
  63820,
  142569,
  1404556,
  156208,
  1371517,
  1399122,
  79815,
  64032,
  1371516,
  1444393,
  1410070,
  160709,
  1340327,
  143634,
  143633,
  80026,
  1348745,
  93752,
  157845,
  80782,
  1369577,
  1373819,
  1347777,
  1444564,
  102396,
  86445,
  146136,
  1412536,
  1413470,
  144380,
  1436955,
  53724,
  58587,
  1413373,
  1340741,
  86152,
  1347552,
  152062,
  50289,
  89479,
  1354364,
  1402875,
  160077,
  80115,
  1367084,
  1368333,
  139550,
  1378280,
  1359027,
  1420306,
  78316,
  146980,
  1444769,
  1390467,
  154752,
  1383128,
  70275,
  92885,
  1380304,
  155257,
  73102,
  1407046,
  142022,
  1396889,
  142540,
  55601,
  59990,
  60354,
  139055,
  139989,
  76388,
  1412352,
  1375875,
  53238,
  95940,
  1389410,
  141060,
  59106,
  1420997,
  1369702,
  157843,
  1408481,
  1391600,
  60149,
  1449686,
  1445625,
  1366217,
  1413243,
  78305,
  62722,
  59139,
  53380,
  79360,
  139926,
  89800,
  96444,
  1360515,
  1374145,
  83880,
  1369418,
  1400390,
  158399,
  1401860,
  52209,
  140468,
  143212,
  78777,
  67855,
  65604,
  1412161,
  75359,
  140828,
  1401861,
  91608,
  81249,
  1401859,
  69805,
  1357516,
  78746,
  1400068,
  78709,
  83939,
  98866,
  1344015,
  1393625,
  72911,
  140470,
  1355643,
  1412200,
  140472,
  66097,
  66096,
  1389327,
  79075,
  93600,
  60366,
  52082,
  56204,
  56096,
  1393626,
  66119,
  1375387,
  52151,
  141362,
  1359333,
  94147,
  151590,
  143209,
  143210,
  50799,
  71675,
  73945,
  139488,
  1389328,
  96934,
  96935,
  140473,
  65290,
  72708,
  96937,
  51201,
  143211,
  140471,
  96165,
  137840,
  69006,
  90258,
  154487,
  102380,
  146036,
  84908,
  49088,
  1393628,
  72726,
  49071,
  93242,
  139704,
  1368477,
  143520,
  99865,
  151784,
  143208,
  1382365,
  1442022,
  140467,
  83944,
  1389329,
  94885,
  1360231,
  151783,
  145114,
  53223,
  84350,
  139927,
  97915,
  101747,
  72274,
  78908,
  140476,
  145118,
  95745,
  140475,
  56272,
  139184,
  143689,
  69784,
  1389331,
  78761,
  69837,
  72895,
  69671,
  90264,
  68998,
  90242,
  1389330,
  1442023,
  151595,
  144320,
  140469,
  140474,
  62297,
  140104,
  1379733,
  158317,
  95637,
  1369103,
  68752,
  139188,
  139187,
  95635,
  95636,
  89735,
  89723,
  155358,
  142660,
  100158,
  1379734,
  68744,
  152119,
  100157,
  100156,
  89697,
  146537,
  146538,
  89677,
  1370324,
  48315,
  139281,
  101364,
  99932,
  95051,
  98756,
  98740,
  81818,
  47580,
  1344444,
  92171,
  92150,
  49980,
  98761,
  98741,
  48145,
  1344696,
  92218,
  98739,
  72574,
  98763,
  64734,
  139282,
  149803,
  1384216,
  77391,
  1358901,
  138276,
  1339507,
  50150,
  49968,
  49391,
  47735,
  140358,
  49922,
  49092,
  49528,
  49615,
  49596,
  1386014,
  142506,
  48062,
  49094,
  142503,
  1343409,
  49044,
  1413992,
  148224,
  96065,
  1375310,
  159484,
  1411074,
  56031,
  1439229,
  82789,
  1411075,
  64507,
  64751,
  81362,
  81161,
  1386957,
  1392907,
  1336915,
  93100,
  93099,
  90900,
  97493,
  153178,
  153177,
  1406489,
  1380394,
  96430,
  66662,
  1410656,
  1421769,
  138017,
  102412,
  1382230,
  1391705,
  1410631,
  98455,
  98060,
  67031,
  1410658,
  1410632,
  1379607,
  86600,
  1382231,
  1444953,
  1421770,
  1410660,
  1359843,
  143970,
  86243,
  1367456,
  1391706,
  143683,
  1391704,
  1393367,
  1361452,
  65427,
  1415368,
  1438465,
  1394777,
  1443243,
  151824,
  1414416,
  1335347,
  1421185,
  1401567,
  1354215,
  1440428,
  1353631,
  1364645,
  1444651,
  1411342,
  1441320,
  1437451,
  1391707,
  1437450,
  1440502,
  68545,
  1369928,
  1378956,
  143428,
  155766,
  151916,
  1445382,
  1421893,
  1415075,
  1441937,
  50409,
  50405,
  1369210,
  78527,
  1363595,
  1400065,
  1364641,
  1440455,
  1366301,
  151088,
  1441652,
  1398817,
  1396924,
  151087,
  148203,
  146440,
  1444169,
  1412033,
  1437278,
  1393853,
  1449680,
  140460,
  50374,
  93052,
  58967,
  1444204,
  75111,
  1446780,
  1373678,
  1445609,
  70029,
  1445026,
  61612,
  85005,
  1404508,
  75955,
  1356527,
  1339216,
  1404507,
  85171,
  1388474,
  75596,
  1398459,
  1404506,
  1387421,
  61549,
  1400624,
  1356526,
  1391494,
  96351,
  1444524,
  1437314,
  1372680,
  1446180,
  75818,
  79004,
  73025,
  96672,
  49985,
  49974,
  1437502,
  150295,
  103265,
  1444546,
  1444548,
  1414220,
  49941,
  1439253,
  1414218,
  1414222,
  1436425,
  1351890,
  1413684,
  1414221,
  1382409,
  49698,
  1437044,
  140135,
  1438341,
  1437191,
  1399670,
  1445289,
  1414219,
  49263,
  101626,
  47745,
  1421936,
  69115,
  95355,
  1421058,
  144129,
  1349073,
  82217,
  75664,
  138555,
  158750,
  1414710,
  1445912,
  1373273,
  1401832,
  101125,
  71286,
  1446410,
  1445884,
  1344521,
  50903,
  147532,
  140845,
  1446708,
  161093,
  147336,
  1366764,
  1402915,
  139901,
  1446404,
  1402923,
  98696,
  55098,
  1367108,
  142003,
  1403092,
  154951,
  1409497,
  1445022,
  1445023,
  1445024,
  1445025,
  1382000,
  1370371,
  1373957,
  59931,
  1361294,
  55126,
  1365094,
  91917,
  142413,
  145001,
  1368655,
  1404983,
  1369828,
  52258,
  1437115,
  57391,
  142486,
  1365100,
  1373959,
  59148,
  1379918,
  64599,
  1437225,
  52249,
  58162,
  1369827,
  81847,
  52127,
  92324,
  1379721,
  96442,
  1445003,
  1335754,
  1371564,
  1389325,
  1393456,
  1393611,
  72867,
  1393610,
  140728,
  149587,
  1413532,
  1414375,
  59542,
  1415204,
  1408147,
  54713,
  94471,
  49513,
  79583,
  143696,
  1439091,
  93050,
  1388230,
  143578,
  96150,
  1420323,
  1373319,
  1445058,
  76186,
  76186,
  1395901,
  1391956,
  98990,
  1368706,
  51566,
  101664,
  86213,
  101666,
  66713,
  1404439,
  1329840,
  85943,
  1413582,
  101665,
  86283,
  1403322,
  1392592,
  57134,
  1349921,
  1349920,
  1449667,
  1449668,
  1364665,
  59255,
  144100,
  1439171,
  1436789,
  81666,
  1440348,
  1390625,
  1375688,
  60692,
  1410321,
  154968,
  100429,
  91039,
  50434,
  101929,
  50629,
  1421378,
  1389736,
  84526,
  68510,
  95379,
  54403,
  88263,
  89206,
  97091,
  1363797,
  59898,
  50120,
  55132,
  53493,
  99079,
  53499,
  89348,
  53437,
  71647,
  49532,
  1371558,
  68126,
  53752,
  150919,
  49523,
  49533,
  74351,
  1411525,
  138570,
  1449796,
  53479,
  63121,
  84295,
  1353717,
  101692,
  89661,
  1359480,
  1437860,
  1441155,
  1400132,
  1344668,
  1388393,
  74745,
  1362630,
  1398342,
  84387,
  150933,
  75183,
  1390939,
  49741,
  142035,
  70439,
  99548,
  1364502,
  1350176,
  1359992,
  144293,
  77464,
  89746,
  100008,
  1440533,
  1359721,
  68494,
  1440891,
  1379482,
  1371540,
  1344382,
  60232,
  95977,
  59721,
  149541,
  1400274,
  93427,
  65443,
  1358454,
  67002,
  1406448,
  1382976,
  1363803,
  1368985,
  1363806,
  139344,
  1382975,
  1444942,
  67960,
  49119,
  1344201,
  95108,
  1385762,
  1383965,
  1414765,
  1407398,
  64778,
  1385763,
  80332,
  1398454,
  1391801,
  1397288,
  1391800,
  1378659,
  59640,
  84963,
  59554,
  146760,
  84178,
  86053,
  91013,
  65906,
  157892,
  51780,
  1446717,
  70699,
  1365227,
  93520,
  1365228,
  1391752,
  1414717,
  1441314,
  1445570,
  1414521,
  1401053,
  1444576,
  1401052,
  1366820,
  1414164,
  1397551,
  1438578,
  1361455,
  1375998,
  1348240,
  1436650,
  1391780,
  1378207,
  1390964,
  1409140,
  1383832,
  1415292,
  1415190,
  150642,
  1404438,
  1441681,
  1441680,
  1443642,
  1409178,
  1441694,
  150495,
  1397101,
  1437058,
  160297,
  91172,
  69573,
  70464,
  99256,
  86869,
  86956,
  89858,
  69561,
  142766,
  91004,
  141256,
  1442011,
  91291,
  91010,
  91132,
  69491,
  1439146,
  142852,
  1345114,
  1399153,
  1360779,
  1375818,
  75282,
  95971,
  1413398,
  86506,
  85030,
  66184,
  138790,
  1421403,
  1383947,
  61873,
  1445138,
  61895,
  1391268,
  1370219,
  87652,
  70448,
  95041,
  1356292,
  98478,
  50093,
  1351853,
  1397232,
  1401335,
  1365914,
  1379466,
  1359437,
  1379467,
  1392033,
  1387304,
  1357359,
  1407097,
  1445036,
  1377574,
  1407096,
  1445378,
  146903,
  1436652,
  1395933,
  50638,
  1389200,
  150247,
  1441438,
  50870,
  1385759,
  48010,
  1393300,
  1441074,
  47985,
  50817,
  1421248,
  1380286,
  1372292,
  1373372,
  1420466,
  1446544,
  1408269,
  1393128,
  78822,
  63221,
  56417,
  97594,
  1446085,
  1370581,
  1415297,
  1421799,
  1421400,
  1441158,
  1441160,
  1441187,
  1441159,
  1413962,
  1363395,
  70158,
  1363496,
  1438643,
  1421304,
  1395229,
  1443161,
  1441755,
  1441383,
  1445448,
  1445450,
  1445449,
  1445447,
  1441754,
  59905,
  1440885,
  1441793,
  1355490,
  1365410,
  1331078,
  1414830,
  1405528,
  65100,
  160143,
  1383100,
  1405527,
  1421605,
  1388028,
  1357656,
  94244,
  67969,
  139888,
  60991,
  1340102,
  156616,
  51411,
  159898,
  81347,
  51654,
  93187,
  93198,
  1391001,
  1415074,
  1375674,
  85950,
  139459,
  1413953,
  86101,
  1400853,
  140008,
  102286,
  66613,
  86043,
  1421242,
  96117,
  140229,
  67397,
  87581,
  67580,
  71800,
  1420395,
  1436499,
  60623,
  77352,
  1412376,
  1437480,
  1445944,
  54566,
  56118,
  54791,
  1414641,
  140239,
  139777,
  139779,
  71438,
  95659,
  71253,
  139782,
  139780,
  57849,
  102005,
  139781,
  139778,
  1381768,
  62969,
  159669,
  159668,
  78920,
  62925,
  1406169,
  51414,
  1446309,
  1446559,
  1403854,
  1408038,
  49340,
  1356147,
  49870,
  47858,
  49874,
  1330028,
  142027,
  59453,
  1405162,
  142436,
  1336718,
  60080,
  1411870,
  96375,
  1403493,
  73550,
  55255,
  60075,
  1420884,
  145108,
  97937,
  143873,
  98048,
  1373698,
  139576,
  72863,
  85260,
  98464,
  138884,
  139283,
  100147,
  69973,
  92119,
  140399,
  1387099,
  1389409,
  1375958,
  1359807,
  1331218,
  1445184,
  1445185,
  1336970,
  1381354,
  144853,
  1436991,
  52467,
  1436994,
  1420582,
  1441856,
  1385338,
  1360181,
  95359,
  1415187,
  153104,
  158488,
  81285,
  67699,
  87990,
  67536,
  87512,
  87549,
  67355,
  143992,
  1415302,
  61272,
  98701,
  72946,
  140488,
  71988,
  97258,
  81257,
  1444527,
  1391977,
  1368314,
  85863,
  91716,
  51259,
  1361766,
  66589,
  86196,
  86087,
  100273,
  93880,
  138584,
  56546,
  102205,
  65693,
  65915,
  62056,
  62045,
  1373363,
  1375158,
  1378495,
  1351516,
  85131,
  82676,
  96961,
  83202,
  1355077,
  1352321,
  79769,
  84209,
  1436561,
  81984,
  1446515,
  1450270,
  86825,
  1421289,
  1350425,
  1350424,
  1330532,
  1421291,
  1330718,
  87832,
  67560,
  74855,
];

export const isCtAppEnabled = (communityId) => {
  const foundCommunity = ctAppCommunities.find((id) => id === communityId);
  return foundCommunity !== undefined;
};
