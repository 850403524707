import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  main_container: {},
  center_container: {
    padding: '0 200px',
  },
  body: {
    backgroundColor: theme.palette.light.platinum3,
    marginTop: theme.spacing(9),
  },

  title: {
    fontSize: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(3),
  },
  subtitle: {
    fontSize: '15px',
    paddingLeft: '32px',
    marginTop: '8px',
    marginBottom: '38px',
  },
  button_group: {
    padding: '62px 0',
    display: 'flex',
    alingItems: 'center',
    justifyContent: 'center',
  },
  outllined_button: {
    marginRight: '8px',
  },
  dashboard_container: {
    display: 'flex',
    flexDirection: 'row',
    order: 1,
    justifyContent: 'left',
    marginLeft: theme.spacing(4),
    paddingTop: theme.spacing(4),
    marginRight: theme.spacing(4),
  },

  dashboardText: {
    paddingLeft: theme.spacing(1),
  },
  manualInquiryText: {
    textDecorationLine: 'underline',
  },
}));

export { useStyles };
