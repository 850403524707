import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './LeftPanel.style';
import ResidentInformation from './ResidentInformation/ResidentInformation';
import Contacts from './Contacts/Contacts';
import ProfessionalSource from './ProfessionalSource/ProfessionalSource';
import useGraphQLQuery from 'hooks/useGraphQLQuery';
import leftPanelQuery from './LeftPanelQuery';
import Loading from 'components/Loading/Loading';
import { toContacts } from './contactsService';
import toBasicInformation from 'components/CaseProfileSidePanel/basicInformationService';
import YglLink from 'components/YglExternalId/YglLink';
import { useExternalId } from 'hooks/useExternalId';
import DocumentHead from 'common/DocumentHead/DocumentHead';

const LeftPanel = ({ labels, familyFileId }) => {
  const { externalId, fetchError } = useExternalId(familyFileId);

  const classes = useStyles();
  const [shouldRefetch, setShouldRefetch] = useState(false);

  useEffect(() => {
    if (shouldRefetch === true) {
      refetch({ familyFileId });
      setShouldRefetch(false);
    }
    // eslint-disable-next-line
  }, [shouldRefetch]);

  const { loading, error, data, refetch } = useGraphQLQuery(leftPanelQuery, {
    variables: { familyFileId },
  });

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  const handleRefetchState = (needToRefetch) => {
    setShouldRefetch(needToRefetch);
  };

  const basicInformation = toBasicInformation(data.findFamilyFileById);

  const contacts = toContacts(
    data.findFamilyFileById.contacts,
    data.findFamilyFileById.residents,
    data.findFamilyFileById.leadData,
    familyFileId,
  );

  const getDocumentTitle = () => {
    const primaryContact = data.findFamilyFileById.contacts.find(
      (contact) => !!contact.profileData.isPrimary,
    );
    return primaryContact
      ? `${primaryContact.profileData.firstName}
    ${primaryContact.profileData.lastName}: ${labels.FAMILY_FILE}`
      : labels.UNKNOWN;
  };

  const professionalSourceExists = () => {
    const exists =
      basicInformation.professionalSourceContactId > 0 ||
      basicInformation.professionalSourceOrganizationName?.length > 0;
    return exists;
  };

  return (
    <div className={classes.left_panel_container}>
      <DocumentHead title={getDocumentTitle()} />
      <Contacts
        labels={labels}
        contact={contacts}
        handleRefetchState={handleRefetchState}
        familyFileId={familyFileId}
      />
      <ResidentInformation
        basicInformation={basicInformation}
        labels={labels}
        handleRefetchState={handleRefetchState}
      />

      <ProfessionalSource
        familyFileId={familyFileId}
        labels={labels}
        professionalSourceExists={professionalSourceExists()}
      />
      <div className={classes.workinglist_link_container}>
        <YglLink
          url="YGL_WORKING_LIST_PAGE_URL"
          externalId={externalId}
          hasError={fetchError}
          type="button"
          target={'self'}
        >
          {labels.GO_TO_WORKING_LIST}
        </YglLink>
      </div>
    </div>
  );
};

LeftPanel.propTypes = {
  labels: PropTypes.shape({
    AGE: PropTypes.string.isRequired,
    EXPECTED_MOVE_IN_DATE: PropTypes.string.isRequired,
    FAMILY_FILE: PropTypes.string.isRequired,
    GO_TO_WORKING_LIST: PropTypes.string.isRequired,
    PREFERRED_LOCATIONS: PropTypes.string.isRequired,
    PRIMARY_CONTACT: PropTypes.string.isRequired,
    UNKNOWN: PropTypes.string.isRequired,
    YEARS_OLD: PropTypes.string.isRequired,
  }).isRequired,
  familyFileId: PropTypes.number.isRequired,
};

LeftPanel.defaultProps = {};

export default LeftPanel;
