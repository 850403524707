import { makeStyles } from '../../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    custom_select: {
      position: 'relative',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: '18px',
    },
    select: {
      border: `1px solid ${theme.palette.light.platinum1}`,
      backgroundColor: theme.palette.light.white,
      boxSizing: 'border-box',
      borderRadius: '2px',
      padding: '8px 16px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      lineHeight: '22px',
      color: theme.palette.dark.eerieBlack5,
      '&:focus': {
        border: `1px solid ${theme.palette.neutral.cosmicCobalt3}`,
        outline: 'none',
      },
      '&:hover': {
        border: `1px solid ${theme.palette.neutral.cosmicCobalt3}`,
        outline: 'none',
      },
      whiteSpace: 'nowrap',
    },
    filled: {
      '& $select': {
        border: `1px solid ${theme.palette.neutral.cosmicCobalt2}`,
      },
      '& $label': {
        color: theme.palette.dark.eerieBlack1,
      },
    },
    icon: {
      paddingLeft: '10px',
      '& svg': {
        verticalAlign: 'middle',
      },
    },
    menu: {
      position: 'absolute',
      backgroundColor: theme.palette.light.white,
      width: '100%',
    },
    checkbox: {
      width: '100%',
      paddingLeft: '9px',
      margin: 0,
      boxSizing: 'border-box',
      '&:hover': {
        backgroundColor: theme.palette.neutral.dropdown,
      },
    },
    items_container: {
      width: '300px',
      position: 'absolute',
      top: 0,
      padding: '10px 0',
      left: 0,
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.neutral.cosmicCobalt5}`,
      backgroundColor: theme.palette.light.white,
      textAlign: 'left',
      lineHeight: '22px',
    },
    selected: {
      backgroundColor: theme.palette.neutral.dropdown,
    },
    label: {
      fontSize: '13px',
      lineHeight: '19px',
      color: theme.palette.dark.eerieBlack5,
      marginBottom: '4px',
      textAlign: 'left',
    },
    divider: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    actions_container: {
      paddingRight: theme.spacing(2),
    },
    actions: {
      textAlign: 'right',
      '& button': {
        marginLeft: theme.spacing(2),
      },
    },
  };
});

export { useStyles };
