import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PrivateRouteWithLayout from './components/PrivateRouteWithLayout/PrivateRouteWithLayout';
import EmptyLayout from './pages/EmptyLayout/EmptyLayout';
import ScriptBuilder from './pages/ScriptBuilder/ScriptBuilder';
import { getAdminCatalogsRequest } from './stateManagement/admin/catalogs/actions/adminCatalogsActions';
import { Switch } from 'react-router-dom';
import roles from './config/roles';
import PropTypes from 'prop-types';
import ScriptPreview from './pages/ScriptBuilder/ScriptPreview';
import { getCatalogsRequest } from './stateManagement/callcenter/catalogs/actions/catalogsActions';

const AdminRoutesWithRedux = ({ match }) => {
  const scriptBuilderScreensAllowedRoles = [
    roles.CALLCENTER,
    roles.MANAGER,
    roles.PARTNERDEV,
    roles.TRAINER,
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdminCatalogsRequest());
    dispatch(getCatalogsRequest());
  }, [dispatch]);
  return (
    <Switch>
      <PrivateRouteWithLayout
        path={`${match.url}/script-builder`}
        component={ScriptBuilder}
        layout={EmptyLayout}
        allowedRoles={scriptBuilderScreensAllowedRoles}
      />
      <PrivateRouteWithLayout
        path={`${match.url}/script-preview/:slug`}
        component={ScriptPreview}
        layout={EmptyLayout}
        allowedRoles={scriptBuilderScreensAllowedRoles}
      />
      <PrivateRouteWithLayout
        path={`${match.url}/script-preview`}
        component={ScriptPreview}
        layout={EmptyLayout}
        allowedRoles={scriptBuilderScreensAllowedRoles}
      />
    </Switch>
  );
};

AdminRoutesWithRedux.propTypes = {
  match: PropTypes.object,
};
export default AdminRoutesWithRedux;
