import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from 'common/Card/Card';
import EditCard from 'common/Card/EditCard/EditCard';
import CallPhone from 'common/Icons/call/CallPhone';
import { useStyles } from './Contacts.style';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import clsx from 'clsx';

import ContactsXLDisplay from './ContactsXLDisplay';

const ContactsXL = ({
  labels,
  familyFileId,
  contact,
  onEditMode,
  flags,
  dialerService,
}) => {
  let familyFileInfo;
  let primaryContact;
  let secondaryContact;
  let additionalContact;
  if (contact) {
    familyFileInfo = {
      familyFileId: familyFileId,
      careTypes: contact.careTypes,
      doNotCall: contact.doNotCall,
    };
    primaryContact = contact.primaryContact;
    secondaryContact = contact.secondaryContact;
    additionalContact = contact.additionalContact;
  }

  const classes = useStyles();

  const ContactsInfo = () => {
    return (
      <Fragment>
        {primaryContact && (
          <ContactsXLDisplay
            labels={labels}
            familyFileInfo={familyFileInfo}
            contact={primaryContact}
            title={labels.PRIMARY_CONTACT}
            dialerService={dialerService}
          />
        )}
        {secondaryContact && (
          <ContactsXLDisplay
            labels={labels}
            familyFileInfo={familyFileInfo}
            contact={secondaryContact}
            title={labels.SECONDARY_CONTACT}
            dialerService={dialerService}
          />
        )}

        {additionalContact && (
          <ContactsXLDisplay
            labels={labels}
            familyFileInfo={familyFileInfo}
            contact={additionalContact}
            title={labels.ADDITIONAL_CONTACT}
            dialerService={dialerService}
          />
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {flags.updateContactsOwl && (
        <EditCard
          headerColor="cosmicCobalt2"
          editButtonColor="cosmicCobalt3"
          title={labels.CONTACTS}
          icon={<CallPhone />}
          className={clsx(classes.contacts, classes.card)}
          clickCallback={() => onEditMode(true)}
          flags={flags}
        >
          <ContactsInfo />
        </EditCard>
      )}
      {!flags.updateContactsOwl && (
        <Card
          className={classes.contacts}
          headerColor="cosmicCobalt2"
          title={labels.CONTACTS}
          icon={<CallPhone />}
        >
          <ContactsInfo />
        </Card>
      )}
    </Fragment>
  );
};

ContactsXL.propTypes = {
  labels: PropTypes.object.isRequired,
  familyFileId: PropTypes.number,
  contact: PropTypes.shape({
    careTypes: PropTypes.arrayOf(PropTypes.object),
    doNotCall: PropTypes.bool,
    primaryContact: PropTypes.shape({
      name: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      relationship: PropTypes.string,
      address1: PropTypes.string,
      address2: PropTypes.string,
      allowContact: PropTypes.bool,
      isInquirer: PropTypes.bool,
      city: PropTypes.string,
      postalCode: PropTypes.string,
      state: PropTypes.string,
      contactDetail: PropTypes.shape({
        phonePrimary: PropTypes.string,
        phoneSecondary: PropTypes.string,
        email: PropTypes.string,
        primaryPhoneType: PropTypes.string,
        secondaryPhoneType: PropTypes.string,
      }),
    }),
    secondaryContact: PropTypes.shape({
      name: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      relationship: PropTypes.string,
      address1: PropTypes.string,
      address2: PropTypes.string,
      allowContact: PropTypes.bool,
      isInquirer: PropTypes.bool,
      city: PropTypes.string,
      postalCode: PropTypes.string,
      state: PropTypes.string,
      contactDetail: PropTypes.shape({
        phonePrimary: PropTypes.string,
        phoneSecondary: PropTypes.string,
        email: PropTypes.string,
        primaryPhoneType: PropTypes.string,
        secondaryPhoneType: PropTypes.string,
      }),
    }),
    additionalContact: PropTypes.shape({
      name: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      relationship: PropTypes.string,
      address1: PropTypes.string,
      address2: PropTypes.string,
      allowContact: PropTypes.bool,
      isInquirer: PropTypes.bool,
      city: PropTypes.string,
      postalCode: PropTypes.string,
      state: PropTypes.string,
      contactDetail: PropTypes.shape({
        phonePrimary: PropTypes.string,
        phoneSecondary: PropTypes.string,
        email: PropTypes.string,
        primaryPhoneType: PropTypes.string,
        secondaryPhoneType: PropTypes.string,
      }),
    }),
  }),
  onEditMode: PropTypes.func,
  flags: PropTypes.shape({
    updateContactsOwl: PropTypes.bool,
  }),
  dialerService: PropTypes.object,
};

ContactsXL.defaultProps = {};

export default withLDConsumer()(ContactsXL);
