import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  community_container: {
    marginTop: '32px',
  },
  community_container_xs: {
    padding: '24px',
    height: 'calc(100vh - 392px)',
  },
  empty_communities_container: {
    marginTop: '24px',
    backgroundColor: theme.palette.light.white,
    boxShadow: 'rgba(186, 186, 184, 0.3) 0px 0px 8px',
    padding: '80px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  empty_communities_container_button: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  empty_communities_container_xs: {
    backgroundColor: theme.palette.light.white,
    boxShadow: 'rgba(186, 186, 184, 0.3) 0px 0px 8px',
    padding: '40px 70px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '32px 0',
  },

  search_community: {
    marginTop: '24px',
    alignSelf: 'center',
  },

  referred_communities_container_xs: {
    '& > div:last-child': {
      paddingBottom: '32px',
      paddingTop: 0,
    },
  },

  saved_communities_container_xs: {
    marginTop: '32px',
    marginBottom: '32px',
    '& > div:last-child': {
      paddingBottom: '32px',
      paddingTop: 0,
    },
  },
  saved_communities_container: {
    marginTop: '32px',
    '& > div:last-child': {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },

  text_container: {
    display: 'flex',
    marginTop: '16px',
    '& b': {
      margin: '0 4px',
    },
  },
  more_details: {
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'center',
  },
  workinglist_link_container: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    '& > a': {
      color: theme.palette.neutral.cosmicCobalt3,
      display: 'flex',
      alignItems: 'center',
      '&:active': {
        color: theme.palette.neutral.cosmicCobalt3,
      },
      '&:visited': {
        color: theme.palette.neutral.cosmicCobalt3,
      },
    },
  },
}));

export { useStyles };
