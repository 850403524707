import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RowCard from 'common/RowCard/RowCard';
import HeaderActivityCard from './HeaderActivityCard';
import EditActivityCard from './EditActivityCard';
import Typography from 'common/Typography/Typography';
import { useStyles } from './ActivityCard.style';
import { colorReference } from 'common/Theme/themes/default';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const ActivityCard = ({
  mode,
  className,
  type,
  description,
  date,
  color,
  dueLabel,
  onEditCardMode,
  actionId,
  isCardBeingEdited,
  shouldRefetchCallback,
  isCompleted,
  flags,
  dataForSegment,
}) => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(isCardBeingEdited);

  useEffect(() => {
    setIsEditing(isCardBeingEdited);
  }, [isCardBeingEdited]);

  const handleOnClick = () => {
    setIsEditing(true);
    onEditCardMode(actionId);
  };

  const handleOnCancel = () => {
    setIsEditing(false);
  };

  const shouldShowMarkCompleteLink = () => {
    return flags.updateContactsOwl && isCompleted === false;
  };

  if (isEditing) {
    return (
      <EditActivityCard
        className={className}
        type={type}
        color={color}
        date={date}
        description={description}
        mode={mode}
        onCancel={handleOnCancel}
        actionId={actionId}
        shouldRefetchCallback={shouldRefetchCallback}
        dataForSegment={dataForSegment}
      />
    );
  }

  return (
    <RowCard className={className} color={color}>
      <HeaderActivityCard
        mode={mode}
        type={type}
        date={date}
        color={color}
        dueLabel={dueLabel}
      />
      <div className={classes.row}>
        <div className={classes.description_container}>
          <div className={classes.description}>
            <Typography color="eerieBlack5">{description}</Typography>
          </div>
          {shouldShowMarkCompleteLink() && (
            <div className={classes.link_complete} onClick={handleOnClick}>
              <Typography color="cosmicCobalt3" level="small">
                Mark complete
              </Typography>
            </div>
          )}
        </div>
      </div>
    </RowCard>
  );
};

ActivityCard.propTypes = {
  className: PropTypes.string,
  dueLabel: PropTypes.string,
  mode: PropTypes.oneOf(['todo', 'upcoming', 'history']),
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.number.isRequired,
  onEditCardMode: PropTypes.func,
  actionId: PropTypes.number.isRequired,
  isCardBeingEdited: PropTypes.bool,
  shouldRefetchCallback: PropTypes.func,
  isCompleted: PropTypes.bool,
  flags: PropTypes.shape({
    updateContactsOwl: PropTypes.bool,
  }),
  dataForSegment: PropTypes.shape({
    familyFileId: PropTypes.number,
    oneId: PropTypes.string,
  }),
  color: PropTypes.oneOf([...colorReference, 'transparent']),
};

ActivityCard.defaultProps = {
  className: PropTypes.string,
  dueLabel: PropTypes.string,
  mode: PropTypes.oneOf(['todo', 'upcoming', 'history']),
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  color: PropTypes.oneOf([...colorReference, 'transparent']),
  date: PropTypes.number.isRequired,
};

ActivityCard.defaultProps = {
  color: 'transparent',
  className: '',
  dueLabel: 'Due',
  mode: 'history',
  isCompleted: false,
};

export default withLDConsumer()(ActivityCard);
