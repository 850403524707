import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    accordionContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    accordionTitle: {
      alignItems: 'center',
      backgroundColor: ({ accordionColor }) =>
        accordionColor === 'blue'
          ? theme.palette.neutral.cosmicCobalt2
          : theme.palette.light.platinum4,
      borderBottom: `1px solid ${theme.palette.light.platinum1}`,
      borderTopLeftRadius: theme.typography.pxToRem(4),
      borderTopRightRadius: theme.typography.pxToRem(4),
      color: ({ accordionColor }) =>
        accordionColor === 'blue'
          ? theme.palette.light.white
          : theme.palette.dark.eerieBlack1,
      cursor: ({ disableToggleClick }) =>
        disableToggleClick ? 'default' : 'pointer',
      display: 'flex',
      flexGrow: '1',
      justifyContent: 'space-between',
      padding: '8px 16px',
      userSelect: 'none',
      verticalAlign: 'middle',
    },
    basicContainer: {},
    basicTitle: {
      '& div': {
        display: 'inline',
      },
      backgroundColor: 'inherit',
      cursor: ({ disableToggleClick }) =>
        disableToggleClick ? 'default' : 'pointer',
      marginBottom: '16px',
      userSelect: 'none',
    },
    body: {
      backgroundColor: theme.palette.light.platinum5,
      boxShadow: ({ themeStyle }) =>
        themeStyle === 'basic' ? '' : '0px 4px 8px rgba(186, 186, 184, 0.3)',
    },
    icon: {
      '& svg': {
        paddingTop: theme.typography.pxToRem(4),
        verticalAlign: 'top',
        width: '1rem',
        height: '1rem',
      },
      display: 'inline',
      paddingLeft: '19px',
    },
    titleIcon: {
      '& svg': {
        width: '1rem',
        height: '1rem',
        marginRight: theme.typography.pxToRem(8),
        marginTop: theme.typography.pxToRem(-2),
        verticalAlign: 'middle',
      },
    },
  };
});

export { useStyles };
