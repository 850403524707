import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles(() => ({
  error_container: {
    paddingTop: '121px',
    overflow: 'auto',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    width: '97px',
    height: '97px',
  },
  title: {
    margin: '18px 0px 16px 0px',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '24px 0 32px 0',
  },
}));

export { useStyles };
