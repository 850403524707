import { makeStyles } from '../../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    tr: {
      color: theme.palette.dark.eerieBlack1,
      display: 'table-row',
      outline: 0,
      verticalAlign: 'middle',
      backgroundColor: theme.palette.light.platinum5,
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: '22px',
      borderBottom: `1px solid ${theme.palette.light.platinum1}`,
    },
    with_hover: {
      '&:hover': {
        backgroundColor: theme.palette.light.platinum4,
      },
    },
    small: {
      '& td': {
        padding: '8px 16px',
      },
    },
    medium: {
      '& td': {
        padding: '12px 16px',
      },
    },
    extended: {
      '& td': {
        padding: '16px',
      },
    },
  };
});

export { useStyles };
