import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles(() => ({
  container: {
    transition: 'ease .6s',
    boxShadow: '0px 0px 8px rgba(13, 19, 33, 0.5)',
    borderRadius: '4px',
    width: '420px',
    paddingBottom: '24px',
    background: 'white',
    zIndex: 3,
    position: 'absolute',
    left: '-210px',
    top: '20px',
  },
  row: {
    display: 'flex',
    flexFlow: 'column no-wrap',
    flexGrow: 1,
  },
  column_community_info: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  column_save_community: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginLeft: '50px',
    marginTop: '24px',
  },
  column_save_community_smallmap: {
    marginLeft: 0,
  },
  filter_content: {
    display: 'flex',
    marginTop: '16px',
  },
  amenity: {
    '& > svg': {
      fontSize: '20px',
      marginLeft: '15px',
    },
  },
  small_container: (props) => ({
    width: '320px',
    left: '-160px',
    top: `-${props.top}px`,
    '&::after': {
      content: `''`,
      top: '100%',
      left: '50%',
      marginLeft: '-10px',
      borderWidth: '10px',
      borderStyle: 'solid',
      borderColor: '#fefefe transparent transparent transparent',
      position: 'absolute',
      backgroundColor: 'transparent',
    },
    '& .small_map_caretypes': {
      display: 'flex',
      flexFlow: 'row wrap',
      '& > div': {
        margin: 0,
      },
    },
  }),
  clear_arrow: {
    top: '32px !important',
    '&::after': {
      borderStyle: 'hidden !important',
    },
    '&::before': {
      content: `''`,
      bottom: '100%',
      left: '50%',
      marginLeft: '-10px',
      borderWidth: '10px',
      borderStyle: 'solid',
      borderColor: '#fefefe transparent transparent transparent',
      position: 'absolute',
      backgroundColor: 'transparent',
      transform: 'rotate(180deg)',
    },
  },
}));

export { useStyles };
