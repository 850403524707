import PropTypes from 'prop-types';
import React from 'react';
import { useStyles } from './ViewRow.style';
import { getReadableDate } from 'utils/dateFormat';
import clsx from 'clsx';

const isEmpty = (param) =>
  param === null ||
  param === undefined ||
  param === false ||
  param.length === 0;

const ViewRow = ({ label, value, date, html }) => {
  const classes = useStyles();

  if (isEmpty(value)) return null;

  let shownValue = value;
  let innerHtml = null;

  if (value === true) {
    return <div className={clsx(classes.name, classes.double)}>{label}</div>;
  }

  if (date) {
    shownValue = getReadableDate(value);
  }

  if (html) {
    shownValue = null;
    innerHtml = { __html: value };
  }
  return (
    <React.Fragment>
      <div className={classes.name}>{label}:</div>
      <div className={classes.value} dangerouslySetInnerHTML={innerHtml}>
        {shownValue}
      </div>
    </React.Fragment>
  );
};

ViewRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  date: PropTypes.bool,
  html: PropTypes.bool,
};

export default ViewRow;
