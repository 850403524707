import React from 'react';
import PropTypes from 'prop-types';
//components
import Button from 'common/Button/Button';
import Container from 'common/Container/Container';
import ContainerItem from 'common/ContainerItem/ContainerItem';
import PinnedMap from 'common/Graphics/communities/PinnedMap';
import PinStart from 'common/Icons/maps/PinStart';
import Typography from 'common/Typography/Typography';
//style
import { useStyles } from './FamilyFileSearchBanner.style';

const FamilyFileSearchBanner = ({ labels, familyFileId, sidePanelView }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container container spacing={sidePanelView ? 0 : 3}>
        <ContainerItem
          xs={12}
          sm={sidePanelView ? 12 : 1}
          className={
            sidePanelView
              ? classes.side_panel_banner_item_logo
              : classes.banner_item_logo
          }
        >
          <PinnedMap
            className={
              sidePanelView
                ? classes.side_panel_banner_search_pin_logo
                : classes.banner_search_pin_logo
            }
          />
        </ContainerItem>
        <ContainerItem
          xs={12}
          sm={sidePanelView ? 12 : 5}
          className={
            sidePanelView
              ? classes.side_panel_banner_item_text
              : classes.banner_item_text
          }
        >
          <Typography level="large" bold>
            {labels.FAMILY_FILE_SEARCH_COMMUNITY_BANNER}
          </Typography>
        </ContainerItem>
        <ContainerItem
          xs={12}
          sm={sidePanelView ? 12 : 6}
          className={
            sidePanelView
              ? classes.side_panel_banner_item_button
              : classes.banner_item_button
          }
        >
          <Button
            openInNewTab
            to={`/communities/${familyFileId}`}
            startIcon={<PinStart />}
          >
            <Typography color="platinum5">
              {labels.FAMILY_FILE_SEARCH_COMMUNITY_BANNER_BUTTON}
            </Typography>
          </Button>
        </ContainerItem>
      </Container>
    </div>
  );
};

FamilyFileSearchBanner.propTypes = {
  sidePanelView: PropTypes.bool,
  labels: PropTypes.shape({
    FAMILY_FILE_SEARCH_COMMUNITY_BANNER: PropTypes.string.isRequired,
    FAMILY_FILE_SEARCH_COMMUNITY_BANNER_BUTTON: PropTypes.string.isRequired,
  }).isRequired,
  familyFileId: PropTypes.number.isRequired,
};

FamilyFileSearchBanner.defaultProps = {
  sidePanelView: false,
};

export default FamilyFileSearchBanner;
