import { makeStyles } from '../../common/Theme/Theme';

const useStyles = (progress, mtd_bar_progress) => {
  return makeStyles((theme) => ({
    surpassing_quota_container: {
      height: theme.spacing(18),
      boxShadow: `0px 0px 8px ${theme.palette.light.boxShadow}`,
      padding: theme.spacing(3),
      borderRadius: '4px',
      backgroundColor: theme.palette.light.white,
    },
    surpassing_quota_container_medium_size: {
      height: theme.spacing(15),
    },
    quota_header_title: {
      fontWeight: 'bold',
      order: 1,
      '& p': {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    month_to_date: {
      textAlign: 'right',
      order: 2,
      verticalAlign: 'top',
      '& p': {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    rate: {
      '& h2': {
        margin: `${theme.spacing(2)}px 0 0 0`,
      },
    },
    bar_container: {
      position: 'relative',
    },
    bar_empty: {
      position: 'absolute',
      width: '100%',
      height: theme.spacing(2),
      backgroundColor: theme.palette.light.platinum2,
      borderRadius: '4px',
    },
    bar_filled: {
      width: `${progress}%`,
      position: 'absolute',
      height: theme.spacing(2),
      backgroundColor: theme.palette.neutral.cosmicCobalt3,
      borderRadius: '4px 0 0 4px',
      zIndex: 2,
    },
    line: {
      left: `${progress}%`,
      width: '2px',
      height: '20px',
      position: 'absolute',
      backgroundColor: theme.palette.neutral.cosmicCobalt3,
      zIndex: 2,
    },
    label_container: {
      position: 'relative',
      marginTop: theme.spacing(3),
    },
    start_label: {
      position: 'absolute',
      left: 0,
      '& p': {
        margin: 0,
      },
    },
    mtd_label: {
      position: 'absolute',
      top: '-1px',
      left: `calc(${mtd_bar_progress}% - 30px)`,
      '& p': {
        margin: 0,
        fontSize: '12px',
      },
    },
    mtd_label_empty: {
      color: theme.palette.dark.eerieBlack1,
      background: 'red',
    },
    mtd_bar_filled: {
      left: 0,
      width: `${mtd_bar_progress}%`,
      position: 'absolute',
      height: theme.spacing(2),
      background: theme.palette.neutral.cosmicCobalt4,
    },
    end_label: {
      position: 'absolute',
      color: theme.palette.dark.eerieBlack1,
      left: 'calc(100% - 15px)',
      '& p': {
        margin: 0,
      },
    },
    quotas_footer_container: {
      marginTop: theme.spacing(7),
      position: 'relative',
    },
    quotas_footer: {
      borderTop: `1px solid ${theme.palette.light.platinum1}`,
      position: 'absolute',
      left: -theme.spacing(3),
      width: `calc(100% + ${theme.spacing(6)}px)`,
      textAlign: 'center',
      paddingTop: theme.spacing(1),
      '& p': {
        color: theme.palette.dark.eerieBlack5,
        margin: 0,
        marginTop: '4px',
      },
    },
    quota_flag_container: {
      position: 'absolute',
      left: `calc(${progress}% - 5px)`,
      top: -theme.spacing(6),
    },
    quota_container: {
      left: `calc(${progress}% - ${theme.spacing(1)}px)`,
      position: 'absolute',
      '& p': {
        margin: 0,
      },
    },
    mtd_line: {
      left: `${mtd_bar_progress}%`,
      width: '2px',
      height: '20px',
      position: 'absolute',
      backgroundColor: theme.palette.neutral.cosmicCobalt4,
    },
    clickable_container: {
      cursor: 'pointer',
      display: 'flow-root',
    },
  }))();
};

export { useStyles };
