import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import Geocode from 'react-geocode';
import Marker from './Marker';
import mapStyles from './mapStyles.json';
import withConfig from '../../utils/withConfig';
import CommunityMarker from './CommunityMarker';
import { useStyles } from './Map.style';
import Checkbox from '../../common/Checkbox/Checkbox';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { getAmenities } from '../../pages/Communities/CommunitiesTable/communityService';

const Map = ({
  address,
  communities,
  labels,
  onCommunitySelected,
  mapSelectedCommunity,
  mapOpenCardCommunity,
  onSave,
  onRemove,
  onMapChange,
  familyFileId,
  mapHoveredCommunity,
  isClickedInsideTheMap,
  dataForSegment,
  flags,
  locationFilter,
  onClickInside,
  onSaveCommunityUpdate,
  communitiesFeature,
  setHoveredCommunity,
  isSmallMap,
  mapSmallContainer,
  setSmallContainerPositionStyle,
  smallContainerPositionStyle,
}) => {
  const classes = useStyles();
  const searchAsIMove = sessionStorage.getItem('searchAsIMove');
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [locationCenter, setLocationCenter] = useState({});
  const defaultMapZoom = 11;
  const [zoom, setZoom] = useState(defaultMapZoom);
  const [searchAsMove, setSearchAsMove] = useState(searchAsIMove === 'true');

  let isMounted = useRef(false);

  const [selectedCommunity, setSelectedCommunity] = useState({
    mapSelectedCommunity,
  });

  const [openCardCommunity, setOpenCardCommunity] = useState({
    mapOpenCardCommunity,
  });

  const [highlightedCommunity, setHighlightedCommunity] = useState({
    mapSelectedCommunity,
  });

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setSelectedCommunity(mapSelectedCommunity);
    if (mapHoveredCommunity && mapHoveredCommunity.id) {
      setHighlightedCommunity(mapHoveredCommunity);
    } else {
      setHighlightedCommunity(mapSelectedCommunity);
    }
    setOpenCardCommunity(mapOpenCardCommunity);

    if (
      highlightedCommunity &&
      highlightedCommunity.geoLocation &&
      highlightedCommunity.geoLocation.lon !== 0
    ) {
      setZoom(defaultMapZoom);
    } else {
      setZoom(defaultMapZoom);
    }
  }, [
    mapSelectedCommunity,
    mapHoveredCommunity,
    mapOpenCardCommunity,
    highlightedCommunity,
  ]);

  const onCommunityMarkerCommunitySelected = (community) => {
    setSelectedCommunity(community);
    onCommunitySelected(community);
    setHoveredCommunity({});
  };

  Geocode.setApiKey(withConfig('GOOGLE_GEOCODE_API_KEY'));

  const getLocationCenter = async () => {
    const {
      results: [
        {
          geometry: {
            location: { lat, lng },
          },
        },
      ],
    } = await Geocode.fromAddress(address);

    if (isMounted.current === true) {
      setLocationCenter({ lat, lng });
      setCenter({ lat, lng });
    }
  };

  useEffect(() => {
    if (address) {
      getLocationCenter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const handleChangeMap = async ({ center }) => {
    if (flags.searchAsIMoveInMap && searchAsMove) {
      const { results } = await Geocode.fromLatLng(center.lat, center.lng);
      const city = results[0].address_components.find((c) =>
        c.types.includes('locality'),
      );
      const state = results[0].address_components.find((c) =>
        c.types.includes('administrative_area_level_1'),
      );
      const zip = results[0].address_components.find((c) =>
        c.types.includes('postal_code'),
      );

      const location = {
        city: city ? city.long_name : '-',
        state: state ? state.short_name : '-',
        zip: zip ? zip.short_name : '-',
      };

      onMapChange(location);
      setCenter(center);
    }
  };

  const toggleSearchAsIMove = () => {
    setSearchAsMove(!searchAsMove);
    sessionStorage.setItem('searchAsIMove', (!searchAsMove).toString());
  };

  const getType = (community) => {
    if (community.isReferred) {
      return 'referred';
    }
    return community.isSaved ? 'saved' : 'default';
  };

  const getMapBounds = (map, maps, communities) => {
    const bounds = new maps.LatLngBounds();

    communities.forEach((community) => {
      bounds.extend(
        new maps.LatLng(community.geoLocation.lat, community.geoLocation.lon),
      );
    });

    return bounds;
  };

  const handleApiLoaded = (map, maps, communities) => {
    const bounds = getMapBounds(map, maps, communities);
    map.fitBounds(bounds, { top: 135, left: 100, right: 100 });
  };

  return (
    <div className={classes.map_container} onClick={onClickInside}>
      {flags.searchAsIMoveInMap && (
        <div className={classes.search_as_i_move}>
          <Checkbox
            label={labels.SEARCH_AS_I_MOVE_THE_MAP}
            checked={searchAsMove}
            onChange={toggleSearchAsIMove}
          />
        </div>
      )}
      <GoogleMapReact
        options={{ styles: mapStyles }}
        bootstrapURLKeys={{
          key: withConfig('GOOGLE_MAPS_API_KEY'),
          libraries: ['places'],
        }}
        defaultZoom={defaultMapZoom}
        onChange={handleChangeMap}
        zoom={zoom}
        center={center}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) =>
          handleApiLoaded(map, maps, communities)
        }
      >
        <Marker
          type="startingPoint"
          lat={locationCenter.lat}
          lng={locationCenter.lng}
        />
        {communities.map((community, key) => (
          <CommunityMarker
            labels={labels}
            community={community}
            onCommunitySelected={onCommunityMarkerCommunitySelected}
            key={key}
            lat={community.geoLocation.lat}
            lng={community.geoLocation.lon}
            type={getType(community)}
            isHovered={
              mapHoveredCommunity && mapHoveredCommunity.id === community.id
            }
            isCommunitySelected={
              selectedCommunity && selectedCommunity.id === community.id
            }
            isPinpointCardOpen={
              openCardCommunity && openCardCommunity.id === community.id
            }
            onSave={() => {
              onSave(community);
            }}
            onRemove={() => {
              onRemove(community);
            }}
            familyFileId={familyFileId}
            isClickedInsideTheMap={isClickedInsideTheMap}
            dataForSegment={dataForSegment}
            locationFilter={locationFilter}
            onSaveCommunityUpdate={onSaveCommunityUpdate}
            communitiesFeature={
              communitiesFeature === null ||
              communitiesFeature === undefined ||
              !communitiesFeature[community.id]
                ? []
                : getAmenities(communitiesFeature[community.id], labels)
            }
            isSmallMap={isSmallMap}
            mapSmallContainer={mapSmallContainer}
            setSmallContainerPositionStyle={setSmallContainerPositionStyle}
            smallContainerPositionStyle={smallContainerPositionStyle}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

Map.propTypes = {
  address: PropTypes.string,
  communities: PropTypes.array,
  labels: PropTypes.object,
  onCommunitySelected: PropTypes.func,
  mapSelectedCommunity: PropTypes.object,
  mapOpenCardCommunity: PropTypes.object,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
  onMapChange: PropTypes.func,
  familyFileId: PropTypes.number,
  flags: PropTypes.object,
  mapHoveredCommunity: PropTypes.object,
  locationFilter: PropTypes.object,
  isClickedInsideTheMap: PropTypes.bool,
  dataForSegment: PropTypes.shape({
    familyFileId: PropTypes.number,
    oneId: PropTypes.string,
  }),
  onClickInside: PropTypes.func,
  onSaveCommunityUpdate: PropTypes.func,
  communitiesFeature: PropTypes.object,
  setHoveredCommunity: PropTypes.func,
  isSmallMap: PropTypes.bool,
  mapSmallContainer: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  setSmallContainerPositionStyle: PropTypes.func,
  smallContainerPositionStyle: PropTypes.object,
};

Map.defaultProps = {
  onClickInside: () => {},
  onSaveCommunityUpdate: () => {},
  setHoveredCommunity: () => {},
};

export default withLDConsumer()(Map);
