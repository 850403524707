import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  referred_community_info_container: {
    display: 'flex',
  },
  community_details_container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginLeft: '24px',
    minWidth: '255px',
  },
  care_types: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '4px',
  },
  care_types_divider: {
    height: '12px',
    width: '1px',
    margin: '0 8px',
    backgroundColor: theme.palette.dark.disabled,
  },
  address: {
    display: 'flex',
    marginTop: '8px',
    '& svg': {
      fontSize: '16px',
      color: theme.palette.neutral.cosmicCobalt2,
      fill: theme.palette.neutral.cosmicCobalt2,
      marginRight: '8px',
    },
  },
  margin_top_four: {
    marginTop: '4px',
  },
}));

export { useStyles };
