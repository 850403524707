import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      '& .main_container': {
        paddingRight: 0,
      },
    },
  },
  right_panel_container: {
    marginTop: '-32px',
    padding: '32px',
    backgroundColor: theme.palette.light.platinum4,
    height: 'calc(100vh - 105px)',
    overflow: 'auto',
  },
}));

export { useStyles };
