import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  incomplete_ff_container: {
    backgroundColor: theme.palette.light.white,
    boxShadow: 'rgba(186, 186, 184, 0.3) 0px 0px 8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '80px 0',
  },
  icon: {
    fontSize: '80px',
  },
  description: {
    marginTop: '24px',
  },
  title: {
    marginTop: '24px',
  },
  edit_ff_button: {
    marginTop: '24px',
  },
}));

export { useStyles };
