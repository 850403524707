import { cloneDeep, difference, intersection } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Menu from 'common/Icons/basic/Menu';
import NotificationsMenu from 'components/NotificationsMenu/NotificactionsMenu';
import UserMenu from 'components/UserMenu/UserMenu';
import { withLabels } from 'context/LabelContext';
import notificationsService from 'services/callCenter/notificationsService';
import { useStyles } from './TopBar.style';
import NavigationSidePanel from '../NavigationSidePanel/NavigationSidePanel';

const haveSkillsUpdated = (
  skills,
  selectedSkills,
  addedSkills,
  removedSkills,
) => {
  const hasAddedOrRemovedSkills =
    addedSkills.length > 0 || removedSkills.length > 0;
  if (hasAddedOrRemovedSkills) {
    return hasAddedOrRemovedSkills;
  }
  if (selectedSkills?.length === 0 || skills?.length === 0) {
    return false;
  }
  return false;
};

const TopBar = ({
  button,
  skills,
  selectedSkills,
  labels,
  callState,
  agentState,
}) => {
  const classes = useStyles();
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const [currentSkills, setCurrentSkills] = useState([]);
  const [skillsNotifications, setSkillsNotifications] = useState([]);
  useEffect(() => {
    const addedSkills = difference(selectedSkills, currentSkills);
    const removedSkills = difference(currentSkills, selectedSkills);
    if (haveSkillsUpdated(skills, selectedSkills, addedSkills, removedSkills)) {
      const existingSkillsList = skills.map((s) => s.id.toString());
      const selectedSkillsExistingInList = intersection(
        existingSkillsList,
        selectedSkills,
      );
      const allNotifications = notificationsService.buildSkillNotifications({
        skills,
        labels,
        skillsNotifications,
        addedSkills,
        removedSkills,
      });
      setSkillsNotifications(allNotifications);
      setCurrentSkills(selectedSkillsExistingInList);
    }
  }, [
    skills,
    currentSkills,
    selectedSkills,
    skillsNotifications,
    setCurrentSkills,
    setSkillsNotifications,
    labels,
  ]);
  const handleHamburgerClick = () => {
    setIsNavigationOpen(true);
  };
  const onNotificationClicked = (notificationClicked) => {
    const newNotifications = cloneDeep(skillsNotifications);
    newNotifications.forEach((n) => {
      if (n.id === notificationClicked.id) {
        n.read = true;
      }
    });
    setSkillsNotifications(newNotifications);
  };

  return (
    <div className={classes.topBarContainer}>
      <div className={classes.left} onClick={handleHamburgerClick}>
        <Menu />
      </div>
      {isNavigationOpen && (
        <NavigationSidePanel
          labels={labels}
          backgroundColor="cosmicCobalt1"
          width="240"
          xColor="platinum5"
          isOpen={isNavigationOpen}
          onClose={() => setIsNavigationOpen(false)}
          agentState={agentState}
        />
      )}
      <div className={classes.right}>
        <div className={classes.alert}>
          <NotificationsMenu
            showUnreadOnly={true}
            notifications={skillsNotifications}
            onNotificationClicked={onNotificationClicked}
          />
        </div>
        <div className={classes.status}>{button}</div>
        <div>
          <UserMenu
            skills={skills}
            selectedSkills={selectedSkills}
            callState={callState}
          />
        </div>
      </div>
    </div>
  );
};

TopBar.propTypes = {
  button: PropTypes.node,
  skills: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  ),
  selectedSkills: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.object,
  callState: PropTypes.string,
  agentState: PropTypes.object,
};

TopBar.defaultProps = {
  callState: '',
  agentState: {},
};

export default withLabels(TopBar);
