import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  notifications: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '25px 26px',
    '&.clickable': {
      cursor: 'pointer',
    },
    '&.open': {
      background: theme.palette.neutral.cosmicCobalt3,
    },
    '& svg': {
      color: theme.palette.light.platinum5,
    },
  },
  bell_container: {
    position: 'relative',
    '& .unread_count': {
      padding: 0,
      position: 'absolute',
      top: '-12px',
      left: '12px',
      width: '22px',
      height: '22px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: `solid 3px ${theme.palette.neutral.cosmicCobalt1}`,
      '&.open': {
        borderColor: theme.palette.neutral.cosmicCobalt3,
      },
    },
  },
  menu_container: {
    position: 'absolute',
    top: '76px',
    left: '-6px',
    padding: '0 6px 6px 6px',
    overflow: 'hidden',
    zIndex: 3,
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    width: theme.spacing(48),
    backgroundColor: theme.palette.light.platinum5,
    boxShadow: '0px 0px 5px 0px rgba(200,200,201,1)',
  },
}));

export { useStyles };
