import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import Typography from 'common/Typography/Typography';
import { useStyles } from './CommunityPreferences.style';

const Field = ({ withTooltip, tooltip, label, value }) => {
  const classes = useStyles();

  const labelComponent = (
    <Typography className={classes.label} color="eerieBlack5" level="small">
      {label}
    </Typography>
  );
  const valueComponent = <Typography level="body">{value}</Typography>;
  if (!withTooltip) {
    return (
      <Fragment>
        {labelComponent}
        {valueComponent}
      </Fragment>
    );
  }
  return (
    <div>
      <SmartTooltip title={tooltip} placement="bottom">
        {labelComponent}
      </SmartTooltip>
      <SmartTooltip title={tooltip} placement="bottom">
        {valueComponent}
      </SmartTooltip>
    </div>
  );
};

Field.defaultProps = {
  withTooltip: false,
};

Field.propTypes = {
  withTooltip: PropTypes.bool,
  tooltip: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
export default Field;
