import { PhoneContext } from '@aplaceformom/owl-connect-client';
import Toast from 'common/Toast/Toast';
import Typography from 'common/Typography/Typography';
import Loading from 'components/Loading/Loading';
import allCallStates, {
  callTypes,
  getCallType,
} from 'config/callStates.config';
import { withLabels } from 'context/LabelContext';
import { useAutoCloseFive9 } from 'hooks/useAutoCloseFive9';
import useGraphQLMutation from 'hooks/useGraphQLMutation';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import {
  logCallTransferred,
  logCompleteCallClicked,
  logCompleteWarmTransfer,
  logInquiryViewed,
  logWarmTransferCancelled,
  logWarmTransferConferenceClicked,
  logWarmTransferListReturned,
} from 'services/callCenter/amplitudeEventsService';
import { mapAudience } from 'services/callCenter/audienceService';
import formService from 'services/callCenter/formService';
import warmTransferService, {
  warmTransferStates,
} from 'services/callCenter/warmTransferService';
import {
  completeTransferCall,
  getManualInquiryState,
  saveFormChicletsRequest,
  saveFormChicletsRequest2,
  startTransferCall,
  updateCloseReason,
  updateDisposition,
  updateFormSteps,
} from 'stateManagement/callcenter/formChiclets/actions/formChicletsActions';
import {
  selectCloseReason,
  selectDisposition,
  selectFormChiclets,
  selectTransferCallInfo,
  selectWarmTransferPoolType,
} from 'stateManagement/callcenter/formChiclets/reducers/formChicletsReducer';
import {
  getInquiryRequest,
  setRedirectToWaitingScreen,
  updateFinishedInboundCall,
} from 'stateManagement/callcenter/inquiry/actions/inquiryActions';
import {
  selectCallId,
  selectCreatedInquiry,
  selectInquiry,
  selectIsInboundCallFinished,
  selectRedirectToWaitingScreen,
} from 'stateManagement/callcenter/inquiry/reducers/inquiryReducer';
import {
  setCancelWarmTransfer,
  setChangeSlaState,
  setCompleteWarmTransfer,
  setCompleteWarmTransferFamilyLeft,
  setFamilyConnected,
  setFamilyDisconnected,
  updateWarmTransferState,
  startWarmTransfer,
} from 'stateManagement/callcenter/warmTransfer/actions/warmTransferActions';
import {
  selectDuplicateFamilyFile,
  selectFindBestSlaAttemptsCount,
  selectIsWarmTransferCompleted,
  selectWarmTransferState,
  selectWarmTransferType,
} from 'stateManagement/callcenter/warmTransfer/reducers/warmTransferReducer';
import {
  setCallOnWrapUpByCaller,
  setDispositionModalOpen,
  setDispositionType,
  updateCallState,
  updateRedialed,
  updateTelephonyWarmTransfer,
} from 'stateManagement/telephony/actions/telephonyActions';
import {
  selectCallOnWrapUpByCaller,
  selectDispositionModalOpen,
} from 'stateManagement/telephony/reducers/telephonyReducer';
import withConfig from 'utils/withConfig';
import CallWrapper from '../CallWrapper/CallWrapper';
import { callBarScreens } from './CallBar/callBarScreenEnum';
import createFamilyFileForWarmTransferMutation from './CreateFamilyFileForWarmTransferMutation';
import createQueuedCallWithClosedInquiry from './CreateQueuedCallWithClosedInquiry';
import DispositionsModal from './DispositionsModal/DispositionsModal';
import FilterBar from './FilterBar/FilterBar';
import FindingSlaPopup from './FormChiclets/FindingSlaPopup';
import FormChiclets from './FormChiclets/FormChiclets';
import updateInquiryMutation from './FormChiclets/ResidentPopup/updateInquiryMutation';
import { useStyles } from './NewInquiry.style';
import OtherAlerts from './OtherAlerts';
import updateStatusDetailMutation from './UpdateStatusDetailMutation';
import WarmTransferAlerts from './WarmTransferAlerts';

const getWinnerSlaAgent = (warmTransfer, availableAdvisors) => {
  let winnerSla = {};

  const winningSlaAgentId = warmTransfer?.winningSlaAgentId;
  if (winningSlaAgentId) {
    winnerSla = availableAdvisors.find((a) => a.agentId === winningSlaAgentId);
  }
  return winnerSla;
};

const NewInquiry = ({ labels, flags }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const setWarmTransferState = useCallback(
    (state) => {
      dispatch(updateWarmTransferState(state));
    },
    [dispatch],
  );

  const {
    auth: contextAuth,
    setDisposition,
    initiateWarmTransfer,
    warmTransfer,
    transferLead,
    availableAdvisors,
    completeWarmConference,
    completeWarmTransfer,
    cancelWarmTransfer,
    endCall,
    callData,
    callAttempt: { isLastCallAttempt },
    transferExternalCall,
    leaveConference,
    dispositions,
    callPreview,
    agentInfo,
    activeCampaigns,
    callState,
    isAuthenticated,
    skills,
    activeConference,
    activeContact,
    deleteCallsFromLists,
    skipPreviewCall,
    redial,
  } = useContext(PhoneContext);

  const [loadingInquiry, setLoadingInquiry] = useState(true);
  const callType = getCallType(
    activeCampaigns,
    callData,
    callPreview,
    callState,
  );
  const [
    shouldDispositionWarmTransfer,
    setShouldDispositionWarmTransfer,
  ] = useState(false);

  const [familyFileId, setFamilyFileId] = useState(null);
  const [isRedialModalOpen, setIsRedialModalOpen] = useState(false);
  const [inquiryUpdateInput, setInquiryUpdateInput] = useState({});
  const assignedToId = parseInt(withConfig('DEFAULT_CALLCENTER_USER_ID'), 10); //2265; //Default user value
  const [hasLoggedAdvisors, setLoggedAdvisors] = useState(false);

  const { number } = callData;
  const [createFamilyFileForWarmTransfer] = useGraphQLMutation(
    createFamilyFileForWarmTransferMutation,
  );

  const [updateInquiry] = useGraphQLMutation(updateInquiryMutation);

  const [updateStatusDetail] = useGraphQLMutation(updateStatusDetailMutation);
  const [createQueuedCall] = useGraphQLMutation(
    createQueuedCallWithClosedInquiry,
  );

  const internalTransferCall = useSelector(selectTransferCallInfo);
  const warmTransferPoolType = useSelector(selectWarmTransferPoolType);
  const { inquiry, inquiryLoading } = useSelector(selectInquiry);
  const isInboundCallFinished = useSelector(selectIsInboundCallFinished);
  const warmTransferState = useSelector(selectWarmTransferState);
  const warmTransferType = useSelector(selectWarmTransferType);
  const isWarmTransferCompleted = useSelector(selectIsWarmTransferCompleted);
  const callOnWrapUpByCaller = useSelector(selectCallOnWrapUpByCaller);
  const dispositionModalOpen = useSelector(selectDispositionModalOpen);
  const redirectToWaitingScreen = useSelector(selectRedirectToWaitingScreen);
  const {
    familyFileStatus: currentFamilyFileStatus,
    hasDuplicate,
  } = useSelector(selectDuplicateFamilyFile);
  const findBestSlaAttempts = useSelector(selectFindBestSlaAttemptsCount);
  const {
    isInboundCallInquiryCreating,
    isInboundCallInquiryCreated,
  } = useSelector(selectCreatedInquiry);
  const callId = useSelector(selectCallId);

  const { formSteps, formChicletsLoading, formChicletsError } = useSelector(
    selectFormChiclets,
  );
  const disposition = useSelector(selectDisposition);

  const { closeReason, closeInquiry, isScheduleCallSelected } = useSelector(
    selectCloseReason,
  );
  if (isLastCallAttempt && !closeInquiry && !isScheduleCallSelected) {
    dispatch(
      updateCloseReason({
        closeInquiry: true,
        closeReason: labels.MAX_ATTEMPTS_REASON,
        isNoAnswerSelected: false,
        removeFromCampaign: true,
      }),
    );
  }

  const { inquiryId } = useParams();
  const loggedOnce = useRef(false);
  const isInquiryUpdated = useRef(false);

  useAutoCloseFive9(isAuthenticated, skills, dispatch);

  //set manual state
  useEffect(() => {
    dispatch(
      getManualInquiryState({
        isManualInquiry: false,
        isCreatedManualInquiry: false,
        hasErrors: false,
      }),
    );
  }, [dispatch]);

  //log inquiry viewed
  useEffect(() => {
    if (loggedOnce.current) {
      return;
    }
    logInquiryViewed(inquiryId, familyFileId);
    loggedOnce.current = true;
  }, [familyFileId, inquiryId]);

  // removes from queue if inquiry is closed
  useEffect(() => {
    if (
      inquiry?.inquiryId?.toString() === inquiryId &&
      inquiry?.status === 'closed' &&
      callPreview?.contact?.inquiryId?.toString() === inquiryId &&
      callPreview?.state === 'PREVIEW'
    ) {
      let callPreviewCampaignName = activeCampaigns.find(
        (ac) => ac.id === callPreview.campaignId,
      )?.name;
      skipPreviewCall('-36');
      deleteCallsFromLists([callPreview?.contact?.phone]);
      createQueuedCall({
        variables: {
          inquiryId: inquiry?.inquiryId,
          phone: callPreview?.contact?.phone,
          list: callPreviewCampaignName,
        },
      });
    }
  }, [
    inquiry,
    inquiryId,
    skipPreviewCall,
    callPreview,
    activeCampaigns,
    createQueuedCall,
    deleteCallsFromLists,
  ]);

  useEffect(() => {
    if (
      warmTransferType &&
      warmTransferState === warmTransferStates.REQUESTED
    ) {
      dispatch(startWarmTransfer());
    }
  }, [warmTransferType, warmTransferState, dispatch]);

  //update call state
  useEffect(() => {
    dispatch(updateCallState({ callState, activeContact, flags }));
  }, [callState, activeContact, flags, dispatch]);

  useEffect(() => {
    dispatch(
      updateTelephonyWarmTransfer({
        agentInfo,
        availableAdvisors,
        cancelWarmTransfer,
        completeWarmTransfer,
        contextAuth,
        warmTransfer,
      }),
    );
  }, [
    agentInfo,
    availableAdvisors,
    cancelWarmTransfer,
    completeWarmTransfer,
    contextAuth,
    warmTransfer,
    dispatch,
  ]);

  //set sla connection state
  useEffect(() => {
    if (availableAdvisors.length > 0 && !hasLoggedAdvisors) {
      logWarmTransferListReturned({
        leadId: warmTransfer?.leadId || 0,
        numSlasReturned: availableAdvisors.length,
        inquiryId: inquiry?.inquiryId || 0,
        desiredPostalCode: inquiry?.desiredPostalCode || '00000',
        warmTransferAttempt: findBestSlaAttempts,
        warmTransferId: warmTransfer?.warmTransferId,
      });
      setLoggedAdvisors(true);
    }
    dispatch(
      setChangeSlaState({
        activeConference,
        availableAdvisors,
        transferLead,
        contextAuth,
        agentInfo,
        completeWarmTransfer,
      }),
    );
  }, [
    activeConference,
    availableAdvisors,
    transferLead,
    contextAuth,
    agentInfo,
    completeWarmTransfer,
    hasLoggedAdvisors,
    warmTransfer,
    inquiry,
    findBestSlaAttempts,
    dispatch,
  ]);

  //set family connection state
  useEffect(() => {
    if (callState === allCallStates.TALKING.key) {
      dispatch(setFamilyConnected());
    } else if (callState === allCallStates.WRAP_UP.key) {
      dispatch(
        setFamilyDisconnected({
          availableAdvisors,
          activeConference,
          activeContact,
          cancelWarmTransfer,
          contextAuth,
          agentInfo,
          completeWarmTransfer,
        }),
      );
    }
  }, [
    callState,
    availableAdvisors,
    activeConference,
    activeContact,
    cancelWarmTransfer,
    contextAuth,
    agentInfo,
    completeWarmTransfer,
    dispatch,
  ]);

  // loads inquiry
  useEffect(() => {
    if (parseInt(inquiryId) !== inquiry?.inquiryId) {
      dispatch(getInquiryRequest(inquiryId, flags.allowDisabledChiclets));
      setLoadingInquiry(false);
    }
  }, [flags.allowDisabledChiclets, inquiry, inquiryId, dispatch]);

  // set disposition
  useEffect(() => {
    if (
      disposition &&
      (callState === allCallStates.WRAP_UP.key || shouldDispositionWarmTransfer)
    ) {
      dispatch(
        setDispositionType({
          activeConference,
          deleteCallsFromLists,
          dispositions,
          isLastCallAttempt,
          leaveConference,
          number,
          setDisposition,
        }),
      );
    }
  }, [
    activeConference,
    callState,
    deleteCallsFromLists,
    dispatch,
    disposition,
    dispositions,
    isLastCallAttempt,
    leaveConference,
    number,
    setDisposition,
    shouldDispositionWarmTransfer,
  ]);

  const updateInquiryStatusDetail = async (statusDetail) => {
    await updateStatusDetail({
      variables: {
        inquiryId: inquiry.inquiryId,
        statusDetail,
      },
    });
  };

  const doSaveForm = useCallback(
    async (formToSave, status) => {
      dispatch(saveFormChicletsRequest({ formToSave, status, closeReason }));
    },
    [dispatch, closeReason],
  );

  const onSaveForm = async (
    newFormSteps,
    selectedOptionValue,
    status = 'open',
  ) => {
    let formToSave = cloneDeep(newFormSteps);
    if (status === 'closed') {
      onEndCall(selectedOptionValue, 'closed', false);
    } else {
      await doSaveForm(formToSave, 'open');
    }
  };

  const handleInternalTransfer = async () => {
    dispatch(saveFormChicletsRequest2(callState.status, closeReason));
    try {
      await transferExternalCall(internalTransferCall.phoneNumber);
    } catch (transferErr) {
      console.error('ERROR on transfer call:', transferErr);
    } finally {
      deleteCallsFromLists([number]);
      dispatch(setRedirectToWaitingScreen(true));
      dispatch(completeTransferCall());
    }
  };

  const onEndCall = async (endCallReason, status, closeInquiry, source) => {
    logCompleteCallClicked({
      disposition,
      familyFileId,
      inquiryId,
      leadId: warmTransfer?.leadId,
      slaEmail: getWinnerSlaAgent(warmTransfer, availableAdvisors)?.email,
      warmTransferAttempt: findBestSlaAttempts,
      warmTransferId: warmTransfer?.warmTransferId,
    });
    if (internalTransferCall.isTransferPending) {
      handleInternalTransfer();
      return;
    }
    if (isWarmTransferCompleted) {
      setShouldDispositionWarmTransfer(true);
    } else {
      if (callState === allCallStates.WRAP_UP.key) {
        setShouldDispositionWarmTransfer(true);
      } else {
        endCall();
      }
    }
    if (!disposition && source !== 'hangup') {
      await doSaveForm(cloneDeep(formSteps), 'open');
      dispatch(setDispositionModalOpen(true));
    }

    if (disposition && endCallReason === 'completeCall') {
      console.log('===BCN-548', closeReason);
      dispatch(saveFormChicletsRequest2('closed', closeReason));
    }
  };

  const onInitiateWarmTransfer = async () => {
    if (activeCampaigns.length > 0) {
      await warmTransferService.onInitiateWarmTransfer(
        formSteps,
        setWarmTransferState,
        dispatch,
        inquiry,
        assignedToId,
        setFamilyFileId,
        initiateWarmTransfer,
        callData,
        createFamilyFileForWarmTransfer,
        warmTransferPoolType,
        updateInquiryStatusDetail,
        callState,
        inquiryUpdateInput,
        currentFamilyFileStatus,
        hasDuplicate,
        updateInquiry,
        isInquiryUpdated,
        warmTransferType,
      );
    } else {
      setWarmTransferState(warmTransferStates.ERROR_CONTEXT);
    }
  };

  const onTransferToSLA = async (_, state = warmTransferStates.WAITING_SLA) => {
    logWarmTransferConferenceClicked({
      inquiryId: inquiry.inquiryId,
      familyFileId: inquiry.familyFileId,
      slaEmail: getWinnerSlaAgent(warmTransfer, availableAdvisors)?.email,
      leadId: warmTransfer.leadId,
      warmTransferId: warmTransfer.warmTransferId,
      warmTransferAttempt: findBestSlaAttempts,
    });
    setWarmTransferState(state);
    const winnerSlaAgent = getWinnerSlaAgent(warmTransfer, availableAdvisors);
    await warmTransferService.onTransferToSLA(winnerSlaAgent, transferLead);
    return formSteps;
  };

  const onCompleteWarmTransfer = async () => {
    logCompleteWarmTransfer({
      inquiryId: inquiry.inquiryId,
      leadId: warmTransfer?.leadId,
      slaEmail: getWinnerSlaAgent(warmTransfer, availableAdvisors)?.email,
      warmTransferAttempt: findBestSlaAttempts,
      warmTransferId: warmTransfer?.warmTransferId,
    });
    dispatch(
      setCompleteWarmTransfer({
        activeConference,
        agentInfo,
        availableAdvisors,
        completeWarmConference,
        completeWarmTransfer,
        contextAuth,
        deleteCallsFromLists,
        dispositions,
        isLastCallAttempt,
        leaveConference,
        number,
        setDisposition,
      }),
    );
  };

  const onCompleteWarmTransferFamilyLeft = async () => {
    dispatch(
      setCompleteWarmTransferFamilyLeft({
        availableAdvisors,
        contextAuth,
        agentInfo,
        completeWarmTransfer,
        completeWarmConference,
        activeContact,
      }),
    );
  };
  const getPayload = (variables) => {
    setInquiryUpdateInput(variables);
  };

  const onCancelTransfer = async () => {
    dispatch(
      setCancelWarmTransfer({
        availableAdvisors,
        cancelWarmTransfer,
        contextAuth,
        agentInfo,
        completeWarmTransfer,
      }),
    );

    logWarmTransferCancelled({
      inquiryId,
      familyFileId,
      leadId: warmTransfer.leadId,
      warmTransferId: warmTransfer.warmTransferId,
      warmTransferAttempt: findBestSlaAttempts,
    });
  };

  const showChicletsOrWaitingScreen = () => {
    if (formSteps?.length > 0) {
      const otherAlertsConfig = [
        {
          isOpen: callOnWrapUpByCaller,
          onClose: () => dispatch(setCallOnWrapUpByCaller(false)),
          alertType: 'CALL_DISCONNECTED_BY_CALLER',
        },
        {
          isOpen: isRedialModalOpen,
          onClose: () => setIsRedialModalOpen(false),
          alertType: 'REDIAL_NOT_ALLOWED',
        },
      ];

      return (
        <Fragment>
          <FindingSlaPopup
            callState={callState}
            buttonEnabled={!!warmTransfer?.warmTransferId}
            onCancelTransfer={onCancelTransfer}
          />
          <WarmTransferAlerts />
          <OtherAlerts alerts={otherAlertsConfig} />
          <FormChiclets
            onSaveForm={onSaveForm}
            sendingPayload={getPayload}
            warmTransfer={{
              warmTransferState,
              initiateWarmTransfer: onInitiateWarmTransfer,
              transferToSLA: onTransferToSLA,
              completeWarmTransfer: onCompleteWarmTransfer,
              completeWarmTransferFamilyLeft: onCompleteWarmTransferFamilyLeft,
              winnerSlaAgent: getWinnerSlaAgent(
                warmTransfer,
                availableAdvisors,
              ),
              previousSla: {
                phoneProviderUsername: inquiryUpdateInput.phoneProviderUsername,
                slaName: inquiryUpdateInput.slaName,
              },
            }}
          />
        </Fragment>
      );
    }
  };

  const showAudienceAlert = () => {
    const audience = mapAudience(inquiry?.audienceKey);
    if (audience?.urgent) {
      return (
        <Toast
          type="warning"
          className={classes.urgent_audience_alert}
          onClose={() => ''}
        >
          <Typography>
            {labels.THIS_INQUIRY_IS_MARKED_AS}
            <strong> {labels.URGENT_NEED}</strong>.
          </Typography>
        </Toast>
      );
    }
    return null;
  };

  const onTransfer = async (element) => {
    const newFormSteps = formService.showInternalTransferChiclet(formSteps);
    dispatch(updateFormSteps(newFormSteps));
    dispatch(
      startTransferCall({
        name: element.value,
        phoneNumber: element.phoneNumber,
      }),
    );
    logCallTransferred(inquiryId, familyFileId, element.label);
  };

  const onReschedule = () => {
    const newFormSteps = formService.showRescheduleCallStep(formSteps);
    dispatch(updateFormSteps(newFormSteps));
  };

  const onRedial = async () => {
    const winnerSla = getWinnerSlaAgent(warmTransfer, availableAdvisors);

    if (winnerSla && JSON.stringify(winnerSla) !== '{}') {
      setIsRedialModalOpen(true);
    } else {
      let redialDisposition = dispositions.filter(
        (d) => d.name === 'CS Redial',
      );
      if (redialDisposition.length > 0) {
        dispatch(updateDisposition('CS Redial'));
        await setDisposition(redialDisposition[0].id, 'CS Redial')
          .then(() => {
            redial();
            dispatch(updateRedialed(true));
          })
          .catch((e) =>
            console.warn(
              'PhoneProvider Error setting disposition for attempted redial:',
              e,
            ),
          );
      }
    }
  };

  const renderFormChiclets = () => {
    return (
      <Fragment>
        <div className={classes.header_content}>
          <FilterBar inquiry={inquiry} className={classes.notes_component} />
        </div>
        {showAudienceAlert()}
        {showChicletsOrWaitingScreen()}
      </Fragment>
    );
  };

  if (inquiryLoading || formChicletsLoading) return <Loading />;

  if (formChicletsError) {
    return (
      <Toast type="error">
        <Typography>Error! {formChicletsError.message}</Typography>
      </Toast>
    );
  }

  if (formSteps?.length === 0) {
    return (
      <Toast type="error">
        <Typography>There is no script to show.</Typography>
      </Toast>
    );
  }

  if (redirectToWaitingScreen) {
    if (callType === callTypes.INBOUND && !isInboundCallFinished) {
      dispatch(updateFinishedInboundCall(true));
    }
    return <Redirect to="/callcenter/waiting" />;
  }

  if (!inquiry && !inquiryLoading && !loadingInquiry) {
    return <Redirect to="/callcenter/home" />;
  }
  if (isInboundCallInquiryCreating) {
    return <Loading />;
  }

  if (
    callType === callTypes.INBOUND &&
    callId !== callData?.callId &&
    !isInboundCallInquiryCreated &&
    !isInboundCallInquiryCreating
  ) {
    return <Redirect to="/callcenter/waiting" />;
  }

  if (inquiry) {
    return (
      <CallWrapper
        onEndCall={onEndCall}
        onTransfer={onTransfer}
        onReschedule={onReschedule}
        onRedial={onRedial}
        isWaitingCallDisplayed={false}
        onUpdateStatusDetail={updateInquiryStatusDetail}
        screen={callBarScreens.SCREENING}
      >
        <DispositionsModal
          isOpen={dispositionModalOpen}
          onClose={() => dispatch(setDispositionModalOpen(false))}
          labels={labels}
          dispositions={dispositions}
        />
        {renderFormChiclets()}
      </CallWrapper>
    );
  }

  return <Loading />;
};

NewInquiry.propTypes = {
  labels: PropTypes.object,
  flags: PropTypes.shape({
    allowDisabledChiclets: PropTypes.bool,
    showFive9Frame: PropTypes.bool,
  }),
};

export default withLDConsumer()(withLabels(NewInquiry));
