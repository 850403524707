import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import withConfig from './withConfig';

const client = new ApolloClient({
  link: ApolloLink.from([
    new HttpLink({
      uri: withConfig('GRAPHQL_URL'),
    }),
  ]),
  cache: new InMemoryCache(),
});

export default client;
