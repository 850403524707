import gql from 'graphql-tag';

export default gql`
  mutation(
    $formDataId: Int!
    $age: String
    $oneId: String
    $familyFileId: Int
  ) {
    updateResidentAge(
      input: {
        formDataId: $formDataId
        age: $age
        oneId: $oneId
        familyFileId: $familyFileId
      }
    ) {
      formDataId
      age
    }
  }
`;
