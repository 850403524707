import { all, put, takeLeading } from 'redux-saga/effects';
import formInitializerService from 'services/callCenter/formInitializerService';
import graphqlClient from 'stateManagement/graphqlClient';
import {
  getScriptBySlugError,
  getScriptBySlugSuccess,
  GET_SCRIPT_BY_SLUG_REQUEST,
  updatePreviewFormSteps,
} from '../actions/scriptPreviewActions';
import findScriptBySlugQuery from '../graphql/findScriptBySlugQuery';

const getScriptBySlug = (slug) => {
  return graphqlClient
    .makeGraphqlRequest({
      query: findScriptBySlugQuery,
      operationName: 'findScriptBySlug',
      variables: { slug },
    })
    .then((response) => response.data.data.findScriptBySlug);
};

function* getScriptPreviewSaga(action) {
  try {
    const script = yield getScriptBySlug(action.payload.slug);
    const formSteps = formInitializerService.initializeForm(script, {});

    yield all([
      put(getScriptBySlugSuccess(script)),
      put(updatePreviewFormSteps(formSteps)),
    ]);
  } catch (err) {
    yield put(getScriptBySlugError(err));
  }
}

function* watchScriptPreview() {
  yield takeLeading(GET_SCRIPT_BY_SLUG_REQUEST, getScriptPreviewSaga);
}

export { watchScriptPreview, getScriptPreviewSaga };
