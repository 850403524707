import gql from 'graphql-tag';

export default gql`
  mutation createKeyNote(
    $familyFileId: Int!
    $noteText: String!
    $updatedAt: Date
  ) {
    createKeyNote(
      input: {
        familyFileId: $familyFileId
        noteText: $noteText
        updatedAt: $updatedAt
      }
    ) {
      keyNoteId
      noteText
      createdAt
      updatedAt
      createdBy
    }
  }
`;
