import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  message_container: {
    position: 'relative',
  },
  header_message: {
    margin: 0,
    paddingLeft: theme.spacing(3),
  },
  header_message_xs: {
    margin: 0,
    fontSize: '20px',
    paddingLeft: theme.spacing(3),
  },
  header_message_sm: {
    margin: 0,
    marginLeft: theme.spacing(2),
    fontSize: '20px',
  },
  header_message_xl: {
    margin: 0,
    marginLeft: theme.spacing(2),
  },
  body_container: {
    display: 'flex',
    position: 'relative',
    '& svg': {
      minWidth: `${theme.spacing(10)}px`,
      fontSize: '80px',
    },
  },
  body_container_xs: {
    '& svg': {
      minWidth: `${theme.spacing(8)}px`,
    },
  },
  body_text: {
    width: '100%',
    marginLeft: theme.spacing(2),
  },
  body_text_sm: {
    width: '100%',
    marginLeft: theme.spacing(2),
    fontSize: '16px',
  },
  body_text_xl: {
    width: '100%',
    marginLeft: theme.spacing(2),
  },
  header_message_bellow_small_size: {},
  message_and_icon: {
    position: 'absolute',
  },
}));

export { useStyles };
