import React from 'react';

const Injured = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.8125 39.8656C37.1653 39.8656 43.9366 32.9755 43.9366 24.4761C43.9366 15.9768 37.1653 9.08667 28.8125 9.08667C20.4598 9.08667 13.6885 15.9768 13.6885 24.4761C13.6885 32.9755 20.4598 39.8656 28.8125 39.8656Z"
        fill="#DDE3F8"
      />
      <path
        d="M10.8745 6.93933H22.1296L28.9348 16.0041L29.6271 17.2628C31.1654 20.0596 30.1784 23.5376 27.7572 25.5499V33.4235H10.8745V6.93933Z"
        fill="#E49B6D"
      />
      <path
        d="M27.7573 34.1387H10.8746C10.4861 34.1387 10.1711 33.8182 10.1711 33.4229V6.93869C10.1711 6.5434 10.4861 6.2229 10.8746 6.2229H22.1297C22.349 6.2229 22.5556 6.32687 22.6888 6.50421L29.4939 15.569C29.5141 15.5958 29.5324 15.6243 29.5488 15.6538L30.2411 16.9123C31.8297 19.8005 31.0749 23.5183 28.4609 25.8796V33.4229C28.4607 33.8182 28.1457 34.1387 27.7573 34.1387ZM11.578 32.7071H27.0538V25.5492C27.0538 25.3343 27.1488 25.1308 27.3123 24.9948C29.6036 23.0903 30.319 19.9854 29.0133 17.6117L28.3453 16.3975L21.7819 7.65448H11.578V32.7071Z"
        fill="#393D49"
      />
      <path
        d="M16.5025 25.5499H4.54395V11.9499C4.54395 9.18262 6.74854 6.93933 9.46806 6.93933H11.5784C14.2979 6.93933 16.5025 9.18262 16.5025 11.9499V25.5499Z"
        fill="#E49B6D"
      />
      <path
        d="M16.5021 26.2654H4.54353C4.15506 26.2654 3.84009 25.9449 3.84009 25.5496V11.9496C3.84009 8.79206 6.36458 6.22327 9.46765 6.22327H11.578C14.6811 6.22327 17.2055 8.79206 17.2055 11.9496V25.5496C17.2055 25.9449 16.8906 26.2654 16.5021 26.2654ZM5.24698 24.8338H15.7987V11.9496C15.7987 9.58157 13.9052 7.65485 11.578 7.65485H9.46765C7.14048 7.65485 5.24698 9.58157 5.24698 11.9496V24.8338Z"
        fill="#393D49"
      />
      <path
        d="M15.0955 25.6287C15.0955 25.6287 5.95068 25.451 5.95068 25.6287V30.5755H15.0955V25.6287Z"
        fill="#F3E0D4"
      />
      <path
        d="M15.0951 31.2906H5.95027C5.56179 31.2906 5.24683 30.9701 5.24683 30.5748V25.628C5.24683 25.4358 5.32526 25.2466 5.45997 25.1121C5.72622 24.8463 5.77423 24.7987 10.5942 24.8461C12.8359 24.8683 15.0859 24.9118 15.1082 24.9124C15.4914 24.9197 15.7983 25.2379 15.7983 25.628V30.5748C15.7985 30.9701 15.4835 31.2906 15.0951 31.2906ZM6.65372 29.859H14.3916V26.3307C11.2488 26.2741 7.94911 26.2443 6.65372 26.2801V29.859Z"
        fill="#393D49"
      />
      <path
        d="M37.554 33.2299L34.9085 32.4803C33.4717 31.295 31.4495 32.5068 29.8893 33.5731L29.8578 33.5946L28.4602 34.5568V41.8813L29.5804 42.6605C30.3096 43.1674 31.2225 43.4444 32.1644 43.4444H38.1326L38.7775 34.6731C38.7775 34.0184 38.2845 33.437 37.554 33.2299Z"
        fill="#F3E0D4"
      />
      <path
        d="M38.1333 44.1603H32.1651C31.0857 44.1603 30.027 43.8375 29.1843 43.2516L28.0639 42.4723C27.8722 42.339 27.7573 42.118 27.7573 41.8814V34.5569C27.7573 34.3193 27.8732 34.0972 28.0667 33.9641L29.4959 32.9802C30.7373 32.1317 33.289 30.3873 35.229 31.8282L37.7431 32.5406C38.7828 32.8351 39.4813 33.6924 39.4813 34.6733C39.4813 34.6911 39.4806 34.7089 39.4794 34.7268L38.8345 43.4982C38.8074 43.8715 38.5014 44.1603 38.1333 44.1603ZM29.1644 41.5035L29.9781 42.0697C30.5892 42.4947 31.366 42.7287 32.1651 42.7287H37.4805L38.0742 34.6518C38.0623 34.3295 37.7807 34.0371 37.3659 33.9195L34.7204 33.1699C34.6275 33.1436 34.541 33.0982 34.4661 33.0364C33.4589 32.205 31.86 33.089 30.2822 34.1674L29.1644 34.9368V41.5035Z"
        fill="#393D49"
      />
      <path
        d="M34.7917 34.8549H41.3572C42.0694 34.8549 42.6468 35.3355 42.6468 35.9285C42.6468 36.5216 42.0694 37.0022 41.3572 37.0022H37.4882"
        fill="#F3E0D4"
      />
      <path
        d="M41.357 37.7174H37.4881C37.0996 37.7174 36.7846 37.3969 36.7846 37.0016C36.7846 36.6063 37.0996 36.2858 37.4881 36.2858H41.357C41.6871 36.2858 41.9432 36.0934 41.9432 35.9279C41.9432 35.7624 41.6871 35.57 41.357 35.57H34.7916C34.4031 35.57 34.0881 35.2495 34.0881 34.8542C34.0881 34.4589 34.4031 34.1384 34.7916 34.1384H41.357C42.456 34.1384 43.35 34.9412 43.35 35.9279C43.35 36.9146 42.4561 37.7174 41.357 37.7174Z"
        fill="#393D49"
      />
      <path
        d="M36.0815 37.0022H42.0023C42.7145 37.0022 43.2919 37.4829 43.2919 38.0759C43.2919 38.6689 42.7145 39.1496 42.0023 39.1496H36.0815"
        fill="#F3E0D4"
      />
      <path
        d="M42.0021 39.8657H36.0814C35.6929 39.8657 35.3779 39.5452 35.3779 39.1499C35.3779 38.7546 35.6929 38.4341 36.0814 38.4341H42.0021C42.3322 38.4341 42.5882 38.2417 42.5882 38.0762C42.5882 37.9107 42.3322 37.7183 42.0021 37.7183H36.0814C35.6929 37.7183 35.3779 37.3978 35.3779 37.0025C35.3779 36.6072 35.6929 36.2867 36.0814 36.2867H42.0021C43.1012 36.2867 43.9951 37.0895 43.9951 38.0762C43.9951 39.0629 43.1012 39.8657 42.0021 39.8657Z"
        fill="#393D49"
      />
      <path
        d="M37.4883 39.1497H42.6468C43.3591 39.1497 43.9364 39.6201 43.9364 40.2006C43.9364 40.7811 43.3591 41.2516 42.6468 41.2516H37.4883"
        fill="#F3E0D4"
      />
      <path
        d="M42.6469 41.9669H37.4884C37.0999 41.9669 36.7849 41.6464 36.7849 41.2511C36.7849 40.8558 37.0999 40.5353 37.4884 40.5353H42.6469C42.9993 40.5353 43.233 40.3336 43.233 40.2001C43.233 40.0666 42.9991 39.8649 42.6469 39.8649H37.4884C37.0999 39.8649 36.7849 39.5444 36.7849 39.1491C36.7849 38.7538 37.0999 38.4333 37.4884 38.4333H42.6469C43.746 38.4333 44.6399 39.2259 44.6399 40.2001C44.6399 41.1743 43.7459 41.9669 42.6469 41.9669Z"
        fill="#393D49"
      />
      <path
        d="M29.8035 33.4696H15.0955V30.575H5.95068V39.8237C5.95068 41.4285 7.22796 42.7301 8.80509 42.7326L29.8678 42.7648V33.4138L29.8035 33.4696Z"
        fill="#FEFEFE"
      />
      <path
        d="M29.8674 43.4809C29.8671 43.4809 29.8667 43.4809 29.8664 43.4809L8.80362 43.4485C6.84242 43.4456 5.24683 41.8195 5.24683 39.8239V30.5752C5.24683 30.1799 5.56179 29.8594 5.95027 29.8594H15.0951C15.4835 29.8594 15.7985 30.1799 15.7985 30.5752V32.754H29.5948C29.7742 32.6773 29.9798 32.6785 30.1621 32.764C30.4113 32.8809 30.571 33.1346 30.571 33.414V42.7652C30.571 42.9553 30.4968 43.1375 30.3646 43.2717C30.2325 43.4055 30.0538 43.4809 29.8674 43.4809ZM6.65372 31.291V39.8239C6.65372 41.0314 7.61919 42.0151 8.80591 42.0171L29.164 42.0482V34.1856H15.0951C14.7066 34.1856 14.3916 33.8651 14.3916 33.4698V31.291H6.65372Z"
        fill="#393D49"
      />
      <path
        d="M34.2639 43.4443H40.7122C41.4245 43.4443 42.0018 42.9534 42.0018 42.3479C42.0018 41.7423 41.4245 41.2515 40.7122 41.2515H36.1984"
        fill="#F3E0D4"
      />
      <path
        d="M40.7121 44.1603H34.2637C33.8753 44.1603 33.5603 43.8398 33.5603 43.4445C33.5603 43.0492 33.8753 42.7287 34.2637 42.7287H40.7121C41.0476 42.7287 41.2982 42.5278 41.2982 42.3481C41.2982 42.1684 41.0474 41.9675 40.7121 41.9675H36.1982C35.8097 41.9675 35.4948 41.647 35.4948 41.2517C35.4948 40.8564 35.8097 40.5359 36.1982 40.5359H40.7121C41.8112 40.5359 42.7051 41.3488 42.7051 42.3481C42.7051 43.3473 41.811 44.1603 40.7121 44.1603Z"
        fill="#393D49"
      />
      <path
        d="M5.95107 39.1498C5.69536 39.1498 5.44881 39.0076 5.3243 38.7601C5.1472 38.4081 5.28402 37.9768 5.62977 37.7966L15.1571 32.8328C15.5028 32.6524 15.9268 32.7918 16.1039 33.1436C16.281 33.4955 16.1442 33.9269 15.7984 34.1071L6.27131 39.0709C6.16861 39.1244 6.05904 39.1498 5.95107 39.1498Z"
        fill="#393D49"
      />
      <path
        d="M13.6875 43.4442C13.5505 43.4442 13.4123 43.4036 13.2908 43.3192C12.9702 43.0958 12.8882 42.6504 13.1077 42.324L19.0666 33.467C19.286 33.141 19.7239 33.0576 20.0445 33.2807C20.3651 33.5041 20.4471 33.9495 20.2276 34.2759L14.2685 43.1329C14.1323 43.3354 13.9117 43.4442 13.6875 43.4442Z"
        fill="#393D49"
      />
      <path
        d="M20.0196 43.4447C19.7482 43.4447 19.4897 43.284 19.3743 43.0147L16.8841 37.2005C16.729 36.8384 16.8919 36.4164 17.248 36.2584C17.6043 36.1007 18.0186 36.2663 18.1739 36.6286L20.6641 42.4428C20.8192 42.805 20.6564 43.227 20.3002 43.385C20.2088 43.4256 20.1135 43.4447 20.0196 43.4447Z"
        fill="#393D49"
      />
      <path
        d="M24.2401 43.4442C23.8516 43.4442 23.5366 43.1237 23.5366 42.7285V33.4232C23.5366 33.0279 23.8516 32.7074 24.2401 32.7074C24.6285 32.7074 24.9435 33.0279 24.9435 33.4232V42.7285C24.9435 43.1237 24.6285 43.4442 24.2401 43.4442Z"
        fill="#393D49"
      />
      <path
        d="M31.978 43.4432L31.9823 43.4302C31.9636 43.4296 31.9452 43.4271 31.9265 43.4263L31.978 43.4432Z"
        fill="white"
      />
      <path
        d="M31.9668 44.1575C31.8954 44.1575 31.824 44.1464 31.7554 44.1231L31.7039 44.1056C31.3698 43.9921 31.1695 43.6446 31.2354 43.2925C31.3014 42.9403 31.6139 42.6921 31.9651 42.7118L32.0132 42.7155C32.2341 42.7238 32.4358 42.8372 32.5621 43.0219C32.6884 43.2064 32.7195 43.4408 32.6504 43.6545C32.5918 43.8352 32.4629 43.9916 32.2959 44.077C32.1933 44.1294 32.0801 44.1575 31.9668 44.1575Z"
        fill="#393D49"
      />
      <path
        d="M34.9088 32.4803C33.4721 31.295 31.4498 32.5068 29.8896 33.5731L29.8674 33.5884V42.7286L31.9262 43.4269C31.9449 43.4278 31.9633 43.4303 31.982 43.431L35.415 32.6237L34.9088 32.4803Z"
        fill="white"
      />
      <path
        d="M31.9825 44.1472C31.9739 44.1472 31.9653 44.147 31.9566 44.1466C31.9341 44.1457 31.9118 44.1441 31.8895 44.1422C31.8268 44.1386 31.7635 44.1264 31.7041 44.1064L29.6453 43.4081C29.3581 43.3104 29.1643 43.037 29.1643 42.7288V33.5886C29.1643 33.3506 29.2806 33.1283 29.4744 32.9952L29.4965 32.98C30.7372 32.1317 33.2895 30.3874 35.2291 31.8281L35.604 31.9344C35.7885 31.9868 35.9441 32.1133 36.0349 32.2851C36.1256 32.4567 36.1435 32.6586 36.0846 32.8441L32.6516 43.6517C32.5576 43.9478 32.2865 44.1472 31.9825 44.1472ZM30.5712 42.2129L31.5272 42.5372L34.5302 33.0836C34.508 33.0691 34.4866 33.0533 34.4662 33.0365C33.5204 32.2563 32.053 32.9875 30.5712 33.9724V42.2129Z"
        fill="#393D49"
      />
      <path
        d="M37.6055 23.4018C37.2171 23.4018 36.9021 23.0813 36.9021 22.686V20.5387C36.9021 20.1434 37.2171 19.8229 37.6055 19.8229C37.994 19.8229 38.309 20.1434 38.309 20.5387V22.686C38.309 23.0813 37.994 23.4018 37.6055 23.4018Z"
        fill="#393D49"
      />
      <path
        d="M37.6055 28.4122C37.2171 28.4122 36.9021 28.0917 36.9021 27.6964V25.549C36.9021 25.1537 37.2171 24.8333 37.6055 24.8333C37.994 24.8333 38.309 25.1537 38.309 25.549V27.6964C38.309 28.0917 37.994 28.4122 37.6055 28.4122Z"
        fill="#393D49"
      />
      <path
        d="M36.1988 24.8338H34.0885C33.7 24.8338 33.385 24.5133 33.385 24.118C33.385 23.7227 33.7 23.4022 34.0885 23.4022H36.1988C36.5873 23.4022 36.9022 23.7227 36.9022 24.118C36.9022 24.5133 36.5873 24.8338 36.1988 24.8338Z"
        fill="#393D49"
      />
      <path
        d="M41.1226 24.8338H39.0123C38.6238 24.8338 38.3088 24.5133 38.3088 24.118C38.3088 23.7227 38.6238 23.4022 39.0123 23.4022H41.1226C41.5111 23.4022 41.8261 23.7227 41.8261 24.118C41.8261 24.5133 41.5111 24.8338 41.1226 24.8338Z"
        fill="#393D49"
      />
      <path
        d="M25.6173 17.6757C26.0058 17.6757 26.3207 17.3553 26.3207 16.9599C26.3207 16.5646 26.0058 16.2441 25.6173 16.2441C25.2288 16.2441 24.9138 16.5646 24.9138 16.9599C24.9138 17.3553 25.2288 17.6757 25.6173 17.6757Z"
        fill="#393D49"
      />
      <path
        d="M29.8678 10.5174C30.2563 10.5174 30.5712 10.1969 30.5712 9.8016C30.5712 9.40628 30.2563 9.08582 29.8678 9.08582C29.4793 9.08582 29.1643 9.40628 29.1643 9.8016C29.1643 10.1969 29.4793 10.5174 29.8678 10.5174Z"
        fill="#393D49"
      />
      <path
        d="M39.3642 8.37051C38.0065 8.37051 36.9021 7.24672 36.9021 5.86525C36.9021 4.48378 38.0065 3.35999 39.3642 3.35999C40.7218 3.35999 41.8262 4.48378 41.8262 5.86525C41.8262 7.24672 40.7218 8.37051 39.3642 8.37051ZM39.3642 4.79156C38.7822 4.79156 38.309 5.27311 38.309 5.86525C38.309 6.45739 38.7822 6.93893 39.3642 6.93893C39.9461 6.93893 40.4193 6.45739 40.4193 5.86525C40.4193 5.27311 39.9461 4.79156 39.3642 4.79156Z"
        fill="#393D49"
      />
    </svg>
  );
};

export default Injured;
