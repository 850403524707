import { makeStyles } from '../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    button: {
      borderRadius: '2px',
      transition: 'background 0.8s',
      boxShadow: `0px 2px 4px ${theme.palette.light.boxShadow}`,
      '& span': {
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontFamily: 'Poppins',
        lineHeight: 'normal',
        letterSpacing: 'initial',
        textTransform: 'initial',
      },
      '& .MuiButton-startIcon svg': {
        fontSize: '24px',
      },
    },
    primary: {
      backgroundColor: theme.palette.neutral.cosmicCobalt3,
      color: theme.palette.light.platinum5,
      '&:hover': {
        backgroundColor: theme.palette.neutral.cosmicCobalt2,
      },
      '& svg': {
        color: theme.palette.light.platinum5,
        fill: theme.palette.light.platinum5,
      },
    },
    secondary: {
      backgroundColor: theme.palette.positive.wintergreenDream2,
      color: theme.palette.light.platinum5,
      '&:hover': {
        backgroundColor: theme.palette.positive.wintergreenDream1,
      },
      '& svg': {
        color: theme.palette.light.platinum5,
        fill: theme.palette.light.platinum5,
      },
    },
    warning: {
      backgroundColor: theme.palette.correction.smokyTopaz2,
      color: theme.palette.light.platinum5,
      '&:hover': {
        backgroundColor: theme.palette.correction.smokyTopaz1,
      },
      '& svg': {
        color: theme.palette.light.platinum5,
        fill: theme.palette.light.platinum5,
      },
    },
    misc: {
      backgroundColor: theme.palette.miscellaneous1.hotCinnamon3,
      color: theme.palette.light.platinum5,
      '&:hover': {
        backgroundColor: theme.palette.miscellaneous1.hotCinnamon1,
      },
      '& svg': {
        color: theme.palette.light.platinum5,
        fill: theme.palette.light.platinum5,
      },
    },
    light: {
      backgroundColor: theme.palette.light.platinum4,
      color: theme.palette.dark.eerieBlack1,
      '&:hover': {
        backgroundColor: theme.palette.light.platinum2,
        color: theme.palette.neutral.cosmicCobalt2,
      },
      '& svg': {
        color: theme.palette.dark.eerieBlack1,
        fill: theme.palette.dark.eerieBlack1,
      },
    },
    disabled: {
      backgroundColor: theme.palette.light.platinum1,
      color: theme.palette.dark.eerieBlack5,
      boxShadow: 'none',
      '&.Mui-disabled': {
        color: theme.palette.dark.eerieBlack5,
      },
      '& svg': {
        color: theme.palette.dark.eerieBlack5,
        fill: theme.palette.dark.eerieBlack5,
      },
    },
    outlined: {
      backgroundColor: theme.palette.light.platinum5,
      border: `1px solid ${theme.palette.neutral.cosmicCobalt3}`,
      color: theme.palette.neutral.cosmicCobalt2,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: theme.palette.neutral.cosmicCobalt2,
        color: theme.palette.light.platinum5,
        '& svg': {
          color: theme.palette.light.platinum5,
          fill: theme.palette.light.platinum5,
        },
      },
      '& svg': {
        color: theme.palette.neutral.cosmicCobalt2,
        fill: theme.palette.neutral.cosmicCobalt2,
      },
    },
    outlinedWarning: {
      backgroundColor: theme.palette.light.platinum5,
      border: `1px solid ${theme.palette.correction.smokyTopaz3}`,
      color: theme.palette.correction.smokyTopaz2,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: theme.palette.correction.smokyTopaz2,
        color: theme.palette.light.platinum5,
        '& svg': {
          color: theme.palette.light.platinum5,
          fill: theme.palette.light.platinum5,
        },
      },
      '& svg': {
        color: theme.palette.correction.smokyTopaz2,
        fill: theme.palette.correction.smokyTopaz2,
      },
    },
    outlinedDisabled: {
      backgroundColor: theme.palette.light.platinum5,
      border: `1px solid ${theme.palette.dark.disabled}`,
      color: theme.palette.dark.disabled,
      boxShadow: 'none',
      '& svg': {
        color: theme.palette.dark.disabled,
        fill: theme.palette.dark.disabled,
      },
    },
    tiny: {
      padding: '0.5rem 0.5rem',
      fontSize: '12px',
    },
    small: {
      padding: '0.5rem 1rem',
      fontSize: '14px',
    },
    medium: {
      padding: '0.5rem 1.5rem',
      fontSize: '16px',
    },
    large: {
      padding: '0.75rem 1.5rem',
      fontSize: '18px',
    },
  };
});

export { useStyles };
