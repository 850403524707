import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/Modal/Modal';
import Dollar from 'common/Icons/finance/Dollar';
import Typography from 'common/Typography/Typography';
import Table from 'common/Table/Table/Table';
import TableRow from 'common/Table/TableRow/TableRow';
import TableCell from 'common/Table/TableCell/TableCell';
import TableBody from 'common/Table/TableBody/TableBody';
import { useStyles } from './CommunityPriceRange.style';
import { toDecimalCurrency } from 'utils/currencyFormat';
import {
  hasHomeCareType,
  hasNonHomeCareType,
} from './CommunityPriceRangeService';
import {
  getNonHomeCareTypeDetails,
  getHomeCareTypeDetails,
} from './RoomChargesModalService';

const CARE_TYPE_CODE_HOMECARE = 'H';

const RoomChargesModal = ({
  careTypeNames,
  careTypes,
  careTypeStyle,
  communityName,
  isModalOpen,
  labels,
  onClose,
  roomCharges,
  roomFees,
}) => {
  const classes = useStyles(careTypeStyle);

  const [homeCareTypeExists, setHomeCareTypeExists] = useState(false);
  const [nonHomeCareTypeExists, setNonHomeCareTypeExists] = useState(true);
  useEffect(() => {
    setHomeCareTypeExists(hasHomeCareType(careTypes));
    setNonHomeCareTypeExists(hasNonHomeCareType(careTypes));
  }, [careTypes]);

  const [
    nonHomeCareFees,
    nonHomeCareTypeCodes,
    nonHomeCareApartmentTypes,
    nonHomeCareFeeTypes,
  ] = getNonHomeCareTypeDetails(careTypes, roomCharges, roomFees);

  const [homeCareFees, homeCareFeeTypes] = getHomeCareTypeDetails(roomFees);

  let emptyHomeCareTableColumnCount = 0;
  if (homeCareTypeExists && nonHomeCareTypeExists) {
    emptyHomeCareTableColumnCount = nonHomeCareTypeCodes.length - 1;
  }

  const getTitle = () => {
    if (nonHomeCareTypeExists) {
      return `${labels.ROOM_CHARGES} ${labels.AND} ${labels.FEES}`;
    }
    return labels.HOME_CARE_RATES;
  };

  const getAmount = (apartmentType, careType) => {
    const chargeRoom = roomCharges.find(
      (room) =>
        room.careType === careType && room.apartmentType === apartmentType,
    );
    if (chargeRoom) {
      return (
        <Typography>{`${toDecimalCurrency(chargeRoom.amount)} ${
          labels.MONTHLY
        }`}</Typography>
      );
    }
    return <Typography color="disabled">{labels.NOT_AVAILABLE}</Typography>;
  };

  const getFeeAmount = (fees, feeType, careType) => {
    const fee = fees.find(
      (fee) => fee.careType === careType && fee.feeType === feeType,
    );
    if (fee) {
      return (
        <Typography>
          {toDecimalCurrency(fee.amount)} {fee.suffix}
        </Typography>
      );
    }
    return <Typography color="disabled">{labels.NOT_AVAILABLE}</Typography>;
  };

  const HomeCareDetails = () => {
    return (
      <>
        <TableRow className={classes.section_header}>
          <TableCell>
            <Typography className={classes.column_header} color="eerieBlack5">
              {labels.CARE_RATES}
            </Typography>
          </TableCell>
          <TableCell>
            <div className={classes.column_header}>
              <Typography level="tiny" className={classes.care_type_tag}>
                {'H'}
              </Typography>
              <Typography color="eerieBlack5">
                {careTypeNames[CARE_TYPE_CODE_HOMECARE]}
              </Typography>
            </div>
          </TableCell>
          {[...Array(emptyHomeCareTableColumnCount)].map((item, key) => (
            <TableCell key={key}></TableCell>
          ))}
        </TableRow>

        {homeCareFees.length === 0 && (
          <TableRow>
            <TableCell>
              <Typography>{labels.NOT_AVAILABLE}</Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        )}

        {homeCareFeeTypes.map((feeType, key) => (
          <TableRow key={key}>
            <TableCell>
              <Typography>
                <>{feeType}</>
              </Typography>
            </TableCell>
            <TableCell>
              {getFeeAmount(homeCareFees, feeType, CARE_TYPE_CODE_HOMECARE)}
            </TableCell>
            {[...Array(emptyHomeCareTableColumnCount)].map((item, key) => (
              <TableCell key={key}></TableCell>
            ))}
          </TableRow>
        ))}
      </>
    );
  };

  const NonHomeCareTable = () => {
    return (
      <>
        <Table>
          <TableBody>
            {homeCareFees.length > 0 && (
              <>
                <TableRow className={classes.section_title}>
                  <TableCell colSpan={8}>
                    <Typography bold level="body">
                      {labels.HOME_CARE_RATES}
                    </Typography>
                  </TableCell>
                </TableRow>
                <HomeCareDetails />
              </>
            )}

            {roomCharges.length > 0 && (
              <TableRow className={classes.section_title}>
                <TableCell colSpan={8}>
                  <Typography bold level="body">
                    {labels.ROOM_CHARGES}
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            <TableRow className={classes.section_header}>
              <TableCell>
                <Typography
                  className={classes.column_header}
                  color="eerieBlack5"
                >
                  {labels.ROOM_TYPES}
                </Typography>
              </TableCell>
              {nonHomeCareTypeCodes.map((careTypeCode, key) => (
                <TableCell key={key}>
                  <div className={classes.column_header}>
                    <Typography level="tiny" className={classes.care_type_tag}>
                      {careTypeCode}
                    </Typography>
                    <Typography color="eerieBlack5">
                      {careTypeNames[careTypeCode]}
                    </Typography>
                  </div>
                </TableCell>
              ))}
            </TableRow>

            {nonHomeCareApartmentTypes.map(
              (apartmentType, apartmentTypeKey) => (
                <TableRow key={apartmentTypeKey}>
                  <TableCell>
                    <Typography>
                      <>{apartmentType}</>
                    </Typography>
                  </TableCell>
                  {nonHomeCareTypeCodes.map((careTypeCode, careTypeKey) => (
                    <TableCell key={careTypeKey}>
                      {getAmount(apartmentType, careTypeCode)}
                    </TableCell>
                  ))}
                </TableRow>
              ),
            )}
            {nonHomeCareFees.length > 0 && (
              <TableRow className={classes.section_title}>
                <TableCell colSpan={8}>
                  <Typography bold level="body">
                    {labels.FEES}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {nonHomeCareFeeTypes.map((feeType, key) => (
              <TableRow key={key}>
                <TableCell>
                  <Typography>
                    <>{feeType}</>
                  </Typography>
                </TableCell>
                {nonHomeCareTypeCodes.map((careTypeCode, key) => (
                  <TableCell key={key}>
                    {getFeeAmount(roomFees, feeType, careTypeCode)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    );
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onClose}
      icon={<Dollar />}
      title={getTitle()}
    >
      <div className={classes.modal_body}>
        <Typography className={classes.modal_title} bold level="large">
          {communityName}
        </Typography>
        <div>
          {!nonHomeCareTypeExists && homeCareTypeExists && (
            <>
              <Table>
                <TableBody>
                  <HomeCareDetails />
                </TableBody>
              </Table>
            </>
          )}
          {nonHomeCareTypeExists && <NonHomeCareTable />}
        </div>
      </div>
    </Modal>
  );
};

RoomChargesModal.propTypes = {
  careTypeNames: PropTypes.object,
  careTypes: PropTypes.object.isRequired,
  careTypeStyle: PropTypes.oneOf(['text', 'tag', 'map']),
  communityName: PropTypes.string,
  isModalOpen: PropTypes.bool,
  labels: PropTypes.object,
  onClose: PropTypes.func,
  roomCharges: PropTypes.array,
  roomFees: PropTypes.array,
};

RoomChargesModal.defaultProps = {
  isModalOpen: false,
  onClose: () => {},
  communityName: '',
  careTypeStyle: 'tag',
};

export default RoomChargesModal;
