import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from './CareTypeFilter.style';
import ChevronBottom from '../../Icons/arrow/ChevronBottom';
import ChevronTop from '../../Icons/arrow/ChevronTop';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Checkbox from '../../Checkbox/Checkbox';
import Divider from 'common/Divider/Divider';
import Button from 'common/Button/Button';

const tryJsonParse = (elements) => {
  try {
    const parsedElements = JSON.parse(JSON.stringify(elements));
    return [true, parsedElements];
  } catch (error) {
    console.error(
      `CareTypeFilter. JSON.parse failed. Error: ${error}. Data: ${elements}`,
    );
    return [false];
  }
};

const CareTypeFilter = ({
  careTypeCodes,
  labels,
  onChange,
  disabled,
  className,
}) => {
  const initialElements = [
    {
      label: `${labels.MEMORY_CARE} (Z)`,
      value: 'memoryCare',
      checked: false,
      code: 'Z',
    },
    {
      label: `${labels.ASSISTED_LIVING} (A)`,
      value: 'assistedLiving',
      checked: false,
      code: 'A',
    },
    {
      label: `${labels.NURSING_HOME} (N)`,
      value: 'nursingHome',
      checked: false,
      code: 'N',
    },
    {
      label: `${labels.SENIOR_APARTMENTS} (P)`,
      value: 'seniorApartments',
      checked: false,
      code: 'P',
    },
    {
      label: `${labels.ADULT_DAY_SERVICES} (D)`,
      value: 'adultDayServices',
      checked: false,
      code: 'D',
    },
    {
      label: `${labels.HOME_CARE} (H)`,
      value: 'homeCare',
      checked: false,
      code: 'H',
    },
    {
      label: `${labels.RESIDENTIAL_CARE_HOME} (G)`,
      value: 'residentialCareHome',
      checked: false,
      code: 'G',
    },
    {
      label: `${labels.RETIREMENT_COMMUNITY} (R)`,
      value: 'independentLiving',
      checked: false,
      code: 'R',
    },
  ];
  const classes = useStyles();
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [elements, setElements] = useState(initialElements);
  const [selectedText, setSelectedText] = useState('');
  const [previousElements, setPreviousElements] = useState([]);

  const selectClasses = clsx([
    className,
    classes.custom_select,
    disabled && classes.disabled,
    elements.filter((element) => element.checked).length > 0 && classes.filled,
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (isOpen) {
          const [isParsed, prevElements] = tryJsonParse(previousElements);
          if (isParsed) {
            setElements(prevElements);
          } else {
            setElements(initialElements);
          }
        }
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef, previousElements, isOpen]);

  useEffect(() => {
    elements.forEach((e) => (e.checked = false));
    careTypeCodes.forEach((careTypeCode) => {
      const element = elements.find((element) => {
        return element.code === careTypeCode;
      });
      if (element) {
        element.checked = true;
      }
    });

    setElements(elements);
    const [isParsed, prevElements] = tryJsonParse(elements);
    if (isParsed) {
      setPreviousElements(prevElements);
    } else {
      setPreviousElements(initialElements);
    }

    setSelectedText(determineSelectedText());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careTypeCodes]);

  useEffect(() => {
    if (!isOpen) {
      const selectedItems = elements.filter((element) => element.checked);
      onChange(selectedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements, isOpen]);

  useEffect(() => {
    setSelectedText(determineSelectedText());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements]);

  const handleOptionList = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const determineSelectedText = () => {
    const selectedItems = elements.filter((element) => {
      return element.checked;
    });

    if (selectedItems.length > 1) {
      return `${labels.SELECTED} (${selectedItems.length})`;
    }
    if (selectedItems.length === 1) {
      return selectedItems[0].label;
    }
    return labels.PLEASE_SELECT;
  };

  const handleCheckboxChange = (e, item) => {
    item.checked = e.target.checked;
    setElements([...elements]);
  };

  const getSelectAllText = () => {
    return isAllSelected ? labels.DESELECT_ALL : labels.SELECT_ALL;
  };

  const handleSelectAllOnChange = (e) => {
    const value = e.target.checked;
    setIsAllSelected(value);
    const newElements = elements.map((element) => {
      element.checked = value;
      return element;
    });
    setElements(newElements);
  };

  const handleApply = () => {
    const [isParsed, prevElementsUpdate] = tryJsonParse(elements);
    if (isParsed) {
      const selectedItems = elements.filter((element) => element.checked);
      onChange(selectedItems);

      setPreviousElements(prevElementsUpdate);
      setIsOpen(false);
    } else {
      setPreviousElements(initialElements);
    }
  };

  const handleClose = () => {
    const [isParsed, prevElements] = tryJsonParse(previousElements);
    if (isParsed) {
      setElements(prevElements);
      setIsOpen(false);
    } else {
      setElements(initialElements);
    }
  };

  return (
    <div className={selectClasses} ref={wrapperRef} data-testid="dropdown-id">
      <div className={classes.label}>{labels.CARE_TYPES}</div>
      <div className={classes.select} onClick={handleOptionList} tabIndex="0">
        <div>{selectedText}</div>
        <div className={classes.icon}>
          {isOpen ? <ChevronTop /> : <ChevronBottom />}
        </div>
      </div>
      {isOpen && !disabled && (
        <div className={classes.menu}>
          <div className={classes.items_container}>
            <Checkbox
              className={classes.checkbox}
              indeterminate={isAllSelected}
              checked={isAllSelected}
              onChange={handleSelectAllOnChange}
              label={getSelectAllText()}
            />
            {elements.map((item, key) => (
              <div key={key}>
                <div className={classes.group_item} key={key}>
                  <Checkbox
                    className={clsx(
                      classes.checkbox,
                      item.checked && classes.selected,
                    )}
                    checked={item.checked}
                    label={item.label}
                    onChange={(e) => handleCheckboxChange(e, item)}
                  />
                </div>
              </div>
            ))}
            <Divider className={classes.divider} color="platinum1" />
            <div className={classes.actions_container}>
              <div className={clsx(classes.actions)}>
                <Button size="small" type="light" onClick={handleClose}>
                  {labels.CLOSE}
                </Button>
                <Button type="secondary" size="small" onClick={handleApply}>
                  {labels.APPLY}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

CareTypeFilter.propTypes = {
  careTypeCodes: PropTypes.array,
  labels: PropTypes.object,
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

CareTypeFilter.defaultProps = {
  careTypeCodes: [],
  disabled: false,
  className: '',
  onChange: () => {},
};

export default CareTypeFilter;
