import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  container: {
    '& p': {
      margin: 0,
    },
    marginTop: theme.spacing(4),
    '&.send_referral_container': {
      marginTop: theme.spacing(0),
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  row_phone: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    paddingTop: theme.spacing(2),
  },
  column: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    '& > p, div, svg': {
      marginTop: '3px',
      lineHeight: '15px',
    },
    '& p, div': {
      marginTop: '3px',
      lineHeight: '15px',
    },
    '& p:first-child, div:first-child': {
      marginTop: '0',
    },
  },

  icon: {
    color: theme.palette.neutral.cosmicCobalt3,
    paddingRight: theme.spacing(2),
  },
  actionicon: {
    color: theme.palette.dark.eerieBlack1,
    paddingRight: theme.spacing(1),
    width: '24px',
    height: '24px',
  },
  actionItem: {
    display: 'flex',
    textAlign: 'left',
    cursor: 'pointer',
    color: theme.palette.dark.eerieBlack1,
    '& div': {
      padding: '9px 16px',
    },
    '& p': {
      padding: '9px 16px',
    },
    '&:hover': {
      backgroundColor: theme.palette.neutral.dropdown,
    },
    '& a, .link': {
      'text-decoration': 'none',
      color: theme.palette.dark.eerieBlack1,
    },
    '& svg': {
      fontSize: '24px',
      color: theme.palette.dark.eerieBlack1,
      marginRight: '12px',
    },
    padding: 0,
  },
  label: {
    paddingRight: theme.spacing(1),
  },
  inline: {
    display: 'inline',
    paddingRight: '25px',
    '& p': {
      display: 'inline',
    },
    '& div': {
      marginRight: theme.spacing(1),
    },
  },

  column_name: {
    display: 'inline-flex',
    paddingTop: '2px',
    paddingRight: '4px',
  },

  divider: {
    height: '1px',
    margin: '16px 0',
    position: 'relative',
    left: theme.spacing(-3),
    width: 'calc(100% + 48px)',

    backgroundColor: theme.palette.light.platinum1,
  },

  contact: {
    display: 'inline-flex',
    alignItems: 'center',
    '& svg': {
      fontSize: theme.spacing(3),
      marginRight: '0px',
      paddingRight: '4px',
    },
    cursor: 'pointer',
  },

  pointer_show: {
    cursor: 'pointer',
  },
  toast_error: {
    marginBottom: '16px',
    width: '100%',
    fontSize: '13px',
    alignItems: 'center',
    '& div:nth-child(2)': {
      marginLeft: '12px',
    },
  },
}));

export { useStyles };
