import React from 'react';
import PropTypes from 'prop-types';

const SelectedHighlighted = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.0008 53.6667L2.33331 2.33331L28.0008 17.0191L53.6666 2.33331L28.0008 53.6667Z"
        fill="#D8A600"
      />
      <path
        d="M28.0008 53.6667L28.0008 17.0191L53.6667 2.33331L28.0008 53.6667Z"
        fill="#E9CE73"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.0008 17.0191L2.33331 2.33331L28.0008 53.6666L53.6666 2.33331L28.0008 17.0191ZM47.6783 8.66027L28.0008 19.9196L8.32177 8.6601L28.0007 48.0167L47.6783 8.66027Z"
        fill="#0D1321"
      />
    </svg>
  );
};

SelectedHighlighted.propTypes = {
  className: PropTypes.string,
};

export default SelectedHighlighted;
