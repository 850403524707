import React from 'react';
import Typography from 'common/Typography/Typography';
import Container from 'common/Container/Container';
import ContainerItem from 'common/ContainerItem/ContainerItem';
import { useStyles } from './MetricHeader.style';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isSameSize from 'utils/sizesHelper';

const MetricHeader = ({ metricTitle, size, labels }) => {
  const classes = useStyles();
  const useMDDownClass = isSameSize(size, 'xs') || isSameSize(size, 'md');
  const useSMDownClass = isSameSize(size, 'xs') || isSameSize(size, 'sm');

  const selectClassesForMonthToDate = clsx([
    classes.month_to_date,
    useMDDownClass ? classes.month_to_date_bellow_medium_size : '',
  ]);

  const selectClassesForTitle = clsx([
    classes.metrics_header_container,
    useSMDownClass ? classes.metrics_header_container_bellow_small_size : '',
  ]);

  return (
    <Container container className={selectClassesForTitle}>
      <ContainerItem
        className={selectClassesForMonthToDate}
        xs={12}
        sm={12}
        md={12}
        lg={4}
      >
        <Typography level="small">
          {`${labels.MONTHS[new Date(Date.now()).getMonth()]} ${new Date(
            Date.now(),
          ).getFullYear()}`}
        </Typography>
      </ContainerItem>
      <ContainerItem
        className={classes.metric_header_title}
        xs={12}
        sm={12}
        md={12}
        lg={8}
      >
        <Typography>
          <b>{metricTitle}</b>
        </Typography>
      </ContainerItem>
    </Container>
  );
};

MetricHeader.propTypes = {
  metricTitle: PropTypes.string,
  size: PropTypes.string,
  labels: PropTypes.object,
};

export default MetricHeader;
