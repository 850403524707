import gql from 'graphql-tag';

export default gql`
  mutation(
    $oneId: String!
    $firstName: String!
    $lastName: String!
    $email: String
    $phone: String!
    $findMatchedContacts: Boolean
    $contactsToMerge: [String]
    $isManualInquiry: Boolean
  ) {
    updateInquiryContact(
      input: {
        oneId: $oneId
        email: $email
        phone: $phone
        firstName: $firstName
        lastName: $lastName
        findMatchedContacts: $findMatchedContacts
        contactsToMerge: $contactsToMerge
        isManualInquiry: $isManualInquiry
      }
    ) {
      errors
      updatedContact {
        oneId
        firstName
        lastName
        phone
        email
      }
      createdContact {
        inquiryContactId
        oneId
        firstName
        lastName
        phone
        email
        updatedAt
      }
      matchedContacts {
        oneId
        inquiryContactId
        firstName
        lastName
        phone
        email
        updatedAt
        inquiries {
          inquiryId
          status
          updatedAt
        }
      }
    }
  }
`;
