import gql from 'graphql-tag';

export default gql`
  mutation updateTourStatus($input: updateTourStatusInput!) {
    updateTourStatus(input: $input) {
      familyCommunityTourId
      familyCommunityTourActivityId
      activityDate
      tourType
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
