import gql from 'graphql-tag';

export default gql`
  mutation upsertReferralNotes($input: UpsertReferralNotesInput!) {
    upsertReferralNotes(input: $input) {
      familyFileId
      summaryNote {
        referralNoteId
        note
      }
      propertyNotes {
        referralNoteId
        note
        communityId
      }
    }
  }
`;
