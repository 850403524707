import {
  GET_SCRIPTS_ERROR,
  GET_SCRIPTS_REQUEST,
  GET_SCRIPTS_SUCCESS,
} from '../actions/scriptBuilderActions';

const scriptBuilderReducer = (
  state = { loading: false, error: null, data: null },
  action,
) => {
  switch (action.type) {
    case GET_SCRIPTS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_SCRIPTS_SUCCESS:
      return {
        loading: false,
        error: null,
        data: action.payload.response,
      };
    case GET_SCRIPTS_ERROR:
      return {
        loading: false,
        error: action.payload.error,
        data: null,
      };
    default:
      return state;
  }
};

export const selectScripts = (state) => ({
  scriptsLoading: state?.admin?.scripts?.loading,
  scriptsError: state?.admin?.scripts?.error,
  scripts: state?.admin?.scripts?.data,
});

export default scriptBuilderReducer;
