export default [
  {
    label: '08:00 AM',
    value: '08:00AM',
    isMorning: true,
  },
  {
    label: '08:30 AM',
    value: '08:30AM',
    isMorning: true,
  },
  {
    label: '09:00 AM',
    value: '09:00AM',
    isMorning: true,
  },
  {
    label: '09:30 AM',
    value: '09:30AM',
    isMorning: true,
  },
  {
    label: '10:00 AM',
    value: '10:00AM',
    isMorning: true,
  },
  {
    label: '10:30 AM',
    value: '10:30AM',
    isMorning: true,
  },
  {
    label: '11:00 AM',
    value: '11:00AM',
    isMorning: true,
  },
  {
    label: '11:30 AM',
    value: '11:30AM',
    isMorning: true,
  },
  {
    label: '12:00 PM',
    value: '12:00PM',
    isMorning: false,
  },
  {
    label: '12:30 PM',
    value: '12:30PM',
    isMorning: false,
  },
  {
    label: '01:00 PM',
    value: '01:00PM',
    isMorning: false,
  },
  {
    label: '01:30 PM',
    value: '01:30PM',
    isMorning: false,
  },
  {
    label: '02:00 PM',
    value: '02:00PM',
    isMorning: false,
  },
  {
    label: '02:30 PM',
    value: '02:30PM',
    isMorning: false,
  },
  {
    label: '03:00 PM',
    value: '03:00PM',
    isMorning: false,
  },
  {
    label: '03:30 PM',
    value: '03:30PM',
    isMorning: false,
  },
  {
    label: '04:00 PM',
    value: '04:00PM',
    isMorning: false,
  },
  {
    label: '04:30 PM',
    value: '04:30PM',
    isMorning: false,
  },
  {
    label: '05:00 PM',
    value: '05:00PM',
    isMorning: false,
  },
  {
    label: '05:30 PM',
    value: '05:30PM',
    isMorning: false,
  },
  {
    label: '06:00 PM',
    value: '06:00PM',
    isMorning: false,
  },
];
