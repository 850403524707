import React from 'react';
import PropTypes from 'prop-types';

const PetFriendly = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7115 13.4584C14.7216 11.9141 13.0356 10.9919 11.2013 10.9919C9.36729 10.9919 7.68111 11.9141 6.69132 13.4584L4.30588 17.1798C3.9101 17.7973 3.72748 18.5105 3.77767 19.2421C3.82786 19.9739 4.10631 20.6554 4.58283 21.2131C5.05968 21.7707 5.68978 22.1517 6.40497 22.3152C7.12016 22.4785 7.85332 22.4089 8.52504 22.1136L8.56884 22.0941C10.2592 21.3655 12.1913 21.3721 13.8775 22.1136C14.3124 22.3048 14.7731 22.4015 15.2381 22.4015C15.4908 22.4015 15.7451 22.3728 15.9971 22.3154C16.7122 22.152 17.3423 21.771 17.8194 21.2135C18.296 20.6559 18.5747 19.9742 18.625 19.2424C18.6754 18.5105 18.4928 17.7973 18.097 17.1796L15.7115 13.4584ZM16.8393 20.3752C16.2325 21.0845 15.251 21.3088 14.3966 20.9331C13.3812 20.4865 12.2912 20.2633 11.2008 20.2633C10.1115 20.2633 9.02169 20.4863 8.0069 20.9325L7.97803 20.9452C7.13023 21.3038 6.1631 21.0767 5.56305 20.3752C4.95696 19.6657 4.88797 18.6613 5.39151 17.8757L7.77711 14.1543C8.52873 12.9818 9.80873 12.2817 11.2013 12.2817C12.594 12.2817 13.8741 12.9818 14.6259 14.1543L17.0112 17.8755C17.5149 18.6615 17.4457 19.6661 16.8393 20.3752Z"
        fill="currentColor"
      />
      <path
        d="M4.9486 13.4537C5.65826 13.1811 6.19738 12.6093 6.46677 11.8434C6.72291 11.1146 6.70193 10.2918 6.40736 9.52645C6.11262 8.76157 5.57652 8.13718 4.89791 7.76808C4.18474 7.38053 3.4014 7.31742 2.69292 7.5905C1.26757 8.13852 0.613468 9.8999 1.235 11.5178C1.73149 12.8055 2.88946 13.6184 4.06019 13.6184C4.35913 13.6184 4.6589 13.5653 4.9486 13.4537ZM2.43846 11.0545C2.07222 10.1012 2.39398 9.08702 3.15617 8.79396C3.29381 8.74092 3.43933 8.71457 3.58787 8.71457C3.81715 8.71457 4.05398 8.77718 4.28192 8.90122C4.6918 9.12395 5.01927 9.51067 5.2039 9.98987C5.38836 10.4694 5.40481 10.9756 5.25006 11.4156C5.10823 11.8187 4.83682 12.1151 4.48602 12.2499L4.48535 12.2503C3.72416 12.5437 2.80588 12.007 2.43846 11.0545Z"
        fill="currentColor"
      />
      <path
        d="M9.57691 10.5381C11.3588 10.5381 12.8085 8.88648 12.8085 6.85638C12.8085 4.82577 11.3588 3.17383 9.57691 3.17383C7.79522 3.17383 6.3457 4.82577 6.3457 6.85638C6.3457 8.88648 7.79522 10.5381 9.57691 10.5381ZM9.57691 4.46339C10.6476 4.46339 11.5189 5.53694 11.5189 6.85638C11.5189 8.17531 10.6476 9.24852 9.57691 9.24852C8.50622 9.24852 7.63527 8.17531 7.63527 6.85638C7.63527 5.53694 8.50622 4.46339 9.57691 4.46339Z"
        fill="currentColor"
      />
      <path
        d="M15.1585 11.4427H15.1586C15.4327 11.5337 15.7124 11.5771 15.9908 11.5771C17.2903 11.5771 18.5567 10.6313 19.0399 9.17777C19.3182 8.34106 19.2999 7.45634 18.9884 6.68676C18.6624 5.88127 18.0583 5.29649 17.2873 5.04002C16.5162 4.78389 15.6818 4.89064 14.9384 5.3408C14.2283 5.77099 13.6841 6.46856 13.4063 7.30528C12.8197 9.07035 13.6057 10.9264 15.1585 11.4427ZM14.6301 7.71197C14.8115 7.16563 15.1583 6.71546 15.6066 6.44389C16.0214 6.19262 16.4739 6.12867 16.8806 6.26379C17.2871 6.39907 17.611 6.721 17.793 7.1705C17.9895 7.65624 17.9979 8.22457 17.8161 8.77091C17.4538 9.86124 16.444 10.5108 15.5653 10.2189C14.6873 9.92687 14.2677 8.8023 14.6301 7.71197Z"
        fill="currentColor"
      />
      <path
        d="M21.963 11.0128L21.9625 11.0123C20.7337 10.1046 18.896 10.498 17.8656 11.8898C16.8362 13.2823 16.9971 15.1543 18.2241 16.0626C18.6716 16.3941 19.2003 16.5526 19.7414 16.5526C20.6845 16.5526 21.6658 16.0712 22.3215 15.186C23.3509 13.7935 23.1901 11.9215 21.963 11.0128ZM21.2851 14.4188C20.6767 15.24 19.6479 15.5124 18.9915 15.0262C18.3355 14.5406 18.2956 13.4775 18.9023 12.6567C19.3057 12.1119 19.8948 11.8087 20.4378 11.8087C20.7124 11.8087 20.9752 11.8863 21.1961 12.0496C21.8516 12.5357 21.8914 13.5985 21.2851 14.4188Z"
        fill="currentColor"
      />
    </svg>
  );
};

PetFriendly.propTypes = {
  className: PropTypes.string,
};

export default PetFriendly;
