import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Media from 'common/Media/Media';
import ResidentInformationMobile from './ResidentInformationMobile';
import ResidentInformationXL from './ResidentInformationXL';
import ResidentUpdate from 'services/Resident/ResidentUpdate';
import { registerEvent } from 'services/Analytics';

const ResidentInformation = ({
  labels,
  basicInformation,
  handleRefetchState,
}) => {
  const [
    editResidentInformationOpen,
    setEditResidentInformationOpen,
  ] = useState(false);

  const [residentInformation] = useState({
    ...basicInformation,
  });

  const handleOnEditMode = (open) => {
    registerEvent('FamilyFiles', 'Edit resident info is opened');
    setEditResidentInformationOpen(open);
  };

  const refetchStateHandler = () => {
    handleRefetchState(true);
  };

  return (
    <Fragment>
      <ResidentUpdate
        labels={labels}
        basicInformation={residentInformation}
        handleRefetchState={refetchStateHandler}
        editResidentInformationOpen={editResidentInformationOpen}
      >
        <Media
          xs={
            <Fragment>
              <ResidentInformationMobile
                labels={labels}
                basicInformation={residentInformation}
                onEditMode={handleOnEditMode}
              />
            </Fragment>
          }
          xl={
            <Fragment>
              <ResidentInformationXL
                labels={labels}
                basicInformation={residentInformation}
                onEditMode={handleOnEditMode}
              />
            </Fragment>
          }
        />
      </ResidentUpdate>
    </Fragment>
  );
};

ResidentInformation.propTypes = {
  labels: PropTypes.shape({
    RESIDENT_INFORMATION: PropTypes.string.isRequired,
    PREFERRED_LOCATIONS: PropTypes.string.isRequired,
    AGE: PropTypes.string.isRequired,
    EXPECTED_MOVE_IN_DATE: PropTypes.string.isRequired,
    LIKELIHOOD_MOVE: PropTypes.string.isRequired,
    UNKNOWN: PropTypes.string.isRequired,
    YEARS_OLD: PropTypes.string.isRequired,
    DO_NOT_CALL: PropTypes.string.isRequired,
    PERCENTAGE: PropTypes.string.isRequired,
    PLEASE_CONFIRM: PropTypes.string.isRequired,
    DONT_MAKE_ANY_CHANGES: PropTypes.string.isRequired,
    BACK_TO_EDITING: PropTypes.string.isRequired,
    YES_DONT_SAVE: PropTypes.string.isRequired,
    SUCCESS: PropTypes.string.isRequired,
    SUCCESSFULLY_UPDATED: PropTypes.string.isRequired,
    OK: PropTypes.string.isRequired,
  }).isRequired,
  basicInformation: PropTypes.shape({
    familyFileId: PropTypes.number.isRequired,
    resident: PropTypes.string,
    salesPhase: PropTypes.string,
    likeliHoodToMove: PropTypes.number,
    location: PropTypes.string,
    expectedMove: PropTypes.any,
    age: PropTypes.number,
    careTypes: PropTypes.arrayOf(PropTypes.object),
    preferredLocations: PropTypes.arrayOf(PropTypes.object),
    doNotCall: PropTypes.bool.isRequired,
  }).isRequired,
  handleRefetchState: PropTypes.func,
};

ResidentInformation.defaultProps = {};

export default ResidentInformation;
