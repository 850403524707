import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  external_call_button: {
    cursor: 'pointer',
    width: '56px',
    height: '56px',
    borderRadius: '50%',
    backgroundColor: theme.palette.positive.wintergreenDream2,
    color: theme.palette.light.platinum5,
    border: `1px solid ${theme.palette.neutral.cosmicCobalt5}`,
    boxShadow: '0px 0px 8px rgba(13, 19, 33, 0.2)',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
    margin: '0',
    justifyContent: 'center',
    '& svg': {
      fontSize: '24px',
      color: theme.palette.light.platinum5,
      fill: theme.palette.light.platinum5,
    },
    '&:hover': {
      backgroundColor: theme.palette.neutral.cosmicCobalt2,
    },
  },
  external_call_container: {
    '& > div:last-child': {
      right: '30px',
    },
  },
  active: {
    backgroundColor: theme.palette.neutral.cosmicCobalt3,
  },
  hide_component: {
    display: 'none',
  },
}));
export { useStyles };
