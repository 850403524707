import jwtDecode from 'jwt-decode';
import crossDomainStorage from './crossDomainStorage';
import withConfig from '../utils/withConfig';
import labels from '../config/labels';
import * as Sentry from '@sentry/react';

const AUTH_RESULT_LOCALSTORAGE_NAME = 'authResult';
const ID_TOKEN_NAME = 'idToken';
const ACCESS_TOKEN_NAME = 'accessToken';
const EXPIRES_IN_NAME = 'expiresIn';
const SCOPE_NAME = 'scope';
const TOKEN_TYPE_NAME = 'tokenType';

let authResult;

const getAuthResultFromLocalStorage = async () => {
  if (!authResult) {
    authResult = await crossDomainStorage.getToken(
      AUTH_RESULT_LOCALSTORAGE_NAME,
    );
  }
  try {
    return JSON.parse(authResult);
  } catch (error) {
    console.error(
      `yglAuthHelper. JSON.parse failed. Error: ${error}. Data: ${authResult}.`,
    );
    return {};
  }
};

const isTokenValid = async (idToken) => {
  try {
    const { alg, kid } = getTokenHeader(idToken);

    const signingKeyPromise = await getSigningKey(kid);

    const signingKey = signingKeyPromise.getPublicKey();

    const verifyTokenPromiseResult = await verifyToken(
      idToken,
      signingKey,
      alg,
    );

    return verifyTokenPromiseResult;
  } catch (error) {
    console.error(labels.ERROR_IN_TOKEN_PROVIDED_BY_YGL, error);
    Sentry.captureException(
      `${labels.ERROR_IN_TOKEN_PROVIDED_BY_YGL}: ${error}`,
    );
    return false;
  }
};

const getSigningKey = (kid) => {
  const jwksClient = require('jwks-rsa');

  const client = jwksClient({
    jwksUri: `https://${withConfig('AUTH0_DOMAIN')}/.well-known/jwks.json`,
  });

  return new Promise((resolve, reject) => {
    client.getSigningKey(kid, (err, key) => {
      if (err) {
        reject(err);
      } else {
        resolve(key);
      }
    });
  });
};

const verifyToken = (idToken, signingKey, alg) => {
  var jwt = require('jsonwebtoken');

  return new Promise((resolve, reject) => {
    jwt.verify(idToken, signingKey, { algorithms: [alg] }, function (err) {
      if (err) {
        reject(err);
      } else {
        console.log('Your YGL token is valid!');
        // decoded token should be available here
        resolve(true);
      }
    });
  });
};

const getTokenHeader = (idToken) => {
  const tokenHeader = jwtDecode(idToken, { header: true });
  return tokenHeader;
};

const isAuthenticated = async () => {
  const idToken = await getValueFromLocalStorage(ID_TOKEN_NAME);
  const validToken = await isTokenValid(idToken);
  const accessToken = await getValueFromLocalStorage(ACCESS_TOKEN_NAME);

  if (validToken) {
    return !!(accessToken && idToken);
  }

  return false;
};

const getValueFromLocalStorage = async (propertyName) => {
  const authResult = await getAuthResultFromLocalStorage();
  return authResult[propertyName];
};

const updateAuth0HookFromYgl = async (auth0FromHook) => {
  if (auth0FromHook && auth0FromHook.cache && auth0FromHook.cache.cache) {
    const idToken = await getValueFromLocalStorage(ID_TOKEN_NAME);
    let decodedIdToken = jwtDecode(idToken);

    decodedIdToken.__raw = idToken;

    decodedIdToken = addMissingValuesByKey(decodedIdToken, 'given_name');
    decodedIdToken = addMissingValuesByKey(decodedIdToken, 'picture');
    decodedIdToken = addMissingValuesByKey(decodedIdToken, 'name');

    const headerIdToken = jwtDecode(idToken, { header: true });
    const options = {
      access_token: await getValueFromLocalStorage(ACCESS_TOKEN_NAME),
      id_token: idToken,
      expires_in: await getValueFromLocalStorage(EXPIRES_IN_NAME),
      scope: await getValueFromLocalStorage(SCOPE_NAME),
      token_type: await getValueFromLocalStorage(TOKEN_TYPE_NAME),
      decodedToken: {
        encoded: {},
        header: headerIdToken,
        claims: decodedIdToken,
        user: decodedIdToken,
      },
    };
    auth0FromHook.cache.cache['default::openid profile email'] = options;
  }
};

const addMissingValuesByKey = (decodedToken, key) => {
  if (decodedToken[key] === undefined) decodedToken[key] = '';
  return decodedToken;
};

const resetAuthResult = () => {
  authResult = null;
};

const getEmailFromLocalStorage = async () => {
  const email = await crossDomainStorage.getEmail();
  return email;
};

export default {
  isAuthenticated,
  updateAuth0HookFromYgl,
  resetAuthResult,
  verifyToken,
  getSigningKey,
  getTokenHeader,
  isTokenValid,
  getEmailFromLocalStorage,
};
