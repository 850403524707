import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  modal_container: {
    width: '896px',
  },
  modal_body: {
    padding: '0px 16px',
    fontFamily: 'Poppins',
  },
  modal_footer: {
    display: 'flex',
    marginTop: '53px',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: theme.spacing(3),
    },
  },
  pipeda_label: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '22px',
    margin: '12px',
  },
}));

export { useStyles };
