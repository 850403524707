import React from 'react';
import PropTypes from 'prop-types';

const Neutral = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM8 14V16H16V14H8ZM14 11V9H17V11H14ZM7 9V11H10V9H7Z"
      />
    </svg>
  );
};

Neutral.propTypes = {
  className: PropTypes.string,
};

export default Neutral;
