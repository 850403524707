import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from 'common/Typography/Typography';
import { useStyles } from './ContactInfo.style';
import clsx from 'clsx';
import TextInput from 'common/TextInput/TextInput';
import Checkbox from 'common/Checkbox/Checkbox';
import Dropdown from 'common/Dropdown/Dropdown';
import relationshipToResident from './relationshipToResident.json';
import states from './states.json';
import countries from './countries.json';
import Divider from 'common/Divider/Divider';
import { formatPhone } from 'utils/phoneFormat';
import { handleOnChange } from 'services/formService';
const ContactInfo = ({
  contactInfo,
  labels,
  handleContactInfo,
  firstNameError,
  lastNameError,
  handlePhoneErrors,
  handleEmailError,
  relationshipToResidentError,
  isPrimaryContact,
  title,
  isAdditionalContact,
  flags,
  enableReferralAlertValidation,
  setFirstNameError,
  setLastNameError,
  setRelationshipToResidentError,
}) => {
  const classes = useStyles();

  const [contact, setContact] = useState({
    ...contactInfo,
  });

  const getEmailAddress = ({ contactDetail, emailAddresses }) => {
    console.dir(emailAddresses);
    if (emailAddresses.length > 0) {
      return emailAddresses[0].emailAddress;
    } else if (contactDetail.email) return contactDetail.email;

    return;
  };
  const [email, setEmail] = useState(getEmailAddress(contact));

  const getPhoneByCode = (code, phones) => {
    const phoneToUse = phones.find((phone) => phone.subCategoryCode === code);
    if (phoneToUse === undefined) return '';
    return `${phoneToUse.areaCode}${phoneToUse.localNumber}`;
  };

  const [homePhone, setHomePhone] = useState(
    getPhoneByCode(labels.HOME_PHONE_CODE, contact.phones),
  );

  const [cellPhone, setCellPhone] = useState(
    getPhoneByCode(labels.CELL_PHONE_CODE, contact.phones),
  );
  const [workPhone, setWorkPhone] = useState(
    getPhoneByCode(labels.WORK_PHONE_CODE, contact.phones),
  );

  const setContactProperty = (name, value) => {
    contact[name] = typeof value === 'string' ? value.trim() : value;
    setContact({ ...contact });
  };

  const getSelectedRelationshipItem = (relationToResidentId) => {
    if (!relationToResidentId) return { value: '', label: '--select--' };

    return relationshipToResident.find(
      (rel) => rel.value.toString() === relationToResidentId.toString(),
    );
  };

  const getSelectedStateItem = (stateSelected) => {
    return states.find((state) => state.value === stateSelected);
  };

  const getSelectedCountryItem = (countrySelected) => {
    return countries.find((country) => country.value === countrySelected);
  };

  const handleBooleanOption = (checked, option) => {
    setContactProperty(option, checked);
  };

  const handleRelationshipToResidentChange = (item) => {
    setContactProperty('relationship', item.label);
    setContactProperty('relationToResidentId', item.value);
    if (item.value) {
      setRelationshipToResidentError(null);
    }
  };

  const handleEmailAddress = (enteredEmailAddress) => {
    contact.emailAddresses = [
      { emailAddress: enteredEmailAddress, isPrimary: true },
    ];
    setEmail(enteredEmailAddress);
    setContact(contact);
    const isValid = isValidEmail(enteredEmailAddress);
    const emailErrorKey = getEmailErrorKey();
    handleEmailError(emailErrorKey, !isValid);

    return enteredEmailAddress;
  };

  const handlePhoneChange = (phone, type) => {
    const clearPhone = phone.replace(/[-\s]/g, '');

    if (type === labels.HOME_PHONE_CODE) setHomePhone(clearPhone);
    if (type === labels.CELL_PHONE_CODE) setCellPhone(clearPhone);
    if (type === labels.WORK_PHONE_CODE) setWorkPhone(clearPhone);

    contact.phones = contact.phones.map((phone) => {
      if (type === phone.subCategoryCode) {
        return {
          ...phone,
          ...{
            localNumber: clearPhone.substring(3),
            areaCode: clearPhone.substring(0, 3),
          },
        };
      }
      return phone;
    });

    const alreadyEnteredPhone = contact.phones.find(
      (phone) => phone.subCategoryCode === type,
    );

    if (alreadyEnteredPhone === undefined) {
      contact.phones.push({
        localNumber: clearPhone.substring(3),
        areaCode: clearPhone.substring(0, 3),
        subCategoryCode: type,
        isPrimaryForCategory: type === labels.CELL_PHONE_CODE,
      });
    }

    const nCellPhone = contact.phones.filter(
      (phone) =>
        phone.subCategoryCode === labels.CELL_PHONE_CODE &&
        phone.localNumber !== '',
    );

    const nHomePhone = contact.phones.filter(
      (phone) =>
        phone.subCategoryCode === labels.HOME_PHONE_CODE &&
        phone.localNumber !== '',
    );

    const nWorkPhone = contact.phones.filter(
      (phone) =>
        phone.subCategoryCode === labels.WORK_PHONE_CODE &&
        phone.localNumber !== '',
    );

    if (nCellPhone.length > 0) {
      contact.phones.map((phone) => {
        if (phone.subCategoryCode !== labels.CELL_PHONE_CODE) {
          phone.isPrimaryForCategory = false;
        } else {
          phone.isPrimaryForCategory = true;
        }
        return '';
      });
    } else {
      if (nHomePhone.length > 0) {
        contact.phones.map((phone) => {
          if (phone.subCategoryCode !== labels.HOME_PHONE_CODE) {
            phone.isPrimaryForCategory = false;
          } else {
            phone.isPrimaryForCategory = true;
          }
          return '';
        });
      } else {
        if (nWorkPhone.length > 0) {
          contact.phones.map((phone) => {
            if (phone.subCategoryCode !== labels.WORK_PHONE_CODE) {
              phone.isPrimaryForCategory = false;
            } else {
              phone.isPrimaryForCategory = true;
            }
            return '';
          });
        }
      }
    }

    const errorKey = getPhoneNumberErrorKey(type);
    const isValid = isValidPhoneNumber(clearPhone);
    handlePhoneErrors(errorKey, !isValid);

    setContact(contact);
  };

  const getPhoneNumberErrorKey = (type) => {
    const PRIMARY = 'primary';
    const SECONDARY = 'secondary';
    const ADDITIONAL = 'additional';

    const phoneErrorsMap = {
      [labels.HOME_PHONE_CODE]: 'HomePhoneError',
      [labels.WORK_PHONE_CODE]: 'WorkPhoneError',
      [labels.CELL_PHONE_CODE]: 'CellPhoneError',
    };

    if (isPrimaryContact) {
      return `${PRIMARY}${phoneErrorsMap[type]}`;
    }
    if (!isPrimaryContact && !isAdditionalContact) {
      return `${SECONDARY}${phoneErrorsMap[type]}`;
    }
    if (isAdditionalContact) {
      return `${ADDITIONAL}${phoneErrorsMap[type]}`;
    }
  };

  const getEmailErrorKey = () => {
    if (isPrimaryContact) {
      return 'primaryEmailError';
    }
    if (!isPrimaryContact && !isAdditionalContact) {
      return 'secondaryEmailError';
    }
    if (isAdditionalContact) {
      return 'additionaEmailError';
    }
  };

  const isValidPhoneNumber = (number) => {
    if (!number) return true;
    const isNumberOnly = /^\d+$/.test(number);
    return number.length === 10 && isNumberOnly;
  };

  const isValidEmail = (email) => {
    if (!email) return true;
    return /^((?!_)\w)+([.-]?\w+)*@\w+([.-]?\w+)*\.\w+$/.test(email);
  };

  useEffect(() => {
    handleContactInfo(contact);
    // eslint-disable-next-line
  }, [contact]);

  const contactInformationContainerClass = clsx(
    classes.row,
    classes.contact_information_container,
    firstNameError || lastNameError ? classes.container_with_error : '',
  );

  const getTextInputId = (type) => {
    let contact = isPrimaryContact ? 'primary-contact' : 'secondary';
    contact = isAdditionalContact ? 'additional-contact' : contact;

    return `${contact}-${type}`;
  };

  return (
    <Fragment>
      <Typography bold>{title}</Typography>
      <div className={contactInformationContainerClass}>
        <div className={classes.names_container}>
          <TextInput
            label={labels.FIRST_NAME}
            value={contact.firstName}
            className={clsx(
              classes.default_name_input,
              'name-input',
              firstNameError ? 'error' : '',
            )}
            onChange={handleOnChange(
              'firstName',
              setFirstNameError,
              setContactProperty,
            )}
            error={!!firstNameError}
            textHint={firstNameError}
            id={getTextInputId('first-name')}
            isMandatory={isPrimaryContact}
            maxLength={45}
            autoComplete="off"
          />
          <TextInput
            label={labels.LAST_NAME}
            value={contact.lastName}
            className={clsx(
              classes.default_name_input,
              'name-input',
              lastNameError ? 'error' : '',
            )}
            onChange={handleOnChange(
              'lastName',
              setLastNameError,
              setContactProperty,
            )}
            error={!!lastNameError}
            textHint={lastNameError}
            id={`${
              isPrimaryContact ? 'primary-contact' : 'secondary'
            }-last-name`}
            isMandatory={isPrimaryContact}
            maxLength={45}
            autoComplete="off"
          />
          <Dropdown
            emptyLabel="--select--"
            error={!!relationshipToResidentError}
            textHint={relationshipToResidentError}
            items={relationshipToResident}
            className={classes.relationship_to_resident}
            label={labels.RELATIONSHIP_TO_RESIDENT}
            onChange={handleRelationshipToResidentChange}
            selectedItem={getSelectedRelationshipItem(
              contact.relationToResidentId,
            )}
            isMandatory={isPrimaryContact}
          />
        </div>
      </div>

      {enableReferralAlertValidation && (
        <>
          <div className={classes.additional_information}>
            <div className={classes.column_address}>
              <Typography bold>{labels.ADDRESS}</Typography>
              <div
                className={clsx(classes.row, classes.address_input_container)}
              >
                <TextInput
                  label={labels.ADDRESS_1}
                  value={contact.address1 || ''}
                  className={classes.address_input}
                  onChange={(value) => setContactProperty('address1', value)}
                  id={`${isPrimaryContact ? 'primary' : 'secondary'}-address1`}
                  maxLength={45}
                  autoComplete="off"
                />

                <TextInput
                  label={labels.ADDRESS_2}
                  value={contact.address2 || ''}
                  className={clsx(classes.address_input, classes.address2)}
                  onChange={(value) => setContactProperty('address2', value)}
                  id={`${isPrimaryContact ? 'primary' : 'secondary'}-address2`}
                  maxLength={45}
                  autoComplete="off"
                />
              </div>
              <div className={clsx(classes.row, classes.city_state_container)}>
                <TextInput
                  label={labels.CITY}
                  value={contact.city || ''}
                  className={classes.default_input}
                  onChange={(value) => setContactProperty('city', value)}
                  id={`${isPrimaryContact ? 'primary' : 'secondary'}-city`}
                  maxLength={45}
                  autoComplete="off"
                />
                <Dropdown
                  emptyLabel={'--select--'}
                  items={states}
                  value={contact.state || ''}
                  className={classes.standard_dropdown}
                  label={labels.STATE_PROV}
                  onChange={(item) => setContactProperty('state', item.value)}
                  selectedItem={getSelectedStateItem(contact.state)}
                />
              </div>
              <div className={clsx(classes.row, classes.city_state_container)}>
                <TextInput
                  label={labels.POSTAL_CODE}
                  value={contact.postalCode || ''}
                  className={classes.default_input}
                  onChange={(value) => setContactProperty('postalCode', value)}
                  id={`${
                    isPrimaryContact ? 'primary' : 'secondary'
                  }-postal-code`}
                  maxLength={45}
                  autoComplete="off"
                />
                <Dropdown
                  emptyLabel={'--select--'}
                  items={countries}
                  className={classes.standard_dropdown}
                  label={labels.COUNTRY}
                  onChange={(item) => setContactProperty('country', item.value)}
                  selectedItem={getSelectedCountryItem(contact.country)}
                />
              </div>
            </div>
            <div className={classes.column_contact}>
              <Typography bold>{labels.CONTACT}</Typography>
              <div className={clsx(classes.row, classes.phones_container)}>
                <TextInput
                  label={labels.HOME_PHONE}
                  value={formatPhone(homePhone)}
                  className={clsx(classes.default_input)}
                  error={!isValidPhoneNumber(homePhone)}
                  textHint={
                    isValidPhoneNumber(homePhone)
                      ? ''
                      : labels.INVALID_PHONE_NUMBER
                  }
                  onChange={(value) =>
                    handlePhoneChange(value, labels.HOME_PHONE_CODE)
                  }
                  id={`${
                    isPrimaryContact ? 'primary' : 'secondary'
                  }-home-phone`}
                  maxLength={45}
                  autoComplete="off"
                  disabled={!flags.editAdditionalContact}
                />

                <TextInput
                  label={labels.WORK_PHONE}
                  value={formatPhone(workPhone)}
                  className={clsx(classes.default_input, classes.phone_input)}
                  onChange={(value) =>
                    handlePhoneChange(value, labels.WORK_PHONE_CODE)
                  }
                  error={!isValidPhoneNumber(workPhone)}
                  textHint={
                    isValidPhoneNumber(workPhone)
                      ? ''
                      : labels.INVALID_PHONE_NUMBER
                  }
                  id={`${
                    isPrimaryContact ? 'primary' : 'secondary'
                  }-work-phone`}
                  maxLength={45}
                  autoComplete="off"
                  disabled={!flags.editAdditionalContact}
                />
              </div>
              <div
                className={clsx(
                  classes.row,
                  classes.phones_container,
                  classes.email_container,
                )}
              >
                <TextInput
                  label={labels.CELL_PHONE}
                  value={formatPhone(cellPhone)}
                  className={clsx(classes.default_input, classes.phone_input)}
                  onChange={(value) =>
                    handlePhoneChange(value, labels.CELL_PHONE_CODE)
                  }
                  error={!isValidPhoneNumber(cellPhone)}
                  textHint={
                    isValidPhoneNumber(cellPhone)
                      ? ''
                      : labels.INVALID_PHONE_NUMBER
                  }
                  id={`${
                    isPrimaryContact ? 'primary' : 'secondary'
                  }-cell-phone`}
                  maxLength={45}
                  autoComplete="off"
                  disabled={!flags.editAdditionalContact}
                />
                <TextInput
                  label={labels.EMAIL_ADDRESS}
                  value={email}
                  className={classes.email_input}
                  onChange={handleEmailAddress}
                  error={!isValidEmail(email)}
                  textHint={isValidEmail(email) ? '' : labels.INVALID_EMAIL}
                  id={`${isPrimaryContact ? 'primary' : 'secondary'}-email`}
                  maxLength={45}
                  autoComplete="off"
                  disabled={!flags.editAdditionalContact}
                />
              </div>
            </div>
          </div>

          <Typography
            level="small"
            color="eerieBlack5"
            className={classes.contact_preference}
          >
            {labels.SELECT_CONTACT_PREFERENCE}
          </Typography>
          <div className={clsx(classes.row, classes.checkboxes_container)}>
            <Checkbox
              label={labels.ALLOW_CONTACT}
              onChange={(item) =>
                handleBooleanOption(item.target.checked, 'allowContact')
              }
              checked={contact.allowContact}
            />
            <Checkbox
              label={labels.INQUIRER}
              onChange={(item) =>
                handleBooleanOption(item.target.checked, 'isInquirer')
              }
              checked={contact.isInquirer}
            />
            <Checkbox
              label={labels.MAILINGS}
              onChange={(item) =>
                handleBooleanOption(item.target.checked, 'allowMailing')
              }
              checked={contact.allowMailing}
            />
          </div>
        </>
      )}
      <Divider className={classes.divider} color="platinum1" />
    </Fragment>
  );
};

ContactInfo.propTypes = {
  labels: PropTypes.object.isRequired,
  contactInfo: PropTypes.shape({
    name: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    relationship: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    allowContact: PropTypes.bool,
    isInquirer: PropTypes.bool,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    contactDetail: PropTypes.shape({
      phonePrimary: PropTypes.string,
      phoneSecondary: PropTypes.string,
      email: PropTypes.string,
      primaryPhoneType: PropTypes.string,
      secondaryPhoneType: PropTypes.string,
    }),
    phones: PropTypes.any,
  }),
  firstNameError: PropTypes.string,
  lastNameError: PropTypes.string,
  relationshipToResidentError: PropTypes.string,
  isPrimaryContact: PropTypes.bool,
  handleContactInfo: PropTypes.func,
  handlePhoneErrors: PropTypes.func,
  handleEmailError: PropTypes.func,
  title: PropTypes.string,
  isAdditionalContact: PropTypes.bool,
  flags: PropTypes.shape({
    editAdditionalContact: PropTypes.bool,
  }),
  enableReferralAlertValidation: PropTypes.bool,
  setFirstNameError: PropTypes.func,
  setLastNameError: PropTypes.func,
  setRelationshipToResidentError: PropTypes.func,
};

ContactInfo.defaultProps = {
  isPrimaryContact: false,
  firstNameError: '',
  lastNameError: '',
  relationshipToResidentError: '',
  isAdditionalContact: false,
  enableReferralAlertValidation: false,
  setFirstNameError: () => {},
  setLastNameError: () => {},
  setRelationshipToResidentError: () => {},
};

export default ContactInfo;
