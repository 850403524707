import React from 'react';
import PropTypes from 'prop-types';

const SquareStairs = ({ className }) => {
  return (
    <svg className={className} width="1em" height="1em" viewBox="0 0 40 40">
      <g>
        <rect y="0" x="0" rx="1" width="5px" height="5px" fill="currentColor" />
      </g>
      <g>
        <rect y="7" x="0" rx="1" width="5px" height="5px" fill="currentColor" />
        <rect y="7" x="7" rx="1" width="5px" height="5px" fill="currentColor" />
      </g>
      <g>
        <rect
          y="14"
          x="0"
          rx="1"
          width="5px"
          height="5px"
          fill="currentColor"
        />
        <rect
          y="14"
          x="7"
          rx="1"
          width="5px"
          height="5px"
          fill="currentColor"
        />
        <rect
          y="14"
          x="14"
          rx="1"
          width="5px"
          height="5px"
          fill="currentColor"
        />
      </g>
      <g>
        <rect
          y="21"
          x="0"
          rx="1"
          width="5px"
          height="5px"
          fill="currentColor"
        />
        <rect
          y="21"
          x="7"
          rx="1"
          width="5px"
          height="5px"
          fill="currentColor"
        />
        <rect
          y="21"
          x="14"
          rx="1"
          width="5px"
          height="5px"
          fill="currentColor"
        />
        <rect
          y="21"
          x="21"
          rx="1"
          width="5px"
          height="5px"
          fill="currentColor"
        />
      </g>
      <g>
        <rect
          y="28"
          x="0"
          rx="1"
          width="5px"
          height="5px"
          fill="currentColor"
        />
        <rect
          y="28"
          x="7"
          rx="1"
          width="5px"
          height="5px"
          fill="currentColor"
        />
        <rect
          y="28"
          x="14"
          rx="1"
          width="5px"
          height="5px"
          fill="currentColor"
        />
        <rect
          y="28"
          x="21"
          rx="1"
          width="5px"
          height="5px"
          fill="currentColor"
        />
        <rect
          y="28"
          x="28"
          rx="1"
          width="5px"
          height="5px"
          fill="currentColor"
        />
      </g>
      <g>
        <rect
          y="35"
          x="0"
          rx="1"
          width="5px"
          height="5px"
          fill="currentColor"
        />
        <rect
          y="35"
          x="7"
          rx="1"
          width="5px"
          height="5px"
          fill="currentColor"
        />
        <rect
          y="35"
          x="14"
          rx="1"
          width="5px"
          height="5px"
          fill="currentColor"
        />
        <rect
          y="35"
          x="21"
          rx="1"
          width="5px"
          height="5px"
          fill="currentColor"
        />
        <rect
          y="35"
          x="28"
          rx="1"
          width="5px"
          height="5px"
          fill="currentColor"
        />
        <rect
          y="35"
          x="35"
          rx="1"
          width="5px"
          height="5px"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

SquareStairs.propTypes = {
  className: PropTypes.string,
};

export default SquareStairs;
