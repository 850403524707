import {
  GET_FORM_CHICLETS_ERROR,
  GET_FORM_CHICLETS_REQUEST,
  GET_FORM_CHICLETS_SUCCESS,
  SAVE_FORM_CHICLETS_ERROR,
  SAVE_FORM_CHICLETS_REQUEST,
  SAVE_FORM_CHICLETS_REQUEST_2,
  SAVE_FORM_CHICLETS_SUCCESS,
  SAVE_INTERNAL_TRANSFER_CALL,
  COMPLETE_INTERNAL_TRANSFER_CALL,
  UPDATE_CLOSE_REASON,
  UPDATE_FORM_STEPS,
  UPDATE_DISPOSITION,
  GET_MANUAL_INQUIRY_STATE,
  ADD_END_CALL_STEPS,
  UPDATE_WARM_TRANSFER_POOL_TYPE,
  RESET_FORM_CHICLETS_STATE,
  UPDATE_COLLAPSED_STATE,
} from '../actions/formChicletsActions';

const saveStatus = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const initialState = {
  loading: false,
  error: null,
  data: null,
  formSteps: null,
  saveStatus: null,
  latestCloseReason: {
    closeInquiry: false,
    closeReason: null,
    isNoAnswerSelected: false,
    isScheduleCallSelected: false,
    removeFromCampaign: false,
  },
  internalTransferCall: {
    name: null,
    isTransferComplete: false,
    isTransferPending: false,
    phoneNumber: null,
  },
  disposition: null,
  manualInquiryState: {
    isManualInquiry: false,
    isCreatedManualInquiry: false,
    hasErrors: false,
  },
  warmTransfer: {
    poolType: null,
  },
};

const formChicletsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_FORM_CHICLETS_STATE:
      return initialState;
    case GET_FORM_CHICLETS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
        formSteps: null,
        latestCloseReason: {
          closeInquiry: false,
          closeReason: null,
          isNoAnswerSelected: false,
          isScheduleCallSelected: false,
          removeFromCampaign: false,
        },
        internalTransferCall: state.internalTransferCall,
        disposition: null,
        manualInquiryState: state.manualInquiryState,
      };
    case GET_FORM_CHICLETS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.response,
        formSteps: null,
        latestCloseReason: state.latestCloseReason,
        internalTransferCall: state.internalTransferCall,
        disposition: null,
        manualInquiryState: state.manualInquiryState,
      };
    case GET_FORM_CHICLETS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        data: null,
        formSteps: null,
        latestCloseReason: state.latestCloseReason,
        internalTransferCall: state.internalTransferCall,
        disposition: null,
        manualInquiryState: state.manualInquiryState,
      };
    case UPDATE_FORM_STEPS:
      return {
        ...state,
        latestCloseReason: state.latestCloseReason,
        internalTransferCall: state.internalTransferCall,
        formSteps: action.payload.formSteps,
        manualInquiryState: state.manualInquiryState,
      };
    case ADD_END_CALL_STEPS:
      return {
        ...state,
        formSteps: [...state.formSteps, ...action.payload.endCallSteps],
      };
    case UPDATE_CLOSE_REASON:
      return {
        ...state,
        latestCloseReason: {
          closeInquiry: action.payload.closeInquiry,
          closeReason: action.payload.closeReason,
          isNoAnswerSelected: action.payload.isNoAnswerSelected,
          isScheduleCallSelected: action.payload.isScheduleCallSelected,
          removeFromCampaign: action.payload.removeFromCampaign,
        },
        internalTransferCall: state.internalTransferCall,
        manualInquiryState: state.manualInquiryState,
      };
    case SAVE_INTERNAL_TRANSFER_CALL:
      return {
        ...state,
        internalTransferCall: action.payload,
      };
    case COMPLETE_INTERNAL_TRANSFER_CALL:
      return {
        ...state,
        internalTransferCall: {
          ...state.internalTransferCall,
          isTransferComplete: true,
          isTransferPending: false,
        },
      };
    case UPDATE_DISPOSITION:
      return {
        ...state,
        disposition: action.payload.disposition,
        manualInquiryState: state.manualInquiryState,
      };
    case SAVE_FORM_CHICLETS_REQUEST:
      return {
        ...state,
        saveStatus: saveStatus.LOADING,
        manualInquiryState: state.manualInquiryState,
      };
    case SAVE_FORM_CHICLETS_REQUEST_2:
      return {
        ...state,
        saveStatus: saveStatus.LOADING,
        manualInquiryState: state.manualInquiryState,
      };
    case SAVE_FORM_CHICLETS_SUCCESS:
      return {
        ...state,
        saveStatus: saveStatus.SUCCESS,
        manualInquiryState: state.manualInquiryState,
      };
    case SAVE_FORM_CHICLETS_ERROR:
      return {
        ...state,
        saveStatus: saveStatus.ERROR,
        manualInquiryState: state.manualInquiryState,
      };
    case GET_MANUAL_INQUIRY_STATE:
      return {
        ...state,
        manualInquiryState: {
          isManualInquiry: action.payload.isManualInquiry,
          isCreatedManualInquiry: action.payload.isCreatedManualInquiry,
          hasErrors: action.payload.hasErrors,
        },
      };
    case UPDATE_WARM_TRANSFER_POOL_TYPE:
      return {
        ...state,
        warmTransfer: {
          poolType: action.payload.poolType,
        },
      };
    case UPDATE_COLLAPSED_STATE:
      return {
        ...state,
        collapsedState: action.payload.collapsedState,
      };
    default:
      return state;
  }
};

export const selectFormChiclets = (state) => ({
  formChicletsLoading: state?.callcenter?.formChiclets?.loading,
  formChicletsError: state?.callcenter?.formChiclets?.error,
  formChiclets: state?.callcenter?.formChiclets?.data,
  formSteps: state?.callcenter?.formChiclets?.formSteps,
});
export const selectCloseReason = (state) =>
  state?.callcenter?.formChiclets?.latestCloseReason;

export const selectTransferCallInfo = (state) =>
  state?.callcenter?.formChiclets?.internalTransferCall;

export const selectDisposition = (state) =>
  state?.callcenter?.formChiclets?.disposition;

export const selectManualInquiryState = (state) =>
  state?.callcenter?.formChiclets?.manualInquiryState;

export const selectWarmTransferPoolType = (state) =>
  state?.callcenter?.formChiclets?.warmTransfer?.poolType;

export const selectCollapsedState = (state) =>
  state?.callcenter?.formChiclets?.collapsedState;

export default formChicletsReducer;
