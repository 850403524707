import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  relationship_options: {
    marginTop: '19px',
    '& .star': {
      color: theme.palette.neutral.cosmicCobalt3,
    },
  },
  loved_ones_name_label: {
    fontStyle: 'italic',
    marginTop: '25px',
    marginBottom: '4px',
  },
  loved_one_name_container: {
    display: 'flex',
    justifyContent: 'space-between',
    '& div:first-child ': {
      marginRight: '6px',
    },
  },
}));

export { useStyles };
