const findEnumQuery = `
  query findFormEnumValuesById($formEnumFieldId: Int!) {
    findFormEnumValuesById(formEnumFieldId: $formEnumFieldId) {
      formEnumFieldValueKey
      formEnumFieldValueValue
      formEnumFieldValueLabel
      formEnumFieldValueOrder
    }
  }`;

export default findEnumQuery;
