import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '@material-ui/core';
import { useStyles } from './MoreFiltersSidePanel.style';
import X from '../../common/Icons/basic/X';
import Options from '../../common/Icons/basic/Options';
import Typography from '../../common/Typography/Typography';
import Minus from '../../common/Icons/basic/Minus';
import MoreFiltersGroup from './MoreFiltersGroup';
import BedroomTypes from './FilterGroups/BedroomTypes';
import Button from '../../common/Button/Button';
import Plus from '../../common/Icons/basic/Plus';
import bedroomTypesList from './FilterGroups/bedroomTypesList';
import roomAmenitiesList from './FilterGroups/roomAmenitiesList';
import communityAmenitiesList from './FilterGroups/communityAmenitiesList';
import healthcareServicesList from './FilterGroups/healthcareServicesList';
import RoomAmenities from './FilterGroups/RoomAmenities';
import CommunityAmenities from './FilterGroups/CommunityAmenities';
import HealthcareServices from './FilterGroups/HealthcareServices';

const MoreFiltersSidePanel = ({
  //#region props
  labels,
  isOpen,
  onChange,
  onClose,
  selectedMoreFilters,
  shouldClearFilters,
  filterToRemove,
  //#endregion props
}) => {
  //#region Hooks
  const classes = useStyles();

  const initialFilterState = [
    ...bedroomTypesList,
    ...roomAmenitiesList,
    ...communityAmenitiesList,
    ...healthcareServicesList,
  ];

  const wrapperRef = useRef(null);
  const [isAllExpanded, setIsAllExpanded] = useState(true);
  const [isBedroomTypesOpen, setIsBedroomTypesOpen] = useState(true);
  const [isHealthServicesOpen, setIsHealthServicesOpen] = useState(true);
  const [isCommunityAmenitiesOpen, setIsCommunityAmenitiesOpen] = useState(
    true,
  );
  const [isRoomAmenitiesOpen, setIsRoomAmenitiesOpen] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState(initialFilterState);
  const [shouldClear, setShouldClear] = useState(false);
  const [areSelectedFilters, setAreSelectedFilters] = useState(false);

  useEffect(() => {
    shouldClearFilters && setShouldClear(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldClearFilters]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        isOpen && onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, isOpen, onClose]);

  useEffect(() => {
    const newFilters = selectedFilters.map((filter) => {
      const found = selectedMoreFilters.find(
        (newFilter) => newFilter.label === filter.label && newFilter.selected,
      );
      if (found) {
        filter.selected = true;
      } else {
        filter.selected = false;
      }
      return filter;
    });
    setSelectedFilters([...newFilters]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMoreFilters]);

  useEffect(() => {
    const areAllExpanded =
      isBedroomTypesOpen &&
      isHealthServicesOpen &&
      isRoomAmenitiesOpen &&
      isCommunityAmenitiesOpen;

    setIsAllExpanded(areAllExpanded);
  }, [
    isBedroomTypesOpen,
    isHealthServicesOpen,
    isRoomAmenitiesOpen,
    isCommunityAmenitiesOpen,
  ]);

  useEffect(() => {
    const areSeletedFilters = selectedFilters.some(
      (filter) => !!filter.selected,
    );

    setAreSelectedFilters(areSeletedFilters);
  }, [selectedFilters]);
  //#endregion Hooks

  //#region Handlers
  const handleAllCollapse = () => {
    setIsBedroomTypesOpen(!isAllExpanded);
    setIsHealthServicesOpen(!isAllExpanded);
    setIsCommunityAmenitiesOpen(!isAllExpanded);
    setIsRoomAmenitiesOpen(!isAllExpanded);
  };

  const handleSelectedFilters = () => {
    onChange(selectedFilters);
  };

  const handleFilterChange = (filters) => {
    const newSelectedFilters = selectedFilters.map((filter) => {
      const newFilter = filters.find((f) => f.label === filter.label);
      if (newFilter) {
        return {
          ...filter,
          ...{ selected: newFilter.selected },
        };
      }
      return filter;
    });
    setSelectedFilters([...newSelectedFilters]);
    if (shouldClear) {
      setShouldClear(false);
    }
  };

  const handleClearFilters = () => {
    const clearFilters = selectedFilters.map((filter) => ({
      ...filter,
      ...{ selected: false },
    }));
    setSelectedFilters([...clearFilters]);
  };

  const handleClearAll = () => {
    const clearFilters = selectedFilters.map((filter) => ({
      ...filter,
      ...{ selected: false },
    }));
    setSelectedFilters([...clearFilters]);
    onChange(clearFilters);
    setShouldClear(true);
  };
  //#endregion Handlers

  //#region Render
  return (
    <Drawer
      ref={wrapperRef}
      className={classes.side_panel_container}
      anchor="right"
      open={isOpen}
      onClose={onClose}
      variant="persistent"
    >
      <div className={classes.header}>
        <div className={classes.header_title}>
          <Options />
          <Typography bold color="eerieBlack1" level="small">
            {labels.MORE_FILTERS}
          </Typography>
        </div>
        <div className={classes.close_icon} onClick={onClose}>
          <X />
        </div>
      </div>
      <div className={classes.filters_content}>
        <div className={classes.collapse_all_row}>
          <div className={classes.collapse_all} onClick={handleAllCollapse}>
            <Typography bold color="eerieBlack1" level="small">
              {isAllExpanded
                ? labels.COLLAPSE_ALL_CATEGORIES
                : labels.EXPAND_ALL_CATEGORIES}
            </Typography>
            {isAllExpanded ? <Minus /> : <Plus />}
          </div>
        </div>
        <div className={classes.filters_container}>
          <MoreFiltersGroup
            isExpanded={isBedroomTypesOpen}
            onCollapse={() => setIsBedroomTypesOpen(!isBedroomTypesOpen)}
            labels={labels}
            title={labels.APARTMENT_UNIT_TYPES}
          >
            <BedroomTypes
              labels={labels}
              filters={selectedFilters.filter(
                (filter) => filter.is === labels.APARTMENT_UNIT_TYPES,
              )}
              filterToRemove={filterToRemove}
              onChange={handleFilterChange}
              onClear={handleClearFilters}
              shouldClear={shouldClear}
            />
          </MoreFiltersGroup>
          <MoreFiltersGroup
            isExpanded={isHealthServicesOpen}
            onCollapse={() => setIsHealthServicesOpen(!isHealthServicesOpen)}
            labels={labels}
            title={labels.HEALTHCARE_SERVICES}
          >
            <HealthcareServices
              labels={labels}
              filters={selectedFilters.filter(
                (filter) => filter.is === labels.HEALTHCARE_SERVICES,
              )}
              filterToRemove={filterToRemove}
              onChange={handleFilterChange}
              onClear={handleClearFilters}
              shouldClear={shouldClear}
            />
          </MoreFiltersGroup>
          <MoreFiltersGroup
            isExpanded={isCommunityAmenitiesOpen}
            onCollapse={() =>
              setIsCommunityAmenitiesOpen(!isCommunityAmenitiesOpen)
            }
            labels={labels}
            title={labels.COMMUNITY_AMENITIES}
          >
            <CommunityAmenities
              labels={labels}
              filters={selectedFilters.filter(
                (filter) => filter.is === labels.COMMUNITY_AMENITIES,
              )}
              filterToRemove={filterToRemove}
              onChange={handleFilterChange}
              onClear={handleClearFilters}
              shouldClear={shouldClear}
            />
          </MoreFiltersGroup>
          <MoreFiltersGroup
            isExpanded={isRoomAmenitiesOpen}
            onCollapse={() => setIsRoomAmenitiesOpen(!isRoomAmenitiesOpen)}
            labels={labels}
            title={labels.ROOM_AMENITIES}
          >
            <RoomAmenities
              labels={labels}
              filters={selectedFilters.filter(
                (filter) => filter.is === labels.ROOM_AMENITIES,
              )}
              filterToRemove={filterToRemove}
              onChange={handleFilterChange}
              onClear={handleClearFilters}
              shouldClear={shouldClear}
            />
          </MoreFiltersGroup>
        </div>
      </div>
      <div className={classes.footer}>
        <Button size="small" onClick={() => handleClearAll()} type="light">
          {labels.CLEAR}
        </Button>
        <Button
          onClick={handleSelectedFilters}
          size="small"
          type={areSelectedFilters ? 'secondary' : 'disabled'}
        >
          {labels.APPLY_FILTERS}
        </Button>
      </div>
    </Drawer>
  );
  //#endregion Render
};

//#region PropTypes and Defaults
MoreFiltersSidePanel.propTypes = {
  isOpen: PropTypes.bool,
  filterToRemove: PropTypes.string,
  selectedMoreFilters: PropTypes.array,
  shouldClearFilters: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  labels: PropTypes.object,
};

MoreFiltersSidePanel.defaultProps = {};
//#endregion PropTypes and Defaults

export default MoreFiltersSidePanel;
