import React from 'react';

const Memory = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8421 37.6164C27.8504 37.6164 35.153 30.3138 35.153 21.3055C35.153 12.2973 27.8504 4.99463 18.8421 4.99463C9.83389 4.99463 2.53125 12.2973 2.53125 21.3055C2.53125 30.3138 9.83389 37.6164 18.8421 37.6164Z"
        fill="#D5ECE8"
      />
      <path
        d="M44.71 26.6162C44.71 24.2655 43.0566 22.1678 40.8508 21.685C42.018 20.7867 42.7827 19.5294 42.7827 17.9429C42.7827 15.23 40.5834 13.0307 37.8703 13.0307C36.9656 13.0307 36.129 13.2924 35.4011 13.719C35.4282 13.5229 35.461 13.3283 35.461 13.1246C35.4612 10.7293 33.5196 8.78796 31.1246 8.78796C28.7295 8.78796 26.788 10.7293 26.788 13.1244C26.788 15.5194 26.788 37.7125 26.788 40.1075C26.788 42.5026 28.7295 44.4442 31.1246 44.4442C33.5196 44.4442 35.4612 42.5026 35.4612 40.1075C35.4612 39.9039 35.4284 39.7093 35.4013 39.513C36.1292 39.9395 36.9658 40.2014 37.8705 40.2014C40.5834 40.2014 42.7829 38.0021 42.7829 35.2892C42.7829 33.7027 42.0182 32.3071 40.8512 31.4089C43.0566 30.926 44.71 28.9666 44.71 26.6162Z"
        fill="#DDE3F8"
      />
      <path
        d="M31.1246 45.203C28.3151 45.203 26.0293 42.9172 26.0293 40.1077V13.1244C26.0293 10.3149 28.3149 8.0293 31.1246 8.0293C33.7354 8.0293 35.894 10.0035 36.1862 12.5376C36.7323 12.3608 37.2954 12.272 37.8704 12.272C40.9974 12.272 43.5413 14.816 43.5413 17.9429C43.5413 19.2368 43.1053 20.4074 42.2685 21.3855C44.1803 22.334 45.4687 24.3595 45.4687 26.6161C45.4687 28.8105 44.1756 30.7881 42.2575 31.7186C43.0839 32.7202 43.5413 33.9695 43.5413 35.2892C43.5413 38.4161 40.9974 40.96 37.8704 40.96C37.2954 40.96 36.7323 40.8711 36.1862 40.6945C35.894 43.2288 33.7354 45.203 31.1246 45.203ZM31.1246 9.54659C29.1517 9.54659 27.5466 11.1517 27.5466 13.1244V40.1077C27.5466 42.0806 29.1517 43.6857 31.1246 43.6857C33.0972 43.6857 34.7023 42.0806 34.7023 40.1077C34.7023 39.988 34.6837 39.8581 34.6623 39.7073L34.6496 39.6167C34.61 39.3288 34.7382 39.0437 34.9798 38.8823C35.2214 38.7207 35.5338 38.7118 35.7847 38.8586C36.4463 39.2465 37.148 39.4429 37.8703 39.4429C40.1606 39.4429 42.0238 37.5797 42.0238 35.2894C42.0238 34.0053 41.4275 32.8103 40.388 32.0103C40.1543 31.8305 40.0459 31.5314 40.1103 31.2433C40.1748 30.9554 40.4001 30.731 40.6884 30.6681C42.579 30.254 43.9512 28.5501 43.9512 26.6163C43.9512 24.6347 42.5484 22.8335 40.6884 22.4261C40.4001 22.3632 40.1748 22.1386 40.1103 21.8509C40.0459 21.5632 40.1542 21.2639 40.388 21.0839C41.4734 20.2484 42.0238 19.1916 42.0238 17.9429C42.0238 15.6526 40.1606 13.7893 37.8703 13.7893C37.148 13.7893 36.4463 13.9858 35.7847 14.3737C35.5342 14.5205 35.2216 14.5117 34.9798 14.35C34.7382 14.1886 34.61 13.9035 34.6496 13.6156L34.6623 13.5247C34.6837 13.3741 34.7023 13.2442 34.7023 13.1246C34.7023 11.1517 33.0972 9.54659 31.1246 9.54659Z"
        fill="#0D1321"
      />
      <path
        d="M8.86597 26.6162C8.86597 24.2655 10.5194 22.1678 12.7252 21.685C11.5582 20.7867 10.7933 19.5294 10.7933 17.9429C10.7933 15.23 12.9926 13.0307 15.7057 13.0307C16.6104 13.0307 17.447 13.2924 18.1749 13.719C18.1478 13.5229 18.115 13.3283 18.115 13.1246C18.1148 10.7293 20.0564 8.78796 22.4514 8.78796C24.8465 8.78796 26.788 10.7293 26.788 13.1244C26.788 15.5194 26.788 37.7125 26.788 40.1075C26.788 42.5026 24.8465 44.4442 22.4514 44.4442C20.0564 44.4442 18.1148 42.5026 18.1148 40.1075C18.1148 39.9039 18.1476 39.7093 18.1747 39.513C17.4468 39.9395 16.6102 40.2014 15.7055 40.2014C12.9926 40.2014 10.7931 38.0021 10.7931 35.2892C10.7931 33.7027 11.5578 32.3071 12.7248 31.4089C10.5194 30.926 8.86597 28.9666 8.86597 26.6162Z"
        fill="#F3E0D4"
      />
      <path
        d="M22.4512 45.203C19.8403 45.203 17.6818 43.2288 17.3895 40.6945C16.8435 40.8713 16.2804 40.96 15.7053 40.96C12.5784 40.96 10.0345 38.4161 10.0345 35.2892C10.0345 33.9695 10.4917 32.7204 11.3183 31.7186C9.40041 30.7881 8.1073 28.8105 8.1073 26.6161C8.1073 24.3595 9.39567 22.334 11.3075 21.3855C10.4707 20.4074 10.0346 19.2368 10.0346 17.9429C10.0346 14.816 12.5786 12.272 15.7055 12.272C16.2806 12.272 16.8437 12.361 17.3897 12.5376C17.6818 10.0037 19.8405 8.0293 22.4514 8.0293C25.2609 8.0293 27.5467 10.3149 27.5467 13.1244V40.1077C27.5467 42.9172 25.2609 45.203 22.4512 45.203ZM18.1747 38.7545C18.3219 38.7545 18.4689 38.7973 18.5962 38.8823C18.8378 39.0437 18.966 39.3288 18.9264 39.6167L18.9136 39.7073C18.8922 39.8581 18.8736 39.988 18.8736 40.1077C18.8736 42.0806 20.4787 43.6857 22.4514 43.6857C24.4243 43.6857 26.0294 42.0806 26.0294 40.1077V13.1244C26.0294 11.1517 24.4243 9.54659 22.4512 9.54659C20.4785 9.54659 18.8734 11.1517 18.8734 13.1244C18.8734 13.244 18.892 13.374 18.9135 13.5246L18.9262 13.6154C18.9658 13.9033 18.8376 14.1884 18.596 14.3498C18.3541 14.5114 18.0416 14.5199 17.791 14.3735C17.1295 13.9856 16.4278 13.7891 15.7055 13.7891C13.4152 13.7891 11.5519 15.6524 11.5519 17.9427C11.5519 19.1915 12.1023 20.2481 13.1878 21.0837C13.4214 21.2637 13.5299 21.5628 13.4654 21.8507C13.4009 22.1386 13.1756 22.363 12.8873 22.4259C11.0271 22.8333 9.62459 24.6345 9.62459 26.6161C9.62459 28.5499 10.9968 30.254 12.8873 30.6679C13.1756 30.7308 13.4009 30.9554 13.4654 31.2431C13.5299 31.5312 13.4216 31.8303 13.1878 32.0101C12.1482 32.8103 11.5519 34.0054 11.5519 35.2892C11.5519 37.5795 13.4152 39.4428 15.7055 39.4428C16.4278 39.4428 17.1295 39.2463 17.791 38.8584C17.9098 38.7892 18.0422 38.7545 18.1747 38.7545Z"
        fill="#0D1321"
      />
      <path
        d="M29.6791 23.0381C29.2601 23.0381 28.9204 22.6984 28.9204 22.2794C28.9204 20.2671 30.5578 18.6298 32.5701 18.6298H33.5337C34.7094 18.6298 35.6661 17.6731 35.6661 16.4974C35.6661 16.0785 36.0058 15.7388 36.4247 15.7388C36.8437 15.7388 37.1834 16.0785 37.1834 16.4974C37.1834 18.5097 35.546 20.1471 33.5337 20.1471H32.5701C31.3944 20.1471 30.4377 21.1037 30.4377 22.2794C30.4377 22.6984 30.098 23.0381 29.6791 23.0381Z"
        fill="#0D1321"
      />
      <path
        d="M39.3157 27.8565H38.8339C37.0871 27.8565 35.666 26.302 35.666 24.3912C35.666 22.4804 37.0871 20.9263 38.8339 20.9263H41.2432C41.6622 20.9263 42.0018 21.266 42.0018 21.6849C42.0018 22.1039 41.6622 22.4436 41.2432 22.4436H38.8339C37.9237 22.4436 37.1833 23.3171 37.1833 24.3914C37.1833 25.4656 37.9237 26.3394 38.8339 26.3394H39.3157C39.7346 26.3394 40.0743 26.6791 40.0743 27.0981C40.0743 27.517 39.7346 27.8565 39.3157 27.8565Z"
        fill="#0D1321"
      />
      <path
        d="M32.5701 37.4934C32.3761 37.4934 32.1816 37.4192 32.0337 37.2711C31.7375 36.9748 31.7375 36.4944 32.0337 36.1984L33.7439 34.4884C34.3622 33.8701 34.7026 33.0479 34.7026 32.1736C34.7026 31.5251 34.5126 30.8973 34.153 30.3577L32.9027 28.4824C32.6702 28.1338 32.7645 27.6628 33.1131 27.4303C33.4615 27.1972 33.9326 27.292 34.1651 27.6406L35.4154 29.516C35.9417 30.3056 36.2199 31.2245 36.2199 32.1736C36.2199 33.4532 35.7217 34.6564 34.8168 35.5611L33.1066 37.2711C32.9583 37.4192 32.7641 37.4934 32.5701 37.4934Z"
        fill="#0D1321"
      />
      <path
        d="M31.3597 33.8232C30.9408 33.8232 30.6011 33.4836 30.6011 33.0646C30.6011 31.8096 29.5801 30.7887 28.3251 30.7887H27.4148C26.9958 30.7887 26.6561 30.449 26.6561 30.03C26.6561 29.611 26.9958 29.2714 27.4148 29.2714H28.3251C30.4167 29.2714 32.1184 30.973 32.1184 33.0646C32.1184 33.4836 31.7787 33.8232 31.3597 33.8232Z"
        fill="#0D1321"
      />
      <path
        d="M23.9368 23.0381C23.5179 23.0381 23.1782 22.6984 23.1782 22.2794C23.1782 21.1037 22.2215 20.1471 21.0458 20.1471H20.0822C18.0698 20.1471 16.4325 18.5097 16.4325 16.4974C16.4325 16.0785 16.7722 15.7388 17.1911 15.7388C17.6101 15.7388 17.9498 16.0785 17.9498 16.4974C17.9498 17.6731 18.9064 18.6298 20.0822 18.6298H21.0458C23.0581 18.6298 24.6955 20.2671 24.6955 22.2794C24.6955 22.6984 24.3558 23.0381 23.9368 23.0381Z"
        fill="#0D1321"
      />
      <path
        d="M14.7819 27.8565H14.3001C13.8812 27.8565 13.5415 27.5168 13.5415 27.0979C13.5415 26.6789 13.8812 26.3392 14.3001 26.3392H14.7819C15.6921 26.3392 16.4325 25.4654 16.4325 24.3912C16.4325 23.317 15.6921 22.4436 14.7819 22.4436H12.3728C11.9538 22.4436 11.6141 22.1039 11.6141 21.6849C11.6141 21.266 11.9538 20.9263 12.3728 20.9263H14.7819C16.5286 20.9263 17.9498 22.4805 17.9498 24.3914C17.9498 26.3022 16.5286 27.8565 14.7819 27.8565Z"
        fill="#0D1321"
      />
      <path
        d="M21.6717 36.4738C20.6799 36.4738 19.7153 36.1657 18.8929 35.5768C18.1214 35.0244 17.5363 34.2631 17.2008 33.3752L16.4042 31.2673C16.2561 30.8755 16.4537 30.4376 16.8455 30.2896C17.2377 30.1408 17.6753 30.339 17.8232 30.731L18.62 32.8391C18.8493 33.4456 19.2489 33.9657 19.7764 34.3435C20.4872 34.8527 21.3543 35.0543 22.2164 34.9115L24.6025 34.5168C25.0144 34.4468 25.4061 34.7281 25.4747 35.1416C25.543 35.5548 25.2635 35.9454 24.8502 36.0138L22.4641 36.4085C22.1995 36.4519 21.9347 36.4738 21.6717 36.4738Z"
        fill="#0D1321"
      />
      <path
        d="M22.863 29.2714C22.4441 29.2714 22.1044 28.9317 22.1044 28.5127C22.1044 26.4211 23.806 24.7195 25.8976 24.7195H26.808C27.2269 24.7195 27.5666 25.0592 27.5666 25.4781C27.5666 25.8971 27.2269 26.2368 26.808 26.2368H25.8976C24.6426 26.2368 23.6217 27.2577 23.6217 28.5127C23.6217 28.9317 23.282 29.2714 22.863 29.2714Z"
        fill="#0D1321"
      />
      <path
        d="M39.705 8.78789C39.5109 8.78789 39.3165 8.71373 39.1686 8.56561C38.8723 8.26936 38.8723 7.78894 39.1686 7.49288L41.4445 5.21694C41.7406 4.92069 42.2214 4.92069 42.5173 5.21694C42.8135 5.51319 42.8135 5.9936 42.5173 6.28967L40.2413 8.56561C40.0934 8.71373 39.8992 8.78789 39.705 8.78789Z"
        fill="#0D1321"
      />
      <path
        d="M44.2567 11.0638H41.2221C40.8032 11.0638 40.4635 10.7241 40.4635 10.3052C40.4635 9.88619 40.8032 9.54651 41.2221 9.54651H44.2567C44.6757 9.54651 45.0154 9.88619 45.0154 10.3052C45.0154 10.7241 44.6757 11.0638 44.2567 11.0638Z"
        fill="#0D1321"
      />
      <path
        d="M37.4289 7.27063C37.01 7.27063 36.6703 6.93094 36.6703 6.51198V3.4774C36.6703 3.05843 37.01 2.71875 37.4289 2.71875C37.8479 2.71875 38.1876 3.05843 38.1876 3.4774V6.51198C38.1876 6.93094 37.8479 7.27063 37.4289 7.27063Z"
        fill="#0D1321"
      />
    </svg>
  );
};

export default Memory;
