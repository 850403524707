import React from 'react';
import PropTypes from 'prop-types';

const TwoBedrooms = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M22 10.551V5.99998C22 4.622 20.878 3.5 19.5 3.5H4.5C3.12202 3.5 2.00002 4.622 2.00002 5.99998V10.551C0.861 10.783 0 11.793 0 13V16V18V20C0 20.276 0.224016 20.5 0.500016 20.5C0.776016 20.5 1.00003 20.276 1.00003 20V18.5H23V20C23 20.276 23.224 20.5 23.5 20.5C23.776 20.5 24 20.276 24 20V18V16V13C24 11.793 23.139 10.783 22 10.551ZM3 5.99998C3 5.17297 3.67298 4.49998 4.5 4.49998H19.5C20.327 4.49998 21 5.17297 21 5.99998V10.5H19.624C19.722 10.225 19.747 9.92797 19.674 9.63598L19.174 7.63597C19.007 6.96697 18.408 6.49995 17.719 6.49995H14.5C13.673 6.49995 13 7.17294 13 7.99995V9.99997C13 10.176 13.036 10.343 13.092 10.5H10.908C10.964 10.343 11 10.176 11 9.99997V7.99995C11 7.17294 10.327 6.49995 9.50002 6.49995H6.28102C5.592 6.49995 4.99303 6.96795 4.82602 7.63597L4.326 9.63598C4.25302 9.92797 4.278 10.225 4.37602 10.5H3V5.99998ZM18.613 10.308C18.518 10.43 18.374 10.5 18.219 10.5H14.5C14.224 10.5 14 10.276 14 9.99997V7.99995C14 7.72395 14.224 7.49994 14.5 7.49994H17.719C17.949 7.49994 18.148 7.65594 18.204 7.87892L18.704 9.87894C18.742 10.029 18.709 10.186 18.613 10.308ZM9.999 7.99897V9.99898C9.999 10.275 9.77498 10.499 9.49898 10.499H5.77997C5.62495 10.499 5.48095 10.429 5.38598 10.307C5.29102 10.185 5.25698 10.028 5.295 9.878L5.79502 7.87798C5.85202 7.65598 6.051 7.49998 6.28003 7.499H9.49903C9.77498 7.499 9.999 7.72297 9.999 7.99897ZM23 17.5H0.999984V16.5H23V17.5H23ZM23 15.5H0.999984V13C0.999984 12.173 1.67297 11.5 2.49998 11.5H5.781H9.50002H14.5H18.219H21.5001C22.3271 11.5 23.0001 12.173 23.0001 13V15.5H23Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

TwoBedrooms.propTypes = {
  className: PropTypes.string,
};

export default TwoBedrooms;
