const cloneDeep = require('lodash.clonedeep');

const enableChicletByStepType = (formSteps, stepType) => {
  let newSteps = cloneDeep(formSteps);
  newSteps = newSteps.map((step) => {
    if (step.type === stepType.key) {
      step.disabled = false;
      return step;
    }
    return step;
  });
  return newSteps;
};

const disableChicletByStepType = (formSteps, stepType) => {
  let newSteps = cloneDeep(formSteps);
  newSteps = newSteps.map((step) => {
    if (step.type === stepType.key) {
      step.disabled = true;
      return step;
    }
    return step;
  });
  return newSteps;
};

const setVisibilityByStepType = (formSteps, stepType, isVisible) => {
  let newSteps = cloneDeep(formSteps);
  newSteps = newSteps.map((step) => {
    if (step.type === stepType.key) {
      step.extraFields.hidden = !isVisible;
      step.hidden = !isVisible;
      return step;
    }
    return step;
  });
  return newSteps;
};

const showChicletByStepType = (formSteps, stepType) => {
  return setVisibilityByStepType(formSteps, stepType, true);
};

const hideChicletByStepType = (formSteps, stepType) => {
  return setVisibilityByStepType(formSteps, stepType, false);
};

const formServiceHelper = {
  enableChicletByStepType,
  disableChicletByStepType,
  showChicletByStepType,
  hideChicletByStepType,
};

export default formServiceHelper;
