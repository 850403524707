import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

const Container = ({ children, className, spacing }) => {
  return (
    <Grid spacing={spacing} container className={className}>
      {children}
    </Grid>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  spacing: PropTypes.number,
  className: PropTypes.string,
};

Container.defaultProps = {
  spacing: 1,
};

export default Container;
