import { combineReducers } from 'redux';
import callcenterReducer from './callcenter/rootReducer';
import telephonyReducer from './telephony/rootReducer';
import adminReducer from './admin/rootReducer';

const rootReducer = combineReducers({
  callcenter: callcenterReducer,
  telephony: telephonyReducer,
  admin: adminReducer,
});

export default rootReducer;
