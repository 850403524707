import { put, takeLeading } from 'redux-saga/effects';
import graphqlClient from 'stateManagement/graphqlClient';
import {
  getCatalogsError,
  getCatalogsSuccess,
  GET_CATALOGS_REQUEST,
} from '../actions/catalogsActions';
import findEnumQuery from '../graphql/findEnumQuery';
import findFormEnumQuery from '../../../admin/catalogs/graphql/findFormEnumQuery';
import { logInquiryErrorViewed } from 'services/callCenter/amplitudeEventsService';

const FAMILY_RELATIONSHIP_ENUM_NAME = 'familiar_relationship';
const MOVE_DATE_RANGE_ENUM_NAME = 'move_date_range';
const REFERRAL_SOURCE_ENUM_NAME = 'referral_source';
const CLOSE_REASONS_ENUM_NAME = 2;

const getCatalog = (enumFieldName) => {
  return graphqlClient
    .makeGraphqlRequest({
      query: findEnumQuery,
      operationName: 'findEnumValuesByFieldName',
      variables: {
        fieldName: enumFieldName,
      },
    })
    .then((response) => response.data.data.findEnumValuesByFieldName);
};

const getCloseReason = (formEnumFieldId) => {
  return graphqlClient
    .makeGraphqlRequest({
      query: findFormEnumQuery,
      operationName: 'findFormEnumValuesById',
      variables: {
        formEnumFieldId,
      },
    })
    .then((response) => response.data.data.findFormEnumValuesById);
};

function* getCatalogsSaga() {
  try {
    const familyRelationships = yield getCatalog(FAMILY_RELATIONSHIP_ENUM_NAME);
    const moveDateRanges = yield getCatalog(MOVE_DATE_RANGE_ENUM_NAME);
    const referralSources = yield getCatalog(REFERRAL_SOURCE_ENUM_NAME);
    const closeReasons = yield getCloseReason(CLOSE_REASONS_ENUM_NAME);

    const catalog = {
      familyRelationships,
      moveDateRanges,
      referralSources,
      closeReasons,
    };

    yield put(getCatalogsSuccess(catalog));
  } catch (err) {
    logInquiryErrorViewed(null, err.message);
    yield put(getCatalogsError(err));
  }
}

function* watchCatalogs() {
  yield takeLeading(GET_CATALOGS_REQUEST, getCatalogsSaga);
}

export { watchCatalogs, getCatalogsSaga };
