import gql from 'graphql-tag';

export default gql`
  mutation(
    $residentId: Int!
    $careTypes: [String!]!
    $oneId: String
    $familyFileId: Int
  ) {
    updateResidentCareTypes(
      input: {
        residentId: $residentId
        careTypes: $careTypes
        oneId: $oneId
        familyFileId: $familyFileId
      }
    ) {
      careTypes {
        code
      }
    }
  }
`;
