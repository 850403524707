import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import clsx from 'clsx';
import Typography from '../../common/Typography/Typography';
import { withLabels } from '../../context/LabelContext';
import { withAuthenticationContext } from '../../context/Authentication';
import { useStyles } from './InitialConsultation.style';
import Button from '../../common/Button/Button';
import User from '../../common/Icons/basic/User';
import Cross from '../../common/Icons/care/Cross';
import NotesSidePanel from '../../components/NotesSidePanel/NotesSidePanel';
import Expandable from '../../common/Expandable/Expandable';
import Loading from '../../components/Loading/Loading';
import Case from '../../common/Icons/basic/Case';
import Smiled from '../../common/Icons/reactions/Smiled';
import CallPhone from '../../common/Icons/call/CallPhone';
import Dollar from '../../common/Icons/finance/Dollar';
import Home from '../../common/Icons/basic/Home';
import Sidebar from '../SendReferral/Sidebar/Sidebar';
import Container from '../../common/Container/Container';
import ContainerItem from '../../common/ContainerItem/ContainerItem';
import Notes from '../../components/NotesSidePanel/Notes';
import { getFamilyFileIdFromUrl } from '../../utils/utils';
import { useLocation } from 'react-router-dom';
import TopBarUser from '../../components/TopBar/TopBarUser';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useExternalId } from 'hooks/useExternalId';
import { logFamilyFilePageViewed } from 'services/familyFileService';
import FinancialCriteriaEdit from '../../components/FamilyFile/FinancialCriteria/FinancialCriteriaEdit';
import useInitialConsultationForm from './useInitialConsultationForm';
import CommunityPreferencesEdit from '../../components/FamilyFile/CommunityPreferences/CommunityPreferencesEdit';
import ContactInformationEdit from '../../components/Contacts/EditContactsInformation/ContactInformationEdit';
import MedicalBackgroundDesktopEdit from 'components/FamilyFile/MedicalBackground/MedicalBackgroundDesktopEdit';

const SubformContainer = (props) => {
  const classes = useStyles();

  // eslint-disable-next-line react/prop-types
  return <div className={classes.subform_container}>{props.children}</div>;
};

const InitialConsultation = ({ labels, auth, flags }) => {
  const classes = useStyles();
  const isLGUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const location = useLocation();
  const familyFileId = getFamilyFileIdFromUrl(location);
  const {
    formData: {
      financialCriteria: financialCriteriaData,
      medicalBackground: medicalBackgroundData,
      communityPreferences: communityPreferencesData,
      contacts: contactsData,
    },
    setFinancialCriteriaFormState,
    setMedicalBackgroundFormState,
    setCommunityPreferenceState,
    submitInitialConsultationForm,
    familyFileData: data,
    isLoading,
    isSubmitting,
    error,
  } = useInitialConsultationForm({
    familyFileId,
    section: 'family file',
    screenName: 'initial consultation',
  });

  const [isNotesPanelOpen, setIsNotesPanelOpen] = useState(false);
  const [primaryContactName, setPrimaryContactName] = useState('');
  const [residentName, setResidentName] = useState('');
  const [isSelfLead, setIsSelfLead] = useState(false);

  useEffect(() => {
    isLGUp && setIsNotesPanelOpen(false);
  }, [isLGUp]);

  const { externalId } = useExternalId(familyFileId);

  useEffect(() => {
    if (externalId) {
      logFamilyFilePageViewed(
        familyFileId,
        externalId,
        'Initial Consultation',
        'none',
      );
    }
  }, [externalId, familyFileId]);

  useEffect(() => {
    const resident = data?.findFamilyFileById?.residents[0]?.profileData;
    if (resident) {
      setResidentName(`${resident.firstName} ${resident.lastName}`);
    }
    const contactList = data?.findFamilyFileById?.contacts;
    if (contactList) {
      const primaryContact = contactList.find(
        (contact) => contact.profileData.isPrimary,
      );
      setPrimaryContactName(
        `${primaryContact.profileData.firstName} ${primaryContact.profileData.lastName}`,
      );
    }

    const audienceKey = data?.findFamilyFileById?.audienceKey;
    if (audienceKey) {
      const selfLeadsAudiences = [
        'self_not_urgent_veteran_financially_unqualified',
        'self_urgent_financially_unqualified',
        'self_not_urgent_financially_qualified',
      ];
      const isSelfLead = selfLeadsAudiences.some((selfAudience) =>
        audienceKey?.includes(selfAudience),
      );

      setIsSelfLead(isSelfLead);
    }
  }, [data, familyFileId]);

  if (isLoading) return <Loading height="calc(100vh - 197px)" />;
  if (error) return `Error! ${error.message}`;

  const handleSaveButtonClick = () => {
    submitInitialConsultationForm({ submitButtonIdentifier: 'Save' });
  };

  return (
    <>
      <div className={classes.top_bar}>
        <Button
          className={clsx('familyButtonClasses', classes.family_file_button)}
          startIcon={<User />}
        >
          <Typography color="platinum5">{primaryContactName}</Typography>
          {!isSelfLead && (
            <>
              &nbsp;
              <Typography color="cosmicCobalt4" level="tiny">
                for
              </Typography>
              &nbsp;
              <Typography color="platinum5">{residentName}</Typography>
            </>
          )}
        </Button>
        <TopBarUser auth={auth} />
      </div>
      <div className={classes.content}>
        <Container spacing={3}>
          <ContainerItem xs={isLGUp ? 9 : 12}>
            {flags.isInitialConsultationEnabled && (
              <div className={classes.formControlsBar}>
                <div className={classes.formControls}>
                  {isSubmitting && (
                    <div>
                      <Loading height="16px" />
                    </div>
                  )}
                  <Button type="secondary" onClick={handleSaveButtonClick}>
                    {labels.SAVE_UPDATES}
                  </Button>
                </div>
              </div>
            )}
            <div className={classes.expandables}>
              <ContainerItem xs={12}>
                <Expandable
                  className={classes.expandable}
                  accordionColor="blue"
                  isExpanded
                  themeStyle="accordion"
                  title={labels.PROFESSIONAL_SOURCE}
                  titleIcon={<Case />}
                >
                  <div>
                    <span>{labels.PROFESSIONAL_SOURCE}</span>
                  </div>
                </Expandable>
              </ContainerItem>
              <ContainerItem xs={12}>
                <Expandable
                  className={classes.expandable}
                  accordionColor="blue"
                  isExpanded
                  themeStyle="accordion"
                  title={labels.INTRODUCTION}
                  titleIcon={<Smiled />}
                >
                  <div>
                    <span>{labels.INTRODUCTION}</span>
                  </div>
                </Expandable>
              </ContainerItem>
              {contactsData.length > 0 && (
                <ContainerItem xs={12}>
                  <Expandable
                    className={classes.expandable}
                    accordionColor="blue"
                    isExpanded
                    themeStyle="accordion"
                    title={labels.CONTACT_INFORMATION}
                    titleIcon={<CallPhone />}
                  >
                    <SubformContainer>
                      {contactsData.map((contact, key) => (
                        <ContactInformationEdit
                          labels={labels}
                          flags={flags}
                          key={key}
                          familyFileId
                          isPrimaryContact={contact.isPrimary}
                          contactInfo={contact}
                          checkForChanges={() => ''}
                        />
                      ))}
                    </SubformContainer>
                  </Expandable>
                </ContainerItem>
              )}
              <ContainerItem xs={12}>
                <Expandable
                  className={classes.expandable}
                  accordionColor="blue"
                  isExpanded
                  themeStyle="accordion"
                  title={labels.RESIDENT_INFORMATION}
                  titleIcon={<User />}
                >
                  <div>
                    <span>{labels.RESIDENT_INFORMATION}</span>
                  </div>
                </Expandable>
              </ContainerItem>
              {medicalBackgroundData && (
                <ContainerItem xs={12}>
                  <Expandable
                    className={classes.expandable}
                    accordionColor="blue"
                    isExpanded
                    themeStyle="accordion"
                    title={labels.MEDICAL_BACKGROUND}
                    titleIcon={<Cross />}
                  >
                    {flags.isInitialConsultationEnabled ? (
                      <SubformContainer>
                        <MedicalBackgroundDesktopEdit
                          medicalBackgroundData={medicalBackgroundData}
                          labels={labels}
                          onChange={setMedicalBackgroundFormState}
                          isSelfLead={isSelfLead}
                          isSubmitting={isSubmitting}
                        />
                      </SubformContainer>
                    ) : (
                      <div>
                        <span>{labels.MEDICAL_BACKGROUND}</span>
                      </div>
                    )}
                  </Expandable>
                </ContainerItem>
              )}
              {financialCriteriaData && (
                <ContainerItem xs={12}>
                  <Expandable
                    className={classes.expandable}
                    accordionColor="blue"
                    isExpanded
                    themeStyle="accordion"
                    title={labels.FINANCIAL_CRITERIA}
                    titleIcon={<Dollar />}
                  >
                    {flags.isInitialConsultationEnabled ? (
                      <SubformContainer>
                        <div className={classes.financial_criteria_container}>
                          <FinancialCriteriaEdit
                            allowEdit
                            financialCriteriaData={financialCriteriaData}
                            labels={labels}
                            onChange={setFinancialCriteriaFormState}
                          />
                        </div>
                      </SubformContainer>
                    ) : (
                      <div>
                        <span>{labels.FINANCIAL_CRITERIA}</span>
                      </div>
                    )}
                  </Expandable>
                </ContainerItem>
              )}
              {communityPreferencesData && (
                <ContainerItem xs={12}>
                  <Expandable
                    className={classes.expandable}
                    accordionColor="blue"
                    isExpanded
                    themeStyle="accordion"
                    title={labels.CARE_AND_PREFERENCES}
                    titleIcon={<Home />}
                  >
                    {flags.isInitialConsultationEnabled ? (
                      <SubformContainer>
                        <CommunityPreferencesEdit
                          communityPreferences={communityPreferencesData}
                          labels={labels}
                          onChange={setCommunityPreferenceState}
                        />
                      </SubformContainer>
                    ) : (
                      <div>
                        <span>{labels.CARE_AND_PREFERENCES}</span>
                      </div>
                    )}
                  </Expandable>
                </ContainerItem>
              )}
            </div>
          </ContainerItem>
          {isLGUp && (
            <ContainerItem className={classes.notes_panel} xs={3}>
              <Notes
                familyFileId={familyFileId}
                labels={labels}
                isOpen={true}
                onClose={() => setIsNotesPanelOpen(false)}
                screenName="initial consultation"
              />
            </ContainerItem>
          )}
        </Container>
        {!isLGUp && !isNotesPanelOpen && (
          <Sidebar
            labels={labels}
            onClickShowNotes={() => setIsNotesPanelOpen(true)}
            hideFamilyFileIcon
            onClickShowFamilyFile={() => false}
          />
        )}
      </div>
      <NotesSidePanel
        familyFileId={familyFileId}
        labels={labels}
        isOpen={isNotesPanelOpen}
        onClose={() => setIsNotesPanelOpen(false)}
        screenName="initial consultation"
        isUnderneathTopBar
      />
    </>
  );
};

InitialConsultation.propTypes = {
  labels: PropTypes.object,
  auth: PropTypes.shape({
    user: PropTypes.shape({
      given_name: PropTypes.string,
      family_name: PropTypes.string,
      picture: PropTypes.string.isRequired,
      name: PropTypes.string,
    }).isRequired,
    logout: PropTypes.func.isRequired,
  }),

  /**
   * Object containing feature flags.
   */
  flags: PropTypes.shape({
    isInitialConsultationEnabled: PropTypes.bool.isRequired,
  }).isRequired,
};

export default withLDConsumer()(
  withAuthenticationContext(withLabels(InitialConsultation)),
);
