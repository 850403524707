import React from 'react';
import PropTypes from 'prop-types';

const Send = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M2.74023 2.25203L4.10524 11.8071L4.87699 12L4.10524 12.1929L2.74023 21.748L22.2362 12L2.74023 2.25203ZM5.89495 10.1929L5.25996 5.74796L17.764 12L5.25996 18.252L5.89495 13.8071L13.1232 12L5.89495 10.1929Z"
      />
    </svg>
  );
};

Send.propTypes = {
  className: PropTypes.string,
};

export default Send;
