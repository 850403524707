import { combineReducers } from 'redux';
import scriptBuilderReducer from './scriptBuilder/reducers/scriptBuilderReducer';
import adminCatalogsReducer from './catalogs/reducers/adminCatalogsReducer';
import scriptPreviewReducer from './scriptPreview/reducers/scriptPreviewReducer';
import snapshotsBuilderReducer from './scriptBuilder/reducers/snapshotsBuilderReducer';

const rootReducer = combineReducers({
  scripts: scriptBuilderReducer,
  catalogs: adminCatalogsReducer,
  scriptPreview: scriptPreviewReducer,
  snapshots: snapshotsBuilderReducer,
});

export default rootReducer;
