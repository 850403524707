import graphqlClient from '../../stateManagement/graphqlClient';
const findOwlCommunitiesQuery = `
query findOwlCommunities(
  $zip: String!
  $distance: Float
  $careTypes: [String!]
  $budgetLte: Int
  $budgetGte: Int
  $groupAttributeNames: [String!]
  $groupNames: [String!]
) {
  findOwlCommunities(
    zipCodes: [$zip]
    distance: $distance
    careTypes: $careTypes
    budgetLte: $budgetLte
    budgetGte: $budgetGte
    groupAttributeNames: $groupAttributeNames
    groupNames: $groupNames
    size: 40
    sort: [{ distance: "asc" }]
    isReferrable: true
  ) {
    id
    name
    organizationName
    address
    address2
    city
    distance
    phoneNumber
    countReferred
    countMovedIn
    state
    zip
    primaryContact
    images {
      isDefault
      assets {
        id
        sizeId
      }
    }
    roomCharges {
      careType
      amount
      apartmentType {
        description
      }
    }
    roomFees {
      amount
      careType
      feeType {
        name
      }
    }
    geoLocation {
      lat
      lon
    }
    title
    ratingAverage
    numberOfReviews
    attributes {
      groupName
      groupAttributes {
        name
        isAvailable
      }
    }
    advisories {
      advisoryId
      advisoryType
      advisoryStatus
      advisoryEndDate
      advisoryStartDate
      vaccineInformation
      vaccineInformationDetails
    }
  }
}`;

const findOwlCommunities = async (variables) => {
  return graphqlClient.makeGraphqlRequest({
    query: findOwlCommunitiesQuery,
    operationName: 'findOwlCommunities',
    variables,
  });
};
const findCommunitiesService = {
  findOwlCommunities,
  findOwlCommunitiesQuery,
};

export default findCommunitiesService;
