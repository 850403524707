import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './CommunityInfo.style';
import Typography from 'common/Typography/Typography';
import Pin from 'common/Icons/maps/Pin';
import CallPhone from 'common/Icons/call/CallPhone';
import Tag from 'common/Tag/Tag';
import ArrowForwardAll from 'common/Icons/arrow/ArrowForwardAll';
import PinCross from 'common/Icons/maps/PinCross';
import PinChecked from 'common/Icons/maps/PinChecked';
import StarFilled from 'common/Icons/basic/StarFilled';
import StarFilledHalf from 'common/Icons/basic/StarFilledHalf';
import StarNo from 'common/Icons/basic/StarNo';
import Vaccine from 'common/Icons/various/Vaccine';
import Link from 'common/Link/Link';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import Modal from 'common/Modal/Modal';
import Button from 'common/Button/Button';
import communityRatingService from 'services/communityRatingsService';

const CommunityInfoXS = ({
  labels,
  communityId,
  name,
  companyName,
  address,
  phone,
  city,
  state,
  zip,
  distance,
  referred,
  moveIns,
  ratingAverage,
  numberOfReviews,
  vaccineInformation,
  logVaccineClick,
}) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getReferredTag = () => {
    if (!referred) {
      return (
        <Tag
          className={classes.empty}
          text={'Not yet referred'}
          icon={<ArrowForwardAll />}
          color="platinum3"
        />
      );
    }
    return (
      <Tag
        text={`Referred (${referred})`}
        icon={<ArrowForwardAll className={classes.referred} />}
        color="fieldDrab5"
      />
    );
  };

  const getMoveInsTag = () => {
    if (!moveIns) {
      return (
        <Tag
          className={classes.empty}
          text={'No move-ins'}
          icon={<PinCross />}
          color="platinum3"
        />
      );
    }
    return (
      <Tag
        text={`Move-Ins (${moveIns})`}
        icon={<PinChecked />}
        color="wintergreenDream5"
      />
    );
  };

  const getAddress = () => {
    const fullAddress = `${address} ${city}, ${state} ${zip}`;
    let distanceLength = 0;
    if (distance) {
      distanceLength = distance.length;
    }
    const isAddressTooLong = fullAddress.length + distanceLength >= 30;

    if (isAddressTooLong) {
      return (
        <div>
          <Typography level="small" color="eerieBlack4">
            {`${address}`}
            {distance && (
              <b className={classes.distance}>{`(${distance} mi)`}</b>
            )}
          </Typography>
          <Typography level="small" color="eerieBlack4">
            {`${city}, ${state} ${zip}`}
          </Typography>
        </div>
      );
    }
    return (
      <Typography level="small" color="eerieBlack4">
        {`${address} ${city}, ${state} ${zip}`}
        {distance && <b className={classes.distance}>{`(${distance} mi)`}</b>}
      </Typography>
    );
  };

  const getRatingAverage = () => {
    if (ratingAverage === 0) {
      return (
        <div className={classes.no_rating_container}>
          <Typography color="disabled">{labels.NO_RATINGS}</Typography>
          <StarNo className={classes.no_rating} />
        </div>
      );
    }

    const roundedRating = ratingAverage.toFixed(1);
    const { wholeStars, halfStar } = communityRatingService.getStarInfo(
      ratingAverage,
      roundedRating,
    );
    const stars = Array.from(Array(wholeStars).keys());

    return (
      <div className={classes.rating_container}>
        <Typography bold color="fieldDrab3">
          {roundedRating}
        </Typography>
        <div className={classes.rating_star_container}>
          {stars.map((key) => (
            <div key={key} className={classes.rating_star}>
              <StarFilled />
            </div>
          ))}
          {halfStar && (
            <div className={classes.rating_star}>
              <StarFilledHalf />
            </div>
          )}
        </div>
        <div className={classes.review_count}>
          <Link
            onClick={(event) =>
              communityRatingService.handleViewReviewsClick(
                event,
                communityId,
                name,
              )
            }
          >
            ({numberOfReviews}{' '}
            {numberOfReviews > 1 ? `${labels.REVIEW}s` : labels.REVIEW})
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.community_info_container_xs}>
      <div className={classes.community_name}>
        <Typography bold>{name}</Typography>
        {vaccineInformation.isVaccineAvailable && (
          <div
            className={classes.community_vaccine}
            onClick={() => {
              logVaccineClick();
              setIsModalOpen(true);
            }}
          >
            <SmartTooltip
              title={labels.COVID_VACCINE_CLINICS_OFFERED}
              placement="top"
            >
              <Vaccine />
            </SmartTooltip>
          </div>
        )}
      </div>
      <Typography className={classes.company_name}>{companyName}</Typography>
      {getRatingAverage()}
      <div className={classes.contact_container}>
        <div className={classes.contact}>
          <Pin />
          {getAddress()}
        </div>
        <div className={classes.contact}>
          <CallPhone />
          <Typography level="small" color="eerieBlack4">
            {phone}
          </Typography>
        </div>
        <div className={classes.tags}>
          {getReferredTag()}
          {getMoveInsTag()}
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        icon={<Vaccine />}
        title={labels.COVID_VACCINATION}
        className={classes.modal_container}
      >
        <div>
          <Typography>
            {labels.PLEASE_INFORM_FAMILY_THAT_COVID_CLINIC_IS_AVAILABLE}{' '}
            <strong>{name}</strong>:
          </Typography>
          <Typography className={classes.vaccine_details}>
            {vaccineInformation.details}
          </Typography>
          <div className={classes.close_button_container}>
            <Button
              type="light"
              onClick={() => {
                setIsModalOpen(false);
              }}
              className={classes.close_button}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

CommunityInfoXS.propTypes = {
  labels: PropTypes.object,
  communityId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  distance: PropTypes.number,
  address: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  referred: PropTypes.number.isRequired,
  moveIns: PropTypes.number.isRequired,
  ratingAverage: PropTypes.number.isRequired,
  numberOfReviews: PropTypes.number.isRequired,
  communitiesFeature: PropTypes.array,
  vaccineInformation: PropTypes.shape({
    isVaccineAvailable: PropTypes.bool.isRequired,
    details: PropTypes.string.isRequired,
  }),
  logVaccineClick: PropTypes.func,
};

CommunityInfoXS.defaultProps = {
  vaccineInformation: {
    isVaccineAvailable: false,
    details: '',
  },
  logVaccineClick: () => {},
};

export default CommunityInfoXS;
