import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  contact_container: {
    display: 'flex',
    flexDirection: 'column',
  },
  search_result_row_container: {
    '& td': {
      padding: '16px 24px',
    },
  },
  name: {
    marginBottom: '4px',
  },
  row: {
    display: 'flex',
    lineHeight: '18px',
  },
  last_contacted_container: {
    display: 'flex',
    lineHeight: '18px',
    marginTop: '16px',
  },
  preferred_location: {
    display: 'flex',
  },
  divider: {
    width: '1px',
    height: '10px',
    margin: '0 5px',
    alignSelf: 'center',
    backgroundColor: theme.palette.light.divider,
  },
  tags_container: {
    display: 'flex',
  },
  tag: {
    height: '28px',
    width: '28px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: theme.palette.care.regalia5,
    color: theme.palette.dark.eerieBlack1,
    fontSize: '12px',
    borderRadius: '50%',
    marginRight: '8px',
  },
  audience_tag: {
    marginTop: '8px',
  },
}));

export { useStyles };
