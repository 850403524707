import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import MaterialTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from './TabPanel';
import { useStyles } from './Tabs.style';

const Tabs = ({ className, tabs, onChange, activeTab, tabStyle }) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(activeTab);
  let materialTabsContainerClass;
  if (tabStyle === 'dark') {
    materialTabsContainerClass = classes.dark_material_tabs_container;
  } else {
    materialTabsContainerClass = classes.material_tabs_container;
  }
  const tabsClass = tabStyle === 'dark' ? classes.dark_tab : classes.tab;

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    onChange(newValue);
  };

  return (
    <div className={className}>
      <Paper square className="tabs_container">
        <MaterialTabs
          className={materialTabsContainerClass}
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          {tabs.map((tab, key) => (
            <Tab
              className={tabsClass}
              key={key}
              label={tab.label}
              disabled={tab.disabled}
            />
          ))}
        </MaterialTabs>
      </Paper>
      <div>
        {tabs.map((tab, key) => {
          return (
            <TabPanel key={key} value={tabIndex} index={key}>
              {tab.component}
            </TabPanel>
          );
        })}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  activeTab: PropTypes.number,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      component: PropTypes.node.isRequired,
    }),
  ).isRequired,
  tabStyle: PropTypes.string,
};

Tabs.defaultProps = {
  className: '',
  activeTab: 0,
  onChange: () => {},
  tabStyle: 'default',
};

export default Tabs;
