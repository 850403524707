import React from 'react';
import PropTypes from 'prop-types';

const IncontinenceCare = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M13.0957 0.931885V24.7414"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.35742 24.7413C7.02408 24.7413 7.61932 24.5032 7.61932 22.9556C7.61932 21.408 7.61932 18.7889 7.61932 18.7889C9.40504 18.7889 10.2384 18.908 10.0003 16.6461C9.76218 14.7413 9.28599 10.8128 9.04789 9.02704C8.8098 7.24133 8.5717 6.76514 7.85742 6.76514L5.35742 7.59847L2.73837 6.76514C2.02408 6.76514 1.78599 7.24133 1.54789 9.02704C1.3098 10.8128 0.833607 14.7413 0.595511 16.6461C0.357416 18.7889 1.19075 18.7889 2.97646 18.7889C2.97646 18.7889 2.97646 21.408 2.97646 22.9556C2.97646 24.5032 3.5717 24.7413 5.35742 24.7413Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.23805 5.21754C4.40472 5.21754 3.69043 4.50326 3.69043 3.66992V2.71754C3.69043 1.88421 4.40472 1.16992 5.23805 1.16992C6.07138 1.16992 6.78567 1.88421 6.78567 2.71754V3.66992C6.66662 4.50326 6.07138 5.21754 5.23805 5.21754Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.4766 7.59847L22.7385 6.76514C24.048 6.76514 24.4052 7.00323 24.4052 8.19371C24.4052 9.62228 24.4052 12.8366 24.4052 14.027C24.4052 15.3366 23.6909 15.5747 22.5004 15.5747C22.5004 15.5747 22.5004 21.6461 22.5004 23.1937C22.5004 24.7413 21.429 24.7413 20.5957 24.7413C19.7623 24.7413 18.6909 24.7413 18.6909 23.1937C18.6909 21.6461 18.6909 15.5747 18.6909 15.5747C17.3814 15.5747 16.7861 15.3366 16.7861 14.027C16.7861 12.8366 16.7861 9.74133 16.7861 8.19371C16.7861 7.00323 17.1433 6.76514 18.4528 6.76514C18.2147 6.76514 20.4766 7.59847 20.4766 7.59847Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.4763 5.21754C19.643 5.21754 18.9287 4.50326 18.9287 3.66992V2.71754C18.9287 1.88421 19.643 1.16992 20.4763 1.16992C21.3097 1.16992 22.024 1.88421 22.024 2.71754V3.66992C22.024 4.50326 21.3097 5.21754 20.4763 5.21754Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0 0.33667)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

IncontinenceCare.propTypes = {
  className: PropTypes.string,
};

export default IncontinenceCare;
