import React, { useState, useRef, useEffect, Fragment } from 'react';
import { useStyles } from './DropdownGroup.style';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ChevronTop from 'common/Icons/arrow/ChevronTop';
import ChevronBottom from 'common/Icons/arrow/ChevronBottom';
const DropdownGroup = ({
  error,
  groups,
  emptyLabel,
  label,
  textHint,
  onChange,
  selectedItem,
  disabled,
  type,
  className,
  isMandatory,
}) => {
  const classes = useStyles();
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(selectedItem);
  const selectClasses = clsx([
    classes.custom_select,
    error && classes.error,
    disabled && classes.disabled,
    selected && classes.filled,
    classes[type],
    className,
  ]);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);
  const selectItem = (item) => {
    onChange(item);
    setSelected(item);
    setIsOpen(false);
  };
  const handleOptionList = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    setSelected(selectedItem);
  }, [selectedItem]);

  const actual = (item) => (
    <div
      key={item.value}
      className={clsx(
        selectedItem && selectedItem.value === item.value && classes.selected,
      )}
      onClick={() => selectItem(item)}
    >
      {item.label}
    </div>
  );
  return (
    <div className={selectClasses} ref={wrapperRef} data-testid="group-id">
      <div className={clsx(classes.label, 'star')}>
        {isMandatory && <span className={classes.mandatory}>* </span>}
        {label}
      </div>
      <div className={classes.select} onClick={handleOptionList} tabIndex="0">
        <div>{selected ? selected.label : emptyLabel}</div>
        <div className={classes.icon}>
          {isOpen ? <ChevronTop /> : <ChevronBottom />}
        </div>
      </div>
      {isOpen && !disabled && (
        <div className={classes.menu}>
          <div className={classes.items_container}>
            {groups.map((group) =>
              group.items.map((item, i) => {
                if (i === 0) {
                  return (
                    <Fragment key={group.name}>
                      <div className={classes.group}>{group.name}</div>
                      {actual(item)}
                    </Fragment>
                  );
                }
                return actual(item);
              }),
            )}
          </div>
        </div>
      )}
      <div className={classes.text_hint}>{textHint}</div>
    </div>
  );
};
DropdownGroup.propTypes = {
  error: PropTypes.bool,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        }),
      ),
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  emptyLabel: PropTypes.string,
  label: PropTypes.string,
  textHint: PropTypes.string,
  type: PropTypes.oneOf(['default', 'textual', 'buttoned']),
  onChange: PropTypes.func,
  selectedItem: PropTypes.object,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  isMandatory: PropTypes.bool,
};
DropdownGroup.defaultProps = {
  emptyLabel: 'Select from list',
  type: 'default',
  error: false,
  disabled: false,
  onChange: () => {},
  className: '',
  isMandatory: false,
};
export default DropdownGroup;
