import React from 'react';

const Cane = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6799 37.76C31.2794 37.76 37.4399 31.5994 37.4399 24C37.4399 16.4006 31.2794 10.24 23.6799 10.24C16.0805 10.24 9.91992 16.4006 9.91992 24C9.91992 31.5994 16.0805 37.76 23.6799 37.76Z"
        fill="#F3F5FC"
      />
      <path
        d="M9.92003 10.24C9.56659 10.24 9.28003 9.95348 9.28003 9.60004V7.68004C9.28003 7.3266 9.56659 7.04004 9.92003 7.04004C10.2735 7.04004 10.56 7.3266 10.56 7.68004V9.60004C10.56 9.95348 10.2735 10.24 9.92003 10.24Z"
        fill="#0D1321"
      />
      <path
        d="M9.92003 14.72C9.56659 14.72 9.28003 14.4335 9.28003 14.08V12.16C9.28003 11.8066 9.56659 11.52 9.92003 11.52C10.2735 11.52 10.56 11.8066 10.56 12.16V14.08C10.56 14.4335 10.2735 14.72 9.92003 14.72Z"
        fill="#0D1321"
      />
      <path
        d="M8.64008 11.52H6.72008C6.36664 11.52 6.08008 11.2334 6.08008 10.88C6.08008 10.5265 6.36664 10.24 6.72008 10.24H8.64008C8.99352 10.24 9.28008 10.5265 9.28008 10.88C9.28008 11.2334 8.99352 11.52 8.64008 11.52Z"
        fill="#0D1321"
      />
      <path
        d="M13.1201 11.52H11.2001C10.8466 11.52 10.5601 11.2334 10.5601 10.88C10.5601 10.5265 10.8466 10.24 11.2001 10.24H13.1201C13.4735 10.24 13.7601 10.5265 13.7601 10.88C13.7601 11.2334 13.4735 11.52 13.1201 11.52Z"
        fill="#0D1321"
      />
      <path
        d="M11.8402 22.4C12.1937 22.4 12.4802 22.1135 12.4802 21.76C12.4802 21.4065 12.1937 21.12 11.8402 21.12C11.4867 21.12 11.2002 21.4065 11.2002 21.76C11.2002 22.1135 11.4867 22.4 11.8402 22.4Z"
        fill="#0D1321"
      />
      <path
        d="M41.2801 19.2C41.6336 19.2 41.9201 18.9135 41.9201 18.56C41.9201 18.2066 41.6336 17.92 41.2801 17.92C40.9267 17.92 40.6401 18.2066 40.6401 18.56C40.6401 18.9135 40.9267 19.2 41.2801 19.2Z"
        fill="#0D1321"
      />
      <path
        d="M37.1201 13.4401C35.8849 13.4401 34.8801 12.4353 34.8801 11.2001C34.8801 9.96488 35.8849 8.96008 37.1201 8.96008C38.3553 8.96008 39.3601 9.96488 39.3601 11.2001C39.3601 12.4353 38.3553 13.4401 37.1201 13.4401ZM37.1201 10.2401C36.5907 10.2401 36.1601 10.6706 36.1601 11.2001C36.1601 11.7295 36.5907 12.1601 37.1201 12.1601C37.6496 12.1601 38.0801 11.7295 38.0801 11.2001C38.0801 10.6706 37.6496 10.2401 37.1201 10.2401Z"
        fill="#0D1321"
      />
      <path
        d="M10.24 34.56C9.0048 34.56 8 33.5552 8 32.32C8 31.0848 9.0048 30.08 10.24 30.08C11.4752 30.08 12.48 31.0848 12.48 32.32C12.48 33.5552 11.4752 34.56 10.24 34.56ZM10.24 31.36C9.71056 31.36 9.28 31.7906 9.28 32.32C9.28 32.8494 9.71056 33.28 10.24 33.28C10.7694 33.28 11.2 32.8494 11.2 32.32C11.2 31.7906 10.7694 31.36 10.24 31.36Z"
        fill="#0D1321"
      />
      <path
        d="M19.5176 15.2872H21.3624C23.526 15.2872 25.28 17.068 25.28 19.2647V26.3053H16.96V17.8839C16.96 16.45 18.1051 15.2872 19.5176 15.2872Z"
        fill="#D8A600"
      />
      <path
        d="M25.2801 26.9454H16.9601C16.6066 26.9454 16.3201 26.6589 16.3201 26.3054V17.884C16.3201 16.0992 17.7546 14.6473 19.5177 14.6473H21.3625C23.8754 14.6473 25.9199 16.7187 25.9199 19.2648V26.3054C25.9201 26.6589 25.6335 26.9454 25.2801 26.9454ZM17.6001 25.6654H24.6401V19.2648C24.6401 17.4245 23.1698 15.9273 21.3626 15.9273H19.5178C18.4605 15.9273 17.6002 16.8051 17.6002 17.884V25.6654H17.6001Z"
        fill="#0D1321"
      />
      <path
        d="M21.7234 20.2753L23.5609 22.4897C24.2653 23.3388 25.2783 23.8732 26.3769 23.9754L32.1442 24.5121C32.5764 24.5522 32.9292 24.1719 32.8567 23.7441C32.8164 23.5062 32.6497 23.3092 32.4217 23.2302L26.5655 21.2006L24.33 18.2188C23.6692 17.3375 22.3493 17.3319 21.6812 18.2076C21.2121 18.8225 21.2295 19.6799 21.7234 20.2753Z"
        fill="#F3E0D4"
      />
      <path
        d="M32.2084 25.1547C32.1674 25.1547 32.1263 25.1528 32.085 25.149L26.3178 24.6123C25.0412 24.4936 23.8871 23.8848 23.0684 22.8981L21.2311 20.6838C20.5426 19.8541 20.5187 18.6761 21.1727 17.8192C21.6117 17.2437 22.2778 16.9141 23.0013 16.9141H23.0114C23.7388 16.9171 24.4061 17.2526 24.8421 17.8347L26.9602 20.6597L32.6312 22.6253C33.0802 22.7808 33.4082 23.1685 33.4877 23.637C33.5559 24.0386 33.436 24.4402 33.1589 24.7387C32.9101 25.0067 32.569 25.1547 32.2084 25.1547ZM22.216 19.8665L24.0533 22.0808C24.6538 22.8043 25.4999 23.2509 26.4362 23.3379L32.2036 23.8746C32.2085 23.875 32.2136 23.8755 32.2205 23.868C32.2274 23.8606 32.2266 23.8554 32.2258 23.8507C32.2244 23.8432 32.2192 23.8371 32.2122 23.8346L26.356 21.8048C26.2354 21.763 26.1301 21.6861 26.0535 21.584L23.8178 18.6022C23.6242 18.344 23.3282 18.1952 23.0056 18.1939C22.6768 18.1973 22.3856 18.3389 22.1898 18.5953C21.9 18.9758 21.9106 19.4984 22.216 19.8665Z"
        fill="#0D1321"
      />
      <path
        d="M18.2648 26.4398L18.7756 33.1797C18.8591 34.2819 18.7312 35.3848 18.3997 36.4393C17.6168 38.9294 16.1528 42.88 16.1528 42.88H22.08L19.52 40.32C20.8 38.4 22.0292 36.1515 22.0292 34.495V30.6312L18.2648 26.4398Z"
        fill="#F7EED0"
      />
      <path
        d="M22.0801 43.52H16.1527C15.9431 43.52 15.7471 43.4174 15.6274 43.2456C15.5078 43.0737 15.4798 42.8541 15.5526 42.6576C15.5673 42.6182 17.0203 38.6933 17.7891 36.2475C18.0977 35.2662 18.2148 34.2504 18.1375 33.2278L17.6268 26.488C17.606 26.2147 17.7615 25.9587 18.0135 25.8512C18.2657 25.7437 18.5579 25.8082 18.7411 26.012L22.5054 30.2034C22.611 30.3208 22.6692 30.4731 22.6692 30.631V34.4949C22.6692 35.927 21.9067 37.8083 20.3409 40.2353L22.5326 42.4272C22.7158 42.6102 22.7703 42.8856 22.6713 43.1246C22.5723 43.3637 22.339 43.52 22.0801 43.52ZM17.0691 42.24H20.535L19.0676 40.7726C18.8518 40.5568 18.8183 40.2189 18.9876 39.9651C20.9724 36.9877 21.3892 35.3472 21.3892 34.4952V30.8765L19.0452 28.2664L19.4139 33.1314C19.5038 34.3168 19.3679 35.4944 19.0103 36.6313C18.4535 38.4024 17.5462 40.9294 17.0691 42.24Z"
        fill="#0D1321"
      />
      <path d="M19.0908 10.24H22.0799V15.2872H19.0908V10.24Z" fill="#F3E0D4" />
      <path
        d="M22.0801 15.9272H19.0909C18.7375 15.9272 18.4509 15.6406 18.4509 15.2872V10.24C18.4509 9.88654 18.7375 9.59998 19.0909 9.59998H22.0801C22.4335 9.59998 22.7201 9.88654 22.7201 10.24V15.2872C22.7201 15.6406 22.4335 15.9272 22.0801 15.9272ZM19.7309 14.6472H21.4401V10.88H19.7309V14.6472Z"
        fill="#0D1321"
      />
      <path
        d="M21.0326 12.48H19.7641C18.9224 12.48 18.24 11.7976 18.24 10.9558V8.96C18.24 6.8392 19.9592 5.12 22.08 5.12C24.2008 5.12 25.92 6.8392 25.92 8.96V14.72H24.6533C23.6712 14.72 22.7333 14.311 22.0649 13.5915L21.0326 12.48Z"
        fill="#F3E0D4"
      />
      <path
        d="M25.9199 15.36H24.6531C23.4968 15.36 22.3827 14.8741 21.596 14.027L20.7535 13.12H19.764C18.5707 13.12 17.5999 12.1491 17.5999 10.9558V8.95998C17.5999 6.48974 19.6096 4.47998 22.0799 4.47998C24.5501 4.47998 26.5599 6.48974 26.5599 8.95998V14.72C26.5599 15.0734 26.2733 15.36 25.9199 15.36ZM22.0799 5.75998C20.3154 5.75998 18.8799 7.1955 18.8799 8.95998V10.9558C18.8799 11.4433 19.2765 11.84 19.764 11.84H21.0325C21.2104 11.84 21.3803 11.9141 21.5015 12.0445L22.5338 13.156C23.0792 13.7432 23.8515 14.08 24.6531 14.08H25.2799V8.95998C25.2799 7.1955 23.8443 5.75998 22.0799 5.75998Z"
        fill="#0D1321"
      />
      <path
        d="M16.96 26.3054C16.96 28.5425 18.1788 30.6176 20.175 31.7785L21.5833 32.5976C22.5592 33.1651 23.155 34.1795 23.155 35.2731V42.88H29.4691L26.2526 39.9974L26.8528 34.4121C26.9681 33.339 26.8988 32.2547 26.648 31.2038L25.4782 26.3054H16.96Z"
        fill="#F7EED0"
      />
      <path
        d="M29.4692 43.52H23.1551C22.8017 43.52 22.5151 43.2335 22.5151 42.88V35.2733C22.5151 34.4138 22.0348 33.6007 21.2617 33.1509L19.8535 32.3319C17.674 31.0642 16.3201 28.7551 16.3201 26.3055C16.3201 25.952 16.6066 25.6655 16.9601 25.6655H25.4783C25.7746 25.6655 26.0321 25.8688 26.1009 26.1568L27.2706 31.0552C27.5391 32.1797 27.6127 33.332 27.4893 34.4805L26.9242 39.7399L29.8964 42.4034C30.0938 42.5803 30.1617 42.861 30.0669 43.1087C29.9721 43.3565 29.7345 43.52 29.4692 43.52ZM23.7951 42.24H27.7961L25.8257 40.4741C25.6724 40.3367 25.5946 40.1338 25.6164 39.9291L26.2165 34.3437C26.3244 33.3408 26.2601 32.3344 26.0257 31.3525L24.9733 26.9455H17.6383C17.8473 28.6971 18.9026 30.2981 20.497 31.2255L21.9052 32.0445C23.0709 32.7224 23.7951 33.9597 23.7951 35.2733V42.24Z"
        fill="#0D1321"
      />
      <path
        d="M39.5624 42.6539C39.2849 42.6539 39.0294 42.4721 38.9481 42.1921L34.4617 26.7431C34.3203 26.2558 33.9974 25.8526 33.5526 25.6081C33.1078 25.3636 32.595 25.3068 32.1072 25.4482C31.6198 25.5898 31.2168 25.9126 30.9721 26.3574C30.7276 26.8022 30.6708 27.3155 30.8123 27.8028L31.3632 29.6999C31.4617 30.0395 31.2665 30.3945 30.927 30.4931C30.588 30.5916 30.2326 30.3964 30.1339 30.0569L29.5832 28.1601C29.3464 27.3443 29.4412 26.485 29.8507 25.7407C30.26 24.9964 30.9345 24.4561 31.7504 24.2193C32.5662 23.9822 33.4252 24.0774 34.1694 24.4866C34.9137 24.8959 35.454 25.5706 35.691 26.3863L40.1774 41.8355C40.2758 42.1748 40.0808 42.5299 39.7411 42.6286C39.6816 42.6457 39.6214 42.6539 39.5624 42.6539Z"
        fill="#0D1321"
      />
    </svg>
  );
};

export default Cane;
