import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from './DistanceFilter.style';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ChevronTop from '../../Icons/arrow/ChevronTop';
import ChevronBottom from '../../Icons/arrow/ChevronBottom';
import Typography from '../../Typography/Typography';
import Radio from '../../Radio/Radio';
import TextInput from '../../TextInput/TextInput';
import Divider from 'common/Divider/Divider';
import Button from 'common/Button/Button';

const DISTANCE_MIN_VALUE = 3;

const DistanceFilter = ({
  labels,
  onChange,
  disabled,
  className,
  selectedValue,
}) => {
  const classes = useStyles();

  const radioOptions = [
    { label: labels.ANY_RANGE, value: '0' },
    { label: `10 ${labels.MILES}`, value: '10' },
    { label: `15 ${labels.MILES}`, value: '15' },
    { label: `20 ${labels.MILES}`, value: '20' },
  ];
  const otherDistanceOption = { label: `${labels.OTHER}:`, value: '-1' };

  const [otherDistance, setOtherDistance] = useState('');

  const getSelectedValue = () => {
    if (!selectedValue) return radioOptions[3];
    let newValue = radioOptions.filter(
      (item) => item.value === selectedValue,
    )[0];
    if (!newValue) {
      setOtherDistance(selectedValue);
      return otherDistanceOption;
    }
    return newValue;
  };

  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [distance, setDistance] = useState(getSelectedValue);
  const [inputError, setInputError] = useState('');
  const [previousDistance, setPreviousDistance] = useState(getSelectedValue);

  const selectClasses = clsx([
    className,
    classes.distance_search_container,
    disabled && classes.disabled,
    !!inputError && classes.error,
    distance.value !== '0' && classes.filled,
  ]);

  useEffect(() => {
    const newDistance = getSelectedValue();
    setDistance(newDistance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        setDistance(previousDistance);
        setIsOpen(false);
        if (Number(previousDistance.value) > 3) {
          setInputError('');
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, previousDistance, isOpen]);

  const handleOtherDistanceChange = (value) => {
    const number = Number(value) || 0;
    if (number < DISTANCE_MIN_VALUE) {
      setInputError(
        labels.DISTANCE_MIN_VALUE.replace('{MIN_VALUE}', DISTANCE_MIN_VALUE),
      );
    } else {
      setInputError('');
    }
    setOtherDistance(number.toString());
  };

  const handleOtherDistanceOption = (value) => {
    handleOtherDistanceChange(value);
    setDistance(value);
  };

  const handleDistanceChange = (value) => {
    setDistance(value);
    setInputError('');
    if (Number(otherDistance) < DISTANCE_MIN_VALUE) {
      setOtherDistance('');
    }
  };

  useEffect(() => {
    if (!isOpen) {
      if (distance.value === '-1') {
        if (Number(otherDistance) >= DISTANCE_MIN_VALUE) {
          onChange(otherDistance);
        }
      } else {
        onChange(distance.value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distance, otherDistance, isOpen]);

  const getSelectedText = () => {
    if (distance.value === '-1') {
      return `${otherDistance || 0} ${labels.MILES}`;
    }
    return distance.label;
  };

  const handleApply = () => {
    if (distance !== null) {
      setPreviousDistance(distance);
      setDistance(distance);
      setIsOpen(false);
    } else if (otherDistance !== null) {
      setPreviousDistance(otherDistance);
      setDistance(otherDistance);
      setIsOpen(false);
    }
  };

  const handleClose = () => {
    setDistance(previousDistance);
    setIsOpen(false);
  };

  return (
    <div className={selectClasses} ref={wrapperRef}>
      <div className={classes.label}>{labels.DISTANCE}</div>
      <div
        data-testid="distance-filter"
        className={classes.select}
        tabIndex="0"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>{getSelectedText()}</div>
        <div className={classes.icon}>
          {isOpen ? <ChevronTop /> : <ChevronBottom />}
        </div>
      </div>
      {isOpen && !disabled && (
        <div className={classes.menu}>
          <div className={classes.items_container}>
            <Typography
              className={classes.group_name}
              level="small"
              color="eerieBlack3"
            >
              {labels.DISTANCE_RANGE_WITHIN}
            </Typography>
            <div>
              <Radio
                className={clsx(classes.radio_container)}
                items={radioOptions}
                isRow={false}
                name="distance_filter"
                selected={distance}
                onChange={handleDistanceChange}
              />
              <div className={classes.other_distance_container}>
                <Radio
                  className={classes.radio_container}
                  items={[otherDistanceOption]}
                  isRow={false}
                  name="distance_filter"
                  selected={distance}
                  onChange={handleOtherDistanceOption}
                />
                <TextInput
                  textHint={inputError}
                  error={!!inputError}
                  placeholder={labels.MILES}
                  onFocus={() => setDistance(otherDistanceOption)}
                  type="number"
                  max={500}
                  value={otherDistance}
                  onChange={handleOtherDistanceChange}
                  className={classes.other_distance_input}
                />
              </div>
            </div>
            <Divider className={classes.divider} color="platinum1" />
            <div className={classes.actions_container}>
              <div className={clsx(classes.actions)}>
                <Button size="small" type="light" onClick={handleClose}>
                  {labels.CLOSE}
                </Button>
                <Button type="secondary" size="small" onClick={handleApply}>
                  {labels.APPLY}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

DistanceFilter.propTypes = {
  labels: PropTypes.object,
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  selectedValue: PropTypes.string,
};

DistanceFilter.defaultProps = {
  disabled: false,
  className: '',
  onChange: () => {},
};

export default DistanceFilter;
