import Mail from 'common/Icons/basic/Mail';
import CommentChecked from 'common/Icons/chatting/CommentChecked';
import Headphone from 'common/Icons/music/Headphone';
import CallPhone from 'common/Icons/call/CallPhone';
import React from 'react';
import {
  isCallAction,
  isEmailAction,
  isOutReachAction,
  isTextAction,
} from './actionTypeService';
import CallVoicemail from 'common/Icons/call/CallVoicemail';
import CallRecieved from 'common/Icons/call/CallRecieved';
import CallMissed from 'common/Icons/call/CallMissed';

const getLastContactIconClass = (action, classes) => {
  const lastContactIconClass = {
    'No Contact': classes.red_icon,
    'Left VM': classes.red_icon,
    Conversation: classes.green_icon,
  };
  if (isCallAction(action.type)) {
    if (action.type === 'Call - Property') {
      return lastContactIconClass.Conversation;
    }
    return lastContactIconClass[action.result];
  }
  if (isEmailAction(action.type)) {
    return classes.green_icon;
  }
  return classes.green_icon;
};

const getLastCallIcon = (lastCall, classes) => {
  const className = getLastContactIconClass(lastCall, classes);
  const lastCallIcon = {
    'No Contact': <CallMissed className={className} />,
    'Left VM': <CallVoicemail className={className} />,
    Conversation: <CallRecieved className={className} />,
  };
  if (lastCall.type === 'Call - Property') {
    return lastCallIcon.Conversation;
  }
  return lastCallIcon[lastCall.result];
};

const getIcon = (action, classes) => {
  const className = getLastContactIconClass(action, classes);
  if (isCallAction(action.type)) {
    return getLastCallIcon(action, classes);
  }
  if (isEmailAction(action.type)) {
    return <Mail className={className} />;
  }
  if (isTextAction(action.type)) {
    return <CommentChecked className={className} />;
  }
  if (isOutReachAction(action.type)) {
    return <Headphone className={className} />;
  }
  return <CallPhone className={className} />;
};

export { getIcon };
