import React from 'react';
import {
  inquiryFields,
  globalFields,
  assignSlaFields,
} from 'services/callCenter/fieldsMapper';

const CustomToolbar = () => (
  <div id="toolbar">
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <select className="ql-size" defaultValue="">
      <option value="10px">Small</option>
      <option>Normal</option>
      <option value="18px">Large</option>
    </select>
    <select className="ql-color" defaultValue="">
      <option value="blue"></option>
      <option value="#999"></option>
      <option></option>
    </select>
    <select className="ql-inquiryVar">
      {Object.entries(inquiryFields).map(([name, value]) => {
        return (
          <option key={name} value={`{{${name}}}`}>
            {value.description}
          </option>
        );
      })}
    </select>
    <select className="ql-globalVar" data-label="Global">
      {Object.entries(globalFields).map(([name, value]) => {
        return (
          <option key={name} value={`{{${name}}}`}>
            {value.description}
          </option>
        );
      })}
    </select>
    <select className="ql-assignSlaVar" data-label="Assigned SLA">
      {Object.entries(assignSlaFields).map(([name, value]) => {
        return (
          <option key={name} value={`{{${name}}}`}>
            {value.description}
          </option>
        );
      })}
    </select>
  </div>
);
export default CustomToolbar;
