import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  saved_community_container: {
    display: 'flex',
    padding: '0 6px',
    marginRight: '8px',
    height: 'fit-content',
    cursor: 'pointer',
    flexGrow: 0,
    '& svg': {
      fontSize: '24px',
    },
  },
  remove: {
    color: theme.palette.correction.smokyTopaz2,
  },
  not_saved_hover: {
    '& path': {
      fill: theme.palette.neutral.cosmicCobalt1,
    },
  },
  loading_spinner: {
    display: 'flex',
    padding: '0 6px',
    marginRight: '8px',
    height: 'fit-content',
    pointerEvents: 'none',
    flexGrow: 0,
    '& svg': {
      fontSize: '24px',
    },
  },
}));

export { useStyles };
