import gql from 'graphql-tag';

let Community = {};

Community.fragments = {
  careTypes: gql`
    fragment CommunityCareTypes on Community {
      careTypes {
        adultDayServices
        assistedLiving
        homeCare
        independentLiving
        memoryCare
        notSure
        nursingHome
        residentialCareHome
        respiteCare
        seniorApartments
      }
    }
  `,
};

export { Community };
