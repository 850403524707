import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { useStyles } from './ActionableTooltip.style';

const ActionableTooltip = ({ children, title, placement }) => {
  const classes = useStyles();

  return (
    <Tooltip
      classes={classes}
      title={title}
      placement={placement}
      interactive
      enterTouchDelay={50}
      leaveTouchDelay={2500}
    >
      {/* Tooltip component has restrictions to the keys/ classes so can't pass in custom class. Hence the style  */}
      <div
        style={{
          display: 'flex',
          marginRight: '0px',
          alignSelf: 'center',
          cursor: 'pointer',
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

ActionableTooltip.propTypes = {
  title: PropTypes.node.isRequired,
  placement: PropTypes.oneOf([
    'bottom',
    'bottom-start',
    'top',
    'left',
    'right',
  ]),
  children: PropTypes.node.isRequired,
};

export default ActionableTooltip;
