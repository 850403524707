import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  last_contacted_container: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:first-child': {
      display: 'flex',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '10px',
      fontSize: '16px',
    },
  },
  green_icon: {
    color: theme.palette.positive.wintergreenDream3,
    fill: theme.palette.positive.wintergreenDream3,
  },
  red_icon: {
    color: theme.palette.correction.smokyTopaz2,
    fill: theme.palette.correction.smokyTopaz2,
  },
  date: {
    marginLeft: '26px',
  },
}));

export { useStyles };
