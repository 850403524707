const findScriptByTargetQuery = `
  query findScriptByTarget($target: String!) {
    findScriptByTarget(target: $target) {
      scriptId
      slug
      name
      description
      target
      type
      version
      versionName
      steps {
        stepId
        number
        order
        name
        size
        text
        type
        isHidden
        isLegal
        isOptional
        isPipeda
        alternateText
        property
        answers {
          answerId
          order
          label
          value
          nextStep
          closeInquiry
          addToDoNotCall
          shows
          hides
          selectedRule
          action
          closeReason
          disposition
          urlLink
          poolType
        }
      }
    }
  }
`;

export default findScriptByTargetQuery;
