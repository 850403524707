import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import clsx from 'clsx';
import { useStyles } from './ReferredCommunitiesRow.style';
import Checkbox from '../../../../common/Checkbox/Checkbox';
import AlertCircle from '../../../../common/Icons/notifications/AlertCircle';
import Typography from '../../../../common/Typography/Typography';
import CommunityCareTypes from '../../../../pages/Communities/CommunityCareTypes/CommunityCareTypes';
import { formatTourActivityDate } from '../../../../common/utils/dateFormat';

import {
  getDate,
  getDateTime,
  getHourAndMinutes12HourFormat,
  getTimeOffsetFromSelectedDate,
} from 'utils/dateFormat';
import {
  getCommunityActions,
  getCommunityActionIcons,
} from './referredCommunitiesService';
import RoomChargesModal from 'pages/Communities/CommunityPriceRange/RoomChargesModal';
import {
  getRoomCharges,
  getRoomFees,
  getCareTypesLabels,
} from 'pages/Communities/CommunitiesTable/communityService';
import { registerEvent } from 'services/Analytics';
import DropdownPopover from 'common/Dropdown/DropdownPopover/DropdownPopover';
import MarkTourCompletedModal from './MarkTourCompletedModal';
import ReferredCommunityStatus from '../ReferredCommunityStatus/ReferredCommunityStatus';
import Alert from 'common/Alert/Alert';
import CalendarPlus from 'common/Icons/time/CalendarPlus';
import Button from 'common/Button/Button';
import ScheduledTourInfo from 'components/SavedCommunities/ScheduledTourInfo';
import ReferredCommunityLinks from '../ReferredCommunityLinks/ReferredCommunityLinks';
import ReferredCommunityInfo from '../ReferredCommunityInfo/ReferredCommunityInfo';
import ScheduleTourActivityTable from './ScheduleTourActivityTable/ScheduleTourActivityTable';
import tourScheduleService, {
  ACTIVITY_TYPE_CODE,
  logRemoveTourTimeSelected,
  TOUR_STATUS,
} from 'services/tourScheduleService';
import { isCtAppEnabled } from 'config/ctAppCommunities';
import useGraphQLMutation from 'hooks/useGraphQLMutation';
import scheduleTourMutation from 'pages/Communities/CommunityInfo/Mutations/scheduleTourMutation';
import updateScheduleTourMutation from 'pages/Communities/CommunityInfo//Mutations/updateScheduleTourMutation';
import deleteTourMutation from 'pages/Communities/CommunityInfo/Mutations/deleteTourMutation';
import withConfig from 'utils/withConfig';
import { getTourStatusLabel } from 'services/referredCommunitiesService';
import { TOUR_STATUS_TEXT } from 'services/tourScheduleService';
import CircleMoreHorizontal from 'common/Icons/basic/CircleMoreHorizontal';
import ReferralCommunityActions from './ReferralCommunityActions';
import FamilyFileLetters from '../../FamilyFileLetters/FamilyFileLetters';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import TourScheduler, {
  referredSchedulerConfiguration,
  referredUpdateSchedulerConfiguration,
} from 'components/Community/TourScheduler/TourScheduler';

const ReferralCommunitiesRow = ({
  community,
  dataForAnalytics,
  familyFileId,
  familyFileUserId,
  flags,
  handleAfterScheduledTourModal,
  labels,
  onChange,
  onTourCompletion,
  dialerService,
  familyFilePrimaryContact,
  externalId,
}) => {
  const imageUrl = withConfig('IMAGE_URL');

  const emptyTourDetail = {
    scheduledTourDate: null,
    tourType: '',
    familyCommunityTourId: null,
    communityId: parseInt(community.id),
    tourActivities: [],
    currentStatus: null,
  };

  const displayScheduleTourButtonForHomeCare = () => {
    return community.careTypes?.homeCare !== undefined;
  };

  const defaultMarkTourCompletedInformation = {
    selectedWhenCompleted: { value: 'SAME' },
  };
  const [isRoomChargesModalOpen, setIsRoomChargesModalOpen] = useState(false);
  const [
    isMarkTourCompletedModalOpen,
    setIsMarkTourCompletedModalOpen,
  ] = useState(false);

  const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false);
  const [
    markTourCompletedInformation,
    setMarkTourCompletedInformation,
  ] = useState(defaultMarkTourCompletedInformation);
  const [isScheduledTourModalOpen, setIsScheduledTourModalOpen] = useState(
    false,
  );
  const [extendedTourDetails, setExtendedTourDetails] = useState(
    community.tourDetails || emptyTourDetail,
  );
  const [tourDraft, setTourDraft] = useState(community.tourDraft);
  const [familyCommunityTourId, setFamilyCommunityTourId] = useState();
  const [selectedTourLabel, setSelectedTourLabel] = useState('');
  const [tourInfo, setTourInfo] = useState();
  const [isTourCanceled, setIsTourCanceled] = useState(
    community.tourDetails?.currentStatus === TOUR_STATUS.CANCELED,
  );
  const [isTourCompleted, setIsTourCompleted] = useState(
    community.tourDetails?.currentStatus === TOUR_STATUS.COMPLETED,
  );

  const [tourStatusLabel, setTourStatusLabel] = useState(
    getTourStatusLabel(extendedTourDetails.currentStatus, labels),
  );
  const [
    isCommunityAndTourDetailsOpen,
    setIsCommunityAndTourDetailsOpen,
  ] = useState(false);

  const [scheduleTour] = useGraphQLMutation(scheduleTourMutation);
  const [updateScheduleTour] = useGraphQLMutation(updateScheduleTourMutation);
  const [deleteScheduledTour] = useGraphQLMutation(deleteTourMutation);

  const allowToScheduleATour = () => {
    if (community.careTypes?.homeCare) {
      return false;
    }
    return (
      (displayScheduleTourButtonForHomeCare() &&
        community.communityStatus === labels.REFERRAL_ALERT_SENT &&
        !community.isRejected &&
        community.tourDetails?.currentStatus !== TOUR_STATUS.SCHEDULED &&
        community.tourDetails?.currentStatus !== TOUR_STATUS.RESCHEDULED) ||
      (displayScheduleTourButtonForHomeCare() && isTourCanceled) ||
      (displayScheduleTourButtonForHomeCare() && isTourCompleted) ||
      (displayScheduleTourButtonForHomeCare() && !community.isRejected)
    );
  };

  const validationForRejectedAndNotClosed = () => {
    return community.isRejected && community.communityStatus !== labels.CLOSED;
  };

  const validateDisable = () => {
    return (
      community.isRejected ||
      community.communityStatus === labels.CLOSED ||
      community.disabled
    );
  };

  const [isAllowedToScheduleATour, setIsAllowedToScheduleATour] = useState(
    allowToScheduleATour(),
  );

  const [isDisable, setIsDisable] = useState(validateDisable());

  const [isRejected, setIsRejected] = useState(
    validationForRejectedAndNotClosed(),
  );

  const classes = useStyles({
    isRowBottomMarginSmall:
      isAllowedToScheduleATour || isCommunityAndTourDetailsOpen,
  });

  const openMarkTourCompletedModal = () => {
    setIsMarkTourCompletedModalOpen(true);
  };

  const [communityActionIcons, setCommunityActionIcons] = useState(
    getCommunityActionIcons(
      familyFileId,
      community,
      classes,
      labels,
      flags,
      openMarkTourCompletedModal,
      false,
    ),
  );

  const [communityActions, setCommunityActions] = useState(
    getCommunityActions(
      familyFileId,
      community,
      labels,
      classes,
      community.tourDetails?.currentStatus !== TOUR_STATUS.COMPLETED &&
        community.tourDetails?.currentStatus !== TOUR_STATUS.CANCELED,
    ),
  );

  useEffect(() => {
    if (tourInfo) {
      setSelectedTourLabel(
        isTourCanceled || isTourCompleted
          ? ''
          : tourScheduleService.formatTourScheduleLabel(tourInfo),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourInfo]);

  useEffect(() => {
    setIsAllowedToScheduleATour(allowToScheduleATour());
    setIsRejected(validationForRejectedAndNotClosed());
    setIsDisable(validateDisable());

    if (community.tourDetails) {
      setTourInfo(tourScheduleService.toTourInfo(community.tourDetails));
      setFamilyCommunityTourId(community.tourDetails.familyCommunityTourId);
      setExtendedTourDetails(community.tourDetails || emptyTourDetail);
      setIsTourCanceled(
        community.tourDetails?.currentStatus === TOUR_STATUS.CANCELED,
      );
      setIsTourCompleted(
        community.tourDetails?.currentStatus === TOUR_STATUS.COMPLETED,
      );
      setCommunityActionIcons(
        getCommunityActionIcons(
          familyFileId,
          community,
          classes,
          labels,
          flags,
          openMarkTourCompletedModal,
          false,
        ),
      );
      setCommunityActions(
        getCommunityActions(
          familyFileId,
          community,
          labels,
          classes,
          community.tourDetails?.currentStatus !== TOUR_STATUS.COMPLETED &&
            community.tourDetails?.currentStatus !== TOUR_STATUS.CANCELED,
        ),
      );
    }
    if (community) {
      setTourDraft(community.tourDraft);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community]);

  useEffect(
    () => {
      setTourStatusLabel(
        getTourStatusLabel(extendedTourDetails.currentStatus, labels),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [extendedTourDetails],
  );

  const careTypeName = {
    A: labels.ASSISTED_LIVING,
    Z: labels.MEMORY_CARE,
    N: labels.NURSING_HOME,
    H: labels.HOME_CARE,
    I: labels.INDEPENDENT_LIVING,
    M: labels.RESPITE_CARE,
    D: labels.ADULT_DAY_SERVICES,
    O: labels.NOT_SURE,
    G: labels.RESIDENTIAL_CARE_HOME,
    P: labels.SENIOR_APARTMENTS,
    R: labels.RETIREMENT_COMMUNITY,
  };

  const handleOnCloseEdit = () => {
    setIsMarkTourCompletedModalOpen(false);
    setMarkTourCompletedInformation(defaultMarkTourCompletedInformation);
  };

  const handleTourCompletion = (showDetailsView) => {
    registerEvent(
      'TourManagement',
      'Mark tour as completed from referred communities section',
    );

    const onTourCompletetionData = {
      communityId: community.id,
      showDetailsView,
    };
    onTourCompletion(onTourCompletetionData);
  };

  const handleSuccessClose = () => {
    setIsSuccessAlertOpen(false);
  };

  const onSave = () => {
    setIsMarkTourCompletedModalOpen(false);
    setIsSuccessAlertOpen(true);
    handleTourCompletion(true);
  };

  const openScheduleTourModal = () => {
    setIsScheduledTourModalOpen(true);
  };

  /**
   * This is only performed for Tours v2.
   */
  const handleTourSchedulerChange = () => {
    // TODO: This is not just for Tour completion - it triggers reload of communities when any Tour details change.
    onTourCompletion();
  };

  const currentUser = JSON.parse(localStorage.getItem('CURRENT_USER'));

  /**
   * This is only performed for Tours v1.
   */
  const handleScheduleTourSave = ({ activityDate, selectedType }) => {
    let scheduleTourPromise;
    const timeOffset = getTimeOffsetFromSelectedDate(activityDate);

    const notes = community?.referralNote?.text;

    const referral = {
      communityId: community.id,
      communityName: community.name,
      notes: notes || '',
      desiredPostalCode: community.zip,
      tourType: selectedType,
      tourDateTime: getDateTime(activityDate),
      isTourScheduled: true,
    };

    if (familyCommunityTourId) {
      scheduleTourPromise = updateScheduleTour({
        variables: {
          familyCommunityTourId,
          activityDate,
          tourType: selectedType,
          activityTypeCode:
            isTourCompleted || isTourCanceled
              ? ACTIVITY_TYPE_CODE.SCHEDULED
              : ACTIVITY_TYPE_CODE.RESCHEDULED,
          timeOffset,
          referral,
          userName: currentUser.username,
          userRole: currentUser.role,
        },
      });
    } else {
      scheduleTourPromise = scheduleTour({
        variables: {
          familyFileId,
          communityId: community.id,
          activityDate,
          activityTypeCode: ACTIVITY_TYPE_CODE.SCHEDULED,
          tourType: selectedType,
          timeOffset,
          referral,
          userName: currentUser.username,
          userRole: currentUser.role,
        },
      });
    }

    scheduleTourPromise
      .then(async (response) => {
        let tourScheduled;
        if (response.data.scheduleTour) {
          tourScheduled = response.data.scheduleTour;
        } else {
          tourScheduled = response.data.updateScheduledTour;
        }

        extendedTourDetails.tourActivities.push(tourScheduled.latestActivity);

        extendedTourDetails.scheduledTourDate = tourScheduled.activityDate;
        extendedTourDetails.tourType = tourScheduled.tourType;
        extendedTourDetails.familyCommunityTourId =
          tourScheduled.familyCommunityTourId;
        extendedTourDetails.communityId = tourScheduled.communityId;
        extendedTourDetails.currentStatus =
          TOUR_STATUS_TEXT[tourScheduled.currentStatus];

        if (familyCommunityTourId && isTourCanceled) {
          setTourStatusLabel(getTourStatusLabel('Scheduled Tour', labels));
        } else if (familyCommunityTourId && isTourCompleted) {
          setTourStatusLabel(getTourStatusLabel('Scheduled Tour', labels));
        } else if (familyCommunityTourId) {
          setTourStatusLabel(getTourStatusLabel('Rescheduled', labels));
        } else {
          setTourStatusLabel(getTourStatusLabel('Scheduled Tour', labels));
        }
        setExtendedTourDetails(extendedTourDetails);
        setIsTourCanceled(false);
        setIsTourCompleted(false);
        setCommunityActionIcons(
          getCommunityActionIcons(
            familyFileId,
            community,
            classes,
            labels,
            flags,
            openMarkTourCompletedModal,
            true,
          ),
        );
        setCommunityActions(
          getCommunityActions(familyFileId, community, labels, classes, true),
        );
        setIsAllowedToScheduleATour(false);

        const selectedDate = formatTourActivityDate(
          tourScheduled.activityDate,
          'MMM DD, YYYY (DDD)',
        );
        const selectedTime = getHourAndMinutes12HourFormat(
          tourScheduled.activityDate,
          true,
        );
        const selectedType = tourScheduled.tourType;

        setFamilyCommunityTourId(tourScheduled.familyCommunityTourId);
        setTourInfo({
          selectedDate,
          selectedTime,
          selectedType,
        });
        community.tourDetails = extendedTourDetails;
      })
      .catch((error) => {
        console.error(
          `There was an error scheduling tour for familyFileId ${familyFileId}`,
          error,
        );
      });
    setIsScheduledTourModalOpen(false);
    community.tourDetails = extendedTourDetails;
    handleAfterScheduledTourModal(community);
  };

  const removeScheduledTour = () => {
    createRemoveTourTimeSelectedAnalyticsEvents();

    const deleteScheduledTourPromise = deleteScheduledTour({
      variables: {
        familyCommunityTourId: parseInt(familyCommunityTourId),
      },
    });

    deleteScheduledTourPromise
      .then(() => {
        setSelectedTourLabel();
        setFamilyCommunityTourId();
        setExtendedTourDetails(emptyTourDetail);
      })
      .catch((error) => {
        console.error(
          `There was an error removing scheduled tour for familyFileId ${familyFileId}`,
          error,
        );
      });
  };

  const getTourAnalyticsDetails = () => {
    const selectedTourTypeText = tourScheduleService.getTourTypeLabel(
      extendedTourDetails.tourType,
    );
    const selectedTime = getHourAndMinutes12HourFormat(
      extendedTourDetails.scheduledTourDate,
      true,
    );
    const tourDateTime = `${getDate(
      extendedTourDetails.scheduledTourDate,
    )} ${selectedTime}`;

    return [selectedTourTypeText, tourDateTime];
  };

  const createRemoveTourTimeSelectedAnalyticsEvents = () => {
    const [selectedTourTypeText, tourDateTime] = getTourAnalyticsDetails();

    logRemoveTourTimeSelected(
      community.id,
      familyFileId,
      dataForAnalytics.originatingPage,
      tourDateTime,
      selectedTourTypeText,
      familyFileUserId,
    );
  };

  const displayTourStatus = extendedTourDetails.scheduledTourDate && (
    <Typography level="small">
      <b>{tourStatusLabel}:</b>{' '}
      {formatTourActivityDate(
        extendedTourDetails.scheduledTourDate,
        'MMM DD, YYYY',
      )}
    </Typography>
  );

  const actionsDropDownToolTipLabel =
    extendedTourDetails.scheduledTourDate &&
    extendedTourDetails.currentStatus !== TOUR_STATUS.COMPLETED &&
    extendedTourDetails.currentStatus !== TOUR_STATUS.CANCELED
      ? labels.MORE_ACTIONS
      : labels.NO_MORE_ACTIONS;

  const getTourSchedulerConfiguration = () => {
    if (tourDraft) {
      if (extendedTourDetails?.familyCommunityTourId) {
        return referredUpdateSchedulerConfiguration;
      }
      return referredSchedulerConfiguration;
    }
    if (extendedTourDetails?.familyCommunityTourId) {
      if (
        extendedTourDetails.currentStatus === TOUR_STATUS.CANCELED ||
        extendedTourDetails.currentStatus === TOUR_STATUS.COMPLETED
      ) {
        return referredSchedulerConfiguration;
      }
      return referredUpdateSchedulerConfiguration;
    }
    return referredSchedulerConfiguration;
  };

  const ScheduleTourContainer = () => {
    return (
      <div className={classes.scheduled_tour_button_container}>
        {selectedTourLabel && (
          <Typography level="small" className={classes.selected_tour_time}>
            {labels.SELECTED_TOUR_TIME}
          </Typography>
        )}
        <div className={classes.button_container}>
          <Button
            type="outlined"
            onClick={openScheduleTourModal}
            startIcon={<CalendarPlus />}
            size="small"
            className={classes.selected_tour_button}
          >
            <Typography level="small">
              {selectedTourLabel ? selectedTourLabel : labels.SCHEDULE_TOUR}
            </Typography>
          </Button>
          {selectedTourLabel && (
            <div
              onClick={removeScheduledTour}
              className={classes.remove_tour_info_container}
            >
              <Typography underlined color="smokyTopaz2" level="small">
                {labels.REMOVE_TOUR_TIME}
              </Typography>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      data-id={community.id}
      className={classes.referred_communities_row_container}
    >
      <div className={classes.referred_communities_row}>
        <div className={classes.community_info_container}>
          <div className={classes.community_basic_info_container}>
            {isDisable ? (
              <SmartTooltip
                title={
                  isRejected
                    ? labels.COMMUNITY_REJECTED_REFERALL
                    : labels.CLOSED_COMMUNITY_TOOLTIP_TEXT
                }
                cursor="default"
                className={classes.checkbox}
              >
                <Checkbox
                  checked={community.isChecked}
                  className={classes.checkbox}
                  onChange={onChange}
                  disabled={isDisable}
                />
              </SmartTooltip>
            ) : (
              <Checkbox
                checked={community.isChecked}
                className={classes.checkbox}
                onChange={onChange}
                disabled={isDisable}
              />
            )}
            <div className={classes.info_and_schedule_tour_container}>
              <div className={classes.community_name_container}>
                <Typography bold className={classes.community_name}>
                  {community.name}
                </Typography>
              </div>
              <div>
                <Typography>{community.organizationName}</Typography>
              </div>
              <div className={classes.community_types_and_details}>
                <CommunityCareTypes
                  careTypeNames={careTypeName}
                  careTypeLabels={getCareTypesLabels(community)}
                />
                <div className={classes.community_details}>
                  <ReferredCommunityLinks
                    careTypeName={careTypeName}
                    community={community}
                    isShowMoreClicked={isCommunityAndTourDetailsOpen}
                    toggleShowMoreClick={() =>
                      setIsCommunityAndTourDetailsOpen(
                        !isCommunityAndTourDetailsOpen,
                      )
                    }
                    labels={labels}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.status_container}>
          <ReferredCommunityStatus status={community.communityStatus} />
          {isRejected && (
            <Typography
              level="small"
              color="smokyTopaz2"
              className={classes.referral_rejected}
            >
              <AlertCircle className={classes.alert_circle} />
              {labels.REFERRAL_REJECTED}
            </Typography>
          )}
        </div>
        <div className={classes.family_letter_sent}>
          <Typography level="small">
            <b>{labels.REFERRAL}: </b>
            {community.isReferralStarted && !community.referralDate
              ? labels.PENDING
              : formatTourActivityDate(community.referralDate, 'MMM DD, YYYY')}
          </Typography>
          <FamilyFileLetters
            familyFileId={familyFileId}
            communityId={community.id}
            labels={labels}
          />
          {displayTourStatus}
        </div>
        <div className={classes.actions_container}>
          <RoomChargesModal
            careTypeNames={careTypeName}
            careTypes={community.careTypes}
            careTypeStyle="tag"
            communityName={community.name}
            isModalOpen={isRoomChargesModalOpen}
            labels={labels}
            onClose={() => setIsRoomChargesModalOpen(false)}
            roomCharges={getRoomCharges(community.roomCharges)}
            roomFees={getRoomFees(community.roomFees)}
          />
          <ReferralCommunityActions actions={communityActionIcons} />

          {!flags.isTourWorkflowV2 && (
            <div className={classes.action_button}>
              <DropdownPopover
                items={communityActions}
                clickableElement={<CircleMoreHorizontal />}
                toolTipContent={actionsDropDownToolTipLabel}
                toolTipPlacement="top"
              />
            </div>
          )}
        </div>
        <Alert
          type="success"
          title={labels.SUCCESS}
          description={labels.MARK_TOUR_COMPLETED_SUCCESS}
          confirmText={labels.OK}
          onConfirm={handleSuccessClose}
          onClose={handleSuccessClose}
          isOpen={isSuccessAlertOpen}
        />
        {isMarkTourCompletedModalOpen && (
          <MarkTourCompletedModal
            community={community}
            familyFileId={familyFileId}
            familyFileUserId={familyFileUserId}
            isModalOpen={isMarkTourCompletedModalOpen}
            labels={labels}
            markTourCompletedInfo={markTourCompletedInformation}
            onClose={handleOnCloseEdit}
            onOpen={openMarkTourCompletedModal}
            onSave={onSave}
            tourDetails={extendedTourDetails}
          />
        )}
      </div>

      {flags.isTourWorkflowV2 ? (
        <>
          {!isDisable && (
            <div className={classes.referred_communities_row}>
              <div className={classes.scheduled_tour_button_container}>
                <TourScheduler
                  community={community}
                  configuration={getTourSchedulerConfiguration()}
                  dataForAnalytics={{
                    ...dataForAnalytics,
                    familyFileId,
                    familyFileUserId,
                  }}
                  familyFileId={familyFileId}
                  isCtAppEnabled={isCtAppEnabled(community.id)}
                  isRemoveTourAllowed={false}
                  onChange={handleTourSchedulerChange}
                  tourDetail={extendedTourDetails}
                  tourDraft={tourDraft}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {isAllowedToScheduleATour && !isDisable && (
            <div className={classes.referred_communities_row}>
              {!community.disabled &&
                community.communityStatus !== TOUR_STATUS.SCHEDULED && (
                  <ScheduleTourContainer />
                )}
              {isScheduledTourModalOpen && (
                <ScheduledTourInfo
                  address={community.address}
                  city={community.city}
                  dataForAnalytics={{
                    careTypes: community.careTypes,
                  }}
                  familyFileId={familyFileId}
                  familyFileUserId={familyFileUserId}
                  isCtAppEnabled={isCtAppEnabled(community.id)}
                  isOpen={isScheduledTourModalOpen}
                  labels={labels}
                  name={community.name}
                  onClose={() => setIsScheduledTourModalOpen(false)}
                  onSave={handleScheduleTourSave}
                  phone={community.phone}
                  state={community.state}
                  tourDetails={
                    isTourCanceled || isTourCompleted
                      ? emptyTourDetail
                      : extendedTourDetails
                  }
                  zip={community.zip}
                />
              )}
            </div>
          )}
        </>
      )}

      {isCommunityAndTourDetailsOpen && (
        <>
          <div
            className={clsx(
              classes.referred_communities_row,
              classes.referred_communities_details,
            )}
          >
            <ReferredCommunityInfo
              labels={labels}
              community={community}
              imageUrl={imageUrl}
              flags={flags}
              familyFileId={familyFileId}
              careTypes={getCareTypesLabels(community)}
              dialerService={dialerService}
              currentUser={currentUser}
              familyFilePrimaryContact={familyFilePrimaryContact}
              externalId={externalId}
            />
          </div>
          <ScheduleTourActivityTable
            className={classes.schedule_tour_activity_table}
            community={community}
            familyFileId={familyFileId}
            familyFileUserId={familyFileUserId}
            labels={labels}
            tourDetails={community.tourDetails}
          />
        </>
      )}
    </div>
  );
};

ReferralCommunitiesRow.propTypes = {
  community: PropTypes.object.isRequired,
  dataForAnalytics: PropTypes.shape({
    originatingPage: PropTypes.string,
  }),
  familyFileId: PropTypes.number.isRequired,
  externalId: PropTypes.number,
  familyFileUserId: PropTypes.number,
  flags: PropTypes.shape({
    isTourWorkflowV2: PropTypes.bool,
  }),
  handleAfterScheduledTourModal: PropTypes.func,
  dialerService: PropTypes.object,
  familyFilePrimaryContact: PropTypes.object,

  labels: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onTourCompletion: PropTypes.func,
};

export default withLDConsumer()(ReferralCommunitiesRow);
