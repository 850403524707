/* eslint-disable */
/* istanbul ignore file */
/*
 Copyright 2011-2013 Abdulla Abdurakhmanov
 Original sources are available at https://code.google.com/p/x2js/

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

function X2JS(config) {
  'use strict';

  var VERSION = '1.1.2';

  config = config || {};
  initConfigDefaults();

  function initConfigDefaults() {
    if (config.escapeMode === undefined) config.escapeMode = true;
    if (config.attributePrefix === undefined) config.attributePrefix = '_';
    if (config.arrayAccessForm === undefined) config.arrayAccessForm = 'none';
    if (config.emptyNodeForm === undefined) config.emptyNodeForm = 'text';
  }

  var DOMNodeTypes = {
    ELEMENT_NODE: 1,
    TEXT_NODE: 3,
    CDATA_SECTION_NODE: 4,
    DOCUMENT_NODE: 9,
  };

  function getNodeLocalName(node) {
    var nodeLocalName = node.localName;
    if (nodeLocalName == null)
      // Yeah, this is IE!!
      nodeLocalName = node.baseName;
    if (nodeLocalName == null || nodeLocalName == '')
      // =="" is IE too
      nodeLocalName = node.nodeName;
    return nodeLocalName;
  }

  function getNodePrefix(node) {
    return node.prefix;
  }

  function escapeXmlChars(str) {
    if (typeof str == 'string')
      return str
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#x27;')
        .replace(/\//g, '&#x2F;');
    else return str;
  }

  function unescapeXmlChars(str) {
    return str
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&#x27;/g, "'")
      .replace(/&#x2F;/g, '/');
  }

  function toArrayAccessForm(obj, childName) {
    switch (config.arrayAccessForm) {
      case 'property':
        if (!(obj[childName] instanceof Array))
          obj[childName + '_asArray'] = [obj[childName]];
        else obj[childName + '_asArray'] = obj[childName];
        break;
      /*case "none":
			break;*/
    }
  }

  function parseDOMChildren(node) {
    if (node.nodeType == DOMNodeTypes.DOCUMENT_NODE) {
      var result = new Object();
      var child = node.firstChild;
      var childName = getNodeLocalName(child);
      result[childName] = parseDOMChildren(child);
      return result;
    } else if (node.nodeType == DOMNodeTypes.ELEMENT_NODE) {
      var result = new Object();
      result.__cnt = 0;

      var nodeChildren = node.childNodes;

      // Children nodes
      for (var cidx = 0; cidx < nodeChildren.length; cidx++) {
        var child = nodeChildren.item(cidx); // nodeChildren[cidx];
        var childName = getNodeLocalName(child);

        result.__cnt++;
        if (result[childName] == null) {
          result[childName] = parseDOMChildren(child);
          toArrayAccessForm(result, childName);
        } else {
          if (result[childName] != null) {
            if (!(result[childName] instanceof Array)) {
              result[childName] = [result[childName]];
              toArrayAccessForm(result, childName);
            }
          }
          var aridx = 0;
          while (result[childName][aridx] != null) aridx++;
          result[childName][aridx] = parseDOMChildren(child);
        }
      }

      // Attributes
      for (var aidx = 0; aidx < node.attributes.length; aidx++) {
        var attr = node.attributes.item(aidx); // [aidx];
        result.__cnt++;
        result[config.attributePrefix + attr.name] = attr.value;
      }

      // Node namespace prefix
      var nodePrefix = getNodePrefix(node);
      if (nodePrefix != null && nodePrefix != '') {
        result.__cnt++;
        result.__prefix = nodePrefix;
      }

      if (result['#text'] != null) {
        result.__text = result['#text'];
        if (result.__text instanceof Array) {
          result.__text = result.__text.join('\n');
        }
        if (config.escapeMode) result.__text = unescapeXmlChars(result.__text);
        delete result['#text'];
        if (config.arrayAccessForm == 'property')
          delete result['#text_asArray'];
      }
      if (result['#cdata-section'] != null) {
        result.__cdata = result['#cdata-section'];
        delete result['#cdata-section'];
        if (config.arrayAccessForm == 'property')
          delete result['#cdata-section_asArray'];
      }

      if (result.__cnt == 1 && result.__text != null) {
        result = result.__text;
      } else if (result.__cnt == 0 && config.emptyNodeForm == 'text') {
        result = '';
      }
      delete result.__cnt;

      if (result.__text != null || result.__cdata != null) {
        result.toString = function () {
          return (
            (this.__text != null ? this.__text : '') +
            (this.__cdata != null ? this.__cdata : '')
          );
        };
      }
      return result;
    } else if (
      node.nodeType == DOMNodeTypes.TEXT_NODE ||
      node.nodeType == DOMNodeTypes.CDATA_SECTION_NODE
    ) {
      return node.nodeValue;
    }
  }

  function startTag(jsonObj, element, attrList, closed) {
    var resultStr =
      '<' +
      (jsonObj != null && jsonObj.__prefix != null
        ? jsonObj.__prefix + ':'
        : '') +
      element;
    if (attrList != null) {
      for (var aidx = 0; aidx < attrList.length; aidx++) {
        var attrName = attrList[aidx];
        var attrVal = jsonObj[attrName];
        resultStr +=
          ' ' +
          attrName.substr(config.attributePrefix.length) +
          "='" +
          attrVal +
          "'";
      }
    }
    if (!closed) resultStr += '>';
    else resultStr += '/>';
    return resultStr;
  }

  function endTag(jsonObj, elementName) {
    return (
      '</' +
      (jsonObj.__prefix != null ? jsonObj.__prefix + ':' : '') +
      elementName +
      '>'
    );
  }

  function endsWith(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  }

  function jsonXmlSpecialElem(jsonObj, jsonObjField) {
    if (
      (config.arrayAccessForm == 'property' &&
        endsWith(jsonObjField.toString(), '_asArray')) ||
      jsonObjField.toString().indexOf(config.attributePrefix) == 0 ||
      jsonObjField.toString().indexOf('__') == 0 ||
      jsonObj[jsonObjField] instanceof Function
    )
      return true;
    else return false;
  }

  function jsonXmlElemCount(jsonObj) {
    var elementsCnt = 0;
    if (jsonObj instanceof Object) {
      for (var it in jsonObj) {
        if (jsonXmlSpecialElem(jsonObj, it)) continue;
        elementsCnt++;
      }
    }
    return elementsCnt;
  }

  function parseJSONAttributes(jsonObj) {
    var attrList = [];
    if (jsonObj instanceof Object) {
      for (var ait in jsonObj) {
        if (
          ait.toString().indexOf('__') == -1 &&
          ait.toString().indexOf(config.attributePrefix) == 0
        ) {
          attrList.push(ait);
        }
      }
    }
    return attrList;
  }

  function parseJSONTextAttrs(jsonTxtObj) {
    var result = '';

    if (jsonTxtObj.__cdata != null) {
      result += '<![CDATA[' + jsonTxtObj.__cdata + ']]>';
    }

    if (jsonTxtObj.__text != null) {
      if (config.escapeMode) result += escapeXmlChars(jsonTxtObj.__text);
      else result += jsonTxtObj.__text;
    }
    return result;
  }

  function parseJSONTextObject(jsonTxtObj) {
    var result = '';

    if (jsonTxtObj instanceof Object) {
      result += parseJSONTextAttrs(jsonTxtObj);
    } else if (jsonTxtObj != null) {
      if (config.escapeMode) result += escapeXmlChars(jsonTxtObj);
      else result += jsonTxtObj;
    }

    return result;
  }

  function parseJSONArray(jsonArrRoot, jsonArrObj, attrList) {
    var result = '';
    if (jsonArrRoot.length == 0) {
      result += startTag(jsonArrRoot, jsonArrObj, attrList, true);
    } else {
      for (var arIdx = 0; arIdx < jsonArrRoot.length; arIdx++) {
        result += startTag(
          jsonArrRoot[arIdx],
          jsonArrObj,
          parseJSONAttributes(jsonArrRoot[arIdx]),
          false,
        );
        result += parseJSONObject(jsonArrRoot[arIdx]);
        result += endTag(jsonArrRoot[arIdx], jsonArrObj);
      }
    }
    return result;
  }

  function parseJSONObject(jsonObj) {
    var result = '';

    var elementsCnt = jsonXmlElemCount(jsonObj);

    if (elementsCnt > 0) {
      for (var it in jsonObj) {
        if (jsonXmlSpecialElem(jsonObj, it)) continue;

        var subObj = jsonObj[it];

        var attrList = parseJSONAttributes(subObj);

        if (subObj == null || subObj == undefined) {
          result += startTag(subObj, it, attrList, true);
        } else if (subObj instanceof Object) {
          if (subObj instanceof Array) {
            result += parseJSONArray(subObj, it, attrList);
          } else {
            var subObjElementsCnt = jsonXmlElemCount(subObj);
            if (
              subObjElementsCnt > 0 ||
              subObj.__text != null ||
              subObj.__cdata != null
            ) {
              result += startTag(subObj, it, attrList, false);
              result += parseJSONObject(subObj);
              result += endTag(subObj, it);
            } else {
              result += startTag(subObj, it, attrList, true);
            }
          }
        } else {
          result += startTag(subObj, it, attrList, false);
          result += parseJSONTextObject(subObj);
          result += endTag(subObj, it);
        }
      }
    }
    result += parseJSONTextObject(jsonObj);

    return result;
  }

  this.parseXmlString = function (xmlDocStr) {
    if (xmlDocStr === undefined) {
      return null;
    }
    var xmlDoc;
    if (window.DOMParser) {
      var parser = new window.DOMParser();
      xmlDoc = parser.parseFromString(xmlDocStr, 'text/xml');
    } else {
      // IE :(
      if (xmlDocStr.indexOf('<?') == 0) {
        xmlDocStr = xmlDocStr.substr(xmlDocStr.indexOf('?>') + 2);
      }
      xmlDoc = new Object('Microsoft.XMLDOM');
      xmlDoc.async = 'false';
      xmlDoc.loadXML(xmlDocStr);
    }
    return xmlDoc;
  };

  this.asArray = function (prop) {
    if (prop instanceof Array) return prop;
    else return [prop];
  };

  this.xml2json = function (xmlDoc) {
    return parseDOMChildren(xmlDoc);
  };

  this.xml_str2json = function (xmlDocStr) {
    var xmlDoc = this.parseXmlString(xmlDocStr);
    return this.xml2json(xmlDoc);
  };

  this.json2xml_str = function (jsonObj) {
    return parseJSONObject(jsonObj);
  };

  this.json2xml = function (jsonObj) {
    var xmlDocStr = this.json2xml_str(jsonObj);
    return this.parseXmlString(xmlDocStr);
  };

  this.getVersion = function () {
    return VERSION;
  };
}

export default X2JS;
