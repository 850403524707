import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DotDefault from '../../common/Icons/maps/DotDefault';
import { useStyles } from './Marker.style';
import StartingPoint from '../../common/Icons/maps/StartingPoint';
import clsx from 'clsx';
import PinAdded from '../../common/Icons/maps/PinAdded';

const Marker = ({ type, $hover }) => {
  const classes = useStyles($hover);
  if (type === 'startingPoint') {
    return (
      <Fragment>
        <StartingPoint className={classes.starting_point} />
        <div className={classes.starting_point_shadow} />
      </Fragment>
    );
  }
  if (type === 'saved') {
    return (
      <Fragment>
        <PinAdded className={clsx(classes.default, classes.common)} />
        <div className={classes.shadow} />
      </Fragment>
    );
  }
  return (
    <Fragment>
      <DotDefault className={clsx(classes.default, classes.common)} />
      <div className={classes.shadow} />
    </Fragment>
  );
};

Marker.propTypes = {
  type: PropTypes.oneOf(['default', 'startingPoint', 'saved']),
  $hover: PropTypes.bool,
};

Marker.defaultProps = {
  type: 'default',
};

export default Marker;
