import React from 'react';
import PropTypes from 'prop-types';

const Mental = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2288 3.20312C9.74848 3.20312 9.28761 3.37114 8.92222 3.66789C8.5568 3.37111 8.09593 3.20312 7.61564 3.20312C6.47392 3.20312 5.54504 4.132 5.54504 5.27371C5.54504 5.78807 5.73497 6.28124 6.07957 6.66215L8.92219 9.80965L11.7645 6.66246C12.1094 6.28127 12.2994 5.78804 12.2994 5.27371C12.2994 4.132 11.3705 3.20312 10.2288 3.20312Z"
        fill="#0D1321"
      />
      <path
        d="M13.282 1.83126C12.0952 0.650329 10.5179 0 8.84066 0C7.17551 3.11714e-05 5.60578 0.644032 4.42058 1.81343C3.263 2.95555 2.60133 4.47073 2.55002 6.09098L0.962052 9.19366C0.824337 9.46276 0.833439 9.77903 0.98646 10.0397C1.13951 10.3004 1.41123 10.4625 1.71331 10.4734L2.50454 10.4948V12.5275C2.50454 13.4598 3.26303 14.2183 4.19534 14.2183H5.74982V16H12.3163V11.5427C13.1322 11.0012 13.816 10.2727 14.3038 9.42246C14.8529 8.46547 15.1403 7.3731 15.135 6.26339C15.1269 4.58625 14.4688 3.01222 13.282 1.83126ZM11.607 10.8853L11.3812 11.0217V15.0649H6.685V13.2832H4.19534C3.77867 13.2832 3.43968 12.9442 3.43968 12.5275V9.58446L1.83466 9.54122L3.48092 6.32465L3.48248 6.21551C3.5245 3.30392 5.92816 0.935173 8.84066 0.935142C11.7816 0.935111 14.1857 3.32739 14.1999 6.26791C14.2089 8.14459 13.2154 9.91388 11.607 10.8853Z"
        fill="#0D1321"
      />
    </svg>
  );
};

Mental.propTypes = {
  className: PropTypes.string,
};

export default Mental;
