const getTypeOfCares = (labels) => {
  return [
    {
      label: `${labels.MEMORY_CARE} (Z)`,
      value: 'memoryCare',
      selected: false,
      code: 'Z',
    },
    {
      label: `${labels.ASSISTED_LIVING} (A)`,
      value: 'assistedLiving',
      selected: false,
      code: 'A',
    },
    {
      label: `${labels.NURSING_HOME} (N)`,
      value: 'nursingHome',
      selected: false,
      code: 'N',
    },
    {
      label: `${labels.SENIOR_APARTMENTS} (P)`,
      value: 'seniorApartments',
      selected: false,
      code: 'P',
    },
    {
      label: `${labels.ADULT_DAY_SERVICES} (D)`,
      value: 'adultDayServices',
      selected: false,
      code: 'D',
    },
    {
      label: `${labels.HOME_CARE} (H)`,
      value: 'homeCare',
      selected: false,
      code: 'H',
    },
    {
      label: `${labels.RESIDENTIAL_CARE_HOME} (G)`,
      value: 'residentialCareHome',
      selected: false,
      code: 'G',
    },
    {
      label: `${labels.RETIREMENT_COMMUNITY} (R)`,
      value: 'independentLiving',
      selected: false,
      code: 'R',
    },
  ];
};

export default getTypeOfCares;
