import { makeStyles } from '../../common/Theme/Theme';
const notePanelWidth = 472;
const topBarHeight = 72;
const useStyles = makeStyles((theme) => ({
  send_referral_container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  top_bar: {
    height: topBarHeight,
    backgroundColor: theme.palette.neutral.cosmicCobalt1,
    width: '100%',
    boxShadow: '0px 0px 8px rgba(13, 19, 33, 0.5)',
    padding: '14px 0',
    display: 'flex',
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  back_container: {
    display: 'flex',
    position: 'absolute',
    left: '24px',
  },
  back_link: {
    display: 'flex',
    textDecoration: 'none',
    color: theme.palette.light.platinum3,
    '& svg': {
      verticalAlign: 'middle',
      fontSize: '24px',
      color: theme.palette.light.platinum3,
      marginRight: '16px',
    },
  },
  lg_main_container: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: theme.palette.light.platinum3,
    height: 'fit-content',
  },
  lg_body: {
    flexGrow: 1,
    backgroundColor: theme.palette.light.platinum3,
    padding: '24px',
    display: 'flex',
    marginRight: 0,
  },
  lg_body_shift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: notePanelWidth + 16,
  },
  lg_referral_body: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.palette.light.platinum5,
    boxShadow: '0px 0px 8px rgba(186, 186, 184, 0.3)',
    borderRadius: '4px',
    height: 'fit-content',
  },
  lg_referral_body_content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
  },
  lg_referral_body_footer: {
    display: 'flex',
    padding: '16px',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${theme.palette.light.platinum1}`,
    '& > *': {
      marginLeft: '24px',
    },
  },
  note: {
    display: 'flex',
    marginTop: '8px',
  },
  items: {
    marginTop: '4px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      color: theme.palette.positive.wintergreenDream2,
      verticalAlign: 'middle',
      marginRight: '10px',
    },
  },
  about_the_family: {
    marginTop: '15px',
  },
  toast: {
    marginTop: '8px',
    marginBottom: '16px',
    width: '75%',
    boxSizing: 'border-box',
  },
  referral_toast_message: {
    display: 'block',
  },
  general_text_input: {
    width: '75%',
    marginBottom: 40,
  },
  divider: {
    margin: 0,
  },
  clear: {
    cursor: 'pointer',
    padding: '8px 16px',
    width: 'fit-content',
  },
  error_toast: {
    marginTop: '8px',
  },
  link: {
    marginTop: '16px',
    display: 'block',
  },
  community_container: {
    paddingBottom: '24px',
    paddingTop: '24px',
    '&:hover': {
      backgroundColor: theme.palette.light.platinum4,
    },
  },
  disabled_button: {
    pointerEvents: 'none',
  },
  empty_body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '130px',
  },
  no_referrals_container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '130px',
  },
  referral_icon: {
    fontSize: '100px',
    marginBottom: theme.spacing(3),
  },
  no_referrals_text: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  community_list: {
    color: theme.palette.dark.eerieBlack5,
    listStyleType: 'none',
    marginBlockStart: '20px',
    marginBlockEnd: '28px',
    '& > li:before': {
      content: `'-'`,
      position: 'relative',
      left: '-6px',
    },
    paddingInlineStart: '8px',
  },
  buttons_container: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button:nth-child(2)': {
      marginLeft: '16px',
    },
  },
  buttons_container_referral: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export { useStyles };
