import { makeStyles } from 'common/Theme/Theme';

const useStyles = makeStyles((theme) => ({
  selected_tour_time: {
    marginBottom: theme.spacing(0.5),
  },
  tour_button_container: {
    display: 'flex',
    alignItems: 'center',
  },
  selected_tour_button: {
    color: theme.palette.dark.eerieBlack1,
  },
  remove_tour_info_container: {
    cursor: 'pointer',
  },
  remove_tour_time: {
    marginTop: theme.spacing(0.5),
  },
}));

export { useStyles };
