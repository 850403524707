import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './SearchResults.style';
import { withLabels } from '../../context/LabelContext';
import useQueryParams from '../../hooks/useQueryParams';
import Typography from '../../common/Typography/Typography';
import Dropdown from '../../common/Dropdown/Dropdown';
import SearchResultsTable from './SearchResultsTable/SearchResultsTable';
import useGraphQLQuery from '../../hooks/useGraphQLQuery';
import searchQuery from './SearchQuery';
import { useHistory } from 'react-router-dom';
import { formatCurrency } from '../../utils/currencyFormat';

const SearchResults = ({ labels }) => {
  const classes = useStyles();
  const history = useHistory();
  const filterType = useQueryParams('type');
  const filterValue = useQueryParams('value');
  const initialPage = useQueryParams('page');
  const initialPageSize = useQueryParams('pageSize');
  const paginationItems = [
    { label: '10', value: '10' },
    { label: '20', value: '20' },
    { label: '30', value: '30' },
  ];

  const getPaginationItem = (pageSize) => {
    return paginationItems.find((item) => item.value === pageSize);
  };

  const [elementsCount, setElementsCount] = useState(0);
  const [pageSize, setPageSize] = useState(
    getPaginationItem(initialPageSize) || paginationItems[0],
  );
  const [pageNumber, setPageNumber] = useState(Number(initialPage) || 1);
  const [pageCount, setPageCount] = useState(1);
  const [results, setResults] = useState([]);

  const getResultsTitle = () => {
    return elementsCount === 1 ? labels.THERE_IS : labels.THERE_ARE;
  };
  const getResultsTypeTitle = () => {
    switch (filterType) {
      case 'FAMILY_FILE_ID':
        return `${labels.FOR} ${labels.FAMILY_FILE_ID}:`;
      case 'RESIDENT_NAME':
        return `${labels.FOR} ${labels.RESIDENT_NAME}:`;
      case 'PRIMARY_CONTACT_NAME':
        return `${labels.FOR} ${labels.PRIMARY_CONTACT}:`;
      case 'EMAIL_ADDRESS':
        return `${labels.FOR} ${labels.EMAIL_ADDRESS}:`;
      case 'PHONE_NUMBER':
        return `${labels.FOR} ${labels.PHONE_NUMBER}:`;
      default:
        return 'FAMILY_FILE_ID';
    }
  };

  const { loading, error, data } = useGraphQLQuery(searchQuery, {
    variables: {
      filterValue,
      filterType,
      pageSize: Number(pageSize.value),
      pageNumber,
    },
  });

  useEffect(() => {
    if (data) {
      setElementsCount(data.search.pagination.elementsCount);
      setPageCount(data.search.pagination.pageCount);
      setResults(data.search.results);
      if (data.search.pagination.elementsCount === 1) {
        const familyFile = data.search.results[0];
        history.push(`/family-file/${familyFile.id}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    history.push(
      `/search?type=${filterType}&value=${filterValue}&page=${pageNumber}&pageSize=${pageSize.value}`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize]);

  if (error) return `Error! ${error.message}`;

  return (
    <div className={classes.search_results_container}>
      <div className={classes.header}>
        <div className={classes.title}>
          <Typography level="h3">{labels.SEARCH_RESULTS}</Typography>
          <div className={classes.results_title}>
            <Typography color="eerieBlack5">{getResultsTitle()}</Typography>
            &nbsp;
            <Typography color="eerieBlack1" bold>{`${formatCurrency(
              elementsCount,
            )} ${labels.RESULTS}`}</Typography>
            &nbsp;
            <Typography className={classes.lowercase}>
              {getResultsTypeTitle()}
            </Typography>
            &nbsp;
            <Typography color="eerieBlack1" bold>
              {filterValue}
            </Typography>
          </div>
        </div>
        {!!elementsCount && (
          <Dropdown
            selectedItem={pageSize}
            items={paginationItems}
            label={labels.SHOW_LIST}
            onChange={(e) => setPageSize(e)}
          />
        )}
      </div>
      <SearchResultsTable
        labels={labels}
        data={results}
        pageCount={pageCount}
        pageNumber={pageNumber}
        loading={loading}
        onPageChange={(page) => {
          setPageNumber(page);
        }}
      />
    </div>
  );
};

SearchResults.propTypes = {
  labels: PropTypes.object,
};

SearchResults.defaultProps = {};

export default withLabels(SearchResults);
