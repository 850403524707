import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { originalFormStepAnswerPropType } from '../../inquiryPropTypes';
import { useStyles } from './CheckboxGroup.style';
import Checkbox from 'common/Checkbox/Checkbox';

const CheckboxGroup = ({ options, selected, onManyChoiceChange }) => {
  const classes = useStyles();
  const [elements, setElements] = useState(options);
  const [shouldFill, setShouldFill] = useState(true);
  useEffect(() => {
    if (shouldFill && selected.userInputValues?.selectedOptions) {
      selected.userInputValues.selectedOptions.forEach((selectedCode) => {
        const selectedElement = elements.find(
          (element) => element.value === selectedCode,
        );
        if (selectedElement) {
          selectedElement.checked = true;
        }
      });
      setElements([...elements]);
      setShouldFill(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFill, options, selected]);

  const handleCheckboxChange = (e, item) => {
    item.checked = e.target.checked;
    setElements([...elements]);
    onManyChoiceChange(elements.filter((e) => e.checked).map((e) => e.value));
  };
  return (
    <div className={classes.checkbox_group}>
      {elements.map((option, key) => {
        return (
          <Checkbox
            key={key}
            label={option.label}
            checked={option.checked}
            onChange={(e) => handleCheckboxChange(e, option)}
          />
        );
      })}
    </div>
  );
};

CheckboxGroup.defaultProps = {
  disabled: false,
};

CheckboxGroup.propTypes = {
  options: PropTypes.arrayOf(originalFormStepAnswerPropType).isRequired,
  answer: PropTypes.object,
  selected: PropTypes.object,
  onManyChoiceChange: PropTypes.func,
};

export default CheckboxGroup;
