import React, { useState, useEffect } from 'react';
import { useStyles } from './EditCard.style';
import { useBackgroundColorStyles } from '../../style/backgroundColor';
import { colorReference } from '../../Theme/themes/default';
import labels from 'config/labels';
import Typography from '../../Typography/Typography';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PencilEdit from '../../Icons/basic/PencilEdit';

const EditCard = ({
  title,
  icon,
  children,
  className,
  headerColor,
  editButtonColor,
  clickCallback,
  type,
  allowEdit,
  flags,
}) => {
  const classes = useStyles();
  const [allowUpdateControls, setAllowUpdateControls] = useState(false);
  const backgroundClasses = useBackgroundColorStyles();
  let buttonClass = clsx(
    classes.edit_container,
    backgroundClasses[editButtonColor],
  );
  let containerHeaderClass = clsx(classes.head, backgroundClasses[headerColor]);

  const handleOnClick = () => {
    clickCallback();
  };

  useEffect(() => {
    if (allowEdit || flags?.updateContactsOwl) {
      setAllowUpdateControls(true);
    }
  }, [flags, allowEdit]);

  if (type && type === 'light') {
    buttonClass = clsx(
      classes.edit_container,
      backgroundClasses[headerColor],
      classes.with_left_border,
    );
  }

  if (allowUpdateControls) {
    containerHeaderClass = clsx(
      classes.head,
      backgroundClasses[headerColor],
      classes.head_flush,
    );
  }
  return (
    <div className={clsx(classes.container, className)}>
      <div className={classes.head_container}>
        <div className={containerHeaderClass}>
          {icon}
          <Typography color="inherit" level="small">
            <b>{title}</b>
          </Typography>
        </div>

        {allowUpdateControls ? (
          <div className={buttonClass} onClick={handleOnClick}>
            <PencilEdit />
            <Typography color="inherit" level="small">
              {labels.EDIT}
            </Typography>
          </div>
        ) : null}
      </div>
      <div className={classes.body}>{children}</div>
    </div>
  );
};

EditCard.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
  editButtonColor: PropTypes.string,
  clickCallback: PropTypes.func.isRequired,
  headerColor: PropTypes.oneOf(colorReference),
  type: PropTypes.oneOf(['light', 'dark']),
  allowEdit: PropTypes.bool,
  flags: PropTypes.shape({
    updateContactsOwl: PropTypes.bool,
  }),
};

EditCard.defaultProps = {
  icon: <div />,
  className: '',
  headerColor: 'platinum4',
  editButtonColor: 'platinum4',
  allowEdit: false,
  type: 'dark',
};

export default EditCard;
