import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './HeaderTitle.style';
import Typography from '../../common/Typography/Typography';
import clsx from 'clsx';

const HeaderTitle = ({ className, title, subtitle, titleLevel }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={clsx(classes.container, className)}>
        <Typography level={titleLevel}>{title}</Typography>
        {subtitle && (
          <div className={classes.subtitle}>
            <Typography level="body" color="eerieBlack5">
              {subtitle}
            </Typography>
          </div>
        )}
      </div>
    </Fragment>
  );
};

HeaderTitle.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleLevel: PropTypes.string,
  subtitle: PropTypes.string,
};

HeaderTitle.defaultProps = {
  className: '',
  titleLevel: 'h3',
};

export default HeaderTitle;
