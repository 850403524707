import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useGraphQLMutation from '../../hooks/useGraphQLMutation';
import { DELETE_STATE_NONE } from '../../common/PlacesInput/PlacesInput';
import Alert from '../../common/Alert/Alert';
import updateResidentAgeMutation from './Mutations/updateResidentAgeMutation';
import updateNamesMutation from './Mutations/updateNamesMutation';
import updateResidentMovingInformationMutation from './Mutations/updateResidentMovingInformationMutation';
import updateCareTypesMutation from './Mutations/updateCareTypesMutation';
import deleteFamilyDesiredLocationsMutation from './Mutations/deleteFamilyDesiredLocationsMutation';
import upsertFamilyDesiredLocationsMutation from './Mutations/upsertFamilyDesiredLocationsMutation';
import { registerEvent } from '../../services/Analytics';
import EditResidentInformation from '../../components/Resident/EditResidentInformation';
import { isResidentsFormChanged } from '../formService';

const ResidentUpdate = ({
  children,
  labels,
  basicInformation,
  handleRefetchState,
  editResidentInformationOpen,
  enableReferralAlertValidation,
}) => {
  const [isOpen, setIsOpen] = useState(editResidentInformationOpen);
  const [residentInformation, setResidentInformation] = useState({
    ...basicInformation,
  });

  const [isConfirmAlertOpen, setIsConfirmAlertOpen] = useState(false);
  const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false);
  const [shouldUpdateResident, setShouldUpdateResidentInfo] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [updateResidentAge] = useGraphQLMutation(updateResidentAgeMutation);
  const [updateNames] = useGraphQLMutation(updateNamesMutation);
  const [updateResidentMovingInformation] = useGraphQLMutation(
    updateResidentMovingInformationMutation,
  );
  const [updateResidentCareTypes] = useGraphQLMutation(updateCareTypesMutation);
  const [deleteFamilyDesiredLocations] = useGraphQLMutation(
    deleteFamilyDesiredLocationsMutation,
  );
  const [upsertFamilyDesiredLocations] = useGraphQLMutation(
    upsertFamilyDesiredLocationsMutation,
  );

  useEffect(() => {
    setIsOpen(editResidentInformationOpen);
  }, [editResidentInformationOpen]);

  useEffect(() => {
    const triggerUpdateResidentInfo = async () => {
      const updateResidentAgePromise = updateResidentAge({
        variables: {
          formDataId: residentInformation.questionsDataFormDataId,
          age: residentInformation.age.toString(),
          oneId: residentInformation.oneId,
          familyFileId: residentInformation.familyFileId,
        },
      });

      const updateNamesPromise = updateNames({
        variables: {
          formDataId: residentInformation.residentsProfileFormDataId,
          firstName: residentInformation.firstName,
          lastName: residentInformation.lastName,
          oneId: residentInformation.oneId,
          familyFileId: residentInformation.familyFileId,
        },
      });

      const updateResidentMovingInformationPromise = updateResidentMovingInformation(
        {
          variables: {
            formDataId: residentInformation.leadDataFormDataId,
            expectedMoveDate: residentInformation.expectedMove,
            likelihoodToMovePct: residentInformation.likeliHoodToMove,
            oneId: residentInformation.oneId,
            familyFileId: residentInformation.familyFileId,
          },
        },
      );

      const updateResidentCareTypesPromise = updateResidentCareTypes({
        variables: {
          residentId: residentInformation.residentId,
          careTypes: residentInformation.careTypes.map(
            (careType) => careType.code,
          ),
          oneId: residentInformation.oneId,
          familyFileId: residentInformation.familyFileId,
        },
      });

      const upsertFamilyDesiredLocationsPromise = upsertFamilyDesiredLocations({
        variables: {
          familyFileId: residentInformation.familyFileId,
          familyDesiredLocations: residentInformation.preferredLocations
            .filter((familyDesiredLocation) => {
              return familyDesiredLocation.deleteState === DELETE_STATE_NONE;
            })
            .map((familyDesiredLocation) => {
              return {
                familyDesiredLocationId:
                  familyDesiredLocation.familyDesiredLocationId < 0
                    ? -1
                    : familyDesiredLocation.familyDesiredLocationId,
                isPrimary: familyDesiredLocation.isPrimary,
                city: familyDesiredLocation.city,
                state: familyDesiredLocation.state,
                zip: familyDesiredLocation.zip,
              };
            }),
          oneId: residentInformation.oneId,
        },
      });

      const deleteFamilyDesiredLocationsPromise = deleteFamilyDesiredLocations({
        variables: {
          familyFileId: residentInformation.familyFileId,
          familyDesiredLocationZipCodes: residentInformation.preferredLocations
            .filter((familyDesiredLocation) => {
              return (
                familyDesiredLocation.familyDesiredLocationId > 0 &&
                familyDesiredLocation.deleteState !== DELETE_STATE_NONE
              );
            })
            .map((familyDesiredLocation) => familyDesiredLocation.zip),
          oneId: residentInformation.oneId,
        },
      });

      await Promise.all([
        updateResidentAgePromise.catch((error) => {
          console.error(`Error updating resident age ${error}`);
        }),
        updateNamesPromise.catch((error) => {
          console.error(
            `Error updating resident first name and last name ${error}`,
          );
        }),
        updateResidentMovingInformationPromise.catch((error) => {
          console.error(`Error updating resident move-in information ${error}`);
        }),
        updateResidentCareTypesPromise.catch((error) => {
          console.error(`Error updating careTypes ${error}`);
        }),
        upsertFamilyDesiredLocationsPromise.catch((error) => {
          console.error(`Error upserting familyDesiredLocations ${error}`);
        }),
        deleteFamilyDesiredLocationsPromise.catch((error) => {
          console.error(`Error deleting familyDesiredLocations ${error}`);
        }),
      ]).then((values) => {
        const errors = values.filter((value) => value === undefined);
        if (errors.length > 0) {
          setIsOpen(true);
          setShowErrorMessage(true);
        } else {
          setIsSuccessAlertOpen(true);
          setShowErrorMessage(false);
          setIsOpen(false);
        }
      });
    };
    if (shouldUpdateResident) {
      triggerUpdateResidentInfo();
    }
    // eslint-disable-next-line
  }, [shouldUpdateResident]);

  const handleSave = (editedResidentInformation) => {
    registerEvent('FamilyFiles', 'Edit resident info is saved');
    setShouldUpdateResidentInfo(true);
    setResidentInformation({ ...editedResidentInformation });
  };

  const handleOnCloseEdit = (editedResidentInformation) => {
    if (!isResidentsFormChanged(basicInformation, editedResidentInformation)) {
      handleDontSave();
      return;
    }

    setResidentInformation({ ...editedResidentInformation });
    setShowErrorMessage(false);
    setIsConfirmAlertOpen(true);
    setIsOpen(false);
  };

  const handleDontSave = () => {
    setIsConfirmAlertOpen(false);
    setShowErrorMessage(false);
    handleRefetchState(true);
    setResidentInformation({ ...basicInformation });
  };

  const handleBackToEditing = () => {
    setIsConfirmAlertOpen(false);
    setIsOpen(true);
  };

  const handleOnEditMode = (open) => {
    registerEvent('FamilyFiles', 'Edit resident info is opened');
    setIsOpen(open);
  };

  const handleSuccessClose = () => {
    handleRefetchState(true);
    setIsSuccessAlertOpen(false);
  };

  const withOnEdit = (Component) => {
    // Adding unknown props to a DOM element is not valid (& causes an 'Unknown Prop Warning')
    if (Component.type === 'div') {
      const ComponentWithoutOnEditMode = React.cloneElement(Component, {});
      return ComponentWithoutOnEditMode;
    }
    const ComponentWithOnEditMode = React.cloneElement(Component, {
      onEditMode: () => handleOnEditMode(),
    });
    return ComponentWithOnEditMode;
  };

  return (
    <Fragment>
      <Alert
        type="delete"
        title={labels.PLEASE_CONFIRM}
        description={labels.DONT_MAKE_ANY_CHANGES}
        cancelText={labels.BACK_TO_EDITING}
        confirmText={labels.YES_DONT_SAVE}
        onConfirm={handleDontSave}
        onClose={handleBackToEditing}
        isOpen={isConfirmAlertOpen}
      />
      <Alert
        type="success"
        title={labels.SUCCESS}
        description={labels.SUCCESSFULLY_UPDATED}
        confirmText={labels.OK}
        onConfirm={handleSuccessClose}
        onClose={handleSuccessClose}
        isOpen={isSuccessAlertOpen}
      />
      <EditResidentInformation
        isOpen={isOpen}
        onClose={handleOnCloseEdit}
        basicInformation={residentInformation}
        labels={labels}
        onSave={handleSave}
        size={'xs'}
        showErrorMessage={showErrorMessage}
        enableReferralAlertValidation={enableReferralAlertValidation}
      />
      {withOnEdit(children)}
    </Fragment>
  );
};

ResidentUpdate.propTypes = {
  labels: PropTypes.shape({
    PLEASE_CONFIRM: PropTypes.string.isRequired,
    DONT_MAKE_ANY_CHANGES: PropTypes.string.isRequired,
    BACK_TO_EDITING: PropTypes.string.isRequired,
    YES_DONT_SAVE: PropTypes.string.isRequired,
    SUCCESS: PropTypes.string.isRequired,
    SUCCESSFULLY_UPDATED: PropTypes.string.isRequired,
    OK: PropTypes.string.isRequired,
  }).isRequired,
  basicInformation: PropTypes.shape({
    familyFileId: PropTypes.number.isRequired,
    resident: PropTypes.string,
    salesPhase: PropTypes.string,
    likeliHoodToMove: PropTypes.number,
    location: PropTypes.string,
    expectedMove: PropTypes.any,
    age: PropTypes.number,
    careTypes: PropTypes.arrayOf(PropTypes.object),
    preferredLocations: PropTypes.arrayOf(PropTypes.object),
    doNotCall: PropTypes.bool.isRequired,
  }).isRequired,
  handleOnEditMode: PropTypes.func,
  handleRefetchState: PropTypes.func,
  editResidentInformationOpen: PropTypes.bool.isRequired,
  children: PropTypes.element,
  enableReferralAlertValidation: PropTypes.bool,
};

ResidentUpdate.defaultProps = {
  enableReferralAlertValidation: true,
  children: <Fragment></Fragment>,
};

export default ResidentUpdate;
