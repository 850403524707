import { makeStyles } from '../../Theme/Theme';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginTop: '3px',
    },
    items_container: {
      border: `1px solid ${theme.palette.neutral.cosmicCobalt5}`,
      textAlign: 'left',
      '& > div': {
        padding: '9px 16px',
        '&:hover': {
          backgroundColor: theme.palette.neutral.dropdown,
          color: theme.palette.dark.eerieBlack1,
        },
      },
      '& svg': {
        fontSize: '20px',
        marginRight: '6px',
      },
    },
    start_icon: {
      '& svg': {
        color: theme.palette.dark.eerieBlack1,
        fontSize: '25px',
      },
    },
    label_container: {
      display: 'flex',
      color: theme.palette.dark.eerieBlack1,
      alignItems: 'center',
      cursor: 'pointer',
      fontSize: '15px',
    },
    disabled_label_container: {
      pointerEvents: 'none',
      color: theme.palette.dark.disabled,
      '& > svg': {
        fill: theme.palette.dark.disabled,
      },
    },
  };
});

export { useStyles };
