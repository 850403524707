import { makeStyles } from '../../common/Theme/Theme';

const useStyles = makeStyles(() => ({
  empty_container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
  },
  no_content: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  no_content_graphic: {
    fontSize: '100px',
    marginBottom: '28px',
  },
  empty_text: {
    marginBottom: '13px',
  },
  save_community_button: {
    '& svg': {
      fontSize: '24px',
      verticalAlign: 'middle',
      marginRight: '1px',
    },
  },
  header_row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
}));

export { useStyles };
