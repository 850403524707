import React from 'react';
import PropTypes from 'prop-types';

const CheckMarks = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M14.6192 6L6.54588 14.1999L3.35443 10.95L1.97363 12.35L6.54588 17L16 7.40006L14.6192 6ZM10.6664 15.0943L12.5459 17L22 7.40006L20.6192 6L12.5459 14.1999L12.0462 13.6932L10.6664 15.0943Z"
      />
    </svg>
  );
};

CheckMarks.propTypes = {
  className: PropTypes.string,
};

export default CheckMarks;
