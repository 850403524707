import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './MoreFiltersGroup.style';
import Typography from '../../common/Typography/Typography';
import ChevronTop from '../../common/Icons/arrow/ChevronTop';
import ChevronBottom from '../../common/Icons/arrow/ChevronBottom';

const MoreFiltersGroup = ({ title, children, isExpanded, onCollapse }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(isExpanded);

  useEffect(() => {
    setIsOpen(isExpanded);
  }, [isExpanded]);

  return (
    <div className={classes.more_filters_container}>
      <div onClick={onCollapse} className={classes.title}>
        <Typography bold color="eerieBlack1" level="small">
          {title}
        </Typography>
        <div>{isOpen ? <ChevronTop /> : <ChevronBottom />}</div>
      </div>
      {isOpen && <div className={classes.filter_container}>{children}</div>}
    </div>
  );
};

MoreFiltersGroup.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onCollapse: PropTypes.func,
  onChange: PropTypes.func,
  isExpanded: PropTypes.bool,
};

MoreFiltersGroup.defaultProps = {
  onChange: () => {},
};

export default MoreFiltersGroup;
