import React, { useState, Fragment, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Card from 'common/Card/Card';
import EditCard from 'common/Card/EditCard/EditCard';
import { useStyles } from './MedicalBackgroundDesktop.style';
import Pulse from 'common/Icons/basic/Pulse';
import Memory from 'common/Graphics/disability/Memory';
import Injured from 'common/Graphics/disability/Injured';
import Walker from 'common/Graphics/disability/Walker';
import Cane from 'common/Graphics/disability/Cane';
import Wheelchair from 'common/Graphics/disability/Wheelchair';
import Independent from 'common/Graphics/disability/Independent';
import Bedridden from 'common/Graphics/disability/Bedridden';
import MedicalBackgroundColumn from './MedicalBackgroundColumn';
import SmartTooltip from 'common/SmartTooltip/SmartTooltip';
import MedicalBackgroundDesktopEdit from './MedicalBackgroundDesktopEdit';
import updateMedicalBackgroundMutation from './UpdateMedicalBackgroundMutation';
import useGraphQLMutation from 'hooks/useGraphQLMutation';
import MedicalBackgroundDefaultPage from './MedicalBackgroundDefaultPage';
import Typography from 'common/Typography/Typography';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { registerEvent } from 'services/Analytics';
import {
  inFamilyFilePage,
  inSendReferralPage,
} from 'services/familyFileService';
import { useLocation } from 'react-router-dom';
import Toast from 'common/Toast/Toast';
import ContainerItem from '../../../common/ContainerItem/ContainerItem';
import FamilyFileAnalyticsService from '../FamilyFileAnalyticsService';

const MedicalBackgroundDesktop = ({
  labels,
  medicalBackgroundData,
  flags,
  onCancelEdit,
  isSelfLead,
  enableReferralAlertValidation,
  onSave,
  allowEdit,
  showSidePanel,
}) => {
  const classes = useStyles();
  const location = useLocation();

  const gettingAroundIcons = {
    'Temporarily Immobile Due to Injury': <Injured />,
    Walker: <Walker />,
    Cane: <Cane />,
    Wheelchair: <Wheelchair />,
    Independent: <Independent />,
    'Bedridden, Unable to Bear Weight': <Bedridden />,
  };
  const [medicalBackgroundState, setMedicalBackgroundState] = useState(
    medicalBackgroundData,
  );
  const [updateMedicalBackground] = useGraphQLMutation(
    updateMedicalBackgroundMutation,
  );

  const draftFormData = useRef({ medicalBackground: medicalBackgroundState });
  const originalFormData = useRef(draftFormData.current.medicalBackground);

  const setMedicalBackgroundFormState = useCallback((medicalBackground) => {
    draftFormData.current = { ...draftFormData.current, medicalBackground };
  }, []);

  const haveMedicalData = (medicalData) => {
    const currentSituation =
      medicalData.currentSituation === undefined ||
      medicalData.currentSituation === null;
    const rehabSkilledNursingRequired =
      medicalData.rehabSkilledNursingRequired === undefined ||
      medicalData.rehabSkilledNursingRequired === null;
    const diabeticCare =
      medicalData.diabetic === undefined || medicalData.diabetic === null;
    const memoryCondition =
      medicalData.memoryLoss === undefined || medicalData.memoryLoss === null;
    const gettingAround =
      medicalData.getAround === undefined || medicalData.getAround === null;
    const bathing =
      medicalData.bathing === undefined || medicalData.bathing === null;
    const toileting =
      medicalData.toileting === undefined || medicalData.toileting === null;
    const medicaid =
      medicalData.medicaid === undefined || medicalData.medicaid === null;

    return (
      currentSituation ||
      rehabSkilledNursingRequired ||
      diabeticCare ||
      memoryCondition ||
      gettingAround ||
      bathing ||
      toileting ||
      medicaid
    );
  };

  const [
    showMedicalBackgroundDefaultPage,
    setShowMedicalBackgroundDefaultPage,
  ] = useState(
    !haveMedicalData(medicalBackgroundData) && flags.updateContactsOwl,
  );

  const [showSuccessOrErrorField, setShowSuccessOrFailureField] = useState(
    false,
  );
  const [showSuccessMessage, setShowSuccessMessage] = useState(true);

  const [editMedicalBackground, setEditMedicalBackground] = useState(false);
  const saveMedicalBackground = () => {
    registerEvent('FamilyFiles', 'Edit medical info is saved');

    const familyFileAnalyticsService = new FamilyFileAnalyticsService();

    familyFileAnalyticsService.submitFamilyFileUpdateAnalytics({
      familyFileId: medicalBackgroundData.familyFileId,
      section: 'family file',
      screenName: 'family file',
      submitButtonIdentifier: 'Save',
      initialMedicalBackgroundFormModel: originalFormData.current,
      updatedMedicalBackgroundFormModel:
        draftFormData.current.medicalBackground,
    });

    const medicalBackgroundDataForSaving =
      draftFormData.current.medicalBackground;

    const currentLivingSituation = {
      variables: {
        formDataId: medicalBackgroundDataForSaving.formDataId,
        currentLiving: medicalBackgroundDataForSaving.currentSituation,
        locationName: medicalBackgroundDataForSaving.locationName,
        rehab: medicalBackgroundDataForSaving.rehabSkilledNursingRequired,
        medications: medicalBackgroundDataForSaving.currentMedication,
        memoryLoss: medicalBackgroundDataForSaving.memoryLoss,
        memoryDiagnosis: medicalBackgroundDataForSaving.memoryDiagnosis,
        wandering: medicalBackgroundDataForSaving.otherIssues,
        toileting: _parseToYesOrNo(
          medicalBackgroundDataForSaving.helpInToileting,
        ),
        getAround: medicalBackgroundDataForSaving.gettingAroundDescription,
        bathing: _parseToYesOrNo(medicalBackgroundDataForSaving.helpInShower),
        diabetic: medicalBackgroundDataForSaving.diabeticCare,
        healthIssues: medicalBackgroundDataForSaving.healthIssues,
        anythingElse: medicalBackgroundDataForSaving.anythingElse,
        oneId: medicalBackgroundData.oneId,
        familyFileId: medicalBackgroundData.familyFileId,
      },
    };
    updateMedicalBackground(currentLivingSituation)
      .then(() => {
        setMedicalBackgroundState(medicalBackgroundDataForSaving);
        setShowSuccessOrFailureField(true);
        setShowSuccessMessage(true);
        setEditMedicalBackground(false);
        originalFormData.current = medicalBackgroundDataForSaving;
        onSave();
      })
      .catch((exception) => {
        console.error(exception.message);
        setShowSuccessOrFailureField(true);
        setShowSuccessMessage(false);
      });
  };

  const cancelMedicalBackgroundEdit = () => {
    onCancelEdit();
    setEditMedicalBackground(false);
  };

  const _parseToYesOrNo = (text) => {
    return text && text === true ? 'Yes' : 'No';
  };

  const renderYesOrNo = (value) => (value ? labels.YES : labels.NO);

  const ShowSuccessOrErrorMessage = () => {
    if (showSuccessOrErrorField) {
      if (showSuccessMessage) {
        return (
          <div className={classes.show_message}>
            <Toast
              onClose={() => {
                setShowSuccessOrFailureField(false);
                setShowSuccessMessage(false);
              }}
            >
              <Typography>
                {labels.MEDICAL_BACKGROUND_SUCCESS_MESSAGE}
              </Typography>
            </Toast>
          </div>
        );
      } else {
        return (
          <div className={classes.show_message}>
            <Toast
              type="error"
              onClose={() => {
                setShowSuccessOrFailureField(false);
              }}
            >
              <Typography>{labels.OVERVIEW_ERROR_MESSAGE}</Typography>
            </Toast>
          </div>
        );
      }
    }
    return <div />;
  };

  if (showMedicalBackgroundDefaultPage) {
    return (
      <MedicalBackgroundDefaultPage
        labels={labels}
        toggleMedicalBackgroundDefaultPage={setShowMedicalBackgroundDefaultPage}
        toggleMedicalBackgroundEditPage={setEditMedicalBackground}
      />
    );
  }
  const renderMemoryText = (medicalBackgroundData) => {
    let text =
      medicalBackgroundData.memoryLoss.toLowerCase() === 'yes'
        ? labels.YES
        : labels.NO;

    if (medicalBackgroundData.memoryLoss.toLowerCase() === 'yes') {
      switch (medicalBackgroundData.memoryDiagnosis?.toLowerCase()) {
        case 'yes, dementia':
          return `${text}, ${labels.MEMORY_DIAGNOSIS_DEMENTIA}`;
        case "yes, alzheimer's":
          return `${text}, ${labels.MEMORY_DIAGNOSIS_ALZHEIMERS}`;
        case 'yes, other memory condition':
          return `${text}, ${labels.MEMORY_DIAGNOSIS_OTHER}`;
        case 'no':
          return `${text}, ${labels.MEMORY_DIAGNOSIS_BUT_NONE}`;
        case 'no, but special memory care needed':
          return `${text}, ${labels.MEMORY_DIAGNOSIS_SPECIAL}`;
        default:
          return `${text}, ${labels.MEMORY_DIAGNOSIS_BUT_NONE}`;
      }
    }
    return `${text}`;
  };

  const enableEditForReferralAlertValidation = inSendReferralPage(location);

  const shouldBeEditable = () => {
    return (
      allowEdit ||
      (flags.updateContactsOwl && inFamilyFilePage(location)) ||
      enableEditForReferralAlertValidation
    );
  };

  const startEdition = () => {
    registerEvent('FamilyFiles', 'Edit medical info is opened');
    setEditMedicalBackground(true);
    setShowSuccessOrFailureField(false);
  };

  const MedicalBackgroundInfo = () => {
    return (
      <Fragment>
        {!shouldBeEditable() && (
          <Fragment>
            <div className={classes.row}>
              <MedicalBackgroundColumn
                unknownLabel={labels.UNKNOWN}
                label={labels.CURRENT_LIVING_SITUATION}
                value={
                  (medicalBackgroundState.currentSituation || labels.UNKNOWN) +
                  (medicalBackgroundState.locationName
                    ? ' | ' + medicalBackgroundState.locationName
                    : '')
                }
              />
            </div>
            <div className={classes.row}>
              <MedicalBackgroundColumn
                unknownLabel={labels.UNKNOWN}
                label={labels.REHAB_SKILLED_NURSING_REQUIRED}
                value={medicalBackgroundState.rehabSkilledNursingRequired}
              />
            </div>
            <div className={classes.row}>
              <MedicalBackgroundColumn
                unknownLabel={labels.UNKNOWN}
                label={labels.CURRENT_MEDICATIONS}
                value={medicalBackgroundState.currentMedication}
              />
            </div>

            {medicalBackgroundState.memoryLoss && (
              <>
                <div className={classes.row}>
                  <MedicalBackgroundColumn
                    unknownLabel={labels.UNKNOWN}
                    label={labels.MEMORY_LOSS}
                    value={renderMemoryText(medicalBackgroundState)}
                    icon={<Memory />}
                  />
                </div>
                {medicalBackgroundState.memoryLoss.toLowerCase() === 'yes' && (
                  <div className={classes.row}>
                    <MedicalBackgroundColumn
                      unknownLabel={labels.UNKNOWN}
                      label={labels.OTHER_ISSUES}
                      value={medicalBackgroundState.otherIssues}
                    />
                  </div>
                )}
              </>
            )}
            <div className={classes.row}>
              <div className={classes.half_column}>
                <MedicalBackgroundColumn
                  unknownLabel={labels.UNKNOWN}
                  label={labels.GETTING_AROUND}
                  value={medicalBackgroundState.gettingAroundDescription}
                  icon={
                    gettingAroundIcons[
                      medicalBackgroundState.gettingAroundDescription
                    ]
                  }
                />
              </div>
              <div className={classes.half_column}>
                <div className={classes.half_column}>
                  <MedicalBackgroundColumn
                    unknownLabel={labels.UNKNOWN}
                    label={labels.HELP_IN_SHOWER}
                    value={renderYesOrNo(medicalBackgroundState.helpInShower)}
                  />
                </div>
                <div className={classes.half_column}>
                  <MedicalBackgroundColumn
                    unknownLabel={labels.UNKNOWN}
                    label={labels.HELP_IN_TOILETING}
                    value={renderYesOrNo(
                      medicalBackgroundState.helpInToileting,
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={classes.row_last_child}>
              <div className={classes.half_column}>
                <MedicalBackgroundColumn
                  unknownLabel={labels.UNKNOWN}
                  label={labels.DIABETIC_CARE}
                  value={medicalBackgroundState.diabeticCare}
                />
              </div>
            </div>
          </Fragment>
        )}
        {!flags.isInitialConsultationEnabled && shouldBeEditable() && (
          <Fragment>
            <ShowSuccessOrErrorMessage />
            <div className={classes.change_background}>
              {!editMedicalBackground && (
                <div onClick={startEdition}>
                  <div className={classes.row}>
                    <div className={classes.half_column}>
                      <SmartTooltip
                        title={
                          labels.CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND
                        }
                        placement="bottom"
                      >
                        <MedicalBackgroundColumn
                          unknownLabel={labels.UNKNOWN}
                          label={labels.CURRENT_LIVING_SITUATION}
                          value={
                            (medicalBackgroundState.currentSituation ||
                              labels.UNKNOWN) +
                            (medicalBackgroundState.locationName
                              ? ' | ' + medicalBackgroundState.locationName
                              : '')
                          }
                        />
                      </SmartTooltip>
                    </div>
                  </div>
                  <div className={classes.row}>
                    <SmartTooltip
                      title={labels.CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND}
                      placement="bottom"
                    >
                      <MedicalBackgroundColumn
                        unknownLabel={labels.UNKNOWN}
                        label={labels.REHAB_SKILLED_NURSING_REQUIRED}
                        value={
                          medicalBackgroundState.rehabSkilledNursingRequired
                        }
                      />
                    </SmartTooltip>
                  </div>

                  {enableReferralAlertValidation && (
                    <>
                      <div className={classes.row}>
                        <SmartTooltip
                          title={
                            labels.CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND
                          }
                          placement="bottom"
                        >
                          <MedicalBackgroundColumn
                            unknownLabel={labels.UNKNOWN}
                            label={labels.CURRENT_MEDICATIONS}
                            value={medicalBackgroundState.currentMedication}
                          />
                        </SmartTooltip>
                      </div>

                      {medicalBackgroundState.memoryLoss && (
                        <>
                          <div className={classes.row}>
                            <SmartTooltip
                              title={
                                labels.CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND
                              }
                              placement="bottom"
                            >
                              <MedicalBackgroundColumn
                                unknownLabel={labels.UNKNOWN}
                                label={labels.MEMORY_LOSS}
                                value={renderMemoryText(medicalBackgroundState)}
                                icon={<Memory />}
                              />
                            </SmartTooltip>
                          </div>
                          {medicalBackgroundState.memoryLoss.toLowerCase() ===
                            'yes' && (
                            <div className={classes.row}>
                              <SmartTooltip
                                title={
                                  labels.CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND
                                }
                                placement="bottom"
                              >
                                <MedicalBackgroundColumn
                                  unknownLabel={labels.UNKNOWN}
                                  label={labels.OTHER_ISSUES}
                                  value={medicalBackgroundState.otherIssues}
                                />
                              </SmartTooltip>
                            </div>
                          )}
                        </>
                      )}
                      <div className={classes.row}>
                        <SmartTooltip
                          title={
                            labels.CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND
                          }
                          placement="bottom"
                        >
                          <MedicalBackgroundColumn
                            unknownLabel={labels.UNKNOWN}
                            label={labels.GETTING_AROUND}
                            value={
                              medicalBackgroundState.gettingAroundDescription
                            }
                            icon={
                              gettingAroundIcons[
                                medicalBackgroundState.gettingAroundDescription
                              ]
                            }
                          />
                        </SmartTooltip>
                      </div>
                      <div className={classes.row}>
                        <div className={classes.half_column}>
                          <SmartTooltip
                            title={
                              labels.CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND
                            }
                            placement="bottom"
                          >
                            <MedicalBackgroundColumn
                              unknownLabel={labels.UNKNOWN}
                              label={labels.HELP_IN_SHOWER}
                              value={renderYesOrNo(
                                medicalBackgroundState.helpInShower,
                              )}
                            />
                          </SmartTooltip>
                        </div>
                        <div className={classes.half_column}>
                          <SmartTooltip
                            title={
                              labels.CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND
                            }
                            placement="bottom"
                          >
                            <MedicalBackgroundColumn
                              unknownLabel={labels.UNKNOWN}
                              label={labels.HELP_IN_TOILETING}
                              value={renderYesOrNo(
                                medicalBackgroundState.helpInToileting,
                              )}
                            />
                          </SmartTooltip>
                        </div>
                      </div>
                      <div className={classes.row_last_child}>
                        <SmartTooltip
                          title={
                            labels.CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND
                          }
                          placement="bottom"
                        >
                          <MedicalBackgroundColumn
                            unknownLabel={labels.UNKNOWN}
                            label={labels.DIABETIC_CARE}
                            value={medicalBackgroundState.diabeticCare}
                          />
                        </SmartTooltip>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </Fragment>
        )}
        {flags.isInitialConsultationEnabled && shouldBeEditable() && (
          <Fragment>
            <ShowSuccessOrErrorMessage />
            <div className={classes.change_background}>
              {editMedicalBackground === false && (
                <div onClick={() => startEdition()}>
                  <div className={classes.row}>
                    <div className={classes.half_column}>
                      <SmartTooltip
                        title={
                          labels.CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND
                        }
                        placement="bottom"
                      >
                        <MedicalBackgroundColumn
                          unknownLabel={labels.UNKNOWN}
                          label={labels.CURRENT_LIVING_SITUATION}
                          value={
                            (medicalBackgroundState.currentSituation ||
                              labels.UNKNOWN) +
                            (medicalBackgroundState.locationName
                              ? ' | ' + medicalBackgroundState.locationName
                              : '')
                          }
                        />
                      </SmartTooltip>
                    </div>
                  </div>
                  {inSendReferralPage(location) && (
                    <div className={classes.row}>
                      <SmartTooltip
                        title={
                          labels.CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND
                        }
                        placement="bottom"
                      >
                        <MedicalBackgroundColumn
                          unknownLabel={labels.UNKNOWN}
                          label={labels.REHAB_SKILLED_NURSING_REQUIRED}
                          value={
                            medicalBackgroundState.rehabSkilledNursingRequired
                          }
                        />
                      </SmartTooltip>
                    </div>
                  )}

                  {enableReferralAlertValidation && (
                    <>
                      <div className={classes.row}>
                        <ContainerItem xs={12} lg={6}>
                          <SmartTooltip
                            title={
                              labels.CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND
                            }
                            placement="bottom"
                          >
                            <MedicalBackgroundColumn
                              unknownLabel={labels.UNKNOWN}
                              label={labels.REHAB_SKILLED_NURSING_REQUIRED}
                              value={
                                medicalBackgroundState.rehabSkilledNursingRequired
                              }
                            />
                          </SmartTooltip>
                        </ContainerItem>
                        <ContainerItem xs={12} lg={6}>
                          <SmartTooltip
                            title={
                              labels.CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND
                            }
                            placement="bottom"
                          >
                            <MedicalBackgroundColumn
                              unknownLabel={labels.UNKNOWN}
                              label={labels.CURRENT_MEDICATIONS}
                              value={medicalBackgroundState.currentMedication}
                            />
                          </SmartTooltip>
                        </ContainerItem>
                      </div>
                      {medicalBackgroundState.memoryLoss && (
                        <>
                          <div className={classes.row}>
                            <ContainerItem xs={12} lg={6}>
                              <SmartTooltip
                                title={
                                  labels.CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND
                                }
                                placement="bottom"
                              >
                                <MedicalBackgroundColumn
                                  unknownLabel={labels.UNKNOWN}
                                  label={labels.MEMORY_LOSS}
                                  value={renderMemoryText(
                                    medicalBackgroundState,
                                  )}
                                  icon={<Memory />}
                                />
                              </SmartTooltip>
                            </ContainerItem>
                            {medicalBackgroundState.memoryLoss.toLowerCase() ===
                              'yes' && (
                              <ContainerItem xs={12} lg={6}>
                                <SmartTooltip
                                  title={
                                    labels.CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND
                                  }
                                  placement="bottom"
                                >
                                  <MedicalBackgroundColumn
                                    unknownLabel={labels.UNKNOWN}
                                    label={labels.OTHER_ISSUES}
                                    value={medicalBackgroundState.otherIssues}
                                  />
                                </SmartTooltip>
                              </ContainerItem>
                            )}
                          </div>
                        </>
                      )}
                      <div className={classes.row}>
                        <ContainerItem xs={12} lg={6}>
                          <SmartTooltip
                            title={
                              labels.CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND
                            }
                            placement="bottom"
                          >
                            <MedicalBackgroundColumn
                              unknownLabel={labels.UNKNOWN}
                              label={labels.GETTING_AROUND}
                              value={
                                medicalBackgroundState.gettingAroundDescription
                              }
                              icon={
                                gettingAroundIcons[
                                  medicalBackgroundState
                                    .gettingAroundDescription
                                ]
                              }
                            />
                          </SmartTooltip>
                        </ContainerItem>
                        <ContainerItem xs={12} lg={6} className={classes.flex}>
                          <div className={classes.flex_grow}>
                            <SmartTooltip
                              title={
                                labels.CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND
                              }
                              placement="bottom"
                            >
                              <MedicalBackgroundColumn
                                unknownLabel={labels.UNKNOWN}
                                label={labels.HELP_IN_SHOWER}
                                value={renderYesOrNo(
                                  medicalBackgroundState.helpInShower,
                                )}
                              />
                            </SmartTooltip>
                          </div>
                          <div className={classes.flex_grow}>
                            <SmartTooltip
                              title={
                                labels.CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND
                              }
                              placement="bottom"
                            >
                              <MedicalBackgroundColumn
                                unknownLabel={labels.UNKNOWN}
                                label={labels.HELP_IN_TOILETING}
                                value={renderYesOrNo(
                                  medicalBackgroundState.helpInToileting,
                                )}
                              />
                            </SmartTooltip>
                          </div>
                        </ContainerItem>
                      </div>
                      <div className={classes.row_last_child}>
                        <ContainerItem xs={12} lg={6}>
                          <SmartTooltip
                            title={
                              labels.CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND
                            }
                            placement="bottom"
                          >
                            <MedicalBackgroundColumn
                              unknownLabel={labels.UNKNOWN}
                              label={labels.DIABETIC_CARE}
                              value={medicalBackgroundState.diabeticCare}
                            />
                          </SmartTooltip>
                        </ContainerItem>
                        <ContainerItem xs={12} lg={6}>
                          <SmartTooltip
                            title={
                              labels.CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND
                            }
                            placement="bottom"
                            className={classes.row}
                          >
                            <MedicalBackgroundColumn
                              unknownLabel={labels.UNKNOWN}
                              label={
                                labels.ARE_THERE_ANY_OTHER_HEALTH_ISSUES_AND_OR_NUTRITIONAL_NEEDS
                              }
                              value={medicalBackgroundState.healthIssues || '-'}
                            />
                          </SmartTooltip>
                          <SmartTooltip
                            title={
                              labels.CLICK_TO_EDIT_OR_UPDATE_MEDICAL_BACKGROUND
                            }
                            placement="bottom"
                            className={classes.row}
                          >
                            <MedicalBackgroundColumn
                              unknownLabel={labels.UNKNOWN}
                              label={
                                labels.IS_THERE_ANYTHING_ELSE_YOU_WOULD_LIKE_TO_TELL_ME_ABOUT_YOUR_LOVED_ONE
                              }
                              value={medicalBackgroundState.anythingElse || '-'}
                            />
                          </SmartTooltip>
                        </ContainerItem>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </Fragment>
        )}
        {editMedicalBackground === true && (
          <div>
            <MedicalBackgroundDesktopEdit
              medicalBackgroundData={medicalBackgroundState}
              labels={labels}
              shouldShowActionControls={true}
              onSave={saveMedicalBackground}
              onCancel={cancelMedicalBackgroundEdit}
              onChange={setMedicalBackgroundFormState}
              isSelfLead={isSelfLead}
              enableReferralAlertValidation={
                enableEditForReferralAlertValidation
              }
              showSidePanel={showSidePanel}
            />
          </div>
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {shouldBeEditable() && (
        <EditCard
          headerColor="platinum4"
          type="light"
          title={labels.MEDICAL_BACKGROUND}
          icon={<Pulse />}
          className={classes.container}
          clickCallback={() => startEdition()}
          flags={flags}
          allowEdit={shouldBeEditable()}
        >
          <MedicalBackgroundInfo />
        </EditCard>
      )}
      {!shouldBeEditable() && (
        <Card
          className={classes.container}
          title={labels.MEDICAL_BACKGROUND}
          icon={<Pulse />}
        >
          <MedicalBackgroundInfo />
        </Card>
      )}
    </Fragment>
  );
};

MedicalBackgroundDesktop.propTypes = {
  labels: PropTypes.object.isRequired,
  showSidePanel: PropTypes.bool,
  onCancelEdit: PropTypes.func,
  medicalBackgroundData: PropTypes.shape({
    formDataId: PropTypes.number,
    rehabSkilledNursingRequired: PropTypes.string,
    memoryLoss: PropTypes.string,
    memoryDiagnosis: PropTypes.string,
    currentSituation: PropTypes.string,
    locationName: PropTypes.string,
    currentMedication: PropTypes.string,
    otherIssues: PropTypes.string,
    moreHelp: PropTypes.bool,
    helpInShower: PropTypes.bool,
    helpInToileting: PropTypes.bool,
    diabeticCare: PropTypes.string,
    gettingAroundType: PropTypes.string,
    gettingAroundDescription: PropTypes.string,
    oneId: PropTypes.string,
    familyFileId: PropTypes.number,
  }).isRequired,
  flags: PropTypes.shape({
    updateContactsOwl: PropTypes.bool,
    isInitialConsultationEnabled: PropTypes.bool,
  }),
  isSelfLead: PropTypes.bool,
  enableReferralAlertValidation: PropTypes.bool,
  onSave: PropTypes.func,
  allowEdit: PropTypes.bool,
};

MedicalBackgroundDesktop.defaultProps = {
  memoryLoss: false,
  enableReferralAlertValidation: true,
  onCancelEdit: () => {},
  onSave: () => {},
  allowEdit: false,
};

export default withLDConsumer()(MedicalBackgroundDesktop);
