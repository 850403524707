import axios from 'axios';
import withConfig from './withConfig';

axios.defaults.timeout = 20000;

const post = async (url, data) => {
  return await axios.post(url, data);
};

const buildRecipients = (emailAddresses) => {
  if (window.ENV.REACT_APP_ENVIRONMENT !== 'prod') {
    return [
      {
        email: withConfig('YGL_EMAIL_TESTING_ACOCUNT'),
        name: withConfig('YGL_EMAIL_NAME_TESTING_ACOCUNT'),
      },
    ];
  }
  return emailAddresses;
};

const sendEmail = async (
  url,
  subject,
  currentUser,
  emailContent,
  recipient,
) => {
  const fullName = `${currentUser.firstName} ${currentUser.lastName}`;
  const sendEmailPromise = post(
    `${withConfig('MANDRILL_API_ENDPOINT')}${url}`,
    {
      key: withConfig('MANDRILL_API_KEY'),
      message: {
        html: emailContent,
        subject: subject,
        from_email: currentUser.email,
        from_name: fullName,
        to: buildRecipients(recipient),
        track_opens: true,
        track_clicks: true,
        important: true,
      },
    },
  )
    .then((sendingEmailResponse) => {
      return sendingEmailResponse;
    })
    .catch((error) => {
      console.error('Error sending email: ', error);
      return { code: 421 };
    });
  return sendEmailPromise;
};

const sendEmailThroughTemplate = async (
  url,
  subject,
  sender,
  templateName,
  templateContent,
  recipient,
  inquiry,
) => {
  const fullName = `${sender.firstName} ${sender.lastName}`;
  const sendEmailPromise = post(
    `${withConfig('MANDRILL_API_ENDPOINT')}${url}`,
    {
      key: withConfig('MANDRILL_API_KEY'),
      template_name: templateName,
      template_content: templateContent,
      message: {
        subject: subject,
        from_email: sender.email,
        from_name: fullName,
        to: buildRecipients(recipient),
        track_opens: true,
        track_clicks: true,
        important: true,
        global_merge_vars: [
          { name: `fContactFirstName`, content: inquiry?.firstName },
        ],
      },
    },
  )
    .then((sendingEmailResponse) => {
      return sendingEmailResponse;
    })
    .catch((error) => {
      console.error('Error sending email: ', error);
      return { code: 421, error };
    });
  return sendEmailPromise;
};

export default { post, sendEmail, sendEmailThroughTemplate };
