import React from 'react';
import PropTypes from 'prop-types';

const CalendarCreate = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="currentColor"
        d="M6 6H8V5H16V6H18V5H20V8H4V5H6V6ZM4 10V20H14V22H4C2.89543 22 2 21.1046 2 20V5C2 3.89543 2.89543 3 4 3H6V2H8V3H16V2H18V3H20C21.1046 3 22 3.89543 22 5V14H20V10H4ZM21 19V17H19V19H17V21H19V23H21V21H23V19H21Z"
      />
    </svg>
  );
};

CalendarCreate.propTypes = {
  className: PropTypes.string,
};

export default CalendarCreate;
