import gql from 'graphql-tag';

export default gql`
  query($communityId: Int!) {
    findAdvisoryData(propertyID: $communityId) {
      advisoryId
      advisoryType
      advisoryStatus
      advisoryEndDate
      advisoryStartDate
      advisoryDescription
      activityRestriction
      activityRestrictionDetails
      activityRestrictionEndDate
      activityRestrictionLevel
      evacuationInformation
      evacuationInformationLevel
      evacuationInformationDetails
      evacuationInformationEndDate
      tourRestriction
      tourRestrictionLevel
      tourRestrictionDetails
      tourRestrictionEndDate
      moveInRestriction
      moveInRestrictionLevel
      moveInRestrictionEndDate
      moveInRestrictionDetails
      assessmentRestriction
      assessmentRestrictionLevel
      assessmentRestrictionDetails
      assessmentRestrictionEndDate
      diningRestriction
      diningRestrictionLevel
      diningRestrictionDetails
      diningRestrictionEndDate
      visitorRestriction
      visitorRestrictionLevel
      visitorRestrictionDetails
      visitorRestrictionEndDate
    }
  }
`;
