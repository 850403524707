import React, { useState, useRef, useEffect } from 'react';
import { useStyles } from './Dropdown.style';
import ChevronBottom from '../Icons/arrow/ChevronBottom';
import ChevronTop from '../Icons/arrow/ChevronTop';
import clsx from 'clsx';
import PropTypes from 'prop-types';
const Dropdown = ({
  error,
  items,
  emptyLabel,
  label,
  textHint,
  onChange,
  selectedItem,
  disabled,
  type,
  className,
  isMandatory,
  dropdownClass,
  useSetState,
  withScroll,
}) => {
  const classes = useStyles();
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(selectedItem);
  const selectClasses = clsx([
    classes.custom_select,
    error && classes.error,
    disabled && classes.disabled,
    selected && classes.filled,
    classes[type],
    className,
    'custom_select',
  ]);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);
  const selectItem = (item) => {
    onChange(item);
    setSelected(item);
    setIsOpen(false);
  };
  const handleOptionList = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    if (useSetState) {
      setSelected(selectedItem);
    }
  }, [selectedItem, useSetState]);

  return (
    <div className={selectClasses} ref={wrapperRef} data-testid="dropdown-id">
      <div className={clsx(classes.label, 'star')}>
        {label}
        {isMandatory && <span className={classes.mandatory}>*</span>}
      </div>
      <div className={classes.select} onClick={handleOptionList} tabIndex="0">
        <div>{selected ? selected.label : emptyLabel}</div>
        <div className={classes.icon}>
          {isOpen ? <ChevronTop /> : <ChevronBottom />}
        </div>
      </div>
      {isOpen && !disabled && (
        <div
          className={clsx(classes.menu, withScroll ? classes.with_scroll : '')}
        >
          <div
            className={dropdownClass ? dropdownClass : classes.items_container}
          >
            {items.map((item) => (
              <div
                key={item.value}
                className={clsx(
                  selectedItem &&
                    selectedItem.value === item.value &&
                    classes.selected,
                )}
                onClick={() => selectItem(item)}
              >
                {item.label}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={classes.text_hint}>{textHint}</div>
    </div>
  );
};
Dropdown.propTypes = {
  error: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  emptyLabel: PropTypes.string,
  label: PropTypes.string,
  textHint: PropTypes.string,
  type: PropTypes.oneOf(['default', 'textual', 'buttoned']),
  onChange: PropTypes.func,
  selectedItem: PropTypes.object,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  isMandatory: PropTypes.bool,
  dropdownClass: PropTypes.string,
  useSetState: PropTypes.bool,
  withScroll: PropTypes.bool,
};
Dropdown.defaultProps = {
  emptyLabel: 'Select from list',
  type: 'default',
  error: false,
  disabled: false,
  onChange: () => {},
  className: '',
  isMandatory: false,
  dropdownClass: '',
  useSetState: true,
  withScroll: false,
};
export default Dropdown;
